import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as actions from '../../actions';
import * as settingsSelectors from '../../selectors';
import { selectors as coreSelectors, translate } from '../../../core';
// import { selectors as authSelectors, actions as authActions } from '../../../auth';
import { LIST_ITEM_TYPES } from '../../constants';

export default function WithHelpAndSupportBase(WrappedComponent) {
    class HelpAndSupportBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            user: PropTypes.object.isRequired,
            feedbackMetadata: PropTypes.array,
        };

        static defaultProps = {
            feedbackMetadata: [],
        };

        constructor(props) {
            super(props);
            this.props.actions.getFeedbackMetadata(this.props.user.partnerId);
        }

        getHelpOptions = func => {
            if (_.isArray(this.props.feedbackMetadata)) {
                return _.filter(this.props.feedbackMetadata, option => (option.type === LIST_ITEM_TYPES.faq || option.type === LIST_ITEM_TYPES.help))
                    .map(option => ({
                        type: option.type,
                        title: option.title,
                        onPress: () => func(option)
                    }));
            }
            return [];
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    getHelpOptions={this.getHelpOptions}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => ({
        feedbackMetadata: settingsSelectors.getFeedbackMetadata(state),
        user: coreSelectors.getCurrentUser(state),
    });

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators({ ...actions }, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(HelpAndSupportBase));
}

export const styles = {
};