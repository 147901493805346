import React from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';
import classnames from 'classnames';
import { baseColors, spacing, layoutStyle, appFonts } from '../../../../styles';
import { components as Core } from '../../../core';
import WithHighestProgressIndicatorBase, { styles as baseStyles } from './HighestProgressIndicatorBase';

const HighestProgressIndicator = ({ highestLevelIndicatorText, className, isLarger }) => (
    <div className={classnames(css(layoutStyle.flexRow, !isLarger && layoutStyle.flexAlignCenter), className)}>
        <Core.Icon
            type="fa"
            fill="solid"
            name="medal"
            size={isLarger ? spacing.s5 : spacing.s3}
            color={baseColors.success}
        />
        <p className={css(styles.indicatorText, isLarger && styles.textLarger)}>
            {highestLevelIndicatorText}
        </p>
    </div>
);

HighestProgressIndicator.propTypes = {
    highestLevelIndicatorText: PropTypes.string,
    className: PropTypes.string,
    isLarger: PropTypes.bool
};

HighestProgressIndicator.defaultProps = {
    highestLevelIndicatorText: null,
    className: null,
    isLarger: false
};

export default WithHighestProgressIndicatorBase(HighestProgressIndicator);

const styles = StyleSheet.create({
    ...baseStyles,
    textLarger: {
        ...appFonts.xlRegular
    }
});
