import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import classNames from 'classnames';
import { IconButton } from '../Icon';
import { layoutStyle, baseColors, importantClass, spacing, appFonts } from '../../../../styles';
import WithClosableInfoSectionBase, { styles as baseStyles } from './ClosableInfoSectionBase';
import Button from '../Button';

class ClosableInfoSection extends PureComponent {
    static propTypes = {
        iconProps: PropTypes.func,
        onClose: PropTypes.func.isRequired,
        text: PropTypes.string.isRequired,
        className: PropTypes.string,
        color: PropTypes.string,
        linkAction: PropTypes.func,
        linkMessage: PropTypes.string,
        isLightStyle: PropTypes.bool,
        lightLinkMessage: PropTypes.node,
        modalLightDesign: PropTypes.bool,
    };

    static defaultProps = {
        color: baseColors.white,
        iconProps: null,
        className: null,
        linkAction: undefined,
        linkMessage: undefined,
        isLightStyle: false,
        lightLinkMessage: null,
        modalLightDesign: false,
    };

    render() {
        const { onClose, text, className, isLightStyle, modalLightDesign, lightLinkMessage } = this.props;
        return (
            <div className={classNames(css(styles.container, layoutStyle.flex, isLightStyle ? styles.lightContainer : null), className)}>
                <IconButton
                    {...this.props.iconProps()}
                    type="fa"
                    fill="light"
                    isSmall={true}
                    className={css(modalLightDesign || isLightStyle ? styles.lightIcon : styles.icon)}
                    onClick={onClose}
                    color={isLightStyle ? baseColors.contextDarkest : baseColors.white}
                />
                <div className={css(styles.text, isLightStyle ? styles.lightText : null, modalLightDesign ? styles.textWrapper : null)}>
                    {text}
                    {lightLinkMessage}
                </div>
                {this.props.linkAction && !modalLightDesign ? (
                    <Button
                        className={css(styles.linkAction, styles.text, styles.linkText, isLightStyle ? styles.lightButtonText : null)}
                        type="text"
                        onPress={this.props.linkAction}>
                        {this.props.linkMessage}
                    </Button>
                ) : null }
            </div>
        );
    }
}

export default WithClosableInfoSectionBase(ClosableInfoSection);

const styles = StyleSheet.create({
    ...baseStyles,
    text: importantClass({
        ...baseStyles.text
    }),
    linkAction: importantClass({
        justifyContent: 'flex-start'
    }),
    container: {
        ...baseStyles.container,
        flexFlow: 'column'
    },
    lightIcon: importantClass({
        ...baseStyles.icon,
        marginTop: spacing.s0,
        marginRight: 0,
        '&:hover': {
            backgroundColor: 'transparent',
        }
    }),
    textWrapper: {
        paddingBottom: spacing.s1,
    },
    lightButtonText: importantClass({
        ...baseStyles.lightText,
        ...appFonts.mdBold,
        marginRight: 'auto',
        '&:hover': {
            backgroundColor: 'transparent',
        }
    }),
});