import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { baseColors } from '../../../../styles';
import * as selectors from '../../selectors';
import { translate, getFirstLoadingWrapper } from '../../../core';
import * as onboardingSelectors from '../../../onboarding/selectors';
import { DEVICES_DASHBOARD_STATUS } from '.././../constants';
import * as actions from '../../actions';

const { getAppsDevices } = actions;

export default function WithAppsDevicesDashboardStatusBase(WrappedComponent) {
    class AppsDevicesDashboardStatusBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            isAppOrDeviceConnected: PropTypes.bool,
            isAppsOrDevicesAvailable: PropTypes.bool,
            hasAppsDevicesTask: PropTypes.bool,
            isAppOrDeviceInError: PropTypes.bool,
            isAppOrDeviceInWarning: PropTypes.bool,
            isLoadingDevices: PropTypes.bool
        };

        static defaultProps = {
            isAppOrDeviceConnected: false,
            isAppsOrDevicesAvailable: false,
            hasAppsDevicesTask: false,
            isAppOrDeviceInError: false,
            isAppOrDeviceInWarning: false,
            isLoadingDevices: false
        };

        constructor(props) {
            super(props);
            this.loadData();
            this.state = {
                //eslint-disable-next-line
                isLoading: props.isLoadingDevices,  //used in getFirstLoadingWrapper
                isFirstLoaded: false
            };
        }

        static getDerivedStateFromProps(nextProps, prevState) {
            return getFirstLoadingWrapper(nextProps, prevState, 'isLoadingDevices');
        }

        get appsDevicesStatusIcon() {
            const { isAppOrDeviceConnected, isAppOrDeviceInError, isAppOrDeviceInWarning } = this.props;

            if (isAppOrDeviceConnected) {
                if (isAppOrDeviceInError) {
                    return { type: 'solid', name: 'times-circle', color: baseColors.danger };
                } else if (isAppOrDeviceInWarning) {
                    return { type: 'solid', name: 'exclamation-circle', color: baseColors.warn };
                }

                return { type: 'solid', name: 'check-circle', color: baseColors.success };
            }

            return { type: 'light', name: 'plus-circle', color: baseColors.secondary };
        }

        get statusAlertType() {
            const { isAppOrDeviceConnected, isAppOrDeviceInError, isAppOrDeviceInWarning } = this.props;
            if (isAppOrDeviceConnected) {
                if (isAppOrDeviceInError) {
                    return DEVICES_DASHBOARD_STATUS.ERROR;
                } else if (isAppOrDeviceInWarning) {
                    return DEVICES_DASHBOARD_STATUS.WARNING;
                }
            }

            return DEVICES_DASHBOARD_STATUS.NONE;
        }

        loadData = () => this.props.actions.getAppsDevices();

        render() {
            const { isFirstLoaded } = this.state;
            return (
                <WrappedComponent
                    {...this.props}
                    appsDevicesStatusIcon={this.appsDevicesStatusIcon}
                    statusAlertType={this.statusAlertType}
                    isFirstLoadedAppsDevices={isFirstLoaded}
                    loadData={this.loadData}
                />
            );
        }
    }

    function mapStateToProps(state) {
        return {
            isAppOrDeviceConnected: !!selectors.getNumberOfConnectedDevices(state),
            isAppsOrDevicesAvailable: !!selectors.isAppsOrDevicesAvailable(state),
            hasAppsDevicesTask: onboardingSelectors.hasAppsDevicesTask(state),
            isAppOrDeviceInError: selectors.getIsAtLeastOneDeviceInError(state),
            isAppOrDeviceInWarning: selectors.getIsAtLeastOneDeviceInWarning(state),
            isLoadingDevices: selectors.isLoadingDevices(state),
            lastSyncDate: selectors.getLastSyncDate(state),
        };
    }

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators({ getAppsDevices }, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(AppsDevicesDashboardStatusBase));
}

const ICON_PADDING_RIGHT = 2;

export const styles = {
    appsDevicesIconPadding: {
        paddingRight: ICON_PADDING_RIGHT,
    },
};
