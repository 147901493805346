/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { css, StyleSheet } from 'aphrodite-jss';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import classnames from 'classnames';
import { layoutStyle, spacing, baseColors, important, importantClass } from '../../../../../styles';
import { components as Core, ROUTES } from '../../../../core';
import WithJoinTeamsItemBase, { styles as baseStyles } from './JoinTeamsItemBase';

class JoinTeamsItem extends PureComponent {
    static propTypes = {
        challengeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        history: PropTypes.object.isRequired,
        i18n: PropTypes.object.isRequired,
        isJoining: PropTypes.bool.isRequired,
        challenge: PropTypes.object.isRequired,
        joinTeam: PropTypes.func.isRequired,
        team: PropTypes.func.isRequired,
        close: PropTypes.func,
        isInvitedByVisible: PropTypes.bool.isRequired,
        invitedByDetails: PropTypes.object.isRequired,
        hasLargeAvatar: PropTypes.bool,
        isTeamFull: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        close: null,
        hasLargeAvatar: false
    };

    componentDidUpdate(prevProps) {
        if (!this.props.isJoining && prevProps.isJoining && this.props.close) {
            this.props.close();
        }
    }

    showTeamPage = () => {
        const { challengeId, team, team: { entityId } } = this.props;
        if (this.props.close) this.props.close();
        this.props.history.push(ROUTES.teamDetails(challengeId, entityId), { team });
    };

    joinTeam = event => {
        event.preventDefault();
        event.stopPropagation();
        this.props.joinTeam();
    };

    get isChallengeMember() {
        return _.get(this.props.challenge, 'isMember');
    }

    get renderMemberCount() {
        const { i18n, membersCount, isTeamFull } = this.props;

        if (!membersCount) return null;

        if (!isTeamFull) {
            return (
                <p className={css(styles.rowSubtitle)}>{membersCount}</p>
            );
        }

        return (
            <div className={css(styles.teamDetailsRow)}>
                <p className={css(styles.rowSubtitle)}>{membersCount}</p>
                <Core.Icon
                    type="fa"
                    fill="solid"
                    name="circle"
                    size={spacing.s0}
                    color={baseColors.grey40}
                    className={css(styles.dotIcon)}
                />
                <p className={css(styles.rowSubtitle)}>{i18n.t('teams.teamFull')}</p>
            </div>
        );
    }

    get renderInvitedBy() {
        const { isInvitedByVisible, invitedByDetails } = this.props;

        if (!isInvitedByVisible) return null;

        return (
            <div className={css(styles.teamDetailsRow, styles.detailsPadding)}>
                <Core.Avatar
                    url={invitedByDetails.image}
                    name={invitedByDetails.name}
                    size="xsmall"
                    className={css(styles.smallAvatar)}
                    disablePress={true}
                />
                <p className={css(styles.rowSubtitle)}>{invitedByDetails.string}</p>
            </div>
        );
    }

    render() {
        const { team, hasLargeAvatar, isLastItem } = this.props;
        return (
            <Core.ListItem
                key={team.entityId}
                onClick={this.showTeamPage}
                className={classnames(css(layoutStyle.listItem), css(styles.borderBottomItem), isLastItem && css(styles.noBottomBorder))}
                button={true}>
                <div className={css(styles.item)}>
                    {hasLargeAvatar ? (
                        <Core.Avatar
                            url={team.imageURL}
                            name={team.name}
                            size="small"
                            disablePress={true}
                            className={css(styles.avatarOffset)}
                        />
                    ) : null}
                    <Core.ListItemText
                        primary={(
                            <div className={css(styles.detailsSection)}>
                                <p className={css(styles.rowTitleStyle)}>{team.name}</p>
                                {this.renderMemberCount}
                                {this.renderInvitedBy}
                            </div>
                        )}
                    />
                    <Core.Icon
                        type="fa"
                        size={spacing.s3}
                        name="chevron-right"
                        color={baseColors.grey40}
                    />
                </div>
            </Core.ListItem>
        );
    }
}

const styles = StyleSheet.create({
    ...baseStyles,
    avatarOffset: {
        marginRight: spacing.s3
    },
    teamDetailsRow: {
        ...baseStyles.teamDetailsRow,
        display: 'flex'
    },
    detailsPadding: {
        paddingTop: spacing.s0
    },
    item: {
        flexDirection: 'row',
        alignItems: 'center',
        display: 'flex',
        flex: 1,
        paddingLeft: spacing.s1,
        paddingRight: spacing.s1,
    },
    borderBottomItem: importantClass({
        borderBottomColor: baseColors.grey80,
        '&:hover': {
            backgroundColor: baseColors.grey90
        }
    }),
    noBottomBorder: importantClass({
        borderBottomWidth: 0
    })
});

export default withRouter(WithJoinTeamsItemBase(JoinTeamsItem));
