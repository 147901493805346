import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { translate } from '../../../core';
import { appFonts, baseColors, spacing } from '../../../../styles';
import * as invitationActions from '../../../invitations/actions';
import { getPlatformInvitationsWithEmail, isLoadingPlatformInvitations } from '../../../invitations/selectors';

export default function WithFamilyMembersBase(WrappedComponent) {
    class FamilyMembersBase extends Component {
        static propTypes = {
            platformInvitations: PropTypes.array.isRequired,
            isLoading: PropTypes.bool.isRequired,
            invitationActions: PropTypes.object.isRequired,
            i18n: PropTypes.object.isRequired,
        };
        constructor(props) {
            super(props);
            this.props.invitationActions.getPlatformInvitations();
        }

        get title() {
            return this.props.i18n.t('familyMembers');
        }

        get subtitle() {
            return this.props.i18n.t('familyMembersDescription');
        }

        get inviteBtnLabel() {
            return this.props.i18n.t('inviteFamilyMember.label');
        }
        get invitationStatusActive() {
            return this.props.i18n.t('familyInvitationStatus.active');
        }
        get invitationStatusSent() {
            return this.props.i18n.t('familyInvitationStatus.sent');
        }
        getFullName = item => `${item.invitee.firstname} ${item.invitee.lastname}`

        getWarningProps = (text, onDelete, onCancel) => {
            const { i18n } = this.props;
            return {
                isButtonVisible: false,
                title: i18n.t('areYouSure'),
                text,
                textSecondLine: i18n.t('deleteFamilyMember.desc'),
                checkboxLabel: i18n.t('deleteCommunityCheckbox'),
                uncheckedColor: baseColors.danger,
                buttons: [
                    { text: i18n.t('deleteFamilyMember.btn'), onPress: () => onDelete(), isDangerText: true, isDisabled: true },
                    { text: i18n.t('button_cancel'), onPress: onCancel ? () => onCancel() : null },
                ],
            };
        };

        deleteFamilyMember = item => {
            const { i18n } = this.props;
            const title = i18n.t('deleteFamilyMember.confirmation.title');
            const msg = i18n.t('deleteFamilyMember.confirmation.desc', { fullName: this.getFullName(item) });
            this.props.invitationActions.deletePlatformInvitation(item.id, item.invitee.firstname, item.userId, title, msg);
        }

        getInfoModalText = item => {
            const fullName = this.getFullName(item);
            return this.props.i18n.t('deleteFamilyMember.areYouSure', { fullName });
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    title={this.title}
                    subtitle={this.subtitle}
                    inviteBtnLabel={this.inviteBtnLabel}
                    getWarningProps={this.getWarningProps}
                    deleteFamilyMember={this.deleteFamilyMember}
                    getInfoModalText={this.getInfoModalText}
                    invitationStatusActive={this.invitationStatusActive}
                    invitationStatusSent={this.invitationStatusSent}
                    getFullName={this.getFullName}
                />
            );
        }
    }

    const mapStateToProps = state => ({
        platformInvitations: getPlatformInvitationsWithEmail(state),
        isLoading: isLoadingPlatformInvitations(state)
    });

    function mapDispatchToProps(dispatch) {
        return {
            invitationActions: bindActionCreators(invitationActions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(FamilyMembersBase));
}

export const styles = {
    subtitle: {
        ...appFonts.smRegular,
        color: baseColors.grey40,
        margin: spacing.s3,
    },
    inviteeName: {
        ...appFonts.mdMedium
    },
    inviteeEmail: {
        ...appFonts.smRegular,
        color: baseColors.grey40
    },
    activeInviteStatus: {
        ...appFonts.smRegular,
        color: baseColors.successDarker
    },
    pendingInviteStatus: {
        ...appFonts.smRegular,
        color: baseColors.warnDarker
    }
};

