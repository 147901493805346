import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Header from '../Header';
import ListItem from '../ListItem';
import WithCreateEntityPageBase from './PageBase';

class CreateEntityPage extends PureComponent {
    static propTypes = {
        onPress: PropTypes.func,
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string,
        items: PropTypes.array,
        titleClassName: PropTypes.string,
        keyExtractor: PropTypes.func.isRequired,
        children: PropTypes.node,
    };

    static defaultProps = {
        subtitle: undefined,
        titleClassName: undefined,
        children: undefined,
        items: undefined,
        onPress: undefined,
    };

    renderItem = item => (
        <ListItem key={this.props.keyExtractor(item)} item={item} onPress={this.props.onPress} />
    );

    render() {
        return (
            <Fragment>
                <Header
                    className={this.props.titleClassName}
                    title={this.props.title}
                    subtitle={this.props.subtitle}
                />
                {this.props.items ? _.map(this.props.items, this.renderItem) : null}
                {this.props.children}
            </Fragment>
        );
    }
}

export default WithCreateEntityPageBase(CreateEntityPage);
