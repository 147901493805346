import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import MUIAvatar from '@material-ui/core/Avatar';
import { baseColors, importantStyles, layoutStyle } from '../../../../styles';
import WithAvatarBase, { SIZES, styles as baseStyles } from './AvatarBase';
import Modal from '../Modal';
import { AsyncComponent } from '../../services';

class Avatar extends PureComponent {
    static propTypes = {
        size: PropTypes.oneOf(['xsmall', 'xmsmall', 'xmsmall20', 'xlsmall', 'small', 'medium', 'large', 'xlarge']).isRequired,
        url: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        disablePress: PropTypes.bool,
        isAvatar: PropTypes.bool,
        className: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number]),
        onPress: PropTypes.func,
        history: PropTypes.object.isRequired,
        classes: PropTypes.object,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        isAvatarContainerDisabled: PropTypes.bool
    };

    static defaultProps = {
        disablePress: false,
        isAvatar: false,
        className: undefined,
        onPress: null,
        classes: undefined,
        isAvatarContainerDisabled: false

    };

    static SIZES = SIZES;

    showProfileDetails = () => {
        if (this.props.onPress) return this.props.onPress();
        if (this.props.history) {
            Modal.open(
                AsyncComponent(() => import('../../../other/components/UserProfile')),
                { id: this.props.id },
                { isContainer: true, isFullHeight: false }
            );
        }
    };

    render() {
        const { url, disablePress, size, name, className, classes, isAvatar, isAvatarContainerDisabled } = this.props;
        const onPress = disablePress ? null : this.showProfileDetails;
        return isAvatar ? (
            <MUIAvatar
                alt="Avatar"
                src={url}
                className={classnames(css(isAvatarContainerDisabled ? null : styles.nameContainer, styles[size], !disablePress ? layoutStyle.cp : null), className)}
                onClick={onPress}
                classes={classes}
            />
        ) : (
            <MUIAvatar
                alt="Avatar"
                className={classnames(css(styles.nameContainer, styles[size], !disablePress ? layoutStyle.cp : null), className)}
                style={{ paddingTop: SIZES[size] * PADDING_MULTIPLIER }}
                onClick={onPress}>
                <span>{name}</span>
            </MUIAvatar>
        );
    }
}


const FONT_SIZE_MULTIPLIER = 2.5;
const PADDING_MULTIPLIER = 0.1;

const styles = StyleSheet.create(importantStyles({
    xsmall: {
        ...baseStyles.xsmall,
        fontSize: SIZES.xsmall / FONT_SIZE_MULTIPLIER
    },
    xmsmall: {
        ...baseStyles.xmsmall,
        fontSize: SIZES.xmsmall / FONT_SIZE_MULTIPLIER
    },
    xmsmall20: {
        ...baseStyles.xmsmall20,
        fontSize: SIZES.xmsmall20 / FONT_SIZE_MULTIPLIER
    },
    xlsmall: {
        ...baseStyles.xlsmall,
        fontSize: SIZES.xlsmall / FONT_SIZE_MULTIPLIER
    },
    small: {
        ...baseStyles.small,
        fontSize: SIZES.small / FONT_SIZE_MULTIPLIER
    },
    medium: {
        ...baseStyles.medium,
        fontSize: SIZES.medium / FONT_SIZE_MULTIPLIER
    },
    large: {
        ...baseStyles.large,
        fontSize: SIZES.large / FONT_SIZE_MULTIPLIER
    },
    xlarge: {
        ...baseStyles.xlarge,
        fontSize: SIZES.xlarge / FONT_SIZE_MULTIPLIER
    },
    nameContainer: {
        ...baseStyles.nameContainer,
        ...baseStyles.name,
        display: 'flex',
        color: baseColors.white
    }
}));

export default withRouter(WithAvatarBase(Avatar));

