import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { translate } from '../../../core';
import * as selectors from '../../selectors';
import { baseColors, fontSize, spacing } from '../../../../styles';

export default function WithOnboardingProfileCarouselBase(WrappedComponent) {
    class OnboardingProfileCarouselBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            slides: PropTypes.array.isRequired,
            companyTasksLength: PropTypes.number.isRequired,
            onRef: PropTypes.func,
        };

        static defaultProps = {
            onRef: undefined
        };

        componentDidMount() {
            if (this.props.onRef) this.props.onRef(this);
        }

        componentWillUnmount() {
            if (this.props.onRef) this.props.onRef(null);
        }

        get length() {
            return this.props.slides.length;
        }

        // is used outside
        hasOnboarding = () => this.length;

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    length={this.length}
                    isDisplayed={!!this.length}
                />
            );
        }
    }

    const mapStateToProps = state => ({
        slides: selectors.getUnfinishedTasks(state),
        percentage: selectors.getPercentageCompleted(state)
    });

    const mapDispatchToProps = dispatch => ({
        actions: bindActionCreators(actions, dispatch)
    });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(OnboardingProfileCarouselBase));
}

export const styles = {
    carousel: { backgroundColor: 'transparent' },
    profileCompletionHeader: {
        marginBottom: spacing.s1
    },
    subtitle: {
        color: baseColors.grey20,
        fontSize: fontSize.xSmall,
        marginBottom: spacing.s0
    },
    marginHorizontal: {
        marginLeft: spacing.s3,
        marginRight: spacing.s3
    },
    onboardingContainer: {
        marginBottom: spacing.s3
    },
    onboardingCarousel: {
        marginTop: spacing.s3
    }
};
