import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { translate } from '../../../core';

export default function WithMoreWebPopupBase(WrappedComponent) {
    class MoreWebPopupBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                />
            );
        }
    }
    const mapStateToProps = state => ({
    });

    const mapDispatchToProps = dispatch => ({
        actions: bindActionCreators({}, dispatch),
    });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(MoreWebPopupBase));
}

