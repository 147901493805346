import React, { PureComponent } from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { spacing, media, importantClass } from '../../../../styles';
import {  tourStyles, webStyles } from '../../styles';
import { components as Core } from '../../../core';

class TourStep extends PureComponent {
    static propTypes = {
        subtitle: PropTypes.string,
        text: PropTypes.string,
        position: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
            pageX: PropTypes.number,
            pageY: PropTypes.number,
            isTabBar: PropTypes.bool
        }),
        hasSubtitle: PropTypes.bool,
        disclaimer: PropTypes.node,
        tooltipClassName: PropTypes.object,
        openSkipModal: PropTypes.func,
        slidesCount: PropTypes.func,
    };

    static defaultProps = {
        subtitle: undefined,
        hasSubtitle: true,
        disclaimer: undefined,
        text: undefined,
        position: undefined,
        tooltipClassName: null,
        openSkipModal: null,
        slidesCount: null
    };

    close = () => {
        this.props.openSkipModal();
    }

    render() {
        const { disclaimer, tooltipClassName, hasSubtitle, subtitle, slidesCount, text } = this.props;
        return (
            <div className={css(styles.mainContainer)}>
                <Core.CloseButton
                    className={css(styles.closeButton)}
                    isSmall={true}
                    onClick={this.close}
                />
                <div className={classnames(css(styles.toolTipContainer), tooltipClassName)} ref={ref => this.container = ref}>
                    <div>
                        {hasSubtitle && subtitle ? (
                            <div className={css(styles.headerContainerTitle)}>
                                <p className={css(styles.subtitle)}>{subtitle}</p>
                            </div>
                        ) : null}
                        {slidesCount ? slidesCount : null}
                        {text ? (
                            <p className={css(styles.text)}>{text}</p>
                        ) : null}
                    </div>
                    {disclaimer}
                </div>
            </div>
        );
    }
}

export default TourStep;

const styles = StyleSheet.create({
    ...tourStyles,
    ...webStyles,
    toolTipContainer: {
        [media.xs]: {
            left: 0,
            marginLeft: spacing.s3,
            marginRight: spacing.s3,
            width: `calc(100% - ${spacing.s7}px) !important`,
            position: 'fixed',
            paddingTop: spacing.s3,
            paddingBottom: spacing.s3
        }
    },
    closeButton: importantClass({
        position: 'absolute',
        top: spacing.s1,
        right: spacing.s1,
    })
});
