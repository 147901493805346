import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { withRouter } from 'react-router-dom';
import { spacing, importantClass } from '../../../../styles';
import { components as Core, Modal, AsyncComponent } from '../../../core';
import Comment from '../Comment';
import WithCommentsBase, { styles as baseStyles } from './CommentsBase';

class Comments extends Component {
    static propTypes = {
        seeMore: PropTypes.func.isRequired,
        onRef: PropTypes.func,
        comments: PropTypes.array,
        i18n: PropTypes.object.isRequired,
        openOptions: PropTypes.func.isRequired,
        onEdit: PropTypes.func.isRequired,
        postComment: PropTypes.func.isRequired,
        isSeeMoreShown: PropTypes.bool.isRequired,
        isFullMode: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        comments: [],
        onRef: undefined
    };

    componentDidMount() {
        if (this.props.onRef) this.props.onRef(this);
    }

    componentWillUnmount() {
        if (this.props.onRef) this.props.onRef(null);
    }

    editComment = comment => {
        Modal.open(
            AsyncComponent(() => import('../CommentEdit/CommentEdit')),
            { commentId: comment.commentId, onEdit: this.props.onEdit },
            { isContainer: true, isFullHeight: false }
        );
    };

    openOptions = comment => this.props.openOptions(comment, this.editComment);

    postComment = (...params) => this.props.postComment(...params);

    render() {
        if (!this.props.comments.length) return null;
        const { i18n, isSeeMoreShown, comments, isFullMode, seeMore } = this.props;
        return (
            <div className={css(styles.commentsContainer)}>
                {isSeeMoreShown &&
                    <Core.Button
                        className={css(styles.seePreviousContainer)}
                        onPress={seeMore}
                        type="text">
                        <span style={styles.seePrevious}>{i18n.t('seePreviousComments')}...</span>
                    </Core.Button> }
                {comments.map(comment => (
                    <Comment
                        key={comment}
                        commentId={comment}
                        showDate={isFullMode}
                        openOptions={this.openOptions}
                    />
                ))}
            </div>
        );
    }
}

export default withRouter(WithCommentsBase(Comments));

const styles = StyleSheet.create({
    ...baseStyles,
    commentsContainer: {
        ...baseStyles.commentsContainer,
        borderTopStyle: 'solid',
    },
    seePreviousContainer: importantClass({
        ...baseStyles.seePreviousContainer,
        paddingLeft: spacing.s2,
        paddingRight: spacing.s2,
        marginLeft: spacing.s6 + 40,
        marginBottom: spacing.s1
    })
});
