import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectors as coreSelectors, translate } from '../../../core';
import { actions as authActions } from '../../../auth';
import { appFonts, baseColors, spacing } from '../../../../styles';

export default function WithSmartModeSettingsBase(WrappedComponent) {
    class SmartModeSettingsBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            i18n: PropTypes.object.isRequired,
            isSmartModeEnabled: PropTypes.bool.isRequired
        };

        constructor(props) {
            super(props);
            const switchStatus = props.isSmartModeEnabled;
            this.state = { switchStatus };
        }

        componentWillMount() {
            this.props.actions.checkSmartModeStatus(this.props.userId);
        }

        static getDerivedStateFromProps(nextProps, prevState) {
            let switchStatus = nextProps.isSmartModeEnabled;
            if (switchStatus !== prevState.switchStatus) {
                switchStatus = prevState.switchStatus;
            }
            const state = { switchStatus };
            return state;
        }

        get title() {
            return this.props.i18n.t('goalSmartMode.title');
        }

        get statusText() {
            const { i18n } = this.props;
            return i18n.t('goalSmartMode.settings.switch');
        }

        get description() {
            const { i18n } = this.props;
            return i18n.t('goalSmartMode.settings.description');
        }

        get switchStatus() {
            return this.state.switchStatus;
        }

        getInfoModalProps = (okHandler, cancelHandler) => {
            const { i18n, isSmartModeEnabled } = this.props;

            return {
                iconName: 'exclamation',
                title: isSmartModeEnabled ? i18n.t('areYouSure') : i18n.t('goalSmartMode.enableSmartMode'),
                text: isSmartModeEnabled ? i18n.t('goalSmartMode.settingsModal.descriptionTurnOff') : i18n.t('goalSmartMode.settingsModal.descriptionTurnOn'),
                isButtonVisible: false,
                buttons: [
                    { text: isSmartModeEnabled ? i18n.t('turnOff') : i18n.t('enable'), onPress: () => okHandler(), isBoldText: true },
                    { text: i18n.t('button_cancel'), onPress: cancelHandler ? () => cancelHandler() : null }
                ]
            };
        }

        changeSetting = () => {
            const { userId, actions } = this.props;
            const currentStatus = this.state.switchStatus;
            this.setState({ switchStatus: !currentStatus });
            actions.updateGoalSmartModeStatus(userId, !currentStatus);
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    title={this.title}
                    statusText={this.statusText}
                    description={this.description}
                    changeSetting={this.changeSetting}
                    getInfoModalProps={this.getInfoModalProps}
                    switchStatus={this.state.switchStatus}
                />
            );
        }
    }

    const mapStateToProps = state => ({
        userId: coreSelectors.getCurrentUserId(state),
        isSmartModeEnabled: coreSelectors.isSmartModeEnabled(state)
    });

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(authActions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(SmartModeSettingsBase));
}

export const styles = {
    text: {
        ...appFonts.mdMedium,
        flex: 1,
        paddingRight: spacing.s2
    },
    descriptionText: {
        paddingTop: spacing.s3,
        ...appFonts.mdRegular
    },
    mainContainer: {
        flexDirection: 'column',
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3
    },
    switchContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottomWidth: 1,
        borderBottomColor: baseColors.grey85,
        paddingBottom: spacing.s3,
        paddingTop: spacing.s3,
        paddingRight: spacing.s1,
    },
};
