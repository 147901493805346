import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import classNames from 'classnames';
import { layoutStyle, spacing } from '../../../../styles';
import WithAboutChallengeBase, { styles as baseStyles } from './AboutChallengeBase';

class AboutChallenge extends PureComponent {
    static propTypes = {
        challenge: PropTypes.object.isRequired,
        isRecommended: PropTypes.bool,
        isUnstarted: PropTypes.bool.isRequired,
        isEnded: PropTypes.bool.isRequired,
        isTeamChallenge: PropTypes.bool.isRequired,
        isGoal: PropTypes.bool.isRequired,
        endDate: PropTypes.string.isRequired,
        frequency: PropTypes.string.isRequired,
        teamSizeText: PropTypes.string.isRequired,
        durationUnitsText: PropTypes.string.isRequired,
        cardBodyClassName: PropTypes.string,
        isRestartGoal: PropTypes.bool,
        titleText: PropTypes.string.isRequired,
        valueText: PropTypes.string.isRequired,
        endsOrEndedText: PropTypes.string.isRequired,
        teamTitleText: PropTypes.string.isRequired,
        durationText: PropTypes.string.isRequired,
        doActivitiesText: PropTypes.string.isRequired,
        isCard: PropTypes.bool,
        cardStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
    };

    static defaultProps = {
        isRecommended: false,
        cardBodyClassName: null,
        isRestartGoal: false,
        isCard: false,
        cardStyle: null
    };

    get frequency() {
        const { frequency, doActivitiesText } = this.props;
        return (
            <div className={css(layoutStyle.flexColumn, layoutStyle.flex1)}>
                <p className={css(styles.flexRowTitleText)}>
                    {doActivitiesText}
                </p>
                <p className={css(styles.flexRowValueText)}>
                    {frequency}
                </p>
            </div>
        );
    }

    get cardStyles() {
        return this.props.isCard && styles.verticalMargin0;
    }

    render() {
        const { teamSizeText, durationUnitsText, isRecommended, challenge, isTeamChallenge, isUnstarted,
            endDate, isEnded, isGoal, cardBodyClassName, isRestartGoal, titleText, valueText, endsOrEndedText,
            teamTitleText, durationText, isCard, cardStyle } = this.props;
        const common = isTeamChallenge ? (
            <div className={css(cardStyle, layoutStyle.flex1)}>
                <p className={css(styles.flexRowTitleText)}>
                    {teamTitleText}
                </p>
                <p className={css(styles.flexRowValueText)}>
                    {teamSizeText}
                </p>
            </div>
        ) : null;

        if (isRecommended) {
            return (
                <div className={classNames(css(styles.cardBody, this.cardStyles), cardBodyClassName)}>
                    <div className={css(cardStyle, layoutStyle.flex1)}>
                        <p className={css(styles.flexRowTitleText)}>
                            {durationText}
                        </p>
                        <p className={css(styles.flexRowValueText)}>
                            {challenge.duration} {durationUnitsText}
                        </p>
                    </div>
                    {this.frequency}
                    {common}
                </div>
            );
        }

        if (isUnstarted) {
            return (
                <div className={classNames(css(styles.cardBody, this.cardStyles), cardBodyClassName)}>
                    <div className={css(cardStyle, layoutStyle.flex1)}>
                        <p className={css(styles.flexRowTitleText)}>
                            {titleText}
                        </p>
                        <p className={css(styles.flexRowValueText)}>
                            {valueText}
                        </p>
                    </div>
                    {isRestartGoal ?
                        null :
                        <div className={css(cardStyle, layoutStyle.flex1)}>
                            <p className={css(styles.flexRowTitleText)}>
                                {endsOrEndedText}
                            </p>
                            <p className={css(styles.flexRowValueText)}>
                                {endDate}
                            </p>
                        </div>}
                    {isCard ? null : this.frequency}
                    {common}
                </div>
            );
        }

        if (isEnded) {
            return (
                <div className={classNames(css(styles.cardBody, this.cardStyles), cardBodyClassName)}>
                    <div className={css(styles.flexRowLeft)}>
                        <p className={css(styles.flexRowTitleText)}>
                            {titleText}
                        </p>
                        <p className={css(styles.flexRowValueText)}>
                            {valueText}
                        </p>
                    </div>
                    {isRestartGoal ?
                        null :
                        <div className={css(styles.flexRowLeft)}>
                            <p className={css(styles.flexRowTitleText)}>
                                {endsOrEndedText}
                            </p>
                            <p className={css(styles.flexRowValueText)}>
                                {endDate}
                            </p>
                        </div>}
                    {common}
                </div>
            );
        }

        return (
            <div className={classNames(css(styles.cardBody, this.cardStyles), cardBodyClassName)}>
                <div className={css(cardStyle, layoutStyle.flex1)}>
                    <p className={css(styles.flexRowTitleText)}>
                        {titleText}
                    </p>
                    <p className={css(styles.flexRowValueText)}>
                        {valueText}
                    </p>
                </div>
                {isRestartGoal ?
                    null :
                    <div className={css(cardStyle, layoutStyle.flex1)}>
                        <p className={css(styles.flexRowTitleText)}>
                            {endsOrEndedText}
                        </p>
                        <p className={css(styles.flexRowValueText)}>
                            {endDate}
                        </p>
                    </div>}
                {isGoal ? this.frequency : null}
                {common}
            </div>
        );
    }
}

export default WithAboutChallengeBase(AboutChallenge);

const styles = StyleSheet.create({
    ...baseStyles,
    cardBody: {
        ...baseStyles.cardBodyStatus,
        alignItems: 'center',
        marginBottom: spacing.s2,
        marginTop: spacing.s7
    },
    verticalMargin0: {
        marginBottom: 0,
        marginTop: 0
    }
});
