import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { translate, DATE_FORMATS } from '../../../core';
import * as selectors from '../../selectors';
import { spacing, baseColors, appFonts } from '../../../../styles';

export const ICON_SIZE = 32;

export default function WithPreviousGoalItemBase(WrappedComponent) {
    class PreviousGoalItemBase extends PureComponent {
        static propTypes = {
            goal: PropTypes.object.isRequired,
            onPress: PropTypes.func.isRequired,
            i18n: PropTypes.object.isRequired,
            isRestartGoal: PropTypes.bool.isRequired,
        };

        onPress = () => this.props.onPress(this.props.goal);

        get dateString() {
            const { goal, i18n, isRestartGoal } = this.props;
            const challengeType = _.capitalize(goal.challengeType);
            const endedString = isRestartGoal ? i18n.t('weeklyGoalEnded') : `${challengeType} ${i18n.t('endedOn')}`;
            return `${endedString} ${this.date}`;
        }

        get achievedString() {
            const { i18n, goal } = this.props;
            return i18n.t('achievedOfGoal', { num: goal.progress.overallUserTotalPercentage });
        }

        get date() {
            return moment(this.props.goal.challengeDeadline).format(DATE_FORMATS.monthFullDayShortYearFull);
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    onPress={this.onPress}
                    achievedString={this.achievedString}
                    dateString={this.dateString}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => ({
        goal: selectors.getChallengeObject(state, ownProps.challengeId),
        isRestartGoal: selectors.isRestartGoal(state, ownProps.challengeId),
    });

    return connect(mapStateToProps)(translate()(PreviousGoalItemBase));
}

export const styles = {
    card: {
        marginLeft: spacing.s3,
        marginRight: spacing.s3,
        paddingTop: spacing.s3,
        paddingBottom: spacing.s3,
        flexDirection: 'row',
        display: 'flex'
    },
    title: {
        ...appFonts.mdBold
    },
    description: {
        ...appFonts.smRegular,
        color: baseColors.grey20,
        marginTop: spacing.s0,
    },
    topMargin: {
        marginTop: spacing.s1,
    },
    weeklyTagWrapper: {
        marginTop: spacing.s0,
    },
    imageArea: {
        marginRight: spacing.s2,
        width: ICON_SIZE,
        height: ICON_SIZE,
    },
};