import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import MUIButton from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { StyleSheet, css } from 'aphrodite-jss';
import classnames from 'classnames';
import { withTheme } from '@material-ui/styles';
import { Fade } from '../Animated';
import { layoutStyle, spacing, importantClass, appFonts, baseColors, containerStyle } from '../../../../styles';
import { telusColor } from '../../../../styles/commonColor';
import { DotsLoading } from '../Loading';

class Button extends PureComponent {
    static SIZES = {
        xsmall: 'xsmall',
        small: 'small',
        medium: 'medium',
        large: 'large'
    };
    static HEIGHTS = {
        xsmall: spacing.s5,
        small: spacing.s7,
        medium: spacing.s9,
        large: spacing.s11,
    };
    static propTypes = {
        onPress: PropTypes.func,
        onLongPress: PropTypes.func,
        children: PropTypes.node,
        type: PropTypes.string,
        color: PropTypes.string,
        circularColor: PropTypes.string,
        isLoading: PropTypes.bool,
        loadingSize: PropTypes.number,
        lowercase: PropTypes.bool,
        withIcon: PropTypes.bool,
        isWidthAuto: PropTypes.bool,
        isHeightAuto: PropTypes.bool,
        size: PropTypes.oneOf(_.values(Button.SIZES)),
        href: PropTypes.string,
        isPrevented: PropTypes.bool,
        isFadeInText: PropTypes.bool,
        spinnerLabel: PropTypes.string,
        spinnerColor: PropTypes.string,
        halfOpacityButton: PropTypes.bool,
        disableMainContainer: PropTypes.bool,
        isSettingPersonalGoal: PropTypes.bool,
        useTelusColors: PropTypes.bool
    };

    static defaultProps = {
        onPress: undefined,
        onLongPress: undefined,
        children: undefined,
        type: 'contained',
        color: 'secondary',
        circularColor: undefined,
        isLoading: false,
        loadingSize: spacing.s5,
        lowercase: true,
        withIcon: false,
        isWidthAuto: false,
        isHeightAuto: false,
        isPrevented: false,
        isFadeInText: false,
        size: Button.SIZES.large,
        href: null,
        spinnerLabel: undefined,
        spinnerColor: undefined,
        halfOpacityButton: false,
        disableMainContainer: false,
        isSettingPersonalGoal: false,
        useTelusColors: false
    };

    static longPressDelay = 1500;

    onButtonPress = () => {
        this.isLongPress = null;
        this.buttonPressTimer = setTimeout(this.onLongPress, Button.longPressDelay);
    };

    onButtonRelease = event => {
        clearTimeout(this.buttonPressTimer);
        if (!this.isLongPress) {
            this.onPress(event);
        }
    };

    onPress = event => {
        this.props.onPress && this.props.onPress(event);
        this.props.isPrevented && event && this.prevent(event);
    };

    onLongPress = () => {
        this.isLongPress = true;
        this.props.onLongPress && this.props.onLongPress();
    };

    prevent = event => {
        event.stopPropagation();
        event.preventDefault();
    };

    onClick = () => {
        if (!this.props.onLongPress) return this.onPress;
        return this.props.isPrevented ? this.prevent : null;
    };

    get materialTheme() {
        return {
            ...this.props.theme,
            palette: {
                ...this.props.theme.palette,
                primary: this.props.theme.palette.secondary
            },
            overrides: {
                MuiButton: {
                    contained: {
                        boxShadow: 'none',
                        '&:hover': {
                            boxShadow: 'none'
                        }
                    },
                },
            }
        };
    }

    get materialThemeTelus() {
        return {
            ...this.materialTheme,
            palette: {
                ...this.materialTheme.palette,
                secondary: { main: telusColor.secondary },
            }
        };
    }

    get theme() {
        return this.props.useTelusColors ? this.materialThemeTelus : this.materialTheme;
    }


    render() {
        const {
            children,
            type,
            color,
            isLoading,
            loadingSize,
            isSubmit,
            lowercase,
            withIcon,
            className,
            disabled,
            isWidthAuto,
            isHeightAuto,
            circularColor,
            size,
            onLongPress,
            isFadeInText,
            spinnerLabel,
            spinnerColor,
            halfOpacityButton,
            disableMainContainer,
            isSettingPersonalGoal,
            ...props
        } = _.omit(this.props, ['onPress', 'isPrevented', 'isMedium']);
        return (
            <ThemeProvider
                theme={this.theme}>
                <MUIButton
                    {...props}
                    disabled={disabled}
                    className={classnames(css(!disableMainContainer && styles.mainContainer, styles[size], containerStyle.buttonFocusVisible), {
                        [css(styles.lowercase)]: lowercase,
                        [css(styles.withIcon)]: withIcon,
                        [css(styles.withLoading)]: isLoading,
                        [css(styles.widthAuto)]: isWidthAuto,
                        [css(styles.heightAuto)]: isHeightAuto
                    }, className)}
                    type={isSubmit ? 'submit' : undefined}
                    variant={type}
                    color={color}
                    classes={{
                        contained: css(styles.whiteText, (halfOpacityButton && isSettingPersonalGoal) ? styles.disabledBgColor : null),
                        disabled: css(halfOpacityButton && disabled ? styles.disabledBgColor : null),
                        outlined: css(halfOpacityButton ? styles.disabledColor : null),
                    }}
                    onTouchStart={onLongPress ? this.onButtonPress : undefined}
                    onTouchEnd={onLongPress ? this.onButtonRelease : undefined}
                    onMouseDown={onLongPress ? this.onButtonPress : undefined}
                    onMouseUp={onLongPress ? this.onButtonRelease : undefined}
                    onClick={this.onClick()}
                    rel={this.props.href ? 'noopener noreferrer' : null}>
                    {isLoading ? (
                        <Fragment>
                            {spinnerLabel ? (
                                <span className={css(styles.full, layoutStyle.flexCenter)}>
                                    <DotsLoading color={spinnerColor || (this.props.type === 'contained' ? baseColors.white : baseColors.secondary)} />
                                    <p className={css(this.labelSizeStyle)}>
                                        {spinnerLabel}
                                    </p>
                                </span>
                            ) : (
                                <Fragment>
                                    <span className={css(styles.hiddenMainText)}>{children}</span>
                                    <span className={css(styles.full, layoutStyle.flexCenter)}>
                                        <CircularProgress color={circularColor || color} size={loadingSize} />
                                    </span>
                                </Fragment>
                            )}
                        </Fragment>
                    ) : (
                        <Fragment>
                            {isFadeInText ? <Fade>{children}</Fade> : children}
                        </Fragment>
                    )}
                </MUIButton>
            </ThemeProvider>
        );
    }
}

export default withTheme(Button);

/* eslint-disable */

//new eslint version doesnt include ingore unused styles rule. Should be fixed by not saving unused styles :/
export const styles = StyleSheet.create({
    mainContainer: importantClass({
        borderRadius: spacing.s11
    }),
    lowercase: importantClass({ textTransform: 'none' }),
    whiteText: { color: 'white!important' },
    secondaryText: { color: baseColors.secondary },
    withIcon: { display: 'block' },
    withLoading: { textAlign: 'center', position: 'relative' },
    full: { position: 'absolute', left: 0, right: 0, top: 0, bottom: 0 },
    hiddenMainText: { opacity: '0', visibility: 'hidden' },
    widthAuto: { minWidth: 0 },
    heightAuto: importantClass({ height: 'auto' }),
    xsmall: { height: Button.HEIGHTS.xsmall, ...appFonts.xsBold, minHeight: spacing.s5, minWidth: 0, paddingTop: 0, paddingBottom: 0 },
    small: { height: Button.HEIGHTS.small, ...appFonts.xsBold, minHeight: spacing.s7, minWidth: 0, paddingTop: 0, paddingBottom: 0 },
    medium: { height: Button.HEIGHTS.medium, ...appFonts.smBold },
    large: { height: Button.HEIGHTS.large, ...appFonts.mdBold },
    disabledBgColor: importantClass({ backgroundColor: baseColors.secondary, opacity: '0.5', cursor: 'not-allowed', pointerEvents: 'auto' }),
    disabledColor: importantClass({ opacity: '0.5', cursor: 'not-allowed', pointerEvents: 'auto' })
});
/* eslint-enable */
