import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { css, StyleSheet } from 'aphrodite-jss';
import { baseColors, layoutStyle, spacing } from '../../../../styles';
import { components as Core, ROUTES } from '../../../core';
import WithGoalActivityHistoryBase, { styles as baseStyles } from './GoalActivityHistoryBase';

const LOAD_NUMBER = 10;

class GoalActivityHistory extends PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
        challenge: PropTypes.object.isRequired,
        periods: PropTypes.array.isRequired,
        getPeriodPointsEarned: PropTypes.func.isRequired,
        getPeriodLabel: PropTypes.func.isRequired,
        isShowingOnboarding: PropTypes.bool,
        getNoActivitiesLabel: PropTypes.func.isRequired,
        getPeriodPercentage: PropTypes.func.isRequired,
        subheaderTitle: PropTypes.string.isRequired,
        keyExtractor: PropTypes.func.isRequired,
    };

    static defaultProps = {
        isShowingOnboarding: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            periodsToRender: _.take(props.periods, LOAD_NUMBER),
            loadsQuantity: props.periods.length ? 1 : 0,
        };
    }

    onEndReached = () => this.setState(prevState => {
        const { periods } = this.props;
        const renderedLength = prevState.loadsQuantity * LOAD_NUMBER;
        const nextRenderedLength = renderedLength + LOAD_NUMBER;
        const end = nextRenderedLength > periods.length ? periods.length : nextRenderedLength;
        return {
            periodsToRender: [...prevState.periodsToRender, ..._.slice(this.props.periods, renderedLength, end)],
            loadsQuantity: prevState.loadsQuantity + 1,
        };
    });

    goPeriodDetails = period => () => {
        this.props.history.push(ROUTES.goalActivityHistoryDetails(this.props.challenge.challengeId),
            { periodIndex: period.index, restartGoalPeriod: period.challengeId && period });
    };

    renderItem = ({ item }) => {
        const { getPeriodLabel, getPeriodPointsEarned, getPeriodPercentage } = this.props;
        return (
            <Core.ListItem
                button={true}
                className={css(layoutStyle.listItem, layoutStyle.listItemNoLeftPadding)}
                onClick={this.goPeriodDetails(item)}>
                <Core.ListItemText
                    primary={
                        <p className={css(styles.flexRowValueText)}>
                            {getPeriodLabel(item)}
                        </p>}
                    secondary={
                        <p className={css(styles.flexRowTitleText)}>
                            {getPeriodPointsEarned(item)}
                        </p>}
                />
                <p className={css(styles.flexRowValueText)}>{getPeriodPercentage(item)}</p>
                <Core.Icon
                    className={css(styles.icon)}
                    name="angle-right"
                    type="fa"
                    size={spacing.s6}
                    color={baseColors.grey50}
                />
            </Core.ListItem>
        );
    };

    get subheader() {
        return (
            <Core.SubHeader
                title={this.props.subheaderTitle}
                noLeftPadding={true}
                noTopPadding={true}
            />
        );
    }

    get emptyComponent() {
        return <p className={css(styles.noPeriods)}>{this.props.getNoActivitiesLabel()}</p>;
    }

    render() {
        const { isShowingOnboarding, keyExtractor, periods } = this.props;
        return (
            <Core.SmallerContainer
                widerHeaderComponent={(
                    <Core.EntityDetailsHeader
                        isOnboarding={isShowingOnboarding}
                        hasBackButton={true}
                    />)
                }>
                <Core.InfiniteLazyListFadeIn
                    keyExtractor={keyExtractor}
                    onEndReachedThreshold={0.1}
                    hasMarginHorizontal={false}
                    className={css(styles.list)}
                    renderItem={this.renderItem}
                    onEndReached={this.onEndReached}
                    data={this.state.periodsToRender}
                    ListHeaderComponent={this.subheader}
                    ListEmptyComponent={this.emptyComponent}
                    hasMore={periods.length !== this.state.periodsToRender.length}
                />
            </Core.SmallerContainer>
        );
    }
}

export default WithGoalActivityHistoryBase(GoalActivityHistory);

const styles = StyleSheet.create({
    ...baseStyles
});
