import React from 'react';
import PropTypes from 'prop-types';
import { NavLink as RRDNavLink } from 'react-router-dom';

const NavLink = ({ children, ...props }) => (
    <RRDNavLink {...props} activeStyle={{}}>
        {children}
    </RRDNavLink>
);

NavLink.propTypes = {
    children: PropTypes.node.isRequired
};

export default NavLink;
