import { appFonts, spacing } from '../../../../styles';

const IMAGE_CONTAINER_HEIGHT = 70;
const IMAGE_BORDER_RADIUS = 6;

export const styles = {
    imageParentContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        flex: 1
    },
    imageContainer: {
        padding: 0,
        height: IMAGE_CONTAINER_HEIGHT,
        flexBasis: '22%',
        flexShrink: 1,
        textAlign: 'center',
        marginRight: spacing.s3,
        marginLeft: spacing.s3,
        marginBottom: spacing.s1
    },
    image: {
        flex: 1,
        borderRadius: IMAGE_BORDER_RADIUS,
        resizeMode: 'contain'
    },
    titleHeader: {
        marginBottom: spacing.s1,
        ...appFonts.mdMedium
    }
};
