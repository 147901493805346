import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { components as Core, translate } from '../../../core';
import { spacing, baseColors, layoutStyle, importantStyles, appFonts } from '../../../../styles';

const HEADER_MIN_HEIGHT = 136;

class PreferencesUI extends PureComponent {
    static propTypes = {
        saveData: PropTypes.func.isRequired,
        toggleCheckbox: PropTypes.func.isRequired,
        i18n: PropTypes.object.isRequired,
        isOnboarding: PropTypes.bool,
        hideBottomButton: PropTypes.bool,
        isSaving: PropTypes.bool,
        selectData: PropTypes.array,
        title: PropTypes.string,
        desc: PropTypes.string,
        selected: PropTypes.array,
        isLastPageInSection: PropTypes.bool
    };

    static defaultProps = {
        isOnboarding: false,
        hideBottomButton: false,
        isSaving: false,
        selectData: [],
        title: '',
        desc: '',
        selected: [],
        isLastPageInSection: false
    };

    get onBoardingFooter() {
        return (
            <div className={css(layoutStyle.bgWhite, layoutStyle.flexJustifyCenter, styles.footerPadding)}>
                {this.innerContent}
            </div>
        );
    }

    get title() {
        return (
            <div>
                <p>{this.props.title}</p>
                <p className={css(styles.desc)}>{this.props.desc}</p>
            </div>
        );
    }

    get innerContent() {
        return (
            <Core.Button
                id="doneBtnOnboarding"
                fullWidth={!this.props.isOnboarding}
                onPress={this.props.saveData}
                className={css(styles.button, layoutStyle.flexCenter, this.props.isOnboarding ? layoutStyle.halfWidth : null)}
                disabled={this.props.isSaving || this.props.selected.length < 1}
                isLoading={this.props.isSaving}>
                {this.props.isLastPageInSection ? this.props.i18n.t('done') : this.props.i18n.t('next')}
            </Core.Button>
        );
    }

    render() {
        const { selected, isOnboarding, title, desc, selectData, toggleCheckbox, hideBottomButton } = this.props;
        return (
            <Core.Layout.PageWrapper isModal={this.props.isOnboarding}>
                <div className={css(styles.container)}>
                    {isOnboarding ? (
                        <div className={css(styles.header)}>
                            <Core.SubHeader title={title} titleAlign={Core.SubHeader.ALIGN.left} noLeftPadding={true} />
                            <p className={css(styles.desc)}>{desc}</p>
                        </div>
                    ) : (
                        <Core.SubHeader
                            title={this.title}
                            className={css(styles.subheader)}
                            titleClassName={css(styles.title)}
                            right={<Core.Button size={Core.Button.SIZES.medium} onPress={this.props.saveData} className={css(styles.saveButton)}>{this.props.i18n.t('save')}</Core.Button>}
                        />
                    )}
                    {
                        selectData.length && selected ? (
                            <Core.MultiSelectCheck
                                options={selectData}
                                onPressCallback={toggleCheckbox}
                                initValues={true}
                                hasContainerStyles={false}
                                selected={selected}
                                checkboxType={Core.Checkbox.TYPES.regular}
                            />
                        )
                            :
                            <Core.ListLoading />
                    }
                    {!hideBottomButton && (
                        <React.Fragment>
                            {isOnboarding ? this.onBoardingFooter : null}
                        </React.Fragment>
                    )}
                </div>
            </Core.Layout.PageWrapper>
        );
    }
}


const styles = StyleSheet.create({
    header: {
        flex: 0,
        flexDirection: 'column',
        minHeight: HEADER_MIN_HEIGHT,
        backgroundColor: baseColors.white,
        marginHorizontal: spacing.s8,
        marginBottom: spacing.s2
    },
    container: {
        position: 'relative',
        marginLeft: `${spacing.s3}px !important`,
        marginRight: `${spacing.s3}px !important`,
        marginBottom: spacing.s6
    },
    ...importantStyles(
        {
            saveButton: {
                minWidth: spacing.s19
            },
            title: {
                paddingLeft: 0
            }
        }),
    desc: {
        ...appFonts.mdRegular,
        color: baseColors.grey40,
    },
    subheader: {
        marginBottom: spacing.s7
    },
    footerPadding: {
        paddingTop: spacing.s7,
    },
});

export default translate()(PreferencesUI);
