import _ from 'lodash';
import * as types from './actionTypes';
import * as api from './api';
import { actions as coreActions, selectors as coreSelectors, tracker } from '../core';
import { HEALTH_SURVEY, HEALTH_SURVEY_INFO, HEALTH_SURVEY_RESULTS, RECOMMENDED_GOALS, APPS_DEVICES_STEPS } from './constants';
import { hasHealthSurveyCard, getUnfinishedTasksForFirebase, getUnfinishedTasks } from './selectors';
import { actions as rewardsActions } from '../rewards';
import { validateError } from '../../config';

const GET_HRA_TIMEOUT = 8000;

export function getEAP() {
    return function (dispatch) {
        dispatch({ type: types.GET_EAP.REQUEST });
        return api.getEAP()
            .then(response => {
                dispatch({ type: types.GET_EAP.SUCCESS, payload: response.data });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.GET_EAP.ERROR, payload: { error } });
            }));
    };
}

export function setEAPOptions(options) {
    return { type: types.SET_EAP_OPTIONS, payload: options };
}

export function saveEAP(concerns, details) {
    return function (dispatch) {
        dispatch({ type: types.SAVE_EAP.REQUEST });
        return api.saveEAP({ concerns, details })
            .then(response => {
                dispatch({ type: types.SAVE_EAP.SUCCESS,
                    payload: {
                        data: response.data,
                        toast: { title: response.data.message, msg: response.data.displayMessage },
                    }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.SAVE_EAP.ERROR, payload: { error } });
            }));
    };
}

export function getInterestGroups() {
    return function (dispatch, getState) {
        const userId = coreSelectors.getCurrentUser(getState()).userId;
        dispatch({ type: types.GET_INTEREST_GROUPS.REQUEST });
        return api.getInterestGroups(userId)
            .then(response => {
                dispatch({
                    type: types.GET_INTEREST_GROUPS.SUCCESS,
                    payload: { data: response.data }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.GET_INTEREST_GROUPS.ERROR, payload: { error } });
            }));
    };
}

export function saveInterestGroups(options, hasToast = true) {
    const actionType = 'saveInterestGroups';
    return function (dispatch, getState) {
        const userId = coreSelectors.getCurrentUser(getState()).userId;
        dispatch({ type: types.SAVE_INTEREST_GROUPS.REQUEST });
        return api.saveInterestGroups(userId, options)
            .then(response => {
                dispatch({
                    type: types.SAVE_INTEREST_GROUPS.SUCCESS,
                    payload: {
                        data: response.data,
                        toast: hasToast ? { actionType } : null
                    }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({
                    type: types.SAVE_INTEREST_GROUPS.ERROR,
                    payload: {
                        toast: { actionType },
                        error
                    }
                });
            }));
    };
}

export function getHealthObjectives() {
    return function (dispatch, getState) {
        const userId = coreSelectors.getCurrentUser(getState()).userId;
        dispatch({ type: types.GET_HEALTH_OBJECTIVES.REQUEST });
        return api.getHealthObjectives(userId)
            .then(response => {
                dispatch({
                    type: types.GET_HEALTH_OBJECTIVES.SUCCESS,
                    payload: { data: response.data }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.GET_HEALTH_OBJECTIVES.ERROR, payload: { error } });
            }));
    };
}

export function saveHealthObjectives(options, hasToast = true) {
    const actionType = 'saveHealthObjectives';
    return function (dispatch, getState) {
        const userId = coreSelectors.getCurrentUser(getState()).userId;
        dispatch({ type: types.SAVE_HEALTH_OBJECTIVES.REQUEST });
        return api.saveHealthObjectives(userId, options)
            .then(response => {
                dispatch({
                    type: types.SAVE_HEALTH_OBJECTIVES.SUCCESS,
                    payload: {
                        data: response.data,
                        toast: hasToast ? { actionType } : null
                    }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({
                    type: types.SAVE_HEALTH_OBJECTIVES.ERROR,
                    payload: {
                        toast: { actionType },
                        error
                    }
                });
            }));
    };
}

export function getHealthTopics() {
    return function (dispatch, getState) {
        const userId = coreSelectors.getCurrentUser(getState()).userId;
        dispatch({ type: types.GET_HEALTH_TOPICS.REQUEST });
        return api.getHealthTopics(userId)
            .then(response => {
                dispatch({
                    type: types.GET_HEALTH_TOPICS.SUCCESS,
                    payload: { data: response.data }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.GET_HEALTH_TOPICS.ERROR, payload: { error } });
            }));
    };
}

export function saveHealthTopics(options, hasToast = true) {
    const actionType = 'saveHealthTopics';
    return function (dispatch, getState) {
        const userId = coreSelectors.getCurrentUser(getState()).userId;
        dispatch({ type: types.SAVE_HEALTH_TOPICS.REQUEST });
        return api.saveHealthTopics(userId, options)
            .then(response => {
                dispatch({
                    type: types.SAVE_HEALTH_TOPICS.SUCCESS,
                    payload: {
                        data: response.data,
                        toast: hasToast ? { actionType } : null
                    }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({
                    type: types.SAVE_HEALTH_TOPICS.ERROR,
                    payload: {
                        toast: { actionType },
                        error
                    }
                });
            }));
    };
}

export function getUserTasks(trackFirebase = false) {
    return function (dispatch, getState) {
        dispatch({ type: types.GET_USER_TASKS.REQUEST });
        return api.getUserTasks()
            .then(res => {
                dispatch({ type: types.GET_USER_TASKS.SUCCESS, payload: { tasks: res.data } });
                if (trackFirebase) {
                    const unfinishedTasks = getUnfinishedTasksForFirebase(getState());
                    const firebaseTasksCount = getUnfinishedTasks(getState()).length;
                    tracker.logEvent('CompleteProfile_TasksRemaining', { count: firebaseTasksCount, unfinishedTasks });
                }
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.GET_USER_TASKS.ERROR, payload: { error } });
            }));
    };
}

export function getHealthSurveyQuestions() {
    return function (dispatch) {
        dispatch({ type: types.GET_HEALTH_SURVEY_RESULTS_STATUS.REQUEST });
        dispatch({ type: types.GET_HEALTH_SURVEY.REQUEST });
        return api.getHealthSurveyQuestions()
            .then(res => {
                dispatch({
                    type: types.GET_HEALTH_SURVEY.SUCCESS,
                    payload: { data: res.data }
                });
                dispatch(getHealthSurveyResults());
            })
            .catch(error => validateError(error, error => {
                dispatch({
                    type: types.GET_HEALTH_SURVEY.ERROR,
                    payload: { error }
                });
                dispatch({ type: types.GET_HEALTH_SURVEY_RESULTS_STATUS.ERROR });
            }));
    };
}

export function getHealthSurveyResults(id = '') {
    return function (dispatch, getState) {
        const { userId } = coreSelectors.getCurrentUser(getState());

        dispatch({ type: types.GET_HEALTH_SURVEY_RESULTS.REQUEST });
        return api.getHealthSurveyResults(id)
            .then(res => {
                dispatch({
                    type: types.GET_HEALTH_SURVEY_RESULTS.SUCCESS,
                    payload: { data: res.data }
                });
                if (userId) {
                    dispatch(getHealthSurveyResultStatus(userId));
                } else {
                    dispatch({ type: types.GET_HEALTH_SURVEY_RESULTS_STATUS.SUCCESS });
                }
            })
            .catch(error => validateError(error, error => {
                dispatch({
                    type: types.GET_HEALTH_SURVEY_RESULTS.ERROR,
                    payload: {
                        error,
                        isErrorStore: true
                    }
                });
                if (userId) { dispatch(getHealthSurveyResultStatus(userId)); }
            }));
    };
}

export function getHealthSurveyResultStatus(userId) {
    return function (dispatch) {
        dispatch({ type: types.GET_HEALTH_SURVEY_STATUS.REQUEST });
        return api.getHealthSurveyResultStatus(userId)
            .then(res => {
                dispatch({
                    type: types.GET_HEALTH_SURVEY_STATUS.SUCCESS,
                    payload: { data: res.data }
                });
                dispatch({ type: types.GET_HEALTH_SURVEY_RESULTS_STATUS.SUCCESS });
                dispatch({ type: types.CLEAR_REFRESH_HRA_SCORE });
            })
            .catch(error => validateError(error, error => {
                dispatch({
                    type: types.GET_HEALTH_SURVEY_STATUS.ERROR,
                    payload: { error }
                });
                dispatch({ type: types.CLEAR_REFRESH_HRA_SCORE });
                dispatch({ type: types.GET_HEALTH_SURVEY_RESULTS_STATUS.ERROR });
            }));
    };
}

export function setVitalStats(answers) {
    return function (dispatch) {
        dispatch({ type: types.SET_VITAL_STATS.REQUEST });
        return api.setVitalStats(answers)
            .then(res => {
                dispatch({
                    type: types.SET_VITAL_STATS.SUCCESS,
                    payload: { data: res.data }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({
                    type: types.SET_VITAL_STATS.ERROR,
                    payload: {
                        error,
                        isErrorStore: true
                    }
                });
            }));
    };
}

export function clearVitalStatsError() {
    return function (dispatch) {
        dispatch(coreActions.removeError(types.SET_VITAL_STATS.NAME));
    };
}

export function setHealthSurveyAnswers(answers, completed) {
    return function (dispatch) {
        dispatch({ type: types.SET_HEALTH_SURVEY_ANSWERS.REQUEST });
        return api.setHealthSurveyAnswers({ answers, completed })
            .then(res => {
                if (completed) {
                    api.setUserTasks({ slug: 'health_survey' });
                    dispatch({ type: types.GET_HRA_SCORES.REQUEST });
                    dispatch({ type: types.START_REFRESH_HRA_SCORE });
                    api.setHRA({ data: {} })
                        .then(res => {
                            setTimeout(() => {
                                dispatch(getHealthSurveyResults())
                                    .then(() => dispatch({ type: types.GET_HRA_SCORES.SUCCESS }));
                            }, GET_HRA_TIMEOUT);
                        })
                        .catch(error => validateError(error, error => {
                            dispatch({ type: types.GET_HRA_SCORES.ERROR, payload: { error } });
                            dispatch({ type: types.CLEAR_REFRESH_HRA_SCORE });
                        }));
                }
                dispatch({
                    type: types.SET_HEALTH_SURVEY_ANSWERS.SUCCESS,
                    payload: { data: res.data }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.SET_HEALTH_SURVEY_ANSWERS.ERROR, payload: { error } });
            }));
    };
}

export function setUserTaskComplete(slug) {
    return function (dispatch) {
        dispatch({ type: types.SET_USER_TASK_COMPLETE.REQUEST });
        return api.setUserTaskComplete(slug)
            .then(() => {
                dispatch({ type: types.SET_USER_TASK_COMPLETE.SUCCESS });
            })
            .catch(error => validateError(error, error => {
                dispatch({
                    type: types.SET_USER_TASK_COMPLETE.ERROR,
                    payload: { error }
                });
            }));
    };
}

export function setHealthSurveyComplete() {
    return function (dispatch, getState) {
        if (hasHealthSurveyCard(getState())) {
            return Promise.all([
                dispatch(setUserTaskComplete(HEALTH_SURVEY_INFO)),
                dispatch(setUserTaskComplete(HEALTH_SURVEY)),
                dispatch(setUserTaskComplete(HEALTH_SURVEY_RESULTS)),
                dispatch(setUserTaskComplete(RECOMMENDED_GOALS)),
            ]).then(() => {
                dispatch(getUserTasks());
                dispatch(rewardsActions.getUserLevels());
            });
        }
        return Promise.resolve();
    };
}

export function clearHealthSurveyResultsError() {
    return function (dispatch) {
        dispatch(coreActions.removeError(types.GET_HEALTH_SURVEY_RESULTS.NAME));
    };
}

export function startShowingOnboarding() {
    return { type: types.START_SHOWING_ONBOARDING };
}

export function stopShowingOnboarding() {
    return { type: types.CLEAR_SHOWING_ONBOARDING };
}

export function updateUserPoints(newPoints) {
    return function (dispatch) {
        dispatch(coreActions.retrieveCurrentUserInfoSuccess({ points: newPoints }));
    };
}

export function setOnboardingComplete() {
    return function (dispatch) {
        dispatch(coreActions.retrieveCurrentUserInfoSuccess({ onboardingIncomplete: false, firstSignIn: 0 }));
        dispatch(stopShowingOnboarding());
        dispatch({ type: types.SET_ONBOARDING_COMPLETE });
    };
}

export function recordStepCompleted(step, isNewLevel) {
    return function (dispatch) {
        dispatch(setUserTaskComplete(step)).then(() => isNewLevel && dispatch(rewardsActions.getUserLevels()));
        dispatch({
            type: types.RECORD_STEP_COMPLETED,
            payload: { step }
        });
    };
}

export function completeAppsDevicesStep() {
    return function (dispatch) {
        _.forEach(APPS_DEVICES_STEPS, step => dispatch(setUserTaskComplete(step)).then(() => dispatch(rewardsActions.getUserLevels())));
        dispatch({ type: types.COMPLETE_APPS_DEVICES_STEP });
    };
}


export function setAfterOnboardingPath(path) {
    return function (dispatch) {
        dispatch({ type: types.SET_AFTER_ONBOARDING_PATH, payload: { data: { path } } });
    };
}

export function toggleSOTs() {
    return function (dispatch) {
        dispatch({ type: types.TOGGLE_SKIPPABLE_ONBOARDING_TASKS });
    };
}

export function setStartTime(step) {
    return function (dispatch) {
        dispatch({ type: types.SET_START_TIME, payload: { step } });
    };
}