import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { translate, pointsHelper, selectors as coreSelectors, DATE_FORMATS } from '../../../core';
import { appFonts, baseColors, spacing } from '../../../../styles';
import {
    getActivityLogs, getActivityLogsByDay, getDeviceActivityLogsByDay,
    getDeviceActivityLogs, getActivityLogsByDayLength, isLoadingActivityLogsNoFilter,
    isLoadingActivityLogsExtended
} from '../../selectors';
import { selectors as appsDevicesSelectors } from '../../../appsdevices';
import { selectors as authSelectors } from '../../../auth';
import * as actions from '../../actions';
import { getDateName, getDateString } from '../../services/activitiesHelper';

const LOGS_LIMIT = 10;

export default function WithRecentActivityLogBase(WrappedComponent) {
    class RecentActivityLogBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            activityLogs: PropTypes.array.isRequired,
            isLoadingActivityLogs: PropTypes.bool,
            logsByDay: PropTypes.array.isRequired,
            score: PropTypes.bool,
            containerStyle: PropTypes.object,
            hideTitle: PropTypes.bool,
            vendorId: PropTypes.number,
            device: PropTypes.object,
            programName: PropTypes.string,
            isSyncingDevice: PropTypes.bool,
            customPointsUnit: PropTypes.string.isRequired
        };

        static defaultProps = {
            score: false,
            containerStyle: null,
            hideTitle: false,
            vendorId: null,
            device: null,
            programName: null,
            isLoadingActivityLogs: false,
            isSyncingDevice: false
        };

        constructor(props) {
            super(props);
            this.state = {
                indicateNoNewActivities: true,
                syncCompleted: false,
                newActivityLogs: []
            };
            this.loadActivities();
            this.setAnimationComplete = this.setAnimationComplete.bind(this);
        }

        loadActivities = () => {
            /***
             * For recent activities app will show all activities for the last 7 days
             * also check this story https://www.pivotaltracker.com/n/projects/2116558/stories/169508741
             */
            const endDate = moment().format(DATE_FORMATS.full);
            this.props.actions.getActivityLogsExtended(undefined, endDate, LOGS_LIMIT);
            this.props.actions.getFilteredActivityLogs();
        }

        UNSAFE_componentWillReceiveProps(nextProps) {
            if (this.props.vendorId) {
                const diff = _.differenceBy(nextProps.activityLogs, this.props.activityLogs, 'activityLogId');
                if (diff.length >= 1) {
                    this.setState({ newActivityLogs: diff });
                    this.setState({ indicateNoNewActivities: false });
                }

                if (nextProps.isSyncingDevice !== this.props.isSyncingDevice) {
                    if (this.props.isSyncingDevice) {
                        this.setState({ syncCompleted: true });
                    }
                }
            }
        }

        getActivityTitle = item => `${item.quantity} ${item.unit}`;

        getActivitySubtitle = item => `${item.activityName}`;

        getDateString = date => getDateString(date);

        getDateName = date => getDateName(date, this.props.i18n);


        getPoints = item => pointsHelper.formatPoints(_.round(_.get(item, 'points', 0), 1), this.props.customPointsUnit);

        get title() {
            if (this.props.vendorId) {
                return this.props.i18n.t('recentlySynced_activity');
            }

            return this.props.score ? this.props.i18n.t('activities') : this.props.i18n.t('my_recently_tracked_activities');
        }

        get noDeviceActivitesString() {
            const { device, programName } = this.props;

            return device ? this.props.i18n.t('appsDevices.deviceDetails.noActivities', { programName, deviceName: device.displayName }) : null;
        }

        get noTrackedActivitiesTitle() {
            return this.props.i18n.t('no_tracked_activities');
        }

        get trackActivityMessage() {
            return this.props.i18n.t('track_activity');
        }

        get hasAnyLogs() {
            const { logsByDay } = this.props;
            return logsByDay.length > 0;
        }

        getItemIsNew = item => _.find(this.state.newActivityLogs, log => log.activityLogId === item.activityLogId);

        setAnimationComplete() {
            this.setState({ syncCompleted: false, indicateNoNewActivities: true });
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    getActivityTitle={this.getActivityTitle}
                    getActivitySubtitle={this.getActivitySubtitle}
                    getDateString={this.getDateString}
                    getDateName={this.getDateName}
                    getPoints={this.getPoints}
                    title={this.title}
                    noDeviceActivitesString={this.noDeviceActivitesString}
                    getItemIsNew={this.getItemIsNew}
                    indicateNoNewActivities={this.state.indicateNoNewActivities}
                    syncCompleted={this.state.syncCompleted}
                    setAnimationComplete={this.setAnimationComplete}
                    loadActivities={this.loadActivities}
                    noTrackedActivitiesTitle={this.noTrackedActivitiesTitle}
                    trackActivityMessage={this.trackActivityMessage}
                    hasAnyLogs={this.hasAnyLogs}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const vendorId = _.get(ownProps, 'vendorId') || _.get(ownProps, 'match.params.vendorId');
        let logsByDay = [];
        let activityLogs = [];
        let device = null;
        let activityLogsLength = 0;
        let programName = null;
        if (vendorId) {
            logsByDay = getDeviceActivityLogsByDay(state, vendorId);
            activityLogs = getDeviceActivityLogs(state, vendorId);
            device = appsDevicesSelectors.getDeviceByVendorId(state, vendorId);
            programName = authSelectors.getProgramName(state);
        } else {
            logsByDay = getActivityLogsByDay(state, LOGS_LIMIT);
            activityLogs = getActivityLogs(state);
            activityLogsLength = getActivityLogsByDayLength(state);
        }
        return {
            activityLogs,
            isLoadingActivityLogs: isLoadingActivityLogsNoFilter(state) || isLoadingActivityLogsExtended(state),
            logsByDay: _.take(logsByDay, LOGS_LIMIT),
            device,
            programName,
            activityLogsLength,
            customPointsUnit: coreSelectors.getCustomPointsUnit(state)
        };
    };

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(RecentActivityLogBase));
}

const CIRCLE_SIZE = spacing.s2;

export const styles = {
    titleContainer: {
        flexDirection: 'row',
        marginTop: spacing.s2,
        marginBottom: spacing.s2,
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: spacing.s3
    },
    title: {
        ...appFonts.lgBold
    },
    subtitleContainer: {
        backgroundColor: baseColors.grey85,
        paddingTop: spacing.s0,
        paddingBottom: spacing.s0,
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3,
        ...appFonts.smMedium,
        flexDirection: 'row'
    },
    dateName: {
        ...appFonts.smBold,
        paddingRight: spacing.s1
    },
    date: {
        color: baseColors.grey40,
        ...appFonts.smRegular
    },
    rightTitle: {
        ...appFonts.smBold,
        color: baseColors.black
    },
    activitySubtitle: {
        ...appFonts.smRegular
    },
    deviceNoActivitiesContainer: {
        paddingTop: spacing.s2,
        paddingBottom: spacing.s2,
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3,
        alignItems: 'center',
        flexDirection: 'column'
    },
    noActivitiesText: {
        textAlign: 'center',
        ...appFonts.mdRegular
    },
    updatingActivitiesContainer: {
        display: 'flex',
        paddingTop: spacing.s9,
        paddingBottom: spacing.s2,
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3,
        alignItems: 'center',
        flexDirection: 'column'
    },
    convertingToPointsText: {
        paddingTop: spacing.s3,
        textAlign: 'center',
        ...appFonts.mdRegular
    },
    waitText: {
        paddingTop: spacing.s0,
        textAlign: 'center',
        ...appFonts.smRegular,
        color: baseColors.grey40
    },
    noNewActivitySection: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        width: '100%',
        paddingTop: spacing.s0,
        paddingBottom: spacing.s3
    },
    noNewActivitiesText: {
        textAlign: 'center',
        ...appFonts.mdRegular
    },
    newActivityCircle: {
        width: CIRCLE_SIZE,
        height: CIRCLE_SIZE,
        borderRadius: CIRCLE_SIZE/2,
        marginRight: spacing.s1,
        backgroundColor: baseColors.secondary
    },
    emptyStateContainer: {
        paddingRight: spacing.s5,
        paddingLeft: spacing.s5,
        paddingTop: spacing.s7,
        paddingBottom: spacing.s7,
        marginTop: spacing.s3,
        borderRadius: 8
    },
    emptyStateTitle: {
        ...appFonts.mdRegular,
        color: baseColors.grey40,
        paddingBottom: spacing.s5
    }
};
