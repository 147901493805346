import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { translate } from '../../../core';
import { getMyEventsSelector, getPastEventsSelector } from '../../selectors';

export default function WithMyTabBase(WrappedComponent) {
    class MyTabBase extends PureComponent {
        static propTypes = {
            events: PropTypes.array
        };

        static defaultProps = {
            events: []
        };

        get eventsLength() {
            return this.props.events.length;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    eventsLength={this.eventsLength}
                />
            );
        }
    }

    function mapStateToProps(state) {
        return {
            events: [...getMyEventsSelector(state), ...getPastEventsSelector(state)]
        };
    }

    return connect(mapStateToProps)(translate()(MyTabBase));
}