import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent, Fragment } from 'react';
import { StyleSheet, css } from 'aphrodite-jss';

import { layoutStyle } from '../../../../styles';
import { components as Events } from '../../../events';
import { components as Rewards } from '../../../rewards';
import { components as Invitations } from '../../../invitations';
import { components as Core, services as coreServices } from '../../../core';
import WithUserProfileBase, { styles as baseStyles } from './UserProfileBase';

class UserProfile extends PureComponent {
    static propTypes = {
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        isLoading: PropTypes.bool,
        hasEvents: PropTypes.bool,
        userData: PropTypes.object,
        userName: PropTypes.string,
        dateJoined: PropTypes.string,
        currentUserCompanyId: PropTypes.number.isRequired,
        i18n: PropTypes.object.isRequired,
        userCompanyId: PropTypes.number,
        prepareString: PropTypes.func,
        pointsNum: PropTypes.string.isRequired,
        pointsText: PropTypes.string.isRequired,
        close: PropTypes.func.isRequired
    };

    static defaultProps = {
        isLoading: false,
        hasEvents: false,
        userName: '',
        dateJoined: '',
        userData: null,
        userCompanyId: undefined,
        prepareString: null,
    };

    get points() {
        return (
            <div className={css(styles.section, layoutStyle.alignCenter)}>
                <p className={css(styles.pointsNum)}>{this.props.pointsNum}</p>
                <p className={css(styles.pointsText)}>{this.props.pointsText}</p>
            </div>
        );
    }

    get achievements() {
        return (
            <Rewards.AchievementsCarousel
                title={this.props.i18n.t('achievements')}
                hideMoreBtn={true}
                separator={false}
                headerStyle={styles.achievementsHeader}
            />
        );
    }

    render() {
        return (
            <Fragment>
                <Core.SubHeader hasBackButton={true} isModal={true} onModalClose={this.props.close} />
                {this.props.isLoading ? <Core.ListLoading /> : null}
                {this.props.userData ?
                    <div className={css(styles.section, styles.topSection, layoutStyle.flex)}>
                        <Core.Avatar
                            size="large"
                            url={this.props.userData.avatarURL}
                            name={coreServices.getUserName(this.props.userData)}
                            key={this.props.userData.userId}
                            disablePress={true}
                        />
                        <div className={css(styles.nameWrapper)}>
                            <p className={css(styles.name)}>{this.props.userName}</p>
                            <p className={css(styles.joined)}>{this.props.dateJoined}</p>
                            {this.props.userCompanyId !== 0 && this.props.currentUserCompanyId === this.props.userCompanyId ?
                                <div>
                                    <Core.TextSeeMore className={css(styles.location)} numberOfLines={1}>
                                        {this.props.prepareString(_.get(this.props.userData.location, 'name', ''))}
                                    </Core.TextSeeMore>
                                    <Core.TextSeeMore className={css(styles.location)} numberOfLines={1}>
                                        {this.props.prepareString(_.get(this.props.userData.department, 'name', ''))}
                                    </Core.TextSeeMore>
                                </div> :
                                <Core.TextSeeMore className={css(styles.location)} numberOfLines={1}>
                                    {this.props.prepareString(_.get(this.props.userData.location, 'name', ''))}
                                </Core.TextSeeMore>}
                            <Invitations.FriendsAndFamily id={this.props.id} />
                        </div>
                    </div> : null}
                {this.props.hasEvents ?
                    <Events.ProfileEvents
                        userId={this.props.id}
                        callback={this.props.close}/> : null
                }
            </Fragment>
        );
    }
}

export default WithUserProfileBase(UserProfile);

const styles = StyleSheet.create({ ...baseStyles });
