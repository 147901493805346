import I18n from '../../../../i18n/i18n';
import { ARROWS } from '../../constants';
import { PLATFORMS, Platform, CSS_CLASSES } from '../../../core';

export default class FriendsAndFamilyTour {
    static REFS = {
        addedFriend: null
    };

    static get isWeb() {
        return Platform.OS === PLATFORMS.web;
    }

    static data() {
        return {
            hasNavBar: true,
            steps: [{
                title: I18n.t('friendsAndFamily.managingTour.title'),
                text: I18n.t('friendsAndFamily.managingTour.body'),
                target: FriendsAndFamilyTour.isWeb ? `.${CSS_CLASSES.addedFriendsTarget}` : FriendsAndFamilyTour.REFS.addedFriend,
                arrow: ARROWS.DOWN,
                hasSubtitle: false,
                hasCloseButton: false,
                hasGotItButton: true
            }]
        };
    }
}
