import React, { PureComponent } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { withRouter } from 'react-router-dom';
import { components as Core, ROUTES } from '../../../core';
import WithChallengeListItemBase, { styles as baseStyles, NUMBER_OF_LINES } from './ChallengeListItemBase';
import { imageSize, media, importantClass, spacing } from '../../../../styles';
import ChallengeImage from '../ChallengeImage';
import BonusChallengeMark from '../BonusChallengeMark';

class ChallengeListItem extends PureComponent {
    static propTypes = {
        challenge: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        showParticipants: PropTypes.bool,
        cardClassName: PropTypes.string,
        imageClassName: PropTypes.string,
        cardInnerClassName: PropTypes.string,
        descriptionClassName: PropTypes.string,
        cardContainerClassName: PropTypes.string,
        challengeIconName: PropTypes.string.isRequired,
        isTeamChallenge: PropTypes.bool.isRequired
    };

    static defaultProps = {
        showParticipants: true,
        cardClassName: undefined,
        imageClassName: undefined,
        cardInnerClassName: undefined,
        descriptionClassName: undefined,
        cardContainerClassName: undefined,
    }

    onChallengePress = () => {
        this.props.history.push(ROUTES.challengeDetails(this.props.challenge.challengeId));
    };


    get imageInnerComponent() {
        const { challenge } = this.props;

        return (
            <div style={styles.imageContainer} className={css(styles.imageContainer)}>
                <ChallengeImage
                    challengeId={challenge.challengeId}
                    image={challenge.challengeImageURL ? challenge.challengeImageURL : undefined}
                    isCarouselItem={false}
                    resizeMode="cover"
                    contentClassName={challenge.isEnded && styles.halfOpacity}
                    imageHeight={imageSize.smd}
                    imageWidth={imageSize.xmd}
                    hasRoundBorder={true}
                />
            </div>
        );
    }

    get membersInfo() {
        const { challenge, numMembersText } = this.props;
        return (_.get(challenge, 'numChallengeParticipants') && (
            <>
                <p className={css(styles.membersText)}>{numMembersText}</p>
            </>
        )
        );
    }

    render() {
        const { challenge, cardClassName, imageClassName, challengeDateText, showParticipants,
            cardInnerClassName, descriptionClassName, cardContainerClassName, challengeIconName, isTeamChallenge } = this.props;

        return (
            <Core.TouchableItem
                onPressSingle={this.onChallengePress}
                image={false}
                imageHeight={imageSize.smd}
                imageWidth={imageSize.xmd}
                imageClassName={imageClassName}
                innerContainerClassName={css(styles.card, challenge.isEnded && styles.alignCenter, cardClassName)}
                containerClassName={css(styles.cardContainer, cardContainerClassName)}
                imageInnerComponent={this.imageInnerComponent}>
                <div className={css(styles.cardInner, cardInnerClassName)}>
                    <div className={css(styles.startsInContainer)}>
                        <Core.ExploreItemMeta iconName={challengeIconName} text={challengeDateText} />
                    </div>
                    <Core.ExploreItemTitle text={challenge.challengeName} />
                    {!challenge.isEnded ? (
                        <div>
                            <Core.TextSeeMore
                                className={css(styles.challengeHeader, descriptionClassName)}
                                isExpandHidden={true}
                                isCollapseHidden={true}
                                numberOfLines={NUMBER_OF_LINES}>
                                {challenge.challengeInstruction}
                            </Core.TextSeeMore>
                            {(showParticipants && challenge?.numChallengeParticipants > 0) ? (
                                <div className={css(styles.avatarsContainer)}>
                                    <Core.ExploreItemMembers members={challenge.participants} isChallenge={true} numParticipants={challenge.numChallengeParticipants} isTeamChallenge={isTeamChallenge} />
                                </div>
                            ) : null}
                            <BonusChallengeMark
                                challengeId={challenge.challengeId}
                                containerClassName={css(styles.bonusMarkContainer)}
                            />
                        </div>
                    ) : null}
                </div>
            </Core.TouchableItem>
        );
    }
}

export default withRouter(WithChallengeListItemBase(ChallengeListItem));

const styles = StyleSheet.create({
    ...baseStyles,
    cardInner: {
        ...baseStyles.cardInner,
        paddingTop: 0
    },
    card: {
        ...baseStyles.card,
        paddingBottom: spacing.s5,
        alignItems: 'flex-start',
        [media.xs]: {
            borderBottomStyle: 'solid'
        }
    },
    challengeHeader: importantClass({
        ...baseStyles.challengeHeader,
        textAlign: 'left',
    }),
    startsInContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingBottom: spacing.s0
    },
    membersText: importantClass({
        ...baseStyles.membersText
    }),
    imageContainer: {
        borderRadius: spacing.s3,
    },
    alignCenter: {
        alignItems: 'center',
    },
    halfOpacity: {
        opacity: '50%',
    },
});

