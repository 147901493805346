import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';
import { withRouter } from 'react-router-dom';
import BonusMark from '../BonusMark';
import { components as Core, ROUTES } from '../../../core';
import { baseColors, importantStyles, layoutStyle, spacing, listItemImageSize } from '../../../../styles';
import WithContentOnFeedRecommendedItemBase, { styles as baseStyles } from './ContentOnFeedRecommendedItemBase';

class ContentOnFeedRecommendedItem extends PureComponent {
    static propTypes = {
        item: PropTypes.object.isRequired,
        image: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        isVideo: PropTypes.bool.isRequired,
        history: PropTypes.object.isRequired,
        isNewVideoControlLayout: PropTypes.bool,
        itemIsFeatured: PropTypes.bool.isRequired,
        tabIndex: PropTypes.string,
    };

    static defaultProps = {
        isNewVideoControlLayout: false,
        tabIndex: '0'
    };

    openItemDetails = () => this.props.history.push(ROUTES.contentDetails(this.props.item.id));

    get renderLabel() {
        return (
            <Core.ExploreItemMeta iconName="tags" textArray={this.props.item.tagsArr} />
        );
    }

    get renderVideoControlContent() {
        const { isVideo, isNewVideoControlLayout, videoDuration } = this.props;

        const wrapperStyle = isNewVideoControlLayout ? '' : css(styles.videoControlContainer);
        const itemSize = isNewVideoControlLayout ? spacing.s5 : spacing.s1;

        return isVideo ? (
            <div className={wrapperStyle}>
                <Core.Icon
                    type="fa"
                    name="play"
                    fill="solid"
                    size={itemSize}
                    color={baseColors.white}
                />
                {videoDuration && !isNewVideoControlLayout ? <p className={css(styles.videoDuration)}>{videoDuration}</p> : null}
            </div>
        ) : null;
    }

    get featuredLabel() {
        const { i18n, itemIsFeatured } = this.props;

        if (!itemIsFeatured) return null;

        return (
            <div className={css(styles.featuredContainer)}>
                <div className={css(styles.featuredIcon)}>
                    <Core.Icon
                        name="sparkles"
                        color={baseColors.primary}
                        type="fa"
                        fill="solid"
                        size={spacing.s2}
                    />
                </div>
                <p className={css(styles.featuredText)}>{i18n.t('promotedForMe')}</p>
            </div>
        );
    }

    render() {
        const { item, image, title, isVideo, tabIndex } = this.props;

        return (
            <div ref={this.childRef}>
                <Core.TouchableItem
                    image={image}
                    isVideoPlayable={false}
                    imageWidth={listItemImageSize}
                    imageHeight={listItemImageSize}
                    videoUrl={isVideo ? item.url : ''}
                    onPressSingle={this.openItemDetails}
                    onImageContent={this.renderVideoControlContent}
                    innerContainerClassName={css(layoutStyle.flex, layoutStyle.flex1)}
                    imageClassName={css(styles.imageStyle)}
                    containerClassName={css(layoutStyle.flex, styles.mainContainer)}
                    tabIndex={tabIndex}>
                    <div className={css(styles.cardHeaderLeft)}>
                        {this.featuredLabel}
                        <Core.ExploreItemTitle text={title} numberOfLines={2} style={styles.title} />
                        <div className={css(styles.bonusContainer)}>
                            <BonusMark item={item} />
                        </div>
                    </div>
                </Core.TouchableItem>
            </div>
        );
    }
}

export default withRouter(WithContentOnFeedRecommendedItemBase(ContentOnFeedRecommendedItem));

const styles = StyleSheet.create({
    ...importantStyles({
        ...baseStyles,
        cardHeaderLeft: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
        },
    }),
    mainContainer: {
        border: 'none',
        marginLeft: spacing.s3,
        marginRight: spacing.s3,
        marginTop: spacing.s1,
        marginBottom: spacing.s2,
    },
    imageStyle: {
        display: 'flex',
        alignItems: 'center',
    },
    bonusContainer: {
        paddingTop: spacing.s0
    },
    featuredContainer: {
        ...baseStyles.featuredContainer,
        alignItems: 'center',
        paddingBottom: 0
    }
});
