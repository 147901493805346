import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { baseColors, appFonts, spacing } from '../../../../styles';
import { translate, tracker, openUrl } from '../../../core';

export default function WithResourceLinkFeedItemBase(WrappedComponent) {
    class ResourceLinkFeedItemBase extends PureComponent {
        static propTypes = {
            item: PropTypes.object.isRequired,
            callback: PropTypes.func,
            i18n: PropTypes.object.isRequired,
            isLastItem: PropTypes.bool
        };

        static defaultProps = {
            callback: null,
            isLastItem: false
        };

        goToResourceLinkItem = () => {
            const { id, url } = this.props.item; 
            tracker.logEvent('resources', { event: 'goToResourceLinkItem', from: 'feeds', id });
            openUrl(url);
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    goToResourceLinkItem={this.goToResourceLinkItem}
                />
            );
        }
    }

    return translate()(ResourceLinkFeedItemBase);
}

export const styles = {
    title: {
        ...appFonts.mdRegular,
        color: baseColors.secondary,
        marginTop: spacing.s2,
    },
};