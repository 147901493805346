import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import moment from 'moment';
import { spacing, baseColors, appFonts, modalPadding } from '../../../../styles';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import * as constants from '../../constants';
import { constants as settingsConstants } from '../../../settings';
import { translate, Alert, selectors as coreSelectors,
    DATE_FORMATS, Platform, PLATFORMS, tracker, searchAndHighlightEntities } from '../../../core';
import { getPreparedActivityLogsByDate, getChallenge, getTrackError, hasTrackedChallenge, isTracking,
    getDeletingActivityLogsError, isDeletingActivityLogs } from '../../selectors';
import { activitiesHelper } from '../../services';
import { getRecentActivities } from '../../services/activitiesHelper';

export default function WithActivityTrackingListBase(WrappedComponent) {
    class ActivityTrackingListBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            activities: PropTypes.array.isRequired,
            selectedDate: PropTypes.string.isRequired,
            searchKey: PropTypes.array.isRequired,
            callback: PropTypes.func,
            pendingActivities: PropTypes.array,
            changedActivities: PropTypes.array,
            deletedActivities: PropTypes.array,
            allLogs: PropTypes.array,
            fromSingleActivityGoal: PropTypes.bool,
            customizePost: PropTypes.bool,
            onlyMeFilter: PropTypes.string.isRequired,
            actions: PropTypes.object.isRequired,
            error: PropTypes.object,
            hasTrackedChallenge: PropTypes.bool.isRequired,
            isFocusedInput: PropTypes.bool,
            isLoading: PropTypes.bool,
            challengeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            title: PropTypes.string,
            navigation: PropTypes.object,
            isHomeCarousel: PropTypes.bool
        };

        static defaultProps = {
            pendingActivities: [],
            changedActivities: [],
            deletedActivities: [],
            allLogs: [],
            callback: null,
            fromSingleActivityGoal: false,
            customizePost: false,
            isFocusedInput: false,
            isLoading: false,
            challengeId: undefined,
            error: undefined,
            title: undefined,
            navigation: {},
            isHomeCarousel: false
        };

        tabs = [
            { id: 'all', label: this.props.i18n.t('all') },
            ...this.props.tabs,
        ];

        state = {
            activities: _.differenceBy(this.props.activities, this.props.pendingActivities, 'activityUnitId'),
            pendingActivities: this.props.pendingActivities,
            changedActivities: this.props.changedActivities,
            activityLogs: getRecentActivities(_.differenceBy(this.props.allLogs, this.props.deletedActivities, 'activityLogId'), this.props.challenge),
            deletedActivities: this.props.deletedActivities,
            isSearching: this.props.isFocusedInput,
            lastAddedItem: null,
            searchText: '',
            selectedTab: this.tabs[0]
        };

        onChangeSearchText = e => {
            if (e.target) {
                this.setState({ searchText: e.target.value });
            } else {
                this.setState({ searchText: e });
            }
        }

        onClearSearchText = () => this.onChangeSearchText('');

        async componentDidUpdate(prevProps) {
            const { isLoading, isHomeCarousel, error, hasTrackedChallenge, fromSingleActivityGoal, actions, challengeId } = this.props;
            if (prevProps.isLoading && !isLoading) {
                if (!error) {
                    if (!isHomeCarousel || this.isWeb) await this.dismissModalAfterLoading();
                    if (!hasTrackedChallenge && fromSingleActivityGoal) {
                        this.showFirstTrackChallengeScreen();
                    }
                } else {
                    actions.clearTrackError(challengeId);
                }
            }
        }

        showFirstTrackChallengeScreen = () => {
            _.has(this, 'wrapped.showFirstTrackChallengeScreen') && this.wrapped.showFirstTrackChallengeScreen();
        };

        dismissModal = () => _.has(this, 'wrapped.dismissModal') && this.wrapped.dismissModal();

        dismissModalAfterLoading = () => _.has(this, 'wrapped.dismissModalAfterLoading') && this.wrapped.dismissModalAfterLoading();

        toggleNavigatorButton = () => {
            _.has(this, 'wrapped.toggleNavigatorButton') && this.wrapped.toggleNavigatorButton();
        };

        get isWeb() {
            return Platform.OS === PLATFORMS.web;
        }

        get selectedDate() {
            return moment(this.props.selectedDate, DATE_FORMATS.full);
        }

        get formatedDate() {
            return this.selectedDate.format(DATE_FORMATS.monthFullDay);
        }

        get placeholderDate() {
            return this.selectedDate.format(DATE_FORMATS.monthFullDayYearFull);
        }

        addActivity = () => {
            if (this.props.callback) {
                this.props.callback(this.state.pendingActivities, this.state.changedActivities, this.state.deletedActivities);
            } else {
                this.submitActivities();
            }
        };

        submitActivities = () => {
            this._deletedActivities = this.state.deletedActivities; // to show points earned in CongratsFirstTrackChallenge
            this._pendingActivities = this.state.pendingActivities; // to show points earned in CongratsFirstTrackChallenge
            activitiesHelper.submitActivities(
                this.props.challengeId,
                this.state.pendingActivities,
                this.state.changedActivities,
                this.state.deletedActivities,
                this.props.onlyMeFilter,
                this.props.customizePost,
                undefined,
                this.changeOrDeleteActivities,
                this.trackActivity,
                this.props.navigation
            );
        };

        changeOrDeleteActivities = (id, ids, hasToast) => {
            this.props.actions.deleteActivityLogs(id, ids, hasToast);
        };

        trackActivity = (id, pendingActivities, filterId, text, image, isSingleChallenge) => {
            this.props.actions.trackActivity(id, pendingActivities, filterId, text, image, isSingleChallenge, this.props.isHomeCarousel);
        };

        getTotalPointsEarned = () => activitiesHelper.getTotalPointsEarned(this._pendingActivities, this._deletedActivities);

        exitScreen = () => {
            if (this.isChangesPresent) {
                const { i18n } = this.props;
                //if edits have been made then pop up a confirmation modal
                Alert.alert('', i18n.t('discard_activity_edits_confirmation_date', { date: this.formatedDate }), [
                    { text: i18n.t('yes_discard_edits'), onPress: this.dismissModal, isDangerButton: true, isActivityTrackingBtn: true },
                    { text: i18n.t('button_cancel'), style: 'cancel' },
                ]);
            } else {
                this.dismissModal();
            }
        };

        get isChangesPresent() {
            return !_.isEqual(
                _.sortBy(this.state.deletedActivities, 'activityUnitId'),
                _.sortBy(this.props.deletedActivities, 'activityUnitId')
            ) || !_.isEqual(
                _.sortBy(this.state.pendingActivities, 'activityUnitId'),
                _.sortBy(this.props.pendingActivities, 'activityUnitId')
            );
        }

        get notTrackedText() {
            return this.props.i18n.t('havent_tracked_in_a_while_date', { currentDate: this.placeholderDate });
        }

        get activityTrackingLabel() {
            return this.state.pendingActivities.length || this.state.activityLogs.length ?
                this.props.i18n.t('activityTracking.addAnotherActivity') : this.props.i18n.t('activityTracking.addActivity');
        }

        get activityTrackingDescription1() {
            return this.props.i18n.t('activityTracking.search.description1');
        }

        get activityTrackingDescription2() {
            return this.props.i18n.t('activityTracking.search.description2');
        }

        get listTitle() {
            return this.props.i18n.t('activityTracking.listTitle');
        }

        get searchBarPlaceholder() {
            return this.props.i18n.t('trackActivity.search');
        }

        get recentTitle() {
            return this.props.i18n.t('Recent');
        }

        get noActivitiesText() {
            return this.props.i18n.t('noActivities');
        }

        deleteActivityData = activity => {
            this.props.actions.deleteActivityLog(activity.activityLogId);
        };

        updateActivityData = activity => {
            const { pendingActivities, activities, deletedActivities, changedActivities } = this.state;
            const { allLogs } = this.props;
            this.setState(
                activitiesHelper.updateActivityData(activity, allLogs, pendingActivities, changedActivities, deletedActivities, activities), () => {
                    this.toggleNavigatorButton();
                    this.stopSearching();
                });
        };

        //this method is to get props for editing modal, and the next one is for track a new activity from list
        getActivityTrackingModalProps = (activity, isPending) => {
            if (!isPending) {
                this.setState({ changedActivities: [...this.state.changedActivities, activity] });
            }
            return ({
                activity: { ...activity, date: this.props.selectedDate },
                callback: this.updateActivityData,
                deleteActivityData: isPending ? this.removePendingActivity : this.deleteActivityData

            });
        }

        removePendingActivity = activity => {
            const { pendingActivities, changedActivities } = this.state;
            this.setState(
                activitiesHelper.removePendingActivity(activity, pendingActivities, changedActivities),
                this.toggleNavigatorButton
            );
        };

        getActivityTrackingModalPropsAdvanced = (activity, isPending) => ({
            ...this.getActivityTrackingModalProps(activity, isPending),
            fromSingleActivityGoal: this.props.fromSingleActivityGoal,
            challengeId: this.props.challengeId,
        });

        focusCallback = () => {
            this.setState({ isSearching: true });
            _.has(this, 'wrapped.focusCallback') && this.wrapped.focusCallback();
        };

        stopSearching = () => {
            this.setState({ isSearching: false });
            _.has(this, 'wrapped.stopSearching') && this.wrapped.stopSearching();
        };

        isAnimated = activity => this.state.lastAddedItem
            ? _.get(activity, 'activityUnitId') === this.state.lastAddedItem.activityUnitId : false;

        saveRef = ref => (this.wrapped = ref);

        onTabPress = selectedTab => {
            const { i18n } = this.props;
            this.setState({ selectedTab });
            switch (selectedTab.label) {
                case i18n.t(settingsConstants.SLUGS.move):
                    tracker.logEvent('ActivityList_FilterMove_Click');
                    break;
                case i18n.t(settingsConstants.SLUGS.eat):
                    tracker.logEvent('ActivityList_FilterEat_Click');
                    break;
                case i18n.t(settingsConstants.SLUGS.feel):
                    tracker.logEvent('ActivityList_FilterFeel_Click');
                    break;
                default:
                    break;
            }
        };

        get filteredActivities() {
            let activities = this.props.activities;
            if (this.state.selectedTab !== this.tabs[0]) {
                activities = _.filter(this.props.activities, activity => activity.activityCategory === this.state.selectedTab.label);
            }
            const sortedActivities = _.sortBy(activities, ['activityName']);
            return searchAndHighlightEntities(sortedActivities, constants.ACTIVITIES_SEARCH_KEYS, this.state.searchText);
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    ref={this.saveRef}
                    isAnimated={this.isAnimated}
                    stopSearching={this.stopSearching}
                    isSearching={this.state.isSearching}
                    activityLogs={this.state.activityLogs}
                    focusCallback={this.focusCallback}
                    getActivityTrackingModalProps={this.getActivityTrackingModalProps}
                    deleteActivityData={this.deleteActivityData}
                    updateActivityData={this.updateActivityData}
                    exitScreen={this.exitScreen}
                    getTotalPointsEarned={this.getTotalPointsEarned}
                    addActivity={this.addActivity}
                    formatedDate={this.formatedDate}
                    pendingActivities={this.state.pendingActivities}
                    removePendingActivity={this.removePendingActivity}
                    isChangesPresent={this.isChangesPresent}
                    notTrackedText={this.notTrackedText}
                    activityTrackingLabel={this.activityTrackingLabel}
                    activityTrackingDescription1={this.activityTrackingDescription1}
                    activityTrackingDescription2={this.activityTrackingDescription2}
                    getActivityTrackingModalPropsAdvanced={this.getActivityTrackingModalPropsAdvanced}
                    listTitle={this.listTitle}
                    searchText={this.state.searchText}
                    onChangeSearchText={this.onChangeSearchText}
                    searchBarPlaceholder={this.searchBarPlaceholder}
                    onClearSearchText={this.onClearSearchText}
                    recentTitle={this.recentTitle}
                    selectedTab={this.state.selectedTab}
                    tabs={this.tabs}
                    onTabPress={this.onTabPress}
                    filteredActivities={this.filteredActivities}
                    noActivitiesText={this.noActivitiesText}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const routeParams = _.get(ownProps, 'route.params');
        return {
            hasTrackedChallenge: hasTrackedChallenge(state),
            tabs: selectors.getActivityCategoryTabs(state),
            challenge: (ownProps.challengeId || routeParams.challengeId) && getChallenge(state, ownProps.challengeId || routeParams.challengeId),
            allLogs: getPreparedActivityLogsByDate(state, ownProps.selectedDate || routeParams.selectedDate, ownProps.activities || routeParams.activities),
            isLoading: isTracking(state, ownProps.challengeId || routeParams.challengeId) || isDeletingActivityLogs(state, ownProps.challengeId),
            error: getTrackError(state, ownProps.challengeId || routeParams.challengeId) || getDeletingActivityLogsError(state, ownProps.challengeId),
            onlyMeFilter: _.get(coreSelectors.getOnlyMeFilter(state), 'filterId'),
            ...(routeParams || {})
        };
    };

    const mapDispatchToProps = dispatch => ({
        actions: bindActionCreators(actions, dispatch)
    });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(ActivityTrackingListBase));
}

export const styles = {
    header: {
        ...appFonts.smMedium,
    },
    headerWrapper: {
        backgroundColor: baseColors.grey85,
        marginTop: spacing.s3,
        // paddingLeft: spacing.s4,
        paddingLeft: modalPadding,
        paddingTop: spacing.s1,
        paddingBottom: spacing.s1,
        marginBottom: spacing.s1
    },
    noActivitiesText: {
        ...appFonts.lgBold,
        color: baseColors.grey40,
        textAlign: 'center'
    },
    noActivitiesTextWrapper: {
        margin: spacing.s3
    },
    noData: {
        alignSelf: 'center',
        textAlign: 'center',
        marginTop: spacing.s4
    },
    noActivitiesBottom: {
        flex: 3
    },
    searchDescription: {
        marginBottom: spacing.s3,
        ...appFonts.xsRegular
    },
    searchDescriptionBold: {
        ...appFonts.xsBold
    },
    recentWrapper: {
        ...appFonts.smBold,
        justifyContent: 'space-between',
        alignItems: 'baseline',
        backgroundColor: baseColors.grey90,
        flexDirection: 'row',
        paddingRight: spacing.s3,
        paddingLeft: spacing.s3
    },
    separator: {
        height: 1,
        backgroundColor: baseColors.grey80,
    },
    emptyList: {
        ...appFonts.smRegular,
        color: baseColors.grey40,
        marginTop: spacing.s3,
        textAlign: 'center',
    },
};
