import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { baseColors, spacing, appFonts } from '../../../../styles';
import { translate } from '../../services';

export default function WithHelperModalBase(WrappedComponent) {
    class HelperModalBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
        };

        get buttonLabel() {
            return this.props.i18n.t('ok');
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    buttonLabel={this.buttonLabel}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const routeParams = _.get(ownProps, 'route.params');
        return { ...(routeParams || {}) };
    };

    return connect(mapStateToProps)(translate()(HelperModalBase));
}

const BUTTON_HEIGHT = 50;
export const SHADOW = { opacity: 0.3, height: 30 };

export const styles = {
    container: {
        backgroundColor: baseColors.white,
        borderRadius: spacing.s1
    },
    description: {
        ...appFonts.smRegular,
        color: baseColors.grey20,
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3,
        marginTop: spacing.s7,
        marginBottom: spacing.s7,
    },
    buttonContainer: {
        justifyContent: 'center',
        alignItems: 'center'
    },
    button: {
        height: BUTTON_HEIGHT,
        backgroundColor: baseColors.white,
    },
    buttonText: {
        ...appFonts.mdRegular,
        color: baseColors.secondary,
    },
};