import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { baseColors } from '../../../../styles';
import { getCurrentUser } from '../../selectors';
import { removeEmojiFromText } from '../../services/validation';

const DEFAULT_SIZE = 'small';
export const SIZES = {
    xsmall: 16,
    xmsmall20: 20,
    xmsmall: 24,
    xlsmall: 32,
    small: 40,
    medium: 60,
    custom: 73,
    large: 80,
    xlarge: 100
};

export default function withAvatarBase(WrappedComponent) {
    class AvatarBase extends PureComponent {
        static propTypes = {
            size: PropTypes.oneOf(['xsmall', 'xmsmall20', 'xmsmall', 'xlsmall', 'small', 'medium', 'large', 'xlarge']),
            url: PropTypes.string,
            name: PropTypes.string,
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            disablePress: PropTypes.bool,
            isCurrentUser: PropTypes.bool,
            defaultImage: PropTypes.string,
            user: PropTypes.object.isRequired
        };

        static defaultProps = {
            size: 'small',
            disablePress: false,
            isCurrentUser: false,
            name: undefined,
            url: undefined,
            id: undefined,
            defaultImage: undefined,
        };

        get isAvatar() {
            return (!!this.avatarUrl && this.avatarUrl.length > 0 && this.avatarUrl.indexOf('placeholder_user_') === -1)
            || this.props.defaultImage;
        }

        get size() {
            return this.props.size || DEFAULT_SIZE;
        }

        get fontSize() {
            return SIZES[this.size] / 2.5;
        }

        get avatarUrl() {
            const { url, user, isCurrentUser } = this.props;
            if (isCurrentUser) return user.avatarURL || undefined;
            return url || undefined;
        }

        get avatarName() {
            const { name, isCurrentUser, user } = this.props;
            return name || (isCurrentUser && `${user.firstNameDisplay} ${user.lastNameDisplay}`) || '';
        }

        get name() {
            const name = removeEmojiFromText(this.avatarName);
            if (!name.trim()) return;
            const words = _.take(name.split(' '), 2);
            return (words.length < 2
                ? words[0].substring(0, 2)
                : _.map(words, '0').join('')
            ).toUpperCase();
        }

        get id() {
            const { id, user } = this.props;
            return id || _.get(user, 'userId', 0);
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    isAvatar={this.isAvatar}
                    size={this.size}
                    fontSize={this.fontSize}
                    name={this.name}
                    url={this.avatarUrl}
                    id={this.id}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        return {
            user: getCurrentUser(state)
        };
    }

    return connect(mapStateToProps)(AvatarBase);
}

export const styles = {
    xsmall: {
        height: SIZES.xsmall,
        width: SIZES.xsmall,
    },
    xmsmall20: {
        height: SIZES.xmsmall20,
        width: SIZES.xmsmall20,
    },
    xmsmall: {
        height: SIZES.xmsmall,
        width: SIZES.xmsmall,
    },
    xlsmall: {
        height: SIZES.xlsmall,
        width: SIZES.xlsmall,
    },
    small: {
        height: SIZES.small,
        width: SIZES.small,
    },
    medium: {
        height: SIZES.medium,
        width: SIZES.medium,
    },
    large: {
        height: SIZES.large,
        width: SIZES.large,
    },
    xlarge: {
        height: SIZES.xlarge,
        width: SIZES.xlarge,
    },
    nameContainer: {
        backgroundColor: baseColors.primary,
        alignItems: 'center',
        justifyContent: 'center'
    },
    name: {
        color: baseColors.white
    },
    goalsAvatars: {
        borderWidth: 1,
        borderColor: baseColors.white
    }
};
