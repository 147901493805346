import React, { PureComponent } from 'react';
import { appFonts, spacing } from '../../../../styles';
import { translate } from '../../../core';

export default function WithSkipTourModalBase(WrappedComponent) {
    class SkipTourModalBase extends PureComponent {
        render() {
            return (
                <WrappedComponent {...this.props} />
            );
        }
    }

    return translate()(SkipTourModalBase);
}


export const styles = {
    title: {
        ...appFonts.xlBold,
        textAlign: 'center',
        marginBottom: spacing.s3
    },
    description: {
        ...appFonts.mdRegular,
        marginBottom: spacing.s5
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    cancelButton: {
        flex: 1,
        marginRight: spacing.s0
    },
    skipButton: {
        flex: 1,
        marginLeft: spacing.s0
    }
};