import i18n from '../../../i18n/i18n';

export default function getTimeString(quantity, unit) {
    let hours, minutes, seconds, hrUnit;
    const secUnit = i18n.t('secondsShort');
    const minUnit = 'min';
    switch (unit) {
        case i18n.t('hoursShort'):
            hours = parseInt(quantity);
            minutes = convertDecimalTime(quantity);
            hrUnit = i18n.t(hours > 1 ? 'hoursShort' : 'hourShort');
            return hours
                ? `${hours} ${hrUnit} ${minutes} ${minUnit}`
                : `${minutes} ${minUnit}`;
        case minUnit:
            minutes = parseInt(quantity);
            seconds = convertDecimalTime(quantity);
            return minutes
                ? `${minutes} ${minUnit} ${seconds} ${secUnit}`
                : `${seconds} ${secUnit}`;
        default:
            return `${quantity} ${unit}`;
    }
}

function convertDecimalTime(quantity) {
    const decimalPart = (`${quantity}`).split('.')[1];
    const decimalTime = parseFloat(`0.${decimalPart}`);
    return Math.floor(decimalTime * 60);
}