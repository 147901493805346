import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { withRouter } from 'react-router-dom';

import crownIcon from '../../../../image/crownIcon3x.png';
import { layoutStyle, importantClass, baseColors } from '../../../../styles';
import { AsyncComponent, components as Core, Modal, ROUTES } from '../../../core';
import WithLeaderboardItemBase, { styles as baseStyles, USER_ICON_SIZE } from './LeaderboardItemBase';

class LeaderboardItem extends PureComponent {
    static propTypes = {
        leaderboard: PropTypes.object.isRequired,
        name: PropTypes.string,
        rankFont: PropTypes.object,
        points: PropTypes.string,
        challengeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        history: PropTypes.object.isRequired,
        isViewer: PropTypes.bool.isRequired,
        viewerNotTeam: PropTypes.bool.isRequired,
        userInfo: PropTypes.node,
        isDisabledAvatar: PropTypes.bool,
        isTeamChallenge: PropTypes.bool,
        itemBg: PropTypes.string,
        textFontWeight: PropTypes.string,
        isGoldenItem: PropTypes.bool,
        showCrownIcon: PropTypes.bool.isRequired,
        isLocationItem: PropTypes.bool,
        team: PropTypes.object.isRequired,
    };

    static defaultProps = {
        name: undefined,
        rankFont: null,
        points: undefined,
        challengeId: undefined,
        userInfo: undefined,
        isDisabledAvatar: false,
        isTeamChallenge: false,
        itemBg: null,
        textFontWeight: null,
        isGoldenItem: false,
        isLocationItem: false
    };

    showItemDetails = () => {
        const { isDisabledAvatar, leaderboard: { itemEntityId }, challengeId, isTeamChallenge, team } = this.props;
        if (isDisabledAvatar) return;
        if (isTeamChallenge) {
            this.props.history.push(ROUTES.teamDetails(challengeId, itemEntityId), { team });
        } else {
            Modal.open(
                AsyncComponent(() => import('../../../other/components/UserProfile')),
                { id: itemEntityId },
                { isContainer: true, isFullHeight: false }
            );
        }
    };

    render() {
        const { itemBg, isViewer, rankFont, leaderboard, isTeamChallenge, challengeId,
            name, userInfo, points, textFontWeight, viewerNotTeam, isGoldenItem, showCrownIcon, isLocationItem } = this.props;
        const hoverBg = (!isViewer && !isGoldenItem && !isLocationItem) ? styles.hoverBg : null;
        const boldStyle =textFontWeight ? styles.fwBold : null;
        const avatarStyles = [
            styles.avatar,
            isGoldenItem && styles.avatarBorder,
            (isGoldenItem && isViewer) && styles.viewerBorder,
        ];
        return (
            <Core.ListItem
                button={isLocationItem ? false : true}
                className={css(
                    layoutStyle.listItem, styles.listItem,
                    hoverBg,
                    isViewer
                        ? styles.userOrUserTeamBg
                        : (itemBg && styles.itemDarkBg),
                    isGoldenItem && styles.goldenBg,
                )}
                onClick={this.showItemDetails}>
                {showCrownIcon && <Core.Image src={crownIcon} className={css(styles.crownIcon)} />}
                <div
                    className={css(styles.rank)}
                    style={rankFont}>
                    {leaderboard.rank}
                </div>
                {isTeamChallenge ? (
                    <Core.TeamAvatar
                        url={leaderboard.avatarURL}
                        name={leaderboard.name}
                        className={css(...avatarStyles)}
                        id={leaderboard.itemEntityId}
                        challengeId={challengeId}
                        leaderboard={leaderboard}
                        disablePress={true}
                    />
                ) : (
                    <Core.Avatar
                        url={leaderboard.avatarURL}
                        name={leaderboard.name}
                        className={css(...avatarStyles)}
                        id={leaderboard.itemEntityId}
                        disablePress={true}
                    />
                )}
                <div className={css(styles.detailsContainer)}>
                    <div className={css(styles.detailsBody)}>
                        {viewerNotTeam ? (
                            <Core.Icon
                                type="fa"
                                name="user"
                                fill="solid"
                                size={USER_ICON_SIZE}
                                color={baseColors.primary}
                                className={css(styles.userIcon)}
                            />
                        ) : null }
                        <Core.TextSeeMore
                            className={css(styles.mainText, layoutStyle.flex1, boldStyle)}
                            numberOfLines={1}>
                            {name}
                        </Core.TextSeeMore>
                    </div>
                    <Core.TextSeeMore className={css(styles.userInfoText)} numberOfLines={1}>
                        {userInfo}
                    </Core.TextSeeMore>
                </div>
                <div className={css(styles.mainText, styles.pointsText, boldStyle)}>
                    {points}
                </div>
            </Core.ListItem>
        );
    }
}

export default withRouter(WithLeaderboardItemBase(LeaderboardItem));

const LINEAR_GRADIENT = `linear-gradient(260deg, ${baseColors.gradientGolden_1} 0%, ${baseColors.gradientGolden_2} 50%, ${baseColors.gradientGolden_3} 100%)`;

const styles = StyleSheet.create({
    ...baseStyles,
    listItem: importantClass({
        ...baseStyles.listItem,
    }),
    hoverBg: importantClass({
        '&:hover': {
            backgroundColor: baseColors.grey85,
        },
    }),
    avatar: importantClass({
        ...baseStyles.avatar,
    }),
    itemDarkBg: importantClass({
        ...baseStyles.itemDarkBg,
    }),
    rank: importantClass({
        ...baseStyles.rank,
    }),
    userOrUserTeamBg: importantClass({
        ...baseStyles.userOrUserTeamBg,
    }),
    goldenBg: {
        background: LINEAR_GRADIENT,
    }
});
