import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import translate from '../../services/translate';
import { spacing, baseColors, appFonts } from '../../../../styles';
import Icon from '../Icon';
import { Rotate } from '../Animated';

const HEIGHT = 60;

const RefreshLoader = React.memo(({ isRefreshing, i18n }) => {
    const icon = (
        <Icon
            size={spacing.s3}
            type="fa"
            name="sync-alt"
            color={baseColors.white}
            className={css(isRefreshing ? styles.iconAnimation : null)}
        />
    );
    return (
        <div className={css(styles.mainContainer, isRefreshing ? styles.refreshMainContainer : null)}>
            <div className={css(styles.innerContainer)}>
                <span className={css(styles.iconContainer)}>
                    {isRefreshing ?
                        <Rotate forever={true} appear={false} enter={false} exit={false}>
                            {icon}
                        </Rotate> : icon
                    }
                </span>
                <span className={css(styles.text)}>{i18n.t('feeds.indicatorLoader.defaultTitle')}</span>
            </div>
        </div>
    );
});

RefreshLoader.propTypes = {
    i18n: PropTypes.object.isRequired,
    isRefreshing: PropTypes.bool,
};

RefreshLoader.defaultProps = {
    isRefreshing: false
};

export default translate()(RefreshLoader);

const styles = StyleSheet.create({
    mainContainer: {
        height: 0,
        transition: 'all 1s',
        backgroundColor: baseColors.primary,
        overflow: 'hidden',
        color: baseColors.white,
        padding: `0 ${spacing.s3}px`,
        display: 'flex',
        flex: 1
    },
    refreshMainContainer: {
        height: HEIGHT
    },
    innerContainer: {
        height: HEIGHT,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    text: {
        ...appFonts.mdMedium,
        marginLeft: spacing.s3
    },
    iconContainer: {
        width: spacing.s4,
        height: spacing.s4,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
});