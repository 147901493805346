import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getResourceLinks } from '../../actions';
import * as selectors from '../../selectors';
import { translate, MAX_COUNT } from '../../../core';

export default function WithResourceLinksListBase(WrappedComponent) {
    class ResourceLinksListBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            items: PropTypes.array,
            count: PropTypes.number,
            isLoading: PropTypes.bool,
            i18n: PropTypes.object.isRequired
        };

        static defaultProps = {
            items: [],
            count: 0,
            isLoading: false
        };

        constructor(props) {
            super(props);
            
            this.props.actions.getResourceLinks({ offset: 0, maxCount: MAX_COUNT }, true);
        }

        loadMoreContent = () => {
            if (!this.props.isLoading && this.hasMore) {
                this.props.actions.getResourceLinks({ offset: this.props.items.length, maxCount: MAX_COUNT }, false);
            }
        };

        get hasMore() {
            return this.props.items.length < this.props.count;
        }

        get title() {
            return this.props.i18n.t('quickLinkTitle');
        }

        get emptyListMessage() {
            return this.props.i18n.t('noResources');
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    title={this.title}
                    hasMore={this.hasMore}
                    loadMoreContent={this.loadMoreContent}
                    emptyListMessage={this.emptyListMessage}
                />
            );
        }
    }

    function mapStateToProps(state) {
        return {
            items: selectors.getResourceLinks(state),
            count: selectors.getResourceLinksCount(state),
            isLoading: selectors.isLoadingResourceLinks(state),
        };
    }

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators({ getResourceLinks }, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(ResourceLinksListBase));
}