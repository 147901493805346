import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { layoutStyle, containerStyle, importantClass, spacing } from '../../../../styles';
import { components as Core } from '../../../core';
import WithPartnerCustomSSOBase, { styles as baseStyles } from './PartnerCustomSSOBase';

class PartnerCustomSSO extends PureComponent {
    static propTypes = {
        signUpPartnerSSO: PropTypes.func.isRequired,
        signUp: PropTypes.func.isRequired,
        label: PropTypes.string.isRequired,
        noSsoLabel: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired
    };

    render() {
        return (
            <div className={css(layoutStyle.fw)}>
                <div className={css(containerStyle.container, layoutStyle.textCenter)}>
                    <div className={css(styles.header)}>
                        <p className={css(styles.headerText)}>
                            {this.props.subtitle}
                        </p>
                    </div>
                    <div className={css(containerStyle.main)}>
                        <Core.Button
                            fullWidth={true}
                            className={css(styles.button)}
                            onPress={this.props.signUpPartnerSSO}>
                            {this.props.label}
                        </Core.Button>
                        <Core.Button
                            fullWidth={true}
                            className={css(styles.button)}
                            type="outlined"
                            onPress={this.props.signUp}>
                            {this.props.noSsoLabel}
                        </Core.Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default WithPartnerCustomSSOBase(PartnerCustomSSO);

const styles = StyleSheet.create({
    ...baseStyles,
    button: importantClass({ ...baseStyles.button }),
    headerText: {
        ...baseStyles.headerText,
        marginTop: spacing.s2,
    },
    header: {
        marginBottom: spacing.s5,
    }
});
