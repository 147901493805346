import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import _ from 'lodash';
import { components as Core, CAROUSEL_TYPES } from '../../../core';
import { spacing, appFonts } from '../../../../styles';
import TaskCard from '../TaskCard';
import WithOnboardingProfileCarousel, { styles as baseStyles } from './ProfileCarouselBase';

class OnboardingProfileCarousel extends PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        isDisplayed: PropTypes.bool.isRequired,
        slides: PropTypes.array.isRequired,
        length: PropTypes.number.isRequired,
        percentage: PropTypes.number.isRequired,
    };

    static defaultProps = {};

    renderItem = item => (
        <TaskCard
            key={item.action_text}
            task={item}
        />
    );

    render() {
        const { isDisplayed, i18n, slides, percentage } = this.props;
        return isDisplayed > 0 ? (
            <div className={css(styles.onboardingContainer)}>
                <div className={css(styles.marginHorizontal)}>
                    <div className={css(styles.profileCompletionHeader)}>
                        <div className={css(styles.header)}>{i18n.t('complete_my_profile')}</div>
                        <div>{i18n.t('do_following_tasks', { num: slides.length })}</div>
                    </div>
                    <div className={css(styles.subtitle)}>{i18n.t('profile_completion', { percentage })}</div>
                    <Core.ProgressBar
                        variant="determinate"
                        filledPercentage={percentage}
                    />
                </div>
                <div className={css(styles.onboardingCarousel)}>
                    <Core.Carousel
                        type={CAROUSEL_TYPES.twoOnly}
                        count={this.props.length}
                        separator={true}
                        arrows={true}
                        dots={true}
                        itemsLength={this.props.slides.length}>
                        {_.map(this.props.slides, this.renderItem)}
                    </Core.Carousel>
                </div>
            </div>
        ) : null;
    }
}

export default WithOnboardingProfileCarousel(OnboardingProfileCarousel);

const styles = StyleSheet.create({
    ...baseStyles,
    header: {
        ...appFonts.xlBold,
        marginBottom: spacing.s0
    },
    onboardingCarousel: {
        marginTop: spacing.s11
    }
});