import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { appFonts, spacing } from '../../../../styles';

class NotAvailableContent extends PureComponent {
    static propTypes = {
        text: PropTypes.string.isRequired
    };

    render() {
        return <div className={css(styles.text)}>{this.props.text}</div>;
    }
}

const styles = StyleSheet.create({
    text: {
        ...appFonts.mdRegular,
        padding: spacing.s3,
        textAlign: 'center'
    }
});

export default NotAvailableContent;
