import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';

import ContentItem from '../ContentItem';
import { appFonts, baseColors, importantClass, spacing } from '../../../../styles';
import { components as Core } from '../../../core';
import WithContentListBase from './ContentListBase';
import ContentSearch from '../ContentSearch';

class ContentList extends PureComponent {
    static propTypes = {
        category: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        searchCallback: PropTypes.func.isRequired,
        items: PropTypes.array.isRequired,
        count: PropTypes.number.isRequired,
        isLoading: PropTypes.bool,
        hasMore: PropTypes.bool,
        isSearching: PropTypes.bool,
        isPage: PropTypes.bool,
        title: PropTypes.string,
        search: PropTypes.string,
        loadMoreContent: PropTypes.func.isRequired,
        i18n: PropTypes.object.isRequired,
        listContentClassName: PropTypes.string,
        contentEmptyListParams: PropTypes.object,
        isMoreTab: PropTypes.bool
    };

    static defaultProps = {
        title: null,
        search: null,
        hasMore: false,
        isPage: true,
        isSearching: false,
        isLoading: false,
        listContentClassName: '',
        contentEmptyListParams: undefined,
        isMoreTab: false
    };

    get renderEmptyList() {
        const { contentEmptyListParams } = this.props;
        return <Core.EmptyListSimple {...contentEmptyListParams} className={css(styles.emptyList)} />;
    }

    renderItem = ({ item }) => {
        const { items, isMoreTab } = this.props;
        if (items.length === 0) return null;
        const isLastItemInList = items[items.length - 1].id === item.id && items.length % 2 === 1;
        return <ContentItem item={item} isNewVideoControlLayout={false} isLastItemInList={isLastItemInList} isMoreTab={isMoreTab} />;
    }

    get headerComponent() {
        const { title, isPage } = this.props;
        return isPage ? (
            <React.Fragment>
                <Core.EntityDetailsHeader hasBackButton={true} />
                {title ? (
                    <Core.SubHeader
                        title={title}
                        noTopPadding={true}
                        right={this.searchComponent}
                        className={css(styles.searchBar)}
                    />
                ) : null
                }
            </React.Fragment>
        )
            : null;
    }

    get searchComponent() {
        return (
            <ContentSearch
                category={this.props.category}
                type={this.props.type}
                callback={this.props.searchCallback}
            />
        );
    }

    keyExtractor = item => item.id;

    get wrapper() {
        return this.props.isPage ? Core.SmallerContainer : Core.Layout.WiderContainer;
    }

    get moreTabTitle() {
        const { isMoreTab, companyName, items, i18n } = this.props;
        return (
            isMoreTab && items?.length ? (
                <div className={css(styles.moreTabPlaceholder)}>{i18n.t('addedBy', { companyName })}</div>
            ) : null
        );
    }

    render() {
        const { items, loadMoreContent, hasMore, isLoading, isSearching, listContentClassName } = this.props;
        return (
            <this.wrapper>
                {this.moreTabTitle}
                <Core.InfiniteLazyListFadeIn
                    data={items}
                    keyExtractor={this.keyExtractor}
                    renderItem={this.renderItem}
                    onEndReached={loadMoreContent}
                    onEndReachedThreshold={0.5}
                    hasMore={hasMore}
                    isLoading={isLoading || isSearching}
                    ListHeaderComponent={this.headerComponent}
                    ListEmptyComponent={this.renderEmptyList}
                    isHorizontal={false}
                    twoColumns={true}
                    listContentClassName={listContentClassName}
                />
            </this.wrapper>
        );
    }
}

const styles = StyleSheet.create({
    searchBar: {
        paddingTop: spacing.s3
    },
    emptyList: importantClass({
        ...appFonts.mdRegular,
        color: baseColors.grey40,
        paddingTop: spacing.s7,
        minHeight: 'calc(100vh - 500px)',
        justifyContent: 'start'
    }),
    moreTabPlaceholder: {
        ...appFonts.mdBold,
        marginTop: spacing.s10,
        paddingLeft: spacing.s1,
        marginBottom: -spacing.s4
    }
});

export default WithContentListBase(ContentList);
