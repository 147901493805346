import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { layoutStyle, spacing, windowSize } from '../../../../styles';
import WithRecentActivityLogBase, { styles as baseStyles } from './RecentActivityLogBase';
import { AsyncComponent, components as Core, Modal, ROUTES } from '../../../core';
import ActivityLogItem from '../ActivityLogItem';
import tracker from '../../../core/services/tracker/tracker';

const ANIMATION_DURATION = 1000;
const FADE_OUT_DELAY = 3000;
const END_ANIMATION_DELAY = 4000;

class RecentActivityLog extends PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
        isLoadingActivityLogs: PropTypes.bool.isRequired,
        logsByDay: PropTypes.array.isRequired,
        score: PropTypes.bool,
        getDateString: PropTypes.func,
        getDateName: PropTypes.func,
        title: PropTypes.string.isRequired,
        vendorId: PropTypes.number,
        noDeviceActivitesString: PropTypes.func,
        isSyncingDevice: PropTypes.bool,
        i18n: PropTypes.object.isRequired,
        getItemIsNew: PropTypes.func,
        indicateNoNewActivities: PropTypes.bool.isRequired,
        syncCompleted: PropTypes.bool.isRequired,
        setAnimationComplete: PropTypes.func,
        noTrackedActivitiesTitle: PropTypes.string.isRequired,
        trackActivityMessage: PropTypes.string.isRequired,
        hasAnyLogs: PropTypes.bool.isRequired
    };

    static defaultProps = {
        score: false,
        getDateString: undefined,
        getDateName: undefined,
        vendorId: null,
        noDeviceActivitesString: undefined,
        isSyncingDevice: false,
        getItemIsNew: undefined,
        setAnimationComplete: undefined
    };

    constructor(props) {
        super(props);
        this.state = {
            animateNoNewActivities: false
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.vendorId) {
            if (this.props.syncCompleted && this.props.indicateNoNewActivities) {
                this.animateNoNewView();
            }
        }
    }

    animateNoNewView = () => {
        setTimeout(() =>
            this.setState({ animateNoNewActivities: true }),
        ANIMATION_DURATION);
    }

    get trackActivityText() {
        return this.props.i18n.t('trackActivity.text');
    }

    get noNewActivitiesView() {
        return (
            <Core.Animated.Fade
                bottom={true}
                when={this.state.animateNoNewActivities}
                onReveal={this.animationFinished}
                duration={ANIMATION_DURATION}>
                <div className={css(styles.noNewActivitySection)}>
                    <p style={styles.noNewActivitiesText}>{this.props.i18n.t('recentActivityLog.noNewActivities')}</p>
                </div>
            </Core.Animated.Fade>
        );
    }

    animationFinished = () => {
        setTimeout(() =>
            this.setState({ animateNoNewActivities: false }),
        FADE_OUT_DELAY);

        setTimeout(() =>
            this.props.setAnimationComplete(),
        END_ANIMATION_DELAY);
    }

    viewActivityLog = () => {
        this.props.history.push(ROUTES.activityLog());
    };

    renderDay = (dailyActivityLogs, i) => {
        const { getDateName, getDateString } = this.props;

        return (
            <div key={i.toString()}>
                <div className={css(styles.subtitleContainer, layoutStyle.flex)}>
                    <span className={css(styles.dateName)}>{getDateName(dailyActivityLogs.date)}</span>
                    <span className={css(styles.date)}>{getDateString(dailyActivityLogs.date)}</span>
                </div>
                <Core.List component="div" disablePadding={true}>
                    {_.map(dailyActivityLogs.logs, this.renderItem)}
                </Core.List>
            </div>
        );
    }

    renderItem = (item, i) => (
        <ActivityLogItem
            key={item.activityLogId}
            isFirstItem={i === 0}
            activityLogId={item.activityLogId}
            itemHasSwipeout={true}
            itemShowsDeviceIcon={!this.props.vendorId}
            itemShowsNewStatus={this.props.getItemIsNew(item)}
        />
    );

    get renderRecentActivities() {
        const { score, title, logsByDay, isLoadingActivityLogs, hasAnyLogs } = this.props;
        return (
            <>
                {isLoadingActivityLogs ?
                    <Core.ListLoading /> :
                    <>
                        <div className={css(styles.titleContainer, score && styles.scoreStyle)}>
                            <div className={css(styles.scoreContainer)}>
                                <span className={css(styles.title)}>
                                    {title}
                                </span>
                            </div>
                            {hasAnyLogs ?
                                <Core.ViewAllButton
                                    onPress={this.viewActivityLog}
                                />
                                : null}
                        </div>
                        {hasAnyLogs ? _.map(logsByDay, this.renderDay) : this.emptyStateContent}
                    </>
                }
            </>
        );
    }

    get deviceActivitiesContent() {
        const { isSyncingDevice, isLoadingActivityLogs, i18n, logsByDay, noDeviceActivitesString,
            syncCompleted, indicateNoNewActivities, hasAnyLogs } = this.props;
        if (isSyncingDevice) {
            return (
                <div className={css(styles.updatingActivitiesContainer)}>
                    <Core.CalculatingCircles isVisible={isSyncingDevice} />
                    <p className={css(styles.convertingToPointsText)}>{i18n.t('recentActivityLog.convertingActivityPoints')}</p>
                    <p className={css(styles.waitText)}>{i18n.t('recentActivityLog.activityMinuteOrTwo')}</p>
                </div>
            );
        } else if (isLoadingActivityLogs) {
            return (
                <div className={css(layoutStyle.commonPadding)}>
                    <Core.SkeletonContent width="100%" height={spacing.s17}>
                        <Core.SkeletonRect width="100%" height={spacing.s3} />
                        <Core.SkeletonRect className={css(styles.skeletonItem)} width="100%" height={spacing.s3} />
                        <Core.SkeletonRect className={css(styles.skeletonItem)} width="66%" height={spacing.s3} />
                    </Core.SkeletonContent>
                </div>
            );
        }

        return (
            <div>
                {syncCompleted && indicateNoNewActivities ?
                    <div>
                        {this.noNewActivitiesView}
                    </div>
                    : null}
                {logsByDay && hasAnyLogs ?
                    <div>
                        {_.map(logsByDay, this.renderDay)}
                    </div>
                    :
                    <div className={css(styles.deviceNoActivitiesContainer)}>
                        <p className={css(styles.noActivitiesText)}>{noDeviceActivitesString}</p>
                    </div>
                }
            </div>
        );
    }

    get renderDeviceActivities() {
        const { score, title } = this.props;
        return (
            <Fragment>
                <div className={css(styles.titleContainer, score && styles.scoreStyle)}>
                    <div className={css(styles.scoreContainer)}>
                        <p className={css(styles.title)}>{title}</p>
                    </div>
                </div>
                {this.deviceActivitiesContent}
            </Fragment>
        );
    }

    onTrackActivity = () => {
        tracker.logEvent('track_activity');
        Modal.open(
            AsyncComponent(() => import('../../../challenges/components/TrackActivityForm')),
            {
                isModal: true,
                trackActivityTitle: this.state.trackActivityText
            },
            {
                isNoPadding: true,
                isContainer: true,
                modalHidden: true,
                isMaxWidthLimited: true,
                isMaxHeight: true,
            },
        );
    };

    get emptyStateContent() {
        const { noTrackedActivitiesTitle, trackActivityMessage } = this.props;
        return (
            <div className={css(layoutStyle.bgGrey90, layoutStyle.flexColumnCenter, styles.emptyStateContainer)}>
                <p className={css(styles.emptyStateTitle)}>{noTrackedActivitiesTitle}</p>
                <Core.Button onPress={this.onTrackActivity}>
                    <span>+ {trackActivityMessage}</span>
                </Core.Button>
            </div>
        );
    }

    render() {
        const { vendorId } = this.props;
        return vendorId ? this.renderDeviceActivities : this.renderRecentActivities;
    }
}

const styles = StyleSheet.create({
    ...baseStyles,
    scoreContainer: {
        maxWidth: (windowSize.width - 2 * spacing.s2) * 0.65 // eslint-disable-line
    },
    subtitleContainer: {
        ...baseStyles.subtitleContainer,
        display: 'flex',
        marginBottom: spacing.s1
    },
    scoreStyle: {
        marginTop: spacing.s5
    },
    titleContainer: {
        ...baseStyles.titleContainer,
        display: 'flex'
    },
    skeletonItem: {
        marginTop: spacing.s0,
    },
});

export default withRouter(WithRecentActivityLogBase(RecentActivityLog));
