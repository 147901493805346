import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';

import * as actions from '../../actions';
import { translate } from '../../../core';
import { baseColors } from '../../../../styles';
import { TOURS } from '../../constants';
import { selectors as rewardsSelectors } from '../../../rewards';

export default function WithTourLayoutBase(WrappedComponent) {
    class TourLayoutBase extends PureComponent {
        static propTypes = {
            tour: PropTypes.func.isRequired,
            data: PropTypes.object.isRequired,
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            type: PropTypes.oneOf([TOURS.PRODUCT_TOUR]).isRequired,
            isRewardsEnabled: PropTypes.bool.isRequired,
        };

        constructor(props) {
            super(props);
            this.state = {
                skipModalVisible: false
            };
        }

        get tourData() {
            const { tour, data, isRewardsEnabled } = this.props;
            return tour.data({ ...data, isRewardsEnabled });
            /* Often isRewardsEnabled prop in data object has an initial value of false just because the rewards
            were not loaded yet when the data object was formed and that causes rewards step of the product tour to be skipped */
        }

        get hasFinishScreen() {
            return _.has(this.tourData, 'finishScreen');
        }

        get finishScreen() {
            return this.hasFinishScreen ? {
                ...this.tourData.finishScreen,
                iconBackgroundColor: baseColors.primary,
                isTextCentered: true
            } : null;
        }

        get hasInitialScreen() {
            return _.has(this.tourData, 'initialScreen');
        }

        get initialScreen() {
            return this.hasInitialScreen ? {
                ...this.tourData.initialScreen,
                onButtonPress: this.onNext,
                iconBackgroundColor: baseColors.primary,
                isTextCentered: true
            } : null;
        }

        onCancelSkipTour = () => {
            this.setState(() => ({ skipModalVisible: false }));
        };

        onClose = () => {
            this.setState(() => ({ skipModalVisible: true }));
        };

        saveRef = ref => this.wrapped = ref;

        get toolTipMethods() {
            return {
                onClose: this.onClose,
                onBack: this.onBack,
                onNext: this.onNext
            };
        }

        onFinishTour = () => {
            this.wrapped.onFinishTour();
            _.eq(TOURS.PRODUCT_TOUR, this.props.type) ? this.props.actions.finishProductTour({ done: 1 }) : this.props.actions.finishTour(this.props.type);
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    tourData={this.tourData}
                    initialScreen={this.initialScreen}
                    finishScreen={this.finishScreen}
                    onClose={this.onClose}
                    hasInitialScreen={this.hasInitialScreen}
                    hasFinishScreen={this.hasFinishScreen}
                    ref={this.saveRef}
                    {...this.state}
                    toolTipMethods={this.toolTipMethods}
                    onFinishTour={this.onFinishTour}
                    onCancelSkipTour={this.onCancelSkipTour}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const routeParams = _.get(ownProps, 'route.params');
        return {
            isRewardsEnabled: rewardsSelectors.isRewardsEnabled(state),
            ...(routeParams || {}),
        };
    };

    const mapDispatchToProps = dispatch => ({
        actions: bindActionCreators(actions, dispatch)
    });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(TourLayoutBase));
}


export const styles = {
    mainContainer: {
        flex: 1,
    },
    skipButton: {
        borderColor: baseColors.white
    }
};
