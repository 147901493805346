import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { StyleSheet, css } from 'aphrodite-jss';

import { baseColors, importantStyles, layoutStyle, spacing } from '../../../../styles';
import { components as Core, Modal } from '../../../core';
import WithChoiceQuestionBase, { styles as baseStyles, ICON_SIZE, HELPER_ICON_SIZE, APPLE_ICON } from './ChoiceQuestionBase';

class ChoiceQuestion extends PureComponent {
    static propTypes = {
        callback: PropTypes.func,
        onNext: PropTypes.func.isRequired,
        questionOptions: PropTypes.array.isRequired,
        question: PropTypes.object.isRequired,
        onOptionClick: PropTypes.func.isRequired,
        getGuideModalProps: PropTypes.func.isRequired,
        answer: PropTypes.number,
        errorText: PropTypes.string,
        helperText: PropTypes.object,
        i18n: PropTypes.object.isRequired,
        isSavingAnswer: PropTypes.bool,
        disabledId: PropTypes.number
    };

    static defaultProps = {
        errorText: '',
        answer: null,
        callback: null,
        helperText: null,
        isSavingAnswer: false,
        disabledId: undefined
    };

    renderText = () => <div>{this.props.helperText.list}</div>;

    openModal = () => {
        const { i18n, getGuideModalProps } = this.props;

        this.closeModal = Modal.open(
            Core.InfoModal,
            {
                ...getGuideModalProps(),
                renderText: this.renderText,
                buttons: [{ text: i18n.t('got_it'), onPress: () => this.closeModal() }],
                textStyle: styles.explanation
            },
            {
                fadeTransition: true,
                PaperProps: { elevation: 0 },
                isTransparent: true,
                isNoPadding: true
            }
        );
    };

    get options() {
        return _.map(this.props.questionOptions, metadata => {
            const { question }= this.props;
            const optionId = `${_.camelCase(`${question.title || question.label}`)}ChoiceOption-${metadata.id}`;
            return (
                <Core.Button
                    id={optionId}
                    key={metadata.id}
                    fullWidth={true}
                    className={css(styles.option)}
                    type={metadata.id === this.props.answer ? 'contained' : 'outlined'}
                    onPress={this.props.onOptionClick(metadata.id)}
                    disabled={this.props.isSavingAnswer && this.props.disabledId === metadata.id}>
                    {metadata.text}
                </Core.Button>
            );
        });
    }

    render() {
        const { question, helperText, i18n, errorText, callback, onNext } = this.props;
        return (
            <div className={css(styles.bottomPadding)}>
                <div className={css(styles.textContainer)}>
                    <div className={css(styles.header)}>
                        {
                            !_.isEmpty(question.icon) ?
                                <div className={css(styles.icon)}>
                                    {question.icon !== APPLE_ICON ?
                                        <Core.Icon
                                            type="fa"
                                            name={question.icon}
                                            size={ICON_SIZE}
                                            color={baseColors.primary}
                                        /> :
                                        <Core.Icon
                                            type="fa"
                                            name="apple-alt"
                                            size={ICON_SIZE}
                                            color={baseColors.primary}
                                        />
                                    }
                                </div> : null
                        }
                    </div>
                    <div className={css(layoutStyle.flexColumnCenter)}>
                        <div className={css(styles.questionText)}>
                            {question.text}
                        </div>
                        {helperText ? (
                            <button onClick={this.openModal} id="choiceQuestionBtn">
                                <Core.Icon type="fa" name="question-circle" size={HELPER_ICON_SIZE} color={baseColors.secondary} />
                                <span className={css(styles.scoreMeaning)}>{i18n.t('viewGuidelines')}</span>
                            </button>
                        ) : null}
                    </div>
                </div>
                {this.options}
                <span className={css(styles.errorText)}>{errorText}</span>
                {callback ?
                    <Core.Layout.FixedModalFooterButton>
                        <Core.Button
                            type="contained"
                            size="medium"
                            className={css(layoutStyle.halfWidth)}
                            onPress={onNext}>
                            {this.props.i18n.t('next')}
                        </Core.Button>
                    </Core.Layout.FixedModalFooterButton>
                    : null}
            </div>
        );
    }
}

const MIN_HEIGHT = 180;

const styles = StyleSheet.create(importantStyles({
    ...baseStyles,
    header: {
        ...baseStyles.header,
        display: 'flex',
        justifyContent: 'center'
    },
    textContainer: {
        minHeight: MIN_HEIGHT
    },
    iconClassName: {
        backgroundColor: baseColors.primary
    },
    bottomPadding: {
        paddingBottom: spacing.s3
    }
}));

export default WithChoiceQuestionBase(ChoiceQuestion);
