import { NAME as name } from './constants';
import { createActionTypes } from '../core';

const NAME = name.toUpperCase();

// for api
export const ADD_LIKE = createActionTypes(`${NAME}/ADD_LIKE`);
export const DELETE_LIKE = createActionTypes(`${NAME}/DELETE_LIKE`);
export const POST_COMMENT = createActionTypes(`${NAME}/POST_COMMENT`);
export const EDIT_COMMENT = createActionTypes(`${NAME}/EDIT_COMMENT`);
export const MODERATE_COMMENT = createActionTypes(`${NAME}/MODERATE_COMMENT`);
export const DELETE_COMMENT = createActionTypes(`${NAME}/DELETE_COMMENT`);
export const GET_COMMENTS = createActionTypes(`${NAME}/GET_COMMENTS`);
export const GET_COMMENTS_FOR_TYPE = createActionTypes(`${NAME}/GET_COMMENTS_FOR_TYPE`);
export const GET_MORE_COMMENTS = createActionTypes(`${NAME}/GET_MORE_COMMENTS`);
export const DELETE_COMMENTS = createActionTypes(`${NAME}/DELETE_COMMENTS`);
export const GET_LIKES = createActionTypes(`${NAME}/GET_LIKES`);

export const GET_REACTION_EMOJIS = createActionTypes(`${NAME}/GET_REACTION_EMOJIS`);
export const ADD_REACTION_EMOJI = createActionTypes(`${NAME}/ADD_REACTION_EMOJI`);
export const REMOVE_REACTION_EMOJI = createActionTypes(`${NAME}/REMOVE_REACTION_EMOJI`);

export const GET_REACTIONS = createActionTypes(`${NAME}/GET_REACTIONS`);