import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { spacing } from '../../../../styles';
import { selectors as coreSelectors, translate } from '../../../core';

export default function WithShareNewsBase(WrappedComponent) {
    class ShareNewsBase extends PureComponent {
        static propTypes = {
            shareNewsText: PropTypes.string,
            user: PropTypes.object.isRequired,
            i18n: PropTypes.object.isRequired,
        };

        static defaultProps = {
            shareNewsText: undefined,
        };

        getUpdateOptions = (shareYourDay, trackActivity) => {
            const { i18n } = this.props;
            return [{
                title: i18n.t('shareAPost'),
                onPress: shareYourDay
            }, {
                title: i18n.t('track_an_activity'),
                onPress: trackActivity
            }];
        };

        get shareDayText() {
            const { i18n, shareNewsText } = this.props;
            if (shareNewsText) {
                return this.props.shareNewsText;
            }
            return i18n.t('share_smth');
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    shareDayText={this.shareDayText}
                    getUpdateOptions={this.getUpdateOptions}
                    optionsTitle={this.props.i18n.t('what_to_share')}
                />
            );
        }
    }

    function mapStateToProps(state) {
        return {
            user: coreSelectors.getCurrentUser(state),
        };
    }

    return connect(mapStateToProps)(translate()(ShareNewsBase));
}

export const styles = {
    shareDayIcon: {
        paddingLeft: spacing.s0,
        paddingRight: spacing.s0
    },
    iconSet: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center'
    }
};
