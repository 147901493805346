import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';

import { baseColors, spacing, layoutStyle, appFonts } from '../../../../../styles';
import WithDashboardProgressIndicatorBase, { styles as baseStyles } from './DashboardProgressIndicatorBase';
import { components as Core } from '../../../../core';
import HighestProgressIndicator from '../../HighestProgressIndicator';

class DashboardProgressIndicator extends PureComponent {
    static propTypes = {
        percentFilled: PropTypes.number,
        isSkeletonShown: PropTypes.bool,
        isHighestScoreReached: PropTypes.bool,
        levelName: PropTypes.string,
        daysLeftText: PropTypes.string,
        rewardSection: PropTypes.node,
        monthDayText: PropTypes.string,
        soonReset: PropTypes.bool
    };

    static defaultProps = {
        percentFilled: 0,
        isSkeletonShown: false,
        isHighestScoreReached: false,
        levelName: null,
        rewardSection: null,
        daysLeftText: null,
        monthDayText: null,
        soonReset: false
    };

    get skeleton() {
        const lgHeight = spacing.s5;
        const smHeight = spacing.s3 + 2;

        return (
            <div id="dashboard-skeleton" className={css(styles.skeletonWrapper)}>
                <Core.SkeletonContent width={300} height={100}>
                    <Core.SkeletonRect height={30} width={220} />
                    <Core.SkeletonRect height={lgHeight} width="100%" className={css(styles.skeleton)} />
                    <Core.SkeletonRect width={160} height={smHeight} className={css(styles.skeleton)} />
                </Core.SkeletonContent>
            </div>
        );
    }

    render() {
        const { levelName, rewardSection, percentFilled, daysLeftText, isSkeletonShown, isHighestScoreReached, monthDayText, soonReset } = this.props;
        return (
            <div className={css(layoutStyle.flexRow, layoutStyle.flexSpaceBetween, layoutStyle.flexAlignCenter)} style={{ position: 'relative' }}>
                {isSkeletonShown ?
                    this.skeleton : (
                        <div className={css(styles.leftPart)}>
                            <div className={css(layoutStyle.flexRow, layoutStyle.flexSpaceBetween, styles.section)}>
                                <div className={css(styles.levelName)}>{levelName}</div>
                                {rewardSection}
                            </div>
                            <div className={css(layoutStyle.flexRow, layoutStyle.flexAlignCenter, styles.section, layoutStyle.pRelative)}>
                                <Core.ProgressBar
                                    filledPercentage={percentFilled}
                                    isPercentageShown={true}
                                    variant="determinate"
                                    isAnimated={true}
                                    classes={{ root: css(layoutStyle.flex1) }}
                                    accessibilityLabels={`Dashboard progressbar. Value is ${percentFilled}`}
                                />
                            </div>
                            { isHighestScoreReached && <HighestProgressIndicator />}
                            <div className={css(layoutStyle.flexRow, styles.daysInfo)}>
                                {monthDayText ? <div className={css(styles.monthDayText)}>{monthDayText}</div> : null}
                                {soonReset ? <div className={css(styles.daysLeftText)}>{daysLeftText}</div> : null}
                            </div>
                        </div>
                    )}
                <div className={css(styles.iconWrapper)}>
                    <Core.Icon
                        name="chevron-right"
                        type="fa"
                        color={baseColors.grey40}
                        size={spacing.s3}
                        style={{ marginLeft: spacing.s5 }}
                    />
                </div>
            </div>
        );
    }
}

const styles = StyleSheet.create({
    ...baseStyles,
    levelName: {
        ...appFonts.xlBold
    },
    section: {
        marginBottom: spacing.s2
    },
    leftPart: {
        flex: 1,
        marginRight: spacing.s7
    },
    iconWrapper: {
        height: spacing.s1
    },
    skeleton: {
        paddingTop: spacing.s2
    },
    skeletonWrapper: { marginTop: 2 },
    daysInfo: {
        marginTop: spacing.s1,
        flexWrap: 'wrap',
        gap: `${spacing.s0}px ${spacing.s2}px`
    }
});

export default WithDashboardProgressIndicatorBase(DashboardProgressIndicator);
