import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import TextSeeMore from '../TextSeeMore';
import WithExploreItemDescriptionBase, { styles as baseStyles } from './ExploreItemDescriptionBase';


class ExploreItemDescription extends PureComponent {
    static propTypes = {
        text: PropTypes.string.isRequired,
        numberOfLines: PropTypes.number.isRequired,
        color: PropTypes.string.isRequired,
    };

    render() {
        const { text, numberOfLines, color } = this.props;

        return (
            <div className={css(styles.mainContainer)}>
                <TextSeeMore
                    numberOfLines={numberOfLines}
                    className={css(styles.text)}
                    isExpandHidden={true}>
                    <p style={{ color }}>
                        {text}
                    </p>
                </TextSeeMore>
            </div>
        );
    }
}

export default WithExploreItemDescriptionBase(ExploreItemDescription);

const styles = StyleSheet.create({
    ...baseStyles,
    mainContainer: {
        ...baseStyles.mainContainer,
        display: 'flex'
    },
});
