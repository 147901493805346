import I18n from '../../../../i18n/i18n';
import { PLATFORMS, Platform, CSS_CLASSES } from '../../../core';
import { ARROWS } from '../../constants';

export default class ProductTour {
    // we use refs to get position of elements
    static REFS = {
        dashboard: null,
        rewards: null,
        goals: null,
        scrollView: null,
        SOT: null
    };

    static get isWeb() {
        return Platform.OS === PLATFORMS.web;
    }

    static steps({ pointsName, rewardPointName, isHealthSurveyEnabled }) {
        return [
            {
                title: I18n.t('productTour.dashboard.title'),
                text: I18n.t('productTour.dashboard.body', { pointsName }),
                target: ProductTour.isWeb ? `.${CSS_CLASSES.dashboardTourTarget}` : ProductTour.REFS.dashboard,
                arrow: ProductTour.isWeb ? ARROWS.DOWN : ARROWS.UP
            },
            {
                title: I18n.t('productTour.rewards.title'),
                text: I18n.t('productTour.rewards.body', { rewardPointName }),
                target: ProductTour.isWeb ? `.${CSS_CLASSES.rewards}` : ProductTour.REFS.rewards,
                arrow: ProductTour.isWeb ? ARROWS.DOWN : ARROWS.UP
            },
            {
                title: I18n.t('productTour.goals.title'),
                text: I18n.t('productTour.goals.body'),
                target: ProductTour.isWeb ? `.${CSS_CLASSES.goals}` : ProductTour.REFS.goals,
                arrow: ARROWS.DOWN
            },
            {
                title: I18n.t('productTour.explore.title'),
                text: I18n.t('productTour.explore.body'),
                arrow: ProductTour.isWeb ? ARROWS.UP : ARROWS.DOWN,
                tabIcon: 1,
                target: `.${CSS_CLASSES.exploreMenuItem}`,
            },
            {
                title: I18n.t('productTour.me.title'),
                text: I18n.t(isHealthSurveyEnabled ? 'productTour.me.body' : 'meTour.healthSurveyDisabled'),
                arrow: ProductTour.isWeb ? ARROWS.UP : ARROWS.DOWN,
                tabIcon: ProductTour.isWeb ? 3: 4,
                target: `.${CSS_CLASSES.meMenuItem}`,
            }
        ];
    }

    // data is required, this function returns object with initialScreen, steps and finishScreen
    static data({ isRewardsEnabled, programName, ...restProps }) {
        const steps = ProductTour.steps(restProps);
        if (!isRewardsEnabled) {
            steps.splice(1, 1);
        }
        return {
            initialScreen: {
                title: I18n.t('productTour.initialScreen.title', { programName }),
                text: I18n.t('productTour.initialScreen.body'),
                iconName: 'location-arrow',
                target: 'body'
            },
            steps,
            finishScreen: {
                title: I18n.t('productTour.finishScreen.title'),
                text: I18n.t('productTour.finishScreen.body'),
                buttonTitle: I18n.t('productTour.finishScreen.button'),
                iconName: 'location-arrow'
            }
        };
    }
}
