import _ from 'lodash';
import React from 'react';
import Highlighter from '../components/Highlighter';
import { baseColors } from '../../../styles';

/**
 * search in entity props for search keys and return highliter instead of string if match with search text
 */
export default function searchAndHighlightEntities(entities, searchKeys, searchText, backgroundColor = baseColors.grey85) {
    if (!searchText) return entities;

    const text = searchText.toLowerCase();

    return _.compact(_.map(entities, activity => {
        const sk = _.find(searchKeys, sk => activity[sk].toString().toLowerCase().indexOf(text) !== -1);
        return sk && {
            ...activity,
            [`${sk}${searchAndHighlightEntities.SEARCH_NAME}`]: (
                <Highlighter
                    autoEscape={true}
                    highlightStyle={{ backgroundColor }}
                    searchWords={[text]}
                    textToHighlight={activity[sk]}
                />
            )
        };
    }));
}

searchAndHighlightEntities.SEARCH_NAME = 'Search';