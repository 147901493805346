import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import Checkbox from '../Checkbox';
import Button from '../Button';
import SubHeader from '../SubHeader';
import { spacing, importantStyles, baseColors, layoutStyle } from '../../../../styles';
import WithDeletionWarningBase, { styles as baseStyles } from './DeletionWarningBase';

class DeletionWarning extends PureComponent {
    static propTypes = {
        name: PropTypes.string.isRequired,
        onCheckbox: PropTypes.func.isRequired,
        onConfirm: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        isChecked: PropTypes.bool,
        description: PropTypes.string,
        checkboxLabel: PropTypes.string,
        confirmButton: PropTypes.string,
        i18n: PropTypes.object.isRequired,
    };

    static defaultProps = {
        description: null,
        confirmButton: null,
        checkboxLabel: null,
        isChecked: false
    };

    render() {
        const classes = { label: css(styles.checkboxLabel) };
        const { onConfirm, onCheckbox, onClose } = this.props;
        return (
            <div className={css(styles.mainContainer)}>
                <SubHeader
                    noLeftPadding={true}
                    noTopPadding={true}
                    className={css(styles.title)}
                    title={this.props.name}
                    hasBorder={true}
                    isDeletionWarning={true}
                    titleClassName={css(styles.db, layoutStyle.textOverflow)}
                />
                <div className={css(styles.bodyContainer)}>
                    <div className={css(styles.description)}>
                        {this.props.description}
                    </div>
                    <Checkbox
                        checked={this.props.isChecked}
                        onChange={onCheckbox}
                        label={this.props.checkboxLabel}
                        classes={classes}
                        color={baseColors.dangerDarker}
                    />
                </div>
                <div className={css(layoutStyle.flexRow, styles.buttonsContainer)}>
                    <Button
                        type="outlined"
                        size="small"
                        className={css(styles.cancelButton)}
                        onPress={onClose}>
                        {this.props.i18n.t('discardAlert.cancel')}
                    </Button>
                    <Button
                        disabled={!this.props.isChecked}
                        type="contained"
                        className={this.props.isChecked ? css(styles.removeButtonActive) : null}
                        size="small"
                        onPress={onConfirm}>
                        {this.props.confirmButton}
                    </Button>
                </div>
            </div>
        );
    }
}

const styles = StyleSheet.create({
    ...baseStyles,
    mainContainer: {
        backgroundColor: baseColors.white,
        borderRadius: spacing.s1
    },
    buttonsContainer: {
        borderStyle: 'solid',
        borderWidth: 0,
        ...baseStyles.buttonsContainer
    },
    ...importantStyles({
        db: {
            display: 'block'
        },
        title: {
            ...baseStyles.title
        },
        cancelButton: {
            ...baseStyles.cancelButton
        },
        removeButtonActive: {
            ...baseStyles.removeButtonActive
        },
        checkboxLabel: {
            ...baseStyles.checkboxLabel
        }
    })
});

export default WithDeletionWarningBase(DeletionWarning);