import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { withRouter } from 'react-router-dom';
import { containerStyle, layoutStyle } from '../../../../styles';
import { components as Core } from '../../../core';
import Logo from '../Logo';
import WithProgramDomainBase, { styles as baseStyles } from './ProgramDomainBase';

class ProgramDomain extends PureComponent {
    static propTypes = {
        updateText: PropTypes.func.isRequired,
        submit: PropTypes.func.isRequired,
        text: PropTypes.string.isRequired,
        isLoading: PropTypes.bool,
        error: PropTypes.object,
        i18n: PropTypes.object.isRequired,
        findDomainName: PropTypes.func.isRequired
    };

    static defaultProps = {
        isLoading: false,
        error: undefined
    };

    updateText = event => {
        this.props.updateText(event.target.value);
    };

    submit = event => {
        event.preventDefault();
        this.props.submit();
    };

    render() {
        const { i18n } = this.props;
        return (
            <div className={css(layoutStyle.fw)}>
                <form className={css(containerStyle.container)} onSubmit={this.submit} noValidate={true}>
                    <div className={css(containerStyle.header, layoutStyle.textCenter)}>
                        <Logo />
                    </div>
                    <div className={css(containerStyle.main)}>
                        <Core.Input
                            fullWidth={true}
                            id="username"
                            label={i18n.t('programDomainLabel')}
                            value={this.props.text}
                            onChange={this.updateText}
                            helperText={this.props.error}
                            error={!!this.props.error}
                            maxLength={40}
                        />
                        <Core.Button
                            onPress={this.props.findDomainName}
                            type="text"
                            lowercase={true}
                            className={styles.findButton}
                            fullWidth={true}>
                            {i18n.t('findProgramName')}
                        </Core.Button>
                    </div>
                    <div className={css(containerStyle.footerButtonContainer)}>
                        <Core.Button
                            lowercase={true}
                            isSubmit={true}
                            disabled={this.props.isLoading}
                            isLoading={this.props.isLoading}
                            fullWidth={true}>
                            {i18n.t('next')}
                        </Core.Button>
                    </div>
                </form>
            </div>
        );
    }
}

export default withRouter(WithProgramDomainBase(ProgramDomain));

const styles = StyleSheet.create({
    ...baseStyles,
    findButton: {
        ...baseStyles.findButton,
        ...baseStyles.findButtonText
    },
});
