import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import { firebaseConfig } from '../../../../config/buildVariant';
import global from '../../../../config/globals';

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const _tracker = firebase.analytics();

export default class tracker {
    static logEvent(event, params) {
        if (global.dataCollectionDisabled === true) return;
        return _tracker.logEvent(event, params);
    }

    static setCurrentScreen(screenName, screenClassOverride) {}

    static setUserProperties(object) {
        if (global.dataCollectionDisabled === true) return;
        return _tracker.setUserProperties(object);
    }

    static setUserId(userId) {
        if (global.dataCollectionDisabled === true) return;
        return _tracker.setUserId(userId);
    }

    static logError(message) {}
}
