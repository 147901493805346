import { NAME as name } from './constants';
import { createActionTypes } from '../core';

const NAME = name.toUpperCase();

export const GET_EAP = createActionTypes(`${NAME}/GET_EAP`);
export const SET_EAP_OPTIONS = `${NAME}/SET_EAP_OPTIONS`;
export const SET_ONBOARDING_COMPLETE = `${NAME}/SET_ONBOARDING_COMPLETE`;
export const RECORD_STEP_COMPLETED = `${NAME}/RECORD_STEP_COMPLETED`;
export const COMPLETE_APPS_DEVICES_STEP = `${NAME}/COMPLETE_APPS_DEVICES_STEP`;
export const SAVE_EAP = createActionTypes(`${NAME}/SAVE_EAP`);

export const GET_INTEREST_GROUPS = createActionTypes(`${NAME}/GET_INTEREST_GROUPS`);
export const SAVE_INTEREST_GROUPS = createActionTypes(`${NAME}/SAVE_INTEREST_GROUPS`);
export const GET_HEALTH_OBJECTIVES = createActionTypes(`${NAME}/GET_HEALTH_OBJECTIVES`);
export const SAVE_HEALTH_OBJECTIVES = createActionTypes(`${NAME}/SAVE_HEALTH_OBJECTIVES`);
export const GET_HEALTH_TOPICS = createActionTypes(`${NAME}/GET_HEALTH_TOPICS`);
export const SAVE_HEALTH_TOPICS = createActionTypes(`${NAME}/SAVE_HEALTH_TOPICS`);
export const GET_USER_TASKS = createActionTypes(`${NAME}/GET_USER_TASKS`);
export const GET_HRA_SCORES = createActionTypes(`${NAME}/GET_HRA_SCORES`);
export const SET_HEALTH_SURVEY_ANSWERS = createActionTypes(`${NAME}/SET_HEALTH_SURVEY_ANSWERS`);
export const SET_USER_TASK_COMPLETE = createActionTypes(`${NAME}/SET_USER_TASK_COMPLETE`);

export const GET_HEALTH_SURVEY = createActionTypes(`${NAME}/GET_HEALTH_SURVEY`);
export const SAVE_HEALTH_SURVEY = createActionTypes(`${NAME}/SAVE_HEALTH_SURVEY`);
export const GET_HEALTH_SURVEY_RESULTS = createActionTypes(`${NAME}/GET_HEALTH_SURVEY_RESULTS`);
export const GET_HEALTH_SURVEY_STATUS = createActionTypes(`${NAME}/GET_HEALTH_SURVEY_STATUS`);

export const SET_VITAL_STATS = createActionTypes(`${NAME}/SET_VITAL_STATS`);
export const START_SHOWING_ONBOARDING = `${NAME}/START_SHOWING_ONBOARDING`;
export const CLEAR_SHOWING_ONBOARDING = `${NAME}/CLEAR_SHOWING_ONBOARDING`;

export const START_REFRESH_HRA_SCORE = `${NAME}/START_REFRESH_HRA_SCORE`;
export const CLEAR_REFRESH_HRA_SCORE = `${NAME}/CLEAR_REFRESH_HRA_SCORE`;
export const SET_AFTER_ONBOARDING_PATH = `${NAME}/SET_AFTER_ONBOARDING_PATH`;
export const TOGGLE_SKIPPABLE_ONBOARDING_TASKS = `${NAME}/TOGGLE_SKIPPABLE_ONBOARDING_TASKS`;
export const GET_HEALTH_SURVEY_RESULTS_STATUS = createActionTypes(`${NAME}/GET_HEALTH_SURVEY_RESULTS_STATUS`);

export const SET_START_TIME = `${NAME}/SET_START_TIME`;
