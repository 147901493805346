import _ from 'lodash';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { appFonts, baseColors, spacing } from '../../../../styles';
import { translate, constants as coreConstants, parsers } from '../../../core';
import { TOUCHABLE_TYPES, TRANSACTION_TYPES } from '../../constants';

export const IMAGE_SIZE = 40;

export default function WithRewardTouchableItemBase(WrappedComponent) {
    class RewardTouchableItemBase extends Component {
        static propTypes = {
            item: PropTypes.object.isRequired,
            onPressSingle: PropTypes.func,
            type: PropTypes.string.isRequired,
            i18n: PropTypes.object.isRequired,
        };

        static defaultProps = {
            onPressSingle: null
        };

        onDetailsPress = () => {
            if (this.props.onPressSingle) {
                this.props.onPressSingle(this.item);
            }
        };

        get item() {
            return this.props.item;
        }

        get date() {
            return moment.unix(this.item.timestampIssued || this.item.timestampCreated).format(coreConstants.DATE_FORMATS.dayFullMonthFullYear);
        }

        get transactionPoints() {
            const points = _.get(this.item, 'points', 0);
            return (points > 0 ? '+' : '') + points;
        }

        get levelProgressValue() {
            const percentageCompletion = this.item.percentageCompletion * 100;

            if (percentageCompletion > 100) {
                return 100;
            }
            return percentageCompletion;
        }

        get imageUrl() {
            if (this.props.type === TOUCHABLE_TYPES.LEVEL) {
                return this.item.iconUrl;
            } else if (this.props.type === TOUCHABLE_TYPES.ORDER) {
                return this.item.smallImageUrl;
            } else if (_.has(this.item, 'imageUrl')) {
                return this.item.imageUrl;
            }
            return undefined;
        }

        get itemDescription() {
            return parsers.fixAmpersand(this.item.description);
        }

        get showChallengeIcon() {
            const type = _.get(this.item, 'type');
            return type === TRANSACTION_TYPES.CHALLENGE || type === TRANSACTION_TYPES.GOAL;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    onDetailsPress={this.onDetailsPress}
                    date={this.date}
                    transactionPoints={this.transactionPoints}
                    levelProgressValue={this.levelProgressValue}
                    imageUrl={this.imageUrl}
                    itemDescription={this.itemDescription}
                    showChallengeIcon={this.showChallengeIcon}
                />
            );
        }
    }

    return translate()(RewardTouchableItemBase);
}

const ICON_SIZE = 40;

export const styles = {
    innerContainer: {
        flexDirection: 'row'
    },
    itemContainer: {
        flex: 1,
        flexDirection: 'row'
    },
    name: {
        ...appFonts.lgMedium
    },
    additionalInfo: {
        color: baseColors.grey40,
        ...appFonts.xsRegular
    },
    content: {
        flex: 1
    },
    asideInfo: {
        marginLeft: spacing.s3
    },
    levelProgressContainer: {
        flex: 3
    },
    progress: {
        marginTop: spacing.s0
    },
    asideRewardsPoints: {
        color: baseColors.primary,
        textAlign: 'right',
        ...appFonts.lgMedium
    },
    asideRewardsName: {
        color: baseColors.primary,
        textAlign: 'right',
        ...appFonts.xsRegular
    },
    item: {
        paddingTop: spacing.s3,
        paddingBottom: spacing.s3,
    },
    coinsIconBg: {
        height: ICON_SIZE,
        width: ICON_SIZE,
        borderRadius: ICON_SIZE / 2,
        backgroundColor: baseColors.warnLightest,
    },
};
