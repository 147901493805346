import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { css } from 'aphrodite-jss';
import { layoutStyle } from '../../../../styles';

const Title = React.memo(({ title, titleClassName }) => {
    return (
        <h2 className={classnames(css(layoutStyle.wordBreak), titleClassName)}>{title}</h2>
    );
});

Title.propTypes = {
    title: PropTypes.string,
    titleClassName: PropTypes.string,
};

Title.defaultProps = {
    title: '',
    titleClassName: null,
};

export default Title;