import * as types from './actionTypes';
import * as api from './api';
import { actions as coreActions } from '../core';

export function finishTour(type) {
    return function (dispatch) {
        dispatch({ type: types.FINISH_TOUR.SUCCESS, payload: { type } });
    };
}

export function finishProductTour(parameters = {}) {
    return function (dispatch) {
        dispatch({ type: types.FINISH_PRODUCT_TOUR.REQUEST });
        return api.completeProductTour(parameters)
            .then(res => {
                dispatch({
                    type: types.FINISH_PRODUCT_TOUR.SUCCESS, payload: { data: res.data }
                });
                dispatch(coreActions.retrieveCurrentUserInfo());
            })
            .catch(error => dispatch({ type: types.FINISH_PRODUCT_TOUR.ERROR, payload: { error } }));
    };
}
