import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';

import { spacing, importantClass, layoutStyle } from '../../../../styles';
import WithAppsDevicesBase, { styles as baseStyles } from './AppsDevicesBase';
import { components as Core } from '../../../core';
import AppsDevicesList from '../AppsDevicesList';
import { DEVICE_CONNECTION, SUCCESSFUL_CONNECTION_STATUS } from '../../constants';

class AppsDevices extends PureComponent {
    static propTypes = {
        isOnboarding: PropTypes.bool,
        onTabPress: PropTypes.func.isRequired,
        onNext: PropTypes.func,
        isLoading: PropTypes.bool.isRequired,
        isConnecting: PropTypes.bool.isRequired,
        description: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        buttonLabel: PropTypes.string.isRequired,
        queryParams: PropTypes.object,
        tagLabel: PropTypes.string.isRequired,
        arePointsHidden: PropTypes.bool
    };

    static defaultProps = {
        isOnboarding: false,
        onNext: null,
        queryParams: {},
        arePointsHidden: false
    };

    componentDidMount() {
        const { status } = this.props.queryParams;
        if (status && window.opener) {
            window.opener.postMessage(status === SUCCESSFUL_CONNECTION_STATUS
                ? DEVICE_CONNECTION.SUCCESS
                : DEVICE_CONNECTION.ERROR, '*'
            );
            window.close();
        }
    }

    onTabsChange = value => {
        this.props.onTabPress(value);
    };

    get afterTabsComponent() {
        return <AppsDevicesList isOnboarding={this.props.isOnboarding} />;
    }

    render() {
        const { isOnboarding, title, tagLabel, isLoading, buttonLabel, isConnecting, onNext,
            description, arePointsHidden } = this.props;
        return (
            <Core.Layout.PageWrapper isModal={isOnboarding}>
                <div className={css(layoutStyle.flexColumn)}>
                    {!isOnboarding ?
                        <React.Fragment>
                            <Core.EntityDetailsHeader
                                hasBackButton={true}
                                title={title}
                            />
                            <Core.SubHeader
                                title={title}
                                noTopPadding={true}
                                className={css(styles.subHeaderOverriden)}
                                titleAlign='center'
                            />
                        </React.Fragment>
                        : null
                    }
                    <div className={css(styles.descriptionText)}>
                        {description}
                    </div>
                    {arePointsHidden ? null :
                        <div className={css(styles.tagWrapper)}>
                            <Core.Tag label={tagLabel}></Core.Tag>
                        </div>
                    }
                </div>
                {isLoading ? <Core.ListLoading key="Loading" /> : this.afterTabsComponent }
                {isOnboarding ?
                    <div className={css(layoutStyle.flexJustifyCenter, styles.buttonWrapper)}>
                        <Core.Button
                            id="nextBtnOnAppsDevices"
                            className={css(layoutStyle.halfWidth)}
                            onPress={onNext}>
                            {buttonLabel}
                        </Core.Button>
                    </div> : null
                }
                <Core.BlockingLoading isLoading={isConnecting} />
            </Core.Layout.PageWrapper>
        );
    }
}

const BUTTON_BOTTOM_OFFSET = 36;

const styles = StyleSheet.create({
    ...baseStyles,
    descriptionText: {
        ...baseStyles.descriptionText,
        textAlign: 'center',
        marginLeft: '25%',
        marginRight: '25%',
        marginTop: 0
    },
    subHeaderOverriden: importantClass({
        paddingBottom: 0
    }),
    tagWrapper: {
        marginTop: spacing.s6,
        marginBottom: spacing.s1
    },
    buttonWrapper: {
        marginBottom: BUTTON_BOTTOM_OFFSET
    }
});

export default WithAppsDevicesBase(AppsDevices);
