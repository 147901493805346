import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import I18n from '../../../../i18n';
import { languageHelper, Platform, PLATFORMS, selectors as coreSelectors } from '../../../core';
import * as selectors from '../../selectors';

export default function WithLogoBase(WrappedComponent) {
    const LogoBase = ({ isSaved, isPrimary, company: { logoUrl, logoUrlDefault, secondaryLogoUrl }, isLiveBetter, isTelus, isTelusMobileWelcome, isWebWelcome, hasCompany, colorLogo, isAuth, ...props }) => {
        // todo: download and save correct primary logo

        const platformLogo = () => {
            const logoCondition = Platform.OS === PLATFORMS.web && !colorLogo;

            if ((!hasCompany || isSaved) && isLiveBetter) {
                return logoCondition
                    ? require('../../../../image/medibank-logo-white.png')
                    : require('../../../../image/medibank-logo.png');
            }

            if (isTelus) {
                if (isWebWelcome) {
                    return languageHelper.isFrenchLanguage(I18n) ? require('../../../../image/welcomePageTelus/web/THWS-logo-french.png') : require('../../../../image/welcomePageTelus/web/THWS-logo-english.png');
                }

                const telusLogoEnglish = logoCondition
                    ? require('../../../../image/telus-logo-white.png')
                    : require('../../../../image/telus-logo.png');

                const telusLogoFrench = logoCondition
                    ? require('../../../../image/telus-logo-white-french.png')
                    : require('../../../../image/telus-logo-french.png');

                return languageHelper.isFrenchLanguage(I18n) ? telusLogoFrench : telusLogoEnglish;
            }

            return require('../../../../image/sprout-logo.png');
        };

        const getSavedPrimaryLogo = useCallback(() => platformLogo(), []);

        const getSavedLogo = useCallback(() => platformLogo(), []);

        const src = useMemo(() => {
            const logoLink = languageHelper.isEnglishLanguage(I18n) ? logoUrl : logoUrlDefault;
            if (isPrimary && !isAuth && !isTelusMobileWelcome) return logoLink ? { uri: logoLink } : getSavedPrimaryLogo();
            return secondaryLogoUrl && !isSaved && !isTelusMobileWelcome ? { uri: secondaryLogoUrl } : getSavedLogo();
        });

        return <WrappedComponent {...props} src={src} isAuth={isAuth} />;
    };

    LogoBase.propTypes = {
        isSaved: PropTypes.bool,
        isPrimary: PropTypes.bool,
        isLiveBetter: PropTypes.bool,
        isTelus: PropTypes.bool,
        hasCompany: PropTypes.bool,
        colorLogo: PropTypes.bool,
        company: PropTypes.object,
        isAuth: PropTypes.bool,
    };

    LogoBase.defaultProps = {
        isSaved: false,
        isPrimary: false,
        isLiveBetter: false,
        isTelus: false,
        hasCompany: false,
        colorLogo: false,
        company: {},
        isAuth: false
    };

    const mapStateToProps = state => ({
        isLiveBetter: coreSelectors.isLiveBetter(state),
        isTelus: coreSelectors.isTelus(state),
        company: selectors.getCompany(state),
        hasCompany: selectors.hasCompany(state)
    });

    return connect(mapStateToProps)(LogoBase);
}
