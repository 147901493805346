import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { appFonts, baseColors, spacing } from '../../../../styles';
import { translate, pointsHelper, selectors as coreSelectors } from '../../../core';
import * as selectors from '../../selectors';
import * as actions from '../../actions';

export default function WithCompetitionActivityHistoryDetailsBase(WrappedComponent) {
    class CompetitionActivityHistoryDetailsBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            challenge: PropTypes.object.isRequired,
            activity: PropTypes.object.isRequired,
            customPointsUnit: PropTypes.string.isRequired,
            challengeId: PropTypes.object.isRequired,
        };

        constructor(props) {
            super(props);
            props.actions.getChallenge(props.challengeId);
        }

        componentDidUpdate(prevProps, prevState, snapshot) {
            if(!prevProps.challenge && this.props.challenge) {
                this.props.actions.getActivityLogsExtended(this.props.challenge.startDate, this.props.challenge.challengeDeadline);
            }
        }

        get points() {
            return this.props.activity && pointsHelper.formatPoints(_.get(this.props.activity, 'points', 0), this.props.customPointsUnit);
        }

        get logs() {
            return _.get(this.props.activity, 'logs', []);
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    points={this.points}
                    logs={this.logs}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const challengeId = ownProps.challengeId || _.get(ownProps, 'match.params.challengeId') || _.get(ownProps, 'route.params.challengeId');
        const date = ownProps.date || _.get(ownProps, 'location.state.date') || _.get(ownProps, 'route.params.date');
        return {
            isLoading: selectors.isLoadingActivityLogsExtended(state) || selectors.isLoadingChallengeInfo(state, challengeId),
            challenge: selectors.getChallenge(state, challengeId),
            activity: selectors.getActivityLogsForChallengeByDayForDate(state, challengeId, date),
            customPointsUnit: coreSelectors.getCustomPointsUnit(state),
            challengeId
        };
    };

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(CompetitionActivityHistoryDetailsBase));
}

export const styles = {
    headingContainer: {
        marginTop: spacing.s9,
        marginBottom: spacing.s9,
    },
    noActivitiesTrackedText: {
        ...appFonts.smMedium,
        textAlign: 'center',
        color: baseColors.grey40,
        marginVertical: spacing.s5
    },
    largeHeadingText: {
        ...appFonts.xxxxlBold
    },
    smallHeadingText: {
        ...appFonts.mdRegular,
        color: baseColors.grey40
    },

    empty: {
        ...appFonts.mdMedium,
        marginHorizontal: spacing.s3,
        textAlign: 'center'
    }
};