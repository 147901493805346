import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { appFonts, baseColors, spacing } from '../../../../styles';
import { translate, selectors as coreSelectors, color, BRAND_COLOR, numbers } from '../../../core';
import { getChallenge, isBonusChallenge } from '../../selectors';
import { selectors as rewardsSelectors } from '../../../rewards';
import { isPersonal, isBonusRecommendedGoal } from '../../services/helper';

export default function WithBonusChallengeMarkBase(WrappedComponent) {
    class BonusChallengeMarkBase extends PureComponent {
        static propTypes = {
            challenge: PropTypes.object.isRequired,
            isBonusChallenge: PropTypes.number.isRequired,
            i18n: PropTypes.object.isRequired,
            pointsName: PropTypes.string,
            pointsUnit: PropTypes.string,
            currencyName: PropTypes.string,
            isSmallerSize: PropTypes.bool,
            isChallengeDetailsPage: PropTypes.bool,
            isChallengeCard: PropTypes.bool,
            showDoubleBonusTag: PropTypes.bool,
        };

        static defaultProps = {
            pointsName: '',
            pointsUnit: '',
            currencyName: '',
            isSmallerSize: false,
            isChallengeDetailsPage: false,
            isChallengeCard: false,
            showDoubleBonusTag: false,
        };

        getInfoModalProps = () => {
            const { i18n, currencyName, pointsName, challenge } = this.props;
            let textName = 'bonusChallengeInfo.withReward';
            let amount;
            let points;
            if (this.potentialBonusCurrency) {
                amount = this.potentialBonusCurrency;
                points = currencyName;
            } else if (this.potentialBonusPoints) {
                amount = this.potentialBonusPoints;
                points = pointsName;
            } else {
                textName = 'bonusChallengeInfo.noReward';
            }
            const challengeType = _.toLower(i18n.t(isPersonal(challenge) ? 'goal' : 'challenge'));
            const commonText = i18n.t(textName, { amount, points, challengeType });
            const commonBoldText = amount && points ? i18n.t('bonusChallengeInfo.boldText', { amount, points }) : null;
            let bonusGoalTextKey = '';
            const keyBase = 'bonusGoal.additionalInfo';
            if (this.potentialBonusPoints) bonusGoalTextKey = `${keyBase}`;
            const bonusGoalText = this.showBonusGoalText && this.potentialBonusPoints ? i18n.t(bonusGoalTextKey, { points }) : null;
            const bonusGoalBoldText = points ? i18n.t('bonusGoal.boldText', { points }) : null;
            let htmlText = `${commonText.replace(commonBoldText, `<strong>${commonBoldText}</strong>`)}`;
            if (bonusGoalText) htmlText += `\n\n${bonusGoalText.replace(bonusGoalBoldText, `<strong>${bonusGoalBoldText}</strong>`)}`;
            return {
                htmlText,
                iconName: 'question',
                isButtonVisible: false,
                buttonTitle: i18n.t('got_it'),
            };
        };

        get showBonusGoalText() {
            const { challenge } = this.props;
            return ((isPersonal(challenge) && this.canBeTrackedWithAppOrDevice) || this.hasDeviceBonus);
        }

        get potentialBonusCurrency() {
            return _.get(this.props.challenge, 'potentialBonusCurrency');
        }

        get potentialBonusPoints() {
            const { challenge: { potentialBonusPoints }, showDoubleBonusTag } = this.props;
            return showDoubleBonusTag ? potentialBonusPoints * 2 : potentialBonusPoints;
        }

        get text() {
            const { i18n, pointsUnit, currencyName, challenge, isChallengeCard } = this.props;

            if (this.potentialBonusCurrency) {
                return `+ ${numbers.numberWithCommas(this.potentialBonusCurrency)} ${currencyName}`;
            } else if (this.potentialBonusPoints) {
                const bonusPointsText = `+ ${numbers.numberWithCommas(this.potentialBonusPoints)} ${pointsUnit}`;
                return isChallengeCard ? bonusPointsText : `${bonusPointsText} ${i18n.t('bonus').toLowerCase()}`;
            } else if (challenge.goalName) {
                return i18n.t('bonusGoal.markTitle');
            }
            return i18n.t('bonusChallenge');
        }

        get iconSize() {
            return this.props.isSmallerSize ? 10 : 14;
        }

        get deviceBonusText() {
            return this.props.i18n.t('deviceBonus');
        }

        get canBeTrackedWithAppOrDevice() {
            return !!this.props.challenge.recommendedTrackingApps.length;
        }

        get hasDeviceBonus() {
            const { isChallengeDetailsPage, isBonusChallenge } = this.props;
            return isBonusChallenge
                && isChallengeDetailsPage
                && !!this.potentialBonusPoints
                && !this.potentialBonusCurrency
                && this.canBeTrackedWithAppOrDevice;
        }


        render() {
            if (!this.props.isBonusChallenge && !this.hasDeviceBonus) return null;
            return (
                <WrappedComponent
                    {...this.props}
                    text={this.text}
                    deviceBonusText={this.deviceBonusText}
                    getInfoModalProps={this.getInfoModalProps}
                    iconSize={this.iconSize}
                    hasDeviceBonus={this.hasDeviceBonus}
                    potentialBonusCurrency={this.potentialBonusCurrency}
                />
            );
        }
    }
    const mapStateToProps = (state, ownProps) => ({
        pointsUnit: coreSelectors.getCustomPointsUnit(state),
        pointsName: coreSelectors.getCustomPointsName(state),
        currencyName: rewardsSelectors.externalRewardName(state),
        challenge: ownProps.challengeId ? getChallenge(state, ownProps.challengeId) : ownProps.goal,
        isBonusChallenge: ownProps.challengeId
            ? isBonusChallenge(state, ownProps.challengeId)
            : isBonusRecommendedGoal(_.get(ownProps, 'goal')),
    });

    return connect(mapStateToProps)(translate()(BonusChallengeMarkBase));
}

export const INFO_ICON_SIZE = 16;
export const TIMES = 2;
export const TIMES_ICON = 'times';
export const TIMES_ICON_SIZE = spacing.s1;
export const WATCH_ICON = 'watch-fitness';
export const WATCH_ICON_SIZE = 14;

export const styles = {
    content: {
        paddingLeft: spacing.s1,
        paddingRight: spacing.s1,
        paddingTop: spacing.s0,
        paddingBottom: spacing.s0,
        borderRadius: spacing.s0,
    },
    generalBg: {
        backgroundColor: color.brandColorMaker(baseColors.primary, BRAND_COLOR.LIGHT_SHADE),
    },
    rewardsBonusBg: {
        backgroundColor: baseColors.warnLightest,
    },
    generalColor: {
        color: color.brandColorMaker(baseColors.primary, BRAND_COLOR.DARK_SHADE),
    },
    rewardsBonusColor: {
        color: baseColors.warnDarkest,
    },
    smFont: {
        ...appFonts.smMedium,
    },
    xsFont: {
        ...appFonts.xsMedium,
    },
    coinsIcon: {
        marginRight: spacing.s1,
    },
    coinsIconSmaller: {
        marginRight: spacing.s0,
    },
    deviceBonusColor: {
        color: baseColors.contextDarkest,
    },
    deviceBonusBg: {
        backgroundColor: baseColors.contextLightest,
    },
    mRight: {
        marginRight: spacing.s1,
    },
    deviceBonusIcon: {
        marginRight: 2,
    },
    timesIcon: {
        marginRight: spacing.s0,
    },
};
