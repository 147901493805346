import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { layoutStyle } from '../../../../styles';
import { components as Core, constants as coreConstants } from '../../../core';
import WithCommentEditBase, { styles as baseStyles } from './CommentEditBase';

class CommentEdit extends PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        close: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        editComment: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        text: PropTypes.string
    };

    static defaultProps = {
        disabled: false,
        text: ''
    };

    goBack = () => this.props.close();

    editComment = () => {
        if (this.props.disabled) return;
        this.props.editComment();
        this.goBack();
    };

    onChange = event => {
        this.props.onChange(event.target.value);
    };

    render() {
        const { i18n, text, disabled } = this.props;
        return (
            <div className={css(layoutStyle.fhFlex)}>
                <Core.SubHeader
                    title={i18n.t('edit')}
                    right={<Core.CloseButton onClick={this.goBack} />}
                />
                <div>
                    <Core.Input
                        rows={coreConstants.MULTIPLE_INPUT_ROWS}
                        className={css(styles.input)}
                        autoFocus={true}
                        onChange={this.onChange}
                        multiline={true}
                        value={text}
                        placeholder={i18n.t('simplePlaceholder')}
                        InputProps={{ disableUnderline: true }}
                        fullWidth={true}
                    />
                </div>
                <div className={css(styles.footer)}>
                    <Core.Button
                        disabled={disabled}
                        onPress={this.editComment}
                        type="text">
                        <span className={css(styles.footerButton, disabled && styles.disabled)}>
                            {i18n.t('postCommentButton')}
                        </span>
                    </Core.Button>
                </div>
            </div>
        );
    }
}

export default WithCommentEditBase(CommentEdit);

const styles = StyleSheet.create({
    ...baseStyles,
    footer: {
        ...baseStyles.footer,
        display: 'flex'
    },
});
