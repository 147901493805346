import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { css, StyleSheet } from 'aphrodite-jss';
import classNames from 'classnames';
import { components as Core } from '../../../core';
import WithInvitationBase, { styles as baseStyles } from './InvitationBase';
import { importantClass } from '../../../../styles';

class Invitation extends PureComponent {
    static propTypes = {
        invitedBy: PropTypes.object.isRequired,
        containerClassName: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
        imageURl: PropTypes.string,
        userName: PropTypes.string,
        invitationMessage: PropTypes.string
    };

    static defaultProps = {
        containerClassName: {},
        imageURl: undefined,
        userName: undefined,
        invitationMessage: undefined
    };

    get item() {
        return this.props.invitedBy;
    }

    render() {
        return (
            <div className={classNames(css(styles.mainContainer), this.props.containerClassName)}>
                <Core.Avatar
                    url={this.props.imageURl}
                    name={this.props.userName}
                    size="xlsmall"
                    id={_.get(this.item, 'id')}
                />
                <Core.TextSeeMore
                    className={css(styles.textItem)}
                    numberOfLines={2}
                    isCollapseHidden={true}
                    isExpandHidden={true}>
                    {this.props.invitationMessage}
                </Core.TextSeeMore>
            </div>
        );
    }
}

const styles = StyleSheet.create({
    ...baseStyles,
    textItem: importantClass({
        ...baseStyles.textItem,
        marginRight: 0
    }),
    mainContainer: {
        ...baseStyles.mainContainer,
        display: 'flex'
    }
});

export default WithInvitationBase(Invitation);
