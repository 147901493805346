import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { baseColors } from '../../../../styles';
import { components as Core } from '../../../core';
import WithBookmarkBase, { ICON_SIZE } from './BookmarkBase';

class Bookmark extends PureComponent {
    static propTypes = {
        isBookmarked: PropTypes.bool.isRequired,
        toggleBookmark: PropTypes.func.isRequired,
    };

    toggleBookmark = e => {
        e.stopPropagation();
        this.props.toggleBookmark();
    };

    render() {
        const { isBookmarked } = this.props;
        return (
            isBookmarked ?
                <Core.Icon
                    name="bookmark"
                    type="fa"
                    fill='solid'
                    size={ICON_SIZE}
                    color={baseColors.secondary}
                    onClick={this.toggleBookmark}
                /> : null
        );
    }
}

export default WithBookmarkBase(Bookmark);