import spacing from './spacing';
import baseColors from './commonColor';
import { windowSize } from './constants';

const modalStyle = {
    screenContainer: {
        flex: 1,
        alignSelf: 'center',
        width: windowSize.width,
        justifyContent: 'center',
        paddingHorizontal: spacing.s3,
        backgroundColor: baseColors.transparent,
    },
    bodyContainerNoPadding: {
        backgroundColor: baseColors.white,
        marginHorizontal: spacing.s1,
        borderRadius: spacing.s1,
        shadowRadius: spacing.s2,
        shadowOpacity: 0.3,
        shadowOffset: { width: 0, height: 0 },
        shadowColor: '#000',
        elevation: 5
    },
    bodyContainer: {
        backgroundColor: baseColors.white,
        marginHorizontal: spacing.s1,
        padding: spacing.s3,
        borderRadius: spacing.s1,
        shadowRadius: spacing.s2,
        shadowOpacity: 0.3,
        shadowOffset: { width: 0, height: 0 },
        shadowColor: '#000',
        elevation: 5
    },
    containerPadding: {
        padding: spacing.s3
    },
    bottomButton: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomRightRadius: spacing.s1,
        borderBottomLeftRadius: spacing.s1,
    },
};

export default modalStyle;
