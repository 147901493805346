import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';
import Tooltip from '@material-ui/core/Tooltip';

import classnames from 'classnames';
import WithImageLabel, { styles as baseStyles } from './ImageLabelBase';
import { baseColors, layoutStyle, spacing, appFonts } from '../../../../styles';
import Icon from '../Icon';

export const MAX_LABEL_COMPANY_LENGTH = 30;

class ImageWithLabel extends PureComponent {
    static propTypes = {
        isCarouselItem: PropTypes.bool,
        labelTitle: PropTypes.string.isRequired,
        imageSize: PropTypes.object.isRequired,
        labelBackgroundColor: PropTypes.string,
        labelIconName: PropTypes.string,
        source: PropTypes.object.isRequired,
        className: PropTypes.object,
        labelText: PropTypes.string.isRequired,
        hasRoundBorder: PropTypes.bool,
        alt: PropTypes.string,
    };

    static defaultProps = {
        isCarouselItem: true,
        className: null,
        hasRoundBorder: false,
        labelBackgroundColor: baseColors.corporateImageLabel,
        labelIconName: 'briefcase',
        alt: ''
    };

    //todo make this function works
    // calculate the maximum allowed width of the label text
    // get width of the label(we have ref to it this.label) - 2 padding (16px * 2) - iconSize - labeText width - 3 dots width
    get maxLabelCompanyLength() {
        return  this.label && this.label.offsetWidth - 3 * spacing.s3 - 2 * spacing.s6 - spacing.s1;
    }

    labelTitleTruncate = text => (
        <div className={css(layoutStyle.flexRowCenter)}>
            <Tooltip
                disableTouchListener
                placement='top'
                title={<span className={css(styles.tooltipText)}>{text}</span>}
            >
                <span>{text.substr(0, MAX_LABEL_COMPANY_LENGTH)}... {this.props.labelText}</span>
            </Tooltip>
        </div>);

    get renderLabelSpanSection() {
        const { isCarouselItem, labelTitle, labelText } = this.props;
        return isCarouselItem ? (
            <div className={css(layoutStyle.flexRow)}>
                <span className={css(styles.companyNameTitle, styles.truncate, styles.commonLabelTextFontSize)}>
                    {this.truncateText ? this.labelTitleTruncate(labelTitle) : `${labelTitle} ${labelText}`}
                </span>
            </div>) : null;
    }

    get truncateText() {
        return this.props.labelTitle > MAX_LABEL_COMPANY_LENGTH;
    }

    saveRef = ref => (this.label = ref);

    render() {
        const { labelBackgroundColor, labelIconName, isCarouselItem, contentClassName } = this.props;
        const positionOfLabel = isCarouselItem ? layoutStyle.flexJustifyEnd : layoutStyle.flexJustifyCenter;
        const borderRadius = this.props.hasRoundBorder ? styles.exploreBorderRadius : null;
        const labelBorderRadius = this.props.hasRoundBorder ? styles.labelBottomRadius : null;
        return (
            <div className={classnames(css(styles.contentWrapper, contentClassName))} style={this.props.imageSize}>
                <img
                    className={css(...this.props.className, styles.image, borderRadius)}
                    src={this.props.source}
                    style={this.props.imageSize}
                    alt={this.props.alt}
                />
                <div
                    ref={this.saveRef}
                    style={labelBackgroundColor}
                    className={css(styles.labelContainer, layoutStyle.flexAlignCenter, layoutStyle.flexRow, labelBorderRadius, positionOfLabel)}
                >
                    {
                        labelIconName ? <Icon
                            name={labelIconName}
                            type='light'
                            size={spacing.s3}
                            color={baseColors.white}
                        /> : null
                    }

                    {this.renderLabelSpanSection}
                </div>
            </div>
        );
    }
}

export default WithImageLabel(ImageWithLabel);


const styles = StyleSheet.create({
    ...baseStyles,
    exploreBorderRadius: {
        borderRadius: spacing.s1
    },
    contentWrapper: {
        position: 'relative'
    },
    image: {
        flex: 1,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    labelBottomRadius: {
        borderBottomRightRadius: spacing.s1,
        borderBottomLeftRadius: spacing.s1,
    },
    tooltipText: {
        ...appFonts.mdRegular
    },
});
