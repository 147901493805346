import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import classnames from 'classnames';
import WithBonusChallengeMarkBase, { styles as baseStyles, INFO_ICON_SIZE, TIMES, TIMES_ICON,
    TIMES_ICON_SIZE, WATCH_ICON, WATCH_ICON_SIZE } from './BonusChallengeMarkBase';
import { baseColors, layoutStyle, spacing } from '../../../../styles';
import { components as Core, Modal } from '../../../core';

class BonusChallengeMark extends PureComponent {
    static propTypes = {
        potentialBonusCurrency: PropTypes.number.isRequired,
        text: PropTypes.string.isRequired,
        iconSize: PropTypes.number.isRequired,
        deviceBonusText: PropTypes.string.isRequired,
        getInfoModalProps: PropTypes.func.isRequired,
        containerClassName: PropTypes.string,
        shouldShowMoreInfo: PropTypes.bool,
        isSmallerSize: PropTypes.bool,
        hasDeviceBonus: PropTypes.bool,
        isBonusChallenge: PropTypes.number.isRequired,
        deviceBonusClassName: PropTypes.string,
        isAppsDevicesSection: PropTypes.bool,
    };

    static defaultProps = {
        isSmallerSize: false,
        containerClassName: null,
        shouldShowMoreInfo: false,
        hasDeviceBonus: false,
        deviceBonusClassName: null,
        isAppsDevicesSection: false
    };

    showMoreInfo = event => {
        event.stopPropagation();
        const infoModalProps = this.props.getInfoModalProps();
        this.closeInfoModal = Modal.open(
            Core.InfoModal,
            {
                ...infoModalProps,
                buttons: [{ text: infoModalProps.buttonTitle, onPress: () => this.closeInfoModal() }],
                textStyle: styles.additionalInfo,
                iconClassName: css(styles.iconClassName),
            },
            {
                isContainer: true,
                isTransparent: true,
                isNoPadding: true,
                fadeTransition: true,
                isMaxWidthLimited: true,
            }
        );
    }

    get coinsIcon() {
        const { iconSize, isSmallerSize } = this.props;
        return (
            <Core.Icon
                type="light"
                name="coins"
                size={iconSize}
                color={baseColors.warn}
                className={css(isSmallerSize ? styles.coinsIconSmaller : styles.coinsIcon)}
            />
        );
    }

    get infoIcon() {
        return (
            <Core.IconButton
                onClick={this.showMoreInfo}
                isSmall={true}
                name="question-circle"
                size={INFO_ICON_SIZE}
                type="fa"
                fill="solid"
                color={baseColors.grey50}
            />
        );
    }

    get deviceBonusMark() {
        const { deviceBonusClassName, deviceBonusText } = this.props;
        return (
            <div className={classnames(css(layoutStyle.flexRow), deviceBonusClassName)}>
                <Core.Icon
                    type="fa"
                    fill="regular"
                    name={WATCH_ICON}
                    size={WATCH_ICON_SIZE}
                    color={baseColors.contextDarkest}
                    className={css(styles.deviceBonusIcon, styles.deviceIcon)}
                />
                <p>{TIMES}</p>
                <Core.Icon
                    type="fa"
                    fill="solid"
                    name={TIMES_ICON}
                    size={TIMES_ICON_SIZE}
                    color={baseColors.contextDarkest}
                    className={css(styles.timesIcon, styles.icon)}
                />
                <p>{deviceBonusText}</p>
            </div>
        );
    }

    render() {
        const { potentialBonusCurrency, text, isSmallerSize, containerClassName,
            shouldShowMoreInfo, hasDeviceBonus, isBonusChallenge, isAppsDevicesSection } = this.props;
        const colorScheme = potentialBonusCurrency
            ? css(styles.rewardsBonusColor, styles.rewardsBonusBg)
            : css(styles.generalColor, styles.generalBg);

        return (
            <div className={classnames(css(layoutStyle.flexRow), containerClassName)}>
                {isBonusChallenge && !isAppsDevicesSection ?
                    (
                        <div className={classnames(
                            css(styles.content, isSmallerSize ? styles.xsFont : styles.smFont, styles.mRight),
                            colorScheme)}>
                            {potentialBonusCurrency ? this.coinsIcon : null}
                            {text}
                        </div>
                    ) : null
                }
                {hasDeviceBonus &&
                    (
                        <div className={css(styles.content, isSmallerSize ? styles.xsFont : styles.smFont,
                            styles.deviceBonusColor, styles.deviceBonusBg)}>
                            {this.deviceBonusMark}
                        </div>
                    )
                }
                {shouldShowMoreInfo && this.infoIcon}
            </div>
        );
    }
}

export default WithBonusChallengeMarkBase(BonusChallengeMark);

const styles = StyleSheet.create({
    ...baseStyles,
    additionalInfo: {
        marginTop: spacing.s3,
        color: baseColors.grey20,
        marginBottom: spacing.s7,
    },
    iconClassName: {
        backgroundColor: baseColors.primary
    },
    icon: {
        marginTop: spacing.s0 + 1
    },
    deviceIcon: {
        paddingTop: 2,
    }
});