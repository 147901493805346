import { persistReducer } from 'redux-persist';
import * as types from './actionTypes';
import { NAME } from './constants';
import { actionTypes as coreActionTypes, Storage, tracker } from '../core';

const initialState = {
    items: {},
    ids: [],
    numResources: 0
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_RESOURCE_LINKS.SUCCESS: {
            const { items, ids, isReplace, count } = action.payload;
            return {
                ...state,
                items: isReplace ? items : { ...state.items, ...items },
                ids: isReplace ? ids : [...state.ids, ...ids],
                numResources: count
            };
        }
        case coreActionTypes.RESET: {
            return initialState;
        }
        default:
            return state;
    }
};

const serialize = data => {
    try {
        return JSON.stringify(data);
    } catch (err) {
        const message = 'Failed to serialize resources data';
        const serializeError = JSON.stringify({
            message,
            err
        });
        tracker.logError(serializeError);
        return JSON.stringify({});
    }
};

const persistConfig = {
    key: NAME,
    storage: Storage.storageType(),
    serialize
};

export default persistReducer(persistConfig, reducer);