import React from 'react';
import PropTypes from 'prop-types';
import ReactAutolinker from 'react-autolinker';
import { StyleSheet, css } from 'aphrodite-jss';
import { baseColors } from '../../../../styles';
import SafeA from '../SafeA';

const styles = StyleSheet.create({
    autoLinker: {
        color: baseColors.secondary
    }
});

const defaultOptions = { truncate: 30, className: css(styles.autoLinker), twitter: false };

const renderLink = link => (
    <SafeA className={css(styles.autoLinker)} href={link.attrs.href}>
        {link.innerHtml}
    </SafeA>
);

const AutoLink = ({ options: opts, renderLink, ...props }) => {
    const options = { ...defaultOptions, ...opts };
    return <ReactAutolinker {...props} renderLink={renderLink} options={options} />;
};
AutoLink.propTypes = { options: PropTypes.object, renderLink: PropTypes.func };
AutoLink.defaultProps = { options: defaultOptions, renderLink };

export default AutoLink;
