import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { translate } from '../../../../core';
import { spacing } from '../../../../../styles';
import { CATEGORIES, DEFAULT_ICON_CATEGORY } from '../../../constants';

export default function WithWellbeingIconBase(WrappedComponent) {
    class WellbeingIconBase extends PureComponent {
        static propTypes = {
            slug: PropTypes.string.isRequired,
            size: PropTypes.number,
            className: PropTypes.string
        };

        static defaultProps = {
            size: spacing.s11,
            className: null
        };

        get category() {
            return CATEGORIES[this.props.slug] || DEFAULT_ICON_CATEGORY;
        }

        get iconSize() {
            return this.props.size * 0.6;
        }

        get containerStyles() {
            return {
                backgroundColor: this.category.bgr,
                height: this.props.size,
                width: this.props.size
            };
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    containerStyles={this.containerStyles}
                    iconSize={this.iconSize}
                    category={this.category}
                />
            );
        }
    }

    return translate()(WellbeingIconBase);
}

const BORDER_RADIUS = 90;

export const styles = {
    healthScoreIconContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: BORDER_RADIUS
    }
};