import React, { PureComponent } from 'react';
import { appFonts, baseColors, spacing } from '../../../../styles';
import { translate } from '../../services';

export const ICON_SIZE = spacing.s7;

export default function WithDisclaimerSectionBase(WrappedComponent) {
    class DisclaimerSectionBase extends PureComponent {
        render() {
            return (
                <WrappedComponent {...this.props} />
            );
        }
    }
    return translate()(DisclaimerSectionBase);
}

export const styles = {
    main: {
        flex: 1,
        backgroundColor: baseColors.grey40
    },
    mainLight: {
        backgroundColor: baseColors.white,
    },
    innerContainer: {
        flexDirection: 'row',
        paddingTop: spacing.s2,
        paddingBottom: spacing.s2,
        paddingRight: spacing.s2,
        paddingLeft: spacing.s2,
        display: 'flex'
    },
    text: {
        color: baseColors.white
    },
    title: {
        ...appFonts.mdMedium,
        lineHeight: appFonts.xlBold.lineHeight
    },
    message: {
        ...appFonts.smRegular
    },
    icon: {
        marginRight: spacing.s3
    },
    linkAction: {
        marginTop: spacing.s2
    },
    linkText: {
        ...appFonts.mdMedium
    },
    innerContainerLight: {
        display: 'flex',
        flexDirection: 'row',
        padding: spacing.s3,
        borderRadius: spacing.s1,
        backgroundColor: baseColors.contextLightest,
    },
    messageLight: {
        ...appFonts.mdRegular,
        paddingTop: spacing.s0,
        color: baseColors.contextDarkest,
    },
    iconLight: {
        color: baseColors.contextDarkest,
    },
};
