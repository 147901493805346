import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { translate } from '../../../core';
import * as selectors from '../../selectors';
import * as actions from '../../actions';

export default function WithAppsDevicesPreviewBase(WrappedComponent) {
    class AppsDevicesPreviewBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            numDevices: PropTypes.number.isRequired,
            syncStatus: PropTypes.string.isRequired,
            i18n: PropTypes.object.isRequired
        };

        constructor(props) {
            super(props);
        }

        get numConnected() {
            const { numDevices, i18n } = this.props;
            if (numDevices) return i18n.t('num_connected', { numDevices });
            return '';
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    numConnected={this.numConnected}
                />
            );
        }
    }

    const mapStateToProps = state => ({
        numDevices: selectors.getNumberOfConnectedDevices(state),
        syncStatus: selectors.getSyncStatus(state)
    });

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(AppsDevicesPreviewBase));
}