/* eslint-disable camelcase */
import _ from 'lodash';
import moment from 'moment';
import global from '../../../../config/globals';
import { DATE_FORMATS } from '../../../core';

export default class PromoterScoreService {
    static enableService = false;
    static configure({ user, isWootricEnabled, isMonthOrMoreOnPlatform }) {
        PromoterScoreService.enableService = user && isWootricEnabled && isMonthOrMoreOnPlatform;
        PromoterScoreService.enableService = PromoterScoreService.enableService && (global.systemEnvironment.environment === 'live') && global.dataCollectionDisabled === false;

        if (PromoterScoreService.enableService) {
            const userDate = _.get(user, 'dateRegistered', 0);
            const date_registered = moment.unix(userDate).format(DATE_FORMATS.fullSlash);

            const accountToken = 'NPS-4162260f';
            const externalId = _.get(user, 'userIdExternal', String(_.get(user, 'userId', '')));

            const role = _.get(user, 'role', 'user');
            const company = _.get(user, 'company.name', '');
            const companyId = _.get(user, 'companyId', '');
            const partner = _.get(user, 'partnerName', 'Sprout');
            const lastLoginDate = _.get(user, 'lastLogin', 0);

            const setupScript = document.createElement('script');
            setupScript.type = 'text/javascript';
            setupScript.async = true;
            setupScript.innerHTML = `
                window.wootricSettings = {
                    account_token: "${accountToken}",
                    properties: {
                        role: "${role}",
                        company: "${company}",
                        company_id: "${companyId}",
                        partner: "${partner}", 
                        last_login_date: "${lastLoginDate}",
                        client: 'web',
                        date_registered: "${date_registered}",
                        user_id: "${externalId}"
                    }
                };
            `;
            document.body.appendChild(setupScript);
        }
    }

    static enableSurvey() {
        if (PromoterScoreService.enableService) {
            // Beacon
            const beacon = document.createElement('script');
            beacon.type = 'text/javascript';
            beacon.async = true;

            beacon.src = 'https://cdn.wootric.com/wootric-sdk.js';
            beacon.onload = function () {
                window.wootric('run');
            };
            document.body.appendChild(beacon);
        }
    }

    static disableSurvey() {
        PromoterScoreService.enableService = false;
    }
}
