import _ from 'lodash';
import ReactPlayer from 'react-player';
import moment from 'moment';
import { CATEGORIES, CATEGORY_TYPES_API_PARAM, TYPES } from '../constants';
import i18n from '../../../i18n';

export function getRequestParams(type, category, offset, maxCount, search, isTagFilter) {
    return _.assign(getTypeParams(type, category, search, isTagFilter), getLimitParams(offset, maxCount));
}

export function getTypeParams(type, category, search, isTagFilter = false) {
    const params = {};
    const categoryType = isTagFilter ? CATEGORY_TYPES_API_PARAM.TAG : CATEGORY_TYPES_API_PARAM.CATEGORY;
    const typeApiParam = isTagFilter ? CATEGORY_TYPES_API_PARAM.TYPE : '';
    const setCorrectType = type === TYPES.VIDEO ? 'video_url' : type;
    switch (category) {
        case CATEGORIES.ALL:
            type && _.assign(params, { filters: type });
            break;
        case CATEGORIES.RECOMMENDED:
            _.assign(params, { filters: `${category}` });
            break;
        case CATEGORIES.VIDEO:
            _.assign(params, { filters: 'type_video_url' });
            break;
        case CATEGORIES.BOOKMARKS:
            _.assign(params, { filters: 'bookmarked' });
            break;
        case CATEGORIES.SEARCH: {
            if (type === TYPES.VIDEO) {
                _.assign(params, { filters: 'type_video_url' });
            }
            else if (type === TYPES.MORE) {
                _.assign(params, { clientExternalOnly: 1 });
            }
            else {
                _.assign(params, { filters: `${type}` });
            }
            break;
        }
        default:
            _.assign(params, type ? { filters: `${categoryType}${category},${typeApiParam}${setCorrectType}` } : { filters: `${categoryType}${category}` });
            break;
    }
    return search ? { ...params, search } : params;
}

function getLimitParams(offset, maxCount = 20) {
    return { offset, maxCount };
}

export const isVideo = url => ReactPlayer.canPlay(url);

export const isVideoMP4 = metadata => _.get(metadata, 'hls');

export const isFeatured = content => {
    const { promoted, promote_start, promote_end } = content;

    const formatDate = unixDate => unixDate ? moment.unix(unixDate)._d : moment()._d;

    const todayDate = formatDate();
    const itemStartDate = formatDate(promote_start);
    const itemEndDate = formatDate(promote_end);

    return !!(+promoted && todayDate > itemStartDate && todayDate < itemEndDate);
};

export const itemIsCMSContent = item => _.get(item, 'external_id', '').length > 0;

export const secondsIntoTime = duration => {
    if (!duration || duration === '0') return null;
    const sec = +duration;
    const hours = Math.floor(sec / 3600) || null;
    const minutes = Math.floor((sec - (hours * 3600)) / 60);
    let seconds = sec - (hours * 3600) - (minutes * 60);
    if (seconds < 10) seconds = `0${seconds}`;
    return `${hours ? `${hours}:` : ''}${minutes}:${seconds}`;
};

export function getContentEmptyListParams(category, search) {

    const results = {
        search: { iconName: 'search', message: i18n.t('searchEmpty') },
        all: { iconName: 'question', message: i18n.t('emptyListDefaultMessage') },
        recommended: { iconName: 'user', message: i18n.t('nothing_to_show') },
        bookmarks: { iconName: 'bookmark', message: i18n.t('no_saved_content') },
    };

    return search ? results.search : results[category];
}