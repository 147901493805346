import React from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';
import classnames from 'classnames';
import { styles as baseStyles } from './HeaderBase';


export default function CreateEntityHeader({ className, title, subtitle }) {
    return (
        <div className={classnames(css(styles.header, className))}>
            <p className={css(styles.title)}>{title}</p>
            {subtitle ? <p className={css(styles.subtitle)}>{subtitle}</p> : null}
        </div>
    );
}
CreateEntityHeader.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    className: PropTypes.string
};
CreateEntityHeader.defaultProps = {
    subtitle: undefined,
    className: null
};

const styles = StyleSheet.create(baseStyles);
