import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
    usersLoading,
    getUsers,
    getUsersCount,
    invitationSending,
    isSendingPlatformInvitation
} from '../../selectors';

import * as actions from '../../actions';
import { translate, SEARCH_INPUT_DELAY, selectors as coreSelectors } from '../../../core';
import { selectors as authSelectors, actions as authActions } from '../../../auth';
import { appFonts, baseColors, spacing } from '../../../../styles';
import { actions as communityActions, selectors as communitySelectors } from '../../../communities';
import { actions as eventsActions, selectors as eventSelectors } from '../../../events';
import { actions as challengesActions, selectors as challengesSelectors } from '../../../challenges';
import { ENTITIES, TYPES } from '../../constants';
import { initInvitedMembers } from '../../services';

const SEARCH_USERS = 'searchUsers';
const GET_USERS = 'gettingUsers';

export default function WithInvitationsListBase(WrappedComponent) {
    class InvitationsListBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
                .isRequired,
            isInviteByEmailShown: PropTypes.bool,
            isLoading: PropTypes.bool,
            items: PropTypes.array,
            invitationSending: PropTypes.bool,
            isMembersPage: PropTypes.bool,
            entity: PropTypes.string,
            usersCount: PropTypes.number,
            isSearching: PropTypes.bool,
            callback: PropTypes.func,
            isRetail: PropTypes.bool,
            isNotificationShown: PropTypes.bool,
            company: PropTypes.object,
            programNameRestrictionHidden: PropTypes.bool,
            type: PropTypes.string,
            companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            toggleMembers: PropTypes.func,
            isUserInvitation: PropTypes.bool
        };

        static defaultProps = {
            isInviteByEmailShown: false,
            isLoading: false,
            items: [],
            invitationSending: false,
            isMembersPage: false,
            usersCount: 0,
            isSearching: false,
            entity: '',
            callback: null,
            isRetail: false,
            company: {},
            programNameRestrictionHidden: false,
            isNotificationShown: true,
            type: TYPES.USERS,
            toggleMembers: null,
            isUserInvitation: true
        };

        static PAGE = {
            MEMBERS: 'MEMBERS',
            EMAIL: 'EMAIL'
        };

        constructor(props) {
            super(props);
            this._doSearch = _.debounce(
                this._doSearch.bind(this),
                props.isUserInvitation ? SEARCH_INPUT_DELAY : 0
            );
            this.state = {
                page: InvitationsListBase.PAGE.MEMBERS,
                searchMemberValue: '',
                invitedMembers: [],
                emailsArray: [],
                emailFieldEditable: false,
                emailValue: '',
                emailTextMessage: props.i18n.t('invitationMessage'),
                isTyping: false,
                filteredItems: []
            };
            switch (props.type) {
                case TYPES.DEPARTMENTS: props.actions.getDepartmentsList(props.companyId);
                    break;
                case TYPES.REGIONS: props.actions.getRegions(props.companyId);
                    break;
                default: props.actions.getUsersToInvite();
            }
        }

        componentDidUpdate(prevProps, prevState) {
            if (this.props.toggleMembers && this.state.invitedMembers.length !== prevState.invitedMembers.length) {
                this.props.toggleMembers(this.state.invitedMembers);
            }
        }

        static getDerivedStateFromProps(nextProps, prevState) {
            if (_.isEmpty(prevState.invitedMembers) && !_.isEmpty(nextProps.invitedMembers)) {
                return { invitedMembers: initInvitedMembers(nextProps.invitedMembers, nextProps.items) };
            }
            return null;
        }

        get isRegionOrDepartment() {
            return this.props.type === TYPES.DEPARTMENTS || this.props.type === TYPES.REGIONS;
        }

        get isMembersPage() {
            return this.state.page === InvitationsListBase.PAGE.MEMBERS;
        }

        get customNavBarButtonsRight() {
            let rightButtons;
            if (this.isMembersPage) {
                rightButtons = {
                    title: this.props.callback ? this.props.i18n.t('next') : this.props.i18n.t('invitation.send'),
                    id: 'inviteByMembers',
                    action: this.onInviteByMembers
                };
            } else {
                rightButtons = {
                    title: this.props.i18n.t('inviteByMembers'),
                    id: 'selectInviteByMembers',
                    action: this.onSelectInviteByMembers
                };
            }
            return rightButtons;
        }

        get customNavBarButtonsLeft() {
            return {
                title: this.props.i18n.t('button_cancel'),
                id: 'backButton'
            };
        }

        onSelectInviteByMembers = () => {
            this.setState(() => ({
                page: InvitationsListBase.PAGE.MEMBERS,
                emailsArray: [],
                invitedMembers: []
            }));
        };

        onInviteByMembers = () => {
            const { invitedMembers, emailsArray } = this.state;
            if (!this.allInvites.length) return;
            const invitedMemberIds = _.compact(
                _.map(invitedMembers, u => u.id)
            );
            const emails = _.compact(
                _.map(emailsArray, u => u.inviteeEmail)
            );
            if (invitedMemberIds.length || emails.length) {
                this.sendInvitation(invitedMemberIds, emails);
            }
        };

        onToggleMember = (user, selected) => {
            this.setState(prevState => {
                if (user.inviteeEmail) {
                    return ({
                        emailsArray: selected
                            ? _.filter(
                                prevState.emailsArray,
                                item => item.inviteeEmail !== user.inviteeEmail
                            )
                            : [...prevState.emailsArray, user]
                    });
                }
                return ({
                    invitedMembers: selected
                        ? _.filter(
                            prevState.invitedMembers,
                            item => item.id !== user.id
                        )
                        : [...prevState.invitedMembers, user]
                });
            });
        };

        sendInvitation = (membersIds, emails) => {
            this.props.actions.inviteToEntity(
                this.props.entity,
                this.props.entityId,
                membersIds,
                emails
            );
        };

        get isInviteByEmailShown() {
            return this.props.entity !== ENTITIES.TEAM && this.props.isInviteByEmailShown;
        }

        onInviteByEmail = emailsArray => {
            this.setState({ emailsArray });
        };

        loadMoreUsers = () => {
            if (!this.props.isLoading && this.hasMore) {
                this.props.actions.getUsersToInvite({
                    offset: this.props.items.length,
                    search: this.state.searchMemberValue
                });
            }
        };

        get allInvites() {
            return [
                ...this.state.invitedMembers,
                ...this.state.emailsArray
            ];
        }

        searchByName = value => {
            this.setState({ searchMemberValue: value, isTyping: true });
            this._doSearch();
        };

        searchUserByEmail = value => {
            this.setState({ emailValue: value, isTyping: true });
        };

        clearEmailInput = () => {
            this.setState({ emailValue: '' });
        };

        _doSearch = () => {
            this.setState(() => ({ isTyping: false }));
            const { searchMemberValue } = this.state;
            const { items, actions } = this.props;
            if(this.isRegionOrDepartment) {
                const filteredItems = _.filter(items, item => _.includes(item.label.toLowerCase(), searchMemberValue.toLowerCase()));
                this.setState({ filteredItems });
            } else {
                actions.getUsersToInvite({ search: searchMemberValue.trim() }, SEARCH_USERS);
            }
        };

        clear = () => {
            this.setState({ searchMemberValue: '' });
            this._doSearch();
        };

        get items() {
            return this.state.filteredItems.length ? this.state.filteredItems : this.props.items;
        }

        get hasMore() {
            return this.items.length < this.props.usersCount;
        }

        hideProgramNameRestriction = () => {
            this.setState({ programNameRestrictionHidden: true });
            switch (this.props.entity) {
                case ENTITIES.COMMUNITY:
                    this.props.actions.hideCommunityNotification(
                        this.props.entityId
                    );
                    break;
                case ENTITIES.EVENT:
                    this.props.actions.hideEventNotification(
                        this.props.entityId
                    );
                    break;
                case ENTITIES.CHALLENGE:
                    this.props.actions.hideChallengeNotification(
                        this.props.entityId
                    );
                    break;
                default:
                    break;
            }
        };

        get emptyListUsersMessage() {
            return this.props.i18n.t('no_users_found');
        }

        get searchOthersText() {
            return `${this.props.i18n.t('invitation.searchForOthersToInvite')}. ${this.props.i18n.t('invitation.whoYouAreLookingFor')} `;
        }

        get inviteByEmailButtonLabel() {
            return this.props.i18n.t('inviteByEmail');
        }

        get searchPlaceHolder() {
            return this.props.i18n.t('search');
        }

        get invitationSendingMessage() {
            return this.props.i18n.t('invitationSendingMessage');
        }

        get propramNameTitle() {
            return this.props.i18n.t('invitation.programNameOnly.title', { programName: this.props.company.name });
        }

        get programNameDescription() {
            return this.props.i18n.t('invitation.programNameOnly.description', {
                entityType: _.get(this.props, 'entity', '').toLowerCase(), programName: this.props.company.name
            });
        }

        get dismissButtonLabel() {
            return this.props.i18n.t('button_dismiss');
        }

        get inviteByEmailText() {
            return this.props.i18n.t('invitation.whoYouAreLookingFor');
        }

        get isProgramRestrictionShown() {
            return this.props.isRetail && this.props.isNotificationShown && !this.props.programNameRestrictionHidden;
        }

        get isUserInvitation() { return this.props.type === TYPES.USERS; }

        get title() {
            const { entity, i18n } = this.props;
            switch (entity) {
                case ENTITIES.TEAM:
                    return i18n.t('invite_to_team');
                case ENTITIES.CHALLENGE:
                    return i18n.t('createChallenge.additional.inviteTitle');
                case ENTITIES.EVENT:
                    return i18n.t('inviteToEvent');
                case ENTITIES.COMMUNITY:
                default:
                    return i18n.t('inviteToCommunity');
            }
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    isInviteByEmailShown={this.isInviteByEmailShown}
                    customNavBarButtonsRight={this.customNavBarButtonsRight}
                    customNavBarButtonsLeft={this.customNavBarButtonsLeft}
                    title={this.title}
                    onToggleMember={this.onToggleMember}
                    isMembersPage={this.isMembersPage}
                    searchMemberValue={this.state.searchMemberValue}
                    searchByName={this.searchByName}
                    invitedMembers={this.state.invitedMembers}
                    allInvites={this.allInvites}
                    emailsArray={this.state.emailsArray}
                    emailFieldEditable={this.state.emailFieldEditable}
                    clear={this.clear}
                    searchUserByEmail={this.searchUserByEmail}
                    emailValue={this.state.emailValue}
                    emailTextMessage={this.state.emailTextMessage}
                    loadMoreUsers={this.loadMoreUsers}
                    clearEmailInput={this.clearEmailInput}
                    isTyping={this.state.isTyping}
                    onInviteByEmail={this.onInviteByEmail}
                    programNameRestrictionHidden={
                        this.state.programNameRestrictionHidden
                    }
                    hideProgramNameRestriction={this.hideProgramNameRestriction}
                    emptyListUsersMessage={this.emptyListUsersMessage}
                    searchOthersText={this.searchOthersText}
                    inviteByEmailButtonLabel={this.inviteByEmailButtonLabel}
                    searchPlaceHolder={this.searchPlaceHolder}
                    invitationSendingMessage={this.invitationSendingMessage}
                    propramNameTitle={this.propramNameTitle}
                    programNameDescription={this.programNameDescription}
                    dismissButtonLabel={this.dismissButtonLabel}
                    inviteByEmailText={this.inviteByEmailText}
                    isProgramRestrictionShown={this.isProgramRestrictionShown}
                    isUserInvitation={this.isUserInvitation}
                    items={this.items}
                    hasMore={this.hasMore}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        const callback = ownProps.callback || _.get(ownProps, 'route.params.callback.callback');
        const isInviteByEmailShown = _.get(ownProps, 'route.params.callback.isInviteByEmailShown');
        const entityId = ownProps.entityId || _.get(ownProps, 'match.params.entityId') || _.get(ownProps, 'route.params.entityId');
        let items = [];
        let isLoading = false;
        let isNotificationShown;
        const entity = ownProps.entity || _.get(ownProps, 'match.params.entity') || _.get(ownProps, 'route.params.entity');
        switch (ownProps.type) {
            case TYPES.DEPARTMENTS:
                items = authSelectors.getDepartmentsForSelect(state);
                isLoading = authSelectors.isLoadingDepartments(state);
                break;
            case TYPES.REGIONS:
                items = authSelectors.getRegionsForSelect(state);
                isLoading = authSelectors.isLoadingRegions(state);
                break;
            default:
                items = getUsers(state);
                isLoading = usersLoading(state, GET_USERS);
        }
        switch (entity) {
            case ENTITIES.TEAM:
            case ENTITIES.CHALLENGE:
                isNotificationShown = challengesSelectors.isNotificationShown(state, entityId);
                break;
            case ENTITIES.COMMUNITY:
                isNotificationShown = communitySelectors.isNotificationShown(state, entityId);
                break;
            case ENTITIES.EVENT:
                isNotificationShown = eventSelectors.isNotificationShown(state, entityId);
                break;
            default:
                isNotificationShown = ownProps.isNotificationShown;
        }
        return {
            items,
            usersCount: getUsersCount(state),
            isLoading,
            callback,
            isInviteByEmailShown,
            isSearching: usersLoading(state, SEARCH_USERS),
            invitationSending:
                invitationSending(state) ||
                isSendingPlatformInvitation(state, entityId),
            entityId,
            entity,
            company: authSelectors.getCompany(state),
            isRetail: authSelectors.isRetail(state),
            isNotificationShown,
            companyId: coreSelectors.getCurrentUser(state).companyId
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(
                {
                    ...communityActions,
                    ...eventsActions,
                    ...challengesActions,
                    ...authActions,
                    ...actions
                },
                dispatch
            )
        };
    }
    return connect(
        mapStateToProps,
        mapDispatchToProps
    )(translate()(InvitationsListBase));
}

export const styles = {
    mainContainer: {
        backgroundColor: baseColors.white
    },
    container: {
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3
    },
    emailTextMessage: {
        ...appFonts.lgRegular
    },
    searchTitle: {
        marginBottom: spacing.s3
    },
    invitationLink: {
        color: baseColors.secondary,
        ...appFonts.smRegular
    },
    invitationLabel: {
        marginTop: spacing.s3,
        color: baseColors.grey40,
        ...appFonts.smRegular
    },
    indicator: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: spacing.s3
    },
    emptyContainer: {
        alignItems: 'center',
        padding: spacing.s3
    },
    isProgramNameOnly: {
        backgroundColor: baseColors.grey85,
        margin: spacing.s3,
        padding: spacing.s3,
        borderRadius: spacing.s1
    },
    actionSearchByEmail: {
        marginTop: spacing.s1,
        ...appFonts.smRegular
    },
    inviteByEmailWrapper: {
        marginTop: spacing.s10
    },
    offsetTop: {
        marginTop: spacing.s3
    },
    programNameTitle: {
        marginTop: spacing.s1,
        ...appFonts.mdMedium
    },
    programNameDesc: {
        marginTop: spacing.s0,
        ...appFonts.smRegular
    },
    dismissButton: {
        height: spacing.s9,
        marginTop: spacing.s5,
        paddingLeft: spacing.s5,
        paddingRight: spacing.s5
    },
    inviteByEmailButton: {
        margin: spacing.s3
    },
    buttonContainer: {
        paddingTop: spacing.s2,
        paddingBottom: 0,
    },
    inviteByEmailTitle: { ...appFonts.mdRegular }
};
