import React, { PureComponent } from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import WithTeamChallengeActionSheetBase, { styles as baseStyles } from './TeamChallengeActionSheetBase';
import { AsyncComponent, components as Core, Modal, ROUTES } from '../../../core';
import ChallengeParticipants from '../ChallengeDetails/ChallengeParticipants/ChallengeParticipants';
import { baseColors, spacing, appFonts, importantClass } from '../../../../styles';

class TeamChallengeActionSheet extends PureComponent {

    get renderHeader() {
        const { challenge, challengeId, participantsString, duration } = this.props;
        return (
            <div className={css(styles.mainContainer)}>
                <Core.Image
                    id={challengeId}
                    src={challenge.challengeImageURL}
                    className={css(styles.imageHeader)}
                />
                <div className={css(styles.mainWrapper)}>
                    <div className={css(styles.challengeTitle)}>{challenge.challengeName}</div>
                    <div className={css(styles.membersContainer)}>
                        <div className={css(styles.membersText)}>
                            {challenge.numChallengeParticipants > 0 ? (
                                <div className={css(styles.participantsContainer)}>
                                    {_.isEmpty(challenge) ? null : <ChallengeParticipants sizeAvatar="xlsmall" showOnCard={true} challengeId={challengeId} isFeaturedChallenge={true} />}
                                    <div className={css(styles.membersAndDateText)}>
                                        {participantsString}
                                    </div>
                                    <Core.Icon
                                        name="circle"
                                        type="fa"
                                        fill="solid"
                                        className={css(styles.webDot)}
                                        color={baseColors.grey40}
                                        size={spacing.s0}
                                        resizeMode="contain"
                                    />
                                </div>
                            ) : null
                            }
                            <p className={css(styles.duration)}>{duration}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    get renderTeamInvites() {
        const { challengeId, challenge, i18n } = this.props;
        return (
            <div>
                <p className={css(styles.descText)}>{i18n.t('joinTeamActionSheetText')}</p>
                {_.isEmpty(challenge) ? null : <ChallengeParticipants sizeAvatar="xlsmall" showOnCard={true} challengeId={challengeId} returnTeamList={true} isFeaturedChallenge={true} />}
            </div>
        );
    }

    get renderButtons() {
        const { i18n } = this.props;
        return (
            <div className={css(styles.buttonsContainer)}>
                <Core.Button
                    type="outlined"
                    size="large"
                    fullWidth={true}
                    onPress={this.viewAllTeams}
                    className={css(styles.btnLeft)}>
                    <Core.Icon name="search" type="light" size={spacing.s3} color={baseColors.secondary} />
                    <p className={css(styles.btnText)}>{i18n.t('teams.findTeam')}</p>
                </Core.Button>
                <Core.Button
                    type="outlined"
                    size="large"
                    fullWidth={true}
                    onPress={this.viewCreateTeamScreen}>
                    <Core.Icon name="plus" type="light" size={spacing.s3} color={baseColors.secondary} />
                    <p className={css(styles.btnText)}>{i18n.t('create_team')}</p>
                </Core.Button>
            </div>
        );
    }

    viewCreateTeamScreen = () => {
        this.close = Modal.open(AsyncComponent(() => import('../CreateEditTeam')), {
            challengeId: this.props.challengeId,
            isModal: true,
            redirectAfterUpdate: true
        }, {
            cancelable: false,
            isNoPadding: true,
            isContainer: true,
            isFullHeight: true
        });
    };

    viewAllTeams = () => {
        this.props.history.push(ROUTES.joinTeam(this.props.challengeId));
    };

    render() {
        return (
            <div>
                {_.isEmpty(this.props.challenge) ? null :
                    (
                        <>
                            <Core.EntityDetailsHeader hasBackButton={true} />
                            <Core.SmallerContainer>
                                {this.renderHeader}
                                {this.renderTeamInvites}
                                {this.renderButtons}
                            </Core.SmallerContainer>
                        </>
                    )
                }
            </div>
        );
    }
}

const CHALLENGE_IMAGE = 120;
const styles = StyleSheet.create({
    ...baseStyles,
    mainContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: spacing.s8
    },
    mainWrapper: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: spacing.s5,
        justifyContent: 'center'
    },
    imageHeader: importantClass({
        width: CHALLENGE_IMAGE,
        height: CHALLENGE_IMAGE,
        borderRadius: CHALLENGE_IMAGE
    }),
    descText: {
        marginTop: spacing.s5,
        marginBottom: spacing.s5,
        ...appFonts.mdRegular,
        color: baseColors.grey20
    },
    webDot: {
        marginLeft: spacing.s0,
        width: spacing.s0,
        height: spacing.s0
    },
    membersAndDateText: {
        ...baseStyles.membersAndDateText,
        paddingTop: 'auto',
    },
    duration: {
        ...baseStyles.membersAndDateText,
        paddingTop: 'auto',
        marginLeft: spacing.s0
    },
    participantsContainer: {
        display: 'flex',
        flex: 1,
        alignItems: 'center'
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'center',
        marginTop: spacing.s5
    },
    btnLeft: importantClass({
        marginRight: spacing.s3
    }),
    btnText: {
        marginLeft: spacing.s2
    }
});

export default withRouter(WithTeamChallengeActionSheetBase(TeamChallengeActionSheet));
