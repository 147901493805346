import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getLike } from '../../selectors';
import { baseColors, spacing } from '../../../../styles';

export default function WithLikeItemBase(WrappedComponent) {
    class LikeItemBase extends PureComponent {
        static propTypes = {
            likeId: PropTypes.number.isRequired, // eslint-disable-line
            like: PropTypes.object.isRequired,
        };

        get user() {
            const { like: { firstname, lastname, image_url, user_id } } = this.props;
            return {
                firstNameDisplay: firstname,
                lastNameDisplay: lastname,
                avatarURL: image_url,
                userId: user_id
            };
        }

        get subtitile() {
            const { like: { department, location } } = this.props;
            if (department && location) {
                return `${department} | ${location}`;
            }
            if (location) {
                return `${location}`;
            }
            return null;
        }

        render() {
            return <WrappedComponent {...this.props} subtitle={this.subtitile} user={this.user} />;
        }
    }

    function mapStateToProps(state, ownProps) {
        return {
            like: getLike(state, ownProps.likeId)
        };
    }

    return connect(mapStateToProps)(LikeItemBase);
}

export const styles = {
    mainLikeContainer: {
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3,
    },
    userPanel: {
        paddingTop: spacing.s3,
        paddingBottom: spacing.s3,
        borderBottomWidth: 2,
        borderBottomColor: baseColors.grey85
    },
};
