import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { css, StyleSheet } from 'aphrodite-jss';
import Icon from '../Icon';
import Button from '../Button';
import { styles as baseStyles, ICON_SIZE } from './DisclaimerSectionBase';
import { baseColors, importantClass, layoutStyle, spacing } from '../../../../styles';


class DisclaimerSection extends PureComponent {
    static propTypes = {
        title: PropTypes.string,
        message: PropTypes.string.isRequired,
        iconName: PropTypes.string,
        linkAction: PropTypes.func,
        linkMessage: PropTypes.string,
        modalLightDesign: PropTypes.bool,
        lightLinkMessage: PropTypes.node,
    };

    static defaultProps = {
        title: undefined,
        iconName: 'lock-alt',
        linkAction: undefined,
        linkMessage: undefined,
        modalLightDesign: false,
        lightLinkMessage: null,
    };

    render() {
        const { title, message, iconName, linkAction, linkMessage, modalLightDesign, lightLinkMessage } = this.props;
        return (
            <div className={css(styles.main, modalLightDesign && styles.mainLight)}>
                <div className={css(modalLightDesign ? styles.innerContainerLight : styles.innerContainer)}>
                    <Icon
                        className={css(styles.icon, modalLightDesign ? styles.iconLight : null)}
                        name={iconName}
                        type="fa"
                        size={ICON_SIZE}
                        color={baseColors.white}
                    />
                    <div className={css(layoutStyle.flex1)}>
                        {title ? <p className={css(styles.text, styles.title)}>{title}</p> : null }
                        {message ? <p className={modalLightDesign ? css(styles.messageLight) : css(styles.text, styles.message)}>{message}</p> : null}
                        {linkAction && !modalLightDesign ?
                            <Button
                                className={css(styles.linkAction, styles.text, styles.linkText)}
                                type="text"
                                onPress={linkAction}>
                                {linkMessage}
                            </Button> : null}
                        {lightLinkMessage}
                    </div>
                </div>
            </div>
        );
    }
}

const styles = StyleSheet.create({
    ...baseStyles,
    text: importantClass({
        ...baseStyles.text
    }),
    linkAction: importantClass({
        ...baseStyles.linkAction,
        justifyContent: 'flex-start'
    }),
    innerContainerLight: {
        ...baseStyles.innerContainerLight,
        paddingBottom: spacing.s1,
    },
});

export default DisclaimerSection;
