import { StyleSheet } from 'aphrodite-jss';
import { appFonts, importantClass, baseColors } from '../../../styles';

const RIGHT_BUTTON_WIDTH = 64;

export const headerStyles = StyleSheet.create({
    buttonText: importantClass({ ...appFonts.smRegular }),
    headerTitle: importantClass({ ...appFonts.smBold, color: baseColors.white }),
    buttonLeftOffset: {
        width: RIGHT_BUTTON_WIDTH
    }
});