import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import WithEventMembersBase from './EventMembersBase';
import { components as Core } from '../../../core';

class EventMembers extends PureComponent {
    static propTypes = {
        members: PropTypes.array.isRequired,
        isInviter: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        loadMore: PropTypes.func.isRequired,
        getTitle: PropTypes.func.isRequired,
        isPrivate: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired,
        getDescription: PropTypes.func,
    };

    static defaultProps = {
        getDescription: null,
    };

    renderAttendeeItem = (item, onPress) => {
        const { getDescription, isInviter, isPrivate, getTitle } = this.props;
        return (
            <Core.AttendeeItem
                imageUrl={item.imageUrl}
                title={getTitle(item)}
                description={getDescription(item)}
                isInviter={isInviter(item)}
                id={item.id}
                onPress={onPress}
                isPrivate={isPrivate(item)}
            />
        );
    };

    render() {
        return (
            <Core.SmallerContainer
                widerHeaderComponent={(
                    <Core.EntityDetailsHeader
                        hasBackButton={true}
                        title={this.props.title}
                    />)
                }>
                <Core.SubHeader
                    title={this.props.title}
                    noTopPadding={true}
                />
                <Core.AttendeesList
                    members={this.props.members}
                    loadMore={this.props.loadMore}
                    isLoading={this.props.isLoading}
                    renderAttendee={this.renderAttendeeItem}
                />
            </Core.SmallerContainer>
        );
    }
}

export default WithEventMembersBase(EventMembers);
