import _ from 'lodash';
import * as types from './actionTypes';
import { actionTypes as coreTypes, reducerHelper } from '../core';

export const initialState = {
    categories: [],
    items: {},
    refreshTime: 0,
    tags: [],
    isLoadingContentCount: 0
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.GET_CONTENT.SUCCESS:
        case types.SEARCH_CONTENT.SUCCESS: 
        case types.GET_RECOMMENDED_CONTENT.SUCCESS: {
            const { category, items, ids, count, isReplace, type = 'all', isAll, categories } = action.payload;
            let loadingCount = state.isLoadingContentCount - 1;
            if (loadingCount < 0) {
                loadingCount = 0;
            }
            return {
                ...state,
                isLoadingContentCount: loadingCount,
                refreshTime: new Date().getTime(),
                [category]: {
                    ...(state[category] || {}),
                    [type]: {
                        ids: isReplace ? ids : _.uniq([...(_.get(state, [category, type, 'ids']) || []), ...ids]),
                        count
                    }
                },
                items: {
                    ...state.items,
                    ...items
                },
                categories: isAll ? categories : state.categories
            };
        }
        case types.GET_CONTENT_ITEM.SUCCESS: {
            const { item } = action.payload;
            return {
                ...state,
                items: {
                    ...state.items,
                    [item.id]: item
                }
            };
        }
        case types.GET_CONTENT_TAGS.SUCCESS: {
            const tagsWithId = _.map(action.payload, tag => ({ ...tag, id: tag.slug }));
            return {
                ...state,
                tags: tagsWithId
            };
        }
        case types.BOOKMARK_CONTENT.SUCCESS: {
            const { item, value } = action.payload;

            // update bookmarks list
            const existing = _.get(state, 'bookmarks.all.ids') || [];
            const bookmarks = value ? _.uniq([...existing, item.id]) : _.filter(existing, b => b !== item.id);
            const updatedState = reducerHelper.updateDeepProperty(state, ['bookmarks', 'all'], { ids: bookmarks, count: bookmarks.length });

            // update item property
            return reducerHelper.updateDeepProperty(updatedState, 'items', { ...updatedState.items, [item.id]: item });
        }
        case types.INCREMENT_LOADING_COUNT.SUCCESS: {
            const count = state.isLoadingContentCount + 1;
            return {
                ...state,
                isLoadingContentCount: count
            };
        }
        case types.CLEAR_CONTENT.SUCCESS: {
            return initialState;
        }
        case coreTypes.RESET: {
            return initialState;
        }
        default:
            return state;
    }
};
