import React, { PureComponent } from 'react';
import { css, StyleSheet } from 'aphrodite-jss';
import PropTypes from 'prop-types';
import { components as Core } from '../../../core';
import WithContentSearchBase from './ContentSearchBase';

class ContentSearch extends PureComponent {
    static propTypes = {
        search: PropTypes.string.isRequired,
        onSearch: PropTypes.func.isRequired,
        i18n: PropTypes.object.isRequired,
        isLibraryPage: PropTypes.bool
    };

    static defaultProps = {
        isLibraryPage: false
    }

    render() {
        const { i18n, search, onSearch, isLibraryPage, onFocus, onBlur } = this.props;
        return (
            <div className={css(styles.rightHeaderPart)}>
                <Core.Input
                    margin="none"
                    value={search}
                    fullWidth={true}
                    customType="search"
                    onChangeText={onSearch}
                    placeholder={i18n.t('search_placeholder')}
                    isLibraryPage={isLibraryPage}
                    onFocus={onFocus}
                    onBlur={onBlur}
                />
            </div>
        );
    }
}

const styles = StyleSheet.create({
    rightHeaderPart: {
        display: 'flex',
        flexDirection: 'row'
    }
});

export default WithContentSearchBase(ContentSearch);