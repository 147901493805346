import { PureComponent } from 'react';
import PropTypes from 'prop-types';

const DEFAULT_INTERVAL = 5 * 60 * 1000; // 5min

class Polling extends PureComponent {
    static propTypes = {
        callback: PropTypes.func.isRequired,
        interval: PropTypes.number
    };

    static defaultProps = {
        interval: undefined
    };

    componentDidMount() {
        this._interval = setInterval(() => this.props.callback(), this.props.interval || DEFAULT_INTERVAL);
    }

    componentWillUnmount() {
        clearInterval(this._interval);
    }

    render() {
        return null;
    }
}

export default Polling;
