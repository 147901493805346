import React from 'react';
import PropTypes from 'prop-types';
import MUITooltip from '@material-ui/core/Tooltip';

export const Tooltip = React.memo(({ children, title }) =>
    <MUITooltip placement="bottom" title={title}>
        {children}
    </MUITooltip>
);

Tooltip.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired
};

export default Tooltip;