import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';

import ResourceLinkItem from '../ResourceLinkItem';
import { spacing } from '../../../../styles';
import { components as Core } from '../../../core';
import WithResourceLinksListBase from './ResourceLinksListBase';

class ResourceLinksList extends PureComponent {
    static propTypes = {
        items: PropTypes.array.isRequired,
        isLoading: PropTypes.bool,
        hasMore: PropTypes.bool,
        isPage: PropTypes.bool,
        title: PropTypes.string,
        loadMoreContent: PropTypes.func.isRequired,
        emptyListMessage: PropTypes.string
    };

    static defaultProps = {
        title: null,
        hasMore: false,
        isPage: true,
        isLoading: false,
        emptyListMessage: null
    };

    get renderEmptyList() {
        if (!this.props.isLoading && !this.props.items.length) {
            return <Core.EmptyListSimple message={this.props.emptyListMessage} />;
        }
        return null;
    }

    renderItem = ({ item }) => (
        <div className={css(styles.itemPadding)}>
            <ResourceLinkItem item={item} />
        </div>
    );

    get headerComponent() {
        const { title, isPage } = this.props;
        return isPage ?
            <React.Fragment>
                <Core.EntityDetailsHeader hasBackButton={true} />
                {title ?
                    <Core.SubHeader
                        title={title}
                        noTopPadding={true}
                        className={css(styles.titleBar)}
                    /> : null
                }
            </React.Fragment>
            : null;
    }

    keyExtractor = item => item.id;

    get wrapper() {
        return this.props.isPage ? Core.SmallerContainer : Core.Layout.WiderContainer;
    }

    render() {
        return (
            <this.wrapper>
                <Core.InfiniteLazyListFadeIn
                    data={this.props.items}
                    keyExtractor={this.keyExtractor}
                    renderItem={this.renderItem}
                    onEndReached={this.props.loadMoreContent}
                    onEndReachedThreshold={0.5}
                    hasMore={this.props.hasMore}
                    isLoading={this.props.isLoading}
                    ListHeaderComponent={this.headerComponent}
                    ListEmptyComponent={this.renderEmptyList}
                    isHorizontal={false}
                />
            </this.wrapper>
        );
    }
}

const styles = StyleSheet.create({
    titleBar: {
        paddingTop: spacing.s3
    },
    itemPadding: {
        paddingTop: spacing.s2,
        paddingBottom: spacing.s2
    }
});

export default WithResourceLinksListBase(ResourceLinksList);