import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';

import { translate } from '../../services';
import { appFonts, baseColors, spacing } from '../../../../styles';

export default function WithDeletionWarningBase(WrappedComponent) {
    class DeletionWarningBase extends PureComponent {
        static propTypes = {
            onConfirm: PropTypes.func.isRequired,
            onClose: PropTypes.func
        };

        static defaultProps = {
            onClose: undefined
        };

        constructor(props) {
            super(props);
            this.state = { isChecked: false };
        }

        onCheckbox = () => this.setState(oldState => ({ isChecked: !oldState.isChecked }));

        onConfirm = () => {
            this.props.onConfirm();
            this.props.onClose && this.props.onClose();
        };

        get isChecked() {
            return this.state.isChecked;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    isChecked={this.isChecked}
                    onCheckbox={this.onCheckbox}
                    onConfirm={this.onConfirm}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const routeParams = _.get(ownProps, 'route.params');
        return { ...(routeParams || {}) };
    };

    return connect(mapStateToProps)(translate()(DeletionWarningBase));
}

export const styles = {
    description: {
        ...appFonts.mdRegular,
        marginBottom: spacing.s2
    },
    bodyContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: spacing.s4,
        paddingBottom: 0
    },
    buttonsContainer: {
        padding: spacing.s3,
        borderTopColor: baseColors.grey70,
        borderTopWidth: 1,
        justifyContent: 'flex-end'
    },
    title: {
        color: baseColors.black,
        padding: spacing.s2
    },
    cancelButton: {
        borderColor: baseColors.grey70,
        color: baseColors.grey40,
        marginRight: spacing.s2
    },
    removeButtonActive: {
        backgroundColor: baseColors.dangerDarker
    },
    checkboxLabel: {
        color: baseColors.dangerDarker,
        ...appFonts.smRegular
    }
};
