import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { translate, selectors as coreSelectors } from '../../../core';
import * as contentSelectors from '../../selectors';
import * as contentConstants from '../../constants';
import * as contentActions from '../../actions';

export default function WithContentOnExploreBase(WrappedComponent) {
    class ContentOnExploreBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            content: PropTypes.array,
            actions: PropTypes.object.isRequired,
            isLiveBetter: PropTypes.bool.isRequired
        };

        static defaultProps = {
            content: []
        };

        constructor(props) {
            super(props);
            if (!this.props.isLiveBetter) {
                this.props.actions.getRecommendedContent(contentConstants.CATEGORIES.ALL, contentConstants.TYPES.RECOMMENDED, {}, true);
            }
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                />
            );
        }
    }

    function mapStateToProps(state) {
        const isLiveBetter = coreSelectors.isLiveBetter(state);
        const content = isLiveBetter ? contentSelectors.getAllContent(state) : contentSelectors.getRecommendedContent(state);
        return {
            content,
            isLiveBetter,
            isLoading: contentSelectors.isLoadingContent(state),
            contentOnFeeds: contentSelectors.getPromotedContent(state)[contentConstants.CATEGORIES.ALL]
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(contentActions, dispatch)
        };
    }


    return connect(mapStateToProps, mapDispatchToProps)(translate()(ContentOnExploreBase));
}