import { baseColors, spacing, appFonts } from '../../../../styles';

const BORDER_RADIUS = spacing.s2;

export const styles = {
    container: {
        backgroundColor: baseColors.grey85,
        borderRadius: BORDER_RADIUS,
        paddingTop: spacing.s0,
        paddingBottom: spacing.s0,
        paddingLeft: spacing.s1,
        paddingRight: spacing.s1,
        marginBottom: spacing.s0,
        marginRight: spacing.s0,
    },
    text: {
        ...appFonts.xsBold,
        color: baseColors.grey20,
    },
    icon: {
        marginLeft: spacing.s1
    }
};
