import axios from 'axios';
import { getBaseUrl, getAppVersion } from './services';
import global from '../../config/globals';

const getLoginUrl = endpoint => endpoint
    ? `${global.systemEnvironment.SPROUT_LOGIN_URL}${endpoint}`
    : global.systemEnvironment.SPROUT_LOGIN_URL;

export function getFilters() {
    return axios.get(getBaseUrl('filters'));
}

export function retrieveCurrentUserInfo() {
    return axios.get(getBaseUrl('auth/current_user'));
}

export function getUrlPreview(text) {
    return axios.post(getBaseUrl('stream_items/preview'), { text });
}

export function logMessage(message) {
    return axios.post(getLoginUrl('client_logs'), { message });
}

export function checkAppVersion() {
    return axios.get(getLoginUrl('check_version'), { params: { currentAppVersion: getAppVersion() } });
}
