import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import getFilterName from '../../services/getFilterName';
import { baseColors, spacing, appFonts } from '../../../../styles/commonCSS';
import { selectors as coreSelectors, translate } from '../../../core';
import { getFilter, isLoadingStreams, getDisableStreamsWeights, getStreamFiltersForSelect } from '../../selectors';
import * as actions from '../../actions';

export default function WithStreamFilterBase(WrappedComponent) {
    class StreamFilterBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            appliedFilter: PropTypes.object,
            user: PropTypes.object.isRequired,
            filters: PropTypes.array.isRequired,
            isLoading: PropTypes.bool,
            filtersByTimeAdded: PropTypes.bool,
            isLiveBetter: PropTypes.bool
        };

        static defaultProps = {
            appliedFilter: {},
            isLoading: false,
            filtersByTimeAdded: false,
            isLiveBetter: false
        };

        constructor(props) {
            super(props);
            props.actions.getFilter();
            props.actions.getDisableStreamsWeights();
        }

        get filterText() {
            const { appliedFilter, user, filtersByTimeAdded, appliedFilterSecondary, translateOptions, i18n, isLiveBetter } = this.props;
            const filterText = !filtersByTimeAdded ? getFilterName(appliedFilter, user, isLiveBetter) : _.get(appliedFilterSecondary, 'label');
            return translateOptions ? i18n.t(filterText) : filterText;
        }

        onChange = filter => {
            const { actions: { setFilter, setDisableStreamsWeights }, filtersByTimeAdded } = this.props;
            !filtersByTimeAdded ? setFilter(filter) : setDisableStreamsWeights(filter);
        }

        onChangeFirstFilter = filter => {
            const { actions: { setFilter } } = this.props;
            return setFilter(filter);
        };

        onChangeSecondFilter = filter => {
            const { actions: { setDisableStreamsWeights } } = this.props;
            return setDisableStreamsWeights(filter);
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    filterText={this.filterText}
                    onChange={this.onChange}
                    onChangeFirstFilter={this.onChangeFirstFilter}
                    onChangeSecondFilter={this.onChangeSecondFilter}
                />
            );
        }
    }

    function mapStateToProps(state) {
        return {
            user: coreSelectors.getCurrentUser(state),
            appliedFilter: getFilter(state),
            appliedFilterSecondary: getDisableStreamsWeights(state),
            isLoading: isLoadingStreams(state),
            filters: getStreamFiltersForSelect(state),
            isLiveBetter: coreSelectors.isLiveBetter(state)
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(StreamFilterBase));
}

export const styles = {
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: spacing.s3
    },
    innerContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    iconContainer: {
        marginLeft: spacing.s3
    },
    shareDayText: {
        ...appFonts.xsRegular,
        color: baseColors.black,
        marginBottom: spacing.s0
    },
    filterText: {
        ...appFonts.mdMedium,
        color: baseColors.secondary
    },
    userPanel: {
        backgroundColor: baseColors.grey80
    },
    cardHeader: {
        backgroundColor: baseColors.grey80
    },
    cardHeaderText: {
        ...appFonts.smMedium
    }
};
