import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { css } from 'aphrodite-jss';

import { baseColors, spacing, layoutStyle } from '../../../../../styles';
import { components as Core, ROUTES } from '../../../../core';
import WithEarnPartnersProfileTabItemBase from './EarnPartnersProfileTabItemBase';

class EarnPartnersProfileTabItem extends PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
        i18n: PropTypes.object.isRequired
    };

    openPartnersList = () => this.props.history.push(ROUTES.earnPartnersList());

    render() {
        const { i18n } = this.props;

        return (
            <Core.ListItem button={true} onClick={this.openPartnersList} className={css(layoutStyle.listItem)}>
                <Core.ListItemIcon>
                    <Core.Icon
                        type="fa"
                        size={spacing.s4}
                        name="shopping-cart"
                        fill="light"
                    />
                </Core.ListItemIcon>
                <Core.ListItemText primary={i18n.t('myLinkedPartners')} />
                <Core.Icon
                    type="fa"
                    name="angle-right"
                    color={baseColors.secondary}
                />
            </Core.ListItem>
        );
    }
}

export default withRouter(WithEarnPartnersProfileTabItemBase(EarnPartnersProfileTabItem));