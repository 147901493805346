import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { translate } from '../../../core';
import * as selectors from '../../selectors';

export default function WithOnboardingProgressBarBase(WrappedComponent) {
    class OnboardingProgressBarBase extends PureComponent {
        static propTypes = {
            step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            onboardingFlowSteps: PropTypes.object
        };

        static defaultProps = {
            onboardingFlowSteps: null
        };

        get percentage() {
            const { onboardingFlowSteps, step } = this.props;
            const key = _.findKey(this.props.onboardingFlowSteps, s => _.includes(s, this.props.step));
            const index = _.indexOf(onboardingFlowSteps[key], step) + 1;
            const denominator = _.get(onboardingFlowSteps[key], 'length') || 1;
            return (index / denominator) * 100;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    percentage={this.percentage}
                />
            );
        }
    }

    const mapStateToProps = state => ({ onboardingFlowSteps: selectors.getOnboardingStepsFlow(state) });

    return connect(mapStateToProps)(translate()(OnboardingProgressBarBase));
}