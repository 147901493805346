import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { translate } from '../../../core';
import * as contentActions from '../../actions';

export const ICON_SIZE = 16;

export default function WithBookmarkBase(WrappedComponent) {
    class BookmarkBase extends PureComponent {
        static propTypes = {
            item: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired
        };

        get isBookmarked() {
            return this.props.item.bookmarked === '1';
        }

        toggleBookmark = () => {
            this.props.actions.bookmarkContent(this.props.item, !this.isBookmarked);
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    isBookmarked={this.isBookmarked}
                    toggleBookmark={this.toggleBookmark}
                />
            );
        }
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(contentActions, dispatch)
        };
    }

    return connect(null, mapDispatchToProps)(translate()(BookmarkBase));
}