import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';

import { isVideo, itemIsCMSContent, secondsIntoTime } from '../../services';
import { spacing, baseColors, appFonts } from '../../../../styles';
import { parsers, translate, constants as coreConstants, selectors as coreSelectors } from '../../../core';

export default function WithContentItemBase(WrappedComponent) {
    class ContentItemBase extends PureComponent {
        static propTypes = {
            item: PropTypes.object.isRequired,
            i18n: PropTypes.object.isRequired,
            customPointsName: PropTypes.string.isRequired,
        };

        get item() {
            return this.props.item;
        }

        get itemIsCMSContent() {
            return _.get(this.item, 'external_id', '').length > 0;
        }

        get preview() {
            return this.item.preview_metadata || {};
        }

        get image() {
            if (itemIsCMSContent(this.item)) {
                const url = _.get(this.item, 'image_url');
                if (url) {
                    return parsers.getSecureImageUrl(url);
                }
            }
            return parsers.getSecureImageUrl(this.preview.src || this.preview.photo_path || this.item.thumbnail_url);
        }

        get title() {
            const { title } = this.item.highlight || {};
            return (title && title[0]) || this.item.title;
        }

        get categoryColor() {
            const slug = coreConstants.ICON_CATEGORIES[_.get(this.item, 'category_slug')];
            return this.props.isLiveBetter ? _.get(slug, 'bgr') : baseColors.secondary;
        }

        get isVideo() {
            return isVideo(_.get(this.props, 'item.url')) || isVideo(_.get(this.props, 'item.preview_metadata.hls'));
        }

        get itemLabel() {
            const { isLiveBetter, item } = this.props;
            return isLiveBetter ? item.category : item.tags;
        }

        get hasSeveralLabels() {
            const { isLiveBetter, item } = this.props;
            return !isLiveBetter && item.tagsArr.length > 1;
        }

        get videoDuration() {
            const { item: { reading_time } } = this.props;
            return reading_time && secondsIntoTime(reading_time);
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    image={this.image}
                    title={this.title}
                    isVideo={this.isVideo}
                    itemLabel={this.itemLabel}
                    categoryColor={this.categoryColor}
                    videoDuration={this.videoDuration}
                    hasSeveralLabels={this.hasSeveralLabels}
                />
            );
        }
    }

    const mapStateToProps = state => ({
        isLiveBetter: coreSelectors.isLiveBetter(state),
        customPointsName: coreSelectors.getCustomPointsName(state),
    });

    return connect(mapStateToProps)(translate()(ContentItemBase));
}

const VIDEO_DURATION_CONTAINER_VERTICAL_PADDING = 2;

export const styles = {
    card: {
        flexDirection: 'row',
        backgroundColor: baseColors.white,
        paddingTop: spacing.s3,
        paddingBottom: spacing.s3,
        marginLeft: spacing.s3,
        marginRight: spacing.s3
    },
    title: {
        ...appFonts.lgBold,
        color: baseColors.black,
        marginTop: spacing.s0,
        marginBottom: spacing.s1,
    },
    categoryText: {
        ...appFonts.smMedium,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    highlighted: {
        backgroundColor: baseColors.grey85,
        paddingLeft: spacing.s1,
        paddingRight: spacing.s1
    },
    categoryWrapper: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: -spacing.s1
    },
    labelContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    dotIcon: {
        marginLeft: spacing.s1,
        marginRight: spacing.s1
    },
    videoControlContainer: {
        display: 'flex',
        position: 'absolute',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        borderRadius: spacing.s0,
        paddingTop: VIDEO_DURATION_CONTAINER_VERTICAL_PADDING,
        paddingBottom: VIDEO_DURATION_CONTAINER_VERTICAL_PADDING,
        paddingLeft: spacing.s0,
        paddingRight: spacing.s0,
        bottom: spacing.s1,
        right: spacing.s1
    },
    videoDuration: {
        ...appFonts.xsBold,
        color: baseColors.white,
        marginLeft: spacing.s0
    },
    bonusContainer: {
        paddingTop: spacing.s0
    }
};
