import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import classnames from 'classnames';
import Dotdotdot from 'react-dotdotdot';
import _ from 'lodash';
import translate from '../../services/translate';
import { appFonts, baseColors, spacing } from '../../../../styles';
import { components as Core } from '../../../core';

const BACKGROUND_LINEAR_GRADIENT = 'linear-gradient(90deg,rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)';
const BACKGROUND_LINEAR_GRADIENT_GREY = 'linear-gradient(90deg,rgba(251, 248, 248, 0) 0%, rgba(251, 248, 248, 1) 100%)';

class TextSeeMore extends PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        className: PropTypes.string,
        containerClassName: PropTypes.string,
        buttonClassName: PropTypes.string,
        numberOfLines: PropTypes.number,
        isCollapseHidden: PropTypes.bool,
        isExpandHidden: PropTypes.bool,
        callback: PropTypes.func,
        isEditing: PropTypes.bool,
        i18n: PropTypes.object.isRequired,
        isChallengeCommunitiesDetailsPage: PropTypes.bool,
        isSelectFieldComponent: PropTypes.bool
    };

    static defaultProps = {
        className: undefined,
        containerClassName: undefined,
        buttonClassName: undefined,
        numberOfLines: 3,
        isCollapseHidden: true,
        isExpandHidden: true,
        callback: undefined,
        isEditing: false,
        isChallengeCommunitiesDetailsPage: false,
        isSelectFieldComponent: false
    };

    constructor(props) {
        super(props);

        this.state = {
            isExpanded: false,
            isExpandable: false
        };
    }

    componentDidMount() {
        this._lines = this.countLines(this.node);
        this.setState({ mounted: true, isExpandable: this._lines > this.props.numberOfLines });
    }

    componentDidUpdate(prevProps, prevState) {
        /* eslint-disable */
        // in order to recount lines the component has to be rendered and state.isExpandable has to be false
        if (prevProps.isEditing && !this.props.isEditing) {
            prevState.isExpandable ?
                this.setState({ isExpandable: false }, this.recountLines)
                : this.recountLines();
        }
        /* eslint-enable */
    }

    toggleLines = event => {
        event.preventDefault();
        event.stopPropagation();
        this.setState(({ isExpanded }) => ({ isExpanded: !isExpanded }), () => {
            if (this.props.callback) this.props.callback(!this.state.isExpanded);
        });
    };

    countLines(target) {
        const style = window.getComputedStyle(target, null);
        let height = _.parseInt(style.getPropertyValue('height'));
        const fontSize = _.parseInt(style.getPropertyValue('font-size'));
        let lineHeight = _.parseInt(style.getPropertyValue('line-height'));
        const boxSizing = style.getPropertyValue('box-sizing');

        if (_.isNaN(lineHeight)) lineHeight = fontSize * 1.2;

        if (boxSizing === 'border-box') {
            const paddingTop = _.parseInt(style.getPropertyValue('padding-top'));
            const paddingBottom = _.parseInt(style.getPropertyValue('padding-bottom'));
            const borderTop = _.parseInt(style.getPropertyValue('border-top-width'));
            const borderBottom = _.parseInt(style.getPropertyValue('border-bottom-width'));
            height = height - paddingTop - paddingBottom - borderTop - borderBottom;
        }
        return _.ceil(height / lineHeight);
    }

    recountLines = () => {
        this._lines = this.countLines(this.node);
        this.setState({ isExpandable: this._lines > this.props.numberOfLines });
    }

    get expandText() {
        return this.props.i18n.t('seeMoreText');
    }

    get collapseText() {
        return this.props.i18n.t('seeLessLongComment');
    }

    get isExpandButtonShown() {
        return this.state.isExpandable && !this.props.isExpandHidden && !this.state.isExpanded;
    }

    get isCollapseButtonShown() {
        return this.state.isExpandable && !this.props.isCollapseHidden && this.state.isExpanded;
    }

    get isExpanded() {
        return !this.state.mounted || this.state.isExpanded || !this.state.isExpandable;
    }

    get isCollapsed() {
        return this.state.mounted && !this.state.isExpanded && this.state.isExpandable;
    }

    get shortText() {
        const { children } = this.props;
        const childrenCopy = children.props?.htmlText;
        const childrenCopyToArr = _.split(childrenCopy, '<br /><br />');
        return <Core.InnerHtml htmlText={childrenCopyToArr[0]} className={css(styles.cardBodyDescription)} />;
    }

    get body() {
        const { children, className, numberOfLines, isChallengeCommunitiesDetailsPage, isSelectFieldComponent } = this.props;
        let shortDescription = null;

        if (isChallengeCommunitiesDetailsPage) {
            shortDescription = this.shortText;
        }

        return (
            <p className={className} ref={node => (this.node = node)}>
                {this.isCollapsed || isSelectFieldComponent ?
                    <Dotdotdot clamp={numberOfLines}>{shortDescription ? shortDescription : children}</Dotdotdot> : children}
            </p>
        );
    }

    render() {
        const { containerClassName, buttonClassName, isChallengeCommunitiesDetailsPage, greyGradient } = this.props;
        return (
            <div className={classnames(containerClassName, css(styles.container))}>
                {this.body}
                { this.isExpandButtonShown ? (
                    <div
                        className={
                            css(styles.seeMoreButtonPosition,
                                isChallengeCommunitiesDetailsPage && styles.seeMoreButtonPositionWithBiggerPadding)
                        }>
                        <span className={css(styles.seeMoreButtonGradient, greyGradient ? styles.greyGradient : null)} />
                        <button className={classnames(css(styles.seeMoreText), buttonClassName)} onClick={this.toggleLines}>
                            {this.expandText}
                        </button>
                    </div>
                ) : null}
                {this.isCollapseButtonShown ? (
                    <div>
                        <button className={classnames(css(styles.seeLessText), buttonClassName)} onClick={this.toggleLines}>
                            {this.collapseText}
                        </button>
                    </div>
                ) : null}
            </div>
        );
    }
}

export default translate()(TextSeeMore);

const styles = StyleSheet.create({
    container: {
        position: 'relative'
    },
    seeMoreButtonPosition: {
        position: 'absolute',
        bottom: -1,
        right: 0,
    },
    seeMoreButtonPositionWithBiggerPadding: {
        right: spacing.s3
    },
    seeMoreButtonGradient: {
        minWidth: spacing.s2,
        height: '100%',
        background: BACKGROUND_LINEAR_GRADIENT,
        position: 'absolute',
        left: -spacing.s2
    },
    greyGradient: {
        background: BACKGROUND_LINEAR_GRADIENT_GREY
    },
    seeMoreText: {
        color: baseColors.grey20,
        ...appFonts.mdMedium,
        padding: `0 0 0 ${spacing.s0}px`,
        background: baseColors.white,

        '&:hover': {
            textDecoration: 'underline',
            cursor: 'pointer'
        }
    },
    seeLessText: {
        color: baseColors.grey20,
        ...appFonts.mdMedium,
        marginTop: spacing.s1,

        '&:hover': {
            textDecoration: 'underline',
            cursor: 'pointer'
        }
    },
    cardBodyDescription: {
        paddingRight: spacing.s3,
        paddingTop: spacing.s2,
        color: baseColors.grey20,
        lineHeight: appFonts.mdRegular.lineHeight
    },
});

