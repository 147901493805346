import React, { Component } from 'react';
import PropTypes from 'prop-types';
import WithHelpAndSupportBase from './HelpAndSupportBase';
import { baseColors, spacing } from '../../../../styles';
import { AsyncComponent, components as Core, Modal, openUrl } from '../../../core';
import { LIST_ITEM_TYPES } from '../../constants';

class HelpAndSupport extends Component {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        getHelpOptions: PropTypes.func.isRequired
    };

    onPressHelpOption = option => openUrl(option.url);

    onGetHelpPress = option => (
        option.type === LIST_ITEM_TYPES.faq ?
            this.onPressHelpOption(option) :
            this.onPressFeedbackOption(option)
    );

    onPressFeedbackOption = option => {
        Modal.open(AsyncComponent(() => import('../Feedback')), { option, isPopup: true }, {
            cancelable: true,
            isNoPadding: false,
            isContainer: false,
            isFullHeight: false
        });
    };

    rightIcon = item => {
        if (item.type === LIST_ITEM_TYPES.faq) {
            return (
                <Core.Icon
                    type="fa"
                    name="external-link"
                    color={baseColors.grey50}
                    size={spacing.s3}
                />
            );
        }

        return null;
    }

    renderListOfActions = actions => (
        <>
            <Core.List>
                {actions.map(item => (
                    <>
                        <Core.ListItem button={true} onClick={item.onPress}>
                            <Core.ListItemText primary={item.title} />
                            {this.rightIcon(item)}
                        </Core.ListItem>
                        <Core.Divider light={true} />
                    </>
                ))
                }
            </Core.List>
        </>
    );

    render() {
        const { i18n, getHelpOptions } = this.props;
        return (
            <Core.Layout.WiderContainer>
                <Core.EntityDetailsHeader
                    hasBackButton={true}
                />
                <Core.Layout.CenteredContainer >
                    <Core.SubHeader
                        title={i18n.t('helpAndSupport')}
                        noTopPadding={true}
                    />
                    {this.renderListOfActions(getHelpOptions(this.onGetHelpPress))}
                </Core.Layout.CenteredContainer>
            </Core.Layout.WiderContainer>
        );
    }
}

export default WithHelpAndSupportBase(HelpAndSupport);
