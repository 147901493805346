import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectors as coreSelectors, translate } from '../../../core';
import { appFonts, baseColors, spacing } from '../../../../styles';
import * as selectors from '../../selectors';
import * as actions from '../../actions';

export default function WithPrivacySettingsBase(WrappedComponent) {
    class PrivacySettingsBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            i18n: PropTypes.object.isRequired,
        };

        componentWillMount() {
            this.props.actions.getCurrentUserPrivacySettings(this.props.userId);
        }

        get title() {
            return this.props.i18n.t('privacySettingsTitle');
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    title={this.title}
                />
            );
        }
    }

    const mapStateToProps = state => ({
        userId: coreSelectors.getCurrentUserId(state),
        isLoading: selectors.isLoadingCurrentUserPrivacySettings(state),
        currentUserPrivacySettings: selectors.getCurrentUserPrivacySettings(state),
        privacyOptionsList: selectors.getCurrentUserPrivacySettingsOptionsList(state)
    });

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(PrivacySettingsBase));
}

export const styles = {
    container: {
        backgroundColor: baseColors.white
    },
    itemContainer: {
        flex: 1,
        paddingLeft: spacing.s2
    },
    titleItem: {
        paddingLeft: spacing.s2,
        justifyContent: 'flex-start'
    },
    item: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottomWidth: 1,
        borderBottomColor: baseColors.grey80,
        paddingTop: spacing.s0,
        paddingBottom: spacing.s0,
        paddingRight: spacing.s3
    },
    text: {
        ...appFonts.mdMedium,
        flex: 1,
        paddingRight: spacing.s2
    }
};
