import i18n from '../../../i18n/i18n';
import Alert from '../components/Alert';

const INAPROPRIENT_CONTENT_TYPES = Object.freeze({
    image: {
        name: 'image',
        statusCode: 406
    },
    text: {
        name: 'text',
        statusCode: 403
    }
});

const getInapropriateErrors = error => {
    if (!error.response) return;
    const isInaproppriateContent = error.response.status === INAPROPRIENT_CONTENT_TYPES.image.statusCode;
    const isInaproppriateText = error.response.status === INAPROPRIENT_CONTENT_TYPES.text.statusCode;
    let type;
    if (isInaproppriateContent) {
        type = INAPROPRIENT_CONTENT_TYPES.image.name;
    } else if (isInaproppriateText) {
        type = INAPROPRIENT_CONTENT_TYPES.text.name;
    }
    return isInaproppriateContent || isInaproppriateText ? type : undefined;
};

const getAlertMessage = type => {
    let message;
    if (type === INAPROPRIENT_CONTENT_TYPES.text.name) {
        message = i18n.t('feeds.inapropriateTextError.message');
    } else if (type === INAPROPRIENT_CONTENT_TYPES.image.name) {
        message = i18n.t('feeds.postImageError.message');
    }
    return message;
};

const openInapropriateContentAlert = (type, clearError, deletePhoto, dismissAlert = null) => {
    Alert.alert(i18n.t('feeds.postImageError.title'), getAlertMessage(type), [
        {
            text: i18n.t('discardPost'),
            style: 'destructive',
            onPress: () => {
                clearError();
                if (dismissAlert) dismissAlert();
                if (type === INAPROPRIENT_CONTENT_TYPES.image.name) {
                    deletePhoto();
                }
            }
        },
        { text: i18n.t('ok') }
    ]);
};

export { getInapropriateErrors, getAlertMessage, openInapropriateContentAlert } ;