import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';
import _ from 'lodash';
import moment from 'moment';
import WithMoreBase, { styles as baseStyles, privacyPolicyId, termsServiceId, disclaimerId } from './MoreBase';
import { components as Core, ROUTES, Modal, AsyncComponent, openUrl, tracker } from '../../../core';
import { constants as settingsConstants } from '../../../settings';
import { spacing, baseColors, layoutStyle } from '../../../../styles';

class More extends Component {
    static propTypes = {
        history: PropTypes.object.isRequired,
        openMoreWebPopup: PropTypes.func.isRequired,
        i18n: PropTypes.object.isRequired,
        numDevices: PropTypes.number,
        syncStatus: PropTypes.object,
        sectionOne: PropTypes.array,
        sectionTwo: PropTypes.array,
        sectionThree: PropTypes.array,
        termsOfService: PropTypes.string,
        companyHasExternalApps: PropTypes.bool.isRequired,
        handleOpenApp: PropTypes.func.isRequired
    };

    static defaultProps = {
        numDevices: null,
        syncStatus: null,
        sectionOne: null,
        sectionTwo: null,
        sectionThree: null,
        termsOfService: undefined
    };

    componentDidMount() {
        tracker.logEvent('MorePage');
    }

    get appsDevicesSubtitle() {
        const { numDevices, syncStatus, i18n } = this.props;
        const timestamp = syncStatus ? moment(syncStatus).fromNow() : '--';

        const connectedDevicesString = `${i18n.t('number_connected', { numDevices })} • ${i18n.t('lastSync')} ${timestamp}`;

        return (
            <div className={css(styles.appsDevicesSubtitle)}>
                <p>{connectedDevicesString}</p>
            </div>
        );
    }


    get angleRight() {
        return (
            <Core.Icon
                type="fa"
                name="angle-right"
                size={spacing.s5}
                color={baseColors.grey50}
            />
        );
    }

    onClickFunc = item => {
        if (item.id === termsServiceId) {
            this.goToLegal(settingsConstants.LIST_ITEM_TYPES.terms);
        } else if (item.id === privacyPolicyId) {
            this.goToLegal(settingsConstants.LIST_ITEM_TYPES.policy);
        } else if (item.id === disclaimerId) {
            this.goToLegal(settingsConstants.LIST_ITEM_TYPES.disclaimer);
        } else {
            this.props.history.push(ROUTES[item.route](), { myTab: true });
        }
        this.props.openMoreWebPopup();
    }

    goToLegal = type => {
        if (type === settingsConstants.LIST_ITEM_TYPES.terms && this.props.termsOfService) {
            openUrl(this.props.termsOfService);
        } else if (type === disclaimerId) {
            Modal.open(AsyncComponent(() => import('../../../auth/components/Disclaimer')), { legalType: type, isPopup: true, isModal: true }, {
                cancelable: true,
                isNoPadding: true,
                isContainer: true,
                isFullHeight: true,
                isNoPaddingHorizontal: true,
                isNoWidthLimit: true,
                maxWidth: 600,
                isMaxHeight: true
            });
        } else {
            Modal.open(AsyncComponent(() => import('../../../settings/components/Legals')), { legalType: type, isPopup: true, isModal: true }, {
                cancelable: true,
                isNoPadding: true,
                isContainer: true,
                isFullHeight: true,
                isNoPaddingHorizontal: true,
                isNoWidthLimit: true,
                maxWidth: 600,
                isMaxHeight: true
            });
        }
    }

    externalAppsSection = () => {
        const { sectionOne, companyHasExternalApps, i18n } = this.props;

        if (!companyHasExternalApps) return null;

        return (
            <>
                <div className={css(styles.sectionContainer)}>
                    <p className={css(styles.externalAppsSectionTitle)}>{i18n.t('includedInMembership')}</p>
                    {_.map(sectionOne, item => this.renderExternalAppItem(item))}
                </div>
                <div className={css(styles.border)} />
            </>
        );
    }

    renderExternalAppItem = item => (
        <div
            className={css(styles.contentContainer)}
            onClick={() => this.props.handleOpenApp(item)}>
            <>
                <div className={css(layoutStyle.flexRowCenter)} accessible={false}>
                    {item.image ? (
                        <Core.Image
                            src={item.image}
                            className={css(styles.externalAppIcon)}
                        />
                    ) : null}
                    <div className={css(styles.textWrapper)}>
                        <p className={css(styles.externalAppTitle)}>{item.title}</p>
                        <div className={css(styles.subtitleContainer, styles.externalSubtitleContainer)}>
                            {item.subtitle ? <p className={css(styles.externalAppSubtitle)}>{item.subtitle}</p> : null}
                        </div>
                    </div>
                </div>
                <Core.Icon
                    name="external-link"
                    type="fa"
                    fill="regular"
                    size={spacing.s3}
                    color={baseColors.grey50}
                />
            </>
        </div>
    )

    renderItem = (item, isLastChild) => {
        const { i18n } = this.props;

        if (item.id === 'appsAndDevices') {
            // eslint-disable-next-line no-param-reassign
            item.subtitle = this.appsDevicesSubtitle;
        }

        return (
            <div className={css(styles.contentContainer)} onClick={() => this.onClickFunc(item)}>
                <div className={css(layoutStyle.flexRowCenter)}>
                    {item.icon ? (
                        <Core.Icon
                            type="fa"
                            fill="light"
                            name={item.icon}
                            size={spacing.s5}
                            color={baseColors.black}
                            className={css(styles.icon)}
                        />
                    ) : null}
                    <div className={css(styles.textWrapper, item.id === 'EAP' && { width: '80%' })}>
                        <p className={css(styles.title)}>{i18n.t(item.title)}</p>
                        <div className={css(styles.subtitleContainer)}>
                            {item.subtitle ? <p className={css(styles.subtitle)}>{item.subtitleTranslated ? item.subtitle : i18n.t(item.subtitle)}</p> : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { sectionTwo, sectionThree } = this.props;

        return (
            <>
                {this.externalAppsSection()}
                <div className={css(styles.sectionContainer)}>
                    {
                        _.map(sectionTwo, list => (
                            <>
                                {_.map(list, item => {
                                    const isLastChild = list.indexOf(item) === list.length - 1;
                                    return this.renderItem(item, isLastChild);
                                })}
                            </>

                        ))
                    }
                </div>
                <div className={css(styles.border)} />
                <div className={css(styles.sectionContainer)}>
                    {
                        _.map(sectionThree, list => (
                            <>
                                {_.map(list, item => {
                                    const isLastChild = list.indexOf(item) === list.length - 1;
                                    return this.renderItem(item, isLastChild);
                                })}
                            </>
                        ))
                    }
                </div>
            </>
        );
    }
}


const styles = StyleSheet.create({
    ...baseStyles,
    sectionContainer: {
        paddingTop: spacing.s1,
        paddingBottom: spacing.s1,
    },
    border: {
        borderBottomWidth: 1,
        borderBottomColor: baseColors.grey80,
        borderBottomStyle: 'solid',
    },
    contentContainer: {
        paddingTop: spacing.s1,
        paddingBottom: spacing.s1,
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flex: 1,
        '&:hover': {
            backgroundColor: baseColors.grey90,
            cursor: 'pointer'
        },
    },
    subtitleContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        flex: 1
    },
    appsDevicesSubtitle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
    },
    externalAppsSectionTitle: {
        ...baseStyles.externalAppsSectionTitle,
        paddingTop: spacing.s1,
        paddingBottom: spacing.s0
    }
});

export default WithMoreBase(More);
