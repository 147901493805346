import React from 'react';
import PropTypes from 'prop-types';
import { translate } from '../../services';
import { appFonts, baseColors, spacing } from '../../../../styles';

export default function WithFeaturedTagBase(WrappedComponent) {
    const FeaturedTagBase = props => (
        <WrappedComponent {...props} />
    );

    FeaturedTagBase.propTypes = {
        showTag: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
        text: PropTypes.string
    };

    FeaturedTagBase.defaultProps = {
        showTag: false,
        text: ''
    };

    return translate()(FeaturedTagBase);
}

export const styles = {
    container: {
        borderRadius: spacing.s0,
        position: 'absolute',
        left: spacing.s1,
        top: spacing.s1,
        backgroundColor: baseColors.primary
    },
    text: {
        ...appFonts.smMedium,
        color: baseColors.white,
    }
};
