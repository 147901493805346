import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';

import { layoutStyle, spacing } from '../../../../styles';
import WithProfileOverviewBase, { styles as baseStyles } from './ProfileOverviewBase';
import { components as Core } from '../../../core';

class ProfileOverview extends PureComponent {
    static propTypes = {
        currentUser: PropTypes.object.isRequired,
        isUpdating: PropTypes.bool,
        isDeleting: PropTypes.bool,
        showBorder: PropTypes.bool.isRequired,
        locationString: PropTypes.string.isRequired,
        updateImage: PropTypes.func,
        deletePhoto: PropTypes.func,
        departmentString: PropTypes.string.isRequired,
        avatarName: PropTypes.string.isRequired,
        preferredName: PropTypes.string.isRequired,
        i18n: PropTypes.object.isRequired,
        dotDivider: PropTypes.element
    };

    static defaultProps = {
        isUpdating: false,
        isDeleting: false,
        updateImage: null,
        deletePhoto: null,
        dotDivider: null
    };

    render() {
        const {
            currentUser,
            departmentString,
            locationString,
            updateImage,
            showBorder,
            deletePhoto,
            avatarName,
            preferredName,
            isUpdating,
            isDeleting,
            dotDivider
        } = this.props;

        return (
            <div className={css(styles.profileSummaryContainer, showBorder ? styles.showBorder : null)}>
                <Core.BlockingLoading isLoading={isUpdating || isDeleting} />
                <div className={css(layoutStyle.flexCenter)}>
                    <Core.AvatarUpload
                        fileChanged={updateImage}
                        deleteFile={deletePhoto}
                        isDeleting={isDeleting}
                        picture={currentUser.avatarURL}
                        title={avatarName}
                    />
                    <div className={css(styles.profileSummary)}>
                        <div className={css(styles.nameText)}>{preferredName}</div>
                        <div className={css(styles.addInfo)}>
                            { currentUser.companyId && currentUser.department ?
                                <div className={css(styles.locationText)}>{ departmentString }</div> : null }
                            {dotDivider}
                            { currentUser.location ?
                                <div className={css(styles.locationText)}>{ locationString }</div> : null }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const styles = StyleSheet.create({
    ...baseStyles,
    showBorder: {
        borderStyle: 'solid',
        borderWidth: 0,
        marginBottom: spacing.s3,
        ...baseStyles.showBorder
    },
    profileSummaryContainer: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: spacing.s7,
        justifyContent: 'space-between'
    },
    addInfo: {
        marginTop: spacing.s0,
        marginBottom: spacing.s0,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
});

export default WithProfileOverviewBase(ProfileOverview);
