import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as actions from '../../actions';
import {
    translate,
    selectors as coreSelectors,
    componentHelper,
    ENTITIES,
    ROUTES,
    Platform,
    PLATFORMS
} from '../../../core';
import { spacing, appFonts, layoutStyle, baseColors } from '../../../../styles';
import { isEditingTeam, isCreatingTeam, getChallenge, getTeamDetails, getTeamCurrentMembersWithoutCurrentUser,
    getCreateEditTeamError } from '../../selectors';
import { constants as invitationConstants } from '../../../invitations';

export const TEAM_NAME_MAX_LENGTH = 100;

export default function WithCreateEditTeamBase(WrappedComponent) {
    class CreateEditTeamBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            history: PropTypes.object.isRequired,
            teamDetails: PropTypes.object,
            currentMembers: PropTypes.array,
            challenge: PropTypes.object,
            actions: PropTypes.object.isRequired,
            isEditing: PropTypes.bool,
            user: PropTypes.object.isRequired,
            createdCallback: PropTypes.func,
            isLoading: PropTypes.bool,
            isModal: PropTypes.bool,
            challengeId: PropTypes.number.isRequired
        };

        static defaultProps = {
            teamDetails: undefined,
            currentMembers: [],
            challenge: undefined,
            isEditing: false,
            isLoading: false,
            isModal: false,
            createdCallback: undefined
        };

        constructor(props) {
            super(props);
            this.state = {
                teamName: props.teamDetails ? props.teamDetails.name : '',
                invitedMembers: [],
                emailsArray: [],
                removedMembers: [],
                errorMessage: '',
                currentMembers: [this.memberFromUser, ...this.props.currentMembers],
                isSaved: false,
                edited: false
            };
            if (!this.props.challenge) this.props.actions.getChallenge(props.challengeId);
        }

        get memberFromUser() {
            return {
                firstname: this.props.user.firstNameDisplay,
                lastname: this.props.user.lastNameDisplay,
                preferredname: this.props.user.preferredName,
                id: this.props.user.userId,
                avatarURL: this.props.user.avatarURL,
                location: this.props.user.location
            };
        }

        componentDidUpdate(prevProps) {
            componentHelper.onActionComplete(this.props, prevProps, () => {
                if (this.props.challenge.userEntity) {
                    this.props.actions.getChallengeTeam(this.props.challengeId, this.props.challenge.userEntity.id);
                }
                if (this.props.createdCallback) this.props.createdCallback();
                this.setState({ isSaved: true }, this.goBack);
            }, this.props.actions.clearCreateEditTeamError);
        }

        goBack = () => {
            componentHelper.goBack(this.props);
        };

        get isChangesPresent() {
            return (_.get(this.props, 'teamDetails.name')
                ? this.state.teamName !== this.props.teamDetails.name : this.state.teamName) ||
                this.allInvitations.length || this.state.removedMembers.length || this.state.picture;
        }

        get isAskingAboutLiving() {
            return this.isChangesPresent && !this.state.isSaved;
        }

        get isMobile() {
            return Platform.OS !== PLATFORMS.web;
        }

        createEditTeam = () => {
            const { teamName, invitedMembers, removedMembers, errorMessage } = this.state;
            const { i18n, isEditing, teamDetails, challengeId, challenge: { challengeName },
                redirectAfterUpdate, navigation, history } = this.props;
            if (!teamName) {
                this.setState({ errorMessage: i18n.t('specify_team_name') });
                return;
            }
            errorMessage && this.setState({ errorMessage: '' });
            const pictureData = _.has(this.state, 'picture') ? this.state.picture : undefined;
            const invitedUserIds = _.map(invitedMembers, 'id');
            if (isEditing) {
                const removedUserIds = _.map(removedMembers, 'id');
                const teamId = teamDetails.entityId;
                this.props.actions.editTeam(challengeId, teamId, teamName, invitedUserIds,
                    removedUserIds, pictureData, teamName, i18n.t('team_edits_submitted'));
            } else if (redirectAfterUpdate) {
                this.props.actions.createTeam(challengeId, teamName, invitedUserIds, pictureData,
                    challengeName, i18n.t('has_been_created', { name: teamName }));
                if (this.isMobile) {
                    //need double go back to ensure ending up on the home screen
                    navigation.goBack();
                    navigation.goBack();
                    navigation.push(ROUTES.challengeDetails(), { challengeId });
                } else {
                    history.replace(ROUTES.home());
                    history.push(ROUTES.challengeDetails(challengeId));
                }
            } else {
                this.props.actions.createTeam(challengeId, teamName, invitedUserIds, pictureData,
                    challengeName, i18n.t('has_been_created', { name: teamName }));
            }
        };

        get picture() {
            return _.has(this.state, 'picture') ? this.state.picture : _.get(this.props, 'teamDetails.imageURL');
        }

        get pictureFull() {
            return _.has(this.state, 'pictureFull') ? this.state.pictureFull : _.get(this.props, 'teamDetails.imageURL');
        }

        updatePicture = (picture, pictureFull) => this.setState({ picture, pictureFull, edited: true });

        removeMember = (member, isEmail) => () => {
            this.setState(state => {
                if (_.has(member, 'deleted')) {
                    return ({ // only current members have deleted property
                        currentMembers: _.filter(state.currentMembers, m => m.id !== member.id),
                        removedMembers: [...state.removedMembers, member]
                    });
                } else if (isEmail) {
                    return ({
                        emailsArray: _.filter(state.emailsArray, m => m.inviteeEmail !== member.inviteeEmail)
                    });
                }
                return ({
                    invitedMembers: _.filter(state.invitedMembers, m => m.id !== member.id),
                    edited: true
                });
            });
        };

        onNameChange = teamName => this.setState({ teamName, edited: true });

        get buttonLabel() {
            return this.props.isEditing ? this.props.i18n.t('save') : this.props.i18n.t('create');
        }

        get title() {
            return this.props.isEditing ? this.props.i18n.t('edit_your_team') : this.props.i18n.t('create_team');
        }

        isCurrentUser = item => item.id === this.props.user.userId;

        getName = item => this.isCurrentUser(item) ? this.props.i18n.t('you') : `${item.firstname} ${item.lastname}`;

        getColorObject = item => this.isCurrentUser(item) ? layoutStyle.colorSecondary : layoutStyle.colorBlack;

        updateInvitedMembers = (invitedMembers, emailsArray) => {
            this.setState(state => ({
                invitedMembers: _.unionBy(state.invitedMembers, invitedMembers, 'id'),
                emailsArray: _.unionBy(state.emailsArray, emailsArray, 'inviteeEmail'),
                edited: true
            }));
        };

        get allInvitations() {
            return [...this.state.invitedMembers, ...this.state.emailsArray];
        }

        inviteToTeamProps = () => ({
            entityId: this.props.challengeId,
            callback: this.updateInvitedMembers,
            isInviteByEmailShown: false,
            entity: invitationConstants.ENTITIES.TEAM
        });

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    edited={this.state.edited}
                    title={this.title}
                    buttonLabel={this.buttonLabel}
                    onNameChange={this.onNameChange}
                    teamName={this.state.teamName}
                    removedMembers={this.state.removedMembers}
                    allInvitations={this.allInvitations}
                    isCurrentUser={this.isCurrentUser}
                    getName={this.getName}
                    getColorObject={this.getColorObject}
                    removeMember={this.removeMember}
                    updatePicture={this.updatePicture}
                    picture={this.picture}
                    pictureFull={this.pictureFull}
                    createEditTeam={this.createEditTeam}
                    isAskingAboutLiving={this.isAskingAboutLiving}
                    currentMembers={this.state.currentMembers}
                    errorMessage={this.state.errorMessage}
                    entityType={ENTITIES.team}
                    inviteToTeamProps={this.inviteToTeamProps}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const teamId = ownProps.teamId || _.get(ownProps, 'route.params.teamId') || _.get(ownProps, 'match.params.teamId');
        const challengeId = ownProps.challengeId || _.get(ownProps, 'route.params.challengeId') || _.get(ownProps, 'match.params.challengeId');
        const routeParams = _.get(ownProps, 'route.params');
        return {
            teamId,
            challengeId,
            isEditing: ownProps.isEditing || !!teamId,
            isLoading: isCreatingTeam(state) || isEditingTeam(state),
            challenge: getChallenge(state, challengeId),
            user: coreSelectors.getCurrentUser(state),
            teamDetails: getTeamDetails(state, challengeId, teamId),
            currentMembers: getTeamCurrentMembersWithoutCurrentUser(state, teamId),
            error: getCreateEditTeamError(state),
            ...routeParams
        };
    };

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(CreateEditTeamBase));
}

const ENVELOPE_CONTAINER_SIZE = spacing.s9;

export const styles = {
    titleStyle: {
        ...appFonts.mdBold,
        marginBottom: spacing.s0,
        marginLeft: spacing.s2
    },
    emailIconContainer: {
        width: ENVELOPE_CONTAINER_SIZE,
        height: ENVELOPE_CONTAINER_SIZE,
        borderRadius: ENVELOPE_CONTAINER_SIZE / 2,
        backgroundColor: baseColors.grey50
    }
};
