import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { layoutStyle, importantClass, spacing, baseColors, media, appFonts } from '../../../../../styles';
import { ActionSheet, AsyncComponent, components as Core, Modal, ROUTES, Storage } from '../../../../core';
import JoinTeamsList from '../../JoinTeamsList';
import WithChallengeParticipantsBase, { styles as baseStyles } from './ChallengeParticipantsBase';
import { constants as invitationConstants } from '../../../../invitations';
import { IS_ABOUT_TAB } from '../../../constants';

export const NUMBER_OF_LINES = 12;

class ChallengeParticipants extends PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        onJoinChallengeIndividual: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired,
        challengeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        challenge: PropTypes.object.isRequired,
        participants: PropTypes.array.isRequired,
        membershipDetails: PropTypes.array,
        isLoading: PropTypes.bool,
        isLoadingMembership: PropTypes.bool,
        activateButtonLabel: PropTypes.string.isRequired,
        isAfterDeadline: PropTypes.bool.isRequired,
        isMember: PropTypes.bool.isRequired,
        invitingTeams: PropTypes.array.isRequired,
        numMembersText: PropTypes.string.isRequired,
        isUserCompanyPartner: PropTypes.bool.isRequired,
        invitedBy: PropTypes.object,
        isRegionsDepartmentsChallenge: PropTypes.bool,
        inviteToChallengeText: PropTypes.string.isRequired,
        getOptions: PropTypes.func.isRequired,
        entityId: PropTypes.number.isRequired,
        description: PropTypes.string.isRequired,
        isChallengeDescription: PropTypes.bool.isRequired,
        sizeAvatar: PropTypes.string,
        showOnCard: PropTypes.bool,
        showInviteButton: PropTypes.bool.isRequired,
        challengeParticipants: PropTypes.array.isRequired,
        isTeamChallenge: PropTypes.bool.isRequired
    };

    static defaultProps = {
        membershipDetails: undefined,
        invitedBy: undefined,
        isLoading: false,
        isLoadingMembership: false,
        isRegionsDepartmentsChallenge: false,
        sizeAvatar: 'small',
        showOnCard: false
    };

    get avatarList() {
        const { participants, invitedBy, isMember, inviteToChallengeText, isChallengeDescription, description, numMembersText, sizeAvatar, showOnCard, showInviteButton, challengeParticipants } = this.props;
        const isOnPress = showOnCard ? null : this.allMembersPressed;
        return participants.length || showOnCard ? (
            <>
                <div className={css(showOnCard ? null : styles.viewParticipantsAndJoin)}>
                    <div className={css(layoutStyle.flexAlignCenter, layoutStyle.justifyStart)}>
                        <Core.AvatarList
                            members={challengeParticipants}
                            onPress={isOnPress}
                            membersAvatarClassName={css(styles.membersAvatar, showOnCard && styles.membersAvatarSpacing)}
                            containerClassName={css(showOnCard ? null : styles.avatarListContainer)}
                            sizeAvatar={sizeAvatar}
                            isShowNumbersOfMembers={false}
                        />
                        {!showOnCard && (
                            <div>
                                <div className={css(styles.membersDetails, layoutStyle.flex)}>
                                    {numMembersText}
                                </div>
                                {invitedBy ? <div className={css(styles.invitedText)}>{invitedBy}</div> : null}
                            </div>
                        )}
                    </div>
                    {showInviteButton ? (
                        <Core.Button
                            style={styles.inviteButton}
                            isMedium={true}
                            textStyle={styles.inviteButtonLabel}
                            withIcon={true}
                            onPress={this.onInvite}
                            type="outlined">
                            <Core.Icon type="fa" name="user-plus" size={spacing.s3} color={baseColors.secondary} className={css(styles.plusUsericon)} />
                            {inviteToChallengeText}
                        </Core.Button>
                    ) : null}
                </div>
                {!showOnCard && (
                    <Fragment>
                        {isMember && isChallengeDescription ? (
                            <Core.TextSeeMore
                                numberOfLines={NUMBER_OF_LINES}
                                isCollapseHidden={false}
                                isExpandHidden={false}
                                buttonClassName={css(styles.seeMoreStyle)}
                                isChallengeCommunitiesDetailsPage={true}>
                                <Core.InnerHtml htmlText={description} className={css(styles.cardBodyDescription, styles.descriptionText, styles.aTags)} />
                            </Core.TextSeeMore>
                        ) : null}
                    </Fragment>
                )}
            </>
        ) : (
            <Fragment>
                {isMember && isChallengeDescription ? (
                    <div className={css(styles.descriptionMargin)}>
                        <Core.TextSeeMore
                            numberOfLines={NUMBER_OF_LINES}
                            isCollapseHidden={false}
                            isExpandHidden={false}
                            isChallengeCommunitiesDetailsPage={true}
                            buttonClassName={css(styles.seeMoreStyle)}>
                            <Core.InnerHtml htmlText={description} className={css(styles.cardBodyDescription, styles.descriptionText, styles.aTags)} />
                        </Core.TextSeeMore>
                    </div>
                ) : null}
            </Fragment>
        );
    }

    openOptions = event => {
        const { getOptions } = this.props;
        ActionSheet.open(getOptions(this.inviteToTeam, this.inviteToChallenge), undefined, undefined, event);
    };

    inviteToTeam = () => {
        const { entityId, challengeId, history } = this.props;
        history.push(ROUTES.inviteToTeam(challengeId, entityId, invitationConstants.ENTITIES.TEAM));
    };

    inviteToChallenge = () => {
        const { history, challengeId } = this.props;
        history.push(ROUTES.inviteToChallenge(challengeId, invitationConstants.ENTITIES.CHALLENGE));
    }

    onInvite = this.props.isTeamChallenge ? this.openOptions : this.inviteToChallenge;

    allMembersPressed = () => {
        const { challengeId, isUnstartedChallenge } = this.props;
        Storage.setItem(IS_ABOUT_TAB, true);
        this.props.history.push(ROUTES.challengeMembers(challengeId), { isUnstartedChallenge });
    }

    get viewParticipantsAndJoin() {
        const { isChallengeDescription, isLoadingMembership, description, membershipDetails } = this.props;
        return (
            <Fragment>
                {this.avatarList}
                {isLoadingMembership ? <Core.ListLoading /> : null}
                {!isLoadingMembership && membershipDetails ? (
                    <Fragment>
                        <div className={css(styles.descriptionMargin)}>
                            {this.joinSection}
                        </div>
                        <div className={css(styles.descriptionMargin, isChallengeDescription ? layoutStyle.flex : null)}>
                            {isChallengeDescription ? (
                                <Core.TextSeeMore
                                    numberOfLines={NUMBER_OF_LINES}
                                    isCollapseHidden={false}
                                    isExpandHidden={false}
                                    isChallengeCommunitiesDetailsPage={true}>
                                    <Core.InnerHtml htmlText={description} className={css(styles.cardBodyDescription, styles.descriptionText, styles.aTags)} />
                                </Core.TextSeeMore>
                            ) : null}
                        </div>
                        {this.invitingTeams}
                    </Fragment>
                ) : null}
            </Fragment>
        );
    }

    get joinSection() {
        return !this.props.isUserCompanyPartner ? this.joinCreateButtons : this.activateButton;
    }

    get joinCreateButtons() {
        const { i18n, membershipDetails, isAfterDeadline } = this.props;
        if (isAfterDeadline) return null;
        if (_.get(membershipDetails, 'length')) {
            return (
                <div className={css(styles.buttonsRow)}>
                    <Core.Button
                        onPress={this.viewAllTeams}
                        size="large"
                        className={css(styles.btnWidth)}>
                        {i18n.t('join_team')}
                    </Core.Button>
                    <Core.Button
                        onPress={this.viewCreateTeamScreen}
                        size="large"
                        className={css(styles.twoButtonsRow, styles.btnWidth)}>
                        {i18n.t('create_team')}
                    </Core.Button>
                </div>
            );
        }
        return (
            <Core.Button
                onPress={this.viewCreateTeamScreen}
                size="large"
                className={css(styles.btnWidth)}>
                {i18n.t('create_team')}
            </Core.Button>
        );
    }

    viewAllTeams = () => {
        this.props.history.push(ROUTES.joinTeam(this.props.challengeId));
    };

    viewCreateTeamScreen = () => {
        this.close = Modal.open(AsyncComponent(() => import('../../CreateEditTeam')), {
            challengeId: this.props.challengeId,
            isModal: true
        }, {
            cancelable: false,
            isNoPadding: true,
            isContainer: true,
            isFullHeight: true
        });
    };

    get activateButton() {
        const { isAfterDeadline, isLoading, activateButtonLabel, isChallengeDescription } = this.props;

        if (isAfterDeadline) return null;

        return (
            <div className={css(styles.cardBodyButton, !isChallengeDescription ? layoutStyle.fw : null)}>
                <Core.Button
                    isLoading={isLoading}
                    disabled={isLoading}
                    onPress={this.props.onJoinChallengeIndividual}
                    className={css(styles.btnCenter, styles.btnWidth)}
                    size="large">
                    {activateButtonLabel}
                </Core.Button>
            </div>
        );
    }

    get invitingTeams() {
        const { invitingTeams, i18n, challenge, isRegionsDepartmentsChallenge, returnTeamList } = this.props;
        if (!invitingTeams.length || isRegionsDepartmentsChallenge) return null;
        return (
            <Fragment>
                <div className={css(styles.invitingTeams)}>
                    <p className={css(styles.smallGreyTitle)}>{i18n.t('teams.teamInvites')}</p>
                    <JoinTeamsList
                        challengeId={challenge.challengeId}
                        hasInvitingTeams={true}
                        isPage={false}
                        hasSearchBox={false}
                        returnTeamList={returnTeamList}
                    />
                </div>
            </Fragment>
        );
    }

    render() {
        const { isMember, showOnCard, returnTeamList } = this.props;
        if (returnTeamList) return this.invitingTeams;
        return isMember || showOnCard ? this.avatarList : this.viewParticipantsAndJoin;
    }
}

export default withRouter(WithChallengeParticipantsBase(ChallengeParticipants));

const styles = StyleSheet.create({
    ...baseStyles,
    membersAvatar: {
        ...baseStyles.membersAvatar,
        borderStyle: 'solid'
    },
    twoButtonsRow: importantClass({
        marginLeft: spacing.s2
    }),
    avatarListContainer: importantClass({
        ...baseStyles.avatarListContainer,
        marginLeft: spacing.s1,
        marginRight: 0,
        marginTop: 0,
        '&:hover': {
            backgroundColor: 'transparent',
        }
    }),
    plusUsericon: {
        marginRight: spacing.s0
    },
    viewParticipantsAndJoin: {
        ...baseStyles.viewParticipantsAndJoin,
        display: 'flex',
        paddingRight: spacing.s3,
        marginTop: spacing.s5
    },
    cardBodyDescription: {
        paddingRight: spacing.s3,
        paddingTop: spacing.s2,
        color: baseColors.grey20,
        lineHeight: appFonts.mdRegular.lineHeight,
    },
    buttonsRow: {
        width: '100%',
        flexDirection: 'row',
    },
    cardBodyButton: {
        ...baseStyles.cardBodyButton,
        minWidth: '30%',
        paddingTop: 0,
        [media.up_lg]: {
            minWidth: '25%',
        }
    },
    membersAvatarSpacing: {
        marginLeft: -spacing.s1
    },
    descriptionMargin: {
        marginTop: spacing.s5,
    },
    invitingTeams: {
        marginTop: spacing.s3,
        border: `1px solid  ${baseColors.grey80}`
    },
    btnCenter: {
        textAlign: 'center'
    },
    btnWidth: importantClass({
        minWidth: spacing.s4 * 10
    })
});
