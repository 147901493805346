import { NAME as name } from './constants';
import { createActionTypes } from '../core';

const NAME = name.toUpperCase();

export const GET_NOTIFICATIONS = createActionTypes(`${NAME}/GET_NOTIFICATIONS`);
export const GET_MORE_NOTIFICATIONS = createActionTypes(`${NAME}/GET_MORE_NOTIFICATIONS`);
export const GET_UNREAD_COUNT = createActionTypes(`${NAME}/GET_UNREAD_COUNT`);
export const GET_UNSEEN_COUNT = createActionTypes(`${NAME}/GET_UNSEEN_COUNT`);
export const MARK_NOTIFICATION_READ = createActionTypes(`${NAME}/MARK_NOTIFICATION_READ`);
export const MARK_ALL_NOTIFICATIONS_READ = createActionTypes(`${NAME}/MARK_ALL_NOTIFICATIONS_READ`);
export const MARK_ALL_NOTIFICATIONS_CLICKED = createActionTypes(`${NAME}/MARK_ALL_NOTIFICATIONS_CLICKED`);
export const REGISTER_DEVICE_FOR_PUSH = createActionTypes(`${NAME}/REGISTER_DEVICE_FOR_PUSH`);
export const UPDATE_PRIORITY_NOTIFICATIONS_IDS = `${NAME}/UPDATE_PRIORITY_NOTIFICATIONS_IDS`;