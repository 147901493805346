import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { css, StyleSheet } from 'aphrodite-jss';
import { withTheme } from '@material-ui/styles';
import { baseColors, spacing, appFonts, importantStyles, layoutStyle, containerStyle } from '../../../../styles';
import Card from '../Card';
import { translate } from '../../services';

class ScrollableTabs extends PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        onChange: PropTypes.func,
        onPress: PropTypes.func,
        tabs: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string.isRequired,
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
        })).isRequired,
        activeTab: PropTypes.shape({
            label: PropTypes.string.isRequired,
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
        }).isRequired,
        tabClassName: PropTypes.string,
        barStyles: PropTypes.string,
        scrollable: PropTypes.bool,
        scrollButtons: PropTypes.string,
        barPosition: PropTypes.string,
        isVertical: PropTypes.bool,
        isNewDesign: PropTypes.bool,
        isChallengeDetailsPage: PropTypes.bool,
        searchComponent: PropTypes.node,
        isLibraryPage: PropTypes.bool,
        isCategory: PropTypes.bool,
        flexContainer: PropTypes.string,
        innerComponent: PropTypes.node,
        isTabsWrap: PropTypes.bool,
        isFilterEmojiModal: PropTypes.bool
    };

    static defaultProps = {
        barPosition: 'sticky',
        tabClassName: undefined,
        onChange: undefined,
        onPress: undefined,
        barStyles: '',
        scrollable: true,
        scrollButtons: 'off',
        isVertical: true,
        isNewDesign: false,
        isChallengeDetailsPage: false,
        searchComponent: undefined,
        isLibraryPage: false,
        isCategory: false,
        flexContainer: undefined,
        innerComponent: undefined,
        isTabsWrap: false,
        isFilterEmojiModal: false
    };

    get variant() {
        if (this.props.scrollable) return 'scrollable';
        return undefined;
    }

    getTabContent = tab => {
        const { i18n } = this.props;
        const tabName = tab.label || tab.tag_name;
        const translatedName = tab.translate ? i18n.t(tabName) : tabName;

        if (tab.headTitle) {
            return (
                <Fragment>
                    <p className={css(styles.tabSmallHeader)}>{tab.headTitle}</p>
                    <p>{translatedName}</p>
                </Fragment>
            );
        } else if (tab.icon) {
            return (
                <div className={css(styles.tabWithIcon)}>
                    {tab.icon}
                    <span className={css(styles.textNearIcon)}>{translatedName}</span>
                </div>
            );
        }
        return (<p>{translatedName}</p>);
    };

    renderTab = tab => {
        const { isVertical, isNewDesign, isChallengeDetailsPage, isLibraryPage, isCategory, isSearchInputFocused, hasVideoContent,
            isFilterEmojiModal, activeTab, theme } = this.props;

        const tabRootStyle = () => {
            if (isChallengeDetailsPage) return styles.challengeDetailTabRoot;
            if (isLibraryPage) {
                return hasVideoContent ? styles.withVideoTab : styles.libraryTabRoot;
            }
            if (isCategory) return styles.category;
            if (isNewDesign) return styles.tabRootNew;
            if (isFilterEmojiModal) return styles.filterEmojiTab;
            return styles.tabRoot;
        };
        const isActive = activeTab.id === tab.id;

        return (
            <Tab
                value={tab.id}
                key={tab.id}
                label={this.getTabContent(tab)}
                id={_.camelCase(`${tab.id}Tab`)}
                tabIndex="0"
                classes={{
                    root: isVertical
                        ? css(styles.tabVerticalRoot, containerStyle.menuItemfocusVisible)
                        : css(tabRootStyle()),
                    textColorSecondary: css(isNewDesign ? styles.textColorSecondaryNew : styles.textColorSecondary,
                        isCategory && styles.categoryTextColor),
                    selected: css(isSearchInputFocused ? styles.textColorSecondary : styles.selected,
                        isVertical ? styles.selectedVertical : null,
                        isCategory ? styles.categorySelected : null,
                        containerStyle.selectedMenuItemfocusVisible
                    ),
                    wrapper: css(
                        isChallengeDetailsPage || isLibraryPage || isCategory ? null : layoutStyle.flexJustifyAlignStart,
                        isNewDesign ? styles.tabTextNew : styles.tabText,
                        isCategory ? styles.categoryTabText : null
                    )
                }}
                style={(isActive && isVertical) ? { background: theme.palette.secondary.main }: {}}
            />
        );
    }

    wrapVerticalWithPanel = content => (
        <Card className={css(styles.panel)}>{content}</Card>
    );

    get content() {
        const { activeTab, tabs, isVertical, onChange, isCategory, isSearchInputFocused,
            searchComponent, innerComponent, isTabsWrap, ...props } = this.props;

        return (
            <Tabs
                {..._.omit(props, [
                    'onPress', 'scrollable', 'activeTab', 'barPosition', 'tabClassName', 'barStyles', 'tReady', 'isNewDesign', 't', 'flexContainer',
                ])}
                classes={{
                    flexContainer: isTabsWrap ? css(styles.flexContainerWrap) : null
                }}
                variant={this.variant}
                orientation={isVertical ? 'vertical' : 'horizontal'}
                value={activeTab ? activeTab.id : null}
                onChange={onChange}
                indicatorColor="secondary"
                textColor="secondary"
                TabIndicatorProps={{ classes: { root: css((isVertical || isCategory || isSearchInputFocused) ? layoutStyle.hidden : styles.tabIndicator) } }}>
                {_.map(tabs, this.renderTab)}
                <div className={css(layoutStyle.flexCenter)}>
                    {searchComponent}
                </div>
                {innerComponent}
            </Tabs>
        );
    }

    render() {
        const { isVertical } = this.props;
        return isVertical ? this.wrapVerticalWithPanel(this.content) : this.content;
    }
}

export default translate()(withTheme(ScrollableTabs));

const LIBRARY_TAB_WIDTH = '25%';
const MIN_BUTTON_WIDTH = 80;
const LIBRARY_TAB_WIDTH_HAS_VIDEO = '20%';
const LABEL_OFFSET = 6;

const styles = StyleSheet.create({
    ...importantStyles({
        tabIndicator: {
            height: 3
        },
        challengeDetailTabRoot: {
            minWidth: 0,
            flex: 1,
            flexGrow: 1,
            maxWidth: '100%'
        },
        tabRoot: {
            marginRight: spacing.s7,
            minWidth: 0
        },
        tabRootNew: {
            minWidth: 0,
            paddingRight: spacing.s3,
            paddingLeft: spacing.s3,
        },
        labelContainer: {
            padding: 0
        },
        tabText: {
            ...appFonts.mdMedium,
            textTransform: 'none',
            textAlign: 'left'
        },
        categoryTabText: {
            ...appFonts.smRegular,
        },
        tabTextNew: {
            ...appFonts.smRegular,
            textTransform: 'none',
        },
        categoryTextColor: {
            color: baseColors.grey20
        },
        textColorSecondary: {
            color: baseColors.grey40
        },
        textColorSecondaryNew: {
            color: baseColors.grey40
        },
        selected: {
            color: baseColors.secondary
        },
        categorySelected: {
            backgroundColor: baseColors.grey40,
            color: baseColors.white,
            border: 'none'
        },
        verticalButton: {
            marginRight: 0
        },
        tabVerticalRoot: {
            maxWidth: '100%',
            minHeight: 'auto'
        },
        selectedVertical: {
            color: baseColors.white,
            borderRadius: spacing.s0
        },
        tabSmallHeader: {
            ...appFonts.smRegular
        },
        panel: {
            padding: spacing.s1,
            boxShadow: '0 8px 18px rgba(0, 0, 0, 0.15)',
        },
        tabWithIcon: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center'
        },
        textNearIcon: {
            marginLeft: spacing.s2,
            textAlign: 'left'
        },
        libraryTabRoot: {
            width: LIBRARY_TAB_WIDTH
        },
        withVideoTab: {
            width: LIBRARY_TAB_WIDTH_HAS_VIDEO
        },
        category: {
            borderRadius: spacing.s1,
            marginRight: LABEL_OFFSET,
            marginBottom: spacing.s1,
            padding: `${spacing.s1}px ${spacing.s3}px`,
            minWidth: MIN_BUTTON_WIDTH,
            backgroundColor: baseColors.grey85,
            minHeight: 'auto',
        },
        flexContainerWrap: {
            display: 'flex',
            flexWrap: 'wrap'
        },
        filterEmojiTab: {
            marginRight: 0,
            minWidth: 0,
            padding: spacing.s2,
            height: spacing.s9
        },
    }),
});
