import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from '../../../../core';
import * as rewardsSelectors from '../../../selectors';

export default function WithAchievementsCarouselBase(WrappedComponent) {
    class AchievementsCarouselBase extends PureComponent {
        static propTypes = {
            achievements: PropTypes.array,
            achievementsCount: PropTypes.number,
            score: PropTypes.bool,
            title: PropTypes.string,
            i18n: PropTypes.object.isRequired
        };

        static defaultProps = {
            achievements: [],
            achievementsCount: 0,
            score: false,
            title: null
        };

        get title() {
            return this.props.title || this.props.i18n.t('my_achievements');
        }

        get hasAchievements() {
            return !!this.props.achievements.length;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    title={this.title}
                    hasAchievements={this.hasAchievements}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => ({
        achievements: ownProps.score ? rewardsSelectors.getEarnedAchievements(state, true) :
            rewardsSelectors.getAchievements(state, true),
        achievementsCount: ownProps.score ? rewardsSelectors.getEarnedAchievementsCount(state) :
            rewardsSelectors.getAchievementsCount(state)
    });

    return connect(mapStateToProps)(translate()(AchievementsCarouselBase));
}