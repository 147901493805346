import PropTypes from 'prop-types';
import React, { PureComponent, Fragment } from 'react';
import { css } from 'aphrodite-jss';
import { components as Core, ROUTES } from '../../../core';
import { REDEEM_STATES } from '../../constants';
import OrderAddressItem from '../OrderAddressItem';
import OrderHeader from '../OrderHeader';
import WithOrderRedemptionConfirmationBase from './OrderRedemptionConfirmationBase';
import { layoutStyle, containerStyle } from '../../../../styles';
import { headerStyles } from '../header';

class OrderRedemptionConfirmation extends PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
        item: PropTypes.object,
        address: PropTypes.object,
        orderId: PropTypes.number.isRequired,
        externalRewardName: PropTypes.string.isRequired,
        clearRedeemProductError: PropTypes.func.isRequired,
        clearTemporaryRewardRedemptionToken: PropTypes.func.isRequired,
        onOrder: PropTypes.func.isRequired,
        addErrorToast: PropTypes.func.isRequired,
        currentStep: PropTypes.string.isRequired,
        isLoading: PropTypes.bool,
        triggerSSO: PropTypes.func.isRequired,
        i18n: PropTypes.object.isRequired,
        title: PropTypes.string,
        productId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    };

    static defaultProps = {
        item: {},
        address: null,
        isLoading: false,
        title: undefined
    };

    constructor(props) {
        super(props);
        this.isOrder = false;
    }

    order = () => {
        if (!this.isOrder) {
            this.isOrder = true;
            this.props.onOrder();
        }
    };

    componentDidUpdate(prevProps) {
        switch (prevProps.currentStep) {
            case REDEEM_STATES.submitting:
            case REDEEM_STATES.submitOrder: {
                if (this.props.currentStep === REDEEM_STATES.sso) {
                    this.props.clearRedeemProductError();
                    this.props.triggerSSO();
                }
                else if (this.props.currentStep === REDEEM_STATES.orderConfirmation) {
                    this.goToOrderDetails();
                }
                else if (this.props.currentStep === REDEEM_STATES.orderError) {
                    this.props.clearRedeemProductError();
                }
                break;
            }
            case REDEEM_STATES.reAuthentication: {
                if (this.props.currentStep === REDEEM_STATES.autoSubmit) {
                    this.props.clearRedeemProductError();
                    this.props.clearTemporaryRewardRedemptionToken();
                    this.onOrder();
                }
                else if (this.props.currentStep === REDEEM_STATES.submitOrder) {
                    this.props.addErrorToast();
                }
                break;
            }
            case REDEEM_STATES.autoSubmit: {
                if (this.props.currentStep === REDEEM_STATES.orderConfirmation) {
                    this.goToOrderDetails();
                }
                else if (this.props.currentStep === REDEEM_STATES.submitOrder) {
                    this.props.addErrorToast();
                }
                break;
            }
            default:
                break;
        }
    }

    goToOrderDetails = () => {
        const { orderId } = this.props;
        this.props.history.push(ROUTES.orderDetails(orderId), { fromRedemption: true });
    };

    goToProductDetails = () => this.props.history.go(-2);

    onEditAddressPage = () => {
        const { title, productId } = this.props;
        this.props.history.push(ROUTES.orderShippingAddress(), { shippingAddress: this.props.address, productId, title });
    };

    render() {
        const { isLoading, externalRewardName, item, address } = this.props;
        return (
            <Fragment>
                <Core.SmallerContainer>
                    <Core.FixedHeader
                        fullWidth={true}
                        subheader={
                            <Core.SubHeader
                                title={this.props.title}
                                className={css(layoutStyle.bgSecondary)}
                                titleClassName={css(headerStyles.headerTitle)}
                                left={
                                    <Core.Button
                                        onPress={this.goToProductDetails}
                                        size={Core.Button.SIZES.small}
                                        className={css(layoutStyle.noShadow, headerStyles.buttonText)}
                                        color="secondary">
                                        {this.props.i18n.t('button_cancel')}
                                    </Core.Button>
                                }
                                right={
                                    <Core.Button
                                        onPress={this.order}
                                        size={Core.Button.SIZES.small}
                                        className={css(layoutStyle.noShadow, headerStyles.buttonText)}
                                        color="secondary">
                                        {this.props.i18n.t('order')}
                                    </Core.Button>
                                }
                            />
                        }
                    />
                    <Core.BlockingLoading isLoading={isLoading} />
                    <div className={css(containerStyle.container)}>
                        <OrderHeader product={item} externalRewardName={externalRewardName} />
                        {address ?
                            <OrderAddressItem address={address} onEdit={this.onEditAddressPage} /> : null
                        }
                    </div>
                </Core.SmallerContainer>
            </Fragment>
        );
    }
}

export default WithOrderRedemptionConfirmationBase(OrderRedemptionConfirmation);