import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { spacing, appFonts, baseColors } from '../../../../styles';
import { translate } from '../../../core';
import { getChallenge, getChallengeLeaderboard } from '../../selectors';
import { getGoalProgress, isChallengeUpdating, isInGracePeriod, isCompletedNotInGracePeriod, isTeamChallenge } from '../../services/helper';

export default function WithCompetitionProgressCardBase(WrappedComponent) {
    class CompetitionProgressCardBase extends PureComponent {
        static propTypes = {
            challengeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // eslint-disable-line
            challenge: PropTypes.object.isRequired,
            isUpdating: PropTypes.bool
        };

        static defaultProps = {
            isUpdating: false
        };

        state = {
            isLoadingChallengeLeaderboard: true,
        };

        componentDidUpdate(prevProps) {
            if (prevProps.leaderboard || (!prevProps.leaderboard && this.props.leaderboard)) {
                // eslint-disable-next-line react/no-did-update-set-state
                this.setState({ isLoadingChallengeLeaderboard: false });
            }
        }

        get progressList() {
            return getGoalProgress(this.props.challenge);
        }

        get overallProgress() {
            // this.progressList always returns an array of either 2([periodProgress, overallProgress]) or 1 items([overallProgress])
            return this.progressList[1] ? this.progressList[1] : this.progressList[0];
        }

        get leaderboardItems() {
            const items = _.get(this.props, 'leaderboard.items', []);
            if (!items.length) return null;
            const itemsToShow = _.slice(items, 0, 3);
            const userId = _.get(this.props, 'challenge.userEntity.id');
            const viewer = _.find(items, item => item.isViewer === 1 && parseInt(item.itemEntityId) === userId) || { rank: 0 };
            return viewer.rank > 3 ? [...itemsToShow, viewer] : itemsToShow;
        }

        get noParticipationText() {
            return this.props.i18n.t('noParticipation');
        }

        getLeaderboardItemName = item => {
            const { challenge, i18n } = this.props;
            return !isTeamChallenge(challenge) && item.isViewer ? i18n.t('you') : item.name;
        }

        render() {
            const { challenge } = this.props;

            return (
                <WrappedComponent
                    {...this.props}
                    overallProgress={this.overallProgress}
                    leaderboardItems={this.leaderboardItems}
                    isInGracePeriod={isInGracePeriod(challenge)}
                    isTeamChallenge={isTeamChallenge(challenge)}
                    noParticipationText={this.noParticipationText}
                    isLoading={this.state.isLoadingChallengeLeaderboard}
                    getLeaderboardItemName={this.getLeaderboardItemName}
                    isCompletedNotInGracePeriod={isCompletedNotInGracePeriod(challenge)}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const challenge = getChallenge(state, ownProps.challengeId);
        return {
            challenge,
            isUpdating: isChallengeUpdating(challenge),
            leaderboard: getChallengeLeaderboard(state, ownProps.challengeId),
        };
    };

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(CompetitionProgressCardBase));
}

const CROWN_TOP = 5;
const TROPHY_ICON_WIDTH =68;
const TROPHY_ICON_HEIGHT = 60;
const COMPETITION_BODY_HEIGHT = 200;

export const styles = {
    competitionCard: {
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: spacing.s1
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
    },
    crownIcon: {
        height: spacing.s2,
        width: spacing.s4,
        position: 'absolute',
        top: CROWN_TOP,
        marginLeft: spacing.s9,
        borderRadius: 0,
    },
    competitionGracePeriod: {
        marginTop: spacing.s2,
        marginBottom: 'auto',
        textAlign: 'left',
    },
    memberScore: {
        marginLeft: 'auto',
    },
    avatar: {
        width: spacing.s4,
        height: spacing.s4,
        borderWidth: 2,
        borderRadius: spacing.s2,
        marginLeft: spacing.s1,
        marginRight: spacing.s1,
        borderStyle: 'solid',
        borderColor: baseColors.warn,
        fontSize: spacing.s1,
    },
    noImageBorder: {
        borderWidth: 0,
    },
    memberPosition: {
        minWidth: spacing.s3,
        width: 'auto',
        color: baseColors.grey40,
    },
    leaderboardItem: {
        ...appFonts.mdRegular,
        color: baseColors.grey40,
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3,
        paddingBottom: spacing.s1 + 2,
    },
    viewerItem: {
        fontWeight: 'bold',
        backgroundColor: baseColors.white,
        paddingTop: spacing.s1,
    },
    memberName: {
        paddingRight: spacing.s1,
    },
    blackColor: {
        color: baseColors.black,
    },
    boldText: {
        fontWeight: 'bold',
        color: baseColors.black,
    },
    userIcon: {
        marginTop: spacing.s0,
        marginRight: spacing.s0,
    },
    leaderboardItemsWrapper: {
        paddingTop: spacing.s2,
    },
    noParticipation: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: COMPETITION_BODY_HEIGHT,
    },
    trophyIcon: {
        width: TROPHY_ICON_WIDTH,
        height: TROPHY_ICON_HEIGHT,
    },
    noParticipationText: {
        ...appFonts.smRegular,
        color: baseColors.grey40,
        marginTop: spacing.s1,
    },
};