import React, { PureComponent } from 'react';
import { appFonts, baseColors, spacing } from '../../../../styles';

export default function WithCheckboxWithInputBase(WrappedComponent) {
    class CheckboxWithInputBase extends PureComponent {
        static propTypes = {
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                />
            );
        }
    }

    return CheckboxWithInputBase;
}

export const styles = {
    input: {
        ...appFonts.mdRegular,
        color: baseColors.grey20,
    },
    adornmentText: {
        ...appFonts.xsMedium,
        color: baseColors.grey40,
        marginRight: spacing.s3,
    },
    warnColorText: {
        color: baseColors.warnDarker
    }
};
