import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { bindActionCreators } from 'redux';

import { translate, ENTITIES } from '../../../core';
import * as selectors from '../../selectors';
import { updateChallenge } from '../../actions';

export default function WithEditChallengeImageBase(WrappedComponent) {
    class EditChallengeImageBase extends PureComponent {
        static propTypes = {
            onPress: PropTypes.func,
            i18n: PropTypes.object.isRequired,
            newChallenge: PropTypes.object.isRequired,
            challenge: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            isChallengeUpdating: PropTypes.bool
        };

        static defaultProps = {
            onPress: null,
            isChallengeUpdating: false
        };

        constructor(props) {
            super(props);
            this.wrapped = React.createRef();
            this.state = {
                pictureFull: _.get(props.challenge, 'challengeImageURL'),
            };
        }


        componentDidUpdate(prevProps) {
            if (!this.props.isChallengeUpdating && prevProps.isChallengeUpdating) {
                this._finish();
            }
        }

        _finish = () => this.wrapped.current.finish();

        onPress = () => {
            const { picture, pictureFull } = this.state;
            const { challenge } = this.props;
            this.props.actions.updateChallenge({ ...challenge, picture, pictureFull });
        };

        onImageChange = (picture, pictureFull) => {
            this.setState({ picture, pictureFull });
        };

        get entityType() {
            return _.get(this.props.challenge, 'solo') === 1 ? ENTITIES.goal : ENTITIES.challenge;
        }

        get buttonTitle() {
            return this.props.i18n.t('submit');
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    onPress={this.onPress}
                    onImageChange={this.onImageChange}
                    pictureFull={this.state.pictureFull}
                    entityType={this.entityType}
                    buttonTitle={this.buttonTitle}
                    ref={this.wrapped}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        const challengeId = ownProps.challengeId || _.get(ownProps, 'route.params.challengeId');
        return {
            challenge: selectors.getChallenge(state, challengeId),
            isChallengeUpdating: selectors.isChallengeUpdating(state, challengeId)
        };
    }

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators({ updateChallenge }, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(EditChallengeImageBase));
}
