import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';

import { components as Core } from '../../../core';
import WithLocationDetailsBase from './LocationDetailsBase';
import { baseColors, spacing } from '../../../../styles';

class LocationDetails extends PureComponent {
    static propTypes = {
        isCityIncluded: PropTypes.bool,
        isCountryShown: PropTypes.bool,
        i18n: PropTypes.object.isRequired,
        countries: PropTypes.array,
        country: PropTypes.object,
        departments: PropTypes.array,
        department: PropTypes.object,
        regions: PropTypes.array,
        region: PropTypes.object,
        locations: PropTypes.array,
        location: PropTypes.object,
        cities: PropTypes.array,
        city: PropTypes.object,
        departmentName: PropTypes.string.isRequired,
        locationName: PropTypes.string.isRequired,
        regionName: PropTypes.string.isRequired,
        cityName: PropTypes.string.isRequired,
        countryName: PropTypes.string.isRequired,
        onDepartmentChange: PropTypes.func.isRequired,
        onLocationChange: PropTypes.func.isRequired,
        onCityChange: PropTypes.func.isRequired,
        onRegionChange: PropTypes.func.isRequired,
        onCountryChange: PropTypes.func.isRequired,
        isEditable: PropTypes.bool,
        isLoadingDepartments: PropTypes.bool.isRequired,
        isCorporateLocation: PropTypes.bool.isRequired,
        errors: PropTypes.object,
        isCountryPreFilled: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        isCityIncluded: true,
        isCountryShown: false,
        countries: [],
        country: undefined,
        departments: [],
        department: undefined,
        regions: [],
        region: undefined,
        locations: [],
        location: undefined,
        cities: [],
        city: undefined,
        isEditable: true,
        errors: {}
    };

    render() {
        return (
            <div>
                {!this.props.isEditable ?
                    <div className={css(styles.disclaimer)}>{this.props.i18n.t('HRIS.disclaimer')}</div>
                    : null
                }
                {this.props.isCountryShown ? (
                    <Core.SelectField
                        id="countryLocation"
                        selected={this.props.country}
                        options={this.props.countries}
                        fieldName={this.props.countryName}
                        disabled={this.props.isCountryPreFilled}
                        onChange={this.props.onCountryChange}
                        checkboxType={Core.Checkbox.TYPES.simple}
                        error={this.props.errors.country}
                        modalWithScrollbar={true}
                        shouldShowMessage={false}
                    />
                ) : null}
                <Core.SelectField
                    id="regionLocation"
                    selected={this.props.region}
                    options={this.props.regions}
                    fieldName={this.props.regionName}
                    disabled={!this.props.isEditable}
                    onChange={this.props.onRegionChange}
                    checkboxType={Core.Checkbox.TYPES.simple}
                    error={this.props.errors.region}
                    modalWithScrollbar={true}
                    shouldShowMessage={false}
                />
                {this.props.isCityIncluded ? (
                    <Core.SelectField
                        id="cityLocation"
                        selected={this.props.city}
                        options={this.props.cities}
                        fieldName={this.props.cityName}
                        disabled={!this.props.isEditable}
                        onChange={this.props.onCityChange}
                        checkboxType={Core.Checkbox.TYPES.simple}
                        error={this.props.errors.city}
                        modalWithScrollbar={true}
                        shouldShowMessage={false}
                    />
                ) : null
                }
                <Core.SelectField
                    id="location"
                    selected={this.props.location}
                    options={this.props.locations}
                    fieldName={this.props.locationName}
                    disabled={!this.props.isEditable}
                    onChange={this.props.onLocationChange}
                    checkboxType={Core.Checkbox.TYPES.simple}
                    error={this.props.errors.location}
                    modalWithScrollbar={true}
                    shouldShowMessage={false}
                />
                {this.props.isCorporateLocation ? (
                    <Core.SelectField
                        id="departmentLocation"
                        selected={this.props.department}
                        options={this.props.departments}
                        fieldName={this.props.departmentName}
                        disabled={!this.props.isEditable || this.props.isLoadingDepartments}
                        isLoading={this.props.isLoadingDepartments}
                        onChange={this.props.onDepartmentChange}
                        checkboxType={Core.Checkbox.TYPES.simple}
                        error={this.props.errors.department}
                        modalWithScrollbar={true}
                        hasLongContent={true}
                        shouldShowMessage={false}
                    />
                ) : null
                }
            </div>
        );
    }
}

const styles = StyleSheet.create({
    disclaimer: {
        color: baseColors.grey40,
        top: spacing.s3,
        marginBottom: spacing.s3
    },
});

export default WithLocationDetailsBase(LocationDetails);