import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { baseColors, layoutStyle } from '../../../../styles';
import Button from '../Button';
import Icon from '../Icon';
import { PageWrapper } from '../Layout';
import WithNoConnectionBase, { styles as baseStyles, ICON_SIZE } from './NoConnectionBase';

class NoConnection extends PureComponent {
    static propTypes = {
        isChecking: PropTypes.bool,
        close: PropTypes.func.isRequired,
        getText: PropTypes.func.isRequired,
        onButtonPress: PropTypes.func.isRequired,
    };

    static defaultProps = {
        isChecking: false
    };

    // is used from parent
    close = () => {
        this.props.close();
        window.location.reload(false);
    };

    render() {
        const { getText, onButtonPress, isChecking } = this.props;
        return (
            <PageWrapper isModal={true}>
                <div className={css(styles.container)}>
                    <div className={css(styles.bodyContainer)}>
                        <div className={css(styles.body)}>
                            <div className={css(styles.iconContainer)}>
                                <Icon name="wifi-slash" size={ICON_SIZE} type="light" color={baseColors.warn} />
                            </div>
                            <p className={css(styles.title)}>{getText('title')}</p>
                            <p className={css(styles.description, layoutStyle.fw)}>{getText('description')}</p>
                        </div>
                        <Button
                            onPress={onButtonPress}
                            isLoading={isChecking}
                            isDisabled={isChecking}>
                            {getText('button')}
                        </Button>
                    </div>
                </div>
            </PageWrapper>
        );
    }
}

export default WithNoConnectionBase(NoConnection);

const styles = StyleSheet.create({
    ...baseStyles,
    container: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    }
});
