import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { baseColors, important } from '../../../../styles';
import withAccordionListItemBase, { styles as baseStyles } from './AccordionListItemBase';

const useStyles = makeStyles({
    root: {
        width: '100%',
        boxShadow: 'none',
        '&:before': {
            top: 0,
            height: 0,
        },
    },
    rootBorder: {
        borderTopWidth: 1,
        borderTopStyle: 'solid',
        borderTopColor: baseColors.grey70,
    },
    icon: {
        color: baseColors.grey50,
    },
    expanded: {
        minHeight: important(0),
    },
});

const AccordionListItem = props => {
    const { itemHeader, itemDetails, index, itemClassName, getActiveIndex, checkExpanded, hasSeparator, chevronColor, useRootBorder } = props;
    const [expanded, setExpanded] = React.useState(null);
    const classes = useStyles();

    const handleChange = itemIndex => (event, newExpanded) => {
        setExpanded(newExpanded ? itemIndex : false);
        getActiveIndex(newExpanded ? itemIndex : false);
    };

    return (
        <Accordion
            square={true}
            key={`accord-${index}`}
            expanded={checkExpanded ? expanded === index : false}
            onChange={handleChange(index)}
            className={classNames(classes.root, useRootBorder ? classes.rootBorder : null, itemClassName)}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon fontSize="large" classes={{ root: chevronColor ? chevronColor : classes.icon }} />}
                aria-controls={`${index}-content`}
                classes={{ expanded: classes.expanded }}>
                {itemHeader}
            </AccordionSummary>
            {hasSeparator ? <div className={css(styles.separator)} /> : null}
            {itemDetails}
        </Accordion>
    );
};

AccordionListItem.propTypes = {
    checkExpanded: PropTypes.bool.isRequired,
    getActiveIndex: PropTypes.func.isRequired,
    itemClassName: PropTypes.string,
    hasSeparator: PropTypes.bool,
    useRootBorder: PropTypes.bool,
    chevronColor: PropTypes.string
};

AccordionListItem.defaultProps = {
    itemClassName: undefined,
    hasSeparator: true,
    useRootBorder: true,
    chevronColor: undefined
};

export default withAccordionListItemBase(AccordionListItem);

const styles = StyleSheet.create({
    ...baseStyles,
});
