import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Platform, PLATFORMS, translate, validation } from '../../../core';
import * as actions from '../../actions';
import { appFonts, baseColors, spacing } from '../../../../styles';
import { getCompany } from '../../../auth/selectors';
import { actions as authActions } from '../../../auth';

export const FIELDS = [
    {
        label: 'inviteFamilyMember.email',
        value: 'email',
        func: 'onEmailChange'
    },
    {
        label: 'inviteFamilyMember.firstName',
        value: 'firstName',
        func: 'onFirstNameChange'
    },
    {
        label: 'inviteFamilyMember.lastName',
        value: 'lastName',
        func: 'onLastNameChange'
    }
];
const ALPHABETICAL_REGEX = /[^A-Za-z\\s]/g;

export default function WithInviteFamilyMemberBase(WrappedComponent) {
    class InviteFamilyMemberBase extends Component {
        static propTypes = {
            company: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            authActions: PropTypes.object.isRequired,
            i18n: PropTypes.object.isRequired,
        };

        constructor(props) {
            super(props);
            this.state = {
                email: '',
                firstName: '',
                lastName: '',
                checkboxApproval: false,
                multiSelect1: undefined,
                isMultiSelect1Visible: false,
                multiSelect2: undefined,
                isMultiSelect2Visible: false,
                checkboxPolicy: false,
                errors: {}
            };
        }
        get sendInvitationLabel() {
            return this.props.i18n.t('inviteFamilyMember.btn');
        }
        get isWeb() {
            return Platform.OS === PLATFORMS.web;
        }
        onEmailChange = emailValue => this.setState({ email: emailValue });
        onFirstNameChange = firstNameValue => {
            const firstNameAlphabetical = firstNameValue.replace(ALPHABETICAL_REGEX, '');
            this.setState({ firstName: firstNameAlphabetical });
        }
        onLastNameChange = lastNameValue => {
            const lastNameAlphabetical = lastNameValue.replace(ALPHABETICAL_REGEX, '');
            this.setState({ lastName: lastNameAlphabetical });
        }
        onCheckboxApprovalChange = () => this.setState({ checkboxApproval: !this.state.checkboxApproval });
        onCheckboxPolicyChange = () => this.setState({ checkboxPolicy: !this.state.checkboxPolicy });
        toggleMultiSelect1 = data => {
            this.setState({ multiSelect1: data, isMultiSelect1Visible: true });
        };
        toggleMultiSelect2 = data => {
            this.setState({ multiSelect2: data, isMultiSelect2Visible: true });
        };
        validateEmail = () => {
            if (this.state.email && !validation.isEmail(this.state.email)) {
                return { email: this.props.i18n.t('invitation.email.invalid_email') };
            }
        }
        onInvitePress = () => {
            const { email, firstName, lastName } = this.state;
            const errors = { ...this.validateEmail() };
            this.setState(() => ({ errors }));
            if (_.isEmpty(errors)) {
                //passing ageFlag: 1 because the user can only press the button when all questions about age passed the checks
                this.props.actions.sendFamilyMemberInvitation([{ email, firstName, lastName, ageFlag: 1 }]);
                this.isWeb ? this.props.history.goBack() : this.props.navigation.goBack();
            } else {
                this.props.actions.validateFamilyInvitationDataError();
            }
        }
        get isSubmitBtnActive() {
            const { email, firstName, lastName, checkboxApproval, multiSelect1, multiSelect2, checkboxPolicy, isMultiSelect1Visible, isMultiSelect2Visible } = this.state;
            const inputsFilled = email && firstName && lastName && checkboxApproval;

            if (inputsFilled) {
                if (isMultiSelect1Visible && _.get(multiSelect1, 'id', multiSelect1)) {
                    return true;
                } else if (isMultiSelect1Visible && !_.get(multiSelect1, 'id', multiSelect1) && isMultiSelect2Visible && _.get(multiSelect2, 'id', multiSelect2)) {
                    return checkboxPolicy;
                }
            } else return false;
            return false;
        }
        get options() {
            const { i18n } = this.props;
            return [{
                id: 1,
                label: i18n.t('invitation.yes')
            }, {
                id: 0,
                label: i18n.t('invitation.no')
            }];
        }
        get isTermsAndPolicyVisible() {
            const { checkboxApproval, isMultiSelect1Visible, multiSelect1, isMultiSelect2Visible } = this.state;
            let isVisible = false;
            if (checkboxApproval && (isMultiSelect1Visible && !_.get(multiSelect1, 'id', multiSelect1)) && isMultiSelect2Visible) {
                isVisible = true;
            }
            return isVisible;
        }
        get approvalToInviteLabel() {
            return this.props.i18n.t('inviteFamilyMember.approval', { company: this.props.company.programName });
        }
        get multiSelect1Question() {
            return this.props.i18n.t('inviteFamilyMember.multiSelect1');
        }
        get multiSelect2Question() {
            return this.props.i18n.t('inviteFamilyMember.multiSelect2');
        }
        get confirmTermsPolicy() {
            return this.props.i18n.t('inviteFamilyMember.confirmTermsPolicy');
        }
        get inviteAgeWarning() {
            return this.props.i18n.t('inviteFamilyMember.warning');
        }
        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    email={this.state.email}
                    firstName={this.state.firstName}
                    lastName={this.state.lastName}
                    checkboxApproval={this.state.checkboxApproval}
                    multiSelect1={this.state.multiSelect1}
                    multiSelect2={this.state.multiSelect2}
                    checkboxPolicy={this.state.checkboxPolicy}
                    onEmailChange={this.onEmailChange}
                    onFirstNameChange={this.onFirstNameChange}
                    onLastNameChange={this.onLastNameChange}
                    onCheckboxApprovalChange={this.onCheckboxApprovalChange}
                    toggleMultiSelect1={this.toggleMultiSelect1}
                    toggleMultiSelect2={this.toggleMultiSelect2}
                    onCheckboxPolicyChange={this.onCheckboxPolicyChange}
                    onInvitePress={this.onInvitePress}
                    sendInvitationLabel={this.sendInvitationLabel}
                    isSubmitBtnActive={this.isSubmitBtnActive}
                    options={this.options}
                    errors={this.state.errors}
                    termsOfService={this.termsOfService}
                    privacyPolicy={this.privacyPolicy}
                    isMultiSelect1Visible={this.state.isMultiSelect1Visible}
                    isMultiSelect2Visible={this.state.isMultiSelect2Visible}
                    isTermsAndPolicyVisible={this.isTermsAndPolicyVisible}
                    approvalToInviteLabel={this.approvalToInviteLabel}
                    multiSelect1Question={this.multiSelect1Question}
                    multiSelect2Question={this.multiSelect2Question}
                    confirmTermsPolicy={this.confirmTermsPolicy}
                    inviteAgeWarning={this.inviteAgeWarning}
                />
            );
        }
    }

    const mapStateToProps = state => ({
        company: getCompany(state)
    });
    const mapDispatchToProps = dispatch => ({
        actions: bindActionCreators(actions, dispatch),
        authActions: bindActionCreators(authActions, dispatch)
    });


    return connect(mapStateToProps, mapDispatchToProps)(translate()(InviteFamilyMemberBase));
}

export const styles = {
    textStyle: {
        ...appFonts.mdRegular,
        color: baseColors.grey20,
        fontWeight: '200',
    },
    warningContainer: {
        backgroundColor: baseColors.dangerLightest,
        padding: spacing.s3,
        borderRadius: spacing.s1,
        justifyContent: 'center',
        width: '100%'
    },
    warningText: {
        color: baseColors.dangerDarkest,
        ...appFonts.mdRegular
    },
    linkPolicyText: {
        ...appFonts.smBold,
        color: baseColors.secondary,
    },
    policyTextWrapper: {
        flex: 1,
        flexWrap: 'wrap'
    },
    webViewContainer: {
        flex: 1,
        backgroundColor: baseColors.white
    },
    policyText: {
        ...appFonts.smRegular,
        color: baseColors.grey20,
    }
};

