import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import _ from 'lodash';
import { AsyncComponent, components as Core, Modal, ROUTES } from '../../../core';
import { appFonts, baseColors, important, importantClass, layoutStyle, spacing } from '../../../../styles';
import WithCommunityItemDetailsBase, { styles as baseStyles } from './CommunityItemDetailsBase';
import { components as Feeds, constants as feedsConstants } from '../../../feeds';
import { components as Settings, constants as settingsConstants } from '../../../settings';

const NUMBER_OF_LINES = 3;

class CommunityItemDetails extends PureComponent {
    static propTypes = {
        item: PropTypes.object,
        isMember: PropTypes.bool,
        isUserRole: PropTypes.bool,
        communityJoining: PropTypes.bool,
        communityLoading: PropTypes.bool,
        communityEditing: PropTypes.bool,
        history: PropTypes.object.isRequired,
        imageSrc: PropTypes.string.isRequired,
        openOptions: PropTypes.func.isRequired,
        joinCommunity: PropTypes.func.isRequired,
        getWarningProps: PropTypes.func.isRequired,
        invitedByText: PropTypes.string.isRequired,
        numMembersText: PropTypes.string.isRequired,
        getInviteToCommunityProps: PropTypes.func.isRequired,
        joinCommunityButtonLabel: PropTypes.string.isRequired,
        contentIsDeletedOrInappropriate: PropTypes.string.isRequired,
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        inviteButtonText: PropTypes.string.isRequired,
        notJoinedCommunityLabel: PropTypes.string.isRequired
    };

    static defaultProps = {
        item: null,
        isMember: false,
        isUserRole: true,
        communityJoining: undefined,
        communityLoading: undefined,
        communityEditing: undefined,
    };

    componentDidUpdate(prevProps) {
        if (!this.props.item && prevProps.item) this.props.history.goBack();
    }

    get renderCards() {
        const { item, item: { id } } = this.props;
        return (
            <div className={css(styles.streamHeader)}>
                <Feeds.StreamFilter
                    filtersByTimeAdded={true}
                    hasShareNewsComponent={false}
                    containerClassName={css(styles.container)}>
                    <Feeds.ShareNews
                        bodyClassName={css(layoutStyle.textLeft)}
                        postParams={{
                            postToEntityId: id,
                            postToEntityType: feedsConstants.STREAM_ENTITY_TYPES.group
                        }}
                        title={item.name}
                    />
                </Feeds.StreamFilter>
            </div>
        );
    }

    get noContent() {
        const { communityLoading } = this.props;
        return (
            !communityLoading ? <Core.NotAvailableContent text={this.props.contentIsDeletedOrInappropriate} /> : null
        );
    }

    editCommunity = () => {
        Modal.open(AsyncComponent(() => import('../EditCommunity')), {
            isModal: true,
            communityId: this.props.item.id
        }, {
            cancelable: false,
            isNoPadding: true,
            isContainer: true,
            isFullHeight: true,
            fadeTransition: true,
        });
    };

    inviteToCommunity = () => {
        const props = this.props.getInviteToCommunityProps();
        this.props.history.push(ROUTES.inviteToCommunity(props.entityId, props.entity));
    };

    allMembersPressed = () => {
        this.props.history.push(ROUTES.communityMembers(this.props.item.id));
    };

    openOptions = e => this.props.openOptions(this.editCommunity, this.inviteToCommunity, e);

    showDeletionWarning = onConfirm => {
        const { i18n, getWarningProps } = this.props;

        const onDelete = () => {
            onConfirm();
            this.closeWarning();
        };

        this.closeWarning = Modal.open(
            Core.InfoModal,
            {
                ...getWarningProps(),
                buttons: [
                    { text: i18n.t('button_delete_community'), onPress: () => onDelete(), isDangerText: true, isDisabled: true },
                    { text: i18n.t('button_cancel'), onPress: () => this.closeWarning() },
                ],
            },
            { isContainer: true, isNoPadding: true, isMaxWidthLimited: true, fadeTransition: true }
        );
    };

    get membersInfo() {
        const { numMembersText, invitedByText } = this.props;
        return (
            <>
                <p className={css(styles.membersText)}>{numMembersText}</p>
                {invitedByText ? <p className={css(styles.invitedText)}>{invitedByText}</p> : null}
            </>
        );
    }

    get renderTabBarHeader() {
        const { item, isMember, inviteButtonText } = this.props;

        return (
            <div>
                <Core.Title title={item.name} titleClassName={css(styles.title)} />
                <Core.TextSeeMore
                    className={css(styles.description)}
                    numberOfLines={NUMBER_OF_LINES}
                    isExpandHidden={false}
                    isCollapseHidden={false}
                    isEditing={this.props.communityEditing}
                    isChallengeCommunitiesDetailsPage={true}>
                    <Core.InnerHtml htmlText={item.description} />
                </Core.TextSeeMore>
                {_.get(item.members, 'length') > 0 ? (
                    <Core.AvatarList
                        size="xsmall"
                        members={item.members.slice(0, 5)}
                        numMembers={item.numMembers}
                        onPress={this.allMembersPressed}
                        supplementaryComponent={this.membersInfo}
                        membersAvatarClassName={css(styles.membersAvatar)}
                        containerClassName={css(styles.avatarListContainer)}
                        avatarsContainerClassName={css(styles.avatarsContainerClassName)}
                    />
                ) : null}
                {isMember ? (
                    <Core.Button
                        isSmall={true}
                        onPress={this.inviteToCommunity}
                        type="outlined"
                        className={css(styles.inviteButton)}>
                        <div className={css(styles.inviteButtonContent)}>
                            <Core.Icon
                                fill="regular"
                                type="fa"
                                name="user-plus"
                                size={spacing.s3}
                                color={baseColors.secondary}
                                className={css(styles.plusUsericon)}
                            />
                            <span className={css(styles.inviteButtonText)}>{inviteButtonText}</span>
                        </div>
                    </Core.Button>
                ) : null}
            </div>
        );
    }

    get rightNavIconProps() {
        const { isMember, isUserRole } = this.props;
        return isMember || !isUserRole ? {
            name: 'ellipsis-h',
            onClick: this.openOptions
        } : null;
    }

    render() {
        const { item, communityLoading, communityJoining, history, joinCommunityButtonLabel, imageSrc, notJoinedCommunityLabel } = this.props;
        return (
            <Core.Layout.WiderContainer>
                <Core.BlockingLoading isLoading={communityLoading} />
                <div className={css(styles.cardBody)}>
                    {item ? (
                        <Core.EntityDetailsLayout
                            tabsProps={{ headerComponent: this.renderTabBarHeader }}
                            rightNavIconProps={this.rightNavIconProps}
                            title={item.name}>
                            <Fragment>
                                <Core.Image
                                    className={css(styles.communityImage)}
                                    src={imageSrc}
                                    alt={item.name}
                                />
                                {!this.props.isMember ?
                                    <div className={css(styles.functionalButtonsContainer)}>
                                        <Core.Button
                                            disabled={communityJoining}
                                            isLoading={communityJoining}
                                            onPress={this.props.joinCommunity}
                                            className={css(styles.functionalButton, styles.functionalButtonText)}>
                                            {joinCommunityButtonLabel}
                                        </Core.Button>
                                    </div> :
                                    <Settings.PrivacySection
                                        slug={settingsConstants.PRIVACY_SLUGS.COMMUNITIES}
                                        hasVerticalPadding={true}
                                        isLightStyle={true}
                                    />
                                }
                                <div className={!this.props.isMember ? css(styles.streamHeaderWrapper) : null}>
                                    {!this.props.isMember ? (
                                        <Core.EmptyListSimple message={notJoinedCommunityLabel} iconName="users" />
                                    ) : (
                                        <>
                                            {this.renderCards}
                                            <Feeds.FeedList
                                                history={history}
                                                entityId={item.id}
                                                entityType={feedsConstants.STREAM_ENTITY_TYPES.group}
                                                canLoadContent={item ? this.props.isMember : false}
                                            />
                                        </>
                                    )}
                                </div>
                            </Fragment>
                        </Core.EntityDetailsLayout>
                    ) :
                        this.noContent
                    }
                </div>
            </Core.Layout.WiderContainer>
        );
    }
}

const styles = StyleSheet.create({
    ...baseStyles,
    membersAvatar: {
        ...baseStyles.membersAvatar,
        borderStyle: 'solid'
    },
    functionalButton: importantClass({
        ...baseStyles.functionalButton,
        width: '100%'
    }),
    description: {
        ...appFonts.mdRegular,
        wordBreak: 'break-word',
        wordWrap: 'break-word',
        marginBottom: spacing.s3,
        color: baseColors.grey20
    },
    container: importantClass({
        paddingBottom: 0
    }),
    inviteButton: {
        width: '100%',
        marginTop: important(spacing.s5),
        padding: important(`${spacing.s2}px ${spacing.s3}px`)
    },
    inviteButtonText: {
        ...appFonts.smBold
    },
    membersText: {
        ...baseStyles.membersText,
        marginLeft: spacing.s1
    },
    plusUsericon: {
        marginRight: spacing.s0
    },
    inviteButtonContent: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    avatarsContainerClassName: importantClass({
        flexDirection: 'row-reverse'
    })
});

export default WithCommunityItemDetailsBase(CommunityItemDetails);
