import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { selectors as coreSelectors, translate, Storage, Platform, PLATFORMS, ENTITIES_ACTIVE_TAB } from '../../../core';
import { EVENT_ENTITY_TYPES } from '../../constants';
import * as selectors from '../../selectors';
import { baseColors, spacing } from '../../../../styles';


export default function WithEventsAllBase(WrappedComponent) {
    class EventsAllBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired
        };

        constructor(props) {
            super(props);
            this.TABS = {
                MY_ITEMS: { label: props.i18n.t('myEvents'), id: EVENT_ENTITY_TYPES.MY_ITEMS },
                ALL_ITEMS: { label: props.i18n.t('all'), id: EVENT_ENTITY_TYPES.ALL_ITEMS }
            };
            this.tabs = [this.TABS.ALL_ITEMS, this.TABS.MY_ITEMS];
            const isMyTab = !(Platform.OS === PLATFORMS.web) ? _.get(props.route.params, 'myTab') : null;
            this.state = {
                selectedTab: isMyTab ? this.TABS.MY_ITEMS : this.TABS.ALL_ITEMS
            };
        }

        async componentDidMount() {
            if (Platform.OS === PLATFORMS.web) {
                const selectedTab = await Storage.getItem(ENTITIES_ACTIVE_TAB.EXPLORE_EVENTS);
                if (selectedTab) {
                    this.setState({ selectedTab });
                }
            }
        }

         selectActiveTab = async selectedTab => {
             await this.setState({ selectedTab });

             if (Platform.OS === PLATFORMS.web) {
                 Storage.setItem(ENTITIES_ACTIVE_TAB.EXPLORE_EVENTS, selectedTab);
             }
         };

         get title() {
             return this.props.i18n.t('events');
         }

         render() {
             return (
                 <WrappedComponent
                     {...this.props}
                     selectedTab={this.state.selectedTab}
                     selectActiveTab={this.selectActiveTab}
                     tabs={this.tabs}
                     title={this.title}
                 />
             );
         }
    }

    function mapStateToProps(state, ownProps) {
        const exploreLoadingStatus = _.get(ownProps, 'location.state.exploreLoadingStatus')
            || ownProps.exploreLoadingStatus
            || _.get(ownProps, 'route.params.exploreLoadingStatus');
        const routeParams = _.get(ownProps, 'route.params');
        const isLoadingMyEvents = selectors.isLoadingMyEvents(state) || selectors.isLoadingPastEvents(state);
        const isLoadingAllEvents = selectors.isLoadingRecommendedEvents(state) || selectors.isLoadingInvitedEvents(state);
        return {
            isLoadingMyEvents,
            isLoadingAllEvents,
            isLoading: isLoadingMyEvents || isLoadingAllEvents,
            allEventsLength: selectors.getAllEventsLength(state),
            currentUserId: coreSelectors.getCurrentUserId(state),
            exploreLoadingStatus,
            ...(routeParams || {}),
        };
    }

    return connect(mapStateToProps)(translate()(EventsAllBase));
}

export const styles = {
    main: {
        flex: 1,
        backgroundColor: baseColors.white
    },
    tabs: {
        marginRight: spacing.s4
    },
    headerText: {
        marginLeft: spacing.s3,
        marginRight: spacing.s3,
    }
};
