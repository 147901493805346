import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { withRouter } from 'react-router-dom';
import { components as Core, ROUTES, onKeyPress } from '../../../../core';
import { baseColors, importantStyles, spacing, appFonts } from '../../../../../styles';
import WithCarouselItemBase, { styles as baseStyles } from './CarouselItemBase';

class CarouselItem extends PureComponent {
    static propTypes = {
        achievementId: PropTypes.number.isRequired,
        history: PropTypes.object.isRequired,
        achievement: PropTypes.object.isRequired,
        filledPercentages: PropTypes.number.isRequired,
        rewardText: PropTypes.string.isRequired
    };

    showAchievementDetails = () => {
        const { achievementId } = this.props;
        this.props.history.push(ROUTES.achievementDetails(achievementId));
    };

    render() {
        const { achievement, rewardText, filledPercentages } = this.props;
        const classes = {
            barColorPrimary: css(styles.barColorPrimary),
            colorPrimary: css(styles.colorPrimary)
        };
        return (
            <div
                className={css(styles.mainView)}
                tabIndex="0"
                role="button"
                onKeyDown={onKeyPress.enter(this.showAchievementDetails)}
                onClick={this.showAchievementDetails}>
                <Core.Image src={achievement.iconUrl} className={css(styles.image)} />
                <Core.ProgressBar
                    variant="determinate"
                    filledPercentage={filledPercentages}
                    classes={classes}
                />
                <div className={css(styles.name)}>{achievement.name}</div>
                <span className={css(styles.subtitle)}>{rewardText}</span>
            </div>
        );
    }
}

export default withRouter(WithCarouselItemBase(CarouselItem));

const styles = StyleSheet.create(importantStyles({
    ...baseStyles,
    mainView: {
        marginRight: spacing.s3,
        marginBottom: spacing.s3
    },
    image: {
        ...baseStyles.image,
        objectFit: 'contain',
        margin: '0 auto',
        marginBottom: spacing.s2
    },
    barColorPrimary: {
        backgroundColor: baseColors.primary
    },
    colorPrimary: {
        backgroundColor: baseColors.grey85
    },
    name: {
        ...appFonts.lgMedium,
        marginTop: spacing.s1
    }
}));