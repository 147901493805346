import _ from 'lodash';
import * as types from './actionTypes';
import * as api from './api';
import { actions as coreActions, normalize, constants as coreConstants, Storage } from '../core';
import { getEarnPartnerIdsByCategories } from './services/rewardsActions';
import { ACHIEVEMENTS_STATUSES, REDEEM_PRODUCT_ID } from './constants';
import * as selectors from './selectors';
import { services as watchServices } from '../watch';
import { validateError } from '../../config';

const { addToast } = coreActions;

export function getRewards() {
    return function (dispatch) {
        dispatch({ type: types.GET_REWARDS.REQUEST });
        return api.getRewards()
            .then(res => {
                dispatch({
                    type: types.GET_REWARDS.SUCCESS,
                    payload: { data: res.data }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.GET_REWARDS.ERROR });
            }));
    };
}

export function getHeroProducts(params = {}) {
    return function (dispatch) {
        dispatch({ type: types.GET_HEROS.REQUEST });
        return api.getHeroProducts(params)
            .then(res => {
                dispatch({
                    type: types.GET_HEROS.SUCCESS,
                    payload: {
                        items: res.data.data,
                        total: res.data.total
                    }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.GET_HEROS.ERROR });
            }));
    };
}

export function getRewardsProducts(params = {}, isReplace = true) {
    return function (dispatch) {
        dispatch({ type: types.GET_REWARDS_PRODUCTS.REQUEST });
        return api.getProducts(params)
            .then(res => {
                const featured = _.map(_.filter(res.data.data, { isFeatured: 1 }), item => item.id);
                dispatch({
                    type: types.GET_REWARDS_PRODUCTS.SUCCESS,
                    payload: {
                        ...normalize.normalizeArray(res.data.data),
                        data: res.data.data,
                        featured,
                        total: res.data.total,
                        isReplace
                    }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.GET_REWARDS_PRODUCTS.ERROR });
            }));
    };
}

export function getRewardsProduct(itemId) {
    return function (dispatch) {
        dispatch({ type: types.GET_REWARDS_PRODUCT.REQUEST });
        return api.getProduct(itemId)
            .then(res => {
                dispatch({
                    type: types.GET_REWARDS_PRODUCT.SUCCESS,
                    payload: { data: res.data }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.GET_REWARDS_PRODUCT.ERROR });
            }));
    };
}

export function redeemProduct(data) {
    const actionType = 'redeemRewardSuccessful';
    return function (dispatch) {
        dispatch({ type: types.REDEEM_REWARDS_PRODUCT.REQUEST });
        return api.redeemProduct(data)
            .then(res => {
                dispatch({
                    type: types.REDEEM_REWARDS_PRODUCT.SUCCESS,
                    payload: { toast: { actionType }, data: res.data }
                });
                setTimeout(() => dispatch(coreActions.askUserForAppStoreReviewIfPossible()), coreConstants.APP_RATING_DELAY);
            })
            .catch(error => validateError(error, error => {
                dispatch({
                    type: types.REDEEM_REWARDS_PRODUCT.ERROR,
                    payload: {
                        toast: {
                            title: ''
                        },
                        error,
                        isErrorStore: true
                    }
                });
            }));
    };
}

export function saveProductId(productId) {
    return function (dispatch) {
        Storage.setItem(REDEEM_PRODUCT_ID, productId);
    };
}

export function retrieveRedeemProductId() {
    return function (dispatch) {
        return Storage.getItemSync(REDEEM_PRODUCT_ID);
    };
}

export function clearRedeemProductId() {
    return function (dispatch) {
        Storage.removeItemSync(REDEEM_PRODUCT_ID);
    };
}

export function getRewardsCategories(params = {}, isReplace = true) {
    return function (dispatch) {
        dispatch({ type: types.GET_REWARDS_CATEGORIES.REQUEST });
        return api.getRewardsCategories(params)
            .then(res => {
                dispatch({
                    type: types.GET_REWARDS_CATEGORIES.SUCCESS,
                    payload: {
                        ...normalize.normalizeArray(res.data),
                        total: res.data.length
                    }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.GET_REWARDS_CATEGORIES.ERROR });
            }));
    };
}


/**
 * Clears redeemProduct error in redux store. It is not bundled in redeemProduct()
 * such that a component can ensure SSO authentication has completed before clearing
 * the error.
 */
export function clearRedeemProductError() {
    return function (dispatch) {
        dispatch(coreActions.removeError(types.REDEEM_REWARDS_PRODUCT.NAME));
    };
}

export function getRewardsTransactions(params = {}, isReplace = true) {
    return function (dispatch) {
        dispatch({ type: types.GET_REWARDS_TRANSACTIONS.REQUEST });
        return api.getTransactions(params)
            .then(res => {
                dispatch({
                    type: types.GET_REWARDS_TRANSACTIONS.SUCCESS,
                    payload: {
                        ...normalize.normalizeArray(res.data.data),
                        total: res.data.total,
                        isReplace
                    }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.GET_REWARDS_TRANSACTIONS.ERROR });
            }));
    };
}

export function getRewardsOrders(params = {}, isReplace = true) {
    return function (dispatch) {
        dispatch({ type: types.GET_REWARDS_ORDERS.REQUEST });
        return api.getOrders(params)
            .then(res => {
                dispatch({
                    type: types.GET_REWARDS_ORDERS.SUCCESS,
                    payload: {
                        ...normalize.normalizeArray(res.data.data),
                        total: res.data.total,
                        isReplace
                    }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.GET_REWARDS_ORDERS.ERROR });
            }));
    };
}

export function getRewardsOrder(itemId) {
    return function (dispatch) {
        dispatch({ type: types.GET_REWARDS_ORDER.REQUEST });
        return api.getOrder(itemId)
            .then(res => {
                dispatch({
                    type: types.GET_REWARDS_ORDER.SUCCESS,
                    payload: { data: res.data }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.GET_REWARDS_ORDER.ERROR });
            }));
    };
}

export function gettingShippingAddress() {
    return function (dispatch) {
        dispatch({ type: types.GET_SHIPPING_ADDRESS.REQUEST });
        return api.getShippingAddress()
            .then(res => {
                dispatch({
                    type: types.GET_SHIPPING_ADDRESS.SUCCESS,
                    payload: { data: res.data }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.GET_SHIPPING_ADDRESS.ERROR });
            }));
    };
}

export function validateShippingAddress() {
    return function (dispatch) {
        dispatch(addToast('danger', 'validateShippingAddress'));
    };
}

export function getUserLevels(period = 'week') {
    return function (dispatch, getState) {
        dispatch(getAchievements()).then(achievements => {
            dispatch({ type: types.GET_LEVELS.REQUEST });
            return api.getLevels(period)
                .then(res => {
                    const companyLevels = _.map(res.data.companyLevels, level => {
                        const achievement = _.find(achievements, achievement => Number(level.reward_id) === achievement.id);
                        return {
                            ...level,
                            externalRewardPoints: _.get(achievement, 'externalRewardPoints'),
                            iconUrl: _.get(achievement, 'iconUrl')
                        };
                    });
                    dispatch({
                        type: types.GET_LEVELS.SUCCESS,
                        payload: { levels: { ...res.data, companyLevels } }
                    });
                    const levels = selectors.getLevels(getState());
                    const externalRewardsName = selectors.getExternalRewardName(getState());
                    const companyLevel = selectors.getNextCompanyLevel(getState());
                    watchServices.appleWatchHelper.sendUserLevelToWatch(
                        levels,
                        externalRewardsName,
                        companyLevel
                    );
                })
                .catch(error => validateError(error, error => {
                    dispatch({ type: types.GET_LEVELS.ERROR, payload: { error } });
                }));
        });
    };
}

export function getAchievements(params = {}) {
    return function (dispatch) {
        dispatch({ type: types.GET_ACHIEVEMENTS.REQUEST });
        return api.getAchievements(params)
            .then(res => {
                const earnedItems = _.filter(res.data, item => item.badgeStatus === ACHIEVEMENTS_STATUSES.earned);
                const earnedInProgressItems = _.filter(res.data, item => item.badgeStatus === ACHIEVEMENTS_STATUSES.inProgress && !_.isEmpty(_.filter(item.datesAwarded, item => item.percentage_completion === 1)));
                const inProgressItems = _.filter(res.data, item => item.badgeStatus === ACHIEVEMENTS_STATUSES.inProgress);
                const earned = normalize.normalizeArray([...earnedItems, ...earnedInProgressItems]).ids;
                const inProgress = normalize.normalizeArray(inProgressItems).ids;
                dispatch({
                    type: types.GET_ACHIEVEMENTS.SUCCESS,
                    payload: { ...normalize.normalizeArray(res.data), earned, inProgress }
                });
                return res.data;
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.GET_ACHIEVEMENTS.ERROR, payload: { error } });
            }));
    };
}

export function getUpcomingAchievements() {
    return function (dispatch) {
        dispatch({ type: types.GET_UPCOMING_ACHIEVEMENTS.REQUEST });
        return api.getUpcomingAchievements()
            .then(res => {
                dispatch({
                    type: types.GET_UPCOMING_ACHIEVEMENTS.SUCCESS,
                    payload: { ...normalize.normalizeArray(res.data) }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.GET_UPCOMING_ACHIEVEMENTS.ERROR });
            }));
    };
}

export function getTemporaryRewardRedemptionToken(ssoObject) {
    const actionType = 'getTemporaryRewardRedemptionToken';
    return function (dispatch) {
        dispatch({ type: types.GET_REWARD_REDEMPTION_TOKEN.REQUEST });
        return api.getRedemptionToken(ssoObject)
            .then(res => {
                dispatch({
                    type: types.GET_REWARD_REDEMPTION_TOKEN.SUCCESS,
                    payload: { token: res.data.token }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({
                    type: types.GET_REWARD_REDEMPTION_TOKEN.ERROR,
                    payload: {
                        error,
                        isErrorStore: true,
                        toast: { actionType }
                    }
                });
            }));
    };
}

export function clearTemporaryRewardRedemptionTokenError() {
    return function (dispatch) {
        dispatch(coreActions.removeError(types.GET_REWARD_REDEMPTION_TOKEN.NAME));
    };
}

export function clearTemporaryRewardRedemptionToken() {
    return function (dispatch) {
        dispatch({ type: types.CLEAR_REWARD_REDEMPTION_TOKEN });
    };
}

export function clearRedeemRewardOrderId() {
    return function (dispatch) {
        dispatch({ type: types.CLEAR_REDEEM_REWARD_ORDER_ID });
    };
}

export function getEarnPartners(cached = false) {
    return function (dispatch) {
        const params = { cache: cached ? 1 : 0 };
        dispatch({ type: types.GET_EARNPARTNERS_PARTNERS.REQUEST });
        return api.getEarnPartners(params)
            .then(res => {
                dispatch({
                    type: types.GET_EARNPARTNERS_PARTNERS.SUCCESS,
                    payload: {
                        ...normalize.normalizeArray(res.data, 'earnPartnerId'),
                        categories: getEarnPartnerIdsByCategories(res.data),
                        total: res.data.length,
                        isReplace: true
                    }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.GET_EARNPARTNERS_PARTNERS.ERROR, payload: { error } });
            }));
    };
}

export function getEarnPartner(partnerId) {
    return function (dispatch) {
        dispatch({ type: types.GET_EARNPARTNERS_PARTNER.REQUEST });
        return api.getEarnPartner(partnerId)
            .then(res => {
                dispatch({
                    type: types.GET_EARNPARTNERS_PARTNER.SUCCESS,
                    payload: { data: res.data }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.GET_EARNPARTNERS_PARTNER.ERROR, payload: { error } });
            }));
    };
}

export function getEarnPartnerCategories() {
    return function (dispatch) {
        dispatch({ type: types.GET_EARNPARTNERS_CATEGORIES.REQUEST });
        return api.getEarnPartnerCategories()
            .then(res => {
                dispatch({
                    type: types.GET_EARNPARTNERS_CATEGORIES.SUCCESS,
                    payload: { data: res.data }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.GET_EARNPARTNERS_CATEGORIES.ERROR, payload: { error } });
            }));
    };
}

export function linkNewEarnPartnerLink(params) {
    const partnerId = params.earnPartnerId;
    return function (dispatch) {
        dispatch({ type: types.UPDATE_EARNPARTNERS_LINK.REQUEST });
        return api.postEarnPartnerLink(params)
            .then(res => {
                dispatch({
                    type: types.UPDATE_EARNPARTNERS_LINK.SUCCESS,
                    payload: { data: res.data }
                });
                dispatch(getEarnPartner(partnerId));
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.UPDATE_EARNPARTNERS_LINK.ERROR,
                    payload: {
                        toast: {
                            title: ''
                        },
                        error,
                        isErrorStore: true
                    }
                });
            }));
    };
}

export function updateEarnPartnerLink(params) {
    return function (dispatch) {
        dispatch({ type: types.UPDATE_EARNPARTNERS_LINK.REQUEST });
        return api.deleteEarnPartnerLink(params)
            .then(res => {
                dispatch(linkNewEarnPartnerLink(params));
            })
            .catch(error => validateError(error, error => {
                dispatch({
                    type: types.UPDATE_EARNPARTNERS_LINK.ERROR,
                    payload: {
                        toast: {
                            title: ''
                        },
                        error,
                        isErrorStore: true
                    }
                });
            }));
    };
}

export function unlinkEarnPartnerLink(params) {
    const partnerId = params.earnPartnerId;
    return function (dispatch) {
        dispatch({ type: types.UPDATE_EARNPARTNERS_LINK.REQUEST });
        return api.deleteEarnPartnerLink(params)
            .then(res => {
                dispatch({
                    type: types.UPDATE_EARNPARTNERS_LINK.SUCCESS,
                    payload: { data: res.data }
                });
                dispatch(getEarnPartner(partnerId));
            })
            .catch(error => validateError(error, error => {
                dispatch({
                    type: types.UPDATE_EARNPARTNERS_LINK.ERROR,
                    payload: {
                        toast: {
                            title: ''
                        },
                        error,
                        isErrorStore: true
                    }
                });
            }));
    };
}
