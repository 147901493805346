import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { translate, parsers } from '../../../../core';
import { spacing } from '../../../../../styles';
import * as actions from '../../../actions';
import * as helper from '../../../services/profileEditorHelper';
import {
    isLoadingUserBiometrics,
    getUserBiometricsData,
    getUserBiometricsCustomComponent,
    isSavingUserBiometrics
} from '../../../selectors';

export default function WithBiometricsEditorBase(WrappedComponent) {
    class BiometricsEditorBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            hasUnsavedChanges: PropTypes.func,
            biometricFields: PropTypes.array,
            biometricCustomComponent: PropTypes.object,
            isLoading: PropTypes.bool,
            isSaving: PropTypes.bool,
            i18n: PropTypes.object.isRequired
        };

        static defaultProps = {
            biometricFields: [],
            biometricCustomComponent: {},
            hasUnsavedChanges: null,
            isLoading: false,
            isSaving: false
        };

        static getDerivedStateFromProps(nextProps, prevState) {
            const state = { isLoadingOrSaving: nextProps.isLoadingOrSaving };
            let { biometricFields } = nextProps;
            if (prevState.isLoadingOrSaving && !nextProps.isLoadingOrSaving) {
                biometricFields = _.map(nextProps.biometricFields, field => ({
                    ...field,
                    defaultValue: field.value,
                    storedData: {
                        value: field.value,
                        unit: field.unit
                    }
                }));
                return {
                    ...state,
                    biometricFields,
                };
            }
            return state;
        }

        constructor(props) {
            super(props);
            this.state = {
                biometricFields: props.biometricFields,
                errors: {}
            };
            this.props.actions.getUserBiometrics();
        }

        getDefaultUnitName = item => _.get(_.find(item.supportedUnits, ['slug', item.defaultUnit]), 'name');

        isTextNaN = text => _.isNaN(_.toNumber(text));

        updateValueByUnit = (item, newUnitName) => {
            this.trackChanges(true);
            if (item.storedData.unit === newUnitName) return item.storedData.value;

            const defaultUnit = this.getDefaultUnitName(item);
            const userPreferredUnit = _.find(item.supportedUnits, ['name', newUnitName]);
            const itemValue = _.get(item, 'defaultValue') || _.get(item, 'value', '');

            if (itemValue) {
                return (newUnitName !== defaultUnit && !this.isTextNaN(itemValue)) ?
                    parsers.stringExpressionToValue(
                        _.get(userPreferredUnit, 'conversionFromDefault', '').replace('$x', itemValue)
                    ).toFixed(2) : itemValue;
            }
            return '';
        };

        saveChanges = () => {
            const errors = helper.validateProfileValues(this.state.biometricFields, this.state.userPreferredWaistCircumferenceUnit);
            this.setState(() => ({ errors }));
            if (!_.isEmpty(errors)) {
                this.props.actions.validateProfileDataError();
            }
            else {
                this.trackChanges(false);
                const biometricsSend = {};
                _.forEach(this.state.biometricFields, field => {
                    biometricsSend[field.stateKey] = _.toString(field.defaultValue);
                });
                this.props.actions.saveUserBiometrics(biometricsSend);
                return true;
            }
        };

        onChangeText = item => text => {
            this.trackChanges(true);
            if (this.isTextNaN(text)) return;
            const userPreferredUnit = _.find(item.supportedUnits, ['name', item.unit]);
            const newDefaultValue =
                userPreferredUnit &&
                item.unit !== this.getDefaultUnitName(item) &&
                text &&
                !this.isTextNaN(text) ?
                    parsers.stringExpressionToValue(
                        _.get(userPreferredUnit, 'conversionToDefault', '').replace('$x', text)
                    ).toFixed(2) : text;
            // if user changes value after toggling the unit, we store the new value and unit
            const newStoredData = (item.unit !== item.storedData.unit) ? {
                value: text,
                unit: item.unit
            } : { value: text };
            this.setState(oldState => {
                const biometricFields = helper.updateBiometricUnit(
                    oldState.biometricFields,
                    item.stateKey,
                    item.unit,
                    text,
                    newDefaultValue,
                    newStoredData
                );
                return { biometricFields };
            });
        };

        onUnitChange = item => currentValue => {
            if (item.supportedUnits && item.supportedUnits.length > 1) {
                const newValue = this.updateValueByUnit(item, currentValue);
                const biometricFields = helper.updateBiometricUnit(this.state.biometricFields, item.stateKey, currentValue, newValue);
                this.setState({ biometricFields });
            }
        };

        trackChanges = hasChanges => this.props.hasUnsavedChanges && this.props.hasUnsavedChanges(hasChanges);

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    saveChanges={this.saveChanges}
                    onUnitChange={this.onUnitChange}
                    onChangeText={this.onChangeText}
                    biometricFields={this.state.biometricFields}
                    errors={this.state.errors}
                />
            );
        }
    }

    const mapStateToProps = state => {
        const isLoading = isLoadingUserBiometrics(state);
        const isSaving = isSavingUserBiometrics(state);
        return {
            biometricFields: getUserBiometricsData(state),
            biometricCustomComponent: getUserBiometricsCustomComponent(state),
            isLoading,
            isSaving,
            isLoadingOrSaving: isLoading || isSaving
        };
    };

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(BiometricsEditorBase));
}

export const styles = {
    innerContainer: {
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3
    }
};
