import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import _ from 'lodash';

import { baseColors, spacing } from '../../../../../styles';
import WithWellbeingProgressIndicatorBase, { styles as baseStyles } from './WellbeingProgressIndicatorBase';
import { components as Core } from '../../../../core';

const BREAKPOINTS = [50, 100, 150];
const START_ANGLE = 135;
const ALL_DEGREES = 270;

const GRADIENTS = [
    {
        linearProps: {},
        stops: [
            {
                offset: '100%',
                stopColor: baseColors.gradientRed
            }
        ]
    }, {
        linearProps: { x1: '20%', y1: '0%', x2: '50%', y2: '100%' },
        stops: [
            {
                offset: '0%',
                stopColor: baseColors.gradientYellow
            },
            {
                offset: '20%',
                stopColor: baseColors.gradientYellow
            },
            {
                offset: '100%',
                stopColor: baseColors.gradientRed
            }
        ]
    }, {
        linearProps: { x1: '0%', y1: '0%', x2: '80%', y2: '100%' },
        stops: [
            {
                offset: '0%',
                stopColor: baseColors.gradientGreen,
                stopOpacity: 0.8
            },
            {
                offset: '15%',
                stopColor: baseColors.gradientYellow
            },
            {
                offset: '50%',
                stopColor: baseColors.gradientYellow
            },
            {
                offset: '100%',
                stopColor: baseColors.gradientRed
            }
        ]
    }, {
        linearProps: { x1: '10%', y1: '80%', x2: '100%', y2: '100%' },
        stops: [
            {
                offset: '0%',
                stopColor: baseColors.gradientGreen
            },
            {
                offset: '30%',
                stopColor: baseColors.gradientYellow
            },
            {
                offset: '50%',
                stopColor: baseColors.gradientYellow
            },
            {
                offset: '100%',
                stopColor: baseColors.gradientRed
            }
        ]
    }
];

class WellbeingProgressIndicator extends PureComponent {
    static propTypes = {
        score: PropTypes.number,
        children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
        onClick: PropTypes.func
    };

    static defaultProps = {
        children: null,
        score: 0,
        onClick: undefined
    };

    getAngleByScore = score => (score * ALL_DEGREES / 100);

    injectSvg = () => {
        const angle = this.getAngleByScore(this.props.score);
        let obj = {};
        if (angle < BREAKPOINTS[0]) obj = GRADIENTS[0];
        else if (angle >= BREAKPOINTS[0] && angle < BREAKPOINTS[1]) obj = GRADIENTS[1];
        else if (angle>= BREAKPOINTS[1] && angle < BREAKPOINTS[2]) obj = GRADIENTS[2];
        else if (angle >= BREAKPOINTS[2]) obj = GRADIENTS[3];

        return (
            <linearGradient id="gr1" {...obj.linearProps}>
                {_.map(obj.stops, (item, i) => <stop {...item} key={i} />)}
            </linearGradient>
        );
    };

    render() {
        return (
            <div className={css(styles.circleContainer)}>
                <Core.ProgressCircle
                    size={10}
                    onClick={this.props.onClick}
                    startAngle={START_ANGLE}
                    lengthAngle={ALL_DEGREES}
                    lineWidth={15}
                    animate={true}
                    progress={this.props.score}
                    progressColor="url(#gr1)"
                    text={this.props.children}
                    injectSvg={this.injectSvg()}
                />
            </div>
        );
    }
}

const CONTAINER_SIZE = spacing.s4 * 10;

const styles = StyleSheet.create({
    ...baseStyles,
    circleContainer: {
        width: CONTAINER_SIZE,
        height: CONTAINER_SIZE,
        alignSelf: 'center'
    }
});

export default WithWellbeingProgressIndicatorBase(WellbeingProgressIndicator);