import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import WithEditChallengeImageBase from './EditChallengeImageBase';
import { components as Core } from '../../../core';

class CreateChallengeImage extends PureComponent {
    static propTypes = {
        onPress: PropTypes.func,
        buttonTitle: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        entityType: PropTypes.string.isRequired,
        onImageChange: PropTypes.func.isRequired,
        pictureFull: PropTypes.string,
        close: PropTypes.func.isRequired,
        isChallengeUpdating: PropTypes.bool
    };

    static defaultProps = {
        onPress: null,
        pictureFull: undefined,
        isChallengeUpdating: false
    };

    finish = () => this.props.close();

    render() {
        return (
            <Fragment>
                <Core.BlockingLoading isLoading={this.props.isChallengeUpdating} />
                <Core.SubHeader
                    title={this.props.title}
                    titleSize={Core.SubHeader.SIZES.large}
                    hasBackButton={false}
                    left={<Core.CloseButton onClick={this.finish} isModal={true}/>}
                    right={<Core.NavButtonText text={this.props.buttonTitle} onClick={this.props.onPress} />}
                />
                <Core.ImageUploader
                    hasDeleteOption={false}
                    fileChanged={this.props.onImageChange}
                    picture={this.props.pictureFull}
                    entityType={this.props.entityType}
                />
            </Fragment>
        );
    }
}

export default WithEditChallengeImageBase(CreateChallengeImage);
