import React from 'react';
import PropTypes from 'prop-types';
import { SkeletonTheme } from 'react-loading-skeleton';
import { baseColors } from '../../../../styles';

// eslint-disable-next-line react/display-name
const SkeletonContent = React.memo(({ children, isDark, primaryColor, ...props }) => {
    const defaultPrimaryColor = isDark ? baseColors.dashboardProgressBackground : baseColors.grey80;
    return (
        <SkeletonTheme
            color={primaryColor ? primaryColor : defaultPrimaryColor}
            highlightColor={isDark ? baseColors.grey80 : baseColors.grey85}
            {...props}>
            {children}
        </SkeletonTheme>
    );
});

SkeletonContent.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
    isDark: PropTypes.bool,
    primaryColor: PropTypes.string,
};

SkeletonContent.defaultProps = {
    isDark: false,
    primaryColor: null,
};

export default SkeletonContent;