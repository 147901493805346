import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { selectors as coreSelectors, translate } from '../../../../core';
import { actions as authActions, selectors as authSelectors } from '../../../../auth';
import { appFonts, baseColors, spacing } from '../../../../../styles';
import { isLanguageChanged } from '../../../selectors';
import { actions as onboardingActions } from '../../../../onboarding';

const BORDER_COLOR = baseColors.grey85;

export default function WithProfileEditorBase(WrappedComponent) {
    class ProfileEditorBase extends PureComponent {
        static propTypes = {
            currentUser: PropTypes.object.isRequired,
            userEmail: PropTypes.string.isRequired,
            i18n: PropTypes.object.isRequired,
            isBiometricsEnabled: PropTypes.bool.isRequired
        };

        getHealthTopics = () => this.props.actions.getHealthTopics(this.props.userId);

        getProfileItems = funcObj => {
            const { i18n, isBiometricsEnabled } = this.props;

            const items = [
                { title: i18n.t('basicPhysicalDetails'), subtitle: i18n.t('physicalDetailsDescription'), onPress: funcObj.personal },
                { title: i18n.t('myWellbeingInterests'), onPress: funcObj.interests },
                { title: i18n.t('my_heatlh_obj'), onPress: funcObj.objectives }
            ];

            return isBiometricsEnabled ? [...items, { title: i18n.t('biometrics'), onPress: funcObj.biometrics }] : items;
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    getProfileItems={this.getProfileItems}
                    getHealthTopics={this.getHealthTopics}
                />
            );
        }
    }

    const mapStateToProps = state => {
        const currentUser = coreSelectors.getCurrentUser(state);
        return {
            currentUser,
            userEmail: _.get(currentUser, 'email', ''),
            isLanguageChanged: isLanguageChanged(state),
            userId: coreSelectors.getCurrentUserId(state),
            isBiometricsEnabled: authSelectors.isBiometricsEnabled(state)
        };
    };

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators({ ...authActions, ...onboardingActions }, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(ProfileEditorBase));
}

export const styles = {
    emailView: {
        marginLeft: spacing.s4,
        top: -spacing.s3
    },
    emailText: {
        color: baseColors.grey40
    },
    header: {
        marginBottom: spacing.s5
    },
    listItemTitle: {
        ...appFonts.lgMedium,
        color: baseColors.black
    },
    lastListItem: {
        borderTopWidth: 10,
        borderTopColor: BORDER_COLOR
    },
    firstListItem: {
        borderBottomWidth: 10,
        borderBottomColor: BORDER_COLOR
    },
    disclaimer: {
        color: baseColors.grey40,
        top: spacing.s3,
        marginBottom: spacing.s3
    }
};
