import React from 'react';
import PropTypes from 'prop-types';
import { translate } from '../../services';

export default function WithViewAllButtonBase(WrappedComponent) {
    const ViewAllButtonBase = props => {
        const text = `${props.text || props.i18n.t('viewAllButton')} ${props.number || ''}`;
        return <WrappedComponent {...props} text={text} />;
    };

    ViewAllButtonBase.propTypes = {
        text: PropTypes.string,
        number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        i18n: PropTypes.object.isRequired
    };

    ViewAllButtonBase.defaultProps = {
        text: undefined,
        number: undefined,
    };

    return translate()(ViewAllButtonBase);
}
