import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { translate } from '../../../core';
import { isResetingPassword, getResetPasswordError } from '../../selectors';

const ERRORS = {
    missing_field: i18n => i18n.t('resetPasswordMissingField'),
    invalid_parameter_value: i18n => i18n.t('resetPasswordInvalidValue'),
    resource_not_found: i18n => i18n.t('resetPasswordNotFound'),
    default: i18n => i18n.t('resetPasswordNotCompleted')
};

export default function WithResetPasswordBase(WrappedComponent) {
    class ResetPasswordBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            resetPassword: PropTypes.object,
            isLoading: PropTypes.bool,
            i18n: PropTypes.object.isRequired,
            error: PropTypes.object,
            email: PropTypes.string
        };

        static defaultProps = {
            resetPassword: {},
            isLoading: false,
            error: undefined,
            email: ''
        };

        constructor(props) {
            super(props);
            this.state = { email: '', showError: false };
        }

        componentDidMount() {
            this.setState({ email: this.props.email });
        }

        componentDidUpdate(prevProps) {
            if (prevProps.isLoading && !this.props.isLoading && !this.props.error) {
                this.wrapped.goBack();
            }
        }

        sendResetEmail = () => {
            this.setState(() => ({ showError: true }));
            this.props.actions.resetPassword(this.state.email);
        };

        get errorMessage() {
            if (!this.state.showError || !this.props.error) return '';
            return ERRORS[this.props.error.errorCode] ?
                ERRORS[this.props.error.errorCode](this.props.i18n) : ERRORS.default(this.props.i18n);
        }

        _clearError = () => {
            if (this.props.error) {
                this.props.actions.clearResetPasswordError();
                this.setState(() => ({ showError: false }));
            }
        };

        onChangeEmail = email => {
            this.setState({ email });
            this._clearError();
        };

        saveRef = ref => {
            this.wrapped = ref;
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    ref={this.saveRef}
                    onChangeEmail={this.onChangeEmail}
                    sendResetEmail={this.sendResetEmail}
                    errorMessage={this.errorMessage}
                    email={this.state.email}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        const email = _.get(ownProps, 'email') || _.get(ownProps, 'match.params.email') || _.get(ownProps, 'route.params.email');
        return {
            email,
            isLoading: isResetingPassword(state),
            error: getResetPasswordError(state)
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(ResetPasswordBase));
}
