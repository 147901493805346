import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as selectors from '../../../selectors';
import { selectors as coreSelectors, translate } from '../../../../core';
import * as actions from '../../../actions';
import { appFonts, spacing } from '../../../../../styles';
import { isLoadingChallengesList } from '../../../selectors';

export default function WithChallengesBase(WrappedComponent) {
    class ChallengeInvitationsBase extends Component {

        get invitationsArray() {
            const invitedChallenges = _.flatten(this.props.invitedChallenges);
            const invitationsArray = [];
            _.map(invitedChallenges, challenge => _.map(challenge?.invitation?.invitedBy, item => invitationsArray.push({ invitation: item, challenge })));
            return _.flattenDeep(invitationsArray);
        }

        get invitesTitle() {
            return this.props.i18n.t('invites');
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    invitationsArray={this.invitationsArray}
                    invitesTitle={this.invitesTitle}
                />
            );
        }
    }

    const mapStateToProps = state => ({
        userId: coreSelectors.getCurrentUserId(state),
        invitedChallenges: selectors.getActiveInvites(state),
        isLoadingChallenges: isLoadingChallengesList(state),
    });

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(ChallengeInvitationsBase));

}


export const styles = {
    titleText: {
        ...appFonts.lgBold,
        paddingTop: spacing.s0
    },
};
