import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { StyleSheet, css } from 'aphrodite-jss';
import { baseColors, spacing, windowSize, layoutStyle, importantClass } from '../../../../styles';
import ReactionItem from '../ReactionItem';
import WithReactionsListBase, { styles as baseStyles } from './ReactionsListBase';
import { components as Core, onKeyPress } from '../../../core';

class ReactionsList extends PureComponent {
    static propTypes = {
        reactions: PropTypes.array.isRequired,
        loadMoreReactions: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired,
        activeTab: PropTypes.object.isRequired,
        onActiveTabChange: PropTypes.func.isRequired,
        tabs: PropTypes.array.isRequired
    };

    constructor(props) {
        super(props);
    }

    onPress = tab => {
        this.props.onActiveTabChange(tab);
    }

    get tabs() {
        const { tabs } = this.props;

        return _.map(tabs, tab => ({
            ...tab,
            icon: tab.icon && <img src={tab.icon} className={css(styles.tabIcon)} />
        }));
    }

    get emojiFilter() {
        const { activeTab } = this.props;

        return (
            <div className={css(styles.tabsWrapper)}>
                <Core.TabsWithContent
                    tabs={this.tabs}
                    activeTab={activeTab}
                    onChange={this.onPress}
                    isHorizontal={true}
                    tabsContainerClassName={styles.tabsContainer}
                    isFilterEmojiModal={true}
                />
            </div>
        );
    }

    renderItem = ({ item }) => <ReactionItem reactionId={item.id} navigation={this.props.navigation} />;

    render() {
        const { reactions, isLoading, loadMoreReactions } = this.props;

        return (
            <div className={css(styles.mainContainer)}>
                <div className={css(styles.headerInnerContainer)}>
                    {this.emojiFilter}
                    <div
                        tabIndex="0"
                        role="button"
                        onKeyDown={onKeyPress.esc(this.props.close)}
                        onClick={this.props.close}>
                        <Core.Icon
                            type="fa"
                            name="times"
                            size={spacing.s4}
                        />
                    </div>
                </div>
                {isLoading ? (
                    <div className={css(layoutStyle.flexRowCenter)}>
                        <Core.ListLoading key="Loading" />
                    </div>
                ) : (
                    <div className={css(styles.mainList)}>
                        <Core.InfiniteLazyListFadeIn
                            data={reactions}
                            keyExtractor={item => item}
                            renderItem={this.renderItem}
                            onEndReached={loadMoreReactions}
                            onEndReachedThreshold={0.5}
                            hasMore={true}
                            isLoading={isLoading}
                            offset={windowSize.height}
                            overflow={true}
                        />
                    </div>
                )}
            </div>
        );
    }
}

export default _.assign(WithReactionsListBase(ReactionsList));

const styles = StyleSheet.create({
    ...baseStyles,
    mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: spacing.s2,
    },
    mainList: {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        paddingTop: spacing.s3,
        paddingHorizontal: spacing.s3,
        paddingRight: spacing.s2,
        paddingLeft: spacing.s2
    },
    headerInnerContainer: {
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'center',
        maxWidth: '100%',
        height: spacing.s11,
        backgroundColor: baseColors.white,
        zIndex: 100,
        position: 'sticky',
        top: 0,
        boxShadow: 'inset 0px -1px 0px rgba(213, 213, 213, 1)',
        paddingRight: spacing.s2,
        paddingLeft: spacing.s2
    },
    tabsContainer: importantClass({
        display: 'flex',
        justifyContent: 'centre',
    }),
    tabsWrapper: {
        overflowX: 'scroll',
        width: '85%',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
    }
});
