import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { baseColors, spacing, appFonts } from '../../../../styles';
import { translate } from '../../services';

export default function WithStepsBase(WrappedComponent) {
    class StepsBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            componentType: PropTypes.string.isRequired,
            propsToPass: PropTypes.object.isRequired,
            showNextScreens: PropTypes.array,
            showCreateScreens: PropTypes.array,
            cancelModalData: PropTypes.object.isRequired,
            i18n: PropTypes.object.isRequired,
            isSingleStep: PropTypes.bool,
            buttonLabel: PropTypes.string,
            goBack: PropTypes.func,
            showArrowBack: PropTypes.bool
        };

        static defaultProps = {
            isSingleStep: false,
            buttonLabel: undefined,
            goBack: null,
            showNextScreens: [],
            showCreateScreens: [],
            showArrowBack: false
        };

        get isFirstPage() {
            return this.props.isSingleStep ||  this.props.propsToPass.step === this.props.componentType;
        }

        get isBackButtonShown() {
            return !this.props.showArrowBack && !this.isFirstPage;
        }

        getModalProps = () => ({
            iconName: 'exclamation-circle',
            title: this.props.cancelModalData.title,
            text: this.props.cancelModalData.text,
            isButtonVisible: false
        });

        get isNextButtonShown() {
            return this.props.isSingleStep || _.includes([...this.props.showNextScreens, ...this.props.showCreateScreens], this.props.componentType);
        }

        get nextButtonLabel() {
            const { buttonLabel, isSingleStep, i18n, showCreateScreens, componentType } = this.props;
            if(buttonLabel) return buttonLabel;
            return isSingleStep || _.includes(showCreateScreens, componentType) ?
                i18n.t('create') : i18n.t('next');
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    getModalProps={this.getModalProps}
                    isFirstPage={this.isFirstPage}
                    isNextButtonShown={this.isNextButtonShown}
                    nextButtonLabel={this.nextButtonLabel}
                    isBackButtonShown={this.isBackButtonShown}
                />
            );
        }
    }

    return translate()(StepsBase);
}

export const styles = {
    infoContainer: {
        backgroundColor: baseColors.white,
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    personalizeText: {
        ...appFonts.mdMedium
    },
    progressBarOffset: {
        marginTop: spacing.s6
    }
};
