import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { appFonts, baseColors, spacing } from '../../../../../styles';
import { translate, parsers, numbers } from '../../../../core';
import { getChallenge } from '../../../selectors';

export const NUMBER_OF_LINES = 3;

export default function WithChallengeInstructionBase(WrappedComponent) {
    class ChallengeInstructionBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            challenge: PropTypes.object.isRequired,
        };

        get instructionWithoutBr() {
            return parsers.replaceAllBr(this.instruction);
        }

        get instruction() {
            const instruction = parsers.htmlDecode(this.props.challenge.challengeInstruction).split(' ');
            return numbers.numberWithCommasInText(instruction);
        }

        render() {
            if (_.get(this.props.challenge, 'challengeInstruction.length') <= 1) return null;
            return (
                <WrappedComponent
                    {...this.props}
                    instruction={this.instruction}
                    instructionWithoutBr={this.instructionWithoutBr}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => ({
        challenge: getChallenge(state, ownProps.challengeId)
    });

    return connect(mapStateToProps)(translate()(ChallengeInstructionBase));
}

export const styles = {
    cardBodyInstructionPanel: {
        paddingTop: spacing.s3,
    },
    cardBodyInstructionTitle: {
        ...appFonts.smBold,
        color: baseColors.grey
    },
    cardBodyInstructionText: {
        color: baseColors.grey20,
        textAlign: 'left',
        ...appFonts.mdRegular
    },
    seeMoreStyle: {
        ...appFonts.mdBold,
    },
};
