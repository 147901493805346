import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { appFonts, baseColors, spacing } from '../../../../styles';

export const ICON_SIZE = spacing.s7;

export default function WithInfoSectionBase(WrappedComponent) {
    class InfoSectionBase extends PureComponent {
        static propTypes = {
            onPress: PropTypes.func
        };

        static defaultProps = {
            onPress: null
        };

        onPress = () => this.props.onPress && this.props.onPress();


        render() {
            return <WrappedComponent {...this.props} onPress={this.onPress} />;
        }
    }
    return InfoSectionBase;
}

export const styles = {
    innerContainer: {
        backgroundColor: baseColors.white,
        borderRadius: spacing.s1,
        elevation: 5,
        padding: spacing.s3,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flex: 1
    },
    title: {
        ...appFonts.lgMedium
    },
    subtitle: {
        ...appFonts.smMedium,
        marginTop: spacing.s0,
        color: baseColors.secondary
    },
    dataContainer: {
        flex: 1,
        marginLeft: spacing.s3
    },
    icon: {
        marginTop: spacing.s0
    }
};
