import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { withRouter } from 'react-router-dom';

import { components as Core, ROUTES } from '../../../core';
import { components as Invitations } from '../../../invitations';
import { importantStyles, layoutStyle, libraryContentImageWidth, libraryContentImageHeight } from '../../../../styles';
import WithCommunityItemBase, { styles as baseStyles } from './CommunityItemBase';

class CommunityItem extends PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
        item: PropTypes.object.isRequired,
        containerClassName: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
        imageUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        inviteCommunityType: PropTypes.string.isRequired,
        communityEventsText: PropTypes.string.isRequired,
        locationChangeCallback: PropTypes.func.isRequired,
    };

    static defaultProps = {
        containerClassName: undefined,
        imageUrl: undefined
    };

    get communityHeader() {
        const { item: { isInvitation, invitedBy }, inviteCommunityType } = this.props;
        return isInvitation ? (
            <Invitations.Invitation
                invitedBy={invitedBy}
                type={inviteCommunityType}
                containerClassName={css(styles.communityInvitationContainer)}
            />
        ) : null;
    }

    onDetailsPress = () => {
        const { history, item, locationChangeCallback, openInNewWindow } = this.props;
        if (locationChangeCallback) locationChangeCallback();
        if (openInNewWindow) {
            window.open(ROUTES.communityDetails(item.id), { rel: 'noopener noreferrer' });
        } else {
            history.push(ROUTES.communityDetails(item.id));
        }
    };

    render() {
        const { item, imageUrl, communityEventsText } = this.props;
        return (
            <Core.TouchableItem
                onPressSingle={this.onDetailsPress}
                separator={false}
                containerClassName={css(layoutStyle.entityItemMainContainer, layoutStyle.flex, styles.mainContainer)}
                innerContainerClassName={css(layoutStyle.flex, layoutStyle.flex1)}
                imageWidth={libraryContentImageWidth}
                imageHeight={libraryContentImageHeight}
                image={imageUrl}
                headerComponent={this.communityHeader}
                item={item}>
                <div className={css(styles.communityItemContainer, layoutStyle.flex1)}>
                    <Fragment>
                        <Core.ExploreItemTitle text={item.name} />
                        <div className={css(styles.overlapAvatarList)}>
                            <Core.ExploreItemMembers members={item.members} isCommunity={true} numParticipants={item.numMembers} />
                        </div>
                    </Fragment>
                    {item.numEvents > 0 && (
                        <Core.TextSeeMore
                            numberOfLines={1}
                            className={css(styles.communityDetails)}
                            isExpandHidden={true}>
                            {communityEventsText}
                        </Core.TextSeeMore>
                    )}
                </div>
            </Core.TouchableItem>
        );
    }
}

const styles = StyleSheet.create(importantStyles({
    ...baseStyles,
    imageStyle: {
        ...baseStyles.imageStyle,
        flexShrink: '0'
    },
    mainContainer: {
        ...baseStyles.mainContainer,
        flexDirection: 'column'
    }
}));

export default withRouter(WithCommunityItemBase(CommunityItem));
