import React, { PureComponent } from 'react';
import { css, StyleSheet } from 'aphrodite-jss';
import { SwipeableDrawer } from '@material-ui/core';
import PropTypes from 'prop-types';
import { styles as baseStyles } from '../ActivityLogList/ActivityLogListBase';
import ActivityLogList from '../ActivityLogList/ActivityLogList';
import { appFonts, baseColors, spacing, windowSize } from '../../../../styles';
import translate from '../../../core/services/translate/translate';
import * as Core from '../../../core/components';


class ActivityLogModal extends PureComponent {

    static propTypes = {
        i18n: PropTypes.object.isRequired,
        isVisible: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
        onOpen: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.scrollParentRef = React.createRef();
    }

    get emptyModalComponent() {
        return <Core.EmptyListSimple message={this.props.i18n.t('no_tracked_activities')} />;
    }

    render() {
        const { isVisible, onClose, onOpen, i18n } = this.props;
        return (
            <SwipeableDrawer
                anchor="right"
                open={isVisible}
                onClose={() => onClose()}
                onOpen={() => onOpen()}>
                <div className={css(styles.activityLogContainer)} ref={this.scrollParentRef}>
                    <h2 className={css(styles.header)}>{i18n.t('activityLog')}</h2>
                    <ActivityLogList
                        overflow={true}
                        itemHasSwipeout={false}
                        emptyModalComponent={this.emptyModalComponent}
                        scrollParentRef={this.scrollParentRef}
                        isInModal={true}
                    />
                </div>
            </SwipeableDrawer>
        );
    }
}

export default translate()(ActivityLogModal);

const MIN_CONTAINER_WIDTH = 380;

const styles = StyleSheet.create({
    ...baseStyles,
    activityLogContainer: {
        minWidth: MIN_CONTAINER_WIDTH,
        height: windowSize.height-spacing.s0,
        overflow: 'auto'
    },
    header: {
        ...appFonts.xxlBold,
        alignSelf: 'center',
        paddingBottom: spacing.s1,
        paddingLeft: spacing.s3,
        paddingTop: spacing.s14,
        position: 'sticky',
        backgroundColor: baseColors.white,
        top: 0,
        zIndex: 1
    }
});
