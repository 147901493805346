/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { withLastLocation } from 'react-router-last-location';
import WithFeedbackBase, { styles as baseStyles } from './FeedbackBase';

import { spacing, layoutStyle } from '../../../../styles';
import { components as Core } from '../../../core';
import { INPUT_MAX_LENGTH } from '../../constants';

const ROW_NUMBER = spacing.s3;

class Feedback extends Component {
    static propTypes = {
        userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        partnerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        isLoading: PropTypes.bool,
        description: PropTypes.string,
        title: PropTypes.string,
        feedbackText: PropTypes.string.isRequired,
        onChange: PropTypes.func,
        getSubmitParams: PropTypes.func,
        postFeedback: PropTypes.func,
        clearError: PropTypes.func,
        isCharsExceeded: PropTypes.bool,
        isCharsError: PropTypes.bool,
        charsRemainingText: PropTypes.string,
        feedbackError: PropTypes.object,    //eslint-disable-line
        option: PropTypes.object.isRequired,
        lastLocation: PropTypes.object, //eslint-disable-line
        i18n: PropTypes.object.isRequired,
        close: PropTypes.func,
        isPopup: PropTypes.bool
    };

    static defaultProps = {
        isLoading: false,
        onChange: null,
        description: '',
        title: '',
        getSubmitParams: null,
        postFeedback: null,
        clearError: null,
        isCharsExceeded: false,
        isCharsError: false,
        charsRemainingText: undefined,
        feedbackError: undefined,
        close: undefined,
        isPopup: false
    };

    submitFeedback = () => {
        const { i18n, userId, postFeedback, partnerId, feedbackText, getSubmitParams } = this.props;
        const { slug, id } = this.props.option;

        const feedbackMsg = `${i18n.t('feedback_email_message') +
            feedbackText +
            i18n.t('feedback_email_user_id') +
            userId
        }`;
        const submitParams = getSubmitParams(slug);
        postFeedback(feedbackText, feedbackMsg, submitParams[0], submitParams[1], submitParams[2], 'web', partnerId, id);
        if(!this.props.isCharsError && this.props.close) {
            this.props.close();
        }
        if (this.props.clearError) this.props.clearError();
    };

    onChange = e => this.props.onChange(e.target.value);

    get wrapper() {
        return this.props.isPopup ? Core.Layout.WiderContainer : Core.Layout.CenteredContainer;
    }

    render() {
        const { isCharsExceeded, isLoading, i18n, isPopup, description, charsRemainingText, title } = this.props;
        const { placeholder } = this.props.option;
        return (
            <Core.Layout.WiderContainer>
                {!isPopup ? (
                    <Core.EntityDetailsHeader
                        hasBackButton={true}
                    />
                ) : null}
                <this.wrapper>
                    <Core.SubHeader
                        title={title}
                        noTopPadding={true}
                        titleSize={Core.SubHeader.SIZES.xlarge}
                        hasBackButton={isPopup}
                        noLeftPadding={true}
                        isModal={isPopup}
                        onModalClose={this.props.close}
                        right={(
                            <Core.Button
                                className={css(styles.saveButton)}
                                disabled={isLoading}
                                isSubmit={true}
                                isLoading={isLoading}
                                onPress={this.submitFeedback}
                                type="text">
                                {i18n.t('submit')}
                            </Core.Button>
                        )}
                    />
                    <span>{description}</span>
                    <Core.Input
                        label={placeholder}
                        onChange={this.onChange}
                        multiline={true}
                        rows={ROW_NUMBER}
                        fullWidth={true}
                        inputProps={{ maxLength: INPUT_MAX_LENGTH }}
                    />
                    <div className={css(styles.charsRemain, isCharsExceeded && layoutStyle.danger)}>{charsRemainingText}</div>
                    <div className={css(styles.footerButtonsContainer)} />
                </this.wrapper>
            </Core.Layout.WiderContainer>
        );
    }
}

const styles = StyleSheet.create({
    ...baseStyles,
    charsRemain: {
        ...baseStyles.charsRemain,
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: spacing.s2,
        paddingBottom: spacing.s2
    },
    footerButtonsContainer: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    saveButton: {
        marginLeft: `${spacing.s2}px !important`
    }
});

export default withLastLocation(WithFeedbackBase(Feedback));
