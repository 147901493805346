import PropTypes from 'prop-types';
import React, { PureComponent, Fragment } from 'react';
import { css, StyleSheet } from 'aphrodite-jss';
import _ from 'lodash';
import OrderAddressItem from '../OrderAddressItem';
import WithProductDetailsBase, { styles as baseStyles } from './OrderDetailsBase';
import { components as Core, ROUTES } from '../../../core';
import { containerStyle, baseColors, importantClass, spacing, appFonts } from '../../../../styles';
import { REWARD_IMAGE_MAX_WIDTH } from '../../constants';

class OrderDetails extends PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
        item: PropTypes.object.isRequired,
        clearRewardOrderId: PropTypes.func.isRequired,
        timeCreated: PropTypes.string,
        i18n: PropTypes.object.isRequired,
        hasInstruction: PropTypes.bool.isRequired,
        hasAddress: PropTypes.bool,
        redeemedText: PropTypes.string,
        pointsRedeemed: PropTypes.string,
        fromRedemption: PropTypes.bool.isRequired,
        title: PropTypes.string,
        instruction: PropTypes.string,
        redeemForText: PropTypes.string.isRequired,
        statusText: PropTypes.string.isRequired,
        orderDetailsText: PropTypes.string.isRequired,
        shouldRenderCheckSection: PropTypes.bool.isRequired
    };

    static defaultProps = {
        title: undefined,
        hasAddress: false,
        redeemedText: undefined,
        pointsRedeemed: undefined,
        timeCreated: undefined,
        instruction: undefined
    };

    onDone = () => {
        const { clearRewardOrderId, history } = this.props;
        clearRewardOrderId();
        history.push(ROUTES.rewards());
    };

    get renderCheckIcon() {
        return (
            <Core.Icon
                type="fa"
                fill="solid"
                name="check-circle"
                size={spacing.s4}
                color={baseColors.success}
                className={css(styles.iconCheckContainer)}
            />
        );
    }

    get renderDoneButton() {
        const { fromRedemption, i18n } = this.props;
        return fromRedemption ? (
            <Core.Button
                onPress={this.onDone}
                className={css(styles.doneButton)}>
                {i18n.t('done')}
            </Core.Button>
        ) : null;
    }

    renderCaruselItem = item => {
        if (!item.value) return null;

        if (item.type === 'url') {
            const displayLink = this.props.getShortLink(item);

            return (
                <div className={css(styles.credentialItem)}>
                    <p className={css(styles.smallTitle)}>{item.label}</p>
                    <Core.TextSeeMore numberOfLines={1} className={css(styles.coreInfoText, styles.link)}>
                        <a href={item.value} className={css(styles.coreInfoText, styles.link)}>
                            {displayLink}
                        </a>
                    </Core.TextSeeMore>
                </div>
            );
        }

        return (
            <div className={css(styles.credentialItem)}>
                <p className={css(styles.smallTitle)}>{item.label}</p>
                <p className={css(styles.coreInfoText)}>{item.value}</p>
            </div>
        );
    }

    render() {
        const {
            item,
            title,
            hasAddress,
            statusText,
            timeCreated,
            instruction,
            redeemedText,
            redeemForText,
            hasInstruction,
            pointsRedeemed,
            fromRedemption,
            orderDetailsText,
            shouldRenderCheckSection
        } = this.props;
        return (
            <Fragment>
                {!fromRedemption ? <Core.EntityDetailsHeader hasBackButton={true} /> : null}
                <div className={css(styles.orderDetailsWrapper)}>
                    <div className={css(containerStyle.container)}>
                        <p className={css(styles.title)}>{title}</p>
                        <div className={css(styles.orderInfoContainer)}>
                            <Core.Image
                                className={css(styles.image)}
                                src={item.largeImageUrl}
                            />
                            <div className={css(styles.orderInfoWrapper)}>
                                <div className={css(styles.infoContainerSpacing)}>
                                    <p className={css(styles.coreInfoText)}>{item.productName}</p>
                                    <p className={css(styles.smallTitle)}>{timeCreated}</p>
                                </div>
                                <div className={css(styles.credentialsItemsWrapper)}>
                                    {_.map(item.credentials, this.renderCaruselItem)}
                                </div>
                                <div className={css(styles.successInfoContainer)}>
                                    {shouldRenderCheckSection && this.renderCheckIcon}
                                    <p className={css(styles.longText)}>{redeemedText}</p>
                                </div>
                                {this.renderDoneButton}
                            </div>
                        </div>
                        <div className={css(styles.orderContainer)}>
                            <p className={css(styles.orderDetailsText)}>{orderDetailsText}</p>
                            <div className={css(styles.orderInnerContainer)}>
                                <div className={css(styles.orderItem)}>
                                    <p className={css(styles.smallTitle)}>{redeemForText}</p>
                                    <p className={css(styles.coreInfoText)}>{pointsRedeemed}</p>
                                </div>
                                <div className={css(styles.orderItem)}>
                                    <p className={css(styles.smallTitle)}>{statusText}</p>
                                    <p className={css(styles.coreInfoText)}>{item.status}</p>
                                </div>
                            </div>
                        </div>
                        <div className={css(styles.additionalInfoContainer)}>
                            {hasInstruction ?
                                <p className={css(styles.longText)}>{instruction}</p> : null
                            }
                        </div>
                        {hasAddress ?
                            <OrderAddressItem address={item.address} /> : null
                        }
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default WithProductDetailsBase(OrderDetails);

const DONE_BUTTON_WIDTH = 200;
const MAX_WIDTH_OF_ORDER_INFO = 485;

const styles = StyleSheet.create({
    ...baseStyles,
    orderDetailsWrapper: {
        paddingLeft: spacing.s15,
        paddingRight: spacing.s15
    },
    orderInfoContainer: {
        display: 'flex',
        width: 'auto%',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    },
    orderInfoWrapper: {
        width: MAX_WIDTH_OF_ORDER_INFO,
        paddingLeft: spacing.s0
    },
    orderContainer: {
        ...baseStyles.orderContainer,
        borderTopStyle: 'solid'
    },
    doneButton: importantClass({
        ...baseStyles.doneButton,
        color: baseColors.white,
        width: DONE_BUTTON_WIDTH
    }),
    title: {
        ...appFonts.xlBold,
        marginTop: spacing.s3,
        marginBottom: spacing.s3
    },
    credentialsItemsWrapper: {
        border: `1px solid ${baseColors.grey70}`,
        padding: spacing.s3,
        borderRadius: spacing.s1,
        marginBottom: spacing.s5,
        paddingBottom: spacing.s0
    },
    credentialItem: {
        marginBottom: spacing.s2
    },
    image: importantClass({
        width: 'auto',
        maxWidth: REWARD_IMAGE_MAX_WIDTH,
        boxShadow: '0px 8px 18px rgba(0, 0, 0, 0.15)',
    }),
    link: importantClass({
        ...baseStyles.link,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        '&:hover': {
            textDecoration: 'none',
            cursor: 'pointer'
        }
    })
});