import { createActionTypes } from '../core';
import { NAME as name } from './constants';

const NAME = name.toUpperCase();
export const GET_USERS_TO_INVITATION = createActionTypes(`${NAME}/GET_USERS_TO_INVITATION`);
export const INVITE_TO_ENTITY = createActionTypes(`${NAME}/INVITE_TO_ENTITY`);
export const GET_USERS_TO_PLATFORM_INVITATION = createActionTypes(`${NAME}/GET_USERS_TO_PLATFORM_INVITATION`);
export const GET_USERS_TO_PLATFORM_INVITATION_MORE = createActionTypes(`${NAME}/GET_USERS_TO_PLATFORM_INVITATION_MORE`);
export const GET_PLATFORM_INVITATIONS = createActionTypes(`${NAME}/GET_PLATFORM_INVITATIONS`);
export const SEND_PLATFORM_INVITATIONS = createActionTypes(`${NAME}/SEND_PLATFORM_INVITATIONS`);
export const DELETE_PLATFORM_INVITATION = createActionTypes(`${NAME}/DELETE_PLATFORM_INVITATION`);
export const GET_USER_BY_EMAIL = createActionTypes(`${NAME}/GET_USER_BY_EMAIL`);
export const CLEAR_USER_BY_EMAIL = `${NAME}/CLEAR_USER_BY_EMAIL`;
export const SHOW_LEARN_FRIENDS_AND_FAMILY_MODAL = `${NAME}/SHOW_LEARN_FRIENDS_AND_FAMILY_MODAL`;