export const numberWithCommas = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const intOrZero = count => count ? parseInt(count) : 0;

export const getBadgeNumber = num => num > 9 ? '9+' : num;

export const getBadgeWidth = num => num > 9 ? 26 : 18;

export const numberWithCommasInText = text => {
    const editedText = text.map(t => {
        const tCopy = t;
        if (!Number.isNaN(parseInt(tCopy))) {
            return numberWithCommas(t);
        }

        return t;
    });

    return editedText.join(' ');
};