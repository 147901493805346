import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { css, StyleSheet } from 'aphrodite-jss';
import { layoutStyle } from '../../../../styles';
import { components as Core } from '../../../core';
import ChallengeProgressCircle from '../ChallengeProgressCircle';
import ActivityHistoryDetailsItem from '../ActivityHistoryDetailsItem';
import WithGoalActivityHistoryDetailsBase, { styles as baseStyles } from './GoalActivityHistoryDetailsBase';

class GoalActivityHistoryDetails extends PureComponent {
    static propTypes = {
        progressCircleValue: PropTypes.number.isRequired,
        arrayOfDatesInPeriod: PropTypes.array.isRequired,
        getActivitiesForDate: PropTypes.func.isRequired,
        nothingTrackedText: PropTypes.string.isRequired,
        getFormattedDate: PropTypes.func.isRequired,
        hasNoActivities: PropTypes.bool.isRequired,
        emptyListText: PropTypes.string.isRequired,
        ofTotalText: PropTypes.string.isRequired,
        earnedText: PropTypes.string.isRequired,
        language: PropTypes.string.isRequired,
        isShowingOnboarding: PropTypes.bool,
        title: PropTypes.string.isRequired,
        pointsForPeriod: PropTypes.string,
        isLoading: PropTypes.bool,
    };

    static defaultProps = {
        isShowingOnboarding: false,
        pointsForPeriod: '0',
        isLoading: false,
    };

    renderListItem = date => {
        const { getActivitiesForDate, arrayOfDatesInPeriod, getFormattedDate, nothingTrackedText } = this.props;
        const activities = getActivitiesForDate(date);
        return (
            <Fragment>
                {_.get(arrayOfDatesInPeriod, 'length') && arrayOfDatesInPeriod.length > 0 ?
                    <div className={css(styles.fullWidthBar)}>
                        <div className={css(layoutStyle.flex1)}>
                            <p className={css(styles.fullBarText)}>{getFormattedDate(date)}</p>
                        </div>
                    </div> : null}
                <div className={css(layoutStyle.commonPaddingHorizontal)}>
                    {activities && activities.length ?
                        _.map(activities[0].logs, this.renderActivity) : (
                            <p className={css(styles.noActivitiesTrackedText)}>
                                {nothingTrackedText}
                            </p>
                        )}
                </div>
            </Fragment>
        );
    };

    renderActivity = activity => <ActivityHistoryDetailsItem activity={activity} />;

    render() {
        const { isLoading, isShowingOnboarding, title, progressCircleValue, language, pointsForPeriod,
            hasNoActivities, arrayOfDatesInPeriod, earnedText, ofTotalText, emptyListText } = this.props;
        return isLoading ? <Core.BlockingLoading isLoading={isLoading} /> :(
            <Core.SmallerContainer
                widerHeaderComponent={(
                    <Core.EntityDetailsHeader
                        isOnboarding={isShowingOnboarding}
                        hasBackButton={true}
                    />)
                }>
                <Core.SubHeader
                    title={title}
                    noTopPadding={true}
                />
                <div className={css(styles.headingContainer)}>
                    <div className={css(styles.contentSubContainer)}>
                        <ChallengeProgressCircle fill={progressCircleValue} language={language}/>
                    </div>
                    <div className={css(styles.contentSubContainer)}>
                        <div>
                            <p className={css(styles.flexRowTitleText)}>{earnedText}</p>
                            <p className={css(styles.flexRowValueText)}>
                                {pointsForPeriod}
                            </p>
                            <p className={css(styles.flexRowTitleText)}>
                                {ofTotalText}
                            </p>
                        </div>
                    </div>
                </div>
                {hasNoActivities ?
                    <p className={css(styles.noPeriods)}>{emptyListText}</p> : (
                        <Core.List>
                            {_.map(arrayOfDatesInPeriod, this.renderListItem)}
                        </Core.List>
                    )}
            </Core.SmallerContainer>
        );
    }
}

export default WithGoalActivityHistoryDetailsBase(GoalActivityHistoryDetails);

const styles = StyleSheet.create({
    ...baseStyles
});
