import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { appFonts, baseColors, spacing } from '../../../../styles';

export default function WithExploreItemDescriptionBase(WrappedComponent) {
    class ExploreItemDescriptionBase extends PureComponent {
        static propTypes = {
            text: PropTypes.string,
            color: PropTypes.string,
            numberOfLines: PropTypes.number
        };

        static defaultProps = {
            text: '',
            color: baseColors.grey40,
            numberOfLines: 2
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                />
            );
        }
    }

    return ExploreItemDescriptionBase;
}

export const styles = {
    mainContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: spacing.s0,
    },
    text: {
        ...appFonts.mdRegular,
        color: baseColors.grey40
    },
    labelContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: spacing.s1
    }
};
