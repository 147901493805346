import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { appFonts, baseColors, spacing } from '../../../../styles';

export default function WithExploreItemMetaBase(WrappedComponent) {
    class ExploreItemMetaBase extends PureComponent {
        static propTypes = {
            iconName: PropTypes.string,
            iconStyle: PropTypes.string,
            textArray: PropTypes.array,
            text: PropTypes.string,
            color: PropTypes.string,
            isCarouselItem: PropTypes.bool
        };

        static defaultProps = {
            iconName: '',
            iconStyle: 'regular',
            textArray: [],
            text: '',
            color: baseColors.grey40,
            isCarouselItem: false
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                />
            );
        }
    }

    return ExploreItemMetaBase;
}

export const styles = {
    mainContainer: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        marginTop: spacing.s1,
        marginBottom: spacing.s0,
    },
    carouselContainer: {
        marginLeft: spacing.s0
    },
    textItem: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    text: {
        ...appFonts.smRegular,
    },
    dotIcon: {
        marginLeft: spacing.s1,
        marginRight: spacing.s1,
    },
    labelContainer: {
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: spacing.s1,
        flexWrap: 'wrap',
    }
};
