import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';

import { layoutStyle } from '../../../../../styles';
import { components as Core, Modal } from '../../../../core';
import WithPrivacySettingsItemBase, { styles as baseStyles } from './PrivacySettingsItemBase';

class PrivacySettingsItem extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool,
        getValue: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired,
        privacySettingsElement: PropTypes.object.isRequired,
        i18n: PropTypes.object.isRequired,
        updateTheValue: PropTypes.func.isRequired,
        translationTitle: PropTypes.string,
        getSubmitParams: PropTypes.func,
        userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        getInfoModalProps: PropTypes.func.isRequired,
        close: PropTypes.func
    };

    static defaultProps = {
        isLoading: true,
        translationTitle: null,
        getSubmitParams: null,
        close: undefined
    };

    onConfirmPrivacyChange = async () => {
        await this.closeModal();
        this.props.close && this.props.close();

        const { slug, privacyTypeId, value } = this.props.privacySettingsElement;

        this.props.updateTheValue(this.props.userId, slug, privacyTypeId, value);
    };

    closeModal = () => {
        this.close && this.close();
    };

    changeSetting = () => {
        const { getInfoModalProps } = this.props;

        this.close = Modal.open(
            Core.InfoModal,
            getInfoModalProps(this.onConfirmPrivacyChange, this.closeModal),
            {
                PaperProps: { elevation: 0 },
                isNoPadding: true,
                isTransparent: true,
                isContainer: true,
                fadeTransition: true,
            }
        );
    };

    renderItem = item => {
        const descriptionToShow = !this.props.getValue(item) ? item.description : item.descriptionOff;

        const option = {
            title: item.name,
            action: this.changeSetting,
            isSwitch: true,
            checked: !item.value
        };

        return (
            <div className={css(styles.itemContainer)}>
                <div className={css(styles.item, layoutStyle.cp)}>
                    <Core.ListOptionPopup {...option} />
                </div>
                <span className={css(styles.textSmall)}>{descriptionToShow}</span>
            </div>
        );
    };

    render() {
        const { privacySettingsElement, i18n, close } = this.props;
        return (
            <>
                {privacySettingsElement.title ?
                    <Core.SubHeader
                        title={privacySettingsElement.title}
                        noTopPadding={true}
                    /> : null
                }
                {privacySettingsElement ? this.renderItem(privacySettingsElement) : null}
                <div className={css(styles.footerButtonsContainer)}>
                    <Core.Button
                        onPress={close}
                        type="text">
                        {i18n.t('close')}
                    </Core.Button>
                </div>
            </>
        );
    }
}

const styles = StyleSheet.create({
    ...baseStyles,
    footerButtonsContainer: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
});

export default WithPrivacySettingsItemBase(PrivacySettingsItem);