import spacing from '../spacing';
import appFonts from '../appFonts';
import baseColors from '../commonColor';
import { windowSize, commonPadding, largeFooterHeight } from '../constants';

const container = {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    backgroundColor: baseColors.white
};

const containerStyle = {
    container: {
        ...container,
        paddingLeft: commonPadding,
        paddingRight: commonPadding,
    },
    containerNoPadding: {
        ...container,
    },
    containerPadding: {
        paddingLeft: commonPadding,
        paddingRight: commonPadding,
    },
    containerPaddingLarger: {
        paddingLeft: spacing.s5,
        paddingRight: spacing.s5,
        paddingTop: spacing.s4,
        paddingBottom: spacing.s5,
    },
    // containerHeight: Platform.select({
    //     android: {
    //         height: Dimensions.get('window').height - 136
    //         // Since the container extends beyond the bottom of the screen, define an explicit
    //         // height of the container to prevent that situation
    //     },
    //     ios: {
    //         flex: 1,
    //     }
    // }),
    header: {
        // height: windowSize.height * 0.13,
        minHeight: windowSize.height * 0.13,
        marginBottom: spacing.s4
        // backgroundColor: 'gray',
    },
    subLogoContainer: {
        marginBottom: windowSize.height * 0.05,
        alignItems: 'center',
    },
    titleContainer: {
        marginLeft: commonPadding,
        marginRight: commonPadding,
        color: baseColors.black,
    },
    subLogoText: {
        ...appFonts.programName,
        color: baseColors.grey40
    },

    footer: {
        minHeight: windowSize.height * 0.05,
        marginBottom: spacing.s10,
    }, // , 100%
    largeFooter: {
        minHeight: largeFooterHeight,
    },
    footerButtonContainer: {
        flexDirection: 'column',
        flex: 1,
        alignSelf: 'stretch',
        marginTop: spacing.s0,
        marginBottom: spacing.s4
    },
    largeFooterButtonContainer: {
        marginTop: spacing.s4,
        marginBottom: spacing.s5
    },
    largeFooterContent: {
        marginLeft: 0,
        marginRight: 0,
        width: '100%'
    },
    positionCenter: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
    },
    button: {
        width: windowSize.width * 0.90,
        marginLeft: spacing.s0,
        marginRight: spacing.s0,
    },
    buttonMedium: {
        width: windowSize.width * 0.85
    },
    buttonText: {
        ...appFonts.lgMedium
    },

    main: {
        flex: 1
    },
    inputContainer: {
        borderBottomWidth: 0,
        marginLeft: commonPadding,
        marginRight: commonPadding,
    },
    fixedMainTop: {
        position: 'relative',
        top: 0,
        left: 0,
        right: 0,
        height: 50,
        alignItems: 'center'
    },
    fixedMainFooter: {
        //  backgroundColor: 'blue',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: 50
    },
    input: {
        color: baseColors.secondary,
        ...appFonts.xsMedium,
        flex: 3
    },
    title: {
        ...appFonts.xlBold,
        textAlign: 'center'
    },
    iconContainer: {
        marginTop: windowSize.height,
        flexDirection: 'row'
    },
    h3: {
        textAlign: 'center',
        alignItems: 'center',
        ...appFonts.lgMedium,
        marginBottom: spacing.s2
    },
    overlay: {
        position: 'absolute',
        left: 0,
        top: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.25)',
        height: windowSize.height,
        width: windowSize.width
    },

    menuItemfocusVisible: {
        '&:focus-visible': {
            outlineColor: baseColors.context,
            outlineStyle: 'solid',
            outlineWidth: spacing.s0 - 2,
            outlineOffset: -spacing.s0 - 2,
            borderRadius: spacing.s1,
        },
    },
    selectedMenuItemfocusVisible: {
        '&:focus-visible': {
            outlineColor: baseColors.white,
        },
    },
    buttonFocusVisible: {
        '&:focus-visible': {
            outlineColor: baseColors.context,
            outlineStyle: 'solid',
            outlineWidth: spacing.s0 - 2,
            outlineOffset: spacing.s0 - 2,
        },
    },
    listItemfocusVisible: {
        '&:focus-visible': {
            outlineColor: baseColors.context,
            outlineStyle: 'solid',
            outlineWidth: spacing.s0 - 2,
            outlineOffset: spacing.s0 - 2,
            borderRadius: spacing.s1
        }
    },
    listItemfocusVisibleWithSmallerOutlineOffset: {
        '&:focus-visible': {
            outlineColor: baseColors.context,
            outlineStyle: 'solid',
            outlineWidth: spacing.s0 - 2,
            outlineOffset: -spacing.s0/2,
            borderRadius: spacing.s1
        }
    }
};

export default containerStyle;
