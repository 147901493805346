import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { baseColors, appFonts, spacing } from '../../../../styles';
import { selectors as coreSelectors, Alert, translate, ActionSheet, tracker } from '../../../core';
import { getCommentsByTypeAndId, isEditingComment, isPostingComment } from '../../selectors';
import { LIKES_ENTITY_TYPES, COMMENT_MODES } from '../../constants';

export const VISIBLE_COMMENTS_COUNT_DEFAULT = 2;

export default function WithCommentsBase(WrappedComponent) {
    class CommentsBase extends Component {
        static propTypes = {
            commentedItemType: PropTypes.oneOf(_.values(LIKES_ENTITY_TYPES)).isRequired,
            commentedItemId: PropTypes.number.isRequired,
            visibleCount: PropTypes.number,
            count: PropTypes.number.isRequired,
            seeMore: PropTypes.func,
            mode: PropTypes.oneOf(_.values(COMMENT_MODES)),
            actions: PropTypes.object.isRequired,
            user: PropTypes.object.isRequired,
            comments: PropTypes.array,
            i18n: PropTypes.object.isRequired,
        };

        static defaultProps = {
            comments: [],
            seeMore: undefined,
            visibleCount: undefined,
            mode: COMMENT_MODES.preview,
        };

        constructor(props) {
            super(props);
            this.state = {
                visibleCount: this.props.visibleCount,
                lastDeletedCommentId: null,
            };
        }

        UNSAFE_componentWillReceiveProps(nextProps) {
            if (this.props.comments !== nextProps.comments) {
                const diff = nextProps.comments.length - this.props.comments.length;
                this.setState(() => ({ visibleCount: _.max([this._visibleCount + diff, VISIBLE_COMMENTS_COUNT_DEFAULT]) }));
            }
        }

        get isFullMode() {
            return this.props.mode === COMMENT_MODES.full;
        }

        get _visibleCount() {
            return this.isFullMode ? this.state.visibleCount : VISIBLE_COMMENTS_COUNT_DEFAULT;
        }

        get comments() {
            return _.takeRight(this.props.comments, this._visibleCount);
        }

        get isSeeMoreShown() {
            return this._visibleCount < this.props.count;
        }

        deleteComment = commentId => {
            const { commentedItemType, commentedItemId } = this.props;
            if (this.state.lastDeletedCommentId === commentId) return;
            this.setState({ lastDeletedCommentId: commentId });
            this.props.actions.deleteComment(commentId, commentedItemId, commentedItemType);
            tracker.logEvent('comment', { event: 'delete', commentId, commentedItemId, commentedItemType });
        };

        tryModerateComment = commentId => {
            const { i18n } = this.props;
            this._openAlert(i18n.t('moderate'), i18n.t('moderateDescription'),
                () => this._moderateComment(commentId));
        };

        _openAlert(title, text, okHandler) {
            const { i18n } = this.props;
            Alert.alert(title, text, [
                { text: i18n.t('ok'), onPress: okHandler, isPrimary: true },
                { text: i18n.t('button_cancel'), style: 'cancel' },
            ]);
        }

        _moderateComment(commentId) {
            const { commentedItemType, commentedItemId } = this.props;
            this.props.actions.moderateComment(commentId, commentedItemId, commentedItemType);
            tracker.logEvent('comment', { event: 'moderate', commentId, commentedItemId, commentedItemType });
        }

        seeMore = () => this.props.seeMore ? this.props.seeMore() : this._defaultSeeMore();

        _defaultSeeMore() {
            const nextCount = this.roundToTen(this._visibleCount);
            if (this.props.comments.length >= this.props.count || nextCount <= this.props.comments.length) {
                this.setState(() => ({ visibleCount: nextCount }));
            } else {
                this.loadComments();
            }
        }

        loadComments() {
            const { comments, commentedItemId, commentedItemType } = this.props;
            this.props.actions.getComments(commentedItemType, commentedItemId, comments[0].commentId);
        }

        roundToTen(n) {
            return Math.ceil((n + 1) / 10) * 10;
        }

        onEdit = async ({ commentId, commentText }, comment) => {
            this.props.actions.editComment({ commentId, commentText }, comment);
            tracker.logEvent('comment', { event: 'edit', id: commentId });
        };

        postComment = commentText => {
            const { commentedItemType, commentedItemId } = this.props;
            tracker.logEvent('comment', { event: 'post', commentedItemType, id: commentedItemId });
            return this.props.actions.postComment(commentedItemType, commentedItemId, commentText, this._createTempComment(commentText));
        };

        _createTempComment(commentText) {
            const id = Date.now();
            const { user } = this.props;
            return {
                commentId: id,
                avatarURL: user.avatarURL,
                owner: { firstName: user.firstNameDisplay, lastName: user.lastNameDisplay },
                commentText,
                dateTimeCreated: new Date()
            };
        }

        isOwner = comment => comment.owner.userId === this.props.user.userId;

        getOptions = (comment, editComment) => {
            const { i18n } = this.props;
            return this.isOwner(comment)
                ? [
                    { title: i18n.t('editComment'), onPress: () => editComment(comment) },
                    { title: i18n.t('deleteComment'), onPress: () => this.deleteComment(comment.commentId) }
                ]
                : [{ title: i18n.t('moderate'), onPress: () => this.tryModerateComment(comment.commentId) }];
        };

        openOptions = (comment, editComment) => {
            const options = this.getOptions(comment, editComment);
            const destructiveButtonIndex = this.isOwner(comment) ? options.length - 1 : undefined;
            ActionSheet.open(options, destructiveButtonIndex, undefined, this.props.navigation);
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    comments={this.comments}
                    isOwner={this.isOwner}
                    openOptions={this.openOptions}
                    onEdit={this.onEdit}
                    isSeeMoreShown={this.isSeeMoreShown}
                    isFullMode={this.isFullMode}
                    postComment={this.postComment}
                    seeMore={this.seeMore}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        return {
            comments: getCommentsByTypeAndId(state, ownProps.commentedItemType, ownProps.commentedItemId),
            user: coreSelectors.getCurrentUser(state),
            isEditing: isEditingComment(state),
            isPosting: isPostingComment(state)
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(CommentsBase));
}

export const styles = {
    commentsContainer: {
        borderTopWidth: 1,
        borderTopColor: baseColors.grey80,
        paddingTop: spacing.s4,
        paddingBottom: spacing.s1,
        flex: 1,
    },
    seePreviousContainer: {
        marginBottom: spacing.s3,
        // marginLeft: spacing.s9 + 40,
        flex: 1,
    },
    seePrevious: {
        ...appFonts.mdRegular
    }
};
