import _ from 'lodash';

export default function encodeQueryData(data) {
    let str = '';
    _.mapKeys(data, (value, key) => {
        if (str !== '') {
            str += '&';
        }
        str += `${key}=${encodeURIComponent(value)}`;
    });
    return str;
}
