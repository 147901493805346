import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';

import { baseColors } from '../../../../../styles';

const CIRCLE_WIDTH = 64;

class CalculatingCircles extends PureComponent {
    static propTypes = {
        width: PropTypes.number,
        type: PropTypes.string,
        color: PropTypes.string
    };

    static defaultProps = {
        width: CIRCLE_WIDTH,
        type: 'bubbles',
        color: baseColors.secondary
    };

    render() {
        return <ReactLoading {...this.props} height={this.props.width} />;
    }
}

export default CalculatingCircles;