import { normalize, schema } from 'normalizr';
import _ from 'lodash';

export const normalizeArray = (data, idAttribute = 'id', ...nestedEntities) => {
    const getSchema = (total, n) => {
        const schemaN = new schema.Entity(n.entities, total, { idAttribute: n.idAttribute });
        return { [n.entities]: [schemaN] };
    };

    let nestedSchema;
    if (nestedEntities.length) {
        const nested = _.reverse(nestedEntities);
        nestedSchema = _.reduce(nested, getSchema, getSchema(undefined, nested[0]));
    }

    const itemsSchema = new schema.Entity('items', nestedSchema, { idAttribute });
    const normalized = normalize(data, [itemsSchema]);
    return { ...normalized.entities, items: normalized.entities.items || {}, ids: normalized.result };
};
