import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { appFonts, spacing, commonPadding } from '../../../../styles';
import { MAX_COUNT, translate, tracker, firebaseEventNamesHelper } from '../../../core';
import {
    getScoreLeaderboards, isLoadingLeaderboards, isLoadingChallengesList,
    getMyChallengesCount, getFormattedChallenges
} from '../../selectors';
import * as actions from '../../actions';
import { DISPLAY_TYPES, LEADERBOARD_SLUG_TYPE } from '../../constants';
//Max value of characters for firebase is 40, "Leaderboard_" and "_View" together uqual 17
const MAX_NUMBER_AVAILABLE = 23;
const LOADING_LABEL_DELAY = 2000;

export default function WithLeaderboardsBase(WrappedComponent) {
    class LeaderboardsBase extends PureComponent {
        static propTypes = {
            leaderboards: PropTypes.array,
            isLoading: PropTypes.bool,
            actions: PropTypes.object.isRequired,
            isLoadingChallenges: PropTypes.bool,
            count: PropTypes.number,
            challenges: PropTypes.array,
            i18n: PropTypes.object.isRequired
        };

        static defaultProps = {
            leaderboards: [],
            isLoading: false,
            isLoadingChallenges: false,
            count: 0,
            challenges: []
        };

        constructor(props) {
            super(props);
            this.clickableItems = [props.i18n.t('name'), 'Group']; // contestantLabelDisplay value for groups doesn't change after switching language
            setTimeout(() => props.actions.getChallengesList({ filter: DISPLAY_TYPES.ATTENDED, start: 0, maxCount: MAX_COUNT }, true));
        }

        static getDerivedStateFromProps(nextProps, prevState) {
            if (prevState.activeSlide >= nextProps.leaderboards.length) {
                return { activeSlide: 0 };
            }
            return prevState;
        }

        state = { activeSlide: 0, challengeId: 0, showLoadingLabel: false };

        componentDidMount() {
            setTimeout(() => this.setState({ showLoadingLabel: true }), LOADING_LABEL_DELAY);
        }

        componentDidUpdate(prevProps, prevState, snapshot) {
            if (!prevProps.isLoading && this.props.isLoading) {
                // eslint-disable-next-line react/no-did-update-set-state
                this.setState({ showLoadingLabel: false });
            }
            setTimeout(() => this.setState({ showLoadingLabel: true }), LOADING_LABEL_DELAY);
            if (prevProps.isLoadingChallenges && !this.props.isLoadingChallenges) {
                this.title && tracker.logEvent(this.firebaseEvent);
                if (this.props.challenges.length < this.props.count) {
                    this.props.actions.getChallengesList({ filter: DISPLAY_TYPES.ATTENDED, start: this.props.challenges.length, maxCount: MAX_COUNT }, false);
                }
            }
        }

        get title() {
            return _.get(this.props.leaderboards, [this.state.activeSlide, 'leaderboardNameDisplay']);
        }

        get activeLeaderboardSlug() {
            return _.get(this.props.leaderboards, [this.state.activeSlide, 'slug']);
        }

        get firebaseEvent() {
            const eventName = firebaseEventNamesHelper(this.activeLeaderboardSlug);
            const tooMuch = eventName.length >= MAX_NUMBER_AVAILABLE;
            const firebaseEventName = `Leaderboard_${tooMuch ? eventName.slice(0, MAX_NUMBER_AVAILABLE) : eventName}_View`;
            return firebaseEventName;
        }

        get isDisabledPress() {
            const { leaderboards } = this.props;
            const { activeSlide } = this.state;
            const contestantLabelDisplay = leaderboards && activeSlide < leaderboards.length ? leaderboards[activeSlide].contestantLabelDisplay : '';
            return !_.includes(this.clickableItems, contestantLabelDisplay);
        }

        get loadingLabel() {
            return this.props.i18n.t('leaderboards.loadingLabel');
        }

        get emptyLeaderboardText() {
            return this.props.i18n.t('nothingToShow');
        }

        get isLocationLeaderboard() {
            const slug = this.activeLeaderboardSlug;

            if (slug === LEADERBOARD_SLUG_TYPE.location || slug === LEADERBOARD_SLUG_TYPE.region || slug === LEADERBOARD_SLUG_TYPE.department) {
                return true;
            }

            return false;
        }

        onSnapToItem = activeSlide => {
            this.setState({ activeSlide }, () => tracker.logEvent(this.firebaseEvent));
        }

        setChallengeId = id => this.setState(() => ({ challengeId: id }));

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    title={this.title}
                    onSnapToItem={this.onSnapToItem}
                    loadingLabel={this.loadingLabel}
                    activeSlide={this.state.activeSlide}
                    challengeId={this.state.challengeId}
                    setChallengeId={this.setChallengeId}
                    isDisabledPress={this.isDisabledPress}
                    showLoadingLabel={this.state.showLoadingLabel}
                    emptyLeaderboardText={this.emptyLeaderboardText}
                    isLocationLeaderboard={this.isLocationLeaderboard}
                />
            );
        }
    }

    const mapStateToProps = state => ({
        leaderboards: getScoreLeaderboards(state),
        isLoading: isLoadingLeaderboards(state) || isLoadingChallengesList(state),
        isLoadingChallenges: isLoadingChallengesList(state),
        challenges: getFormattedChallenges(state),
        count: getMyChallengesCount(state),
    });

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(LeaderboardsBase));
}


export const styles = {
    innerContainer: {
        marginTop: spacing.s5,
        marginBottom: spacing.s3,
        flex: 1
    },
    indicator: {
        flex: 1,
        alignItems: 'center',
        marginTop: spacing.s3,
        marginBottom: spacing.s3
    },
    title: {
        ...appFonts.xlMedium,
        paddingLeft: commonPadding,
        paddingRight: commonPadding,
        textAlign: 'center',
        marginTop: spacing.s1,
    },
    indicatorText: {
        marginTop: spacing.s2,
        paddingLeft: commonPadding,
        paddingRight: commonPadding,
        ...appFonts.xsRegular,
        textAlign: 'center'
    }
};
