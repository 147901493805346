import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FREQUENCY } from '../../constants';
import { translate, Platform, PLATFORMS } from '../../../core';
import { getChallenge, isRestartGoal } from '../../selectors';
import { appFonts, spacing } from '../../../../styles';

export default function WithProgressTitleBase(WrappedComponent) {
    class ProgressTitleBase extends PureComponent {
        static propTypes = {
            title: PropTypes.string,
            onPress: PropTypes.func.isRequired,
            i18n: PropTypes.object.isRequired,
            challengeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // eslint-disable-line
            challenge: PropTypes.object.isRequired,
            isRestartGoal: PropTypes.bool.isRequired,
            isDailyProgress: PropTypes.bool
        };

        static defaultProps = {
            title: null,
            isDailyProgress: false
        };

        get buttonTitle() {
            const { challenge, i18n, isRestartGoal, isDailyProgress } = this.props;
            if (!challenge) return this.props.i18n.t('view_history');
            const daily = i18n.t(FREQUENCY.daily).toLowerCase();
            const weekly = i18n.t(FREQUENCY.weekly).toLowerCase();
            const frequency = isRestartGoal ? weekly : challenge.frequency || daily;
            return i18n.t(isDailyProgress ? 'viewDetails' : 'frequencyViewHistory', { frequency });
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    buttonTitle={this.buttonTitle}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => ({
        challenge: getChallenge(state, ownProps.challengeId),
        isRestartGoal: isRestartGoal(state, ownProps.challengeId),
    });

    return connect(mapStateToProps)(translate()(ProgressTitleBase));
}

const NEGATIVE_MARGIN = Platform.OS === PLATFORMS.web ? -6 : -8;
const MARGIN_TOP = Platform.OS === PLATFORMS.web ? 0 : spacing.s5;
const NEGATIVE_MARGIN_TOP = Platform.OS === PLATFORMS.web ? 0 : -spacing.s0;

export const styles = {
    flexRow: {
        alignItems: 'center'
    },
    progressTitle: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: spacing.s3,
        paddingRight: spacing.s3,
        display: 'flex'
    },
    daylyProgressTitle: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        display: 'flex',
        marginTop: MARGIN_TOP
    },
    title: {
        ...appFonts.lgBold
    },
    negativeMargin: {
        marginLeft: NEGATIVE_MARGIN,
        marginTop: NEGATIVE_MARGIN_TOP
    }
};
