import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import { DatePicker } from '@material-ui/pickers';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { useTheme } from '@material-ui/styles';
import { baseColors, spacing } from '../../../../styles';
import { translate } from '../../services';
import Input from '../Input';
import { DATE_FIELD_TYPE } from '../../constants';

const TOOLBAR_WIDTH = 160;

function DateField(props) {
    const materialTheme = useTheme();

    const [isOpen, setIsOpen] = useState(false);

    const open = useCallback(() => setIsOpen(true), []);

    const close = useCallback(() => setIsOpen(false), []);

    const theme = {
        ...materialTheme,
        palette: {
            ...materialTheme.palette,
            primary: materialTheme.palette.secondary
        },
        overrides: {
            MuiPickersToolbar: {
                toolbarLandscape: {
                    maxWidth: TOOLBAR_WIDTH
                },
            },
            MuiPickersCalendarHeader: {
                switchHeader: {
                    color: baseColors.secondary,
                },
            },
            MuiPickersBasePicker: {
                pickerViewLandscape: {
                    paddingBottom: spacing.s12
                }
            },
            MuiDialogActions: {
                root: {
                    position: 'absolute',
                    width: '100%',
                    left: 0,
                    bottom: 0,
                }
            },
            MuiPickersToolbarButton: {
                toolbarBtn: {
                    maxWidth: '100%'
                }
            }
        },
    };

    if (props.type === DATE_FIELD_TYPE.text) {
        return (
            <Input
                {...props}
                value={props.dateFormat && props.value && (props.value instanceof Date && !_.isNaN(props.value)) ? moment(props.value).format(props.dateFormat) : props.value}
            />
        );
    }

    const renderInput = () => (
        <Input
            {...props}
            openDatePicker={open}
            onFocus={open}
            readOnly={true}
            customType="date"
            value={props.dateFormat && props.value ? moment(props.value).format(props.dateFormat) : props.value}
        />
    );

    return (
        <ThemeProvider theme={theme}>
            <DatePicker
                {...props}
                orientation="landscape"
                value={props.value || props.defaultDate || new Date()}
                open={isOpen}
                onOpen={open}
                onClose={close}
                TextFieldComponent={renderInput}
                autoOk={false}
                okLabel={props.i18n.t('ok').toUpperCase()}
                cancelLabel={props.i18n.t('button_cancel').toUpperCase()}
                onAccept={props.onChange}
            />
        </ThemeProvider>
    );
}

DateField.propTypes = {
    dateFormat: PropTypes.string,
    i18n: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    defaultDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    type: PropTypes.string
};

DateField.defaultProps = {
    dateFormat: undefined,
    value: undefined,
    defaultDate: undefined,
    type: DATE_FIELD_TYPE.picker
};

export default translate()(DateField);
