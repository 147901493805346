import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { AsyncComponent, components as Core, Modal, onKeyPress } from '../../../core';
import WithGoalIconBase, { styles as baseStyles } from './GoalIconBase';
import defaultIcon from '../../../../image/goals/goal_default@3x.png';
import { layoutStyle } from '../../../../styles';

class GoalIcon extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        size: PropTypes.number.isRequired,
        callback: PropTypes.func,
        categoryIcon: PropTypes.string.isRequired,
        challengeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        detailsDeleteRedirect: PropTypes.string,
        isTaskCard: PropTypes.bool,
        goalIcon: PropTypes.string.isRequired,
        noIconUrl: PropTypes.bool.isRequired,
        isDisabledOnPress: PropTypes.bool,
        tabIndex: PropTypes.string
    };

    static defaultProps = {
        className: null,
        callback: null,
        challengeId: undefined,
        detailsDeleteRedirect: undefined,
        isTaskCard: false,
        isDisabledOnPress: false,
        tabIndex: undefined
    };

    onClick = () => {
        const { isDisabledOnPress, callback, challengeId, detailsDeleteRedirect, isTaskCard } = this.props;
        if (isDisabledOnPress) return null;
        if (callback) callback();
        challengeId && Modal.open(
            AsyncComponent(() => import('./GoalInfo')),
            { challengeId, detailsDeleteRedirect, isTaskCard },
            { isContainer: true, isNoPadding: true }
        );
    };

    render() {
        const { goalIcon, size, className, categoryIcon, challengeId, noIconUrl, tabIndex, i18n } = this.props;
        return (
            <div>
                {goalIcon ? (
                    <div
                        style={{ height: size, width: size }}
                        className={css(styles.imageContainer, (!challengeId ? layoutStyle.noCursor : null))}
                        onClick={this.onClick}
                        role="button"
                        tabIndex={tabIndex}
                        onKeyDown={onKeyPress.enter(this.onClick)}>
                        <Core.Image
                            src={noIconUrl ? defaultIcon : goalIcon}
                            alt={i18n.t('goalIcon')}
                        />
                    </div>
                )
                    : (
                        <Core.Icon
                            onClick={this.onClick}
                            type="fa"
                            name="bullseye-arrow"
                            size={size}
                            color={categoryIcon}
                            className={className}
                        />
                    )}
            </div>
        );
    }
}

export default WithGoalIconBase(GoalIcon);

const styles = StyleSheet.create({
    ...baseStyles
});
