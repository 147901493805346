import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate, selectors as coreSelectors, entitiesHelper } from '../../../core';
import * as eventsActions from '../../actions';
import { EVENT_ENTITY_TYPES } from '../../constants';
import * as selectors from '../../selectors';
import { onRefresh } from '../../services/fetchEventsByType';

export default function WithEventsBase(WrappedComponent) {
    class EventsBase extends PureComponent {
        static propTypes = {
            events: PropTypes.array,
            title: PropTypes.string,
            userId: PropTypes.number.isRequired,
            actions: PropTypes.object.isRequired,
            type: PropTypes.string.isRequired,
            i18n: PropTypes.object.isRequired,
            isLoading: PropTypes.object,
            exploreLoadingStatus: PropTypes.object,
            isLoadingEvents: PropTypes.bool,
            onRef: PropTypes.func,
        };

        static defaultProps = {
            title: '',
            isLoading: {},
            events: [],
            onRef: null,
            exploreLoadingStatus: {},
            isLoadingEvents: false,
        };

        constructor(props) {
            super(props);
            this.onRefresh();
        }

        onRefresh = () => {
            const { actions, type, userId } = this.props;
            onRefresh(actions, type, userId);
        };

        isEventStarted = (event) => entitiesHelper.isAfterDeadline(event.eventDateTime);

        get eventsList() {
            if (this.props.type === EVENT_ENTITY_TYPES.RECOMMENDED) {
                return _.filter(this.props.events, event => !this.isEventStarted(event));
            }

            return _.map(this.props.events, event => ({
                ...event,
                hasNoButton: this.props.type === EVENT_ENTITY_TYPES.PAST
            }));
        }

        get isAllType() {
            return this.props.type === EVENT_ENTITY_TYPES.ALL_ITEMS;
        }

        get count() {
            return this.isAllType ? null : this.eventsList.length;
        }

        get isInvitation() {
            return this.props.type === EVENT_ENTITY_TYPES.INVITED;
        }

        get isLoadingItems() {
            const { isLoadingEvents, exploreLoadingStatus, exploreLoadingStatus: { isLoadingAllExplore, initialLoadDataComplete } } = this.props;
            return _.isEmpty(exploreLoadingStatus) ? isLoadingEvents : isLoadingAllExplore || !initialLoadDataComplete;
        }

        get noEvents() {
            const { i18n } = this.props;
            return {
                title: i18n.t('eventCarousel.emptyEventsMessage'),
                subtitle: i18n.t('eventCarousel.emptyEvents.subtitle'),
                buttonLabel: i18n.t('eventCarousel.emptyEvents.button'),
            };
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    isAllType={this.isAllType}
                    isInvitation={this.isInvitation}
                    count={this.count}
                    eventsList={this.eventsList}
                    type={this.props.type}
                    isLoadingItems={this.isLoadingItems}
                    noEvents={this.noEvents}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        const selector = selectors.eventListSelector[ownProps.type] || selectors.getAllEventsSelector;
        const currentUser = coreSelectors.getCurrentUser(state);
        const userId = ownProps.userId || currentUser.userId;
        return {
            isLoadingEvents: selectors.isLoadingEventsByType(state, ownProps.type) || selectors.isLoadingAllEvents(state),
            events: selector(state),
            userId
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(eventsActions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(EventsBase));
}
