import React, { PureComponent } from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { layoutStyle } from '../../../../styles';
import WithChallengesListBase, { styles as baseStyles } from './ChallengesByTypeBase';
import { components as Core } from '../../../core';
import ChallengeListItem from '../ChallengeListItem';

class ChallengesList extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        challenges: PropTypes.array,
        loadMoreContent: PropTypes.func.isRequired,
        keyExtractor: PropTypes.func.isRequired,
        title: PropTypes.string,
        mainContainerClassName: PropTypes.string,
    };

    static defaultProps = {
        challenges: null,
        title: null,
        mainContainerClassName: undefined
    };

    renderItem = ({ item }) => <ChallengeListItem challenge={item} />;

    renderHeader = () => this.props.title ? (
        <React.Fragment>
            <Core.EntityDetailsHeader
                hasBackButton={true}
            />
            <Core.SubHeader
                title={this.props.title}
                noTopPadding={true}
            />
        </React.Fragment>
    ) : null;

    render() {
        return (
            <Core.SmallerContainer>
                <div className={classnames(css(layoutStyle.flex1, styles.mainContainer), this.props.mainContainerClassName)}>
                    <Core.InfiniteLazyListFadeIn
                        isHorizontal={false}
                        data={this.props.challenges}
                        keyExtractor={this.props.keyExtractor}
                        renderItem={this.renderItem}
                        ListHeaderComponent={this.renderHeader}
                        onEndReached={this.props.loadMoreContent}
                        onEndReachedThreshold={0.5}
                        isLoading={this.props.isLoading}
                        twoColumns={false}
                    />
                </div>
            </Core.SmallerContainer>
        );
    }
}

const styles = StyleSheet.create({
    ...baseStyles
});

export default WithChallengesListBase(ChallengesList);
