import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import { translate, constants as coreConstants, parsers } from '../../../core';
import { FIELDS, PUBLIC_COMMUNITY, PRIVATE_COMMUNITY } from '../../constants';
import { appFonts, baseColors, spacing } from '../../../../styles';

export default function WithCreateCommunityBase(WrappedComponent) {
    class CreateCommunityBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            community: PropTypes.object,
            communityId: PropTypes.string,
            i18n: PropTypes.object.isRequired,
            entityType: PropTypes.string,
        };

        static defaultProps = {
            community: {},
            communityId: null,
            entityType: null,
        };

        constructor(props) {
            super(props);
            this.state = this._getInitialData(props);
        }

        _getInitialData = ({ community }) => community ? {
            picture: _.get(this.props, 'community.groupImageURL'),
            name: community.name,
            description: community.description && parsers.replaceAllBr(community.description),
            type: community.type,
            edited: false
        } : {
            picture: '',
            name: '',
            description: '',
            type: PUBLIC_COMMUNITY,
            edited: false
        };

        onChange = prop => value => {
            this.setState({ [prop]: value, edited: true });
        };

        toggleType = () => {
            this.setState(state => ({ type: state.type === PRIVATE_COMMUNITY ? PUBLIC_COMMUNITY : PRIVATE_COMMUNITY, edited: true }));
        };

        editCommunity = () => {
            const params = _.pick(this.state, [FIELDS.NAME, FIELDS.DESCRIPTION, FIELDS.PICTURE, FIELDS.TYPE]);
            // Update pictures starts with data: or empty to remove picture
            if ((params[FIELDS.PICTURE] || '').startsWith('http')) {
                delete params[FIELDS.PICTURE];
            }
            this.props.actions.editCommunity(params, this.props.community.id);
        };

        fileChanged = (file, picture = '') => this.setState({ picture, edited: true });

        get isPrivate() {
            return this.state.type === PRIVATE_COMMUNITY;
        }

        get namePlaceholder() { return this.props.i18n.t('nameOfCommunity'); }
        get descriptionPlaceholder() { return this.props.i18n.t('whatCommunityAbout'); }
        get listTitle() { return this.props.i18n.t('createChallenge.additional.makePublicName'); }
        get listSubTitle() { return this.props.i18n.t('createChallenge.additional.makePublicDescription'); }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    fields={this.state}
                    onChange={this.onChange}
                    isPrivate={this.isPrivate}
                    toggleType={this.toggleType}
                    editCommunity={this.editCommunity}
                    fileChanged={this.fileChanged}
                    picture={this.state.picture}
                    title={this.props.i18n.t('editCommunity')}
                    rightButtonTitle={this.props.i18n.t('save')}
                    entityType={coreConstants.ENTITIES.community}
                    namePlaceholder={this.namePlaceholder}
                    descriptionPlaceholder={this.descriptionPlaceholder}
                    listTitle={this.listTitle}
                    listSubTitle={this.listSubTitle}
                />
            );
        }
    }
    function mapStateToProps(state, ownProps) {
        const id = ownProps.communityId
            || _.get(ownProps, 'match.params.id')
            || _.get(ownProps, 'match.params.communityId')
            || _.get(ownProps, 'route.params.communityId');
        return {
            isEditing: selectors.communityEditing(state),
            community: selectors.getSingleCommunity(state, id),
        };
    }

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(CreateCommunityBase));
}

const INPUT_HEIGHT = 160;
export const styles = {
    mainContainer: {
        flex: 1,
    },
    dataContainer: {
        flex: 1,
        margin: spacing.s3,
        marginTop: 0
    },
    editCommunityButton: {
        marginTop: spacing.s5,
    },
    privateContainer: {
        paddingTop: spacing.s3,
        paddingBottom: spacing.s3
    },
    privateWrapper: {
        marginLeft: 0
    },
    privateTitle: {
        ...appFonts.mdBold,
        marginLeft: 0
    },
    privateSubtitle: {
        ...appFonts.xsRegular,
        color: baseColors.grey40,
        marginLeft: 0
    },
    inputContainer: {
        paddingBottom: spacing.s1
    },
    formContainer: {
        height: INPUT_HEIGHT
    }
};
