import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import _ from 'lodash';

import { withRouter } from 'react-router-dom';
import { spacing, importantStyles, baseColors, layoutStyle } from '../../../../styles';
import { components as Core, CSS_CLASSES, Modal, ROUTES, Storage } from '../../../core';
import WithHealthSurveyBase, { styles as baseStyles } from './HealthSurveyBase';
import VitalStats from '../VitalStats';
import ChoiceQuestion from '../ChoiceQuestion';
import { SURVEY_QUESTIONS_TYPES } from '../../constants';
import NumericQuestion from '../NumericQuestion';
import InfoScreen from '../InfoScreen';
import { NOTIFICATIONS_DEEP_LINK } from '../../../notifications/constants';

class HealthSurvey extends PureComponent {
    static propTypes = {
        answerSelected: PropTypes.func.isRequired,
        close: PropTypes.func,
        onSkip: PropTypes.func,
        goBack: PropTypes.func.isRequired,
        goForward: PropTypes.func.isRequired,
        question: PropTypes.object,
        componentType: PropTypes.string.isRequired,
        onActionTextModalClose: PropTypes.func.isRequired,
        hasRightButton: PropTypes.bool,
        getModalProps: PropTypes.func.isRequired,
        isOnboarding: PropTypes.bool,
        percentageComplete: PropTypes.number.isRequired,
        isFirstPage: PropTypes.bool.isRequired,
        isLoading: PropTypes.bool,
        allAnswers: PropTypes.object.isRequired,
        index: PropTypes.number.isRequired,
        questions: PropTypes.array.isRequired,
        rightButtonLabel: PropTypes.string,
        i18n: PropTypes.object.isRequired,
        isModal: PropTypes.bool,
        isSavingAnswer: PropTypes.bool,
        isSettingUserTaskComplete: PropTypes.bool,
    };

    static defaultProps = {
        question: null,
        onSkip: null,
        isOnboarding: false,
        hasRightButton: false,
        isLoading: false,
        rightButtonLabel: '',
        isModal: true,
        close: undefined,
        isSavingAnswer: false,
        isSettingUserTaskComplete: false,
    };

    static COMPONENTS = {
        [SURVEY_QUESTIONS_TYPES.choice]: ChoiceQuestion,
        [SURVEY_QUESTIONS_TYPES.numeric]: NumericQuestion,
        [SURVEY_QUESTIONS_TYPES.custom]: VitalStats,
        [SURVEY_QUESTIONS_TYPES.intro]: InfoScreen
    };

    async componentWillUnmount() {
        await Storage.removeItem(NOTIFICATIONS_DEEP_LINK);
    }

    answerSelected = val => this.props.answerSelected(val);

    onButtonPress = () => {
        const { isOnboarding, close, history } = this.props;
        if (isOnboarding) {
            this.onSkipSurvey();
        } else {
            close ? close() : history.push(ROUTES.notifications());
        }
    };

    close = async () => {
        const { close, history } = this.props;
        const isDeepLink = await Storage.getItem(NOTIFICATIONS_DEEP_LINK);
        if (close) {
            close();
        }
        if (isDeepLink) {
            history.push(ROUTES.wellbeingScore());
        }
    };

    onSkipSurvey = () => {
        const { i18n, getModalProps } = this.props;

        this.closeModal = Modal.open(
            Core.InfoModal,
            {
                ...getModalProps(),
                buttons: [
                    { text: i18n.t('ok'), onPress: () => this.confirmSkip(), isBoldText: true },
                    { text: i18n.t('button_cancel'), onPress: () => this.closeModal() }
                ]
            },
            {
                cancelable: false,
                fadeTransition: true,
                PaperProps: { elevation: 0 },
                isNoPadding: true,
                isTransparent: true
            }
        );
    };

    confirmSkip = async () => {
        await this.closeModal();
        if (this.props.onSkip) this.props.onSkip();
    };

    closeHelperModal = () => {
        this.closeActionText();
        this.props.onActionTextModalClose();
    };

    openActionTextModal = text => {
        this.closeActionText = Modal.open(
            Core.HelperModal,
            {
                text,
                onButtonPress: this.closeHelperModal,
                fullWidth: true
            },
            {
                isContainer: true,
                isNoPadding: true,
                fadeTransition: true,
                isMaxWidthLimited: true,
            }
        );
    };

    get component() {
        const { componentType } = this.props;
        return _.get(HealthSurvey.COMPONENTS, `${componentType}`);
    }

    get getSurveyProps() {
        const { question, goForward } = this.props;

        if (question) {
            if (question.type === SURVEY_QUESTIONS_TYPES.custom) {
                return {
                    id: this.props.index,
                    onClick: goForward,
                    allFieldsRequired: true,
                    isModal: !this.props.isOnboarding && this.props.isModal
                };
            } else if (question.type === SURVEY_QUESTIONS_TYPES.intro) {
                return { onClick: goForward };
            }
        }
        return null;
    }

    get quitButton() {
        const { rightButtonLabel, i18n } = this.props;

        return (
            <Core.Button
                id={_.camelCase(`${rightButtonLabel}BtnHealthSurvey`)}
                type="text"
                size="small"
                onPress={this.onButtonPress}>
                {rightButtonLabel || i18n.t('quit')}
            </Core.Button>
        );
    }

    render() {
        const { question, isFirstPage, percentageComplete } = this.props;
        return (
            <Core.Layout.PageWrapper isModal={this.props.isModal}>
                <Core.Layout.FHFlex className={css(styles.modalContainer, layoutStyle.heightAuto)}>
                    <div className={css(layoutStyle.flexRow, layoutStyle.flexAlignCenter)}>
                        {!isFirstPage ?
                            <Core.BackButton
                                id="backBtnHealthSurvey"
                                size={Core.Button.SIZES.small}
                                onClick={this.props.goBack}
                            /> : null}
                        <div className={css(layoutStyle.flex1)}>
                            <Core.ProgressBar
                                variant="determinate"
                                filledPercentage={percentageComplete}
                            />
                        </div>
                        {this.quitButton}
                    </div>
                    <div>
                        <div className={css(styles.infoContainer, layoutStyle.flex)}>
                            <div className={css(styles.personalizeText)}>
                                {this.props.i18n.t('health_survey_questions')}
                            </div>
                        </div>
                    </div>
                    <div className={CSS_CLASSES.modalBodyWithScrollbar}>
                        {this.component ?
                            <this.component
                                key={question.id}
                                question={question}
                                onClick={this.answerSelected}
                                answer={this.props.allAnswers[question.id]}
                                isSavingAnswer={this.props.isSavingAnswer}
                                isSettingUserTaskComplete={this.props.isSettingUserTaskComplete}
                                isOnboarding={this.props.isOnboarding}
                                isWellbeingSurvey={true}
                                {...this.getSurveyProps}
                            /> : null
                        }
                    </div>
                    <Core.BlockingLoading isLoading={this.props.isLoading} />
                </Core.Layout.FHFlex>
            </Core.Layout.PageWrapper>
        );
    }
}

const CONTAINER_WIDTH_FOR_WELLBEING_SURVEY = 600;

const styles = StyleSheet.create(importantStyles({
    ...baseStyles,
    modalContainer: {
        padding: spacing.s3,
        borderRadius: spacing.s1,
        backgroundColor: baseColors.white,
        maxWidth: CONTAINER_WIDTH_FOR_WELLBEING_SURVEY,
        margin: '0 auto'
    },
}));

export default withRouter(WithHealthSurveyBase(HealthSurvey));
