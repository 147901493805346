import spacing from '../spacing';

export const ICONS = {
    eat: 'utensils',
    move: 'walking',
    mind: 'brain',
    breathe: 'wind', // todo: change to right from fa
    company: 'building',
    program: 'address-card',
    users: 'users',
    leaf: 'leaf',
    clipboard: 'clipboard-check',

    goal: 'bullseye-arrow',
    challenge: 'trophy-alt',
};

// preferably to use this instead of consts below
export const imageSize = Object.freeze({
    xxxl: 370,
    xxl: 300,
    xl: 280,
    xlg: 240,
    lg: 210,
    xmd: 160,
    md: 150,
    smd: 100,
    sm: 80,
    xs: 50,
    xxs: 40,
    xxxs: 30
});

export const libraryContentImageWidth = imageSize.xmd;
export const libraryContentImageHeight = imageSize.smd;

export const feedContentImageWidth = imageSize.smd;

export const invitationItemWidth = imageSize.xmd;
export const invitationItemHeight = imageSize.smd;

export const exploreTopImageHeight = imageSize.xlg;
export const exploreImageHeight = imageSize.md;
export const exploreImageWidth = imageSize.xxl;
export const detailsImageSize = imageSize.xl;
export const listItemImageSize = imageSize.sm;
export const challengeDetailsImageSize = imageSize.xxxl;

export const commonPadding = spacing.s3;

export const commonPaddingVertical = commonPadding;

export const modalPadding = commonPadding;

export const navButtonSize = spacing.s5;

export const headerHeight = 64;

export const subHeaderMinHeight = 54; // equals android navbar height

export const cardBorderRadius = spacing.s1;
export const largeFooterHeight = 83;
export const footerHeight = 65;

export const syncIconSize = 10;
