import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { translate } from '../../../core';
import * as actions from '../../actions';
import { getLikesByTypeAndId, isLoadingLikes } from '../../selectors';
import { appFonts, baseColors, spacing } from '../../../../styles';

export default function WithLikesBase(WrappedComponent) {
    class LikesBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            likes: PropTypes.array,
            likedItemType: PropTypes.string.isRequired,
            likedItemId: PropTypes.number.isRequired,
            likeCount: PropTypes.number.isRequired,
            isLoading: PropTypes.bool,
        };

        static defaultProps = {
            likes: [],
            isLoading: false
        };

        constructor(props) {
            super(props);
            this.props.actions.getLikes(this.props.likedItemType, this.props.likedItemId, 0);
        }

        loadMoreLikes = () => {
            if (!this.props.isLoading && this.props.likes.length < this.props.likeCount) {
                this.props.actions.getLikes(this.props.likedItemId, this.props.likedItemType, this.props.likes.length || 0);
            }
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    loadMoreLikes={this.loadMoreLikes}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        const likedItemType = ownProps.likedItemType || _.get(ownProps, 'match.params.likedItemType') || _.get(ownProps, 'route.params.likedItemType');
        const likedItemId = ownProps.likedItemId || _.get(ownProps, 'match.params.likedItemId') || _.get(ownProps, 'route.params.likedItemId');
        return {
            likes: getLikesByTypeAndId(state, likedItemType, likedItemId),
            isLoading: isLoadingLikes(state),
            likedItemType,
            likedItemId
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(LikesBase));
}

export const styles = {
    mainContainer: {
        flex: 1,
    },
    headerInnerContainer: {
        backgroundColor: 'transparent',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    headerContentText: {
        color: baseColors.white
    },
    likeIcon: {
        paddingRight: spacing.s3
    },
    likesCount: {
        paddingLeft: spacing.s1,
        paddingRight: spacing.s1,
        ...appFonts.xlMedium
    },
    likesText: {
        ...appFonts.xlRegular
    },
    dismissIcon: {
        alignSelf: 'flex-end'
    },
    mainList: {
        flex: 1,
        backgroundColor: baseColors.white,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10
    },

    headerBody: {
        flexDirection: 'row',
    },
};
