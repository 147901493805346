import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import classNames from 'classnames';
import _ from 'lodash';

import Avatar from '../Avatar';
import Button from '../Button';
import { importantClass, spacing, baseColors } from '../../../../styles';
import WithAvatarListBase, { styles as baseStyles } from './AvatarListBase';
import { components as Core } from '../../indexBase';

const INDEX_OF_LAST_AVATAR = 4; //last fifth avatar is darkened and has more icon
class AvatarList extends PureComponent {
    static propTypes = {
        members: PropTypes.array.isRequired,
        onPress: PropTypes.func,
        numMembers: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        membersAvatarClassName: PropTypes.string.isRequired,
        sizeAvatar: PropTypes.string,
        containerClassName: PropTypes.string,
        supplementaryComponent: PropTypes.element,
        isPressDisabled: PropTypes.bool,
        i18n: PropTypes.object.isRequired,
        sizeContainer: PropTypes.string,
        getName: PropTypes.func.isRequired,
        isShowNumbersOfMembers: PropTypes.bool,
        avatarsContainerClassName: PropTypes.string,
        isLiveBetter: PropTypes.bool.isRequired,
        categoryColor: PropTypes.string
    };

    static defaultProps = {
        onPress: null,
        numMembers: 0,
        sizeAvatar: 'small',
        containerClassName: undefined,
        isPressDisabled: true,
        supplementaryComponent: undefined,
        sizeContainer: 'small',
        isShowNumbersOfMembers: true,
        avatarsContainerClassName: undefined,
        categoryColor: null
    };

    get supplementary() {
        const { supplementaryComponent, numMembers, i18n, membersClassName } = this.props;
        return supplementaryComponent ? supplementaryComponent
            : <div className={classNames(css(styles.numberOfMembers), membersClassName)}>{i18n.t('communityMembers', { numberOfMembers: numMembers })}</div>;
    }

    get avatarsIcons() {
        const { members, membersAvatarClassName, sizeAvatar, isPressDisabled, hasMoreIcon, isGoal, biggerAvatar } = this.props;
        return (
            _.map(members, member => {
                const getUrl = member.imageURL || member.userImageURL || member.imageUrl || member.challengeImageURL || member.iconUrl || member.eventImageURL || member.groupImageURL;
                return (
                    (hasMoreIcon && members.indexOf(member) === INDEX_OF_LAST_AVATAR) ?
                        <div className={css(styles.overallWrapper)}>
                            <Core.Icon
                                type="fa"
                                name="ellipsis-h"
                                size={spacing.s4}
                                color={baseColors.white}
                                className={css(biggerAvatar ? styles.iconBiggerSize : styles.icon)}
                            />
                            <div className={css(styles.wrapper)}>
                                <Avatar
                                    url={isGoal? member.challengeIconURL : getUrl}
                                    id={member.userId}
                                    name={this.props.getName(member)}
                                    key={member.id || member.userId}
                                    className={membersAvatarClassName}
                                    size={sizeAvatar}
                                    disablePress={isPressDisabled}
                                />
                            </div>
                        </div>
                        :
                        <Avatar
                            url={isGoal? member.challengeIconURL : getUrl}
                            id={member.userId}
                            name={this.props.getName(member)}
                            key={member.id || member.userId}
                            className={membersAvatarClassName}
                            size={sizeAvatar}
                            disablePress={isPressDisabled}
                        />
                );
            })
        );
    }

    get achievementsIcon() {
        const { isGoal, isLiveBetter, categoryColor } = this.props;
        return (
            _.map(this.props.members, member => (
                <>
                    {isGoal && isLiveBetter ? (
                        <Core.Icon
                            type="fa"
                            name="bullseye-arrow"
                            size={spacing.s4}
                            color={categoryColor(member)}
                            containerStyle={styles.liveBetterGoal}
                        />

                    ) : (
                        <Core.Image
                            src={isGoal ? member.challengeIconURL : member.iconUrl}
                            className={css(styles.image, isGoal && styles.goalAvatar)}
                            alt="achievementImage"
                            resizeMode="contain"
                        />
                    )
                    }
                </>
            ))
        );
    }

    render() {
        const { containerClassName, onPress, sizeContainer, isShowNumbersOfMembers,
            avatarsContainerClassName, isAchievements, isGoalsSection } = this.props;
        return (
            <Button
                isHeightAuto={true}
                onPress={onPress}
                type="text"
                size={sizeContainer}
                className={classNames(css(styles.membersContainer), containerClassName)}
                disabled={!onPress && true}>
                <div className={classNames(css(styles.avatarsContainer), avatarsContainerClassName)}>
                    {isAchievements || isGoalsSection ? this.achievementsIcon : this.avatarsIcons}
                </div>
                {isShowNumbersOfMembers ? <div>{this.supplementary}</div> : null}
            </Button>
        );
    }
}

const ICON_LEFT_OFFSET = 66;
const ICON_LEFT_BIGGER_OFFSET = 102;

export default WithAvatarListBase(AvatarList);

const styles = StyleSheet.create({
    ...baseStyles,
    membersContainer: importantClass({
        ...baseStyles.membersContainer
    }),
    image: {
        width: spacing.s6,
        height: spacing.s6,
        marginLeft: -spacing.s2,
    },
    overallWrapper: {
        backgroundColor: baseColors.black,
        zIndex: 1,
        borderRadius: spacing.s5,
        marginLeft: -spacing.s1
    },
    wrapper: {
        opacity: 0.5,
        marginLeft: spacing.s1
    },
    icon: {
        position: 'absolute',
        top: spacing.s1,
        left: ICON_LEFT_OFFSET,
        zIndex: 1
    },
    iconBiggerSize: {
        position: 'absolute',
        top: spacing.s2,
        left: ICON_LEFT_BIGGER_OFFSET,
        zIndex: 1
    },
    liveBetterGoal: {
        ...baseStyles.liveBetterGoal,
        marginLeft: -spacing.s2,
    },
    goalAvatar: {
        borderWidth: 1,
        borderColor: baseColors.white,
        borderStyle: 'solid',
        borderRadius: spacing.s9,
        width: spacing.s7,
        height: spacing.s7,
    }
});
