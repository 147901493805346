import { createSelector } from 'reselect';
import _ from 'lodash';
import { NAME, EVENT_ENTITY_TYPES } from './constants';
import { selectors as coreSelectors, numbers, entitiesHelper, MAX_SLIDES_COUNT, PARTICIPANTS_NUMBER_AVATARS,
    getRequestId } from '../core';
import * as types from './actionTypes';
import i18n from '../../i18n';

export const getAllEvents = state => state[NAME].items;
export const getFetchedEventsCount = state => state[NAME].fetchedEventsCount;
export const getNewEventsCount = createSelector(
    getFetchedEventsCount,
    coreSelectors.getSecondParam,
    (counts, type) => _.get(counts, type, 0)
);

const isCarousel = (state, isCarousel = false) => isCarousel;
const getIds = (ids, isCarousel = false) => (isCarousel ? ids.slice(0, MAX_SLIDES_COUNT) : ids);
const getItems = (items, ids, isCarousel) => _.map(getIds(ids, isCarousel), id => items[id]);

const getAllEventsIds = state => state[NAME][EVENT_ENTITY_TYPES.ALL_ITEMS];
const getMyEventsIds = state => state[NAME][EVENT_ENTITY_TYPES.MY_ITEMS];
const getRecommendedEventsIds = state => state[NAME][EVENT_ENTITY_TYPES.RECOMMENDED];
const getInvitedEventsIds = state => state[NAME][EVENT_ENTITY_TYPES.INVITED];
const getPastEventsIds = state => state[NAME][EVENT_ENTITY_TYPES.PAST];
const getUserEventsIds = state => state[NAME][EVENT_ENTITY_TYPES.USER];

export const getAllEventsLength = createSelector(getAllEventsIds, getRecommendedEventsIds, getInvitedEventsIds, (allItems, recommendedItems, invitedItems) => {
    return [...allItems, ...recommendedItems, ...invitedItems].length;
});

export const getEvent = (state, id) => getAllEvents(state)[id];

export const getAllEventsSelector = createSelector(getAllEvents, getAllEventsIds, isCarousel, getItems);
export const getMyEventsSelector = createSelector(getAllEvents, getMyEventsIds, isCarousel, getItems);
export const getRecommendedEventsSelector = createSelector(getAllEvents, getRecommendedEventsIds, isCarousel, getItems);
export const getInvitedEventsSelector = createSelector(getAllEvents, getInvitedEventsIds, isCarousel, getItems);
export const getPastEventsSelector = createSelector(getAllEvents, getPastEventsIds, isCarousel, getItems);
export const getUserEventsSelector = createSelector(getAllEvents, getUserEventsIds, isCarousel, getItems);

export const eventListSelector = {
    [EVENT_ENTITY_TYPES.ALL_ITEMS]: getAllEventsSelector,
    [EVENT_ENTITY_TYPES.MY_ITEMS]: getMyEventsSelector,
    [EVENT_ENTITY_TYPES.INVITED]: getInvitedEventsSelector,
    [EVENT_ENTITY_TYPES.RECOMMENDED]: getRecommendedEventsSelector,
    [EVENT_ENTITY_TYPES.PAST]: getPastEventsSelector,
    [EVENT_ENTITY_TYPES.USER]: getUserEventsSelector
};

const getMembers = state => state[NAME].members;
const getAllMembers = state => getMembers(state).items;
const getMembersIds = state => getMembers(state).ids;
const getEventMembersIds = (state, id) => _.get(getMembersIds(state), id, []);
export const getEventMembers = createSelector(getAllMembers, getEventMembersIds, (items, ids) => _.filter(_.map(ids, id => items[id]), _.identity));
const getEventInviters = createSelector(getEvent, item => _.get(item, 'eventInviters', []));
export const getEventInvitersIds = createSelector(getEventInviters, items => _.map(items, item => item.userId));

export const isUserAndCurrentUserAntendedTheSameEntity = createSelector(coreSelectors.getCurrentUser, coreSelectors.getSecondParam, (currecntUser, eventAttendees) => {
    return _.find(eventAttendees, attendee => attendee.userId === currecntUser.userId) ? 1: 0;
});

export const getEventPrivateMembersCount = (state, id) => numbers.intOrZero(_.get(getEvent(state, id), 'numPrivateEventAttendees'));

export const getEventMembersAndPrivateSummary = createSelector(getEventMembers, getEventPrivateMembersCount,
    (members, privateCount) => privateCount === 0 ? members : [...members, {
        ...entitiesHelper.getPrivateSummary(),
        firstName: i18n.t('privateUsersFirstNamePlaceHolderEvent', { n: privateCount }),
        lastName: i18n.t('privateUsersLastNamePlaceHolderEvent'),
        description: i18n.t('privateUsersDescriptionEvent'),
    }]
);
export const isLoadingEventsByType = (state, type) => coreSelectors.isLoading(state, `${types.GET_EVENTS.NAME}_${type}`);
export const isLoadingAllEvents = state => coreSelectors.isLoading(state, `${types.GET_EVENTS.NAME}_${EVENT_ENTITY_TYPES.ALL_ITEMS}`);
export const isLoadingMyEvents = state => coreSelectors.isLoading(state, `${types.GET_EVENTS.NAME}_${EVENT_ENTITY_TYPES.MY_ITEMS}`);
export const isLoadingRecommendedEvents = state => coreSelectors.isLoading(state, `${types.GET_EVENTS.NAME}_${EVENT_ENTITY_TYPES.RECOMMENDED}`);
export const isLoadingInvitedEvents = state => coreSelectors.isLoading(state, `${types.GET_EVENTS.NAME}_${EVENT_ENTITY_TYPES.INVITED}`);
export const isLoadingPastEvents = state => coreSelectors.isLoading(state, `${types.GET_EVENTS.NAME}_${EVENT_ENTITY_TYPES.PAST}`);
export const isLoadingEvent = (state, id) => coreSelectors.isLoading(state, getRequestId(types.GET_EVENT_BY_ID.NAME, id));
export const isDeleting = state => coreSelectors.isLoading(state, types.DELETE_EVENT.NAME);
export const isLeaving = state => coreSelectors.isLoading(state, types.LEAVE_EVENT.NAME);
export const isCreating = state => coreSelectors.isLoading(state, types.CREATE_EVENT.NAME);
export const isEditing = state => coreSelectors.isLoading(state, types.EDIT_EVENT.NAME);
export const isJoiningEvent = (state, id) => coreSelectors.isLoading(state, getRequestId(types.JOIN_EVENT.NAME, id));
export const isLoadingEventMembers = state => coreSelectors.isLoading(state, types.GET_EVENT_MEMBERS.NAME);

export const getJoiningError = (state, id) => coreSelectors.getError(state, getRequestId(types.JOIN_EVENT.NAME, id));
export const getEventError = (state, id) => coreSelectors.getError(state, getRequestId(types.GET_EVENT_BY_ID.NAME, id));

const getAttendeesItems = item => _.get(item, 'eventAttendees', []).slice(0, PARTICIPANTS_NUMBER_AVATARS);
export const getAttendees = createSelector(getEvent, getAttendeesItems);

export const getLastCreatedEventId = state => state[NAME].lastCreatedId;
export const isNotificationShown = createSelector(getEvent, event => _.get(event, 'isNotificationShown'));