import moment from 'moment/moment';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { translate, constants as coreConstants } from '../../../core';
import * as eventsActions from '../../actions';
import { EVENT_ENTITY_TYPES } from '../../constants';
import { isJoiningEvent } from '../../selectors';
import { appFonts, baseColors, spacing, listItemImageSize } from '../../../../styles';

export default function WithEventListItemBase(WrappedComponent) {
    class EventListItemBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object,
            event: PropTypes.object,
            i18n: PropTypes.object.isRequired
        };
        static defaultProps = {
            event: {},
            actions: {}
        };

        get eventTime() {
            return _.replace(moment(this.props.event.eventDateTime).format(coreConstants.DATE_FORMATS.twelveHoursTime), ':00', '');
        }

        get eventDate() {
            return moment(this.props.event.eventDateTime).format(coreConstants.DATE_FORMATS.dayFullMonthDay);
        }

        get fullEventDate() {
            const { i18n } = this.props;
            return `${this.eventDate} ${i18n.t('at')} ${this.eventTime}`;
        }

        get eventImage() {
            return this.props.event.eventImageURL;
        }

        get isShowButton() {
            return this.type === EVENT_ENTITY_TYPES.INVITED || this.type === EVENT_ENTITY_TYPES.RECOMMENDED;
        }

        joinEvent = () => {
            this.props.actions.joinEvent(this.props.event.eventId);
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    isShowButton={this.isShowButton}
                    joinEvent={this.joinEvent}
                    eventTime={this.eventTime}
                    eventImage={this.eventImage}
                    fullEventDate={this.fullEventDate}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        return {
            isJoiningEvent: isJoiningEvent(state, ownProps.event.eventId)
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(eventsActions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(EventListItemBase));
}

export const styles = {
    main: {
        margin: spacing.s3
    },
    innerEventContainer: {
        flex: 1,
        flexDirection: 'row',
        borderRadius: spacing.s1,
        marginLeft: 0,
        marginRight: 0,
    },
    eventDetailsContainer: {
        flex: 2,
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3,
        flexDirection: 'row'
    },
    eventDetailsText: {
        alignSelf: 'flex-start',
        marginBottom: spacing.s0,
        ...appFonts.smRegular,
        color: baseColors.grey40
    },
    eventNameText: {
        ...appFonts.lgBold,
        marginBottom: spacing.s0
    },
    eventImageArea: {
        height: listItemImageSize,
        width: listItemImageSize,
        justifyContent: 'center',
        shadowRadius: spacing.s1,
        shadowOpacity: 0.15,
        shadowOffset: { width: 0, height: spacing.s1 },
        shadowColor: baseColors.black
    },
    eventImage: {
        width: '100%',
        height: '100%',
        borderRadius: spacing.s1
    },
    avatarListContainer: {
        marginLeft: spacing.s1
    },
    membersAvatar: {
        marginLeft: -spacing.s1,
        borderWidth: 1,
        borderColor: baseColors.white
    },
    button: {
        alignSelf: 'flex-start',
        marginTop: spacing.s3
    },
    avatarList: {
        color: baseColors.grey40,
        ...appFonts.xsRegular
    },
    imageStyle: {
        width: listItemImageSize,
        height: listItemImageSize
    },
    bonusSection: {
        paddingTop: spacing.s1
    }
};
