import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { translate, constants as coreConstants, entitiesHelper, getUserName } from '../../../core';
import * as eventsActions from '../../actions';
import * as selectors from '../../selectors';

export default function WithEventMembersBase(WrappedComponent) {
    class EventMembersBase extends PureComponent {
        static propTypes = {
            eventId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            members: PropTypes.array,
            inviters: PropTypes.array,
            actions: PropTypes.object.isRequired,
            i18n: PropTypes.object.isRequired,
            isLoading: PropTypes.bool
        };

        static defaultProps = {
            members: [],
            inviters: [],
            isLoading: false
        };

        constructor(props) {
            super(props);
            this.props.actions.getEventMembers(this.props.eventId);
            this.isLoadMore = true;
            this.isFirstLoading = true;
        }

        componentDidUpdate(prevProps) {
            if (prevProps.isLoading && !this.props.isLoading && !this.isFirstLoading &&
                (prevProps.members.length === this.props.members.length || this.props.members.length - prevProps.members.length < coreConstants.MEMBERS_MAX_COUNT)) {
                this.isLoadMore = false;
            }
            if (prevProps.isLoading && !this.props.isLoading && this.isFirstLoading) {
                this.isFirstLoading = false;
            }
        }

        loadMore = () => {
            if (this.isLoadMore && !this.props.isLoading) {
                this.props.actions.getEventMembers(this.props.eventId, { start: this.props.members.length });
            }
        };

        getDescription = item => {
            if (entitiesHelper.isPrivate(item)) {
                return item.description;
            }
            const location = (item.location && item.location !== 'global') ? item.location : this.props.i18n.t('no_location');
            if (item.department && item.department !== 'global') {
                return `${item.department} | ${location}`;
            }
            return location;
        };

        isInviter = item => _.includes(this.props.inviters, Number(item.id));

        get title() {
            return this.props.i18n.t('attendeesTitle');
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    loadMore={this.loadMore}
                    getTitle={getUserName}
                    getDescription={this.getDescription}
                    isInviter={this.isInviter}
                    isPrivate={entitiesHelper.isPrivate}
                    title={this.title}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        const eventId = ownProps.eventId || _.get(ownProps, 'match.params.id') || _.get(ownProps, 'route.params.eventId');
        return {
            members: selectors.getEventMembersAndPrivateSummary(state, eventId),
            privateMembersCount: selectors.getEventPrivateMembersCount(state, eventId),
            inviters: selectors.getEventInvitersIds(state, eventId),
            isLoading: selectors.isLoadingEventMembers(state),
            eventId
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(eventsActions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(EventMembersBase));
}
