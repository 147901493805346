import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import { baseColors, spacing, appFonts } from '../../../../styles';
import {
    isLoadingDevices,
    isConnecting,
    isHiddenAppsDevicesInformationText,
    getNumberOfConnectedDevices,
    getConnectedDevicesNames,
    getConnectedDevices,
    isSuccessfullyConnected
} from '../../selectors';
import {
    translate,
    constants as coreConstants,
    actions as coreActions,
    selectors as coreSelectors,
    parseQueryString,
    pointsHelper
} from '../../../core';
import * as actions from '../../actions';
import { selectors as authSelectors } from '../../../auth';
import { actions as onboardingActions, FIREBASE_NAMES, selectors as onboardingSelectors } from '../../../onboarding';

export default function WithAppsDevicesBase(WrappedComponent) {
    class AppsDevicesBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            callback: PropTypes.func,
            isConnecting: PropTypes.bool,
            onNext: PropTypes.func,
            step: PropTypes.string,
            isHiddenAppsDevicesInformationText: PropTypes.bool,
            programName: PropTypes.string.isRequired,
            isLoading: PropTypes.bool,
            currentUser: PropTypes.object.isRequired,
            points: PropTypes.number,
            numOfConnectedDevices: PropTypes.number,
            isOnboarding: PropTypes.bool,
            hasAppsDevicesTask: PropTypes.bool,
            tabs: PropTypes.array.isRequired,
            i18n: PropTypes.object.isRequired,
            customPointsName: PropTypes.string.isRequired,
            queryParams: PropTypes.object,
            isLiveBetter: PropTypes.bool,
            customPointsUnit: PropTypes.string.isRequired,
            isSuccessfullyConnected: PropTypes.bool,
            connected: PropTypes.array
        };

        static defaultProps = {
            onNext: null,
            step: null,
            callback: null,
            isHiddenAppsDevicesInformationText: false,
            isConnecting: false,
            points: 0,
            numOfConnectedDevices: 0,
            isOnboarding: false,
            hasAppsDevicesTask: false,
            isLoading: false,
            isLiveBetter: false,
            queryParams: {},
            isSuccessfullyConnected: false,
            connected: []
        };

        constructor(props) {
            super(props);
            //props.actions.getAppsDevices();
            if (props.isConnecting || props.queryParams.status === 'success') {
                props.actions.addToast(coreConstants.TOAST_TYPES.SUCCESS, 'connectingDevice');
            }
        }

        componentDidUpdate(prevProps) {
            if (this.props.numOfConnectedDevices > 0 && !prevProps.numOfConnectedDevices && !this.props.isOnboarding && this.props.hasAppsDevicesTask) {
                const userPoints = _.toInteger(_.get(this.props.currentUser, 'points', 0));
                const newPoints = userPoints + _.toInteger(this.props.points);
                this.props.actions.completeAppsDevicesStep();
                this.props.actions.updateUserPoints(newPoints);
            }
        }

        onNext = () => {
            if (this.props.onNext) this.props.onNext(this.props.step);
        };

        get title() {
            return this.props.i18n.t('apps_devices_title');
        }

        get points() {
            return pointsHelper.formatPoints(this.props.points, this.props.customPointsUnit);
        }

        get buttonLabel() {
            const { i18n, isLiveBetter } = this.props;
            return isLiveBetter ? i18n.t('next') : i18n.t('onboarding_button_title');
        }

        get description() {
            return this.props.i18n.t('apps_devices_description', { customPoints: this.props.customPointsName });
        }

        get arePointsHidden() {
            const { isLiveBetter, isSuccessfullyConnected, isOnboarding, connected } = this.props;
            return isLiveBetter || isSuccessfullyConnected || !isOnboarding || connected.length > 0;
        }

        get tagLabel() {
            return `+ ${this.props.points}`;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    tabs={this.props.tabs}
                    onNext={this.onNext}
                    onTabPress={this.onTabPress}
                    activeCategory={this.activeCategory}
                    title={this.title}
                    points={this.points}
                    buttonLabel={this.buttonLabel}
                    description={this.description}
                    arePointsHidden={this.arePointsHidden}
                    tagLabel={this.tagLabel}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const queryParams = parseQueryString(_.get(ownProps, 'location.search', ''));
        return {
            isLoading: isLoadingDevices(state),
            isConnecting: isConnecting(state),
            points: onboardingSelectors.getAppsDevicesPoints(state),
            currentUser: coreSelectors.getCurrentUser(state),
            numOfConnectedDevices: getNumberOfConnectedDevices(state),
            connectedDevices: getConnectedDevicesNames(state),
            hasAppsDevicesTask: onboardingSelectors.hasAppsDevicesTask(state),
            isHiddenAppsDevicesInformationText: isHiddenAppsDevicesInformationText(state),
            programName: authSelectors.getProgramName(state),
            customPointsName: coreSelectors.getCustomPointsName(state),
            isShowingOnboarding: onboardingSelectors.isShowingOnboarding(state),
            queryParams,
            startTime: onboardingSelectors.getStartTime(state, FIREBASE_NAMES.apps_and_devices),
            customPointsUnit: coreSelectors.getCustomPointsUnit(state),
            connected: getConnectedDevices(state),
            isSuccessfullyConnected: isSuccessfullyConnected(state)
        };
    };

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators({ ...actions, ...coreActions, ...onboardingActions }, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(AppsDevicesBase));
}

export const styles = {
    descriptionText: {
        ...appFonts.mdRegular,
        backgroundColor: baseColors.white,
        color: baseColors.grey20,
        marginTop: spacing.s3,
        marginBottom: spacing.s1,
    },
    title: {
        ...appFonts.xlBold,
        color: baseColors.black,
        marginLeft: 0,
        marginRight: 0,
        flex: 1
    },
    platformContainer: {
        paddingTop: spacing.s2
    }
};
