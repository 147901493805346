import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { baseColors, spacing } from '../../../../../styles';
import * as actions from '../../../actions';
import * as selectors from '../../../selectors';
import { translate, getFirstLoadingWrapper, selectors as coreSelectors, DATE_FORMATS } from '../../../../core';
import * as appsDevicesSelectors from '../../../../appsdevices/selectors';
import { QUARTER } from '../../../constants';

export default function WithDashboardBase(WrappedComponent) {
    class DashboardBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            levels: PropTypes.object.isRequired,
            score: PropTypes.bool,
            onboarding: PropTypes.bool,
            onRef: PropTypes.func,
            externalRewardName: PropTypes.string,
            i18n: PropTypes.object.isRequired,
            levelIndex: PropTypes.number,
            notCompletedLevels: PropTypes.array,
            isRewardsEnabled: PropTypes.bool,
            isAppsOrDevicesAvailable: PropTypes.bool,
            isLoading: PropTypes.bool,
            isLoadingData: PropTypes.bool,
            userFirstName: PropTypes.string,
            isLiveBetter: PropTypes.bool
        };

        static defaultProps = {
            score: false,
            onboarding: false,
            onRef: null,
            externalRewardName: '',
            levelIndex: undefined,
            isRewardsEnabled: false,
            notCompletedLevels: undefined,
            isAppsOrDevicesAvailable: false,
            isLoading: false,
            isLoadingData: false,
            userFirstName: '',
            isLiveBetter: false
        };

        constructor(props) {
            super(props);
            const currentLevelIndex = !_.isNil(props.levelIndex) ? props.levelIndex : 0;
            this.state = {
                currentLevelIndex,
                initialLevelIndex: currentLevelIndex,
                //eslint-disable-next-line
                isLoading: props.isLoading || props.isLoadingData, //used in getFirstLoadingWrapper
                isFirstLoaded: false
            };
        }

        static getDerivedStateFromProps(nextProps, prevState) {
            return getFirstLoadingWrapper(nextProps, prevState);
        }

        loadData = () => {
            this.props.actions.getUserLevels('week');
            this.props.actions.getRewards();
        };

        get currentLevelIndex() {
            return this.state.currentLevelIndex;
        }

        updateLevelInformation = index => {
            this.setState(() => ({ currentLevelIndex: index }));
        };

        get carouselData() {
            return this.props.notCompletedLevels || [{ name: '', score: 0 }];
        }

        get welcomeText() {
            const { i18n, userFirstName } = this.props;
            return i18n.t('welcomeUser', { userFirstName });
        }

        get yourProgressText() {
            const { i18n, levels } = this.props;
            return i18n.t('dashboard.timePeriodProgress',
                { period: _.get(levels, 'frequency', '') === QUARTER ? i18n.t('quarter') : i18n.t('period') });
        }

        get daysLeftText() {
            const { i18n, levels } = this.props;
            const daysNumber = Number(_.get(levels, 'remainingDays'));
            const daysString = daysNumber > 1 ? i18n.t('number_days', { num: daysNumber }) : i18n.t('one_day');
            if (daysNumber) {
                return (`${daysString} ${i18n.t('left')}`);
            } else if (daysNumber === 0) {
                return i18n.t('finalDay');
            }
            return null;
        }

        get levelDuration() {
            const startDate = moment(_.get(this.props.levels, 'startDate'));
            const endDate = moment(_.get(this.props.levels, 'endDate'));
            if (startDate.isValid()) {
                return `${startDate.format(DATE_FORMATS.monthDay)} - ${endDate.format(DATE_FORMATS.monthDay)}`;
            }
            return '';
        }

        render() {
            const { isFirstLoaded } = this.state;
            return (
                <WrappedComponent
                    {...this.props}
                    isLoading={this.props.isLoading || this.props.isLoadingData}
                    carouselData={this.carouselData}
                    updateLevelInformation={this.updateLevelInformation}
                    initialLevelIndex={this.state.initialLevelIndex}
                    loadData={this.loadData}
                    currentLevelIndex={this.state.currentLevelIndex}
                    isFirstLoaded={isFirstLoaded}
                    welcomeText={this.welcomeText}
                    yourProgressText={this.yourProgressText}
                    daysLeft={this.daysLeftText}
                    levelDuration={this.levelDuration}
                />
            );
        }
    }

    function mapStateToProps(state) {
        return {
            notCompletedLevels: selectors.getNotCompletedLevelsOrLast(state),
            isRewardsEnabled: selectors.isRewardsEnabled(state),
            isAppsOrDevicesAvailable: (appsDevicesSelectors.getAllDevices(state)).length > 0 ? true : false,
            isLoading: selectors.isLoadingRewards(state) || selectors.isLoadingLevels(state),
            isLoadingRewards: selectors.isLoadingRewards(state),
            userFirstName: coreSelectors.getUserFirstName(state),
            levels: selectors.getLevels(state),
            isLiveBetter: coreSelectors.isLiveBetter(state)
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(DashboardBase));
}

export const styles = {
    backgroundContainer: {
        backgroundColor: baseColors.dashboardBackground,
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0
    },
    newLevelContainer: {
        marginBottom: spacing.s4
    },
};
