import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import _ from 'lodash';

import { components as Core } from '../../../../core';
import EarnPartnersItem from '../EarnPartnersItem';
import WithEarnPartnersListBase, { styles as baseStyles } from './EarnPartnersListBase';
import { spacing, importantClass, media } from '../../../../../styles';

class EarnPartnersList extends PureComponent {
    static propTypes = {
        partnersForCategory: PropTypes.array.isRequired,
        scrollableTabs: PropTypes.array.isRequired,
        activeTab: PropTypes.object.isRequired,
        onTabPress: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired,
        i18n: PropTypes.object.isRequired,
        search: PropTypes.string,
        onSearch: PropTypes.func.isRequired,
        earnPartnersProgramName: PropTypes.string.isRequired,
        onClearSearch: PropTypes.func.isRequired
    };

    static defaultProps = {
        search: ''
    };

    get renderHeaderComponent() {
        const { earnPartnersProgramName } = this.props;

        return (
            <React.Fragment>
                <Core.EntityDetailsHeader
                    hasBackButton={true}
                    title={earnPartnersProgramName}
                />
                <Core.SubHeader
                    title={earnPartnersProgramName}
                    noTopPadding={true}
                    right={
                        <div className={css(styles.mainSearchBar)}>
                            {this.searchComponent}
                        </div>
                    }
                    additionalSearchBar={this.searchComponent}
                />
            </React.Fragment>
        );
    }

    get itemsToRender() {
        const { partnersForCategory, isLoading } = this.props;
        if (partnersForCategory.length > 0) {
            return (
                <Core.List component="div">
                    {_.map(partnersForCategory, this.renderPartnerItem)}
                </Core.List>
            );
        } else if (!partnersForCategory.length && !isLoading) {
            return this.renderEmptyListMessage;
        }
        return null;
    }

    get searchComponent() {
        return (
            <Core.SearchBar
                className={css(styles.searchBar)}
                value={this.props.search}
                onChangeText={this.props.onSearch}
                onClear={this.props.onClearSearch}
                label={this.props.i18n.t('search')}
            />
        );
    }

    get renderEmptyListMessage() {
        const { activeTab, search } = this.props;
        if (search) {
            return (
                <Core.EmptyListSimple
                    message={this.props.i18n.t('noPartnersFound')}
                    className={css(styles.emptyList)}
                    isIconHidden={true}
                />
            );
        }

        return (
            <Core.EmptyListSimple
                message={activeTab.emptyMessage}
                className={css(styles.emptyList)}
            />
        );
    }

    renderPartnerItem = data => <EarnPartnersItem partner={data} />;

    render() {
        return (
            <Core.Layout.WiderContainer>
                <Core.BlockingLoading isLoading={this.props.isLoading} />
                {this.renderHeaderComponent}
                <Core.TabsWithContent
                    onChange={this.props.onTabPress}
                    tabs={this.props.scrollableTabs}
                    activeTab={this.props.activeTab}
                    showTabs={!this.props.search}
                    isStickyUnderNavBar={true}>
                    {this.itemsToRender}
                </Core.TabsWithContent>
            </Core.Layout.WiderContainer>
        );
    }
}

export default WithEarnPartnersListBase(EarnPartnersList);

const styles = StyleSheet.create({
    ...baseStyles,
    searchBar: importantClass({
        marginBottom: 0
    }),
    mainSearchBar: {
        marginLeft: spacing.s4,
        display: 'block',
        [media.xsOnly]: {
            display: 'none'
        }
    }
});
