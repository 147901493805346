import i18n from '../../../i18n';

export const getContentItemAttributes = (title, item, unit) => {
    const joinTags = item.tagsArr.join(', ');
    const tagsValue = joinTags?.length ? `Tags: ${joinTags}.` : '';

    const pointsValue = item?.externalRewardPoints || item?.activity_quantity || 0;
    const earnText = item.already_read === '1' ? i18n.t('bonusMark.earned', { qty: pointsValue, points: unit }) : i18n.t('bonusMark.earn', { qty: pointsValue, points: unit }) ;

    const label = `${tagsValue} ${title}. ${pointsValue > 0 ? earnText : ''}`;

    return {
        accessible: true,
        accessibilityLabel: label,
        testID: 'ContentItem',
        accessibilityRole: 'none'
    };
};
