import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { baseColors, spacing, layoutStyle } from '../../../../styles';
import { components as Core, ROUTES } from '../../../core';
import { components as Challenges } from '../../../challenges';
import WithTaskCard, { styles as baseStyles } from './TaskCardBase';

class TaskCard extends PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
        i18n: PropTypes.object.isRequired,
        task: PropTypes.object.isRequired,
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        icon: PropTypes.object.isRequired,
        label: PropTypes.string.isRequired,
        points: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    };

    loadTaskFlow = () => {
        const step = _.get(this.props.task, 'tasks.0.task_slug');
        const slug = _.get(this.props.task, 'group_slug');
        this.props.history.push(ROUTES.onboardingFlowStep(step), { slug, isTaskCard: true });
    };

    render() {
        const { description, points, icon, label, title, subtitle } = this.props;
        return (
            <Challenges.Card.Container hasPointer={false}>
                <Challenges.Card.Header
                    title={title}
                    subtitle={subtitle}
                    icon={<Challenges.ChallengeCardHeaderIcon icon={icon} />}
                />
                <Challenges.Card.Body isGrey={false} style={layoutStyle.flexAlignCenter}>
                    <div className={css(styles.textContainer, styles.bodyText)}>
                        {description}
                    </div>
                    <div className={css(layoutStyle.flex1)}>
                        {points ? (
                            <div className={css(styles.bonusContainerInner, styles.bonusText)}>
                                {this.props.i18n.t('earn')}
                                <span className={css(styles.points)}>{points}</span>
                            </div>
                        ) : null}
                    </div>
                </Challenges.Card.Body>
                <Challenges.Card.Footer isGrey={false}>
                    <Core.Button
                        size="medium"
                        className={css(layoutStyle.cardFooterButton)}
                        onPress={this.loadTaskFlow}>
                        {label}
                    </Core.Button>
                </Challenges.Card.Footer>
            </Challenges.Card.Container>
        );
    }
}

export default withRouter(WithTaskCard(TaskCard));

const styles = StyleSheet.create({
    ...baseStyles,
    points: {
        color: baseColors.primary,
        marginLeft: spacing.s0
    }
});