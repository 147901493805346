import React from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';
import { spacing, baseColors } from '../../../../styles';
import WithFeaturedTagBase, { styles as baseStyles } from './FeaturedTagBase';

const FeaturedTag = ({ showTag, containerClassName, textClassName, text }) => (
    !!showTag && (
        <div className={css(styles.container, { backgroundColor: baseColors.primary }, containerClassName)}>
            <p className={css(styles.text, textClassName)}>{text}</p>
        </div>
    )
);

FeaturedTag.propTypes = {
    textClassName: PropTypes.string,
    containerClassName: PropTypes.string

};

FeaturedTag.defaultProps = {
    textClassName: undefined,
    containerClassName: undefined
};

export default WithFeaturedTagBase(FeaturedTag);

const styles = StyleSheet.create({
    ...baseStyles,
    container: {
        ...baseStyles.container,
        padding: `${spacing.s0}px ${spacing.s1}px`,
        whiteSpace: 'nowrap'
    }
});
