import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { getFeed } from '../../selectors';
import { ROUTES, ENTITIES } from '../../../core';

export default function WithFeedItemBase(WrappedComponent) {
    class FeedItemBase extends PureComponent {
        static propTypes = {
            streamItemId: PropTypes.number.isRequired,
            stream: PropTypes.object.isRequired,
            commentProps: PropTypes.object,
            isDetails: PropTypes.bool
        };

        static defaultProps = {
            commentProps: {},
            isDetails: false
        };

        entityDetailsInfo = key => {
            const { stream } = this.props;
            if (key === 'user.name') {
                return this.userDetailsInfo(stream.streamItemDisplay.generatedText.values.user.id.toString());
            }
            switch (stream.relationTypeSlug) {
                case ENTITIES.group: {
                    if (stream.relatedItemDetails.groupId || stream.relatedToId) {
                        const id = _.get(stream.relatedItemDetails, 'groupId', '').toString() || _.get(stream, 'relatedToId', '').toString();
                        return {
                            screen: ROUTES.communityDetails(),
                            url: ROUTES.communityDetails(id),
                            props: { id },
                        };
                    }
                    return {};
                }
                case ENTITIES.challenge: {
                    const id = stream.relatedItemDetails.challengeId;
                    return {
                        screen: ROUTES.challengeDetails(),
                        url: ROUTES.challengeDetails(id),
                        props: { challengeId: id },
                    };
                }
                case ENTITIES.event: {
                    const id = stream.relatedItemDetails.eventId || stream.relatedToId;
                    return {
                        screen: ROUTES.eventDetails(),
                        url: ROUTES.eventDetails(id),
                        props: { itemId: id }
                    };
                }
                default:
                    return {};
            }
        };

        userDetailsInfo = id => ({
            screen: ROUTES.userProfile(),
            url: ROUTES.userProfile(id),
            props: { id }
        });

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    entityDetailsInfo={this.entityDetailsInfo}
                    userDetailsInfo={this.userDetailsInfo}
                    relationTypeSlug={this.props.stream.relationTypeSlug}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        return {
            stream: getFeed(state, ownProps.streamItemId)
        };
    }

    return connect(mapStateToProps)(FeedItemBase);
}