import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';
import classnames from 'classnames';
import { baseColors, layoutStyle } from '../../../../../styles';
import { styles as baseStyles } from './FooterBase';

class CardFooter extends PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        className: PropTypes.string,
        isGrey: PropTypes.bool,
        isBorder: PropTypes.bool,
        isColumn: PropTypes.bool,
        isDismiss: PropTypes.bool
    };

    static defaultProps = {
        className: undefined,
        isGrey: true,
        isBorder: false,
        isColumn: false,
        isDismiss: false
    };

    render() {
        const { isGrey, className, isBorder, isColumn, isDismiss } = this.props;
        return (
            <div className={
                classnames(
                    css(layoutStyle.cardPaddingHalf, !isDismiss && styles.footer),
                    { [css(styles.grey)]: isGrey, [css(styles.border)]: isBorder },
                    { [css(layoutStyle.flexColumnCenter)]: isColumn, [css(layoutStyle.flexRowCenter)]: !isColumn },
                    className
                )
            }>
                {this.props.children}
            </div>
        );
    }
}

export default CardFooter;

const styles = StyleSheet.create({
    ...baseStyles,
    grey: {
        ...baseStyles.grey,
        backgroundColor: baseColors.grey90,
    },
    border: {
        ...baseStyles.border,
        borderTopStyle: 'solid'
    }
});

