import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { browserName, browserVersion } from 'react-device-detect';
import _ from 'lodash';
import HealthScoreFeedbackModalBase, { styles as baseStyles } from './HealthScoreFeedbackModalBase';
import { baseColors, importantClass, layoutStyle, spacing } from '../../../../styles';
import { components as Core } from '../../../core';

const FIELD_ROW_NUMBER = 4;

class HealthScoreFeedbackModal extends PureComponent {
    static propTypes = {
        emojiSectionText: PropTypes.string.isRequired,
        emojies: PropTypes.object.isRequired,
        commentSectionText: PropTypes.string.isRequired,
        commentSectionPlaceholder: PropTypes.string.isRequired,
        thankYouModalText: PropTypes.string.isRequired,
        submitBtnText: PropTypes.string.isRequired,
        closeBtnText: PropTypes.string.isRequired,
        closeModal: PropTypes.func.isRequired,
        isEmojiSectionVisible: PropTypes.bool.isRequired,
        isThankYouModalVisible: PropTypes.bool.isRequired,
        isCommentSectionVisible: PropTypes.bool.isRequired,
        userComment: PropTypes.string.isRequired,
        setNewUserComment: PropTypes.func.isRequired,
        setChosenRating: PropTypes.func.isRequired,
        chosenRating: PropTypes.PropTypes.string.isRequired,
        submitForm: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired,
        spaceInEmojiBlock: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            heightOfModalWrapper: 0,
        };
    }

    componentDidMount() {
        this.modalWrapperHeight();
    }

    componentDidUpdate(prevProps) {
        const { isEmojiSectionVisible, isCommentSectionVisible, isThankYouModalVisible } = this.props;

        const updateComponent = !(isEmojiSectionVisible === prevProps.isEmojiSectionVisible &&
            isCommentSectionVisible === prevProps.isCommentSectionVisible &&
            isThankYouModalVisible === prevProps);
        if (updateComponent) {
            this.modalWrapperHeight();
        }
    }

    onCommentValueChange(e) {
        const { setNewUserComment } = this.props;
        setNewUserComment(e.target.value);
    }

    onSubmit = () => {
        const { submitForm } = this.props;
        const browserInfo = `${browserName} ${browserVersion}`;
        submitForm(browserInfo);
    }

    get emojiSection() {
        const { emojies, isEmojiSectionVisible, spaceInEmojiBlock, emojiSectionText } = this.props;

        const bottomSpaceClass = _.get(styles, spaceInEmojiBlock);

        return isEmojiSectionVisible && (
            <div className={css(styles.horizontalPadding)}>
                <Core.Text className={css(styles.modalText)}>{emojiSectionText}</Core.Text>
                <div className={css(styles.emojiWrapper)}>
                    {this.emojiItem(emojies.frowningFace)}
                    {this.emojiItem(emojies.neutralFace)}
                    {this.emojiItem(emojies.grinningFace)}
                </div>
                <div className={css(bottomSpaceClass)} />
            </div>
        );
    }

    get commentSection() {
        const { commentSectionText, isCommentSectionVisible, commentSectionPlaceholder, submitBtnText,
            userComment } = this.props;

        return isCommentSectionVisible && (
            <>
                <div className={css(styles.horizontalPadding)}>
                    <Core.Text className={css(styles.modalText)}>{commentSectionText}</Core.Text>
                    <Core.Input
                        placeholder={commentSectionPlaceholder}
                        multiline={true}
                        rows={FIELD_ROW_NUMBER}
                        inputClassName={styles.inputClassName}
                        className={css(styles.inputStyles)}
                        fullWidth={true}
                        autoFocus={true}
                        value={userComment}
                        onChange={e => this.onCommentValueChange(e)}
                    />
                </div>
                <div className={css(styles.divider)} />
                <div
                    role="button"
                    onClick={this.onSubmit}
                    tabIndex="0"
                    className={css(styles.footerButton, layoutStyle.cp)}>
                    {submitBtnText}
                </div>
            </>
        );
    }

    get thankYouModal() {
        const { thankYouModalText, closeBtnText, closeModal, isThankYouModalVisible } = this.props;

        return isThankYouModalVisible && (
            <>
                <Core.Text className={css(styles.modalText, styles.horizontalPadding)}>{thankYouModalText}</Core.Text>
                <div className={css(styles.divider)} />
                <div
                    role="button"
                    className={css(styles.footerButton, layoutStyle.cp)}
                    onClick={closeModal}
                    tabIndex="0">
                    {closeBtnText}
                </div>
            </>
        );
    }

    modalWrapperHeight = () => {
        const modal = document.getElementById('mainContainer');
        this.setState({ heightOfModalWrapper: modal.clientHeight + 35 });
    }

    emojiItem = item => {
        const { chosenRating } = this.props;

        const middleEmojiStyle = item.rating === '3' ? styles.spaceAroundCenterEmoji : null;

        let activeClassName = null;
        if (chosenRating === item.rating) activeClassName = styles.activeEmoji;

        const wrapperClasses = [styles.emojiBlock, middleEmojiStyle, activeClassName];

        return (
            <div
                role="button"
                tabIndex="0"
                onClick={() => this.changeRating(item.rating)}
                className={css(...wrapperClasses)}>
                <img src={item.src} className={css(styles.emoji)} />
            </div>
        );
    }

    changeRating(newRating) {
        this.props.setChosenRating(newRating);
    }

    render() {
        const { isLoading } = this.props;

        return (
            <>
                <div className={css(styles.modalWrapper)} style={{ height: this.state.heightOfModalWrapper }}>
                    <div id="mainContainer" className={css(styles.mainContainer)}>
                        <Core.BlockingLoading isLoading={isLoading} />
                        <div className={css(styles.iconBlockWrapper)}>
                            <div className={css(styles.iconBlock)}>
                                <Core.Icon
                                    type="light"
                                    name="comment-dots"
                                    size={spacing.s7}
                                />
                            </div>
                        </div>
                        {this.emojiSection}
                        {this.commentSection}
                        {this.thankYouModal}
                    </div>
                </div>
            </>
        );
    }
}


const styles = StyleSheet.create({
    ...baseStyles,
    activeEmoji: {
        border: `3px solid ${baseColors.secondary}`
    },
    horizontalPadding: {
        padding: `0 ${spacing.s3}px`,
    },
    inputClassName: importantClass({
        marginTop: spacing.s1,
        marginBottom: spacing.s1
    })
});

export default HealthScoreFeedbackModalBase(HealthScoreFeedbackModal);
