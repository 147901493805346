import React from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';
import { components as Core } from '../../../core';
import { baseColors, spacing } from '../../../../styles';
import WithProgressTitleBase, { styles as baseStyles } from './ProgressTitleBase';

const ProgressTitle = ({ title, onPress, buttonTitle, isDailyProgress }) => (
    <div className={css(isDailyProgress ? styles.daylyProgressTitle : styles.progressTitle)}>
        <div className={css(styles.flexRow)}>
            <p className={css(styles.title)}>{title}</p>
        </div>
        <div className={css(isDailyProgress ? styles.negativeMargin : styles.flexRow)}>
            <Core.Button
                onPress={onPress}
                type="text"
                size={Core.Button.SIZES.small}>
                {buttonTitle}
                {!isDailyProgress ? (
                    <Core.Icon
                        name="angle-right"
                        type="fa"
                        size={spacing.s3}
                        color={baseColors.secondary}
                    />
                ) : null }
            </Core.Button>
        </div>
    </div>
);

ProgressTitle.propTypes = {
    title: PropTypes.string,
    onPress: PropTypes.func.isRequired,
    buttonTitle: PropTypes.string.isRequired
};

ProgressTitle.defaultProps = {
    title: null
};

export default WithProgressTitleBase(ProgressTitle);

const styles = StyleSheet.create({
    ...baseStyles
});