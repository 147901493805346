import React, { PureComponent } from 'react';
import { css, StyleSheet } from 'aphrodite-jss';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { layoutStyle, important, headerHeight, spacing, baseColors } from '../../../../styles';
import { components as Core, ROUTES, CAROUSEL_TYPES } from '../../../core';
import GoalCard from './GoalCard';
import ChallengeCard from '../ChallengeCard';
import GoalsInfo from '../GoalsInfo';
import PersonalGoalsList from './PersonalGoalsList';
import { RECOMMENDED_GOALS } from '../../../onboarding/constants';
import WithPersonalGoalsBase, { styles as baseStyles } from './PersonalGoalsBase';

// disabled fly-to-noGoalIcon-animation
//const ANIMATION_DURATION = 1000;

class PersonalGoals extends PureComponent {
    static propTypes = {
        goals: PropTypes.array,
        onRef: PropTypes.func,
        onAllGoalsSet: PropTypes.func,
        hasAllGoalsSet: PropTypes.bool,
        isSettingRecommendedGoals: PropTypes.bool,
        selectedTab: PropTypes.object.isRequired,
        isTopTab: PropTypes.bool.isRequired,
        onSetPersonalGoal: PropTypes.func.isRequired,
        tabSelected: PropTypes.func.isRequired,
        tabs: PropTypes.array.isRequired,
        isDisabledSetting: PropTypes.bool.isRequired,
        isTaskCard: PropTypes.bool,
        isShowingOnboarding: PropTypes.bool,
        isEarnPointsTextShown: PropTypes.bool,
        earnPointsTextStart: PropTypes.string,
        earnPointsTextEnd: PropTypes.string,
        earnPointsTextCenter: PropTypes.string,
        createCustomGoalButton: PropTypes.node.isRequired,
    };

    static defaultProps = {
        goals: undefined,
        onRef: null,
        onAllGoalsSet: null,
        hasAllGoalsSet: false,
        isSettingRecommendedGoals: false,
        isTaskCard: false,
        isShowingOnboarding: false,
        isEarnPointsTextShown: false,
        earnPointsTextStart: null,
        earnPointsTextEnd: null,
        earnPointsTextCenter: null,
    };

    nodes = [];
    activeSlide = 0;

    componentDidMount() {
        if (this.props.onRef) this.props.onRef(this);
    }

    componentWillUnmount() {
        if (this.props.onRef) this.props.onRef(null);
    }

    componentDidUpdate(prevProps) {
        if (this.props.hasAllGoalsSet && !prevProps.hasAllGoalsSet && this.props.onAllGoalsSet) {
            this.props.onAllGoalsSet();
        }
    }

    setGoal = (goal, node, isSet, id) => {
        const { isSettingRecommendedGoals, isDisabledSetting, onSetPersonalGoal } = this.props;

        if (isSettingRecommendedGoals || (isDisabledSetting && !isSet)) return;

        onSetPersonalGoal(goal, isSet, id);

        // disabled fly-to-noGoalIcon-animation
        // !isSet && animations.flyTo(node, this.node, () => {
        //     if (this.node) {
        //         this.node.style.transition = `color ${ANIMATION_DURATION}ms`;
        //         this.node.style.color = getRecommendedGoalIconColor(goal);
        //     }
        // }, ANIMATION_DURATION, true);
    };

    renderCarouselItem = (item, index) => (
        <GoalCard
            key={index}
            noPadding={this.props.isShowingOnboarding}
            buttonPress={this.setGoal}
            challengeId={item.goalName}
            isLoading={this.props.isSettingRecommendedGoals}
        />
    );

    renderSetCarouselItem = item => (
        <ChallengeCard
            key={item.challengeId}
            challengeId={item.challengeId}
            isDisabled={false}
        />
    );

    get tabContent() {
        return (
            <PersonalGoalsList
                setGoal={this.setGoal}
                hasBorder={true}
                isSetPersonalGoalPage={true}
                isDisabledSetting={this.props.isDisabledSetting}
                slug={this.props.isTopTab ? null : this.props.selectedTab.id}
            />
        );
    }

    get renderSetGoalsCarousel() {
        const { isTaskCard, goals } = this.props;
        return (
            <div className={css(styles.setCarouselContainer)}>
                <Core.Carousel
                    parent={this}
                    type={isTaskCard ? CAROUSEL_TYPES.twoOnly : CAROUSEL_TYPES.goalsCarousel}
                    arrows={true}
                    dots={true}
                    itemsLength={goals.length}>
                    {_.map(goals, this.renderSetCarouselItem)}
                </Core.Carousel>
            </div>
        );
    }

    saveRef = nodes => (this.nodes = nodes);

    get node() {
        return this.nodes[this.props.goals.length];
    }

    get detailsDeleteRedirect() {
        return this.props.isShowingOnboarding ? ROUTES.onboardingFlowStep(RECOMMENDED_GOALS) : ROUTES.setPersonalGoal();
    }

    render() {
        const { isEarnPointsTextShown, isShowingOnboarding, goals, isTaskCard, tabs, tabSelected, selectedTab,
            earnPointsTextStart, earnPointsTextEnd, earnPointsTextCenter, createCustomGoalButton, headerTitle } = this.props;
        return (
            <div className={isShowingOnboarding && css(styles.goalsContainer)}>
                <GoalsInfo
                    isTitleVisible={false}
                    containerClassName={css(styles.goalsInfo)}
                    goals={goals}
                    onRef={this.saveRef}
                    detailsDeleteRedirect={this.detailsDeleteRedirect}
                    isTaskCard={isTaskCard}
                />
                {isEarnPointsTextShown && (
                    <p className={css(styles.earnPointsText, layoutStyle.textCenter)}>
                        {earnPointsTextStart}
                        <span className={css(styles.points)}>{earnPointsTextCenter}</span>
                        {earnPointsTextEnd}
                    </p>
                )}
                <div className={css(styles.divider)} />
                <React.Fragment>
                    <p className={css(styles.headerTitle)}>{headerTitle}</p>
                    <Core.TabsWithContent
                        tabs={tabs}
                        onPress={tabSelected}
                        activeTab={selectedTab}
                        isStickyUnderNavBar={true}
                        barStyles={styles.tabsSticky}
                        tabsInnerComponent={createCustomGoalButton}>
                        {this.tabContent}
                    </Core.TabsWithContent>
                </React.Fragment>
            </div>
        );
    }
}

export default WithPersonalGoalsBase(PersonalGoals);

const styles = StyleSheet.create({
    ...baseStyles,
    tabsSticky: {
        top: important(headerHeight)
    },
    goalsContainer: {
        '@media (min-width:997px)': {
            paddingLeft: spacing.s1,
            paddingRight: spacing.s0
        }
    },
    earnPointsText: {
        ...baseStyles.earnPointsText,
        color: baseColors.primary
    },
    divider: {
        height: spacing.s0,
        marginBottom: spacing.s7,
        backgroundColor: baseColors.grey85,
    }
});
