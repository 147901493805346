import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { components as Core, Modal } from '../../../core';
import WithSkipEAPModalsLogicBase from './SkipEAPModalsLogicBase';

export default function WithSkipEAPModalsLogic(WrappedComponent) {
    class SkipEAPModalsLogic extends PureComponent {
        static propTypes = {
            infoModalProps: PropTypes.func.isRequired,
            navigator: PropTypes.object.isRequired,
            i18n: PropTypes.object.isRequired,
            skip: PropTypes.func,
            cancelButtonLabel: PropTypes.string,
            okButtonLabel: PropTypes.string,
        };

        static defaultProps = {
            skip: null,
            cancelButtonLabel: undefined,
            okButtonLabel: undefined,
        };

        onSkipEAP = () => {
            this.closeModal();
            this.props.skip();
        };

        showInfoModal = () => {
            const { infoModalProps, okButtonLabel, cancelButtonLabel } = this.props;

            this.closeModal = Modal.open(
                Core.InfoModal,
                {
                    ...infoModalProps(),
                    buttons: [
                        { text: okButtonLabel, onPress: () => this.onSkipEAP(), isBoldText: true },
                        { text: cancelButtonLabel, onPress: () => this.closeModal() }
                    ]
                },
                {
                    cancelable: false,
                    fadeTransition: true,
                    PaperProps: { elevation: 0 },
                    isNoPadding: true,
                    isTransparent: true
                }
            );
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    showInfoModal={this.showInfoModal}
                />
            );
        }
    }

    return WithSkipEAPModalsLogicBase(SkipEAPModalsLogic);
}
