import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import _ from 'lodash';
import Popover from '@material-ui/core/Popover';
import Grow from '@material-ui/core/Grow';
import { spacing, baseColors, appFonts } from '../../../../styles';
import { components as Core, Modal, AsyncComponent, onKeyPress, } from '../../../core';
import WithLikesCommentsPanelBase, { styles as baseStyles, reactionIcon, commentIcon } from './LikesCommentsPanelBase';
import modalProps from '../ReactionsList/modalProps';
import ReactionsModal from '../ReactionsModal';

class LikesCommentsPanel extends PureComponent {
    static propTypes = {
        likeCount: PropTypes.number.isRequired,
        commentCount: PropTypes.number.isRequired,
        isLikeActive: PropTypes.bool.isRequired,
        doLike: PropTypes.func.isRequired,
        doComment: PropTypes.func.isRequired,
        onCommentsClick: PropTypes.func.isRequired,
        i18n: PropTypes.object.isRequired,
        isLoading: PropTypes.bool,
        commentsCountLabel: PropTypes.string,
        likedItemType: PropTypes.string.isRequired,
        likedItemId: PropTypes.number.isRequired,
        appliedReactions: PropTypes.object.isRequired,
        hasReactions: PropTypes.bool.isRequired,
        getReactionImage: PropTypes.func.isRequired,
        selectReaction: PropTypes.func.isRequired,
        removeReaction: PropTypes.func.isRequired,
        userHasSelectedReaction: PropTypes.func.isRequired,
        reactionsCountString: PropTypes.string,
        reactionsAvailable: PropTypes.bool.isRequired
    };

    static defaultProps = {
        isLoading: false,
        commentsCountLabel: undefined,
        reactionsCountString: undefined
    };

    constructor(props) {
        super(props);
        this.state = { reactionsMenuIsOpen: false };
    }

    showLikes = () => {
        if (this.props.likeCount > 0) {
            const { likedItemType, likedItemId, likeCount } = this.props;
            Modal.open(
                AsyncComponent(() => import('../Likes')),
                { likedItemType, likedItemId, likeCount },
                modalProps
            );
        }
    };

    showReactions = () => {
        if (this.props.hasReactions) {
            const { likedItemType, likedItemId, reactionsCount, appliedReactions } = this.props;
            Modal.open(
                AsyncComponent(() => import('../ReactionsList')),
                { likedItemType, likedItemId, reactionsCount, appliedReactions },
                modalProps
            );
        }
    };

    saveRefLike = ref => (this.privacyLike = ref);

    doLike = () => this.privacyLike && this.privacyLike.action();

    showReactionsMenu = event => {
        let menuX, menuY;
        const rect = event.target.getBoundingClientRect();
        if (rect) {
            menuX = rect.right - (rect.width/2);
            menuY = rect.top - spacing.s1;
        }
        if (this.props.reactionsAvailable) {
            this.setState({ reactionsMenuIsOpen: true, reactionsAnchorEl: _.get(event, 'target'), menuX, menuY });
        }
    };

    get reactionsSection() {
        const { appliedReactions, hasReactions } = this.props;
        if (hasReactions) {
            return (
                <>
                    {_.map(appliedReactions, this.renderAppliedReaction)}
                    {this.addReactionButton}
                </>
            );
        }
        return (
            this.addReactionButton
        );
    }

    get addReactionButton() {
        return (
            <div
                role="button"
                tabIndex="0"
                onKeyDown={onKeyPress.enter(this.showReactionsMenu)}
                className={css(styles.iconButton, styles.iconButtonHover)}
                onClick={this.showReactionsMenu}>
                <Core.Image
                    src={reactionIcon}
                    className={css(styles.actionPanelIcon)}
                />
            </div>
        );
    }

    renderAppliedReaction = reaction => {
        const { userHasSelectedReaction, removeReaction, selectReaction, getReactionImage } = this.props;
        return (
            <div
                role="button"
                tabIndex="0"
                className={css(styles.iconButton, styles.appliedReactionButton, userHasSelectedReaction(reaction) ? styles.userAppliedReactionButton : null)}
                onKeyDown={onKeyPress.enter(userHasSelectedReaction(reaction) ? () => removeReaction(reaction) : () => selectReaction(reaction))}
                onClick={userHasSelectedReaction(reaction) ? () => removeReaction(reaction) : () => selectReaction(reaction)}>
                <Core.Image src={getReactionImage(reaction.slug)} className={css(styles.emojiImage)} />
                <p className={css(styles.countText)} allowFontScaling={false}>{reaction.count}</p>
            </div>
        );
    };

    closeReactionsMenu = () => this.setState({ reactionsMenuIsOpen: false, reactionsAnchorEl: null, menuX: null, menuY: null });

    reactionsMenu(reactionsMenuIsOpen, reactionsAnchorEl, menuX, menuY, usePosition) {
        const { likedItemId, toUserId, likedItemType } = this.props;
        const passProps = { likedItemId, toUserId, likedItemType };
        return (
            <Popover
                anchorEl={reactionsAnchorEl}
                anchorPosition={{ left: menuX, top: menuY }}
                anchorReference={usePosition ? 'anchorPosition' : 'anchorEl'}
                open={reactionsMenuIsOpen}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                TransitionComponent={Grow}
                onClose={this.closeReactionsMenu}
                PaperProps={{
                    style: {
                        boxShadow: baseColors.shadowHighest,
                        borderRadius: spacing.s16,
                    }
                }}>
                <ReactionsModal closeModal={this.closeReactionsMenu} {...passProps} />
            </Popover>
        );
    }

    render() {
        const { commentCount, doComment, hasReactions, reactionsCountString } = this.props;
        const { reactionsMenuIsOpen, reactionsAnchorEl, menuX, menuY } = this.state;
        const usePosition = menuX && menuY;
        return (
            <div className={css(styles.mainContainer)}>
                <div className={css(styles.actionPanel)}>
                    {this.reactionsMenu(reactionsMenuIsOpen, reactionsAnchorEl, menuX, menuY, usePosition)}
                    <div className={css(styles.iconArea, hasReactions ? styles.wideWidth : styles.halfWidth)}>
                        {this.reactionsSection}
                    </div>
                    <div className={css(styles.iconArea, hasReactions ? styles.smallWidth : styles.halfWidth)}>
                        <div
                            role="button"
                            tabIndex="0"
                            onKeyDown={onKeyPress.enter(doComment)}
                            className={css(styles.iconButton, styles.iconButtonHover)}
                            onClick={doComment}>
                            <Core.Image
                                src={commentIcon}
                                className={css(styles.actionPanelIcon)}
                            />
                            {commentCount > 0 ? <p className={css(styles.countText)} allowFontScaling={false}>{commentCount} </p> : null}
                        </div>
                    </div>
                </div>
                {reactionsCountString ? (
                    <div role="button" tabIndex="0" onClick={this.showReactions} onKeyDown={this.showReactions}>
                        <p className={css(styles.reactionsCountText)} allowFontScaling={false}>{reactionsCountString}</p>
                    </div>
                )
                    : null}
            </div>
        );
    }
}

export default WithLikesCommentsPanelBase(LikesCommentsPanel);

const styles = StyleSheet.create({
    ...baseStyles,
    actionPanel: {
        ...baseStyles.actionPanel,
        display: 'flex',
    },
    mainContainer: {
        ...baseStyles.mainContainer,
        borderTopStyle: 'solid',
        backgroundColor: baseColors.white
    },
    iconArea: {
        ...baseStyles.iconArea,
        alignItems: 'center',
        display: 'flex',
    },
    iconButton: {
        ...baseStyles.iconButton,
        height: spacing.s7,
        display: 'flex',
    },
    iconButtonHover: {
        '&:hover': {
            backgroundColor: baseColors.grey85
        }
    },
    appliedReactionButton: {
        ...baseStyles.appliedReactionButton,
        borderColor: baseColors.grey85,
        borderWidth: 1,
        borderStyle: 'solid',
        '&:hover': {
            borderColor: baseColors.grey70,
        }
    },
    userAppliedReactionButton: {
        ...baseStyles.userAppliedReactionButton,
        '&:hover': {
            borderColor: baseColors.context,
            borderWidth: 1,
            borderStyle: 'solid',
        }
    },
    wideWidth: {
        ...baseStyles.wideWidth,
        marginRight: spacing.s3,
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
    },
    reactionsCountText: {
        ...appFonts.mdRegular,
        color: baseColors.grey40,
        paddingLeft: spacing.s3,
        paddingBottom: spacing.s2,
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline'
        }
    }
});
