import _ from 'lodash';
import i18n from '../../../i18n/i18n';

export const frequencyMap = () => ({
    daily: {
        unit: i18n.t('day').toLowerCase(),
        units: i18n.t('days').toLowerCase(),
        label: i18n.t('daily').toLowerCase(),
        longLabel: `${i18n.t('every')} ${i18n.t('day')}`.toLowerCase(),
        multiplier: 1
    },
    weekly: {
        unit: i18n.t('week').toLowerCase(),
        units: i18n.t('weeks').toLowerCase(),
        label: i18n.t('weekly').toLowerCase(),
        longLabel: `${i18n.t('every')} ${i18n.t('week')}`.toLowerCase(),
        multiplier: 7
    },
    monthly: {
        unit: i18n.t('month').toLowerCase(),
        units: i18n.t('months').toLowerCase(),
        label: i18n.t('monthly').toLowerCase(),
        longLabel: `${i18n.t('every')} ${i18n.t('month')}`.toLowerCase(),
        multiplier: 30
    },
    total: {
        unit: i18n.t('inTotal').toLowerCase(),
        label: i18n.t('inTotal').toLowerCase(),
        longLabel: i18n.t('inTotal').toLowerCase()
    }
});

const goalDurationMap = {
    days: {
        multiplier: 1
    },
    weeks: {
        multiplier: 7
    },
    months: {
        multiplier: 30
    },
    years: {
        multiplier: 365
    }
};

export function frequencyToUnit(frequency) {
    return _.get(frequencyMap(), [frequency, 'unit']);
}

export function frequencyToUnits(frequency) {
    return _.get(frequencyMap(), [frequency, 'units']);
}

export function frequencyToLabel(frequency) {
    return _.get(frequencyMap(), [frequency, 'label']);
}

export function frequencyToLongLabel(frequency) {
    return _.get(frequencyMap(), [frequency, 'longLabel']);
}

export function frequencyToMultiplier(frequency) {
    return _.get(frequencyMap(), [frequency, 'multiplier']);
}

export function getFrequencyDuration(duration, frequency) {
    return duration * (_.get(frequencyMap(), [frequency, 'multiplier']) || 1);
}

export function getOriginDuration(duration, frequency) {
    return duration / (_.get(frequencyMap(), [frequency, 'multiplier']) || 1);
}

export function getGoalDuration(duration, frequency) {
    return duration * (_.get(goalDurationMap, [frequency, 'multiplier']) || 1);
}

export function pluralFrequencyToUnit(duration, frequency) {
    return (duration === 1 ? frequencyToUnit(frequency) : frequencyToUnits(frequency)) || frequencyToUnits('daily');
}

