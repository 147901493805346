import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { selectors as coreSelectors, Alert, translate, ActionSheet } from '../../../../core';
import * as actions from '../../../actions';
import { getFeed } from '../../../selectors';
import { STREAM_ITEM_TYPE_SLUGS } from '../../../constants';

const OPTIONS_TYPE_SLUGS = [
    STREAM_ITEM_TYPE_SLUGS.status_update,
    STREAM_ITEM_TYPE_SLUGS.add_activities,
    STREAM_ITEM_TYPE_SLUGS.add_notification
];

export default function WithFeedItemOptionsBase(WrappedComponent) {
    class FeedItemOptionsBase extends PureComponent {
        static propTypes = {
            streamItemId: PropTypes.number.isRequired,
            stream: PropTypes.object.isRequired,
            user: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            i18n: PropTypes.object.isRequired,
        };

        _openAlert(title, text, okHandler) {
            const { i18n } = this.props;
            Alert.alert(title, text, [
                { text: i18n.t('ok'), onPress: okHandler, isPrimary: true },
                { text: i18n.t('button_cancel'), style: 'cancel' },
            ]);
        }

        getOptions = editPost => {
            const { i18n, stream: { adminStreamItemId } } = this.props;
            const buttons = [];
            if (this.isOwner()) {
                if (this._canEdit()) {
                    buttons.push({ title: i18n.t('editPost'), onPress: () => editPost() });
                }
                buttons.push({ title: i18n.t('deletePost'), onPress: () => this._deletePost() });
            }
            if (!this.isOwner() && !adminStreamItemId) {
                buttons.push({ title: i18n.t('moderate'), onPress: () => this._tryModeratePost() });
            }
            return buttons;
        };

        openOptions = (editPost, event) => {
            const options = this.getOptions(editPost);
            const destructiveButtonIndex = this.isOwner() ? options.length - 1 : 0;
            ActionSheet.open(options, destructiveButtonIndex, event, this.props.navigation);
        };

        isOwner = () => (this.props.stream.owner.userId === this.props.user.userId);

        _canEdit = () => this.isOwner() && this.props.stream.streamItemTypeSlug === STREAM_ITEM_TYPE_SLUGS.status_update;

        _deletePost = () => this.props.actions.deleteStream(this.props.stream.streamItemId);

        _tryModeratePost = (isHidingUser = false) => {
            const { i18n, stream } = this.props;
            const ownerName = _.get(stream.owner, 'firstName', '');
            const alertTitle = isHidingUser ? i18n.t('hidePost', { ownerName }) : i18n.t('moderate');
            const alertMessage = isHidingUser ? i18n.t('moderateHideUserDescription') : i18n.t('moderateDescription');
            this._openAlert(alertTitle, alertMessage, () => this._moderatePost(isHidingUser));
        };

        _moderatePost = (isHidingUser = false) => this.props.actions.moderateStream(this.props.stream.streamItemId, isHidingUser);

        get isOptionsAvailable() {
            const { stream: { streamItemTypeSlug, adminStreamItemId } } = this.props;
            return _.includes(OPTIONS_TYPE_SLUGS, streamItemTypeSlug) && (!adminStreamItemId || this.isOwner());
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    isOptionsAvailable={this.isOptionsAvailable}
                    isOwner={this.isOwner}
                    openOptions={this.openOptions}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        return {
            stream: getFeed(state, ownProps.streamItemId),
            user: coreSelectors.getCurrentUser(state)
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(FeedItemOptionsBase));
}
