import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as selectors from '../../selectors';
import { getFirstLoadingWrapper, timeout, translate } from '../../../core';
import * as actions from '../../actions';
import { spacing, baseColors, appFonts } from '../../../../styles';
import { selectors as onboardingSelectors } from '../../../onboarding';
import { selectors as challengesSelectors } from '../../../challenges';
import { selectors as rewardsSelectors } from '../../../rewards';
import { selectors as appsDevicesSelectors } from '../../../appsdevices';

export default function WithPriorityNotificationFeedBannerBase(WrappedComponent) {
    class PriorityNotificationFeedBannerBase extends PureComponent {
        static propTypes = {
            priorityNotifications: PropTypes.array,
            actions: PropTypes.object.isRequired,
            i18n: PropTypes.object.isRequired,
            isShowingOnboarding: PropTypes.bool,
            isLoadingShartedData: PropTypes.bool
        };

        static defaultProps = {
            priorityNotifications: [],
            isShowingOnboarding: false,
            isLoadingShartedData: false
        };

        constructor(props) {
            super(props);
            this.state = {
                //eslint-disable-next-line
                isLoading: props.isLoadingShartedData, //used in getFirstLoadingWrapper
            };
        }

        static getDerivedStateFromProps(nextProps, prevState) {
            return getFirstLoadingWrapper(nextProps, prevState, 'isLoadingShartedData');
        }

        get title() {
            return this.props.i18n.t('notification.priorityNotification.title');
        }

        get subtitle() {
            return this.props.i18n.t('notification.priorityNotification.subtitle');
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    title={this.title}
                    subtitle={this.subtitle}
                />
            );
        }
    }

    const mapStateToProps = state => ({
        unreadCount: selectors.getUnreadPriorityCount(state),
        isFirstPriorityLoad: selectors.isFirstPriorityLoad(state),
        isShowingOnboarding: onboardingSelectors.isShowingOnboarding(state),
        isLoadingShartedData: challengesSelectors.isLoadingChallenges(state) ||
            challengesSelectors.isLoadingRecommendedChallenges(state) ||
            challengesSelectors.isLoadingRecommendedGoals(state) ||
            rewardsSelectors.isLoadingRewards(state) ||
            rewardsSelectors.isLoadingLevels(state) ||
            appsDevicesSelectors.isLoadingDevices(state)
    });
    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(timeout(translate()(PriorityNotificationFeedBannerBase)));
}

const circleSize = spacing.s8;

export const styles = {
    notificationBanner: {
        backgroundColor: baseColors.white,
        padding: spacing.s2
    },
    unreadCountWrapper: {
        backgroundColor: baseColors.danger,
        borderRadius: spacing.s12,
        height: circleSize,
        width: circleSize,
        marginRight: spacing.s2
    },
    unreadCountText: {
        color: baseColors.white,
        ...appFonts.lgBold
    },
    textColor: {
        color: baseColors.danger
    },
    title: {
        ...appFonts.mdMedium
    },
    subTitle: {
        ...appFonts.smRegular
    }
};