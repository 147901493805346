import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { selectors as coreSelectors, translate } from '../../../core';
import * as actions from '../../actions';
import {
    getFeaturedChallengesNotHidden,
    isTrackByDevice,
} from '../../selectors';
import * as selectors from '../../selectors';
import { appFonts, baseColors, spacing } from '../../../../styles';
import { dateRange } from '../../services/helper';

export default function WithTeamChallengeActionSheetBase(WrappedComponent) {
    class TeamChallengeActionSheetBase extends PureComponent {

        componentDidMount() {
            this.props.actions.getChallenge(this.props.challengeId);
        }

        get participantsString() {
            const { challenge, i18n } = this.props;
            return challenge.numChallengeParticipants === 1 ? i18n.t('one_team') : i18n.t('number_teams', { num: challenge.numChallengeParticipants });
        }

        get duration() {
            return dateRange(this.props.challenge);
        }
        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    participantsString={this.participantsString}
                    duration={this.duration}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const challengeId = _.get(ownProps, 'location.state.challengeId') || _.get(ownProps, 'route.params.challengeId');
        const challenge = selectors.getChallenge(state, challengeId) ? selectors.getChallenge(state, challengeId) : {};
        return {
            challengeId,
            challenge,
            featuredChallenges: getFeaturedChallengesNotHidden(state),
            user: coreSelectors.getCurrentUser(state),
            isLoadingChallenges: selectors.isLoadingChallenges(state),
            isTrackByDevice: isTrackByDevice(state, ownProps.challengeId),
            isBonusChallenge: selectors.isBonusChallenge(state, ownProps.challengeId),
        };
    };

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });


    return connect(mapStateToProps, mapDispatchToProps)(translate()(TeamChallengeActionSheetBase));
}


export const styles = {
    membersContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    membersText: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: -spacing.s0
    },
    membersInfoText: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    membersAndDateText: {
        ...appFonts.smRegular,
        alignItems: 'baseline',
        color: baseColors.grey40,
        flexShrink: 0
    },
    membersTitle: {
        ...appFonts.xsBold,
        marginBottom: spacing.s1,
        paddingTop: 6,
        paddingBottom: 6,
        paddingRight: spacing.s3,
        paddingLeft: spacing.s3,
        color: baseColors.grey20,
        backgroundColor: baseColors.grey85,
    },
    header: {
        ...appFonts.smBold,
        color: baseColors.grey20,
    },
    challengeTitle: {
        ...appFonts.xlBold,
        marginBottom: spacing.s0
    },
    invitedText: {
        ...appFonts.smRegular,
        color: baseColors.grey40,
        paddingLeft: spacing.s0
    }
};
