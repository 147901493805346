import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import axios from 'axios';
import packageJson from './../package.json';
import { onKeyPress, refreshCacheAndReload } from './modules/core';
import { baseColors, spacing, layoutStyle } from './styles';
import MyContext from './context';
import { SPACING_WITH_POP_UP } from './modules/shared/constants';
import i18n from './i18n';
import { getDarkenColor } from './styles/commonColor';

global.appVersion = packageJson.version;
//

const semverGreaterThan = (versionA, versionB) => {
    const versionsA = versionA.split(/\./g);
    const versionsB = versionB.split(/\./g);
    let i =
        ((versionsA.length > versionsB.length) &&
            versionsA.length) || versionsB.length;
    while (i > 0) {
        const a = Number(versionsA.shift());
        const b = Number(versionsB.shift());
        if (a === b) {
            i -= 1;
        } else {
            return a > b;
        }
    }
    return false;
};

const Notification = props => {
    const samePrimaryColors = baseColors.primary === baseColors.secondary;
    Notification.propTypes = {
        refreshCacheAndReload: PropTypes.func.isRequired,
    };
    return (
        <div
            className={css(styles.notification, samePrimaryColors && styles.darkenedBackground)}>
            <p>
                {i18n.t('cache.new_version')}
                <span
                    className={css(layoutStyle.cp)}
                    onClick={props.refreshCacheAndReload}
                    onKeyDown={onKeyPress.enter(props.refreshCacheAndReload)}
                    tabIndex="0"
                    role="button">
                    <b> {i18n.t('refresh_bar')}</b>
                </span>
            </p>
        </div>
    );
};

const INTERVAL = 2 * 60 * 1000; // 2min

class Cache extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            isLatestVersion: false,
        };
    }

    componentDidMount() {
        this.fetchNotifications();
        this._interval = setInterval(() => this.fetchNotifications(), INTERVAL);
    }

    componentWillUnmount() {
        clearInterval(this._interval);
    }

    fetchNotifications = () => {
        return axios.get('/meta.json')
            .then(meta => {
                const latestVersion = meta.data.version;
                const currentVersion = global.appVersion;
                const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
                if (shouldForceRefresh) {
                    this.setState({ loading: false, isLatestVersion: false });
                } else {
                    this.setState({ loading: false, isLatestVersion: true });
                }
            });
    }

    render() {
        const { loading, isLatestVersion } = this.state;
        return this.props.children({ loading, isLatestVersion, refreshCacheAndReload });
    }
}

class WithCacheApp extends Component {
    static propTypes = {
        children: PropTypes.object.isRequired,
    };

    render() {
        return (
            <Cache>
                {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                    if (loading) return null;
                    if (!loading && !isLatestVersion) {
                        // You can decide how and when you want to force reload
                        // refreshCacheAndReload();
                    }

                    return (
                        <Fragment>
                            <div>
                                {!loading && !isLatestVersion &&
                                    <Notification refreshCacheAndReload={refreshCacheAndReload} />}
                            </div>
                            <div className={!isLatestVersion && css(styles.appWithPopUp)}>
                                <MyContext.Provider value={{ isLatestVersion }}>
                                    {this.props.children}
                                </MyContext.Provider>
                            </div>
                        </Fragment>
                    );
                }}
            </Cache>
        );
    }
}

const CacheApp = props => <WithCacheApp {...props} />;

const styles = StyleSheet.create({
    appWithPopUp: { paddingTop: SPACING_WITH_POP_UP },
    notification: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        width: '100%',
        height: SPACING_WITH_POP_UP,
        backgroundColor: baseColors.secondary,
        color: baseColors.white,
        padding: spacing.s3,
        position: 'fixed',
        top: 0,
        zIndex: '1999'
    },
    darkenedBackground: {
        backgroundColor: getDarkenColor(baseColors.secondary, 0.25)
    }
});

export default CacheApp;
