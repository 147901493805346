import { persistReducer } from 'redux-persist';
import * as types from './actionTypes';
import { TOURS } from './constants';
import { actionTypes as coreTypes, Storage, tracker } from '../core';

const initialState = {
    [TOURS.FRIENDS_AND_FAMILY_TOUR]: {
        isFinished: false
    },
    [TOURS.NEW_FEATURE_TOUR]: {
        isFinished: false
    },
    [TOURS.NEW_SLEEP_TRACKING_TOUR]: {
        isFinished: false
    },
    [TOURS.NEW_FEATURE_SMART_GOALS]: {
        isFinished: false
    },
    [TOURS.NEW_FEATURE_REACTIONS_TO_THE_POST]: {
        isFinished: false
    },
    [TOURS.NEW_FEATURE_MODAL_ME_PAGE_UPDATES]: {
        isFinished: false
    },
    [TOURS.NEW_FEATURE_MODAL_BELL_EAP_PROMO]: {
        isFinished: false
    },
    [TOURS.NEW_FEATURE_MODAL_QUICKER_ACTIVITY_LOG_VIEW]: {
        isFinished: false
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FINISH_TOUR.SUCCESS: {
            const { type } = action.payload;
            return {
                ...state,
                [type]: {
                    ...state[type],
                    isFinished: true
                }
            };
        }
        case coreTypes.RESET: {
            return initialState;
        }
        default:
            return state;
    }
};

const serialize = data => {
    try {
        return JSON.stringify(data);
    } catch (err) {
        const message = 'Failed to serialize tours data';
        const serializeError = JSON.stringify({
            message,
            err
        });
        tracker.logError(serializeError);
        return JSON.stringify({});
    }
};

const persistConfig = {
    key: TOURS,
    storage: Storage,
    serialize
};

export default persistReducer(persistConfig, reducer);
