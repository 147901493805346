import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { appFonts, baseColors, listItemImageSize, spacing } from '../../../../styles';
import { translate } from '../../../core';

const SHADOW = { opacity: 0.15, height: 8, width: 4 };

export default function WithHeroProductItemBase(WrappedComponent) {
    class HeroProductItemBase extends PureComponent {
        static propTypes = {
            product: PropTypes.object.isRequired,
            onPressSingle: PropTypes.func,
            separator: PropTypes.bool,
            mainContainerStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.array])
        };

        static defaultProps = {
            onPressSingle: null,
            separator: true,
            mainContainerStyle: null
        };

        onDetailsPress = () => {
            if (this.props.onPressSingle) {
                this.props.onPressSingle(this.item);
            }
        };

        get item() {
            return this.props.product;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    item={this.item}
                    onDetailsPress={this.onDetailsPress}
                />
            );
        }
    }

    return translate()(HeroProductItemBase);
}

export const styles = {
    innerContainer: {
        flexDirection: 'row'
    },
    itemContainer: {
        flex: 1,
        backgroundColor: 'transparent',
        flexDirection: 'column',
        marginLeft: spacing.s3,
        textAlign: 'center',
        justifyContent: 'center'
    },
    title: {
        color: baseColors.black,
        marginBottom: spacing.s0,
        ...appFonts.mdBold
    },
    details: {
        ...appFonts.smRegular,
        color: baseColors.primary
    },
    item: {
        paddingVertical: spacing.s3
    },
    image: {
        flex: 1,
        borderRadius: 6,
        resizeMode: 'contain'
    },
    imageContainer: {
        width: listItemImageSize,
        height: listItemImageSize,
        shadowRadius: 4,
        shadowOpacity: SHADOW.opacity,
        shadowOffset: { width: SHADOW.width, height: SHADOW.height },
        shadowColor: baseColors.black,
        elevation: 1
    },
    heroSection: {
        marginBottom: 0,
        marginTop: 0,
        alignItems: 'flex-start',
        flex: 1,
        flexDirection: 'column'
    },
    heroContainer: {
        marginHorizontal: 0,
        marginVertical: 0,
        flex: 1,
        flexDirection: 'row'
    },
    detailsReward: {
        ...appFonts.smRegular,
        color: baseColors.primary
    },
};