import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import EarnPartnersItem from '../EarnPartnersItem';

class EarnPartnersCarouselItem extends PureComponent {
    static propTypes = {
        partners: PropTypes.array.isRequired
    };

    renderItem = item => <EarnPartnersItem key={item.earnPartnerId} partner={item} isIconHidden={true} />;

    render() {
        return _.map(this.props.partners, this.renderItem);
    }
}

export default EarnPartnersCarouselItem;