import _ from 'lodash';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import defaultImage from '../../../../image/placeholder_group_o.jpg';
import { constants as coreConstants, translate, entitiesHelper, languageHelper } from '../../../core';
import * as eventsActions from '../../actions';
import { EVENT_ENTITY_TYPES } from '../../constants';
import { isJoiningEvent } from '../../selectors';
import { appFonts, baseColors, spacing, exploreImageHeight } from '../../../../styles';
import { frenchTimeStringBuilder } from '../../services';
import JoinedTag from '../../../core/components/JoinedTag/JoinedTag';

export default function WithEventListItemBase(WrappedComponent) {
    class EventListItemBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            onPressSingle: PropTypes.func.isRequired,
            i18n: PropTypes.object.isRequired,
            item: PropTypes.object.isRequired,
            type: PropTypes.string.isRequired
        };

        onPressSingle = () => this.props.onPressSingle(this.props.item.eventId);

        get invitedBy() {
            let invitedUser = _.get(this.props.item, 'eventInviters.0', null);
            if (invitedUser) {
                const { firstName, lastName, userId, imageURL } = invitedUser;
                invitedUser = {
                    image_url: imageURL,
                    firstname: firstName,
                    lastname: lastName,
                    id: userId
                };
            }
            return invitedUser;
        }

        get eventTime() {
            return _.replace(moment(this.props.item.eventDateTime).format(coreConstants.DATE_FORMATS.twelveHoursTime), ':00', '');
        }

        get eventDate() {
            return moment(this.props.item.eventDateTime).format(coreConstants.DATE_FORMATS.dayFullMonthDay);
        }

        get eventImage() {
            return this.props.item.eventImageURL || defaultImage;
        }

        get fullEventDate() {
            const { i18n, item: { eventDateTime } } = this.props;
            if (languageHelper.isFrenchLanguage(i18n)) {
                const startDate = moment(eventDateTime).format('ddd [le] D MMM');
                return `${startDate} de ${frenchTimeStringBuilder(eventDateTime)}`;
            }
            return `${this.eventDate} ${i18n.t('at')} ${this.eventTime}`;
        }

        get isShowButton() {
            return this.type === EVENT_ENTITY_TYPES.INVITED || this.type === EVENT_ENTITY_TYPES.RECOMMENDED;
        }

        joinEvent = () => {
            this.props.actions.joinEvent(this.props.item.eventId);
        };

        get isAfterDeadline() {
            return entitiesHelper.isAfterDeadline(this.props.item.eventDateTime);
        }

        get isAttendedEvent() {
            return !!this.props.item.isMember && !this.isAfterDeadline;
        }

        get isAvailableJoining() {
            const { type } = this.props;
            const isMember = _.get(this.props, 'item.isMember');
            return !isMember && !this.isAfterDeadline && type !== EVENT_ENTITY_TYPES.MY_ITEMS;
        }

        get renderJoinedTag() {
            return <JoinedTag showTag={this.isAttendedEvent} />;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    onPressSingle={this.onPressSingle}
                    invitedBy={this.invitedBy}
                    isShowButton={this.isShowButton}
                    joinEvent={this.joinEvent}
                    image={this.eventImage}
                    fullEventDate={this.fullEventDate}
                    isAttendedEvent={this.isAttendedEvent}
                    isAvailableJoining={this.isAvailableJoining}
                    joinedTag={this.renderJoinedTag}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        return {
            isJoiningEvent: isJoiningEvent(state, ownProps.item.eventId)
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(eventsActions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(EventListItemBase));
}

export const styles = {
    main: {
        margin: spacing.s3
    },
    innerEventContainer: {
        flex: 1,
        flexDirection: 'row',
        borderRadius: spacing.s1,
        marginLeft: 0,
        marginRight: 0,
    },
    eventDetailsContainer: {
        flex: 2,
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3,
        flexDirection: 'row'
    },
    eventDetailsText: {
        alignSelf: 'flex-start',
        marginBottom: spacing.s0,
        ...appFonts.smRegular
    },
    eventNameText: {
        ...appFonts.lgBold,
        marginBottom: spacing.s0
    },
    eventImageArea: {
        height: exploreImageHeight,
        width: exploreImageHeight,
        justifyContent: 'center',
        shadowRadius: spacing.s1,
        shadowOpacity: 0.15,
        shadowOffset: { width: 0, height: spacing.s1 },
        shadowColor: baseColors.black
    },
    eventImage: {
        width: '100%',
        height: '100%',
        borderRadius: spacing.s1
    },
    avatarListContainer: {
        marginLeft: spacing.s1
    },
    membersAvatar: {
        marginLeft: -spacing.s1,
        borderWidth: 1,
        borderColor: baseColors.white
    },
    button: {
        alignSelf: 'flex-start',
        marginTop: spacing.s3
    },
    avatarList: {
        color: baseColors.grey40,
        ...appFonts.xsRegular
    },
    eventTime: {
        ...appFonts.smRegular,
        color: baseColors.grey40
    },
    timeJoinWrapper: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        flex: 1
    },
    eventTimeWrapper: {
        flexDirection: 'column',
        flex: 1
    },
    carouselSlide: {
        paddingBottom: spacing.s3
    },
    bonusSection: {
        paddingTop: spacing.s1
    }
};
