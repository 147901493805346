import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import _ from 'lodash';
import { StyleSheet, css } from 'aphrodite-jss';
import { layoutStyle, spacing } from '../../../../../styles';
import { CREATE_INPUTS } from '../../../constants';
import { components as Core } from '../../../../core';
import WithModeratorOptionsBase, { styles as baseStyles } from './ModeratorOptionsBase';

class ModeratorOptions extends PureComponent {
    static propTypes = {
        onChangeText: PropTypes.func.isRequired,
        i18n: PropTypes.object.isRequired,
        onLocationsSelect: PropTypes.func.isRequired,
        onSelectChange: PropTypes.func.isRequired,
        locations: PropTypes.array,
        locationsList: PropTypes.array,
        type: PropTypes.string,
        points: PropTypes.string,
        cost: PropTypes.string,
        department: PropTypes.string,
        maxParticipants: PropTypes.string,
        pointsLabel: PropTypes.string,
        associatedLocation: PropTypes.string,
        associatedLocations: PropTypes.array.isRequired,
        associatedDepartments: PropTypes.array.isRequired,
        eventTypes: PropTypes.array.isRequired,
        isSelectedLocations: PropTypes.bool,
        removeLocation: PropTypes.func.isRequired,
        getSelectedValue: PropTypes.func.isRequired,
        isEditing: PropTypes.bool,
        readOnlyLocations: PropTypes.string,
        readOnlyDepartment: PropTypes.string,
        errors: PropTypes.object
    };

    static defaultProps = {
        errors: {},
        readOnlyLocations: null,
        readOnlyDepartment: null,
        locations: [],
        locationsList: [],
        isEditing: false,
        isSelectedLocations: false,
        maxParticipants: null,
        points: null,
        cost: null,
        department: null,
        pointsLabel: null,
        associatedLocation: null,
        type: null
    };

    renderSelectedLocationsChips = () => (
        <div className={css(styles.chipsContainer)}>
            {_.map(this.props.locations, this.renderChip)}
        </div>
    );

    renderChip = item => (
        <Core.Chip
            key={item.id}
            onDelete={this.props.removeLocation(item.id)}
            label={item.label}
        />
    );

    renderReadOnlyField = (title, item) => (
        item ? (
            <div className={css(layoutStyle.commonPaddingBottom)}>
                <div className={css(styles.title)}>{title}</div>
                <div className={css(styles.readOnly)}>{item}</div>
            </div>
        ) : null
    );

    render() {
        const {
            i18n, points, cost, associatedDepartments, department,
            associatedLocation, associatedLocations, pointsLabel
        } = this.props;
        return (
            <div>
                <Core.SelectField
                    options={this.props.eventTypes}
                    fieldName={i18n.t('createEvent.type.title')}
                    selected={this.props.getSelectedValue(this.props.eventTypes, this.props.type)}
                    onChange={this.props.onSelectChange(CREATE_INPUTS.type)}
                    checkboxType={Core.Checkbox.TYPES.simple}
                    className={css(styles.selectField)}
                />
                <div className={css(layoutStyle.flex)}>
                    <div className={css(layoutStyle.twoButtonsLeft)}>
                        <Core.Input
                            label={pointsLabel}
                            type="number"
                            autoCorrect={false}
                            onChangeText={this.props.onChangeText(CREATE_INPUTS.points)}
                            value={points}
                            fullWidth={true}
                            error={!!this.props.errors.errorPoints}
                            helperText={this.props.errors.errorPoints}
                        />
                    </div>
                    <div className={css(layoutStyle.twoButtonsRight)}>
                        <Core.Input
                            label={i18n.t('createEvent.cost')}
                            type="number"
                            autoCorrect={false}
                            onChangeText={this.props.onChangeText(CREATE_INPUTS.cost)}
                            value={cost}
                            error={!!this.props.errors.errorCost}
                            helperText={this.props.errors.errorCost}
                            fullWidth={true}
                        />
                    </div>
                </div>
                {this.props.isEditing ?
                    this.renderReadOnlyField(i18n.t('createEvent.associated.location'), this.props.readOnlyLocations) :
                    <Core.SelectField
                        options={associatedLocations}
                        fieldName={i18n.t('createEvent.associated.location')}
                        selected={this.props.getSelectedValue(associatedLocations, associatedLocation)}
                        onChange={this.props.onSelectChange(CREATE_INPUTS.associatedLocation)}
                        checkboxType={Core.Checkbox.TYPES.simple}
                        className={css(styles.selectField)}
                    />
                }
                {this.props.isSelectedLocations ?
                    <React.Fragment>
                        <Core.SelectField
                            singleSelect={false}
                            options={this.props.locationsList}
                            fieldName={i18n.t('createEvent.selectLocations')}
                            selected={this.props.locations}
                            isDoneUsed={true}
                            onChange={this.props.onLocationsSelect}
                            checkboxType={Core.Checkbox.TYPES.rounded}
                            className={css(styles.selectField)}
                            error={this.props.errors.errorLocations}
                        />
                        {this.renderSelectedLocationsChips()}
                    </React.Fragment> : null
                }
                {this.props.isEditing ?
                    this.renderReadOnlyField(i18n.t('createEvent.associated.department'), this.props.readOnlyDepartment) :
                    <Core.SelectField
                        options={associatedDepartments}
                        fieldName={i18n.t('createEvent.associated.department')}
                        selected={this.props.getSelectedValue(associatedDepartments, department)}
                        onChange={this.props.onSelectChange(CREATE_INPUTS.department)}
                        checkboxType={Core.Checkbox.TYPES.simple}
                        className={css(styles.selectField)}
                    />
                }
                <Core.Input
                    type="number"
                    label={i18n.t('createEvent.maxParticipants')}
                    autoCorrect={false}
                    onChangeText={this.props.onChangeText(CREATE_INPUTS.maxParticipants)}
                    error={!!this.props.errors.errorMaxParticipants}
                    helperText={this.props.errors.errorMaxParticipants}
                    value={this.props.maxParticipants}
                    fullWidth={true}
                />
            </div>
        );
    }
}


export default WithModeratorOptionsBase(ModeratorOptions);

const styles = StyleSheet.create({
    ...baseStyles,
    chipsContainer: {
        display: 'flex',
        overflow: 'scroll',
        paddingBottom: spacing.s2
    }
});
