import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as actions from '../../actions';
import { baseColors, spacing, appFonts } from '../../../../styles';
import { translate } from '../../../core';
import { getChallenge, getChallengeLeaderboard } from '../../selectors';

export default function WithCompetitionCardLeaderboardBase(WrappedComponent) {
    class CompetitionCardLeaderboardBase extends PureComponent {
        static propTypes = {
            challengeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // eslint-disable-line
            challenge: PropTypes.object.isRequired,
            i18n: PropTypes.object.isRequired,
            isUpdating: PropTypes.bool,
            leaderboard: PropTypes.object.isRequired
        };

        static defaultProps = {
            isUpdating: false
        };

        get leaderboardPosition() {
            const userId = _.get(this.props, 'challenge.userEntity.id');
            const leaderboardItems = _.get(this.props.leaderboard, 'items', []);
            const myLeaderboard = _.find(leaderboardItems, item =>
                item.isViewer === 1 && parseInt(item.itemEntityId) === userId);
            const leaderboardRank = _.get(myLeaderboard, 'rank');
            return leaderboardRank || _.get(this.props, 'challenge.progress.rank');
        }

        get leaderboardTitle() {
            return this.props.i18n.t('position');
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    leaderboardPosition={this.leaderboardPosition}
                    leaderboardTitle={this.leaderboardTitle}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => ({
        challenge: getChallenge(state, ownProps.challengeId),
        leaderboard: getChallengeLeaderboard(state, ownProps.challengeId),
    });

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(CompetitionCardLeaderboardBase));
}

const CROWN_ICON_HEIGHT = 14;
const CROWN_ICON_WIDTH = 22;
const STATS_LETTER_SPACING = 0.5;

export const styles = {
    position: {
        ...appFonts.lineXlRegular,
        color: baseColors.black
    },
    leaderboardTitle: {
        ...appFonts.xsRegular,
        textAlign: 'center',
        maxWidth: '100%',
        textTransform: 'uppercase',
        color: baseColors.grey40,
        paddingTop: spacing.s0,
        paddingBottom: spacing.s0,
        letterSpacing: STATS_LETTER_SPACING,
        marginBottom: spacing.s0
    },
    leaderboardContainer: {
        width: 'auto',
        minWidth: '30%',
        paddingLeft: spacing.s1,
        paddingRight: spacing.s1,
        borderRightWidth: 1,
        borderColor: baseColors.grey80,
    },
    updatingText: {
        ...appFonts.mdMedium,
        color: baseColors.black
    },
    crownIcon: {
        height: CROWN_ICON_HEIGHT,
        width: CROWN_ICON_WIDTH,
        borderRadius: 0
    }
};
