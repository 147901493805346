import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from '../../../core';
import { spacing, appFonts } from '../../../../styles';
import { getMyPersonalGoalsLimited } from '../../selectors';
import * as actions from '../../actions';

export default function WithSetGoalCardSectionBase(WrappedComponent) {
    class SetGoalCardSectionBase extends PureComponent {
        static propTypes = {
            className: PropTypes.string,
            setGoals: PropTypes.array,
            goals: PropTypes.array,
            iconSize: PropTypes.number,
            iconClassName: PropTypes.string,
            actions: PropTypes.object.isRequired,
            onRef: PropTypes.func,
            loadChallenges: PropTypes.bool,
        };

        static defaultProps = {
            className: '',
            setGoals: [],
            goals: [],
            iconSize: spacing.s11,
            iconClassName: '',
            onRef: undefined,
            loadChallenges: true,
        };

        constructor(props) {
            super(props);
            if (props.loadChallenges) {
                this.props.actions.getPersonalGoalsAndChallenges({ isSolo: 1, isEnded: 0, isUnstarted: 0, isCompleted: undefined });
            }
        }

        nodes = [];

        get hasSetGoals() {
            return !!this.props.goals.length;
        }

        get goals() {
            return this.hasSetGoals ? this.props.goals : this.props.setGoals;
        }

        keyExtractor = (goal, index) => _.get(goal, 'challengeId') || _.get(goal, 'goalName') || index;

        deleteGoal = id => this.props.actions.deleteChallenge(id);

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    goals={this.goals}
                    onRef={this.onRef}
                    deleteGoal={this.deleteGoal}
                    keyExtractor={this.keyExtractor}
                />
            );
        }
    }

    const mapStateToProps = state => ({ setGoals: getMyPersonalGoalsLimited(state) });

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(SetGoalCardSectionBase));
}

export const styles = {
    iconContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row'
    },
    goalCard: {
        paddingLeft: spacing.s3,
        paddingRight: spacing.s1,
        paddingTop: spacing.s2,
        paddingBottom: spacing.s3,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    },
    mainInfo: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: spacing.s0,
    },
    infoSection: {
        marginLeft: spacing.s2,
    },
    goalTitle: {
        ...appFonts.mdMedium
    },
    bonusMark: {
        marginTop: spacing.s1
    },
    deleteButton: {
        width: spacing.s7,
        height: spacing.s7,
        alignItems: 'center',
        justifyContent: 'center',
    }
};
