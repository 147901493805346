import React from 'react';
import PropTypes from 'prop-types';
import CountUp from 'react-countup';

const AnimateNumber = React.memo(({ value, ...props }) => {
    return (
        <CountUp
            end={value}
            duration={2}
            formattingFn={parseInt}
            {...props}
        />
    );
});

AnimateNumber.propTypes = {
    value: PropTypes.number
};

AnimateNumber.defaultProps = {
    value: 0
};

export default AnimateNumber;