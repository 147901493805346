import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { LOGIN_STEPS as STEPS } from '../../constants';
import { components as Core, ROUTES, AsyncComponent } from '../../../core';
import WithSignInFlowBase from './SignInFlowBase';

class SignInFlow extends PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
        step: PropTypes.string.isRequired,
        localStep: PropTypes.string,
        originStep: PropTypes.string,
        openNextPage: PropTypes.func.isRequired,
        updateLocalLoginStep: PropTypes.func.isRequired,
        isLogining: PropTypes.bool,
    };

    static defaultProps = {
        isLogining: false,
        localStep: undefined,
        originStep: undefined
    };

    static COMPONENTS = {
        [STEPS.programDomain]: AsyncComponent(() => import('../ProgramDomain')),
        [STEPS.findProgramDomain]: AsyncComponent(() => import('../FindDomainName')),
        [STEPS.corporatePin]: AsyncComponent(() => import('../CorporatePin')),
        [STEPS.signInSelection]: AsyncComponent(() => import('../SignInSelection')),
        [STEPS.signIn]: AsyncComponent(() => import('../SignIn')),
    };

    constructor(props) {
        super(props);
        this._checkStep();
    }

    _checkStep = () => {
        if (this.props.originStep && this.props.originStep !== this.props.localStep) this._redirect();
    };

    _redirect = () => {
        this.props.history.replace(ROUTES.signInFlow());
    };

    openNextPage = (nextStep, userSelection = '', props = {}) => {
        if (!SignInFlow.COMPONENTS[nextStep]) this._redirect();
        this.props.updateLocalLoginStep(nextStep);
        this.props.history.push(ROUTES.signInStep(nextStep), props);
    };

    switchToSignUp = ({ step }) => {
        this.props.history.replace(ROUTES.signupStep(step));
    };

    get component() {
        return SignInFlow.COMPONENTS[this.props.step];
    }

    render() {
        return (
            <Fragment>
                {this.component ?
                    <this.component
                        {...this.props}
                        callback={this.props.openNextPage}
                    /> : null}
                <Core.BlockingLoading isLoading={this.props.isLogining} />
            </Fragment>
        );
    }
}

export default WithSignInFlowBase(SignInFlow);
