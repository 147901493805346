import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as selectors from '../../../selectors';
import { appFonts, baseColors, spacing } from '../../../../../styles';
import { pointsHelper, translate, selectors as coreSelectors } from '../../../../core';

export const ICON_SIZE = 32;
export const FADE_DURATION = 400;

export default function WithGoalItemBase(WrappedComponent) {
    class GoalItemBase extends PureComponent {
        static propTypes = {
            goal: PropTypes.object.isRequired,
            i18n: PropTypes.object.isRequired,
            buttonPress: PropTypes.func.isRequired,
            customPointsUnit: PropTypes.string.isRequired,
            goalCardPress: PropTypes.func.isRequired,
            onboardingNewDesign: PropTypes.bool,
            isSettingStarterGoal: PropTypes.bool,
        };

        static defaultProps = {
            onboardingNewDesign: false,
            isSettingStarterGoal: false,
        }

        state = {
            checked: false,
            isSetInCurrentSession: false
        };

        componentWillUnmount() {
            this.setState({ isSetInCurrentSession: false });
        }

        get bonusTextNew() {
            return `+${pointsHelper.formatPoints(this.props.goal.potentialBonusPoints, this.props.customPointsUnit)} bonus`;
        }

        get isSuccessState() {
            const { isSet, canShowSuccessCards } = this.props;
            return this.state.isSetInCurrentSession && isSet && canShowSuccessCards;
        }

        get allSetText() {
            return this.props.i18n.t('isAllSet');
        }

        get successLabel() {
            return this.props.i18n.t('undoLabel');
        }

        get isGoalCardHidden() {
            const { isSet, onboardingNewDesign } = this.props;
            return !this.isSuccessState && isSet && !onboardingNewDesign;
        }

        get smartGoalDescriptionText() {
            return this.props.i18n.t('goalSmartMode.description');
        }

        buttonPress = (challenge, isSet, setChallengeId) => {
            this.props.buttonPress(challenge, this.node, isSet, setChallengeId);
            if (!isSet) this.setState({ isSetInCurrentSession: true });
        };

        goalCardPress = () => {
            const { goal } = this.props;
            const id = goal.starter;
            this.props.goalCardPress(id, { goal, isChecked: !this.state.checked });
            this.setState({ checked: !this.state.checked });
        };

        saveRef = ref => (this.node = ref);

        render() {
            if (this.isGoalCardHidden) return null;
            return (
                <WrappedComponent
                    {...this.props}
                    {...this.state}
                    bonusTextNew={this.bonusTextNew}
                    buttonPress={this.buttonPress}
                    goalCardPress={this.goalCardPress}
                    saveRef={this.saveRef}
                    allSetText={this.allSetText}
                    successLabel={this.successLabel}
                    isSuccessState={this.isSuccessState}
                    tooltipText={this.tooltipText}
                    smartGoalDescriptionText={this.smartGoalDescriptionText}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => ({
        isSetting: selectors.isSettingPersonalGoal(state, ownProps.goal.goalName),
        isRestartGoal: selectors.isRestartGoal(state, ownProps.goal.goalName),
        customPointsUnit: coreSelectors.getCustomPointsUnit(state),
        isSet: ownProps.isSettingStarterGoal ? false : selectors.isSetRecommendedGoal(state, ownProps.challengeId),
        isSmartModeGoal: selectors.isSmartModeGoal(state, ownProps.challengeId || ownProps.goal.goalName)
    });

    return connect(mapStateToProps)(translate()(GoalItemBase));
}

const CHECKBOX_SIZE = 26;
const SC_PADDING_HORIZONTAL = spacing.s5;
const SC_PADDING_VERTICAL = spacing.s3;

export const styles = {
    goalContainer: {
        flexDirection: 'row',
        paddingTop: spacing.s5,
    },
    goalContainerNew: {
        paddingTop: spacing.s3,
        marginBottom: spacing.s3
    },
    goalItemContainer: {
        flex: 1,
        backgroundColor: 'transparent',
        flexDirection: 'column'
    },
    goalTitle: {
        color: baseColors.black,
        ...appFonts.mdBold
    },
    goalTitleNew: {
        color: baseColors.grey20,
        ...appFonts.mdBold
    },
    goalDescription: {
        color: baseColors.grey20,
        ...appFonts.smRegular,
        marginTop: spacing.s1,
        marginBottom: spacing.s3,
    },
    goalDescriptionNew: {
        ...appFonts.mdRegular,
        color: baseColors.grey20,
        paddingTop: spacing.s1 - 2
    },
    smartGoalDescription: {
        color: baseColors.grey20,
        ...appFonts.smRegular,
        marginTop: spacing.s1,
    },
    setGoalButtonContainer: {
        paddingTop: spacing.s1,
        paddingBottom: spacing.s5,
    },
    containerClassName: {
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: spacing.s3,
        flex: 1
    },
    checkboxSize: {
        width: CHECKBOX_SIZE,
        height: CHECKBOX_SIZE
    },
    weeklyTagWrapper: {
        marginTop: spacing.s0,
    },
    imageArea: {
        marginRight: spacing.s2,
        height: ICON_SIZE,
        width: ICON_SIZE,
    },
    successCard: {
        paddingTop: SC_PADDING_HORIZONTAL,
        paddingBottom: SC_PADDING_HORIZONTAL,
        paddingRight: SC_PADDING_VERTICAL,
        paddingLeft: SC_PADDING_VERTICAL,
        backgroundColor: baseColors.grey90,
        borderBottomWidth: 1,
        borderBottomColor: baseColors.grey80,
    },
    successCardBoldText: {
        ...appFonts.mdBold,
        color: baseColors.grey20,
        marginRight: spacing.s0,
    },
    successCardText: {
        ...appFonts.mdRegular,
        color: baseColors.grey20,
    },
    successCardTitleWrapper: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: spacing.s3,
        marginBottom: spacing.s3,
    },
    goalIconBorder: {
        borderWidth: 2,
        borderRadius: spacing.s4,
        borderColor: baseColors.white,
    },
};
