import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';

import moment from 'moment';
import { DATE_FORMATS, selectors as coreSelectors, timeout, translate } from '../../../../core';
import { appFonts, baseColors } from '../../../../../styles';
import * as selectors from '../../../selectors';

const SOON_RESOLVE_VALUE = 10;

export default function WithDashboardProgressIndicatorBase(WrappedComponent) {
    class DashboardProgressIndicatorBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            levels: PropTypes.object.isRequired,
            isFirstLoaded: PropTypes.bool,
            isLoading: PropTypes.bool,
        };

        static defaultProps = {
            isFirstLoaded: false,
            isLoading: false
        };

        get backgroundColor() {
            return baseColors.grey80;
        }

        get trackActivityText() {
            return this.props.i18n.t('trackActivity.text');
        }

        get daysLeft() {
            return Number(_.get(this.props.levels, 'remainingDays'));
        }

        get soonReset() {
            return this.daysLeft <= SOON_RESOLVE_VALUE;
        }

        get monthDayText() {
            const date = moment().add(this.daysLeft, 'days').format(DATE_FORMATS.monthDay);
            return this.props.i18n.t('resetsOn', { date });
        }

        get daysLeftText() {
            return `${this.daysLeft} ${this.props.i18n.t('deadlineDaysLeft')}`;
        }

        get isSkeletonShown() {
            return !this.props.isFirstLoaded && this.props.isLoading;
        }

        get dashboardProgressLabel() {
            return this.props.i18n.t('activityProgress', { levelName: this.props.levelNameTitle, percentFilled: this.props.percentFilled, daysLeft: this.daysLeft });
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    backgroundColor={this.backgroundColor}
                    trackActivityText={this.trackActivityText}
                    daysLeftText={this.daysLeftText}
                    monthDayText={this.monthDayText}
                    isSkeletonShown={this.isSkeletonShown}
                    soonReset={this.soonReset}
                    dashboardProgressLabel={this.dashboardProgressLabel}
                />
            );
        }
    }

    function mapStateToProps(state) {
        return {
            isLiveBetter: coreSelectors.isLiveBetter(state),
            levels: selectors.getLevels(state),
        };
    }

    return connect(mapStateToProps)(translate()(timeout(DashboardProgressIndicatorBase)));
}

export const styles = {
    daysLeftText: {
        ...appFonts.smMedium,
        color: baseColors.grey20
    },
    monthDayText: {
        ...appFonts.smRegular,
        color: baseColors.grey40,
    }
};
