import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';
import _ from 'lodash';

import CarouselItem from '../CarouselItem';
import CommunityItem from '../CommunityItem';
import WithCommunitiesBase, { styles as baseStyles } from './CommunitiesBase';
import { spacing, baseColors, media, important, importantStyles, layoutStyle } from '../../../../styles';
import { components as Core, ROUTES, constants as coreConstants, tracker, Modal, AsyncComponent, CSS_CLASSES } from '../../../core';


class Communities extends PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        activeTab: PropTypes.string.isRequired,
        numInvites: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        numRecent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        isLoading: PropTypes.bool,
        invitedCommunitiesCarousel: PropTypes.array,
        recentCommunitiesCarousel: PropTypes.array,
        search: PropTypes.string,
        onSearch: PropTypes.func.isRequired,
        isAllTab: PropTypes.bool,
        isMyTab: PropTypes.bool,
        handleChange: PropTypes.func.isRequired,
        tabs: PropTypes.array.isRequired,
        isDisplayingCarousels: PropTypes.bool,
        communityItemsToRender: PropTypes.array,
        loadMoreContent: PropTypes.func.isRequired,
        communityCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        history: PropTypes.object.isRequired,
        invitedCommunitiesIds: PropTypes.array,
        join: PropTypes.func.isRequired,
        myCommunitiesEmpty: PropTypes.string.isRequired,
        searchCommunitiesEmpty: PropTypes.string.isRequired,
        isMyCommunitiesEmptyMessageShown: PropTypes.bool.isRequired,
        isCommunitiesEmptyMessageShown: PropTypes.bool.isRequired,
        searchCommunitiesPlaceholder: PropTypes.string.isRequired,
        communitiesTitle: PropTypes.string.isRequired,
        invitedCommunities: PropTypes.string.isRequired,
        recent: PropTypes.string.isRequired,
        allCommunitiesHeading: PropTypes.string.isRequired,
        myCommunitiesHeading: PropTypes.string.isRequired,
        searchLoaderText: PropTypes.string.isRequired,
        onClearSearch: PropTypes.func.isRequired,
        focusCallback: PropTypes.func.isRequired,
        isFocused: PropTypes.bool.isRequired
    };

    static defaultProps = {
        numInvites: undefined,
        numRecent: undefined,
        isLoading: false,
        invitedCommunitiesCarousel: [],
        recentCommunitiesCarousel: [],
        search: '',
        isAllTab: false,
        isMyTab: false,
        isDisplayingCarousels: false,
        communityItemsToRender: [],
        communityCount: undefined,
        invitedCommunitiesIds: [],
    };

    constructor(props) {
        super(props);
        this.carouselRef = React.createRef();
        this.state = {};
    }
    componentDidUpdate(prevProps) {
        const { numInvites, invitedCommunitiesIds, history } = prevProps;
        if (this.props.numInvites < numInvites) {
            const diff = _.difference(invitedCommunitiesIds, this.props.invitedCommunitiesIds);
            if (diff.length) history.push(ROUTES.communityDetails(diff[0]));
        }
    }

    get searchComponent() {
        const { search, searchCommunitiesPlaceholder, onClearSearch, focusCallback, onSearch, isFocused } = this.props;
        return (
            <Core.SearchBar
                value={search}
                onChangeText={onSearch}
                onClear={onClearSearch}
                label={searchCommunitiesPlaceholder}
                onFocus={focusCallback}
                isSearching={isFocused}
            />
        );
    }

    get emptyCommunitiesComponent() {
        const { myCommunitiesEmpty, searchCommunitiesEmpty, isMyCommunitiesEmptyMessageShown,
            isCommunitiesEmptyMessageShown } = this.props;
        if (isMyCommunitiesEmptyMessageShown) {
            return <Core.EmptyListSimple message={myCommunitiesEmpty} />;
        }
        if (isCommunitiesEmptyMessageShown) {
            const icon = <Core.Icon name="search" type="fa" size={spacing.s4} color={baseColors.grey50} />;
            return <Core.EmptyListSimple message={searchCommunitiesEmpty} icon={icon} />;
        }
        return null;
    }

    onPressSingle = item => {
        if (this.state.isSliding) return;
        this.props.history.push(ROUTES.communityDetails(item.id));
    };

    renderCarouselItem = (item, isInvitationCarousel) => (
        <CarouselItem
            onPressSingle={this.onPressSingle}
            id={item.id}
            key={item.id}
            join={this.props.join}
            isInvitationCarousel={isInvitationCarousel}
            carouselRef={this.carouselRef}
        />
    );

    renderInvitationCarouselItem = item => this.renderCarouselItem(item, true);
    renderRecentCarouselItem = item => this.renderCarouselItem(item, false);

    renderListHeading = text => <Core.SubHeader titleSize={Core.SubHeader.SIZES.large} title={text} />;

    get searchLoadingComponent() {
        const { isLoading, search, searchLoaderText } = this.props;
        return isLoading && search ? (
            <div className={css(styles.indicator, layoutStyle.flex)}>
                <Core.ListLoading key="Loading" />
                <p className={css(styles.indicatorText)}>{searchLoaderText}</p>
            </div>
        ) : null;
    }

    get afterTabsComponent() {
        const { isAllTab, numInvites, isDisplayingCarousels, invitedCommunitiesCarousel, numRecent,
            recentCommunitiesCarousel, communityItemsToRender, loadMoreContent, communityCount, isLoading,
            invitedCommunities, recent, allCommunitiesHeading, isMyTab, myCommunitiesHeading, search } = this.props;
        return (
            <div>
                {this.searchLoadingComponent}
                {isAllTab && (
                    <Fragment>
                        <div ref={this.carouselRef}>
                            {numInvites > 0 && isDisplayingCarousels && (
                                <Core.Carousel
                                    dots={true}
                                    parent={this}
                                    count={numInvites}
                                    title={invitedCommunities}
                                    className={CSS_CLASSES.communities}
                                    titleSize={Core.SubHeader.SIZES.large}
                                    type={coreConstants.CAROUSEL_TYPES.threeOnly}
                                    itemsLength={invitedCommunitiesCarousel.length}>
                                    {_.map(invitedCommunitiesCarousel, this.renderInvitationCarouselItem)}
                                </Core.Carousel>
                            )}
                            {numRecent > 0 && isDisplayingCarousels && (
                                <Core.Carousel
                                    dots={true}
                                    parent={this}
                                    title={recent}
                                    count={numRecent}
                                    className={CSS_CLASSES.communities}
                                    titleSize={Core.SubHeader.SIZES.large}
                                    type={coreConstants.CAROUSEL_TYPES.threeOnly}
                                    itemsLength={recentCommunitiesCarousel.length}>
                                    {_.map(recentCommunitiesCarousel, this.renderRecentCarouselItem)}
                                </Core.Carousel>
                            )}
                        </div>
                        <Core.InfiniteLazyListFadeIn
                            isHorizontal={false}
                            twoColumns={false}
                            data={communityItemsToRender}
                            keyExtractor={item => item.id}
                            renderItem={this.renderItem}
                            onEndReached={loadMoreContent}
                            onEndReachedThreshold={0.5}
                            hasMore={communityItemsToRender.length < communityCount}
                            isLoading={isLoading}
                            isSearching={isLoading && search}
                            ListEmptyComponent={this.emptyCommunitiesComponent}
                            ListHeaderComponent={this.renderListHeading(allCommunitiesHeading)}
                        />
                    </Fragment>
                )}
                {
                    isMyTab && (
                        <Core.InfiniteLazyListFadeIn
                            isHorizontal={false}
                            twoColumns={false}
                            data={communityItemsToRender}
                            keyExtractor={item => item.id}
                            renderItem={this.renderItem}
                            onEndReached={loadMoreContent}
                            onEndReachedThreshold={0.5}
                            hasMore={communityItemsToRender.length < communityCount}
                            isLoading={isLoading}
                            isSearching={isLoading && search}
                            ListEmptyComponent={this.emptyCommunitiesComponent}
                            ListHeaderComponent={this.renderListHeading(myCommunitiesHeading)}
                        />
                    )}
            </div>
        );
    }

    goToCreateCommunity = () => {
        tracker.logEvent('add_group', { plus: false });
        Modal.open(AsyncComponent(() => import('../CreateCommunity')), { isModal: true }, {
            cancelable: false,
            isNoPadding: true,
            isContainer: true,
            isFullHeight: true
        });
    };

    renderItem = community => <CommunityItem id={community.item.id} />;

    render() {
        const { communitiesTitle, handleChange, tabs, activeTab, i18n } = this.props;
        return (
            <Core.Layout.WiderContainer>
                <Core.EntityDetailsHeader
                    hasBackButton={true}
                    rightComponent={(
                        <Core.CreateEntityButton
                            onPress={this.goToCreateCommunity}
                            text={i18n.t('createCommunityFlow.createNew')}
                        />
                    )}
                    title={communitiesTitle}
                />
                <Core.SubHeader
                    title={communitiesTitle}
                    right={(
                        <div className={css(styles.mainSearchBar)}>
                            {this.searchComponent}
                        </div>
                    )}
                    noTopPadding={true}
                    additionalSearchBar={this.searchComponent}
                />
                <Core.TabsWithContent
                    onChange={handleChange}
                    tabs={tabs}
                    activeTab={activeTab}
                    tabClassName={css(styles.tabs)}
                    isStickyUnderNavBar={true}>
                    {this.afterTabsComponent}
                </Core.TabsWithContent>
            </Core.Layout.WiderContainer>
        );
    }
}

const styles = StyleSheet.create(importantStyles({
    ...baseStyles,
    heading: {
        margin: spacing.s3
    },
    header: {
        ...baseStyles.header,
        display: 'flex',
        justifyContent: 'space-between',
        margin: spacing.s3,
        marginBottom: 0,
        alignItems: 'center'
    },
    tabs: {
        marginBottom: spacing.s5
    },
    icon: {
        marginTop: spacing.s2,
        marginRight: spacing.s2
    },
    rightHeaderPart: {
        display: 'flex',
        flexDirection: 'row'
    },
    mainSearchBar: {
        display: 'block',
        flex: 1,
        [media.xsOnly]: {
            display: 'none'
        }
    },
    removeLeftPadding: {
        paddingLeft: important(0)
    }
}));

export default WithCommunitiesBase(Communities);
