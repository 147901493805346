import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { translate } from '../../../core';
import * as selectors from '../../selectors';
import * as actions from '../../actions';

const MAX_COUNT = 10;

export default function WithPreviousGoalsBase(WrappedComponent) {
    class PreviousGoalsBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            goals: PropTypes.array,
            count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            isLoadingMore: PropTypes.bool,
            i18n: PropTypes.object.isRequired,
        };

        static defaultProps = {
            isLoadingMore: false,
            count: 0,
            goals: []
        };

        constructor(props) {
            super(props);
            this.loadGoals();
            this.initSet();
        }

        componentDidUpdate(prevProps) {
            if (prevProps.isLoadingMore && !this.props.isLoadingMore) {
                if ((this.props.goals.length >= this.props.count) || !this.props.count) {
                    this.canLoadMore = false;
                }
            }
            else this.initSet();
        }

        onRefresh = () => {
            this.isRefreshing = true;
            this.loadGoals();
        };

        loadGoals = (params = {}) => {
            this.props.actions.getPreviousPersonalGoals(params, false);
        };

        loadMore = () => {
            const { isLoadingMore, goals, count } = this.props;
            if (!isLoadingMore && this.canLoadMore && count && this._start <= this.props.goals.length) {
                this.loadGoals({ start: goals.length }, false);
                // todo: remove it if count will be fixed on server side
                this._start += MAX_COUNT;
            }
        };

        initSet = () => {
            this.canLoadMore = true;
            this.isRefreshing = false;
            this._start = MAX_COUNT;
        };

        keyExtractor = item => item.challengeId.toString();

        get title() {
            return this.props.i18n.t('previousGoalsTitle');
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    isRefreshing={this.isRefreshing}
                    onRefresh={this.onRefresh}
                    loadMore={this.loadMore}
                    canLoadMore={this.canLoadMore}
                    keyExtractor={this.keyExtractor}
                    title={this.title}
                />
            );
        }
    }

    const mapStateToProps = state => ({
        goals: selectors.getCompletedPersonalGoals(state),
        count: selectors.getCompletedChallengesCount(state),
        isLoadingMore: selectors.isLoadingChallenges(state)
    });

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(PreviousGoalsBase));
}
