import React from 'react';
import { translate } from '../../../../core';
import { appFonts, spacing } from '../../../../../styles';

export default function WithFeedPostEditorPreviewBase(WrappedComponent) {
    const FeedPostEditorPreviewBase = props => (
        <WrappedComponent {...props} />
    );

    return translate()(FeedPostEditorPreviewBase);
}

export const styles = {
    attachedContent: {
        flexDirection: 'row',
        marginLeft: spacing.s3,
        marginRight: spacing.s3,
        marginBottom: spacing.s3,

        display: 'flex'
    },
    attachedImage: {
        height: 100,
        width: 100,
        marginRight: spacing.s3,
        borderRadius: 0
    },
    insertedLink: {
        alignSelf: 'flex-end'
    },
    insertedLinkText: {
        ...appFonts.mdMedium
    },
    previewTitle: {
        ...appFonts.mdBold
    },
    previewDescription: {
        ...appFonts.smRegular,
    },
};
