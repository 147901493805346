import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';
import _ from 'lodash';
import { layoutStyle } from '../../../../styles';
import { components as Core } from '../../../core';
import ActivityHistoryDetailsItem from '../ActivityHistoryDetailsItem';
import WithCompetitionActivityHistoryDetailsBase, { styles as baseStyles } from './CompetitionActivityHistoryDetailsBase';

class CompetitionActivityHistoryDetails extends PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        points: PropTypes.string.isRequired,
        logs: PropTypes.array.isRequired,
        isLoading: PropTypes.bool
    };

    static defaultProps = {
        isLoading: false
    };

    renderActivity = activity => <ActivityHistoryDetailsItem activity={activity} />;

    render() {
        const { isLoading, i18n, points, logs } = this.props;
        return isLoading ? <Core.BlockingLoading isLoading={isLoading} /> :(
            <Core.SmallerContainer
                widerHeaderComponent={(
                    <Core.EntityDetailsHeader
                        hasBackButton={true}
                    />)
                }>
                <div className={css(layoutStyle.flexColumnCenter, layoutStyle.commonPaddingHorizontal, styles.headingContainer)}>
                    <p className={css(styles.smallHeadingText)}>{i18n.t('you_earned')}</p>
                    <p className={css(styles.largeHeadingText)}>{points}</p>
                </div>
                <div>
                    {!logs.length ? (
                        <p className={css(styles.empty)}>
                            {i18n.t('havent_tracked_challenge')}
                        </p>
                    ) : (
                        <Core.List>
                            {_.map(logs, this.renderActivity)}
                        </Core.List>
                    )}
                </div>
            </Core.SmallerContainer>
        );
    }
}

export default WithCompetitionActivityHistoryDetailsBase(CompetitionActivityHistoryDetails);

const styles = StyleSheet.create({
    ...baseStyles
});