import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { translate, MAX_COUNT } from '../../../core';
import { spacing } from '../../../../styles';
import * as selectors from '../../selectors';
import * as actions from '../../actions';
import { DISPLAY_TYPES } from '../../constants';

export default function WithChallengesListBase(WrappedComponent) {
    class ChallengesListBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object,
            challenges: PropTypes.array,
            count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            type: PropTypes.string,
            isLoading: PropTypes.bool
        };

        static defaultProps = {
            actions: null,
            challenges: null,
            count: 0,
            type: '',
            isLoading: false
        };

        constructor(props) {
            super(props);
            this.loadChallenges();
        }

        get filter() {
            return this.props.type;
        }

        loadChallenges = () => {
            this.props.actions.getChallengesList({ filter: this.filter, maxCount: MAX_COUNT, start: 0 }, true);
        };

        loadMoreContent = () => {
            if (!this.props.isLoading && this.props.challenges.length < this.props.count) {
                this.props.actions.getChallengesList({ filter: this.filter, maxCount: MAX_COUNT, start: this.props.challenges.length }, false);
            }
        };

        keyExtractor = item => item.challengeId;

        get title() {
            const { type, i18n } = this.props;
            switch (type) {
                case DISPLAY_TYPES.BONUS:
                    return i18n.t('bonusChallenges');
                case DISPLAY_TYPES.INVITATIONS:
                    return i18n.t('invitations');
                case DISPLAY_TYPES.COMPANY:
                    return i18n.t('companyChallenges');
                case DISPLAY_TYPES.RECOMMENDED:
                    return i18n.t('recommended');
                default:
                    return null;
            }
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    title={this.title}
                    loadMoreContent={this.loadMoreContent}
                    loadChallenges={this.loadChallenges}
                    keyExtractor={this.keyExtractor}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const type = ownProps.type || _.get(ownProps, 'match.params.type') || _.get(ownProps, 'route.params.type');
        const challenges = selectors.getChallengesByType[type].items(state);
        const count = selectors.getChallengesByType[type].count(state);
        return {
            type,
            challenges,
            count,
            isLoading: selectors.isLoadingChallengesList(state)
        };
    };

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(ChallengesListBase));
}

export const styles = {
    header: {
        marginBottom: spacing.s3
    }
};