import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as FALight from '@fortawesome/pro-light-svg-icons';
import * as FARegular from '@fortawesome/pro-regular-svg-icons';
import * as FASolid from '@fortawesome/pro-solid-svg-icons';
import * as FABarnds from '@fortawesome/free-brands-svg-icons';
import * as MUIIcons from '@material-ui/icons';
import SvgIcon from '@material-ui/core/SvgIcon';
import MUIIconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import CircularProgress from '@material-ui/core/CircularProgress';
import { StyleSheet, css } from 'aphrodite-jss';
import classnames from 'classnames';
import { appFonts, baseColors, spacing, importantStyles } from '../../../../styles';
import icomoonConfig from '../../assets/icomoon';

const TYPES = {
    material: 'material',
    fa: 'fa',
    config: 'config',
};

const FA_ICONS = { light: FALight, regular: FARegular, solid: FASolid, brands: FABarnds };

const BADGE_CONTENT_LIMIT = 99;
const DEFAULT_SIZE = 24;
const DEFAULT_COLOR = 'default';
const INHERIT = 'inherit';
const DEFAULT_FILL = 'light';

/**
 * Main icon component,
 */
const Icon = ({ type, ...props }) => {
    switch (type) {
        case TYPES.material:
            return <MaterialIcon {...props} />;
        case TYPES.config:
            return <ConfigIcon {...props} />;
        case TYPES.fa:
        default:
            return <FAIcon {...props} />;
    }
};
Icon.propTypes = { type: PropTypes.string };
Icon.defaultProps = { type: TYPES.material };

export default Icon;

/**
 * MaterialIcon - icons from @material-ui
 */
const MaterialIcon = props => {
    const { color, size, name, className } = props;
    const IconComponent = MUIIcons[name];
    if (!IconComponent) return notFound(TYPES.material, props);
    return (
        <span style={{ color: baseColors[color] || color, fontSize: size }} className={classnames(css(styles.icon), className)}>
            <IconComponent fontSize="inherit" color="inherit" style={{ fontSize: size }} />
        </span>
    );
};
MaterialIcon.propTypes = {
    color: PropTypes.string,
    size: PropTypes.number,
    name: PropTypes.string.isRequired,
    className: PropTypes.string
};

MaterialIcon.defaultProps = {
    color: DEFAULT_COLOR,
    size: DEFAULT_SIZE,
    className: undefined
};

/**
 * FontAwesomeIcon - icons from react-fontawesome
 */
const FAIcon = props => {
    const {
        name,
        size,
        className,
        color,
        fill,
        disabled,
        ...other
    } = _.omit(props, ['containerStyle']);
    const iconName = _.camelCase(`fa-${name}`);
    const IconComponent = FA_ICONS[fill][iconName];
    if (!IconComponent) return notFound(`${TYPES.fa}/${fill}`, props);
    return (
        <FontAwesomeIcon
            {...other}
            color={disabled ? 'inherit' : (baseColors[color] || color)}
            icon={IconComponent}
            style={{ width: size, height: size }}
            className={classnames(css(styles.icon), className)}
        />
    );
};
FAIcon.propTypes = {
    name: PropTypes.string.isRequired,
    size: PropTypes.number,
    className: PropTypes.string,
    color: PropTypes.string,
    fill: PropTypes.string,
    disabled: PropTypes.bool,
    containerStyle: PropTypes.string
};

FAIcon.defaultProps = {
    size: DEFAULT_SIZE,
    className: undefined,
    color: INHERIT,
    fill: DEFAULT_FILL,
    disabled: false,
    containerStyle: null
};


/**
 * ConfigIcon component, only displays icons from icomoonIcons config
 */
const ConfigIcon = props => {
    const { color, size, name } = props;
    const IconComponent = icomoonIcons[name];
    if (!IconComponent) return notFound(TYPES.config, props);
    return (
        <SvgIcon style={{ fontSize: size, color: baseColors[color] || color }} viewBox={IconComponent.viewBox}>
            <g>
                {_.map(IconComponent.paths, path => <path key={path} d={path} />)}
            </g>
        </SvgIcon>
    );
};
ConfigIcon.propTypes = {
    color: PropTypes.string,
    size: PropTypes.number,
    name: PropTypes.string.isRequired
};

ConfigIcon.defaultProps = {
    color: DEFAULT_COLOR,
    size: DEFAULT_SIZE
};

/**
 * icons from icomoonConfig, to proper show on web - correct viewBox should be set
 * Icons for welcome page, will be added more if needed
 */
const icomoonIcons = {
    trophy: {
        paths: _.get(_.find(icomoonConfig.icons, icon => icon.properties.name === 'trophy'), 'icon.paths'),
        viewBox: '0 0 895 1012'
    },
    Move: {
        paths: _.get(_.find(icomoonConfig.icons, icon => icon.properties.name === 'Move'), 'icon.paths'),
        viewBox: '0 0 620 1010'
    },
    explore: {
        paths: _.get(_.find(icomoonConfig.icons, icon => icon.properties.name === 'explore'), 'icon.paths'),
        viewBox: '0 0 1024 1024'
    }
};

/**
 * IconButton component, based on material IconButton, includes Icon component
 */
export const IconButton = ({ iconSize, name, size, type, color, iconClassName, className, isSmall, circularColor, isLoading, loadingSize, fill, hasRoundHover, iconCustomClass, ...props }) => (
    <MUIIconButton {...props} color={color} className={classnames({ [css(styles.iconButtonSmall)]: isSmall }, className)}>
        {isLoading ? <CircularProgress color={circularColor || color} size={loadingSize} /> : (
            <Icon
                type={type}
                name={name}
                color={color}
                size={size}
                className={classnames(css(styles.iconButtonIcon, hasRoundHover ? styles.iconSameSizeContainer : undefined, iconCustomClass ? iconCustomClass : undefined), iconClassName)}
                fill={fill}
            />
        )}
    </MUIIconButton>
);
IconButton.propTypes = {
    color: PropTypes.string,
    size: PropTypes.number,
    type: PropTypes.string,
    name: PropTypes.string.isRequired,
    iconClassName: PropTypes.string,
    className: PropTypes.string,
    isSmall: PropTypes.bool,
    fill: PropTypes.string,
    isLoading: PropTypes.bool,
    circularColor: PropTypes.string,
    loadingSize: PropTypes.number,
    hasRoundHover: PropTypes.bool,
    iconCustomClass: PropTypes.object,
    iconSize: PropTypes.number
};

IconButton.defaultProps = {
    color: undefined,
    size: undefined,
    type: undefined,
    fill: undefined,
    iconClassName: undefined,
    className: undefined,
    isSmall: false,
    isLoading: false,
    circularColor: undefined,
    loadingSize: spacing.s5,
    hasRoundHover: false,
    iconCustomClass: undefined,
    iconSize: null
};

/**
 * IconBadge component, based on material Badge and IconButton
 */
export const IconBadge = ({ iconSize, badgeContent, name, size, type, color, iconClassName, ...props }) => {
    const classes = { badge: classnames(css(styles.badge)) };
    const isHidden = badgeContent <= 0;
    return (
        <Badge
            max={BADGE_CONTENT_LIMIT}
            invisible={isHidden}
            badgeContent={badgeContent}
            classes={classes}>
            <Icon {...props} type={type} name={name} color={color} size={size} className={iconClassName} />
        </Badge>
    );
};
IconBadge.propTypes = {
    badgeContent: PropTypes.number.isRequired,
    color: PropTypes.string,
    size: PropTypes.number,
    type: PropTypes.string,
    name: PropTypes.string.isRequired,
    iconClassName: PropTypes.string,
    iconSize: PropTypes.number
};
IconBadge.defaultProps = {
    color: INHERIT,
    size: DEFAULT_SIZE,
    iconClassName: null,
    type: undefined,
    iconSize: null
};

function notFound(type, { name, color, size, className }) {
    const msg = `Can not find icon ${name} in type ${type}, please check if you use correct type and name`;
    if (process.env.NODE_ENV !== 'production') throw new Error(msg);
    console.error('Icon.js', msg);
    return (
        <span
            style={{ color: baseColors[color] || color, width: size, height: size, borderRadius: size / 2 }}
            className={classnames(css(styles.icon), className)}
        />
    );
}

const styles = StyleSheet.create({
    icon: {
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    ...importantStyles({
        badge: {
            background: baseColors.dangerDarker,
            color: baseColors.white,
            height: spacing.s1 * 2,
            borderRadius: 10,
            right: 0,
            ...appFonts.xxsBold
        },
        noBadge: {
            display: 'none'
        },
        iconButton: {
            padding: spacing.s1
        },
        iconButtonSmall: {
            padding: spacing.s0
        },
        iconButtonIcon: {
            width: '1em'
        },
        iconSameSizeContainer: {
            height: spacing.s7,
            width: spacing.s7
        }
    })
});
