import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as actions from '../../actions';
import { appFonts, spacing, baseColors } from '../../../../styles';
import {
    translate, selectors as coreSelectors, actions as coreActions, constants as coreConstants,
    validation, Platform, PLATFORMS, ROUTES, components as Core,
} from '../../../core';
import { getCompany, getPrivacyPolicy, getTermsOfService, isRetail, isCreatingAccount, isSso, getCreateAccount,
    getEnabledRegistrationFields, hasLocation, hasDisclaimerStep, getSsoDetails, getCreateAccountError,
    hasPartnerSsoSignUpModal, getPartnerSsoSignUpModal, isEnabledHRIS, isNoEmailHRIS, getEmployeeID, isHRISLocationNoEmailHRIS } from '../../selectors';
import {
    EXTERNAL_SERVICES,
    POLICY_FIELD_NAME,
    POSSIBLE_TYPES,
    MYMEDIBANK_AGE_ERROR,
    EMPLOYEE_ID,
    EMAIL_NOT_FOUND
} from '../../constants';
import { getSupportEmail } from '../../services/helper';

export const INPUT_MAX_LENGTH = 40;
const ERROR_CODES = {
    bpidExists: 'bpid_exists'
};

export default function WithRegisterBase(WrappedComponent) {
    class RegisterBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            sso: PropTypes.object,
            company: PropTypes.object.isRequired,
            isRetail: PropTypes.bool,
            isSSO: PropTypes.bool,
            callback: PropTypes.func.isRequired,
            registrationFields: PropTypes.array.isRequired,
            privacyPolicy: PropTypes.object,
            termsOfService: PropTypes.object,
            isLoading: PropTypes.bool,
            createAccount: PropTypes.object,
            i18n: PropTypes.object.isRequired,
            error: PropTypes.object,
            avoidDataSubmissionCallback: PropTypes.func,
            step: PropTypes.string.isRequired,
            allowableFields: PropTypes.array,
            ignoreSSO: PropTypes.bool,
            partnerSsoSignUpModal: PropTypes.object,
            ssoDetails: PropTypes.object,
            isHRISEnabled: PropTypes.bool,
            isNoEmailHRIS: PropTypes.bool,
            employeeId: PropTypes.string,
            isHRISLocationNoEmailHRIS: PropTypes.bool
        };

        static defaultProps = {
            sso: {},
            isSSO: false,
            isRetail: false,
            privacyPolicy: {},
            termsOfService: {},
            isLoading: false,
            allowableFields: undefined,
            createAccount: undefined,
            error: undefined,
            avoidDataSubmissionCallback: undefined,
            ignoreSSO: false,
            partnerSsoSignUpModal: undefined,
            ssoDetails: undefined,
            isHRISEnabled: false,
            isNoEmailHRIS: false,
            employeeId: '',
            isHRISLocationNoEmailHRIS: false
        };

        constructor(props) {
            super(props);

            this.state = {
                ...this.initialValues(),
                formSubmitted: false,
                isEmailError: undefined
            };

            this.getPolicies();

            // todo: check if needed
            // if (props.createAccount) {
            //     this.props.callback(REGISTRATION_STEPS.register);
            // }
        }

        UNSAFE_componentWillReceiveProps(nextProps) {
            if (!_.isEqual(nextProps.registrationFields, this.props.registrationFields) || (this.props.isSSO && !_.isEqual(nextProps.sso, this.props.sso))) {
                this.setState({ ...this.initialValues(nextProps.registrationFields, nextProps.sso) });
            }
        }

        componentDidUpdate(prevProps) {
            const fields = this.summarizeFields();
            if (prevProps.isLoading && !this.props.isLoading) {
                if (this.props.error) {
                    this.showNotFieldServerError();
                } else if (this.props.avoidDataSubmissionCallback) {
                    this.props.avoidDataSubmissionCallback({ ...fields, ...this.defaultFieldsToSend }, this.props.step);
                } else {
                    this.props.callback(this.props.step);
                }
            }

            if (prevProps.company !== this.props.company) {
                this.getPolicies();
            }
        }

        initialValues(fields = this.props.registrationFields, ssoValues = this.props.sso) {
            const values = {};
            _.forEach(fields, field => {
                const type = this.getFieldType(field);
                if (this.props.isSSO) {
                    if (ssoValues[field.name]) {
                        values[field.name] = ssoValues[field.name];
                    } else {
                        values[field.name] = _.has(field, 'defaultValue') ? field.defaultValue : ((type === POSSIBLE_TYPES.bool && false) || '');
                    }
                } else {
                    values[field.name] = _.has(field, 'defaultValue') ? field.defaultValue : ((type === POSSIBLE_TYPES.bool && false) || '');
                }
            });
            return values;
        }

        getFieldType(field) {
            return field.type || POSSIBLE_TYPES.text;
        }

        getPolicies() {
            const params = {};
            if (this.props.isRetail) {
                params.partnerId = this.props.company.partnerId;
                params.isRetail = true;
            } else {
                params.companyId = this.props.company.companyId;
            }

            if (params.companyId || params.partnerId) {
                this.props.actions.getTermsOfService(params);
                this.props.actions.getPrivacyPolicy(params);
            }
        }

        get fieldNames() {
            return _.map(this.props.registrationFields, 'name');
        }

        get fields() {
            return _.pick(this.state, this.fieldNames);
        }

        onFormSubmit = async () => {
            await this.setState({ formSubmitted: true });
            const isError = _.some(this.fieldNames, fieldName => this.getFieldError(fieldName));
            if (!isError) this.submit();
        };

        summarizeFields = () => {
            const fields = {};
            _.forEach(this.props.registrationFields, field => {
                const type = this.getFieldType(field);
                const value = this.fieldValue(field.name);
                if (type === POSSIBLE_TYPES.bool) {
                    fields[field.name] = value ? 1 : 0;
                } else {
                    fields[field.name] = value;
                }
            });
            return fields;
        }

        submit = () => {
            const fields = this.summarizeFields();
            // SAML SSO don't submit, the user entry already exists, all fields are valid as they are server provided
            if (this.props.isSSO && this.props.sso.ssotype === EXTERNAL_SERVICES.saml) {
                this.updateSSOUserNames(fields);
                this.props.callback(this.props.step);
            } else {
                const validateOnly = !!this.props.avoidDataSubmissionCallback;
                this.props.actions.createAccount({ ...fields, ...this.defaultFieldsToSend, validateOnly });
            }
        };

        // SFE-4704 Some SSO providers don't send the first and last name. So we will update the already created user with names when they submit the register screen.
        updateSSOUserNames = fields => {
            const { sso: { firstName, lastName } } = this.props;
            if (!firstName.length || !lastName.length) {
                const nameFields = { firstName: _.get(fields, 'firstName', ''), lastName: _.get(fields, 'lastName', '') };
                this.props.actions.updateUser(nameFields);
            }
        }

        get defaultFieldsToSend() {
            const fields = { send_email: 0 };
            if (this.props.isRetail) {
                fields.partner_id = this.props.company.partnerId;
                fields.retail = true;
            }
            else {
                fields.company_id = this.props.company.companyId;
            }
            if (this.props.isSSO) {
                fields.ssotype = this.props.sso.ssotype;
                fields.token = this.props.sso.token;
            }
            if (this.props.isHRISEnabled && (this.props.isNoEmailHRIS || this.props.isHRISLocationNoEmailHRIS)) {
                if (this.props.employeeId) {
                    fields.employeeId = this.props.employeeId;
                }
            }
            return fields;
        }

        get privacyPolicy() {
            return this.props.privacyPolicy.content;
        }

        get termsOfService() {
            return this.props.termsOfService;
        }

        onChangeText = prop => value => {
            this.setState({ [prop]: value }, this._clearError);
        };

        onChangeBool = prop => () => {
            this.setState(state => ({ [prop]: !state[prop] }), this._clearError);
        };

        _clearError = () => {
            if (this.props.error) {
                this.props.actions.clearCreateAccountError();
            }
        };

        showNotFieldServerError = () => {
            const { errorCode, displayMessage } = this.props.error;
            if (!_.includes(this.fieldNames, errorCode) && errorCode !== MYMEDIBANK_AGE_ERROR && errorCode !== EMPLOYEE_ID) {
                this.props.actions.addToast(coreConstants.TOAST_TYPES.DANGER, null,
                    displayMessage || this.getDefaultServerErrorMessage(errorCode), this.props.i18n.t('registration.signup.errorHeading'));
            }
        };

        getFieldError = fieldName => {
            if (!this.state.formSubmitted) return;
            const value = this.fieldValue(fieldName);
            if (this.props.error) {
                if (this.props.error.errorCode === EMPLOYEE_ID && Platform.OS !== PLATFORMS.web) {
                    this.showErrorAlert();
                    return;
                }
                if (this.props.error.errorDetails === EMAIL_NOT_FOUND && Platform.OS !== PLATFORMS.web) {
                    this.showErrorAlert(true);
                    return;
                }
                return this.getServerError(fieldName);
            }
            const specificError = this[`${fieldName}ErrorHandler`] && this[`${fieldName}ErrorHandler`](value);
            if (specificError) return specificError;
            if (value === '') {
                return this.props.i18n.t('required');
            }
            return '';
        };

        showErrorAlert = isEmailError => {
            this.setState(() => ({ isEmailError }));
            const { navigation } = this.props;
            if (Platform.OS !== PLATFORMS.web) {
                navigation.navigate(ROUTES.infoModal(), {
                    ...this.getUserNotInSystemModal(),
                    renderText: this.renderModalText,
                });
            }
        }

        renderModalText = () => {
            const { htmlText } = this.getUserNotInSystemModal();
            return <Core.HtmlView value={htmlText} style={styles.modalText} hasInlineLinks={true} />;
        };
        getUserNotInSystemModal = () => {
            const { i18n, isTelus } = this.props;

            let string1 = i18n.t('hrisEmployeeID.infoModal.notInSystem');
            let string2 = i18n.t('hrisEmployeeID.infoModal.administrator');
            let fieldText = this.summarizeFields().employeeId;

            if (this.state.isEmailError) {
                string1 = i18n.t('hrisEmployeeID.infoModal.eligibleEmail');
                string2 = i18n.t('hrisEmployeeID.infoModal.emailNotInSystem');
                fieldText = this.summarizeFields().email;
            }
            const string3 = `${i18n.t('notRegistered.infoModal.emailHelp')} <a href="mailto:${getSupportEmail(isTelus)}" style="color: ${baseColors.secondary};"><strong>${getSupportEmail(isTelus)}</strong></a>`;
            const htmlText = `${`<strong>${fieldText} </strong>${string1}\n\n${string2}\n\n${string3}`}`;

            return {
                htmlText,
                title: i18n.t('notRegistered.infoModal.title'),
                iconName: 'question',
                buttons: [{ text: i18n.t('close') }],
                isButtonVisible: false
            };
        };

        getServerError = fieldName => {
            const { errorCode, displayMessage } = this.props.error;
            return (fieldName === errorCode) ? displayMessage : '';
        };

        getDefaultServerErrorMessage = errorCode => {
            switch (errorCode) {
                case ERROR_CODES.bpidExists:
                    return this.props.i18n.t('registration.bpidExists');
                default:
                    return this.props.i18n.t('registration.generalError');
            }
        };

        // separate error handler for email
        emailErrorHandler(value) {
            if (value !== '' && !validation.isEmail(value)) {
                return this.props.i18n.t('resetPasswordInvalidValue');
            }
        }

        // separate error handler for policy agreement
        policyAgreeErrorHandler(value) {
            if (value !== true) {
                return this.props.i18n.t('policyAgreeError');
            }
        }

        isFromSso = fieldName => this.props.isSSO && !!this.props.sso[fieldName];

        fieldValue = fieldName => this.state[fieldName];

        isFieldDisabled = field => !field.forcedEditable && (field.readonly || this.isFromSso(field.name));

        renderField = (field, renderInput, renderSwitch, index) => {
            if (field.name === POLICY_FIELD_NAME) return null; // custom render
            const type = this.getFieldType(field);
            switch (type) {
                case POSSIBLE_TYPES.bool:
                    return renderSwitch(field);
                case POSSIBLE_TYPES.password:
                    return !this.props.isSSO ? renderInput(field, 'password', index) : null;
                case POSSIBLE_TYPES.text:
                default:
                    return renderInput(field, undefined, index);
            }
        };

        getInfoModalProps = okHandler => {
            const { partnerSsoSignUpModal, i18n, ssoDetails, sso } = this.props;
            const { firstName } = ssoDetails ? ssoDetails : sso;

            const welcomeString = partnerSsoSignUpModal.welcome_string[i18n.acceptLanguage()];

            return {
                iconName: 'user',
                title: firstName ? `${welcomeString} ${firstName}` : welcomeString,
                text: partnerSsoSignUpModal.fist_string[i18n.acceptLanguage()],
                textSecondLine: partnerSsoSignUpModal.second_string[i18n.acceptLanguage()],
                isButtonVisible: false,
                buttons: [{ text: i18n.t('got_it'), onPress: okHandler ? () => okHandler() : null }],
            };
        };

        saveRef = ref => (this.wrapped = ref);

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    ref={this.saveRef}
                    renderField={this.renderField}
                    fieldValue={this.fieldValue}
                    isFromSso={this.isFromSso}
                    getFieldError={this.getFieldError}
                    onChangeBool={this.onChangeBool}
                    onChangeText={this.onChangeText}
                    termsOfService={this.termsOfService}
                    privacyPolicy={this.privacyPolicy}
                    onFormSubmit={this.onFormSubmit}
                    formSubmitted={this.state.formSubmitted}
                    fieldNames={this.fieldNames}
                    isFieldDisabled={this.isFieldDisabled}
                    getInfoModalProps={this.getInfoModalProps}
                    {...this.fields}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        return {
            sso: getSsoDetails(state),
            isSSO: !ownProps.ignoreSSO && isSso(state),
            company: getCompany(state),
            isRetail: isRetail(state),
            isLiveBetter: coreSelectors.isLiveBetter(state),
            privacyPolicy: getPrivacyPolicy(state),
            termsOfService: getTermsOfService(state),
            createAccount: getCreateAccount(state),
            registrationFields: ownProps.allowableFields ?
                _.filter(getEnabledRegistrationFields(state), o => _.includes(ownProps.allowableFields, o.name))
                : getEnabledRegistrationFields(state),
            hasLocation: hasLocation(state),
            hasDisclaimer: hasDisclaimerStep(state),
            isLoading: isCreatingAccount(state),
            error: getCreateAccountError(state),
            hasPartnerSsoSignUpModal: hasPartnerSsoSignUpModal(state),
            partnerSsoSignUpModal: getPartnerSsoSignUpModal(state),
            isHRISEnabled: isEnabledHRIS(state),
            isNoEmailHRIS: isNoEmailHRIS(state),
            employeeId: getEmployeeID(state),
            isTelus: coreSelectors.isTelus(state),
            isHRISLocationNoEmailHRIS: isHRISLocationNoEmailHRIS(state)
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators({ ...coreActions, ...actions }, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(RegisterBase));
}

export const styles = {
    linkPolicyText: {
        ...appFonts.smBold,
        color: baseColors.secondary,
    },
    policyText: {
        ...appFonts.smRegular,
        color: baseColors.black,
    },
    promoText: {
        ...appFonts.smRegular,
        color: baseColors.black,
        flex: 1,
        flexWrap: 'wrap'
    },
    policyTextWrapper: {
        flex: 1,
        flexWrap: 'wrap'
    },
    webViewContainer: {
        flex: 1,
        backgroundColor: baseColors.white
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        backgroundColor: baseColors.white
    },
    textWithSwitchContainer: {
        alignItems: 'center',
        backgroundColor: 'transparent',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignSelf: 'stretch',
        flexWrap: 'nowrap',
        paddingBottom: spacing.s2
    },
    textWithSwitchContainerPadding: {
        paddingBottom: spacing.s3
    },
    inputContainer: {
        borderBottomWidth: 0
    },
    footer: {
        borderColor: baseColors.grey70,
        borderTopWidth: 1
    },
    policyError: {
        ...appFonts.xsRegular,
        marginBottom: spacing.s2,
        color: baseColors.dangerDarker,
        textAlign: 'left'
    },
    disclaimer: {
        color: baseColors.grey40,
        top: spacing.s3,
        marginBottom: spacing.s3
    },
    modalText: {
        ...appFonts.mdRegular,
    }
};
