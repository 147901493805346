/* eslint-disable react/no-danger */
import React, { PureComponent, Fragment } from 'react';
import { css, StyleSheet } from 'aphrodite-jss';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';
import _ from 'lodash';

import BonusMark from '../BonusMark';
import { components as Core, ROUTES, openUrl } from '../../../core';
import { baseColors, fontSize, spacing, importantClass, detailsImageSize, layoutStyle, appFonts } from '../../../../styles';
import { VIDEO_DURATION_CONTAINER_HORIZONTAL_PADDING } from '../../constants';
import WithContentItemDetailsBase, { styles as baseStyles, HTMLStyle } from './ContentItemDetailsBase';
import PlayButton from '../../../../image/PlayButton.png';
import UserFeedbackWidget from '../UserFeedbackWidget';

class ContentItemDetails extends PureComponent {
    static propTypes = {
        categoryColor: PropTypes.string,
        markAsReadWatched: PropTypes.func,
        markAsOpened: PropTypes.func,
        item: PropTypes.object.isRequired,
        image: PropTypes.string.isRequired,
        isVideo: PropTypes.bool.isRequired,
        actions: PropTypes.object.isRequired,
        isLoading: PropTypes.bool.isRequired,
        history: PropTypes.object.isRequired,
        isBookmarked: PropTypes.bool.isRequired,
        buttonText: PropTypes.string.isRequired,
        onToggleBookmark: PropTypes.func.isRequired,
        timeToReadLabel: PropTypes.string,
        itemIsCMSContent: PropTypes.bool.isRequired,
        isRead: PropTypes.bool,
        itemLabel: PropTypes.string.isRequired,
        hasSeveralLabels: PropTypes.bool.isRequired,
        videoDuration: PropTypes.string
    };

    static defaultProps = {
        categoryColor: baseColors.grey40,
        markAsReadWatched: undefined,
        markAsOpened: undefined,
        timeToReadLabel: undefined,
        isRead: false,
        videoDuration: null
    };

    constructor(props) {
        super(props);
        this.state = {
            hasMarkedAsRead: false
        };
    }

    componentDidUpdate(prevProps) {
        if (!this.props.isLoading && prevProps.isLoading && !this.props.item) {
            this.props.actions.showContentIsNotAvailable();
            this.props.history.goBack();
            this.props.history.push(ROUTES.content());
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.listenToScroll);
        this.props.markAsOpened();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll);
    }

    onClick = () => openUrl(this.props.item.redirect_url, false);

    get rightNavIconProps() {
        return {
            name: 'bookmark',
            onClick: this.props.onToggleBookmark,
            fill: this.props.isBookmarked ? 'solid' : 'light',
            size: spacing.s4,
            iconCustomClass: styles.iconForHeader
        };
    }

    get buttonText() {
        return (
            <div className={css(styles.viewButtonText)}>{this.props.buttonText}</div>
        );
    }

    get buttonIcon() {
        return <Core.Icon name="external-link" type="fa" color={baseColors.white} size={spacing.s3} />;
    }

    get createMarkup() {
        const { item } = this.props;
        return { __html: `${HTMLStyle}<div id="cmsStyles">${item.preview_metadata}</div>` };
    }

    get renderLabel() {
        const { item, hasSeveralLabels, itemLabel, categoryColor } = this.props;

        const dotIcon = isLastItem => (
            !isLastItem && (
                <Core.Icon
                    name="circle"
                    fill="solid"
                    type="fa"
                    className={css(styles.dotIcon)}
                    color={baseColors.grey70}
                    size={spacing.s0}
                />
            )
        );

        if (hasSeveralLabels) {
            return (
                <div className={css(styles.labelContainer)}>
                    {_.map(item.tagsArr, (label, index) => {
                        const isLastItem = index === item.tagsArr.length - 1;
                        return (
                            <div className={css(styles.categoryText)} style={{ color: categoryColor }}>
                                <Fragment>
                                    {label}
                                    {dotIcon(isLastItem)}
                                </Fragment>
                            </div>
                        );
                    })}
                </div>
            );
        }

        return (
            <div className={css(styles.labelContainer)}>
                <div className={css(styles.categoryText)} style={{ color: categoryColor }}>
                    {itemLabel}
                </div>
            </div>
        );
    }

    listenToScroll = () => {
        const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        const offset = window.pageYOffset;

        if (offset >= height/2) {
            const { itemIsCMSContent, isRead, markAsReadWatched } = this.props;
            if (itemIsCMSContent && !isRead && !this.state.hasMarkedAsRead) {
                this.setState({ hasMarkedAsRead: true });
                markAsReadWatched();
            }
        }
    }

    cmsOnClick = event => {
        if (event.target.href) {
            event.preventDefault();
            openUrl(event.target.href);
        }
    }

    get renderVideoControlContent() {
        const { isVideo, videoDuration } = this.props;
        return isVideo ? (
            <div className={css(styles.videoControlContainer)}>
                <Core.Image src={PlayButton} className={css(styles.playButton)} />
                {videoDuration ? (
                    <div className={css(styles.videoDurationContainer)}>
                        <p className={css(styles.videoDuration)}>{videoDuration}</p>
                    </div>
                ) : null}
            </div>
        ) : null;
    }

    renderCMSContent() {
        const { item, image, timeToReadLabel, isLoading } = this.props;
        const { hasMarkedAsRead } = this.state;
        return (
            <div className={css(styles.container)}>
                <Core.BlockingLoading isLoading={isLoading} />
                {item && !isLoading ? (
                    <React.Fragment>
                        <div className={css(styles.body)}>
                            {this.renderLabel}
                            <div className={css(styles.cmsTitle)}>{item.title}</div>
                            <BonusMark
                                item={item}
                                showBonusText={true}
                                labelClassName={styles.bonusLabel}
                                showCheck={hasMarkedAsRead}
                                containerClassName={css(styles.bonusMarkWrapper)}
                            />
                            {image ?
                                <Core.Image src={image} alt={item.name} className={css(styles.cmsImage)} resizeMode="cover" />
                                : null}
                            {timeToReadLabel ? (
                                <div className={css(styles.timeToReadWrapper)}>
                                    <Core.Icon name="clock" type="light" size={spacing.s3} color={baseColors.grey40} />
                                    <p className={css(styles.timeToRead)}>{timeToReadLabel}</p>
                                </div>
                            ) : null}
                            <div className={css(styles.cmsContent)}>
                                {/* eslint-disable-next-line react/no-danger */}
                                <div onClick={this.cmsOnClick} dangerouslySetInnerHTML={this.createMarkup} />
                            </div>
                        </div>
                    </React.Fragment>
                ) : null}
            </div>
        );
    }

    renderLegacyContent() {
        const { item, image, bonusLabel, isVideo, markAsReadWatched, isLoading } = this.props;
        const hls = _.get(this.props, 'item.preview_metadata.hls');
        return (
            <div className={css(styles.container)}>
                <Core.BlockingLoading isLoading={isLoading} />
                {item && !isLoading ? (
                    <React.Fragment>
                        <div className={css(styles.headerContainer)}>
                            {isVideo ? (
                                <ReactPlayer
                                    url={hls || item.url}
                                    playing={true}
                                    controls={true}
                                    light={item.preview_metadata?.src}
                                    className={css(styles.playerRadius)}
                                    onStart={markAsReadWatched}
                                    playIcon={this.renderVideoControlContent}
                                />
                            ) : (
                                <Core.Image
                                    onClick={this.onClick}
                                    className={css(styles.image, layoutStyle.cp)}
                                    src={image}
                                    alt={item.name}
                                />
                            )}
                        </div>
                        <div className={css(styles.body)}>
                            {this.renderLabel}
                            <div className={css(styles.title)}>{item.title}</div>
                            <div className={css(styles.description)}>{item.description}</div>
                            <BonusMark
                                item={item}
                                showBonusText={true}
                                bonusLabel={bonusLabel}
                                labelClassName={styles.bonusLabel}
                                containerClassName={css(styles.bonusMarkWrapper)} />
                            {!isVideo && (
                                <Core.Button
                                    className={css(styles.functionalButton, styles.functionalButtonText, styles.buttonContent)}
                                    href={item.redirect_url}
                                    target="_blank">
                                    {this.buttonText}
                                    {this.buttonIcon}
                                </Core.Button>
                            )}
                        </div>
                    </React.Fragment>
                ) : null}
            </div>
        );
    }

    render() {
        const { itemIsCMSContent, isVideo, item } = this.props;

        if (!item) {
            return null;
        }

        return (
            <Core.SmallerContainer
                isFixedFooter={true}
                widerHeaderComponent={(
                    <Core.EntityDetailsHeader
                        hasBackButton={true}
                        rightNavIconProps={this.rightNavIconProps}
                    />
                )
                }>
                {itemIsCMSContent ? this.renderCMSContent() : this.renderLegacyContent()}
                <UserFeedbackWidget isVideo={isVideo} contentItem={item} />
            </Core.SmallerContainer>
        );
    }
}

export default WithContentItemDetailsBase(ContentItemDetails);

const cmsImageHeight = 360;

const styles = StyleSheet.create({
    ...baseStyles,
    container: {
        display: 'flex',
        flexDirection: 'column'
    },
    body: {
        ...baseStyles.body,
        paddingLeft: spacing.s5,
        paddingRight: spacing.s5,
    },
    headerContainer: {
        ...baseStyles.headerContainer,
        display: 'flex',
        position: 'relative',
    },
    functionalButton: importantClass({
        ...baseStyles.functionalButton,
        width: '100%',
        marginTop: spacing.s7
    }),
    buttonContent: {
        flexDirection: 'row'
    },
    description: {
        ...baseStyles.description,
        lineHeight: fontSize.xLarge.lineHeight
    },
    image: {
        ...baseStyles.image,
        height: detailsImageSize
    },
    iconForHeader: {
        height: spacing.s4,
        width: spacing.s4,
        margin: spacing.s1
    },
    playerRadius: {
        overflow: 'hidden',
        borderRadius: spacing.s1,
    },
    cmsImage: {
        ...baseStyles.cmsImage,
        borderRadius: spacing.s1,
        height: cmsImageHeight
    },
    cmsContent: {
        paddingTop: spacing.s6,
        marginLeft: spacing.s5,
        marginRight: spacing.s5,
        cursor: 'default'
    },
    cmsTitle: {
        ...appFonts.xxxlBold,
    },
    timeToReadWrapper: {
        ...baseStyles.timeToReadWrapper,
        paddingLeft: spacing.s5
    },
    labelContainer: {
        ...baseStyles.labelContainer,
        display: 'flex'
    },
    videoControlContainer: {
        ...baseStyles.videoControlContainer,
        position: 'relative',
        paddingLeft: spacing.s2,
        paddingRight: spacing.s2
    },
    playButton: importantClass({
        ...baseStyles.playButton
    }),
    videoDurationContainer: {
        ...baseStyles.videoDurationContainer,
        padding: `${spacing.s0}px ${VIDEO_DURATION_CONTAINER_HORIZONTAL_PADDING}px`
    }
});
