import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { components as Core, onKeyPress } from '../../../core';
import WithCommentBase, { styles as baseStyles, NUMBER_OF_LINES } from './CommentBase';
import { layoutStyle, importantClass } from '../../../../styles';

class Comment extends PureComponent {
    static propTypes = {
        commentId: PropTypes.number.isRequired, // eslint-disable-line
        openOptions: PropTypes.func.isRequired,
        showDate: PropTypes.bool,
        comment: PropTypes.object.isRequired,
        preparedHtmlText: PropTypes.string,
        formattedDate: PropTypes.string
    };

    static defaultProps = {
        showDate: false,
        preparedHtmlText: undefined,
        formattedDate: undefined,
    };

    render() {
        const { comment, preparedHtmlText, formattedDate, openOptions, showDate } = this.props;
        return (
            <div className={css(styles.comment, layoutStyle.flexRow)}>
                <Core.Avatar
                    id={comment.owner.userId}
                    url={comment.avatarURL}
                    name={`${comment.owner.firstName} ${comment.owner.lastName}`}
                    className={css(styles.userAvatar)}
                />
                <div className={css(styles.commentInner, layoutStyle.flexColumn)}>
                    <div
                        tabIndex="0"
                        role="button"
                        onClick={openOptions}
                        onKeyDown={onKeyPress.enter(openOptions)}
                        className={css(styles.commentBubble)}>
                        <span className={css(styles.owner)}>
                            {comment.owner.firstName} {comment.owner.lastName}
                        </span>
                        <Core.TextSeeMore
                            numberOfLines={NUMBER_OF_LINES}
                            greyGradient={true}
                            buttonClassName={css(styles.seeMoreStyle)}
                            isCollapseHidden={true}
                            isExpandHidden={false}>
                            <Core.AutoLink text={preparedHtmlText} />
                        </Core.TextSeeMore>
                    </div>
                    {showDate ? <p className={css(styles.date)}>{formattedDate}</p> : null}
                </div>
            </div>
        );
    }
}

export default WithCommentBase(Comment);


const styles = StyleSheet.create({
    ...baseStyles,
    seeMoreStyle: importantClass({
        ...baseStyles.seeMoreStyle
    })
});
