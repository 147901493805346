import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { spacing } from '../../../../../styles';
import WithEarnPartnersListBaseWrapper from './EarnPartnersListBaseWrapper';
import { getPartnersForCategory } from '../../../selectors';

export default function WithEarnPartnersListBase(WrappedComponent) {
    class EarnPartnersListBase extends Component {
        static propTypes = {
            activeTab: PropTypes.object.isRequired,
            search: PropTypes.string
        };

        static defaultProps = {
            search: undefined,
        };

        get tabs() {
            const { isLoading, scrollableTabs } = this.props;
            return isLoading ? [] : scrollableTabs;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    scrollableTabs={this.tabs}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        return {
            partnersForCategory: getPartnersForCategory(state, ownProps.activeTab, ownProps.search)
        };
    }

    return WithEarnPartnersListBaseWrapper(connect(mapStateToProps)(EarnPartnersListBase));
}

export const styles = {
    mainContainer: {
        flex: 1
    },
    header: {
        flex: 0,
        marginTop: spacing.s3
    },
    itemContainer: {
        marginLeft: spacing.s3,
        marginRight: spacing.s3
    },
    tabsContainer: {
        marginBottom: spacing.s3
    }
};
