export const NAME = 'communities';
export const MAX_COUNT = 12;
export const NOT_PERSISTED = true;

export const TYPES = {
    INVITATIONS: 'invites',
    ATTENDED: 'attending',
    RECOMMENDED: 'recommended',
    RECENT: 'recent',
    ALL: 'all'
};

export const FIELDS = {
    NAME: 'name',
    DESCRIPTION: 'description',
    PICTURE: 'picture',
    TYPE: 'type'
};

export const PUBLIC_COMMUNITY = 'public';
export const PRIVATE_COMMUNITY = 'private';

export const COMMUNITY_TYPES = Object.freeze({
    public: PUBLIC_COMMUNITY,
    private: PRIVATE_COMMUNITY
});

export const CREATE_COMMUNITY_DEFAULT_PROPS = {
    image: () => ({}),
};


export const CREATE_COMMUNITY_ITEM_IDS = {
    publicCommunity: COMMUNITY_TYPES.public,
    privateCommunity: COMMUNITY_TYPES.private,
    communityType: 'communityType',
    publicCommunityName: 'publicCommunityName',
    privateCommunityName: 'privateCommunityName',
    joinExisting: 'joinExisting',
    communityDescription: 'communityDescription',
    image: 'image',
    finalize: 'finalize',
    created: 'created'
};

export const CREATE_COMMUNITY_STEPS = {
    communityType: 'communityType',
    communityName: 'communityName',
    joinExisting: 'joinExisting',
    communityDescription: 'communityDescription',
    image: 'image',
};
