import { createSelector } from 'reselect';
import _ from 'lodash';
import { NAME, DASHBOARD_RESOURCES_COUNT } from './constants';
import * as types from './actionTypes';
import { selectors as coreSelectors } from '../core';

const getAllResourceLinks = state => state[NAME].items;
const getAllResourceLinkIds = state => state[NAME].ids;
export const getResourceLinksCount = state => state[NAME].numResources;

export const getResourceLinks = createSelector(getAllResourceLinks, getAllResourceLinkIds, (items, ids) => _.map(ids, id => items[id]));

export const getDashboardResourceLinks = createSelector(getResourceLinks, items => _.take(items, DASHBOARD_RESOURCES_COUNT));

export const isLoadingResourceLinks = state => coreSelectors.isLoading(state, types.GET_RESOURCE_LINKS.NAME);