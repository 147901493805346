import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import * as actions from '../../actions';
import { translate, tracker } from '../../../core';
import { getNotificationSettings, getPrivacySettings, isLoadingNotificationsAndEmailsSettings } from '../../selectors';
import { appFonts, baseColors, spacing } from '../../../../styles';
import { NOTIFICATIONS_SETTINGS_TABS } from '../../constants';

const NOTIFICATION_SETTINGS_FLAG = 'notificationSetting';
const PRIVACY_SETTINGS_FLAG = 'privacySetting';

export default function WithNotificationsSettingsBase(WrappedComponent) {
    class NotificationsSettingsBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            settings: PropTypes.array.isRequired,
            privacySettings: PropTypes.array.isRequired,
            isLoading: PropTypes.bool,
            selectedTab: PropTypes.number,
            navigation: PropTypes.object,
            i18n: PropTypes.object.isRequired
        };

        static defaultProps = {
            isLoading: false,
            navigation: {},
            selectedTab: 0
        };

        constructor(props) {
            super(props);
            this.tabs = [
                { label: props.i18n.t('notifications'), id: NOTIFICATIONS_SETTINGS_TABS.notifications },
                { label: props.i18n.t('emails'), id: NOTIFICATIONS_SETTINGS_TABS.emails }
            ];
            this.state = {
                selectedTab: this.tabs[this.props.selectedTab],
                changedValuesMap: {
                    privacySetting: {},
                    notificationSetting: {}
                }
            };
        }

        componentDidMount() {
            this.props.actions.getNotificationsSettings();
            this.props.actions.getUserPrivacySettings('email');
        }

        getValue = item => {
            const { flag, localId } = item;
            if (_.has(this.state.changedValuesMap[flag], localId)) {
                return this.state.changedValuesMap[flag][localId];
            }
            return this._getOriginValueById(item);
        };

        changeSetting = item => {
            const { flag, value, localId, notificationSettingTypeId, privacySettingTypeId } = item;
            this.setState(prevState => ({
                changedValuesMap: {
                    ...prevState.changedValuesMap,
                    [flag]: {
                        ...prevState.changedValuesMap[flag],
                        [localId]: !value
                    }
                }
            }));
            if (flag === NOTIFICATION_SETTINGS_FLAG) {
                this.props.actions.setNotificationsSetting(notificationSettingTypeId, !value);
            }
            else {
                // Need to switch true/false for API because PRIVACY is set to TRUE if the user
                // does NOT want to receive notifications
                this.props.actions.setUserPrivacySetting('email', privacySettingTypeId, value);
            }
        };

        showSelectedTab = () => {
            const { selectedTab } = this.state;
            if (selectedTab.id === NOTIFICATIONS_SETTINGS_TABS.notifications) {
                return this.allSettings('notificationSettingTypeId');
            } else if (selectedTab.id === NOTIFICATIONS_SETTINGS_TABS.emails) {
                return this.allSettings('privacySettingTypeId');
            }
            return null;
        };

        allSettings = typeId => {
            const { i18n } = this.props;
            const title = i18n.t('notify_header');
            let flag = PRIVACY_SETTINGS_FLAG;
            let array = this.props.privacySettings ? this.props.privacySettings : [];
            if (typeId === 'notificationSettingTypeId') {
                flag = NOTIFICATION_SETTINGS_FLAG;
                array = this.props.settings;
            }

            const settingsArray = array.length ? [{ title, id: title }].concat(array) : [];

            return _.map(settingsArray, obj => _.assign({}, obj, {
                localId: obj[typeId],
                flag
            }));
        };

        _getOriginValueById(item) {
            if (item.flag === NOTIFICATION_SETTINGS_FLAG) {
                return _.find(this.allSettings('notificationSettingTypeId'), s => s.notificationSettingTypeId === item.notificationSettingTypeId).value;
            }
            return _.find(this.allSettings('privacySettingTypeId'), s => s.privacySettingTypeId === item.privacySettingTypeId).value;
        }

        tabSelected = tab => {
            tracker.setCurrentScreen(`NotificationsSettings.${tab.id}`, this.props.navigation);
            this.setState({ selectedTab: tab });
        };

        get title() {
            return this.props.i18n.t('notificationsSettingsTitle');
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    tabs={this.tabs}
                    selectedTab={this.state.selectedTab}
                    showSelectedTab={this.showSelectedTab}
                    tabSelected={this.tabSelected}
                    getValue={this.getValue}
                    isLoading={this.props.isLoading}
                    changeSetting={this.changeSetting}
                    title={this.title}
                    allSettings={this.allSettings}
                />
            );
        }
    }

    const mapStateToProps = state => ({
        settings: getNotificationSettings(state),
        privacySettings: getPrivacySettings(state),
        isLoading: isLoadingNotificationsAndEmailsSettings(state)
    });

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(NotificationsSettingsBase));
}

export const styles = {
    container: {
        backgroundColor: baseColors.white
    },
    itemContainer: {
        flex: 1,
        paddingLeft: spacing.s2
    },
    titleItem: {
        paddingLeft: spacing.s2,
        justifyContent: 'flex-start'
    },
    item: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottomWidth: 1,
        borderBottomColor: baseColors.grey80,
        paddingTop: spacing.s0,
        paddingBottom: spacing.s0,
        paddingRight: spacing.s3
    },
    text: {
        ...appFonts.mdMedium,
        flex: 1,
        paddingRight: spacing.s2
    }
};
