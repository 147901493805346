import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { baseColors, fontSize, spacing, important, layoutStyle } from '../../../../../styles';
import FeedItemOptions from '../FeedItemOptions';
import { components as Core, onKeyPress } from '../../../../core';
import WithFeedItemHeaderBaseBase, { styles as baseStyles } from './FeedItemHeaderBase';

class FeedItemHeader extends PureComponent {
    static propTypes = {
        streamItemId: PropTypes.number.isRequired, // eslint-disable-line
        stream: PropTypes.object.isRequired,
        i18n: PropTypes.object.isRequired,
        isVisibleOnlyMe: PropTypes.bool.isRequired,
        getText: PropTypes.func.isRequired,
        points: PropTypes.number,
        date: PropTypes.string.isRequired,
        goToUserDetails: PropTypes.func,
    };

    static defaultProps = {
        points: undefined,
        goToUserDetails: undefined,
    };

    get text() {
        const sharedStyle = css(styles.entityNameTitle, layoutStyle.cp, styles.accessibilityStyle);
        const entityNameList = ['user.name', 'group_name', 'challenges.name', 'events.name', 'groups.name', 'event_name', 'challenge_name', 'user1', 'user2'];
        const templateKeyStyles = Object.fromEntries(entityNameList.map(name => [name, sharedStyle]));

        return this.props.getText(templateKeyStyles, true);
    }

    get visibleOnlyMe() {
        const { i18n, isVisibleOnlyMe } = this.props;
        return isVisibleOnlyMe ? (
            <div className={css(styles.cardSubtitle)}>
                <Core.Icon
                    type="fa"
                    name="lock"
                    size={fontSize.xxSmall}
                    color={baseColors.grey50}
                    className={css(styles.iconLock)}
                />
                <span>{` ${i18n.t('visibleOnlyToMe')}`}</span>
            </div>
        ) : null;
    }

    render() {
        const { stream, i18n, date, points, streamItemId } = this.props;
        const text = this.text;
        return (
            <Core.CardHeader
                avatar={
                    <Core.Avatar
                        url={stream.avatarURL}
                        name={`${stream.owner.firstName} ${stream.owner.lastName}`}
                        id={stream.owner.userId}
                    />
                }
                title={
                    <div className={css(styles.cardHeader)}>
                        {this.visibleOnlyMe}
                        <div>
                            {text ?
                                <p>
                                    <span className={css(styles.cardSpecialTitle)}>{text}</span>
                                    {points ?
                                        <span className={css(styles.pointsValue)}>
                                            {` (${points} ${i18n.t('activityPoints', { customPoints: 'pts' })})`}
                                        </span>: null}
                                </p> :
                                <span
                                    className={css(styles.cardTitle, styles.entityNameTitle, styles.accessibilityStyle)}
                                    tabIndex="0"
                                    role="button"
                                    onClick={this.props.goToUserDetails}
                                    onKeyDown={onKeyPress.enter(this.props.goToUserDetails)}>
                                    {`${stream.owner.firstName} ${stream.owner.lastName}`}
                                </span>}
                        </div>
                    </div>
                }
                subheader={<p className={css(styles.cardSubtitle)}>{date}</p>}
                action={<FeedItemOptions streamItemId={streamItemId} />}
                className={css(styles.alignChildrenStart)}
            />
        );
    }
}

export default WithFeedItemHeaderBaseBase(FeedItemHeader);

export const styles = StyleSheet.create({
    ...baseStyles,
    cardSubtitle: {
        ...baseStyles.cardSubtitle,
        display: 'flex',
        alignItems: 'baseline'
    },
    iconLock: {
        marginRight: spacing.s0
    },
    alignChildrenStart: {
        alignItems: important('flex-start', false)
    },
    accessibilityStyle: {
        color: baseColors.black,
        '&:hover': {
            textDecoration: 'underline'
        },
    }
});

