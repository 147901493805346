import _ from 'lodash';
import { ROUTES, openUrl, Modal, AsyncComponent, Storage } from '../../../core';
import { NOTIFICATION_ENTITY_TYPES, NOTIFICATIONS_DEEP_LINK } from '../../constants';

export default function performAction({ ...props }) {
    const { item, history } = props;
    const itemAction = _.get(item, 'actions.0', item);
    const isChallenge = !!_.get(item, 'notificationMessage.values.challengeName', false);
    const deepLink = _.get(item, 'deep_link');
    const { entityType, entityId } = itemAction || {};
    switch (entityType) {
        case NOTIFICATION_ENTITY_TYPES.stream:
        case NOTIFICATION_ENTITY_TYPES.streams:
            history.push(ROUTES.feedDetails(Number(entityId)));
            return true;
        case NOTIFICATION_ENTITY_TYPES.challenge:
        case NOTIFICATION_ENTITY_TYPES.challenges:
            history.push(ROUTES.challengeDetails(entityId));
            return true;
        case NOTIFICATION_ENTITY_TYPES.event:
            history.push(ROUTES.eventDetails(entityId));
            return true;
        case NOTIFICATION_ENTITY_TYPES.group:
        case NOTIFICATION_ENTITY_TYPES.groups:
            if (isChallenge) {
                history.push(ROUTES.challengeDetails(entityId));
            } else {
                // TODO: test
                history.push(ROUTES.communityDetails(entityId));
            }
            return true;
        case NOTIFICATION_ENTITY_TYPES.user_reward: {
            history.push(ROUTES.achievementDetails(entityId));
            return true;
        }
        case NOTIFICATION_ENTITY_TYPES.apps_devices: {
            history.push(ROUTES.deviceDetails(entityId));
            return true;
        }
        case NOTIFICATION_ENTITY_TYPES.user: {
            // TODO: test
            Modal.open(
                AsyncComponent(() => import('../../../other/components/UserProfile')),
                { id: entityId },
                { isContainer: true, isFullHeight: false }
            );
            return true;
        }
        default:
            if ((item.notificationType === 'admin_notify' || item.notificationType === 'admin_challenge_notify') && item.notificationMessage &&
                item.notificationMessage.values) {

                Storage.setItem(NOTIFICATIONS_DEEP_LINK, true);
                let url;
                if (deepLink) {
                    const host = _.get(window.location, 'origin', 'sproutatwork.com');
                    url = `${host}${deepLink}`;
                    if (url) openUrl(url, true, true);
                } else {
                    const valueNotification = _.get(item.notificationMessage.values, 'notification');
                    if (valueNotification) {
                        url = getUrl(valueNotification);
                        if (url) openUrl(url);
                    }
                }

                return true;
            }
            break;
    }
}

function getUrl(string) {
    return (string.match(/\bhttps?::?\/\/\S+/gi) || [])[0];
}
