import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';
import { components as Core } from '../../../core';
import { layoutStyle } from '../../../../styles';
import WithActivityHistoryDetailsItemBase, { styles as baseStyles } from './ActivityHistoryDetailsItemBase';

class ActivityHistoryDetailsItem extends PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        activity: PropTypes.object.isRequired,
        points: PropTypes.string.isRequired
    };

    render() {
        const { activity } = this.props;
        return (
            <Core.ListItem
                key={activity.activityId}
                className={css(layoutStyle.listItem, layoutStyle.listItemNoHPadding, layoutStyle.listItemNoLastBorder)}
                button={false}>
                <Core.ListItemText
                    primary={
                        <p numberOfLines={1} className={css(styles.flexRowValueText)}>
                            {activity.activityName}
                        </p>
                    }
                    secondary={
                        <p className={css(styles.flexRowTitleText)}>
                            {this.props.i18n.t('achievement_earned')} {this.props.points}
                        </p>
                    }
                />
                <div className={css(layoutStyle.flexColumn, layoutStyle.textRight)}>
                    <p numberOfLines={1} className={css(styles.flexRowValueText)}>
                        {activity.quantity}
                    </p>
                    <p className={css(styles.flexRowTitleText)}>{activity.unit}</p>
                </div>
            </Core.ListItem>
        );
    }
}

export default WithActivityHistoryDetailsItemBase(ActivityHistoryDetailsItem);

const styles = StyleSheet.create({
    ...baseStyles
});