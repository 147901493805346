import { createSelector } from 'reselect';
import _ from 'lodash';
import { selectors as coreSelectors } from '../core';
import { CHILD_NAMES, NAME } from './constants';
import * as types from './actionTypes';

const selectItemType = (state, type) => type;
const selectItemId = (state, type, id) => id;
const selectId = (state, id) => id;

export const isAddingLike = (state, id) => coreSelectors.isLoading(state, id);
export const isDeletingLike = (state, id) => coreSelectors.isLoading(state, id);
export const isProcessingLike = (state, id) => isAddingLike(state, id) || isDeletingLike(state, id);
export const isLoadingLikes = state => coreSelectors.isLoading(state, types.GET_LIKES.NAME);

export const isEditingComment = (state, id) => coreSelectors.isLoading(state, id);
export const isPostingComment = (state, id) => coreSelectors.isLoading(state, id);


const getCommentsStore = state => state[NAME][CHILD_NAMES.comments];
const getCommentsItems = state => getCommentsStore(state).items;

export const getCommentsByTypeAndId = createSelector(
    [getCommentsStore, selectItemType, selectItemId],
    (store, type, id) => store[type][id]
);

export const getComment = createSelector(
    [getCommentsItems, selectId],
    (items, id) => items[id]
);


const getLikesStore = state => state[NAME][CHILD_NAMES.likes];
const getLikesItems = state => getLikesStore(state).items;

export const getLikesByTypeAndId = createSelector(
    [getLikesStore, selectItemType, selectItemId],
    (store, type, id) => store[type][id]
);

export const getLike = createSelector(
    [getLikesItems, selectId],
    (items, id) => items[id]
);

export const getContentError = state => coreSelectors.getError(state, types.POST_COMMENT.NAME) ||
    coreSelectors.getError(state, types.EDIT_COMMENT.NAME);
export const isPosting = state => coreSelectors.isLoading(state, types.POST_COMMENT.NAME);
export const isEditing = state => coreSelectors.isLoading(state, types.EDIT_COMMENT.NAME);
export const isDeleting = state => coreSelectors.isLoading(state, types.DELETE_COMMENT.NAME);


const getReactionsStore = state => state[NAME][CHILD_NAMES.reactions];
const getReactionItems = state => getReactionsStore(state).items;
export const getAvailableReactions = state => getReactionsStore(state).availableReactions;

export const getReactionsByTypeAndId = createSelector([getReactionsStore, selectItemType, selectItemId], (store, type, id) => store[type][id]);
export const getReaction = createSelector([getReactionItems, selectId], (items, id) => items[id]);

export const getReactionsById = createSelector(getReactionItems, coreSelectors.getSecondParam, (items, ids) => _.filter(items, item => _.includes(ids, item.id)));

export const isLoadingReactions = state => coreSelectors.isLoading(state, types.GET_REACTIONS.NAME);