import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { withRouter } from 'react-router-dom';

import { spacing, layoutStyle } from '../../../../styles';
import { components as Core, ROUTES, tracker } from '../../../core';
import WithFindFriendBase, { styles as baseStyles } from './FindFriendBase';
import { components as Settings, constants as settingsConstants } from '../../../settings';

class FindFriend extends PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
        i18n: PropTypes.object.isRequired,
        isEmptyComponent: PropTypes.bool
    };

    static defaultProps = {
        isEmptyComponent: false
    };

    openFindFriendsAndFamily = () => {
        tracker.logEvent('library', { event: 'friendsAndFamily', from: 'feeds' });
        this.props.history.push(ROUTES.findFriendsAndFamily());
    };

    render() {
        return this.props.isEmptyComponent ? null : (
            <Core.Card className={css(styles.card)}>
                <div className={css(styles.elementsContainer, layoutStyle.flexColumn, layoutStyle.flexAlignCenter)}>
                    <div className={css(styles.containerInside, layoutStyle.flexColumn)}>
                        <Core.Icon
                            className={css(styles.iconContainer)}
                            type="fa"
                            name="user-friends"
                            size={spacing.s11}
                        />
                        <p className={css(styles.description)}>{this.props.i18n.t('invitation.noFriendsAndFamilyAdded')}</p>
                    </div>
                    <Core.Button
                        size={Core.Button.SIZES.medium}
                        onPress={this.openFindFriendsAndFamily}>
                        {this.props.i18n.t('invitation.findFriendsAndFamily')}
                    </Core.Button>
                </div>
                <Settings.PrivacySection
                    slug={settingsConstants.PRIVACY_SLUGS.SEARCH_RESULTS}
                    hasPadding={true}
                    isDisclaimerOnly={true}
                    modalLightDesign={true}
                />
            </Core.Card>
        );
    }
}

export default withRouter(WithFindFriendBase(FindFriend));

const styles = StyleSheet.create({
    ...baseStyles,
    elementsContainer: {
        ...baseStyles.elementsContainer,
        flex: 1
    },
    containerInside: {
        alignItems: 'center',
        justifyContent: 'center'
    },
    card: { margin: spacing.s0 }
});