import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { getPersonalGoalsAndChallenges } from '../../actions';
import { appFonts, spacing } from '../../../../styles';
import { translate, tracker, Platform, PLATFORMS } from '../../../core';
import { getMyPersonalGoalsLimited } from '../../selectors';
import { MAX_PERSONAL_GOALS } from '../../constants';

export default function WithRecommendedGoalsCardBase(WrappedComponent) {
    class RecommendedGoalsCardBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            setGoals: PropTypes.array,
            availableGoalsCount: PropTypes.number.isRequired,
            actions: PropTypes.object.isRequired,
            onBodyPress: PropTypes.func.isRequired,
        };

        static defaultProps = {
            setGoals: []
        };

        constructor(props) {
            super(props);
            tracker.logEvent('SetMoreGoalsTile_View');
        }

        onBodyPress = () => {
            tracker.logEvent('SetMoreGoalsTile_ClickBody');
        }

        onActionPress = () => {
            tracker.logEvent('SetMoreGoalsTile_ClickAction');
        }


        get isWeb() {
            return Platform.OS === PLATFORMS.web;
        }

        get title() {
            if (this.props.availableGoalsCount === 1) {
                return this.props.i18n.t('personal_goal.carousel.heading_singular');
            }
            return this.props.i18n.t('personal_goal.carousel.heading_plural', { number: this.props.availableGoalsCount });
        }


        get description() {
            const { i18n, setGoals } = this.props;
            return i18n.t(`${!setGoals.length ? 'goalsCard.emptyStateTitle' : 'recommendedCardDesc'}`);
        }

        get isEmptyState() {
            return !this.props.setGoals?.length;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    title={this.title}
                    description={this.description}
                    onBodyPress={this.onBodyPress}
                    onActionPress={this.onActionPress}
                    isEmptyState={this.isEmptyState}
                />
            );
        }
    }

    const mapStateToProps = state => ({
        setGoals: getMyPersonalGoalsLimited(state),
        availableGoalsCount: MAX_PERSONAL_GOALS - _.get(getMyPersonalGoalsLimited(state), 'length', 0)
    });

    const mapDispatchToProps = dispatch => ({
        actions: bindActionCreators({ getPersonalGoalsAndChallenges }, dispatch)
    });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(RecommendedGoalsCardBase));
}

export const styles = {
    // containerWrap: {
    //     backgroundColor: baseColors.white,
    //     flex: 1,
    //     borderRadius: spacing.s1,
    //     padding: spacing.s3,
    //     justifyContent: 'space-between',
    //     alignItems: 'center',
    // },
    // innerContainer: {
    //     flex: 1,
    //     alignItems: 'center',
    //     justifyContent: 'center',
    // },
    iconContainer: {
        marginBottom: spacing.s2
    },
    icon: {
        marginLeft: spacing.s1,
        marginRight: spacing.s1
    },
    description: {
        ...appFonts.mdRegular,
        textAlign: 'center'
    },
    title: {
        ...appFonts.lgMedium,
        textAlign: 'center',
        marginBottom: spacing.s1
    },
};
