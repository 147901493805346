import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MUICheckbox from '@material-ui/core/Checkbox';
import Icon from '../Icon';
import { baseColors, spacing } from '../../../../styles';

const COLOR = 'secondary';

const Checkbox = ({ checked, onChange, label, disabled, type, className, checkBoxClassName, color = COLOR, customIcon, checkedIcon, inputProps={}, ...props }) => (
    <FormControlLabel
        className={className}
        control={(
            <MUICheckbox
                className={checkBoxClassName}
                checked={checked}
                onChange={onChange}
                color={color}
                inputProps={inputProps}
                tabIndex={type === Checkbox.TYPES.simple ? '0' : '-1'}
                {...checkedIcon && { checkedIcon }}
                {...(customIcon ? { icon: customIcon } : getIcons(type, color))}
            />
        )}
        disabled={disabled}
        label={label}
        {...props}
    />
);

Checkbox.TYPES = {
    regular: 'regular',
    rounded: 'rounded',
    simple: 'simple',
    button: 'button',
    roundedRadio: 'roundedRadio'
};

function getIcons(type, color) {
    switch (type) {
        case Checkbox.TYPES.rounded:
            return {
                icon: <Icon type="material" name="RadioButtonUnchecked" color={color} />,
                checkedIcon: <Icon type="material" name="CheckCircle" color={color} />
            };
        case Checkbox.TYPES.simple:
            return {
                icon: <span />,
                checkedIcon: <Icon type="material" name="Check" color={color} />
            };
        case Checkbox.TYPES.button:
            return {
                icon: <Icon name="chevron-right" type="fa" size={spacing.s3} color={color} />,
                checkedIcon: <Icon name="chevron-right" type="fa" size={spacing.s3} color={baseColors.white} />
            };
        case Checkbox.TYPES.roundedRadio:
            return {
                icon: <Icon type="material" name="RadioButtonUnchecked" color={color} />,
                checkedIcon: <Icon type="material" name="RadioButtonChecked" color={color} />
            };
        case Checkbox.TYPES.regular:
        default:
            return {
                icon: <Icon type="material" name="CheckBoxOutlineBlank" color={color} />,
                checkedIcon: <Icon type="material" name="CheckBox" color={color} />
            };
    }
}

Checkbox.propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    disabled: PropTypes.bool,
    type: PropTypes.oneOf(_.values(Checkbox.TYPES)),
    className: PropTypes.string,
    checkBoxClassName: PropTypes.string,
    color: PropTypes.string,
    customIcon: PropTypes.object,
    checkedIcon: PropTypes.object,
};

Checkbox.defaultProps = {
    checked: false,
    onChange: null,
    label: undefined,
    disabled: false,
    type: Checkbox.TYPES.regular,
    className: null,
    checkBoxClassName: null,
    color: undefined,
    customIcon: undefined,
    checkedIcon: undefined,
};

export default Checkbox;
