import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { css, StyleSheet } from 'aphrodite-jss';
import { layoutStyle, spacing, baseColors, importantClass, } from '../../../../styles';
import { components as Core } from '../../../core';
import GoalProgressInfo from '../GoalProgressInfo';
import CompetitionCardLeaderboard from '../CompetitionCardLeaderboard';
import { CHALLENGE_TYPES } from '../../constants';
import crownIcon from '../../../../image/crownIcon3x.png';
import trophyIcon from '../../../../image/trophyIcon@3x.png';
import WithCompetitionProgressCardBase, { styles as baseStyles } from './CompetitionProgressCardBase';

class CompetitionProgressCard extends PureComponent {
    static propTypes = {
        challengeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // eslint-disable-line
        isCompletedNotInGracePeriod: PropTypes.bool.isRequired,
        noParticipationText: PropTypes.string.isRequired,
        overallProgress: PropTypes.object.isRequired,
        isInGracePeriod: PropTypes.bool.isRequired,
        isTeamChallenge: PropTypes.bool.isRequired,
        getLeaderboardItemName: PropTypes.func,
        leaderboardItems: PropTypes.array,
        isLoading: PropTypes.bool,
        isUpdating: PropTypes.bool,
    };

    static defaultProps = {
        isLoading: true,
        isUpdating: false,
        leaderboardItems: null,
        getLeaderboardItemName: null,
    };

    renderAvatar = item => (
        <Core.Avatar
            name={item.name}
            size="xmsmall20"
            disablePress={true}
            url={item.avatarURL}
            id={item.itemEntityId}
            className={css(styles.avatar, item.rank > 3 && styles.noImageBorder)}
        />
    );

    renderLeaderboardItem = item => {
        const { isTeamChallenge, getLeaderboardItemName } = this.props;
        const noViewerInTopThree = item.rank > 3;

        return (
            <div className={css(layoutStyle.flexRow, styles.leaderboardItem,
                item.isViewer && styles.boldText,
                noViewerInTopThree && styles.viewerItem,
            )}>
                <div className={css(styles.memberPosition, item.isViewer && styles.boldText)}>{item.rank}</div>
                {this.renderAvatar(item)}
                { (noViewerInTopThree && !isTeamChallenge) &&
                    (
                        <Core.Icon
                            type="fa"
                            name="user"
                            fill="solid"
                            size={spacing.s2}
                            color={baseColors.primary}
                            className={css(styles.userIcon)}
                        />
                    )
                }
                <Core.TextSeeMore
                    numberOfLines={1}
                    className={css(styles.memberName)}
                    isExpandHidden={true}>
                    {getLeaderboardItemName(item)}
                </Core.TextSeeMore>
                <div className={css(styles.memberScore)}>{item.score}</div>
            </div>
        );
    }

    renderEndedStateBody = () => {
        const { leaderboardItems } = this.props;
        return (
            (leaderboardItems && leaderboardItems.length) ? (
                <div className={css(styles.competitionGracePeriod)}>
                    <Core.Image src={crownIcon} className={css(styles.crownIcon)} />
                    <div className={css(styles.leaderboardItemsWrapper)}>
                        {_.map(leaderboardItems, this.renderLeaderboardItem)}
                    </div>
                </div>
            ) : this.renderNoParticipationBlock()
        );
    }

    renderNoParticipationBlock = () => {
        const { noParticipationText, isLoading } = this.props;
        return isLoading ? null : (
            <div className={css(styles.noParticipation)}>
                <Core.Image src={trophyIcon} className={css(styles.trophyIcon)} />
                <p className={css(styles.noParticipationText)}>{noParticipationText}</p>
            </div>
        );
    }

    render() {
        const { challengeId, isUpdating, overallProgress, isInGracePeriod, isCompletedNotInGracePeriod } = this.props;

        return (isInGracePeriod || isCompletedNotInGracePeriod ?
            this.renderEndedStateBody() :
            (
                <div className={css(styles.competitionCard)}>
                    <CompetitionCardLeaderboard challengeId={challengeId} isUpdating={isUpdating} />
                    <div className={css(layoutStyle.flex1, styles.progressContainer)}>
                        <GoalProgressInfo
                            challengeId={challengeId}
                            period={overallProgress}
                            isUpdating={isUpdating}
                            progressType={CHALLENGE_TYPES.competition}
                        />
                    </div>
                </div>
            )
        );
    }
}

export default WithCompetitionProgressCardBase(CompetitionProgressCard);

const LINEAR_GRADIENT = `linear-gradient(260deg, ${baseColors.gradientGolden_1} 0%, ${baseColors.gradientGolden_2} 50%, ${baseColors.gradientGolden_3} 100%)`;

const styles = StyleSheet.create({
    ...baseStyles,
    competitionCard: {
        ...baseStyles.competitionCard,
        paddingBottom: 0
    },
    leaderboardItemsWrapper: {
        ...baseStyles.leaderboardItemsWrapper,
        background: LINEAR_GRADIENT,
    },
    leaderboardItem: {
        ...baseStyles.leaderboardItem,
        background: LINEAR_GRADIENT,
    },
    viewerItem: {
        ...baseStyles.viewerItem,
        background: baseColors.white,
    },
    avatar: importantClass({
        ...baseStyles.avatar,
    }),
});