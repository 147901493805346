import _ from 'lodash';
import { createSelector } from 'reselect';
import {
    NAME,
    MAX_EARN_PARTNERS_REWARDS_DETAILS_COUNT,
    EARN_PARTNERS_IS_ENABLED,
    EARN_PARTNERS_STATUS_TYPES,
    EARN_PARTNERS_TABS, ACHIEVEMENTS_STATUSES,
    LEVEL_BADGE_SLUGS
} from './constants';
import { selectors as coreSelectors, constants as coreConstants } from '../core';
import * as types from './actionTypes';
import I18n from '../../i18n/i18n';

const getAll = state => state[NAME].products.items;
const getAllProductsIds = state => state[NAME].products.ids;
const getAllFeaturedIds = state => state[NAME].products.featured;
const getAllRandomIds = state => state[NAME].products.randomIds;
const getProductsByCategoryIds = (state, category) => state[NAME].products.categories[category];
const getAllTransactions = state => state[NAME].transactions.items;
const getAllTransactionsIds = state => state[NAME].transactions.ids;
const getAllOrders = state => state[NAME].orders.items;
const getAllOrdersIds = state => state[NAME].orders.ids;
const getRedeemError = state => coreSelectors.getError(state, types.REDEEM_REWARDS_PRODUCT.NAME);

const isCarousel = (state, isCarousel = false) => isCarousel;
const getIds = (ids, isCarousel = false) => (isCarousel ? ids.slice(0, coreConstants.MAX_SLIDES_COUNT) : ids);
const getItems = (items, ids, isCarousel) => _.map(getIds(ids, isCarousel), id => items[id]);

export const isRewardsEnabled = state => state[NAME].isRewardsEnabled;
export const isBadgesEnabled = state => state[NAME].isBadgesEnabled;
export const getRewards = state => state[NAME].rewards;
export const getRewardsCategories = state => state[NAME].rewardsCategories;
export const externalRewardName = state => _.get(state[NAME].rewards, 'externalRewardName', '');
export const externalRewardsDescription = state => _.get(state[NAME].rewards, 'externalRewardsDescription', '');

export const getProducts = createSelector(getAll, getAllProductsIds, (items, ids) => _.filter(_.map(ids, id => items[id]), _.identity));
export const getFeaturedProducts = createSelector(getAll, getAllFeaturedIds, getItems);
export const getFeaturedProductsCount = createSelector(getFeaturedProducts, items => items.length);
export const getRandomProducts = createSelector(getAll, getAllRandomIds, (items, ids) => _.filter(_.map(ids, id => items[id]), _.identity));
export const getHeroProducts = state => state[NAME].heros.items;
export const getProduct = (state, id) => getAll(state)[id];
export const productRegions = (state, id) => getAll(state)[id].regions;
export const productCountry = (state, id) => getAll(state)[id].country;
export const getProductRegions = createSelector(productRegions,
    regions => _.map(regions, item => ({ id: item.value, label: item.label }))
);
export const getProductCountry = createSelector(productCountry, country => ({ id: country.value, label: country.label }));
export const getProductsCurrentAmount = state => state[NAME].products.ids.length;
export const getProductsAmount = state => state[NAME].products.total;

export const getProductByCategory = createSelector(getAll, getProductsByCategoryIds, (items, ids) => _.filter(_.map(ids, id => items[id]), _.identity));
export const getProductCategories = state => _.union(_.map(getProducts(state), 'category'));
export const getProductRewardsCategories = createSelector(getRewardsCategories, data => _.map(data.items, item => item.name));

export const getProductsCountByCategory = createSelector(getProductByCategory, items => items.length);

export const getTransactions = createSelector(getAllTransactions, getAllTransactionsIds, (items, ids) => _.filter(_.map(ids, id => items[id]), _.identity));
export const getTransactionsAmount = state => state[NAME].transactions.total;
export const getOrders = createSelector(getAllOrders, getAllOrdersIds, (items, ids) => _.filter(_.map(ids, id => items[id]), _.identity));
export const getOrder = (state, id) => getAllOrders(state)[id];
export const getOrdersAmount = state => state[NAME].orders.total;

export const getShippingAddress = state => state[NAME].shippingAddress;
export const isExternalCatalog = createSelector(getRewards, rewards => !_.get(rewards, 'rewardType') && _.get(rewards, 'catalogUrl'));

export const isLoadingRewards = state => coreSelectors.isLoading(state, types.GET_REWARDS.NAME);
export const isLoadingProducts = state => coreSelectors.isLoading(state, types.GET_REWARDS_PRODUCTS.NAME);
export const isLoadingTransactions = state => coreSelectors.isLoading(state, types.GET_REWARDS_TRANSACTIONS.NAME);
export const isLoadingOrders = state => coreSelectors.isLoading(state, types.GET_REWARDS_ORDERS.NAME);
export const isLoadingOrder = state => coreSelectors.isLoading(state, types.GET_REWARDS_ORDER.NAME);
export const isRedemption = state => coreSelectors.isLoading(state, types.REDEEM_REWARDS_PRODUCT.NAME);
export const isOrderError = state => !!coreSelectors.getError(state, types.REDEEM_REWARDS_PRODUCT.NAME);
export const isLoadingShippingAddress = state => coreSelectors.isLoading(state, types.GET_SHIPPING_ADDRESS.NAME);
export const isLoadingLevels = state => coreSelectors.isLoading(state, types.GET_LEVELS.NAME);

export const getCurrentOrderId = state => Number(state[NAME].redemptionSession.orderId);
export const isRedemptionTokenExpired = createSelector(getRedeemError, error => _.get(error, 'response.data.errorDetails.token', '') > 0);
export const getRedemptionToken = state => state[NAME].redemptionSession.token;
export const getRedemptionTokenError = state => coreSelectors.getError(state, types.GET_REWARD_REDEMPTION_TOKEN.NAME);
export const isLoadingGetRedemptionToken = state => coreSelectors.isLoading(state, types.REDEEM_REWARDS_PRODUCT.NAME);
export const isRedemptionTokenError = state => !!getRedemptionTokenError(state);


export const getLevels = state => state[NAME].levels;
export const getExternalRewardName = state => getRewards(state).externalRewardName;
export const getExternalRewardsDescription = state => getRewards(state).externalRewardsDescription;
export const getNotCompletedLevelsOrLast = createSelector(getLevels, levels => {
    if (!_.has(levels, 'companyLevels') || !levels.companyLevels.length) return null;
    const notCompleted = _.filter(levels.companyLevels, item => item.score > levels.scoreForPeriod);
    return notCompleted.length > 0 ? notCompleted : [levels.companyLevels[levels.companyLevels.length - 1]];
});
export const getCompanyLevels = createSelector(getLevels, levels => levels.companyLevels || []);
export const getLevelsFrequency = createSelector(getLevels, levels => levels.frequency);

export const getNextCompanyLevel = createSelector(getLevels, levels => {
    if (!_.has(levels, 'companyLevels') || !levels.companyLevels.length) return null;
    const nextLevelValue = levels.nextLevel;
    if (nextLevelValue) {
        const nextLevel = _.filter(levels.companyLevels, item => item.level === nextLevelValue);
        return nextLevel[0];
    }

    return _.last(levels.companyLevels);
});

const getAchievementsStore = state => _.get(state[NAME], 'achievements');
const getAchievementsItems = state => _.get(getAchievementsStore(state), 'items');
const getAchievementsIds = state => _.get(getAchievementsStore(state), 'ids');
const getAchievementsEarnedIds = state => getAchievementsStore(state).earned;
const getAchievementsInProgressIds = state => getAchievementsStore(state).inProgress;
const getUpcomingAchievementsStore = state => getAchievementsStore(state).upcoming || {};
const getUpcomingAchievementsItems = state => getUpcomingAchievementsStore(state).items;
const getAchievementsUpcomingIds = state => getUpcomingAchievementsStore(state).ids;

export const getAchievements = createSelector(getAchievementsItems, getAchievementsIds, isCarousel, getItems);
export const getRewardsAchievements = createSelector(getAchievements, items => _.filter(items, item => item.progressAmount < item.progressTarget && !_.isNull(item.externalRewardPoints)));
export const getAllRewardsAchievements = createSelector(getAchievements, items => _.filter(items, item => item.externalRewardPoints));
export const getEarnedAchievements = createSelector(getAchievementsItems, getAchievementsEarnedIds, isCarousel, getItems);
export const getInProgressAchievements = createSelector(getAchievementsItems, getAchievementsInProgressIds, isCarousel, getItems);
export const getAvailableAchievements = createSelector(getAchievements, items => _.filter(items, item => item.badgeStatus === ACHIEVEMENTS_STATUSES.available));
export const getEarnedAchievementsCount = createSelector(getEarnedAchievements, items => items.length);
export const getAchievementsCount = createSelector(getAchievements, items => items.length);
export const getUpcomingAchievements = createSelector(getUpcomingAchievementsItems, getAchievementsUpcomingIds, isCarousel, getItems);

export const getAllRewardsAcheivementsSorted = createSelector(getAllRewardsAchievements, items => {
    if (!_.get(items, 'length')) return [];
    const sorted = _.orderBy(items, ['slug'], ['asc']);

    sorted.unshift(_.find(items, item => item.slug === LEVEL_BADGE_SLUGS.LEVEL_GOLD));
    sorted.unshift(_.find(items, item => item.slug === LEVEL_BADGE_SLUGS.LEVEL_SILVER));
    sorted.unshift(_.find(items, item => item.slug === LEVEL_BADGE_SLUGS.LEVEL_BRONZE));

    return _.uniqBy(_.filter(sorted, item => item !== undefined), 'slug');
});

export const getAllNotLevelAchievementsSorted = createSelector(getAllRewardsAchievements, items => {
    if (!_.get(items, 'length')) return [];

    const filtered = _.filter(items, item =>
        item &&
        item.slug !== LEVEL_BADGE_SLUGS.LEVEL_GOLD &&
        item.slug !== LEVEL_BADGE_SLUGS.LEVEL_SILVER &&
        item.slug !== LEVEL_BADGE_SLUGS.LEVEL_BRONZE);

    return _.uniqBy(_.orderBy(filtered, ['externalRewardPoints', 'name'], ['desc', 'asc']), 'slug');
});

export const getAchievement = (state, id) => getAchievementsItems(state)[id];
export const getUpcomingAchievement = (state, id) => getUpcomingAchievementsItems(state)[id];
export const getAchievementBySlug = createSelector((state, slug) => _.find(getAchievementsItems(state), item => item.slug === slug), item => item);
export const isLoadingAchievements = state => coreSelectors.isLoading(state, types.GET_ACHIEVEMENTS.NAME);

const getAllEarnPartners = state => state[NAME].earnPartners.items;
const getAllEarnPartnersIds = state => state[NAME].earnPartners.ids;
export const getEarnPartners = createSelector(getAllEarnPartners, getAllEarnPartnersIds, (items, ids) => _.filter(_.map(ids, id => items[id]), _.identity));
export const getEarnPartnerCategories = state => state[NAME].earnPartnersCategories;
export const getEarnPartnerCategoryIDs = createSelector(getEarnPartners, items => _.union(_.map(items, 'categoryId')));
export const isLoadingEarnPartners = state => coreSelectors.isLoading(state, types.GET_EARNPARTNERS_PARTNERS.NAME);
export const getEarnPartnersByCount = createSelector(getEarnPartners, items => _.take(items, MAX_EARN_PARTNERS_REWARDS_DETAILS_COUNT));

const CAROUSEL_ITEMS = 4;
const ITEMS_PER_PAGE = 2;
export const getEarnPartnersCarouselArray = createSelector(getEarnPartners, partners => _.chunk(_.take(partners, CAROUSEL_ITEMS), ITEMS_PER_PAGE));

export const isLinkingEarnPartner = state => coreSelectors.isLoading(state, types.UPDATE_EARNPARTNERS_LINK.NAME);
export const isLoadingEarnPartner = state => coreSelectors.isLoading(state, types.GET_EARNPARTNERS_PARTNER.NAME);
export const getEarnPartner = (state, id) => getAllEarnPartners(state)[id];
export const getEarnPartnerStatus = state => state[NAME].earnPartnerStatus;

export const isEarnPartnersEnabled = state => (EARN_PARTNERS_IS_ENABLED ? !!coreSelectors.getCurrentUser(state).isEarnParnersEnabled : false);
export const getEarnPartnersDescription = state => _.get(coreSelectors.getCurrentUser(state), 'earnPartnersDescription', '');
export const getEarnPartnersProgramName = state => _.get(coreSelectors.getCurrentUser(state), 'earnPartnersName', '');

export const filteredCategories = createSelector(getEarnPartnerCategories, getEarnPartnerCategoryIDs, (categories, ids) => _.filter(categories, item => ids.includes(item.id)));
export const getCategoriesTabs = createSelector(filteredCategories, items => {
    const tabs = {};
    for (let i = 0; i < items.length; i++) {
        tabs[i] = {
            id: items[i].id,
            order: items[i].order,
            label: items[i].name,
            emptyMessage: I18n.t('noPartnersInCategory')
        };
    }
    return _.sortBy(tabs, 'order');
});

export const getPartnersWithLinkStatus = createSelector(getEarnPartners, partners => _.filter(partners, item => (_.get(item, 'status', '') === EARN_PARTNERS_STATUS_TYPES.LINKED
        || _.get(item, 'status', '') === EARN_PARTNERS_STATUS_TYPES.PENDING
        || _.get(item, 'status', '') === EARN_PARTNERS_STATUS_TYPES.ERROR)));

export const getPartnersBySearch = createSelector(getEarnPartners, coreSelectors.getThirdParam,
    (partners, search) => _.filter(partners, item => item.name.toLowerCase().includes(search.toLowerCase())));

export const getPartnersByTab = createSelector(getEarnPartners, coreSelectors.getSecondParam,
    (partners, activeTab) => _.filter(partners, item => item.categoryId === activeTab.id));

export const getPartnersForCategory = createSelector(
    getEarnPartners,
    coreSelectors.getSecondParam,
    coreSelectors.getThirdParam,
    getPartnersBySearch,
    getPartnersByTab,
    getPartnersWithLinkStatus,
    (partners, activeTab, search, bySearch, byTab, withLinkStatus) => {
        if (search) {
            return bySearch;
        }
        if (activeTab.id !== EARN_PARTNERS_TABS.allTabId && activeTab.id !== EARN_PARTNERS_TABS.linkedTabId) {
            return byTab;
        }
        if (activeTab.id === EARN_PARTNERS_TABS.linkedTabId) {
            return withLinkStatus;
        }
        return partners;
    }
);
