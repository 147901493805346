import axios from 'axios';
import { getBaseUrl } from '../core';

const getLikesUrl = endpoint => endpoint ? getBaseUrl(`likes/${endpoint}`) : getBaseUrl('likes');
const getCommentsUrl = endpoint => endpoint ? getBaseUrl(`comments/${endpoint}`) : getBaseUrl('comments');

export function addLike(data) {
    return axios.post(getLikesUrl(), data);
}

export function deleteLike(likeId) {
    return axios.delete(getLikesUrl(likeId));
}

export function moderateComment(commmentId) {
    return axios.post(getBaseUrl('moderation'), { entityType: 'comment', entityId: commmentId });
}

export function deleteComment(commentId) {
    return axios.delete(getCommentsUrl(commentId));
}

export function editComment(comment) {
    return axios.put(getCommentsUrl(), comment);
}

export function postComment(comment) {
    return axios.post(getCommentsUrl(), comment);
}

export function getComments(params) {
    return axios.get(getCommentsUrl(), { params: { maxCount: 10, ...params } });
}

export function getLikes(id, params) {
    return axios.get(getLikesUrl(id), { params: { maxCount: 20, ...params } });
}

export function getReactionEmojis() {
    return axios.get(getBaseUrl('emojis'));
}

export function addReaction(params) {
    return axios.post(getLikesUrl(), params);
}

export function removeReaction(likeId) {
    return axios.delete(getLikesUrl(likeId));
}

export function getReactions(id, params) {
    return axios.get(getLikesUrl(id), { params: { maxCount: 20, ...params } });
}
