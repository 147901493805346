import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { pointsHelper, translate, selectors as coreSelectors } from '../../../core';
import { appFonts, baseColors } from '../../../../styles';

export default function WithActivityHistoryDetailsItemBase(WrappedComponent) {
    class ActivityHistoryDetailsItemBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            activity: PropTypes.object.isRequired,
            customPointsUnit: PropTypes.string.isRequired
        };

        get points() {
            // backend calculation has been using math.ceil, FE should keep it consistent
            return pointsHelper.formatPoints(Math.ceil(_.get(this.props.activity, 'points', 0)), this.props.customPointsUnit);
        }

        render() {
            return <WrappedComponent {...this.props} points={this.points} />;
        }
    }

    const mapStateToProps = state => ({
        customPointsUnit: coreSelectors.getCustomPointsUnit(state)
    });

    return connect(mapStateToProps)(translate()(ActivityHistoryDetailsItemBase));
}

export const styles = {
    flexRowValueText: {
        ...appFonts.mdBold
    },
    flexRowTitleText: {
        ...appFonts.smRegular,
        color: baseColors.grey40
    },
};
