import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { translate, selectors as coreSelectors } from '../../../core';
import * as eventsActions from '../../actions';
import { eventListSelector, getAllEventsSelector, isLoadingEventsByType, isLoadingAllEvents, getNewEventsCount } from '../../selectors';
import { onRefresh } from '../../services';
import { baseColors } from '../../../../styles';
import { EVENT_ENTITY_TYPES, MAX_COUNT } from '../../constants';
import { selectors as otherSelectors, actions as otherActions } from '../../../other';


export default function WithEventsListBase(WrappedComponent) {
    class EventsListBase extends PureComponent {
        static propTypes = {
            isLoading: PropTypes.bool,
            i18n: PropTypes.object.isRequired,
            userName: PropTypes.object,
            actions: PropTypes.object.isRequired,
            otherActions: PropTypes.object.isRequired,
            type: PropTypes.string.isRequired,
            events: PropTypes.array,
            lastFetchedEventsCount: PropTypes.number,
            userId: PropTypes.number.isRequired
        };

        static defaultProps = {
            isLoading: false,
            lastFetchedEventsCount: 0,
            userName: '',
            events: []
        };
        constructor(props) {
            super(props);
            this.loadEvents();
            this.loadUserData();
        }

        loadEvents = () => {
            const { actions, type, userId } = this.props;
            onRefresh(actions, type, userId, 0, true);
        };

        loadUserData = () => {
            const { type, userId } = this.props;
            if (type === EVENT_ENTITY_TYPES.USER) {
                this.props.otherActions.getUserData(userId);
            }
        };

        get hasMore() {
            return this.props.lastFetchedEventsCount === MAX_COUNT;
        }

        loadMoreContent = () => {
            const { actions, type, userId, isLoading, events } = this.props;
            if (!isLoading && this.hasMore) {
                onRefresh(actions, type, userId, events.length, false);
            }
        };

        get title() {
            const { type, i18n } = this.props;
            switch (type) {
                case EVENT_ENTITY_TYPES.MY_ITEMS:
                    return i18n.t('upcoming');
                case EVENT_ENTITY_TYPES.PAST:
                    return i18n.t('past_events');
                case EVENT_ENTITY_TYPES.INVITED:
                    return i18n.t('invitedCommunities');
                case EVENT_ENTITY_TYPES.RECOMMENDED:
                    return i18n.t('recommended');
                case EVENT_ENTITY_TYPES.USER:
                    return i18n.t('userEvents', { name: this.props.userName });
                case EVENT_ENTITY_TYPES.ALL_ITEMS:
                default:
                    return i18n.t('allCommunities');
            }
        }

        get isUsersAttendingEvents() {
            return this.props.type === EVENT_ENTITY_TYPES.USER;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    hasMore={this.hasMore}
                    loadMoreContent={this.loadMoreContent}
                    onRefresh={this.loadEvents}
                    title={this.title}
                    isUsersAttendingEvents={this.isUsersAttendingEvents}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        const type = ownProps.type || _.get(ownProps, 'match.params.type') || _.get(ownProps, 'route.params.type');
        const currentSelector = eventListSelector[type] || getAllEventsSelector;
        const isLoading = isLoadingEventsByType(state, type) || isLoadingAllEvents(state);
        const userId = ownProps.userId
            || _.get(ownProps, 'match.params.userId')
            || _.get(ownProps, 'route.params.userId')
            || coreSelectors.getCurrentUserId(state);
        return {
            events: currentSelector(state),
            isLoading,
            type,
            userName: otherSelectors.getUserFirstName(state, userId),
            userId,
            lastFetchedEventsCount: getNewEventsCount(state, type)
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(eventsActions, dispatch),
            otherActions: bindActionCreators(otherActions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(EventsListBase));
}

export const styles = {
    container: {
        backgroundColor: baseColors.white
    },
    separator: {
        backgroundColor: baseColors.grey80,
        height: 1
    },
};

