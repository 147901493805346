import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { appFonts, baseColors, spacing } from '../../../../styles';

export default function WithProgressBarBase(WrappedComponent) {
    class ProgressBarBase extends PureComponent {
        static propTypes = {
            filledPercentage: PropTypes.number,
            showSuccessPercentageStyle: PropTypes.bool
        };

        static defaultProps = {
            filledPercentage: 0,
            showSuccessPercentageStyle: false
        };

        get percentageString() {
            return `${parseInt(this.props.filledPercentage)}%`;
        }

        get animationDuration() {
            return this.props.filledPercentage * 10;
        }

        get initialValue() {
            return this.props.filledPercentage > 5 ? this.props.filledPercentage - 5 : 0;
        }

        get filledPercentage() {
            return 50;
        }

        get showSuccessPercentage() {
            const { filledPercentage, showSuccessPercentageStyle } = this.props;
            return !!(filledPercentage >= 100 && showSuccessPercentageStyle);
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    initialValue={this.initialValue}
                    percentageString={this.percentageString}
                    animationDuration={this.animationDuration}
                    showSuccessPercentage={this.showSuccessPercentage}
                />
            );
        }
    }

    return ProgressBarBase;
}

export const WIDTH_LIMIT = 30;
export const WIDTH_LIMIT_WEB = 40;

export const styles = {
    percentage: {
        ...appFonts.lgBold,
        color: baseColors.white
    },
    percentageInside: {
        color: baseColors.grey40
    },
    percentageWrapper: {
        flexDirection: 'row',
        height: '100%',
        position: 'absolute',
        zIndex: 1,
        marginLeft: -50
    },
    percentageWrapperInside: {
        marginLeft: spacing.s1,
    },
    percentageSuccessText: {
        color: baseColors.successDarker
    }
};
