import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { translate } from '../../../core';
import * as selectors from '../../selectors';
import { CATEGORIES } from '../../constants';

export default function WithContentOnFeedsRecommendedBase(WrappedComponent) {
    class ContentOnFeedsRecommendedBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired
        };

        get infoText() {
            const { i18n } = this.props;
            return i18n.t('selectionsFromLibrary');
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    infoText={this.infoText}
                />
            );
        }
    }

    function mapStateToProps(state) {
        const recommendedItemsCount = _.slice(selectors.getRecommendedContent(state)[CATEGORIES.ALL], 0, 3).length;
        return {
            itemsCount: recommendedItemsCount
        };
    }

    return connect(mapStateToProps)(translate()(ContentOnFeedsRecommendedBase));
}