import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { css, StyleSheet } from 'aphrodite-jss';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import { layoutStyle, importantStyles, baseColors } from '../../../../styles';
import WithAlertBase, { styles as baseStyles, Alert } from './AlertBase';

export default Alert;

export class AlertComp extends PureComponent {
    static propTypes = {
        defaultButtonText: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = { open: false };
    }

    componentDidMount() {
        Alert.alert = this.open;
    }

    open = (title, text, buttons, { cancelable = true } = {}) => {
        this.setState({ open: true, title, text, buttons, cancelable });
    };

    close = () => {
        this.setState({ open: false });
    };

    onPress = button => () => {
        this.close();
        button.onPress && button.onPress();
    };

    render() {
        const { title, text, buttons, open, cancelable } = this.state;
        return (
            <Dialog
                onEscapeKeyDown={cancelable ? this.close : undefined}
                onBackdropClick={cancelable ? this.close : undefined}
                disableEscapeKeyDown={!cancelable}
                disableBackdropClick={!cancelable}
                open={open}
                onClose={this.close}
                PaperProps={{
                    classes: { root: css(styles.container) }
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle
                    id="alert-dialog-title"
                    disableTypography={true}
                    classes={{ root: css(styles.title) }}
                >
                    {title}
                </DialogTitle>
                <DialogContent classes={{ root: css(styles.content) }}>
                    <DialogContentText
                        id="alert-dialog-description"
                        classes={{ root: css(styles.contentText) }}
                    >
                        {text}
                    </DialogContentText>
                </DialogContent>
                <DialogActions classes={{ root: css(styles.buttonsContainer) }}>
                    {buttons && buttons.length ? (
                        <div className={css(layoutStyle.fw)}>
                            {_.map(buttons, (button, index) => (
                                <>
                                    <Divider classes={{ root: css(styles.divider) }} />
                                    <Button
                                        id={_.camelCase(`${button.text}OnAlert`)}
                                        onClick={this.onPress(button)}
                                        color="secondary"
                                        fullWidth={true}
                                        disableRipple={true}
                                        autoFocus={index === (buttons.length - 1)}
                                        key={button.text}
                                        classes={{
                                            text: css(
                                                styles.button,
                                                styles.buttonText,
                                                (button.isPrimary || button.isDangerButton || buttons.length === 1) && styles.bolderBtnText,
                                                button.isDangerButton && styles.dangerColor
                                            ),
                                        }}
                                    >
                                        {button.text}
                                    </Button>
                                </>
                            ))}
                        </div>
                    ) : (
                        <div className={css(layoutStyle.fw)}>
                            <Divider classes={{ root: css(styles.divider) }} />
                            <Button
                                id="okOnAlert"
                                onClick={this.close}
                                color="secondary"
                                fullWidth={true}
                                disableRipple={true}
                                classes={{ text: css(styles.buttonText, styles.bolderBtnText) }}
                                autoFocus
                            >
                                {this.props.defaultButtonText}
                            </Button>
                        </div>
                    )}
                </DialogActions>
            </Dialog>
        );
    }
}

const MODAL_WIDTH = 400;
const BUTTON_HEIGHT = 50;
const CONTAINER_BORDER_RADIUS = 8;

const styles = StyleSheet.create({
    ...importantStyles({
        ...baseStyles,
        container: {
            width: MODAL_WIDTH,
            borderRadius: CONTAINER_BORDER_RADIUS,
        },
        divider: {
            height: 1,
            backgroundColor: baseColors.grey80,
        },
        buttonsContainer: {
            padding: 0,
        },
        button: {
            height: BUTTON_HEIGHT,
            backgroundColor: baseColors.white,
            '&:hover': {
                backgroundColor: baseColors.grey90,
            },
        },
    }),
});

export const AlertComponent = WithAlertBase(AlertComp);
