import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { translate, selectors as coreSelectors } from '../../../../core';
import * as actions from '../../../actions';
import { spacing } from '../../../../../styles';
import * as selectors from '../../../selectors';
import { getCurrentUserLeaderboardInfo, getCurrentUserLeaderBoardTeamInfo } from '../../../services/helper';
import { CHALLENGE_ENTITY_TYPES } from '../../../constants';

const MAX_COUNT_IN_LIST = 100;

export default function WithLeaderboardBase(WrappedComponent) {
    class LeaderboardBase extends PureComponent {
        static propTypes = {
            history: PropTypes.object.isRequired,
            i18n: PropTypes.object.isRequired,
            leaderboard: PropTypes.object,
            user: PropTypes.object.isRequired,
            challenge: PropTypes.object.isRequired,
            customPointsUnit: PropTypes.string.isRequired,
            challengeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        };

        static defaultProps = {
            leaderboard: undefined,
        };

        state = { isViewMe: false };

        get text() {
            const { challenge: { userEntity }, i18n } = this.props;
            if (this.state.isViewMe) return i18n.t('show_leader');
            return i18n.t(userEntity.entityType === CHALLENGE_ENTITY_TYPES.user
                ? 'show_me' : 'show_my_team');
        }

        get nothingToShowText() {
            return this.props.i18n.t('nothingToShow');
        }

        get viewerInfo() {
            const { user } = this.props;
            if (this.challengeType === 'userTeam' || this.challengeType === 'companyTeam') {
                return getCurrentUserLeaderBoardTeamInfo(this.userEntity, this.viewerRank, this.overallUserValue, this.unit);
            }

            return getCurrentUserLeaderboardInfo(user, this.viewerRank, this.overallUserValue, this.unit);
        }

        get overallUserValue() {
            return _.get(this.props, 'challenge.progress.overallUserValue');
        }

        get unit() {
            return this.props.customPointsUnit;
        }

        get viewerRank() {
            return _.get(this.props, 'challenge.progress.rank');
        }

        get isViewerOutsideTop100() {
            return this.viewerRank > MAX_COUNT_IN_LIST;
        }

        get leaderboardFormatted() {
            const { leaderboard } = this.props;
            const formattedItems = [...leaderboard.items, this.viewerInfo];
            return { ...leaderboard, items: formattedItems };
        }

        get challengeType() {
            return _.get(this.props, 'challenge.challengeSubentityType');
        }

        get userEntity() {
            return _.get(this.props, 'challenge.userEntity');
        }

        get leaderboard() {
            const { leaderboard } = this.props;
            return this.isViewerOutsideTop100 ? this.leaderboardFormatted : leaderboard;
        }

        get leaderboardItemsCount() {
            return _.get(this.props, 'leaderboard.items.length', 0);
        }

        isCurrentUser = item => item.itemEntityId === this.props.challenge.userEntity.id.toString();

        toggleLeaderboardView = () => this.setState(state => ({ isViewMe: !state.isViewMe }));

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    leaderboard={this.leaderboard}
                    isCurrentUser={this.isCurrentUser}
                    toggleLeaderboardView={this.toggleLeaderboardView}
                    isViewMe={this.state.isViewMe}
                    text={this.text}
                    nothingToShowText={this.nothingToShowText}
                    leaderboardItemsCount={this.leaderboardItemsCount}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => ({
        leaderboard: selectors.getChallengeLeaderboard(state, ownProps.challengeId),
        challenge: selectors.getChallenge(state, ownProps.challengeId),
        user: coreSelectors.getCurrentUser(state),
        customPointsUnit: coreSelectors.getCustomPointsUnit(state),
    });

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(LeaderboardBase));
}

export const styles = {
    buttonContainer: {
        marginTop: spacing.s1
    },
};
