import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { selectors as coreSelectors, translate } from '../../../core';
import { selectors as authSelectors, actions as authActions } from '../../../auth';
import { LIST_ITEM_TYPES } from '../../constants';

export default function WithLegalsBase(WrappedComponent) {
    class LegalsBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            legalType: PropTypes.string,
            user: PropTypes.object,
            currentUser: PropTypes.object,
            settings: PropTypes.object,
            isLoading: PropTypes.bool,
            termsOfService: PropTypes.object,
            privacyPolicy: PropTypes.object,
            hasBottomBackButton: PropTypes.bool,
            hasTopBackButton: PropTypes.bool
        };

        static defaultProps = {
            user: {},
            currentUser: {},
            settings: {},
            legalType: '',
            isLoading: false,
            termsOfService: {},
            privacyPolicy: {},
            hasBottomBackButton: false,
            hasTopBackButton: false
        };

        constructor(props) {
            super(props);
            this.getPolicies();
        }

        get disclaimerText() {
            const content = this.props.legalType === LIST_ITEM_TYPES.policy ?
                this.props.privacyPolicy.content :
                this.props.termsOfService.content;
            return content;
        }

        get title() {
            const { legalType, i18n } = this.props;
            return legalType === LIST_ITEM_TYPES.terms ? i18n.t('auth.signUp.termsOfService') : i18n.t('auth.signUp.privacyPolicy');
        }

        getPolicies = () => {
            const params = (this.props.currentUser.companyId) ? { companyId: this.props.currentUser.companyId } :
                { partnerId: this.props.currentUser.partnerId, isRetail: true };

            if (this.props.legalType === LIST_ITEM_TYPES.terms) {
                this.props.actions.getTermsOfService(params);
            }
            if (this.props.legalType === LIST_ITEM_TYPES.policy) {
                this.props.actions.getPrivacyPolicy(params);
            }
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    disclaimerText={this.disclaimerText}
                    title={this.title}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => ({
        legalType: ownProps.legalType || _.get(ownProps, 'match.params.type') || _.get(ownProps, 'route.params.legalType'),
        hasBottomBackButton: ownProps.hasBottomBackButton || _.get(ownProps, 'match.params.hasBottomBackButton') || _.get(ownProps, 'route.params.hasBottomBackButton'),
        buttonLabel: ownProps.buttonLabel || _.get(ownProps, 'match.params.buttonLabel') || _.get(ownProps, 'route.params.buttonLabel'),
        hasTopBackButton: ownProps.hasTopBackButton || _.get(ownProps, 'match.params.hasTopBackButton') || _.get(ownProps, 'route.params.hasTopBackButton'),
        termsOfService: authSelectors.getTermsOfService(state),
        privacyPolicy: authSelectors.getPrivacyPolicy(state),
        isLoading: authSelectors.isLoadingLegals(state),
        currentUser: coreSelectors.getCurrentUser(state)
    });

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(authActions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(LegalsBase));
}
