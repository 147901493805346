import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { translate } from '../../../core';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import { MAX_COUNT, TYPES } from '../../constants';
import { appFonts, spacing } from '../../../../styles';

export default function WithProfileCommunitiesBase(WrappedComponent) {
    class ProfileCommunitiesBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            communities: PropTypes.array,
            i18n: PropTypes.object.isRequired,
            numAttended: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        };

        static defaultProps = {
            communities: [],
            numAttended: null
        };

        constructor(props) {
            super(props);
            this.props.actions.getCommunities({ filter: TYPES.ATTENDED, limit: MAX_COUNT, start: 0 }, true);
        }

        get hasCommunities() {
            return !!this.props.communities.length;
        }

        get title() { return this.props.i18n.t('myCommunities'); }
        get discoverMoreButtonLabel() { return this.props.i18n.t('discoverMoreCommunities'); }
        get promptJoinCommunities() { return this.props.i18n.t('promptJoinCommunities'); }
        get exploreCommunitiesButtonLabel() { return this.props.i18n.t('exploreCommunities'); }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    hasCommunities={this.hasCommunities}
                    title={this.title}
                    discoverMoreButtonLabel={this.discoverMoreButtonLabel}
                    promptJoinCommunities={this.promptJoinCommunities}
                    exploreCommunitiesButtonLabel={this.exploreCommunitiesButtonLabel}
                />
            );
        }
    }

    const mapStateToProps = state => ({
        communities: selectors.getMyCommunities(state, true),
        numAttended: selectors.numAttended(state)
    });

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(ProfileCommunitiesBase));
}

export const styles = {
    carouselEmptyContainer: {
        marginLeft: spacing.s3,
        marginRight: spacing.s3,
        marginTop: spacing.s1,
        marginBottom: spacing.s1
    },
    promptJoinCommunities: {
        marginTop: spacing.s3,
        marginBottom: spacing.s3
    },
    carouselEmptyTitle: {
        ...appFonts.lgBold,
        marginBottom: spacing.s1
    }
};