import 'moment/min/moment-with-locales';
import { parseDomain, fromUrl } from 'parse-domain';
import _ from 'lodash';
import I18n from '../../i18n/i18n';
import { BUILDS, appNames, appStoreAppNames, appStoreUrls } from './buildVariantBase';
import global from '../globals';
import { getApiUrlByProgramName, getLoginApiUrlByProgramName, getApiUrlByProgramNameAndEnv, getLoginApiUrlByProgramNameAndEnv,
    getTelusCAApiUrlByProgramName, getTelusCALoginApiUrlByProgramName,
    getTelusCAApiUrlByProgramNameAndEnv, getTelusCALoginApiUrlByProgramNameAndEnv,
    getMedibankAUApiUrlByProgramName, getMedibankAULoginApiUrlByProgramName,
    getMedibankAUApiUrlByProgramNameAndEnv, getMedibankAULoginApiUrlByProgramNameAndEnv } from '../apiUrls';

export { BUILDS };

const getSubDomainAndVariant = (url = window.location.hostname) => {
    // e.g. 'programname.dev.au.sproutatwork.com'
    // e.g. 'company.wellbeing.telushealth.com'
    // e.g. 'company.test.wellbeing.telushealth.com'
    // e.g. 'company.bien-etre.telussante.com'

    // hardcode subdomain if you need to use other server on web
    // const subdomain = 'optime.dev';
    const parsedDomain = parseDomain(fromUrl(url));
    let buildVariant = BUILDS.sprout;

    if (parsedDomain.hostname.endsWith('.wellbeing.telushealth.com') || parsedDomain.hostname.endsWith('.bien-etre.telussante.com')) {
        // Don't need the 'wellbeing' or 'bien-etre' part
        parsedDomain.subDomains = _.dropRight(parsedDomain.subDomains, 1);
        buildVariant = BUILDS.telus;
    }
    const subdomain = _.join(_.get(parsedDomain, 'subDomains', []), '.');
    const programName = subdomain.length > 0 ? parsedDomain.subDomains[0] : '';
    if (url.endsWith('.au') || url.includes('.au.')) {
        I18n.changeLanguage('en-AU');
        buildVariant = BUILDS.mylivebetter;
    }
    if (subdomain.length) {
        const subdomainsLength = parsedDomain.subDomains.length;
        if (subdomainsLength > 1) {
            const currentEnv = subdomain.substr(subdomain.indexOf('.') + 1);
            if (parsedDomain.domain === 'medibank') {
                // It is the medibank.com.au environment
                global.systemEnvironment = {
                    environment: _.split(subdomain, '.')[1],
                    SPROUT_LOGIN_URL: getMedibankAULoginApiUrlByProgramNameAndEnv(programName, currentEnv),
                    SPROUT_BASE_URL: getMedibankAUApiUrlByProgramNameAndEnv(programName, currentEnv),
                    subdomain,
                    domain: parsedDomain.domain
                };
                buildVariant = BUILDS.mylivebetter;
            }
            else if ((parsedDomain.domain === 'telushealth') || (parsedDomain.domain === 'telussante')) {
                // It is the telus.com environment
                global.systemEnvironment = {
                    environment: _.split(subdomain, '.')[1],
                    SPROUT_LOGIN_URL: getTelusCALoginApiUrlByProgramNameAndEnv(programName, currentEnv),
                    SPROUT_BASE_URL: getTelusCAApiUrlByProgramNameAndEnv(programName, currentEnv),
                    subdomain,
                    domain: parsedDomain.domain
                };
                if (parsedDomain.domain === 'telussante') {
                    I18n.changeLanguage('fr-CA');
                }
                buildVariant = BUILDS.telus;
            }
            else {
                global.systemEnvironment = {
                    environment: _.split(subdomain, '.')[1],
                    SPROUT_LOGIN_URL: getLoginApiUrlByProgramNameAndEnv(programName, currentEnv),
                    SPROUT_BASE_URL: getApiUrlByProgramNameAndEnv(programName, currentEnv),
                    subdomain,
                    domain: parsedDomain.domain
                };
            }
        }
        else if (parsedDomain.domain === 'medibank') {
            // Medibank Live
            global.systemEnvironment = {
                environment: 'live',
                SPROUT_LOGIN_URL: getMedibankAULoginApiUrlByProgramName(programName),
                SPROUT_BASE_URL: getMedibankAUApiUrlByProgramName(programName),
                subdomain,
                domain: parsedDomain.domain
            };
            buildVariant = BUILDS.mylivebetter;
        }
        else if ((parsedDomain.domain === 'telushealth') || (parsedDomain.domain === 'telussante')) {
            // Telus
            global.systemEnvironment = {
                environment: 'live',
                SPROUT_LOGIN_URL: getTelusCALoginApiUrlByProgramName(programName),
                SPROUT_BASE_URL: getTelusCAApiUrlByProgramName(programName),
                subdomain,
                domain: parsedDomain.domain
            };
            if (parsedDomain.domain === 'telussante') {
                I18n.changeLanguage('fr-CA');
            }
            buildVariant = BUILDS.telus;
        }
        else {
            global.systemEnvironment = {
                environment: 'live',
                SPROUT_LOGIN_URL: getLoginApiUrlByProgramName(programName),
                SPROUT_BASE_URL: getApiUrlByProgramName(programName),
                subdomain,
                domain: parsedDomain.domain
            };
        }
    }
    return [programName, buildVariant];
};

export const [partnerSubdomain, systemBuildVariant] = getSubDomainAndVariant();

export const appName = appNames[systemBuildVariant];
export const appStoreName = appStoreAppNames[systemBuildVariant];

if (systemBuildVariant === 'mylivebetter') {
    I18n.defaultLocale = 'en-AU';
}

export const rememberMeEnabled = false;

export const appStoreUrl = {
    android: appStoreUrls.android[systemBuildVariant],
    ios: appStoreUrls.ios[systemBuildVariant],
    huawei: appStoreUrls.huawei[systemBuildVariant]
};

export const pushNotificationsConfig = null;

// Your web app's Firebase configuration
export const firebaseConfig = (systemBuildVariant === BUILDS.telus) ? {
    apiKey: 'AIzaSyAaab2rQItD5IulNHJnM3LCVB3eZBWocPg',
    authDomain: 'telushealthwellness.firebaseapp.com',
    projectId: 'telushealthwellness',
    storageBucket: 'telushealthwellness.appspot.com',
    messagingSenderId: '825008802882',
    appId: '1:825008802882:web:9be0624c42cdecf2bb71db',
    measurementId: 'G-GWEP8HMWVT'
} : {
    apiKey: 'AIzaSyAOdWpOUr9q6Nsig-E4uwIEeiM0teEPozc',
    authDomain: 'sproutmobile3.firebaseapp.com',
    databaseURL: 'https://sproutmobile3.firebaseio.com',
    projectId: 'sproutmobile3',
    storageBucket: 'sproutmobile3.appspot.com',
    messagingSenderId: '661205309888',
    appId: '1:661205309888:web:654d4f9e9fdb0c8c311025',
    measurementId: 'G-G7SS2JNDWP'
};

export default systemBuildVariant;
