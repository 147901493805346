import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { StyleSheet, css } from 'aphrodite-jss';

import { components as Core } from '../../../../core';
import WithFeedItemImageBase, { styles as baseStyles } from './FeedItemImageBase';
import { components as Challenges } from '../../../../challenges';
import { imageSize, spacing } from '../../../../../styles';


class FeedItemImage extends PureComponent {
    static propTypes = {
        isChallenge: PropTypes.bool,
        challengeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        entityImageSource: PropTypes.string.isRequired,
        stream: PropTypes.object.isRequired,
        alt: PropTypes.string,
        className: PropTypes.object
    };

    static defaultProps = {
        isChallenge: false,
        alt: '',
        className: null,
        challengeId: null
    };

    render() {
        const { isChallenge, challengeId, entityImageSource, stream } = this.props;
        return isChallenge ? (
            <Challenges.ChallengeImage
                challengeId={challengeId}
                image={entityImageSource}
                itemDetails={stream.relatedItemDetails}
                isCarouselItem={true}
                imageClassName={css(styles.exploreHeightImage)}
                resizeMode="cover"
                imageHeight={imageSize.lg}
                imageWidth='100%'
            />
        ) : (
            <div style={{ height: 200 }}>
                <Core.Image
                    src={entityImageSource}
                    resizeMode="cover"
                    isFadeIn={true}
                    alt={this.props.alt}
                    className={classnames(this.props.className, css(styles.exploreHeightImage))}
                />
            </div>);
    }
}

export default WithFeedItemImageBase(FeedItemImage);

export const styles = StyleSheet.create({
    ...baseStyles,
    exploreHeightImage: {
        ...baseStyles.exploreHeightImage,
        borderTopLeftRadius: spacing.s1,
        borderTopRightRadius: spacing.s1
    }
});

