import appFonts from './appFonts/index';

const elements = {
    header1: { ...appFonts.xxxlBold },
    header2: { ...appFonts.xlBold },
    header3: { ...appFonts.lgBold },
    header4: { ...appFonts.smBold }
};

export default elements;
