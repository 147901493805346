import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React, { PureComponent } from 'react';
import { actions as meActions } from '../../../settings';
import { getAppVersion, translate } from '../../../core';
import frowningFace from '../../../../image/feedback_smileys/Frowning_face.png';
import neutralFace from '../../../../image/feedback_smileys/Neutral_face.png';
import grinningFace from '../../../../image/feedback_smileys/Grinning_face.png';
import { appFonts, baseColors, spacing } from '../../../../styles';


export default function UserFeedbackWidgetBase(WrappedComponent) {
    class UserFeedbackWidgetBase extends PureComponent {
        constructor(props) {
            super(props);

            this.state = {
                isEmojiSectionVisible: true,
                isCommentSectionVisible: false,
                isThankYouSectionVisible: false,
                userComment: '',
                emojis: {
                    frowningFace: {
                        accessibilityTag: 'Sad face',
                        src: frowningFace,
                        rating: '1'
                    },
                    neutralFace: {
                        accessibilityTag: 'Okay face',
                        src: neutralFace,
                        rating: '3'
                    },
                    grinningFace: {
                        accessibilityTag: 'Happy Face',
                        src: grinningFace,
                        rating: '5'
                    }
                },
                chosenRating: ''
            };
        }

        setNewUserComment = newComment => {
            this.setState({ userComment: newComment });
        }

        setChosenRating = newRating => {
            this.setState({ chosenRating: newRating, isCommentSectionVisible: true });
        }

        submitForm = deviceModel => {
            const { actions, isVideo, contentItem } = this.props;
            const { chosenRating, userComment } = this.state;
            const appVersion = getAppVersion();

            const params = {
                rating: chosenRating,
                feedback_slug: 'content_item_feedback',
                comments: userComment,
                app_version: appVersion,
                device: deviceModel,
                metadata: '',
                contentId: contentItem.id,
                linkId: contentItem.link_id,
                contentTitle: contentItem.title,
                contentTags: contentItem.tagsArr,
                isVideo,
            };

            actions.sendUserFeedback(params);
            this.setState({
                isCommentSectionVisible: false,
                isEmojiSectionVisible: false,
                isThankYouSectionVisible: true,
            });
        }

        get submitBtnText() {
            return this.props.i18n.t('submit');
        }

        get thankYouText() {
            return this.props.i18n.t('thanksForFeedback');
        }

        get titleQuestionText() {
            const { isVideo, i18n } = this.props;
            return i18n.t(isVideo ? 'didYouLikeVideo' : 'didYouLikeArticle');
        }

        get commentSectionLabel() {
            return this.props.i18n.t('tellUsMore');
        }

        render() {

            return (
                <WrappedComponent
                    {...this.props}
                    isEmojiSectionVisible={this.state.isEmojiSectionVisible}
                    isThankYouSectionVisible={this.state.isThankYouSectionVisible}
                    isCommentSectionVisible={this.state.isCommentSectionVisible}
                    setNewUserComment={this.setNewUserComment}
                    userComment={this.state.userComment}
                    emojis={this.state.emojis}
                    chosenRating={this.state.chosenRating}
                    setChosenRating={this.setChosenRating}
                    submitForm={this.submitForm}
                    titleQuestionText={this.titleQuestionText}
                    thankYouText={this.thankYouText}
                    submitBtnText={this.submitBtnText}
                    commentSectionLabel={this.commentSectionLabel}
                    closeBtnText={this.closeBtnText}
                />
            );
        }
    }
    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(meActions, dispatch)
        };
    }

    return connect(null, mapDispatchToProps)((translate()(UserFeedbackWidgetBase)));
}

export const styles = {
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: baseColors.grey90,
        borderRadius: spacing.s1,
        marginTop: 0,
        marginBottom: 0
    },
    title: {
        color: baseColors.grey40,
        ...appFonts.mdRegular,
        paddingTop: spacing.s1,
        paddingBottom: spacing.s1
    },
    emojiWrapper: {
        height: spacing.s9,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    emojiBlock: {
        width: spacing.s9,
        height: spacing.s9,
        borderRadius: spacing.s5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    emoji: {
        width: spacing.s7 - 2,
        height: spacing.s7 - 2
    },
    spaceAroundCenterEmoji: {
        marginLeft: spacing.s1,
        marginRight: spacing.s1
    },
};
