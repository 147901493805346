import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ActivityTrackingItem, { ACTIVITY_STATUSES } from '../ActivityTrackingItem';

export { ACTIVITY_STATUSES };

export default class ActivityList extends PureComponent {
    static propTypes = {
        status: PropTypes.oneOf(_.values(ACTIVITY_STATUSES)),
        activities: PropTypes.array.isRequired,
        onItemClick: PropTypes.func.isRequired,
        onItemDelete: PropTypes.func.isRequired,
        isAnimated: PropTypes.func.isRequired,
        addedActivities: PropTypes.array,
    };

    static defaultProps = {
        status: ACTIVITY_STATUSES.tracked,
        addedActivities: null,
    };

    renderActivity = ({ item }) => {
        const { onItemClick, onItemDelete, status, isAnimated, addedActivities } = this.props;
        const isSelected = _.some(addedActivities, item);
        return (
            <ActivityTrackingItem
                isSelected={isSelected}
                activity={item}
                onClick={onItemClick}
                onDelete={onItemDelete}
                status={status}
                isAnimated={isAnimated && isAnimated(item)}
            />
        );
    };

    keyExtractor = item => `${_.get(item, 'activityId')}_${_.get(item, 'activityUnitId')}`;

    render() {
        const { activities } = this.props;
        return _.map(activities, item => this.renderActivity({ item }));
    }
}
