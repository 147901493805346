import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';

import GoalIcon from '../../GoalIcon';
import GoalCardButton from '../GoalCardButton';
import WeeklyGoalTag from '../../WeeklyGoalTag';
import BonusChallengeMark from '../../BonusChallengeMark';
import { components as Core } from '../../../../core';
import WithGoalItemBase, { styles as baseStyles, ICON_SIZE, FADE_DURATION } from './GoalItemBase';
import { importantClass, important, spacing, baseColors, layoutStyle, appFonts } from '../../../../../styles';

const BACKGROUND_LINEAR_GRADIENT = 'linear-gradient(180deg, rgba(255, 255, 255, 0.2), rgba(32, 175, 255, 0.13))';
class GoalItem extends PureComponent {
    static propTypes = {
        goal: PropTypes.object.isRequired,
        buttonPress: PropTypes.func.isRequired,
        saveRef: PropTypes.func.isRequired,
        challengeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        onboardingNewDesign: PropTypes.bool,
        bonusTextNew: PropTypes.string,
        checkboxType: PropTypes.string,
        goalCardPress: PropTypes.func,
        checked: PropTypes.bool,
        hasBorder: PropTypes.bool,
        isRestartGoal: PropTypes.bool,
        isSuccessState: PropTypes.bool,
        allSetText: PropTypes.string.isRequired,
        successLabel: PropTypes.string,
        isFirstItem: PropTypes.bool.isRequired,
        isSetButtonDisabled: PropTypes.bool.isRequired,
        isSmartModeGoal: PropTypes.bool.isRequired,
        smartGoalDescriptionText: PropTypes.string.isRequired
    };

    static defaultProps = {
        onboardingNewDesign: false,
        bonusTextNew: null,
        checkboxType: Core.Checkbox.TYPES.rounded,
        goalCardPress: null,
        checked: false,
        hasBorder: false,
        isRestartGoal: false,
        isSuccessState: false,
        successLabel: null,
    };

    state = {
        height: 0,
    }

    componentDidMount() {
        const height = this.card.clientHeight;
        this.setState({ height });
    }

    get customIcon() {
        return <div className={css(styles.customIconStyle)} />;
    }

    get goalIcon() {
        const { goal } = this.props;
        return <GoalIcon goal={goal} slug={goal.categorySlug} size={ICON_SIZE} onPersonalGoalCard={true} />;
    }

    get headerSection() {
        const { goal, onboardingNewDesign, checkboxType, checked, challengeId, isRestartGoal } = this.props;
        return onboardingNewDesign ? (
            <div className={css(layoutStyle.flex)}>
                <div className={css(layoutStyle.flexColumn, isRestartGoal ? null : styles.topMargin)}>
                    <p className={css(styles.goalTitleNew)}>{goal.goalName}</p>
                    <WeeklyGoalTag goalName={goal.goalName} />
                </div>
                <Core.Checkbox
                    className={css(styles.checkboxWrapper)}
                    color={baseColors.secondary}
                    type={checkboxType}
                    checked={checked}
                    customIcon={!checked ? this.customIcon : null}
                />
            </div>
        ) : (
            <Fragment>
                <p className={css(styles.goalTitle)}>{goal.goalName}</p>
                <WeeklyGoalTag challengeId={challengeId} containerClassName={css(styles.weeklyTagWrapper)} />
            </Fragment>
        );
    }

    get successCard() {
        const { goal, allSetText, buttonPress, challengeId, successLabel, isFirstItem, isSuccessState } = this.props;
        return (
            <Core.FadeInFadeOut in={isSuccessState} timeout={FADE_DURATION}>
                <div
                    className={css(layoutStyle.flexColumnCenter, styles.successCard, isFirstItem && styles.mTop)}
                    style={{ minHeight: this.state.height }}>
                    <div className={css(styles.goalIconBorder)}>{this.goalIcon}</div>
                    <div className={css(styles.successCardTitleWrapper)}>
                        <p className={css(styles.successCardBoldText)}>{goal.goalName}</p>
                        <p className={css(styles.successCardText)}>{allSetText}</p>
                    </div>
                    <GoalCardButton
                        onPress={buttonPress}
                        challengeId={challengeId}
                        size="small"
                        type="text"
                        disableRipple={true}
                        successLabel={successLabel}
                        className={css(styles.undoButton)}
                    />
                </div>
            </Core.FadeInFadeOut>
        );
    }

    get tooltipText() {
        const { isSetButtonDisabled, i18n } = this.props;
        return isSetButtonDisabled ? i18n.t('settingGoals.description') : '';
    }

    saveRef = ref => {
        const { saveRef } = this.props;
        saveRef && saveRef(ref);
        this.card = ref;
    }

    render() {
        const { goal, bonusTextNew, buttonPress, challengeId, onboardingNewDesign,
            goalCardPress, hasBorder, isSuccessState, isSmartModeGoal, smartGoalDescriptionText } = this.props;
        return (
            <div ref={this.saveRef} className={css(layoutStyle.pRelative)}>
                {this.successCard}
                <Core.FadeInFadeOut in={!isSuccessState} timeout={FADE_DURATION}>
                    <Core.TouchableItem
                        onPressSingle={onboardingNewDesign ? goalCardPress : this.openDetails}
                        innerContainerStyle={styles.goalItemContainer}
                        imageInnerComponent={this.goalIcon}
                        imageClassName={css(styles.imageArea)}
                        role="listitem"
                        tabIndex={null}
                        innerContainerClassName={css(onboardingNewDesign ? styles.containerClassNameNew : styles.containerClassName, (hasBorder && !isSmartModeGoal) && styles.border)}
                        containerClassName={css(onboardingNewDesign ? styles.goalContainerNew : styles.goalContainer, isSmartModeGoal ? styles.goalContainerGradient : null)}>
                        <div className={css(styles.infoContainer)}>
                            <div className={css(styles.goalItemTextContainer)}>
                                {this.headerSection}
                                {isSmartModeGoal ? (
                                    <p className={css(onboardingNewDesign ? styles.goalDescriptionNew : styles.smartGoalDescription)} numberOfLines={2}>{smartGoalDescriptionText}</p>
                                ) : null}
                                <p className={css(onboardingNewDesign ? styles.goalDescriptionNew : styles.goalDescription)} numberOfLines={2}>{goal.instruction}</p>
                                <div>
                                    {onboardingNewDesign ? (
                                        <div className={css(styles.tagWrapper)}>
                                            <Core.Tag label={bonusTextNew} className={styles.tagClassName} />
                                        </div>
                                    ) : <BonusChallengeMark goal={goal} />}
                                </div>
                            </div>
                            {onboardingNewDesign ? null : (
                                <div className={css(styles.setGoalButtonContainer, styles.buttonContainerWidth)}>
                                    <Core.CustomTooltip title={this.tooltipText}>
                                        <GoalCardButton
                                            halfOpacityButton={!!this.tooltipText}
                                            disableRipple={!!this.tooltipText}
                                            onPress={buttonPress}
                                            challengeId={challengeId}
                                            size="small"
                                        />
                                    </Core.CustomTooltip>
                                </div>
                            )}
                        </div>
                    </Core.TouchableItem>
                </Core.FadeInFadeOut>
            </div>
        );
    }
}

export default WithGoalItemBase(GoalItem);

const styles = StyleSheet.create({
    ...baseStyles,
    goalContainer: {
        ...baseStyles.goalContainer,
        borderBottomStyle: 'solid',
        cursor: important('auto')
    },
    goalContainerNew: {
        ...baseStyles.goalContainerNew,
        borderRadius: spacing.s1,
        boxShadow: '0px 2px 1px rgba(0, 0, 0, 0.02), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)'
    },
    buttonContainerWidth: {
        display: 'inline-block',
    },
    tagWrapper: {
        marginTop: spacing.s2,
        marginBottom: spacing.s3
    },
    tagClassName: {
        justifyContent: 'start'
    },
    goalDescriptionNew: importantClass({
        ...baseStyles.goalDescriptionNew,
        paddingRight: spacing.s8
    }),
    customIconStyle: {
        ...baseStyles.checkboxSize,
        border: `1px solid ${baseColors.grey70}`,
        borderRadius: '50%'
    },
    checkboxWrapper: {
        ...baseStyles.checkboxSize,
        position: 'absolute',
        top: spacing.s3,
        right: spacing.s1 + 2
    },
    containerClassNameNew: {
        ...baseStyles.containerClassName,
        paddingLeft: spacing.s3,
    },
    containerClassName: {
        ...baseStyles.containerClassName,
        marginLeft: spacing.s3,
        marginRight: spacing.s3,
        marginBottom: -1,
        paddingLeft: 0
    },
    border: { borderBottom: `1px solid ${baseColors.grey80}` },
    imageArea: {
        ...baseStyles.imageArea,
        marginRight: important(spacing.s2),
    },
    topMargin: {
        marginTop: spacing.s1,
    },
    successCard: {
        ...baseStyles.successCard,
        borderBottomStyle: 'solid',
        marginRight: spacing.s3,
        marginLeft: spacing.s3,
    },
    successCardBoldText: {
        ...baseStyles.successCardBoldText,
        ...appFonts.smBold,
    },
    successCardText: {
        ...baseStyles.successCardText,
        ...appFonts.smRegular,
    },
    undoButton: importantClass({
        height: spacing.s3,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    }),
    mTop: {
        marginTop: spacing.s5,
    },
    goalIconBorder: {
        ...baseStyles.goalIconBorder,
        borderStyle: 'solid',
    },
    setGoalButtonContainer: {
        ...baseStyles.setGoalButtonContainer,
        paddingTop: spacing.s3,
    },
    goalContainerGradient: {
        backgroundImage: BACKGROUND_LINEAR_GRADIENT
    }
});
