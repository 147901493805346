import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as selectors from '../../selectors';
import { translate, selectors as coreSelectors } from '../../../core';
import * as actions from '../../actions';

const { getChallengesList, updateNewChallenge, updateCreateChallengeStep } = actions;

export default function WithExploreChallengesBase(WrappedComponent) {
    class ExploreChallengesBase extends PureComponent {
        static propTypes = {
            challenges: PropTypes.array.isRequired,
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired
        };

        constructor(props) {
            super(props);
        }

        get createTitle() {
            return this.props.i18n.t('challengeCarousel.emptyChallenge.prompt');
        }

        get createButtonLabel() {
            return this.props.i18n.t('challengeCarousel.createChallengeButton');
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    createTitle={this.createTitle}
                    createButtonLabel={this.createButtonLabel}
                />
            );
        }
    }

    const mapStateToProps = state => ({
        challenges: selectors.getExploreChallengesCarousel(state),
        isExtendedRole: coreSelectors.isUserExtendedRole(state),
        userId: coreSelectors.getCurrentUserId(state),
        isLoading: selectors.isLoadingExploreChallenges(state)
    });

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators({ getChallengesList, updateNewChallenge, updateCreateChallengeStep }, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(ExploreChallengesBase));
}
