import axios from 'axios';
import { getBaseUrl } from '../core';

const getUrl = endpoint => endpoint ? getBaseUrl(`notifications${endpoint}`) : getBaseUrl('notifications');

export function getNotifications(parameters) {
    const params = { maxCount: 25, ...parameters };
    return axios.get(getUrl('_clicked'), { params });
}

export function markAsClicked(params) {
    return axios.put(getUrl(), params);
}

export function markAllAsRead() {
    return axios.post(getUrl('/read'), {});
}

export function markAllAsClicked() {
    return axios.post(getUrl('/clicked'), {});
}

export function markAllAsSeen() {
    return axios.post(getUrl('/seen'), {});
}

export function getUnreadCount() {
    return axios.get(getUrl('/unread_count'));
}

export function getUnseenCount() {
    return axios.get(getUrl('/unseen_count'));
}

export function registerDeviceForPush(params) {
    return axios.post(getBaseUrl('notifications/registrations'), params);
}

export function unregisterDeviceForPush(deviceId) {
    return axios.delete(getBaseUrl(`notifications/registrations/${deviceId}`));
}
