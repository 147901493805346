import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { appFonts, baseColors, spacing } from '../../../../styles';
import { components as Core, numbers, openUrl, parsers, translate, validation } from '../../../core';
import { NOTIFICATIONS_WITH_DEFAULT_AVATAR } from '../../constants';

export const NOT_ROUNDED_IMAGE_TYPES = ['user_reward_grant'];

export default function WithNotificationItemBase(WrappedComponent) {
    class NotificationItemBase extends PureComponent {
        static propTypes = {
            info: PropTypes.object.isRequired,
            markAsClicked: PropTypes.func.isRequired,
            action: PropTypes.func
        };

        static defaultProps = {
            action: undefined
        };

        constructor(props) {
            super(props);
            this.state = {
                read: props.info.read,
                clicked: props.info.clicked || 0,
                links: []
            };
        }

        static getDerivedStateFromProps(nextProps, prevState) {
            if (nextProps.info.clicked !== prevState.clicked) {
                return { clicked: nextProps.info.clicked };
            }
            return null;
        }

        markAsClicked = () => {
            this.setState(() => ({ read: 1, clicked: 1 }));
            this.props.markAsClicked(this.props.info);
        };

        onNotificationPress = () => {
            const { template } = this.props.info.notificationMessage || {};
            if (!this.state.clicked) {
                this.markAsClicked();
            }
            if (this.props.action) this.props.action(this.props.info);
            const urlFromText = validation.hasUrlInStr(template);
            const validatedLink = urlFromText ? urlFromText[0] : '';
            return validatedLink ? openUrl(validatedLink) : null;
        };

        getFormattedDate = date => moment(date).fromNow();

        get text() {
            const { notificationMessage, notificationType } = this.props.info || {};
            const { template, values } = notificationMessage || {};
            const valuesCoppy = { ...values };
            if (valuesCoppy.new_target_amount) {
                valuesCoppy.new_target_amount = numbers.numberWithCommas(valuesCoppy.new_target_amount);
            }
            const result = template && parsers.parseNotificationMessage(template, valuesCoppy, Core.Text, notificationType);
            if (result && result.links.length !== this.state.links.length) {
                this.setState(() => ({ links: result.links }));
            }
            return result && result.fullText;
        }

        get hasBorderRadius() {
            const { info: { notificationType, imageUrl } } = this.props;
            return !!(!_.includes(NOT_ROUNDED_IMAGE_TYPES, notificationType) && imageUrl) || _.includes(NOTIFICATIONS_WITH_DEFAULT_AVATAR, notificationType);
        }

        get read() {
            return !!this.state.read;
        }

        get clicked() {
            return !!this.state.clicked;
        }

        get notificationImage() {
            const { info: { notificationType, imageUrl, notificationIcon } } = this.props;
            if (NOTIFICATIONS_WITH_DEFAULT_AVATAR.includes(notificationType)) return imageUrl || null;
            return imageUrl || notificationIcon;
        }

        get notificationUserName() {
            const { info } = this.props;
            return _.get(info, 'notificationMessage.values.userString');
        }

        get isImageSvg() {
            return this.notificationImage ? this.notificationImage.includes('.svg') : null;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    read={this.read}
                    text={this.text}
                    clicked={this.clicked}
                    isImageSvg={this.isImageSvg}
                    hasBorderRadius={this.hasBorderRadius}
                    getFormattedDate={this.getFormattedDate}
                    notificationImage={this.notificationImage}
                    onNotificationPress={this.onNotificationPress}
                    notificationUserName={this.notificationUserName}
                />
            );
        }
    }

    return translate()(NotificationItemBase);
}

export const IMAGE_SIZE = 50;
const CIRCLE_SIZE = spacing.s2;
const BORDER_WIDTH = 0.5;

export const styles = {
    cardContainer: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center'
    },
    card: {
        flexDirection: 'row',
        flexGrow: 1,
        flex: 1,
        alignItems: 'center',
        paddingBottom: spacing.s2,
    },
    circle: {
        width: CIRCLE_SIZE,
        height: CIRCLE_SIZE,
        maxWidth: CIRCLE_SIZE,
        borderRadius: CIRCLE_SIZE/2,
        marginRight: spacing.s1,
        marginTop: spacing.s1
    },
    unreadCircle: {
        backgroundColor: baseColors.context
    },
    image: {
        height: IMAGE_SIZE,
        width: IMAGE_SIZE,
        marginRight: spacing.s3
    },
    roundedImage: {
        borderRadius: IMAGE_SIZE/2,
        borderWidth: BORDER_WIDTH,
        borderColor: baseColors.grey80
    },
    cardInner: {
        flex: 1
    },
    text: {
        ...appFonts.smRegular,
        color: baseColors.grey20
    },
    date: {
        paddingTop: spacing.s0,
        ...appFonts.xsRegular,
        color: baseColors.grey40
    },
    openedText: {
        color: baseColors.grey40
    }
};