import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';

import { important } from '../../../../styles';
import ActivityLogList from '../ActivityLogList';
import { components as Core } from '../../../core';
import WithActivityLogBase, { styles as baseStyles } from './ActivityLogBase';

class ActivityLog extends Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
    };


    render() {
        const { title } = this.props;

        return (
            <Core.Layout.WiderContainer>
                <Core.EntityDetailsHeader
                    hasBackButton={true}
                    title={title}
                />
                <Core.SmallerContainer>
                    <Core.SubHeader
                        title={title}
                        noTopPadding={true}
                        titleClassName={css(styles.title)}
                    />
                    <ActivityLogList />
                </Core.SmallerContainer>
            </Core.Layout.WiderContainer>
        );
    }
}

const styles = StyleSheet.create({
    ...baseStyles,
    title: {
        paddingLeft: important(0),
    },
});

export default WithActivityLogBase(ActivityLog);
