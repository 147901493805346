import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { translate, getUserName } from '../../../core';
import { appFonts, baseColors, spacing } from '../../../../styles';

export const REMOVE_ICON_NAME = 'times-circle';

export default function WithAddedUserItem(WrappedComponent) {
    class AddedUserItemBase extends PureComponent {
        static propTypes = {
            removeUserHandler: PropTypes.func,
            item: PropTypes.object.isRequired,
            isUserInvitation: PropTypes.bool
        };

        static defaultProps = {
            removeUserHandler: null,
            isUserInvitation: true
        };

        removeUserHandler = () => {
            this.props.removeUserHandler(this.props.item, true);
        };

        get userName() {
            return this.props.isUserInvitation ? getUserName(this.props.item) : this.props.item.label;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    removeUserHandler={this.removeUserHandler}
                    userName={this.userName}
                />
            );
        }
    }

    return translate()(AddedUserItemBase);
}

export const styles = {
    mainContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: spacing.s3,
        paddingTop: spacing.s0,
        paddingBottom: spacing.s0,
        paddingLeft: spacing.s0,
        paddingRight: spacing.s0,
        marginLeft: spacing.s0,
        marginRight: spacing.s0,
        backgroundColor: baseColors.grey85
    },
    textItem: {
        paddingLeft: spacing.s0,
        paddingRight: spacing.s0,
        ...appFonts.xsRegular,
    },
    envelope: {
        marginLeft: spacing.s1
    }
};
