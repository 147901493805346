import { EVENT_ENTITY_TYPES, MAX_COUNT } from '../constants';


export const onRefresh = (actions, type, userId, position = 0, isReplace = true) => {
    switch (type) {
        case (EVENT_ENTITY_TYPES.MY_ITEMS):
        case (EVENT_ENTITY_TYPES.USER):
            actions.getUpcomingEvents(userId, type, isReplace);
            break;
        case (EVENT_ENTITY_TYPES.ALL_ITEMS):
            actions.getAllEvents({ maxCount: MAX_COUNT, offset: position }, EVENT_ENTITY_TYPES.ALL_ITEMS, isReplace);
            break;
        case (EVENT_ENTITY_TYPES.PAST):
            actions.getAllEvents({
                maxCount: MAX_COUNT,
                offset: position,
                pastAttended: 1
            }, EVENT_ENTITY_TYPES.PAST, isReplace);
            break;
        case (EVENT_ENTITY_TYPES.INVITED):
            actions.getAllEvents({
                maxCount: MAX_COUNT,
                offset: position,
                invited: 1
            }, EVENT_ENTITY_TYPES.INVITED, isReplace);
            break;
        case (EVENT_ENTITY_TYPES.RECENT):
            actions.getAllEvents({
                maxCount: MAX_COUNT,
                offset: position,
                recent: 1
            }, EVENT_ENTITY_TYPES.RECENT, isReplace);
            break;
        case (EVENT_ENTITY_TYPES.RECOMMENDED):
            actions.getAllEvents({
                maxCount: MAX_COUNT,
                offset: position,
                recommended: 1
            }, EVENT_ENTITY_TYPES.RECOMMENDED, isReplace);
            break;
        default:
            break;
    }
};