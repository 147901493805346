import { combineReducers } from 'redux';
import _ from 'lodash';
import { persistReducer } from 'redux-persist';
import * as types from './actionTypes';
import { CHILD_NAMES } from './constants';
import { Storage, tracker } from './services';
import build, { rememberMeEnabled, partnerSubdomain, pushNotificationsConfig } from '../../config/buildVariant';
import { isWiderScreen } from '../../styles';

export const loadingInitialState = {};
export const loadingReducer = (state = loadingInitialState, action) => {
    const { type } = action;
    if (type === types.RESET) return loadingInitialState;
    const matches = /(.*)_(REQUEST|SUCCESS|ERROR)/.exec(type);

    // not a *_REQUEST/*_SUCCESS/*_ERROR actions, so we ignore them
    if (!matches) return state;

    const [, requestName, requestState] = matches;
    const prop = _.get(action, 'payload.requestId') || requestName;

    return {
        ...state,
        [prop]: requestState === 'REQUEST'
    };
};

export const errorInitialState = {};
export const errorReducer = (state = errorInitialState, action) => {
    switch (action.type) {
        case types.ADD_ERROR: {
            const { requestId, error } = action.payload;
            return { ...state, [requestId]: error };
        }
        case types.REMOVE_ERROR: {
            const { requestId } = action.payload;
            return _.omit(state, requestId);
        }
        case types.RESET: {
            return errorInitialState;
        }
        default:
            return state;
    }
};

export const toastInitialState = [];
export const toastReducer = (state = toastInitialState, action) => {
    switch (action.type) {
        case types.ADD_TOAST: {
            const { type, title, message } = action.payload;
            return [{ type, title, message }, ...state];
        }
        case types.REMOVE_TOAST: {
            return _.drop(state);
        }
        case types.RESET: {
            return toastInitialState;
        }
        default:
            return state;
    }
};

export const generalInitialState = {
    filters: {},
    current_user: {},
    preview: {},
    build,
    rememberMeEnabled,
    partnerSubdomain,
    pushNotificationsConfig,
    isWiderScreen: isWiderScreen(),
    lastRatingRequestDate: null,
    initTime: null
};
export const generalReducer = (state = generalInitialState, action) => {
    switch (action.type) {
        case types.GET_FILTERS.SUCCESS: {
            return {
                ...state,
                filters: action.payload.filters
            };
        }
        case types.RESET: {
            return {
                ...generalInitialState,
                lastRatingRequestDate: state.lastRatingRequestDate
            };
        }
        case types.GET_CURRENT_USER.SUCCESS:
            return {
                ...state,
                current_user: { ...state.current_user, ...action.payload.data }
            };
        case types.GET_URL_PREVIEW: {
            const { entityType, preview } = action.payload;
            const value = preview ? { ...state.preview, [entityType]: preview } : _.omit(state.preview, entityType);
            return { ...state, preview: value };
        }
        case types.UPDATE_CONNECTION_STATE: {
            const { isOnline } = action.payload;
            return { ...state, isOnline };
        }
        case types.CHANGE_LANGUAGE:
            return { ...state, current_user: { ...state.current_user, language: action.payload.language } };
        case types.CHANGE_SCREEN_SIZE:
            return { ...state, isWiderScreen: isWiderScreen() };
        case types.REQUEST_RATING.SUCCESS:
            return { ...state, lastRatingRequestDate: action.payload.lastRatingRequestDate };
        case types.SET_INIT_TIME:
            return { ...state, initTime: action.payload.time };
        case types.SAVE_SMART_MODE_STATUS:
            return {
                ...state,
                current_user: { ...state.current_user, isSmartModeEnabled: action.payload.data }
            };
        default:
            return state;
    }
};

const serialize = data => {
    try {
        return JSON.stringify(data);
    } catch (err) {
        const message = 'Failed to serialize core data';
        const serializeError = JSON.stringify({
            message,
            err
        });
        tracker.logError(serializeError);
        return JSON.stringify({});
    }
};

const generalPersistConfig = {
    key: CHILD_NAMES.general,
    storage: Storage.storageType(),
    whitelist: ['current_user', 'filters'],
    serialize
};

export default combineReducers({
    [CHILD_NAMES.loading]: loadingReducer,
    [CHILD_NAMES.error]: errorReducer,
    [CHILD_NAMES.toasts]: toastReducer,
    [CHILD_NAMES.general]: persistReducer(generalPersistConfig, generalReducer)
});
