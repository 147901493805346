import _ from 'lodash';
import * as types from './actionTypes';
import { MAX_RANDOM_ITEMS } from './constants';
import { actionTypes as coreTypes } from '../core';
import { getProductIdsByCategories } from './services/rewardsActions';

export const initialState = {
    isRewardsEnabled: false,
    isBadgesEnabled: false,
    rewards: {
        numEarned: '',
        externalRewardName: '',
        numRedeemable: 0
    },
    redemptionSession: {
        token: '',
        orderId: ''
    },
    products: {
        items: {},
        ids: [],
        featured: [],
        randomIds: [],
        total: 0,
        categories: {}
    },
    rewardsCategories: {
        items: {},
        total: 0
    },
    heros: {
        items: {},
        total: 0
    },
    transactions: {
        items: {},
        ids: [],
        total: 0
    },
    orders: {
        items: {},
        ids: [],
        total: 0
    },
    achievements: {
        items: {},
        ids: [],
        earned: [],
        inProgress: [],
        upcoming: {
            items: {},
            ids: []
        }
    },
    levels: {},
    shippingAddress: {},
    earnPartners: {
        items: {},
        ids: [],
        total: 0
    },
    earnPartnersCategories: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.GET_REWARDS.SUCCESS: {
            const isRewardsEnabled = !_.isEmpty(action.payload.data);
            return {
                ...state,
                isRewardsEnabled,
                rewards: isRewardsEnabled ? action.payload.data : initialState.rewards
            };
        }
        case types.GET_HEROS.SUCCESS: {
            const { items, total } = action.payload;
            const heros = items.splice(0, 1);
            return {
                ...state,
                heros: {
                    items: heros,
                    total
                }
            };
        }
        case types.GET_REWARDS_PRODUCTS.SUCCESS: {
            const { items, ids, featured, isReplace, total, data } = action.payload;
            const randomIds = !state.products.randomIds.length ? _.shuffle(ids).slice(0, MAX_RANDOM_ITEMS) : state.products.randomIds;
            const categories = getProductIdsByCategories(data, { ..._.get(state, 'products.categories', {}) }, isReplace);
            return {
                ...state,
                products: {
                    ...state.products,
                    items: isReplace ? items : { ...state.products.items, ...items },
                    ids: isReplace ? ids : [...state.products.ids, ...ids],
                    featured,
                    randomIds,
                    categories,
                    total
                }
            };
        }
        case types.GET_REWARDS_PRODUCT.SUCCESS: {
            const { data } = action.payload;
            return {
                ...state,
                products: {
                    ...state.products,
                    items: {
                        ...state.products.items,
                        [data.id]: data
                    }
                }
            };
        }
        case types.GET_REWARDS_CATEGORIES.SUCCESS: {
            const { items, total } = action.payload;
            return {
                ...state,
                rewardsCategories: { items, total }
            };
        }
        case types.REDEEM_REWARDS_PRODUCT.SUCCESS: {
            const { data } = action.payload;
            const numRedeemable = state.rewards.numRedeemable - data.pointsRedeemed;
            return {
                ...state,
                rewards: {
                    ...state.rewards,
                    numRedeemable
                },
                redemptionSession: {
                    ...state.redemptionSession,
                    orderId: data.id
                },
                orders: {
                    ...state.orders,
                    ids: [...state.orders.ids, data.id],
                    items: {
                        ...state.orders.items,
                        [data.id]: data
                    }
                }
            };
        }
        case types.GET_REWARDS_TRANSACTIONS.SUCCESS: {
            const { items, ids, isReplace, total } = action.payload;
            return {
                ...state,
                transactions: {
                    ...state.transactions,
                    items: isReplace ? items : { ...state.transactions.items, ...items },
                    ids: isReplace ? ids : [...state.transactions.ids, ...ids],
                    total
                }
            };
        }
        case types.GET_REWARDS_ORDERS.SUCCESS: {
            const { items, ids, isReplace, total } = action.payload;
            return {
                ...state,
                orders: {
                    ...state.orders,
                    items: isReplace ? items : { ...state.orders.items, ...items },
                    ids: isReplace ? ids : [...state.orders.ids, ...ids],
                    total
                }
            };
        }
        case types.GET_REWARDS_ORDER.SUCCESS: {
            const { data } = action.payload;
            return {
                ...state,
                orders: {
                    ...state.orders,
                    items: {
                        ...state.orders.items,
                        [data.id]: data
                    }
                }
            };
        }
        case types.GET_ACHIEVEMENTS.SUCCESS: {
            const { items, ids, earned, inProgress } = action.payload;
            const isBadgesEnabled = !_.isEmpty(action.payload.data);
            return {
                ...state,
                isBadgesEnabled,
                achievements: {
                    ...state.achievements,
                    items,
                    ids,
                    earned,
                    inProgress
                }
            };
        }
        case types.GET_UPCOMING_ACHIEVEMENTS.SUCCESS: {
            const { items, ids } = action.payload;
            return {
                ...state,
                achievements: {
                    ...state.achievements,
                    upcoming: { items, ids }
                }
            };
        }
        case types.GET_LEVELS.SUCCESS: {
            const { levels } = action.payload;
            return { ...state, levels };
        }
        case types.GET_REWARD_REDEMPTION_TOKEN.SUCCESS: {
            const { token } = action.payload;
            return {
                ...state,
                redemptionSession: {
                    ...state.redemptionSession,
                    token
                }
            };
        }
        case types.CLEAR_REWARD_REDEMPTION_TOKEN: {
            return {
                ...state,
                redemptionSession: {
                    ...state.redemptionSession,
                    token: ''
                }
            };
        }
        case types.CLEAR_REDEEM_REWARD_ORDER_ID: {
            return {
                ...state,
                redemptionSession: {
                    ...state.redemptionSession,
                    orderId: 0
                }
            };
        }
        case types.GET_SHIPPING_ADDRESS.SUCCESS: {
            return {
                ...state,
                shippingAddress: action.payload.data
            };
        }
        case types.GET_EARNPARTNERS_CATEGORIES.SUCCESS: {
            return {
                ...state,
                earnPartnersCategories: action.payload.data
            };
        }
        case types.GET_EARNPARTNERS_PARTNERS.SUCCESS: {
            const { items, ids, isReplace, total, categories } = action.payload;
            return {
                ...state,
                earnPartners: {
                    ...state.earnPartners,
                    items: isReplace ? items : { ...state.earnPartners.items, ...items },
                    ids: isReplace ? ids : [...state.earnPartners.ids, ...ids],
                    ...categories,
                    total
                }
            };
        }
        case types.GET_EARNPARTNERS_PARTNER.SUCCESS: {
            const { data } = action.payload;
            return {
                ...state,
                earnPartners: {
                    ...state.earnPartners,
                    items: {
                        ...state.earnPartners.items,
                        [data.earnPartnerId]: data
                    }
                }
            };
        }
        case types.CREATE_EARNPARTNERS_LINK.SUCCESS:
        case types.UPDATE_EARNPARTNERS_LINK.SUCCESS:
        case types.REMOVE_EARNPARTNERS_LINK.SUCCESS: {
            const data = action.payload.data;
            return {
                ...state,
                earnPartnerStatus: data
            };
        }
        case coreTypes.RESET: {
            return initialState;
        }
        default:
            return state;
    }
};
