import React, { Component } from 'react';
import Nprogress from 'nprogress';
import _ from 'lodash';
import { css } from 'aphrodite-jss';
import PropTypes from 'prop-types';
import 'nprogress/nprogress.css';
import { WiderContainer } from '../components/Layout';
import { layoutStyle } from '../../../styles';

export default function asyncComponent(importComponent, componentProps = {}) {
    class AsyncFunc extends Component {
        static propTypes = {
            isModal: PropTypes.bool,
            location: PropTypes.object
        };

        static defaultProps = {
            isModal: false,
            location: {}
        };
        constructor(props) {
            super(props);
            this.state = {
                component: null
            };
        }
        componentWillMount() {
            Nprogress.start();
        }

        componentWillUnmount() {
            this.mounted = false;
        }

        async componentDidMount() {
            this.mounted = true;
            const { default: Component } = await importComponent();
            Nprogress.done();
            if (this.mounted) {
                this.setState({
                    component: <Component {...this.props} {...componentProps} />
                });
            }
        }

        get renderContent() {
            const Component = this.state.component;
            return Component || <div />;
        }

        render() {
            const { hasFullWidthLayout, hasBackground } = componentProps;
            const { isModal } = this.props;
            const isTaskCard = _.get(this.props, 'location.state.isTaskCard');
            const isOnboarding = _.get(this.props, 'location.state.isShowingOnboarding');
            if(isModal) return this.renderContent;
            return (hasFullWidthLayout || isOnboarding ? <div className={hasBackground || isOnboarding ? css(layoutStyle.bgGrey90, layoutStyle.page, layoutStyle.heightAuto, isTaskCard ? layoutStyle.card : null) : null}>{this.renderContent}</div> :
                <WiderContainer>
                    {this.renderContent}
                </WiderContainer>
            );
        }
    }
    return AsyncFunc;
}
