import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { css, StyleSheet } from 'aphrodite-jss';
import { isIE } from 'react-device-detect';

import WithOnboardingInfoScreenBase from './SkipGoButtonsBase';
import { layoutStyle, importantStyles, spacing } from '../../../../styles';
import { components as Core } from '../../../core';
import WithSkipEAPModalsLogic from '../SkipEAPModalsLogic';

class SkipGoButtons extends PureComponent {
    static propTypes = {
        customButtons: PropTypes.func,
        skip: PropTypes.func,
        go: PropTypes.func,
        hasSkipGoBtns: PropTypes.bool,
        screen: PropTypes.object,
        isEAP: PropTypes.bool,
        showInfoModal: PropTypes.func,
        leftButtonLabel: PropTypes.string,
        i18n: PropTypes.object.isRequired,
        rightButtonLabel: PropTypes.string,
        isOnboarding: PropTypes.bool,
        hasSkipButton: PropTypes.bool,
        onboardingNewDesign: PropTypes.bool,
        goalsPageNew: PropTypes.bool,
        goButtonLabel: PropTypes.string.isRequired,
        skipButtonLabel: PropTypes.string.isRequired,
    };

    static defaultProps = {
        screen: {},
        hasSkipGoBtns: false,
        isEAP: false,
        showInfoModal: null,
        skip: null,
        go: null,
        customButtons: null,
        leftButtonLabel: null,
        rightButtonLabel: null,
        isOnboarding: false,
        hasSkipButton: true,
        onboardingNewDesign: false,
        goalsPageNew: false
    };

    skip = () => {
        const { isEAP, skip, isOnboarding } = this.props;
        return isEAP && isOnboarding ? this.props.showInfoModal() : skip();
    };

    skipAndGoButtons = () => {
        const { leftButtonLabel, rightButtonLabel, screen, hasSkipButton, skipButtonLabel,
            goButtonLabel } = this.props;

        const leftButtonId = leftButtonLabel ? _.camelCase(`${leftButtonLabel}Button`) : null;
        const rightButtonId = rightButtonLabel ? _.camelCase(`${rightButtonLabel}Button`) : null;
        return (
            <div className={css(layoutStyle.flexRow)}>
                {!screen.required && hasSkipButton ?
                    <Core.Button
                        id={leftButtonId || 'skipButton'}
                        type="outlined"
                        fullWidth={true}
                        size="large"
                        className={css(layoutStyle.flex1, layoutStyle.twoButtonsLeft)}
                        onPress={this.skip}>
                        {leftButtonLabel || skipButtonLabel}
                    </Core.Button> : null
                }
                <Core.Button
                    id={rightButtonId || goButtonLabel}
                    fullWidth={true}
                    type="contained"
                    className={css(layoutStyle.flex1, layoutStyle.twoButtonsRight)}
                    size="large"
                    onPress={this.props.go}>
                    {this.props.rightButtonLabel || this.props.i18n.t('letsGo')}
                </Core.Button>
            </div>
        );
    };

    get innerContent() {
        return (
            <React.Fragment>
                {this.props.customButtons}
                {this.props.hasSkipGoBtns ? this.skipAndGoButtons() : null}
            </React.Fragment>
        );
    }

    get onBoardingFooter() {
        return (
            <Core.Layout.FixedFooterWiderContainer
                isOnboarding={true}
                isTransparent={true}
                className={css(layoutStyle.noPadding)} fixedFooterClassName={css(this.props.onboardingNewDesign && style.pRelative)}>
                <div className={css(layoutStyle.bgWhite, style.footerPadding)}>
                    {this.innerContent}
                </div>
            </Core.Layout.FixedFooterWiderContainer>
        );
    }

    get footer() {
        return (
            <Core.Layout.FixedFooterWiderContainer
                fixedFooterClassName={css(this.props.goalsPageNew ? style.pRelative : style.nonOnboardingFooter)}
                className={this.props.goalsPageNew ? css(layoutStyle.noPadding, style.buttonsTop) : null}
                itemClassName={this.props.goalsPageNew ? css(style.padding0) : null}
            >
                {this.innerContent}
            </Core.Layout.FixedFooterWiderContainer>
        );
    }

    render() {
        return this.props.customButtons || this.props.hasSkipGoBtns ? (
            <React.Fragment>
                {this.props.isOnboarding && !this.props.goalsPageNew ? this.onBoardingFooter : this.footer}
            </React.Fragment>
        ): null;
    }
}

export default WithSkipEAPModalsLogic(WithOnboardingInfoScreenBase(SkipGoButtons));

const BUTTON_MAX_WIDTH = 300;
const BUTTON_MIN_WIDTH = 150;
const style = StyleSheet.create(importantStyles({
    footerPadding: {
        padding: spacing.s1,
        paddingTop: spacing.s8
    },
    buttonStyle: {
        margin: '0 auto',
        minWidth: BUTTON_MIN_WIDTH,
        maxWidth: BUTTON_MAX_WIDTH
    },
    pRelative: {
        position: 'relative',
    },
    nonOnboardingFooter: {
        position: isIE ? 'absolute' : 'relative',
        padding: spacing.s3,
        paddingBottom: spacing.s0,
    },
    buttonsTop: {
        marginTop: spacing.s1
    },
    padding0: {
        padding: 0
    }
}));
