import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite-jss';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import * as actions from '../../actions';
import { getToasts } from '../../selectors';
import { getToastMessage } from '../../services';
import { baseColors, spacing, appFonts, importantStyles } from '../../../../styles';
import { TOAST_TYPES, TOAST_LIVE_TIME } from '../../constants';
import Icon, { IconButton } from '../Icon';

const variantIcon = {
    [TOAST_TYPES.SUCCESS]: 'CheckCircle',
    [TOAST_TYPES.DANGER]: 'Error',
    [TOAST_TYPES.INFO]: 'InfoOutlined',
};

const SHOW_NEXT_TIMEOUT = 200;

class GlobalToast extends PureComponent {
    static propTypes = {
        actions: PropTypes.object.isRequired,
        toasts: PropTypes.array // eslint-disable-line
    };

    state = { open: false };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.toasts[0]) {
            this._processToast(nextProps.toasts[0]);
        }
    }

    close = (event, reason) => {
        if (reason !== 'clickaway') {
            this.setState({ open: false });
        }
    };

    _processToast(toast) {
        this.props.actions.removeToast();
        if (this.state.open) {
            this.setState({ open: false }, () => {
                setTimeout(() => this._showToast(toast), SHOW_NEXT_TIMEOUT);
            });
        } else {
            this._showToast(toast);
        }
    }

    _showToast({ title, message, type }) {
        const toastMessage = getToastMessage(message);
        this.setState({ open: true, title, message: toastMessage, type });
    }

    render() {
        const { type, open, title, message } = this.state;
        const icon = variantIcon[type] || variantIcon[TOAST_TYPES.INFO];
        return (
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                open={open}
                autoHideDuration={TOAST_LIVE_TIME * 1000}
                onClose={this.close}>
                <SnackbarContent
                    className={css(styles[type])}
                    message={
                        <span className={css(styles.message, styles[`${type}Text`])}>
                            <Icon className={css(styles.icon, styles.iconVariant)} name={icon} />
                            <div>
                                <p className={css(styles.title)}>{title}</p>
                                <p className={css(styles.message)}>{message}</p>
                            </div>
                        </span>
                    }
                    action={[
                        <IconButton
                            key="close"
                            color="inherit"
                            className={css(styles.close, styles[`${type}Text`])}
                            onClick={this.close}
                            name="times"
                            type="fa"
                        />
                    ]}
                />
            </Snackbar>
        );
    }
}

function mapStateToProps(state) {
    return { toasts: getToasts(state) };
}

function mapDispatchToProps(dispatch) {
    return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(GlobalToast);


const styles = StyleSheet.create(importantStyles({
    [TOAST_TYPES.SUCCESS]: { backgroundColor: baseColors.successLightest },
    [TOAST_TYPES.DANGER]: { backgroundColor: baseColors.dangerLightest },
    [TOAST_TYPES.INFO]: { backgroundColor: baseColors.warnLightest },
    [`${[TOAST_TYPES.SUCCESS]}Text`]: { color: baseColors.successDarkest },
    [`${[TOAST_TYPES.DANGER]}Text`]: { color: baseColors.dangerDarkest },
    [`${[TOAST_TYPES.INFO]}Text`]: { color: baseColors.warnDarkest },
    icon: {
        fontSize: spacing.s5,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: spacing.s3,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    title: {
        ...appFonts.lgBold
    }
}));
