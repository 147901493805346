import _ from 'lodash';
import moment from 'moment';
import * as types from './actionTypes';
import { actionTypes as coreActionTypes } from '../core';
import { INTRO_STEPS_WITH_NOTIFICATIONS, INTRO_STEPS } from './constants';

export const SURVEY_IDS = {
    vitalStats: 'vital_stats'
};

export const initialState = {
    interests: {
        answers: [],
        section: {}
    },
    healthObjectives: {
        answers: [],
        section: {}
    },
    healthTopics: {
        answers: [],
        section: {}
    },
    healthSurvey: {
        questions: [],
        results: {
            data: [],
            hra_log: {}
        },
        updated: '',
        status: {}
    },
    EAPOptions: null,
    EAPTask: {},
    isShowingOnboarding: false,
    completedOnboardingSteps: [],
    afterOnboardingPath: null,
    areTasksShown: true,
    startTime: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.GET_EAP.SUCCESS: {
            return {
                ...state,
                EAPTask: action.payload
            };
        }
        case types.SET_EAP_OPTIONS: {
            return {
                ...state,
                EAPOptions: action.payload
            };
        }
        case types.GET_INTEREST_GROUPS.SUCCESS:
        case types.SAVE_INTEREST_GROUPS.SUCCESS:
            return {
                ...state,
                interests: {
                    answers: action.payload.data.answers,
                    section: action.payload.data.section
                },
            };
        case types.GET_HEALTH_OBJECTIVES.SUCCESS:
        case types.SAVE_HEALTH_OBJECTIVES.SUCCESS:
            return {
                ...state,
                healthObjectives: {
                    answers: action.payload.data.answers,
                    section: action.payload.data.section
                },
            };
        case types.GET_HEALTH_TOPICS.SUCCESS:
        case types.SAVE_HEALTH_TOPICS.SUCCESS:
            return {
                ...state,
                healthTopics: {
                    answers: action.payload.data.answers,
                    section: action.payload.data.section
                },
            };
        case types.GET_USER_TASKS.SUCCESS: {
            return {
                ...state,
                screens: action.payload.tasks,
            };
        }
        case types.GET_HEALTH_SURVEY.SUCCESS:
            return {
                ...state,
                healthSurvey: {
                    ...state.healthSurvey,
                    questions: action.payload.data
                }
            };
        case types.GET_HEALTH_SURVEY_STATUS.SUCCESS: {
            return {
                ...state,
                healthSurvey: {
                    ...state.healthSurvey,
                    status: action.payload.data
                }
            };
        }
        case types.GET_HEALTH_SURVEY_RESULTS.SUCCESS: {
            const { data } = action.payload;
            const id = _.get(data, ['hra_log', 'id']);
            return {
                ...state,
                healthSurvey: {
                    ...state.healthSurvey,
                    results: {
                        ...state.healthSurvey.results,
                        [id]: data
                    }
                }
            };
        }
        case types.RECORD_STEP_COMPLETED: {
            const { step } = action.payload;
            if (!_.includes(INTRO_STEPS, step)) {
                return {
                    ...state,
                    completedOnboardingSteps: _.uniq([
                        ...state.completedOnboardingSteps,
                        step
                    ])
                };
            }
            return state;
        }
        case types.SET_VITAL_STATS.SUCCESS: {
            const { data } = action.payload;
            const { questions } = state.healthSurvey;
            const index = _.findIndex(questions, { id: SURVEY_IDS.vitalStats });
            const vitalStats = { ..._.get(questions, index) };
            _.mapKeys(data, (val, key) => { vitalStats[key] = { ...vitalStats[key], id: val.value }; });

            return {
                ...state,
                healthSurvey: {
                    ...state.healthSurvey,
                    questions: [
                        ...questions.slice(0, index),
                        vitalStats,
                        ...questions.slice(index + 1)
                    ]
                }
            };
        }
        case types.START_SHOWING_ONBOARDING: {
            return {
                ...state,
                isShowingOnboarding: true
            };
        }
        case types.CLEAR_SHOWING_ONBOARDING: {
            return {
                ...state,
                isShowingOnboarding: false
            };
        }
        case types.START_REFRESH_HRA_SCORE: {
            return {
                ...state,
                isRefreshingHraScore: true
            };
        }
        case types.CLEAR_REFRESH_HRA_SCORE: {
            return {
                ...state,
                isRefreshingHraScore: false
            };
        }
        case types.COMPLETE_APPS_DEVICES_STEP: {
            return {
                ...state,
                screens: _.omit(state.screens, ['apps_and_devices'])
            };
        }
        case types.SET_ONBOARDING_COMPLETE: {
            const steps = state.completedOnboardingSteps;
            const removeObj = [];
            const screens = _.mapValues(state.screens, screen => {
                const tasks = _.filter(
                    screen.tasks,
                    t => !_.includes(steps, _.get(t, 'task_slug'))
                );
                if (tasks.length !== screen.tasks.length) {
                    if ((tasks.length === 1 && _.includes(INTRO_STEPS_WITH_NOTIFICATIONS, _.get(tasks, '0.task_slug'))) || _.isEmpty(tasks)) {
                        removeObj.push(screen.group_slug);
                        return screen;
                    }
                    return { ...screen, tasks };
                }
                return screen;
            });
            return {
                ...state,
                screens: _.omit(screens, removeObj)
            };
        }
        case types.SET_AFTER_ONBOARDING_PATH: {
            const { data: { path } } = action.payload;
            return {
                ...state,
                afterOnboardingPath: path
            };
        }
        case types.TOGGLE_SKIPPABLE_ONBOARDING_TASKS: {
            return {
                ...state,
                areTasksShown: !state.areTasksShown
            };
        }
        case coreActionTypes.RESET:
            return {
                ...initialState,
                areTasksShown: state.areTasksShown,
            };
        case types.SET_START_TIME: {
            const { step } = action.payload;
            return {
                ...state,
                startTime: { ...state.startTime, [step]: moment() },
            };
        }
        default:
            return state;
    }
};
