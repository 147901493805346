import { createSelector } from 'reselect';
import _ from 'lodash';
import moment from 'moment';
import { NAME, PRIVACY_ON_OFF_TITLES, PRIVACY_ON_OFF_ICONS, STATE_KEYS, SCORE_STATUSES } from './constants';
import * as types from './actionTypes';
import { selectors as coreSelectors, LIST_OPTION_TYPES, parsers, constants as coreConstants } from '../core';
import { selectors as onboardingSelectors } from '../onboarding';
import { baseColors } from '../../styles';

export const getNotificationSettingsItems = state => state[NAME].notificationSettings.items;
export const getNotificationSettingsIds = state => state[NAME].notificationSettings.ids;
export const getNotificationSettings = createSelector(getNotificationSettingsItems, getNotificationSettingsIds, (items, ids) => _.filter(_.map(ids, id => items[id]), _.identity));

export const getPrivacySettingsItems = state => state[NAME].privacySettings.items;
export const getPrivacySettingsIds = state => state[NAME].privacySettings.ids;
export const getPrivacySettingsUserEmail = state => state[NAME].privacySettings.userEmail;
export const getPrivacySettings = createSelector(getPrivacySettingsItems, getPrivacySettingsIds, (items, ids) => _.filter(_.map(ids, id => items[id]), _.identity));

export const getFeedbackMetadata = state => state[NAME].feedback_metadata;

export const getUserPersonalSettings = state => state[NAME].personalSettings;
export const isDateOfBirthEnabled = createSelector(
    getUserPersonalSettings,
    settings => _.get(_.find(settings, item => item.stateKey === STATE_KEYS.dateOfBirth), 'enabled', false)
);
export const getUserBiometricsData = state => state[NAME].biometrics;
export const getUserBiometricsCustomComponent = state => state[NAME].biometricCustomComponent;
export const getUserPreferredHeightUnit = state => state[NAME].userPreferredHeightUnit;
export const getUserPreferredWeightUnit = state => state[NAME].userPreferredWeightUnit;
export const getUserPreferredWaistCircumferenceUnit = state => state[NAME].userPreferredWaistCircumferenceUnit;

export const getFeedbackError = state => coreSelectors.getError(state, types.SET_FEEDBACK_METADATA.NAME);

export const isWellbeingScoreTabToBeSelected = state => state[NAME].wellbeingScoreTab;

export const getCompanyTasks = state => state[NAME].company_tasks;
export const getCompanyFeatures = state => state[NAME].company_features;

export const isLoadingFeedbackMetadata = state => coreSelectors.isLoading(state, types.SET_FEEDBACK_METADATA.NAME);
export const isLoadingFeedback = state => coreSelectors.isLoading(state, types.POST_FEEDBACK.NAME);
export const isLoadingNotificationsAndEmailsSettings = state => (
    coreSelectors.isLoading(state, types.GET_NOTIFICATIONS_SETTINGS.NAME) ||
    coreSelectors.isLoading(state, types.GET_PRIVACY_SETTINGS.NAME)
);
export const isLoadingEmailsSettings = state => coreSelectors.isLoading(state, types.SET_PRIVACY_SETTINGS.NAME);
export const isSettingEmailsError = state => !!coreSelectors.getError(state, types.SET_PRIVACY_SETTINGS.NAME);

export const isLoadingUserBiometrics = state => coreSelectors.isLoading(state, types.GET_BIOMETRICS.NAME);
export const isLoadingPersonalSettings= state => coreSelectors.isLoading(state, types.GET_PERSONAL_SETTINGS.NAME);
export const isSavingUserBiometrics = state => coreSelectors.isLoading(state, types.SAVE_BIOMETRICS.NAME);
export const isSavingPersonalSettings = state => coreSelectors.isLoading(state, types.SAVE_PERSONAL_SETTINGS.NAME);

export const isUpdatingAvatar = state => coreSelectors.isLoading(state, types.UPDATE_USER_AVATAR.NAME);
export const isDeletingAvatar = state => coreSelectors.isLoading(state, types.DELETE_USER_AVATAR.NAME);

export const isLoadingCompanyTasks = state => coreSelectors.isLoading(state, types.GET_COMPANY_TASKS.NAME);

export const getCurrentUserPrivacySettingsItems = state => state[NAME].currentUserPrivacySettings.items;
export const getCurrentUserPrivacySettingsIds = state => state[NAME].currentUserPrivacySettings.ids;
export const isLanguageChanged = state => state[NAME].isLanguageChanged;
export const getCurrentUserPrivacySettings = createSelector(getCurrentUserPrivacySettingsItems, getCurrentUserPrivacySettingsIds, (items, ids) => _.filter(_.map(ids, id => items[id]), _.identity));

// PRIVACY_ON_OFF_TITLES.OFF: Show; PRIVACY_ON_OFF_TITLES.ON: Hide
const getPrivacyTitle = value => (value ? PRIVACY_ON_OFF_TITLES.OFF: PRIVACY_ON_OFF_TITLES.ON);
const getPrivacyTitleIcon = value => (value ? PRIVACY_ON_OFF_ICONS.OFF: PRIVACY_ON_OFF_ICONS.ON);

export const getCurrentUserPrivacySettingsOptionsList = createSelector(getCurrentUserPrivacySettings, items => _.map(_.groupBy(items, item => item.group), (value, key) => ({
    title: key,
    data: _.map(value, item => {
        const { slug, shortName, disabled } = item;
        return {
            title: shortName,
            slug,
            rightTitle: getPrivacyTitle(!disabled), // disabled: 1 - Hide; 0 - Show
            rightTitleIcon: getPrivacyTitleIcon(disabled),
            type: LIST_OPTION_TYPES.ACTION_BUTTON_WITH_ITEM,
            iconType: disabled ? 'regular' : 'solid',
            iconColor: disabled ? baseColors.grey40 : baseColors.success,
        };
    })
})));


export const getIsHiddenPrivacyInformation = state => state[NAME].isHiddenPrivacyInformationText;
export const isHiddenPrivacyInformationText = createSelector(getIsHiddenPrivacyInformation,
    coreSelectors.getSecondParam, (items, slug) => !!_.find(items, { slug }));

export const isLoadingCurrentUserPrivacySettings = state => (
    coreSelectors.isLoading(state, types.GET_CURRENT_USER_PRIVACY_SETTINGS.NAME) ||
    coreSelectors.isLoading(state, types.SET_CURRENT_USER_PRIVACY_SETTINGS.NAME)
);

export const getCurrentUserPrivacySettingsElement = createSelector(getCurrentUserPrivacySettings, coreSelectors.getSecondParam,
    (items, slug) => _.find(items, { slug }));
export const isPrivacyVisible = createSelector(getCurrentUserPrivacySettingsElement,
    item => _.isUndefined(item) ? undefined : !_.get(item, 'disabled'));
export const isPrivacyVisibleInEntity = createSelector(isPrivacyVisible, item => _.isUndefined(item) ? true : item);
export const isPrivacyNotExisting = createSelector(isPrivacyVisible, item => _.isUndefined(item));

export const isHiddenTextOrNotEnabled = createSelector(
    isHiddenPrivacyInformationText,
    coreSelectors.isEnhancedPrivacyInformationEnabled,
    (isHidden, isEnabled) => isHidden || !isEnabled
);

export const getProgress = state => {
    return onboardingSelectors.getCurrentHealthSurveyResultBySlug(state, 'overall');
};

export const getScore = state => {
    return parsers.convertToLocal(_.get(onboardingSelectors.getCurrentHealthSurveyResultBySlug(state, 'overall'), 'score', 0), coreSelectors.getCurrentUserLanguage(state));
};

export const getScoreDescription = state => {
    return _.capitalize(_.get(onboardingSelectors.getCurrentHealthSurveyResultBySlug(state, 'overall'), 'description', ''));
};

export const getHealthSurveyStatebySlug = (state, slug) => {
    return _.get(onboardingSelectors.getCurrentHealthSurveyResultBySlug(state, slug), 'state');
};

export const getUpdatedDate = state => {
    const date = _.get(getProgress(state), 'created') && _.toNumber(getProgress(state).created);
    return moment.unix(date).format(coreConstants.DATE_FORMATS.monthFullDayShortYearFull);
};

export const getUpdatedFromNowTime = state => {
    const date = _.get(getProgress(state), 'created') && _.toNumber(getProgress(state).created);
    return moment(date * 1000).fromNow();
};

export const isTwoHealthResults = state => {
    const hra = onboardingSelectors.getHealthSurveyStatusHra(state);
    return hra && hra.length > 1;
};

export const deltaScore = state => {
    if (isTwoHealthResults(state)) {
        const hra = onboardingSelectors.getHealthSurveyStatusHra(state);
        const newHra = hra[0];
        const oldHra = hra[1];
        return (newHra.score - oldHra.score).toFixed(1);
    }
    return 0;
};

export const getStatus = state => {
    if (deltaScore(state) < 0) {
        return SCORE_STATUSES.dec.id;
    } else if (deltaScore(state) > 0) {
        return SCORE_STATUSES.inc.id;
    }
    return SCORE_STATUSES.nan.id;
};

export const showStatus = state => {
    return getStatus(state) !== SCORE_STATUSES.nan.id;
};

export const updatedPercentage = state => {
    return showStatus(state) ? ((deltaScore(state) < 0 ? '' : '+') + deltaScore(state)) : '';
};

export const isHealthSurveyCompleted = state => {
    const healthSurveyStatusHra = _.get(onboardingSelectors.getHealthSurveyStatusHra(state), '0');
    return healthSurveyStatusHra && healthSurveyStatusHra.status === 'completed';
};

export const getWaistInchesValue = state => state[NAME].waistInchesValue;

export const isLoadingSendFeedbackOnHealthScore = state => coreSelectors.isLoading(state, types.SEND_HEALTH_SCORE_FEEDBACK.NAME);
