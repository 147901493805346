import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent, Fragment } from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import { components as Core, ROUTES } from '../../../core';
import OrderHeader from '../OrderHeader';
import WithOrderShippingAddressBase, { styles as baseStyles } from './OrderShippingAddressBase';
import { layoutStyle } from '../../../../styles';
import { headerStyles } from '../header';
import { SHIPPING_FIELDS } from '../../constants';

const SELECT_FIELD_MAX_LENGTH = 200;

class OrderShippingAddress extends PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        item: PropTypes.object,
        address: PropTypes.object.isRequired,
        formattedAddress: PropTypes.object.isRequired,
        errors: PropTypes.object.isRequired,
        externalRewardName: PropTypes.func.isRequired,
        validateShippingAddress: PropTypes.func.isRequired,
        updateInputValue: PropTypes.func.isRequired,
        updateSelectValue: PropTypes.func.isRequired,
        onNext: PropTypes.func.isRequired,
        fields: PropTypes.array.isRequired,
        history: PropTypes.object.isRequired,
        token: PropTypes.string.isRequired,
        isLoading: PropTypes.bool,
        productCountry: PropTypes.object,
        productRegions: PropTypes.array,
        title: PropTypes.string
    };

    static defaultProps = {
        item: {},
        isLoading: false,
        productCountry: {},
        productRegions: [],
        title: undefined
    };

    onNext = () => this.props.onNext(this.tryOpenRedemptionConfirmationScreen);

    tryOpenRedemptionConfirmationScreen = () => {
        const { history, errors, formattedAddress, token, validateShippingAddress, i18n, item: { id } } = this.props;
        if (_.isEmpty(errors)) {
            history.push(
                ROUTES.orderRedemptionConfirmation(), {
                    productId: id,
                    address: formattedAddress,
                    token,
                    title: i18n.t('redemptionConfirmation'),
                }
            );
        } else {
            validateShippingAddress();
        }
    };

    get headerComponent() {
        return (
            <div>
                <OrderHeader product={this.props.item} externalRewardName={this.props.externalRewardName} />
                <span className={css(styles.fadedText)}>{this.props.i18n.t('enterShippingAddress')}</span>
            </div>
        );
    }

    getSelectField = (fieldData, fieldItem) => {
        const addressData = this.props.address[fieldItem.id]; // region / country
        const addressSelect = _.find(fieldData, data => data.id === addressData);
        return (
            <Core.SelectField
                hasSecondaryStyle={true}
                options={fieldData}
                fieldName={fieldItem.name}
                selected={addressSelect}
                onChange={value => this.props.updateSelectValue(fieldItem.id, fieldData, value)}
                className={css(styles.selectField)}
                error={this.props.errors[fieldItem.id]}
            />
        );
    };

    onChangeText = prop => event => {
        this.props.updateInputValue(prop)(event.target.value);
    };

    renderItem = item => {
        if (item.id === SHIPPING_FIELDS.REGION) {
            return this.getSelectField(this.props.productRegions, item);
        }
        else if (item.id === SHIPPING_FIELDS.COUNTRY) {
            return this.getSelectField([this.props.productCountry], item);
        }
        return (
            <div key={item.name} className={css(styles.containerStyle)}>
                <Core.Input
                    label={item.name}
                    onChange={this.onChangeText(item.id)}
                    editable={true}
                    maxLength={SELECT_FIELD_MAX_LENGTH}
                    value={this.props.address[item.id]}
                    helperText={this.props.errors[item.id]}
                    error={!!this.props.errors[item.id]}
                    fullWidth={true}
                />
            </div>
        );
    };

    render() {
        return (
            <Fragment>
                <Core.SmallerContainer>
                    <Core.FixedHeader
                        fullWidth={true}
                        subheader={
                            <Core.SubHeader
                                title={this.props.title}
                                titleClassName={css(headerStyles.headerTitle)}
                                className={css(layoutStyle.bgSecondary)}
                                left={<div className={css(headerStyles.buttonLeftOffset)} />}
                                right={
                                    <Core.Button
                                        onPress={this.onNext}
                                        size={Core.Button.SIZES.small}
                                        className={css(layoutStyle.noShadow, headerStyles.buttonText)}
                                        color="secondary">
                                        {this.props.i18n.t('next')}
                                    </Core.Button>
                                }
                            />
                        }
                    />
                    {this.headerComponent}
                    {_.map(this.props.fields, this.renderItem)}
                    <Core.BlockingLoading isLoading={this.props.isLoading} />
                </Core.SmallerContainer>
            </Fragment>
        );
    }
}

export default WithOrderShippingAddressBase(OrderShippingAddress);

const styles = StyleSheet.create({ ...baseStyles });