import moment from 'moment';
import I18n from '../../i18n/i18n';

export const NAME = 'onboarding';

export const INCHES = 'inches';
export const IN = 'in';
export const CM = 'cm';
export const LBS = 'lbs';
export const KG = 'kg';
export const FT = 'ft';
export const IN_IM = 'in/im';

export const UNIT_OPTIONS = Object.freeze({
    HEIGHT: [INCHES, CM],
    HEIGHT_FT: [FT, CM],
    HEIGHT_IN: [IN, CM],
    WEIGHT: [LBS, KG],
    WAIST: [INCHES, CM],
    WAIST_IN: [IN, CM]
});

export const SURVEY_QUESTIONS_TYPES = Object.freeze({
    choice: 'choice',
    numeric: 'numeric',
    custom: 'custom',
    intro: 'intro'
});

export const GENDER_VALUES = () => [
    {
        label: I18n.t('gender_male'),
        id: 'm'
    },
    {
        label: I18n.t('gender_female'),
        id: 'f'
    },
    {
        label: I18n.t('gender_non_binary'),
        id: 'o'
    },
    {
        label: I18n.t('gender_rather_not_say'),
        id: '-'
    }
];

export const QUESTION_TITLES = Object.freeze({
    timeSitting: 'Time Sitting',
    activityLevel: 'Activity Level'
});

export const SAMPLE_TASK_SCREENS = Object.freeze({
    welcomeUser: 'welcome_user',
    completeProfile: 'complete_profile',
    taskTracked: 'task_tracked',
    levelBreakdown: 'level_breakdown',
    rewards: 'rewards',
    sampleTask: 'sample_tasks'
});

export const HEALTH_SURVEY = 'health_survey';
export const HEALTH_SURVEY_INFO = 'health_survey_info';
export const HEALTH_SURVEY_RESULTS = 'health_survey_results';
export const RECOMMENDED_GOALS = 'recommended_goals';

export const SAMPLE_TASK = 'sample_task';
export const APPS_AND_DEVICES = 'apps_and_devices';
export const PERSONALIZE = 'personalize';

export const FLOW_STEPS = Object.freeze({
    welcomeUser: 'welcome_user',
    completeProfile: 'complete_profile',
    taskTracked: 'task_tracked',
    levelBreakdown: 'level_breakdown',
    rewards: 'rewards',
    appsDevicesInfo: 'apps_devices_info',
    appsDevices: 'apps_devices',
    appsDevicesPlatform: 'apps_devices_platform',
    chooseGoal: 'choose_a_goal',
    personalizeInfo: 'personalize_info',
    interests: 'interests',
    healthTopics: 'health_topics',
    healthObjectives: 'health_objectives',
    healthSurveyInfo: 'health_survey_info',
    healthSurvey: 'health_survey',
    healthSurveyResults: 'health_survey_results',
    recommendedGoals: 'recommended_goals',
    eapIntro: 'eap_intro',
    eapSurvey: 'eap_survey',
    eapContact: 'eap_contact',
    notifications: 'notifications',
    visibilitySettings: 'visibility_settings',
    helpUsImprove: 'help_us_improve'
});

export const PERSONALIZE_STEPS = [
    FLOW_STEPS.interests,
    FLOW_STEPS.healthTopics,
    FLOW_STEPS.healthObjectives
];

export const INTRO_STEPS = [
    FLOW_STEPS.welcomeUser,
    FLOW_STEPS.appsDevicesInfo,
    FLOW_STEPS.personalizeInfo,
    FLOW_STEPS.healthSurveyInfo,
    FLOW_STEPS.eapIntro
];

export const INTRO_STEPS_WITH_NOTIFICATIONS = [
    ...INTRO_STEPS,
    FLOW_STEPS.notifications
];

export const HEALTH_SURVEY_STEPS = [
    FLOW_STEPS.healthSurvey,
    FLOW_STEPS.healthSurveyResults,
];

export const EAP_STEPS = [
    FLOW_STEPS.eapIntro,
    FLOW_STEPS.eapSurvey,
    FLOW_STEPS.eapContact
];

export const APPS_DEVICES_STEPS = [
    FLOW_STEPS.appsDevicesInfo,
    FLOW_STEPS.appsDevicesPlatform,
    FLOW_STEPS.appsDevices
];

export const PRIVACY_CONTROLS_STEPS = [
    FLOW_STEPS.visibilitySettings,
    FLOW_STEPS.helpUsImprove
];

export const VITAL_STATS = Object.freeze({
    gender: 'gender',
    heightInches: 'heightInches',
    waistInches: 'waistInches',
    weightLbs: 'weightLbs',
    dateOfBirth: 'dateOfBirth',
    userPreferredHeightUnit: 'userPreferredHeightUnit',
    userPreferredWeightUnit: 'userPreferredWeightUnit',
    userPreferredWaistUnit: 'userPreferredWaistUnit',
    heightValue: 'heightValue',
    feetValue: 'feetValue',
    waistValue: 'waistValue',
    weightValue: 'weightValue'
});

export const MAX_DATE = new Date(moment().subtract(18, 'years').toDate());
export const MIN_DATE = new Date(moment().subtract(110, 'years').toDate());

export const FIREBASE_NAMES = Object.freeze({
    health_survey: 'HRA',
    apps_and_devices: 'Wearables',
    notifications: 'Notifications',
    personalize: 'Personalize',
    setGoals: 'SetGoals'
});

export const LBP_ITEMS = Object.freeze({
    apps_devices: 'registerdevice',
    personalize: 'chooseinterest',
    health_survey: 'health-survey-complete',
    join_goal: 'joinrecommendedgoal'
});

export const NOT_PERSISTED = false;

export const POLICY_POPUP_MODAL_MAX_WIDTH = 400;
export const POLICY_POPUP_MODAL_MAX_HEIGHT = 600;
