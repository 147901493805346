import React from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';
import classnames from 'classnames';
import { appFonts, media, importantClass } from '../../../../styles';
import Button from '../Button';
import WithViewAllButtonBase from './ViewAllButtonBase';

const ViewAllButton = ({ onPress, text, offsetRight, textClassName, ...props }) => (
    <Button
        onPress={onPress}
        className={classnames(css(!textClassName && styles.buttonFont, styles.viewAllButton, offsetRight && styles.buttonOffset), textClassName)}
        size="small"
        color="secondary"
        type="outlined">
        {text}
    </Button>
);
ViewAllButton.propTypes = {
    onPress: PropTypes.func,
    text: PropTypes.string,
    lowercase: PropTypes.bool,
    offsetRight: PropTypes.bool,
    textClassName: PropTypes.string
};

ViewAllButton.defaultProps = {
    onPress: undefined,
    text: undefined,
    lowercase: undefined,
    offsetRight: false,
    textClassName: undefined
};

export default WithViewAllButtonBase(ViewAllButton);

const BUTTON_OFFSET = 70;
const styles = StyleSheet.create({
    viewAllButton: importantClass({
        marginRight: 0,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderColor: 'transparent',
        [media.xsOnly]: {
            marginRight: 0
        }
    }),
    buttonOffset: importantClass({
        marginRight: BUTTON_OFFSET,
    }),
    buttonFont: importantClass({ ...appFonts.mdMedium }),
});
