import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { StyleSheet, css } from 'aphrodite-jss';
import { appFonts, baseColors, layoutStyle, spacing } from '../../../styles';
import { components as Core, translate } from '../../core';

class ToursInfoModal extends PureComponent {
    static propTypes = {
        iconName: PropTypes.string,
        title: PropTypes.string.isRequired,
        text: PropTypes.string,
        buttonTitle: PropTypes.string.isRequired,
        onButtonPress: PropTypes.func,
        footerComponent: PropTypes.func,
        iconClassName: PropTypes.string,
        textStyle: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.number]),
        renderIcon: PropTypes.func,
        isButtonVisible: PropTypes.bool,
        i18n: PropTypes.object.isRequired,
        isTextCentered: PropTypes.bool,
        iconBackgroundColor: PropTypes.string
    };

    static defaultProps = {
        iconName: undefined,
        text: undefined,
        onButtonPress: null,
        footerComponent: null,
        iconClassName: css(layoutStyle.bgBonus),
        textStyle: null,
        renderIcon: null,
        isButtonVisible: true,
        isTextCentered: false,
        iconBackgroundColor: undefined,
    };

    get iconContainerStyles() {
        return this.props.renderIcon
            ? css(styles.iconContainer)
            : classNames(css(styles.iconContainer), this.props.iconClassName);
    }

    get buttonTitle() {
        return this.props.buttonTitle || this.props.i18n.t('continue');
    }

    get hasIcon() {
        return this.props.renderIcon || this.props.iconName;
    }

    render() {
        const { renderIcon, iconName, title, text, textStyle, isTextCentered, onButtonPress, footerComponent } = this.props;
        return (
            <div className={css(styles.innerContainer)}>
                {this.hasIcon ?
                    <div className={this.iconContainerStyles} style={{ backgroundColor: this.props.iconBackgroundColor }}>
                        {renderIcon ? renderIcon() : null}
                        {iconName ?
                            <Core.Icon name={iconName} size={ICON_SIZE} type="fa" color={baseColors.white} />
                            : null
                        }
                    </div> : null
                }
                <div className={css(styles.bodyContainer, layoutStyle.flexColumn)}>
                    {title ? <div className={css(styles.title)}>{title}</div> : null}
                    {text ?
                        <p className={css(styles.description, textStyle, isTextCentered ? styles.centerText : null)}>
                            {text}
                        </p> : null
                    }
                    {this.props.isButtonVisible ?
                        <Core.Button onPress={onButtonPress} className={css(styles.closeButton)}>
                            {this.buttonTitle}
                        </Core.Button>
                        : null
                    }
                    {footerComponent ? footerComponent() : null}
                </div>
            </div>
        );
    }
}

export default translate()(ToursInfoModal);

const Z_INDEX = 100;
const ICON_BORDER_RADIUS = 180;
const ICON_BACKGROUND_SIZE = spacing.s8 * 2;
export const ICON_SIZE = 40;

const styles = StyleSheet.create({
    innerContainer: {
        alignItems: 'center',
        flexDirection: 'column',
        display: 'flex',
        marginBottom: -spacing.s5
    },
    bodyContainer: {
        backgroundColor: baseColors.white,
        paddingTop: spacing.s3,
        borderRadius: spacing.s1,
        shadowRadius: spacing.s2,
        shadowOpacity: 0.3,
        shadowOffset: { width: 0, height: 0 },
        shadowColor: baseColors.black,
        width: '100%'
    },
    iconContainer: {
        alignItems: 'center',
        borderRadius: ICON_BORDER_RADIUS,
        width: ICON_BACKGROUND_SIZE,
        height: ICON_BACKGROUND_SIZE,
        display: 'flex',
        justifyContent: 'center',
        zIndex: Z_INDEX,
        position: 'absolute',
        top: -36
    },
    closeButton: {
        width: '100%'
    },
    description: {
        ...appFonts.mdRegular,
        marginTop: spacing.s1,
        marginBottom: spacing.s5,
        whiteSpace: 'pre-wrap'
    },
    centerText: {
        textAlign: 'center'
    },
    title: {
        ...appFonts.xlBold,
        textAlign: 'center',
        marginTop: spacing.s1
    },
});
