import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';
import { withRouter } from 'react-router-dom';
import { components as Core, languageHelper } from '../../../core';
import { components as Invitations } from '../../../invitations';
import { spacing, importantStyles, layoutStyle } from '../../../../styles';
import WithCarouselItemBase, { styles as baseStyles, ITEM_HEIGHT } from './CarouselItemBase';
import { checkItemBounds } from '../../../challenges/services/helper';

class CarouselItem extends PureComponent {
    static propTypes = {
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        item: PropTypes.object.isRequired,
        image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        disableJoinButton: PropTypes.bool,
        joinCommunity: PropTypes.func,
        isInvitation: PropTypes.bool,
        onPressSingle: PropTypes.func.isRequired,
        invitationType: PropTypes.string,
        joinButtonLabel: PropTypes.string,
        carouselRef: PropTypes.object
    };

    static defaultProps = {
        image: undefined,
        disableJoinButton: false,
        joinCommunity: null,
        isInvitation: false,
        invitationType: undefined,
        joinButtonLabel: undefined,
        carouselRef: undefined
    };

    constructor(props) {
        super(props);
        this.childRef = React.createRef();
    }

    get item() {
        return this.props.item;
    }

    get communityHeader() {
        const { invitationType, isInvitation } = this.props;
        return isInvitation ? (
            <Invitations.Invitation
                invitedBy={this.item.invitedBy}
                type={invitationType}
                containerClassName={css(styles.communityInvitationContainer)}
            />
        ): null;
    }

    onPressSingle = () => this.props.onPressSingle(this.props.item);

    render() {
        const { isInvitation, joinCommunity, disableJoinButton, image, item, joinButtonLabel, id, joinedTag, carouselRef } = this.props;
        const isLongLanguage = languageHelper.isLongLanguage();
        const tabIndex = checkItemBounds(carouselRef, this.childRef);

        return (
            <div className={css(styles.slide)} key={id} ref={this.childRef}>
                <Core.TouchableItem
                    onPressSingle={this.onPressSingle}
                    item={this.item}
                    tabIndex={tabIndex}>
                    <div className={css(styles.innerSlide)}>
                        {this.communityHeader}
                        <div className={css(styles.communityImageArea)}>
                            <div>
                                {joinedTag}
                                <Core.Image src={image} className={css(styles.image)} alt="community" />
                                {isInvitation && <div className={css(styles.communityImageBackground)} />}
                            </div>
                        </div>
                        <div className={css(styles.additionalInfo)}>
                            <div>
                                <Core.ExploreItemTitle text={this.item.name} />
                                <Core.ExploreItemMembers members={item.members} isCommunity={true} />
                            </div>
                            {isInvitation && (
                                <Core.Button
                                    onPress={joinCommunity}
                                    disabled={disableJoinButton}
                                    isPrevented={true}
                                    size={Core.Button.SIZES.small}
                                    className={isLongLanguage && css(layoutStyle.textOverflow)}>
                                    {joinButtonLabel}
                                </Core.Button>
                            )}
                        </div>
                    </div>
                </Core.TouchableItem>
            </div>
        );
    }
}

export default withRouter(WithCarouselItemBase(CarouselItem));

const styles = StyleSheet.create(importantStyles({
    ...baseStyles,
    textSeeMore: {
        marginRight: 0,
        textAlign: 'left'
    },
    additionalInfo: {
        ...baseStyles.additionalInfo,
        display: 'flex',
        marginTop: spacing.s1,
        marginLeft: spacing.s0
    },
    slide: {
        flex: 1,
    },
    innerSlide: {
        paddingLeft: spacing.s1,
        paddingRight: spacing.s1,
    },
    image: {
        borderRadius: spacing.s1,
        height: ITEM_HEIGHT
    },
    communityImageBackground: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderRadius: spacing.s1
    },
    communityImageArea: {
        position: 'relative'
    }
}));
