import React from 'react';
import { css, StyleSheet } from 'aphrodite-jss';
import { baseColors, spacing } from '../../../../styles';
import WithJoinedTagBase, { styles as baseStyles } from './JoinedTagBase';
import { components as Core } from '../../index';

const JoinedTag = ({ showTag, text }) => (
    !!showTag && (
        <div className={css(styles.container)}>
            <Core.Icon
                type="fa"
                fill="light"
                name="check"
                size={spacing.s3}
                className={css(styles.checkIcon)}
            />
            <p className={css(styles.text)}>{text}</p>
        </div>
    )
);


export default WithJoinedTagBase(JoinedTag);

const styles = StyleSheet.create({
    ...baseStyles,
    container: {
        ...baseStyles.container,
        padding: `${spacing.s0}px ${spacing.s1}px`
    },
    checkIcon: {
        color: baseColors.white,
        marginRight: spacing.s0,
        marginBottom: spacing.s0-2
    }
});
