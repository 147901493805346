import { appFonts, spacing } from '../../../../../styles';

export const styles = {
    header: {
        paddingTop: spacing.s3,

        paddingBottom: spacing.s3
    },
    title: {
        ...appFonts.lgBold,
        textAlign: 'center'
    },
    subtitle: {
        ...appFonts.xsRegular,
        textAlign: 'center',
        paddingHorizontal: spacing.s1
    },
};
