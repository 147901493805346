import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';

import { baseColors, spacing, layoutStyle } from '../../../../../styles';
import { components as Core } from '../../../../core';
import WithCompletionBonusBase, { styles as baseStyles, ICON_SIZE } from './CompletionBonusBase';
import BonusChallengeMark from '../../BonusChallengeMark';


const CompletionBonus = ({
    isBonusEarned,
    challengeId,
    deviceBonusText,
    earnBonusTextInfo,
    isConnectedDevice,
    formatedPercentage,
    achievedDoubleBonus,
    completionBonusTitle,
    earnedBonusCompleteText,
    doubleBonusForConnectedDeviceText
}) => {

    const renderProgressBar = achievedDoubleBonus ? null : (
        <div>
            <Core.ProgressBar
                height={spacing.s1}
                filledPercentage={formatedPercentage}
                isPercentageShown={true}
                variant="determinate"
                progressColor={baseColors.secondary}
                className={css(styles.progressContainer)}
                isBigPercentage={true}
            />
            <p className={css(styles.earnBonusInfoText)}>{earnBonusTextInfo}</p>
            {isConnectedDevice ? (
                <p className={css(styles.regularTextStyle)}>{doubleBonusForConnectedDeviceText}</p>
            ) : null}
        </div>
    );

    const renderProgress = (isBonusEarned && !achievedDoubleBonus) ? (
        <div className={css(styles.completeBonusContainer, styles.noBottomSpacing)}>
            <div>
                <Core.Icon
                    type="regular"
                    name="check"
                    fill="regular"
                    size={spacing.s3 + 2}
                    color={baseColors.success}
                    className={css(styles.infoIcon)}
                />
            </div>
            <p className={css(styles.lineBreak, styles.regularTextStyle)}>{earnedBonusCompleteText}</p>
        </div>
    ) : renderProgressBar;

    const renderBonusSection = (
        <div className={css(styles.completeBonusContainer, styles.noBottomSpacing)}>
            <BonusChallengeMark
                challengeId={challengeId}
                showDoubleBonusTag={achievedDoubleBonus}
                containerClassName={css(styles.bonusMarkContainer, styles.noBottomSpacing, styles.noTopSpacing)}
            />
            {achievedDoubleBonus ? (
                <div className={css(layoutStyle.flexRow)}>
                    <div className={css(styles.watchIconWrapper)}>
                        <Core.Icon
                            type="fa"
                            fill="regular"
                            name="watch-fitness"
                            size={ICON_SIZE}
                            className={css(styles.infoIcon)}
                        />
                    </div>
                    <Core.Icon
                        type="regular"
                        name="check"
                        fill="regular"
                        size={ICON_SIZE}
                        color={baseColors.success}
                        className={css(styles.infoIcon)}
                    />
                    <p className={css(styles.regularTextStyle)}>{deviceBonusText}</p>
                </div>
            ) : null}
        </div>
    );

    return (
        <Fragment>
            <div className={css(styles.cardBodyContainer)}>
                <div className={css(styles.cardBodyTitleContainer)}>
                    <div className={css(styles.cardBodyTitleText)}>{completionBonusTitle}</div>
                </div>
                <div className={css(isBonusEarned && styles.completeBonusContainer)}>
                    {renderBonusSection}
                    {renderProgress}
                </div>
            </div>
        </Fragment>
    );
};

export default WithCompletionBonusBase(CompletionBonus);

CompletionBonus.propTypes = {
    earnBonusTextInfo: PropTypes.string,
    isBonusEarned: PropTypes.bool.isRequired,
    isConnectedDevice: PropTypes.bool.isRequired,
    deviceBonusText: PropTypes.string.isRequired,
    achievedDoubleBonus: PropTypes.bool.isRequired,
    formatedPercentage: PropTypes.number.isRequired,
    completionBonusTitle: PropTypes.string.isRequired,
    doubleBonusForConnectedDeviceText: PropTypes.string,
    earnedBonusCompleteText: PropTypes.string.isRequired,
    challengeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

CompletionBonus.defaultProps = {
    earnBonusTextInfo: '',
    doubleBonusForConnectedDeviceText: ''
};

const styles = StyleSheet.create({
    ...baseStyles,
    watchIconWrapper: {
        display: 'flex',
        color: baseColors.grey40,
    },
    noBottomSpacing: {
        marginBottom: 0,
        paddingBottom: 0,
    },
    noTopSpacing: {
        marginTop: 0,
        paddingTop: 0,
    },
    lineBreak: {
        lineBreak: 'anywhere',
    },
});