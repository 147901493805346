import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { baseColors, spacing, windowSize } from '../../../../styles';
import { FREQUENCY, PROGRESS_SQUARE_SIZE } from '../../constants';
import { translate } from '../../../core';
import { getChallenge, isTrackByDevice } from '../../selectors';

export default function WithTrackActivityButtonBase(WrappedComponent) {
    class TrackActivityButtonBase extends PureComponent {
        static propTypes = {
            challenge: PropTypes.object.isRequired,
            progress: PropTypes.object.isRequired,
            isTrackByDevice: PropTypes.bool,
        };

        static defaultProps = {
            isTrackByDevice: false,
        };

        get color() {
            return this.props.progress.enable ? baseColors.secondary : baseColors.grey50;
        }

        get isNotTracked() {
            return this.value <= 0;
        }

        get isTracked() {
            return (this.value > 0 && this.value < 100) ||
                (this.value >= 100 && this.props.challenge.frequency !== FREQUENCY.daily);
        }

        get isFullyTracked() {
            return this.value >= 100 && this.props.challenge.frequency === FREQUENCY.daily;
        }

        get value() {
            return _.get(this.props.challenge, 'userActivityLogs.length') ? this.props.progress.value : 0;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    color={this.color}
                    isNotTracked={this.isNotTracked}
                    isTracked={this.isTracked}
                    isFullyTracked={this.isFullyTracked}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => ({
        challenge: getChallenge(state, ownProps.challengeId),
        isTrackByDevice: isTrackByDevice(state, ownProps.challengeId)
    });

    return connect(mapStateToProps)(translate()(TrackActivityButtonBase));
}

const squareSize = (windowSize.width * 0.09) + 4;

export const styles = {
    defaultSquare: {
        marginHorizontal: 2,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: spacing.s10,
        borderWidth: 1,
        display: 'flex',
        width: squareSize,
        height: squareSize,
        maxWidth: PROGRESS_SQUARE_SIZE,
        maxHeight: PROGRESS_SQUARE_SIZE
    },
    fullyTracked: {
        backgroundColor: baseColors.success,
        borderColor: baseColors.success
    }
};