import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as actions from '../../actions';
import { translate, selectors as coreSelectors } from '../../../core';
import { isSso, isSettingLocation, isDataPrefilled, getCompany, getPrivacyPolicy, getTermsOfService, isEnabledHRIS } from '../../selectors';
import { CORPORATE_LINK_CONTEXT, USER_REGISTRATION_STEPS } from '../../constants';
import { appFonts, baseColors, spacing } from '../../../../styles';

export default function WithCDLSelectionBase(WrappedComponent) {
    class CDLSelectionBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            callback: PropTypes.func.isRequired,
            isLoading: PropTypes.bool,
            isDataPrefilled: PropTypes.bool,
            i18n: PropTypes.object.isRequired,
            step: PropTypes.string.isRequired,
            avoidDataSubmissionCallback: PropTypes.func,
            context: PropTypes.string,
            isTelus: PropTypes.bool,
            privacyPolicy: PropTypes.object,
            termsOfService: PropTypes.object,
            isEnabledHRIS: PropTypes.bool,
        };

        static defaultProps = {
            isLoading: false,
            avoidDataSubmissionCallback: null,
            isDataPrefilled: false,
            context: undefined,
            isTelus: false,
            privacyPolicy: {},
            termsOfService: {},
            isEnabledHRIS: false
        };

        constructor(props) {
            super(props);
            this._initActions();
            this.state = {
                disabled: true // this needs to be modified to account for when it's HRIS and there are CDL values available
            };
        }

        _initActions() {
            if (!this.props.avoidDataSubmissionCallback) {
                this.props.actions.updateUser({ currentRegistrationStep: USER_REGISTRATION_STEPS.location });
            }

            if (this.props.isTelus) {
                this.getPolicies();
            }
        }

        componentDidUpdate(prevProps, prevState) {
            if (prevState.disabled === prevProps.isDataPrefilled) {
                this.setState({ disabled: !prevProps.isDataPrefilled }); // eslint-disable-line react/no-did-update-set-state
            }

            if ((prevProps.isDataPrefilled !== this.props.isDataPrefilled) && this.props.isDataPrefilled && this.props.isEnabledHRIS) {
                this.props.callback(this.props.step);
            }
        }

        changeLocation = currentLocation => {
            this.currentLocation = currentLocation;
            this.setState(() => ({
                disabled: currentLocation.isCorporateLocation ? !_.get(currentLocation.department, 'id') : !_.get(currentLocation.location, 'id')
            }));
        };

        locationDetailsAreValid = () => (!this.currentLocation.isCorporateLocation && this.currentLocation.location.location_id !== 0) || this.currentLocation.department.department_id !== 0

        submit = () => {
            if (this.locationDetailsAreValid()) {
                if (this.props.avoidDataSubmissionCallback) {
                    const { location_id } = this.currentLocation.location;
                    const { department_id } = this.currentLocation.department;
                    this.props.avoidDataSubmissionCallback({ location_id, department_id }, this.props.step);
                } else if (!this.props.isDataPrefilled) {
                    const { location_name, location_id } = this.currentLocation.location;
                    if (!this.currentLocation.isCorporateLocation) {
                        this.props.actions.setUserLocation({ location_name, location_id });
                    }
                    else {
                        const { department_name, department_id } = this.currentLocation.department;
                        this.props.actions.setUserLocationDepartment({ location_name, location_id }, { department_name, department_id });
                    }
                    this.props.callback(this.props.step);
                } else {
                    this.props.callback(this.props.step);
                }
            }
        };

        get isCorporateLinking() {
            return this.props.context === CORPORATE_LINK_CONTEXT;
        }

        getPolicies() {
            const { company, actions } = this.props;
            const params = {};
            params.partnerId = _.get(company, 'partnerId');
            params.companyId = _.get(company, 'companyId');
            params.isRetail = _.get(company, 'retail');

            if (params.companyId || params.partnerId) {
                actions.getTermsOfService(params);
                actions.getPrivacyPolicy(params);
            }
        }

        get privacyPolicy() {
            return this.props.privacyPolicy.content;
        }

        get termsOfService() {
            const { termsOfService } = this.props;
            if (_.get(termsOfService, 'terms_url')) {
                return termsOfService;
            }

            return termsOfService.content;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    changeLocation={this.changeLocation}
                    isCorporateLinking={this.isCorporateLinking}
                    submit={this.submit}
                    disabled={this.state.disabled}
                    termsOfService={this.termsOfService}
                    privacyPolicy={this.privacyPolicy}
                />
            );
        }
    }

    function mapStateToProps(state) {
        return {
            isSSO: isSso(state),
            isLoading: isSettingLocation(state),
            isDataPrefilled: isDataPrefilled(state),
            isTelus: coreSelectors.isTelus(state),
            company: getCompany(state),
            privacyPolicy: getPrivacyPolicy(state),
            termsOfService: getTermsOfService(state),
            isEnabledHRIS: isEnabledHRIS(state),
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(CDLSelectionBase));
}

export const styles = {
    linkPolicyText: {
        ...appFonts.mdBold,
        color: baseColors.secondary
    },
    policyText: {
        ...appFonts.mdRegular,
        color: baseColors.black,
        flex: 1,
        flexWrap: 'wrap',
    },
    policyTextWrapper: {
        flex: 1,
        flexWrap: 'wrap',
        textAlign: 'center'
    },
    webViewContainer: {
        flex: 1,
        backgroundColor: baseColors.white
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        backgroundColor: baseColors.white
    },
    policyContainer: {
        alignItems: 'center',
        backgroundColor: 'transparent',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignSelf: 'stretch',
        flexWrap: 'nowrap',
        paddingBottom: spacing.s2,
        marginTop: spacing.s5,
    },
};