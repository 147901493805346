import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { withLastLocation } from 'react-router-last-location';
import classNames from 'classnames';
import { isIE } from 'react-device-detect';
import { baseColors, spacing, headerHeight, windowSize, importantStyles, layoutStyle } from '../../../../styles';
import { AsyncComponent, components as Core, CSS_CLASSES, Modal } from '../../../core';
import InvitationItem from '../InvitationItem';
import WithInvitationsListBase, { styles as stylesBase } from './InvitationsListBase';
import AddedUserList from '../AddedUserList';
import { TYPES } from '../../constants';


class InvitationsList extends PureComponent {
    static propTypes = {
        title: PropTypes.string,
        isLoading: PropTypes.bool,
        items: PropTypes.array,
        i18n: PropTypes.object.isRequired,
        isInvitationSending: PropTypes.bool,
        isMembersPage: PropTypes.bool,
        customNavBarButtonsRight: PropTypes.object,
        customNavBarButtonsLeft: PropTypes.object,
        searchByName: PropTypes.func.isRequired,
        searchMemberValue: PropTypes.string,
        clear: PropTypes.func.isRequired,
        onToggleMember: PropTypes.func.isRequired,
        emailsArray: PropTypes.array.isRequired,
        allInvites: PropTypes.array.isRequired,
        invitedMembers: PropTypes.array,
        loadMoreUsers: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired,
        isModal: PropTypes.bool,
        close: PropTypes.func,
        isSearching: PropTypes.bool,
        isTyping: PropTypes.bool,
        hasMore: PropTypes.bool.isRequired,
        isInviteByEmailShown: PropTypes.bool,
        onInviteByEmail: PropTypes.func,
        invitationSending: PropTypes.bool,
        callback: PropTypes.func,
        hideProgramNameRestriction: PropTypes.func,
        emptyListUsersMessage: PropTypes.string,
        isProgramRestrictionShown: PropTypes.bool,
        searchOthersText: PropTypes.string,
        inviteByEmailButtonLabel: PropTypes.string,
        searchPlaceHolder: PropTypes.string,
        propramNameTitle: PropTypes.string,
        programNameDescription: PropTypes.string,
        dismissButtonLabel: PropTypes.string,
        inviteByEmailText: PropTypes.string,
        invitationSendingMessage: PropTypes.string,
        isUserInvitation: PropTypes.bool,
        type: PropTypes.string
    };

    static defaultProps = {
        title: undefined,
        isLoading: false,
        items: undefined,
        isInvitationSending: false,
        isMembersPage: false,
        customNavBarButtonsRight: undefined,
        customNavBarButtonsLeft: undefined,
        searchMemberValue: undefined,
        invitedMembers: undefined,
        isModal: false,
        close: undefined,
        isSearching: false,
        isTyping: false,
        isInviteByEmailShown: false,
        onInviteByEmail: null,
        invitationSending: false,
        callback: null,
        hideProgramNameRestriction: null,
        emptyListUsersMessage: undefined,
        isProgramRestrictionShown: false,
        searchOthersText: undefined,
        inviteByEmailButtonLabel: undefined,
        searchPlaceHolder: undefined,
        propramNameTitle: undefined,
        programNameDescription: undefined,
        dismissButtonLabel: undefined,
        inviteByEmailText: undefined,
        invitationSendingMessage: undefined,
        isUserInvitation: true,
        type: TYPES.USERS
    };

    componentDidUpdate(prevProps) {
        if (prevProps.invitationSending && !this.props.invitationSending) {
            this.onCallback();
        }
    }

    onCallback = () => this.props.isModal ? this.props.close() : this.props.history.goBack();

    get addedUsersComponent() {
        const { isUserInvitation, allInvites, onToggleMember, isSearching, isTyping, type } = this.props;
        return allInvites.length ? (
            <AddedUserList
                data={allInvites}
                removeUserHandler={onToggleMember}
                showLoader={isSearching || isTyping}
                isUserInvitation={isUserInvitation}
                type={type}
            />
        ) : null;
    }

    callback = () => {
        this.props.callback(this.props.invitedMembers, this.props.emailsArray);
        this.props.close();
    };

    rightButtonAction = () => this.props.callback ? this.callback() : this.props.customNavBarButtonsRight.action();

    get fixedHeader() {
        const { title } = this.props;
        return (
            <React.Fragment>
                <Core.EntityDetailsHeader
                    hasBackButton={true}
                    rightComponent={this.renderNavBarRightButton}
                />
                <Core.SubHeader
                    title={title}
                    noTopPadding={true}
                />
            </React.Fragment>
        );
    }

    get subheader() {
        const { title, isModal, customNavBarButtonsLeft, close } = this.props;
        return (
            <Core.SubHeader
                title={title}
                hasBackButton={!isModal}
                right={this.renderNavBarRightButton}
                titleAlign={Core.SubHeader.ALIGN.center}
                left={isModal ?
                    <Core.Button
                        type="text"
                        onPress={close}>
                        {customNavBarButtonsLeft.title}
                    </Core.Button> : null
                }
            />
        );
    }

    get renderNavBarRightButton() {
        const { allInvites, customNavBarButtonsRight } = this.props;
        return (
            <Core.Button
                type="text"
                disabled={!allInvites.length}
                onPress={this.rightButtonAction}>
                {customNavBarButtonsRight.title}
            </Core.Button>
        );
    }

    get topComponents() {
        return (
            <div>
                {this.searchComponent}
                {this.addedUsersComponent}
                {this.props.isUserInvitation ? this.programNameRestrictionComponent : null}
            </div>
        );
    }

    get emptyUserList() {
        const { emptyListUsersMessage } = this.props;
        const icon = <Core.Icon name="question" type="fa" size={spacing.s4} color={baseColors.grey50} />;
        //return isInviteByEmailShown ? null : <Core.EmptyListSimple message={emptyListUsersMessage} icon={icon} />;  SFE-6225 Hiding invite by email section
        return <Core.EmptyListSimple message={emptyListUsersMessage} icon={icon} />;
    }

    get searchComponent() {
        const { searchMemberValue, clear, searchPlaceHolder, searchByName } = this.props;
        return (
            <Fragment>
                {/* SFE-6225 Hiding invite by email section
                {isUserInvitation && isInviteByEmailShown ?
                    <div className={css(styles.searchTitle)}>
                        <p className={css(styles.actionSearchByEmail, styles.container)}>
                            {searchOthersText}
                            <Core.Button type="text" size={Core.Button.SIZES.small} onPress={this.openInviteByEmail}>
                                <p className={css(styles.invitationLink)}>{inviteByEmailButtonLabel}</p>
                            </Core.Button>
                        </p>
                    </div> : null} */}
                <Core.SearchBar
                    onChangeText={searchByName}
                    value={searchMemberValue}
                    onClear={clear}
                    label={searchPlaceHolder}
                />
            </Fragment>
        );
    }

    renderItem = rowData => (
        <InvitationItem
            item={rowData.item}
            onToggle={this.props.onToggleMember}
            invitedMembers={this.props.invitedMembers}
            containerClassName={css(styles.container)}
            isUserInvitation={this.props.isUserInvitation}
            type={this.props.type}
        />
    );

    get programNameRestrictionComponent() {
        const { isProgramRestrictionShown, propramNameTitle, programNameDescription, dismissButtonLabel,
            hideProgramNameRestriction } = this.props;
        return isProgramRestrictionShown ? (
            <div className={css(layoutStyle.flexCenter, styles.isProgramNameOnly, layoutStyle.flexColumn)}>
                <Core.Icon
                    name="briefcase"
                    type="fa"
                    fill="light"
                    size={spacing.s8}
                    className={css(styles.locationIcon)}
                />
                <p className={css(styles.programNameTitle)}>
                    {propramNameTitle}
                </p>
                <p className={css(styles.programNameDesc, layoutStyle.textCenter)}>
                    {programNameDescription}
                </p>
                <Core.Button
                    onPress={hideProgramNameRestriction}
                    className={css(styles.dismissButton)}
                    size={Core.Button.SIZES.small}
                    type="outlined">
                    {dismissButtonLabel}
                </Core.Button>
            </div>
        ) : null;
    }

    get inviteByEmailComponent() {
        const { isInviteByEmailShown, inviteByEmailText, inviteByEmailButtonLabel } = this.props;
        return isInviteByEmailShown ? (
            <div className={css(layoutStyle.flexColumnCenter, styles.inviteByEmailWrapper)}>
                <p className={css(styles.inviteByEmailTitle)}>
                    {inviteByEmailText}
                </p>
                <Core.Button
                    onPress={this.openInviteByEmail}
                    type="outlined"
                    className={css(styles.inviteByEmailButton, layoutStyle.fw)}>
                    {inviteByEmailButtonLabel}
                </Core.Button>
            </div>
        ) : null;
    }

    openInviteByEmail = () => {
        Modal.open(
            AsyncComponent(() => import('../InviteByEmail')),
            {
                isModal: true,
                isInviteFromEntity: true,
                emailsArray: this.props.emailsArray,
                title: this.props.i18n.t('inviteByEmail'),
                addEmailsToList: this.props.onInviteByEmail
            },
            { isContainer: true, isNoPadding: true, isFullHeight: true }
        );
    };

    keyExtractor = item => item.id;

    get customNavBar() {
        return !this.props.isModal ? this.fixedHeader : this.subheader;
    }

    get wrapper() {
        return this.props.isModal ? React.Fragment : Core.SmallerContainer;
    }

    render() {
        const { isModal, isInvitationSending, invitationSendingMessage, isMembersPage, loadMoreUsers, isLoading,
            hasMore, items, isUserInvitation } = this.props;
        return (
            <this.wrapper>
                <Core.Layout.FHFlex>
                    {isUserInvitation ? this.customNavBar : null}
                    {this.topComponents}
                    <div className={classNames(isIE ? css(styles.flex1) : null, isModal ? CSS_CLASSES.modalBodyWithScrollbar : null, css(styles.scrollableSection))}>
                        <Core.InfiniteLazyListFadeIn
                            data={items}
                            keyExtractor={this.keyExtractor}
                            renderItem={this.renderItem}
                            onEndReached={isMembersPage ? loadMoreUsers : null}
                            onEndReachedThreshold={0.5}
                            isLoading={isLoading}
                            hasMore={hasMore}
                            itemHeight={75}
                            offset={windowSize.height}
                            isInModal={isModal}
                            ListEmptyComponent={this.emptyUserList}
                        />
                        {isUserInvitation ? this.inviteByEmailComponent : null}
                    </div>
                    {isInvitationSending &&
                        <Core.BlockingLoading
                            isLoading={isInvitationSending}
                            messageComponent={invitationSendingMessage}
                        />
                    }
                </Core.Layout.FHFlex>
            </this.wrapper>
        );
    }
}

const styles = StyleSheet.create(importantStyles({
    ...stylesBase,
    headerPositionSticky: {
        top: headerHeight,
        zIndex: 1,
    },
    usersPanelPositionSticky: {
        top: 112
    },
    appBar: {
        boxShadow: 'none'
    },
    actionSearchByEmail: {
        ...stylesBase.actionSearchByEmail,
        marginTop: 0
    },
    flex1: {
        flex: 1
    },
    scrollableSection: {
        marginRight: -spacing.s3,
    },
}));

export default withLastLocation(WithInvitationsListBase(InvitationsList));
