import { NAME as name } from './constants';
import { createActionTypes } from '../core';

const NAME = name.toUpperCase();

// for api
export const GET_CHALLENGES = createActionTypes(`${NAME}/GET_CHALLENGES`);
export const GET_CHALLENGES_LIST = createActionTypes(`${NAME}/GET_CHALLENGES_LIST`);
export const GET_EXPLORE_CHALLENGES = createActionTypes(`${NAME}/GET_EXPLORE_CHALLENGES`);
export const DISMISS_SINGLE_CHALLENGE = createActionTypes(`${NAME}/DISMISS_SINGLE_CHALLENGE`);
export const DISMISS_MULTIPLE_CHALLENGE = createActionTypes(`${NAME}/DISMISS_MULTIPLE_CHALLENGE`);
export const GET_CHALLENGE = createActionTypes(`${NAME}/GET_CHALLENGE`);
export const GET_CHALLENGE_INFO = createActionTypes(`${NAME}/GET_CHALLENGE_INFO`);
export const GET_CHALLENGE_TEAM = createActionTypes(`${NAME}/GET_CHALLENGE_TEAM`);
export const GET_CHALLENGE_LEADERBOARD = createActionTypes(`${NAME}/GET_CHALLENGE_LEADERBOARD`);
export const GET_CHALLENGE_MEMBERSHIP = createActionTypes(`${NAME}/GET_CHALLENGE_MEMBERSHIP`);
export const GET_CHALLENGE_MEMBERS = createActionTypes(`${NAME}/GET_CHALLENGE_MEMBERS`);
export const JOIN_CHALLENGE = createActionTypes(`${NAME}/JOIN_CHALLENGE`);
export const JOIN_CHALLENGE_BY_TEAM_ID = createActionTypes(`${NAME}/JOIN_CHALLENGE_BY_TEAM_ID`);
export const LEAVE_CHALLENGE = createActionTypes(`${NAME}/LEAVE_CHALLENGE`);
export const CREATE_CHALLENGE = createActionTypes(`${NAME}/CREATE_CHALLENGE`);
export const UPDATE_CHALLENGE = createActionTypes(`${NAME}/UPDATE_CHALLENGE`);
export const DELETE_CHALLENGE = createActionTypes(`${NAME}/DELETE_CHALLENGE`);
export const GET_CHALLENGE_RECOMMENDATIONS = createActionTypes(`${NAME}/GET_CHALLENGE_RECOMMENDATIONS`);
export const GET_RECOMMENDED_GOALS = createActionTypes(`${NAME}/GET_RECOMMENDED_GOALS`);
export const GET_STARTER_GOALS = createActionTypes(`${NAME}/GET_STARTER_GOALS`);
export const SET_GOAL = createActionTypes(`${NAME}/SET_GOAL`);
export const SET_GOALS = createActionTypes(`${NAME}/SET_GOALS`);
export const GET_ACTIVITY_UNITS = createActionTypes(`${NAME}/GET_ACTIVITY_UNITS`);
export const GET_ACTIVITY_LOGS = createActionTypes(`${NAME}/GET_ACTIVITY_LOGS`);
export const GET_FILTERED_ACTIVITY_LOGS = createActionTypes(`${NAME}/GET_FILTERED_ACTIVITY_LOGS`);
export const GET_MORE_FILTERED_ACTIVITY_LOGS = createActionTypes(`${NAME}/GET_MORE_FILTERED_ACTIVITY_LOGS`);
export const GET_ACTIVITY_LOGS_EXTENDED = createActionTypes(`${NAME}/GET_ACTIVITY_LOGS_EXTENDED`);
export const DELETE_ACTIVITY_LOG = createActionTypes(`${NAME}/DELETE_ACTIVITY_LOG`);
export const DELETE_ACTIVITY_LOGS = createActionTypes(`${NAME}/DELETE_ACTIVITY_LOGS`);
export const TRACK_ACTIVITY = createActionTypes(`${NAME}/TRACK_ACTIVITY`);
export const UPDATE_AFTER_TRACKING = createActionTypes(`${NAME}/UPDATE_AFTER_TRACKING`);
export const CREATE_TEAM = createActionTypes(`${NAME}/CREATE_TEAM`);
export const EDIT_TEAM = createActionTypes(`${NAME}/EDIT_TEAM`);
export const GET_LEADERBOARDS = createActionTypes(`${NAME}/GET_LEADERBOARDS`);
export const GET_SEARCH_TEAMS_RESULT = createActionTypes(`${NAME}/GET_SEARCH_TEAMS_RESULT`);
export const DECLINE_INVITATION = createActionTypes(`${NAME}/DECLINE_INVITATION`);
export const GET_INVITES = createActionTypes(`${NAME}/GET_INVITES`);

// single actions
export const UPDATE_NEW_CHALLENGE = `${NAME}/UPDATE_NEW_CHALLENGE`;
export const SET_USER_TRACKED_CHALLENGE_STATUS = `${NAME}/SET_USER_TRACKED_CHALLENGE_STATUS`;
export const UPDATE_CREATE_CHALLENGE_STEP = `${NAME}/UPDATE_CREATE_CHALLENGE_STEP`;
export const SHOW_FIRST_ACTIVITY_TRACK_MODAL = `${NAME}/SHOW_FIRST_ACTIVITY_TRACK_MODAL`;
export const HIDE_ACTIVITY_INFORMATION_TEXT = `${NAME}/HIDE_ACTIVITY_INFORMATION_TEXT`;
export const UPDATE_CHALLENGE_SUCCESS = 'UPDATE_CHALLENGE_SUCCESS';
export const DISMISS_CAROUSEL_INSTRUCTIONS = `${NAME}/DISMISS_CAROUSEL_INSTRUCTIONS`;
export const DISMISS_FEATURED_CHALLENGE = createActionTypes(`${NAME}/DISMISS_FEATURED_CHALLENGE`);
