import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as actions from '../../../actions';
import * as selectors from '../../../selectors';
import { appFonts, baseColors, commonPadding, spacing } from '../../../../../styles';
import { isMember, isAfterDeadline, isUserCompanyPartner, isUnstarted } from '../../../services/helper';
import { translate, selectors as coreSelectors, tracker, entitiesHelper, parsers, numbers } from '../../../../core';
import { CHALLENGE_ENTITY_TYPES } from '../../../constants';

export const NUMBER_OF_LINES = 3;

export default function WithChallengeParticipantsBase(WrappedComponent) {
    class ChallengeParticipantsBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            history: PropTypes.object.isRequired,
            challengeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            challenge: PropTypes.object.isRequired,
            participants: PropTypes.array.isRequired,
            membershipDetails: PropTypes.array,
            userId: PropTypes.number.isRequired,
            isLoading: PropTypes.bool,
            isLoadingMembership: PropTypes.bool,
            isRegionsDepartmentsChallenge: PropTypes.bool,
            showOnCard: PropTypes.bool,
            showParticipantsCompletedPeriod: PropTypes.bool
        };

        static defaultProps = {
            membershipDetails: undefined,
            isLoading: false,
            isLoadingMembership: false,
            isRegionsDepartmentsChallenge: false,
            showOnCard: null,
            showParticipantsCompletedPeriod: false
        };

        componentDidMount() {
            this.props.actions.getChallengeMembership(this.props.challengeId);
            this.props.actions.getChallenge(this.props.challengeId);
        }

        componentDidUpdate(prevProps) {
            if (prevProps.challengeId !== this.props.challengeId) {
                this.props.actions.getChallengeMembership(this.props.challengeId);
            }
        }

        get description() {
            return parsers.htmlDecode(this.props.challenge.challengeDescription);
        }

        onJoinChallengeIndividual = () => {
            tracker.logEvent('join_challenge', { id: this.props.challengeId, stream: false });
            this.props.actions.joinChallenge(this.props.challengeId, this.props.userId, false, null, this.props.challenge.challengeName);
        };

        get isUserCompanyPartner() {
            return isUserCompanyPartner(this.props.challenge);
        }

        get numMembersText() {
            const { challenge, i18n, isRegionsDepartmentsChallenge } = this.props;
            if (isRegionsDepartmentsChallenge) {
                return i18n.t('challengerCount', { num: challenge.numChallengeParticipants });
            }
            if (this._isTeamChallenge) {
                if (challenge.numChallengeParticipants === 1) {
                    return i18n.t('one_team');
                }
                return i18n.t('number_teams', { num: numbers.numberWithCommas(challenge.numChallengeParticipants) });
            }
            if (challenge.numChallengeParticipants === 1) {
                return i18n.t('oneParticipant');
            }
            return i18n.t('numberOfParticipants', { num: numbers.numberWithCommas(challenge.numChallengeParticipants) });
        }

        get invitedBy() {
            const { challenge } = this.props;
            if (isMember(challenge) || !_.get(challenge, 'invitation.isInvited')) return;
            const invitedBy = _.uniqBy(_.get(challenge, 'invitation.invitedBy'), 'userId'); // we get 2 duplicate objects for each invitee
            return entitiesHelper.getInvitedByText(invitedBy);
        }

        get _isTeamChallenge() {
            const { group } = CHALLENGE_ENTITY_TYPES;
            const { challenge } = this.props;
            const challengeEntityType = _.get(challenge, 'challengeEntityType');
            return challengeEntityType === group;
        }

        get activateButtonLabel() {
            const { challenge, i18n } = this.props;
            let label = i18n.t('start_goal');
            if (!isMember(challenge)) {
                if (challenge.invitation.isInvited) {
                    label = i18n.t('acceptChallenge');
                } else {
                    label = i18n.t('joinChallenge');
                }
            }
            return label;
        }

        get inviteToChallengeText() {
            return this.props.i18n.t('invite');
        }

        get inviteToYourTeamTitle() {
            return this.props.i18n.t('inviteToYourTeam');
        }

        get inviteToChallengeTitle() {
            return this.props.i18n.t('inviteToThisChallenge');
        }

        getOptions = (inviteToTeamCallback, inviteToChallengeCallback) => {
            const inviteToTeamOption = {
                title: this.inviteToYourTeamTitle,
                onPress: inviteToTeamCallback
            };
            const buttons = [
                {
                    title: this.inviteToChallengeTitle,
                    onPress: inviteToChallengeCallback
                }
            ];
            if (this._isTeamChallenge) {
                return [inviteToTeamOption, ...buttons];
            }
            return buttons;
        };

        get entityId() {
            const { challenge } = this.props;
            return _.get(challenge, 'userEntity.id');
        }

        get isChallengeDescription() {
            const { challenge } = this.props;
            return challenge.challengeDescription.length > 1;
        }

        get showInviteButton() {
            const { showOnCard, challenge } = this.props;
            return isMember(challenge) && !isAfterDeadline(challenge) && !showOnCard;
        }

        get challengeParticipants() {
            const { showParticipantsCompletedPeriod, challenge: { participantsCompletedPeriod }, participants } = this.props;
            return showParticipantsCompletedPeriod && participantsCompletedPeriod ? participantsCompletedPeriod.participants : participants;
        }

        get isUnstartedChallenge() {
            return isUnstarted(this.props.challenge);
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    onJoinChallengeIndividual={this.onJoinChallengeIndividual}
                    isAfterDeadline={isAfterDeadline(this.props.challenge)}
                    isMember={isMember(this.props.challenge)}
                    activateButtonLabel={this.activateButtonLabel}
                    invitedBy={this.invitedBy}
                    numMembersText={this.numMembersText}
                    isUserCompanyPartner={this.isUserCompanyPartner}
                    inviteToChallengeText={this.inviteToChallengeText}
                    getOptions={this.getOptions}
                    entityId={this.entityId}
                    description={this.description}
                    isChallengeDescription={this.isChallengeDescription}
                    showInviteButton={this.showInviteButton}
                    challengeParticipants={this.challengeParticipants}
                    isTeamChallenge={this._isTeamChallenge}
                    isUnstartedChallenge={this.isUnstartedChallenge}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => ({
        userId: coreSelectors.getCurrentUserId(state),
        challenge: selectors.getChallenge(state, ownProps.challengeId),
        membershipDetails: selectors.getChallengeMembership(state, ownProps.challengeId),
        invitingTeams: selectors.getChallengeMembershipInvitingTeams(state, ownProps.challengeId),
        isLoadingMembership: selectors.isLoadingMembership(state, ownProps.challengeId),
        participants: selectors.getChallengeParticipants(state, ownProps.challengeId),
        isLoading: selectors.isJoiningChallenge(state, ownProps.challengeId),
        isRegionsDepartmentsChallenge: selectors.isRegionsOrDepartmentsChallenge(state, ownProps.challengeId)
    });

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(ChallengeParticipantsBase));
}

export const styles = {
    membersAvatar: {
        marginLeft: -spacing.s3,
        borderWidth: 2,
        borderColor: baseColors.white
    },
    avatarListContainer: {
        marginRight: spacing.s3,
        marginLeft: spacing.s3,
        marginTop: spacing.s3,
    },
    avatarListContainerOnCard: {
        marginTop: spacing.s1
    },
    cardBodyButton: {
        paddingTop: spacing.s5,
        alignItems: 'center',
    },
    invitedText: {
        ...appFonts.smRegular,
        color: baseColors.grey40
    },
    joinCreateButtons: {
        flexDirection: 'row',
        paddingBottom: spacing.s3,
        display: 'flex'
    },
    membersDetails: {
        flex: 1,
        flexDirection: 'row',
        color: baseColors.grey40,
    },
    teamsDetails: {
        flexDirection: 'row',
    },
    center: {
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: spacing.s0,
        marginRight: spacing.s0,
    },
    viewParticipantsAndJoin: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    inviteButton: {
        marginRight: spacing.s4
    },
    inviteButtonLabel: {
        marginLeft: spacing.s1,
    },
    membersDetailsOnCard: {
        marginLeft: spacing.s0,
        color: baseColors.grey40,
        paddingTop: spacing.s1,
        flexShrink: 0
    },
    wrappedContainer: {
        flexWrap: 'wrap'
    },
    smallGreyTitle: {
        ...appFonts.smBold,
        paddingLeft: commonPadding,
        paddingTop: spacing.s1,
        paddingBottom: spacing.s1,
        backgroundColor: baseColors.grey85
    },
};
