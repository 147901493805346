import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import * as actions from '../../actions';
import { spacing, baseColors } from '../../../../styles';
import { getChallenge, getConnectedDeviceName, getUnitsForActivityCategory,
    getChallengeProgressByUserActivityLogs } from '../../selectors';
import { getPlatformDevice } from '../../../appsdevices/selectors';
import { actions as coreActions, constants as coreConstants, translate, doublePress, DATE_FORMATS, tracker } from '../../../core';
import { FREQUENCY, ACTIVITIES_SEARCH_KEYS } from '../../constants';

export default function WithDatesSwiperBase(WrappedComponent) {
    class DatesSwiperBase extends PureComponent {
        static propTypes = {
            challenge: PropTypes.object.isRequired,
            i18n: PropTypes.object.isRequired,
            progressData: PropTypes.object.isRequired,
            isCurrentWeek: PropTypes.bool,
            isDisabled: PropTypes.bool,
            challengeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            actions: PropTypes.object.isRequired,
            unitsForActivityCategory: PropTypes.array.isRequired,
            connectedDeviceName: PropTypes.string,
            isHomeCarousel: PropTypes.bool,
            isWeekProgress: PropTypes.bool,
            defaultDevice: PropTypes.object
        };

        static defaultProps = {
            isCurrentWeek: false,
            isDisabled: false,
            connectedDeviceName: undefined,
            isHomeCarousel: false,
            isWeekProgress: false,
            defaultDevice: {}
        };

        showToast = (msg, title = 'Warning') => {
            this.props.actions.addToast(coreConstants.TOAST_TYPES.INFO, undefined, msg, title);
        };

        getQuantity = (progress, activityUnitDetails) => {
            const goalProgress = this.props.challenge.progress;
            const percentageToAdd = (100 - progress.value) / 100;
            if (percentageToAdd <= 0) return 0;
            const missingPoints = goalProgress.overallPointsTargetValue * percentageToAdd;
            const points = _.get(activityUnitDetails, 'points', 1);
            return _.min([Math.ceil(missingPoints/points), 0]);
        };

        getActivitiesToTrack = progress => _.map(this.props.unitsForActivityCategory, activityUnitDetails => ({
            ...activityUnitDetails,
            date: progress.date, // add date
            // prefill for daily goal with remaining value
            quantity: this.props.challenge.frequency === FREQUENCY.daily
                ? this.getQuantity(progress, activityUnitDetails).toString() : undefined
        }));

        handlePress = progress => doublePress(
            () => this._openActivityTrackingCard(progress, false),
            () => this._openActivityTrackingCard(progress, true)
        );

        _openActivityTrackingCard = (progress, isDoubleTap) => {
            if (this.props.isDisabled) {
                if (this.props.isHomeCarousel) tracker.logEvent('ActivityTile_TrackDayDisabled_Click');
                return;
            }
            if (!progress.enable) {
                this.showErrorMessage(progress);
                return;
            }
            const allActivityData = this.getActivitiesToTrack(progress);
            const activityData = _.filter(allActivityData, item => _.get(item, 'activityUnitId'));
            if (allActivityData && allActivityData.length && !activityData.length) {
                this.showToast(this.props.i18n.t('dangerToastMessage'), this.props.i18n.t('track_an_activity'));
                return;
            }
            if (!activityData || activityData.length === 0) {
                if (this.props.isHomeCarousel) tracker.logEvent('ActivityTile_TrackDay_Click');
                tracker.logEvent('track_activity', { isDoubleTap, id: this.props.challengeId, error: 'only_automatic_activities' });
                const goal_or_competition = this.props.challenge.isSolo ? this.props.i18n.t('core.entities.goal') : this.props.i18n.t('core.entities.challenge');
                this.showToast(
                    this.props.i18n.t('only_automatic_activities_with_device', {
                        goal_or_competition
                    }),
                    this.props.i18n.t('track_an_activity')
                );
                return;
            }
            if (this.props.isHomeCarousel) tracker.logEvent('ActivityTile_TrackDay_Click');
            tracker.logEvent('track_activity', { isDoubleTap, id: this.props.challengeId, isCurrentWeek: this.props.isCurrentWeek });
            if (activityData.length === 1) {
                if (_.has(this, 'wrapped.showActivityTrackingModal')) {
                    this.wrapped.showActivityTrackingModal(
                        activityData, isDoubleTap, this.getActivityTrackingModalProps(activityData, isDoubleTap, progress));
                }
            } else if (_.has(this, 'wrapped.showTrackActivitiesList')) {
                tracker.logEvent('track_activity', { isDoubleTap });
                this.wrapped.showTrackActivitiesList(
                    activityData, isDoubleTap, this.getTrackActivitiesListProps(activityData, isDoubleTap));
            }
        };

        getActivityTrackingModalProps = (activities, isDoubleTap, progress) => ({
            activity: activities[0],
            fromSingleActivityGoal: true,
            isDoubleTap,
            challengeId: this.props.challengeId,
            progress,
            isHomeCarousel: this.props.isHomeCarousel,
        });

        getTrackActivitiesListProps = (activities, isDoubleTap) => ({
            title: moment(activities[0].date).format(DATE_FORMATS.fullDayShortMontNum),
            challengeId: this.props.challengeId,
            activities,
            fromSingleActivityGoal: true,
            pendingActivities: [],
            selectedDate: activities[0].date,
            customizePost: isDoubleTap,
            searchKey: ACTIVITIES_SEARCH_KEYS,
            isFocusedInput: true,
            isProgressTab: this.props.isWeekProgress,
            isHomeCarousel: this.props.isHomeCarousel,
        });

        getFontColor = progress => {
            if (progress.enable) {
                return progress.today ? baseColors.black : baseColors.grey40;
            }
            return baseColors.grey60;
        }

        showErrorMessage = progress => {
            if (progress.afterDeadline) {
                this.showToast(this.props.i18n.t('cannotTrackAfterDeadline'), this.props.i18n.t('track_an_activity'));
            } else if (progress.beforeStartDate) {
                this.showToast(this.props.i18n.t('cannotTrackPrior'), this.props.i18n.t('track_an_activity'));
            } else {
                this.showToast(this.props.i18n.t('cannotTrackFuture'), this.props.i18n.t('track_an_activity'));
            }
        };

        saveRef = ref => (this.wrapped = ref);

        dayFirstLetter = progress => moment(progress.date).format(DATE_FORMATS.dayFull)[0];

        dayNumber = progress => moment(progress.date).format(DATE_FORMATS.dayShort);

        isRestartGoalPreviousPeriods = item => {
            const { challenge } = this.props;
            return challenge && challenge.progress.restartGoalPreviousPeriods && item.date < challenge.startDate;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    ref={this.saveRef}
                    dayNumber={this.dayNumber}
                    handlePress={this.handlePress}
                    getFontColor={this.getFontColor}
                    dayFirstLetter={this.dayFirstLetter}
                    isRestartGoalPreviousPeriods={this.isRestartGoalPreviousPeriods}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => ({
        challenge: getChallenge(state, ownProps.challengeId),
        connectedDeviceName: getConnectedDeviceName(state, ownProps.challengeId),
        unitsForActivityCategory: getUnitsForActivityCategory(state, ownProps.challengeId),
        progressData: getChallengeProgressByUserActivityLogs(state, ownProps.challengeId, ownProps.isCurrentWeek),
        defaultDevice: getPlatformDevice(state)
    });

    const mapDispatchToProps = dispatch => ({
        actions: bindActionCreators({ ...coreActions, ...actions }, dispatch)
    });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(DatesSwiperBase));
}

const TODAY_INDICATOR_HEIGHT = 2;

export const styles = {
    itemContainer: {
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center',
        display: 'flex',
        height: '100%'
    },
    textContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: spacing.s1,
        marginBottom: spacing.s0,
        flexDirection: 'column'
    },
    todayIndicator: {
        width: '100%',
        height: TODAY_INDICATOR_HEIGHT,
        backgroundColor: baseColors.primary
    },
    transparentIndicator: {
        backgroundColor: 'transparent'
    },
};
