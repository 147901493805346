import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import EmptyListSimple from '../EmptyListSimple';
import { InfiniteLazyListFadeIn } from '../LazyComponents';
import WithAttendeesListBase from './AttendeesListBase';
import Modal from '../Modal';
import { AsyncComponent } from '../../services';

const ITEM_HEIGHT = 72; // approximate height of item, need for proper work of lazy list

class AttendeesList extends PureComponent {
    static propTypes = {
        members: PropTypes.array,
        i18n: PropTypes.object.isRequired,
        loadMore: PropTypes.func.isRequired,
        isLoading: PropTypes.bool,
        renderAttendee: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired,
        challengeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        isTeamChallenge: PropTypes.bool
    };

    static defaultProps = {
        members: [],
        isLoading: false,
        challengeId: undefined,
        isTeamChallenge: false
    };

    get emptyComponent() {
        return !this.props.isLoading ?
            <EmptyListSimple message={this.props.i18n.t('emptyListDefaultMessage')} /> : null;
    }

    onPressItem = id => {
        Modal.open(
            AsyncComponent(() => import('../../../other/components/UserProfile')),
            { id },
            { isContainer: true, isFullHeight: false }
        );
    };

    renderItem = ({ item }) => this.props.renderAttendee(item, this.onPressItem);

    keyExtractor = item => item.id || item.userId || item.entityId;

    render() {
        const { members, loadMore, ...props } = this.props;
        return (
            <InfiniteLazyListFadeIn
                {...props}
                data={members}
                keyExtractor={this.keyExtractor}
                renderItem={this.renderItem}
                onEndReached={loadMore}
                onEndReachedThreshold={0.1}
                ListEmptyComponent={this.emptyComponent}
                itemHeight={ITEM_HEIGHT}
            />
        );
    }
}

export default withRouter(WithAttendeesListBase(AttendeesList));