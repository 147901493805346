import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { baseColors, spacing, elements } from '../../../../styles';
import { translate } from '../../../core';
import { selectors as onboardingSelectors } from '../../../onboarding';
import * as selectors from '../../selectors';

export default function WithAppsDevicesListBase(WrappedComponent) {
    class AppsDevicesListBase extends PureComponent {
        static propTypes = {
            sections: PropTypes.array.isRequired,
            i18n: PropTypes.object.isRequired
        };

        getHeaderText = data => {
            let headerText;
            if (_.get(data, '0.connected') !== null) {
                if (data.length !== 0) {
                    headerText = _.get(data, '0.connected') ?
                        this.props.i18n.t('connected') :
                        this.props.i18n.t('available');
                }
            }
            return headerText;
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    getHeaderText={this.getHeaderText}
                />
            );
        }
    }

    const mapStateToProps = (state) => ({
        sections: selectors.getDevicesList(state),
        isOnboarding: onboardingSelectors.isShowingOnboarding(state)
    });

    return connect(mapStateToProps)(translate()(AppsDevicesListBase));
}

export const styles = {
    headerText: {
        ...elements.header4,
        color: baseColors.grey20
    },
    connectedData: {
        paddingTop: spacing.s2
    },
    connectedDataNew: {
        paddingLeft: spacing.s5,
        paddingRight: spacing.s5,
        paddingTop: 10,
        paddingBottom: 10,
        backgroundColor: baseColors.grey85,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderTopColor: baseColors.grey80,
        borderBottomColor: baseColors.grey80,
        marginTop: spacing.s5,
    }
};
