import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { components as Core } from '../../../core';
import EventListItem from '../EventListItem';
import WithEventsListBase from './EventsListBase';

class EventsList extends PureComponent {
    static propTypes = {
        title: PropTypes.string.isRequired,
        events: PropTypes.array,
        isLoading: PropTypes.bool,
        isPage: PropTypes.bool,
        loadMoreContent: PropTypes.func,
        hasMore: PropTypes.bool,
        twoColumns: PropTypes.bool
    };

    static defaultProps = {
        events: [],
        isPage: true,
        isLoading: false,
        loadMoreContent: null,
        hasMore: false,
        twoColumns: true
    };

    get header() {
        const { title, isPage } = this.props;
        return (
            <Core.SubHeader
                noLeftPadding={!isPage}
                noTopPadding={!isPage}
                titleSize={!isPage ? Core.SubHeader.SIZES.large : Core.SubHeader.SIZES.xlarge}
                title={title}
            />
        );
    }

    get wrapper() {
        return this.props.isPage ? Core.SmallerContainer : Core.Layout.WiderContainer;
    }

    render() {
        const { isLoading, title, events, hasMore, loadMoreContent, twoColumns } = this.props;
        return events.length ?
            <this.wrapper>
                {this.props.isPage ? <Core.EntityDetailsHeader hasBackButton={true} title={title}/> : null}
                {this.header}
                <Core.InfiniteLazyListFadeIn
                    data={events}
                    keyExtractor={item => item.eventId}
                    renderItem={rowData => (
                        <EventListItem event={rowData.item} type={title.toLowerCase()} />
                    )}
                    onEndReached={loadMoreContent}
                    onEndReachedThreshold={0.5}
                    isLoading={isLoading}
                    hasMore={hasMore}
                    isHorizontal={false}
                    twoColumns={twoColumns}
                />
            </this.wrapper> : null;
    }
}

export default WithEventsListBase(EventsList);
