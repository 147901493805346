import axios from 'axios';
import { getBaseUrl, constants as coreConstants } from '../core';
import { NAME, MAX_COUNT } from './constants';

const getUrl = endpoint => endpoint ? getBaseUrl(`${NAME}${endpoint}`) : getBaseUrl(NAME);

export function getAllEvents(parameters) {
    const params = { maxCount: MAX_COUNT, ...parameters };
    return axios.get(getUrl(), { params });
}

export function getUserUpcomingEvents(userId) {
    return axios.get(getBaseUrl(`users/${userId}`));
}

export function joinEvent(eventId) {
    return axios.post(getBaseUrl(`memberships/events/${eventId}`), {});
}

export function getEventById(eventId) {
    return axios.get(getUrl(`/${eventId}`));
}

export function createEvent(data) {
    return axios.post(getUrl(), data);
}
export function deleteEvent(eventId) {
    return axios.delete(getUrl(`/${eventId}`));
}

export function editEvent({ eventId, data }) {
    return axios.put(getUrl(`/${eventId}`), data);
}

export function leaveEvent(eventId) {
    return axios.delete(getBaseUrl(`memberships/events/${eventId}`));
}

export function getEventMembers(eventId, parameters) {
    const params = { start: 0, maxCount: coreConstants.MEMBERS_MAX_COUNT, order: 'firstname-asc', ...parameters };
    return axios.get(getBaseUrl(`memberships/events/${eventId}`), { params });
}

