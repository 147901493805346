import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Alert, translate } from '../../../core';

export default function WithUnsavedChangesWrapper(WrappedComponent) {
    class UnsavedChangesWrapper extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
        };

        constructor(props) {
            super(props);
            this.state = { hasChanges: false };
        }

        showAlert = (onDiscard, onSave) => {
            const { i18n } = this.props;
            Alert.alert(
                i18n.t('editorAlert.title'),
                i18n.t('editorAlert.text'),
                [
                    this.discardButton(onDiscard),
                    { text: i18n.t('editorAlert.saveAndContinue'), onPress: onSave, isPrimary: true }
                ]
            );
        };

        discardButton = onPress => {
            return { text: this.props.i18n.t('editorAlert.discardChanges'), onPress, isDangerButton: true };
        };

        hasUnsavedChanges = hasChanges => this.setState({ hasChanges });

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    hasChanges={this.state.hasChanges}
                    showAlert={this.showAlert}
                    hasUnsavedChanges={this.hasUnsavedChanges}
                />
            );
        }
    }

    return translate()(UnsavedChangesWrapper);
}
