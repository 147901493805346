import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { StyleSheet, css } from 'aphrodite-jss';

import { ROUTES, components as Core, AsyncComponent } from '../../../core';
import { FLOW_STEPS as STEPS } from '../../constants';
import WithOnboardingFlowBase, { styles as baseStyles } from './OnboardingFlowBase';
import OnboardingProgressBar from '../OnboardingProgressBar';
import { layoutStyle, spacing, importantClass, headerHeight, appFonts } from '../../../../styles';
import { constants as appsdevicesConstants } from '../../../appsdevices';

class OnboardingFlow extends PureComponent {
    static propTypes = {
        actions: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        isShowingOnboarding: PropTypes.bool,
        hasBackButton: PropTypes.bool,
        hasSkipButton: PropTypes.bool,
        hasAppsDevicesTitle: PropTypes.bool,
        hasEAPTitle: PropTypes.bool,
        hasEAPContactTitle: PropTypes.bool,
        hasPersonalizeTitle: PropTypes.bool,
        isRecommendedGoalsScreen: PropTypes.bool,
        hasOnboardingProgress: PropTypes.bool,
        isOnboardingIncomplete: PropTypes.bool,
        isTaskCard: PropTypes.bool,
        rightButtonLabel: PropTypes.string.isRequired,
        onRef: PropTypes.func,
        slug: PropTypes.string,
        onSkip: PropTypes.func,
        step: PropTypes.string,
        onNext: PropTypes.func,
        currentUser: PropTypes.object.isRequired,
        i18n: PropTypes.object.isRequired,
        afterOnboardingPath: PropTypes.string,
        redirectToOnboarding: PropTypes.bool,
        nextPath: PropTypes.string,
        isHomeCard: PropTypes.bool,
        isLiveBetter: PropTypes.bool,
        isLastPageInSection: PropTypes.bool.isRequired
    };

    static defaultProps = {
        hasBackButton: false,
        isShowingOnboarding: false,
        hasSkipButton: false,
        isRecommendedGoalsScreen: false,
        hasAppsDevicesTitle: false,
        isOnboardingIncomplete: false,
        isTaskCard: false,
        hasEAPTitle: false,
        hasEAPContactTitle: false,
        hasPersonalizeTitle: false,
        hasOnboardingProgress: false,
        onRef: null,
        slug: null,
        onSkip: null,
        step: null,
        onNext: null,
        afterOnboardingPath: undefined,
        redirectToOnboarding: false,
        nextPath: undefined,
        isHomeCard: false,
        isLiveBetter: false
    };

    static COMPONENTS = {
        [STEPS.welcomeUser]: AsyncComponent(() => import('../OnboardingWelcomeUser')),
        [STEPS.completeProfile]: AsyncComponent(() => import('../SampleBreatheTask')),
        [STEPS.levelBreakdown]: AsyncComponent(() => import('../LevelBreakdown')),
        [STEPS.rewards]: AsyncComponent(() => import('../RewardsIntro')),
        [STEPS.taskTracked]: AsyncComponent(() => import('../PointsDescription')),
        [STEPS.appsDevicesInfo]: AsyncComponent(() => import('../AppsDevicesIntro')),
        [STEPS.appsDevices]: AsyncComponent(() => import('../../../appsdevices/components/AppsDevices')),
        [STEPS.chooseGoal]: AsyncComponent(() => import('../ChooseGoal')),
        [STEPS.personalizeInfo]: AsyncComponent(() => import('../PersonalizeIntro')),
        [STEPS.interests]: AsyncComponent(() => import('../Interests')),
        [STEPS.healthTopics]: AsyncComponent(() => import('../HealthTopics')),
        [STEPS.healthObjectives]: AsyncComponent(() => import('../HealthObjectives')),
        [STEPS.healthSurveyInfo]: AsyncComponent(() => import('../HealthSurvey/HealthSurveyIntro')),
        [STEPS.healthSurvey]: AsyncComponent(() => import('../HealthSurvey')),
        [STEPS.healthSurveyResults]: AsyncComponent(() => import('../HealthSurvey/HealthSurveyResults')),
        [STEPS.recommendedGoals]: AsyncComponent(() => import('../RecommendedGoals')),
        [STEPS.eapIntro]: AsyncComponent(() => import('../ReachGoal')),
        [STEPS.eapSurvey]: AsyncComponent(() => import('../EAPSelectOptions')),
        [STEPS.eapContact]: AsyncComponent(() => import('../Contact')),
        [STEPS.visibilitySettings]: AsyncComponent(() => import('../VisibilitySettings')),
        [STEPS.helpUsImprove]: AsyncComponent(() => import('../HelpUsImprove'))
    };

    componentDidMount() {
        const { onRef, isOnboardingIncomplete, isTaskCard, isShowingOnboarding, history, redirectToOnboarding,
            actions, step, onNext } = this.props;
        if (onRef) onRef(this);
        if (!isOnboardingIncomplete && !isTaskCard && !isShowingOnboarding) {
            history.push(ROUTES.home());
        }
        if (redirectToOnboarding && !window.location.pathname.includes('onboarding')) {
            actions.setAfterOnboardingPath(this.props.nextPath);
            if (history.location.pathname.includes('apps-devices') && history.location.search.includes('?status=')) {
                if (window.opener) window.opener.postMessage(appsdevicesConstants.DEVICE_CONNECTION.SUCCESS);
                window.close();
            }
            history.push(ROUTES.onboardingFlow());
        }
        if (step === STEPS.notifications) onNext();
    }

    componentWillUnmount() {
        if (this.props.onRef) this.props.onRef(null);
    }

    get component() {
        return OnboardingFlow.COMPONENTS[this.props.step];
    }

    onNext = (nextStep, props = {}) => {
        if (nextStep) {
            this.props.history.push(
                ROUTES.onboardingFlowStep(nextStep),
                {
                    ...props,
                    isOnboarding: true,
                    isTaskCard: this.props.isTaskCard,
                    isHomeCard: this.props.isHomeCard,
                    slug: this.props.slug
                }
            );
        } else if (this.props.isTaskCard) {
            this.props.actions.setOnboardingComplete();
            this.props.history.push(this.props.isHomeCard ? ROUTES.home() : ROUTES.me());
        }
        else if (this.props.afterOnboardingPath) {
            this.props.history.push(this.props.afterOnboardingPath);
        }
        else {
            this.props.history.push(ROUTES.home());
        }
    };

    get renderBody() {
        return (
            this.component ?
                <this.component
                    {...this.props}
                    isOnboarding={true}
                    back={this.back}
                /> : null
        );
    }

    back = () => {
        this.props.history.goBack();
    };

    get skipButton() {
        const skipButtonId = _.camelCase(`${this.props.rightButtonLabel}BtnOnHeader`);
        return this.props.hasSkipButton ?
            <Core.Button id={skipButtonId} type="text" textStyle={styles.skipText} onPress={this.props.onSkip}>
                {this.props.rightButtonLabel}
            </Core.Button> : <div className={css(layoutStyle.buttonTextSmall)} />;
    }

    get progressBar() {
        return this.props.hasOnboardingProgress ? <OnboardingProgressBar step={this.props.step} /> : null;
    }

    get title() {
        if (this.props.hasAppsDevicesTitle) {
            return this.props.i18n.t('apps_devices_title');
        } else if (this.props.hasEAPTitle) {
            return this.props.i18n.t('EAP.title');
        } else if (this.props.hasEAPContactTitle) {
            return this.props.i18n.t('EAP.contactInfo');
        } else if (this.props.isRecommendedGoalsScreen) {
            return this.props.i18n.t('set_a_goal');
        }
        return null;
    }

    get personalize() {
        return this.props.slug === 'personalize';
    }

    get subheader() {
        const { hasBackButton, history, isLiveBetter, step } = this.props;
        const subheaderStyle = step === STEPS.chooseGoal ? styles.starterGoalsSubheader : null;
        return (
            <Core.SubHeader
                title={this.progressBar}
                titleSize={Core.SubHeader.SIZES.large}
                titleClassName={css(layoutStyle.noPadding)}
                left={hasBackButton ? <Core.BackButton id="backBtnOnHeader" onClick={history.goBack} /> : <span/>}
                className={css(layoutStyle.flexAlignStart, subheaderStyle, step === STEPS.appsDevices && styles.appsDevicesSub)}
                right={this.skipButton}>
                {this.title ?
                    <div className={css(styles.personalizeText, ((!isLiveBetter && step !== STEPS.recommendedGoals
                        && !this.personalize) || step === STEPS.appsDevices) && styles.personalizeTextNew)}>
                        {this.title}
                    </div> : null}
            </Core.SubHeader>
        );
    }

    get recommendedGoalsHeader() {
        return (
            <Core.SubHeader
                title={this.title}
                titleAlign="center"
                titleSize="medium"
                right={this.skipButton}
            />
        );
    }

    get isNewContainer() {
        return true;
    }

    get hasFixedHeader() {
        const { step, isLiveBetter } = this.props;
        if(isLiveBetter) return true;
        return step !== STEPS.appsDevicesInfo && step !== STEPS.appsDevices;
    }

    render() {
        const { step, isTaskCard, isLiveBetter } = this.props;
        return (
            <Core.SmallerContainer
                itemClassName={css(layoutStyle.bgWhite, styles.container, isTaskCard && styles.taskCardContainer, this.isNewContainer && styles.containerNew)}
                isFixedFooter={false} isOnboarding={true}
                className={css(layoutStyle.noPadding)}>
                {step === STEPS.recommendedGoals ? this.recommendedGoalsHeader : null}
                {step === STEPS.appsDevices && !isLiveBetter ? this.subheader : null}
                {this.renderBody}
            </Core.SmallerContainer>
        );
    }
}

const CONTAINER_TOP_OFFSET = 60;
const CONTAINER_BOTTOM_OFFSET = 60;
const SUBHEADER_HEIGHT = 48;
const styles = StyleSheet.create({
    ...baseStyles,
    container: importantClass({
        marginTop: spacing.s3,
        minHeight: `calc(100vh - ${spacing.s7}px)`,
        position: 'relative',
        marginBottom: spacing.s3
    }),
    containerNew: importantClass({
        marginTop: CONTAINER_TOP_OFFSET,
        position: 'relative',
        minHeight: 'auto',
        marginBottom: CONTAINER_BOTTOM_OFFSET,
        borderRadius: spacing.s1,
        boxShadow: '0px 2px 1px rgba(0, 0, 0, 0.02), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)'
    }),
    personalizeText: {
        ...baseStyles.personalizeText,
        paddingBottom: spacing.s3
    },
    personalizeTextNew: {
        ...appFonts.xlBold
    },

    starterGoalsSubheader: importantClass({
        minHeight: SUBHEADER_HEIGHT,
        height: SUBHEADER_HEIGHT,
        marginBottom: spacing.s3
    }),
    taskCardContainer: importantClass({
        minHeight: `calc(100vh - ${spacing.s7}px - ${headerHeight}px)`
    }),
    appsDevicesSub: importantClass({
        height: 0,
        minHeight: 0,
        marginBottom: SUBHEADER_HEIGHT
    })
});

export default WithOnboardingFlowBase(OnboardingFlow);
