import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { translate, selectors as coreSelectors, pointsHelper, color, BRAND_COLOR } from '../../../core';
import * as selectors from '../../selectors';
import { appFonts, spacing, baseColors } from '../../../../styles';

export const ICON_SIZE = 14;
export const ICON_NAME = 'check';
export const INFO_ICON_SIZE = 16;

export default function WithEventBonusBase(WrappedComponent) {
    class EventBonusBase extends PureComponent {
        static propTypes = {
            eventId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),    //eslint-disable-line
            event: PropTypes.object,
            i18n: PropTypes.object.isRequired,
            showCheck: PropTypes.bool,
            customPoints: PropTypes.string.isRequired
        };

        static defaultProps = {
            event: {},
            showCheck: false,
        };

        get hasReward() {
            return this.props.event.eventPoints > 0;
        }

        get isCompleted() {
            return moment().isAfter(this.props.event.eventEndDateTime) || this.props.showCheck;
        }

        get bonusLabel() {
            return ` +${pointsHelper.formatPoints(this.props.event.eventPoints, this.props.customPoints)}`;
        }

        getInfoModalProps = () => {
            const { i18n, event, customPoints } = this.props;
            const number = event.eventPoints;
            const points = customPoints;
            const fullText = i18n.t('eventBonusFull', { number, points });
            const boldText = i18n.t('eventBonusBold', { number, points });
            const htmlText = `${fullText.replace(boldText, `<strong>${boldText}</strong>`)}`;
            return {
                htmlText,
                iconName: 'question',
                isButtonVisible: false,
                buttonTitle: i18n.t('got_it'),
            };
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    isCompleted={this.isCompleted}
                    hasReward={this.hasReward}
                    bonusLabel={this.bonusLabel}
                    getInfoModalProps={this.getInfoModalProps}
                />
            );
        }
    }


    function mapStateToProps(state, ownProps) {
        return {
            event: selectors.getEvent(state, ownProps.eventId),
            customPoints: coreSelectors.getCustomPointsName(state)
        };
    }

    return connect(mapStateToProps)(translate()(EventBonusBase));
}

export const styles = {
    bonusText: {
        ...appFonts.smMedium,
        color: color.brandColorMaker(baseColors.primary, BRAND_COLOR.DARK_SHADE),
    },
    bonusBg: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: spacing.s0,
        paddingBottom: spacing.s0,
        paddingRight: spacing.s1,
        paddingLeft: spacing.s0,
        marginRight: spacing.s1,
        borderRadius: spacing.s0,
        backgroundColor: color.brandColorMaker(baseColors.primary, BRAND_COLOR.LIGHT_SHADE),
    },
    bonusWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    infoIconContainer: {
        padding: spacing.s1,
        paddingLeft: spacing.s0
    },
    modalText: {
        ...appFonts.smRegular
    },
    additionalInfo: {
        marginTop: spacing.s3,
        marginBottom: spacing.s7,
    }
};
