import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { css, StyleSheet } from 'aphrodite-jss';
import { components as Core, onKeyPress, ROUTES, } from '../../../core';
import DeviceItem from '../DeviceItem';
import { layoutStyle, importantClass, important, spacing, baseColors } from '../../../../styles';
import { components as Challenges } from '../../../challenges';
import WithChallengeRecommendedDevicesBase, { styles as baseStyles, SHADOW,
    WATCH_ICON_WIDTH, MOBILE_ICON_WIDTH } from './ChallengeRecommendedDevicesBase';

class ChallengeRecommendedDevices extends PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
        isAnyRecommendedConnected: PropTypes.bool.isRequired,
        recommendedDevices: PropTypes.array.isRequired,
        devicesList: PropTypes.array.isRequired,
        title: PropTypes.string.isRequired,
        linkedText: PropTypes.string.isRequired,
        noLinkedCardTitle: PropTypes.string.isRequired,
        noLinkedCardText: PropTypes.string.isRequired,
    };

    get appsDevicesInfo() {
        return this.props.isAnyRecommendedConnected
            ? this.linkedDevicesContent
            : this.noLinkedCard;
    }

    get linkedDevicesContent() {
        return (
            <>
                <p className={css(styles.linkedText)}>{this.props.linkedText}</p>
                <div className={css(styles.topListSeparator)} />
                <Core.List component="div" className={css(styles.listContainer)}>
                    {_.map(this.props.recommendedDevices, this.renderDeviceItem)}
                </Core.List>
            </>
        );
    }

    get noLinkedCard() {
        const { noLinkedCardTitle, noLinkedCardText, devicesList } = this.props;
        return (
            <div
                tabIndex="0"
                role="button"
                onClick={this.openAppsDevices}
                onKeyDown={onKeyPress.enter(this.openAppsDevices)}>
                <Core.Card className={css(styles.cardContainer)}>
                    <div className={css(layoutStyle.flexRow, styles.mRight)}>
                        <Core.Icon
                            type="fa"
                            fill="light"
                            name="watch-fitness"
                            className={css(styles.watchIcon)}
                        />
                        <Core.Icon
                            type="fa"
                            fill="light"
                            name="mobile"
                            className={css(styles.mobileIcon)}
                        />
                    </div>
                    <div className={css(layoutStyle.fw)}>
                        <div className={css(styles.noLinkedCardTitle, layoutStyle.flexSpaceBetween)}>
                            <p>{noLinkedCardTitle}</p>
                            <Core.Icon
                                type="fa"
                                fill="regular"
                                name="angle-right"
                                color={baseColors.grey50}
                                className={css(styles.angleIcon)}
                            />
                        </div>
                        <div className={css(styles.noLinkedCardText)}>{noLinkedCardText}</div>
                        <div className={css(layoutStyle.flexRow, styles.imagesWrapper)}>
                            {_.map(devicesList, this.renderDeviceImage)}
                        </div>
                    </div>
                </Core.Card>
            </div>
        );
    }

    openAppsDevices = () => this.props.history.push(ROUTES.appsDevices());

    renderDeviceImage = device => <Core.Image src={device.iconUrl} key={device.vendorId} className={css(styles.deviceImage)} />;

    renderDeviceItem = device => <DeviceItem vendorId={device.vendorId} key={device.vendorId} isChallenge={true} />;

    render() {
        return (
            <Fragment>
                <div className={css(styles.title, styles.withBorder)}>{this.props.title}</div>
                <Challenges.BonusChallengeMark
                    challengeId={this.props.challenge.challengeId}
                    isChallengeDetailsPage={true}
                    isAppsDevicesSection={true}
                    containerClassName={css(styles.bonusMarkContainer)}
                />
                {this.appsDevicesInfo}
            </Fragment>
        );
    }
}

export default WithChallengeRecommendedDevicesBase(ChallengeRecommendedDevices);

const styles = StyleSheet.create({
    ...baseStyles,
    listContainer: importantClass({
        paddingTop: 0,
        paddingBottom: 0,
        marginBottom: spacing.s7,
    }),
    cardContainer: importantClass({
        ...baseStyles.cardContainer,
        boxShadow: `0px 2px ${SHADOW.height}px rgba(0, 0, 0, ${SHADOW.opacity})`,
    }),
    watchIcon: {
        width: important(WATCH_ICON_WIDTH),
        marginRight: spacing.s0,
    },
    mobileIcon: {
        width: important(MOBILE_ICON_WIDTH),
    },
    angleIcon: {
        paddingLeft: spacing.s3,
    },
    bonusMarkContainer: {
        marginBottom: spacing.s3,
    },
    withBorder: {
        ...baseStyles.withBorder,
        borderTopStyle: 'solid',
    },
});
