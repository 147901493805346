import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

import WithNotificationsSettingsBase, { styles as baseStyles } from './NotificationsSettingsBase';
import { appFonts, baseColors, spacing } from '../../../../styles';
import { components as Core } from '../../../core';

class NotificationsSettings extends Component {
    static propTypes = {
        history: PropTypes.object.isRequired,
        tabs: PropTypes.array.isRequired,
        selectedTab: PropTypes.object,
        tabSelected: PropTypes.func.isRequired,
        changeSetting: PropTypes.func.isRequired,
        showSelectedTab: PropTypes.func.isRequired,
        title: PropTypes.string.isRequired,
        i18n: PropTypes.object.isRequired,
        allSettings: PropTypes.func.isRequired
    };

    static defaultProps = {
        selectedTab: undefined,
    };

    renderItem = (item, i) => {
        if (item.title) return <div className={css(styles.titleItem)} key={i}>{item.title}</div>;
        const option = {
            title: item.label,
            action: () => this.props.changeSetting(item),
            isSwitch: true,
            checked: item.value
        };
        return (
            <div className={css(styles.item)} key={item.localId}>
                <Core.ListOptionPopup {...option} />
            </div>
        );
    };

    renderNotificationItems = () => (
        <>
            <Core.SubHeader noTopPadding={true} noLeftPadding={true} title={this.props.i18n.t('notifications')} />
            {_.map(this.props.allSettings('notificationSettingTypeId'), this.renderItem)}
        </>
    );
    renderEmailItems = () => (
        <div className={css(styles.emailTitle)}>
            <Core.SubHeader noTopPadding={true} noLeftPadding={true} title={this.props.i18n.t('emails')} />
            {_.map(this.props.allSettings('privacySettingTypeId'), this.renderItem)}
        </div>
    );

    get isOpenedFromNotificationsPage() {
        return _.get(this.props.history.location.state, 'isOpenedFromNotificationsPage', false);
    }

    render() {
        if (this.isOpenedFromNotificationsPage) {
            return (
                <Core.SmallerContainer
                    widerHeaderComponent={(
                        <Core.EntityDetailsHeader
                            hasBackButton={true}
                            title={this.props.title}
                        />
                    )
                    }>
                    {this.renderNotificationItems()}
                    {this.renderEmailItems()}
                </Core.SmallerContainer>
            );
        }

        return (
            <>
                {this.renderNotificationItems()}
                {this.renderEmailItems()}
            </>
        );
    }
}

const styles = StyleSheet.create({
    ...baseStyles,
    titleItem: {
        ...appFonts.smMedium,
        justifyContent: 'flex-start',
        color: baseColors.grey40
    },
    item: {
        paddingLeft: 0
    },
    emailTitle: {
        marginTop: spacing.s7
    }
});

export default withRouter(WithNotificationsSettingsBase(NotificationsSettings));
