import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import classnames from 'classnames';
import { withTheme } from '@material-ui/styles';
import { components as Core, onKeyPress } from '../../../../core';
import { layoutStyle, spacing } from '../../../../../styles';
import WithContainerBase, { styles as baseStyles, BASE_CARD_HEIGHT } from './ContainerBase';

class CardContainer extends PureComponent {
    static propTypes = {
        onPress: PropTypes.func,
        children: PropTypes.node.isRequired,
        className: PropTypes.string,
        containerClassName: PropTypes.string,
        hasPointer: PropTypes.bool,
        ref: PropTypes.func,
        tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
        role: PropTypes.string
    };

    static defaultProps = {
        hasPointer: true,
        className: undefined,
        containerClassName: undefined,
        ref: undefined,
        onPress: undefined,
        tabIndex: '0',
        role: 'button'
    };

    get theme() {
        return {
            ...this.props.theme,
            palette: {
                ...this.props.theme.palette,
                primary: this.props.theme.palette.secondary
            },
            overrides: {
                MuiPaper: {
                    rounded: {
                        borderRadius: spacing.s1
                    },
                    elevation1: {
                        boxShadow: '0 1px 2px 0 rgba(0,0,0,.2), 0 0 0 1px rgba(0,0,0,.05)',
                    },
                },
            }
        };
    }

    render() {
        return (
            <div
                tabIndex={this.props.tabIndex}
                role={this.props.role}
                onKeyDown={onKeyPress.enter(this.props.onPress)}
                className={classnames(css(styles.cardWrapper, !this.props.hasPointer ? layoutStyle.noCursor : null), this.props.containerClassName)}
                onClick={this.props.onPress}
                ref={this.props.ref}>
                <Core.Card className={classnames(css(styles.cardContainer), this.props.className)} customTheme={this.theme}>
                    {this.props.children}
                </Core.Card>
            </div>
        );
    }
}

export default withTheme(WithContainerBase(CardContainer));

// For some reason without hardcoded height value
// height of each card is different, based only on content size

const styles = StyleSheet.create({
    ...baseStyles,
    cardWrapper: {
        ...baseStyles.cardWrapper,
        height: BASE_CARD_HEIGHT,
    },
});
