import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';

import { components as Core, numbers } from '../../core';
import { getUnreadCount, isMarkingAllRead } from '../selectors';

const NotificationsIcon = ({ unreadCount, isUpdating, ...props }) => {
    const [unread, setUnread] = useState(numbers.getBadgeNumber(unreadCount));

    useEffect(() => {
        if (!isUpdating && unread !== unreadCount) {
            setUnread(numbers.getBadgeNumber(unreadCount));
        }
    }, [isUpdating, unreadCount]);

    return (
        <Core.IconBadge {..._.omit(props, 'dispatch')} badgeContent={parseInt(unread) > 0 ? unread : null} />
    );
};
NotificationsIcon.propTypes = {
    iconSize: PropTypes.number,
    unreadCount: PropTypes.number
};

NotificationsIcon.defaultProps = {
    iconSize: null,
    unreadCount: 0
};

const mapStateToProps = state => ({
    unreadCount: getUnreadCount(state),
    isUpdating: isMarkingAllRead(state),
});

export default connect(mapStateToProps, null)(NotificationsIcon);