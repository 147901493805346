import { css, StyleSheet } from 'aphrodite-jss';
import { importantClass } from '../../../../styles';

const styles = StyleSheet.create({
    modal: importantClass({
        borderRadius: 10,
        boxShadow: 'none',
        height: 500
    }),
    modalContent: importantClass({
        padding: 0,
    })
});

const modalProps = {
    isContainer: true,
    isFullHeight: false,
    PaperProps: { classes: { root: css(styles.modal) } },
    contentClassName: css(styles.modalContent),
    fadeTransition: true,
    isNoPadding: true,
};

export default modalProps;
