import React, { PureComponent } from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import PropTypes from 'prop-types';
import { baseColors, windowSize, spacing, media, importantClass } from '../../../../styles';
import { ICON_SIZE, tourStyles, webStyles } from '../../styles';
import { components as Core } from '../../../core';
import WithDeviceToolTipBase, { styles as baseStyles, APPS_ICON_SIZE } from './DeviceToolTipBase';
import { ARROWS, UP_ARROW, DOWN_ARROW, ARROW_SIZE, isXSScreenResolution } from '../../constants';
import { DEVICES_DASHBOARD_STATUS } from '../../../appsdevices/constants';

const DEFAULT_CONTAINER_HEIGHT = 238;
const xSMenuWidth = 224;

class DeviceToolTip extends PureComponent {
    static propTypes = {
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string,
        onClose: PropTypes.func,
        onGotIt: PropTypes.func,
        arrow: PropTypes.oneOf([UP_ARROW, DOWN_ARROW]),
        position: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
            pageX: PropTypes.number,
            pageY: PropTypes.number,
            isTabBar: PropTypes.bool
        }),
        hasCloseButton: PropTypes.bool,
        disclaimer: PropTypes.node,
        iconName: PropTypes.string,
        backgroundColor: PropTypes.string.isRequired,
        titleColor: PropTypes.string.isRequired,
        actionColor: PropTypes.string.isRequired,
        type: PropTypes.object.isRequired
    };

    static defaultProps = {
        subtitle: undefined,
        onClose: null,
        onGotIt: null,
        arrow: UP_ARROW,
        hasCloseButton: true,
        disclaimer: undefined,
        position: undefined,
        iconName: undefined
    };

    constructor(props) {
        super(props);
        this.state = {
            // if tooltip is cropped user will see redrawing tooltip, we need DEFAULT_CONTAINER_HEIGHT to avoid this
            containerHeight: DEFAULT_CONTAINER_HEIGHT
        };
    }

    // max value when tool tip modal is visible
    get limitedPosition() {
        return windowSize.height - this.state.containerHeight;
    }

    // get function returns true if ToolTip has top arrow
    // if ToolTipModal is cropped, function will returns another arrow and ToolTipModal will be displayed correctly
    get isTopArrow() {
        return this.props.arrow === ARROWS.UP;
    }

    get isXSScreenResolutionAndMenuItem() {
        return isXSScreenResolution() && this.props.position.isTabBar;
    }

    get containerStyles() {
        const { position } = this.props;
        return { position: 'fixed', top: position.pageY, left: position.pageX };
    }

    get arrowStyles() {
        const styles = this.isXSScreenResolutionAndMenuItem ? { marginLeft: (windowSize.width - xSMenuWidth)/2 + spacing.s3 } : null;
        return { left: ARROW_SIZE + spacing.s3, ...styles };
    }

    get backgroundStyle() {
        return { backgroundColor: this.props.backgroundColor };
    }

    get titleStyle() {
        return { color: this.props.titleColor };
    }

    get actionStyle() {
        return { color: this.props.actionColor };
    }

    render() {
        const { disclaimer } = this.props;
        return (
            <div className={css(styles.mainContainer)}>
                <div
                    style={this.containerStyles}
                    className={css(styles.toolTipContainer)}
                    ref={ref => this.container = ref}>
                    <div className={css(styles.elementsContainer)}>
                        <div style={this.backgroundStyle} className={css(styles.deviceInnerContainerTop)}>
                            <div className={css(styles.appsDevicesHeaderContainer)}>
                                {this.props.title ? (
                                    <div className={css(styles.headerContainerTitle, styles.appsDevicesTitleContainer)}>
                                        {this.props.iconName ?
                                            <div className={css(styles.appsDevicesIcon)}>
                                                <Core.Icon name={this.props.iconName} type="light" size={APPS_ICON_SIZE} color={baseColors.primary} />
                                            </div> : null}
                                        <p style={this.titleStyle} className={css(styles.appsDevicesTitle, (this.props.iconName ? styles.appsDevicesTitleWithIcon : null))}>{this.props.title}</p>
                                    </div>
                                ) : null}

                                {this.props.hasCloseButton ?
                                    <Core.IconButton
                                        name="times-circle"
                                        type="light"
                                        size={ICON_SIZE}
                                        onClick={this.props.onClose}
                                        color={this.props.actionColor}
                                        className={css(styles.closeButton)}/> : null
                                }
                            </div>
                        </div>

                        {disclaimer}

                        <div
                            style={this.backgroundStyle}
                            className={css(styles.innerContainerBottom, this.props.iconName ? styles.actionWithIcon : null)}>
                            <Core.Button onPress={this.props.onGotIt} type="text">
                                <p style={this.actionStyle} className={css(styles.actionText)}>{this.props.subtitle}</p>
                            </Core.Button>
                        </div>
                    </div>
                    <div
                        style={this.arrowStyles}
                        className={css(styles.arrowContainer,
                            this.isTopArrow ? styles.arrowTopContainer : styles.arrowBottomContainer)}>
                        {this.props.type === DEVICES_DASHBOARD_STATUS.NONE ?
                            <div className={css(styles.triangle, this.isTopArrow ? styles.topArrow : styles.bottomArrow)} />
                            : <div className={css((this.props.type === DEVICES_DASHBOARD_STATUS.ERROR ? styles.errorTriangle : styles.warningTriangle), this.isTopArrow ? styles.topArrow : styles.bottomArrow)} />
                        }
                    </div>
                    <div className={css(styles.measure)} />
                </div>
            </div>
        );
    }
}


export default WithDeviceToolTipBase(DeviceToolTip);

export const SHADOW_OPACITY = 0.3;
const CLOSE_BTN_VERTICAL_PADDING = 10;
const MIN_TOOLTIP_WIDTH = 300;

const styles = StyleSheet.create({
    ...tourStyles,
    ...baseStyles,
    ...webStyles,
    toolTipContainer: {
        width: '100%',
        maxWidth: '980px',
        [media.xs]: {
            width: '50%',
            minWidth: MIN_TOOLTIP_WIDTH,
            paddingTop: spacing.s3,
            paddingBottom: spacing.s3
        }
    },
    elementsContainer: {
        marginTop: ARROW_SIZE,
        width: '50%',
        [media.xs]: {
            width: '100%',
            marginTop: 0
        }
    },
    appsDevicesTitleContainer: {
        ...baseStyles.appsDevicesTitleContainer,
        display: 'flex',
        paddingTop: spacing.s3,
    },
    appsDevicesHeaderContainer: {
        ...baseStyles.headerContainer,
        display: 'flex',
        marginBottom: 0,
    },
    errorTriangle: {
        position: 'relative',
        padding: spacing.s3,
        boxSizing: 'border-box',
        '&:after': {
            position: 'absolute',
            width: 0,
            height: 0,
            bottom: 0,
            boxSizing: 'border-box',
            borderStyle: 'solid',
            borderColor: `transparent transparent ${baseColors.danger} ${baseColors.danger}`,
            transformOrigin: '0 0',
            transform: 'rotate(-45deg)',
            boxShadow: `-2px 2px 2px 0px rgba(0, 0, 0, ${SHADOW_OPACITY})`,
            borderWidth: 11.5,
            marginLeft: 0,
            left: `-${spacing.s3}`,
            top: '50%',
            content: '""'
        }
    },
    warningTriangle: {
        position: 'relative',
        padding: spacing.s3,
        boxSizing: 'border-box',
        '&:after': {
            position: 'absolute',
            width: 0,
            height: 0,
            bottom: 0,
            boxSizing: 'border-box',
            borderStyle: 'solid',
            borderColor: `transparent transparent ${baseColors.warn} ${baseColors.warn}`,
            transformOrigin: '0 0',
            transform: 'rotate(-45deg)',
            boxShadow: `-2px 2px 2px 0px rgba(0, 0, 0, ${SHADOW_OPACITY})`,
            borderWidth: 11.5,
            marginLeft: 0,
            left: `-${spacing.s3}`,
            top: '50%',
            content: '""'
        }
    },
    closeButton: importantClass({
        paddingLeft: CLOSE_BTN_VERTICAL_PADDING,
        paddingRight: CLOSE_BTN_VERTICAL_PADDING,
    }),
    deviceInnerContainerTop: {
        ...baseStyles.deviceInnerContainerTop,
        paddingTop: 0,
        paddingRight: 0
    },
    innerContainerBottom: {
        ...baseStyles.innerContainerBottom,
        paddingBottom: 0
    }
});
