import { windowSize } from '../../styles/constants';
import { spacing } from '../../styles';

export const NAME = 'rewards';
export const NOT_PERSISTED = false;
export const SCROLL_THROTTLE = 16;
export const MAX_EARN_PARTNERS_REWARDS_DETAILS_COUNT = 4;
export const LEVEL_ITEM_SIZE = windowSize.width * 0.675;
export const LEVEL_RING_WIDTH = spacing.s2;
export const QUARTER = 'Quarter';
export const MOBILE_REWARD_IMAGE_MAX_HEIGHT = 140;
export const WEB_REWARD_IMAGE_HEIGHT = 240;
export const REWARD_IMAGE_MAX_WIDTH = 225;
export const WEB_IMAGE_SECTION_SPACING = 62;
export const WEB_CONTINUE_BUTTON_WIDTH = 111;
export const WEB_CONTINUE_BUTTON_HEIGHT = 40;

export const REDEEM_STATES = {
    initial: 'initial',
    submitting: 'submitting',
    submitOrder: 'submitOrder',
    orderConfirmation: 'orderConfirmation',
    reAuthentication: 'reAuthentication',
    orderError: 'orderError',
    autoSubmit: 'autoSubmit',
    sso: 'sso',
    goToOrderConfirmation: 'goToOrderConfirmation',
    redeem: 'redeem',
    getToken: 'getToken'
};

export const MAX_RANDOM_ITEMS = 3;

export const PRODUCT_TYPES = {
    FEATURED: 'FEATURED',
    ALL: 'ALL'
};

export const TOUCHABLE_TYPES = {
    LEVEL: 'LEVEL',
    TRANSACTION: 'TRANSACTION',
    ORDER: 'ORDER'
};

export const ACHIEVEMENTS_TABS = {
    achievementsInProgress: 'achievementsInProgress',
    achievementsEarned: 'achievementsEarned',
    allAchievements: 'allAchievements',
    upcomingAchievements: 'upcomingAchievements'
};

export const ACHIEVEMENTS_STATUSES = {
    earned: 'earned',
    inProgress: 'in_progress',
    upcoming: 'upcoming',
    available: 'available'
};


export const SSO_REWARDS = {
    authentication: 'ssoRewardAuthentication',
    reauthentication: 'ssoRewardReauthentication'
};

export const EARN_PARTNERS_STATUS_TYPES = {
    LINKED: 'linked',
    ERROR: 'error',
    PENDING: 'pending'
};

export const LINK_TYPES = {
    linkWithPartner: 'linkWithPartner',
    linkWithApp: 'linkWithApp',
    shopWithPartner: 'shopWithPartner'
};

export const EARN_PARTNERS_IS_ENABLED = true;

export const SHIPPING_FIELDS = {
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    CONTACT_NAME: 'contactName',
    ADDRESS1: 'address1',
    ADDRESS2: 'address2',
    CITY: 'city',
    REGION: 'region',
    COUNTRY: 'country',
    POSTAL_CODE: 'postalCode',
    PHONE: 'phone'
};

export const EARN_PARTNERS_TABS = {
    linkedTabId: 'linked',
    allTabId: 'all'
};

export const DETAILS_TABS = {
    earn: 'EARN',
    transactionLog: 'TRANSACTION_LOG',
    orderHistory: 'ORDER_HISTORY'
};

export const REDEEM_PRODUCT_ID = 'productIdToRedeem';

export const TRANSACTION_TYPES = {
    REWARD: 'reward',
    REDEMPTION: 'redemption',
    ADJUSTMENT: 'adjustment',
    CHALLENGE: 'challenge',
    GOAL: 'goal',
};

export const LEVEL_BADGE_SLUGS = {
    LEVEL_BRONZE: 'sprout-bronzelevelunlocked',
    LEVEL_SILVER: 'sprout-silverlevelunlocked',
    LEVEL_GOLD: 'sprout-goldlevelunlocked'
};
