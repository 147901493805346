import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { spacing, baseColors, elements } from '../../../../styles';
import * as actions from '../../actions';
import { triggerSSO } from '../../services/sso';
import { SSO_CONTEXT } from '../../constants';
import { translate, tracker } from '../../../core';
import { getCompany, getPartner } from '../../selectors';

export default function WithPartnerCustomSSOBase(WrappedComponent) {
    class PartnerCustomSSOBase extends PureComponent {
        static propTypes = {
            company: PropTypes.object.isRequired,
            partnerSSO: PropTypes.object.isRequired,
            callback: PropTypes.func.isRequired,
            i18n: PropTypes.object.isRequired,
            step: PropTypes.string.isRequired
        };

        signUpPartnerSSO = () => {
            const { uri, logout } = this.props.partnerSSO;
            tracker.logEvent('Registration_isMPL');
            triggerSSO({ target: uri, logout }, SSO_CONTEXT.signUp, this.props.company);
        };

        signUp = () => {
            tracker.logEvent('Registration_isNotMPL');
            this.props.callback(this.props.step);
        };

        get subtitle() {
            return _.has(this.props.partnerSSO, 'partnerSSOTitle')
                ? this.props.partnerSSO.partnerSSOTitle[this.props.i18n.acceptLanguage()]
                : this.props.i18n.t('registration.signup.areYouMedibankMember');
        }

        get label() {
            return _.has(this.props.partnerSSO, 'partnerSSOMemberLabel')
                ? this.props.partnerSSO.partnerSSOMemberLabel[this.props.i18n.acceptLanguage()]
                : this.props.i18n.t('registration.signup.isMedibankMember');
        }

        get noSsoLabel() {
            return _.has(this.props.partnerSSO, 'partnerSSONonMemberLabel')
                ? this.props.partnerSSO.partnerSSONonMemberLabel[this.props.i18n.acceptLanguage()]
                : this.props.i18n.t('registration.signup.isNotMedibankMember');
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    signUp={this.signUp}
                    signUpPartnerSSO={this.signUpPartnerSSO}
                    label={this.label}
                    noSsoLabel={this.noSsoLabel}
                    subtitle={this.subtitle}
                />
            );
        }
    }

    function mapStateToProps(state) {
        return {
            partnerSSO: getPartner(state).sso,
            company: getCompany(state)
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(PartnerCustomSSOBase));
}

export const styles = {
    button: {
        marginTop: spacing.s0,
        marginBottom: spacing.s2
    },
    headerText: {
        ...elements.header3,
        textAlign: 'center',
        marginBottom: spacing.s2,
        marginTop: spacing.s6,
    },
    container: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        paddingHorizontal: spacing.s5,
        backgroundColor: baseColors.white,
        marginBottom: spacing.s7,
    }
};
