import _ from 'lodash';
import { persistReducer } from 'redux-persist';
import * as types from './actionTypes';
import { actionTypes as coreActionTypes, Storage, tracker } from '../core';
import { NAME } from './constants';

export const initialState = {
    refreshTime: 0,
    unread_count: { count: 0, priorityNotificationCount: 0 },
    regular: [],
    priority: [],
    items: {},
    isFirstPriorityLoad: true,
    pushNotifications: {
        deviceId: ''
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_NOTIFICATIONS.SUCCESS: {
            const { items, ids } = action.payload;
            return {
                ...state,
                items: { ...state.items, ...items },
                regular: ids
            };
        }
        case types.GET_MORE_NOTIFICATIONS.SUCCESS: {
            const { items, ids } = action.payload;
            return {
                ...state,
                items: { ...state.items, ...items },
                regular: _.uniq([...state.regular, ...ids])
            };
        }
        case types.GET_UNREAD_COUNT.SUCCESS:
            return {
                ...state,
                refreshTime: new Date().getTime(),
                unread_count: action.payload
            };
        case types.GET_UNSEEN_COUNT.SUCCESS:
            return {
                ...state,
                refreshTime: new Date().getTime(),
                unseen_count: action.payload
            };
        case types.MARK_NOTIFICATION_READ.SUCCESS: {
            const { item } = action.payload;
            const priorityNotificationCount = _.includes(state.priority, item.notificationId) ? state.unread_count.priorityNotificationCount - 1 : state.unread_count.priorityNotificationCount;
            const unread_count = {
                count: state.unread_count.count - 1,
                priorityNotificationCount
            };
            return {
                ...state,
                items: { ...state.items, [item.notificationId]: { ...state.items[item.notificationId], ...item } },
                unread_count
            };
        }
        case types.MARK_ALL_NOTIFICATIONS_READ.SUCCESS: {
            const items = _.mapValues(state.items, value => ({ ...value, read: 1 }));
            const unread_count = {
                count: 0,
                priorityNotificationCount: 0
            };
            return {
                ...state,
                items,
                unread_count
            };
        }
        case types.MARK_ALL_NOTIFICATIONS_CLICKED.SUCCESS: {
            const items = _.mapValues(state.items, value => ({ ...value, clicked: 1 }));
            return {
                ...state,
                items
            };
        }
        case types.REGISTER_DEVICE_FOR_PUSH.SUCCESS: {
            return {
                ...state,
                pushNotifications: {
                    ...state.pushNotifications,
                    deviceId: action.data.deviceId
                }
            };
        }
        case coreActionTypes.RESET:
            return initialState;
        default:
            return state;
    }
};

const serialize = data => {
    try {
        return JSON.stringify(data);
    } catch (err) {
        const message = 'Failed to serialize notifications data';
        const serializeError = JSON.stringify({
            message,
            err
        });
        tracker.logError(serializeError);
        return JSON.stringify({});
    }
};

export default persistReducer({
    key: NAME,
    storage: Storage.storageType(),
    blacklist: ['isFirstPriorityLoad'],
    serialize
}, reducer);