import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import translate from '../../services/translate';
import { baseColors, spacing, appFonts } from '../../../../styles';
import { isLiveBetter } from '../../selectors';
import { hexToRgba } from '../../services/color';

export default function WithImageWithLabel(WrappedComponent) {
    class ImageWithLabelBase extends PureComponent {
        static propTypes = {
            isLiveBetter: PropTypes.bool,
            labelBackgroundColor: PropTypes.string,
        }

        static defaultProps = {
            isLiveBetter: false,
            labelBackgroundColor: baseColors.corporateImageLabel,
        }

        get labelBackgroundColor() {
            const { labelBackgroundColor, isLiveBetter } = this.props;

            if (isLiveBetter) {
                return labelBackgroundColor;
            }

            const backgroundColor = labelBackgroundColor.backgroundColor === 'transparent' ? 'transparent' : hexToRgba(baseColors.primary, 0.7);
            return { backgroundColor };
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    labelBackgroundColor={this.labelBackgroundColor}
                />
            );
        }
    }

    const mapStateToProps = state => ({ isLiveBetter: isLiveBetter(state) });

    return connect(mapStateToProps)(translate()(ImageWithLabelBase));
}

const LABEL_HEIGHT = spacing.s7;

export const styles = {
    labelContainer: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        width: '100%',
        color: baseColors.white,
        maxWidth: '100%',
        height: LABEL_HEIGHT,
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3,
    },
    commonLabelTextFontSize: {
        ...appFonts.xsMedium,
        color: baseColors.white,
    },
    companyNameTitle: {
        paddingLeft: spacing.s1,
    },
    labelEntityTitle: {
        paddingLeft: spacing.s0,
    },
    programBackgroundColor: {
        backgroundColor: baseColors.primaryImageLabel
    },
    corporationBackgroundColor: {
        backgroundColor: baseColors.corporateImageLabel
    }
};