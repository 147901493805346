import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import systemURLs from '../../../../config/api';
import * as actions from '../../actions';
import { actions as appsDevicesActions, selectors as appsDevicesSelectors } from '../../../appsdevices';
import global from '../../../../config/globals';
import { getGlobalSettings, getSlides, isRetail, isMultipleSignIn, isLoadingCompany, getLoginRegistrationStep, isLoggingIn, isCreatingAccount, isLoadingProgramDetails, getCreateAccountError, isCheckingSamlStatus, getSsoDetails } from '../../selectors';
import { selectors as coreSelectors, translate, actions as coreActions, timeout } from '../../../core';
import { appFonts, baseColors, commonPadding, spacing, windowSize } from '../../../../styles';

export const DEFAULT_ICON_SIZE = 90;

export default function WithWelcomeBase(WrappedComponent) {
    class WelcomeBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            slides: PropTypes.array.isRequired,
            globalSettings: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            build: PropTypes.string.isRequired,
            partnerSubdomain: PropTypes.string.isRequired,
            isRetail: PropTypes.bool.isRequired,
            isLoadingCompany: PropTypes.bool,
            isLoggingIn: PropTypes.bool,
            isCreatingAccount: PropTypes.bool,
            isLoadingProgramDetails: PropTypes.bool,
            createAccountError: PropTypes.object,
            isCheckingSamlStatus: PropTypes.bool,
            ssoDetails: PropTypes.object.isRequired,
        };

        static defaultProps = {
            isLoadingCompany: false,
            isLoggingIn: false,
            isCreatingAccount: false,
            isLoadingProgramDetails: false,
            isCheckingSamlStatus: false,
            createAccountError: undefined,
        };

        constructor(props) {
            super(props);
            this.state = { environment: global.systemEnvironment.environment };
        }

        changeEnv = isEmulator => () => {
            const currentEnv = global.systemEnvironment.environment;
            const allEnvs = Object.keys(systemURLs);
            let tempNextEnv = allEnvs[((allEnvs.findIndex(env => env === currentEnv) + 1) % allEnvs.length)];
            if (tempNextEnv === 'local' && !isEmulator) tempNextEnv = allEnvs[((allEnvs.findIndex(env => env === tempNextEnv) + 1) % allEnvs.length)];
            const nextEnv = tempNextEnv;
            global.systemEnvironment = { environment: nextEnv, ...systemURLs[nextEnv] };
            global.savedLoginUser = null; // Any saved user should be erased
            this.setState({ environment: nextEnv });
            this.props.actions.internalStateReset(true);
            this.props.actions.saveSystemEnvironment(nextEnv);
            if (this.props.partnerSubdomain) {
                this.props.actions.getProgramConfigurations({ programName: this.props.partnerSubdomain });
            }
        };

        get signinLabel() {
            return this.state.environment === 'live'
                ? this.props.i18n.t('signin')
                : `${this.props.i18n.t('signin')} ${this.state.environment}`;
        }

        get isLoggingInTelus() {
            const { isTelus, isLoggingIn, isCreatingAccount, isLoadingProgramDetails } = this.props;

            if (!isTelus) {
                return false;
            }

            return isLoggingIn || isCreatingAccount || isLoadingProgramDetails;
        }

        get hasTelusCreateAccountError() {
            const { isTelus, createAccountError } = this.props;

            return isTelus && createAccountError;
        }

        clearTelusCreateAccountError = () => {
            if (this.props.createAccountError) {
                setTimeout(() => this.props.actions.clearCreateAccountError(), 3000);
            }
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    changeEnv={this.changeEnv}
                    environment={this.state.environment}
                    signinLabel={this.signinLabel}
                    isLoggingInTelus={this.isLoggingInTelus}
                    hasTelusCreateAccountError={this.hasTelusCreateAccountError}
                    clearTelusCreateAccountError={this.clearTelusCreateAccountError}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        return {
            globalSettings: getGlobalSettings(state),
            slides: getSlides(state),
            build: coreSelectors.getBuildName(state),
            partnerSubdomain: coreSelectors.getPartnerSubdomain(state),
            isRetail: isRetail(state),
            isMultipleSignIn: isMultipleSignIn(state),
            isLoadingCompany: isLoadingCompany(state),
            isConnecting: appsDevicesSelectors.isConnecting(state),
            currentUser: coreSelectors.getCurrentUser(state),
            isLiveBetter: coreSelectors.isLiveBetter(state),
            registrationStep: getLoginRegistrationStep(state),
            isTelus: coreSelectors.isTelus(state),
            isLoggingIn: isLoggingIn(state),
            isCreatingAccount: isCreatingAccount(state),
            isLoadingProgramDetails: isLoadingProgramDetails(state),
            createAccountError: getCreateAccountError(state),
            isCheckingSamlStatus: isCheckingSamlStatus(state),
            ssoDetails: getSsoDetails(state),
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators({ ...actions, ...appsDevicesActions, ...coreActions }, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(timeout(WelcomeBase)));
}

export const styles = {
    title: {
        ...appFonts.xlMedium,
        textAlign: 'left',
        color: baseColors.black
    },
    description: {
        ...appFonts.mdRegular,
        textAlign: 'left',
        color: baseColors.black,
        paddingTop: commonPadding
    },
    iconContainer: {
        marginTop: windowSize.height * 0.20,
        marginBottom: windowSize.height * 0.03,
        marginHorizontal: commonPadding,
        flexDirection: 'row',
        display: 'flex',
    },
    buttonContainer: {
        marginTop: windowSize.height * 0.01,
        marginBottom: windowSize.height * 0.03,
        paddingBottom: spacing.s0,
        paddingHorizontal: commonPadding,
        flex: 1,
        flexDirection: 'row',
        display: 'flex',
    },
};
