import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { StyleSheet, css } from 'aphrodite-jss';
import { components as Core, ROUTES } from '../../../core';
import AchievementItem from '../Achievements/AchievementItem';
import WithMyAchievementsBase, { styles as baseStyles } from './MyAchievementsBase';
import { spacing } from '../../../../styles';
import { ACHIEVEMENTS_TABS } from '../../constants';

class MyAchievements extends PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
        itemsToRender: PropTypes.array,
        selectedTab: PropTypes.object,
        tabs: PropTypes.array,
        tabSelected: PropTypes.func.isRequired,
        title: PropTypes.string.isRequired,
    };

    static defaultProps = {
        itemsToRender: [],
        tabs: [],
        selectedTab: undefined
    };

    get renderSelectedTab() {
        return (
            <div>
                {this.props.itemsToRender.length ?
                    _.map(this.props.itemsToRender, this._renderItem) :
                    this.emptyComponent
                }
            </div>
        );
    }

    get emptyComponent() {
        return <Core.EmptyListSimple className={styles.emptyTest} message={this.props.selectedTab.emptyText} />;
    }

    showAchievementDetails = achievementId => {
        const passProps = {
            isUpcoming: this.props.selectedTab.id === ACHIEVEMENTS_TABS.upcomingAchievements
        };
        this.props.history.push(ROUTES.achievementDetails(achievementId), { ...passProps });
    };

    _renderItem = (item, index) => <AchievementItem key={`${item.id}_${index}`} achievement={item} achievementId={item.id} onPress={this.showAchievementDetails} />;

    render() {
        return (
            <Core.Layout.WiderContainer>
                <Core.EntityDetailsHeader
                    hasBackButton={true}
                    title={this.props.title}
                />
                <Core.SubHeader
                    title={this.props.title}
                    noTopPadding={true}
                />
                <Core.TabsWithContent
                    tabClassName={css(styles.tabs)}
                    tabs={this.props.tabs}
                    onChange={this.props.tabSelected}
                    activeTab={this.props.selectedTab}
                    isStickyUnderNavBar={true}>
                    {this.renderSelectedTab}
                </Core.TabsWithContent>
            </Core.Layout.WiderContainer>
        );
    }
}

const styles = StyleSheet.create({
    ...baseStyles,
    emptyTest: {
        ...baseStyles.emptyTest,
        marginTop: spacing.s12 * 2
    }
});

export default WithMyAchievementsBase(MyAchievements);
