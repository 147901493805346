import global from '../../../config/globals';

export default function getBaseUrl(endPoint, isV2API = false) {
    const sub = (global.systemSubdomain !== '') ? global.systemSubdomain : 'login';
    let base = global.systemEnvironment.SPROUT_BASE_URL.replace('{subdomain}', sub);

    if (isV2API) {
        base = base.replace('/v1/', '/v2/');
    }

    return base + endPoint;
}