import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';

const SkeletonRect = React.memo(({ className, ...props }) => {
    const skeleton = (
        <Skeleton {...props} />
    );
    return (
        className ?
            <div className={classnames(className)}>{skeleton}</div> :
            skeleton
    );
});

SkeletonRect.propTypes = {
    className: PropTypes.string
};

SkeletonRect.defaultProps = {
    className: null
};

export default SkeletonRect;