import { createActionTypes } from './services';
import { NAME as name } from './constants';

const NAME = name.toUpperCase();

export const ADD_ERROR = `${NAME}/ADD_ERROR`;
export const REMOVE_ERROR = `${NAME}/REMOVE_ERROR`;

export const ADD_TOAST = `${NAME}/ADD_TOAST`;
export const REMOVE_TOAST = `${NAME}/REMOVE_TOAST`;

export const RESET = `${NAME}/RESET`;

export const GET_FILTERS = createActionTypes(`${NAME}/GET_FILTERS`);
export const GET_CURRENT_USER = createActionTypes(`${NAME}/GET_CURRENT_USER`);

export const GET_URL_PREVIEW = `${NAME}/GET_URL_PREVIEW`;

export const UPDATE_CONNECTION_STATE = `${NAME}/UPDATE_CONNECTION_STATE`;

export const CHANGE_LANGUAGE = `${NAME}/CHANGE_LANGUAGE`;
export const CHANGE_SCREEN_SIZE = `${NAME}/CHANGE_SCREEN_SIZE`;

export const REQUEST_RATING = createActionTypes(`${NAME}/REQUEST_RATING`);
export const SET_INIT_TIME = `${NAME}/SET_INIT_TIME`;

export const SAVE_SMART_MODE_STATUS = `${NAME}/SAVE_SMART_MODE_STATUS`;
