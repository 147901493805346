import _ from 'lodash';
import React, { PureComponent } from 'react';
import pubSub from './PubSub';
import TOPICS from './topics';

export default function WithPubSub(WrappedComponent) {
    class PubSub extends PureComponent {
        static propTypes = {};

        static defaultProps = {};

        constructor(props) {
            super(props);
            this.subscriptions = [];

            this.pubSub = {
                subscribe: this.subscribe,
                publish: pubSub.publish,
                publishSync: pubSub.publishSync,
                unsubscribe: pubSub.unsubscribe,
                TOPICS
            };
        }

        componentWillUnmount() {
            _.forEach(this.subscriptions, s => s());
        }

        subscribe = (topic, fn) => {
            const unsubscribe = pubSub.subscribe(topic, fn);
            this.subscriptions.push(unsubscribe);
            return unsubscribe;
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    pubSub={this.pubSub}
                />
            );
        }
    }

    return PubSub;
}
