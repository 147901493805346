import { createSelector } from 'reselect';
import _ from 'lodash';
import { NAME, TYPES } from './constants';
import { selectors as coreSelectors, constants as coreConstants, numbers, entitiesHelper,
    PARTICIPANTS_NUMBER_AVATARS, getRequestId
} from '../core';
import * as types from './actionTypes';
import i18n from '../../i18n';

export const getCommunities = state => state[NAME].items;
export const numAttended = state => state[NAME].numAttended;
export const numAll = state => state[NAME].numAll;
export const numRecommended = state => state[NAME].numRecommended;
export const numInvites = state => state[NAME].numInvites;
export const numRecent = state => state[NAME].numRecent;
export const getRefreshTime = state => state[NAME].refreshTime;

export const getSingleCommunity = (state, id) => getCommunities(state)[id];

const getRecommendedCommunitiesIds = (state, isCarousel) => getIds(state[NAME].recommended, isCarousel);
const getMyCommunitiesIds = (state, isCarousel) => getIds(state[NAME].attending, isCarousel);
export const getInvitedCommunitiesIds = (state, isCarousel) => getIds(state[NAME].invites, isCarousel);
const getRecentCommunitiesIds = (state, isCarousel) => getIds(state[NAME].recent, isCarousel);
const getAllCommunitiesIds = (state, isCarousel) => getIds(state[NAME].all, isCarousel);
const isCarousel = (state, isCarousel = false) => isCarousel;

const getItems = (items, ids, isCarousel) => _.map(getIds(ids, isCarousel), id => items[id]);
const getIds = (items, isCarousel = false) => isCarousel ? items.slice(0, coreConstants.MAX_SLIDES_COUNT) : items;

export const getAllCommunities = createSelector(getCommunities, getAllCommunitiesIds, isCarousel, getItems);
export const getRecommendedCommunities = createSelector(getCommunities, getRecommendedCommunitiesIds, isCarousel, getItems);
export const getMyCommunities = createSelector(getCommunities, getMyCommunitiesIds, isCarousel, getItems);
export const getInvitedCommunities = createSelector(getCommunities, getInvitedCommunitiesIds, isCarousel, getItems);
export const getRecentCommunities = createSelector(getCommunities, getRecentCommunitiesIds, isCarousel, getItems);

const getMembers = state => state[NAME].members;
const getAllMembers = state => getMembers(state).items;
const getMembersIds = state => getMembers(state).ids;
const getCommunityMembersIds = (state, id) => getMembersIds(state)[id];
export const getCommunityMembers = createSelector(getAllMembers, getCommunityMembersIds, coreSelectors.getThirdParam,
    (items, ids, isShortList) =>
        isShortList ? _.map(_.take(ids, PARTICIPANTS_NUMBER_AVATARS), id => items[id]) : _.filter(_.map(ids, id => items[id]), _.identity));

export const getCommunityPrivateMembersCount = (state, id) =>
    numbers.intOrZero(_.get(getSingleCommunity(state, id), 'numPrivateGroupMembers'));

export const getCommunityMembersCount = (state, id) =>
    numbers.intOrZero(_.get(getSingleCommunity(state, id), 'numGroupMembers'));

export const getCommunityMembersAndPrivateSummary = createSelector(getCommunityMembers, getCommunityPrivateMembersCount,
    (members, privateCount) => privateCount === 0 ? members : [...members, {
        ...entitiesHelper.getPrivateSummary(),
        firstName: i18n.t('privateUsersFirstNamePlaceHolderCommunity', { n: privateCount }),
        lastName: i18n.t('privateUsersLastNamePlaceHolderCommunity'),
        description: i18n.t('privateUsersDescriptionCommunity'),
    }]
);

export const COMMUNITIES_DATA_BY_TYPE = {
    [TYPES.ATTENDED]: {
        items: getMyCommunities,
        count: numAttended
    },
    [TYPES.INVITATIONS]: {
        items: getInvitedCommunities,
        count: numInvites
    },
    [TYPES.RECOMMENDED]: {
        items: getRecommendedCommunities,
        count: numRecommended
    },
    [TYPES.RECENT]: {
        items: getRecentCommunities,
        count: numRecent
    },
    [TYPES.ALL]: {
        items: getAllCommunities,
        count: numAll
    }
};

export const communitiesLoading = state => coreSelectors.isLoading(state, types.GET_COMMUNITIES.NAME);
export const communityLoading = state => coreSelectors.isLoading(state, types.GET_SINGLE_COMMUNITY.NAME);
export const communityJoining = (state, id) => coreSelectors.isLoading(state, getRequestId(types.JOIN_COMMUNITY.NAME, id));
export const communityEditing = state => coreSelectors.isLoading(state, types.EDIT_COMMUNITY.NAME);
export const isLoadingCommunityMembers = state => coreSelectors.isLoading(state, types.GET_COMMUNITY_MEMBERS.NAME);

export const isJoiningCommunity = (state, id) => coreSelectors.isLoading(state, getRequestId(types.JOIN_COMMUNITY.NAME, id));
export const getJoiningError = (state, id) => coreSelectors.getError(state, getRequestId(types.JOIN_COMMUNITY.NAME, id));

export const getLastCreatedCommunityId = state => state[NAME].lastCreatedId;

export const getCreateCommunityStep = state => state[NAME].createCommunityStep;
export const getNewCommunity = state => state[NAME].newCommunity;
export const isCreatingCommunity = state => coreSelectors.isLoading(state, types.CREATE_COMMUNITY.NAME);
export const getCreateCommunityError = state => coreSelectors.getError(state, types.CREATE_COMMUNITY.NAME);
export const getLastCreatedCommunity = state => getCommunityObject(state, getLastCreatedCommunityId(state));
export const getCommunityObject = (state, id) => getCommunities(state)[id];
export const getCommunitiesByName = createSelector(
    getCommunities, getNewCommunity,
    (communities, newCommunity) => {
        const newName = newCommunity ? newCommunity.name : null;
        if (!newName) return [];
        return _.filter(_.values(communities), c => c.name && _.includes(c.name.toLowerCase(), newName.toLowerCase()));
    }
);
export const isNotificationShown = createSelector(getSingleCommunity, community => _.get(community, 'isNotificationShown'));
