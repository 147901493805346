import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { css, StyleSheet } from 'aphrodite-jss';
import { containerStyle, layoutStyle, importantStyles, spacing } from '../../../../styles';
import { components as Core, onKeyPress, openUrl, Modal } from '../../../core';
import { constants as settingsConstants, components as Settings } from '../../../settings';
import Logo from '../Logo';
import LocationDetails from '../LocationDetails';
import WithCDLSelectionBase, { styles as baseStyles } from './CDLSelectionBase';

class CDLSelection extends PureComponent {
    static propTypes = {
        changeLocation: PropTypes.func.isRequired,
        submit: PropTypes.func.isRequired,
        isCorporateLinking: PropTypes.bool,
        isLoading: PropTypes.bool,
        i18n: PropTypes.object.isRequired,
        disabled: PropTypes.bool.isRequired,
        context: PropTypes.string,
        termsOfService: PropTypes.string,
        privacyPolicy: PropTypes.string,
        isTelus: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        isLoading: false,
        isCorporateLinking: false,
        context: undefined,
        termsOfService: undefined,
        privacyPolicy: undefined,
    };

    openTerms = () => {
        const { termsOfService } = this.props;
        if (_.get(termsOfService, 'terms_url')) {
            openUrl(termsOfService.terms_url);
        } else {
            termsOfService && this.openModal(settingsConstants.LIST_ITEM_TYPES.terms);
        }
    }

    openPrivacy = () => this.props.privacyPolicy && this.openModal(settingsConstants.LIST_ITEM_TYPES.policy);

    openModal = legalType => {
        Modal.open(Settings.Legals, { legalType, isPopup: true, isModal: true }, {
            cancelable: true,
            isNoPadding: true,
            isContainer: true,
            isFullHeight: true,
            isNoPaddingHorizontal: true,
            isNoWidthLimit: true,
            maxWidth: 600,
            isMaxHeight: true
        });
    };

    render() {
        const { isTelus, i18n } = this.props;
        return (
            <div className={css(layoutStyle.fw)}>
                <div className={css(containerStyle.container)}>
                    <div className={css(layoutStyle.textCenter)}>
                        <Core.SubHeader titleAlign={Core.SubHeader.ALIGN.center} left={<span />} title={this.props.i18n.t('auth.setYourDetails')} noTopPadding={true} className={css(styles.header)} />
                        {this.props.isCorporateLinking ? <Logo /> : null}
                    </div>
                    <div className={css(containerStyle.main)}>
                        <LocationDetails
                            changeLocationCallback={this.props.changeLocation}
                            isRegistration={true}
                            context={this.props.context}
                        />
                        {isTelus ? (
                            <div className={css(styles.policyContainer)}>
                                <p className={css(styles.policyTextWrapper)}>
                                    <span className={css(styles.policyText)}>
                                        {i18n.t('termsAndPrivacyPolicyNew')}
                                    </span>
                                    <span
                                        role="button"
                                        tabIndex="0"
                                        onKeyDown={onKeyPress.enter(this.openTerms)}
                                        className={css(styles.linkPolicyText)}
                                        onClick={this.openTerms}>
                                        {' '}{i18n.t('termsOfService')}{' '}
                                    </span>
                                    <span className={css(styles.policyText)}>
                                        {i18n.t('and')}
                                    </span>
                                    <span
                                        role="button"
                                        tabIndex="0"
                                        onKeyDown={onKeyPress.enter(this.openPrivacy)}
                                        className={css(styles.linkPolicyText)}
                                        onClick={this.openPrivacy}>
                                        {' '}{i18n.t('privacyPolicy')}
                                    </span>
                                </p>
                            </div>
                        )
                            : null}
                    </div>
                    <div className={css(containerStyle.largeFooterButtonContainer)}>
                        <Core.Button
                            id="nextOnLocationButton"
                            fullWidth={true}
                            disabled={this.props.disabled}
                            isLoading={this.props.isLoading}
                            useTelusColors={isTelus}
                            onPress={this.props.submit}>
                            {this.props.i18n.t('next')}
                        </Core.Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default WithCDLSelectionBase(CDLSelection);

const styles = StyleSheet.create({
    ...baseStyles,
    ...importantStyles({
        header: {
            marginBottom: 0,
            minHeight: 0
        }
    }),
    policyContainer: {
        ...baseStyles.policyContainer,
        marginTop: spacing.s7
    }
});