import React, { PureComponent } from 'react';
import { translate } from '../../../core';
import { baseColors, spacing, windowSize } from '../../../../styles';

export default function WithAddedUsersList(WrappedComponent) {
    class AddedUsersListBase extends PureComponent {
        render() {
            return (
                <WrappedComponent
                    {...this.props}
                />
            );
        }
    }

    return translate()(AddedUsersListBase);
}

export const styles = {
    mainContainer: {
        paddingLeft: spacing.s2,
        paddingRight: spacing.s3,
        paddingBottom: spacing.s3,
        backgroundColor: baseColors.white,
    },
    list: {
        flexDirection: 'row'
    },
    loaderWrapper: {
        position: 'absolute',
        top: 0,
        zIndex: 1,
        backgroundColor: baseColors.white,
        width: windowSize.width,
        height: windowSize.height,
        paddingTop: spacing.s3,
        paddingBottom: spacing.s3,
        alignItems: 'center'
    },
    offsetTop: {
        paddingTop: spacing.s3
    },
    container: {
        position: 'relative'
    }
};
