import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';
import classnames from 'classnames';
import { isIE } from 'react-device-detect';
import { InputAdornment } from '@material-ui/core';
import translate from '../../services/translate';
import { baseColors, spacing, inputButtonSize, navButtonSize, importantClass } from '../../../../styles';
import Icon, { IconButton } from '../Icon';
import Input from '../Input';

const SEARCH_MAX_LENGTH = 100;

class SearchBar extends PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        label: PropTypes.string,
        value: PropTypes.string,
        onStop: PropTypes.func,
        className: PropTypes.string,
        isSearching: PropTypes.bool,
        onClear: PropTypes.func.isRequired,
        isCustomizedSearchBar: PropTypes.bool,
        addWhiteBg: PropTypes.bool
    };

    static defaultProps = {
        label: undefined,
        value: undefined,
        onStop: undefined,
        className: undefined,
        isSearching: false,
        isCustomizedSearchBar: false,
        addWhiteBg: false
    };

    onClear = event => {
        event.stopPropagation();
        event.preventDefault();
        this.props.onClear();
    };

    get renderClearButton() {
        const { isSearching, value, isCustomizedSearchBar } = this.props;
        return isSearching && value ?
            <IconButton
                onMouseDown={this.onClear}
                type="fa"
                fill={isCustomizedSearchBar ? 'solid' : 'light'}
                name={isCustomizedSearchBar ? 'times-circle' : 'times'}
                size={inputButtonSize}
                color={isCustomizedSearchBar ? baseColors.grey50 : baseColors.secondary}
            /> : null;
    }

    get searchIcon() {
        return (
            <InputAdornment position="start">
                <Icon
                    name="search"
                    type="fa"
                    size={spacing.s3}
                    color={baseColors.grey50}
                />
            </InputAdornment>
        );
    }

    render() {
        const { onStop, className, label, isSearching, addWhiteBg, ...props } = this.props;
        return (
            <div className={classnames(css(!isIE && styles.searchBarContainer), className)}>
                {isSearching && onStop ?
                    <div className={css(styles.searchBarTouchable)}>
                        <IconButton
                            onClick={onStop}
                            type="fa"
                            name="angle-left"
                            size={navButtonSize}
                            color={baseColors.secondary}
                        />
                    </div> : null
                }
                <div className={css(styles.inputWrapper)}>
                    <Input
                        placeholder={label}
                        customType="search"
                        fullWidth={true}
                        inputProps={{ maxLength: SEARCH_MAX_LENGTH }}
                        className={addWhiteBg ? css(styles.whiteBg) : null}
                        {...props}
                    />
                </div>
            </div>
        );
    }
}

export default translate()(SearchBar);

const styles = StyleSheet.create({
    searchBarContainer: {
        flex: 1,
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    searchBarTouchable: {
        flex: 0,
        marginRight: spacing.s1,
    },
    inputWrapper: {
        flex: 1,
    },
    whiteBg: importantClass({
        backgroundColor: baseColors.white
    })
});
