import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
    translate,
    constants as coreConstants,
    Storage,
    Platform,
    PLATFORMS,
    ENTITIES_ACTIVE_TAB,
    selectors as coreSelectors,
} from '../../../core';
import * as contentActions from '../../actions';
import * as selectors from '../../selectors';
import { TYPES, CATEGORIES } from '../../constants';
import { SLUGS } from '../../../settings/constants';
import { getRequestParams, isFeatured } from '../../services';

export default function WithContentBase(WrappedComponent) {
    class ContentBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            recommendedContent: PropTypes.object,
            recommendedContentCount: PropTypes.object,
            promotedContent: PropTypes.object,
            promotedContentCount: PropTypes.object,
            categories: PropTypes.array,
            isLoadingSelector: PropTypes.bool,
            isSearching: PropTypes.bool,
            category: PropTypes.string,
            i18n: PropTypes.object.isRequired,
            tags: PropTypes.array.isRequired,
            hasVideoContent: PropTypes.bool,
            isLoadingContentCount: PropTypes.number,
            isLoadingPromotedContent: PropTypes.bool
        };

        static defaultProps = {
            isLoadingSelector: true,
            isSearching: false,
            recommendedContent: null,
            recommendedContentCount: null,
            promotedContent: null,
            promotedContentCount: null,
            categories: [],
            category: undefined,
            hasVideoContent: false,
            isLoadingContentCount: 0,
            isLoadingPromotedContent: false
        };

        constructor(props) {
            super(props);
            this.TABS = {};
            this.TABS = {
                FOR_YOU: { id: TYPES.RECOMMENDED, label: props.i18n.t('forYou') },
                VIDEO: { id: TYPES.VIDEO, label: props.i18n.t('video') },
                MORE: { id: TYPES.MORE, label: props.i18n.t('moreTab') },
                SAVED: { id: CATEGORIES.BOOKMARKS, label: props.i18n.t('saved') }
            };
            this.state = {
                activeTab: props.activeTab || props.selectedTab || this.tabs[0],
                selectedTab: props.selectedTab || this.tabs[0],
                isSearchInputFocused: false,
                selectedCategory: props.selectedCategory || null
            };
            this._loadedCategories = {};
            this.contentParams = getRequestParams(undefined, CATEGORIES.BOOKMARKS, 0, undefined, undefined, this.isTagFilter);
            this._getContent(CATEGORIES.BOOKMARKS, this.contentParams, true);
            this._getCategoryContent(TYPES.ALL);
            this._getCategoryContent(this.activeCategory);
            this._getContentTags();
            this.searchText = null;
        }

        async componentDidMount() {
            if (Platform.OS === PLATFORMS.web) {
                const activeTab = await Storage.getItem(ENTITIES_ACTIVE_TAB.CONTENT);
                if (activeTab) {
                    const selectedTab = activeTab.slug ? this.TABS.FOR_YOU : activeTab;
                    this.setState({ activeTab, selectedTab });
                }
            }
        }

        get isTagFilter() {
            return this.props.isLiveBetter ? false : true;
        }

        /**
         * get content for category (list items and carousels)
         */
        _getCategoryContent(category, isReplace = true) {
            if (!this._loadedCategories[category] && !this.isMoreTab) {
                this._getContent(category, getRequestParams(undefined, category, 0, undefined, undefined, this.isTagFilter), isReplace);
                this._getContent(category, getRequestParams(TYPES.VIDEO, category, 0, coreConstants.MAX_SLIDES_COUNT, undefined, this.isTagFilter), isReplace, TYPES.VIDEO);
                if (this.props.isLiveBetter) {
                    this._getContent(category, getRequestParams(TYPES.RECOMMENDED, category, 0, coreConstants.MAX_SLIDES_COUNT, undefined, this.isTagFilter), isReplace, TYPES.RECOMMENDED);
                }
                if (this.promotedContentToday.all.length < 1) {
                    this._getContent(category, getRequestParams(TYPES.PROMOTED, category, 0, coreConstants.MAX_SLIDES_COUNT, undefined, this.isTagFilter), isReplace, TYPES.PROMOTED);
                }
                this._loadedCategories[category] = true;
            }

            this.props.actions.getContent(category, { clientExternalOnly: 1 }, true, TYPES.MORE, null, false);
        }

        _getContent(category, params, isReplace = true, type, isSearching = false) {
            this.props.actions.getContent(category, params, isReplace, type, null, isSearching);
        }

        _getContentTags() {
            this.props.actions.getContentTags();
        }

        searchCallback = text => {
            if (text) this.searchText = text;
            else this.searchText = null;
        };

        onFocus = () => { this.setState({ isSearchInputFocused: true }); };
        onBlur = () => { this.setState({ isSearchInputFocused: false }); };

        get activeCategory() {
            return this.props.category || this.state.activeTab.id;
        }

        get tabs() {
            const { hasExternalContent } = this.props;
            const tabs = [this.TABS.FOR_YOU, this.TABS.VIDEO];
            hasExternalContent && tabs.push(this.TABS.MORE);
            tabs.push(this.TABS.SAVED);

            return tabs;
        }

        get libraryHeaderDescriptionText() {
            const { isLiveBetter, i18n } = this.props;
            return isLiveBetter ? null : i18n.t('library_header_description');
        }

        onTabPress = (tab = this.TABS.FOR_YOU, isCategory = false) => {
            const defaultTab = this.isVideoTab ? this.TABS.VIDEO : this.TABS.FOR_YOU;
            const selectedTab = (tab.id === this.state.activeTab.id && isCategory) ? defaultTab : tab;
            const selectedTabOrCategory = isCategory ? 'selectedCategory' : 'selectedTab';

            this.setState(() => ({ activeTab: selectedTab, [selectedTabOrCategory]: tab }));

            if (tab.id !== CATEGORIES.BOOKMARKS) {
                this._getCategoryContent(selectedTab.id);
            }

            if (Platform.OS === PLATFORMS.web) {
                Storage.setItem(ENTITIES_ACTIVE_TAB.CONTENT, selectedTab);
            }
        };

        get title() {
            return this.props.i18n.t('contentTitle');
        }

        onViewAllRecommended = () => {
            _.has(this, 'wrapped.goToByType') && this.wrapped.goToByType(TYPES.RECOMMENDED);
        };

        onViewAllPromoted = () => {
            _.has(this, 'wrapped.goToByType') && this.wrapped.goToByType(TYPES.PROMOTED);
        };

        saveRef = ref => (this.wrapped = ref);

        get promotedContentToday() {
            const { promotedContent } = this.props;
            return _.reduce(Object.keys(promotedContent), (acc, current) => {
                const filteredValues = _.filter(promotedContent[current], item => isFeatured(item));
                return { ...acc, [current]: filteredValues };
            }, {});
        }

        get isRecommendedTab() {
            return this.state.selectedTab.id === CATEGORIES.RECOMMENDED;
        }

        get isVideoTab() {
            return this.state.selectedTab.id === CATEGORIES.VIDEO;
        }
        get isMoreTab() {
            return this.state.selectedTab.id === CATEGORIES.MORE;
        }
        get formattedCategories() {
            const { categories } = this.props;
            return _.filter(categories, item => SLUGS[item.id]);
        }

        get categoriesOrTags() {
            const { isLiveBetter, tags } = this.props;
            return isLiveBetter ? this.formattedCategories : tags;
        }

        get isLoading() {
            const { isLoadingContentCount, isLoadingSelector } = this.props;
            return isLoadingSelector || isLoadingContentCount > 0;
        }

        get searchTab() {
            const { selectedTab } = this.state;
            if (selectedTab.id === CATEGORIES.VIDEO) {
                return TYPES.VIDEO;
            } else if (selectedTab.id === CATEGORIES.MORE) {
                return TYPES.MORE;
            }

            return TYPES.ALL;
        }

        render() {
            const { activeTab, selectedTab, selectedCategory, isSearchInputFocused } = this.state;
            return (
                <WrappedComponent
                    {...this.props}
                    ref={this.saveRef}
                    title={this.title}
                    searchCallback={this.searchCallback}
                    searchText={this.searchText}
                    onViewAllRecommended={this.onViewAllRecommended}
                    onViewAllPromoted={this.onViewAllPromoted}
                    activeCategory={this.activeCategory}
                    tabs={this.tabs}
                    onTabPress={this.onTabPress}
                    activeTab={activeTab}
                    libraryHeaderDescriptionText={this.libraryHeaderDescriptionText}
                    promotedContentToday={this.promotedContentToday}
                    promotedContentTodayCount={this.promotedContentTodayCount}
                    categories={this.formattedCategories}
                    getContent={this._getContent}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    isSearchInputFocused={isSearchInputFocused}
                    selectedTab={selectedTab}
                    selectedCategory={selectedCategory}
                    categoriesOrTags={this.categoriesOrTags}
                    isVideoTab={this.isVideoTab}
                    isMoreTab={this.isMoreTab}
                    isRecommendedTab={this.isRecommendedTab}
                    isLoading={this.isLoading}
                    searchTab={this.searchTab}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        const routeParams = _.get(ownProps, 'route.params');
        const locationState = _.get(ownProps, 'location.state');
        return {
            recommendedContent: selectors.getRecommendedContent(state),
            recommendedContentCount: selectors.getRecommendedContentCount(state),
            promotedContent: selectors.getPromotedContent(state),
            promotedContentCount: selectors.getPromotedContentCount(state),
            categories: selectors.getCategories(state),
            tags: selectors.getContentTags(state),
            isLoadingSelector: selectors.isLoadingContent(state),
            isLiveBetter: coreSelectors.isLiveBetter(state),
            ...(routeParams || {}),
            ...(locationState || {}),
            hasVideoContent: coreSelectors.isLiveBetter(state) ? false : selectors.hasVideoContent(state),
            hasExternalContent: selectors.hasExternalContent(state),
            isLoadingContentCount: selectors.isLoadingContentCount(state),
            isLoadingPromotedContent: selectors.isLoadingPromotedContent(state)
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(contentActions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(ContentBase));
}
