import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { translate } from '../../../core';
import * as selectors from '../../selectors';

export default function WithRecommendedGoalsCarouselBase(WrappedComponent) {
    class RecommendedGoalsCarouselBase extends PureComponent {
        static propTypes = {
            slug: PropTypes.string.isRequired,
            recommendedGoals: PropTypes.array,
            isAllPossibleGoalsSet: PropTypes.bool,
            categoryGoals: PropTypes.array,
            i18n: PropTypes.object.isRequired
        };

        static defaultProps = {
            recommendedGoals: [],
            isAllPossibleGoalsSet: false,
            categoryGoals: []
        };

        get carouselData() {
            return this.props.isAllPossibleGoalsSet ? this.props.categoryGoals : this.props.recommendedGoals;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    carouselData={this.carouselData}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => ({
        recommendedGoals: selectors.getGoalsByCategory(state, ownProps.slug),
        categoryGoals: selectors.getUncompletedPersonalGoalsByCategory(state, ownProps.slug),
        isAllPossibleGoalsSet: selectors.isAllPossibleGoalsSet(state)
    });

    return connect(mapStateToProps)(translate()(RecommendedGoalsCarouselBase));
}
