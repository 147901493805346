import { NAME as name } from './constants';
import { createActionTypes } from '../core';

const NAME = name.toUpperCase();

export const GET_COMMUNITIES = createActionTypes(`${NAME}/GET_COMMUNITIES`);
export const GET_SINGLE_COMMUNITY = createActionTypes(`${NAME}/GET_SINGLE_COMMUNITY`);
export const JOIN_COMMUNITY = createActionTypes(`${NAME}/JOIN_COMMUNITY`);
export const LEAVE_COMMUNITY = createActionTypes(`${NAME}/LEAVE_COMMUNITY`);
export const DELETE_COMMUNITY = createActionTypes(`${NAME}/DELETE_COMMUNITY`);
export const CREATE_COMMUNITY = createActionTypes(`${NAME}/CREATE_COMMUNITY`);
export const EDIT_COMMUNITY = createActionTypes(`${NAME}/EDIT_COMMUNITY`);
export const GET_COMMUNITY_MEMBERS = createActionTypes(`${NAME}/GET_COMMUNITY_MEMBERS`);

export const UPDATE_NEW_COMMUNITY = `${NAME}/UPDATE_NEW_COMMUNITY`;
export const UPDATE_CREATE_COMMUNITY_STEP = `${NAME}/UPDATE_CREATE_COMMUNITY_STEP`;
export const UPDATE_COMMUNITY_SUCCESS = 'UPDATE_COMMUNITY_SUCCESS';
