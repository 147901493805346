import React, { PureComponent } from 'react';
import { css, StyleSheet } from 'aphrodite-jss';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import { withRouter } from 'react-router-dom';
import { layoutStyle, spacing } from '../../../../styles';
import { components as Core, onKeyPress } from '../../../core';
import withNewFeatureModalBase, { styles as baseStyles } from './NewFeatureModalBase';

class NewFeatureModal extends PureComponent {
    static propTypes = {
        onCloseButtonPress: PropTypes.func.isRequired,
        onButtonPress: PropTypes.func.isRequired,
        buttonText: PropTypes.string.isRequired,
        animation: PropTypes.object.isRequired,
        newText: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        close: PropTypes.func.isRequired,
        image: PropTypes.string,
        history: PropTypes.object.isRequired,
        LetsGoButtonText: PropTypes.string.isRequired
    };

    static defaultProps = {
        image: null,
    };

    dismissModal = () => this.props.close();

    createMarkup = () => ({ __html: this.props.text });

    onLetsGoButtonPres = () => {
        this.props.onButtonPress();
    }

    render() {
        const { newText, title, buttonText, onCloseButtonPress, animation, image } = this.props;
        const lottieDefaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animation,
        };

        return (
            <>
                <div className={css(styles.closeBtnContainer)}>
                    <Core.CloseButton
                        onClick={onCloseButtonPress}
                        size={spacing.s4}
                        title=''
                        role="button"
                        aria-label='close button'
                    />
                </div>
                <div className={css(styles.contentContainer)}>
                    <div className={css(styles.lottieWrapper)}>
                        {image ? <Core.Image src={image} /> : (
                            <Lottie
                                style={{ cursor: 'auto' }}
                                isClickToPauseDisabled={true}
                                options={lottieDefaultOptions}
                            />
                        )}
                    </div>
                    {newText && (
                        <div className={css(styles.newTextBg)}>
                            <p className={css(styles.newText)}>{newText}</p>
                        </div>
                    )}
                    {title && <p className={css(styles.title)}>{title}</p>}
                    {/* eslint-disable-next-line react/no-danger */}
                    <div dangerouslySetInnerHTML={this.createMarkup()} className={css(styles.text)} />
                </div>
                {/* <div className={css(styles.divider)} />
                <div
                    tabIndex="0"
                    role="button"
                    onClick={this.onLetsGoButtonPres}
                    onKeyDown={onKeyPress.enter(onButtonPress)}
                    className={css(styles.footerButton, layoutStyle.cp)}>
                    {LetsGoButtonText}
                </div> */}
                <div className={css(styles.divider)} />
                <div
                    tabIndex="0"
                    role="button"
                    onClick={this.onLetsGoButtonPres}
                    onKeyDown={onKeyPress.enter(this.onLetsGoButtonPres)}
                    className={css(styles.footerButton, layoutStyle.cp)}>
                    {buttonText}
                </div>
            </>
        );
    }
}

export default withRouter(withNewFeatureModalBase(NewFeatureModal));

const IMAGE_HEIGHT = 258;

const styles = StyleSheet.create({
    ...baseStyles,
    lottieWrapper: {
        height: IMAGE_HEIGHT,
    },
    contentContainer: {
        ...baseStyles.contentContainer,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    closeBtnContainer: {
        ...baseStyles.closeBtnContainer,
        paddingTop: spacing.s2,
    },
    text: {
        ...baseStyles.text,
        whiteSpace: 'pre-line'
    },
    newTextBg: {
        ...baseStyles.newTextBg,
        maxWidth: 'max-content',
        margin: `${spacing.s5}px auto 0 auto`,
    }
});
