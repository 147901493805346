import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';

import { importantStyles } from '../../../../styles';
import { components as Core, AsyncComponent, Modal } from '../../../core';
import { appsDevicesHelper } from '../../services';
import { HEALTH_CONNECT, HEALTH_KIT } from '../../constants';
import WithDevicesModalsLogicBase, { styles as baseStyles } from './DevicesModalsLogicBase';

export default function WithDevicesModalsLogic(WrappedComponent) {
    class DevicesModalsLogic extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            onDevicePress: PropTypes.func.isRequired,
            deviceName: PropTypes.string.isRequired,
            device: PropTypes.object.isRequired,
            infoModalProps: PropTypes.func.isRequired,
            programName: PropTypes.string.isRequired,
            i18n: PropTypes.object.isRequired,
            customPointsName: PropTypes.string.isRequired
        };

        get modalText() {
            const { i18n, programName, customPointsName } = this.props;
            return (
                <div>
                    <div className={css(styles.textFirstParagraph)}>
                        {i18n.t('appsDevices.connectModal.paragraph1Part1')}
                        <span className={css(styles.boldText)}>{` ${i18n.t('appsDevices.connectModal.paragraph1Part2')} `}</span>
                        {i18n.t('appsDevices.connectModal.paragraph1Part3')}
                    </div>
                    <div>
                        {i18n.t('appsDevices.connectModal.paragraph2Desc1')}
                        <span className={css(styles.boldText)}>{` ${i18n.t('appsDevices.connectModal.paragraph2Desc2')} `}</span>
                        {i18n.t('appsDevices.connectModal.paragraph2Desc3')}
                        <span className={css(styles.boldText)}>{` ${this.props.deviceName} `}</span>
                        {i18n.t('appsDevices.connectModal.paragraph2Desc4')}
                        <span className={css(styles.boldText)}>{` ${programName}`}</span>
                        {i18n.t('appsDevices.connectModal.paragraph2Desc5', { pointsName: customPointsName })}
                    </div>
                </div>
            );
        }

        onContinue = () => {
            this.closeModal();
            this.onConnect();
        };

        onConnect = () => {
            appsDevicesHelper.onDeviceConnect(this.props.device);
        };

        onCloseModal = () => this.closeModal();

        showInfoModal = () => {
            const { infoModalProps } = this.props;

            this.closeModal = Modal.open(
                Core.InfoModal,
                {
                    ...infoModalProps(this.onContinue, this.onCloseModal),
                    text: this.modalText,
                    textStyle: styles.modalText,
                },
                {
                    cancelable: false,
                    PaperProps: { elevation: 0 },
                    isNoPadding: true,
                    isTransparent: true,
                    fadeTransition: true,
                }
            );
        };

        onDevicePress = () => {
            this.props.onDevicePress(this.onConnect, this.showInfoModal, this.showSyncModal);
        };

        showSyncModal = () => {
            this.closeSyncModal = Modal.open(
                AsyncComponent(() => import('../SyncModal')),
                {
                    device: this.props.device,
                    onButtonPress: () => this.closeSyncModal()
                },
                {
                    cancelable: false,
                    PaperProps: { elevation: 0 },
                    isNoPadding: true,
                    isTransparent: true,
                    isDeviceModal: true,
                }
            );
        };

        onSync = (closeModalCallback = null) => {
            if (this.props.device.vendor !== HEALTH_KIT && this.props.device.vendor !== HEALTH_CONNECT) {
                this.props.actions.syncDevice(this.props.device);
            }
            else {
                this.showSyncModal();
                if (closeModalCallback) closeModalCallback();
            }
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    showSyncModal={this.showSyncModal}
                    onDevicePress={this.onDevicePress}
                    onSync={this.onSync}
                />
            );
        }
    }

    return WithDevicesModalsLogicBase(DevicesModalsLogic);
}

const styles = StyleSheet.create(importantStyles({
    ...baseStyles,
    modalText: {
        ...baseStyles.modalText,
        whiteSpace: 'unset',
    },
}));
