/**
 * Update deep property - change references through all path and assign value
 * @param {Object} object - source
 * @param {Array|string} path - path to props
 * @param {*} value - new value
 * @returns {Object} - new object
 */
export function updateDeepProperty(object, path, value) {
    const newObject = { ...object };
    const pathArray = Array.isArray(path) ? path : path.split('.');
    const last = pathArray[pathArray.length - 1];

    pathArray.reduce((subObject, prop) => {
        // eslint-disable-next-line
        subObject[prop] = prop === last ? value : { ...(subObject[prop] || {}) };
        return subObject[prop];
    }, newObject);

    return newObject;
}
