import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { layoutStyle } from '../../../styles';

export { default as Fade } from 'react-reveal/Fade';
export { default as Slide } from 'react-reveal/Slide';
export { default as Shake } from 'react-reveal/Shake';
export { default as Flash } from 'react-reveal/Flash';
export { default as Zoom } from 'react-reveal/Zoom';
export { default as Spin } from 'react-reveal/Spin';
export { default as Rotate } from 'react-reveal/Rotate';

export const SpinOpposite = ({ children }) => (
    <div className={css(styles.rotateOpposite, layoutStyle.flexCenter)}>{children}</div>
);

SpinOpposite.propTypes = {
    children: PropTypes.node.isRequired,
};

const styles = StyleSheet.create({
    //eslint-disable-next-line
    '@keyframes slideRight': {
        from: {
            transform: 'rotate(0deg)',
            animationTimingFunction: 'linear'
        },
        to: { transform: 'rotate(360deg)', }
    },
    rotateOpposite: {
        animationName: 'slideRight',
        animationDuration: '1s',
        animationIterationCount: 'infinite',
    }
});