import React, { PureComponent } from 'react';
import _ from 'lodash';
import { css, StyleSheet } from 'aphrodite-jss';
import { browserName, browserVersion } from 'react-device-detect';
import { components as Core } from '../../../core';
import { baseColors, spacing, importantClass } from '../../../../styles';
import UserFeedbackWidgetBase, { styles as baseStyles } from './UserFeedbackWidgetBase';

class UserFeedbackWidget extends PureComponent {

    get emojiSection() {
        const { emojis, isEmojiSectionVisible } = this.props;
        return isEmojiSectionVisible && (
            <div className={css(styles.emojiWrapper)}>
                { _.map(emojis, emoji => this.emojiItem(emoji))}
            </div>
        );
    }

    emojiItem = item => {
        const { chosenRating } = this.props;

        const middleEmojiStyle = item.rating === '3' ? styles.spaceAroundCenterEmoji : null;

        let activeClassName = null;
        if (chosenRating === item.rating) activeClassName = styles.activeEmoji;

        const wrapperClasses = [styles.emojiBlock, middleEmojiStyle, activeClassName];

        return (
            <div
                role="button"
                tabIndex="0"
                onClick={() => this.changeRating(item.rating)}
                className={css(...wrapperClasses)}>
                <img src={item.src} className={css(styles.emoji)} />
            </div>
        );
    }

    get commentSection() {
        const { isCommentSectionVisible, submitBtnText, commentSectionLabel, userComment } = this.props;

        return isCommentSectionVisible && (
            <>
                <Core.Input
                    placeholder={commentSectionLabel}
                    multiline={false}
                    className={css(styles.inputStyles)}
                    inputClassName={styles.inputClassName}
                    fullWidth={true}
                    autoFocus={true}
                    value={userComment}
                    onChange={e => this.onCommentValueChange(e)}
                />
                <Core.Button
                    onPress={this.onSubmit}
                    className={css(styles.submitBtn)}>
                    {submitBtnText}
                </Core.Button>
            </>
        );
    }

    onCommentValueChange(e) {
        const { setNewUserComment } = this.props;
        setNewUserComment(e.target.value);
    }

    onSubmit = () => {
        const { submitForm } = this.props;
        const browserInfo = `${browserName} ${browserVersion}`;
        submitForm(browserInfo);
    }

    changeRating(newRating) {
        this.props.setChosenRating(newRating);
    }

    render() {
        const { isThankYouSectionVisible, thankYouText, titleQuestionText } = this.props;
        return (
            <div className={css(styles.container)}>
                <div className={css(styles.title)}>{isThankYouSectionVisible ? thankYouText : titleQuestionText}</div>
                {this.emojiSection}
                {this.commentSection}
            </div>

        );
    }
}

export default UserFeedbackWidgetBase(UserFeedbackWidget);

const WIDGET_WIDTH = 300;

const styles = StyleSheet.create({
    ...baseStyles,
    activeEmoji: {
        border: `3px solid ${baseColors.secondary}`
    },
    container: {
        ...baseStyles.container,
        padding: spacing.s5,
        margin: spacing.s4
    },
    inputStyles: importantClass({
        width: WIDGET_WIDTH,
        backgroundColor: baseColors.white,
        borderRadius: spacing.s1,
        paddingBottom: 0
    }),
    submitBtn: importantClass({
        width: WIDGET_WIDTH,
        marginTop: spacing.s1
    }),
    inputClassName: importantClass({
        marginTop: spacing.s1,
        marginBottom: spacing.s1
    })
});
