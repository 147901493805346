import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { translate } from '../../../core';
import * as actions from '../../actions';
import { appFonts, baseColors } from '../../../../styles';
import { getCompany, getPrivacyPolicy, getTermsOfService, isRetail } from '../../../auth/selectors';
import { actions as authActions } from '../../../auth';

export default function WithTermsAndPolicyModalBase(WrappedComponent) {
    class TermsAndPolicyModalBase extends Component {
        static propTypes = {
            company: PropTypes.object.isRequired,
            termsOfService: PropTypes.string,
            privacyPolicy: PropTypes.string,
            isRetail: PropTypes.bool,
            actions: PropTypes.object.isRequired,
            authActions: PropTypes.object.isRequired,
            i18n: PropTypes.object.isRequired,
        };

        static defaultProps = {
            termsOfService: undefined,
            privacyPolicy: undefined,
            isRetail: false,
        };

        constructor(props) {
            super(props);
            this.getPolicies();
        }
        getPolicies() {
            const params = {};
            if (this.props.isRetail) {
                params.partnerId = this.props.company.partnerId;
                params.isRetail = true;
            } else {
                params.companyId = this.props.company.companyId;
            }
            if (params.companyId || params.partnerId) {
                this.props.authActions.getTermsOfService(params);
                this.props.authActions.getPrivacyPolicy(params);
            }
        }
        get privacyPolicy() {
            return this.props.privacyPolicy.content;
        }

        get termsOfService() {
            return this.props.termsOfService;
        }
        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    termsOfService={this.termsOfService}
                    privacyPolicy={this.privacyPolicy}
                />
            );
        }
    }

    const mapStateToProps = state => ({
        company: getCompany(state),
        privacyPolicy: getPrivacyPolicy(state),
        termsOfService: getTermsOfService(state),
        isRetail: isRetail(state),
    });
    const mapDispatchToProps = dispatch => ({
        actions: bindActionCreators(actions, dispatch),
        authActions: bindActionCreators(authActions, dispatch)
    });


    return connect(mapStateToProps, mapDispatchToProps)(translate()(TermsAndPolicyModalBase));
}

export const styles = {
    linkPolicyText: {
        ...appFonts.smBold,
        color: baseColors.secondary,
    },
    policyTextWrapper: {
        flex: 1,
        flexWrap: 'wrap'
    },
    webViewContainer: {
        flex: 1,
        backgroundColor: baseColors.white
    },
    policyText: {
        ...appFonts.smRegular,
        color: baseColors.grey20,
    },
    footer: {
        borderColor: baseColors.grey70,
        borderTopWidth: 1
    },
};

