import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { css, StyleSheet } from 'aphrodite-jss';
import classnames from 'classnames';
import { isIE } from 'react-device-detect';
import { appFonts, baseColors, importantStyles, layoutStyle, spacing } from '../../../../styles';
import { Modal, components as Core, AsyncComponent } from '../../../core';
import ActivityList, { ACTIVITY_STATUSES } from '../ActivityList';
import WithActivityTrackingListBase, { styles as baseStyles } from './ActivityTrackingListBase';
import modalProps from '../CongratsFirstTrackChallenge/modalProps';
import ActivityLogModal from '../ActivityLogModal';
import ActivityTrackingItem from '../ActivityTrackingItem';

class ActivityTrackingList extends PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        searchKey: PropTypes.array.isRequired,
        isLoading: PropTypes.bool,
        close: PropTypes.func.isRequired,
        title: PropTypes.string,
        isAnimated: PropTypes.func.isRequired,
        isChangesPresent: PropTypes.bool.isRequired,
        stopSearching: PropTypes.func.isRequired,
        isSearching: PropTypes.bool.isRequired,
        focusCallback: PropTypes.func.isRequired,
        getActivityTrackingModalProps: PropTypes.func.isRequired,
        removePendingActivity: PropTypes.func.isRequired,
        deleteActivityData: PropTypes.func.isRequired,
        exitScreen: PropTypes.func.isRequired,
        getTotalPointsEarned: PropTypes.func.isRequired,
        addActivity: PropTypes.func.isRequired,
        formatedDate: PropTypes.string.isRequired,
        pendingActivities: PropTypes.array.isRequired,
        allLogs: PropTypes.array.isRequired,
        updateActivityData: PropTypes.func.isRequired,
        selectedDate: PropTypes.string.isRequired,
        challengeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        activities: PropTypes.array.isRequired,
        notTrackedText: PropTypes.string.isRequired,
        activityTrackingLabel: PropTypes.string.isRequired,
        activityTrackingDescription1: PropTypes.string.isRequired,
        activityTrackingDescription2: PropTypes.string.isRequired,
        getActivityTrackingModalPropsAdvanced: PropTypes.func.isRequired,
        listTitle: PropTypes.string.isRequired,
        searchText: PropTypes.string.isRequired,
        onChangeSearchText: PropTypes.func.isRequired,
        searchBarPlaceholder: PropTypes.string.isRequired,
        onClearSearchText: PropTypes.func.isRequired,
        recentTitle: PropTypes.string.isRequired,
        tabs: PropTypes.array.isRequired,
        selectedTab: PropTypes.object.isRequired,
        onTabPress: PropTypes.func.isRequired,
        filteredActivities: PropTypes.array.isRequired,
        noActivitiesText: PropTypes.string.isRequired,
    };

    static defaultProps = {
        isLoading: false,
        title: undefined,
    };

    state = {
        isAddButtonVisible: false,
        isVisible: false
    };

    showFirstTrackChallengeScreen = () => {
        this.close = Modal.open(
            AsyncComponent(() => import('../CongratsFirstTrackChallenge')),
            { pointsEarned: this.props.getTotalPointsEarned() },
            { ...modalProps }
        );
    };

    dismissModal = () => this.props.close();

    dismissModalAfterLoading = () => this.dismissModal();

    toggleNavigatorButton = () => {
        if (this.props.isChangesPresent) {
            this.setState({ isAddButtonVisible: true });
        } else {
            this.setState({ isAddButtonVisible: false });
        }
    };

    showActivityTrackingModal = (isPending = false) => (activity, isAdvancedProps = false) => {
        this.close = Modal.open(
            AsyncComponent(() => import('../ActivityTrackingModal')),
            isAdvancedProps
                ? this.props.getActivityTrackingModalPropsAdvanced(activity, isPending)
                : this.props.getActivityTrackingModalProps(activity, isPending),
            { isContainer: true, fadeTransition: true }
        );
    };

    showActivityTrackingModalAdvanced = activity => this.showActivityTrackingModal(false)(activity, true);

    get recentlyTrackedList() {
        const { allLogs, pendingActivities, listTitle, removePendingActivity, isAnimated, deleteActivityData, formatedDate } = this.props;
        if (allLogs.length > 0 || pendingActivities.length > 0) {
            return (
                <Fragment>
                    <div className={css(styles.headerWrapper)}>
                        <p className={css(styles.header)}>{listTitle} {formatedDate}</p>
                    </div>
                    <ActivityList
                        activities={pendingActivities}
                        status={ACTIVITY_STATUSES.pending}
                        isInModal={true}
                        onItemClick={this.showActivityTrackingModal(true)}
                        onItemDelete={removePendingActivity}
                        isAnimated={isIE ? false : isAnimated}
                    />
                    <ActivityList
                        activities={allLogs}
                        isInModal={true}
                        status={ACTIVITY_STATUSES.tracked}
                        onItemClick={this.showActivityTrackingModal(false)}
                        onItemDelete={deleteActivityData}
                        isAnimated={isIE ? false : isAnimated}
                    />
                </Fragment>
            );
        }

        return (
            <div>
                <div className={css(styles.noActivitiesTextWrapper)}>
                    <p className={css(styles.noActivitiesText)}>
                        {this.props.notTrackedText}
                    </p>
                </div>
                <div className={css(styles.noActivitiesBottom)} />
            </div>
        );
    }

    get addedActivities() {
        return (
            <Fragment>
                <Core.SubHeader
                    className={css(styles.trackedActivityModalSubHeader)}
                    left={<Core.CloseButton onClick={this.props.exitScreen} color={baseColors.black} />}
                    title={this.props.title}
                    titleSize="small"
                    isActivityTrackingSubheader={true}
                    right={this.state.isAddButtonVisible ? (
                        <Core.Button
                            type="text"
                            isWidthAuto={true}
                            onPress={this.props.addActivity}>
                            {this.props.i18n.t('done')}
                        </Core.Button>
                    ) : null}
                />
                <div className={css(styles.modalPadding)}>
                    <p className={css(styles.searchDescription, styles.addActivityDescription)}>
                        {this.props.activityTrackingDescription1}
                        <span className={css(styles.searchDescriptionBold)}>
                            {` ${_.toLower(this.props.activityTrackingLabel)} `}
                        </span>
                        {this.props.activityTrackingDescription2}
                    </p>
                    <Core.Button fullWidth={true} onPress={this.props.focusCallback}>
                        {this.props.activityTrackingLabel}
                    </Core.Button>
                    {this.recentlyTrackedList}
                </div>
            </Fragment>
        );
    }

    get searchActivitiesSubHeader() {
        return (
            <div className={css(styles.subheader)}>
                <span />
                <p className={css(styles.title)}>{this.props.title}</p>
                <div className={css(styles.closeButton)}>
                    <Core.CloseButton onClick={this.props.exitScreen} color={baseColors.black} />
                </div>
            </div>
        );
    }

    get searchBar() {
        const { searchText, searchBarPlaceholder, onClearSearchText, onChangeSearchText } = this.props;

        return (
            <div className={css(styles.searchInputWrapper)}>
                <Core.SearchBar
                    value={searchText}
                    onChange={onChangeSearchText}
                    label={searchBarPlaceholder}
                    autoFocus={true}
                    onClear={onClearSearchText}
                    isSearching={true}
                    className={css(styles.searchBar)}
                    isCustomizedSearchBar={false}
                    addWhiteBg={true}
                />
            </div>
        );
    }

    renderActivity = item => (
        <ActivityTrackingItem
            key={_.get(item, 'activityLogId') || _.get(item, 'activityUnitId')}
            activity={item}
            onClick={this.showActivityTrackingModalAdvanced}
            noPlusIcon={true}
            isCommonPaddingHorizontal={true}
        />
    );

    get recentActivities() {
        const { recentTitle, i18n, activityLogs } = this.props;

        return (
            <>
                <div className={classnames(css(styles.recentWrapper, layoutStyle.flex, !activityLogs.length && layoutStyle.flexJustifyEnd))}>
                    {activityLogs.length ? <p className={css(styles.recentTitle)}>{recentTitle}</p> : null}
                    <Core.Button
                        type="text"
                        size="small"
                        onPress={this.onModalOpen}>
                        {i18n.t('showMyActivityLog')}
                    </Core.Button>
                </div>
                {_.map(activityLogs, this.renderActivity)}
            </>
        );
    }

    onTabChange = (event, value) => {
        const newTab = _.find(this.props.tabs, { id: value });
        this.props.onTabPress(newTab);
    };

    get tabs() {
        const { tabs, selectedTab } = this.props;

        return (
            <>
                <div className={css(styles.tabsContainer)}>
                    <Core.ScrollableTabs
                        tabs={tabs}
                        activeTab={selectedTab}
                        onChange={this.onTabChange}
                        isVertical={false}
                        isNewDesign={true}
                    />
                </div>
                <div className={css(styles.separator)} />
            </>
        );
    }

    get selectedCategoryActivities() {
        const { filteredActivities } = this.props;
        return filteredActivities.length ? _.map(filteredActivities, this.renderActivity) : this.emptyListComponent;
    }

    get emptyListComponent() {
        return (
            <p className={css(styles.emptyList)}>{this.props.noActivitiesText}</p>
        );
    }

    get searchActivities() {

        return (
            <div>
                {this.searchActivitiesSubHeader}
                {this.searchBar}
                {this.recentActivities}
                {this.tabs}
                {this.selectedCategoryActivities}
            </div>
        );
    }

    onModalOpen = () => this.setState({ isVisible: true })

    onModalClose = () => this.setState({ isVisible: false })

    render() {
        return (
            <Core.Layout.FHFlex className={css(styles.modalWrapper)} isInModal={true} >
                <ActivityLogModal isVisible={this.state.isVisible} onOpen={() => this.onModalOpen()} onClose={() => this.onModalClose()} />
                {this.props.isSearching === false ? this.addedActivities : this.searchActivities}
                <Core.BlockingLoading isLoading={this.props.isLoading} />
            </Core.Layout.FHFlex>
        );
    }
}

export default WithActivityTrackingListBase(ActivityTrackingList);

const styles = StyleSheet.create({
    ...baseStyles,
    ...importantStyles({
        modalWrapper: {
            padding: 0
        },
    }),
    headerWrapper: {
        ...baseStyles.headerWrapper,
    },
    ...importantStyles({
        trackedActivityModalSubHeader: {
            paddingTop: 0,
            display: 'flex',
        },
    }),
    subheader: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: baseColors.white,
        padding: `${spacing.s4}px ${spacing.s0}px`
    },
    title: {
        ...appFonts.lgBold,
        color: baseColors.black
    },
    searchInputWrapper: {
        width: '100%',
        padding: `0 ${spacing.s3}px ${spacing.s1}px ${spacing.s3}px`,
        backgroundColor: baseColors.grey90,
        borderTop: `1px solid ${baseColors.grey80}`,
        borderBottom: `1px solid ${baseColors.grey80}`,
    },
    recentWrapper: {
        ...baseStyles.recentWrapper,
        padding: spacing.s2,
    },
    recentTitle: {
        ...baseStyles.recentTitle,
        color: baseColors.grey20,
    },
    addActivityDescription: {
        marginTop: spacing.s3,
    },
    modalPadding: {
        padding: `0 ${spacing.s3}px`
    },
    tabsContainer: {
        backgroundColor: baseColors.grey90,
    },
});
