import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { appFonts, baseColors, spacing } from '../../../../styles';
import * as actions from '../../actions';
import { actions as coreActions, translate, componentHelper, MEMBERS_MAX_COUNT } from '../../../core';
import * as selectors from '../../selectors';

export default function WithJoinTeamsListBase(WrappedComponent) {
    class JoinTeamsListBase extends PureComponent {
        static propTypes = {
            challengeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            actions: PropTypes.object.isRequired,
            i18n: PropTypes.object.isRequired,
            isLoading: PropTypes.bool.isRequired,
            challengeMembers: PropTypes.array,
            challengeMembersFiltered: PropTypes.array,
            challenge: PropTypes.object.isRequired,
            isPage: PropTypes.bool,
            isTeamChallenge: PropTypes.bool,
            isJoining: PropTypes.bool,
            hasInvitingTeams: PropTypes.bool,
            searchingResultTeams: PropTypes.array,
        };

        static defaultProps = {
            challengeMembers: [],
            challengeMembersFiltered: [],
            isPage: true,
            isTeamChallenge: false,
            isJoining: false,
            hasInvitingTeams: false,
            searchingResultTeams: []
        };

        constructor(props) {
            super(props);

            this.state = {
                searchText: ''
            };

            this.props.actions.getChallengeMembers(props.challengeId, props.isTeamChallenge);
            this.isFirstLoading = true;
            this.isLoadMore = true;
            if (!props.challenge) {
                this.props.actions.getChallenge(this.props.challengeId);
            }
        }

        loadMore = () => this.isLoadMore && !this.props.isLoading && this.props.actions.getChallengeMembers(
            this.props.challengeId,
            this.props.isTeamChallenge,
            { start: this.teamsMembershipInfo.length }
        );

        componentDidUpdate(prevProps) {
            componentHelper.onActionComplete(this.props, prevProps, () => {
                if (this.props.isPage) {
                    this.goBack();
                }
            }, this.props.actions.clearJoinChallengeError, 'isJoining');
            const prevTeamsMembershipInfo = prevProps.hasInvitingTeams ? prevProps.challengeMembersFiltered : prevProps.challengeMembers;
            if (prevProps.isLoading && !this.props.isLoading && !this.isFirstLoading &&
                (prevTeamsMembershipInfo.length === this.teamsMembershipInfo.length || this.teamsMembershipInfo.length - prevTeamsMembershipInfo.length < MEMBERS_MAX_COUNT)) {
                this.isLoadMore = false;
            }
            if (prevProps.isLoading && !this.props.isLoading && this.isFirstLoading) {
                this.isFirstLoading = false;
            }
            if (!_.isEqual(prevProps.isTeamChallenge, this.props.isTeamChallenge)) {
                this.props.actions.getChallengeMembers(this.props.challengeId, this.props.isTeamChallenge);
            }
        }

        goBack = () => _.has(this, 'wrapped.goBack') && this.wrapped.goBack();

        showToast = (type, title, msg) => this.props.actions.addToast(type, undefined, msg, title);

        joinTeam = team => {
            this.teamName = team.name;
            this.props.actions.joinChallengeByTeamId(team.entityId, this.teamName);
        };

        saveRef = ref => this.wrapped = ref;

        onChangeSearchText = value => {
            this.setState({ searchText: value });
            this._doSearch(value);
        }

        clear = () => {
            this.setState({ searchText: '' });
        }

        _doSearch = searchText => {
            const { actions, challengeId } = this.props;
            if (searchText) {
                actions.searchTeamsForGroupChallenge({ challengeId, searchText });
            }
        }

        get hasMore() {
            return this.teamsMembershipInfo.length < this.count;
        }

        get count() {
            return _.get(this.props, 'challenge.numChallengeParticipants', 0);
        }

        get teamsMembershipInfo() {
            const { searchText } = this.state;
            const { searchingResultTeams } = this.props;

            if (searchText) {
                return searchingResultTeams;
            }

            return this.props.hasInvitingTeams
                ? this.props.challengeMembersFiltered
                : this.props.challengeMembers;
        }

        get searchPlaceHolder() {
            return this.props.i18n.t('searchPlaceHolder');
        }

        get noTeamsFoundText() {
            return this.props.i18n.t('noTeamsFound');
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    joinTeam={this.joinTeam}
                    loadMore={this.loadMore}
                    isFirstLoading={this.isFirstLoading}
                    ref={this.saveRef}
                    hasMore={this.hasMore}
                    teamsMembershipInfo={this.teamsMembershipInfo}
                    searchPlaceHolder={this.searchPlaceHolder}
                    searchText={this.state.searchText}
                    onChangeSearchText={this.onChangeSearchText}
                    clear={this.clear}
                    noTeamsFoundText={this.noTeamsFoundText}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const challengeId = ownProps.challengeId || _.get(ownProps, 'match.params.challengeId') || _.get(ownProps, 'route.params.challengeId');
        const isFeaturedChallenge = ownProps.isFeaturedChallenge || _.get(ownProps, 'match.params.isFeaturedChallenge') || _.get(ownProps, 'route.params.isFeaturedChallenge');
        return {
            challengeId,
            error: selectors.getJoiningError(state),
            challengeMembers: selectors.getChallengeMembers(state, challengeId),
            challengeMembersFiltered: selectors.getChallengeMembersFiltered(state, challengeId),
            challenge: selectors.getChallenge(state, challengeId),
            isJoining: selectors.isJoiningChallenge(state, challengeId),
            isTeamChallenge: selectors.isTeamChallenge(state, challengeId),
            isLoading: selectors.isLoadingChallengeMembers(state, challengeId) ||
            selectors.isLoadingMembership(state, challengeId) ||
            selectors.isLoadingChallengeInfo(state, challengeId) ||
            selectors.teamSearchIsloading(state, challengeId),
            searchingResultTeams: selectors.getTeamSearchResults(state),
            isFeaturedChallenge,
        };
    };

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators({ ...coreActions, ...actions }, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(JoinTeamsListBase));
}

export const styles = {
    main: {
        backgroundColor: baseColors.white,
        flex: 1,
        marginTop: spacing.s1,
        marginBottom: spacing.s1,
    },
    rowTitleStyle: {
        ...appFonts.mdBold,
        marginBottom: spacing.s0,
        marginLeft: spacing.s2,
        color: baseColors.black
    },
    rowSubtitle: {
        color: baseColors.grey40,
        ...appFonts.xsMedium,
        marginLeft: spacing.s2
    },
    emptyComponent: {
        justifyContent: 'flex-start',
        flex: 0.3
    },
    avatar: {
        marginRight: spacing.s2
    },
    bottomElementOffset: {
        marginBottom: spacing.s1
    }
};
