import systemBuildVariant, { BUILDS } from '../config/buildVariant/buildVariant';
import baseColors from './commonColor';
import { ICONS } from './constants';

const medibankColor = {
    eat: { bgr: '#009E8C', icon: baseColors.white, iconName: ICONS.eat },
    move: { bgr: '#2E92E9', icon: baseColors.white, iconName: ICONS.move },
    mind: { bgr: '#5E50A1', icon: baseColors.white, iconName: ICONS.mind },
    breathe: { bgr: '#5E50A1', icon: baseColors.white, iconName: ICONS.breathe },
    company: { bgr: '#0071C7', icon: baseColors.white, iconName: ICONS.company },
    program: { bgr: '#0071C7', icon: baseColors.white, iconName: ICONS.program },
    users: { bgr: '#0071C7', icon: baseColors.white, iconName: ICONS.users },
    leaf: { bgr: baseColors.grey70, icon: baseColors.black, iconName: ICONS.leaf },
    clipboard: { bgr: baseColors.white, icon: baseColors.primary, iconName: ICONS.clipboard },
    goal: { icon: baseColors.grey70, iconName: ICONS.goal },
    challenge: { icon: baseColors.colourBonus, iconName: ICONS.challenge }
};

const sproutColor = {
    eat: { bgr: '#009E8C', icon: baseColors.white, iconName: ICONS.eat },
    move: { bgr: '#2E92E9', icon: baseColors.white, iconName: ICONS.move },
    mind: { bgr: '#5E50A1', icon: baseColors.white, iconName: ICONS.mind },
    breathe: { bgr: '#5E50A1', icon: baseColors.white, iconName: ICONS.breathe },
    company: { bgr: '#0071C7', icon: baseColors.white, iconName: ICONS.company },
    program: { bgr: '#0071C7', icon: baseColors.white, iconName: ICONS.program },
    users: { bgr: '#0071C7', icon: baseColors.white, iconName: ICONS.users },
    leaf: { bgr: baseColors.grey70, icon: baseColors.black, iconName: ICONS.leaf },
    clipboard: { bgr: baseColors.white, icon: baseColors.primary, iconName: ICONS.clipboard },
    goal: { icon: baseColors.grey70, iconName: ICONS.goal },
    challenge: { icon: baseColors.colourBonus, iconName: ICONS.challenge }
};

const iconColors = (systemBuildVariant === BUILDS.mylivebetter) ? medibankColor : sproutColor;

export default iconColors;