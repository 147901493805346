import _ from 'lodash';
import { persistReducer } from 'redux-persist';
import * as types from './actionTypes';
import { STREAM_ENTITY_TYPES, STREAM_ENTITY_ID_DEFAULT, NAME } from './constants';
import { actionTypes as coreTypes, Storage, tracker } from '../core';
import { actionTypes as commentsActionTypes, constants as commentsConstants } from '../commentslikes';

export const initialState = {
    items: {}, // all items
    [STREAM_ENTITY_TYPES.feed]: {
        [STREAM_ENTITY_ID_DEFAULT]: []
    }, // for home page
    [STREAM_ENTITY_TYPES.group]: {},
    [STREAM_ENTITY_TYPES.challenge]: {},
    [STREAM_ENTITY_TYPES.individuals]: {
        [STREAM_ENTITY_ID_DEFAULT]: [] // for loaded on details page where no entityType and entityId provided
    },
    refreshTime: 0,

    dailyMetrics: {
        activities: { count: 0, detail: [] },
        metrics: [],
        points: {}
    },

    filter: null,
    disableStreamsWeights: 0
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_STREAMS.SUCCESS: {
            const {
                items,
                ids,
                entityType,
                entityId,
            } = action.payload;
            const filteredItems = { ...state[entityType], [entityId]: ids };
            return {
                ...state,
                refreshTime: new Date().getTime(),
                [entityType]: filteredItems,
                items: { ...state.items, ...items }
            };
        }
        case types.GET_MORE_STREAMS.SUCCESS: {
            const {
                items,
                ids,
                entityType = STREAM_ENTITY_TYPES.feed,
                entityId = STREAM_ENTITY_ID_DEFAULT
            } = action.payload;
            const filteredItems = {
                ...state[entityType],
                [entityId]: _.uniq([...(state[entityType][entityId] || []), ...ids])
            };
            return {
                ...state,
                refreshTime: new Date().getTime(),
                [entityType]: filteredItems,
                items: { ...state.items, ...items }
            };
        }
        case types.DELETE_STREAM.SUCCESS:
        case types.MODERATE_STREAM.SUCCESS: {
            const { postId } = action.payload;
            const steramTypes = _.values(STREAM_ENTITY_TYPES);
            const newState = _.mapValues(_.pick(state, steramTypes),
                entityType => _.mapValues(entityType,
                    entityId => _.includes(entityId, postId) ? _.filter(entityId, item => item !== postId) : entityId));
            return {
                ...state,
                ...newState,
                items: _.omit(state.items, [postId])
            };
        }
        case types.GET_STREAM.SUCCESS: {
            const { item: newItem, entityType, entityId, isFirst } = action.payload;
            const items = { ...state.items };
            let streams = state[entityType][entityId];
            const existing = items[newItem.streamItemId];
            items[newItem.streamItemId] = existing ? { ...existing, ...newItem } : newItem;
            if (!existing) {
                streams = isFirst ? [newItem.streamItemId, ...streams] : [...streams, newItem.streamItemId];
            }
            return {
                ...state,
                [entityType]: { ...state[entityType], [entityId]: streams },
                items
            };
        }
        case types.POST_STREAM.SUCCESS: {
            const { item: newItem, entityType, entityId, isFirst } = action.payload;
            const streams = isFirst ? [newItem.streamItemId, ...state[entityType][entityId]]
                : [...state[entityType][entityId], newItem.streamItemId];
            return {
                ...state,
                [entityType]: { ...state[entityType], [entityId]: streams },
                items: {
                    ...state.items,
                    [newItem.streamItemId]: { ...state.items[newItem.streamItemId], ...newItem }
                }
            };
        }
        case types.UPDATE_STREAM.SUCCESS: {
            const { item: newItem } = action.payload;
            return {
                ...state,
                items: {
                    ...state.items,
                    [newItem.streamItemId]: { ...state.items[newItem.streamItemId], ...newItem }
                }
            };
        }
        case commentsActionTypes.ADD_LIKE.SUCCESS: {
            if (action.payload.entityType === commentsConstants.LIKES_ENTITY_TYPES.stream) {
                const { likedItemId: streamItemId, like, user } = action.payload;
                const stream = state.items[streamItemId];
                const likeCount = stream.likeCount + 1;
                const highfives = [...stream.highfives, {
                    userID: user.userId,
                    likeId: like.likeId,
                    firstname: user.firstNameDisplay,
                    lastname: user.lastNameDisplay,
                    preferredname: (user.preferredName === null) ? '' : user.preferredName
                }];
                const viewer = { ...stream.viewer, isLikedByViewer: 1, likeId: like.likeId };
                const items = {
                    ...state.items,
                    [streamItemId]: { ...stream, highfives, likeCount, viewer }
                };
                return { ...state, items };
            }
            return state;
        }
        case commentsActionTypes.DELETE_LIKE.SUCCESS: {
            if (action.payload.entityType === commentsConstants.LIKES_ENTITY_TYPES.stream) {
                const { likedItemId: streamItemId, likeId } = action.payload;
                const stream = state.items[streamItemId];
                const likeCount = stream.likeCount - 1;
                const highfives = _.filter(stream.highfives, highfive => highfive.likeId !== likeId);
                const viewer = { ...stream.viewer, isLikedByViewer: 0, likeId: 0 };
                const items = {
                    ...state.items,
                    [streamItemId]: { ...stream, highfives, likeCount, viewer }
                };
                return { ...state, items };
            }
            return state;
        }
        case commentsActionTypes.MODERATE_COMMENT.SUCCESS:
        case commentsActionTypes.DELETE_COMMENT.SUCCESS: {
            if (action.payload.commentedItemType === commentsConstants.LIKES_ENTITY_TYPES.stream) {
                const { commentedItemId } = action.payload;
                const item = state.items[commentedItemId];
                return {
                    ...state,
                    items: {
                        ...state.items,
                        [commentedItemId]: { ...item, commentCount: item.commentCount - 1 }
                    }
                };
            }
            return state;
        }
        case commentsActionTypes.POST_COMMENT.SUCCESS: {
            if (action.payload.commentedItemType === commentsConstants.LIKES_ENTITY_TYPES.stream) {
                const { commentedItemId } = action.payload;
                const item = state.items[commentedItemId];
                return {
                    ...state,
                    items: {
                        ...state.items,
                        [commentedItemId]: { ...item, commentCount: item.commentCount + 1 }
                    }
                };
            }
            return state;
        }
        case types.SET_STREAM_VIEWER_MEMBER: {
            const { relatedToId } = action.payload;
            return {
                ...state,
                items: _.mapValues(state.items, item => item.relatedToId === relatedToId
                    ? { ...item, viewer: { ...item.viewer, isMember: 1 } } : item)
            };
        }
        case types.REMOVE_STREAM_VIEWER_MEMBER: {
            const { relatedToId } = action.payload;
            return {
                ...state,
                items: _.mapValues(state.items, item => item.relatedToId === relatedToId
                    ? { ...item, viewer: { ...item.viewer, isMember: 0 } } : item)
            };
        }
        case types.DELETE_STREAMS_FOR_ID: {
            const { entityId, entityType } = action.payload;
            return {
                ...state,
                [entityType]: _.omit(state[entityType], [entityId])
            };
        }
        case types.DELETE_STREAMS_RELATED_ITEMS_DETAILS: {
            const { entityId, entityType } = action.payload;
            const items = _.mapValues(state.items, item => item.relationTypeSlug === entityType &&
            item.relatedToId.toString() === entityId.toString() ? { ...item, relatedItemDetails: [] } : item);
            return {
                ...state,
                items
            };
        }
        case types.GET_DAILY_METRICS.SUCCESS: {
            return {
                ...state,
                dailyMetrics: action.payload.data
            };
        }
        case types.SET_FILTER: {
            return {
                ...state,
                filter: action.payload.filter
            };
        }
        case types.SET_STREAM_WEIGHT: {
            return {
                ...state,
                disableStreamsWeights: action.payload.disableStreamsWeights
            };
        }
        case types.TEMP_ADD_REACTION: {
            const { likedItemId, reactions, reactionsCount, userReactionList } = action.payload;
            return {
                ...state,
                items: {
                    ...state.items,
                    [likedItemId]: {
                        ...state.items[likedItemId],
                        reactionsCount,
                        reactions,
                        userReactionList,
                    }
                }
            };
        }
        case coreTypes.RESET: {
            return initialState;
        }
        default:
            return state;
    }
};

const serialize = data => {
    try {
        return JSON.stringify(data);
    } catch (err) {
        const message = 'Failed to serialize feeds data';
        const serializeError = JSON.stringify({
            message,
            err
        });
        tracker.logError(serializeError);
        return JSON.stringify({});
    }
};

const persistConfig = {
    key: NAME,
    storage: Storage.storageType(),
    serialize
};

export default persistReducer(persistConfig, reducer);