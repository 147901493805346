import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { StyleSheet, css } from 'aphrodite-jss';

import { baseColors, layoutStyle, spacing } from '../../../../styles';
import WithNumericQuestionBase, { styles as baseStyles, ICON_SIZE } from './NumericQuestionBase';
import { components as Core } from '../../../core';

class NumericQuestion extends PureComponent {
    static propTypes = {
        question: PropTypes.object.isRequired,
        onClick: PropTypes.func.isRequired,
        i18n: PropTypes.object.isRequired,
        answer: PropTypes.string.isRequired,
        isWellbeingSurvey: PropTypes.bool
    };

    static defaultProps = {
        isWellbeingSurvey: false
    }

    constructor(props) {
        super(props);
        this.state = {
            text: props.answer,
            error: ''
        };
    }

    onInputChange = e => {
        const { question, i18n } = this.props;
        const { value } = e.target;
        this.setState({ text: value });
        const answer = parseInt(value);
        if (Number.isNaN(answer) || answer < question.validation.min || answer > question.validation.max) {
            this.setState({ error: i18n.t('invalid_numeric_response_range', {
                minimum: question.validation.min,
                maximum: question.validation.max
            }) });
        }
        else if (this.state.error !== '') this.setState({ error: '' });
    };

    onNext = () => {
        const value = parseInt(this.state.text);
        this.props.onClick(value);
    };

    render() {
        const { question, isWellbeingSurvey } = this.props;
        const { error, text } = this.state;

        const inputProps = {
            input: css(styles.input),
            root: css(styles.root)
        };

        const classes = { root: css(styles.root) };

        return (
            <div className={css(styles.containerPadding)}>
                <div className={css(layoutStyle.flexColumnCenter)}>
                    <div className={css(styles.header)}>
                        {
                            !_.isEmpty(question.icon) ?
                                <div className={css(styles.icon)}>
                                    <Core.Icon
                                        type="fa"
                                        name={question.icon}
                                        size={ICON_SIZE}
                                        color={baseColors.primary}
                                    />
                                </div> : null
                        }
                    </div>
                    <div className={css(styles.questionText)}>
                        {question.text}
                    </div>
                    <Core.Input
                        id={_.camelCase(`${question.label}NumericInput`)}
                        autoFocus={true}
                        onChange={this.onInputChange}
                        fullWidth={false}
                        InputProps={{ classes: inputProps }}
                        classes={classes}
                        helperText={error}
                        error={!!error}
                        value={text}
                        type="number"
                    />
                </div>
                <Core.Layout.FixedModalFooterButton>
                    <Core.Button
                        id="numericQuestionNextBtn"
                        type="contained"
                        size="medium"
                        disabled={!text || !!error}
                        className={css(layoutStyle.halfWidth, isWellbeingSurvey && styles.wellbeingSurveyContainer)}
                        onPress={this.onNext}>
                        {this.props.i18n.t('next')}
                    </Core.Button>
                </Core.Layout.FixedModalFooterButton>
            </div>
        );
    }
}

const CONTAINER_WIDTH_FOR_WELLBEING_SURVEY= 400;
const styles = StyleSheet.create({
    ...baseStyles,
    input: {
        marginTop: 0
    },
    root: {
        width: '50%',
        alignItems: 'center'
    },
    wellbeingSurveyContainer: {
        maxWidth: CONTAINER_WIDTH_FOR_WELLBEING_SURVEY,
    },
    containerPadding: {
        paddingBottom: spacing.s16
    }
});

export default WithNumericQuestionBase(NumericQuestion);
