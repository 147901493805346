import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { translate } from '../../../../core';

export default function WithEarnPartnersProfileTabItemBase(WrappedComponent) {
    class EarnPartnersProfileTabItemBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                />
            );
        }
    }

    return translate()(EarnPartnersProfileTabItemBase);
}