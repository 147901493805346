import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { baseColors, fontSize, spacing } from '../../../styles';
import translate from '../services/translate';

export const HEIGHT = 30;

const Footer = React.memo(({ i18n }) => (
    <footer className={css(styles.footerContainer)}>
        <div className={css(styles.footer)}>
            <span>{i18n.t('copyright')}</span>
        </div>
    </footer>
));

Footer.propTypes = {
    i18n: PropTypes.object.isRequired
};

const styles = StyleSheet.create({
    footerContainer: {
        height: HEIGHT,
        marginTop: spacing.s5,
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        width: '100%'
    },
    footer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 5,
        minHeight: '100%',
        background: baseColors.primary,
        color: baseColors.white,
        fontSize: fontSize.small
    }
});

export default translate()(Footer);
