/*eslint radix: "error"*/
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import _ from 'lodash';

import { translate, actions as coreActions, Platform, PLATFORMS } from '../../../../core';
import { selectors as authSelectors, actions as authActions } from '../../../../auth';
import { spacing } from '../../../../../styles';
import * as actions from '../../../actions';
import {
    getUserPreferredHeightUnit,
    getUserPreferredWeightUnit,
    getUserPersonalSettings,
    isLoadingPersonalSettings,
    isSavingPersonalSettings,
    getWaistInchesValue,
} from '../../../selectors';
import * as helper from '../../../services/profileEditorHelper';
import { STATE_KEYS } from '../../../constants';
import { constants as onboardingConstants } from '../../../../onboarding';
import { formatTypedDateForWeb } from '../../../../onboarding/services/validationHelper';

const TIME = {
    hour: 12,
    minute: 0,
    second: 0
};

export default function WithPersonalEditorBase(WrappedComponent) {
    class PersonalEditorBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            coreActions: PropTypes.object.isRequired,
            authActions: PropTypes.object.isRequired,
            personalFields: PropTypes.array,
            hasUnsavedChanges: PropTypes.func,
            userPreferredHeightUnit: PropTypes.string,
            userPreferredWeightUnit: PropTypes.string,
            isLoading: PropTypes.bool,
            isSaving: PropTypes.bool,
            i18n: PropTypes.object.isRequired,
            waistInchesValue: PropTypes.number.isRequired,
        };

        static defaultProps = {
            personalFields: [],
            hasUnsavedChanges: null,
            userPreferredHeightUnit: undefined,
            userPreferredWeightUnit: undefined,
            isLoading: false,
            isSaving: false,
        };

        static getDerivedStateFromProps(nextProps, prevState) {
            const state = { isLoadingOrSaving: nextProps.isLoadingOrSaving, isLoadingUser: nextProps.isLoadingUser };
            let initialHeightValue = '';
            let initialWeightValue = '';
            if (prevState.isLoadingOrSaving && !nextProps.isLoadingOrSaving) {
                const personalFields = _.map(nextProps.personalFields, field => {
                    if (field.stateKey === STATE_KEYS.gender) {
                        return {
                            ...field,
                            value: helper.getGenderFromParam(field.value)
                        };
                    }
                    else if (field.stateKey === STATE_KEYS.heightInches) {
                        const value = field.value === null ?
                            '' : Math.round((nextProps.userPreferredHeightUnit === onboardingConstants.CM) ?
                                helper.convertInchesToCm(field.value) :
                                helper.convertInchesToFtAndInches(field.value, onboardingConstants.IN)).toString();
                        const ftValue = helper.convertInchesToFtAndInches(field.value);
                        initialHeightValue = value;
                        return { ...field, value, ftValue };
                    }
                    else if (field.stateKey === STATE_KEYS.weightLbs) {
                        const value = field.value === null ?
                            '' : Math.round((nextProps.userPreferredWeightUnit === onboardingConstants.KG) ?
                                helper.convertLbsToKg(field.value) :
                                field.value).toString();
                        initialWeightValue = value;
                        return { ...field, value };
                    }
                    return field;
                });
                return {
                    ...state,
                    // storedInputs is responsible for remembering the entered values in case if user just toggles the units without changing a value
                    storedInputs: {
                        [STATE_KEYS.heightInches]: {
                            unit: nextProps.userPreferredHeightUnit,
                            unitToCompare: 'userPreferredHeightUnit',
                            value: initialHeightValue
                        },
                        [STATE_KEYS.weightLbs]: {
                            unit: nextProps.userPreferredWeightUnit,
                            unitToCompare: 'userPreferredWeightUnit',
                            value: initialWeightValue
                        }
                    },
                    personalFields,
                    userPreferredWeightUnit: nextProps.userPreferredWeightUnit,
                    userPreferredHeightUnit: nextProps.userPreferredHeightUnit
                };
            }
            return state;
        }

        constructor(props) {
            super(props);
            const personalFields = [...props.personalFields];
            const heightFieldIndex = this.getHeightFieldIndex(props.personalFields);
            const heightField = props.personalFields[heightFieldIndex];
            const inchesValue = _.get(heightField, 'value');
            personalFields[heightFieldIndex] = {
                ...heightField,
                value: props.userPreferredHeightUnit === onboardingConstants.CM
                    ? Math.round(helper.convertInchesToCm(inchesValue))
                    : helper.convertInchesToFtAndInches(inchesValue, onboardingConstants.IN),
                ftValue: helper.convertInchesToFtAndInches(inchesValue)
            };

            this.state = {
                personalFields,
                userPreferredWeightUnit: props.userPreferredWeightUnit,
                userPreferredHeightUnit: props.userPreferredHeightUnit,
                errors: {},
            };
            this.props.actions.getUserSettings();
        }

        getHeightFieldIndex = fields => _.findIndex(fields, f => f.stateKey === STATE_KEYS.heightInches);

        onGenderChange = passedValue => {
            this.trackChanges(true);
            const personalFields = helper.updateProfileValue(this.state.personalFields, STATE_KEYS.gender, passedValue - 1, true);
            this.setState(() => ({ personalFields }));
        };

        static formatDate = date => new Date(moment(date).set(TIME).format());

        onDateChange = event => {
            this.trackChanges(true);
            if (Platform.OS === PLATFORMS.web) {
                const dob = event.currentTarget.value;
                const updatedString = formatTypedDateForWeb({ dob });
                const personalFields = helper.updateProfileValue(this.state.personalFields, STATE_KEYS.dateOfBirth, updatedString);
                this.setState(() => ({ personalFields }));
            } else {
                const dob = event;
                const dateOfBirth = moment(dob).set(TIME).format();
                const personalFields = helper.updateProfileValue(this.state.personalFields, STATE_KEYS.dateOfBirth, dateOfBirth);
                this.setState(() => ({ personalFields }));
            }
        }

        onUnitHeightChange = userPreferredHeightUnit => {
            this.trackChanges(true);
            const convertedValue = this.convertValueOnUnitChange(STATE_KEYS.heightInches, 'userPreferredHeightUnit', userPreferredHeightUnit);
            if (convertedValue) {
                const personalFields = helper.updateProfileValue(
                    this.state.personalFields,
                    STATE_KEYS.heightInches,
                    _.isObject(convertedValue) ? convertedValue.inches || '' : convertedValue
                );
                const heightFieldIndex = this.getHeightFieldIndex(personalFields);
                if (_.isObject(convertedValue)) personalFields[heightFieldIndex].ftValue = convertedValue.ft || '';
                this.setState({
                    userPreferredHeightUnit,
                    personalFields
                });
            } else this.setState({ userPreferredHeightUnit });
        };

        onUnitWeightChange = userPreferredWeightUnit => {
            this.trackChanges(true);
            const convertedValue = this.convertValueOnUnitChange(STATE_KEYS.weightLbs, 'userPreferredWeightUnit', userPreferredWeightUnit);
            if (convertedValue) {
                const personalFields = helper.updateProfileValue(this.state.personalFields, STATE_KEYS.weightLbs, convertedValue);
                this.setState({ userPreferredWeightUnit, personalFields });
            } else this.setState({ userPreferredWeightUnit });
        };

        convertValueOnUnitChange = (stateKey, unitName, unitValue) => {
            const { value, unit } = this.state.storedInputs[stateKey];

            if (unit === unitValue) {
                // if user toggles the unit without previously changing the field value, we show the stored value
                return (unitValue !== onboardingConstants.CM && stateKey === STATE_KEYS.heightInches)
                    ? helper.convertCmToFtAndInches(this.getFieldByStateKey(STATE_KEYS.heightInches).value)
                    : value;
            }

            if (stateKey === STATE_KEYS.heightInches) {
                const heightField = this.getFieldByStateKey(stateKey);
                return (unitValue === onboardingConstants.CM)
                    ? helper.convertFtAndInchesToCm(heightField.ftValue || 0, heightField.value || 0)
                    : helper.convertCmToFtAndInches(heightField.value || 0);
            }

            const oldValue = _.toString(this.getFieldByStateKey(stateKey).value);
            const oldValueNumber = _.parseInt(oldValue);
            if (_.isEmpty(oldValue) || _.isNaN(oldValueNumber)) return null;

            let newValue;
            if (stateKey === STATE_KEYS.weightLbs) {
                newValue = (unitValue === onboardingConstants.KG)
                    ? helper.convertLbsToKg(oldValue)
                    : helper.convertKgToLbs(oldValue);
            }
            return newValue.toFixed(2);
        };

        trackChanges = hasChanges => this.props.hasUnsavedChanges && this.props.hasUnsavedChanges(hasChanges);

        get genderValue() {
            return this.getFieldByStateKey(STATE_KEYS.gender) ? this.getFieldByStateKey(STATE_KEYS.gender).value : '';
        }

        get dateOfBirthValue() {
            if (Platform.OS === PLATFORMS.web) {
                const value = this.getFieldByStateKey(STATE_KEYS.dateOfBirth) ? this.getFieldByStateKey(STATE_KEYS.dateOfBirth).value : null;
                if (value) {
                    const fixedValue = value.replaceAll('-', '/');
                    return fixedValue;
                }

                return null;
            } else {
                const value = this.getFieldByStateKey(STATE_KEYS.dateOfBirth) ?
                    this.getFieldByStateKey(STATE_KEYS.dateOfBirth).value : null;
                const dateOfBirth = moment(value).set(TIME).toDate();
                return value ? dateOfBirth : null;
            }
        }

        getFieldByStateKey = stateKey => _.find(this.state.personalFields, e => e.stateKey === stateKey);

        saveChanges = () => {
            const errors = helper.validateProfileValues(this.state.personalFields, this.state.userPreferredHeightUnit, this.state.userPreferredWeightUnit);
            this.setState(() => ({ errors }));
            if (!_.isEmpty(errors)) {
                this.props.actions.validateProfileDataError();
            } else {
                this.trackChanges(false);
                const personalSend = {};
                this.state.personalFields.forEach(field => {
                    if (field.stateKey === STATE_KEYS.gender) {
                        personalSend[field.stateKey] = helper.getGenderParamValue(field.value);
                    }
                    else if (field.stateKey === STATE_KEYS.heightInches) {
                        const heightVal = parseFloat(field.value);
                        personalSend[field.stateKey] = (this.state.userPreferredHeightUnit === onboardingConstants.CM)
                            ? helper.convertCmToInches(heightVal)
                            : helper.convertFtToInches(parseFloat(field.ftValue)) + heightVal;
                    } else if (field.stateKey === STATE_KEYS.weightLbs) {
                        const weightVal = parseFloat(field.value);
                        personalSend[field.stateKey] = (this.state.userPreferredWeightUnit === onboardingConstants.KG) ? helper.convertKgToLbs(weightVal) : weightVal;
                    } else if (field.stateKey === STATE_KEYS.dateOfBirth && Platform.OS === PLATFORMS.web) {
                        personalSend[field.stateKey] = PersonalEditorBase.formatDate(field.value);
                    } else {
                        personalSend[field.stateKey] = field.value;
                    }
                });
                personalSend.userPreferredHeightUnit = this.state.userPreferredHeightUnit;
                personalSend.userPreferredWeightUnit = this.state.userPreferredWeightUnit;
                personalSend[STATE_KEYS.waistInches] = this.props.waistInchesValue;
                this.props.actions.savePersonal(personalSend);
                return true;
            }
        };

        updatePersonalValue = (item, text, inputType) => {
            const { unit, unitToCompare } = this.state.storedInputs[item.stateKey];
            this.trackChanges(true);
            this.setState(oldState => {
                // if user changes value after toggling the unit, we store the new value and unit
                const newState = (unit !== this.state[unitToCompare]) ? {
                    value: text,
                    unit: oldState[unitToCompare]
                } : { value: text };
                let personalFields = helper.updateProfileValue(oldState.personalFields, item.stateKey, text);
                if (item.stateKey === STATE_KEYS.heightInches && inputType === STATE_KEYS.feetValue) {
                    newState.value = oldState.storedInputs[item.stateKey].value;
                    newState.ftValue = text || '';
                    personalFields = oldState.personalFields;
                    personalFields[this.getHeightFieldIndex(oldState.personalFields)].ftValue = text;
                }
                return ({
                    storedInputs: {
                        ...oldState.storedInputs,
                        [item.stateKey]: {
                            ...oldState.storedInputs[item.stateKey],
                            ...newState
                        }
                    },
                    personalFields
                });
            });
        };

        get feetLabel() {
            return this.props.i18n.t('onboarding.vital_stats.height_ft');
        }

        get inchesOrCmLabel() {
            return this.state.userPreferredHeightUnit === onboardingConstants.CM
                ? this.props.i18n.t('height')
                : this.props.i18n.t('onboarding.vital_stats.height_in');
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    saveChanges={this.saveChanges}
                    getFieldByStateKey={this.getFieldByStateKey}
                    genderValue={this.genderValue}
                    onGenderChange={this.onGenderChange}
                    dateOfBirthValue={this.dateOfBirthValue}
                    onDateChange={this.onDateChange}
                    updatePersonalValue={this.updatePersonalValue}
                    onUnitHeightChange={this.onUnitHeightChange}
                    onUnitWeightChange={this.onUnitWeightChange}
                    personalFields={this.state.personalFields}
                    errors={this.state.errors}
                    userPreferredHeightUnit={this.state.userPreferredHeightUnit}
                    userPreferredWeightUnit={this.state.userPreferredWeightUnit}
                    feetLabel={this.feetLabel}
                    inchesOrCmLabel={this.inchesOrCmLabel}
                />
            );
        }
    }

    const mapStateToProps = state => {
        const isLoading = isLoadingPersonalSettings(state);
        const isSaving = isSavingPersonalSettings(state);
        return {
            personalFields: getUserPersonalSettings(state),
            userPreferredHeightUnit: getUserPreferredHeightUnit(state),
            userPreferredWeightUnit: getUserPreferredWeightUnit(state),
            isLoading,
            isSaving,
            isLoadingOrSaving: isLoading || isSaving,
            isUpdatingUser: authSelectors.isUpdatingUser(state),
            isLoadingUser: authSelectors.isLoadingCurrentUser(state),
            waistInchesValue: getWaistInchesValue(state),
        };
    };

    const mapDispatchToProps = dispatch => ({
        actions: bindActionCreators(actions, dispatch),
        coreActions: bindActionCreators(coreActions, dispatch),
        authActions: bindActionCreators(authActions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(PersonalEditorBase));
}

export const styles = {
    innerContainer: {
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3
    }
};
