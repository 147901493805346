import React from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';
import classnames from 'classnames';
import { spacing, layoutStyle, appFonts, baseColors } from '../../../../styles';
import Avatar from '../Avatar';
import TextSeeMore from '../TextSeeMore';
import UserAvatarHeaderBase, { styles as baseStyles } from './UserAvatarHeaderBase';

const UserAvatarHeader = ({ user, action, title, hideTitle, subtitle, onClick, className, bodyClassName, isAvatarEnabled, isCurrentUser, avatarClassName, feedLabelClassName }) => (
    <div className={classnames(css(styles.userPanel), className)}>
        <Avatar
            isCurrentUser={isCurrentUser}
            url={user.avatarURL}
            name={`${user.firstNameDisplay} ${user.lastNameDisplay}`}
            id={user.userId}
            className={classnames(css(styles.avatar), avatarClassName)}
            disablePress={!isAvatarEnabled}
        />
        <button className={classnames(css(styles.body), bodyClassName)} onClick={onClick}>
            <div className={css(layoutStyle.flex1)}>
                {!hideTitle ?
                    <TextSeeMore
                        numberOfLines={1}
                        className={css(styles.title)}
                        isCollapseHidden={true}
                        isExpandHidden={true}>
                        {title ? title : `${user.firstNameDisplay} ${user.lastNameDisplay}`}
                    </TextSeeMore> : null}
                {subtitle ?
                    <TextSeeMore
                        numberOfLines={1}
                        className={classnames((hideTitle ? css(styles.feedSubtitle) : css(styles.subtitle)), feedLabelClassName)}
                        isCollapseHidden={true}
                        isExpandHidden={true}>
                        {subtitle}
                    </TextSeeMore> : null}
            </div>
            {action}
        </button>
    </div>
);

UserAvatarHeader.propTypes = {
    user: PropTypes.object.isRequired,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    action: PropTypes.node,
    onClick: PropTypes.func,
    className: PropTypes.string,
    bodyClassName: PropTypes.string,
    isAvatarEnabled: PropTypes.bool,
    isCurrentUser: PropTypes.bool,
    hideTitle: PropTypes.bool,
    avatarClassName: PropTypes.string,
    feedLabelClassName: PropTypes.string,
};

UserAvatarHeader.defaultProps = {
    title: undefined,
    subtitle: undefined,
    action: undefined,
    onClick: undefined,
    className: undefined,
    bodyClassName: undefined,
    isAvatarEnabled: false,
    isCurrentUser: false,
    hideTitle: false,
    avatarClassName: undefined,
    feedLabelClassName: undefined
};

export default UserAvatarHeaderBase(UserAvatarHeader);

const styles = StyleSheet.create({
    ...baseStyles,
    userPanel: {
        ...baseStyles.userPanel,
        marginTop: spacing.s3,
        marginBottom: spacing.s3,
        marginLeft: spacing.s3,
        display: 'flex'
    },
    avatar: {
        marginRight: spacing.s5
    },
    body: {
        ...baseStyles.body,
        display: 'flex',
        alignItems: 'center'
    },
    feedSubtitle: {
        ...appFonts.mdRegular,
        color: baseColors.grey40,
    },
});