import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { css, StyleSheet } from 'aphrodite-jss';
import { layoutStyle, headerHeight } from '../../../styles';
import { components as Core } from '../../core';
import { selectors as onboardingSelectors } from '../../onboarding';
import Header from './Header';
import { selectors as settingsSelectors } from '../../settings';
import { selectors as authSelectors } from '../../auth';

const commonComponents = (
    <React.Fragment>
        <Core.GlobalToast />
        <Core.AlertComponent />
        <Core.ActionSheetComponent />
        <Core.ModalComponent />
        <Core.ScrollTop />
        <Core.TrackConnection />
        <Core.ResetScroll />
    </React.Fragment>
);

const Root = ({ children, isLoggedIn, isShowingOnboarding, score, isLoadingScore, isHealthSurveyEnabled, company }) => isLoggedIn && !isShowingOnboarding ? (
    <div className={css(layoutStyle.page, styles.page)}>
        <Header score={score} isLoadingScore={isLoadingScore} isHealthSurveyEnabled={isHealthSurveyEnabled} company={company}/>
        <main className={css(styles.main)}>
            {children}
        </main>
        {commonComponents}
    </div>
) : (
    <main>
        {children}
        {commonComponents}
    </main>
);

Root.propTypes = {
    children: PropTypes.node,
    isLoggedIn: PropTypes.bool,
    isShowingOnboarding: PropTypes.bool,
    score: PropTypes.number,
    isLoadingScore: PropTypes.bool,
    isHealthSurveyEnabled: PropTypes.bool,
};
Root.defaultProps = {
    children: null,
    isLoggedIn: false,
    isShowingOnboarding: false,
    score: null,
    isLoadingScore: true,
    isHealthSurveyEnabled: false
};

const styles = StyleSheet.create({
    page: {
        paddingTop: headerHeight
    },
    main: {
    }
});
const mapStateToProps = state => ({
    isShowingOnboarding: onboardingSelectors.isShowingOnboarding(state),
    score: settingsSelectors.getScore(state),
    isLoadingScore: onboardingSelectors.isLoadingScore(state),
    isHealthSurveyEnabled: onboardingSelectors.isHealthSurveyEnabled(state),
    company: authSelectors.getCompany(state),
});

export default connect(mapStateToProps)(Root);
