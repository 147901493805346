import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import _ from 'lodash';
import Icon from '../Icon';
import { spacing } from '../../../../styles';
import WithExploreItemMetaBase, { styles as baseStyles } from './ExploreItemMetaBase';


class ExploreItemMeta extends PureComponent {
    static propTypes = {
        iconName: PropTypes.string.isRequired,
        iconStyle: PropTypes.string.isRequired,
        textArray: PropTypes.array.isRequired,
        text: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
        isCarouselItem: PropTypes.bool.isRequired
    };

    get renderLabel() {
        const { textArray, text, color } = this.props;

        if (textArray.length > 0) {
            const dotIcon = isLastItem => (
                isLastItem ? null : (
                    <Icon
                        name="circle"
                        fill="solid"
                        type="fa"
                        className={css(styles.dotIcon)}
                        color={color}
                        size={spacing.s0}
                    />
                )
            );

            return (
                <div className={css(styles.labelContainer)}>
                    {_.map(textArray, (label, index) => {
                        const isLastItem = index === textArray.length - 1;
                        return (
                            <div className={css(styles.textItem)}>
                                <p className={css(styles.text)} style={{ color }}>{label}</p>
                                {dotIcon(isLastItem)}
                            </div>
                        );
                    })}
                </div>
            );
        } else {
            return (
                <div className={css(styles.labelContainer)}>
                    <div className={css(styles.textItem)}>
                        <p className={css(styles.text)} style={{ color }}>{text}</p>
                    </div>
                </div>
            );
        }
    }

    render() {
        const { isCarouselItem, iconName, iconStyle, color, mainContainerClassName, iconContainerClassName } = this.props;

        return (
            <div className={css(styles.mainContainer, isCarouselItem ? styles.carouselContainer : null, mainContainerClassName)}>
                <div className={css(styles.iconContainer, iconContainerClassName)}>
                    <Icon
                        type={iconStyle}
                        name={iconName}
                        size={spacing.s2}
                        color={color}
                    />
                </div>
                {this.renderLabel}
            </div>
        );
    }
}

export default WithExploreItemMetaBase(ExploreItemMeta);

const styles = StyleSheet.create({
    ...baseStyles,
    mainContainer: {
        ...baseStyles.mainContainer,
        display: 'flex',
        alignItems: 'center',
    },
    labelContainer: {
        ...baseStyles.labelContainer,
        display: 'flex',
    },
    textItem: {
        ...baseStyles.textItem,
        display: 'flex'
    },
    iconContainer: {
        marginBottom: spacing.s0 - 2,
        display: 'flex'
    }
});
