import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { REGISTRATION_STEPS as STEPS, REGISTRATION_ITEM_IDS as ITEM_IDS } from '../../constants';
import { selectors as coreSelectors } from '../../../core';
import { getSsoDetails, isPartnerDomain, hasDisclaimerStep, isRetail, getLocalRegistrationStep, hasSsoLoginUrl,
    hasPartnerSso, isLogining, getPartnerId } from '../../selectors';
import * as actions from '../../actions';

const ALLOWABLE_FIELDS = ['email', 'employee_id', 'policyAgree'];

export default function WithCorporateAccountLinkFlowBase(WrappedComponent) {
    class CorporateAccountLinkFlowBase extends PureComponent {
        static propTypes = {
            isSSO: PropTypes.bool.isRequired,
            hasDisclaimer: PropTypes.bool.isRequired,
            isPartnerDomain: PropTypes.bool.isRequired,
            isRetail: PropTypes.bool,
            step: PropTypes.string,
            localStep: PropTypes.string,
            partnerSubdomain: PropTypes.string.isRequired,
            actions: PropTypes.object.isRequired,
            ssoDetails: PropTypes.object.isRequired,
            hasSsoLoginUrl: PropTypes.bool.isRequired,
            hasPartnerSso: PropTypes.bool.isRequired
        };

        static defaultProps = {
            isRetail: false,
            step: undefined,
            localStep: undefined
        };

        constructor(props) {
            super(props);
            this.step = this.props.step || this.defaultStep;
        }

        getAdditionalProps(step) {
            switch (step) {
                case STEPS.register: {
                    return { allowableFields: ALLOWABLE_FIELDS };
                }
                default:
                    return {};
            }
        }

        determineRegistrationStep = (completedStep, userSelection) => {
            switch (completedStep) {
                case STEPS.programDomain: {
                    if (userSelection === ITEM_IDS.findProgramDomain) {
                        return STEPS.findProgramDomain;
                    }
                    // to do : refactor for corporate account link
                    if (this.props.hasSsoLoginUrl) {
                        return STEPS.signUpSelection;
                    }
                    // to do : refactor for corporate account link
                    if (this.props.isPartnerDomain) {
                        return STEPS.corporatePin;
                    }
                    return STEPS.register;
                }
                case STEPS.saml: {
                    if (userSelection === ITEM_IDS.samlError) {
                        return STEPS.programDomain;
                    }
                    return STEPS.register;
                }
                case STEPS.findProgramDomain: {
                    return STEPS.programDomain;
                }
                case STEPS.signUpSelection: {
                    return userSelection === ITEM_IDS.corporate ? STEPS.corporatePin : STEPS.register;
                }
                case STEPS.corporatePin: {
                    return STEPS.register;
                }
                case STEPS.register: {
                    return STEPS.location;
                }
                case STEPS.location: {
                    if (this.props.hasDisclaimer) {
                        return STEPS.disclaimer;
                    }
                    return STEPS.verifyCorporateLink;
                }
                case STEPS.disclaimer: {
                    return STEPS.verifyCorporateLink;
                }
                default:
                    break;
            }
        };

        get defaultStep() {
            return STEPS.corporatePin;
        }

        openNextPage = (step, userSelection = '', props = {}) => {
            const nextStep = this.determineRegistrationStep(step, userSelection);
            if (!this.wrapped.openNextPage) throw new Error('openNextPage should be implemented in wrapped component');
            this.wrapped.openNextPage(nextStep, userSelection, { ...props, ...this.getAdditionalProps(nextStep) });
        };

        saveRef = ref => (this.wrapped = ref);

        batchData = (data, step) => {
            this.props.actions.updateCorporateAccountLinkData(data);
            this.openNextPage(step);
        };

        updateLocalRegistrationStep = nextStep => {
            this.props.actions.updateLocalRegistrationStep(nextStep);
        };
        render() {
            return (
                <WrappedComponent
                    ref={this.saveRef}
                    {...this.props}
                    openNextPage={this.openNextPage}
                    step={this.step}
                    originStep={this.props.step}
                    avoidDataSubmissionCallback={this.batchData}
                    updateLocalRegistrationStep={this.updateLocalRegistrationStep}
                    getAdditionalProps={this.getAdditionalProps}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        const routeParams = _.get(ownProps, 'route.params');
        return {
            step: ownProps.step || _.get(ownProps, 'match.params.step'),
            ssoDetails: getSsoDetails(state),
            hasSsoLoginUrl: hasSsoLoginUrl(state),
            isPartnerDomain: isPartnerDomain(state),
            hasDisclaimer: hasDisclaimerStep(state),
            isRetail: isRetail(state),
            partnerSubdomain: coreSelectors.getPartnerSubdomain(state),
            localStep: getLocalRegistrationStep(state),
            hasPartnerSso: hasPartnerSso(state),
            isLogining: isLogining(state),
            partnerId: getPartnerId(state),
            userId: coreSelectors.getCurrentUserId(state),
            ...(routeParams || {}),
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(CorporateAccountLinkFlowBase);
}
