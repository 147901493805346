import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { appFonts, baseColors, spacing } from '../../../../styles';
import { getUserName, translate } from '../../../core';

export default function WithOrderAddressItemBase(WrappedComponent) {
    class OrderAddressItemBase extends PureComponent {
        static propTypes = {
            address: PropTypes.string
        };

        static defaultProps = {
            address: undefined
        };

        get userName() {
            return getUserName(this.props.address);
        }

        render() {
            return (
                <WrappedComponent {...this.props} userName={this.userName} />
            );
        }
    }

    return translate()(OrderAddressItemBase);
}

const SHADOW_RADIUS = spacing.s1;
const SHADOW_OPACITY = 0.15;

export const styles = {
    fadedText: {
        color: baseColors.grey40,
        ...appFonts.xsRegular
    },
    shipContainer: {
        backgroundColor: baseColors.secondary,
        borderRadius: SHADOW_RADIUS,
        shadowRadius: SHADOW_RADIUS,
        shadowOpacity: SHADOW_OPACITY,
        shadowOffset: { width: 0, height: SHADOW_RADIUS },
        shadowColor: baseColors.black,
        padding: spacing.s3,
        marginTop: spacing.s3,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    shipLargeText: {
        color: baseColors.white,
        ...appFonts.lgBold
    },
    shipText: {
        color: baseColors.white,
        ...appFonts.mdRegular
    },
    editButton: {
        padding: spacing.s1
    }
};

