import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

import { components as Core, ROUTES, tracker, onKeyPress } from '../../../core';
import WithResourceLinksOnFeedsBase, { styles as baseStyles } from './ResourceLinksOnFeedsBase';
import ResourceLinkFeedItem from '../ResourceLinkFeedItem';
import { layoutStyle, spacing, baseColors, appFonts } from '../../../../styles';

class ResourceLinksOnFeeds extends PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
        items: PropTypes.array.isRequired,
        i18n: PropTypes.object.isRequired,
        title: PropTypes.string.isRequired,
        hasHorizontalMargin: PropTypes.bool,
        isLoading: PropTypes.bool.isRequired
    };

    static defaultProps = {
        hasHorizontalMargin: false,
    };

    goToAll = () => {
        tracker.logEvent('resources', { event: 'goToAll', from: 'feeds' });
        this.props.history.push(ROUTES.resourcesList());
    };

    renderItem = item => {
        if (item !== undefined) {
            return <ResourceLinkFeedItem item={item} key={item.id} />;
        }

        return null;
    };

    render() {
        if (!this.props.items || !this.props.items.length || this.props.isLoading) return null;
        return (
            <Core.Card className={css(styles.container, !this.props.hasHorizontalMargin ? styles.card : null)}>
                <p className={css(styles.title)}>{this.props.title}</p>
                {_.map(this.props.items, this.renderItem)}
                <div className={css(styles.listSeparator)} />
                <div className={css(styles.buttonContainer)}>
                    <div
                        tabIndex="0"
                        role="button"
                        onClick={this.goToAll}
                        onKeyDown={onKeyPress.enter(this.goToAll)}
                        className={css(styles.buttonText, layoutStyle.cp)}>
                        {this.props.i18n.t('viewAllButton')}
                    </div>
                </div>
            </Core.Card>
        );
    }
}

export default withRouter(WithResourceLinksOnFeedsBase(ResourceLinksOnFeeds));

const PADDING_HORIZONTAL = spacing.s3;

const styles = StyleSheet.create({
    ...baseStyles,
    container: {
        marginTop: spacing.s1,
        paddingTop: spacing.s3,
        paddingLeft: PADDING_HORIZONTAL,
        paddingRight: PADDING_HORIZONTAL,
        backgroundColor: baseColors.white,
        '-ms-grid-column': 1,
        marginBottom: spacing.s7,
    },
    card: {
        marginLeft: spacing.s0,
        marginRight: spacing.s1,
    },
    buttonContainer: {
        ...baseStyles.buttonContainer,
        display: 'flex',
        justifyContent: 'center',
        marginTop: spacing.s3,
        marginBottom: spacing.s3,
        paddingTop: 0,
    },
    listSeparator: {
        ...baseStyles.listSeparator,
        marginLeft: -PADDING_HORIZONTAL,
        marginRight: -PADDING_HORIZONTAL,
    },
    title: {
        ...baseStyles.title,
        ...appFonts.lgBold
    }
});