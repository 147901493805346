import axios from 'axios';
import { getBaseUrl } from '../core';

const EAP_NAME = 'EAP';

export function getEAP() {
    return axios.get(getBaseUrl(`${EAP_NAME}/contact_info`));
}

export function saveEAP(params) {
    return axios.post(getBaseUrl(`${EAP_NAME}/email`), params);
}

export function getInterestGroups(userId) {
    return axios.get(getBaseUrl(`users/${userId}/interest_groups`));
}

export function saveInterestGroups(userId, interests) {
    return axios.put(getBaseUrl(`users/${userId}/interest_groups`), interests);
}

export function getHealthObjectives(userId) {
    return axios.get(getBaseUrl(`users/${userId}/health_objectives`));
}

export function saveHealthObjectives(userId, objectives) {
    return axios.put(getBaseUrl(`users/${userId}/health_objectives`), objectives);
}

export function getHealthTopics(userId) {
    return axios.get(getBaseUrl(`users/${userId}/health_topics`));
}

export function saveHealthTopics(userId, options) {
    return axios.put(getBaseUrl(`users/${userId}/health_topics`), options);
}

export function getUserTasks() {
    const params = { empty_groups: 1 };
    return axios.get(getBaseUrl('tasks/user_tasks'), { params });
}

export function setHealthSurveyAnswers(params) {
    return axios.post(
        getBaseUrl('health_survey/hra_answers_refactor'),
        params
    );
}

export function setUserTasks(params) {
    return axios.post(getBaseUrl('tasks/user_tasks'), params);
}

export function setHRA(params) {
    return axios.post(getBaseUrl('health_survey/hra'), params);
}

export function getHealthSurveyQuestions() {
    return axios.get(getBaseUrl('health_survey/hra_questions_refactor'));
}

export function getHealthSurveyResults(id) {
    return axios.get(getBaseUrl(`health_survey/hra_results/${id}`));
}

export function getHealthSurveyResultStatus(userId) {
    return axios.get(getBaseUrl(`health_survey/status_enabled/${userId}`));
}

export function setVitalStats(answers) {
    return axios.post(getBaseUrl('users/settings/personal'), answers);
}

export function setUserTaskComplete(slug) {
    return axios.post(getBaseUrl('tasks/user_tasks'), { slug });
}
