import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { components as Core, Modal, Storage } from '../../../core';
import LeaderboardChallengesEmpty from '../LeaderboardChallengesEmpty';
import WithLeaderboardsFiltersBase, { TYPES_ENTITY } from './LeaderboardsFiltersBase';
import { SELECTED_FILTER } from '../../constants';

class LeaderboardsFilters extends PureComponent {
    static propTypes = {
        challenges: PropTypes.array,
        isLeaderBoard: PropTypes.bool,
        activityFilterPropsToPass: PropTypes.object.isRequired,
        activityFilterScreenParams: PropTypes.object.isRequired,
        onChangeActivityFilter: PropTypes.func,
        challengeFilterPropsToPass: PropTypes.object.isRequired,
        challengeFilterScreenParams: PropTypes.object.isRequired,
        onChangeChallengeFilter: PropTypes.func,
        onChangeTimeFilter: PropTypes.func,
        timeFilter: PropTypes.object.isRequired,
        typeFilter: PropTypes.object.isRequired,
        isLoading: PropTypes.bool,
        selectedChallengeFilter: PropTypes.object,
    };

    static defaultProps = {
        challenges: [],
        isLeaderBoard: false,
        onChangeActivityFilter: null,
        onChangeChallengeFilter: null,
        selectedChallengeFilter: null,
        onChangeTimeFilter: null,
        isLoading: false
    };

    async componentWillUnmount() {
        await Storage.setItem(SELECTED_FILTER, this.props.selectedChallengeFilter);
    }

    showSelectModal = (screenParams, passProps) => Modal.open(
        Core.SelectModal,
        { ...screenParams, ...passProps },
        { isContainer: true }
    );

    onSelectActivityOrChallenge = item =>
        item.id === TYPES_ENTITY.ACTIVITY ? this.onPressActivityFilter() : this.onPressChallengeFilter();

    onPressActivityFilter = () => {
        const activityFilterPropsToPass = { ...this.props.activityFilterPropsToPass, onPressCallback: this.onChangeActivityFilter };
        this.closeActivityModal = this.showSelectModal(this.props.activityFilterScreenParams, activityFilterPropsToPass);
    };

    onPressChallengeFilter = () => {
        const challengeFilterPropsToPass = {
            ...this.props.challengeFilterPropsToPass,
            onPressCallback: this.onChangeChallengeFilter
        };
        if (this.props.challenges.length > 0) {
            this.closeChallengeModal = this.showSelectModal(this.props.challengeFilterScreenParams, challengeFilterPropsToPass);
        } else {
            Modal.open(
                LeaderboardChallengesEmpty,
                { ...this.props.challengeFilterScreenParams },
                { isContainer: true }
            );
        }
    };

    onChangeActivityFilter = filter => {
        this.props.onChangeActivityFilter(filter);
        this.closeActivityModal && this.closeActivityModal();
    };

    onChangeChallengeFilter = filter => {
        this.props.onChangeChallengeFilter(filter);
        this.closeChallengeModal && this.closeChallengeModal();
    };

    onChangeTimeFilter = filter => this.props.onChangeTimeFilter(filter);

    render() {
        return (
            <React.Fragment>
                {this.props.isLeaderBoard ? (
                    <Core.SelectField
                        {...this.props.typeFilter}
                        onChange={this.onSelectActivityOrChallenge}
                        title={this.props.typeFilter.modalTitle}
                        onSelect={!this.props.isLoading ? this.props.typeFilter.onSelect : null}
                        isFilter={true}
                        isRightMargin={true}
                    />
                ) : null}
                { !this.props.selectedChallengeFilter && this.props.timeFilter ? (
                    <Core.SelectField
                        {...this.props.timeFilter}
                        onChange={this.onChangeTimeFilter}
                        title={this.props.timeFilter.modalTitle}
                        onSelect={!this.props.isLoading ? this.props.timeFilter.onSelect : null}
                        isFilter={true}
                    />
                ) : null}
            </React.Fragment>
        );
    }
}

export default WithLeaderboardsFiltersBase(LeaderboardsFilters);
