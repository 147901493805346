import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as selectors from '../../../selectors';
import { MAX_COUNT, Platform, PLATFORMS, selectors as coreSelectors, translate } from '../../../../core';
import * as actions from '../../../actions';
import { appFonts, spacing } from '../../../../../styles';
import {
    isJoiningChallenge,
    isJoiningChallengeByTeamId,
    isLoadingChallengesList,
    isLoadingInvitationDecline
} from '../../../selectors';
import { constants as challengesConstants } from '../../../index';
import { DISPLAY_TYPES } from '../../../constants';

export default function WithChallengesBase(WrappedComponent) {
    class ChallengeInvitationsBase extends Component {

        getTeamOptions = (create, join) => [
            { title: this.props.i18n.t('create_team'), onPress: create },
            { title: this.props.i18n.t('join_team'), onPress: join }
        ];

        isTeamChallenge = item => {
            const { challengeEntityType, challengeSubentityType } = item;
            const { userTeam, companyTeam } = challengesConstants.CHALLENGE_SUBENTITY_TYPES;

            return challengeEntityType === challengesConstants.CHALLENGE_ENTITY_TYPES.group
                && (challengeSubentityType === userTeam || challengeSubentityType === companyTeam);
        }

        joinedTeamCallback = () => {
            this.props.actions.getChallengesList({ filter: DISPLAY_TYPES.INVITATIONS, maxCount: MAX_COUNT, start: 0, isEnded: 0 }, true);
        }

        handleDecline = invitationId => {
            this.props.actions.declineInvitation(invitationId);
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    getTeamOptions={this.getTeamOptions}
                    isTeamChallenge={this.isTeamChallenge}
                    joinedTeamCallback={this.joinedTeamCallback}
                    invitationsArray={this.invitationsArray}
                    handleDecline={this.handleDecline}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const isWeb = Platform.OS === PLATFORMS.web;

        const challengeIdWeb = _.get(ownProps, 'invitationItem.challenge.challengeId');
        const entityIdWeb = _.get(ownProps, 'invitationItem.invitation.entityId');
        const invitationIdWeb = _.get(ownProps, 'invitationItem.invitation.invitationId');

        const challengeIdMobile = _.get(ownProps, 'invitationItem.item.challenge.challengeId');
        const entityIdMobile = _.get(ownProps, 'invitationItem.item.invitation.entityId');
        const invitationIdMobile = _.get(ownProps, 'invitationItem.item.invitation.invitationId');

        const challengeId = isWeb ? challengeIdWeb : challengeIdMobile;
        const entityId = isWeb ? entityIdWeb : entityIdMobile;
        const invitationId = isWeb ? invitationIdWeb : invitationIdMobile;

        return {
            userId: coreSelectors.getCurrentUserId(state),
            invitedChallenges: selectors.getInvitedChallengesCarousel(state),
            isLoadingChallenges: isLoadingChallengesList(state),
            isJoiningChallenge: isJoiningChallenge(state, challengeId),
            isJoiningById: isJoiningChallengeByTeamId(state, entityId),
            isLoadingInvitationDecline: isLoadingInvitationDecline(state, invitationId)
        };
    };

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(ChallengeInvitationsBase));

}


export const styles = {
    titleText: {
        ...appFonts.lgBold,
        paddingTop: spacing.s0
    },
};
