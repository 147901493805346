import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { components as Core, Modal, AsyncComponent } from '../../../../core';
import { spacing } from '../../../../../styles';
import WithFeedItemOptionsBase from './FeedItemOptionsBase';

class FeedItemOptions extends PureComponent {
    static propTypes = {
        streamItemId: PropTypes.number.isRequired,
        isOptionsAvailable: PropTypes.bool.isRequired,
        openOptions: PropTypes.func.isRequired,
    };

    openOptions = event => this.props.openOptions(this.editPost, undefined, undefined, event);

    editPost = () => {
        Modal.open(
            AsyncComponent(() => import('../../FeedPostEditor')),
            { streamItemId: this.props.streamItemId },
            { isContainer: true, isFullHeight: false, fadeTransition: true }
        );
    };

    render() {
        return this.props.isOptionsAvailable ?
            <Core.IconButton
                onClick={this.openOptions}
                type="fa"
                name="ellipsis-h"
                size={spacing.s7}
                hasRoundHover={true}
            /> : null;
    }
}

export default withRouter(WithFeedItemOptionsBase(FeedItemOptions));

