import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { baseColors, containerStyle, layoutStyle, importantStyles } from '../../../../styles';
import { components as Core, color } from '../../../core';
import WithSignUpSelectionBase, { styles as baseStyles } from './SignUpSelectionBase';

class SignUpSelection extends PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        isGoogleEnabled: PropTypes.bool.isRequired,
        isFacebookEnabled: PropTypes.bool.isRequired,
        signUpEmail: PropTypes.func.isRequired,
        signUpGoogle: PropTypes.func.isRequired,
        signUpFacebook: PropTypes.func.isRequired,
        clear: PropTypes.func.isRequired,
        isLoading: PropTypes.bool,
        isSamlEnabled: PropTypes.bool.isRequired,
        isOnlySamlEnabled: PropTypes.bool,
        signUpSaml: PropTypes.func.isRequired,
    };

    static defaultProps = {
        isLoading: false,
        isOnlySamlEnabled: false
    };

    constructor(props) {
        super(props);
        this.props.clear();
    }

    componentDidMount() {
        if (this.props.isOnlySamlEnabled) {
            this.props.signUpSaml();
        }
    }

    render() {
        const { i18n } = this.props;
        return (
            <div className={css(layoutStyle.fw)}>
                <div className={css(containerStyle.container, layoutStyle.textCenter)}>
                    <div className={css(containerStyle.main)}>
                        {this.props.isFacebookEnabled ?
                            <Core.Button
                                fullWidth={true}
                                lowercase={true}
                                className={css(styles.button, styles.buttonFacebook, styles.buttonTextWhite)}
                                onPress={this.props.signUpFacebook}>
                                {i18n.t('signup_with_facebook')}
                            </Core.Button> : null}
                        {this.props.isGoogleEnabled ?
                            <Core.Button
                                fullWidth={true}
                                lowercase={true}
                                className={css(styles.button, styles.buttonGoogle, styles.buttonTextWhite)}
                                onPress={this.props.signUpGoogle}>
                                {i18n.t('signup_with_google')}
                            </Core.Button> : null
                        }
                        <Core.Button
                            fullWidth={true}
                            lowercase={true}
                            className={css(styles.button, styles.buttonText)}
                            type="outlined"
                            onPress={this.props.signUpEmail}>
                            {i18n.t('signup_with_email')}
                        </Core.Button>
                        {this.props.isSamlEnabled ?
                            <Core.Button
                                fullWidth={true}
                                lowercase={true}
                                className={css(styles.button, styles.buttonText)}
                                type="outlined"
                                onPress={this.props.signUpSaml}>
                                {i18n.t('authentication.saml.signup')}
                            </Core.Button> : null}
                    </div>
                </div>
                <Core.BlockingLoading isLoading={this.props.isLoading} />
            </div>
        );
    }
}

export default WithSignUpSelectionBase(SignUpSelection);

const styles = StyleSheet.create({
    ...baseStyles,
    ...importantStyles({
        button: {
            ...baseStyles.button
        },
        buttonFacebook: {
            ...baseStyles.buttonFacebook,
            '&:hover': {
                backgroundColor: color.lightenDarkenColor(baseColors.facebook, -20),
            }
        },
        buttonGoogle: {
            ...baseStyles.buttonGoogle,
            '&:hover': {
                backgroundColor: color.lightenDarkenColor(baseColors.google, -20),
            }
        },
        buttonTextWhite: {
            ...baseStyles.buttonTextWhite
        },
        buttonTextSecondary: {
            ...baseStyles.buttonTextSecondary
        }
    })
});
