import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { withRouter } from 'react-router-dom';
import { importantClass, layoutStyle } from '../../../../../styles';
import { components as Core, ROUTES } from '../../../../core';
import ChallengeCardHeaderIcon from '../../ChallengeCard/ChallengeCardHeader/ChallengeCardHeaderIcon';
import * as Card from '../../Card';
import WithGoalInfoBase, { styles as baseStyles, INSTRUCTION_LINES } from './GoalInfoBase';

class GoalInfo extends PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        challengeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        challenge: PropTypes.object.isRequired,
        headerIcon: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        close: PropTypes.func.isRequired,
        subtitle: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
        detailsDeleteRedirect: PropTypes.string,
        isTaskCard: PropTypes.bool,
        isOnboarding: PropTypes.bool,
    };

    static defaultProps = {
        detailsDeleteRedirect: undefined,
        isTaskCard: false,
        isOnboarding: false
    };

    openDetails = () => {
        const { isOnboarding, close, detailsDeleteRedirect, history, challengeId, isTaskCard } = this.props;
        close();
        history.push(isOnboarding ? ROUTES.onboardingChallengeDetails(challengeId) : ROUTES.challengeDetails(challengeId), { deleteRedirect: detailsDeleteRedirect, isTaskCard });
    };

    render() {
        return (
            <Core.Layout.PageWrapper isModal={true}>
                <Core.Image className={css(styles.image)} src={this.props.image} />
                <Card.Header
                    title={this.props.challenge.challengeName}
                    subtitle={this.props.subtitle}
                    challengeId={this.props.challengeId}
                    icon={<ChallengeCardHeaderIcon icon={this.props.headerIcon} />}
                />
                <Card.Body isGrey={false}>
                    <Core.TextSeeMore
                        className={css(styles.cardDarkBodyTop, styles.cardDarkHeadingSub)}
                        numberOfLines={INSTRUCTION_LINES}>
                        {this.props.challenge.challengeInstruction}
                    </Core.TextSeeMore>
                </Card.Body>
                <Card.Footer isGrey={false}>
                    <Core.Button
                        id="goalInfoDismissBtn"
                        size="medium"
                        className={css(layoutStyle.cardFooterButton)}
                        type="outlined"
                        onPress={this.props.close}>
                        {this.props.i18n.t('button_dismiss')}
                    </Core.Button>
                    <Core.Button
                        id="goalInfoProgressBtn"
                        size="medium"
                        className={css(layoutStyle.cardFooterButton)}
                        onPress={this.openDetails}>
                        {this.props.i18n.t('progress')}
                    </Core.Button>
                </Card.Footer>
            </Core.Layout.PageWrapper>
        );
    }
}

export default withRouter(WithGoalInfoBase(GoalInfo));

const styles = StyleSheet.create({
    ...baseStyles,
    image: importantClass({ ...baseStyles.image, borderRadius: 0 })
});
