import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';

import { translate, SEARCH_INPUT_DELAY } from '../../../core';
import * as contentActions from '../../actions';
import * as selectors from '../../selectors';
import { TYPES, CATEGORIES } from '../../constants';
import { getTypeParams } from '../../services';

export default function WithContentSearchBase(WrappedComponent) {
    class ContentSearchBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            callback: PropTypes.func.isRequired,
            onRef: PropTypes.func,
            category: PropTypes.string,
            type: PropTypes.string,
            i18n: PropTypes.object.isRequired,
        };

        static defaultProps = {
            onRef: null,
            category: undefined,
            type: undefined,
        };

        constructor(props) {
            super(props);
            this._doSearch = _.debounce(this._doSearch.bind(this), SEARCH_INPUT_DELAY);
            this.state = { search: '' };
        }

        componentDidMount() {
            if (this.props.onRef) this.props.onRef(this);
        }

        componentWillUnmount() {
            if (this.props.onRef) this.props.onRef(null);
            this.clearSearch();
        }

        _getContent(category, params, isReplace = true, type, isSearching = false) {
            this.props.actions.getContent(category, params, isReplace, type, null, isSearching);
        }

        onSearch = text => {
            this.setState(() => ({ search: text }));
            this._doSearch(text);
            this.props.callback(text);
        };

        _doSearch = text => {
            const { type, category } = this.props;
            this._getContent(CATEGORIES.SEARCH, getTypeParams(type, category, text), true, type, true);
        };

        clearSearch = () => {
            this.onSearch('');
            this.props.actions.clearContent(CATEGORIES.SEARCH);
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    onSearch={this.onSearch}
                    search={this.state.search}
                    onClearSearch={this.clearSearch}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        const type = ownProps.type || TYPES.ALL;
        return {
            categories: selectors.getCategories(state),
            isSearching: selectors.isSearchingContent(state),
            type,
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(contentActions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(ContentSearchBase));
}