import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import { withRouter } from 'react-router-dom';

import { components as Core, ROUTES } from '../../../core';
import { SCROLL_THROTTLE } from '../../constants';
import ProductItem from '../ProductItem';
import WithProductsListBase, { styles as baseStyles } from './ProductsListBase';
import { spacing, layoutStyle } from '../../../../styles';

class ProductsList extends PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
        title: PropTypes.string,
        hasItemSeparator: PropTypes.func.isRequired,
        products: PropTypes.array,
        isInline: PropTypes.bool,
        loadMoreProducts: PropTypes.func.isRequired,
        externalRewardName: PropTypes.string.isRequired,
        isLoading: PropTypes.bool.isRequired,
        i18n: PropTypes.object.isRequired,
        rewards: PropTypes.object,
        twoColumns: PropTypes.bool,
    };

    static defaultProps = {
        title: undefined,
        products: [],
        isInline: false,
        rewards: {},
        twoColumns: undefined
    };

    get renderHeaderComponent() {
        return (
            <React.Fragment>
                <Core.EntityDetailsHeader
                    hasBackButton={true}
                    title={this.props.title}
                />
                <Core.SubHeader
                    title={this.props.title}
                    noTopPadding={true}
                />
            </React.Fragment>
        );
    }

    goProductDetails = item => this.props.history.push(ROUTES.productDetails(item.id));

    renderProductItem = data => {
        const { externalRewardName, hasItemSeparator } = this.props;
        return (
            <ProductItem
                key={data.item.id}
                product={data.item}
                externalRewardName={externalRewardName}
                onPressSingle={this.goProductDetails}
                separator={hasItemSeparator(data.index)}
                mainContainerClassName={css(styles.itemContainer)}
            />
        );
    };

    keyExtractor = data => data.id.toString();

    render() {
        const { products, loadMoreProducts, isInline, isLoading, rewards: { isEligible }, twoColumns, i18n } = this.props;
        return (
            <div className={css(layoutStyle.flex1)}>
                <Core.InfiniteLazyListFadeIn
                    ListHeaderComponent={!isInline && this.renderHeaderComponent}
                    scrollEventThrottle={SCROLL_THROTTLE}
                    data={products}
                    keyExtractor={this.keyExtractor}
                    renderItem={this.renderProductItem}
                    onEndReached={loadMoreProducts}
                    onEndReachedThreshold={0.5}
                    isHorizontal={isInline}
                    isLoading={isLoading && isEligible}
                    twoColumns={twoColumns}
                />
                {!isLoading && !products.length ?
                    <Core.EmptyListSimple message={i18n.t('rewardsNoAvailableProducts')} /> : null
                }
            </div>
        );
    }
}

export default withRouter(WithProductsListBase(ProductsList));

const styles = StyleSheet.create({
    ...baseStyles,
    itemContainer: {
        marginLeft: spacing.s1,
        marginRight: spacing.s1,
    }
});
