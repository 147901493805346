import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

import { CAROUSEL_TYPES, components as Core, ENTITIES_ACTIVE_TAB, ROUTES, Storage, tracker } from '../../../core';
import WithContentOnFeedsPromotedBase, { styles as baseStyles } from './ContentOnFeedsPromotedBase';
import ContentOnFeedRecommendedItem from '../ContentOnFeedRecommendedItem';
import { appFonts, baseColors, spacing, importantClass } from '../../../../styles';
import ViewAllButton from '../../../core/components/ViewAllButton';
import ResourceLinkFeedItem from '../../../resources/components/ResourceLinkFeedItem';

class ContentOnFeedsPromoted extends PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
        items: PropTypes.array.isRequired,
        i18n: PropTypes.object.isRequired,
        title: PropTypes.string.isRequired,
        tags: PropTypes.array.isRequired,
        showTagFilterButtons: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            activeDot: 0
        };
    }

    goToAll = () => {
        tracker.logEvent('library', { event: 'goToAll', from: 'feeds' });
        this.props.history.push(ROUTES.content());
    };

    goToContentItem = id => {
        tracker.logEvent('library', { event: 'goToContentItem', from: 'feeds', id });
    };

    renderCarouselItem = (item, index) => (
        <div>
            {_.map(item, content => (
                <ContentOnFeedRecommendedItem item={content} key={content.id} tabIndex={index === this.state.activeDot ? '0': '-1'} />
            ))}
        </div>
    );

    goToContent = () => {
        this.props.history.push(ROUTES.content());
    };

    goToFilteredContent = tag => {
        Storage.setItem(ENTITIES_ACTIVE_TAB.CONTENT, tag);
        this.props.history.push(ROUTES.content(), { selectedCategory: tag, activeTab: tag, scrollToContent: true });
    }

    render() {
        const { items, title, i18n, tags, showTagFilterButtons } = this.props;
        return (
            <Core.Card className={css(styles.container)}>
                <div className={css(styles.headerContainer)}>
                    <p className={css(styles.title)}>{title}</p>
                    <ViewAllButton
                        text={i18n.t('viewAllButton')}
                        onPress={this.goToContent}
                        textClassName={css(styles.viewAllButton)}
                    />
                </div>
                <div>
                    <Core.Carousel
                        type={CAROUSEL_TYPES.single}
                        isFeaturedSection={true}
                        itemsLength={items.length}
                        afterChange={value => this.setState({ activeDot: value })}>
                        {_.map(items, this.renderCarouselItem)}
                    </Core.Carousel>
                </div>
                {showTagFilterButtons && (
                    <div className={css(styles.tagsWrapper)}>
                        <p className={css(styles.tagsTitle)}>{i18n.t('browseByTopic')}</p>
                        <div className={css(styles.tagsButtonsContainer)}>
                            {_.map(tags, item => (
                                <ResourceLinkFeedItem
                                    item={item}
                                    key={item.id}
                                    onClick={this.goToFilteredContent}
                                    textClassName={styles.textClassName}
                                />
                            ))}
                        </div>
                    </div>
                )}
            </Core.Card>
        );
    }
}

export default withRouter(WithContentOnFeedsPromotedBase(ContentOnFeedsPromoted));

const TAGS_CONTAINER_HEIGHT = 156;

const styles = StyleSheet.create({
    ...baseStyles,
    container: {
        paddingTop: spacing.s3,
        marginLeft: spacing.s0,
        marginRight: spacing.s1,
        marginBottom: spacing.s3
    },
    title: {
        ...appFonts.lgBold,
        color: baseColors.black,
        marginLeft: spacing.s3,
        maxWidth: '60%'
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: spacing.s3
    },
    tagsWrapper: {
        height: TAGS_CONTAINER_HEIGHT,
        marginRight: spacing.s3,
        marginLeft: spacing.s3,
        paddingTop: spacing.s5,
        paddingBottom: spacing.s5,
        borderTop: `1px solid ${baseColors.grey80}`
    },
    tagsTitle: {
        ...appFonts.smMedium
    },
    tagsButtonsContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        height: '100%',
        paddingLeft: spacing.s3
    },
    textClassName: {
        ...appFonts.smRegular
    },
    viewAllButton: importantClass({
        ...appFonts.mdMedium
    })
});
