import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classnames from 'classnames';
import { StyleSheet, css } from 'aphrodite-jss';
import { layoutStyle, spacing } from '../../../../styles';
import { components as Core, CSS_CLASSES, CAROUSEL_TYPES } from '../../../core';
import WithRecommendedGoalsCarouselBase from './RecommendedGoalsCarouselBase';
import GoalCarouselItem from '../GoalCarouselItem';

class RecommendedGoalsCarousel extends PureComponent {
    static propTypes = {
        slug: PropTypes.string.isRequired,
        carouselData: PropTypes.array.isRequired
    };

    renderCarouselItem = item => (
        <GoalCarouselItem
            key={item.activity}
            goal={item}
            slug={this.props.slug}
            className={css(layoutStyle.noCursor)}
        />
    );

    render() {
        const { carouselData } = this.props;
        return (
            carouselData.length > 0 ?
                <div className={classnames(css(styles.container), CSS_CLASSES.goals)}>
                    <Core.Carousel
                        type={CAROUSEL_TYPES.twoOnly}
                        arrows={true}
                        dots={true}
                        isBorderShown={false}
                        itemsLength={carouselData.length}>
                        {_.map(carouselData, this.renderCarouselItem)}
                    </Core.Carousel>
                </div> : null
        );
    }
}


export default WithRecommendedGoalsCarouselBase(RecommendedGoalsCarousel);

const styles = StyleSheet.create({
    container: {
        margin: spacing.s2
    }
});