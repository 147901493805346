import { css, StyleSheet } from 'aphrodite-jss';
import React, { PureComponent } from 'react';
import { appFonts, baseColors, importantClass, invitationItemWidth, invitationItemHeight, layoutStyle, spacing } from '../../../../../styles';
import {
    ActionSheet,
    AsyncComponent,
    components as Core,
    Modal,
    ROUTES
} from '../../../../core';
import ChallengeImage from '../../ChallengeImage/ChallengeImage';
import { daysUntilString } from '../../../services/helper';
import BonusChallengeMark from '../../BonusChallengeMark/BonusChallengeMark';
import WithChallengeInvitationItemBase, { styles as baseStyles } from './ChallengeInvitationItemBase';

class ChallengeInvitationItem extends PureComponent {

    constructor(props) {
        super(props);
        this.state = { currentChallengeID: null };
    }
    renderInvitesItem = item => {
        this.setState({ currentChallengeID: item.challenge.challengeId });
        const { userId, imageURL, firstName, lastName, entityId, entityType, entityName, invitationId } = item.invitation;
        const { challengeId, challengeImageURL, startDate, challengeName, challengeInstruction, challengeParticipants } = item.challenge;
        const { i18n, handleDecline, isJoiningChallenge, isJoiningById, isLoadingInvitationDecline, isTeamChallenge } = this.props;
        const invitedToTeam = entityType === 'group';
        return (
            <div className={css(styles.itemContainer)}>
                <Core.TouchableItem item={this.item}>
                    <div className={css(layoutStyle.flexRow, layoutStyle.flexAlignCenter, styles.containerOffset)}>
                        <Core.Avatar
                            id={userId}
                            url={imageURL}
                            name={`${firstName} ${lastName}`}
                            size="xlsmall"
                        />
                        <span className={css(styles.invitedText)}>
                            {invitedToTeam ? i18n.t('invitedToTeam', { firstName, lastName })
                                : i18n.t('invitedToChallenge', { firstName, lastName })}
                        </span>
                    </div>
                    <div className={css(layoutStyle.flexRow)}>
                        <Core.Button
                            fullWidth={true}
                            className={css(styles.button, styles.btnOffset)}
                            type="outlined"
                            isLoading={isLoadingInvitationDecline}
                            spinnerLabel={true}
                            onPress={() => handleDecline(invitationId)}>
                            {i18n.t('decline')}
                        </Core.Button>
                        <Core.Button
                            fullWidth={true}
                            className={css(styles.button)}
                            isLoading={isJoiningById || isJoiningChallenge}
                            spinnerLabel={true}
                            onPress={() => { invitedToTeam ? this.joinTeam(entityId, entityName) : this.handleAccept(item.challenge, entityId); }}>
                            {i18n.t('accept')}
                        </Core.Button>
                    </div>
                    <div className={css(styles.separator)} />
                    <div
                        className={css(layoutStyle.flexRow)}
                        onClick={() => this.onChallengePress(challengeId)} >
                        <div style={styles.imageContainer}>
                            <ChallengeImage
                                challengeId={challengeId}
                                image={challengeImageURL ? challengeImageURL : undefined}
                                isCarouselItem={false}
                                resizeMode="cover"
                                imageStyle={styles.entityCardImage}
                                imageHeight={invitationItemHeight}
                                imageWidth={invitationItemWidth}
                                hasRoundBorder={true}
                            />
                        </div>
                        <div className={css(styles.textContainer)}>
                            <Core.ExploreItemMeta iconName="calendar-alt" text={daysUntilString(startDate)} mainContainerClassName={styles.mainContainerClassName}/>
                            <Core.ExploreItemTitle text={challengeName} />
                            <Core.ExploreItemDescription text={challengeInstruction} />
                            <Core.ExploreItemMembers members={challengeParticipants} isChallenge={true} isTeamChallenge={isTeamChallenge} />
                            <BonusChallengeMark
                                challengeId={challengeId}
                                containerClassName={css(styles.bonusMarkContainer)}
                            />
                        </div>
                    </div>
                </Core.TouchableItem>
            </div>
        );
    }

    onChallengePress = challengeId => {
        this.props.history.push(ROUTES.challengeDetails(challengeId));
    };

    joinTeam = (entityId, entityName) => {
        this.props.actions.joinChallengeByTeamId(entityId, entityName);
    }

    handleAccept = (item, entityId) => {
        if (this.props.isTeamChallenge(item)) {
            ActionSheet.open(this._teamOptions, undefined, undefined);
        } else {
            this.props.actions.joinChallenge(entityId, this.props.userId, false, null, item.challengeName);
        }
    }

    get _teamOptions() {
        return this.props.getTeamOptions(this._createTeam, this._joinTeam);
    }

    _createTeam = () => {
        Modal.open(
            AsyncComponent(() => import('../../../../challenges/components/CreateEditTeam')),
            {
                challengeId: this.state.currentChallengeID,
                createdCallback: this.props.joinedTeamCallback,
                isModal: true
            },
            {
                cancelable: false,
                isNoPadding: true,
                isContainer: true,
                isFullHeight: true
            }
        );
    };

    _joinTeam = () => {
        this.closeJoinTeamModal = Modal.open(
            AsyncComponent(() => import('../../../../challenges/components/JoinTeamsList')),
            {
                isPage: false,
                isModal: true,
                close: () => this.closeJoinTeamModal(),
                challengeId: this.state.currentChallengeID,
                joinedTeamCallback: this.props.joinedTeamCallback
            },
            { isContainer: true }
        );
    };


    render() {
        const { invitationItem } = this.props;
        return (
            <div>
                {this.renderInvitesItem(invitationItem)}
            </div>
        );
    }
}


const styles = StyleSheet.create({
    ...baseStyles,
    itemContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: spacing.s3,
        paddingBottom: 0
    },
    imageContainer: {
        borderRadius: spacing.s1,
        overflow: 'hidden'
    },
    button: importantClass({
        height: spacing.s7
    }),
    bonusMarkContainer: {
        marginTop: spacing.s0 + 2,
    },
    separator: {
        height: 0.5,
        backgroundColor: baseColors.grey70,
        marginTop: spacing.s3,
        marginBottom: spacing.s3
    },
    invitedText: {
        paddingLeft: spacing.s1,
        ...appFonts.smRegular,
        color: baseColors.grey20
    },
    btnOffset: importantClass({
        marginRight: spacing.s1
    }),
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginLeft: spacing.s5
    },
    containerOffset: {
        marginBottom: spacing.s2
    },
    mainContainerClassName: importantClass({
        marginTop: 0
    })
});

export default WithChallengeInvitationItemBase(ChallengeInvitationItem);
