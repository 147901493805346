import React, { PureComponent } from 'react';
import { translate } from '../../../services';
import { baseColors, appFonts, spacing } from '../../../../../styles';

export default function WithAttendeeItemBase(WrappedComponent) {
    class AttendeeItemBase extends PureComponent {
        render() {
            return (
                <WrappedComponent {...this.props} />
            );
        }
    }

    return translate()(AttendeeItemBase);
}

export const styles = {
    itemContainer: {
        alignItems: 'center',
        flexDirection: 'row',
        borderBottomColor: baseColors.grey80,
        borderBottomWidth: 1,
        padding: spacing.s3,
        flex: 1,
    },
    itemDetails: {
        marginLeft: spacing.s3,
        flex: 1,
    },
    title: {
        ...appFonts.mdMedium,
        color: baseColors.black
    },
    description: {
        ...appFonts.smRegular,
        color: baseColors.grey40,
        paddingRight: spacing.s3,
        paddingTop: spacing.s0
    },
    invitedText: {
        color: baseColors.secondary
    },
    iconAvatarContainer: {
        justifyContent: 'center',
        backgroundColor: baseColors.grey80,
        borderRadius: spacing.s7
    },
    icon: {
        justifyContent: 'center',
        padding: spacing.s2
    },
    invitedByRow: {
        flexDirection: 'row',
        paddingTop: spacing.s0
    },
    smallAvatar: {
        width: spacing.s3,
        height: spacing.s3,
    },
    invitedByString: {
        ...appFonts.smRegular,
        color: baseColors.grey40,
        paddingLeft: spacing.s0
    }
};
