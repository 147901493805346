import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { css, StyleSheet } from 'aphrodite-jss';
import { components as Core, ROUTES } from '../../../../core';
import { layoutStyle, baseColors, spacing } from '../../../../../styles';
import { constants as invitationConstants } from '../../../../invitations';
import WithChallengeInfoBase, { styles as baseStyles } from './ChallengeInfoBase';
import NotificationSection from '../NotificationSection';

class ChallengeInfo extends PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
        challengeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        isEnded: PropTypes.bool.isRequired,
        toggleActivities: PropTypes.func.isRequired,
        getEarnPoints: PropTypes.func.isRequired,
        moreButtonText: PropTypes.string.isRequired,
        activities: PropTypes.array.isRequired,
        isToggleShown: PropTypes.bool.isRequired,
        activityListTitle: PropTypes.string.isRequired,
        activityListSubtitle: PropTypes.string.isRequired,
        disclaimerSectionTitle: PropTypes.string.isRequired,
        disclaimerSectionMessage: PropTypes.string.isRequired,
        chevronDirectText: PropTypes.string.isRequired,
    };

    get activityList() {
        if (this.props.activities.length === 0) return null;

        const { activities, activityListTitle, activityListSubtitle, chevronDirectText,
            getEarnPoints, isToggleShown, moreButtonText } = this.props;

        return (
            <Fragment>
                <div className={css(styles.cardBodyDays)}>
                    <div className={css(styles.flexRow)}>
                        <p className={css(styles.cardBodyTitleText)}>{activityListTitle}</p>
                    </div>
                </div>
                <p className={css(styles.activityListTitleText)}>{activityListSubtitle}</p>
                <div className={css(styles.activityList)}>
                    {_.map(activities, item => (
                        <Core.ListItem
                            key={item.unitId}
                            className={css(layoutStyle.listItem, styles.activityItem)}
                            button={false}>
                            <Core.ListItemText
                                primary={(
                                    <Core.TextSeeMore
                                        numberOfLines={1}
                                        className={css(styles.activityListItemText)}
                                        isCollapseHidden={true}
                                        isExpandHidden={true}>
                                        {`${item.activityName} (${item.unitName})`}
                                    </Core.TextSeeMore>
                                )}
                                secondary={<span className={css(styles.flexRowTitleText)}>{getEarnPoints(item)}</span>}
                            />
                        </Core.ListItem>
                    ))}
                </div>
                {isToggleShown ? (
                    <div className={css(layoutStyle.flexRowCenter, styles.moreActivitiesContainer)} onClick={this.props.toggleActivities}>
                        <div className={css(styles.moreActivitiesContainerText)}>
                            {moreButtonText}
                        </div>
                        <Core.Icon size={spacing.s2} type="fa" color={baseColors.secondary} name={chevronDirectText} />
                    </div>
                ) : null}
            </Fragment>
        );
    }

    inviteOthers = () => {
        this.props.history.push(ROUTES.inviteToChallenge(this.props.challengeId, invitationConstants.ENTITIES.CHALLENGE));
    };

    render() {
        const { disclaimerSectionTitle, disclaimerSectionMessage } = this.props;
        return (
            <Fragment>
                <div>
                    {this.activityList}
                </div>
                {!this.props.isEnded ? (
                    <div className={css(styles.disclaimerSection)}>
                        <NotificationSection
                            title={disclaimerSectionTitle}
                            text={disclaimerSectionMessage}
                            bg={baseColors.contextLightest}
                            detailColor={baseColors.contextDarkest}
                            customNotificationClassName={styles.disclaimerPadding}
                        />
                    </div>
                ) : null}
            </Fragment>
        );
    }
}

export default withRouter(WithChallengeInfoBase(ChallengeInfo));

const styles = StyleSheet.create({
    ...baseStyles,
    cardBodyDays: {
        ...baseStyles.cardBodyDays,
        marginTop: spacing.s7
    },
    activityList: {
        marginTop: spacing.s1 + 2,
        marginBottom: spacing.s5,
    },
    activityItem: {
        paddingLeft: spacing.s0
    },
    moreActivitiesContainer: {
        ...baseStyles.moreActivitiesContainer,
        cursor: 'pointer'
    },
    cardBodyTitleText: {
        ...baseStyles.cardBodyTitleText,
        marginTop: spacing.s5,
    },
    disclaimerPadding: {
        marginLeft: 0,
        marginRight: 0
    }
});
