import { createSelector } from 'reselect';
import _ from 'lodash';
import i18n from '../../i18n';
import { FLOW_STEPS as STEPS, NAME, SAMPLE_TASK, FLOW_STEPS, APPS_AND_DEVICES, PERSONALIZE, HEALTH_SURVEY, LBP_ITEMS } from './constants';
import { selectors as coreSelectors, parsers, PLATFORMS, Platform } from '../core';
import * as types from './actionTypes';
import { selectors as rewardsSelectors, constants as rewardsConstants } from '../rewards';

export const getInterests = state => state[NAME].interests;
export const getHealthObjectives = state => state[NAME].healthObjectives;
export const getHealthTopics = state => state[NAME].healthTopics;
export const isSavingInterests = state => coreSelectors.isLoading(state, types.SAVE_INTEREST_GROUPS.NAME);
export const isSavingObjectives = state => coreSelectors.isLoading(state, types.SAVE_HEALTH_OBJECTIVES.NAME);
export const isSavingTopics = state => coreSelectors.isLoading(state, types.SAVE_HEALTH_TOPICS.NAME);
export const getScreens = state => state[NAME].screens;

const getExternalRewardsFields = achievement => ({
    externalRewardName: _.get(achievement, 'externalRewardName'),
    externalRewardPoints: _.get(achievement, 'externalRewardPoints'),
    isEarned: _.get(achievement, 'badgeStatus') === rewardsConstants.ACHIEVEMENTS_STATUSES.earned
});

export const getAllRewardsAchievements = state => {
    return _.get(rewardsSelectors, 'getAllRewardsAchievements') && rewardsSelectors.getAllRewardsAchievements(state);
};

const getItemWithExternalRewards = (item, achievement, isLiveBetter) => isLiveBetter ? ({
    ...item,
    ...getExternalRewardsFields(achievement)
}) : item;

export const getSetGoalsAdditionals = createSelector(getAllRewardsAchievements,
    achievements => {
        const joinGoalAchievement = _.find(achievements, item => item.slug === LBP_ITEMS.join_goal);
        return getExternalRewardsFields(joinGoalAchievement);
    });

export const getScreensFormatted = createSelector(getScreens, getAllRewardsAchievements, coreSelectors.isLiveBetter,
    (screens, achievements, isLiveBetter) => {
        const registerDeviceAchievement = _.find(achievements, item => item.slug === LBP_ITEMS.apps_devices);
        const interestAchievement = _.find(achievements, item => item.slug === LBP_ITEMS.personalize);
        const healthSurveyAchievement = _.find(achievements, item => item.slug === LBP_ITEMS.health_survey);
        return _.mapValues(screens, item => {
            switch (item.group_slug) {
                case SAMPLE_TASK:
                    return { ...item, description: i18n.t('onboarding.breath_sample_task') };
                case APPS_AND_DEVICES:
                    return getItemWithExternalRewards(item, registerDeviceAchievement, isLiveBetter);
                case PERSONALIZE:
                    return getItemWithExternalRewards(item, interestAchievement, isLiveBetter);
                case HEALTH_SURVEY:
                    return getItemWithExternalRewards(item, healthSurveyAchievement, isLiveBetter);
                default:
                    return item;
            }
        });
    });

export const getScreensArray = createSelector(getScreensFormatted, screens => _.values(screens));

export const getScreensArrayForOnboardingWelcome = createSelector(getScreensArray, screens => {
    if (Platform.OS === PLATFORMS.android || Platform.OS === PLATFORMS.web) {
        return _.filter(screens, screen => screen.group_slug !== FLOW_STEPS.notifications);
    }
    return screens;
});

export const getScreensArrayForTasksList = createSelector(getScreensArray, screens => {
    return _.filter(screens, screen => screen.group_slug !== FLOW_STEPS.notifications);
});

export const getUnfinishedTasks = createSelector(getScreensArrayForTasksList, screens => {
    return _.filter(screens, screen => _.get(screen, 'tasks', []).length);
});

export const getUnfinishedTasksForFirebase = createSelector(getUnfinishedTasks, screens => {
    return _.join(_.map(screens, item => item.group_slug));
});

export const getPercentageCompleted = createSelector(getScreensArrayForTasksList, getUnfinishedTasks,
    (allTasks, finishedTasks) => {
        return Math.round((allTasks.length - finishedTasks.length)/allTasks.length * 100);
    }
);

export const getHealthSurveyFromScreens = createSelector(getScreensFormatted, screens => _.get(screens, 'health_survey'));
export const getAppsDevicesFromScreens = createSelector(getScreensFormatted, screens => _.get(screens, 'apps_and_devices'));
export const getPersonalizeFromScreens = createSelector(getScreensFormatted, screens => _.get(screens, 'personalize'));
export const getNotificationsFromScreens = createSelector(getScreensFormatted, screens => _.get(screens, 'notifications'));
export const getSampleTaskFromScreens = createSelector(getScreensFormatted, screens => _.get(screens, 'sample_task'));
export const hasHealthSurveyCard = createSelector(getScreensFormatted, screens => _.has(screens, 'health_survey'));
export const hasAppsDevicesTask = createSelector(getScreensFormatted, screens => _.has(screens, 'apps_and_devices'));
export const getEAPCard = createSelector(getScreensFormatted, screens => _.get(screens, 'eap'));
export const getAppsDevicesPoints = createSelector(getAppsDevicesFromScreens, screen => _.get(screen, 'points', 0));
export const getSpecificSampleTaskFromScreens = createSelector(
    getSampleTaskFromScreens,
    coreSelectors.getSecondParam,
    (screen, task_slug) => _.find(_.get(screen, 'tasks'), { task_slug }) // eslint-disable-line camelcase
);
export const getOnboardingTasks = createSelector(
    getScreensArray,
    coreSelectors.isLiveBetter,
    (screens, isLiveBetter) => _.flatten(_.map(screens, screen => {
        return isLiveBetter ? _.get(screen, 'tasks') : _.filter(_.get(screen, 'tasks'), task => task.show_in_on_boarding === '1');
    }))
);

export const getOnboardingSteps = createSelector(
    getOnboardingTasks,
    tasks => _.map(tasks, task => task.task_slug)
);

export const getSteps = createSelector(
    getScreens,
    getOnboardingSteps,
    coreSelectors.getSecondParam,
    (screens, onboardingSteps, slug) => {
        if (slug) return _.map(_.get(_.get(screens, slug), 'tasks'), t => t.task_slug);
        return onboardingSteps;
    }
);

export const getOnboardingSkipSteps = createSelector(
    getScreensArray,
    coreSelectors.getSecondParam,
    (screens, slug) => {
        if (slug) return [];
        return _.map(screens, screen => _.get(screen, 'tasks.0.task_slug'));
    }
);
export const getOnboardingStepsFlow = createSelector(
    getScreensArray,
    screens => _.reduce(
        screens,
        (el, val) => {
            const key = _.get(val, 'tasks.0.task_slug');
            _.assign(el, { [key]: _.drop(_.map(_.get(val, 'tasks'), t => t.task_slug)) });
            return el;
        },
        {}
    )
);
const getRequiredTasks = createSelector(getScreens, screens => _.filter(screens, item => item.required));
export const getRequiredSteps = createSelector(getRequiredTasks, screens => _.flatten(
    _.map(screens, s => _.map(_.filter(s.tasks, t => _.toInteger(t.required)), t => t.task_slug))
));

export const getOnboardingStep = createSelector(getOnboardingSteps, coreSelectors.getSecondParam,
    (steps, step) => step || _.get(steps, '0') || STEPS.welcomeUser);

export const getOnboardingTasksPoints = createSelector(
    getOnboardingTasks,
    tasks => _.reduce(
        tasks,
        (el, val) => {
            _.assign(el, { [val.task_slug]: _.get(val, 'points', 0) });
            return el;
        },
        {}
    )
);

export const getHealthSurvey = state => state[NAME].healthSurvey;
export const getHealthSurveyQuestions = createSelector(getHealthSurvey, survey => _.get(survey, 'questions'));
export const getHealthSurveyQuestionById = createSelector(
    getHealthSurveyQuestions,
    coreSelectors.getSecondParam,
    (questions, id) => questions[id]
);
const getHealthSurveyResults = createSelector(getHealthSurvey, survey => _.get(survey, 'results'));
export const getHealthSurveyStatusHra = createSelector(getHealthSurvey, survey => _.get(survey, ['status', 'hra']));

const getHealthSurveyCurrentResultId = createSelector(getHealthSurveyStatusHra, status => _.get(status, ['0', 'id']));
export const getCurrentHealthSurveyResult = createSelector(getHealthSurveyResults, getHealthSurveyCurrentResultId, (results, id) => results[id]);

const getHealthSurveyResultBySlug = (result, slug) => _.find(_.get(result, 'data', []), item => item.slug === slug);
export const getCurrentHealthSurveyResultBySlug = createSelector(
    getCurrentHealthSurveyResult, coreSelectors.getSecondParam,
    getHealthSurveyResultBySlug
);
export const isLoadingHealthSurvey = state => coreSelectors.isLoading(state, types.GET_HEALTH_SURVEY.NAME);
export const isLoadingScore = state => coreSelectors.isLoading(state, types.GET_HEALTH_SURVEY_RESULTS_STATUS.NAME);
export const isLoadingHealthSurveyResults = state =>
    coreSelectors.isLoading(state, types.GET_HEALTH_SURVEY_RESULTS.NAME) ||
    coreSelectors.isLoading(state, types.GET_HEALTH_SURVEY_STATUS.NAME) ||
    isRefreshingHraScore(state);
export const isLoadingHealthSurveyResultsStatus = state => coreSelectors.isLoading(state, types.GET_HEALTH_SURVEY_RESULTS_STATUS.NAME) || isRefreshingHraScore(state);
export const getHealthSurveyResultsError = state => coreSelectors.getError(state, types.GET_HEALTH_SURVEY_RESULTS.NAME);
export const getVitalStatsError = state => coreSelectors.getError(state, types.SET_VITAL_STATS.NAME);
export const isLoadingHRA = state => coreSelectors.isLoading(state, types.GET_HRA_SCORES.NAME);

export const getTask = state => state[NAME].EAPTask;
export const isEAPLoading = state => coreSelectors.isLoading(state, types.GET_EAP.NAME);
export const isEAPSaving = state => coreSelectors.isLoading(state, types.SAVE_EAP.NAME);
export const isEAPEnabled = state => _.get(getHealthSurvey(state), 'status.eap_enabled', false);
export const isHealthSurveyEnabled = state => coreSelectors.getIsHealthSurveyEnabled(state); //_.get(getHealthSurvey(state), 'status.enabled', true);
export const getOptions = state => state[NAME].EAPOptions;
export const checkboxOptions = createSelector(getTask, task => task.concernsCheckboxOptions);
export const getCheckboxOptionsSelector = createSelector(checkboxOptions, items => _.map(items, item => ({ ...item,
    headTitle: parsers.removeAllHtmlTags(item.value),
    id: item.value,
    selected: false,
    label: parsers.removeAllHtmlTags(item.label)
})));

export const isShowingOnboarding = state => state[NAME].isShowingOnboarding;
export const isRefreshingHraScore = state => state[NAME].isRefreshingHraScore;

export const isSavingVitalStats = state => coreSelectors.isLoading(state, types.SET_VITAL_STATS.NAME);
export const isSavingAnswer = state => coreSelectors.isLoading(state, types.SET_HEALTH_SURVEY_ANSWERS.NAME);
export const isSettingUserTaskComplete = state => coreSelectors.isLoading(state, types.SET_USER_TASK_COMPLETE.NAME);

export const getAfterOnboardingPath = state => state[NAME].afterOnboardingPath;
export const isLoadingTasks = state => coreSelectors.isLoading(state, types.GET_USER_TASKS.NAME);
export const areTasksShown = state => state[NAME].areTasksShown;

export const getStartTime = (state, step) => _.get(state[NAME].startTime, [step]);
