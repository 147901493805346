import axios from 'axios';
import moment from 'moment';
import { getBaseUrl } from '../core';

const getUrl = endpoint => endpoint ? getBaseUrl(`stream_items/${endpoint}`) : getBaseUrl('stream_items');

export function getStreams(params) {
    return axios.get(getUrl(), { params });
}

export function getStream(postId) {
    return axios.get(getUrl(postId));
}

export function deleteStream(postId) {
    return axios.delete(getUrl(postId));
}

export function modarateStream(postId) {
    return axios.post(getBaseUrl('moderation'), { entityType: 'stream',  entityId: postId });
}

export function postStream(streamItem) {
    return axios.post(getUrl(), streamItem);
}

export function updateStream(streamItem) {
    return axios.put(getUrl(), streamItem);
}

export function getDailyMetrics(userId) {
    return axios.get(getBaseUrl(`users/${userId}/daily_metrics/?date=${moment().format('YYYY-MM-DD')}`));
}
