import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { baseColors, appFonts, spacing, windowSize } from '../../../../styles';
import { parsers, translate } from '../../../core';
import { getComment, isDeleting } from '../../selectors';

export const NUMBER_OF_LINES = 7;

export default function WithCommentBase(WrappedComponent) {
    class CommentBase extends PureComponent {
        static propTypes = {
            commentId: PropTypes.number.isRequired, // eslint-disable-line
            openOptions: PropTypes.func.isRequired,
            isDeleting: PropTypes.bool,
            comment: PropTypes.object
        };

        static defaultProps = {
            comment: null,
            isDeleting: false
        };

        get formattedDate() {
            return moment(this.props.comment.dateTimeCreated).fromNow();
        }

        get preparedHtmlText() {
            return parsers.replaceBrAndATagsWithItsHref(this.props.comment.commentText);
        }

        openOptions = () => {
            const { openOptions, isDeleting, comment } = this.props;
            !isDeleting && openOptions(comment);
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    openOptions={this.openOptions}
                    formattedDate={this.formattedDate}
                    preparedHtmlText={this.preparedHtmlText}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        return {
            comment: getComment(state, ownProps.commentId),
            isDeleting: isDeleting(state),
        };
    }

    return connect(mapStateToProps)(translate()(CommentBase));
}

export const styles = {
    userAvatar: {
        marginLeft: spacing.s3
    },
    comment: {
        marginBottom: spacing.s1,
    },
    commentInner: {
        flexShrink: 1,
        marginLeft: spacing.s2,
        marginRight: spacing.s3
    },
    commentBubble: {
        backgroundColor: baseColors.grey90,
        borderRadius: spacing.s2,
        paddingTop: spacing.s0,
        paddingBottom: spacing.s0,
        paddingLeft: spacing.s2,
        paddingRight: spacing.s2,
        marginBottom: spacing.s0,
        maxWidth: windowSize.width * 0.8
    },
    seeMoreStyle: {
        ...appFonts.smMedium,
        backgroundColor: baseColors.grey90
    },
    date: {
        ...appFonts.xsRegular,
        color: baseColors.grey40,
        paddingLeft: spacing.s2
    },
    owner: {
        ...appFonts.smMedium
    }
};
