import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { css, StyleSheet } from 'aphrodite-jss';

import WithBiometricsEditorBase, { styles as baseStyles } from './BiometricsEditorBase';
import { components as Core, openUrl } from '../../../../core';
import { NUMBER_INPUT_MAX_LENGTH } from '../../../constants';
import { layoutStyle, importantStyles, spacing } from '../../../../../styles';

const SAVE_CHANGES_BUTTON_ID = 'saveChangesButton';
const KEY_CODE_OF_ENTER_BUTTON = 13;

class BiometricsEditor extends Component {
    static propTypes = {
        biometricFields: PropTypes.array,
        biometricCustomComponent: PropTypes.object,
        errors: PropTypes.object,
        onRef: PropTypes.func,
        saveChanges: PropTypes.func,
        onChangeText: PropTypes.func.isRequired,
        onUnitChange: PropTypes.func.isRequired,
        isLoading: PropTypes.bool,
        isSaving: PropTypes.bool,
        i18n: PropTypes.object.isRequired
    };

    static defaultProps = {
        biometricFields: [],
        biometricCustomComponent: {},
        errors: {},
        onRef: null,
        saveChanges: null,
        isLoading: false,
        isSaving: false
    };

    componentDidMount() {
        if (this.props.onRef) this.props.onRef(this);
    }

    componentWillUnmount() {
        if (this.props.onRef) this.props.onRef(null);
    }

    get renderCustomComponent() {
        const { biometricCustomComponent } = this.props;
        const url = biometricCustomComponent.url || '';
        const primaryColor = biometricCustomComponent.primaryColor || '';
        return (
            <Core.InfoSection
                iconName={biometricCustomComponent.icon}
                iconColor={primaryColor}
                title={biometricCustomComponent.title}
                subtitle={biometricCustomComponent.urlText}
                onPress={() => openUrl(url)}
                isTouchable={true}
            />
        );
    }

    onKeyUpHandler = e => {
        if (e.keyCode === KEY_CODE_OF_ENTER_BUTTON) {
            document.getElementById(SAVE_CHANGES_BUTTON_ID).focus();
        }
    }

    renderItem = item => {
        const { errors, i18n } = this.props;
        const unitOptions = item.units || [];
        const value = _.get(item, 'value', '');
        return (
            <Core.UnitField
                unitOptions={unitOptions}
                unit={item.unit}
                onUnitChange={this.props.onUnitChange(item)}
                fullWidth={true}
                value={value ? value.toString() : ''}
                error={!!errors[item.stateKey]}
                helperText={errors[item.stateKey]}
                label={i18n.t(item.name)}
                onChangeText={this.props.onChangeText(item)}
                key={item.name}
                inputProps={{ maxLength: NUMBER_INPUT_MAX_LENGTH, 'aria-label': i18n.t(item.name) }}
                optionsTranslated={true}
                onKeyUp={this.onKeyUpHandler}
            />
        );
    };

    render() {
        const { biometricFields, i18n } = this.props;
        return (
            <div className={css(layoutStyle.flex1)}>
                <Core.SubHeader
                    title={i18n.t('biometrics')}
                    noTopPadding={true}
                    right={<Core.Button id={SAVE_CHANGES_BUTTON_ID} size={Core.Button.SIZES.medium} onPress={this.props.saveChanges} className={css(styles.saveButton)}>{this.props.i18n.t('save')}</Core.Button>}
                />
                {_.get(this.props.biometricCustomComponent, 'title') ? this.renderCustomComponent : null}
                <div className={css(styles.innerContainer)}>
                    {_.map(biometricFields, this.renderItem)}
                </div>
                <Core.BlockingLoading isLoading={this.props.isLoading || this.props.isSaving} />
            </div>
        );
    }
}

const styles = StyleSheet.create({
    ...baseStyles,
    innerContainer: {
        ...baseStyles.innerContainer,
        paddingBottom: spacing.s8
    },
    ...importantStyles(
        {
            saveButton: {
                minWidth: 100
            }
        })
});

export default WithBiometricsEditorBase(BiometricsEditor);
