import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import classnames from 'classnames';
import { components as Core } from '../../../core';
import WithRewardCarouselItemBase, { styles as baseStyles } from './RewardCarouselItemBase';
import { exploreImageHeight, exploreImageWidth, layoutStyle } from '../../../../styles';

class RewardCarouselItem extends PureComponent {
    static propTypes = {
        productName: PropTypes.string.isRequired,
        details: PropTypes.string.isRequired,
        mainContainerStyle: PropTypes.object,
        onPressSingle: PropTypes.func,
        image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
        innerRewardComponent: PropTypes.node,
    };

    static defaultProps = {
        mainContainerStyle: null,
        onPressSingle: null,
        innerRewardComponent: null,
    };

    render() {
        const { onPressSingle, image, productName, details, mainContainerStyle, innerRewardComponent } = this.props;
        return (
            <div className={classnames(css(styles.mainContainer), mainContainerStyle)}>
                {innerRewardComponent ?
                    <Core.TouchableItem
                        onPressSingle={onPressSingle}
                        innerContainerClassName={css(layoutStyle.flex1)}
                        hasImageObjectFitContain={true}>
                        {innerRewardComponent}
                    </Core.TouchableItem> :
                    <Core.TouchableItem
                        onPressSingle={onPressSingle}
                        innerContainerClassName={css(layoutStyle.flex1)}
                        image={image}
                        imageWidth={exploreImageWidth}
                        imageHeight={exploreImageHeight}>
                        <p className={css(styles.productTitle)}>{productName}</p>
                        <p className={css(styles.rewardsDetails)}>{details}</p>
                    </Core.TouchableItem>}
            </div>
        );
    }
}

export default WithRewardCarouselItemBase(RewardCarouselItem);

const styles = StyleSheet.create({ ...baseStyles });
