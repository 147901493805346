import { appFonts, baseColors, spacing } from '../../../../styles';
import { translate } from '../../services';

export default function WithCreateEntityBase(WrappedComponent) {
    return translate()(WrappedComponent);
}

const IMAGE_HEIGHT = 48;

export const styles = {
    mainContainer: {
        marginTop: spacing.s1,
        marginBottom: spacing.s1,
        flex: 1
    },
    name: {
        ...appFonts.xlBold,
    },
    cardContainer: {
        backgroundColor: baseColors.grey90,
        textAlign: 'center',
        alignItems: 'center',
        borderRadius: spacing.s1,
        padding: spacing.s7,
        margin: spacing.s1,
        flex: 1,
    },
    title: {
        ...appFonts.mdRegular,
        color: baseColors.grey20,
        marginTop: spacing.s3,
        textAlign: 'center',
    },
    subtitle: {
        ...appFonts.smRegular,
        color: baseColors.grey40,
        marginTop: spacing.s1,
    },
    button: {
        marginTop: spacing.s5,
    },
    image: {
        height: IMAGE_HEIGHT,
    },
    separator: {
        height: 1,
        backgroundColor: baseColors.grey80,
    },
};
