import _ from 'lodash';
import * as types from './actionTypes';
import * as api from './api';
import { normalize } from '../core';
import { validateError } from '../../config';

export function getResourceLinks(params, isReplace = true) {
    return function (dispatch) {
        dispatch({ type: types.GET_RESOURCE_LINKS.REQUEST });
        return api.getResourceLinks(params)
            .then(res => {
                const items = res.data.data;
                dispatch({
                    type: types.GET_RESOURCE_LINKS.SUCCESS,
                    payload: {
                        ...normalize.normalizeArray(_.flatten(items)),
                        count: res.data.total,
                        isReplace
                    }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.GET_RESOURCE_LINKS.ERROR, payload: { error } });
            }));
    };
}