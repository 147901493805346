import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite-jss';
import { layoutStyle } from '../../../../styles';
import { fileToBase64 } from '../../services';
import WithImageUploadGeneralBase from './ImageUploadGeneralBase';

class ImageUploadGeneral extends PureComponent {
    static propTypes = {
        fileChanged: PropTypes.func.isRequired,
        children: PropTypes.node,
        showPicker: PropTypes.func.isRequired,
        onRef: PropTypes.func,
        isDeleting: PropTypes.bool,
    };

    static defaultProps = {
        children: null,
        onRef: undefined,
        isDeleting: false,
    };

    componentDidMount() {
        if (this.props.onRef) this.props.onRef(this);
    }

    componentDidUpdate() {
        if (this.props.isDeleting) this.clearImagePicker();
    }

    componentWillUnmount() {
        if (this.props.onRef) this.props.onRef(null);
    }

    showPicker = () => this.props.showPicker(this.openImagePicker, false);

    openImagePicker = () => this.file.click();

    clearImagePicker = () => {
        this.file.value = null;
    };

    fileChanged = async () => {
        if (!this.file.files[0]) return;
        const fullData = await fileToBase64(this.file.files[0]);
        const data = fullData.split('base64,')[1];
        this.props.fileChanged(data, fullData);
    };

    render() {
        return (
            <Fragment>
                {this.props.children}
                <input
                    ref={ref => (this.file = ref)}
                    type="file"
                    name="filename"
                    accept="image/gif, image/jpeg, image/png"
                    onChange={this.fileChanged}
                    className={css(layoutStyle.hidden)}
                />
            </Fragment>
        );
    }
}

export default WithImageUploadGeneralBase(ImageUploadGeneral);
