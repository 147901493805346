import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classnames from 'classnames';
import { StyleSheet, css } from 'aphrodite-jss';
import { layoutStyle, spacing } from '../../../../styles';
import GoalIcon from '../GoalIcon';
import WithGoalIconsSectionBase, { styles as baseStyles } from './GoalIconsSectionBase';

class GoalIconsSection extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        goals: PropTypes.array,
        iconSize: PropTypes.number,
        iconClassName: PropTypes.string,
        getSlug: PropTypes.func.isRequired,
        keyExtractor: PropTypes.func.isRequired,
        onRef: PropTypes.func.isRequired,
        detailsDeleteRedirect: PropTypes.string,
        isTaskCard: PropTypes.bool,
    };

    static defaultProps = {
        className: '',
        goals: [],
        iconSize: spacing.s11,
        iconClassName: '',
        detailsDeleteRedirect: undefined,
        isTaskCard: false,
    };

    onRef = index => node => this.props.onRef(index)(_.get(node, 'firstChild'));

    renderGoalIcon = (goal, index) => (
        <span
            key={this.props.keyExtractor(goal, index)}
            ref={this.onRef(index)}
            className={classnames(css(layoutStyle.inlineFlex, goal ? layoutStyle.cp : null), this.props.iconClassName)}>
            <GoalIcon
                slug={this.props.getSlug(goal)}
                goal={goal}
                size={this.props.iconSize}
                className={css(styles.icon)}
                challengeId={_.get(goal, 'challengeId')}
                detailsDeleteRedirect={this.props.detailsDeleteRedirect}
                isTaskCard={this.props.isTaskCard}
            />
        </span>
    );

    render() {
        return (
            <div className={classnames(css(styles.iconContainer), this.props.className)}>
                {_.map(this.props.goals, this.renderGoalIcon)}
            </div>
        );
    }
}

export default WithGoalIconsSectionBase(GoalIconsSection);

const styles = StyleSheet.create({
    ...baseStyles,
    icon: {
        transition: 'all 2s'
    },
});
