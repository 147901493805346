import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import { css, StyleSheet } from 'aphrodite-jss';
import { withRouter } from 'react-router-dom';
import {
    components as Core,
    CAROUSEL_TYPES,
    CSS_CLASSES,
    tracker,
    ROUTES,
    Storage,
    ENTITIES_ACTIVE_TAB
} from '../../../core';
import RecommendedGoalsCard from '../RecommendedGoalsCard';
import ChallengeCard from '../ChallengeCard';
import { spacing, appFonts, baseColors, windowSize, important, importantClass } from '../../../../styles';
import WithCarouselBase, { BASE_CARD_HEIGHT, CIRCLE_SIZE, RECT_HEIGHT, RECT_COLOR } from './CarouselBase';
import { DISPLAY_TYPES } from '../../constants';
import RecommendedChallengesCard from '../RecommendedChallengesCard';

const SLIDES_QUANTITY = windowSize.isLg ? 3 : 2;

class Carousel extends PureComponent {
    static propTypes = {
        challenges: PropTypes.array,
        goalsCompletedNotDismissed: PropTypes.array,
        isAllowedToSetGoal: PropTypes.bool.isRequired,
        isFirstPriorityLoad: PropTypes.bool.isRequired,
        isCarouselUnfilled: PropTypes.bool.isRequired,
        goalsData: PropTypes.array,
        challengesData: PropTypes.array,
        goalsTitle: PropTypes.string.isRequired,
        challengesTitle: PropTypes.string.isRequired,
    };

    static defaultProps = {
        challenges: [],
        goalsCompletedNotDismissed: [],
        goalsData: [],
        challengesData: [],
    };

    constructor(props) {
        super(props);
        this.challengesСarouselRef = React.createRef();
        this.goalsCarouselRef = React.createRef();

        this.state = {
            isSliding: false,
            quantity: SLIDES_QUANTITY,
            activeSlideIndexes: [0, SLIDES_QUANTITY - 1],
            showChallengesCarousel: true,
        };
    }

    componentDidMount() {
        this.props.loadData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isFirstPriorityLoad && !this.props.isFirstPriorityLoad) {
            tracker.logEvent('Home_Carousel_TilesTotal', { index: this.slides.length || 0 });
            tracker.logEvent('Home_Carousel_TilesViewed', { index: SLIDES_QUANTITY });
        }
    }

    get challengesCards() {
        return _.map(this.props.challengesData, item => {
            const slideIndex = _.findIndex(this.data, slide => slide === item);
            return (
                <ChallengeCard
                    slideIndex={slideIndex}
                    activeSlideIndexes={this.state.activeSlideIndexes}
                    key={item.challengeId}
                    challengeId={item.challengeId}
                    isDisabled={this.state.isSliding}
                    isHomeCarousel={true}
                    carouselRef={this.challengesСarouselRef}
                />
            );
        });
    }

    get goalsCards() {
        return _.map(this.props.goalsData, item => {
            const slideIndex = _.findIndex(this.data, slide => slide === item);
            return (
                <ChallengeCard
                    slideIndex={slideIndex}
                    activeSlideIndexes={this.state.activeSlideIndexes}
                    key={item.challengeId}
                    challengeId={item.challengeId}
                    isDisabled={this.state.isSliding}
                    isHomeCarousel={true}
                    carouselRef={this.goalsCarouselRef}
                />
            );
        });
    }

    get recommendGoalsCard() {
        return this.props.isAllowedToSetGoal
            ? <RecommendedGoalsCard key="recommendedGoals" isSingle={!this.hasGoals && !this.hasChallenges} carouselRef={this.goalsCarouselRef} />
            : null;
    }

    get recommendedChallengesCard() {
        return !this.hasChallenges
            ? <RecommendedChallengesCard key="recommendedChallenges" isSingle={!this.hasGoals && !this.hasChallenges} />
            : null;
    }

    get slides() {
        return _.compact([
            ...this.challengesCards,
            this.recommendGoalsCard,
        ]);
    }

    get goalsSlides() {
        return _.compact([
            ...this.goalsCards,
            !this.isCardOutsideCarousel && this.recommendGoalsCard,
        ]);
    }

    get challengesSlides() {
        return _.compact([
            ...this.challengesCards,
            this.recommendedChallengesCard
        ]);
    }

    get data() {
        const { challenges, goalsCompletedNotDismissed } = this.props;
        return _.compact([
            ...challenges,
            this.recommendGoalsCard,
            ...goalsCompletedNotDismissed,
        ]);
    }

    get hasChallenges() {
        return !!this.props.challengesData.length;
    }

    get hasGoals() {
        return !!this.props.goalsData.length;
    }

    get hasOneGoalSet() {
        return _.get(this.props, 'goalsData.length') === 1;
    }

    get goalsCarouselType() {
        if (!this.hasGoals) return CAROUSEL_TYPES.single;
        return CAROUSEL_TYPES.twoOnly;
    }

    get cardSkeletons() {
        const skeletons = [];
        for (let i = 0; i < 3; i++) {
            skeletons.push(this.cardSkeletonItem());
        }
        return skeletons;
    }

    get hasChallengesOnly() {
        return this.hasChallenges && !this.hasGoals;
    }

    get isCardOutsideCarousel() {
        return (!this.hasChallenges
            && (this.goalsCarouselType === CAROUSEL_TYPES.single)
            && this.hasGoals);
    }

    get gridSize() {
        return this.isCardOutsideCarousel ? 4 : 12;
    }

    cardSkeletonItem = () => (
        <div className={css(styles.skeletonCardContainer)}>
            <div id="carousel-card-skeleton" className={css(styles.skeletonCard)}>
                <div className={css(styles.topPart)}>
                    <div id="card-circle-skeleton" className={css(styles.circle)}>
                        <Core.SkeletonContent primaryColor={RECT_COLOR} >
                            <Core.SkeletonRect height={CIRCLE_SIZE} width={CIRCLE_SIZE} />
                        </Core.SkeletonContent>
                    </div>
                    <div className={css(styles.topRightBox)}>
                        <Core.SkeletonContent primaryColor={RECT_COLOR}>
                            <Core.SkeletonRect height={RECT_HEIGHT} width="100%" />
                            <Core.SkeletonRect height={RECT_HEIGHT} width="66.6%" className={css(styles.mTop)} />
                        </Core.SkeletonContent>
                    </div>
                </div>
                <div className={css(styles.middlePart)}>
                    <Core.SkeletonContent primaryColor={RECT_COLOR}>
                        <Core.SkeletonRect height={RECT_HEIGHT} width="75%" />
                        <Core.SkeletonRect height={RECT_HEIGHT} width="100%" className={css(styles.mTopBottom)} />
                        <Core.SkeletonRect height={RECT_HEIGHT} width="75%" />
                    </Core.SkeletonContent>
                </div>
                <Core.SkeletonContent primaryColor={RECT_COLOR}>
                    <Core.SkeletonRect height={spacing.s0} width="100%" className={css(styles.bottomPart)} />
                </Core.SkeletonContent>
            </div>
        </div>
    )

    afterChange = index => {
        this.setState({ activeSlideIndexes: [index, index + SLIDES_QUANTITY - 1] });
        if (this.state.quantity < this.slides.length) {
            this.setState(prevState => ({ quantity: prevState.quantity + SLIDES_QUANTITY }), () => {
                tracker.logEvent('Home_Carousel_TilesViewed', { index: this.state.quantity });
            });
        }
    };

    viewAllAction = isGoalCarousel => {
        const { history } = this.props;
        if (isGoalCarousel) {
            history.push(ROUTES.setPersonalGoal());
        } else {
            Storage.setItem(ENTITIES_ACTIVE_TAB.EXPLORE_CHALLENGES, { id: DISPLAY_TYPES.ON_AND_UPCOMING, label: this.props.i18n.t('onAndUpcoming') });
            history.push(ROUTES.challenges());
        }
    }


    renderCarouselHeader = isGoalCarousel => {
        const { goalsTitle, challengesTitle, i18n } = this.props;
        return (
            <div className={css(styles.cardHeader)}>
                <div className={css(styles.cardHeaderContent)}>
                    <p className={css(styles.cardHeaderText)}>
                        {isGoalCarousel ? goalsTitle : challengesTitle}
                    </p>
                    {((isGoalCarousel && !this.hasGoals) || (!isGoalCarousel && !this.hasChallenges)) ? null : (
                        <Core.ViewAllButton
                            text={i18n.t('viewAllButton')}
                            onPress={() => this.viewAllAction(isGoalCarousel)}
                            textClassName={css(styles.textClassName)}
                        />
                    )}
                </div>
            </div>
        );
    };

    renderCards = () => (
        <>
            {this.state.showChallengesCarousel ?
                (
                    <Grid item={true} xs={4}>
                        <Core.Card className={css(styles.card, styles.mRight)}>
                            {this.renderCarouselHeader(false)}
                            <div className={CSS_CLASSES.goals} ref={this.challengesСarouselRef}>
                                <Core.Carousel
                                    parent={this}
                                    type={CAROUSEL_TYPES.single}
                                    arrows={true}
                                    dots={true}
                                    goalsChallengesSlider={true}
                                    beforeChange={this.beforeChange}
                                    afterChange={this.afterChange}
                                    itemsLength={this.challengesSlides.length}>
                                    {this.challengesSlides}
                                </Core.Carousel>
                            </div>
                        </Core.Card>
                    </Grid>
                ) : null }
            <Grid item={true} xs={8}>
                <Core.Card className={css(styles.card)}>
                    {this.renderCarouselHeader(true)}
                    <div className={CSS_CLASSES.goals} ref={this.goalsCarouselRef}>
                        <Core.Carousel
                            parent={this}
                            type={this.goalsCarouselType}
                            arrows={true}
                            dots={true}
                            goalsChallengesSlider={true}
                            beforeChange={this.beforeChange}
                            afterChange={this.afterChange}
                            itemsLength={this.goalsSlides.length}>
                            {this.goalsSlides}
                        </Core.Carousel>
                    </div>
                </Core.Card>
            </Grid>
            {this.isCardOutsideCarousel ? (
                <Grid item={true} xs={4}>
                    <Core.Card className={css(styles.card, this.hasOneGoalSet && styles.cardTopMargin)}>
                        {this.hasOneGoalSet ? null : this.renderCarouselHeader(true)}
                        {this.recommendGoalsCard}
                    </Core.Card>
                </Grid>
            ) : null }
        </>
    );

    render() {
        return (
            <Grid container={true}>
                {this.props.isCarouselUnfilled ? this.cardSkeletons : this.renderCards()}
            </Grid>
        );
    }
}

export default withRouter(WithCarouselBase(Carousel));

const styles = StyleSheet.create({
    cardHeader: {
        color: baseColors.black,
        paddingLeft: spacing.s0,
        paddingBottom: spacing.s3,
    },
    cardHeaderContent: {
        height: spacing.s7,
        backgroundColor: 'transparent',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline'
    },
    cardHeaderText: {
        ...appFonts.lgMedium,
        margin: 'auto 0'
    },
    card: {
        marginTop: spacing.s3,
        marginBottom: 0,
        boxShadow: important('none'),
    },
    skeletonCardContainer: {
        width: '33.3%',
        padding: spacing.s0,
        marginTop: spacing.s2,
        marginBottom: spacing.s4,
    },
    skeletonCard: {
        height: BASE_CARD_HEIGHT,
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3,
        paddingTop: spacing.s15,
        borderRadius: spacing.s1,
        boxShadow: '0 1px 2px 0 rgba(0,0,0,.2), 0 0 0 1px rgba(0,0,0,.05)'
    },
    mRight: {
        marginRight: spacing.s5,
    },
    topPart: {
        display: 'flex',
        height: spacing.s15,
    },
    circle: {
        flexShrink: 0,
        marginRight: spacing.s3,
    },
    topRightBox: {
        width: '100%',
        marginTop: spacing.s1 + 2,
    },
    mTop: {
        marginTop: spacing.s2,
    },
    middlePart: {
        width: '100%',
        marginTop: spacing.s15,
    },
    mTopBottom: {
        marginTop: spacing.s3,
        marginBottom: spacing.s3,
    },
    bottomPart: {
        marginTop: spacing.s11,
    },
    cardTopMargin: {
        marginTop: spacing.s13,
    },
    textClassName: importantClass({
        marginRight: -spacing.s2,
        ...appFonts.mdMedium
    })
});
