import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';
import { withRouter } from 'react-router-dom';

import Bookmark from '../Bookmark';
import BonusMark from '../BonusMark';
import { components as Core, ROUTES } from '../../../core';
import { baseColors, importantStyles, layoutStyle, spacing, libraryContentImageWidth, libraryContentImageHeight, media } from '../../../../styles';
import WithContentItemBase, { styles as baseStyles } from './ContentItemBase';

class ContentItem extends PureComponent {
    static propTypes = {
        item: PropTypes.object.isRequired,
        image: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        isVideo: PropTypes.bool.isRequired,
        history: PropTypes.object.isRequired,
        isNewVideoControlLayout: PropTypes.bool,
        isLastItemInList: PropTypes.bool.isRequired
    };

    static defaultProps = {
        isNewVideoControlLayout: false
    };

    openItemDetails = () => this.props.history.push(ROUTES.contentDetails(this.props.item.id));

    get renderLabel() {
        const { item, isMoreTab } = this.props;
        return (
            isMoreTab ? null : <Core.ExploreItemMeta iconName="tags" textArray={item.tagsArr} />
        );
    }

    get renderVideoControlContent() {
        const { isVideo, isNewVideoControlLayout, videoDuration } = this.props;

        const wrapperStyle = isNewVideoControlLayout ? '' : css(styles.videoControlContainer);
        const itemSize = isNewVideoControlLayout ? spacing.s5 : spacing.s1;

        return isVideo ? (
            <div className={wrapperStyle}>
                <Core.Icon
                    type='fa'
                    name='play'
                    fill='solid'
                    size={itemSize}
                    color={baseColors.white}
                />
                {videoDuration && !isNewVideoControlLayout ? <p className={css(styles.videoDuration)}>{videoDuration}</p> : null}
            </div>
        ) : null;
    }

    render() {
        const { item, image, title, isVideo, isLastItemInList } = this.props;

        return (
            <Core.TouchableItem
                image={image}
                isVideoPlayable={false}
                imageWidth={libraryContentImageWidth}
                imageHeight={libraryContentImageHeight}
                videoUrl={isVideo ? item.url : ''}
                onPressSingle={this.openItemDetails}
                onImageContent={this.renderVideoControlContent}
                innerContainerClassName={css(layoutStyle.flex, layoutStyle.flex1)}
                containerClassName={css(layoutStyle.entityItemMainContainer, layoutStyle.flex, styles.mainContainer, isLastItemInList && styles.mainContainerHalfWidth)}
            >
                <div className={css(styles.cardHeaderLeft)}>
                    <div className={css(styles.categoryWrapper)}>
                        {this.renderLabel}
                        <Bookmark item={item} />
                    </div>
                    <Core.ExploreItemTitle text={title} numberOfLines={3} />
                    <div className={css(styles.bonusContainer)}>
                        <BonusMark item={item} />
                    </div>
                </div>
            </Core.TouchableItem>
        );
    }
}

export default withRouter(WithContentItemBase(ContentItem));

const styles = StyleSheet.create({
    ...importantStyles({
        ...baseStyles,
        cardHeaderLeft: {
            ...baseStyles.cardHeaderLeft,
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
        },
        categoryWrapper: {
            ...baseStyles.categoryWrapper,
            display: 'flex',
        },
    }),
    mainContainer: {
        border: 'none',
        marginLeft: 0,
        marginRight: spacing.s3,
    },
    mainContainerHalfWidth: {
        width: `calc(50% - ${spacing.s3}px)`,

        [media.xsOnly]: {
            width: '100%'
        }
    }
});
