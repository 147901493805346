import React, { useState, useEffect } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import { baseColors, spacing } from '../../../../styles';
import WithProgressBarBase, { styles as baseStyles, WIDTH_LIMIT_WEB } from './ProgressBarBase';
import AnimateNumber from '../AnimateNumber';

const ProgressBar = ({ filledPercentage, percentageString, isPercentageShown, isAnimated, animationDuration, classes,
    initialValue, progressColor, isBigPercentage, backgroundColor, percentageTextColor, showSuccessPercentage, accessibilityLabels, ...otherProps }) => {
    const [animateValue, setAnimateValue] = useState(initialValue);
    const [percentage, setPercentage] = useState(0);
    const [isProgressAnimated, setAnimated] = useState(false);
    const [isPercentageOutside, setIsPercentageOutside] = useState(0);
    const progressRef = React.createRef();

    useEffect(() => {
        setIsPercentageOutside(progressRef.current.offsetWidth * filledPercentage / 100 < WIDTH_LIMIT_WEB);
        setPercentage(filledPercentage);
        setTimeout(() => {
            setAnimateValue(filledPercentage);
            setAnimated(true);
        }, animationDuration);
    }, [filledPercentage]);

    const getPercentageStringStyle = () => {
        const BIG_PERCENTAGE_FONT_SIZE = 18;
        const percentageColor = percentageTextColor || baseColors.black;
        return {
            width: percentageString,
            textAlign: 'right',
            fontSize: isBigPercentage ? BIG_PERCENTAGE_FONT_SIZE : null,
            color: showSuccessPercentage ? baseColors.successDarker : percentageColor
        };
    };

    const getAnimatedPercentage = () => {
        return {
            ...baseStyles.percentage,
            left: isProgressAnimated ? `${filledPercentage}%` : `calc(${filledPercentage}% - 10px)`,
            opacity: _.toInteger(isProgressAnimated),
            zIndex: 1,
            ...baseStyles.percentageWrapper,
            ...isPercentageOutside && { ...baseStyles.percentageWrapperInside },
            ...isPercentageOutside && { ...baseStyles.percentageInside },
            marginLeft: spacing.s1,
            paddingRight: filledPercentage <= 10 ? spacing.s2 : spacing.s3,
            transition: 'all .15s linear',
            transform: filledPercentage >= 10 ? 'translateX(-100%)' : 0
        };
    };

    const styles = {
        ...classes,
        ...useStyles({ animationDuration, isAnimated, progressColor, backgroundColor })
    };

    return (
        <React.Fragment>
            {isPercentageShown ? (
                <>
                    {isAnimated ? (
                        <div style={getAnimatedPercentage()}>
                            <AnimateNumber
                                value={animateValue}
                                start={initialValue}
                                redraw={true}
                            />
                            <span>%</span>
                        </div>
                    ) : <div style={getPercentageStringStyle()}>{percentageString}</div>}
                </>
            ) : null
            }
            <LinearProgress
                {...otherProps}
                value={percentage}
                classes={styles}
                ref={progressRef}
                title={accessibilityLabels}
                aria-label={accessibilityLabels}
            />
        </React.Fragment>
    );
};

export default WithProgressBarBase(ProgressBar);

ProgressBar.propTypes = {
    variant: PropTypes.string.isRequired,
    isPercentageShown: PropTypes.bool,
    percentageString: PropTypes.string.isRequired,
    filledPercentage: PropTypes.number,
    classes: PropTypes.object,
    isAnimated: PropTypes.bool,
    animationDuration: PropTypes.number,
    initialValue: PropTypes.number,
    progressColor: PropTypes.string,
    isBigPercentage: PropTypes.bool,
    percentageTextColor: PropTypes.string,
    showSuccessPercentage: PropTypes.bool,
    backgroundColor: PropTypes.string,
    accessibilityLabels: PropTypes.string
};

ProgressBar.defaultProps = {
    isPercentageShown: false,
    filledPercentage: 0,
    classes: {},
    isAnimated: false,
    animationDuration: 0,
    initialValue: null,
    progressColor: null,
    isBigPercentage: false,
    percentageTextColor: null,
    showSuccessPercentage: false,
    backgroundColor: null,
    accessibilityLabels: 'Progress bar'
};

const useStyles = makeStyles({
    barColorPrimary: {
        backgroundColor: props => props.progressColor ? props.progressColor : baseColors.primary,
        borderRadius: props => props.isAnimated ? spacing.s2 : spacing.s0,
        transitionDuration: props => props.isAnimated && props.animationDuration,
        transitionProperty: 'all'
    },
    colorPrimary: {
        backgroundColor: props => props.backgroundColor ? props.backgroundColor : baseColors.grey80
    },
    root: {
        borderRadius: props => props.isAnimated ? spacing.s2 : spacing.s0,
        height: props => props.isAnimated ? spacing.s5 : spacing.s1,
        flex: 1
    },
});
