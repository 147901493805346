import React from 'react';
import PropTypes from 'prop-types';
import MaterialChip from '@material-ui/core/Chip';
import { css, StyleSheet } from 'aphrodite-jss';
import { importantStyles, spacing } from '../../../../styles';
import Icon from '../Icon';
import WithChipBase, { REMOVE_ICON_NAME } from './ChipBase';

const Chip = ({ label, onDelete, classes, clickable, ...props }) => (
    <MaterialChip
        classes={{ root: css(styles.root), ...classes }}
        deleteIcon={
            <Icon
                name={REMOVE_ICON_NAME}
                fill="solid"
                type="fa"
                size={spacing.s3}
            />
        }
        label={label}
        onDelete={onDelete}
        clickable={clickable}
        {...props}
    />
);

Chip.propTypes = {
    classes: PropTypes.object,
    onDelete: PropTypes.func.isRequired,
    clickable: PropTypes.bool,
    label: PropTypes.string
};

Chip.defaultProps = {
    classes: null,
    clickable: false,
    label: null
};

export default WithChipBase(Chip);

const styles = StyleSheet.create(importantStyles({
    root: {
        margin: spacing.s0
    }
}));