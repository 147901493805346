import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import * as actions from '../../../actions';
import { selectors as coreSelectors, translate, ENTITIES } from '../../../../core';
import { getChallenge, getHeaderIcon } from '../../../selectors';
import { selectors as authSelectors } from '../../../../auth';
import {
    getChallengeSubtitle,
    isInGracePeriod,
    isCompletedNotInGracePeriod,
    isFeaturedChallenge
} from '../../../services/helper';

export default function WithChallengeCardHeaderBase(WrappedComponent) {
    class ChallengeCardHeaderBase extends PureComponent {
        static propTypes = {
            challenge: PropTypes.object.isRequired,
            headerIcon: PropTypes.object.isRequired,
            subtitle: PropTypes.string,
            programName: PropTypes.string,
            language: PropTypes.string.isRequired
        };

        static defaultProps = {
            programName: undefined,
            subtitle: null
        };

        get subtitle() {
            return getChallengeSubtitle(this.props.challenge, this.props.programName, false, this.props.language);
        }

        get isChallengeSubtitle() {
            return _.toLower(this.subtitle) === ENTITIES.challenge;
        }

        get instruction() {
            const { challenge } = this.props;
            return _.get(challenge, 'challengeInstruction');
        }

        get isInstructionHidden() {
            const { challenge } = this.props;
            return isInGracePeriod(challenge) || isCompletedNotInGracePeriod(challenge) || !this.instruction;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    subtitle={this.subtitle}
                    instruction={this.instruction}
                    isChallengeSubtitle={this.isChallengeSubtitle}
                    isInstructionHidden={this.isInstructionHidden}
                    isFeaturedChallenge={isFeaturedChallenge(this.props.challenge)}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => ({
        challenge: getChallenge(state, ownProps.challengeId),
        headerIcon: getHeaderIcon(state, ownProps.challengeId),
        programName: authSelectors.getProgramName(state),
        language: coreSelectors.getCurrentUserLanguage(state)
    });

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(ChallengeCardHeaderBase));
}
