import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { baseColors, spacing, appFonts } from '../../../../styles';
import * as actions from '../../actions';
import { isProcessingLike, getAvailableReactions } from '../../selectors';
import { translate, tracker } from '../../../core';
import { getCurrentUserId } from '../../../core/selectors';
import { getReactionsForStreamItem, getReactionsCountForStreamItem, getUniqueReactionsCountForStreamItem, getUserReactionsListForStreamItem } from '../../../feeds/selectors';

export const reactionIcon = require('../../../../image/icon-addReaction.png');
export const commentIcon = require('../../../../image/icon-comment.png');

export default function WithLikesCommentsPanel(WrappedComponent) {
    class LikesCommentsPanelBase extends PureComponent {
        static propTypes = {
            likeCount: PropTypes.number.isRequired,
            commentCount: PropTypes.number.isRequired,
            isLikeActive: PropTypes.bool.isRequired,
            doLike: PropTypes.func,
            doComment: PropTypes.func,
            onLikesClick: PropTypes.func,
            onCommentsClick: PropTypes.func.isRequired,
            actions: PropTypes.object.isRequired,
            likedItemId: PropTypes.number.isRequired,
            likeId: PropTypes.number.isRequired,
            toUserId: PropTypes.number.isRequired,
            entityType: PropTypes.string,
            isLoading: PropTypes.bool,
            i18n: PropTypes.object.isRequired,
            appliedReactions: PropTypes.object,
            reactionsCount: PropTypes.number,
            availableReactions: PropTypes.array,
            streamItemUpdated: PropTypes.string.isRequired,
            reactionsUsersCount: PropTypes.number,
            userReactionsList: PropTypes.array
        };

        static defaultProps = {
            doLike: undefined,
            isLoading: false,
            entityType: undefined,
            onLikesClick: undefined,
            doComment: undefined,
            appliedReactions: undefined,
            reactionsCount: 0,
            availableReactions: [],
            reactionsUsersCount: 0,
            userReactionsList: undefined
        };

        constructor(props) {
            super(props);
        }

        get commentsCountLabel() {
            const { i18n } = this.props;
            return this.props.commentCount === 1 ? i18n.t('commentsCountLabelSingular') : i18n.t('commentsCountLabel');
        }

        get hasReactions() {
            return this.props.reactionsCount;
        }

        get reactionsAvailable() {
            return this.props.availableReactions.length > 0;
        }

        get reactionsCountString() {
            const { i18n, reactionsCount, reactionsUsersCount, userReactionsList } = this.props;

            if (reactionsCount > 0 && userReactionsList.length > 0) {
                const firstUser = userReactionsList[0];
                let userName = '';
                if (firstUser) {
                    const userFirst = _.get(firstUser, 'firstname', '');
                    const userLast = _.get(firstUser, 'lastname', '');
                    userName = `${userFirst} ${userLast}`;
                }

                switch (reactionsUsersCount) {
                    case 0: {
                        return undefined;
                    }
                    case 1: {
                        return userName;
                    }
                    case 2: {
                        return i18n.t('usersReacted.oneOther', { userName });
                    }
                    default: {
                        const count = reactionsUsersCount - 1;
                        if (count <= 0) {
                            return userName;
                        }
                        return i18n.t('usersReacted.multipleOthers', { userName, count });
                    }
                }
            }

            return undefined;
        }

        getReactionImage = slug => _.get(_.find(this.props.availableReactions, reaction => reaction.slug === slug), 'emoji', undefined);

        getReactionId = slug => _.get(_.find(this.props.availableReactions, reaction => reaction.slug === slug), 'id', undefined);

        userHasSelectedReaction = reaction => _.get(reaction, 'userReacted');

        userReactionId = reaction => _.get(reaction, 'userReactionId');

        doLike = () => {
            if (this.props.doLike) {
                this.props.doLike();
            } else {
                this.toggleLike();
            }
        };

        toggleLike = () => {
            const { likedItemId, likeId, toUserId, entityType } = this.props;
            if (this.props.isLikeActive) {
                this.props.actions.deleteLike(likedItemId, likeId, entityType);
            } else {
                tracker.logEvent('like', { id: likedItemId, to: toUserId, entity: entityType });
                this.props.actions.addLike(likedItemId, toUserId, entityType);
            }
        };

        selectReaction = reaction => {
            const { likedItemId, toUserId, likedItemType } = this.props;

            const id = this.getReactionId(reaction.slug);

            if (id) {
                this.props.actions.addReaction(likedItemId, toUserId, likedItemType, id, reaction);
            }
        }

        removeReaction = reaction => {
            const { likedItemId } = this.props;

            const reactionId = this.userReactionId(reaction);
            if (reactionId) {
                this.props.actions.removeReaction(reactionId, likedItemId, reaction);
            }
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    doLike={this.doLike}
                    commentsCountLabel={this.commentsCountLabel}
                    hasReactions={this.hasReactions}
                    getReactionImage={this.getReactionImage}
                    selectReaction={this.selectReaction}
                    removeReaction={this.removeReaction}
                    userHasSelectedReaction={this.userHasSelectedReaction}
                    reactionsCountString={this.reactionsCountString}
                    reactionsAvailable={this.reactionsAvailable}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        return {
            isLoading: isProcessingLike(state, ownProps.likedItemId),
            appliedReactions: getReactionsForStreamItem(state, ownProps.likedItemId),
            reactionsCount: getReactionsCountForStreamItem(state, ownProps.likedItemId),
            reactionsUsersCount: getUniqueReactionsCountForStreamItem(state, ownProps.likedItemId),
            userReactionsList: getUserReactionsListForStreamItem(state, ownProps.likedItemId),
            availableReactions: getAvailableReactions(state),
            currentUserId: getCurrentUserId(state),
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(LikesCommentsPanelBase));
}

export const styles = {
    countPanelText: {
        ...appFonts.mdRegular,
        fontWeight: '200',
        color: baseColors.black
    },
    mainContainer: {
        borderTopColor: baseColors.grey80,
        borderTopWidth: 1,
    },
    actionPanel: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        flex: 1,
        paddingTop: spacing.s2,
        paddingBottom: spacing.s0,
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3,
    },
    actionPanelIcon: {
        width: spacing.s5,
        height: spacing.s5,
    },
    iconArea: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
    iconButton: {
        borderRadius: spacing.s7,
        paddingLeft: spacing.s2,
        paddingRight: spacing.s2,
        height: spacing.s7,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        marginBottom: spacing.s1,
    },
    appliedReactionButton: {
        backgroundColor: baseColors.grey85,
        paddingLeft: spacing.s1,
        paddingRight: spacing.s1,
        marginRight: spacing.s1
    },
    userAppliedReactionButton: {
        backgroundColor: baseColors.contextLightest,
        borderColor: baseColors.context,
        borderWidth: 1,
        borderStyle: 'solid',
    },
    halfWidth: {
        width: '50%',
    },
    wideWidth: {
        width: '80%',
    },
    smallWidth: {
        width: '20%',
    },
    emojiImage: {
        width: spacing.s4,
        height: spacing.s4,
        borderRadius: 0
    },
    countText: {
        marginLeft: spacing.s0,
        ...appFonts.smRegular
    },
    wrapping: {
        marginRight: spacing.s3,
        flexWrap: 'wrap',
    }
};