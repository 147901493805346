import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import { DATE_FORMATS, parsers, Platform, PLATFORMS, selectors as coreSelectors, translate } from '../../../core';
import { appFonts, baseColors, spacing } from '../../../../styles';
import * as selectors from '../../selectors';
import * as actions from '../../actions';

export const INITIAL_MONTH_INDEX = 3;
const MAX_DAYS_ALLOWED = 7;

export default function withTrackActivityDatepickerBase(WrappedComponent) {
    class TrackActivityDatepickerBase extends PureComponent {
        static propTypes = {
            onDatepickerSubmit: PropTypes.func.isRequired,
            checkedOffDates: PropTypes.array.isRequired,
            actions: PropTypes.object.isRequired,
            i18n: PropTypes.object.isRequired,
        };

        constructor(props) {
            super(props);
            this.state = {
                activeMonthIndex: INITIAL_MONTH_INDEX,
                selectedDates: props.checkedOffDates,
            };

            const startDate = moment().subtract(this.calendarData.length - 1, 'month').startOf('month').format(DATE_FORMATS.full);
            const endDate = moment().format(DATE_FORMATS.full);
            if (Platform.OS !== PLATFORMS.web) {
                // The modal is appearing with a delay without adding setTimeout on mobile
                setTimeout(() => this.props.actions.getActivityLogsExtended(startDate, endDate));
            } else {
                this.props.actions.getActivityLogsExtended(startDate, endDate);
            }
        }

        onSwitchMonths = index => this.setState({ activeMonthIndex: index });

        onDatePress = date => this.setState(prevState => {
            const isDateSelected = _.some(prevState.selectedDates, selectedDate => selectedDate.isSame(date, 'day'));
            if (this.areMaxDaysSelected && !isDateSelected) return;
            return {
                selectedDates: isDateSelected ?
                    _.filter(prevState.selectedDates, selectedDate => !selectedDate.isSame(date, 'day'))
                    : [...prevState.selectedDates, moment(date)]
            };
        });

        onSubmit = () => {
            const { onDatepickerSubmit } = this.props;
            onDatepickerSubmit(this.state.selectedDates);
            this.dismissModal();
        };

        saveRef = ref => (this.wrapped = ref);

        dismissModal = () =>   _.has(this, 'wrapped.dismissModal') && this.wrapped.dismissModal();

        get isLeftArrowHidden() {
            return this.state.activeMonthIndex === 0;
        }

        get isRightArrowHidden() {
            return this.state.activeMonthIndex === INITIAL_MONTH_INDEX;
        }

        get datepickerTitle() {
            return this.props.i18n.t('selectDates');
        }

        get calendarData() {
            return [
                { id: 1, month: moment().subtract(3, 'month').startOf('month').format(DATE_FORMATS.full) },
                { id: 2, month: moment().subtract(2, 'month').startOf('month').format(DATE_FORMATS.full) },
                { id: 3, month: moment().subtract(1, 'month').startOf('month').format(DATE_FORMATS.full) },
                { id: 4, month: moment().startOf('month').format(DATE_FORMATS.full) }
            ];
        }

        get footerBtnText() {
            return this.props.i18n.t('ok');
        }

        get selectedText() {
            return `${this.state.selectedDates.length} ${this.props.i18n.t('selected')}`;
        }

        get areMaxDaysSelected() {
            return this.state.selectedDates.length === MAX_DAYS_ALLOWED;
        }

        get limitReachedText() {
            return this.props.i18n.t('trackActivity.limitMessage');
        }

        get activityTrackedText() {
            return this.props.i18n.t('activityTracked');
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    activityTrackedText={this.activityTrackedText}
                    isRightArrowHidden={this.isRightArrowHidden}
                    areMaxDaysSelected={this.areMaxDaysSelected}
                    isLeftArrowHidden={this.isLeftArrowHidden}
                    limitReachedText={this.limitReachedText}
                    selectedDates={this.state.selectedDates}
                    datepickerTitle={this.datepickerTitle}
                    onSwitchMonths={this.onSwitchMonths}
                    footerBtnText={this.footerBtnText}
                    selectedText={this.selectedText}
                    calendarData={this.calendarData}
                    onDatePress={this.onDatePress}
                    onSubmit={this.onSubmit}
                    ref={this.saveRef}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const routeParams = _.get(ownProps, 'route.params');

        return {
            language: parsers.convertLangString(coreSelectors.getCurrentUserLanguage(state)),
            activityLogs: selectors.getPreparedActivityLogsForAllUnits(state),
            ...(routeParams || {})
        };
    };

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(TrackActivityDatepickerBase));
}

const LIMIT_CONTAINER_HEIGHT = 42;
const LIMIT_CONTAINER_TOP_MARGIN = 12;
export const SLIDING_DISTANCE = LIMIT_CONTAINER_HEIGHT + LIMIT_CONTAINER_TOP_MARGIN;

export const styles = {
    arrows: {
        position: 'absolute',
        zIndex: 1,
        right: 0,
        left: 0,
        top: 0,
    },
    activityTrackedLegend: {
        ...appFonts.smRegular,
        color: baseColors.grey40,
        marginRight: spacing.s4,
        marginLeft: 'auto',
    },
    selectedText: {
        ...appFonts.mdRegular,
        color: baseColors.grey20,
        textAlign: 'center',
    },
    limitReachedText: {
        ...appFonts.smRegular,
        marginTop: LIMIT_CONTAINER_TOP_MARGIN,
        backgroundColor: baseColors.grey80,
        height: LIMIT_CONTAINER_HEIGHT,
        borderRadius: spacing.s1,
        color: baseColors.grey20,
        padding: spacing.s2,
        width: '100%',
    },
};
