import _ from 'lodash';

export default function decodeURI(path) {
    const result = {};
    _.forEach(_.split(path, '&'), part => {
        const item = part.split('=');
        result[item[0]] = decodeURIComponent(item[1]);
    });
    return result;
}
