import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
    communitiesLoading,
    getMyCommunities,
    getInvitedCommunities,
    getRecentCommunities,
    getAllCommunities,
    numAttended,
    numAll,
    numInvites,
    numRecommended,
    numRecent,
    getInvitedCommunitiesIds
} from '../../selectors';
import * as actions from '../../actions';
import { MAX_COUNT, TYPES } from '../../constants';
import { translate, SEARCH_INPUT_DELAY, Storage, Platform, PLATFORMS, ENTITIES_ACTIVE_TAB } from '../../../core';
import { appFonts, spacing, baseColors } from '../../../../styles';

export default function WithCommunitiesBase(WrappedComponent) {
    class CommunitiesBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            activeTab: PropTypes.string,
            numAttended: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            numAll: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            numInvites: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            numRecent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            isLoading: PropTypes.bool,
            actions: PropTypes.object.isRequired,
            invitedCommunities: PropTypes.array,
            recentCommunities: PropTypes.array,
            myCommunities: PropTypes.array,
            allCommunities: PropTypes.array,
            communityCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        };

        static defaultProps = {
            activeTab: undefined,
            numAttended: undefined,
            numAll: undefined,
            numInvites: undefined,
            numRecent: undefined,
            isLoading: false,
            invitedCommunities: undefined,
            recentCommunities: undefined,
            myCommunities: undefined,
            allCommunities: undefined,
            communityCount: undefined
        };

        constructor(props) {
            super(props);
            this.TABS = {
                MY: { id: 'my', label: this.props.i18n.t('myCommunities') },
                ALL: { id: 'all', label: this.props.i18n.t('all') }
            };

            this.tabs = [
                this.TABS.MY,
                this.TABS.ALL
            ];
            const activeTab = props.activeTab ? _.find(this.tabs, { id: props.activeTab }, this.TABS.ALL) : this.TABS.ALL;
            const isMyTab = !(Platform.OS === PLATFORMS.web) ? _.get(props.route.params, 'myTab') : null;
            this.state = {
                activeTab: isMyTab ? this.TABS.MY : activeTab,
                search: '',
                debouncingSearch: false,
                tabsSearchValue: {},
                isFocused: false
            };
            this._doSearch = _.debounce(this._doSearch.bind(this), SEARCH_INPUT_DELAY);
            this.props.actions.getCommunities({ filter: TYPES.ATTENDED, limit: MAX_COUNT, start: 0 }, true);
            this.props.actions.getCommunities({ filter: TYPES.ALL, limit: MAX_COUNT, start: 0 }, true);
            this.props.actions.getRecentCommunities();
            this.props.actions.getCommunities({ filter: TYPES.INVITATIONS, limit: MAX_COUNT, start: 0 }, true);
        }

        async componentDidMount() {
            if (Platform.OS === PLATFORMS.web) {
                const activeTab = await Storage.getItem(ENTITIES_ACTIVE_TAB.EXPLORE_COMMUNITIES);
                if (activeTab) {
                    this.setState({ activeTab });
                }
            }
        }

        handleChange = value => {
            const prevValue = this.state.tabsSearchValue[value.id];
            this.setState(() => ({ activeTab: value, search: '', tabsSearchValue: { ...this.state.tabsSearchValue, [value.id]: '' } }), () => {
                if (prevValue) {
                    this.props.actions.getCommunities({ filter: this.communityFilter, limit: MAX_COUNT, start: 0, search: this.state.search }, true);
                }
            });

            if (Platform.OS === PLATFORMS.web) {
                Storage.setItem(ENTITIES_ACTIVE_TAB.EXPLORE_COMMUNITIES, value);
            }
        };

        get allTabId() {
            return this.TABS.ALL.id;
        }

        get myTabId() {
            return this.TABS.MY.id;
        }

        onSearch = value => {
            this.setState({ search: value, debouncingSearch: true });
            this._doSearch(value);
        };

        _doSearch = text => {
            this.props.actions.getCommunities({ filter: this.communityFilter, limit: MAX_COUNT, start: 0, search: text }, true);
            this.setState({ debouncingSearch: false, tabsSearchValue: { ...this.state.tabsSearchValue, [this.state.activeTab.id]: text } });
        };

        onClearSearch = () => {
            this.onSearch('');
        };

        get isDisplayingCarousels() {
            return !this.state.tabsSearchValue[this.state.activeTab.id] && (!this.state.search || this.state.debouncingSearch);
        }

        get communityCount() {
            if (this.state.activeTab.id === this.myTabId) {
                return this.props.numAttended;
            } else if (this.state.activeTab.id === this.allTabId) {
                return this.props.numAll;
            }
            return '';
        }

        get communityItemsToRender() {
            if (this.state.activeTab.id === this.myTabId) {
                return this.props.myCommunities;
            } else if (this.state.activeTab.id === this.allTabId) {
                return this.props.allCommunities;
            }
            return [];
        }

        get communityFilter() {
            if (this.isMyTab) {
                return TYPES.ATTENDED;
            } else if (this.isAllTab) {
                return TYPES.ALL;
            }
            return '';
        }

        loadMoreContent = () => {
            if (!this.props.isLoading && this.communityItemsToRender.length < this.communityCount) {
                this.props.actions.getCommunities({ filter: this.communityFilter, limit: MAX_COUNT, start: this.communityItemsToRender.length, search: this.state.search }, false);
            }
        };

        get isAllTab() {
            return this.state.activeTab.id === this.allTabId;
        }

        get isMyTab() {
            return this.state.activeTab.id === this.myTabId;
        }

        join = (item, isInvitation) => {
            this.props.actions.joinCommunity(item.id, isInvitation, undefined, this.props.i18n.t('joiningCommunityToast.success.message', { name: item.name }));
        };

        get communitiesTitle() {
            return this.props.i18n.t('communities');
        }

        get searchCommunitiesPlaceholder() {
            return this.props.i18n.t('search');
        }

        get myCommunitiesEmpty() {
            return this.props.i18n.t('myCommunitiesEmpty');
        }

        get searchCommunitiesEmpty() {
            return this.props.i18n.t('searchCommunitiesEmpty');
        }

        get isMyCommunitiesEmptyMessageShown() {
            const { communityItemsToRender, debouncingSearch, isMyTab, props: { isLoading }, state: { search } } = this;
            return isMyTab && communityItemsToRender.length === 0 && !search && !debouncingSearch && !isLoading;
        }

        get isCommunitiesEmptyMessageShown() {
            const { communityItemsToRender, debouncingSearch, props: { isLoading }, state: { search } } = this;
            return search && !isLoading && !debouncingSearch && !communityItemsToRender.length;
        }

        get invitedCommunities() {
            return this.props.i18n.t('invitedCommunities');
        }

        get recent() { return this.props.i18n.t('recent'); }

        get allCommunitiesHeading() { return this.props.i18n.t('allCommunities'); }
        get myCommunitiesHeading() { return this.props.i18n.t('myCommunities'); }
        get searchLoaderText() { return this.props.i18n.t('searching'); }

        focusCallback = () => this.setState({ isFocused: true });

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    communityItemsToRender={this.communityItemsToRender}
                    handleChange={this.handleChange}
                    tabs={this.tabs}
                    onSearch={this.onSearch}
                    search={this.state.search}
                    activeTab={this.state.activeTab}
                    isDisplayingCarousels={this.isDisplayingCarousels}
                    communityCount={this.communityCount}
                    communityFilter={this.communityFilter}
                    isAllTab={this.isAllTab}
                    isMyTab={this.isMyTab}
                    loadMoreContent={this.loadMoreContent}
                    join={this.join}
                    onClearSearch={this.onClearSearch}
                    communitiesTitle={this.communitiesTitle}
                    searchCommunitiesPlaceholder={this.searchCommunitiesPlaceholder}
                    myCommunitiesEmpty={this.myCommunitiesEmpty}
                    searchCommunitiesEmpty={this.searchCommunitiesEmpty}
                    isMyCommunitiesEmptyMessageShown={this.isMyCommunitiesEmptyMessageShown}
                    isCommunitiesEmptyMessageShown={this.isCommunitiesEmptyMessageShown}
                    invitedCommunities={this.invitedCommunities}
                    recent={this.recent}
                    allCommunitiesHeading={this.allCommunitiesHeading}
                    myCommunitiesHeading={this.myCommunitiesHeading}
                    searchLoaderText={this.searchLoaderText}
                    focusCallback={this.focusCallback}
                    isFocused={this.state.isFocused}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        const activeTab = _.get(ownProps, 'activeTab') || _.get(ownProps, 'location.state.activeTab');
        const routeParams = _.get(ownProps, 'route.params');
        return {
            activeTab,
            allCommunities: getAllCommunities(state),
            myCommunities: getMyCommunities(state),
            isLoading: communitiesLoading(state),
            numAttended: numAttended(state),
            numAll: numAll(state),
            numInvites: numInvites(state),
            numRecommended: numRecommended(state),
            numRecent: numRecent(state),
            recentCommunitiesCarousel: getRecentCommunities(state, true),
            invitedCommunitiesCarousel: getInvitedCommunities(state, true),
            invitedCommunitiesIds: getInvitedCommunitiesIds(state),
            ...(routeParams || {})
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        };
    }
    return connect(mapStateToProps, mapDispatchToProps)(translate()(CommunitiesBase));
}
export const styles = {
    mainContainer: {
        flex: 1,
        backgroundColor: baseColors.white
    },
    header: {
        paddingLeft: 0,
        marginTop: spacing.s1
    },
    subHeader: {
        ...appFonts.xlBold,
        marginLeft: spacing.s3,
        marginRight: spacing.s3
    },
    emptyCommunitiesText: {
        marginLeft: spacing.s3,
        marginRight: spacing.s3
    },
    searchContainer: {
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3,
    },
    emptyContainer: {
        marginBottom: spacing.s7
    },
    indicator: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: spacing.s3,
        marginBottom: spacing.s3
    },
    indicatorText: {
        ...appFonts.smMedium,
        marginLeft: spacing.s1,
        color: baseColors.secondary
    },
    recentCarousel: {
        marginBottom: spacing.s3
    },
    tabs: {
        marginBottom: spacing.s5
    },
    invitationTitle: {
        marginTop: 0
    }
};
