import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate, selectors as coreSelectors } from '../../../../core';
import * as constants from '../../../constants';
import { actions as authActions, selectors as authSelectors } from '../../../../auth';
import { appFonts, spacing } from '../../../../../styles';

export default function WithModeratorOptionsBase(WrappedComponent) {
    class ModeratorOptionsBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            locations: PropTypes.array,
            locationsList: PropTypes.array,
            type: PropTypes.string,
            points: PropTypes.string,
            cost: PropTypes.string,
            department: PropTypes.string,
            maxParticipants: PropTypes.string,
            departments: PropTypes.array,
            companyId: PropTypes.number.isRequired,
            i18n: PropTypes.object.isRequired,
            customPointsName: PropTypes.string.isRequired
        };

        static defaultProps = {
            locations: [],
            locationsList: [],
            departments: [],
            maxParticipants: null,
            type: null,
            points: null,
            cost: null,
            department: null
        };

        constructor(props) {
            super(props);
            props.actions.getUserLocations(props.companyId);
            this.EVENT_TYPES = [
                { id: constants.EVENT_TYPES.company, label: props.i18n.t('createEvent.type.company') },
                { id: constants.EVENT_TYPES.user, label: props.i18n.t('createEvent.type.user') }
            ];
        }

        get pointsLabel() {
            return _.capitalize(this.props.customPointsName);
        }

        get readOnlyLocations() {
            return _.join(_.map(this.props.locations, l => l.label), ', ');
        }

        get readOnlyDepartment() {
            return _.get(_.find(this.props.departments, d => d.id === this.props.department), 'label');
        }

        get associatedLocations() {
            return ([
                { id: constants.ALL_ID, label: this.props.i18n.t('all') },
                { id: constants.SELECTED_ID, label: this.props.i18n.t('selected') }
            ]);
        }

        get associatedDepartments() {
            return ([
                { id: constants.ALL_ID, label: this.props.i18n.t('all') },
                ...this.props.departments
            ]);
        }

        get locationsList() {
            return _.map(this.props.locationsList, l => ({
                id: l.location_id,
                label: l.location_name
            }));
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    eventTypes={this.EVENT_TYPES}
                    pointsLabel={this.pointsLabel}
                    readOnlyLocations={this.readOnlyLocations}
                    readOnlyDepartment={this.readOnlyDepartment}
                    locationsList={this.locationsList}
                    associatedDepartments={this.associatedDepartments}
                    associatedLocations={this.associatedLocations}
                />
            );
        }
    }

    function mapStateToProps(state) {
        return {
            companyId: coreSelectors.getCurrentUserCompanyId(state),
            departments: authSelectors.getDepartmentsForSelect(state),
            locationsList: authSelectors.getAssociatedLocations(state),
            customPointsName: coreSelectors.getCustomPointsName(state)
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(authActions, dispatch),
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(ModeratorOptionsBase));
}

export const styles = {
    readOnly: {
        ...appFonts.mdRegular
    },
    input: {
        paddingBottom: spacing.s3
    },
    title: {
        ...appFonts.lgMedium,
        marginBottom: spacing.s0,
        width: '100%'
    },
    selectField: {
        marginBottom: spacing.s5
    }
};

