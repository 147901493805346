import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import classNames from 'classnames';
import { spacing, appFonts, layoutStyle, baseColors } from '../../../../styles';
import WithLegalsBase from './LegalsBase';
import { components as Core, CSS_CLASSES, onKeyPress } from '../../../core';

const _fontSize = appFonts.mdRegular;
class Legals extends Component {
    static propTypes = {
        disclaimerText: PropTypes.string,
        isLoading: PropTypes.bool,
        title: PropTypes.string,
        close: PropTypes.func.isRequired,
        i18n: PropTypes.object.isRequired,
    };

    static defaultProps = {
        isLoading: false,
        disclaimerText: null,
        title: null
    };

    createMarkup = () => ({ __html: `<div style="font-size: ${_fontSize}px; font-family: “Helvetica Now Text SA", "Helvetica Neue", Helvetica, Arial, sans-serif;">${this.props.disclaimerText}</div>` });

    render() {
        const { title } = this.props;
        return (
            <Core.Layout.FHFlex>
                <Core.SubHeader
                    title={title}
                    noTopPadding={true}
                    hasBackButton={false}
                    titleAlign="center"
                    noLeftPadding={true}
                />
                {/* eslint-disable-next-line react/no-danger */}
                <div dangerouslySetInnerHTML={this.createMarkup()} className={classNames(CSS_CLASSES.modalBodyWithScrollbar, css(styles.legalText))} />
                <div
                    tabIndex="0"
                    role="button"
                    onClick={this.props.close}
                    onKeyDown={onKeyPress.enter(this.props.close)}
                    className={css(layoutStyle.fw, layoutStyle.flexRowCenter, styles.closeButton)}>
                    <p className={css(styles.closeButtonText)}>{this.props.i18n.t('close')}</p>
                </div>
                {this.props.isLoading ? <Core.ListLoading key="Loading" /> : null}
            </Core.Layout.FHFlex>
        );
    }
}

const styles = StyleSheet.create({
    legalText: {
        padding: spacing.s3
    },
    closeButton: {
        borderColor: baseColors.grey70,
        borderTopWidth: 1,
        borderTopStyle: 'solid',
        minHeight: spacing.s10,
    },
    closeButtonText: {
        textAlign: 'center',
        color: baseColors.secondary
    }
});


export default WithLegalsBase(Legals);
