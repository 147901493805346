import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { appFonts, spacing, baseColors } from '../../../../styles';
import { translate } from '../../services';
import { Platform } from '../../../core';
import { PLATFORMS } from '../../constants';

export default function WithSelectModalBase(WrappedComponent) {
    class SelectModalBase extends PureComponent {
        static propTypes = {
            title: PropTypes.string,
            subtitle: PropTypes.string,
            options: PropTypes.array,
            onPressCallback: PropTypes.func,
            singleSelect: PropTypes.bool,
            open: PropTypes.bool,
            onClose: PropTypes.func,
            navigation: PropTypes.object.isRequired,
            isDoneUsed: PropTypes.bool,
            hasNestedModal: PropTypes.bool,
            backButtonVisible: PropTypes.bool,
            selected: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
            isIconBack: PropTypes.bool,
            i18n: PropTypes.object.isRequired
        };

        static defaultProps = {
            isIconBack: false,
            title: undefined,
            subtitle: undefined,
            options: [],
            onPressCallback: null,
            singleSelect: false,
            open: null,
            onClose: null,
            isDoneUsed: false,
            hasNestedModal: false,
            backButtonVisible: false,
            selected: {}
        };

        state = this.hasCallback ? { selected: this.props.selected } : {};

        onPressCallback = selected => this.setState({ selected },
            () => this.props.hasNestedModal ? this.props.onPressCallback(selected) : null);

        onChange = selected => this.hasCallback ? this.onPressCallback(selected) : this.props.onPressCallback(selected);

        get hasCallback() { return this.props.isDoneUsed || this.props.hasNestedModal; }

        get selected() { return this.hasCallback ? this.state.selected : this.props.selected; }

        get isWeb() { return Platform.OS === PLATFORMS.web; }

        onDone = () => {
            if (this.props.onClose) this.props.onClose();
            if (!this.isWeb) this.props.navigation.goBack();
            this.props.onPressCallback(this.state.selected);
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    selected={this.selected}
                    onChange={this.onChange}
                    onDone={this.onDone}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const routeParams = _.get(ownProps, 'route.params');
        return { ...(routeParams || {}) };
    };

    return connect(mapStateToProps)(translate()(SelectModalBase));
}

export const styles = {
    mainContainer: {
        flex: 1,
        backgroundColor: baseColors.white
    },
    innerContainer: {
        paddingTop: spacing.s3
    },
    title: {
        marginLeft: 0,
        paddingHorizontal: spacing.s3,
        marginBottom: spacing.s1

    },
    subtitle: {
        ...appFonts.mdRegular,
        margin: spacing.s3,
        marginTop: 0,
    }
};
