import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { baseColors, spacing, appFonts } from '../../../../styles';
import { translate } from '../../../core';
import * as selectors from '../../selectors';

export default function WithResourceLinksOnFeedsBase(WrappedComponent) {
    class ResourceLinksOnFeedsBase extends PureComponent {
        static propTypes = {
            items: PropTypes.array,
            i18n: PropTypes.object.isRequired,
            resourcesCount: PropTypes.number,
            isLoading: PropTypes.bool
        };

        static defaultProps = {
            items: [],
            resourcesCount: 0,
            isLoading: false
        };

        get title() {
            return this.props.i18n.t('quickLinkTitle');
        }

        isLastItemInList = (item) => {
            return _.indexOf(this.props.items, item) === (this.props.items.length - 1);
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    title={this.title}
                    isLastItemInList={this.isLastItemInList}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        return {
            items: selectors.getDashboardResourceLinks(state),
            resourcesCount: selectors.getResourceLinksCount(state),
            isLoading: selectors.isLoadingResourceLinks(state)
        };
    }

    return connect(mapStateToProps)(translate()(ResourceLinksOnFeedsBase));
}

export const styles = {
    listContainer: {
        paddingBottom: spacing.s1
    },
    title: {
        ...appFonts.xlBold,
        color: baseColors.black,
    },
    buttonContainer: {
        marginLeft: spacing.s3,
        marginRight: spacing.s3,
        paddingTop: spacing.s2
    },
    buttonText: {
        ...appFonts.mdRegular,
        paddingVertical: spacing.s3,
        color: baseColors.secondary,
        textAlign: 'center',
    },
    listSeparator: {
        height: 1,
        backgroundColor: baseColors.grey80,
        marginTop: spacing.s5,
    },
};