import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { bindActionCreators } from 'redux';

import * as selectors from '../../selectors';
import { MAX_PERSONAL_GOALS } from '../../constants';
import * as actions from '../../actions';
import { formatGoalForSetting } from '../../services/helper';
import { spacing, appFonts, baseColors } from '../../../../styles';
import { selectors as onboardingSelectors } from '../../../onboarding';
import { Alert, translate, timeout, selectors as coreSelectors, tracker, Storage, Platform, PLATFORMS, ENTITIES_ACTIVE_TAB } from '../../../core';

export default function WithPersonalGoalsBase(WrappedComponent) {
    class PersonalGoalsBase extends PureComponent {
        static propTypes = {
            goals: PropTypes.array,
            i18n: PropTypes.object.isRequired,
            goalTabs: PropTypes.array,
            onRef: PropTypes.func,
            actions: PropTypes.object.isRequired,
            userId: PropTypes.number.isRequired,
            isSettingRecommendedGoals: PropTypes.bool,
            isLiveBetter: PropTypes.bool,
            setGoalsAdditionals: PropTypes.object,
            isLoading: PropTypes.bool,
            navigation: PropTypes.object,
            isLoadingTabs: PropTypes.bool
        };

        static defaultProps = {
            goals: undefined,
            goalTabs: [],
            onRef: null,
            isSettingRecommendedGoals: false,
            isLiveBetter: false,
            setGoalsAdditionals: {},
            isLoading: false,
            navigation: {},
            isLoadingTabs: false
        };

        constructor(props) {
            super(props);

            this.TOP_TAB = {
                label: this.props.i18n.t('recommended'),
                id: 'top'
            };

            this.state = { selectedTab: this.tabs[0] };
            if (this.props.isLiveBetter) {
                this.props.actions.getChallengeRecommendations();
            } else {
                this.props.actions.getRecommendedGoals();
            }
        }

        async componentDidMount() {
            if (this.props.onRef) this.props.onRef(this);

            if (Platform.OS === PLATFORMS.web) {
                const selectedTab = await Storage.getItem(ENTITIES_ACTIVE_TAB.PERSONAL_GOALS);
                if (selectedTab) {
                    this.setState({ selectedTab });
                }
            }
        }

        componentWillUnmount() {
            if (this.props.onRef) this.props.onRef(null);
        }

        get tabs() {
            return [this.TOP_TAB, ...this.props.goalTabs];
        }

        tabSelected = tab => {
            tracker.setCurrentScreen(`PersonalGoals.${tab.id}`, this.props.navigation);
            this.setState({ selectedTab: tab });

            if (Platform.OS === PLATFORMS.web) {
                Storage.setItem(ENTITIES_ACTIVE_TAB.PERSONAL_GOALS, tab);
            }
        };

        showAlert = () => {
            Alert.alert(this.props.i18n.t('personalGoal.all_goals.errorHeading'), this.props.i18n.t('personalGoal.all_goals.error'));
        };

        get hasNoGoalsSet() {
            return !this.props.goals.length;
        }

        onSetPersonalGoal = (goal, isSet, challengeId) => {
            const { actions, userId } = this.props;
            !isSet ? actions.setGoal(formatGoalForSetting(goal, userId), true, this.hasNoGoalsSet)
                : actions.deleteChallenge(challengeId);
        };

        get hasAllGoalsSet() {
            return this.props.goals.length >= MAX_PERSONAL_GOALS;
        }

        get isDisabledSetting() {
            return this.hasAllGoalsSet || (this.props.goals.length >= MAX_PERSONAL_GOALS - 1 && this.props.isSettingRecommendedGoals);
        }

        get isTopTab() {
            return this.state.selectedTab.id === this.TOP_TAB.id || !_.includes(this.tabs, this.state.selectedTab);
        }

        get hasUnsetGoals() {
            return !this.isDisabledSetting || (this.isDisabledSetting && this.props.isSettingRecommendedGoals);
        }

        getLearnModalProps = () => ({
            iconName: 'bullseye-arrow',
            title: this.props.i18n.t('wellbeing.goalSetting'),
            text: this.props.i18n.t('settingGoals.description'),
            buttonTitle: this.props.i18n.t('got_it'),
            isButtonVisible: false,
        });

        get selectedTab() {
            return _.includes(this.tabs, this.state.selectedTab) ? this.state.selectedTab : this.TOP_TAB;
        }

        _openAlert = (title, text, okHandler) => {
            const { i18n } = this.props;
            Alert.alert(title, text, [
                { text: i18n.t('ok'), onPress: okHandler, isPrimary: true },
                { text: i18n.t('button_cancel'), style: 'cancel' },
            ]);
        };

        get earnPointsTextStart() {
            const { i18n } = this.props;
            return i18n.t('setGoals.earnPointsText.start');
        }

        get earnPointsTextEnd() {
            const { i18n } = this.props;
            return i18n.t('setGoals.earnPointsText.end');
        }

        get earnPointsTextCenter() {
            const { setGoalsAdditionals: { externalRewardName, externalRewardPoints } } = this.props;
            return ` ${externalRewardPoints} ${externalRewardName} `;
        }

        get learnMoreText() {
            return this.props.i18n.t('goalSettings.learnMore');
        }

        get isEarnPointsTextShown() {
            const { setGoalsAdditionals: { isEarned, externalRewardName, externalRewardPoints }, isLiveBetter, goals, isLoading } = this.props;
            return (externalRewardName && externalRewardPoints && !isEarned && !goals.length && !isLoading) && isLiveBetter;
        }

        get headerTitle() {
            return this.props.i18n.t('goalGallery');
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    hasAllGoalsSet={this.hasAllGoalsSet}
                    selectedTab={this.selectedTab}
                    hasUnsetGoals={this.hasUnsetGoals}
                    getLearnModalProps={this.getLearnModalProps}
                    isTopTab={this.isTopTab}
                    onSetPersonalGoal={this.onSetPersonalGoal}
                    tabSelected={this.tabSelected}
                    showAlert={this.showAlert}
                    tabs={this.tabs}
                    isDisabledSetting={this.isDisabledSetting}
                    earnPointsTextStart={this.earnPointsTextStart}
                    earnPointsTextEnd={this.earnPointsTextEnd}
                    earnPointsTextCenter={this.earnPointsTextCenter}
                    learnMoreText={this.learnMoreText}
                    isEarnPointsTextShown={this.isEarnPointsTextShown}
                    headerTitle={this.headerTitle}
                />
            );
        }
    }

    function mapStateToProps(state) {
        return {
            goalRecommendations: selectors.getRecommendedGoalsCarousel(state),
            goals: selectors.getMyPersonalGoals(state),
            goalTabs: selectors.getRecommendedGoalsTabs(state),
            isSettingRecommendedGoals: selectors.isSettingPersonalGoals(state),
            userId: coreSelectors.getCurrentUserId(state),
            isLiveBetter: coreSelectors.isLiveBetter(state),
            setGoalsAdditionals: onboardingSelectors.getSetGoalsAdditionals(state),
            isLoading: selectors.isLoadingChallenges(state),
            isLoadingTabs: selectors.isLoadingRecommendedGoals(state) || selectors.isLoadingRecommendedChallenges(state)
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(timeout(PersonalGoalsBase)));
}

const GOALS_INFO_MARGIN_BOTTOM = 26;

export const styles = {
    goalsInfo: {
        marginTop: spacing.s3,
        marginBottom: GOALS_INFO_MARGIN_BOTTOM,
    },
    recommendationCarouselContainer: {
        flex: 1,
        marginTop: spacing.s5,
        marginBottom: spacing.s5,
    },
    learnMoreGoalSetting: {
        ...appFonts.mdMedium,
        color: baseColors.secondary,
        textAlign: 'center'
    },
    setCarouselContainer: {
        marginTop: -spacing.s1
    },
    earnPointsText: {
        ...appFonts.mdRegular,
        marginBottom: spacing.s5
    },
    points: {
        ...appFonts.mdBold
    },
    headerTitle: {
        ...appFonts.xlBold,
        marginBottom: spacing.s7
    }
};
