import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import * as actions from '../../../actions';
import {
    getCurrentUserPrivacySettingsElement,
    isLoadingCurrentUserPrivacySettings
} from '../../../selectors';
import { appFonts, baseColors, spacing } from '../../../../../styles';
import { translate, selectors as coreSelectors } from '../../../../core';

export const EXCLAMATION_ICON = 'exclamation-circle';

export default function WithPrivacySettingsItemBase(WrappedComponent) {
    class PrivacySettingsItemBase extends PureComponent {
        static propTypes = {
            commentedItemType: PropTypes.string,
            commentedItemId: PropTypes.number,
            actions: PropTypes.object.isRequired,
            settings: PropTypes.array,
            privacySettingsElement: PropTypes.object.isRequired,
            isLoading: PropTypes.bool,
            i18n: PropTypes.object.isRequired,
            slug: PropTypes.string,
            translationTitle: PropTypes.string,
            userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
        };

        static defaultProps = {
            isLoading: false,
            translationTitle: null,
            commentedItemType: null,
            commentedItemId: null,
            settings: null,
            slug: null,
        };

        constructor(props) {
            super(props);

            this.state = {
                changedValuesMap: {
                    privacySetting: {}
                }
            };
        }

        getValue = item => {
            const { slug } = item;

            if (_.has(this.state.changedValuesMap.privacySetting, slug)) {
                return this.state.changedValuesMap.privacySetting[slug];
            }

            return this._getOriginValueById(item);
        };

        _getOriginValueById(item) {
            return item.disabled;
        }

        updateTheValue = async (userId, slug, privacyTypeId, value) => {
            this.setState(prevState => ({
                changedValuesMap: {
                    ...prevState.changedValuesMap,
                    privacySetting: {
                        ...prevState.changedValuesMap.privacySetting,
                        [slug]: !value
                    }
                }
            }));

            this.props.actions.setCurrentUserPrivacySetting(userId, slug, privacyTypeId, !value);
        };

        getInfoModalProps = (okHandler, cancelHandler) => {
            const { i18n, privacySettingsElement: { description, descriptionOff, value } } = this.props;
            const descriptionToShow = value ? description : descriptionOff;

            return {
                iconName: EXCLAMATION_ICON,
                title: i18n.t('privacyChangeConfirmationMessage'),
                text: `${descriptionToShow || ' '}`,
                isButtonVisible: false,
                buttons: [
                    { text: i18n.t('ok'), onPress: () => okHandler(), isBoldText: true },
                    { text: i18n.t('button_cancel'), onPress: cancelHandler ? () => cancelHandler() : null }
                ]
            };
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    getValue={this.getValue}
                    updateTheValue={this.updateTheValue}
                    getInfoModalProps={this.getInfoModalProps}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const slug = ownProps.slug || _.get(ownProps, 'match.params.slug') || _.get(ownProps, 'route.params.slug');

        return {
            privacySettingsElement: getCurrentUserPrivacySettingsElement(state, slug),
            isLoading: isLoadingCurrentUserPrivacySettings(state),
            userId: coreSelectors.getCurrentUser(state).userId
        };
    };

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(PrivacySettingsItemBase));
}

export const styles = {
    container: {
        backgroundColor: baseColors.white
    },
    titleItem: {
        paddingLeft: spacing.s2,
        justifyContent: 'flex-start'
    },
    itemContainer: {
        flex: 1,
        paddingLeft: spacing.s2
    },
    item: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottomWidth: 1,
        borderBottomColor: baseColors.grey80,
        paddingTop: spacing.s0,
        paddingBottom: spacing.s0,
        paddingRight: spacing.s3
    },
    text: {
        ...appFonts.mdMedium,
        flex: 1,
        paddingRight: spacing.s2
    },
    textSmall: {
        ...appFonts.smRegular,
        flex: 1,
        paddingRight: spacing.s2,
        paddingTop: spacing.s2
    }
};
