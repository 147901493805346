import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classnames from 'classnames';
import { StyleSheet, css } from 'aphrodite-jss';
import { appFonts, baseColors, layoutStyle, spacing, importantClass } from '../../../../styles';
import Modal from '../Modal';
import SelectModal from '../SelectModal';
import Icon from '../Icon';
import WithSelectFieldBase, { LOGO_SIZE, styles as baseStyles } from './SelectFieldBase';
import { components as Core } from '../../../core';

const NUMBER_OF_LINES_FOR_SELECT_VALUE = 1;

class SelectField extends PureComponent {
    static propTypes = {
        options: PropTypes.array,
        message: PropTypes.string,
        singleSelect: PropTypes.bool,
        fieldName: PropTypes.string.isRequired,
        disabled: PropTypes.bool,
        isFocused: PropTypes.bool,
        i18n: PropTypes.object.isRequired,
        selected: PropTypes.object,
        className: PropTypes.string,
        wrapperClassName: PropTypes.string,
        children: PropTypes.node,
        onChange: PropTypes.func,
        error: PropTypes.string,
        modalTitle: PropTypes.string,
        isFilter: PropTypes.bool,
        translateOptions: PropTypes.bool,
        isModifiedDesign: PropTypes.bool,
        onPress: PropTypes.func,
        isRightMargin: PropTypes.bool,
        fadeTransition: PropTypes.bool,
        valueTextStyle: PropTypes.string,
        isVisibleOnFilter: PropTypes.bool,
        hasLongContent: PropTypes.bool
    };

    static defaultProps = {
        disabled: false,
        isFocused: false,
        singleSelect: true,
        className: undefined,
        wrapperClassName: undefined,
        selected: undefined,
        children: undefined,
        message: undefined,
        error: undefined,
        modalTitle: undefined,
        isFilter: false,
        translateOptions: false,
        isModifiedDesign: false,
        onPress: undefined,
        options: [],
        onChange: undefined,
        isRightMargin: false,
        fadeTransition: false,
        valueTextStyle: null,
        isVisibleOnFilter: true,
        hasLongContent: false
    };

    showModal = () => {
        const { fieldName, fadeTransition, ...rest } = this.props;
        this.close = Modal.open(
            SelectModal,
            {
                title: fieldName,
                onPressCallback: this.onChange,
                onClose: this.closeModal,
                ...rest
            },
            { isContainer: true, fadeTransition }
        );
    };

    closeModal = () => {
        this.close && this.close();
    };

    onChange = option => {
        if (option) this.props.onChange(option);
        this.closeModal();
    };

    get renderFilter() {
        const { selected, isFocused, className, disabled, options, children, message, translateOptions, i18n, isRightMargin } = this.props;
        const showSelectedStyling = selected || isFocused;
        const label = selected ? selected.label : message;
        return (
            <button
                className={classnames(className)}
                onClick={this.showModal}
                disabled={disabled || _.isEmpty(options)}>
                {children ? children : (
                    <div className={css(styles.overallWrapper, styles.overallWrapperFilter, (isRightMargin && styles.marginRightItem))}>
                        <div className={css(styles.body, styles.bodyFilter)}>
                            <p className={css(showSelectedStyling ? styles.valueText : styles.valueTextDefault, styles.filterText)}>
                                {translateOptions ? i18n.t(label) : label}
                            </p>
                        </div>
                        <div className={css(styles.iconWrapper, styles.iconWrapperFilter)}>
                            <Icon type="fa" name="angle-down" size={LOGO_SIZE} color={disabled ? baseColors.grey70 : baseColors.secondary} />
                        </div>
                    </div>
                )}
                {this.props.error ? <div className={css(styles.error)}>{this.props.error}</div> : null}
            </button>
        );
    }

    get renderIcon() {
        const { icon, iconWrapper, disabled } = this.props;
        return (
            <div className={classnames(css(styles.iconWrapper), iconWrapper)}>
                { icon ? icon : <Icon type="fa" name="angle-down" size={LOGO_SIZE} color={disabled ? baseColors.grey70 : baseColors.secondary} /> }
            </div>
        );
    }

    showLabel() {
        const { translateOptions, i18n, hasLongContent, selected, message } = this.props;
        const label = selected && selected.label ? selected.label : message;
        const labelText = translateOptions ? i18n.t(label) : label;

        if (hasLongContent) {
            return (
                <Core.TextSeeMore
                    isSelectFieldComponent={true}
                    numberOfLines={NUMBER_OF_LINES_FOR_SELECT_VALUE}>
                    {labelText}
                </Core.TextSeeMore>
            );
        }

        return labelText;
    }

    render() {
        const { selected, isFocused, fieldName, className, disabled, options, children, isFilter,
            wrapperClassName, isModifiedDesign, message, onPress, valueTextStyle, isVisibleOnFilter } = this.props;
        const showSelectedStyling = !disabled && (selected || isFocused || isModifiedDesign);
        const label = selected && selected.label ? selected.label : message;
        return isFilter ? this.renderFilter : (
            <button
                className={classnames(css(layoutStyle.db, disabled ? styles.disabledCursor : null), className)}
                onClick={onPress ? onPress : this.showModal}
                disabled={(disabled || _.isEmpty(options)) && !isModifiedDesign}>
                {children ? children :
                    <div className={classnames(css(styles.overallWrapper, isModifiedDesign && styles.modifiedWrapper, disabled ? styles.disabledField : null), wrapperClassName)}>
                        <div className={css(styles.body, isModifiedDesign && styles.modifiedBody)}>
                            {label ?
                                <div className={css(styles.fieldContainer)}>
                                    {fieldName && isVisibleOnFilter &&
                                    (
                                        <p className={css(
                                            showSelectedStyling ? styles.titleText : styles.titleTextDefault,
                                            styles.filterWeb,
                                            isModifiedDesign && styles.modifiedFieldName,
                                            disabled ? styles.disabledText : null
                                        )}>
                                            {fieldName}
                                        </p>
                                    )}
                                    <p className={classnames(css(
                                        showSelectedStyling ? styles.valueText : styles.valueTextDefault,
                                        styles.filterWeb,
                                        isModifiedDesign && styles.modifiedFieldValue
                                    ), valueTextStyle,
                                    disabled ? styles.disabledText : null)}>
                                        {this.showLabel()}
                                    </p>
                                </div> :
                                <div className={css(styles.fieldContainer, styles.fieldContainerEmpty)}>
                                    <p className={css(styles.emptyValueText, styles.filterWeb, disabled ? styles.disabledText : null)}>
                                        {fieldName}
                                    </p>
                                </div>
                            }
                        </div>
                        {this.renderIcon}
                    </div>}
                {this.props.error ?
                    <div className={css(styles.error)}>{this.props.error}</div> : null
                }
            </button>
        );
    }
}

export default WithSelectFieldBase(SelectField);

const SELECT_BORDER_RADIUS = spacing.s3;
const styles = StyleSheet.create({
    ...baseStyles,
    overallWrapper: {
        ...baseStyles.overallWrapper,
        display: 'flex',
        borderStyle: 'solid'
    },
    filterWeb: {
        textAlign: 'left'
    },
    iconWrapper: {
        justifyContent: 'center',
        alignItems: 'flex-end',
        paddingRight: spacing.s2,
        display: 'flex',
        flexDirection: 'column'
    },
    bodyFilter: {
        flex: 'auto'
    },
    overallWrapperFilter: {
        marginLeft: spacing.s1,
        borderColor: baseColors.secondary,
        borderWidth: 1,
        paddingLeft: spacing.s3,
        paddingRight: spacing.s2,
        borderRadius: SELECT_BORDER_RADIUS,
        flexDirection: 'row',
        alignItems: 'flex-end',
        height: 'auto',
        marginBottom: spacing.s3
    },
    filterText: importantClass({
        ...appFonts.smRegular,
        color: baseColors.secondary,
        padding: 0,
        paddingRight: spacing.s0
    }),
    iconWrapperFilter: {
        top: spacing.s0,
        position: 'relative',
        paddingRight: spacing.s0
    },
    fieldContainer: {
        flex: 6,
        paddingTop: spacing.s0,
    },
    modifiedFieldName: {
        lineHeight: `${spacing.s3}px`,
    },
    modifiedBody: {
        paddingBottom: spacing.s1,
    },
    marginRightItem: {
        marginLeft: 0,
        marginRight: spacing.s0
    },
    disabledCursor: {
        cursor: 'default'
    },
    titleText: {
        ...baseStyles.titleText,
        paddingTop: spacing.s0/2,
        paddingBottom: 0
    }
});
