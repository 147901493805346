import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { withRouter } from 'react-router-dom';
import { components as Core, ROUTES, onKeyPress } from '../../../core';
import WithPrivacySectionBase, { styles as baseStyles } from './PrivacySectionBase';
import { layoutStyle, spacing, appFonts, baseColors } from '../../../../styles';

class PrivacySection extends PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
        isEnhancedPrivacyInformationEnabled: PropTypes.bool.isRequired,
        isHiddenPrivacyInformationText: PropTypes.bool.isRequired,
        privacyIcon: PropTypes.string,
        privacyMessage: PropTypes.string,
        isPrivacyVisible: PropTypes.bool.isRequired,
        onHidePrivacyInformationText: PropTypes.func.isRequired,
        isDisclaimerOnly: PropTypes.bool,
        message: PropTypes.string.isRequired,
        isPrivacyNotExisting: PropTypes.bool.isRequired,
        isLightStyle: PropTypes.bool,
        managePrivacySettingUrlTitle: PropTypes.string.isRequired,
        hasPadding: PropTypes.bool,
        hasVerticalPadding: PropTypes.bool,
        modalLightDesign: PropTypes.bool,
    };

    static defaultProps = {
        privacyIcon: undefined,
        privacyMessage: undefined,
        isDisclaimerOnly: false,
        isLightStyle: false,
        hasPadding: false,
        hasVerticalPadding: false,
        modalLightDesign: false,
    };

    openPrivacySettings = () => this.props.history.push(ROUTES.privacySettings());

    get lightLinkMessage() {
        return this.props.modalLightDesign ? (
            <div
                tabIndex="0"
                role="button"
                onKeyDown={onKeyPress.enter(this.openPrivacySettings)}
                onClick={this.openPrivacySettings}
                className={css(styles.lightLinkMessage)}>
                <p>{this.props.managePrivacySettingUrlTitle}</p>
                <Core.Icon
                    name="angle-right"
                    type="fa"
                    size={spacing.s6}
                    className={css(styles.iconAngle)}
                />
            </div>
        ) : null;
    }

    get disclaimer() {
        const { managePrivacySettingUrlTitle, hasPadding, hasVerticalPadding, modalLightDesign } = this.props;

        return (
            <div
                className={css(
                    hasPadding ? styles.containerWithPadding : styles.disclaimerContainer,
                    hasVerticalPadding && layoutStyle.commonPaddingVertical
                )}
            >
                <Core.DisclaimerSection
                    modalLightDesign={modalLightDesign}
                    lightLinkMessage={this.lightLinkMessage}
                    iconName={this.props.privacyIcon}
                    message={this.props.privacyMessage}
                    linkMessage={managePrivacySettingUrlTitle}
                    linkAction={this.openPrivacySettings}
                />
            </div>
        );
    }

    render() {
        const { isEnhancedPrivacyInformationEnabled, isHiddenPrivacyInformationText, isPrivacyVisible, modalLightDesign,
            isDisclaimerOnly, onHidePrivacyInformationText, message, isPrivacyNotExisting, managePrivacySettingUrlTitle } = this.props;

        if (isPrivacyNotExisting) return null;

        if (isDisclaimerOnly) {
            return isEnhancedPrivacyInformationEnabled ? this.disclaimer : null;
        }

        if (!isPrivacyVisible) {
            return this.disclaimer;
        } else if (isEnhancedPrivacyInformationEnabled && !isHiddenPrivacyInformationText) {
            return (
                <div className={css(styles.containerWithPadding)}>
                    <Core.ClosableInfoSection
                        onClose={onHidePrivacyInformationText}
                        text={message}
                        linkMessage={managePrivacySettingUrlTitle}
                        linkAction={this.openPrivacySettings}
                        isLightStyle={this.props.isLightStyle}
                        lightLinkMessage={this.lightLinkMessage}
                        modalLightDesign={modalLightDesign}
                    />
                </div>
            );
        }
        return null;
    }
}

export default withRouter(WithPrivacySectionBase(PrivacySection));

export const styles = StyleSheet.create({
    ...baseStyles,
    lightLinkMessage: {
        color: baseColors.contextDarkest,
        ...appFonts.mdMedium,
        display: 'flex',
        alignItems: 'center',
        marginTop: spacing.s3,
        width: 'fit-content',
    },
    iconAngle: {
        color: baseColors.black,
        marginBottom: spacing.s0,
        paddingRight: spacing.s1,
    },
    containerWithPadding: {
        borderRadius: spacing.s1,
        paddingLeft: 0
    }
});
