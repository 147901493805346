import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';
import { baseColors, spacing } from '../../../../styles';
import { components as Core } from '../../../core';
import WithTrackActivityButtonBase, { styles as baseStyles } from './TrackActivityButtonBase';

class TrackActivityButton extends PureComponent {
    static propTypes = {
        color: PropTypes.string.isRequired,
        isNotTracked: PropTypes.bool.isRequired,
        isTracked: PropTypes.bool.isRequired,
        isFullyTracked: PropTypes.bool.isRequired,
        progress: PropTypes.object.isRequired,
        isTrackByDevice: PropTypes.bool,
    };

    static defaultProps = {
        isTrackByDevice: false
    };

    render() {
        const cursorStyle = (this.props.progress.enable || !this.props.isTrackByDevice) ? 'pointer' : 'not-allowed';
        if (this.props.isNotTracked) {
            return (
                <div className={css(styles.defaultSquare)} style={{ borderColor: this.props.color, cursor: cursorStyle }}>
                    {!this.props.isTrackByDevice ?
                        <Core.Icon type="light" name="plus" size={spacing.s4} color={this.props.color} /> : null}
                </div>
            );
        }
        if (this.props.isTracked) {
            return (
                <div className={css(styles.defaultSquare)} style={{ borderColor: this.props.color }}>
                    <Core.Icon type="light" name="check" size={spacing.s4} color={this.props.color} />
                </div>
            );
        }
        if (this.props.isFullyTracked) {
            return (
                <div className={css(styles.defaultSquare, styles.fullyTracked)}>
                    <Core.Icon type="light" name="check" size={spacing.s4} color={baseColors.white} />
                </div>
            );
        }
        return null;
    }
}

export default WithTrackActivityButtonBase(TrackActivityButton);

const styles = StyleSheet.create({
    ...baseStyles,
    defaultSquare: {
        ...baseStyles.defaultSquare,
        margin: 'auto',
        borderStyle: 'solid',
        cursor: 'pointer',
        maxWidth: spacing.s8,
        maxHeight: spacing.s8,
    },
});