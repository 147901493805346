import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

import { components as Core, ROUTES } from '../../../../core';
import WithAchievementsCarouselBase from './AchievementsCarouselBase';
import CarouselItem from '../CarouselItem';

class AchievementsCarousel extends PureComponent {
    static propTypes = {
        hasAchievements: PropTypes.bool,
        achievements: PropTypes.array,
        history: PropTypes.object.isRequired,
        title: PropTypes.string.isRequired,
        selectedTab: PropTypes.string,
    };

    static defaultProps = {
        hasAchievements: false,
        achievements: [],
        selectedTab: null
    };

    viewAllAction = () => {
        this.props.history.push(ROUTES.myAchievements(), { selectedTab: this.props.selectedTab });
    };

    renderItem = item => (<CarouselItem achievementId={item.id} key={item.id} />);

    render() {
        const { title, achievements, hasAchievements } = this.props;
        return (
            hasAchievements ?
                <Core.Carousel
                    titleSize={Core.SubHeader.SIZES.large}
                    type="multiple"
                    title={title}
                    viewAllPress={this.viewAllAction}
                    itemsLength={achievements.length}>
                    {_.map(achievements, item => this.renderItem(item))}
                </Core.Carousel> : null
        );
    }
}

export default withRouter(WithAchievementsCarouselBase(AchievementsCarousel));