import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { components as Core, parsers, translate, ENTITIES, selectors as coreSelectors, tracker } from '../../../../core';
import { getFeed } from '../../../selectors';
import { selectors as authSelectors } from '../../../../auth';
import { STREAM_ITEM_TYPE_SLUGS } from '../../../constants';
import { appFonts, baseColors } from '../../../../../styles';

export default function WithFeedItemHeaderBaseBase(WrappedComponent) {
    class FeedItemHeaderBase extends PureComponent {
        static propTypes = {
            streamItemId: PropTypes.number.isRequired, // eslint-disable-line
            stream: PropTypes.object.isRequired,
            i18n: PropTypes.object.isRequired,
            onEntityDetails: PropTypes.func.isRequired,
            onUserDetails: PropTypes.func.isRequired,
            customPointsUnit: PropTypes.string.isRequired,
            user: PropTypes.object.isRequired,
            isLiveBetter: PropTypes.bool.isRequired,
            isWhitelabel: PropTypes.bool,
            programName: PropTypes.string,
        };

        static defaultProps = {
            isWhitelabel: false,
            programName: null,
        };

        get date() {
            return moment(this.props.stream.timestampCreated * 1000).fromNow();
        }

        get points() {
            return (this.props.stream.streamItemTypeSlug === STREAM_ITEM_TYPE_SLUGS.add_activities)
                ? _.get(this.props.stream, 'streamItemDisplay.generatedText.values.points') : undefined;
        }

        get isVisibleOnlyMe() {
            return this.props.stream.private === 1;
        }

        get isRegistrationStreamPost() {
            return _.get(this.props.stream, 'streamItemTypeSlug') === STREAM_ITEM_TYPE_SLUGS.add_user;
        }

        get programName() {
            const { user: { fullProgramName }, isWhitelabel, programName, isLiveBetter } = this.props;
            if (this.isRegistrationStreamPost && !isLiveBetter && isWhitelabel) {
                return programName ? programName : fullProgramName;
            }
            return null;
        }

        getText = (styles, isWeb) => {
            const { stream } = this.props;
            const text = _.get(stream, 'streamItemDisplay.generatedText');
            if (text && text.template && text.values) {
                let { values } = text;
                const name = `${stream.owner.firstName} ${stream.owner.lastName}`;
                if (_.get(values, 'list_of_user_names')) {
                    const arrayItems = _.split(_.get(values, 'list_of_user_names'), ',');
                    arrayItems[0] = name;
                    values = {
                        ...values,
                        list_of_user_names: _.toString(arrayItems)
                    };
                } else if (_.get(values, 'user.name')) {
                    values = {
                        ...values,
                        user: {
                            ...values.user,
                            name
                        }
                    };
                }
                switch (this.props.stream.relationTypeSlug) {
                    case ENTITIES.group:
                    case ENTITIES.challenge:
                    case ENTITIES.event:
                        return parsers.generateStringWithValuesStyled(
                            text.template,
                            values,
                            styles,
                            this.props.onEntityDetails,
                            Core.Text,
                            isWeb
                        );
                    default:
                        return parsers.generateStringWithValuesStyled(
                            text.template,
                            values,
                            styles,
                            null,
                            Core.Text,
                            isWeb,
                            this.programName,
                        );
                }
            }
            return undefined;
        };

        goToUserDetails = () => {
            this.props.onUserDetails(this.props.stream.owner.userId);
            tracker.logEvent('view', { entity: this.props.stream.relationTypeSlug, stream: true });
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    getText={this.getText}
                    isVisibleOnlyMe={this.isVisibleOnlyMe}
                    points={this.points}
                    date={this.date}
                    goToUserDetails={this.goToUserDetails}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        return {
            stream: getFeed(state, ownProps.streamItemId),
            user: coreSelectors.getCurrentUser(state),
            isLiveBetter: coreSelectors.isLiveBetter(state),
            isWhitelabel: authSelectors.isWhitelabel(state),
            programName: authSelectors.getProgramName(state),
            customPointsUnit: coreSelectors.getCustomPointsUnit(state)
        };
    }

    return connect(mapStateToProps)(translate()(FeedItemHeaderBase));
}

export const styles = {
    cardHeader: {
        flex: 1
    },
    cardTitle: {
        ...appFonts.mdBold
    },
    entityNameTitle: {
        fontWeight: 'bold',
        color: baseColors.secondary
    },
    cardSubtitle: {
        ...appFonts.smRegular,
        color: baseColors.grey40,
    },
    cardSpecialTitle: {
        ...appFonts.mdRegular
    },
    pointsValue: {
        ...appFonts.mdBold,
        color: baseColors.secondary
    },
};
