import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NetInfo from '../services/NetInfo';
import Modal from './Modal';
import NoConnection from './NoConnection';
import translate from '../services/translate';
import * as actions from '../actions';
import * as selectors from '../selectors';

class TrackConnection extends PureComponent {
    static propTypes = {
        actions: PropTypes.object.isRequired,
        isOnline: PropTypes.bool
    };

    static defaultProps = {
        isOnline: undefined
    };

    componentDidMount() {
        NetInfo.addEventListener(this.onConnectionChange);
    }

    componentWillUnmount() {
        NetInfo.removeEventListener(this.onConnectionChange);
    }

    componentDidUpdate(prevProps) {
        if (this.props.isOnline !== prevProps.isOnline) {
            if (!this.props.isOnline) {
                Modal.open(NoConnection, {}, { isContainer: true, isFullHeight: true, cancelable: false });
            } else {
                this.close && this.close();
                window.location.reload(false);
            }
        }
    }

    onConnectionChange = isOnline => {
        this.props.actions.updateConnectionState(isOnline);
    };

    render() { return null; }
}

const mapStateToProps = state => ({
    isOnline: selectors.isOnline(state)
});

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(TrackConnection));
