import { createSelector } from 'reselect';
import _ from 'lodash';
import moment from 'moment';
import {
    DEFAULT_ICON_CATEGORY as DEFAULT_ICON, ICON_CATEGORIES, selectors as coreSelectors, getRequestId, CHALLENGE_DURATION_REFERENCE,
    DATE_FORMATS, MAX_SLIDES_COUNT, PARTICIPANTS_NUMBER_AVATARS, pointsHelper, numbers, entitiesHelper, ENTITIES
} from '../core';
import { NAME, CHALLENGE_TYPES, CHALLENGE_ENTITY_TYPES, UNIT_TYPES, MAX_PERSONAL_GOALS, AUTOMATICALLY_TRACKED_ACTIVITY_INPUT_DEVICE,
    CHALLENGES_CAROUSEL_TYPES, DISPLAY_TYPES, ITEMS_PER_SLIDE, ONBOARDING_GOALS, DEFAULT_TIME_ID, TIME_FILTER_4_MONTHS } from './constants';
import * as types from './actionTypes';
import * as helper from './services/helper';
import * as periodHelper from './services/challengePeriodHelper';
import * as activitiesHelper from './services/activitiesHelper';
import i18n from '../../i18n';

const getChallengesItems = state => state[NAME].items;
export const getChallengeObject = (state, id) => getChallengesItems(state)[id];
export const getChallengesIds = state => state[NAME][DISPLAY_TYPES.UNCOMPLETED_CAROUSEL].ids;
const getCompletedChallengesIds = state => state[NAME][DISPLAY_TYPES.COMPLETED_CAROUSEL].ids;
export const getCompletedChallengesCount = state => state[NAME][DISPLAY_TYPES.COMPLETED_CAROUSEL].count;
export const getChallengesIdsCount = state => state[NAME][DISPLAY_TYPES.UNCOMPLETED_CAROUSEL].count;

export const getInvites = state => state[NAME].invitedChallenges.challenges;
export const getActiveInvites = createSelector(getInvites, items => _.filter(items, item => item.isEnded !== 1 && item.invitation.isInvited));

export const getChallenges = createSelector(getChallengesItems, getChallengesIds,
    (items, ids) => _.filter(_.map(ids, id => items[id]), ch => !_.get(ch, 'isDismissed', 0)));

export const getCompletedChallenges = createSelector(getChallengesItems, getCompletedChallengesIds,
    (items, ids) => _.map(ids, id => items[id]));
export const getAllRecommendedGoals = state => state[NAME].recommendations;

export const getRecommendedGoalsArray = state => state[NAME].recommendedGoals;
export const getRecommendedGoals = createSelector(getAllRecommendedGoals, getRecommendedGoalsArray, coreSelectors.isLiveBetter,
    (allGoals, recommended, isLiveBetter) => isLiveBetter ? allGoals : recommended);
export const getRecommendedGoal = createSelector(getRecommendedGoals, coreSelectors.getSecondParam,
    (items, name) => _.find(items, item => item.goalName === name));

export const getStarterGoals = state => _.sortBy(state[NAME].starterGoals, goal => goal.starter);
export const getStarterGoal = createSelector(getStarterGoals, coreSelectors.getSecondParam,
    (items, name) => _.find(items, item => item.goalName === name));

export const getSpecialsGoals = createSelector(getRecommendedGoals,
    (items) => _.filter(items, item => _.some(ONBOARDING_GOALS, goalName => item.goalName === goalName)));
export const getCompletedPersonalGoals = createSelector(getCompletedChallenges, goals =>
    _.orderBy(_.filter(goals, goal => helper.isPersonal(goal) && goal.isEnded), 'challengeDeadline', 'desc'));

export const getChallenge = (state, id) => getChallengeObject(state, id) || getRecommendedGoal(state, id) || getStarterGoal(state, id);
export const getLastCreatedChallengeId = state => state[NAME].lastCreatedId;
export const getLastCreatedChallenge = state => getChallengeObject(state, getLastCreatedChallengeId(state));
export const getChallengeUserEntityId = (state, id) => _.get(getChallenge(state, id), 'userEntity.id');
export const getAllChallenges = createSelector(getChallenges, getCompletedChallenges,
    (challenges, completedChallenges) => [...challenges, ...completedChallenges]);

export const isBonusChallenge = createSelector(getChallenge, challenge => _.get(challenge, 'isBonusChallenge'));
export const isRestartGoal = createSelector(getChallenge, challenge => !!Number(_.get(challenge, 'restartGoalTs')) || (_.has(challenge, 'restartDay') && challenge.restartDay !== 'none'));
export const isSmartModeGoal = createSelector(getChallenge, challenge => _.has(challenge, 'smartMode') ? (challenge.smartMode === '1' || challenge.smartMode === true) : undefined);

export const getCompletedNotDismissedChallenges = createSelector(getCompletedChallenges,
    challenges => _.filter(challenges, i => i.isDismissed === 0 && !helper.isGoal(i)));
export const getCompletedNotDismissedGoals = createSelector(getCompletedChallenges,
    challenges => _.filter(challenges, i => {
        if (helper.isGoal(i) && i.isDismissed === 0) {
            if (+i.restartGoalTs) return !i.isEnded;
            return i;
        }
    })
);
export const getCompletedCompetitionsNumber = createSelector(getCompletedNotDismissedChallenges,
    challenges => _.filter(challenges, ch => ch && ch.isMember && ch.challengeType === CHALLENGE_TYPES.competition).length);
export const getCompletedPersonalGoalsNumber = createSelector(getCompletedNotDismissedChallenges,
    getCompletedCompetitionsNumber, (challenges, competitionsNumber) => (challenges.length - competitionsNumber));
export const getCompletedBonusNumber = createSelector(getCompletedNotDismissedChallenges,
    challenges => Math.round(_.reduce(challenges, (total, ch) => (total + ch.potentialBonusPoints), 0)));

export const getRecentlyCompletedChallenges = createSelector(getCompletedChallenges,
    challenges => _.filter(challenges, ch => _.get(ch, 'track_deadline.days_left', 0) > 0));
export const getNotRecentlyCompletedChallenges = createSelector(getCompletedChallenges,
    challenges => _.filter(challenges, ch => _.get(ch, 'track_deadline.days_left', 0) === 0));

export const getNonPersonalChallenges = createSelector(getChallenges,
    challenges => _.filter(challenges, item => item && item.isSolo === 0));

export const getMyOrInvitedChallenges = createSelector(getChallenges,
    challenges => _.filter(challenges, c => c && (c.isMember === 1 || (c.invitation && _.get(c.invitation, 'invitedBy.length')))));

export const getMyIsMemberChallenges = createSelector(getChallenges,
    challenges => _.uniq(_.filter(challenges, c => c && c.isMember === 1)));
export const getFeaturedChallengesArray = state => _.get(state[NAME], 'featuredChallenges.ids');

export const getFeaturedChallengesItems = createSelector(getChallengesItems, getFeaturedChallengesArray,
    (items, ids) => _.map(ids, id => items[id]));

export const getFeaturedChallengesNotHidden = createSelector(getFeaturedChallengesItems,
    challenges => _.filter(challenges, c => c.isHidden !== 1));
export const getMyIsMemberChallengesFiltered = createSelector(getMyIsMemberChallenges,
    challenges => _.filter(challenges, c => !helper.isUnstartedWeeklyGoal(c) && !helper.isWeeklyGoalGracePeriodEnded(c)));

export const getExploreChallenges = createSelector(getChallenges, challenges =>
    _.take(_.filter(challenges, c => c && c.isMember === 0 && c.isSolo === 0 && c.isCompleted === 0), MAX_SLIDES_COUNT));

export const getMyPersonalGoals = createSelector(getChallenges,
    challenges => _.filter(challenges, c => c && c.isSolo === 1 && c.isMember === 1 && !c.isEnded && !helper.isUnstartedWeeklyGoal(c)));
export const isAllowedToSetGoal = createSelector(getMyPersonalGoals, getCompletedNotDismissedGoals,
    (goals, completedGoals) => goals.length + completedGoals.length < 3);
export const getMyPersonalGoalsLimited =
    createSelector(getMyPersonalGoals, items => _.take(items, MAX_PERSONAL_GOALS));

export const getChallengesByCarouselType = createSelector(getChallenges, coreSelectors.getSecondParam,
    (challenges, type) => ({
        [CHALLENGES_CAROUSEL_TYPES.programCompetitions]: helper.filterProgramCompetitions,
        [CHALLENGES_CAROUSEL_TYPES.competitions]: helper.filterCompetitions,
        [CHALLENGES_CAROUSEL_TYPES.goals]: helper.filterPersonalGoals,
    })[type](challenges));

const getMyPersonalGoalsName = createSelector(getMyPersonalGoals, items => _.map(items, goal => goal.challengeName));
const getRecommendedGoalIds = createSelector(getRecommendedGoals, getMyPersonalGoals, (recommended, setGoals) => {
    return _.every(recommended, goal => !!_.get(goal, 'recommendedGoalsId')) && _.map(setGoals, goal => goal.recommendedGoalsId);
});

export const isSetRecommendedGoal = createSelector(getMyPersonalGoalsName, getRecommendedGoal, getRecommendedGoalIds,
    (setNames, goal, setIds) => {
        if (setIds) return _.includes(setIds, goal.recommendedGoalsId);
        return _.includes(setNames, goal.goalName);
    });
export const getSetRecommendedChallengeIdByName = createSelector(getMyPersonalGoals, getRecommendedGoal,
    (goals, goal) => _.get(_.find(goals, g => g.challengeName === goal.goalName), 'challengeId'));
export const getRecommendedGoalsCarousel = createSelector(getRecommendedGoals,
    goals => _.take(goals, MAX_SLIDES_COUNT));
export const getStarterGoalsCarousel = createSelector(getStarterGoals,
    goals => _.take(goals, MAX_SLIDES_COUNT));
export const getRecommendedGoalsBySlug = createSelector(getRecommendedGoals, coreSelectors.getSecondParam,
    (items, slug) => _.filter(items, { categorySlug: slug }));
const getRecommendedGoalsSlugs = createSelector(getRecommendedGoals,
    items => _.uniqBy(_.map(items, item => ({ categorySlug: item.categorySlug, categoryName: item.categoryName })), 'categorySlug'));
export const getRecommendedGoalsTabs = createSelector(getRecommendedGoalsSlugs,
    items => _.map(items, item => ({ id: item.categorySlug, label: item.categoryName })));

export const isSettingPersonalGoals = state => coreSelectors.isLoading(state, types.SET_GOALS.NAME);
export const isSettingPersonalGoal = (state, id) => coreSelectors.isLoading(state, getRequestId(types.SET_GOAL.NAME, id));

export const getTotalPointsForCompletedChallenges = createSelector(getCompletedNotDismissedChallenges, challenges => {
    const total = _.reduce(challenges, (total, challenge) => (challenge.challengeType === CHALLENGE_TYPES.competition
        ? total + challenge.progress.overallUserValue
        : total + (challenge.progress.overallUserValue / 100 * challenge.progress.overallPointsTargetValue)), 0);
    return Math.round(total);
});

export const isLoadingChallenges = state => coreSelectors.isLoading(state, types.GET_CHALLENGES.NAME);
export const isLoadingChallengeInfo = (state, id) => coreSelectors.isLoading(state, getRequestId(types.GET_CHALLENGE_INFO.NAME, id));
export const isLeavingChallenge = (state, id) => coreSelectors.isLoading(state, getRequestId(types.LEAVE_CHALLENGE.NAME, id));
export const isDeletingChallenge = (state, id) => coreSelectors.isLoading(state, getRequestId(types.DELETE_CHALLENGE.NAME, id));
export const getChallengeInfoError = (state, id) => coreSelectors.getError(state, getRequestId(types.GET_CHALLENGE_INFO.NAME, id));
export const isLoadingRecommendedChallenges = state => coreSelectors.isLoading(state, types.GET_CHALLENGE_RECOMMENDATIONS.NAME);
export const isLoadingRecommendedGoals = state => coreSelectors.isLoading(state, types.GET_RECOMMENDED_GOALS.NAME);
export const isLoadingStarterGoals = state => coreSelectors.isLoading(state, types.GET_STARTER_GOALS.NAME);
export const isLoadingInvitationDecline = (state, id) => coreSelectors.isLoading(state, getRequestId(types.DECLINE_INVITATION.NAME, id));

export const isDismissing = (state, id) => coreSelectors.isLoading(state, getRequestId(types.DISMISS_SINGLE_CHALLENGE, id));
export const isDismissingMultiple = state => coreSelectors.isLoading(state, types.DISMISS_MULTIPLE_CHALLENGE.NAME);

export const isJoiningChallenge = (state, id) => coreSelectors.isLoading(state, getRequestId(types.JOIN_CHALLENGE.NAME, id));
export const getJoiningError = state => coreSelectors.getError(state, types.JOIN_CHALLENGE.NAME);

export const isJoiningChallengeByTeamId = (state, teamId) => coreSelectors.isLoading(state, getRequestId(types.JOIN_CHALLENGE_BY_TEAM_ID.NAME, teamId));
export const getJoiningByTeamIdError = (state, teamId) => coreSelectors.getError(state, getRequestId(types.JOIN_CHALLENGE_BY_TEAM_ID.NAME, teamId));
export const getChallengeIdsByTeamIds = state => state[NAME].challengeIdsByTeamIds;
export const getChallengeIdByTeamId = createSelector(getChallengeIdsByTeamIds, coreSelectors.getSecondParam,
    (items, teamId) => items[teamId]);
export const hasTrackedChallenge = state => state[NAME].hasTrackedChallenge;

// FOR LIST
export const isLoadingChallengesList = state => coreSelectors.isLoading(state, types.GET_CHALLENGES_LIST.NAME);

// FOR EXPLORE
export const isLoadingExploreChallenges = state => coreSelectors.isLoading(state, types.GET_EXPLORE_CHALLENGES.NAME);

const getItems = (items, ids) => _.map(getIds(ids), id => items[id]);
const getIds = (items, isCarousel = false) => (isCarousel ? items.slice(0, 10) : items);

const groupItemsForCarousel = items => _.chunk(items, ITEMS_PER_SLIDE);

// added list to the end as already exists
const getAllChallengesIds = state => getIds(_.get(state[NAME][DISPLAY_TYPES.ALL].ids));
export const getAllChallengesList = createSelector(getChallengesItems, getAllChallengesIds, getItems);

const getMyChallengeIds = state => getIds(state[NAME][DISPLAY_TYPES.ATTENDED].ids);
export const getMyChallenges = createSelector(getChallengesItems, getMyChallengeIds, getItems);

const getCompanyChallengeIds = state => getIds(state[NAME][DISPLAY_TYPES.COMPANY].ids);
export const getCompanyChallengeItems = createSelector(getChallengesItems, getCompanyChallengeIds, getItems);
export const getCompanyChallenges = createSelector(getCompanyChallengeItems, items => _.filter(items, item => item.isEnded !== 1));
export const getCompanyChallengesCarousel = createSelector(getCompanyChallenges, groupItemsForCarousel);

const getRecommendedChallengeIds = state => getIds(state[NAME][DISPLAY_TYPES.RECOMMENDED].ids);
export const getRecommendedChallengeItems = createSelector(getChallengesItems, getRecommendedChallengeIds, getItems);
export const getRecommendedChallenges = createSelector(getRecommendedChallengeItems, items => _.filter(items, item => item.isEnded !== 1));
export const getRecommendedChallengesCarousel = createSelector(getRecommendedChallenges, groupItemsForCarousel);

const getRecentChallengeIds = state => getIds(state[NAME][DISPLAY_TYPES.RECENT].ids);
export const getRecentChallengeItems = createSelector(getChallengesItems, getRecentChallengeIds, getItems);
export const getRecentChallenges = createSelector(getRecentChallengeItems, items => _.filter(items, item => item.isEnded !== 1));

const getInvitedChallengeIds = state => getIds(state[NAME][DISPLAY_TYPES.INVITATIONS].ids);
export const getInvitedChallengeItems = createSelector(getChallengesItems, getInvitedChallengeIds, getItems);
export const getInvitedChallenges = createSelector(getInvitedChallengeItems, items => _.filter(items, item => item.isEnded !== 1));
export const getInvitedChallengesCarousel = createSelector(getInvitedChallenges, groupItemsForCarousel);

const getCompletedChallengeIds = state => getIds(state[NAME][DISPLAY_TYPES.COMPLETED].ids);
// added list to the end as already exists
export const getCompletedChallengesList = createSelector(getChallengesItems, getCompletedChallengeIds, getItems);

const getBonusChallengeIds = state => getIds(state[NAME][DISPLAY_TYPES.BONUS].ids);
export const getBonusChallenges = createSelector(getChallengesItems, getBonusChallengeIds, getItems);

const getChallengesCreatedHyUsers = state => getIds(state[NAME][DISPLAY_TYPES.USER].ids);
export const getChallengesCreatedByUser = createSelector(getChallengesItems, getChallengesCreatedHyUsers, getItems);

export const getMyChallengesCount = state => state[NAME][DISPLAY_TYPES.ATTENDED].count;
export const getAllChallengesListCount = state => state[NAME][DISPLAY_TYPES.ALL].count;
export const getRecommendedChallengesCount = state => state[NAME][DISPLAY_TYPES.RECOMMENDED].count;
export const getInvitedChallengesCount = state => state[NAME][DISPLAY_TYPES.INVITATIONS].count;
export const getRecentChallengesCount = state => state[NAME][DISPLAY_TYPES.RECENT].count;
export const getCompanyChallengesCount = state => state[NAME][DISPLAY_TYPES.COMPANY].count;
export const getCompletedChallengesListCount = state => state[NAME][DISPLAY_TYPES.COMPLETED].count;
export const getBonusChallengesListCount = state => state[NAME][DISPLAY_TYPES.BONUS].count;
export const getChallengesCreatedByUserCount = state => state[NAME][DISPLAY_TYPES.USER].count;

export const getChallengesByType = {
    [DISPLAY_TYPES.ALL]: {
        items: getAllChallengesList,
        count: getAllChallengesListCount
    },
    [DISPLAY_TYPES.ATTENDED]: {
        items: getMyChallenges,
        count: getMyChallengesCount
    },
    [DISPLAY_TYPES.INVITATIONS]: {
        items: getInvitedChallenges,
        count: getInvitedChallengesCount
    },
    [DISPLAY_TYPES.RECOMMENDED]: {
        items: getRecommendedChallenges,
        count: getRecommendedChallengesCount
    },
    [DISPLAY_TYPES.RECENT]: {
        items: getRecentChallenges,
        count: getRecentChallengesCount
    },
    [DISPLAY_TYPES.COMPANY]: {
        items: getCompanyChallenges,
        count: getCompanyChallengesCount
    },
    [DISPLAY_TYPES.COMPLETED]: {
        items: getCompletedChallengesList,
        count: getCompletedChallengesListCount
    },
    [DISPLAY_TYPES.BONUS]: {
        items: getBonusChallenges,
        count: getBonusChallengesListCount
    }
};

export const getExploreChallengesCarousel = createSelector(
    getCompanyChallenges,
    getRecommendedChallenges,
    getInvitedChallenges,
    getRecentChallenges,
    (companyChallenges, recommendedChallenges, invitedChallenges, recentChallenges) => {
        const allChallenges = [
            ...companyChallenges,
            ...recommendedChallenges,
            ...invitedChallenges,
            ...recentChallenges
        ];

        return _.take(_.uniqBy(allChallenges, 'challengeId'), MAX_SLIDES_COUNT);
    }
);

// leaderboard
export const getChallengeLeaderboard = (state, challengeId) => state[NAME].leaderboards[challengeId];
export const getChallengeLeaderboardForEntityId = createSelector(getChallengeLeaderboard, coreSelectors.getThirdParam,
    (leaderboard, entityId) => entityId && _.find(_.get(leaderboard, 'items', []), e => e.itemEntityId === entityId.toString()));

// membership
const getMembership = state => state[NAME].memberships;
export const getChallengeMembership = (state, id) => getMembership(state)[id];
export const getChallengeMembershipInvitingTeams = createSelector(getChallengeMembership, membershipDetails => {
    if (!_.get(membershipDetails, 'length')) return [];
    return _.filter(membershipDetails, m => _.get(m, 'groupInfo.invitedBy') !== false);
});

export const isLoadingMembership = (state, id) => coreSelectors.isLoading(state, getRequestId(types.GET_CHALLENGE_MEMBERSHIP.NAME, id));

export const getTeamSearchResults = state => state[NAME].searchingResultTeams;
export const teamSearchIsloading = (state, challengeId) => coreSelectors.isLoading(state, getRequestId(types.GET_SEARCH_TEAMS_RESULT.NAME, challengeId));

export const getChallengeParticipants = createSelector(getChallenge, challenge => {
    if (helper.isPersonal(challenge)) return [];
    return _.take(
        helper.isTeamChallenge(challenge) || helper.isRegionsOrDepartmentsChallenge(challenge) ? challenge.participants : challenge.challengeParticipants,
        PARTICIPANTS_NUMBER_AVATARS
    );
});

export const isNotificationShown = createSelector(getChallenge, challenge => _.get(challenge, 'isNotificationShown'));
export const isTeamChallenge = createSelector(getChallenge, challenge => helper.isTeamChallenge(challenge));
export const isRegionsOrDepartmentsChallenge = createSelector(getChallenge, challenge => helper.isRegionsOrDepartmentsChallenge(challenge));

const getMembers = state => state[NAME].members;
export const getChallengeMembers = (state, id) => _.get(getMembers(state), id, []);
export const getChallengeMembersFiltered = createSelector(getChallengeMembers, members =>
    _.filter(members, member => _.get(member, 'groupInfo.invitedBy') !== false));
export const getChallengeMembersCount = (state, id) =>
    numbers.intOrZero(_.get(getChallenge(state, id), 'numChallengeParticipants', 0));
export const getChallengePrivateMembersCount = (state, id) =>
    numbers.intOrZero(_.get(getChallenge(state, id), 'numPrivateChallengeParticipants', 0));
export const getChallengeMembersAndPrivateSummary = createSelector(getChallengeMembers, getChallengePrivateMembersCount,
    (members, privateCount) => privateCount === 0 ? members : [...members, {
        ...entitiesHelper.getPrivateSummary(),
        firstName: i18n.t('privateUsersFirstNamePlaceHolderChallenge', { n: privateCount }),
        lastName: i18n.t('privateUsersLastNamePlaceHolderChallenge'),
        description: i18n.t('privateUsersDescriptionChallenge'),
    }]
);
export const isLoadingChallengeMembers = (state, id) => coreSelectors.isLoading(state, getRequestId(types.GET_CHALLENGE_MEMBERS.NAME, id));

export const getChallengeInviter = createSelector(getChallenge, item => _.get(item, 'invitation.invitedBy.0'));
export const getChallengeInvitersIds = createSelector(getChallengeInviter, item => item ? [item.userId] : []);


// activities
export const getActivityCategories = state => state[NAME].activities.categories;
export const getActivityCategoriesKeyedBySlug = createSelector(getActivityCategories,
    categories => _.keyBy(_.values(categories), 'activityCategorySlug'));
export const getActivityCategory = (state, id) => getActivityCategories(state)[id];
export const getActivities = state => state[NAME].activities.activities;
export const getActivityUnits = state => state[NAME].activities.activityUnits;
export const getActivityUnitsForChallengeCreation = createSelector(getActivityUnits, activityUnits => (
    _.filter(activityUnits, unit => unit.visible_in_challenge_creation)
));
export const getActivityCategoriesForCreateChallenge = createSelector(getActivityCategories, getActivities,
    (categories, activities) => {
        const categoriesWithVisibleActivities = _.filter(categories, category => {
            const categoryActivities = _.map(category.activities, activityId => activities[activityId]);
            return _.some(categoryActivities, activity => activity.visible_in_challenge_creation);
        });
        return _.map(categoriesWithVisibleActivities, category => {
            const icon = ICON_CATEGORIES[category.activityCategorySlug] || DEFAULT_ICON;
            const activityNames = _.map(_.take(category.activities, 5), activityId => activities[activityId].activityName).join(', ');
            return {
                ...category,
                id: category.activityCategoryId,
                name: category.activityCategoryDisplayName,
                description: `${activityNames}, etc`,
                leftIcon: icon.iconName,
                leftIconColor: icon.bgr,
            };
        });
    });
export const getActivityTimePeriods = state => state[NAME].activities.logs.timePeriods;
export const getVisibleActivityUnits = createSelector(getActivityUnits,
    units => _.filter(units, unit => (_.has(unit, 'visible') ? unit.visible : true)));
export const getActivityLogIds = state => state[NAME].activities.logs.ids;

export const getFilteredActivityLogIds = createSelector(
    getActivityTimePeriods, coreSelectors.getSecondParam,
    (periods, id) => _.get(periods, [id])
);

export const getActivityLogIdsExtended =
    state => state[NAME].activities.logs.idsExtended || state[NAME].activities.logs.ids;
export const getActivityLogItems = state => state[NAME].activities.logs.items;
export const getActivityLogItemsForChallenge = (state, challengeId) => _.get(getChallenge(state, challengeId), 'userActivityLogs', []);

export const getActivityLogs = createSelector(getActivityLogItems, getActivityLogIds,
    (items, ids) => _.map(ids, id => items[id]));
export const getFilteredActivityLogs = createSelector(getActivityLogItems, getFilteredActivityLogIds,
    (items, ids) => _.map(ids, id => items[id]));
export const getActivityLogsExtended = createSelector(getActivityLogItems, getActivityLogIdsExtended,
    (items, ids) => _.map(ids, id => items[id]));
export const getAllActivityLogs = createSelector(getActivityLogs, getActivityLogsExtended,
    (all, extended) => _.uniqBy([...all, ...extended], 'activityLogId'));

const getActivityLogsIdsByFilter = createSelector(
    getActivityTimePeriods, coreSelectors.getSecondParam,
    (items, filterId) => _.get(items, [filterId])
);
export const getActivityLogsByFilterId = createSelector(
    getActivityLogItems, getActivityLogsIdsByFilter,
    (items, ids) => _.map(ids, id => items[id])
);

export const isLoadingActivityLogsExtended = state =>
    coreSelectors.isLoading(state, types.GET_ACTIVITY_LOGS_EXTENDED.NAME);

export const hasMoreActivityLogsExtended = state => state?.challenges?.activities?.logs?.hasMore;

export const getDefaultTimeFilter = createSelector(coreSelectors.getDefaultTimeFilter, filter => {
    return filter ? {
        ...filter,
        label: _.get(filter, 'displayName', ''),
        id: _.get(filter, 'filterId')
    } : null;
});

export const getDefaultActivitiesFilter = createSelector(coreSelectors.getDefaultActivitiesFilter, filter => {
    return {
        ...filter,
        label: _.get(filter, 'displayName', ''),
        id: _.get(filter, 'filterId')
    };
});

export const getActivityLogTabs = createSelector(
    coreSelectors.getTimeFiltersSortedByDefault,
    filters => _.filter(_.map(filters, filter => ({
        label: _.get(filter, 'displayName', ''),
        id: _.get(filter, 'filterId'),
        referenceName: filter.referenceName
    })), tab => tab.referenceName !== CHALLENGE_DURATION_REFERENCE)
);

export const getActivityCategoryTabs = createSelector(getActivityCategories, getVisibleActivityUnits, (categories, activities) => {
    const categoriesArr = _.filter(_.toArray(categories), category => {
        const categoryVisibleActivities = _.filter(activities, activity => activity.activityCategory === category.activityCategoryDisplayName);
        return categoryVisibleActivities.length;
    });
    return _.map(categoriesArr, category => ({
        id: _.get(category, 'activityCategoryId'),
        label: _.get(category, 'activityCategoryDisplayName')
    }));
});

export const getGroupedActivityLogsByFilterId = createSelector(getActivityLogsByFilterId, items => {
    const groupedItems = activitiesHelper.groupActivityLogsByDay(items);
    return _.orderBy(groupedItems, ['date'], ['desc']);
});

export const getGroupedActivityLogsExtended = createSelector(getActivityLogsExtended, items => {
    const groupedItems = activitiesHelper.groupActivityLogsByDay(items);
    return _.orderBy(groupedItems, ['date'], ['desc']);
});

export const getActivityLogsCountByFilterId = createSelector(getActivityLogsByFilterId, items => items.length);
export const getLastActivityIdByFilterId = createSelector(getActivityLogsByFilterId,
    items => (items.length ? _.get(_.last(items), 'activityLogId') : 0));
export const isLoadingActivityLogs = (state, filterId) =>
    coreSelectors.isLoading(state, getRequestId(types.GET_ACTIVITY_LOGS.NAME, filterId));
export const isLoadingFilteredActivityLogs = (state, filterId) =>
    coreSelectors.isLoading(state, getRequestId(types.GET_FILTERED_ACTIVITY_LOGS.NAME, filterId));
export const isLoadingMoreFilteredActivityLogs = (state, filterId) =>
    coreSelectors.isLoading(state, getRequestId(types.GET_MORE_FILTERED_ACTIVITY_LOGS.NAME, filterId));
export const isLoadingActivityLogsNoFilter = state => coreSelectors.isLoading(state, types.GET_ACTIVITY_LOGS.NAME);

const reviseActivityUnitDetails = (activityUnit, activityCategories, activities, units, isShowingAll = false, visibleInLogOnly = false) => {
    if (activityUnit.unit_type === UNIT_TYPES.category) {
        if (activityCategories) {
            const activitiesForCategory = activityCategories[activityUnit.unit_id];
            if (activitiesForCategory && units) {
                let activityUnitDetailsRevised = [];
                _.forEach(activitiesForCategory.activities, activityId => {
                    const activity = activities[activityId];
                    if (visibleInLogOnly) {
                        if (!activity.visible_in_log) return;
                        activityUnitDetailsRevised = [
                            ...activityUnitDetailsRevised,
                            ..._.map(activity.activityUnits, unitId => units[unitId])
                        ];
                    } else if (activity.visible === 1 || isShowingAll) {
                        activityUnitDetailsRevised = [
                            ...activityUnitDetailsRevised,
                            ..._.map(activity.activityUnits, unitId => units[unitId])
                        ];
                    }
                });
                return activityUnitDetailsRevised;
            }
        }
    } else if (units) {
        return [units[activityUnit.unit_id]];
    }
    return [];
};

export const getUnitsForActivityCategory = createSelector(getChallenge, getActivityCategories, getActivities, getActivityUnits,
    ({ activityUnitDetails = [] } = {}, activityCategories, activities, units) => {
        //this block adds the individual activities for each activity category to activityUnitDetails
        let activityUnitDetailsRevised = [];
        _.forEach(activityUnitDetails, activityUnit => {
            if (activityUnit.show_in_tracker === '0') return;
            activityUnitDetailsRevised = [...activityUnitDetailsRevised, ...reviseActivityUnitDetails(activityUnit, activityCategories, activities, units)];
        });
        return activityUnitDetailsRevised;
    });

export const getVisibleInLogUnitsForActivityCategory = createSelector(getChallenge, getActivityCategories, getActivities, getActivityUnits,
    ({ activityUnitDetails = [] } = {}, activityCategories, activities, units) => {
        //this block adds the individual activities for each activity category to activityUnitDetails
        let activityUnitDetailsRevised = [];
        _.forEach(activityUnitDetails, activityUnit => {
            if (activityUnit.show_in_tracker === '0') return;
            activityUnitDetailsRevised = [
                ...activityUnitDetailsRevised,
                ...reviseActivityUnitDetails(activityUnit, activityCategories, activities, units, false, true)
            ];
        });
        return activityUnitDetailsRevised;
    });

// hotfix, it is duplication of previous selector and removed code that we don't need
export const getAllUnitsForActivityCategory = createSelector(getChallenge, getActivityCategories, getActivities, getActivityUnits,
    ({ challengeId, activityUnitDetails = [] } = {}, activityCategories, activities, units) => {
        let activityUnitDetailsRevised = [];
        _.forEach(activityUnitDetails, activityUnit => {
            activityUnitDetailsRevised = [...activityUnitDetailsRevised, ...reviseActivityUnitDetails(activityUnit, activityCategories, activities, units)];
        });
        return activityUnitDetailsRevised;
    });

export const getUnitsForActivityCategoryAll = createSelector(getChallenge, getActivityCategories, getActivities, getActivityUnits,
    ({ activityUnitDetails = [] } = {}, activityCategories, activities, units) => {
        //this block adds the individual activities for each activity category to activityUnitDetails
        let activityUnitDetailsRevised = [];
        _.forEach(activityUnitDetails, activityUnit => {
            activityUnitDetailsRevised = [
                ...activityUnitDetailsRevised,
                ...reviseActivityUnitDetails(activityUnit, activityCategories, activities, units, true)
            ];
        });
        return activityUnitDetailsRevised;
    });
export const getUnitsForActivityCategoryWithName =
    createSelector(getUnitsForActivityCategoryAll, units => _.filter(units, a => _.get(a, 'activityName')));

export const getVisibleInChallengeCreationForCategoryWithName =
    createSelector(getUnitsForActivityCategoryWithName, units =>  _.filter(units, u => u.visible_in_challenge_creation));

export const getVisibleInLogUnitsForCategoryWithName =
    createSelector(getUnitsForActivityCategoryWithName, units => _.filter(units, u => u.visible_in_log));

export const getUnitsForActivityCategoryLimited =
    createSelector(getUnitsForActivityCategoryWithName, coreSelectors.getThirdParam, (units, number) => _.take(units, number));

export const isTracking = (state, id) => coreSelectors.isLoading(state, getRequestId(types.TRACK_ACTIVITY.NAME, id));
export const getTrackError = (state, id) => coreSelectors.getError(state, getRequestId(types.TRACK_ACTIVITY.NAME, id));

export const isDeletingActivityLogs = (state, id) => coreSelectors.isLoading(state, getRequestId(types.DELETE_ACTIVITY_LOGS.NAME, id));
export const getDeletingActivityLogsError = (state, id) => coreSelectors.getError(state, getRequestId(types.DELETE_ACTIVITY_LOGS.NAME, id));

// filter and format activityLogs so that it looks like activities and can be used in the tracker
const prepareActivityLogs = (activityLogs, activities) => {
    const activityUnitIds = _.map(activities, ac => _.toString(_.get(ac, 'activityUnitId')));
    return _.compact(_.map(activityLogs,
        log => (_.includes(activityUnitIds, _.toString(_.get(log, 'activityUnitId'))) || _.get(log, 'inputDevice') !== 'web' ? {
            ...log,
            quantity: _.get(log, 'quantity', '').toString(),
            unitPoints: _.get(log, 'pointsPerUnit'),
            unitName: _.get(log, 'unit'),
            activityCategory: _.get(log, 'categoryName'),
        } : undefined)));
};
export const getPreparedActivityLogsForAllUnits =
    createSelector(getActivityLogsExtended, getVisibleActivityUnits, prepareActivityLogs);

export const getActivityLogsForWeek = createSelector(getActivityLogs, activityLogs => {
    const weekAgo = moment(new Date()).add(-7, 'days').startOf('day');
    return _.filter(activityLogs, log => _.get(log, 'date') && moment(log.date).isAfter(weekAgo));
});

export const getActivityLogsByDate = createSelector(getAllActivityLogs, coreSelectors.getSecondParam,
    (activityLogs, selectedDate) => _.filter(activityLogs, l => _.get(l, 'date') === selectedDate));

export const getPreparedActivityLogsByDate =
    createSelector(getActivityLogsByDate, coreSelectors.getThirdParam, prepareActivityLogs);

export const getPreparedActivityLogsForWeekForAllUnits =
    createSelector(getActivityLogsForWeek, getVisibleActivityUnits, prepareActivityLogs);

export const getPreparedRecentActivities = createSelector(getPreparedActivityLogsForWeekForAllUnits, activities => {
    const uniqueActivities = _.uniqBy(activities, 'activityId');
    const manuallyTrackedActivities = _.filter(uniqueActivities, activity => activity.inputDevice !== AUTOMATICALLY_TRACKED_ACTIVITY_INPUT_DEVICE);
    return manuallyTrackedActivities;
});


// for single challenge

export const getChallengeCategoryId =
    (state, challengeId) => Number(_.get(getChallenge(state, challengeId), 'activityUnitDetails[0].activity_id', 0));
export const getChallengeCategoryName =
    (state, challengeId) => String(_.get(getChallenge(state, challengeId), 'activityUnitDetails[0].activityCategory'));
export const getChallengeCategorySlug = createSelector(getActivities, getChallengeCategoryId, getChallengeCategoryName,
    (activities, activityId, categoryName) => {
        if (!activityId) return categoryName ? _.toLower(categoryName) : undefined;
        return _.get(activities, [activityId], {}).activityCategorySlug;
    });

export const isWithOther = (state, challengeId) => !getChallenge(state, challengeId).isSolo;
// export const isWithOther = createSelector(getChallenge, challenge => !challenge.isSolo);

export const getRecommendedGoalIcon = (state, challengeId) => _.get(getChallenge(state, challengeId), 'icon_url');
export const getGoalChallengeIcon = (state, challengeId) => _.get(getChallenge(state, challengeId), 'challengeIconURL');

export const getHeaderIcon = createSelector(getChallengeCategorySlug, isWithOther, getGoalChallengeIcon, coreSelectors.isLiveBetter,
    (slug, isWithOther, icon, isLiveBetter) => {
        if (icon && !isLiveBetter) return { iconUrl: icon };
        if (isWithOther) return ICON_CATEGORIES.challenge;
        return { ...ICON_CATEGORIES.goal, icon: _.get(ICON_CATEGORIES, [slug, 'bgr'], DEFAULT_ICON.bgr) };
    });

export const getHeaderIconRecommendedGoal = createSelector(getRecommendedGoal, getRecommendedGoalIcon, coreSelectors.isLiveBetter, (goal, icon, isLiveBetter) => {
    if (icon && !isLiveBetter) return { iconUrl: icon };
    return { ...ICON_CATEGORIES.goal, icon: helper.getRecommendedGoalIconColor(goal) };
});

export const isAfterDeadline = createSelector(getChallenge, helper.isAfterDeadline);

export const getConnectedDeviceName = createSelector(getChallenge, helper.getConnectedDeviceName);
export const getAllConnectedDevicesNames = createSelector(getChallenge, helper.getAllConnectedDevicesNames);

export const getRemainingInfo = createSelector(getChallenge, helper.getRemainingInfo);

export const getEligableActivities = createSelector(getChallenge, helper.getEligableActivities);

export const getPeriodSingleTrackedActivityValue = createSelector(getChallenge, helper.getPeriodSingleTrackedActivityValue);

export const getOverallSingleTrackedActivityValue = createSelector(getChallenge, helper.getOverallSingleTrackedActivityValue);

export const getActivitiesToDisplay = createSelector(getChallenge, getEligableActivities, helper.getActivitiesToDisplay);

export const getCategoryColor = createSelector(getChallengeCategorySlug, slug => _.get(ICON_CATEGORIES, [slug, 'bgr'], DEFAULT_ICON.bgr));

export const getIsTeamChallenge = createSelector(getChallenge, challenge => _.get(challenge, 'challengeEntityType') === CHALLENGE_ENTITY_TYPES.group);

export const getIsCompletedChallenge = createSelector(getChallenge, helper.isCompleted);

export const getIsInGracePeriodChallenge = createSelector(getChallenge, helper.isInGracePeriod);

export const getIsAfterDeadline = createSelector(getChallenge, helper.isAfterDeadline);

export const getIsPersonalChallenge = createSelector(getChallenge, helper.isPersonal);

export const getChallengeStartDate = createSelector(getChallenge, challenge => {
    return challenge.startDate;
});

export const isTrackByDevice = createSelector(getChallenge, challenge => {
    // identify if all activities are tracking by device, return true or false
    const activityUnitDetailsArr = _.get(challenge, 'activityUnitDetails');
    if (_.isUndefined(activityUnitDetailsArr) || activityUnitDetailsArr.length === 0) {
        return false;
    }
    const activityUnitsTrackedByDeviceArr =
        _.filter(activityUnitDetailsArr, { unit_type: UNIT_TYPES.unit, show_in_tracker: '0' });
    return activityUnitDetailsArr.length === activityUnitsTrackedByDeviceArr.length;
});

export const isTeamSizeIncorrect = createSelector(getChallenge, challenge => {
    const { userEntity, minTeamMembers } = challenge;
    return _.get(userEntity, 'entityType') === CHALLENGE_ENTITY_TYPES.group &&  _.get(userEntity, 'numberOfUsers', 0) < minTeamMembers;
});

export const getChallengeProgress = createSelector(getChallenge, getActivityLogsExtended, coreSelectors.getThirdParam, getUnitsForActivityCategory,
    (challenge, activityLogs, isCurrentWeek, challengeUnits) => {
        const dailyProgress = helper.getDailyProgress(challenge, activityLogs, isCurrentWeek, _.map(challengeUnits, 'unitId'));
        return {
            dailyProgress,
            index: _.findIndex(dailyProgress, day => day.date === helper.getStartOfWeek())
        };
    });

export const getChallengeProgressByUserActivityLogs = createSelector(getChallenge, coreSelectors.getThirdParam, getAllUnitsForActivityCategory,
    (challenge, isCurrentWeek, challengeUnits) => {
        const { restartGoalPreviousPeriods } = challenge.progress;

        const restartGoalActivityLogs = () => {
            const activityLogsArray = [...challenge.userActivityLogs];
            restartGoalPreviousPeriods && Object.values(restartGoalPreviousPeriods).map(item => item.userActivityLogs.map(activity => activityLogsArray.push(activity)));
            return _.sortBy(activityLogsArray, 'date');
        };

        const formattedActivityLogs = challenge.isSolo && challenge.restartGoalTs ? restartGoalActivityLogs() : challenge.userActivityLogs;
        const dailyProgress = helper.getDailyProgress(challenge, formattedActivityLogs || [], isCurrentWeek, _.map(challengeUnits, 'unitId'));

        return {
            dailyProgress,
            index: _.findIndex(dailyProgress, day => day.date === helper.getStartOfWeek())
        };
    });

export const getActiveChallengesForDate = createSelector(getMyOrInvitedChallenges, coreSelectors.getSecondParam, coreSelectors.getFirstParam,
    (challenges, activities, state) => _.uniq(_.flatten(_.map(activities, ac => _.compact(_.map(challenges, ch => {
        const unitIds = _.map(getUnitsForActivityCategory(state, ch.challengeId), 'unitId');
        return _.includes(unitIds, ac.activityUnitId) && helper.isDateInChallengeRange(ac.date, ch) ? ch.challengeId : undefined;
    }))))));

export const isUpdatingAfterTracking = state => coreSelectors.isLoading(state, types.UPDATE_AFTER_TRACKING.NAME);

const getUserObject = (state, teamNumberId) => (teamNumberId ? _.find(state.sprout.challengeTeams.teams, item => Number(item.teamId) === Number(teamNumberId)) : null);

export const getTeamUserObject = createSelector(getUserObject, coreSelectors.getCurrentUserId,
    (teamObject, userId) => (teamObject ? (_.find(teamObject.challengers, item => Number(item.id) === Number(userId))) : null));

export const getTeams = state => state[NAME].teams;
export const getChallengeTeamById = (state, id) => getTeams(state)[id];
export const getChallengeTeam =
    createSelector(getChallenge, getTeams, (challenge, teams) => teams[_.get(challenge, 'userEntity.id')]);
export const getChallengeTeamMember = createSelector(getChallengeTeam, coreSelectors.getCurrentUserId,
    (team, userId) => (team ? _.find(team.challengers, { id: userId }) : null));

export const getCompetitionScore = createSelector(getChallengeTeamMember, getChallenge, coreSelectors.getCustomPointsUnit,
    (teamMember, challenge, customPointsUnit) => teamMember ? pointsHelper.formatPoints(teamMember.score, customPointsUnit)
        : pointsHelper.formatPoints(challenge.progress.overallUserValue, customPointsUnit));

export const getCompetitionTeamScore = createSelector(getChallenge, challenge => pointsHelper.formatPointsValue(challenge.progress.overallUserValue));

export const getTeamDetails = createSelector(getChallenge, coreSelectors.getThirdParam, (challenge, teamId) =>
    teamId && _.find(_.get(challenge, 'participants') || [], e => e.entityId && e.entityId.toString() === teamId.toString()));
export const getTeamData = createSelector(getChallengeMembers, coreSelectors.getThirdParam, (members, teamId) =>
    teamId && _.find(members || [], m => m.entityId && m.entityId.toString() === teamId.toString()));

export const getTeamCurrentMembersWithoutCurrentUser = createSelector(getChallengeTeamById, coreSelectors.getCurrentUserId,
    (team, userId) => _.filter(_.get(team, 'challengers', []), c => c.deleted === 0 && c.id !== userId.toString()));
export const getTeamCurrentMembers = createSelector(getChallengeTeamById,
    team => _.filter(_.get(team, 'challengers', []), c => c.deleted === 0));
export const getTeamFormerMembers = createSelector(getChallengeTeamById,
    team => _.orderBy(_.filter(_.get(team, 'challengers', []), c => c.deleted === 1), ['score'], ['desc']));

export const isCreatingTeam = state => coreSelectors.isLoading(state, types.CREATE_TEAM.NAME);
export const isEditingTeam = state => coreSelectors.isLoading(state, types.EDIT_TEAM.NAME);
export const getCreateEditTeamError = state => coreSelectors.getError(state, types.EDIT_TEAM.NAME);

const getActivityCategoryBySlug = createSelector(getActivityCategoriesKeyedBySlug, coreSelectors.getSecondParam,
    (items, slug) => items[slug]);
export const getUncompletedPersonalGoalsCount = createSelector(getMyPersonalGoals, items => items.length);
export const getUncompletedPersonalGoalsByCategory = createSelector(getMyPersonalGoals, getActivityCategoryBySlug, getActivities,
    (goals, activityCategory, activities) => {
        if (!_.get(activityCategory, 'activities')) return [];
        return _.filter(goals, item => {
            const activityId = Number(_.get(item, 'activityUnitDetails[0].activity_id', 0));
            if (activityId) {
                const index = _.indexOf(activityCategory.activities, activityId);
                if (index === -1) {
                    return null;
                }
                return _.find(activities, { activityId });
            }
            const category = _.get(item, 'activityUnitDetails[0].activityCategory');
            return activityCategory.activityCategorySlug === _.toLower(category);
        });
    }
);

const getRecommendedGoalsByCategory = createSelector(
    getRecommendedGoals, coreSelectors.getSecondParam,
    (items, slug) => _.filter(items, item => item.categorySlug === slug)
);
export const getGoalsByCategory = createSelector(getRecommendedGoalsByCategory, getUncompletedPersonalGoalsByCategory,
    (recItems, allItems) => {
        let items = allItems;
        _.forEach(recItems, rItem => {
            const setValue = _.find(allItems, { challengeName: rItem.goalName });
            if (!setValue) items = [...items, rItem];
        });
        return items;
    }
);
export const isAllPossibleGoalsSet = createSelector(getUncompletedPersonalGoalsCount, goalsCount => goalsCount >= MAX_PERSONAL_GOALS);

export const getSettedGoalsByCategory = createSelector(getRecommendedGoalsByCategory, getUncompletedPersonalGoalsByCategory,
    (recItems, allItems) => {
        let items = allItems;
        _.forEach(recItems, rItem => {
            const setValue = _.find(allItems, { challengeName: rItem.goalName });
            if (!setValue) items = [...items, rItem];
        });
        return items;
    }
);

export const getActivityLogsByDay = createSelector(getActivityLogsExtended, coreSelectors.getSecondParam, (logs, limit) => {
    return activitiesHelper.groupActivityLogsByDay(_.take(logs, limit));
});

/***
 * For getting correct number of logs by day
 * we need to make a flat list and return it length
 */
export const getActivityLogsByDayLength = createSelector(getActivityLogsExtended, logs => {
    return _.flatMap(logs, (n) => [n]).length;
});

const today = moment(new Date()).format(DATE_FORMATS.full);
const yesterday = moment(new Date()).add(-1, 'days').format(DATE_FORMATS.full);
const begOfWeek = moment().startOf('week');

const getTodayLogs = createSelector(getActivityLogs, logs => _.filter(logs, l => l.date === today));
const getYesterdayLogs = createSelector(getActivityLogs, logs => _.filter(logs, l => l.date === yesterday));
const getThisWeekLogs = createSelector(
    getActivityLogs,
    logs => _.filter(activitiesHelper.groupActivityLogsByDay(logs), l => begOfWeek <= moment(l.date, DATE_FORMATS.full))
);
const getThisMonthLogs = createSelector(getActivityLogs, logs => activitiesHelper.groupActivityLogsByDay(logs));

export const getActivitiesToday = createSelector(
    getTodayLogs, coreSelectors.getSecondParam,
    (devices, showHeader) => ([devices, showHeader])
);
export const getActivitiesYesterday = createSelector(
    getYesterdayLogs, coreSelectors.getSecondParam,
    (devices, showHeader) => ([devices, showHeader])
);
export const getActivitiesThisWeek = createSelector(
    getThisWeekLogs, coreSelectors.getSecondParam,
    (devices, showHeader) => ([devices, showHeader])
);
export const getActivitiesThisMonth = createSelector(
    getThisMonthLogs, coreSelectors.getSecondParam,
    (devices, showHeader) => ([devices, showHeader])
);

export const getActivityLogById = (state, id) => getActivityLogItems(state)[id];

export const getProgressPeriods = createSelector(getChallenge, isRestartGoal, (challenge, isRestartGoal) => {
    const periods = _.get(challenge, 'progress.periods', []);
    const previousPeriods = _.orderBy(_.values(_.get(challenge, 'progress.restartGoalPreviousPeriods')), ['startDate'], ['desc']);
    const periodsFormatted = [...periods, ...previousPeriods];
    const periodsWithDate = challenge && _.map(periodsFormatted, period => ({ ...period, date: periodHelper.startDateForPeriod(period, challenge) }));
    return _.orderBy(periodsWithDate, ['date'], ['desc']);
});

export const getRestartGoalPeriods = createSelector(getChallenge, challenge => {
    if (!challenge) return [];
    const { progress: { userPercentValue, restartGoalPreviousPeriods, userPointsValue },
        challengeId, challengeDeadline, restartIndex, startDate } = challenge;
    const currentPeriod = {
        challengeId,
        endDate: challengeDeadline,
        restartGoalPeriodIndex: restartIndex,
        startDate,
        totalPercentage: userPercentValue,
        totalScore: _.toString(userPointsValue),
    };
    const periods = [currentPeriod, ..._.values(restartGoalPreviousPeriods)]; // currently server can return prev periods both as object and array
    return _.orderBy(periods, ['startDate'], ['desc']);
});

export const getProgressPeriodByIndex = createSelector(getChallenge, coreSelectors.getThirdParam, isRestartGoal,
    (challenge, index, isRestartGoal) => {
        if(!challenge) return {};
        // The server sends index sometimes as a string, sometimes as an int!
        // eslint-disable-next-line eqeqeq
        const period = _.find(challenge.progress[isRestartGoal ? 'restartGoalPreviousPeriods' : 'periods'],
            period => period[isRestartGoal ? 'restartGoalPeriodIndex' : 'index'] === index
        );
        return { ...period, date: periodHelper.startDateForPeriod(period, challenge) };
    }
);

export const getPointsForPeriod = createSelector(getProgressPeriodByIndex, getChallenge, coreSelectors.getCustomPointsUnit, coreSelectors.getFourthParam,
    (period, challenge, customPointsUnit, restartGoalPeriod) =>
        pointsHelper.formatPoints(periodHelper.pointsForPeriod(restartGoalPeriod || period, challenge), customPointsUnit));

export const getRelevantActivitiesGrouped = createSelector(getProgressPeriodByIndex, getChallenge, getActivityLogsExtended, coreSelectors.getFourthParam,
    (...params) => _.groupBy(activitiesHelper.groupActivityLogsByDay(periodHelper.relevantActivitiesForPeriod(...params), true), 'date'));

export const getActivitiesForRestartGoal = createSelector(coreSelectors.getThirdParam, getChallenge, getActivityLogsExtended, coreSelectors.getFourthParam,
    (...params) => _.groupBy(activitiesHelper.groupActivityLogsByDay(periodHelper.relevantActivitiesForPeriod(...params), true), 'date'));

export const getArrayOfDatesInPeriod = createSelector(getProgressPeriodByIndex, getChallenge, coreSelectors.getFourthParam,
    (period, challenge, restartGoalPeriod) => periodHelper.arrayOfDatesInPeriod(restartGoalPeriod || period, challenge));

export const getActivityLogsForChallengeByDay = createSelector(getActivityLogItemsForChallenge,
    activityLogs => _.orderBy(activitiesHelper.groupActivityLogsByDay(activityLogs, true),
        ['date'], ['desc']));

export const getActivityLogsForChallengeByWeek = createSelector(getActivityLogItemsForChallenge, coreSelectors.getThirdParam, getChallenge, isRestartGoal,
    (activityLogs, startDate, challenge, isRestartGoal) => {
        let logs = activityLogs;
        let challengeStartDate = startDate;
        if (isRestartGoal) {
            const previousPeriods = _.values(_.get(challenge, 'progress.restartGoalPreviousPeriods'));
            const previousPeriodsLogs = _.flatMap(previousPeriods, period => period.userActivityLogs);
            logs = [...previousPeriodsLogs, ...activityLogs];
            if (previousPeriods.length) challengeStartDate = previousPeriods[0].startDate;
            return activitiesHelper.groupActivityLogsByWeek(logs, challengeStartDate, isRestartGoal, challenge.challengeDeadline, challenge.isEnded);
        } else {
            let allLogs = [];
            const periods = _.get(challenge.progress, 'periods');
            if (periods) {
                _.forEach(periods, period => {
                    const periodLogs = activitiesHelper.groupActivityLogsByWeekPeriod(logs, period.startDate, period.endDate, period.isCurrent, period.index);
                    allLogs = _.concat(allLogs, periodLogs);
                });
            }
            return allLogs;
        }
    });

export const getActivityLogsForChallengeByDayForDate =
    createSelector(getActivityLogItemsForChallenge, coreSelectors.getThirdParam, (activityLogs, date) => {
        return activitiesHelper.groupActivityLogsByDayObject(activityLogs, true)[date];
    });

export const getCreateChallengeStep = state => state[NAME].createChallengeStep;
export const getCreateChallengeSteps = state => state[NAME].createChallengeSteps;
export const getNewChallenge = state => state[NAME].newChallenge;
export const isCreatingChallenge = state => coreSelectors.isLoading(state, types.CREATE_CHALLENGE.NAME);
export const getCreateChallengeError = state => coreSelectors.getError(state, types.CREATE_CHALLENGE.NAME);

export const isShownFirstActivityTrackModal = state => state[NAME].isShownFirstActivityTrackModal;
export const isHiddenActivityInformationText = state => state[NAME].isHiddenActivityInformationText;

export const getUniqueActivitiesCategories = createSelector(getActivityCategories,
    categories => {
        return _.uniqBy(_.values(categories),
            'activityCategorySlug');
    }
);

export const getLoggedCategoriesArray = createSelector(getFilteredActivityLogs,
    logs => {
        const loggedCategories = {};
        _.forEach(logs, log => {
            if (!loggedCategories[log.categoryName]) loggedCategories[log.categoryName] = 0;
            loggedCategories[log.categoryName] += _.get(log, 'points', 0);
        });
        return loggedCategories;
    }
);
export const getCategoriesWithValues = createSelector(getUniqueActivitiesCategories, getLoggedCategoriesArray,
    (categories, points) => {
        const initCategories = _.map(categories, item => ({
            slug: item.activityCategorySlug,
            name: item.activityCategoryDisplayName,
            result: 0
        }));
        const categoriesData = _.forEach(initCategories, activity => {
            const updatedActivity = activity;
            _.forIn(points, (value, key) => {
                if (activity.slug === key.toLowerCase()) {
                    updatedActivity.result = value;
                }
            });
        });
        const filteredCategories = _.filter(categoriesData, item => item.result);
        return filteredCategories.length ? _.orderBy(filteredCategories, ['result'], ['desc']) : [];
    }
);

export const getMaxLoggedValue = createSelector(getCategoriesWithValues,
    categories => { return _.get(_.maxBy(categories, 'result'), 'result'); }
);

export const getScoreLeaderboards = state => state[NAME].allLeaderBoards;
export const isLoadingLeaderboards = state => coreSelectors.isLoading(state, types.GET_LEADERBOARDS.NAME);

export const getFormattedTimeFilters = createSelector(coreSelectors.getTimeFilters,
    filters => _.map(filters, item => ({ id: item.filterId, label: item.displayName, referenceName: item.referenceName })));

export const getFormattedChallenges = createSelector(getMyChallenges,
    filters => _.map(filters, item => ({ id: item.challengeId, label: item.challengeName })));

export const getLeaderboardChallenges = createSelector(getMyChallenges,
    filters => _.filter(filters, item => item.challengeType !== CHALLENGE_TYPES.goal));

export const getFormatedLeaderboardChallenges = createSelector(getLeaderboardChallenges,
    filters => _.map(filters, item => ({ id: item.challengeId, label: item.challengeName })));

export const getFormattedActivities = createSelector(coreSelectors.getActivitiesFiltersWithSubFilters,
    filters => _.map(filters, item => ({ id: item.filterId, label: item.displayName })));

export const getIsCarouselInstructionalSlideDismissed = state => state[NAME].carouselInstructionalSlideDismissed;

export const getDeviceActivityLogsByDay = createSelector(getActivityLogs, coreSelectors.getSecondParam, (logs, vendorId) => {
    const deviceLogs = _.filter(logs, log => _.get(log, 'vendorId') && log.vendorId === vendorId);
    return activitiesHelper.groupActivityLogsByDay(deviceLogs);
});

export const getDeviceActivityLogs = createSelector(getActivityLogs, coreSelectors.getSecondParam, (logs, vendorId) => {
    const deviceLogs = _.filter(logs, log => _.get(log, 'vendorId') && log.vendorId === vendorId);
    return deviceLogs.length >= 1 ? deviceLogs : [];
});

export const getDeviceMostRecentActivity = createSelector(getDeviceActivityLogs, logs => {
    const deviceLogs = _.take(logs, 1);

    return deviceLogs.length === 1 ? _.get(deviceLogs[0].logs, [0], null) : null;
});

export const isChallengeUpdating = (state, id) => coreSelectors.isLoading(state, getRequestId(types.UPDATE_CHALLENGE.NAME, id));

const getBonusChallengesConfiguration = createSelector(coreSelectors.getBonusChallengesConfiguration, config => {
    return config ? config : {};
});

export const getIsBonusChallengesEnabled = createSelector(getBonusChallengesConfiguration, config => {
    return _.get(config, 'enabled', 0);
});

export const getBonusChallengesDescription = createSelector(getBonusChallengesConfiguration, config => {
    return _.get(config, 'description', '');
});

export const getBonusChallengesTitle = createSelector(getBonusChallengesConfiguration, config => {
    return _.get(config, 'title', '');
});

export const getBonusChallengesToDisplay = createSelector(getBonusChallenges, getChallenges, coreSelectors.getSecondParam, (bonusChallenges, allChallenges, count) => {
    if (bonusChallenges.length > 0) {
        return count ? _.take(bonusChallenges, count) : bonusChallenges;
    }

    if (allChallenges.length > 0) {
        const endingSoon = _.orderBy(_.filter(allChallenges, challenge => !helper.isCompleted(challenge) && helper.isProgramGoal(challenge)), 'challengeDeadline', 'asc');
        if (endingSoon.length >= 3 && count) {
            return count ? _.take(endingSoon, count) : endingSoon;
        }

        const startingSoon = _.orderBy(_.filter(allChallenges, challenge => helper.isUpcoming(challenge) && helper.isProgramGoal(challenge)), 'startDate', 'asc');

        const returnChallenges = _.union(endingSoon, startingSoon);
        return count ? _.take(returnChallenges, count): returnChallenges;
    }

    return [];
});

export const getChallengeType = createSelector(getChallenge, challenge => {
    return i18n.t(helper.isGoalType(challenge) && !helper.isPersonal(challenge)
        ? ENTITIES.challenge
        : _.get(challenge, 'challengeType', ENTITIES.challenge)
    ).toLowerCase();
});

export const getUnitSingularById = createSelector(getActivityUnits, coreSelectors.getSecondParam,
    (activityUnits, unitId) => _.get(activityUnits[unitId], 'unitSingular'));

export const isCarouselUnfilled = createSelector(getChallengesIds, getChallengesIdsCount, (ids, count) => {
    const hasIds = !!Number(_.get(ids, 'length'));
    return count === 0 ? false : !hasIds;
});

export const getDefaultTimeFiltersId = (state) => {
    const timeFilters = coreSelectors.getTimeFiltersOriginal(state);
    const filterId = _.filter(timeFilters, (item) => item.referenceName === TIME_FILTER_4_MONTHS)[0]?.filterId ?? DEFAULT_TIME_ID;
    return filterId;
};
