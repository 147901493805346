import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { AsyncComponent, components as Core } from '../../../core';
import { CREATE_COMMUNITY_STEPS, CREATE_COMMUNITY_STEPS as STEPS } from '../../constants';
import WithCreateCommunityBase from './CreateCommunityBase';

const SHOW_NEXT_BUTTON = [STEPS.communityName, STEPS.communityDescription];
const SHOW_CREATE_BUTTON = [STEPS.image];

class CreateCommunity extends PureComponent {
    static propTypes = {
        title: PropTypes.string.isRequired,
        step: PropTypes.string,
        openNextPage: PropTypes.func.isRequired,
        localSteps: PropTypes.array,
        localStep: PropTypes.string,
        isCreating: PropTypes.bool,
        updateLocalRegistrationStep: PropTypes.func.isRequired,
        i18n: PropTypes.object.isRequired,
        close: PropTypes.func.isRequired,
        clear: PropTypes.func.isRequired
    };

    static defaultProps = {
        step: STEPS.communityName,
        localSteps: [],
        localStep: STEPS.communityName,
        isCreating: false,
    };

    static COMPONENTS = {
        [STEPS.communityName]: AsyncComponent(() => import('./CreateCommunityName')),
        [STEPS.communityDescription]: AsyncComponent(() => import('./CreateCommunityDescription')),
        [STEPS.image]: AsyncComponent(() => import('./CreateCommunityImage')),
        [STEPS.joinExisting]: AsyncComponent(() => import('./CreateCommunityJoin')),
    };

    constructor(props) {
        super(props);
        this.propsToPass = {
            onPress: props.openNextPage,
            onClose: props.close,
            onFinish: props.clear,
            onCancel: this.cancelFlow,
            step: props.step
        };
        this.state = { localSteps: [props.step], joinExistingComponent: false };
        this.props.updateLocalRegistrationStep(props.step);
    }

    get cancelModalData() {
        const { i18n } = this.props;
        const actionType = i18n.t('createEntity.action.creating');
        const entity = i18n.t('createEntity.type.community');
        return {
            title: i18n.t('createEntity.cancel.title', { entity, actionType }),
            text: i18n.t('createEntity.cancel.message', { entity, actionType })
        };
    }

    get componentType() {
        return this.props.localStep;
    }

    updateLocalSteps = step => {
        this.setState(prevState => {
            const index = _.findIndex(prevState.localSteps, s => s === step);
            const localSteps = index === -1 ? [...prevState.localSteps, step] : _.take(prevState.localSteps, index + 1);
            return ({ localSteps });
        });
    }

    openNextPage = screenConfig => this.updateLocalSteps(screenConfig.step);

    onGoBack = () => {
        const { localStep } = this.props;
        if (!localStep || !_.includes(this.state.localSteps, localStep)) return;
        const prevStep = this.state.localSteps[_.indexOf(this.state.localSteps, localStep) - 1];
        if(prevStep) {
            this.props.updateLocalRegistrationStep(prevStep);
            this.updateLocalSteps(prevStep);
        }
    }

    cancelFlow = () => {
        if (!this.state.joinExistingComponent) {
            this.props.clear();
            this.props.close();
        }
    }

    closeBtnAction = () => {
        this.props.clear();
        this.props.close();
    }

    get component() {
        this.setState({ joinExistingComponent: this.componentType === CREATE_COMMUNITY_STEPS.joinExisting });
        return _.get(CreateCommunity.COMPONENTS, this.componentType);
    }

    renderStep = props => {
        const LocalComponent = this.component;
        return (<LocalComponent
            step={this.componentType}
            openInNewWindow={this.state.joinExistingComponent}
            {...props}
        />);
    }

    render() {
        const { title, isCreating } = this.props;
        return (
            <Core.Steps
                propsToPass={this.propsToPass}
                componentType={this.componentType}
                cancelFlow={this.closeBtnAction}
                title={title}
                isCloseButtonShown={true}
                goBack={this.onGoBack}
                showNextScreens={SHOW_NEXT_BUTTON}
                showCreateScreens={SHOW_CREATE_BUTTON}
                isCreating={isCreating}
                cancelModalData={this.cancelModalData}>
                {this.renderStep}
            </Core.Steps>
        );
    }
}

export default withRouter(WithCreateCommunityBase(CreateCommunity));
