import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { StyleSheet, css } from 'aphrodite-jss';

import Icon from '../Icon';
import WithInfoSectionBase, { styles as baseStyles } from './InfoSectionBase';
import { baseColors, spacing, layoutStyle } from '../../../../styles';

const ICON_SIZE = spacing.s7;

class InfoSection extends PureComponent {
    static propTypes = {
        iconName: PropTypes.string.isRequired,
        iconColor: PropTypes.string,
        title: PropTypes.string,
        titleComponent: PropTypes.element,
        isTouchable: PropTypes.bool,
        subtitle: PropTypes.string.isRequired,
        onPress: PropTypes.func
    };

    static defaultProps = {
        title: undefined,
        isTouchable: false,
        iconColor: baseColors.colourBonus,
        titleComponent: null,
        onPress: null
    };

    get renderTitle() {
        return this.props.titleComponent || <div className={css(styles.title)}>{this.props.title || ''}</div>;
    }

    onContainerClick = () => this.props.onPress ? this.props.onPress() : null;

    render() {
        return (
            <div className={css(styles.innerContainer, layoutStyle.flex)}>
                <button onClick={this.onContainerClick} className={css(styles.buttonFlex)}>
                    <Icon
                        color={this.props.iconColor}
                        name={this.props.iconName}
                        type="fa"
                        size={ICON_SIZE}
                        className={css(styles.iconToCenter)}
                        containerStyle={[styles.icon]}
                    />
                    <div className={css(layoutStyle.flexColumn, styles.dataContainer)}>
                        {this.renderTitle}
                        <p className={css(styles.subtitle)}>
                            {this.props.subtitle}
                        </p>
                    </div>
                </button>
            </div>
        );
    }
}

export default WithInfoSectionBase(InfoSection);

const styles = StyleSheet.create({
    ...baseStyles,
    innerContainer: {
        ...baseStyles.innerContainer,
        margin: spacing.s3,
        boxShadow: baseColors.shadowHighest,
    },
    dataContainer: {
        ...baseStyles.dataContainer,
    },
    buttonFlex: {
        display: 'flex',
        flex: 1
    },
    iconToCenter: {
        alignSelf: 'center'
    }
});
