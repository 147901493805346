import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { translate, pointsHelper, ICON_CATEGORIES, selectors as coreSelectors } from '../../../core';
import * as selectors from '../../selectors';
import * as actions from '../../actions';
import { appFonts, baseColors, spacing } from '../../../../styles';

export default function WithTaskCardBase(WrappedComponent) {
    class TaskCardBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            task: PropTypes.object.isRequired,
            customPointsName: PropTypes.string.isRequired,
            customPointsUnit: PropTypes.string.isRequired,
        };

        get title() {
            // todo: add to vocab
            return this.props.task.title || 'Sample Task';
        }

        get subtitle() {
            return this.props.task.subtitle || this.props.i18n.t('build_your_profile');
        }

        get description() {
            const description = _.get(this.props.task, 'description');
            return _.replace(description, '%{point_name}', this.props.customPointsName);
        }

        get points() {
            const { task } = this.props;
            const points = _.get(task, 'points', 0);
            const bonusPoints = _.get(task, 'bonusPoints', 0);
            return (bonusPoints || points) && pointsHelper.formatPoints(bonusPoints || points, this.props.customPointsUnit);
        }

        get label() {
            return this.props.task.action_text ? this.props.task.action_text : this.props.i18n.t('letsGo');
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    title={this.title}
                    subtitle={this.subtitle}
                    description={this.description}
                    points={this.points}
                    label={this.label}
                    icon={{ ...ICON_CATEGORIES.clipboard, icon: baseColors.primary }}
                />
            );
        }
    }

    const mapStateToProps = state => ({
        screens: selectors.getScreens(state),
        customPointsName: coreSelectors.getCustomPointsName(state),
        customPointsUnit: coreSelectors.getCustomPointsUnit(state)
    });

    const mapDispatchToProps = dispatch => ({
        actions: bindActionCreators(actions, dispatch)
    });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(TaskCardBase));
}

export const styles = {
    bodyText: {
        ...appFonts.lgMedium,
        textAlign: 'center',
        color: baseColors.black,
        paddingTop: spacing.s5
    },
    textContainer: {
        flex: 2
    },
    bonusContainerInner: {
        flex: 0,
        flexDirection: 'row'
    },
    bonusText: {
        ...appFonts.xlBold,
        textAlign: 'center'
    }
};
