import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { appFonts, baseColors, spacing, windowSize } from '../../../../styles';
import { translate, selectors as coreSelectors, MAX_COUNT } from '../../../core';
import { selectors as challengeSelectors, constants as challengeConstants, actions as challengeActions } from '../../../challenges';
import * as rewardsActions from '../../actions';
import * as selectors from '../../selectors';

const BONUS_CHALLENGES_COUNT = 3;

export default function withHowToEarnBase(WrappedComponent) {
    class HowToEarnBase extends Component {
        static propTypes = {
            rewards: PropTypes.object,
            actions: PropTypes.object,
            achievements: PropTypes.array,
            transactionsAmount: PropTypes.number,
            isLoadingAchievements: PropTypes.bool,
            isLoadingChallengesList: PropTypes.bool,
            i18n: PropTypes.object.isRequired,
            fullProgramName: PropTypes.string,
            rewardsCurrencyName: PropTypes.string.isRequired,
            programName: PropTypes.string,
            isLoadingOrders: PropTypes.bool,
            earnPartners: PropTypes.array,
            isLoadingEarnPartners: PropTypes.bool,
            isEarnPartnersEnabled: PropTypes.number.isRequired,
            earnPartnersDescription: PropTypes.string.isRequired,
            earnPartnersProgramName: PropTypes.string.isRequired,
            pointsName: PropTypes.string.isRequired,
            bonusChallengesTitle: PropTypes.string.isRequired,
            bonusChallengesDescription: PropTypes.string.isRequired,
            isBonusChallengesEnabled: PropTypes.number.isRequired,
            bonusChallenges: PropTypes.array,
            externalRewardName: PropTypes.string.isRequired,
        };

        static defaultProps = {
            rewards: {},
            fullProgramName: '',
            programName: '',
            achievements: [],
            transactionsAmount: 0,
            isLoadingAchievements: false,
            isLoadingChallengesList: false,
            isLoadingOrders: false,
            actions: {},
            earnPartners: [],
            isLoadingEarnPartners: false,
            bonusChallenges: []
        };

        constructor(props) {
            super(props);
            props.actions.getAchievements({ getColourfulBadge: 1 });
            props.actions.getEarnPartners();
            props.actions.getChallengesList({ filter: challengeConstants.DISPLAY_TYPES.BONUS, maxCount: MAX_COUNT, start: 0 }, true);
        }

        get isLoading() {
            return this.props.isLoadingAchievements || this.props.isLoadingChallengesList || this.props.isLoadingEarnPartners;
        }

        get items() {
            return this.props.achievements;
        }

        get partnerItems() {
            return this.props.earnPartners;
        }

        get bonusChallengeItems() {
            const rewardBonusChallenges = _.filter(this.props.bonusChallenges, ch => !!ch.potentialBonusCurrency);
            return _.take(rewardBonusChallenges, BONUS_CHALLENGES_COUNT);
        }

        get title() {
            const { externalRewardName, i18n } = this.props;
            return i18n.t('rewardsHowDoIEarn', { currencyName: externalRewardName });
        }

        get rewardDetailsText() {
            return this.props.i18n.t('rewardsDetails');
        }

        get sectionsInfoProps() {
            const { i18n, externalRewardName } = this.props;
            const currencyName = { currency: externalRewardName };

            return {
                levelsTitle: i18n.t('levelUpTitle'),
                viewAllText: i18n.t('viewAllButton'),
                achievementsTitle: i18n.t('achievements'),
                browsePartnersText: i18n.t('browseAllPartners'),
                bonusChallengesTitle: i18n.t('bonusChallenges'),
                earnPartnersTitle: i18n.t('earnWithPartners.title'),
                levelsDescription: i18n.t('levelUpDescription', currencyName),
                earnPartnersDescription: i18n.t('earnWithPartners.description'),
                achievementsDescription: i18n.t('achievementsDescription', currencyName),
                bonusChallengesDescription: i18n.t('bonusChallengesDescription', currencyName),
            };
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    rewardName={this.rewardName}
                    programName={this.programName}
                    items={this.items}
                    isLoading={this.isLoading}
                    partnerItems={this.partnerItems}
                    title={this.title}
                    bonusChallengeItems={this.bonusChallengeItems}
                    rewardDetailsText={this.rewardDetailsText}
                    {...this.sectionsInfoProps}
                />
            );
        }
    }

    function mapStateToProps(state) {
        return {
            rewards: selectors.getRewards(state),
            levels: selectors.getCompanyLevels(state),
            transactionsAmount: selectors.getTransactionsAmount(state),
            isLoadingAchievements: selectors.isLoadingAchievements(state),
            achievements: selectors.getAllNotLevelAchievementsSorted(state),
            rewardsCurrencyName: selectors.externalRewardName(state),
            isLoadingEarnPartners: selectors.isLoadingEarnPartners(state),
            earnPartners: selectors.getEarnPartnersByCount(state),
            isEarnPartnersEnabled: selectors.isEarnPartnersEnabled(state),
            pointsName: coreSelectors.getCustomPointsName(state),
            isBonusChallengesEnabled: challengeSelectors.getIsBonusChallengesEnabled(state),
            bonusChallenges: challengeSelectors.getBonusChallenges(state),
            isLoadingChallengesList: challengeSelectors.isLoadingChallengesList(state),
            externalRewardName: selectors.externalRewardName(state),
            isLiveBetter: coreSelectors.isLiveBetter(state),
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators({ ...rewardsActions, ...challengeActions }, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(HowToEarnBase));
}

const SMALL_SCREEN_WIDTH = 320;
const isSmallWidth = windowSize.width <= SMALL_SCREEN_WIDTH;
const nameFont = isSmallWidth ? appFonts.smBold : appFonts.mdBold;

export const styles = {
    buttonText: {
        ...appFonts.smMedium,
        color: baseColors.secondary
    },
    headerTitle: {
        ...appFonts.xlBold,
        marginTop: spacing.s5,
        marginBottom: spacing.s1,
    },
    headerDescription: {
        ...appFonts.mdRegular,
        color: baseColors.grey20,
    },
    footerButtonContainer: {
        marginBottom: spacing.s7,
    },
    levelItem: {
        alignItems: 'center',
        height: 'auto',
    },
    sectionWrapper: {
        paddingBottom: spacing.s9,
        justifyContent: 'center',
        borderBottomWidth: 1,
        borderBottomColor: baseColors.grey80,
    },
    rewardNameStyles: {
        marginBottom: spacing.s0,
        paddingTop: spacing.s2,
        textAlign: 'center',
        ...nameFont,
    },
};
