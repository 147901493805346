import React, { PureComponent } from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import PropTypes from 'prop-types';
import WithTermsAndPolicyModalBase, { styles as baseStyles } from './TermsAndPolicyModalBase';
import { appFonts, spacing, containerStyle, importantClass } from '../../../../styles';
import { components as Core, Modal, onKeyPress } from '../..';
import { CSS_CLASSES } from '../../constants';

const _fontSize = appFonts.mdRegular;

class TermsAndPolicyModal extends PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        termsOfService: PropTypes.string,
        privacyPolicy: PropTypes.string,
        isPrivacyPolicyLink: PropTypes.bool,
        isInvitationPage: PropTypes.bool
    };

    static defaultProps = {
        termsOfService: undefined,
        privacyPolicy: undefined,
        isPrivacyPolicyLink: false,
        isInvitationPage: false
    };

    constructor(props) {
        super(props);
        this.state = {
            title: undefined,
        };
    }

    openTerms = () => {
        this.setState({ title: this.props.i18n.t('auth.signUp.termsOfService') });
        if (this.props.termsOfService.content) {
            this.openModal(this.props.termsOfService.content);
        } else if (this.props.termsOfService) {
            this.openModal(this.props.termsOfService);
        }
    }

    openPrivacy = () => {
        this.setState({ title: this.props.i18n.t('auth.signUp.privacyPolicy') });
        this.openModal(this.props.privacyPolicy);
    }

    createMarkup = content => ({ __html: `<div style="font-size: ${_fontSize}px; font-family: “Helvetica Now Text SA", "Helvetica Neue", Helvetica, Arial, sans-serif;">${content}</div>` });

    openModal = content => {
        this.closeModal = Modal.open(
            () => this.renderPolicy(content),
            {},
            { isContainer: true, isFullHeight: true }
        );
    };

    renderPolicy(content) {
        const { i18n, isInvitationPage } = this.props;
        const { title } = this.state;
        return (
            <div className={css(styles.modalContainer)}>
                {title ? (
                    <Core.SubHeader
                        title={title}
                        noTopPadding={true}
                        hasBackButton={false}
                        titleAlign="center"
                        noLeftPadding={true}
                    />
                ) : null}
                <div className={css(styles.webViewContainer)}>
                    {/* eslint-disable-next-line react/no-danger */}
                    <div dangerouslySetInnerHTML={this.createMarkup(content)} className={CSS_CLASSES.disclaimerText} />
                </div>
                <div className={css(styles.footer)}>
                    <div className={css(containerStyle.largeFooterButtonContainer)}>
                        <Core.Button
                            fullWidth={true}
                            className={css(containerStyle.buttonText)}
                            onPress={this.closeModal}>
                            {isInvitationPage ? i18n.t('next') : i18n.t('back')}
                        </Core.Button>
                    </div>
                </div>
            </div>
        );
    }
    render() {
        const { i18n, isPrivacyPolicyLink, content, isInvitationPage } = this.props;
        return (
            <div>
                {isPrivacyPolicyLink ? (
                    <div onClick={this.openPrivacy}>
                        {content}
                    </div>
                ) : (
                    <div className={css(styles.termsAndPolicyContainer)}>
                        <p className={css(styles.policyTextWrapper)}>
                            <span className={css(styles.policyText)}>
                                {i18n.t('termsOfUsePrivacyPreLinks')}
                            </span>
                            <span
                                role="button"
                                tabIndex="0"
                                onKeyDown={onKeyPress.enter(this.openTerms)}
                                className={css(styles.linkPolicyText)}
                                onClick={this.openTerms}>
                                {' '}{i18n.t('termsOfService')}{' '}
                            </span>
                            <span className={css(styles.policyText)}>
                                {i18n.t('and')}
                            </span>
                            <span
                                role="button"
                                tabIndex="0"
                                onKeyDown={onKeyPress.enter(this.openPrivacy)}
                                className={css(styles.linkPolicyText)}
                                onClick={this.openPrivacy}>
                                {' '}{i18n.t('privacyPolicy')}
                            </span>
                            {isInvitationPage ? (
                                <span className={css(styles.policyText)}>
                                    {' '}{i18n.t('inviteFamilyMember.onTheirBehalf')}
                                </span>
                            )
                                : null
                            }
                        </p>
                    </div>
                )}
            </div>
        );
    }
}

const styles = StyleSheet.create({
    ...baseStyles,
    webViewContainer: {
        ...baseStyles.webViewContainer,
        overflow: 'auto'
    },
    modalContainer: {
        ...baseStyles.modalContainer,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    footer: importantClass({
        borderTop: '1px solid #DFE1E6',
        paddingHorizontal: spacing.s3
    }),
});

export default WithTermsAndPolicyModalBase(TermsAndPolicyModal);

