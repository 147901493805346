import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from '../../../core';
import { baseColors } from '../../../../styles';
import * as selectors from '../../selectors';

const EXCLAMATION_ICON = 'exclamation-circle';

export default function WithSkipEAPModalsLogicBase(WrappedComponent) {
    class SkipEAPModalsLogicBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired
        };

        infoModalProps = () => ({
            iconName: EXCLAMATION_ICON,
            title: this.props.i18n.t('eap.skipModal.title'),
            text: this.props.i18n.t('eap.skipModal.description'),
            isButtonVisible: false,
            iconBackgroundColor: baseColors.primary
        });

        get cancelButtonLabel() {
            return this.props.i18n.t('button_cancel');
        }

        get okButtonLabel() {
            return this.props.i18n.t('ok');
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    infoModalProps={this.infoModalProps}
                    cancelButtonLabel={this.cancelButtonLabel}
                    okButtonLabel={this.okButtonLabel}
                />
            );
        }
    }

    const mapStateToProps = state => ({
        unfinishedTasks: selectors.getUnfinishedTasksForFirebase(state),
        isShowingOnboarding: selectors.isShowingOnboarding(state),
        firebaseTasksCount: selectors.getUnfinishedTasks(state).length,
    });
    return connect(mapStateToProps)(translate()(SkipEAPModalsLogicBase));
}
