const DOUBLE_PRESS_DELAY = 400;

// let isPressed = false;
export default function doublePress(onSingle, onDouble) {
    let isPressed = false;
    return function () {
        if (!isPressed) {
            isPressed = true;
            setTimeout(() => {
                if (isPressed) onSingle();
                isPressed = false;
            }, DOUBLE_PRESS_DELAY);
        } else {
            isPressed = false;
            onDouble();
        }
    };
}