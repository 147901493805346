import React from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';
import { components as Core } from '../../../../core';
import { layoutStyle, importantClass } from '../../../../../styles';
import WithFeedPostEditorPreviewBase, { styles as baseStyles } from './FeedPostEditorPreviewBase';

const FeedPostEditorPreview = ({ i18n, image, isEditing, preview, link, showLinkModal, showPicker }) => {
    if (image) {
        return (
            <div className={css(styles.attachedContent)}>
                {image ?
                    <button onClick={showPicker}>
                        <Core.Image
                            isFadeIn={true}
                            alt="create post image"
                            src={image}
                            className={css(styles.attachedImage)}
                        />
                    </button> : null}
                {link ?
                    <button onClick={showLinkModal}>
                        <Core.TextSeeMore
                            numberOfLines={3}
                            className={css(styles.insertedLinkText)}
                            containerClassName={css(styles.insertedLink)}
                            isExpandHidden={true}>
                            {`${i18n.t('link')}: ${link}`}
                        </Core.TextSeeMore>
                    </button> : null}
            </div>
        );
    }
    if (isEditing || !preview) {
        if (link) {
            return (
                <button onClick={showLinkModal}>
                    <Core.TextSeeMore
                        numberOfLines={3}
                        className={css(styles.insertedLinkText)}
                        containerClassName={css(styles.insertedLink)}
                        isExpandHidden={true}>
                        {`${i18n.t('link')}: ${link}`}
                    </Core.TextSeeMore>
                </button>
            );
        }
        return null;
    }
    return (
        <div className={css(styles.attachedContent)}>
            <Core.Image
                alt="create post link preview image"
                src={preview.src}
                className={css(styles.attachedImage)}
            />
            <div className={css(layoutStyle.flexColumn, layoutStyle.flex1)}>
                <Core.TextSeeMore
                    numberOfLines={1}
                    className={css(styles.previewTitle)}
                    isExpandHidden={true}>
                    {preview.title}
                </Core.TextSeeMore>
                <Core.TextSeeMore
                    numberOfLines={3}
                    className={css(styles.previewDescription)}
                    containerClassName={css(layoutStyle.flex1)}
                    isExpandHidden={true}>
                    {preview.description}
                </Core.TextSeeMore>
                <button onClick={showLinkModal}>
                    <Core.TextSeeMore
                        numberOfLines={3}
                        className={css(styles.insertedLinkText)}
                        containerClassName={css(styles.insertedLink)}
                        isExpandHidden={true}>
                        {`${i18n.t('link')}: ${link}`}
                    </Core.TextSeeMore>
                </button>
            </div>
        </div>
    );
};

FeedPostEditorPreview.propTypes = {
    i18n: PropTypes.object.isRequired,
    image: PropTypes.string,
    isEditing: PropTypes.bool,
    preview: PropTypes.object,
    link: PropTypes.string,
    showLinkModal: PropTypes.func.isRequired,
    showPicker: PropTypes.func.isRequired,
};

FeedPostEditorPreview.defaultProps = {
    image: undefined,
    isEditing: false,
    preview: undefined,
    link: undefined,
};

export default WithFeedPostEditorPreviewBase(FeedPostEditorPreview);

const styles = StyleSheet.create({
    ...baseStyles,
    attachedContent: {
        ...baseStyles.attachedContent,
        display: 'flex'
    },
    insertedLink: {
        ...baseStyles.insertedLinkText,
        wordBreak: 'break-word',
        wordWrap: 'break-word',
        maxWidth: '100%'
    },
    attachedImage: importantClass({
        ...baseStyles.attachedImage
    }),
});