import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import InnerHtml from '../InnerHtml.web';
import WithExploreItemTitleBase, { styles as baseStyles } from './ExploreItemTitleBase';


class ExploreItemTitle extends PureComponent {
    static propTypes = {
        text: PropTypes.string.isRequired,
        numberOfLines: PropTypes.number.isRequired,
        color: PropTypes.string.isRequired,
        style: PropTypes.oneOfType([PropTypes.object, PropTypes.number])
    };

    static defaultProps = {
        style: {}
    };

    render() {
        const { text, numberOfLines, color, style } = this.props;

        if (!text) return null;

        return (
            <div className={css(styles.mainContainer)}>
                <p className={css(styles.text, style && style)} style={{ color }} numberOfLines={numberOfLines}><InnerHtml htmlText={text} /></p>
            </div>
        );
    }
}

export default WithExploreItemTitleBase(ExploreItemTitle);

const styles = StyleSheet.create({
    ...baseStyles,
    mainContainer: {
        ...baseStyles.mainContainer,
        display: 'flex'
    },
});
