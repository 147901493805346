import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { withLastLocation } from 'react-router-last-location';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { baseColors, spacing, importantStyles, appFonts, layoutStyle } from '../../../../styles';
import { components as Core, ROUTES, ActionSheet, openUrl, constants as coreConstants } from '../../../core';
import WithDeviceDetailsBase, { styles as baseStyles } from './DeviceDetailsBase';
import WithDevicesModalsLogic from '../DevicesModalsLogic';
import { appsDevicesHelper } from '../../services';
import { components as Challenges } from '../../../challenges';
import { DEVICE_CONNECTION } from '../../constants';
import connectWithStrava from '../../../../image/connectWithStrava.png';

const ICON_SIZE = spacing.s4;
export const ANIMATION_DURATION = 400;

class DeviceDetails extends PureComponent {
    static propTypes = {
        actions: PropTypes.object.isRequired,
        onDevicePress: PropTypes.func.isRequired,
        onSync: PropTypes.func.isRequired,
        disconnectionError: PropTypes.object,
        onDisconnect: PropTypes.func.isRequired,
        isShowingOnboarding: PropTypes.bool,
        isLoading: PropTypes.bool.isRequired,
        isDisconnecting: PropTypes.bool.isRequired,
        device: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        i18n: PropTypes.object.isRequired,
        formatLastSyncDate: PropTypes.func.isRequired,
        getDeviceHasError: PropTypes.func,
        getShowDeviceErrorWarning: PropTypes.func,
        onDismissWarning: PropTypes.func.isRequired,
        getDeviceWarningString: PropTypes.func.isRequired,
        getDeviceErrorString: PropTypes.func.isRequired,
        getDeviceWarningActionString: PropTypes.func.isRequired,
        isRefreshing: PropTypes.object.isRequired,
        setIsRefreshing: PropTypes.func.isRequired,
        isSyncing: PropTypes.bool,
        connected: PropTypes.array,
        onFinishAppsDevicesOnboardingStep: PropTypes.func.isRequired,
        getConnectButtonTitle: PropTypes.func.isRequired,
    };

    static defaultProps = {
        isShowingOnboarding: false,
        disconnectionError: undefined,
        getDeviceHasError: undefined,
        getShowDeviceErrorWarning: undefined,
        isSyncing: false,
        connected: []
    };

    componentDidMount() {
        const { goBack } = this;
        const { i18n, actions, connected, onFinishAppsDevicesOnboardingStep } = this.props;
        window.onmessage = function (message) {
            const isConnected = message.data === DEVICE_CONNECTION.SUCCESS;
            const isError = message.data === DEVICE_CONNECTION.ERROR;
            if (isConnected || isError) {
                if(isConnected && !connected.length) onFinishAppsDevicesOnboardingStep();
                goBack();
                actions.finishConnecting(true);
                actions.addToast(
                    coreConstants.TOAST_TYPES[isConnected ? 'SUCCESS' : 'DANGER'],
                    null,
                    i18n.t(`connectingDeviceToast.${isConnected ? 'success' : 'danger'}.message`),
                    i18n.t(`connectingDeviceToast.${isConnected ? 'success' : 'danger'}.title`)
                );
            }
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isDisconnecting && !this.props.isDisconnecting && !this.props.disconnectionError) {
            this.goBack();
        }
    }

    goBack = () => this.props.history.push(_.get(this.props, 'lastLocation.pathname') || ROUTES.appsDevices());

    onPressSync = () => {
        if (this.props.onSync && !this.props.isRefreshing) {
            this.props.setIsRefreshing(true);
            this.props.onSync(this.onFinishRefreshing);
        }
    };

    onFinishRefreshing = () => {
        this.props.setIsRefreshing(false);
    };

    onActivitiesPress = () => {
        const { device, isShowingOnboarding } = this.props;
        const vendorId = device.vendorId;
        this.props.history.push(isShowingOnboarding ? ROUTES.onboardingDeviceActivities(vendorId) : ROUTES.deviceActivities(vendorId));
    }

    onDismissWarning = () => {
        this.props.onDismissWarning();
    };

    get deviceSyncDate() {
        const { device, getDeviceHasError } = this.props;

        const statusText = appsDevicesHelper.deviceStatusString(device);

        if (device.connected) {
            return (
                <div className={css(styles.statusSection)}>
                    <p className={css(getDeviceHasError() ? styles.errorText : styles.statusText)}>{statusText}</p>
                </div>
            );
        }

        return (<p className={css(styles.deviceDescription)}>{device.description}</p>);
    }

    get warningErrorSection() {
        const { getDeviceHasError, getShowDeviceErrorWarning, getDeviceWarningString, getDeviceErrorString, getDeviceWarningActionString } = this.props;

        if (!getShowDeviceErrorWarning()) {
            return null;
        }

        let alertText = '';
        if (getDeviceHasError()) {
            alertText = getDeviceErrorString();
        } else {
            alertText = getDeviceWarningString();
        }

        return (
            <div style={styles.alertContainer}>
                <p className={css(styles.statusText, getDeviceHasError() ? styles.errorText : styles.warningText)}>{alertText}</p>
                <div className={css(styles.buttonContainer)}>
                    <Core.Button onPress={this.props.onDismissWarning} type="text">
                        <p className={css(styles.actionText)}>{getDeviceWarningActionString()}</p>
                    </Core.Button>
                </div>
            </div>
        );
    }

    get listSection() {
        const { i18n, device } = this.props;
        const troubleShootingURL = (device.troubleshootUrl !== undefined) ? device.troubleshootUrl : null;
        return (
            <div className={css(styles.listSection)}>
                {troubleShootingURL ?
                    <div className={css(styles.listItemContainer)}>
                        <ListItem id="troubleshootingButton" button={true} onClick={() => this.openTroubleshootingURL()}>
                            <ListItemText
                                primary={
                                    <span>
                                        {i18n.t('appsDevices.troubleshooting')}
                                    </span>
                                }
                            />
                            <Core.Icon
                                name="angle-right"
                                size={spacing.s4}
                                color={baseColors.grey50}
                                type="regular"
                            />
                        </ListItem>
                    </div>
                    : null}
                <div className={css(styles.lastListItemContainer)}>
                    <ListItem id="activitiesButton" button={true} onClick={() => this.onActivitiesPress()}>
                        <ListItemText
                            primary={
                                <span>
                                    {i18n.t('supported_activities')}
                                </span>
                            }
                        />
                        <Core.Icon
                            name="angle-right"
                            size={spacing.s4}
                            color={baseColors.grey50}
                            type="regular"
                        />
                    </ListItem>
                </div>
            </div >
        );
    }

    get recentActivitiesSection() {
        const { device, isRefreshing, isSyncing } = this.props;

        if (!device.connected) {
            return null;
        }

        return (
            <Challenges.RecentActivityLog vendorId={device.vendorId} isSyncingDevice={isRefreshing || isSyncing} />
        );
    }

    get disconnectOptions() {
        return [{
            title: this.props.i18n.t('button_disconnect'),
            onPress: this.props.onDisconnect
        }];
    }

    openOptions = () => {
        ActionSheet.open(this.disconnectOptions, 0);
    };

    openTroubleshootingURL = () => {
        const { device } = this.props;
        const troubleShootingURL = (device.troubleshootUrl !== undefined) ? device.troubleshootUrl : null;
        if (troubleShootingURL) {
            openUrl(troubleShootingURL);
        }
    };

    get header() {
        const { isShowingOnboarding } = this.props;
        return (
            <Core.EntityDetailsHeader
                hasBackButton={true}
                rightNavIconProps={this.props.device.connected ? {
                    name: 'ellipsis-h',
                    onClick: this.openOptions
                } : null}
                isOnboarding={isShowingOnboarding}
            />
        );
    }

    render() {
        const { i18n, device, isShowingOnboarding, isLoading, isRefreshing, isSyncing, getConnectButtonTitle } = this.props;
        return (
            <Core.SmallerContainer
                itemClassName={isShowingOnboarding && css(layoutStyle.bgWhite, styles.container)}
                isOnboarding={isShowingOnboarding}
                className={css(layoutStyle.noPadding)}
                widerHeaderComponent={isShowingOnboarding ? null : this.header}>
                <Core.BlockingLoading isLoading={isLoading} />
                <div className={css(styles.mainContainer)}>
                    {isShowingOnboarding ? this.header : null}
                    <div className={css(styles.headerContainer, isShowingOnboarding && styles.onboardingHeader)}>
                        <Core.Image src={device.iconUrl} className={css(styles.deviceIcon)} alt={`${device.displayName} logo image`} />
                        <span className={css(styles.deviceNameWeb)}>{device.displayName}</span>
                        {this.deviceSyncDate}
                        {device.connected ? (
                            <div>
                                {this.warningErrorSection}
                            </div>
                        ) : null}
                    </div>
                </div>
                {device.connected ? (
                    <div className={css(styles.buttonContainer)}>
                        <Core.Button
                            id="syncNowButton"
                            className={css(styles.syncButton)}
                            onPress={this.onPressSync}
                            size="medium"
                            type="outlined">
                            {isRefreshing || isSyncing ? (
                                <Core.Animated.SpinOpposite>
                                    <Core.Icon
                                        type="fa"
                                        name="sync"
                                        color={baseColors.secondary}
                                        size={ICON_SIZE}
                                        style={css(styles.syncIcon)}
                                    />
                                </Core.Animated.SpinOpposite>
                            )
                                : (
                                    <Core.Icon
                                        type="fa"
                                        name="sync"
                                        color={baseColors.secondary}
                                        size={ICON_SIZE}
                                        style={css(styles.syncIcon)}
                                    />
                                )}
                            <p className={css(styles.syncButtonText)}>{i18n.t('sync_now')}</p>
                        </Core.Button>
                    </div>
                ) : (
                    <div className={css(styles.buttonContainer)}>
                        {device.vendor === 'strava' ? (
                            <div onClick={this.props.onDevicePress}>
                                <Core.Image src={connectWithStrava} className={css(styles.connectStravaImage)} alt="Connect with Strava" />
                            </div>
                        )
                            : (
                                <Core.Button
                                    id="connectButton"
                                    size="medium"
                                    onPress={this.props.onDevicePress}>
                                    {getConnectButtonTitle()}
                                </Core.Button>
                            )}
                    </div>
                )}
                {this.listSection}
                {this.recentActivitiesSection}
            </Core.SmallerContainer>
        );
    }
}

const styles = StyleSheet.create(importantStyles({
    ...baseStyles,
    mainContainer: {
        ...baseStyles.mainContainer,
        paddingBottom: spacing.s6
    },
    syncButton: {
        marginLeft: spacing.s2
    },
    headerContainer: {
        ...baseStyles.headerContainer,
        marginBottom: spacing.s0,
        display: 'flex'
    },
    onboardingHeader: {
        marginTop: spacing.s16
    },
    syncButtonText: {
        paddingLeft: spacing.s1
    },
    syncIcon: {
        paddingTop: spacing.s1,
        paddingBottom: spacing.s1
    },
    listItemContainer: {
        ...baseStyles.listItemContainer,
        marginRight: spacing.s0
    },
    deviceNameWeb: {
        ...appFonts.xlBold,
        paddingTop: spacing.s0,
        paddingBottom: spacing.s0
    },
    container: {
        marginTop: spacing.s3,
        minHeight: `calc(100vh - ${spacing.s7}px)`,
        position: 'relative',
        marginBottom: spacing.s3
    },
    connectStravaImage: {
        height: spacing.s10,
        width: 'auto',
        cursor: 'pointer'
    }
}));

export default withRouter(withLastLocation(WithDevicesModalsLogic(WithDeviceDetailsBase(DeviceDetails))));
