import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { css, StyleSheet } from 'aphrodite-jss';
import { withLastLocation } from 'react-router-last-location';
import { AsyncComponent, components as Core, Modal } from '../../../core';
import { baseColors, spacing, layoutStyle } from '../../../../styles';
import WithCreateEditTeamBase, { styles as baseStyles, TEAM_NAME_MAX_LENGTH } from './CreateEditTeamBase';
import { components as Settings, constants as settingsConstants } from '../../../settings';

class CreateEditTeam extends PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        currentMembers: PropTypes.array,
        isLoading: PropTypes.bool,
        buttonLabel: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        onNameChange: PropTypes.func.isRequired,
        teamName: PropTypes.string.isRequired,
        removedMembers: PropTypes.array.isRequired,
        allInvitations: PropTypes.array,
        isCurrentUser: PropTypes.func.isRequired,
        getName: PropTypes.func.isRequired,
        getColorObject: PropTypes.func.isRequired,
        removeMember: PropTypes.func.isRequired,
        updatePicture: PropTypes.func.isRequired,
        pictureFull: PropTypes.string,
        createEditTeam: PropTypes.func.isRequired,
        errorMessage: PropTypes.string,
        inviteToTeamProps: PropTypes.func.isRequired,
        entityType: PropTypes.string.isRequired,
        close: PropTypes.func,
        isEditing: PropTypes.bool,
        edited: PropTypes.bool,
    };

    static defaultProps = {
        currentMembers: [],
        allInvitations: [],
        isLoading: false,
        errorMessage: undefined,
        pictureFull: undefined,
        close: null,
        isEditing: false,
        edited: false
    };

    constructor(props) {
        super(props);
        this.propsToPass = {
            onPress: this.props.createEditTeam
        };
    }


    get cancelModalData() {
        const { i18n, isEditing } = this.props;
        const actionType = isEditing ? i18n.t('createEntity.action.editing') :  i18n.t('createEntity.action.creating');
        const entity = i18n.t('createEntity.type.team');
        return {
            title: i18n.t('createEntity.cancel.title', { entity, actionType }),
            text: i18n.t('createEntity.cancel.message', { entity, actionType })
        };
    }

    inviteToTeam = () => {
        Modal.open(AsyncComponent(() => import('../../../invitations/components/InvitationsList')),
            {
                ...this.props.inviteToTeamProps(),
                isModal: true
            }, { isContainer: true, isFullHeight: true });
    };

    renderRemovedMembers = item => this.renderMembers(item, true);

    renderNotRemovedMembers = item => this.renderMembers(item, false);

    renderMembers = (item, isRemoved) => {
        const isEmail = _.get(item, 'inviteeEmail');
        return (
            <div className={css(layoutStyle.commonPaddingHorizontal)}>
                <Core.ListItem
                    key={isEmail ? item.inviteeEmail : item.userId}
                    className={css(layoutStyle.listItem, layoutStyle.listItemNoHPadding)}
                    button={false}>
                    {isEmail ?
                        <div className={css(styles.emailIconContainer, layoutStyle.flexCenter)}>
                            <Core.Icon
                                color={baseColors.white}
                                name="envelope"
                                type="fa"
                                size={spacing.s4}
                            />
                        </div> :
                        <Core.Avatar
                            url={item.avatarURL}
                            name={`${item.firstname} ${item.lastname}`}
                            size="small"
                            id={item.id}
                        />
                    }
                    <Core.ListItemText
                        primary={
                            <p className={css(styles.titleStyle, this.props.getColorObject(item))}>
                                {isEmail ? item.inviteeEmail : this.props.getName(item)}
                            </p>
                        }
                    />
                    {(!this.props.isCurrentUser(item) && !isRemoved) || isEmail ?
                        <Core.IconButton
                            isSmall={true}
                            onClick={this.props.removeMember(item, isEmail)}
                            name="minus-circle"
                            type="fa"
                            fill="solid"
                            size={spacing.s5}
                            color={baseColors.danger}
                        /> : null}
                </Core.ListItem>
            </div>
        );
    };

    get teamsList() {
        return (
            <div>
                <p className={css(layoutStyle.smallGreyTitle)}>
                    {this.props.i18n.t('team_members')}
                </p>
                {_.map(this.props.currentMembers, this.renderNotRemovedMembers)}
                {this.props.allInvitations.length ? (
                    <div>
                        <p className={css(layoutStyle.smallGreyTitle)}>
                            {this.props.i18n.t('members_to_invite')}
                        </p>
                        {_.map(this.props.allInvitations, this.renderNotRemovedMembers)}
                    </div>
                ) : null}
                {this.props.removedMembers.length ? (
                    <div>
                        <p className={css(layoutStyle.smallGreyTitle)}>
                            {this.props.i18n.t('members_to_remove')}
                        </p>
                        {_.map(this.props.removedMembers, this.renderRemovedMembers)}
                    </div>
                ) : null}
            </div>
        );
    }

    get disclaimerSection() {
        return (
            <Settings.PrivacySection
                slug={settingsConstants.PRIVACY_SLUGS.CHALLENGE_TEAMS}
                privacyIcon="hand-paper"
                isDisclaimerOnly={true}
            />
        );
    }

    getSingleStepContent = () => {
        return (
            <Fragment>
                <Core.ImageUploader
                    fileChanged={this.props.updatePicture}
                    picture={this.props.pictureFull}
                    entityType={this.props.entityType}
                    isDeletedPictureEmpty={true}
                />
                <div className={css(layoutStyle.commonPaddingHorizontal)}>
                    <Core.Input
                        fullWidth={true}
                        label={this.props.i18n.t('team_name')}
                        value={this.props.teamName}
                        onChangeText={this.props.onNameChange}
                        helperText={this.props.errorMessage}
                        error={!!this.props.errorMessage}
                        inputProps={{ maxLength: TEAM_NAME_MAX_LENGTH }}
                    />
                    <div className={css(layoutStyle.commonPaddingVertical)}>
                        <Core.Button fullWidth={true} onPress={this.inviteToTeam}>
                            {this.props.i18n.t('inviteOthersToTeam')}
                        </Core.Button>
                    </div>
                </div>
                {this.disclaimerSection}
                {this.teamsList}
                <Core.BlockingLoading isLoading={this.props.isLoading} />
            </Fragment>
        );
    }

    cancelFlow = () => this.props.close();

    render() {
        return (
            <Core.Steps
                propsToPass={this.propsToPass}
                cancelFlow={this.cancelFlow}
                title={this.props.title}
                isCloseButtonShown={true}
                isSingleStep={true}
                cancelModalData={this.cancelModalData}
                buttonLabel={this.props.buttonLabel}
                edited={this.props.edited}
                modalWithScrollbar={true}
                needTrackChanges={true}>
                {this.getSingleStepContent}
            </Core.Steps>
        );
    }
}

export default withLastLocation(WithCreateEditTeamBase(CreateEditTeam));

const styles = StyleSheet.create({
    ...baseStyles
});
