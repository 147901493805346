import _ from 'lodash';

// make rule important
export function important(rule, isNumberTransformed = true) {
    return `${_.isNumber(rule) && isNumberTransformed ? `${rule}px` : rule}!important`;
}

// make class (object) of rules important
export function importantClass(classObject, isNumberTransformed = true) {
    return _.mapValues(classObject, value => _.isObject(value)
        ? importantClass(value, isNumberTransformed) : important(value, isNumberTransformed));
}

// make styleSheet (object of objects) of rules important
export function importantStyles(stylesObject, isNumberTransformed = true) {
    return _.mapValues(stylesObject, value => importantClass(value, isNumberTransformed));
}
