import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { baseColors, spacing, appFonts } from '../../../../styles';
import { translate } from '../../../core';

export const ICON_SIZE = 64;

export default function WithNumericQuestionBase(WrappedComponent) {
    class NumericQuestionBase extends PureComponent {
        static propTypes = {
            question: PropTypes.object.isRequired,
            onClick: PropTypes.func.isRequired,
            i18n: PropTypes.object.isRequired,
            answer: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        };

        static defaultProps = { answer: null };

        get answer() {
            return this.props.answer ? String(this.props.answer) : '';
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    answer={this.answer}
                />
            );
        }
    }

    return translate()(NumericQuestionBase);
}

export const styles = {
    header: {
        flexDirection: 'row',
        marginTop: spacing.s5,
        alignSelf: 'center'
    },
    questionText: {
        textAlign: 'center',
        color: baseColors.grey20,
        maxWidth: '100%',
        ...appFonts.lgMedium
    },
    icon: {
        alignSelf: 'center',
        marginBottom: spacing.s3
    }
};