import { NAME as name } from './constants';
import { createActionTypes } from '../core';

const NAME = name.toUpperCase();

export const GET_CONTENT = createActionTypes(`${NAME}/GET_CONTENT`);
export const GET_CONTENT_PROMOTED = createActionTypes(`${NAME}/GET_CONTENT_PROMOTED`);
export const GET_CONTENT_ITEM = createActionTypes(`${NAME}/GET_CONTENT_ITEM`);
export const BOOKMARK_CONTENT = createActionTypes(`${NAME}/BOOKMARK_CONTENT`);
export const SEARCH_CONTENT = createActionTypes(`${NAME}/SEARCH_CONTENT`);
export const MARK_READ_WATCHED = createActionTypes(`${NAME}/MARK_READ_WATCHED`);
export const MARK_OPENED = createActionTypes(`${NAME}/MARK_OPENED`);
export const GET_CONTENT_TAGS = createActionTypes(`${NAME}/GET_CONTENT_TAGS`);
export const UPDATE_RECOMMENDED_CONTENT = createActionTypes(`${NAME}/UPDATE_RECOMMENDED_CONTENT`);
export const GET_RECOMMENDED_CONTENT = createActionTypes(`${NAME}/GET_RECOMMENDED_CONTENT`);
export const CLEAR_CONTENT = createActionTypes(`${NAME}/CLEAR_CONTENT`);
export const INCREMENT_LOADING_COUNT = createActionTypes(`${NAME}/INCREMENT_LOADING_COUNT`);