import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import * as actions from '../../actions';
import {
    getFeedsByTypeAndId,
    isLoadingMoreStreams,
    isLoadingStreams,
    getFilterId,
    getDisableStreamsWeights
} from '../../selectors';
import { selectors as coreSelectors } from '../../../core';

export default function WithFeedListBase(WrappedComponent) {
    class FeedListBase extends Component {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            entityType: PropTypes.string.isRequired,
            entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            canLoadContent: PropTypes.bool,
            recommendations: PropTypes.arrayOf(PropTypes.shape({
                index: PropTypes.number.isRequired,
                component: PropTypes.object.isRequired,
                name: PropTypes.string.isRequired
            })),
            earnPartners: PropTypes.arrayOf(PropTypes.shape({
                index: PropTypes.number.isRequired,
                component: PropTypes.object,
                name: PropTypes.string.isRequired
            })),
            filterId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            feeds: PropTypes.array,
            isLoading: PropTypes.bool,
            isLoadingMore: PropTypes.bool,
            currentUser: PropTypes.object.isRequired
        };

        static defaultProps = {
            canLoadContent: true,
            recommendations: [],
            earnPartners: [],
            filterId: 0,
            feeds: [],
            isLoading: false,
            isLoadingMore: false
        };

        /**
         * @constructor
         * check if content needs to be loaded, assign initial state
         */
        constructor(props) {
            super(props);
            this.state = { hasMore: true };
            this.loadContent();
        }

        /**
         * check if canLoadContent changed and if no more streams
         * @param {Object} nextProps - props
         */
        UNSAFE_componentWillReceiveProps(nextProps) {
            if (this.props.isLoadingMore && !nextProps.isLoadingMore &&
                this.props.feeds !== nextProps.feeds && nextProps.feeds.length === this.props.feeds.length) {
                this.setState({ hasMore: false });
            }
        }

        componentDidUpdate(prevProps) {
            if ((this.props.filterId && (prevProps.filterId !== this.props.filterId || (!prevProps.canLoadContent && this.props.canLoadContent))) ||
                prevProps.disableStreamsWeights !== this.props.disableStreamsWeights) {
                this.loadContent();
            }
        }

        /**
         * initial load of streams
         */
        loadContent = () => {
            const { actions: { getStreams }, entityId, entityType, filterId, disableStreamsWeights } = this.props;
            getStreams(undefined, undefined,
                entityId, entityType, filterId, disableStreamsWeights?.id);
        };

        /**
         * check if can load more
         * @returns {boolean}
         */
        get isLoadMoreAvailable() {
            return this.props.canLoadContent && !this.isLoading && this.state.hasMore && this.props.feeds.length > 0;
        }

        /**
         * load more streams on lazy load
         */
        loadMoreContent = () => {
            const { actions: { getStreams }, feeds, entityId, entityType, filterId, disableStreamsWeights } = this.props;
            if (this.isLoadMoreAvailable) {
                getStreams(feeds[feeds.length - 1], undefined,
                    entityId, entityType, filterId, disableStreamsWeights.id
                );
            }
        };

        /**
         * get specific recommendation item by index
         * @returns {(Object|undefined)}
         */
        getRecommendation = index =>
            this.props.recommendations ? _.find(this.props.recommendations, r => index === r.index) : undefined;

        /**
         * is loading
         * @returns {(boolean|undefined)}
         */
        get isLoading() {
            return this.props.isLoading || this.props.isLoadingMore;
        }

        /**
         * get key from item
         * @returns {string}
         */
        keyExtractor = item => item ? item.toString() : undefined;

        /**
         * render
         * @returns {ReactElement}
         */
        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    loadContent={this.loadContent}
                    loadMoreContent={this.loadMoreContent}
                    getRecommendation={this.getRecommendation}
                    hasMore={this.state.hasMore}
                    isLoading={this.isLoading}
                    keyExtractor={this.keyExtractor}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        return {
            feeds: getFeedsByTypeAndId(state, ownProps.entityType, ownProps.entityId),
            isLoading: isLoadingStreams(state),
            isLoadingMore: isLoadingMoreStreams(state),
            filterId: getFilterId(state),
            currentUser: coreSelectors.getCurrentUser(state),
            disableStreamsWeights: getDisableStreamsWeights(state),
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(FeedListBase);
}
