import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite-jss';
import { components as Core, ROUTES } from '../../../core';
import * as Card from '../Card';
import { appFonts, layoutStyle, baseColors, spacing, importantClass } from '../../../../styles';
import WithRecommendedChallengesCardBase from './RecommendedChallengesCardBase';
import trophyIcon from '../../../../image/trophyWithStar.png';

class RecommendedChallengesCard extends PureComponent {
    static propTypes = {
        description: PropTypes.string.isRequired,
        buttonLabel: PropTypes.string.isRequired,
        onButtonPress: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired,
        isSingle: PropTypes.bool.isRequired
    }

    goToExploreChallenges = e => {
        e.stopPropagation();
        this.props.onButtonPress();
        this.props.history.push(ROUTES.challenges(), { showOnAndUpcomingChallenges: true });
    }

    render() {
        const { buttonLabel, description, isSingle } = this.props;

        return (
            <Card.Container
                hasPointer={false}
                className={css(styles.cardContainer)}
                containerClassName={css(isSingle && styles.cardHeight)}>
                <Card.Body isGrey={false} className={css(layoutStyle.flexColumnCenter, layoutStyle.flex1)}>
                    <Core.Image
                        src={trophyIcon}
                        className={css(styles.iconWrapper)}
                    />
                    <div className={css(styles.contentMaxWidth)}>
                        <p className={css(styles.descriptionWeb)}>{description}</p>
                    </div>
                    <Core.Button
                        type="outlined"
                        size="medium"
                        onPress={this.goToExploreChallenges}>
                        {buttonLabel}
                    </Core.Button>
                </Card.Body>
            </Card.Container>
        );
    }
}

export default withRouter(WithRecommendedChallengesCardBase(RecommendedChallengesCard));

const styles = StyleSheet.create({
    cardContainer: importantClass({
        boxShadow: 'none',
        paddingTop: spacing.s7,
        paddingBottom: spacing.s7,
        backgroundColor: baseColors.grey90,
        borderRadius: spacing.s1
    }),
    cardHeight: importantClass({
        height: 'auto',
    }),
    descriptionWeb: {
        ...appFonts.smRegular,
        color: baseColors.grey20,
        textAlign: 'center',
        maxWidth: '100%',
        marginBottom: spacing.s2,
        marginTop: spacing.s2
    },
    contentMaxWidth: {
        width: '100%',
    },
    iconWrapper: {
        alignSelf: 'center',
        width: spacing.s8,
        height: spacing.s7
    },
});
