import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { StyleSheet, css } from 'aphrodite-jss';
import { components as Core } from '../../../core';
import { imageSize } from '../../../../styles';
import { styles as baseStyles } from './RewardsPartnersListBase';

class RewardsPartnersList extends PureComponent {
    static propTypes = {
        title: PropTypes.string,
        partners: PropTypes.array
    };

    static defaultProps = {
        title: '',
        partners: []
    };

    renderPartnerItem(item) {
        return (
            <div key={item.earnPartnerId} className={css(styles.imageContainer)}>
                <Core.Image src={item.logo ? item.logo : null} className={css(styles.image)} />
            </div>
        );
    }

    render() {
        return (
            <div>
                <span className={css(styles.titleHeader)}>{this.props.title}</span>
                <div className={css(styles.imageParentContainer)}>
                    {_.map(this.props.partners, this.renderPartnerItem)}
                </div>
            </div>
        );
    }
}

export default RewardsPartnersList;

const styles = StyleSheet.create({
    ...baseStyles,
    imageContainer: {
        ...baseStyles.imageContainer,
        width: imageSize.sm,
        height: imageSize.sm,
        flexShrink: 'unset',
        flexBasis: 'unset',
        marginLeft: 0
    }
});
