import {  Modal, AsyncComponent } from '../../../../core';

export default function openCreateChallenge(step, params) {
    Modal.open(AsyncComponent(() => import('../../../components/CreateChallenge')), {
        step,
        params,
        isModal: true
    }, {
        cancelable: false,
        isNoPadding: true,
        isContainer: true,
        isFullHeight: true,
        fadeTransition: true,
    });
}
