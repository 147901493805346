import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { translate, selectors as coreSelectors } from '../../../core';
import * as actions from '../../actions';
import { getOptions, getTask, isEAPLoading, getCheckboxOptionsSelector } from '../../selectors';
import { baseColors, appFonts, spacing } from '../../../../styles';

export default function WithEAPSelectOptionsBase(WrappedComponent) {
    class EAPSelectOptionsBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            options: PropTypes.array,
            selected: PropTypes.array,
            task: PropTypes.object,
            user: PropTypes.object.isRequired,
            isLoading: PropTypes.bool,
            onNext: PropTypes.func,
            checkboxOptions: PropTypes.array.isRequired,
            i18n: PropTypes.object.isRequired
        };

        static defaultProps = {
            options: [],
            selected: [],
            task: [],
            isLoading: false,
            onNext: null
        };

        constructor(props) {
            super(props);
            this.state = {
                selected: props.selected ? props.selected : _.reject(this.props.checkboxOptions, { selected: false })
            };
            if (_.isEmpty(props.task)) {
                this.props.actions.getEAP();
            }
        }

        toggleCheckbox = data => {
            const selected = _.map(data, item => {
                return { ...item, selected: true };
            });
            this.setState({ selected });
        };

        goToContactInfo = () => {
            this.props.actions.setEAPOptions(this.state.selected);
        };

        get rightButtonLabel() { return this.props.i18n.t('EAP.tellMore'); }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    toggleCheckbox={this.toggleCheckbox}
                    selected={this.state.selected}
                    options={this.props.checkboxOptions}
                    goToContactInfo={this.goToContactInfo}
                    rightButtonLabel={this.rightButtonLabel}
                />
            );
        }
    }

    function mapStateToProps(state) {
        return {
            task: getTask(state),
            user: coreSelectors.getCurrentUser(state),
            isLoading: isEAPLoading(state),
            selected: getOptions(state),
            checkboxOptions: getCheckboxOptionsSelector(state)
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(EAPSelectOptionsBase));
}

const INPUT_HEIGHT = 160;
export const styles = {
    container: {
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3,
    },
    selectHeader: {
        ...appFonts.smMedium,
        color: baseColors.black,
        marginBottom: spacing.s0,
        paddingTop: spacing.s3
    },
    selectSubheader: {
        ...appFonts.smRegular,
    },
    subtitle: {
        marginTop: spacing.s3,
        marginBottom: spacing.s1,
    },
    wrapperStyle: {
        marginLeft: 0,
        flex: 1
    },
    formContainer: {
        height: INPUT_HEIGHT
    },
    footer: {
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3,
    },
    footerButtonContainer: {
        marginTop: spacing.s1,
        marginBottom: spacing.s3
    },
    policyTextWrapper: {
        alignSelf: 'center',
        marginTop: spacing.s0,
    },
    policyText: {
        ...appFonts.smMedium
    }
};