import React, { PureComponent } from 'react';
import _ from 'lodash';
import { css, StyleSheet } from 'aphrodite-jss';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { layoutStyle, spacing } from '../../../../styles';
import { components as Core, onKeyPress, Modal, AsyncComponent } from '../../../core';
import withUpdatedPolicyPopupBase, { styles as baseStyles } from './UpdatedPolicyPopupBase';

class UpdatedPolicyPopup extends PureComponent {
    static propTypes = {
        onButtonPress: PropTypes.func.isRequired,
        buttonText: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        details: PropTypes.string.isRequired,
        disclaimerText: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
        updatedPolicies: PropTypes.array.isRequired,
        policyTitleByType: PropTypes.func.isRequired
    };

    get policiesSection() {
        const { updatedPolicies, policyTitleByType } = this.props;
        return (
            <>
                {_.map(updatedPolicies, policy => (
                    <span
                        role="button"
                        tabIndex="0"
                        onKeyDown={onKeyPress.enter(this.openPolicy(policy))}
                        className={css(styles.policyRow)}
                        onClick={this.openPolicy(policy)}>{policyTitleByType(policy.type)}
                    </span>
                ))}
            </>
        );
    }

    dismissModal = () => this.props.close();

    openPolicy = policy => event => {
        event.stopPropagation();

        const type = policy.type;

        if (type === 'disclaimer') {
            Modal.open(AsyncComponent(() => import('../../../auth/components/Disclaimer')), { legalType: type, isPopup: true, isModal: true }, {
                cancelable: true,
                isNoPadding: true,
                isContainer: true,
                isFullHeight: true,
                isNoPaddingHorizontal: true,
                isNoWidthLimit: true,
                maxWidth: 600,
                isMaxHeight: true
            });
        } else {
            Modal.open(AsyncComponent(() => import('../../../settings/components/Legals')), { legalType: type, isPopup: true, isModal: true }, {
                cancelable: true,
                isNoPadding: true,
                isContainer: true,
                isFullHeight: true,
                isNoPaddingHorizontal: true,
                isNoWidthLimit: true,
                maxWidth: 600,
                isMaxHeight: true
            });
        }
    }

    render() {
        const { details, title, buttonText, onButtonPress, disclaimerText, image } = this.props;
        return (
            <div>
                <Core.Image src={image} className={css(styles.image)} />
                <div className={css(layoutStyle.flexColumn, styles.mainContainer)}>
                    <div className={css(layoutStyle.flexColumn)}>
                        <p className={css(styles.title)}>{title}</p>
                        <p className={css(styles.subtitle)}>{details}</p>
                        {this.policiesSection}
                    </div>
                    <div className={css(layoutStyle.flexColumnCenter, layoutStyle.fixedFooterModalContainer, styles.buttonSection)}>
                        <p className={css(styles.disclaimer)}>{disclaimerText}</p>
                        <Core.Button
                            size={Core.Button.SIZES.medium}
                            className={css(styles.button)}
                            onPress={onButtonPress}
                            disableRipple={true}
                            aira-label="continue button"
                            fullWidth={true}>
                            {buttonText}
                        </Core.Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(withUpdatedPolicyPopupBase(UpdatedPolicyPopup));

const HEADER_IMAGE_WIDTH = 400;
const HEADER_IMAGE_HEIGHT = 200;

const styles = StyleSheet.create({
    ...baseStyles,
    image: {
        width: HEADER_IMAGE_WIDTH,
        height: HEADER_IMAGE_HEIGHT,
        borderRadius: `${spacing.s1}px ${spacing.s1}px 0 0`
    },
    mainContainer: {
        ...baseStyles.mainContainer,
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3
    },
    buttonSection: {
        ...baseStyles.buttonSection,
        paddingBottom: spacing.s4,
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3,
    }
});
