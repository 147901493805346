import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { appFonts, baseColors, spacing } from '../../../../styles';
import { translate } from '../../../core';
import { COMMON_PROPS, TYPES } from '../../constants';

export default function WithChallengeProgressCircleBase(WrappedComponent) {
    class ChallengeProgressCircleBase extends PureComponent {
        static propTypes = {
            fill: PropTypes.number.isRequired,
            i18n: PropTypes.object.isRequired,
            isUpdating: PropTypes.bool,
            type: PropTypes.oneOf(_.values(TYPES)),
            language: PropTypes.string.isRequired,
        };

        static defaultProps = {
            isUpdating: false,
            type: TYPES.regular
        };

        get currentType() {
            return COMMON_PROPS[!_.includes(this.props.language, 'en') ? TYPES.spanish : this.props.type];
        }

        get periodContainerStyles() {
            return { width: this.currentType.circleSize, height: this.currentType.circleSize };
        }

        get isFull() {
            return this.props.fill >= 100;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    periodContainerStyles={this.periodContainerStyles}
                    currentType={this.currentType}
                    isFull={this.isFull}
                />
            );
        }
    }

    return translate()(ChallengeProgressCircleBase);
}

export const styles = {
    periodContainer: {
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
    },
    periodPercentagesFull: {
        ...appFonts.lgMedium,
        color: baseColors.black,
        marginBottom: spacing.s2,
        marginLeft: spacing.s0
    },
    periodPercentages: {
        ...appFonts.lgMedium,
        paddingTop: spacing.s0,
        paddingLeft: spacing.s0,
    },
    periodText: {
        color: baseColors.black,
        textAlign: 'center',
        lineHeight: 18
    },
    updatingText: {
        ...appFonts.mdMedium,
        color: baseColors.black
    }
};
