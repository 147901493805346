import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';
import { layoutStyle } from '../../../../styles';
import { components as Core } from '../../../core';
import WithCompetitionCardLeaderboardBase, { styles as baseStyles } from './CompetitionCardLeaderboardBase';
import crownIcon from '../../../../image/crownIcon3x.png';

class CompetitionCardLeaderboard extends PureComponent {
    static propTypes = {
        challengeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // eslint-disable-line
        leaderboardPosition: PropTypes.number.isRequired,
        i18n: PropTypes.object.isRequired,
        isUpdating: PropTypes.bool.isRequired,
        leaderboardTitle: PropTypes.string.isRequired,
    };

    render() {
        const { leaderboardTitle, isUpdating, i18n, leaderboardPosition } = this.props;
        return (
            <div className={css(layoutStyle.flexColumnCenter, styles.leaderboardContainer)}>
                <Core.Image src={crownIcon} className={css(styles.crownIcon)} />
                <p className={css(styles.leaderboardTitle)}>{leaderboardTitle}</p>
                {isUpdating ? (
                    <Core.Animated.Flash duration={3000} forever={true}>
                        <p className={css(styles.updatingText)}>{i18n.t('updating')}</p>
                    </Core.Animated.Flash>
                ) : <p className={css(styles.position)}>{leaderboardPosition}</p>}
            </div>
        );
    }
}

export default WithCompetitionCardLeaderboardBase(CompetitionCardLeaderboard);

const styles = StyleSheet.create({
    ...baseStyles,
    leaderboardContainer: {
        ...baseStyles.leaderboardContainer,
        borderRightStyle: 'solid'
    },
    leaderboardTitle: {
        ...baseStyles.leaderboardTitle,
        paddingBottom: 0,
    },
});