import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { css, StyleSheet } from 'aphrodite-jss';
import Grid from '@material-ui/core/Grid';
import { layoutStyle } from '../../../../styles';
import { WiderContainer } from '../Layout';

export const SmallerContainer = React.memo(({ children, className, isFixedFooter, isBorder, widerHeaderComponent,
    itemClassName, isOnboarding, gridSm }) =>
    (
        <WiderContainer>
            {widerHeaderComponent}
            <Grid
                container={true}
                justify="center"
                className={classnames(isFixedFooter ? css(styles.containerWithFixedFooter) : undefined, className)}>
                <Grid
                    item={true}
                    sm={gridSm}
                    className={classnames(css(isBorder ? layoutStyle.fixedFooterBorder : null), itemClassName)}>
                    <div className={css(isOnboarding ? null : layoutStyle.commonLayout)}>{children}</div>
                </Grid>
            </Grid>
        </WiderContainer>
    )
);

SmallerContainer.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    itemClassName: PropTypes.string,
    isFixedFooter: PropTypes.bool,
    isBorder: PropTypes.bool,
    widerHeaderComponent: PropTypes.node,
    isOnboarding: PropTypes.bool,
    gridSm: PropTypes.number
};

SmallerContainer.defaultProps = {
    className: undefined,
    itemClassName: undefined,
    isFixedFooter: false,
    isBorder: false,
    widerHeaderComponent: null,
    isOnboarding: false,
    gridSm: 8
};

export default SmallerContainer;

const styles = StyleSheet.create({
    containerWithFixedFooter: {
        paddingBottom: 48 //we need it to support existing code, because implementation of layout is awful
    }
});
