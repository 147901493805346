import _ from 'lodash';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { appFonts, baseColors, spacing } from '../../../../styles';
import { translate, selectors as coreSelectors, parsers, constants as coreConstants } from '../../../core';
import * as rewardsActions from '../../actions';
import { externalRewardName, getOrder, getRewards } from '../../selectors';

export default function WithOrderDetailsBase(WrappedComponent) {
    class OrderDetailsBase extends Component {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            rewards: PropTypes.object,
            order: PropTypes.object,
            orderId: PropTypes.number.isRequired,
            userEmail: PropTypes.string,
            fromRedemption: PropTypes.bool,
            i18n: PropTypes.object.isRequired,
            externalRewardName: PropTypes.string
        };

        static defaultProps = {
            rewards: {},
            order: {},
            userEmail: '',
            fromRedemption: false,
            externalRewardName: undefined
        };

        constructor(props) {
            super(props);
            this.props.actions.getRewardsOrder(props.orderId);
        }

        get item() {
            return this.props.order;
        }

        get hasInstruction() {
            return _.has(this.item, 'instruction');
        }

        get instruction() {
            return parsers.htmlDecode(parsers.removeAllHtmlTags(this.item.instruction || ''));
        }

        get hasAddress() {
            return _.get(this.item, 'address');
        }

        get timeCreated() {
            const orderCreatedTime = moment.unix(this.item.timestampCreated).format(coreConstants.DATE_FORMATS.monthFullDayShortYearFull);
            return this.props.i18n.t('orderedOnDateText', { orderCreatedTime });
        }

        get redeemedText() {
            return this.props.fromRedemption ? this.props.i18n.t('orderSuccessfulyyItemForConfirmation', { email: this.props.userEmail }) : '';
        }

        get shouldRenderCheckSection() {
            return this.props.fromRedemption;
        }

        get title() {
            const { fromRedemption, i18n } = this.props;
            return fromRedemption ?
                i18n.t('orderComplete') :
                i18n.t('orderSummary');
        }

        get pointsRedeemed() {
            return `${this.item.pointsRedeemed} ${this.props.externalRewardName}`;
        }


        get redeemForText() {
            return this.props.i18n.t('amountExchanged');
        }

        get statusText() {
            return this.props.i18n.t('orderStatus');
        }


        get orderDetailsText() {
            return this.props.i18n.t('orderDetails');
        }

        clearRewardOrderId = () => {
            this.props.actions.clearRedeemRewardOrderId();
        };

        getShortLink = item => {
            const link = item.value;
            const cutLink = _.split(link, '//');
            return cutLink[1];
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    item={this.item}
                    title={this.title}
                    hasInstruction={this.hasInstruction}
                    instruction={this.instruction}
                    hasAddress={this.hasAddress}
                    clearRewardOrderId={this.clearRewardOrderId}
                    timeCreated={this.timeCreated}
                    redeemedText={this.redeemedText}
                    pointsRedeemed={this.pointsRedeemed}
                    redeemForText={this.redeemForText}
                    statusText={this.statusText}
                    orderDetailsText={this.orderDetailsText}
                    getShortLink={this.getShortLink}
                    shouldRenderCheckSection={this.shouldRenderCheckSection}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        const orderId = ownProps.orderId || _.get(ownProps, 'match.params.orderId') || _.get(ownProps, 'route.params.orderId');
        const fromRedemption = ownProps.fromRedemption || _.get(ownProps, 'location.state.fromRedemption') || _.get(ownProps, 'route.params.fromRedemption');
        return {
            rewards: getRewards(state),
            order: getOrder(state, orderId),
            userEmail: coreSelectors.getCurrentUser(state).email,
            externalRewardName: externalRewardName(state),
            orderId: parseInt(orderId),
            fromRedemption
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(rewardsActions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(OrderDetailsBase));
}

export const styles = {
    mainContainer: {
        paddingTop: spacing.s5
    },
    coreInfoText: {
        ...appFonts.mdMedium
    },
    smallTitle: {
        color: baseColors.grey40,
        ...appFonts.smRegular,
        marginBottom: spacing.s0
    },
    longText: {
        ...appFonts.mdRegular,
        color: baseColors.grey20,
        flex: 1,
        flexWrap: 'wrap',
        whiteSpace: 'pre-line'
    },
    infoContainerSpacing: {
        marginBottom: spacing.s5
    },
    successInfoContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        marginBottom: spacing.s5
    },
    iconCheckContainer: {
        marginRight: spacing.s1
    },
    redeemedText: {
        color: baseColors.successDarker,
        ...appFonts.lgMedium,
        marginTop: spacing.s3,
        marginBottom: spacing.s3,
    },
    boldText: {
        ...appFonts.mdMedium
    },
    description: {
        ...appFonts.mdRegular,
        marginBottom: spacing.s1
    },
    orderContainer: {
        flex: 1,
        flexDirection: 'column',
        borderTopWidth: 1,
        borderTopColor: baseColors.grey70,
        paddingTop: spacing.s5
    },
    orderInnerContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: spacing.s3,
        marginBottom: spacing.s3
    },
    orderItem: {
        width: '50%'
    },
    additionalInfoContainer: {
        marginBottom: spacing.s1
    },
    doneButton: {
        marginBottom: spacing.s5
    },
    orderDetailsText: {
        ...appFonts.lgBold
    },
    link: {
        color: baseColors.secondary,
    }
};