import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import WithHealthTopicsBase from './HealthTopicsBase';
import PreferencesUI from '../PreferencesUI';
import { translate } from '../../../core';

class HealthTopics extends PureComponent {
    static propTypes = {
        saveData: PropTypes.func.isRequired,
        toggleCheckbox: PropTypes.func.isRequired,
        i18n: PropTypes.object.isRequired,
        isOnboarding: PropTypes.bool,
        isSaving: PropTypes.bool,
        screenDetails: PropTypes.object.isRequired,
        selectData: PropTypes.array,
        hideBottomButton: PropTypes.bool,
        selected: PropTypes.array,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        isLastPageInSection: PropTypes.bool
    };

    static defaultProps = {
        hideBottomButton: false,
        isOnboarding: false,
        isSaving: false,
        selectData: [],
        selected: [],
        isLastPageInSection: false
    };

    render() {
        const { i18n, isLastPageInSection } = this.props;
        return (
            <PreferencesUI
                isOnboarding={this.props.isOnboarding}
                saveData={this.props.saveData}
                toggleCheckbox={this.props.toggleCheckbox}
                i18n={i18n}
                hideBottomButton={this.props.hideBottomButton}
                isSaving={this.props.isSaving}
                selectData={this.props.selectData}
                screenDetails={this.props.screenDetails}
                title={this.props.title}
                desc={this.props.description}
                selected={this.props.selected}
                isLastPageInSection={isLastPageInSection}
            />
        );
    }
}

export default translate()(WithHealthTopicsBase(HealthTopics));
