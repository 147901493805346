import { spacing, windowSize, media } from '../../styles';

export const NAME = 'tours';
export const NOT_PERSISTED = false;

export const TOURS = {
    PRODUCT_TOUR: 'productTour',
    FRIENDS_AND_FAMILY_TOUR: 'friendsAndFamilyTour',
    NEW_FEATURE_TOUR: 'newFeatureTour',
    NEW_SLEEP_TRACKING_TOUR: 'newSleepTrackingTour',
    NEW_FEATURE_SMART_GOALS: 'newFeatureSmartGoals',
    NEW_FEATURE_REACTIONS_TO_THE_POST: 'newFeatureReactionToThePost',
    NEW_FEATURE_MODAL_ME_PAGE_UPDATES: 'newFeatureModalMePageUpdates',
    NEW_FEATURE_MODAL_BELL_EAP_PROMO: 'newFeatureModalBellEAP',
    NEW_FEATURE_MODAL_QUICKER_ACTIVITY_LOG_VIEW: 'newFeatureModalQuickerActivityLogView'
};

export const UP_ARROW = 'UP';
export const DOWN_ARROW = 'DOWN';
export const ARROWS = {
    UP: UP_ARROW,
    DOWN: DOWN_ARROW
};
export const ARROW_SIZE = spacing.s3;
export const isXSScreenResolution = () => windowSize.width <= media.values.sm;

export const NEW_FEATURE_MODAL_MAX_WIDTH = 400;
