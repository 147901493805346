/* eslint-disable no-case-declarations */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { StyleSheet, css } from 'aphrodite-jss';
import { components as Core, openUrl } from '../../../../core';
import WithEarnPartnersDetailBase, { styles as baseStyles, ONE_LINE, TWO_LINES, THREE_LINES } from './EarnPartnersDetailBase';
import { EARN_PARTNERS_STATUS_TYPES, LINK_TYPES, WEB_REWARD_IMAGE_HEIGHT } from '../../../constants';
import { appFonts, baseColors, importantClass, spacing, layoutStyle } from '../../../../../styles';

class EarnPartnersDetail extends PureComponent {
    static propTypes = {
        partner: PropTypes.object.isRequired,
        onChange: PropTypes.func.isRequired,
        i18n: PropTypes.object.isRequired,
        listItems: PropTypes.array.isRequired,
        linkPartner: PropTypes.func.isRequired,
        partnerDescriptions: PropTypes.array.isRequired,
        unlinkPartner: PropTypes.func.isRequired,
        updateLinkPartner: PropTypes.func.isRequired,
        showLinkingStatus: PropTypes.bool.isRequired,
        identifierFieldError: PropTypes.string.isRequired,
        userAccountNumber: PropTypes.string
    };

    static defaultProps = {
        userAccountNumber: ''
    };

    onChange = prop => value => {
        this.props.onChange(prop)(value);
    };

    get noImageHeaderSection() {
        return (
            <div className={css(styles.noHeaderImageContainer)} />
        );
    }

    get linkSection() {
        const { partner, linkPartner, unlinkPartner, updateLinkPartner, identifierFieldError } = this.props;
        if (partner.linkingAllowed) {
            let statusText = '';
            let statusLabelView = null;
            let textFieldView = null;
            let linkView = null;
            switch (partner.status) {
                case (EARN_PARTNERS_STATUS_TYPES.LINKED):
                    statusText = this.props.i18n.t('linkedToPartnerName', { partner_name: partner.name });
                    statusLabelView = (
                        <div className={css(styles.statusContainer)}>
                            <div className={css(styles.circle, styles.linkedCircle)}>
                                <Core.Icon
                                    name="link"
                                    size={spacing.s2}
                                    color={baseColors.white}
                                    type="fa"
                                />
                            </div>
                            <Core.TextSeeMore
                                isCollapseHidden={true}
                                isExpandHidden={true}
                                numberOfLines={TWO_LINES}
                                className={css(styles.normalStatusLabel)}>
                                {statusText}
                            </Core.TextSeeMore>
                        </div>
                    );
                    linkView = (
                        <Core.Button
                            size="medium"
                            fullWidth={true}
                            onPress={unlinkPartner}
                            isLoading={this.props.showLinkingStatus}
                            type="outlined">
                            {this.props.i18n.t('unlinkAccount')}
                        </Core.Button>
                    );
                    break;
                case (EARN_PARTNERS_STATUS_TYPES.PENDING):
                    statusText = this.props.i18n.t('linkStatusUpdating');
                    statusLabelView = (
                        <div className={css(styles.statusContainer)}>
                            <div className={css(styles.circle, styles.pendingCircle)}>
                                <Core.Icon
                                    name="clock"
                                    size={spacing.s2}
                                    color={baseColors.white}
                                    type="fa"
                                />
                            </div>
                            <Core.TextSeeMore
                                isCollapseHidden={true}
                                isExpandHidden={true}
                                numberOfLines={ONE_LINE}
                                className={css(styles.normalStatusLabel)}>
                                {statusText}
                            </Core.TextSeeMore>
                        </div>
                    );
                    const statusDetailsText = partner.linkID
                        ? this.props.i18n.t('earnPartnerAccountPendingAddLink')
                        : this.props.i18n.t('earnPartnerAccountPendingRemoveLink');
                    linkView = (
                        <div className={css(styles.statusContainer)}>
                            <Core.TextSeeMore
                                isCollapseHidden={true}
                                isExpandHidden={true}
                                numberOfLines={TWO_LINES}
                                className={css(styles.detailsText)}>
                                {statusDetailsText}
                            </Core.TextSeeMore>
                        </div>
                    );
                    break;
                case (EARN_PARTNERS_STATUS_TYPES.ERROR):
                    statusText = this.props.i18n.t('linkingError');
                    statusLabelView = (
                        <div className={css(styles.statusContainer, styles.noBottomMargin)}>
                            <div className={css(styles.circle, styles.errorCircle)}>
                                <Core.Icon
                                    name="exclamation-circle"
                                    size={spacing.s2}
                                    color={baseColors.white}
                                    type="fa"
                                    fill="light"
                                />
                            </div>
                            <Core.TextSeeMore
                                isCollapseHidden={true}
                                isExpandHidden={true}
                                numberOfLines={TWO_LINES}
                                className={css(styles.normalStatusLabel)}>
                                {statusText}
                            </Core.TextSeeMore>
                        </div>
                    );
                    const { userAccountNumber } = this.props;
                    textFieldView = (
                        <Core.Input
                            label={this.props.i18n.t('linkToPartner')}
                            onChangeText={this.onChange('userAccountNumber')}
                            fullWidth={true}
                            value={userAccountNumber}
                            helperText={identifierFieldError}
                            error={!!identifierFieldError}
                        />
                    );

                    linkView = (
                        <Core.Button
                            size="medium"
                            fullWidth={true}
                            onPress={updateLinkPartner}
                            isLoading={this.props.showLinkingStatus}
                            type="contained">
                            {this.props.i18n.t('linkAccount')}
                        </Core.Button>
                    );
                    break;
                default:
                    switch (partner.linkType) {
                        case LINK_TYPES.linkWithPartner:
                            statusText = this.props.i18n.t('linkToPartnerNameWebsite', { partner_name: partner.name });

                            linkView = (
                                <Core.Button
                                    fullWidth={true}
                                    size="medium"
                                    withIcon={true}
                                    onPress={this.openPartnerURL(partner.linkPartnerUrl)}
                                    type="contained">
                                    {statusText}
                                    {this.shopButtonIcon}
                                </Core.Button>
                            );
                            break;
                        case LINK_TYPES.linkWithApp:
                        default:
                            statusText = this.props.i18n.t('linkToPartnerName', { partner_name: partner.name });

                            textFieldView = (
                                <Core.Input
                                    fullWidth={true}
                                    label={this.props.i18n.t('earnPartnerMembershipIdentifier')}
                                    onChangeText={this.onChange('userAccountNumber')}
                                    error={!!identifierFieldError}
                                    helperText={identifierFieldError}
                                />
                            );

                            linkView = (
                                <Core.Button
                                    fullWidth={true}
                                    size="medium"
                                    onPress={linkPartner}
                                    isLoading={this.props.showLinkingStatus}
                                    type="contained">
                                    {this.props.i18n.t('linkAccount')}
                                </Core.Button>
                            );
                    }
                    statusLabelView = (
                        <div className={css(styles.statusContainer, styles.noBottomMargin)}>
                            <Core.TextSeeMore
                                isCollapseHidden={true}
                                isExpandHidden={true}
                                numberOfLines={ONE_LINE}
                                className={css(styles.normalStatusLabel)}>
                                {statusText}
                            </Core.TextSeeMore>
                        </div>
                    );
            }

            return (
                <div className={css(styles.sectionCard, styles.rowContainer)}>
                    {statusLabelView}
                    {textFieldView}
                    <div className={css(styles.buttonContainer)}>
                        {linkView}
                    </div>
                </div>
            );
        }

        return (
            <div className={css(styles.sectionCard, styles.rowContainer)}>
                <div className={css(styles.statusContainer)}>
                    <Core.TextSeeMore
                        isCollapseHidden={true}
                        isExpandHidden={true}
                        className={css(styles.normalStatusLabel)}
                        numberOfLines={ONE_LINE}>
                        {this.props.i18n.t('shopWithPartnerName', { partner_name: partner.name })}
                    </Core.TextSeeMore>
                </div>
                <div className={css(styles.buttonContainer)}>
                    <Core.Button
                        size="medium"
                        fullWidth={true}
                        onPress={this.openPartnerURL(partner.shopPartnerUrl)}
                        type="contained">
                        {this.props.i18n.t('shopWithPartner')}
                        {this.shopButtonIcon}
                    </Core.Button>
                </div>
            </div>
        );
    }

    get shopButtonIcon() {
        return (
            <div className={css(styles.shopButtonIcon)}>
                <Core.Icon
                    name="external-link"
                    type="fa"
                    fill="light"
                    color={baseColors.white}
                    size={spacing.s3}
                />
            </div>
        );
    }

    renderListItem = item => (
        item.url ?
            <Core.ListItem
                className={css(layoutStyle.listItem)}
                button={true}
                onClick={this.openPartnerURL(item.url)}>
                <Core.ListItemText primary={item.label} />
                <Core.Icon
                    type="fa"
                    name="angle-right"
                    color={baseColors.secondary}
                />
            </Core.ListItem> : null
    );

    renderPartnerDetails = item => (
        item.description ?
            <div className={css(styles.rowContainer)}>
                <Core.TextSeeMore
                    isCollapseHidden={true}
                    isExpandHidden={true}
                    numberOfLines={ONE_LINE}
                    className={css(styles.headerLabel)}>
                    {item.label}
                </Core.TextSeeMore>
                <span className={css(styles.detailsText)}>{item.description}</span>
            </div> : null
    );

    get detailsScrollView() {
        const { partner } = this.props;
        return (
            <div className={css(styles.mainContainer)}>
                <Core.FixedHeader
                    fullWidth={true}
                    subheader={
                        <Core.SubHeader>
                            <Core.TextSeeMore
                                className={css(styles.partnerTitle)}
                                isCollapseHidden={true}
                                isExpandHidden={true}>
                                {partner.name}
                            </Core.TextSeeMore>
                        </Core.SubHeader>
                    }
                />
                {partner.headline ?
                    <div className={css(styles.sectionCard)}>
                        <div className={css(styles.rowContainer)}>
                            <Core.TextSeeMore className={css(styles.detailsText)} numberOfLines={TWO_LINES}>{partner.headline}</Core.TextSeeMore>
                        </div>
                    </div> : null
                }
                <div className={css(styles.sectionCard)}>
                    {partner.description ?
                        <div className={css(styles.rowContainer)}>
                            <Core.TextSeeMore
                                numberOfLines={THREE_LINES}
                                isExpandHidden={false}
                                isCollapseHidden={true}
                                buttonClassName={css(styles.seeMoreStyle)}>
                                <Core.AutoLink
                                    className={css(styles.detailsText)}
                                    text={partner.description}
                                />
                            </Core.TextSeeMore>
                        </div> : null
                    }
                    {_.map(this.props.partnerDescriptions, this.renderPartnerDetails)}
                </div>
                {this.linkSection}
                <Core.List component="ul">
                    {_.map(this.props.listItems, this.renderListItem)}
                </Core.List>
            </div>
        );
    }

    openPartnerURL = url => () => {
        if (url) openUrl(url);
    };

    render() {
        const { partner } = this.props;

        return (
            <Core.SmallerContainer
                widerHeaderComponent={(
                    <Core.EntityDetailsHeader
                        hasBackButton={true}
                    />)}
            >
                {partner.logo ?
                    <Core.Image
                        resizeMode="contain"
                        className={css(styles.logo)}
                        src={partner.logo}
                    /> : this.noImageHeaderSection
                }
                {this.detailsScrollView}
            </Core.SmallerContainer>
        );
    }
}

export default WithEarnPartnersDetailBase(EarnPartnersDetail);

const styles = StyleSheet.create({
    ...baseStyles,
    rowContainer: {
        ...baseStyles.rowContainer,
        wordBreak: 'break-word',
        wordWrap: 'break-word',
    },
    sectionCard: {
        ...baseStyles.sectionCard,
        borderStyle: 'solid',
        paddingRight: spacing.s0
    },
    seeMoreStyle: importantClass({
        ...appFonts.xsBold,
        color: baseColors.secondary,
        marginVertical: spacing.s0
    }),
    circle: {
        ...baseStyles.circle,
        display: 'flex',
    },
    statusContainer: {
        ...baseStyles.statusContainer,
        display: 'flex'
    },
    noBottomMargin: {
        marginBottom: 0
    },
    partnerTitle: {
        marginLeft: spacing.s2,
        ...appFonts.lgMedium
    },
    logo: {
        height: WEB_REWARD_IMAGE_HEIGHT,
        width: 'auto !important',
        margin: 'auto'
    }
});