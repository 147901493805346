import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import { StyleSheet, css } from 'aphrodite-jss';

import Icon from '../Icon';
import Button from '../Button';
import Checkbox from '../Checkbox';
import { baseColors, layoutStyle, spacing, importantStyles, containerStyle } from '../../../../styles';
import WithInfoModalBase, { styles as baseStyles, ICON_SIZE, TOP_DISTANCE_TO_ICON,
    ICON_BACKGROUND_SIZE, BOTTOM_BTN_STYLES, SHADOW } from './InfoModalBase';


class InfoModal extends PureComponent {
    static propTypes = {
        iconName: PropTypes.string,
        title: PropTypes.string.isRequired,
        text: PropTypes.string,
        textSecondLine: PropTypes.string,
        htmlText: PropTypes.string,
        buttonTitle: PropTypes.string.isRequired,
        onButtonPress: PropTypes.func,
        footerComponent: PropTypes.func,
        iconClassName: PropTypes.string,
        className: PropTypes.string,
        textStyle: PropTypes.string,
        renderIcon: PropTypes.func,
        hasIcon: PropTypes.string,
        isButtonVisible: PropTypes.bool,
        close: PropTypes.func,
        renderText: PropTypes.func,
        isTextCentered: PropTypes.bool,
        iconBgColor: PropTypes.string.isRequired,
        buttons: PropTypes.array,
        onCheckbox: PropTypes.func.isRequired,
        checkboxLabel: PropTypes.string,
        isChecked: PropTypes.bool,
        hasSecondLineText: PropTypes.bool,
        iconFill: PropTypes.string,
        customButtons: PropTypes.object
    };

    static defaultProps = {
        iconName: undefined,
        text: undefined,
        textSecondLine: undefined,
        htmlText: undefined,
        onButtonPress: null,
        footerComponent: null,
        iconClassName: css(layoutStyle.bgBonus),
        className: null,
        textStyle: null,
        renderIcon: null,
        hasIcon: false,
        isButtonVisible: true,
        close: null,
        renderText: undefined,
        isTextCentered: false,
        buttons: [],
        checkboxLabel: undefined,
        isChecked: false,
        hasSecondLineText: false,
        iconFill: 'light',
        customButtons: null
    };

    get iconContainerStyles() {
        return this.props.renderIcon
            ? css(styles.iconContainer)
            : classNames(css(styles.iconContainer), this.props.iconClassName);
    }

    get textStyles() {
        const { textStyle, isTextCentered, title, hasIcon, checkboxLabel, hasSecondLineText } = this.props;
        return css(
            styles.description, textStyle,
            hasSecondLineText && styles.mBottom,
            isTextCentered && styles.centerText,
            !title && styles.mTop,
            !title && !hasIcon && styles.textOnly,
            checkboxLabel && styles.descriptionContainer
        );
    }

    createMarkup = () => ({ __html: this.props.htmlText });

    onButtonPress = () => this.props.onButtonPress ? this.props.onButtonPress() : this.props.close();

    render() {
        const { className, hasIcon, iconBgColor, renderIcon, iconName, title, text, textSecondLine,
            textStyle, isTextCentered, htmlText, renderText, isButtonVisible, buttonTitle,
            footerComponent, onCheckbox, isChecked, checkboxLabel, buttons, customButtons, iconFill, greyText } = this.props;
        const classes = { label: css(styles.checkboxLabel) };

        return (
            <div className={className}>
                <div className={css(styles.innerContainer)}>
                    {hasIcon ? (
                        <div className={this.iconContainerStyles} style={{ backgroundColor: iconBgColor, zIndex: Z_INDEX }}>
                            {renderIcon ? renderIcon() : null}
                            {iconName ?
                                <Icon name={iconName} size={ICON_SIZE} type="fa" fill={iconFill} color={baseColors.white} />
                                : null
                            }
                        </div>
                    ) : null
                    }
                    <div className={css(styles.bodyContainer, layoutStyle.flexColumn, hasIcon && styles.topOffset)}>
                        <div className={css(layoutStyle.commonPaddingHorizontal)}>
                            {title && <div className={css(styles.title, !hasIcon && styles.noIconOffset, greyText && styles.greyColorText)}>{title}</div>}
                            <div className={css(styles.description, checkboxLabel && styles.descriptionContainer, textStyle)}>
                                {text && <p className={this.textStyles}>{text}</p>}
                                {textSecondLine && (
                                    <p className={css(textStyle, isTextCentered && styles.centerText)}>
                                        {textSecondLine}
                                    </p>
                                )}
                                {/* eslint-disable-next-line react/no-danger */}
                                {htmlText && <div dangerouslySetInnerHTML={this.createMarkup()} className={css(textStyle)} />}
                                {renderText && <div className={textStyle}>{renderText()}</div>}
                            </div>
                        </div>
                        {isButtonVisible ? (
                            <Button
                                type="text"
                                id="infoModalButton"
                                disableRipple={true}
                                onPress={this.onButtonPress}
                                className={css(styles.defaultText, styles.modalBottomBtn, styles.buttonBorder)}>
                                {buttonTitle}
                            </Button>
                        ) : null}
                        {checkboxLabel ? (
                            <Checkbox
                                classes={classes}
                                checked={isChecked}
                                onChange={onCheckbox}
                                label={checkboxLabel}
                                color={baseColors.dangerDarker}
                                className={css(styles.checkboxContainer)}
                            />
                        ) : null }
                        {footerComponent ? footerComponent() : null}
                        {buttons.length ? (
                            <div className={css(layoutStyle.flexColumn)}>
                                {_.map(buttons, (button, index) => (
                                    <Button
                                        type="text"
                                        size="medium"
                                        key={button.text}
                                        disableRipple={true}
                                        onPress={button.onPress}
                                        disableMainContainer={true}
                                        disabled={button.isDisabled && !isChecked}
                                        id={_.camelCase(`${button.text}InfoModal`)}
                                        className={css(
                                            button.isBoldText ? styles.boldText : styles.defaultText,
                                            index === (buttons.length - 1) ? styles.modalBottomBtn : styles.modalBtn,
                                            button.isDangerText && styles.dangerText,
                                            button.isDisabled && !isChecked && styles.disabledButton,
                                            styles.buttonBorder,
                                            containerStyle.menuItemfocusVisible,
                                        )}
                                        tabIndex="0">
                                        {button.text}
                                    </Button>
                                ))}
                            </div>
                        ) : customButtons}
                    </div>
                </div>
            </div>
        );
    }
}

export default WithInfoModalBase(InfoModal);

const Z_INDEX = 100;
const ICON_BORDER_RADIUS = 180;
const BODY_TOP = spacing.s7;
const MODAL_MIN_WIDTH = 400;

const styles = StyleSheet.create(importantStyles({
    ...baseStyles,
    innerContainer: {
        minWidth: MODAL_MIN_WIDTH,
        alignItems: 'center',
        flexDirection: 'column',
        display: 'flex'
    },
    topOffset: {
        paddingTop: TOP_DISTANCE_TO_ICON,
        position: 'relative',
        top: -BODY_TOP,
    },
    noIconOffset: {
        marginTop: BODY_TOP,
    },
    textOnly: {
        marginTop: spacing.s7,
    },
    bodyContainer: {
        backgroundColor: baseColors.white,
        borderRadius: spacing.s1,
        shadowRadius: spacing.s2,
        shadowOpacity: SHADOW.opacity,
        shadowOffset: { width: 0, height: 0 },
        shadowColor: baseColors.black,
        boxShadow: `0px 30px 60px -30px rgba(0, 0, 0, ${SHADOW.opacity})`,
        width: '100%'
    },
    iconContainer: {
        alignItems: 'center',
        borderRadius: ICON_BORDER_RADIUS,
        width: ICON_BACKGROUND_SIZE,
        height: ICON_BACKGROUND_SIZE,
        display: 'flex',
        justifyContent: 'center',
    },
    closeButton: {
        width: '100%'
    },
    description: {
        ...baseStyles.description,
        whiteSpace: 'pre-wrap'
    },
    modalBtn: {
        ...baseStyles.modalBtn,
        '&:hover': {
            borderRadius: 0,
            backgroundColor: baseColors.grey90,
        },
        '&:after': {
            borderRadius: 0,
        },
    },
    modalBottomBtn: {
        ...baseStyles.modalBottomBtn,
        '&:hover': {
            ...BOTTOM_BTN_STYLES,
            backgroundColor: baseColors.grey90,
        },
        '&:after': {
            borderRadius: 0,
        },
    },
    checkboxContainer: {
        marginBottom: spacing.s3,
        marginLeft: spacing.s0,
    },
    checkboxLabel: {
        ...baseStyles.checkboxLabel,
        marginLeft: -5,
    },
    descriptionContainer: {
        marginBottom: 3,
    },
    disabledButton: {
        opacity: '0.5',
        cursor: 'not-allowed',
        pointerEvents: 'auto',
    },
    buttonBorder: {
        borderTop: `1px solid ${baseColors.grey80}`,
    },
    greyColorText: {
        color: baseColors.grey20
    }
}));
