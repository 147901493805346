import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export default function WithCreateChallengePageBase(WrapperComponent) {
    return class CreateChallengePageBase extends PureComponent {
        static propTypes = {
            onPress: PropTypes.func,
            title: PropTypes.string.isRequired,
            subtitle: PropTypes.string,
            items: PropTypes.array,
            titleStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.number])
        };

        static defaultProps = {
            subtitle: undefined,
            titleStyle: undefined,
            items: undefined,
            onPress: undefined,
        };

        keyExtractor = item => _.toString(item.id);

        render() {
            return (
                <WrapperComponent
                    {...this.props}
                    keyExtractor={this.keyExtractor}
                />
            );
        }
    };
}
