import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FREQUENCY } from '../../constants';
import * as selectors from '../../selectors';
import { getChallenge } from '../../actions';
import { hasMultipleActivitiesToTrack, isGoal } from '../../services/helper';
import { appFonts, commonPadding, baseColors, spacing } from '../../../../styles';
import { pointsHelper, translate, DATE_FORMATS, selectors as coreSelectors, constants as coreConstants } from '../../../core';
import { getSingleActivityTotal, daysWithActivities, datesUntilDeadline, arrayOfDatesInPeriod, getPeriodSmartTarget, datesInWeekPeriod } from '../../services/activitiesHelper';

export const MAX_PERCENTAGE_STRING = '100%';
export const MIN_PERCENTAGE_STRING = '0%';
export const LOAD_NUMBER = 10;

export default function WithCompetitionActivityHistoryBase(WrappedComponent) {
    class CompetitionActivityHistoryBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            history: PropTypes.object.isRequired,
            challenge: PropTypes.object.isRequired,
            activityLogs: PropTypes.array.isRequired,
            customPointsUnit: PropTypes.string.isRequired,
            actions: PropTypes.object.isRequired,
            challengeId: PropTypes.string.isRequired,
        };

        constructor(props) {
            super(props);
            _.isEmpty(this.props.challenge) && props.actions.getChallenge(props.challengeId);
        }

        getItemPoints = (item, isDailyHeader = false, isActivityDetails = false) => {
            if (this.isOneActivityToTrack) {
                if (isActivityDetails) return this.activityString(item);
                return isDailyHeader ? this.dailyTotalString(item.logs) : this.weeklyTotalString(item.logs);
            }
            const points = item.pointsPerWeek || item.points || 0;
            return pointsHelper.formatPoints(points, this.pointsUnit(points));
        };

        getItemDate = item => item.period ? item.period : moment(item.date).format(DATE_FORMATS.monthFullDayShort);

        getItemPercentage = (item, isDailyHeader = false, isRestartGoal = false, period = undefined) => {
            if (!this.isPercentageTagShown(isDailyHeader)) return null;
            const singleActivityTotal = getSingleActivityTotal(item.logs, isDailyHeader);
            const multipleActivitiesTotal = this.isWeeklyPeriod ? item.pointsPerWeek : item.points;
            const gainedPerPeriod = this.isOneActivityToTrack ? singleActivityTotal : multipleActivitiesTotal;
            let percentageForPeriod = Math.round(gainedPerPeriod / this.overallTargetValue * 100);

            if (isRestartGoal && period) {
                const periodTargetValue = this.getPeriodSmartTarget(period);
                percentageForPeriod = Math.round(gainedPerPeriod / periodTargetValue * 100);
            }

            return `${percentageForPeriod > 100 ? 100 : percentageForPeriod}%`;
        }

        get overallTargetValue() {
            const { challenge } = this.props;
            return parseInt(_.get(challenge, `activityUnitDetails[0].${this.isOneActivityToTrack ? 'target_quantity' : 'amount'}`));
        }

        get noActivitiesText() {
            return this.props.i18n.t(`havent_tracked_${this.challengeLabel}`);
        }

        get challengeLabel() {
            const { challenge } = this.props;
            if (_.isEmpty(challenge)) return null;
            return isGoal(challenge) ? coreConstants.ENTITIES.goal : coreConstants.ENTITIES.challenge;
        }

        get progressDetailsText() {
            return this.props.i18n.t('progressDetails');
        }

        get isOneActivityToTrack() {
            return !hasMultipleActivitiesToTrack(this.props.challenge);
        }

        get daysWithActivities() {
            const { activityLogs } = this.props;
            return daysWithActivities(activityLogs);
        }

        get isWeeklyPeriod() {
            return _.get(this.props.challenge, 'frequency') === FREQUENCY.weekly;
        }

        get isDailyPeriod() {
            return _.get(this.props.challenge, 'frequency') === FREQUENCY.daily;
        }

        get hasNoActivities() {
            return !_.get(this.props.challenge, 'progress.periods.length');
        }

        get noTrackedText() {
            return this.props.i18n.t('no_activities_tracked');
        }

        get restartGoalStartDate() {
            const { challenge } = this.props;
            return _.get(challenge, 'progress.restartGoalPreviousPeriods[0].startDate')
            || _.get(challenge, 'startDate');
        }

        get challengePreviousDays() {
            const challenge = _.get(this.props, 'challenge');
            if (!challenge) return [];
            const { startDate, challengeDeadline, isEnded } = challenge;
            return datesUntilDeadline(startDate, challengeDeadline, isEnded);
        }

        getDatesInPeriod = (periodDate, index) => {
            const { isRestartGoal, activityLogs, challenge } = this.props;
            const isFirstWeek = index === (activityLogs.length - 1);
            const startDate = isRestartGoal ? this.restartGoalStartDate : challenge.startDate;
            return arrayOfDatesInPeriod(periodDate, startDate, isFirstWeek, isRestartGoal);
        }

        getDatesInWeekPeriod = log => datesInWeekPeriod(log.date);

        isPercentageTagShown = isDailyHeader => (this.isWeeklyPeriod && !isDailyHeader) || this.isDailyPeriod;

        dailyTotalString = dailyLogs => {
            const dailyTotal = getSingleActivityTotal(dailyLogs, true);
            const activityUnitId = dailyLogs && dailyLogs[0].activityUnitId;
            const unit = this.activityUnit(activityUnitId, dailyTotal);
            return `${dailyTotal} ${unit}`;
        };

        weeklyTotalString = weeklyLogs => {
            const { challenge } = this.props;
            const weeklyTotal = getSingleActivityTotal(weeklyLogs);
            const activityUnitId = _.get(challenge, 'activityUnitsIds[0]');
            const unit = this.activityUnit(activityUnitId, weeklyTotal);
            return `${weeklyTotal} ${unit}`;
        }

        activityString = item => {
            return `${item.quantity} ${this.activityUnitString(item)}`;
        }

        activityUnitString = item => {
            const unit = this.activityUnit(item.activityUnitId, item.quantity);
            return `${unit}`;
        }

        pointsUnit = points => {
            const unitDetails = _.get(this.props.challenge, 'activityUnitDetails[0]');
            if (this.isOneActivityToTrack) return points === 1 ? unitDetails.unit_singular : unitDetails.unit;
            return this.props.customPointsUnit;
        }

        activityUnit = (activityUnitId, quantity) => {
            const { customPointsUnit, challenge, activityUnits } = this.props;
            if (!activityUnitId) {
                const oneActivityUnit = _.get(challenge, 'dailyAverageAmount.unit') || _.get(challenge, 'activityUnitDetails[0].unit');
                return this.isOneActivityToTrack ? oneActivityUnit : customPointsUnit;
            }
            const activityUnit = activityUnits && activityUnits[activityUnitId];
            if (activityUnit) return quantity === 1 ? activityUnit.unitSingular : activityUnit.unitName;
        }

        getActivityDetails = activity => {
            const { customPointsUnit, i18n } = this.props;
            return this.isOneActivityToTrack
                ? `${i18n.t('earned')} ${activity.points} ${customPointsUnit}`
                : `${activity.quantity} ${this.activityUnit(activity.activityUnitId, activity.quantity)}`;
        }

        getPeriodSmartTarget = item => {
            const { isRestartGoal, challenge } = this.props;
            if (!isRestartGoal) {
                return undefined;
            }

            return getPeriodSmartTarget(item, isRestartGoal, challenge);
        }

        activityItemHeaderProps = item => ({
            date: this.getItemDate(item),
            points: this.getItemPoints(item),
            completionPercentage: this.getItemPercentage(item),
            weeklyTarget: `${this.getPeriodSmartTarget(item)} ${this.activityUnitString(item)}`
        });

        activityItemDetailsProps = (item, isRestartGoal = false, period = undefined) => ({
            date: this.getItemDate(item),
            points: this.getItemPoints(item, true),
            completionPercentage: this.getItemPercentage(item, true, isRestartGoal, period),
        });

        activityDetailsProps = activity => ({
            activityDetails: this.getActivityDetails(activity),
            activityPoints: this.getItemPoints(activity, false, true),
        });

        getFormattedDate = date => moment(date).format(DATE_FORMATS.monthFullDayShort);

        get dailyTargetText() {
            return this.props.i18n.t('dailyTarget');
        }

        get smartHeaderText() {
            return this.props.i18n.t('dailyTargetForWeek');
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    activityItemHeaderProps={this.activityItemHeaderProps}
                    activityItemDetailsProps={this.activityItemDetailsProps}
                    activityDetailsProps={this.activityDetailsProps}
                    daysWithActivities={this.daysWithActivities}
                    isWeeklyPeriod={this.isWeeklyPeriod}
                    isDailyPeriod={this.isDailyPeriod}
                    noTrackedText={this.noTrackedText}
                    noActivitiesText={this.noActivitiesText}
                    progressDetailsText={this.progressDetailsText}
                    getFormattedDate={this.getFormattedDate}
                    getDatesInPeriod={this.getDatesInPeriod}
                    getDatesInWeekPeriod={this.getDatesInWeekPeriod}
                    challengePreviousDays={this.challengePreviousDays}
                    smartHeaderText={this.smartHeaderText}
                    dailyTargetText={this.dailyTargetText}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const challengeId = ownProps.challengeId || _.get(ownProps, 'match.params.challengeId') || _.get(ownProps, 'route.params.challengeId');
        const challenge = selectors.getChallenge(state, challengeId);
        const isWeeklyPeriod = _.get(challenge, 'frequency') === FREQUENCY.weekly;
        const isRestartGoal = selectors.isRestartGoal(state, challengeId);
        const isSmartModeGoal = selectors.isSmartModeGoal(state, challengeId);

        return {
            challenge,
            customPointsUnit: coreSelectors.getCustomPointsUnit(state),
            isLoading: selectors.isLoadingChallengeInfo(state, challengeId),
            challengeId,
            activityLogs: isWeeklyPeriod || isRestartGoal
                ? selectors.getActivityLogsForChallengeByWeek(state, challengeId, challenge.startDate)
                : selectors.getActivityLogsForChallengeByDay(state, challengeId),
            periods: selectors.getProgressPeriods(state, challengeId),
            activityUnits: selectors.getActivityUnits(state),
            activityLogsExtended: selectors.getActivityLogsExtended(state),
            isRestartGoal,
            isSmartModeGoal
        };
    };

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators({ getChallenge }, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(CompetitionActivityHistoryBase));
}

const ACTIVITY_ITEM_HEIGHT = 46;
const SUBHEADER_HEIGHT = 34;
const DAILY_HEADER_HEIGHT = 42;
const TAG_WIDTH = 48;
const TAG_BG_COLOR = 'rgba(0,0,0,0.05)';

const TAG_COMMON_PROPS = {
    width: TAG_WIDTH,
    marginLeft: spacing.s3,
    marginRight: spacing.s1,
    justifyContent: 'center',
    padding: spacing.s0,
};

const TAG_TEXT_COMMON_PROPS = {
    ...appFonts.smMedium,
    marginTop: 1,
};

export const styles = {
    flexRowValueText: {
        ...appFonts.mdBold
    },
    icon: {
        marginLeft: commonPadding
    },
    noActivitiesTrackedText: {
        ...appFonts.mdRegular,
        color: baseColors.grey50,
    },
    itemContainer: {
        paddingLeft: spacing.s7,
        paddingRight: spacing.s3,
        paddingTop: spacing.s0,
        marginBottom: spacing.s4,
    },
    activity: {
        height: ACTIVITY_ITEM_HEIGHT,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    activityName: {
        ...appFonts.smMedium,
        color: baseColors.grey20,
    },
    itemDetails: {
        marginTop: 2,
        ...appFonts.smRegular,
        color: baseColors.grey40,
    },
    alignRight: {
        marginLeft: 'auto',
    },
    itemValue: {
        ...appFonts.smRegular,
        color: baseColors.grey20,
    },
    subheader: {
        height: SUBHEADER_HEIGHT,
        display: 'flex',
        ...appFonts.smBold,
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3,
        paddingTop: spacing.s1,
        paddingBottom: spacing.s1,
        color: baseColors.grey20,
        backgroundColor: baseColors.grey85,
    },
    dailyHeaderHeight: {
        height: DAILY_HEADER_HEIGHT,
    },
    tag: {
        ...TAG_COMMON_PROPS,
    },
    dailyTag: {
        ...TAG_COMMON_PROPS,
        marginRight: 0,
    },
    tagText: {
        ...TAG_TEXT_COMMON_PROPS,
    },
    tagBgGrey: {
        ...TAG_COMMON_PROPS,
        backgroundColor: TAG_BG_COLOR,
    },
    dailyTagBgGrey: {
        ...TAG_COMMON_PROPS,
        marginRight: 0,
        backgroundColor: TAG_BG_COLOR,
    },
    tagTextGrey: {
        ...TAG_TEXT_COMMON_PROPS,
        color: baseColors.grey20,
    },
    noActivityBody: {
        paddingLeft: spacing.s7,
        paddingRight: spacing.s3,
        paddingTop: spacing.s1,
        paddingBottom: spacing.s3,
    },
    noTrackedText: {
        ...appFonts.smRegular,
        color: baseColors.grey50,
    },
    heading: {
        ...appFonts.mdMedium,
        color: baseColors.grey20,
        marginRight: spacing.s3,
    },
    secondaryHeading: {
        ...appFonts.mdRegular,
        color: baseColors.grey20,
        marginLeft: 'auto',
    },
    containerBottomBorder: {
        borderBottomWidth: 0.5,
        borderBottomColor: baseColors.grey70,
    },
    mRight: {
        marginRight: spacing.s1 - 1,
    },
    smartHeaderPadding: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: spacing.s3,
        paddingTop: spacing.s2
    },
    smartTargetSection: {
        marginLeft: 'auto',
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
    smartTargetValue: {
        color: baseColors.grey20,
        paddingLeft: spacing.s1,
        ...appFonts.mdRegular
    },
    dailyTarget: {
        color: baseColors.grey40,
        ...appFonts.smRegular
    }
};
