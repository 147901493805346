import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';
import { baseColors } from '../../../../styles';
import { components as Core } from '../../../core';
import { COMMON_PROPS } from '../../constants';
import WithChallengeProgressCircleBase, { styles as baseStyles } from './ChallengeProgressCircleBase';

export { COMMON_PROPS };

class ChallengeProgressCircle extends PureComponent {
    static propTypes = {
        fill: PropTypes.number.isRequired,
        i18n: PropTypes.object.isRequired,
        isUpdating: PropTypes.bool.isRequired,
        currentType: PropTypes.object.isRequired,
        periodContainerStyles: PropTypes.object.isRequired,
        isFull: PropTypes.bool.isRequired,
    };

    get periodData() {
        return (
            <div className={css(styles.periodContainer)} style={this.props.periodContainerStyles}>
                {this.props.isUpdating ?
                    <Core.Animated.Flash duration={3000} forever={true}>
                        <p className={css(styles.updatingText)}>{this.props.i18n.t('updating')}</p>
                    </Core.Animated.Flash> :
                    <React.Fragment>
                        {this.props.isFull ?
                            <Core.Icon
                                type="fa"
                                name="check"
                                color={baseColors.primary}
                                size={this.props.currentType.iconSize}
                            /> : null}
                        <p className={css(this.props.isFull ? styles.periodPercentagesFull : styles.periodPercentages)}>
                            {`${this.props.fill}%`}
                        </p>
                    </React.Fragment>
                }
            </div>
        );
    }

    render() {
        return (
            <div style={{ width: this.props.currentType.circleSize, maxWidth: this.props.currentType.circleMaxSize }}>
                <Core.ProgressCircle
                    progress={this.props.fill}
                    progressColor={baseColors.secondary}
                    bgColor={baseColors.grey85}
                    text={this.periodData}
                />
            </div>
        );
    }
}

export default WithChallengeProgressCircleBase(ChallengeProgressCircle);

const styles = StyleSheet.create({
    ...baseStyles,
    periodContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
    }
});