import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent, Fragment } from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import { importantClass, layoutStyle, spacing, baseColors } from '../../../../styles';
import { components as Core, parsers, ROUTES, Modal } from '../../../core';
import { REDEEM_STATES, REWARD_IMAGE_MAX_WIDTH, WEB_IMAGE_SECTION_SPACING, WEB_CONTINUE_BUTTON_WIDTH, WEB_CONTINUE_BUTTON_HEIGHT } from '../../constants';
import WithProductDetailsBase, { styles as baseStyles, FIXED_HEADER_MARGIN_TOP } from './ProductDetailsBase';
import OrderHeader from '../OrderHeader';

const HTML_PROPS = {
    description: 'description',
    disclaimer: 'disclaimer'
};

const LINK_BOTTOM = 2;

class ProductDetails extends PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
        rewards: PropTypes.object,
        item: PropTypes.object,
        rewardRedemptionToken: PropTypes.string,
        setStep: PropTypes.func,
        onRedeem: PropTypes.func,
        i18n: PropTypes.object.isRequired,
        isLoading: PropTypes.bool,
        title: PropTypes.string,
        filledPercentage: PropTypes.number,
        decodeHTML: PropTypes.func.isRequired,
        isWebGetToken: PropTypes.bool.isRequired,
        getToken: PropTypes.func,
        setLoading: PropTypes.func,
        rewardText: PropTypes.string.isRequired,
        isEnoughPoints: PropTypes.bool.isRequired,
        descriptionLabels: PropTypes.object.isRequired,
        needPointsText: PropTypes.string.isRequired
    };

    static defaultProps = {
        rewards: {},
        item: {},
        rewardRedemptionToken: undefined,
        setStep: undefined,
        onRedeem: undefined,
        isLoading: false,
        title: undefined,
        filledPercentage: 0,
        getToken: undefined,
        setLoading: undefined,
    };

    constructor(props) {
        super(props);
        this.state = {
            description: this.preparedProperty(HTML_PROPS.description),
            disclaimer: this.preparedProperty(HTML_PROPS.disclaimer),
            isDisabled: false
        };

        if (props.isWebGetToken) {
            this.props.setLoading(true);
            this.props.setStep(REDEEM_STATES.getToken);
            this.props.getToken();
        }
    }

    componentDidUpdate(prevProps) {
        const { item, orderId, history } = this.props;
        _.forEach(HTML_PROPS, property => {
            if (_.get(prevProps.item, property) !== _.get(item, property)) {
                this.updateProperty(property);
            }
        });
        if (orderId && (prevProps.orderId !== orderId)) {
            history.push(
                ROUTES.orderDetails(orderId),
                { fromRedemption: true }
            );
        }
    }

    updateProperty = property => {
        this.setState({ [property]: this.preparedProperty(property) });
    };

    preparedProperty = property => parsers.replaceBrAndATagsWithItsHrefAndReturnLinks(this.props.item[property] || '');

    goToOrder = () => {
        const { i18n, setStep, item, history, title, rewardRedemptionToken, externalRewardName, onOrder } = this.props;
        setStep(REDEEM_STATES.initial);

        return item.requireAddress ? (
            history.push(
                ROUTES.orderShippingAddress(), {
                    productId: item.id,
                    token: rewardRedemptionToken,
                    title
                })
        ) : (
            this.closeInfoModal = Modal.open(
                Core.InfoModal,
                {
                    renderText: () => <OrderHeader product={item} externalRewardName={externalRewardName} />,
                    isButtonVisible: false,
                    buttons: [
                        {
                            text: i18n.t('placeOrder'),
                            onPress: () => {
                                this.setState({ isDisabled: true });
                                !this.state.isDisabled && onOrder();
                                this.closeInfoModal();
                            },
                            isBoldText: true
                        },
                        { text: i18n.t('button_cancel'), onPress: () => this.closeInfoModal() }
                    ]
                },
                {
                    isContainer: true,
                    isTransparent: true,
                    isNoPadding: true,
                    fadeTransition: true,
                    isMaxWidthLimited: true,
                }
            )
        );
    };

    onRedeem = () => this.props.onRedeem(this.goToOrder);

    renderLink = type => match => (
        <a target="_blank" className={css(styles.link, styles.linkOnWeb)} href={match.attrs.href}>
            {/* eslint-disable-next-line react/no-this-in-sfc */}
            {` ${this.state[type].links[match.attrs.href] || match.attrs.href} `}
        </a>
    );

    get description() {
        return this.props.decodeHTML(_.get(this.state.description, 'text'));
    }

    get disclaimer() {
        return this.props.decodeHTML(_.get(this.state.disclaimer, 'text'));
    }

    get rewardSection() {
        const { rewardText, isEnoughPoints } = this.props;
        return (
            <div className={css(styles.enoughPointInfo, !isEnoughPoints ? styles.enoughPointInfoSpacing : null)}>
                <Core.Icon
                    type="light"
                    name="coins"
                    size={spacing.s4}
                    color={baseColors.warn}
                />
                <div className={css(styles.redeemCommonText)}>{rewardText}</div>
            </div>
        );
    }

    get renderProgressSection() {
        const { isEnoughPoints, i18n, needPointsText, rewards, filledPercentage } = this.props;
        return isEnoughPoints ? (
            <div className={css(styles.enoughPointContainer)}>
                {this.rewardSection}
                <Core.Button
                    className={css(styles.continueButton)}
                    onPress={this.onRedeem}>
                    {i18n.t('continue')}
                </Core.Button>
            </div>
        ) : (
            <div className={css(styles.progressContainer)}>
                {this.rewardSection}
                <div className={css(styles.moreContainer)}>
                    <div className={css(styles.morePointsText)}>
                        {needPointsText}
                    </div>
                    {rewards.learnUrl ? (
                        <a
                            href={rewards.learnUrl}
                            target="_blank"
                            className={css(styles.learnMoreLink)}>
                            {i18n.t('learnEarnMore')}
                        </a>
                    ) : null}
                </div>
                <Core.ProgressBar
                    variant="determinate"
                    filledPercentage={filledPercentage}
                    classes={{ root: css(styles.progress) }}
                    className={css(styles.progress)}
                />
            </div>
        );
    }

    get renderDescribeInfo() {
        const { descriptionLabels } = this.props;
        return (
            <Fragment>
                {this.description ? (
                    <div className={css(styles.descriptionContainer)}>
                        <div className={css(styles.descriptionTitle)}>{descriptionLabels.terms}</div>
                        <Core.AutoLink
                            text={this.description}
                            className={css(styles.description)}
                            renderLink={this.renderLink(HTML_PROPS.description)}
                        />
                    </div>
                ) : null}
                {_.get(this.state.disclaimer, 'text') ? (
                    <div className={css(styles.descriptionContainer)}>
                        <div className={css(styles.descriptionTitle)}>{descriptionLabels.disclaimer}</div>
                        <Core.AutoLink
                            text={this.disclaimer}
                            className={css(styles.description)}
                            renderLink={this.renderLink(HTML_PROPS.disclaimer)}
                        />
                    </div>
                ) : null}
            </Fragment>
        );
    }

    render() {
        const { item } = this.props;

        return (
            <div className={css(layoutStyle.flex)}>
                <Core.BlockingLoading isLoading={this.props.isLoading} />
                <Core.SmallerContainer
                    gridSm={10}
                    widerHeaderComponent={(
                        <Core.EntityDetailsHeader
                            hasBackButton={true}
                        />
                    )
                    }>
                    <div className={css(styles.flexRowWrapper)}>
                        <div className={css(styles.headerContainer)}>
                            <Core.Image
                                className={css(styles.image)}
                                src={item.largeImageUrl}
                            />
                            <div className={css(styles.imageBackground)} />
                        </div>
                        <div>
                            <Core.FixedHeader
                                title={item.productName}
                                titleStyle={styles.title}
                                offsetBottom={-FIXED_HEADER_MARGIN_TOP}
                            />
                            <h2 className={css(styles.title)}>{item.productName}</h2>
                            <div className={css(styles.company)}>{item.brandName}</div>
                            {this.renderProgressSection}
                            {this.renderDescribeInfo}
                        </div>
                    </div>
                </Core.SmallerContainer>
            </div>
        );
    }
}

export default WithProductDetailsBase(ProductDetails);

const BORDER_BOTTOM_LINE = `1px solid ${baseColors.grey70}`;

const styles = StyleSheet.create({
    ...baseStyles,
    image: importantClass({
        width: 'auto',
        maxWidth: REWARD_IMAGE_MAX_WIDTH,
        margin: 'auto',
        boxShadow: '0px 8px 18px rgba(0, 0, 0, 0.15)'
    }),
    headerContainer: {
        ...baseStyles.headerContainer,
        position: 'relative',
        marginBottom: 0,
        marginRight: WEB_IMAGE_SECTION_SPACING
    },
    linkOnWeb: importantClass({
        display: 'unset',
        margin: -spacing.s0,
        bottom: LINK_BOTTOM,
        paddingLeft: spacing.s0,
        paddingRight: spacing.s0,
        textDecoration: 'none'
    }),
    flexRowWrapper: {
        display: 'flex',
        flexDirection: 'row'
    },
    progressContainer: {
        paddingBottom: spacing.s5,
        borderBottom: BORDER_BOTTOM_LINE
    },
    enoughPointContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingBottom: spacing.s5,
        borderBottom: BORDER_BOTTOM_LINE
    },
    enoughPointInfo: {
        ...baseStyles.enoughPointInfo,
        marginBottom: 0
    },
    enoughPointInfoSpacing: {
        marginBottom: spacing.s5
    },
    continueButton: {
        ...baseStyles.continueButton,
        minWidth: WEB_CONTINUE_BUTTON_WIDTH,
        height: WEB_CONTINUE_BUTTON_HEIGHT
    },
    company: {
        ...baseStyles.company,
        marginBottom: spacing.s5
    },
    moreContainer: {
        display: 'flex'
    },
    morePointsText: {
        ...baseStyles.morePointsText,
        marginRight: spacing.s1,
        marginBottom: 0
    },
    learnMoreLink: importantClass({
        ...baseStyles.learnMoreLink,
        cursor: 'pointer'
    }),
    description: {
        ...baseStyles.description,
        whiteSpace: 'pre-line'
    }
});
