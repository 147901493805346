import React, { PureComponent } from 'react';
import { appFonts, baseColors, spacing } from '../../../../styles';
import { translate } from '../../services';

const TIMES_ICON = 'times';

export default function WithClosableInfoSectionBase(WrappedComponent) {
    class ClosableInfoSectionBase extends PureComponent {
        iconProps = () => ({
            name: TIMES_ICON,
            size: spacing.s3,
            color: baseColors.white
        });

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    iconProps={this.iconProps}
                />
            );
        }
    }
    return translate()(ClosableInfoSectionBase);
}

export const styles = {
    container: {
        backgroundColor: baseColors.secondary,
        marginBottom: spacing.s1,
        marginTop: spacing.s1,
        borderRadius: spacing.s1
    },
    lightContainer: {
        backgroundColor: baseColors.contextLightest
    },
    lightText: {
        color: baseColors.contextDarkest,
        ...appFonts.mdRegular
    },
    linkText: {
        ...appFonts.mdMedium
    },
    icon: {
        alignSelf: 'flex-end',
        marginTop: spacing.s1,
        marginRight: spacing.s1
    },
    text: {
        color: baseColors.white,
        ...appFonts.mdMedium,
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3,
        paddingBottom: spacing.s3
    }
};
