import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';
import { withRouter } from 'react-router-dom';
import { isIE } from 'react-device-detect';

import classNames from 'classnames';
import Bookmark from '../Bookmark';
import BonusMark from '../BonusMark';
import { components as Core, ROUTES } from '../../../core';
import { spacing, exploreImageHeight, appFonts, importantClass, baseColors } from '../../../../styles';
import WithCarouselItemBase, { styles as baseStyles } from './CarouselItemBase';
import PlayButton from '../../../../image/PlayButton.png';

const MAX_TEXT_WIDTH = 360;
class CarouselItem extends PureComponent {
    static propTypes = {
        item: PropTypes.object.isRequired,
        isFeaturedSection: PropTypes.bool,
        image: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
        isVideo: PropTypes.bool.isRequired,
        history: PropTypes.object.isRequired,
        onPressSingle: PropTypes.func.isRequired,
        isGradientFadedImage: PropTypes.bool,
        isCarouselItem: PropTypes.bool,
        customContentClassName: PropTypes.string,
        isLibraryPage: PropTypes.bool,
        imageClassName: PropTypes.string,
        videoDuration: PropTypes.string,
        isDisabledVideoControlContent: PropTypes.bool,
        isContentHeroCarusel: PropTypes.bool
    };

    static defaultProps = {
        isFeaturedSection: false,
        image: undefined,
        isCarouselItem: true,
        isGradientFadedImage: false,
        customContentClassName: undefined,
        isLibraryPage: false,
        imageClassName: undefined,
        videoDuration: null,
        isDisabledVideoControlContent: false,
        isContentHeroCarusel: false
    };

    openItemDetails = () => {
        const { id } = this.props.item;
        this.props.history.push(ROUTES.contentDetails(id));
    };

    get renderLabel() {
        return (
            <Core.ExploreItemMeta iconName="tags" textArray={this.props.item.tagsArr} isCarouselItem={true} />
        );
    }

    get renderVideoControlContent() {
        const { isVideo, isDisabledVideoControlContent, videoDuration } = this.props;
        return isVideo && !isDisabledVideoControlContent ? (
            <div className={isIE ? '' : css(styles.videoControlContainer)}>
                <Core.Image src={PlayButton} className={css(styles.playButton)} />
                {videoDuration ? (
                    <div className={css(styles.videoDurationWrapper)}>
                        <div className={css(styles.videoDurationContainer)}>
                            <p className={css(styles.videoDuration)}>{videoDuration}</p>
                        </div>
                    </div>
                ) : null}
            </div>
        ) : null;
    }

    render() {
        const { onPressSingle, image, item,
            isFeaturedSection, isVideo, featuredTag, isGradientFadedImage, isContentHeroCarusel,
            isLibraryPage, isCarouselItem, customContentClassName, earnLabelText, imageWidth, imageHeight, imageClassName, joinedTag } = this.props;
        return (
            <Core.TouchableItem
                image={image}
                isCarouselItem={isCarouselItem}
                isVideoPlayable={false}
                featuredTag={featuredTag}
                joinedTag={joinedTag}
                onPressSingle={onPressSingle}
                imageWidth={imageWidth || null}
                imageHeight={imageHeight || exploreImageHeight}
                videoUrl={isVideo ? item.url : ''}
                isFeaturedSection={isFeaturedSection}
                isGradientFadedImage={isGradientFadedImage}
                imageClassName={imageClassName}
                isLibraryPage={isLibraryPage}
                onImageContent={this.renderVideoControlContent}
                isContentHeroCarusel={isContentHeroCarusel}>
                <div className={classNames(css(styles.contentWrapper), customContentClassName)}>
                    {!isLibraryPage ? (
                        <div className={css(styles.categoryWrapper)}>
                            {this.renderLabel}
                            <Bookmark item={item} />
                        </div>
                    ) : null}
                    <Core.ExploreItemTitle text={item.title} style={isLibraryPage && styles.libraryTitle} color={isLibraryPage ? baseColors.white : baseColors.black} />
                    {!isLibraryPage ? (
                        <div className={css(styles.bonusContainer)}>
                            <BonusMark item={item} />
                        </div>
                    ) : <div className={css(styles.librarySubtitle)}>{earnLabelText}</div>}
                </div>
            </Core.TouchableItem>
        );
    }
}

export default withRouter(WithCarouselItemBase(CarouselItem));

const styles = StyleSheet.create({
    ...baseStyles,
    contentWrapper: {
        ...baseStyles.contentWrapper,
        flexDirection: 'column',
        paddingLeft: spacing.s2,
        paddingRight: spacing.s1,
        marginTop: spacing.s2
    },
    librarySubtitle: {
        ...appFonts.smRegular,
        color: 'rgb(255,255,255,.75)'
    },
    libraryTitle: {
        ...appFonts.xxxlMedium,
        maxWidth: MAX_TEXT_WIDTH,
        color: baseColors.white,
        marginBottom: spacing.s0,
    },
    playButton: importantClass({
        ...baseStyles.playButton
    }),
    categoryWrapper: {
        ...baseStyles.categoryWrapper,
        marginLeft: -spacing.s0
    }
});
