import _ from 'lodash';
import I18n from '../../../../i18n';

const language = _.split(I18n.language, '-', 2);
const navigatorLanguage = _.split(navigator.language, '-', 2);

export function isLongLanguage() {
    return language[0] !== 'en' && language[0] !== 'fr';
}

export const isFrenchLanguage = i18n => {
    const currentLanguage = _.split(i18n.language, '-', 2);
    return (currentLanguage[0] || navigatorLanguage[0]) === 'fr';
};

export const isEnglishLanguage = i18n => {
    const currentLanguage = _.split(i18n.language, '-', 2);
    return (currentLanguage[0] || navigatorLanguage[0]) === 'en';
};
