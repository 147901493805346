import _ from 'lodash';
import * as types from './actionTypes';
import { actionTypes as invitationTypes } from '../invitations';

const initialState = {
    userDetails: {
        items: {}
    },
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_USER_DATA.SUCCESS: {
            return {
                ...state,
                userDetails: {
                    ...state.userDetails,
                    items: {
                        ...state.userDetails.items,
                        [action.payload.userId]: action.payload
                    }
                }
            };
        }
        case invitationTypes.SEND_PLATFORM_INVITATIONS.SUCCESS: {
            const { userId, item } = action.payload;
            return _.has(state.userDetails.items, userId) ? {
                ...state,
                userDetails: {
                    ...state.userDetails,
                    items: {
                        ...state.userDetails.items,
                        [userId]: {
                            ...state.userDetails.items[userId],
                            friendsAndFamilyInviteId: item.id
                        }
                    }
                }
            } : state;
        }
        case invitationTypes.DELETE_PLATFORM_INVITATION.SUCCESS: {
            const { userId } = action.payload;
            return userId && _.has(state.userDetails.items, userId) ? {
                ...state,
                userDetails: {
                    ...state.userDetails,
                    items: {
                        ...state.userDetails.items,
                        [userId]: {
                            ...state.userDetails.items[userId],
                            friendsAndFamilyInviteId: null
                        }
                    }
                }
            } : state;
        }
        default:
            return state;
    }
};

export default reducer;