import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { translate } from '../../../core';
import * as actions from '../../actions';
import { getReactionsByTypeAndId, isLoadingReactions, getReactionsById, getAvailableReactions } from '../../selectors';
import { spacing } from '../../../../styles';

export default function WithReactionsList(WrappedComponent) {
    class ReactionsListBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            reactions: PropTypes.array,
            likedItemType: PropTypes.string.isRequired,
            likedItemId: PropTypes.number.isRequired,
            reactionCount: PropTypes.number.isRequired,
            isLoading: PropTypes.bool,
            allReactions: PropTypes.array.isRequired,
            availableReactions: PropTypes.array,
            i18n: PropTypes.object.isRequired,
            appliedReactions: PropTypes.array
        };

        static defaultProps = {
            reactions: [],
            isLoading: false,
            availableReactions: [],
            appliedReactions: []
        };

        constructor(props) {
            super(props);
            this.props.actions.getReactions(this.props.likedItemType, this.props.likedItemId, 0);
            this.TABS = this.tabs;

            this.state = {
                activeTab: this.TABS[0],
            };
        }

        get tabs() {
            const { appliedReactions } = this.props;
            const TABS = [{ id: 'ALL', label: this.props.i18n.t('all') }];

            const emojiesTabs = _.map(appliedReactions, item => ({
                id: item.slug,
                icon: this.getEmojiImage(item.slug),
                label: `${item.count}`
            }));

            const sortedEmojiesTabs = _.sortBy(emojiesTabs, 'label', 'desc').reverse();

            return [...TABS, ...sortedEmojiesTabs];
        }

        get reactions() {
            const { activeTab } = this.state;
            const { reactions } = this.props;

            if (activeTab.id === 'ALL') {
                return reactions;
            }

            return _.filter(reactions, reaction => reaction.slug === activeTab.id);
        }

        getEmojiImage(slug) {
            const { availableReactions } = this.props;

            const emojiItem = _.find(availableReactions, item => item.slug === slug);
            return emojiItem.emoji;
        }

        onActiveTabChange = tab => {
            this.setState({ activeTab: tab });
        }

        loadMoreReactions = () => {
            if (!this.props.isLoading && this.props.reactions.length < this.props.reactionCount) {
                this.props.actions.getReactions(this.props.likedItemId, this.props.likedItemType, this.props.reactions.length || 0);
            }
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    loadMoreReactions={this.loadMoreReaction}
                    tabs={this.tabs}
                    activeTab={this.state.activeTab}
                    onActiveTabChange={this.onActiveTabChange}
                    reactions={this.reactions}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        const likedItemType = ownProps.likedItemType || _.get(ownProps, 'match.params.likedItemType') || _.get(ownProps, 'route.params.likedItemType');
        const likedItemId = ownProps.likedItemId || _.get(ownProps, 'match.params.likedItemId') || _.get(ownProps, 'route.params.likedItemId');
        const appliedReactions = ownProps.appliedReactions || _.get(ownProps, 'route.params.appliedReactions');
        const reactionIds = getReactionsByTypeAndId(state, likedItemType, likedItemId);
        return {
            reactions: getReactionsById(state, reactionIds),
            availableReactions: getAvailableReactions(state),
            isLoading: isLoadingReactions(state),
            likedItemType,
            likedItemId,
            appliedReactions
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(ReactionsListBase));

}

export const styles = {
    mainContainer: {
        flex: 1,
    },
    tabIcon: {
        width: spacing.s3,
        height: spacing.s3
    }
};