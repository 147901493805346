import { CORPORATE_ACCOUNT_LINK } from '../constants';

export function handleCorporateAccountLink(params, actions) {
    const context = params.context;
    const status = params.status;
    if (status === CORPORATE_ACCOUNT_LINK.accountLinkSuccessStatus) {
        actions.linkCorporateAccountSuccess();
        actions.handleLinking();
    } else if (status === CORPORATE_ACCOUNT_LINK.accountLinkFailedStatus) {
        actions.linkCorporateAccountError();
    }
    return handleRedirect(context, status);
}

// needed shortly for complex routing
export function handleRedirect(context, status) {
    const isUserLoggedIn = true;

    switch (context) {
        case CORPORATE_ACCOUNT_LINK.corporateAccountLink: {
            if (status === CORPORATE_ACCOUNT_LINK.accountLinkSuccessStatus && isUserLoggedIn) {
                return CORPORATE_ACCOUNT_LINK.corporateAccountLinkSuccess;
            } else if (status === CORPORATE_ACCOUNT_LINK.accountLinkFailedStatus && isUserLoggedIn) {
                return CORPORATE_ACCOUNT_LINK.corporateAccountLinkError;
            }
            return;
        }
        case CORPORATE_ACCOUNT_LINK.accountLinkFailedStatus: {
            if (isUserLoggedIn) {
                return CORPORATE_ACCOUNT_LINK.corporateAccountLinkError;
            }
            return;
        }
        default: {
            return null;
        }
    }
}