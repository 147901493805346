import { css, StyleSheet } from 'aphrodite-jss';
import React, { PureComponent } from 'react';
import _ from 'lodash';
import { baseColors, spacing, appFonts } from '../../../../../styles';
import {
    CAROUSEL_TYPES,
    components as Core,
} from '../../../../core';
import WithChallengeInvitationsBase, { styles as baseStyles } from './ChallengeInvitationsBase';
import ChallengeInvitationItem from '../ChallengeInvitationItem';

class ChallengeInvitations extends PureComponent {

    render() {
        const { invitedChallenges, invitationsArray, invitesTitle, history } = this.props;
        return (
            <div className={css(styles.main)}>
                <div className={css(styles.title)}>{invitesTitle}</div>
                <Core.Carousel
                    arrows={true}
                    noMargin={true}
                    infinteScroll={true}
                    type={CAROUSEL_TYPES.twoOnly}
                    dots={true}
                    focusOnSelected={false}
                    isInvitesSection={true}
                    itemsLength={invitedChallenges.length}>
                    {_.map(invitationsArray, item => <ChallengeInvitationItem invitationItem={item} history={history} />)}
                </Core.Carousel>
            </div>
        );
    }
}


const styles = StyleSheet.create({
    ...baseStyles,
    main: {
        backgroundColor: baseColors.grey90,
        padding: spacing.s3,
        paddingBottom: 0,
        marginBottom: spacing.s5,
        borderRadius: spacing.s2,
        paddingTop: spacing.s1,
    },
    title: {
        ...appFonts.xlBold,
        paddingTop: spacing.s3,
        paddingBottom: spacing.s0
    }
});

export default WithChallengeInvitationsBase(ChallengeInvitations);
