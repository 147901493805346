import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { isIE } from 'react-device-detect';
import classnames from 'classnames';
import { baseColors, layoutStyle, spacing } from '../../../../../styles';
import { components as Core } from '../../../../core';
import withHeaderBase, { styles as baseStyles } from './HeaderBase';
import BonusChallengeMark from '../../BonusChallengeMark';
import WeeklyGoalTag from '../../WeeklyGoalTag';
import ChallengeParticipants from '../../ChallengeDetails/ChallengeParticipants';
import { CHALLENGE_CARD_HEADER_ITEMS_SIZES as SIZES } from '../../../constants';

const IMAGE_LINEAR_GRADIENT = 'linear-gradient(180deg, rgba(0, 0, 0, 0) 15%, rgba(0, 0, 0, 0.7) 85%)';

class CardHeader extends PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        icon: PropTypes.node,
        title: PropTypes.string.isRequired,
        challengeId: PropTypes.number.isRequired,
        multiLineTitle: PropTypes.bool,
        instruction: PropTypes.string,
        isInstructionHidden: PropTypes.bool,
        getNumberOfLines: PropTypes.func.isRequired,
        noPadding: PropTypes.bool,
        isRestartGoal: PropTypes.bool,
        navigation: PropTypes.object.isRequired,
        challenge: PropTypes.object.isRequired,
        challengeImage: PropTypes.string,
        challengeDuration: PropTypes.object.isRequired,
        useDangerColor: PropTypes.string,
        zeroParticipants: PropTypes.string,
        endedDateText: PropTypes.string,
        startsInTitle: PropTypes.string.isRequired,
        durationTitle: PropTypes.string.isRequired,
        isGoal: PropTypes.bool.isRequired,
        numMembersText: PropTypes.string.isRequired,
        language: PropTypes.string.isRequired,
        instructionString: PropTypes.string.isRequired
    };

    static defaultProps = {
        multiLineTitle: false,
        icon: undefined,
        instruction: '',
        isInstructionHidden: true,
        noPadding: true,
        isRestartGoal: false,
        challengeImage: null,
        useDangerColor: null,
        zeroParticipants: null,
        endedDateText: null
    };

    get isEnglish() {
        const { language } = this.props;
        return language === 'en-CA' || language === 'en-AU';
    }

    get renderUnstartedChallengeHeader() {
        const { challengeImage, title, challengeId, challengeDateInfo: { duration }, challengeDuration: { startsIn, durationCount }, zeroParticipants, multiLineTitle, startsInTitle, durationTitle, numMembersText, instructionString, featuredTag } = this.props;
        const imageBackStyle = { height: SIZES.UPCOMING_CHALLENGE_IMAGE_HEIGHT, backgroundImage: `${IMAGE_LINEAR_GRADIENT}, url(${challengeImage})` };
        return (
            <div>
                <div className={css(styles.imageContainer)} style={imageBackStyle}>
                    <div className={css(styles.featuredTagForUpcomingChallenge)}>{featuredTag}</div>
                    <div className={css(styles.dateInfoContainer, styles.unstartedDateInfoContainer, !this.isEnglish ? styles.unstartedDateInfoContainerFrench : null)}>
                        <div className={css(styles.startsInContainer)}>
                            <p className={css(styles.dateTitle)}>{startsInTitle}</p>
                            <p className={css(styles.dateInfo)}>{startsIn}</p>
                        </div>
                        <div className={css(styles.durationContainer)}>
                            <p className={css(styles.dateTitle)}>{durationTitle}</p>
                            <p className={css(styles.dateInfo)}>{durationCount}</p>
                        </div>
                    </div>
                    <div className={css(styles.unstartedBrandContainer)} />
                </div>
                <div className={css(layoutStyle.cardPadding, styles.itemDetailsInfoContainer)}>
                    <div className={css(styles.titleContainer, styles.unstartedTitleSpacing)}>
                        <div className={css(styles.headerTextArea)}>
                            {multiLineTitle ?
                                <p className={css(styles.title)}>{title}</p>
                                : (
                                    <Core.TextSeeMore
                                        numberOfLines={1}
                                        className={css(styles.title)}
                                        isExpandHidden={true}>
                                        {title}
                                    </Core.TextSeeMore>
                                )
                            }
                        </div>
                    </div>
                    <div className={css(styles.membersContainer)}>
                        <ChallengeParticipants sizeAvatar='xmsmall' showOnCard={true} challengeId={challengeId} />
                        <div className={css(styles.membersInfoText)}>
                            {zeroParticipants ? (
                                <p className={css(styles.membersAndDateText)}>{zeroParticipants}</p>
                            ) : (
                                <div className={css(styles.membersAndDateText)}>
                                    {numMembersText}
                                </div>
                            )}
                            <Core.Icon
                                name='circle'
                                type='fa'
                                fill='solid'
                                className={css(styles.webDot)}
                                color={baseColors.grey40}
                                size={spacing.s0}
                                resizeMode='contain'
                            />
                            <p className={css(styles.membersAndDateText)}>{duration}</p>
                        </div>
                    </div>
                    <BonusChallengeMark
                        challengeId={challengeId}
                    />
                    <Core.InnerHtml htmlText={instructionString} className={css(styles.instruction, layoutStyle.textLeft)} />
                </div>
            </div>
        );
    }

    get renderActiveOrEndedChallengeHeader() {
        const { challengeImage, title, challengeId, challengeDateInfo: { details }, isEnded, useDangerColor, multiLineTitle, endedDateText, featuredTag } = this.props;
        const formatedMainImageSize = isEnded ? SIZES.ENDED_CHALLENGE_IMAGE_HEIGHT : SIZES.ACTIVE_CHALLENGE_IMAGE_HEIGHT;
        const imageBackStyle = { height: formatedMainImageSize, backgroundImage: `url(${challengeImage})` };
        return (
            <div>
                <div className={css(styles.imageContainer, styles.unsetFlexDirection)} style={imageBackStyle} />
                {!isEnded && <div className={css(styles.featuredTagForUpcomingChallenge)}>{featuredTag}</div>}
                <div className={css(layoutStyle.cardPadding, styles.itemDetailsInfoContainer)}>
                    <div className={css(styles.titleContainer)}>
                        <div className={css(styles.headerTextArea)}>
                            {multiLineTitle ?
                                <p className={css(styles.title)}>{title}</p>
                                : (
                                    <Core.TextSeeMore
                                        numberOfLines={1}
                                        className={css(styles.title)}
                                        isExpandHidden={true}>
                                        {title}
                                    </Core.TextSeeMore>
                                )
                            }
                        </div>
                    </div>
                    <div className={css(styles.detailsContainer, isIE && styles.topMarginIE)}>
                        {isEnded ? (
                            <Fragment>
                                <Core.Icon
                                    type="fa"
                                    fill="regular"
                                    name="flag-checkered"
                                    size={SIZES.FLAG_ICON_SIZE}
                                    color={baseColors.grey40}
                                    className={css(styles.flagIcon)}
                                />
                                <p className={css(styles.dateInfoText)}>{endedDateText}</p>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <BonusChallengeMark
                                    challengeId={challengeId}
                                />
                                <p className={css(styles.dateInfoText, useDangerColor ? styles.dangerColor : null)}>{details}</p>
                            </Fragment>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    get renderFeaturedChallengeHeader() {
        const { challengeImage, title, challengeId, challengeDateInfo: { duration },
            multiLineTitle, featuredTag, challenge: { numChallengeParticipants }, participantsString } = this.props;

        const imageBackStyle = { height: SIZES.FEATURED_CHALLENGE_IMAGE_HEIGHT, backgroundImage: `url(${challengeImage})` };
        const noParticipants = numChallengeParticipants === 0;
        const isLong = numChallengeParticipants > 100;

        return (
            <div>
                <div className={css(styles.imageContainer, styles.unsetFlexDirection)} style={imageBackStyle}>
                    <div className={css(styles.featuredTag)}>{featuredTag}</div>
                </div>
                <div className={css(layoutStyle.cardPadding, styles.itemDetailsInfoContainer)}>
                    <div className={css(styles.titleContainer)}>
                        <div className={css(styles.headerTextArea)}>
                            {multiLineTitle ?
                                <p className={css(styles.title)}>{title}</p>
                                : (
                                    <Core.TextSeeMore
                                        numberOfLines={1}
                                        className={css(styles.title)}
                                        isExpandHidden={true}>
                                        {title}
                                    </Core.TextSeeMore>
                                )
                            }
                        </div>
                    </div>
                    <div className={css(styles.membersContainer)}>
                        {noParticipants ? null : <ChallengeParticipants sizeAvatar="xmsmall20" showOnCard={true} challengeId={challengeId} />}
                        <div className={classnames(css(styles.membersText), css(styles.membersWidth))}>
                            {numChallengeParticipants > 0 ? (
                                <div className={css(styles.participantsContainer)}>
                                    <div className={css(styles.featuredParticipantsText)}>
                                        {participantsString}
                                    </div>
                                    <Core.Icon
                                        name="circle"
                                        type="fa"
                                        fill="solid"
                                        className={css(styles.webDot)}
                                        color={baseColors.grey40}
                                        size={spacing.s0}
                                        resizeMode="contain"
                                    />
                                </div>
                            ) : null
                            }
                            <Core.TextSeeMore
                                numberOfLines={1}
                                className={classnames(css(styles.membersAndDateText),
                                    isLong && css(styles.additionalMargin),
                                    noParticipants && css(styles.noParticipants))}
                                isExpandHidden={true}>
                                {duration}
                            </Core.TextSeeMore>

                        </div>
                    </div>
                    <BonusChallengeMark
                        challengeId={challengeId}
                    />
                </div>
            </div>
        );
    }

    get renderChallengeHeader() {
        const { isUnstarted, isFeaturedChallenge } = this.props;
        if (isFeaturedChallenge) {
            return this.renderFeaturedChallengeHeader;
        } else if (isUnstarted) {
            return this.renderUnstartedChallengeHeader;
        } else {
            return this.renderActiveOrEndedChallengeHeader;
        }
    }

    get renderGoalHeader() {
        const { icon, challengeId, title, challengeDateInfo: { details, duration }, isEnded, useDangerColor, noPadding, multiLineTitle, endedDateText, isUnstarted, challengeDuration: { startsIn, durationCount }, startsInTitle, durationTitle, instructionString } = this.props;
        const imageBackStyle = { height: SIZES.UPCOMING_CHALLENGE_IMAGE_HEIGHT, alignItems: 'center' };
        return (
            <div>
                {isUnstarted ? (
                    <div className={css(styles.imageContainer)} style={imageBackStyle}>
                        <div className={css(styles.dateInfoContainer, styles.unstartedDateInfoContainer, !this.isEnglish ? styles.unstartedDateInfoContainerFrench : null)}>
                            <div className={css(styles.startsInContainer)}>
                                <p className={css(styles.goalDateTitle)}>{startsInTitle}</p>
                                <p className={css(styles.goalDateInfo)}>{startsIn}</p>
                            </div>
                            <div className={css(styles.durationContainer)}>
                                <p className={css(styles.goalDateTitle)}>{durationTitle}</p>
                                <p className={css(styles.goalDateInfo)}>{durationCount}</p>
                            </div>
                        </div>
                    </div>
                ) : null}
                <div className={css(layoutStyle.cardPadding)}>
                    <div className={css(styles.titleContainer)}>
                        <div className={css(styles.header)}>
                            {icon ? (
                                <div className={css(styles.goalIcon)}>
                                    {icon}
                                </div>
                            ) : null }
                            <div className={css(styles.headerTextArea, icon && !noPadding ? styles.paddingRight45 : null)}>
                                {multiLineTitle ?
                                    <p className={css(styles.title)}>{title}</p>
                                    : (
                                        <Core.TextSeeMore
                                            numberOfLines={1}
                                            className={css(styles.title)}
                                            isExpandHidden={true}>
                                            {title}
                                        </Core.TextSeeMore>
                                    )
                                }
                                <WeeklyGoalTag challengeId={challengeId} />
                            </div>
                        </div>
                    </div>
                    <div className={css(styles.detailsContainer, styles.detailsGoalSpacing)}>
                        {isEnded ? (
                            <Fragment>
                                <Core.Icon
                                    type="fa"
                                    fill="regular"
                                    name="flag-checkered"
                                    size={SIZES.FLAG_ICON_SIZE}
                                    color={baseColors.grey40}
                                    className={css(styles.flagIcon)}
                                />
                                <p className={css(styles.dateInfoText)}>{endedDateText}</p>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <BonusChallengeMark
                                    challengeId={challengeId}
                                />
                                <p className={css(styles.dateInfoText, useDangerColor ? styles.dangerColor : null)}>{details}</p>
                            </Fragment>
                        )}
                        {isUnstarted ? (
                            <div className={css(styles.membersAndDateText, styles.unstartedGoalDurationSpacing, styles.removeTopSpacing)}>{duration}</div>
                        ) : null}
                    </div>
                    {isUnstarted ? (
                        <Core.InnerHtml htmlText={instructionString} className={css(styles.instruction, layoutStyle.textLeft)} />
                    ) : null}
                </div>
            </div>
        );
    }

    render() {
        const { isGoal } = this.props;
        return (
            <Fragment>
                {isGoal ? this.renderGoalHeader : this.renderChallengeHeader}
            </Fragment>
        );
    }
}
const CUSTOM_FONT_SIZE = 13;
const MAX_WIDTH_MEMBERS_TEXT = 190;

const styles = StyleSheet.create({
    ...baseStyles,
    imageContainer: {
        ...baseStyles.imageContainer,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        alignItems: 'flex-end',
        flexDirection: 'column',
        backgroundRepeat: 'no-repeat'
    },
    unstartedDateInfoContainer: {
        ...baseStyles.dateInfoContainer,
        alignItems: 'center',
        alignSelf: 'center'
    },
    unstartedDateInfoContainerFrench: {
        width: '90%'
    },
    unstartedBrandContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
        left: spacing.s3,
        bottom: spacing.s1,
        alignSelf: 'flex-start',
        position: 'relative'
    },
    webDot: {
        marginLeft: spacing.s0,
        marginRight: spacing.s0,
        width: spacing.s0,
        height: spacing.s0
    },
    membersAndDateText: {
        ...baseStyles.membersAndDateText,
        paddingTop: 'auto',
        fontSize: CUSTOM_FONT_SIZE
    },
    featuredParticipantsText: {
        ...baseStyles.membersAndDateText,
        fontSize: CUSTOM_FONT_SIZE
    },
    unstartedTitleSpacing: {
        marginBottom: 'auto'
    },
    unsetFlexDirection: {
        flexDirection: 'unset'
    },
    detailsGoalSpacing: {
        marginTop: spacing.s1
    },
    topMarginIE: {
        marginTop: spacing.s4,
    },
    removeTopSpacing: {
        marginTop: 0
    },
    participantsContainer: {
        display: 'flex',
        flex: 1,
        alignItems: 'center'
    },
    additionalMargin: {
        maxWidth: spacing.s19,
        paddingLeft: spacing.s0
    },
    noParticipants: {
        paddingLeft: spacing.s0,
        marginTop: spacing.s0,
        marginBottom: spacing.s0 + 2
    },
    membersWidth: {
        maxWidth: MAX_WIDTH_MEMBERS_TEXT
    },
    featuredTagForUpcomingChallenge: {
        ...baseStyles.featuredTag,
        top: spacing.s0,
        left: spacing.s0
    }
});

export default withHeaderBase(CardHeader);
