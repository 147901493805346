import axios from 'axios';
import { getBaseUrl } from '../core';

export const API_ENDPOINTS = {
    notificationsSettings: 'users/settings/notifications',
    userPrivacySettings: 'users/settings/privacy',
    userUnsubscribeSettings: 'users/settings/email_unsubscription',
    currentUserPrivacySettings: 'users/{id}/privacy_settings',
    userBiometrics: 'users/biometrics',
    userPersonalSettings: 'users/settings/personal',
    profileImage: 'users/profile/image',
    companyTasks: 'tasks/company_tasks',
    companies: 'companies',
    feedback: 'feedback',
    trackActivity: 'activity_logs',
    userFeedback: 'feedback_log',
    familyInvitations: 'users/family_invitations'
};

const getUserPrivacyUrl = (userId, slug) => {
    const mainUrl = API_ENDPOINTS.currentUserPrivacySettings.replace('{id}', userId);

    return `${getBaseUrl(mainUrl)}${slug ? `/${slug}` : ''}`;
};

export function getNotificationsSettings() {
    return axios.get(getBaseUrl(API_ENDPOINTS.notificationsSettings));
}

export function setNotificationsSetting(notificationSettingTypeId, value) {
    return axios.put(
        getBaseUrl(API_ENDPOINTS.notificationsSettings),
        [{ notificationSettingTypeId, value }]
    );
}

export function getUserPrivacySettings(type) {
    return axios.get(`${getBaseUrl(API_ENDPOINTS.userPrivacySettings)}/${type}`);
}

export function setUserPrivacySetting(type, privacySettingTypeId, value) {
    return axios.put(
        getBaseUrl(API_ENDPOINTS.userPrivacySettings),
        { type, privacySettingTypeId, value }
    );
}

export function getUnsubscribeSettings(token) {
    return axios.get(`${getBaseUrl(API_ENDPOINTS.userUnsubscribeSettings)}?token=${token}`);
}

export function setUnsubscribeSetting(privacySettingTypeId, value, token) {
    return axios.put(
        `${getBaseUrl(API_ENDPOINTS.userUnsubscribeSettings)}?token=${token}`,
        { privacySettingTypeId, value }
    );
}

export function getCurrentUserPrivacySettings(userId, privacyTypeSlug) {
    return axios.get(getUserPrivacyUrl(userId, privacyTypeSlug));
}

export function setCurrentUserPrivacySetting(userId, privacyTypeSlug, value) {
    return axios.put(
        getUserPrivacyUrl(userId, privacyTypeSlug),
        { privacyTypeSlug, value }
    );
}

export function getFeedbackMetadata(partnerId) {
    return axios.get(`${getBaseUrl(API_ENDPOINTS.feedback)}/${partnerId}`);
}

export function postFeedback(feedback, client, partner_id, feedback_id) {
    return axios.post(getBaseUrl(API_ENDPOINTS.feedback), { feedback, client, partner_id, feedback_id });
}

export function getUserBiometrics() {
    return axios.get(getBaseUrl(API_ENDPOINTS.userBiometrics));
}

export function getUserSettings() {
    return axios.get(getBaseUrl(API_ENDPOINTS.userPersonalSettings));
}

export function saveUserBiometrics(params) {
    return axios.put(getBaseUrl(API_ENDPOINTS.userBiometrics), params);
}

export function savePersonal(params) {
    return axios.put(getBaseUrl(API_ENDPOINTS.userPersonalSettings), params);
}

export function getCompanyTasks(companyId) {
    return axios.get(getBaseUrl(`${API_ENDPOINTS.companyTasks}/${companyId}`));
}

export function getCompanyFeatures(companyId) {
    return axios.get(`${getBaseUrl(API_ENDPOINTS.companies)}/${companyId}/features`);
}

export function updateAvatar(data) {
    return axios.post(getBaseUrl(API_ENDPOINTS.profileImage), data);
}

export function deleteAvatar() {
    return axios.delete(getBaseUrl(API_ENDPOINTS.profileImage));
}

export function sendUserFeedback(params) {
    return axios.post(getBaseUrl(API_ENDPOINTS.userFeedback), params);
}

export function sendFamilyMemberInvitation(params) {
    return axios.post(getBaseUrl(API_ENDPOINTS.familyInvitations), params);
}
