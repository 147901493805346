import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { withRouter } from 'react-router-dom';
import { isIE } from 'react-device-detect';

import { appFonts, baseColors, containerStyle, layoutStyle, spacing } from '../../../../../styles';
import { components as Core, ROUTES, onKeyPress } from '../../../../core';
import WithTaskListItem, { styles as baseStyles, BULLET_SIZE } from './TaskListItemBase';
import { LINE_WIDTH } from '../TasksBase';

class TaskListItem extends PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        task: PropTypes.object.isRequired,
        title: PropTypes.string.isRequired,
        points: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        length: PropTypes.number.isRequired,
        step: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
    };

    loadTaskFlow = () => {
        const { step, slug } = this.props;
        if (!step) return;
        this.props.history.push(ROUTES.onboardingFlowStep(step), { slug, isHomeCard: true, isTaskCard: true });
    };

    get isFinishedTask() {
        return !this.props.task.tasks.length;
    }

    get tabIndex() {
        return this.isFinishedTask ? '-1' : '0';
    }

    render() {
        const { points, title, i18n } = this.props;
        const width = 100 / this.props.length;
        return (
            <div
                role="button"
                tabIndex={this.tabIndex}
                onKeyDown={onKeyPress.enter(this.loadTaskFlow)}
                className={css(layoutStyle.pRelative, containerStyle.listItemfocusVisible)}
                style={{ width: `${width}%` }}
                onClick={this.loadTaskFlow}>
                <div className={css(styles.line, styles.lineTop, this.isFinishedTask && styles.greenLine)} />
                <div className={css(styles.titleBox, layoutStyle.flexAlignCenter, layoutStyle.flexColumn,
                    this.isFinishedTask && styles.titleBoxHover)}>
                    <div className={css(styles.bulletBox, this.isFinishedTask && styles.iconBox)}>
                        {this.isFinishedTask ? (
                            <Core.Icon
                                color={baseColors.white}
                                name="check"
                                type="fa"
                                size={ICON_SIZE}
                                className={css(styles.check)}
                            />
                        ) :
                            <div className={css(styles.bullet)} />}
                    </div>
                    <div className={css(layoutStyle.fw, layoutStyle.textCenter)}>
                        <p className={css(styles.title)}>{title}</p>
                        {this.isFinishedTask ?
                            <p className={css(styles.done)}>{i18n.t('done')}</p> :
                            <>{points ? <p className={css(styles.bonusText)}>{`+ ${points}`}</p> : null}</>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(WithTaskListItem(TaskListItem));

const ICON_BOX_SIZE = 22;
const BULLET_BOX = spacing.s4 - LINE_WIDTH;
const ICON_SIZE = spacing.s2;
const BULLET_OFFSET = (BULLET_BOX - BULLET_SIZE)/2;

const styles = StyleSheet.create({
    ...baseStyles,
    titleBox: {
        marginTop: spacing.s0 + spacing.s0 / 2,
        marginLeft: spacing.s0 / 2,
        marginRight: spacing.s0 / 2,
        marginBottom: spacing.s1,
        height: 'calc(100% - 5px)',
        '&:hover': {
            backgroundColor: baseColors.grey90
        },
        '&:active': {
            backgroundColor: baseColors.grey90
        },
        '&:focus': {
            backgroundColor: baseColors.grey90
        }
    },
    titleBoxHover: {
        cursor: 'initial',
        '&:hover': {
            backgroundColor: baseColors.white
        },
        '&:active': {
            backgroundColor: baseColors.white
        },
        '&:focus': {
            backgroundColor: baseColors.white
        }
    },
    title: {
        ...baseStyles.title,
        marginTop: spacing.s1
    },
    bullet: {
        ...baseStyles.bullet,
        zIndex: 2,
        top: BULLET_OFFSET,
        left: BULLET_OFFSET,
        position: 'absolute'
    },
    bulletBox: {
        ...baseStyles.bulletBox,
        width: BULLET_BOX,
        height: BULLET_BOX,
        zIndex: 1,
        position: 'absolute',
        top: -5,
        marginLeft: isIE ? -BULLET_BOX/2 : 0
    },
    line: {
        ...baseStyles.line,
        height: LINE_WIDTH,
        width: `calc(100% - ${spacing.s0}px)`,
        left: LINE_WIDTH, //half of circle + spacing
        top: 3
    },
    iconBox: {
        backgroundColor: baseColors.primary,
        border: `${LINE_WIDTH}px solid ${baseColors.white}`,
        width: ICON_BOX_SIZE,
        height: ICON_BOX_SIZE,
        top: -7
    },
    check: {
        zIndex: 2,
        top: -(BULLET_BOX - ICON_SIZE)/2,
        position: 'relative',
        left: (BULLET_BOX - ICON_SIZE)/2
    },
    greenLine: {
        backgroundColor: baseColors.primary,
    },
    done: {
        ...appFonts.smRegular,
        color: baseColors.grey50
    }
});

