import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { components as Core, Modal, numbers, onKeyPress, tracker } from '../../../../core';
import WithDashboardLevelItemBase, { styles as baseStyles } from './DashboardLevelItemBase';
import DashboardProgressIndicator from '../DashboardProgressIndicator';
import DashboardEarnPointsInfo from '../DashboardEarnPointsInfo';
import { baseColors, layoutStyle, spacing, appFonts, media } from '../../../../../styles';

class DashboardLevelItem extends Component {
    static propTypes = {
        onPress: PropTypes.func.isRequired,
        isLoading: PropTypes.bool,
        onButtonPress: PropTypes.func,
        cropDegree: PropTypes.number.isRequired,
        customPoints: PropTypes.string.isRequired,
        percentFilled: PropTypes.number.isRequired,
        pointsNeededText: PropTypes.string.isRequired,
        levelName: PropTypes.string.isRequired,
        rewardAmount: PropTypes.number.isRequired,
        isFirstLoaded: PropTypes.bool,
        scoreForPeriodNumber: PropTypes.number,
        isHighestScoreReached: PropTypes.bool,
        isRewardsEnabled: PropTypes.bool
    };

    static defaultProps = {
        onButtonPress: null,
        isFirstLoaded: false,
        scoreForPeriodNumber: 0,
        isHighestScoreReached: false,
        isLoading: false,
        isRewardsEnabled: false,
    };

    get lengthAngle() {
        return 360 - this.props.cropDegree;
    }

    get startAngle() {
        return 90 + (this.props.cropDegree / 2);
    }

    showLevelAlert = event => {
        event.stopPropagation();
        this.closeInfoModal = Modal.open(
            DashboardEarnPointsInfo,
            { onButtonPress: () => this.closeInfoModal() },
            { isContainer: true, isTransparent: true, isNoPadding: true, isMaxWidthLimited: true }
        );
    };

    scorePerPeriodFormatter = value => numbers.numberWithCommas(parseInt(value));

    get progressIndicatorContent() {
        const { customPoints, pointsNeededText, isFirstLoaded, scoreForPeriodNumber } = this.props;
        return (
            isFirstLoaded ?
                <div id="progress-indicator-content" key="centerText" className={css(layoutStyle.flexColumnCenter, styles.mb5)}>
                    <p id="points-earned" className={css(styles.dashboardPointsEarned)}>
                        <Core.AnimateNumber
                            value={scoreForPeriodNumber}
                            formattingFn={this.scorePerPeriodFormatter}
                        />
                        <span id="points-name"> {customPoints}</span>
                    </p>
                    <div
                        id="points-needed-container"
                        className={css(layoutStyle.flexRowCenter, styles.dashboardPointsNeeded)}
                    >
                        <p id="points-needed" className={css(styles.textIconVerticalAlignment)}>
                            {pointsNeededText}
                        </p>
                        <Core.IconButton
                            onClick={this.showLevelAlert}
                            isSmall={true}
                            name="question-circle"
                            size={spacing.s3}
                            type="fa"
                            fill="solid"
                            color={baseColors.grey40}
                        />
                    </div>
                </div> :
                <React.Fragment>
                    <Core.SkeletonRect width={70} height={50} className={css(styles.skeletonScoreText)} />
                    <Core.SkeletonRect width={120} height={spacing.s4} />
                </React.Fragment>
        );
    }

    viewLeaderboard = () => {
        tracker.logEvent('Dashboard_Click_ViewLeaderboard');
        this.props.onButtonPress();
    }

    get rewardsSection() {
        const { rewardAmount, isRewardsEnabled } = this.props;
        return (
            <>
                {(isRewardsEnabled && rewardAmount > 0) &&
                <div id="rewards-container" className={css(layoutStyle.flexRowCenter)}>
                    <Core.Icon
                        type="light"
                        name="coins"
                        size={spacing.s4}
                        color={baseColors.warn}
                    />
                    <span id="reward-amount" className={css(styles.rewardAmount)}>+{rewardAmount}</span>
                </div>}
            </>
        );
    }

    render() {
        const { onPress, percentFilled, isFirstLoaded, isLoading, levelName, isHighestScoreReached } = this.props;
        return (
            <div
                id="progress-container"
                onClick={onPress}
                tabIndex="0"
                role="button"
                onKeyDown={onKeyPress.enter(onPress)}>
                <DashboardProgressIndicator
                    percentFilled={percentFilled}
                    startAngle={this.startAngle}
                    lengthAngle={this.lengthAngle}
                    text={this.progressIndicatorContent}
                    isFirstLoaded={isFirstLoaded}
                    isLoading={isLoading}
                    levelName={levelName}
                    rewardSection={this.rewardsSection}
                    viewLeaderboard={this.viewLeaderboard}
                    isHighestScoreReached={isHighestScoreReached}
                />
            </div>
        );
    }
}

export default WithDashboardLevelItemBase(DashboardLevelItem);

const POINTS_NEEDED_MARGIN_LEFT = 9;

const styles = StyleSheet.create({
    ...baseStyles,
    skeletonScoreText: {
        marginBottom: spacing.s2
    },
    dashboardPointsNeeded: {
        ...baseStyles.dashboardPointsNeeded,
        marginTop: 0,
        marginLeft: POINTS_NEEDED_MARGIN_LEFT,
        [media.sm]: {
            ...appFonts.mdRegular,
        },
    },
    rewardAmount: {
        ...appFonts.xlMedium,
        color: baseColors.black,
        marginLeft: spacing.s1,
        marginRight: spacing.s0,
    },
    dashboardPointsEarned: {
        ...baseStyles.dashboardPointsEarned,
        marginTop: spacing.s0,
        [media.sm]: {
            ...appFonts.xlRegular,
        },
    },
    mb5: {
        marginBottom: spacing.s5,
    },
    textIconVerticalAlignment: {
        paddingTop: '2px',
        [media.sm]: {
            paddingTop: '1px',
        },
    },
});
