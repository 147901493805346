import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import _ from 'lodash';

import { components as Core } from '../../../../core';
import { baseColors, importantStyles, spacing, appFonts } from '../../../../../styles';
import WithAchievementDetailsBase, { styles as baseStyles } from './AchievementDetailsBase';

import BonusMark from '../../../../content/components/BonusMark';

class AchievementDetails extends PureComponent {
    static propTypes = {
        achievement: PropTypes.object.isRequired,
        formatDate: PropTypes.func.isRequired,
        rewardData: PropTypes.array.isRequired,
        datesAwarded: PropTypes.array.isRequired,
        isLoading: PropTypes.bool.isRequired,
        isRewardDataLengthOdd: PropTypes.number.isRequired,
        i18n: PropTypes.object.isRequired,
        progressPercentages: PropTypes.number.isRequired,
        instruction: PropTypes.string.isRequired,
        rewardText: PropTypes.string.isRequired,
        awardedDatesInfo: PropTypes.object.isRequired,
        isEarned: PropTypes.bool.isRequired,
        endDate: PropTypes.string.isRequired,
        showAwardsSection: PropTypes.bool.isRequired
    };

    get renderCheckIcon() {
        const { isEarned } = this.props;
        return isEarned ? (
            <Core.Icon
                type="fa"
                fill="solid"
                name="check-circle"
                size={spacing.s4}
                color={baseColors.success}
                className={css(styles.iconCheckContainer)}
            />
        ) : null;
    }

    get renderCoinIcon() {
        return (
            <Core.Icon
                type="fa"
                fill="light"
                name="coins"
                size={spacing.s3}
                color={baseColors.warn}
                className={css(styles.iconCoinsContainer)}
            />
        );
    }

    get renderMainInfo() {
        const { progressPercentages, achievement, instruction, rewardText, endDate } = this.props;

        const classes = {
            barColorPrimary: css(styles.barColorPrimary),
            colorPrimary: css(styles.colorPrimary)
        };

        return (
            <div className={css(styles.section)}>
                <Core.Image src={achievement.iconUrl} className={css(styles.image)} resizeMode="contain" />
                <div className={css(styles.achievementDetails)}>
                    <div className={css(styles.titleContainer)}>
                        <div className={css(styles.title)}>{achievement.name}</div>
                        {this.renderCheckIcon}
                    </div>
                    <div className={css(styles.instructionContainer)}>
                        <span className={css(styles.badgeInstruction)}>{instruction}</span>
                        <span className={css(styles.endDateText)}>{endDate}</span>
                    </div>
                    <BonusMark
                        item={achievement}
                        isAchievement={true}
                        bonusLabel={rewardText}
                        icon={this.renderCoinIcon}
                        labelClassName={styles.bonusText}
                        containerClassName={css(styles.bonusMarkContainer)}
                    />
                </div>
                <div className={css(styles.metricsContainer)}>
                    <Core.ProgressBar
                        isPercentageShown={true}
                        variant="determinate"
                        filledPercentage={progressPercentages}
                        classes={classes}
                        percentageTextColor={baseColors.grey40}
                        backgroundColor={baseColors.grey80}
                    />
                </div>
            </div>
        );
    }

    // UNCOMMENT TO SHOW METRICS SECTION
    // get renderMetrics() {
    //     return (
    //         <div className={css(styles.flexMetricsContainer)}>
    //             {_.map(this.props.rewardData, (item, i) => {
    //                 const centerFirstItem = this.props.isRewardDataLengthOdd && i === 0;
    //                 return item.value ? (
    //                     <div className={css(styles.metricsItem, centerFirstItem ? styles.centeredFirstItem : null)} key={item.name}>
    //                         <span className={css(styles.itemTitle)}>{item.name}</span>
    //                         <div className={css(styles.itemValue)}>{item.value}</div>
    //                     </div>
    //                 ) : null;
    //             })}
    //         </div>
    //     );
    // }

    get renderAwardedDates() {
        const { showAwardsSection, datesAwarded, awardedDatesInfo, formatDate } = this.props;
        const classes = { primary: css(styles.awardedDatesListItemTitle) };
        return showAwardsSection ? (
            <div>
                <div className={css(styles.awardedDatesHeader)}>
                    {!!datesAwarded.length && <span className={css(styles.awardedDatesHeaderTitle)}>{awardedDatesInfo.title}</span>}
                    <span className={css(styles.awardedDatesHeaderSubtitle)}>{awardedDatesInfo.subtitle}</span>
                </div>
                <Core.List component="div">
                    {_.map(datesAwarded, (item, i) => (
                        <Core.ListItem key={i.toString()} divider={true}>
                            <Core.ListItemText primary={formatDate(item.end_date)} classes={classes} />
                        </Core.ListItem>
                    ))}
                </Core.List>
            </div>
        ) : null;
    }

    renderContent = () => (
        this.props.achievement ? (
            <Core.SmallerContainer
                widerHeaderComponent={<Core.EntityDetailsHeader hasBackButton={true} />}
                gridSm={6}>
                <div className={css(styles.mainView)}>
                    {this.renderMainInfo}
                </div>
                {this.renderAwardedDates}
            </Core.SmallerContainer>
        ) : null
    );

    render() {
        return this.props.isLoading ? <Core.ListLoading /> : this.renderContent();
    }
}


export default WithAchievementDetailsBase(AchievementDetails);

const styles = StyleSheet.create(importantStyles({
    ...baseStyles,
    mainView: {
        flex: 1,
        marginTop: spacing.s5
    },
    flexMetricsContainer: {
        ...baseStyles.metricsContainer,
        display: 'flex'
    },
    metricsContainer: {
        ...baseStyles.metricsContainer,
        marginTop: spacing.s7
    },
    metricsItem: {
        ...baseStyles.metricsItem,
        textAlign: 'center'
    },
    image: {
        ...baseStyles.image,
        objectFit: 'contain',
        margin: '0 auto',
        borderRadius: 0,
    },
    barColorPrimary: {
        backgroundColor: baseColors.primary
    },
    colorPrimary: {
        backgroundColor: baseColors.grey85
    },
    name: {
        ...appFonts.lgBold,
        marginTop: spacing.s0
    },
    achievementDetails: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    awardedDatesHeader: {
        ...baseStyles.awardedDatesHeader,
        marginTop: spacing.s7
    },
    endDateText: {
        ...baseStyles.endDateText,
        whiteSpace: 'nowrap',
    },
}));