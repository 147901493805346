import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import I18n from '../../../../i18n/i18n';
import { translate, Platform, PLATFORMS } from '../../../core';
import { baseColors } from '../../../../styles';
import { DEVICES_DASHBOARD_STATUS } from '.././../constants';


export default function WithAppsDevicesStatusAlertBase(WrappedComponent) {
    class AppsDevicesStatusAlertBase extends PureComponent {
        static propTypes = {
            navigation: PropTypes.object.isRequired,
            actionCallback: PropTypes.func.isRequired,
            closeCallback: PropTypes.func.isRequired,
            iconRef: PropTypes.func.isRequired,
            type: PropTypes.object
        };

        static defaultProps = {
            type: DEVICES_DASHBOARD_STATUS.NONE
        };

        onClose = () => {
            if (this.props.closeCallback) {
                this.props.closeCallback();
            }
    
            if (Platform.OS !== PLATFORMS.web) {
                this.props.navigation.pop();
            }
        };

        onPress = () => {
            this.onClose();
    
            if (this.props.actionCallback) {
                this.props.actionCallback();
            }
        };
    
        get title() {
            const { type } = this.props;
            if (type === DEVICES_DASHBOARD_STATUS.ERROR) {
                return I18n.t('appsDevices.statusAlert.errorMessage');
            } else if (type === DEVICES_DASHBOARD_STATUS.WARNING) {
                return I18n.t('appsDevices.statusAlert.warningMessage');
            }

            return I18n.t('appsDevices.statusAlert.connectMessage');
        }

        get linkText() {
            const { type } = this.props;
            if (type === DEVICES_DASHBOARD_STATUS.ERROR) {
                return I18n.t('appsDevices.statusAlert.errorAction');
            } else if (type === DEVICES_DASHBOARD_STATUS.WARNING) {
                return I18n.t('appsDevices.statusAlert.warningAction');
            }

            return I18n.t('appsDevices.statusAlert.connectAction');
        }

        get iconName() {
            const { type } = this.props;
            if (type === DEVICES_DASHBOARD_STATUS.NONE) {
                return 'watch-fitness';
            }

            return null;
        }

        get backgroundColor() {
            const { type } = this.props;
            if (type === DEVICES_DASHBOARD_STATUS.ERROR) {
                return baseColors.danger;
            } else if (type === DEVICES_DASHBOARD_STATUS.WARNING) {
                return baseColors.warn;
            }

            return baseColors.white;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    title={this.title}
                    linkText={this.linkText}
                    iconName={this.iconName}
                    backgroundColor={this.backgroundColor}
                    onClose={this.onClose}
                    onPress={this.onPress}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const routeParams = _.get(ownProps, 'route.params');
        return { ...(routeParams || {}) };
    };

    return connect(mapStateToProps)(translate()(AppsDevicesStatusAlertBase));
}


export const styles = {
    mainContainer: {
        flex: 1,
        backgroundColor: baseColors.transparent
    }
};
