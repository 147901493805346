import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import moment from 'moment';
import { baseColors, spacing, appFonts } from '../../../../styles';
import * as selectors from '../../selectors';
import { appsDevicesHelper } from '../../services';
import { translate, timeout, DATE_FORMATS, constants as CoreConstants, selectors as coreSelectors } from '../../../core';
import * as actions from '../../actions';
import { actions as challengesActions, selectors as challengeSelectors } from '../../../challenges';
import {
    selectors as onboardingSelectors,
    actions as onboardingActions,
    constants as onboardingConstants
} from '../../../onboarding';
import { addToast } from '../../../core/actions';

export default function WithDeviceDetailsBase(WrappedComponent) {
    class DeviceDetailsBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            deviceWarning: PropTypes.object.isRequired,
            disconnectionError: PropTypes.object,
            isShowingOnboarding: PropTypes.bool,
            isDisconnecting: PropTypes.bool,
            isSyncing: PropTypes.bool,
            isLoading: PropTypes.bool,
            setTimeout: PropTypes.func.isRequired,
            device: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
            i18n: PropTypes.object.isRequired,
            isLoadingActivityLogs: PropTypes.bool,
            onSync: PropTypes.func.isRequired,
            isConnecting: PropTypes.bool,
            currentUser: PropTypes.object.isRequired,
            points: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            isLiveBetter: PropTypes.bool
        };

        static defaultProps = {
            isShowingOnboarding: false,
            isDisconnecting: false,
            isSyncing: false,
            isLoading: false,
            disconnectionError: undefined,
            device: {},
            isLoadingActivityLogs: false,
            isConnecting: false,
            isLiveBetter: false
        };

        constructor(props) {
            super(props);
            props.actions.getDevice();
            this.state = {
                isRefreshing: false
            };
        }

        changeConnectingPlatformDeviceState = value => {
            if (!value) this.props.actions.finishConnecting();
        };

        UNSAFE_componentWillReceiveProps(nextProps) {
            if (nextProps.device && this.props.device && nextProps.device.lastSyncDate !== this.props.device.lastSyncDate) {
                this.refreshActivityLogs();
                this.props.setTimeout(() => { this.setState({ isRefreshing: false }); }, CoreConstants.TOAST_LIVE_TIME);
            }

            if (nextProps.isSyncing !== this.props.isSyncing) {
                if (nextProps.isSyncing) {
                    this.setState({ isRefreshing: true });
                } else if (nextProps.device.lastSyncDate === this.props.device.lastSyncDate) {
                    this.setState({ isRefreshing: false });
                }
            }
        }

        onDisconnect = () => {
            this.props.actions.disconnectDevice(this.props.device);
            this.clearError();
        };

        onDismissWarning = () => {
            this.props.actions.updateDeviceNextWarningDate(this.props.device.vendorId);
            this.props.actions.getDevice(this.props.device.vendorId);
        };

        clearError = () => {
            if (this.props.disconnectionError) this.props.actions.clearDisconnectionError();
        };

        refreshActivityLogs = () => {
            if (!this.props.isLoadingActivityLogs) {
                this.props.actions.getActivityLogs();
            }
        };

        formatLastSyncDate = () => {
            const { i18n, device } = this.props;
            if (!device) return i18n.t('not_synced');
            const tempDate = new Date(device.lastSyncDate);
            return moment(tempDate).isValid() ?
                `${moment(tempDate).format('LL')}, ${moment(tempDate).format(DATE_FORMATS.twelveHoursTime)}` :
                i18n.t('inProgress');
        };

        getDeviceHasWarning = () => {
            const { device } = this.props;
            return appsDevicesHelper.deviceHasWarning(device);
        };

        getDeviceHasError = () => {
            const { device } = this.props;
            return appsDevicesHelper.deviceHasError(device);
        };

        deviceStatusMuteHasExpired = () => {
            const { device, deviceWarning } = this.props;
            return appsDevicesHelper.deviceStatusMuteHasExpired(device, deviceWarning);
        };

        getShowDeviceErrorWarning = () => !!((this.getDeviceHasError() || this.getDeviceHasWarning()) && this.deviceStatusMuteHasExpired());

        getDeviceWarningString = () => this.props.i18n.t('appsDevices.deviceDetails.warningMessage');

        getDeviceErrorString = () => this.props.i18n.t('appsDevices.deviceDetails.errorMessage');

        getDeviceWarningActionString = () => this.props.i18n.t('appsDevices.deviceDetails.warningAction');

        getConnectButtonTitle = () => {
            const { i18n, device: { displayName } } = this.props;
            if (this.getDeviceHasError()) {
                return `${i18n.t('reconnect_device')} ${displayName}`;
            }
            return `${i18n.t('connect')} ${displayName}`;
        }

        setIsRefreshing = refreshing => {
            this.setState({ isRefreshing: refreshing });
        }

        onFinishAppsDevicesOnboardingStep = () => {
            const userPoints = _.toInteger(_.get(this.props.currentUser, 'points', 0)) || 0;
            const newPoints = userPoints + _.toInteger(this.props.points[onboardingConstants.FLOW_STEPS.appsDevices]);
            this.props.actions.recordStepCompleted(onboardingConstants.FLOW_STEPS.appsDevices, true);
            this.props.actions.updateUserPoints(newPoints);
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    onDisconnect={this.onDisconnect}
                    clearError={this.clearError}
                    formatLastSyncDate={this.formatLastSyncDate}
                    getDeviceHasWarning={this.getDeviceHasWarning}
                    getDeviceHasError={this.getDeviceHasError}
                    getShowDeviceErrorWarning={this.getShowDeviceErrorWarning}
                    onDismissWarning={this.onDismissWarning}
                    getDeviceWarningString={this.getDeviceWarningString}
                    getDeviceErrorString={this.getDeviceErrorString}
                    getDeviceWarningActionString={this.getDeviceWarningActionString}
                    refreshActivityLogs={this.refreshActivityLogs}
                    isRefreshing={this.state.isRefreshing}
                    setIsRefreshing={this.setIsRefreshing}
                    changeConnectingPlatformDeviceState={this.changeConnectingPlatformDeviceState}
                    onFinishAppsDevicesOnboardingStep={this.onFinishAppsDevicesOnboardingStep}
                    getConnectButtonTitle={this.getConnectButtonTitle}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const vendorId = _.get(ownProps, 'vendorId')
            || _.get(ownProps, 'match.params.vendorId')
            || _.get(ownProps, 'route.params.vendorId');
        return {
            isShowingOnboarding: onboardingSelectors.isShowingOnboarding(state),
            deviceWarning: selectors.getDeviceWarningDatesByVendorId(state, vendorId),
            device: selectors.getDeviceByVendorId(state, vendorId),
            isDisconnecting: selectors.isDisconnectingDevice(state),
            isSyncing: selectors.isSyncingDevice(state, vendorId),
            isLoading: selectors.isLoadingDevice(state),
            disconnectionError: selectors.getDisconnectionError(state),
            sLoadingActivityLogs: challengeSelectors.isLoadingActivityLogs(state),
            isConnecting: selectors.isConnecting(state),
            currentUser: coreSelectors.getCurrentUser(state),
            points: onboardingSelectors.getOnboardingTasksPoints(state),
            connected: selectors.getConnectedDevices(state),
            isLiveBetter: coreSelectors.isLiveBetter(state)
        };
    };

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators({
        ...actions,
        ...challengesActions,
        addToast,
        ...onboardingActions
    }, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(timeout(DeviceDetailsBase)));
}

const IMAGE_SIZE = 96;

export const styles = {
    mainContainer: {
        flex: 1,
        backgroundColor: baseColors.white
    },
    headerContainer: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: spacing.s5,
        marginTop: spacing.s3
    },
    deviceIcon: {
        height: IMAGE_SIZE,
        width: IMAGE_SIZE,
    },
    deviceName: {
        flex: 1,
        ...appFonts.xlBold,
        paddingTop: spacing.s0,
        paddingBottom: spacing.s0
    },
    deviceDescription: {
        ...appFonts.mdRegular,
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3,
        textAlign: 'center',
    },
    syncingText: {
        ...appFonts.mdBold,
        marginBottom: spacing.s0
    },
    syncedData: {
        ...appFonts.mdRegular
    },
    buttonContainer: {
        display: 'flex',
        paddingBottom: spacing.s3,
        flexDirection: 'column',
        alignItems: 'center'
    },
    syncButton: {
        flex: 1,
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
    },
    listSection: {
        backgroundColor: baseColors.grey85,
        paddingBottom: spacing.s0,
        paddingTop: spacing.s0
    },
    listItemContainer: {
        borderBottomColor: baseColors.grey80,
        backgroundColor: baseColors.white,
    },
    lastListItemContainer: {
        borderBottomColor: baseColors.white,
        backgroundColor: baseColors.white
    },
    animatedContainer: {
        paddingLeft: spacing.s1,
        paddingRight: spacing.s1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    alertContainer: {
        display: 'flex',
        paddingTop: spacing.s2,
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3,
        flexDirection: 'column',
        alignItems: 'center'
    },
    warningText: {
        color: baseColors.warnDarker
    },
    lastSyncedTextGroup: {
        flexDirection: 'row',
    },
    actionText: {
        color: baseColors.secondary
    },
    statusText: {
        ...appFonts.smRegular,
        color: baseColors.grey40,
    },
    errorText: {
        ...appFonts.smRegular,
        color: baseColors.dangerDarker
    },
};
