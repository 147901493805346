/* eslint-disable jsx-a11y/alt-text */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { css, StyleSheet } from 'aphrodite-jss';
import classnames from 'classnames';
import { Transition } from 'react-transition-group';
import { isIE } from 'react-device-detect';
import { styles as fadeStyles, getFadeClasses } from '../LazyComponents';
import { spacing } from '../../../../styles';

export default class Image extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        duration: PropTypes.number,
        isNaturalRatio: PropTypes.bool,
        isFadeIn: PropTypes.bool,
        resizeMode: PropTypes.string,
        src: PropTypes.string,
        placeholder: PropTypes.node,
        onLoad: PropTypes.func,
        alt: PropTypes.string
    };

    static defaultProps = {
        isFadeIn: false,
        isNaturalRatio: false,
        duration: 300,
        className: undefined,
        resizeMode: undefined,
        src: undefined,
        placeholder: null,
        onLoad: undefined,
        alt: 'image'
    };

    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            image: props.src,
            showPlaceHolder: false,
        };
    }

    onLoad = () => {
        if (!isIE) {
            const { isNaturalRatio, onLoad } = this.props;
            onLoad && onLoad();
            if (isNaturalRatio) {
                const { naturalHeight, naturalWidth, height, width } = this.ref;
                this.ref.height = (naturalHeight || height) / ((naturalWidth || width) / width);
            }
        }
        this.setState({ loaded: true });
    };

    componentDidMount() {
        if (isIE) {
            this.containerRef.style.backgroundImage = `url("${this.props.src}")`;
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (isIE) {
            if (nextProps.src !== this.state.image) {
                this.containerRef.style.backgroundImage = `url("${nextProps.src}")`;
                this.setState({ image: nextProps.src });
            }
        }
    }

    saveRef = ref => (this.ref = ref);
    saveContainerRef = ref => (this.containerRef = ref);

    get image() {
        const { duration, isFadeIn, className, resizeMode, ...props } = _.omit(this.props, 'isNaturalRatio');
        const { loaded } = this.state;
        const source = _.get(this.props.src, 'default') ? this.props.src.default : this.props.src;
        const imageStyle = { objectFit: resizeMode };
        if (isIE) {
            return isFadeIn ? (
                <Transition in={loaded} timeout={duration}>
                    {state => (
                        <div
                            ref={this.saveContainerRef}
                            className={
                                classnames(
                                    getFadeClasses(fadeStyles, state, duration),
                                    css(styles.imageContainer),
                                    css(styles.image),
                                    css(styles.imageIE),
                                    className)}
                            style={imageStyle} >
                            <img
                                {...props}
                                className={classnames(css(styles.image, styles.imageHiddenIE), className)}
                                ref={this.saveRef}
                                onLoad={this.onLoad}
                                style={imageStyle}
                                onError={this.setError}
                            />
                        </div>

                    )}
                </Transition>
            ) : (
                <div ref={this.saveContainerRef} className={classnames(css(styles.image), css(styles.imageIE), resizeMode === 'contain' ? css(styles.imageContainIE) : null, className)} style={imageStyle} />
            );
        }

        return isFadeIn ? (
            <Transition in={loaded} timeout={duration}>
                {state => (
                    <div className={classnames(getFadeClasses(fadeStyles, state, duration), css(styles.imageContainer))}>
                        <img
                            {...props}
                            className={classnames(css(styles.image), className)}
                            ref={this.saveRef}
                            onLoad={this.onLoad}
                            style={imageStyle}
                            onError={this.setError}
                            src={source}
                        />
                    </div>

                )}
            </Transition>
        ) : (
            <img
                {...props}
                className={classnames(css(styles.image), this.props.className)}
                style={imageStyle}
                onError={this.setError}
                onLoad={this.onLoad}
                src={source}
                alt={this.props.alt}
            />
        );
    }

    setError = () => {
        if (this.props.placeholder) this.setState({ showPlaceHolder: true });
    }

    render() {
        return this.state.showPlaceHolder ? this.props.placeholder : this.image;
    }
}

export const styles = StyleSheet.create({
    imageContainer: {
        width: '100%',
        height: '100%'
    },
    image: {
        display: 'block',
        objectFit: 'cover',
        width: '100%',
        borderRadius: spacing.s1
    },
    imageIE: {
        width: '100%',
        height: '100%',
        position: 'relative',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat'
    },
    imageHiddenIE: {
        opacity: 0
    },
    imageContainIE: {
        backgroundSize: 'contain',
    }
});
