import React, { PureComponent, Fragment } from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import { components as Core, ActionSheet, CSS_CLASSES } from '../../../core';
import WithInviteByEmailBase, { styles as baseStyles } from './InviteByEmailBase';
import { baseColors, layoutStyle, spacing } from '../../../../styles';

import PlatformUserItem from '../PlatformUserItem';

class InviteByEmail extends PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        email: PropTypes.string.isRequired,
        onChangeEmail: PropTypes.func.isRequired,
        onInvite: PropTypes.func.isRequired,
        platformInvitations: PropTypes.array.isRequired,
        isLoading: PropTypes.bool.isRequired,
        isSending: PropTypes.bool.isRequired,
        isEnabled: PropTypes.bool.isRequired,
        getIconName: PropTypes.func.isRequired,
        isInviteFromEntity: PropTypes.bool,
        isOpenedFromSettings: PropTypes.bool,
        showAlert: PropTypes.func.isRequired,
        removeItem: PropTypes.func.isRequired,
        addEmailsToList: PropTypes.func,
        emailsList: PropTypes.array,
        title: PropTypes.string,
        addEmailToList: PropTypes.func,
        userByEmail: PropTypes.object,
        close: PropTypes.func.isRequired,
        customNavBarButtonsLeft: PropTypes.func.isRequired,
        customNavBarButtonsRight: PropTypes.func.isRequired
    };

    static defaultProps = {
        isInviteFromEntity: false,
        isOpenedFromSettings: false,
        addEmailsToList: null,
        emailsList: [],
        title: null,
        addEmailToList: null,
        userByEmail: undefined
    };

    onPressDelete = item => {
        ActionSheet.open(
            this.discardOptions(item),
            undefined,
            this.props.i18n.t('invitation.removeEmailConfirmation')
        );
    };

    discardOptions = item => {
        const { i18n, removeItem } = this.props;
        const removeInvitation = () => removeItem(item);
        return [
            {
                title: i18n.t('invitation.yes'),
                onPress: removeInvitation
            }
        ];
    };

    renderPlatformInvitation = item => {
        const onPressDelete = e => this.onPressDelete(item, e);
        return this.props.isInviteFromEntity ?
            <Core.ListItem key={item.id}>
                <Core.ListItemText primary={item.inviteeEmail} />
                <Core.IconButton
                    size={spacing.s3}
                    onClick={onPressDelete}
                    type="fa"
                    iconCustomClass={styles.deleteIcon}
                    color={baseColors.danger}
                    name="trash-alt"
                />
            </Core.ListItem> :
            <div key={item.id} className={css(styles.itemContainer, layoutStyle.flex)}>
                <p className={css(styles.itemText)}>{item.inviteeEmail}</p>
                <Core.Icon
                    type="fa"
                    name={this.props.getIconName(item)}
                    size={spacing.s5}
                />
            </div>;
    };

    get emptyComponent() {
        return (
            <div className={css(styles.innerContainer)}>
                <p className={css(styles.emptyMessage)}>
                    {this.props.isInviteFromEntity
                        ? this.props.i18n.t('invitation.noInvitationsToBeSent')
                        : this.props.i18n.t('invitation.youHaveNoSentInvitations')
                    }
                </p>
            </div>
        );
    }

    get subheaderRightButton() {
        if (this.props.isInviteFromEntity) {
            return (
                <Core.Button
                    type="text"
                    onPress={this.onInviteFromEntity}>
                    {this.props.customNavBarButtonsRight().title}
                </Core.Button>
            );
        } else if (this.props.isOpenedFromSettings) {
            return (
                <Core.Button
                    type="text"
                    onPress={this.props.close}>
                    {this.props.i18n.t('done')}
                </Core.Button>
            );
        }

        return null;
    }

    keyExtractor = item => item.id;

    onInviteFromEntity = () => {
        this.props.close();
        this.props.addEmailsToList(this.props.emailsList);
    };

    goBack = () => this.props.showAlert(this.props.close);

    get listItems() {
        return this.props.isInviteFromEntity ? this.props.emailsList : this.props.platformInvitations;
    }

    get content() {
        return (
            <Core.Layout.FHFlex>
                <div className={css(styles.headerContainer, layoutStyle.flexColumn, layoutStyle.commonPadding)}>
                    <Core.SubHeader
                        noTopPadding={true}
                        title={this.props.title || this.props.i18n.t('invite')}
                        left={this.props.isInviteFromEntity ?
                            <Core.Button
                                type="text"
                                onPress={this.goBack}>
                                {this.props.customNavBarButtonsLeft().title}
                            </Core.Button> : null
                        }
                        titleAlign={Core.SubHeader.ALIGN.center}
                        right={this.subheaderRightButton}
                    />
                    <Core.Input
                        autoCapitalize="none"
                        label={this.props.i18n.t('enterEmail')}
                        value={this.props.email}
                        onChangeText={this.props.onChangeEmail}
                        fullWidth={true}
                    />
                    {this.props.userByEmail && !this.props.isInviteFromEntity ? (
                        <Fragment>
                            <p className={css(styles.contentContainerTitle, styles.foundEmailTitle)}>
                                {this.props.i18n.t('invitation.foundByEmail')}
                            </p>
                            <PlatformUserItem
                                userId={this.props.userByEmail}
                                hasBorder={false}
                                isSuggestedUser={true}
                            />
                        </Fragment>
                    ) : (
                        <Core.Button
                            onPress={this.props.isInviteFromEntity
                                ? this.props.addEmailToList
                                : this.props.onInvite
                            }
                            size={Core.Button.SIZES.medium}
                            fullWidth={true}
                            disabled={!this.props.isEnabled}
                            isLoading={this.props.isSending}>
                            {this.props.isInviteFromEntity
                                ? this.props.i18n.t('invitation.addEmailToList')
                                : this.props.i18n.t('invitation.sendInvitation')
                            }
                        </Core.Button>
                    )}
                </div>
                <div className={classNames(css(styles.contentContainer), CSS_CLASSES.modalBody)}>
                    <p className={css(styles.contentContainerTitle)}>
                        {this.props.isInviteFromEntity
                            ? this.props.i18n.t('invitation.toBeSent')
                            : this.props.i18n.t('invitation.sentInvitations')}
                    </p>
                    {this.props.isLoading ?
                        <Core.ListLoading key="Loading" /> :
                        <Fragment>
                            <Core.List keyExtractor={this.keyExtractor}>
                                {_.map(this.listItems, this.renderPlatformInvitation)}
                            </Core.List>
                            {!this.listItems.length && this.emptyComponent}
                        </Fragment>
                    }
                </div>
            </Core.Layout.FHFlex>
        );
    }

    render() {
        const hasLayoutContainer = _.get(this, 'props.location.state.hasLayoutContainer');
        return hasLayoutContainer ? <Core.SmallerContainer>{this.content}</Core.SmallerContainer> : this.content;
    }
}

const styles = StyleSheet.create({
    ...baseStyles,
    headerContainer: {
        ...baseStyles.headerContainer,
        borderBottomStyle: 'solid'
    },
    deleteIcon: {
        width: spacing.s3,
        height: spacing.s3,
    }
});

export default WithInviteByEmailBase(InviteByEmail);
