import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { spacing, baseColors, appFonts } from '../../../../styles';
import { translate, color, BRAND_COLOR, pointsHelper, selectors as coreSelectors } from '../../../core';

export const ICON_SIZE = 14;
export const ICON_NAME = 'check';

export default function withBonusMarkBase(WrappedComponent) {
    class BonusMarkBase extends PureComponent {
        static propTypes = {
            item: PropTypes.object.isRequired,
            showCheck: PropTypes.bool,
            bonusLabel: PropTypes.string
        };

        static defaultProps = {
            showCheck: false,
            bonusLabel: undefined
        };

        get item() {
            return this.props.item;
        }

        get hasReward() {
            return !!Number(this.item.activity_quantity || this.item.externalRewardPoints);
        }

        get isRead() {
            return this.item.already_read === '1' || this.props.showCheck;
        }

        get earnedBonusText() {
            return this.props.i18n.t('youEarnedBonus');
        }

        get pointsValue() {
            if (_.get(this.item, 'externalRewardPoints')) {
                return this.item.externalRewardPoints;
            }

            if (_.get(this.item, 'activity_quantity')) {
                return this.item.activity_quantity;
            }

            return 0;
        }

        get bonusLabel() {
            if (this.props.bonusLabel) {
                return this.props.bonusLabel;
            }

            return `+ ${pointsHelper.formatPoints(this.pointsValue, this.props.customPointsUnit)}`;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    isRead={this.isRead}
                    hasReward={this.hasReward}
                    earnedBonusText={this.earnedBonusText}
                    bonusLabel={this.bonusLabel}
                />
            );
        }
    }

    const mapStateToProps = state => ({
        customPointsUnit: coreSelectors.getCustomPointsUnit(state),
    });

    return connect(mapStateToProps)(translate()(BonusMarkBase));
}

export const styles = {
    bonusText: {
        ...appFonts.smMedium,
        color: color.brandColorMaker(baseColors.primary, BRAND_COLOR.DARK_SHADE),
    },
    bonusBg: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: spacing.s0,
        paddingBottom: spacing.s0,
        paddingRight: spacing.s1,
        paddingLeft: spacing.s1,
        marginRight: spacing.s1,
        borderRadius: spacing.s0,
        backgroundColor: color.brandColorMaker(baseColors.primary, BRAND_COLOR.LIGHT_SHADE),
    },
    achievementBonusBg: {
        backgroundColor: baseColors.warnLightest
    },
    bonusWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    earnedBonusText: {
        ...appFonts.smRegular,
        marginLeft: spacing.s0,
        color: baseColors.grey40,
        marginBottom: 0
    },
};