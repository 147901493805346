const LOGIN_STATUS = {
    connected: 'connected'
};

const FIELDS = {
    firstName: 'first_name',
    lastName: 'last_name',
    email: 'email',
    publicProfile: 'public_profile'
};
const LOGIN_SCOPE = [FIELDS.publicProfile, FIELDS.email];
const ME_FIELDS = [FIELDS.firstName, FIELDS.lastName, FIELDS.email].join(',');

class Facebook {
    constructor() {
        this.init();
    }

    init = () => {
        window.fbAsyncInit = function () {
            window.FB.init({
                appId: '1643469392390073',
                cookie: true, // enable cookies to allow the server to access the session
                xfbml: true, // parse social plugins on this page
                autoLogAppEvents: true,
                version: 'v3.1'
            });
        };

        // Load the SDK asynchronously
        (function (d, s, id) {
            const fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            const js = d.createElement(s); js.id = id;
            js.src = 'https://connect.facebook.net/en_US/sdk.js';
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    };

    getToken() {
        return this._accessToken;
    }

    login = (callback, errorCallback, scope = LOGIN_SCOPE) => window.FB.login(response => {
        if (response.authResponse) {
            this._accessToken = response.authResponse.accessToken;
            callback(response.authResponse);
        }
    }, { scope });

    me = (callback, errorCallback, fields = ME_FIELDS) => window.FB.api('/me', { fields }, result => {
        if (result.error) return errorCallback && errorCallback(result.error);
        callback({ firstName: result.first_name, lastName: result.last_name, email: result.email });
    });

    isSignedIn(callback) {
        return window.FB.getLoginStatus(response => callback(response.status === LOGIN_STATUS.connected));
    }

    logout = callback => this.isSignedIn(isSignedIn => {
        if (isSignedIn) {
            return window.FB.logout(response => {
                this._accessToken = null;
                callback(response);
            });
        }
    });
}

const fb = new Facebook();

export default fb;
