import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { baseColors, spacing, appFonts } from '../../../../styles';
import { translate, openInapropriateContentAlert, ALERT_TIMEOUT, timeout } from '../../../core';
import { isLoadingStreamItem, getFeed } from '../../selectors';
import { actions as commentsLikesActions, selectors as commentslikesSelectors } from '../../../commentslikes';
import { constants as settingsConstants, selectors as settingsSelectors } from '../../../settings';

const VISIBLE_COMMENTS_COUNT_DEFAULT = 2;
const VISIBLE_COMMENTS_COUNT = 10;
export const INPUT_MAX_LENGTH = 200;

export default function WithFeedDetailsBase(WrappedComponent) {
    class FeedDetailsBase extends Component {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            streamItemId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            stream: PropTypes.object.isRequired,
            isSeeMore: PropTypes.bool,
            autoFocus: PropTypes.bool,
            i18n: PropTypes.object.isRequired,
            isPosting: PropTypes.bool,
            isEditable: PropTypes.bool.isRequired,
            setTimeout: PropTypes.func.isRequired,
            contentError: PropTypes.object,
        };

        static defaultProps = {
            isPosting: false,
            isSeeMore: false,
            autoFocus: false,
            contentError: undefined
        };

        constructor(props) {
            super(props);
            this.visibleCommentsCount = this.props.isSeeMore ? VISIBLE_COMMENTS_COUNT : VISIBLE_COMMENTS_COUNT_DEFAULT;
            this.state = {};
            this.onRefresh();
        }

        static getDerivedStateFromProps(nextProps, prevState) {
            if (prevState.isPosting === nextProps.isPosting) return null;
            return {
                isPosting: nextProps.isPosting,
                commentText: !nextProps.contentError ? '' : prevState.commentText
            };
        }

        componentDidUpdate(prevProps, prevState) {
            if (prevProps.isPosting && !this.props.isPosting && !prevProps.contentError && this.props.contentError) {
                this.props.setTimeout(() => {
                    this.openErrorAlert(this.props.contentError.type);
                }, ALERT_TIMEOUT);
            }
        }

        openErrorAlert = type => {
            openInapropriateContentAlert(type, this.props.actions.clearPostError, null);
        };

        get stream() {
            return this.props.stream;
        }

        get isDisabled() {
            return !this.state.commentText || !this.props.isEditable;
        }

        onRefresh = () => {
            this.props.actions.getStreamItem(this.props.streamItemId);
        };

        postComment = () => {
            const { commentText } = this.state;
            this.props.actions.clearPostError();
            if (this.commentsRef && !_.isEmpty(_.trim(commentText))) this.commentsRef.postComment(commentText);
        };

        onChange = commentText => this.setState({ commentText });

        onRefComments = ref => (this.commentsRef = ref);

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    postComment={this.postComment}
                    onChange={this.onChange}
                    onRefComments={this.onRefComments}
                    isDisabled={this.isDisabled}
                    commentText={this.state.commentText}
                    visibleCommentsCount={this.visibleCommentsCount}
                    hasStream={!!this.props.stream}
                    onRefresh={this.onRefresh}
                    openErrorAlert={this.openErrorAlert}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        const streamItemId = ownProps.streamItemId || _.get(ownProps, 'route.params.streamItemId') || _.get(ownProps, 'match.params.streamItemId');
        return {
            autoFocus: ownProps.autoFocus || _.get(ownProps, 'location.state.autoFocus'),
            isSeeMore: ownProps.isSeeMore || _.get(ownProps, 'location.state.isSeeMore'),
            streamItemId,
            stream: getFeed(state, streamItemId),
            isLoading: isLoadingStreamItem(state),
            contentError: commentslikesSelectors.getContentError(state),
            isPosting: commentslikesSelectors.isPosting(state),
            isEditable: settingsSelectors.isHiddenTextOrNotEnabled(state,
                settingsConstants.PRIVACY_SLUGS.COMMENT_TO_ACTION)
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators({ ...actions, ...commentsLikesActions }, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(timeout(translate()(FeedDetailsBase)));
}

export const styles = {
    footer: {
        flexDirection: 'row',
        backgroundColor: baseColors.white,
        paddingLeft: spacing.s3,
        paddingTop: spacing.s2,
        paddingBottom: spacing.s2,
        alignItems: 'flex-start',
    },
    footerInput: {
        borderColor: baseColors.grey80,
        borderWidth: 1,
        flex: 1,
        backgroundColor: baseColors.grey80,
        borderRadius: spacing.s2,
        paddingTop: spacing.s0,
        paddingLeft: spacing.s2,
        paddingRight: spacing.s2,
        color: baseColors.black,
        ...appFonts.mdRegular
    },
    footerButtonText: {
        ...appFonts.mdRegular,
        color: baseColors.secondary
    },
    disabled: {
        color: baseColors.grey50
    },
    userAvatar: {
        marginRight: spacing.s2
    }
};
