import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import {
    constants as coreConstants,
    actions as coreActions,
    translate
} from '../../../core';
import { getEmailProgramNameError, isLoadingEmailProgramName } from '../../selectors';

export const INPUT_LENGTH = 200;

export default function WithFindDomainNameBase(WrappedComponent) {
    class FindDomainNameBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            isLoading: PropTypes.bool,
            error: PropTypes.object,
            i18n: PropTypes.object.isRequired,
            callback: PropTypes.func.isRequired,
            step: PropTypes.string.isRequired,
        };

        static defaultProps = {
            isLoading: false,
            error: undefined
        };

        constructor(props) {
            super(props);
            this.state = { errorMessage: '', email: '' };
        }

        UNSAFE_componentWillReceiveProps(nextProps) {
            if (!nextProps.isLoading && this.props.isLoading) {
                if (nextProps.error) {
                    this.defineErrorMessage(nextProps.error.errorCode);
                } else {
                    this.props.callback(this.props.step);
                }
            }
        }

        _clearError = () => {
            if (this.props.error) {
                this.props.actions.clearEmailProgramNameError();
                this.setState({ errorMessage: '' });
            }
        };

        sendEmailProgramName = () => {
            this.props.actions.getEmailProgramName(this.state.email);
        };

        defineErrorMessage = errorCode => {
            const { i18n } = this.props;
            switch (errorCode) {
                case 'missing_field':
                    this.setState({ errorMessage: i18n.t('resetPasswordMissingField') });
                    break;
                case 'invalid_parameter_value':
                    this.setState({ errorMessage: i18n.t('resetPasswordInvalidValue') });
                    break;
                case 'resource_not_found':
                    this.setState({ errorMessage: i18n.t('resetPasswordNotFound') });
                    break;
                case 'server_error':
                    this.showToast(coreConstants.TOAST_TYPES.DANGER,
                        i18n.t('findProgramErrorTitle'), i18n.t('findProgramErrorMessage'));
                    break;
                default:
                    this.setState({ errorMessage: i18n.t('deepLinks.requestCouldNotBeCompleted') });
                    break;
            }
        };

        showToast = (type, title, msg) => {
            this.props.actions.addToast(type, undefined, msg, title);
        };

        onChangeEmail = email => {
            this._clearError();
            this.setState({ email });
        };

        render() {
            return (
                <WrappedComponent
                    ref={ref => this.wrapped = ref}
                    {...this.props}
                    email={this.state.email}
                    onChangeEmail={this.onChangeEmail}
                    showToast={this.showToast}
                    errorMessage={this.state.errorMessage}
                    sendEmailProgramName={this.sendEmailProgramName}
                />
            );
        }
    }

    function mapStateToProps(state) {
        return {
            error: getEmailProgramNameError(state),
            isLoading: isLoadingEmailProgramName(state),
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators({ ...coreActions, ...actions }, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(FindDomainNameBase));
}
