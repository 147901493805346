import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import { css, StyleSheet } from 'aphrodite-jss';
import classNames from 'classnames';
import { baseColors, spacing, importantStyles, headerHeight, important, layoutStyle, media } from '../../../../styles';
import { MIN_HEIGHT as TOP_BAR_HEIGHT } from '../SubHeader';
import ScrollableTabs from '../ScrollableTabs';
import SmallerContainer from '../web/SmallerContainer';
import { isWiderScreen } from '../../selectors';
import { changeScreenSize } from '../../actions';
import { SEARCH_INPUT_DELAY } from '../../constants';

class TabsWithContent extends PureComponent {
    static propTypes = {
        onPress: PropTypes.func,
        onChange: PropTypes.func,
        tabs: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string.isRequired,
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
        })),
        activeTab: PropTypes.shape({
            label: PropTypes.string,
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        }),
        tabClassName: PropTypes.string,
        barStyles: PropTypes.string,
        barPosition: PropTypes.string,
        children: PropTypes.node.isRequired,
        showTabs: PropTypes.bool,
        actions: PropTypes.object.isRequired,
        isWiderScreen: PropTypes.bool,
        headerComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
        isTabsContentVisible: PropTypes.bool,
        isStickyUnderNavBar: PropTypes.bool,
        isHeaderSticky: PropTypes.bool,
        tabsContainerClassName: PropTypes.object,
        afterTabsComponent: PropTypes.node,
        isChallengeDetailsPage: PropTypes.bool,
        searchComponent: PropTypes.object,
        isLibraryPage: PropTypes.bool,
        isHorizontal: PropTypes.bool,
        tabsInnerComponent: PropTypes.node,
        hasVideoContent: PropTypes.bool,
        isFilterEmojiModal: PropTypes.bool,
        hasSectionBorder: PropTypes.bool
    };

    static defaultProps = {
        barPosition: 'sticky',
        tabClassName: undefined,
        onPress: undefined,
        onChange: null,
        barStyles: '',
        activeTab: {},
        tabs: [],
        showTabs: true,
        isWiderScreen: true,
        headerComponent: null,
        isStickyUnderNavBar: false,
        isTabsContentVisible: true,
        isHeaderSticky: false,
        tabsContainerClassName: undefined,
        afterTabsComponent: null,
        isChallengeDetailsPage: false,
        searchComponent: undefined,
        isLibraryPage: false,
        isHorizontal: false,
        tabsInnerComponent: null,
        hasVideoContent: false,
        isFilterEmojiModal: false,
        hasSectionBorder: true
    };

    constructor(props) {
        super(props);
        this.changeScreenSize = _.debounce(
            this.props.actions.changeScreenSize,
            SEARCH_INPUT_DELAY
        );
    }

    componentDidMount() {
        window.addEventListener('resize', this.changeScreenSize);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.changeScreenSize);
    }

    get isVertical() {
        return this.props.isHorizontal ? false : this.props.isWiderScreen;
    }

    onChange = (event, value) => {
        if (!value) return null;
        const newValue = _.find(this.props.tabs, { id: value });
        const method = this.props.onChange || this.props.onPress;
        method && method(newValue);
    };

    tabsWrapper = children => {
        const { barStyles, tabClassName, headerComponent, isStickyUnderNavBar, isHeaderSticky, tabs,
            tabsContainerClassName, afterTabsComponent, isWiderScreen, isChallengeDetailsPage,
            barPosition, isLibraryPage, isFilterEmojiModal, hasSectionBorder } = this.props;
        if (isChallengeDetailsPage) {
            return (
                <div>
                    {!isHeaderSticky ? headerComponent : null}
                    <SmallerContainer itemClassName={css(styles.tabsWrapper, hasSectionBorder ? styles.tabWrapperBorder : null)}>
                        {Array.isArray(tabs) && tabs.length ? children : null}
                    </SmallerContainer>
                </div>
            );
        }

        if (isLibraryPage) {
            return (
                <Grid item={true} sm={12} className={[css(styles.verticalLeftContainer), css(tabsContainerClassName)]}>
                    {!isHeaderSticky ? headerComponent : null}
                    <div className={css(
                        layoutStyle.stickyContainer,
                        isStickyUnderNavBar ? layoutStyle.stickyContainerUnderNavBar : null,
                        layoutStyle.flexColumn,
                        styles.libraryTabsContainer
                    )}>
                        {isHeaderSticky ? headerComponent : null}
                        {Array.isArray(tabs) && tabs.length ? children : null}
                        {afterTabsComponent ? afterTabsComponent : null}
                    </div>
                </Grid>
            );
        }

        if (isFilterEmojiModal) {
            return (
                <div className={css(
                    layoutStyle.stickyContainer,
                    isStickyUnderNavBar ? layoutStyle.stickyContainerUnderNavBar : null,
                    layoutStyle.flexColumn,
                    tabsContainerClassName
                )}>
                    {isHeaderSticky ? headerComponent : null}
                    {Array.isArray(tabs) && tabs.length ? children : null}
                    {afterTabsComponent ? afterTabsComponent : null}
                </div>
            );
        }

        return isWiderScreen ?
            (
                <Grid item={true} sm={4} className={[css(styles.verticalLeftContainer), tabsContainerClassName]}>
                    {!isHeaderSticky ? headerComponent : null}
                    <div className={css(
                        layoutStyle.stickyContainer,
                        isStickyUnderNavBar ? layoutStyle.stickyContainerUnderNavBar : null,
                        layoutStyle.flexColumn
                    )}>
                        {isHeaderSticky ? headerComponent : null}
                        {Array.isArray(tabs) && tabs.length ? children : null}
                        {afterTabsComponent ? afterTabsComponent : null}
                    </div>
                </Grid>
            ) : (
                <AppBar
                    position={barPosition}
                    color={baseColors.white}
                    classes={{
                        root: classNames(css(styles.appBar), tabClassName),
                        positionSticky: css(
                            styles.headerPositionSticky,
                            isStickyUnderNavBar ? styles.horizontalStickyContainerUnderNavBar : null,
                            styles.zIndex,
                            barStyles
                        ),
                    }}>
                    {children}
                </AppBar>
            );
    };

    render() {
        const { tabs, activeTab, isTabsContentVisible, barStyles, children, showTabs, isWiderScreen, headerComponent,
            isChallengeDetailsPage, searchComponent, isLibraryPage, isSearchInputFocused, tabsInnerComponent, hasVideoContent,
            isFilterEmojiModal } = this.props;
        const childrenContent = isChallengeDetailsPage ? <SmallerContainer>{children}</SmallerContainer> : <Grid item={true} sm={isWiderScreen ? 8 : 12}>{children}</Grid>;
        return (
            <div className={isChallengeDetailsPage ? null : css(layoutStyle.flexRow)}>
                {!isWiderScreen ? headerComponent : null}
                {showTabs ? this.tabsWrapper(
                    isTabsContentVisible ?
                        (
                            <ScrollableTabs
                                scrollable={!isWiderScreen}
                                onChange={this.onChange}
                                tabs={tabs}
                                activeTab={activeTab}
                                barStyles={barStyles}
                                isVertical={this.isVertical}
                                isChallengeDetailsPage={isChallengeDetailsPage}
                                searchComponent={searchComponent}
                                isLibraryPage={isLibraryPage}
                                isSearchInputFocused={isSearchInputFocused}
                                innerComponent={tabsInnerComponent}
                                hasVideoContent={hasVideoContent}
                                isFilterEmojiModal={isFilterEmojiModal}
                            />
                        )
                        : null
                ) : null}
                {children ? childrenContent : null}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    isWiderScreen: isWiderScreen(state)
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ changeScreenSize }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(TabsWithContent);

TabsWithContent.TABS_OFFSET = headerHeight + TOP_BAR_HEIGHT;

const styles = StyleSheet.create({
    ...importantStyles({
        appBar: {
            boxShadow: 'none',
            backgroundColor: baseColors.white,
            borderBottomColor: baseColors.grey85,
            borderBottomWidth: 3,
            borderBottomStyle: 'solid',
            paddingLeft: spacing.s1,
            margin: 0
        },
        headerPositionSticky: {
            top: headerHeight,
        },
        horizontalStickyContainerUnderNavBar: {
            [media.xs]: {
                top: headerHeight + 40
            }
        },
    }),
    zIndex: {
        zIndex: important(1, false)
    },
    verticalLeftContainer: {
        paddingRight: spacing.s3
    },
    tabsWrapper: {
        marginTop: important(spacing.s3),
    },
    tabWrapperBorder: {
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: baseColors.grey70,
    },
    libraryTabsContainer: {
        width: '100%'
    }
});
