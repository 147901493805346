import _ from 'lodash';
import { createSelector } from 'reselect';
import { NAME, TYPES, CATEGORIES } from './constants';
import * as types from './actionTypes';
import { selectors as coreSelectors } from '../core';

const getItemsIdsByPath = (state, path) => _.get(state[NAME], path, {}).ids || [];
const getItemsCountByPath = (state, path) => _.get(state[NAME], path, {}).count || 0;
const getContentItems = state => state[NAME].items;
const getAllCategories = state => state[NAME].categories;

export const getContentByPath = createSelector(getContentItems, getItemsIdsByPath, (items, ids) => _.filter(_.map(ids, id => items[id]), _.identity));

export const getContentCountByPath = createSelector(getItemsCountByPath, count => count);

const getCarouselContent = createSelector(getContentByPath, items => items.slice(0, 10));

export const getAllContent = state => getCarouselContent(state, [CATEGORIES.ALL, TYPES.ALL]);
export const getAllContentCount = state => getContentCountByPath(state, [CATEGORIES.ALL, TYPES.ALL]);
export const getPromotedContent = state => {
    const contents = {
        [CATEGORIES.ALL]: getCarouselContent(state, [CATEGORIES.ALL, TYPES.PROMOTED])
    };
    _.forEach(state[NAME].categories, category => {
        contents[category.id] = getCarouselContent(state, [category.id, TYPES.PROMOTED]);
    });
    return contents;
};

const carouselItemsCount = 9;

const groupItemsForCarousel = items => _.chunk(items, 3);

export const getPromotedWithRecentContent = createSelector(getPromotedContent, getAllContent, (promoted, recent) => {
    const allPromoted = promoted[CATEGORIES.ALL];
    const promotedWithRecent = _.uniqBy([...allPromoted, ...recent], item => item.id);

    return allPromoted.length >= carouselItemsCount
        ? allPromoted
        : promotedWithRecent;
});

export const getPromotedWithRecentContentLimited = createSelector(getPromotedWithRecentContent, content => _.slice(content, 0, carouselItemsCount));
export const getPromotedContentCarousel = createSelector(getPromotedWithRecentContentLimited, groupItemsForCarousel);

export const getPromotedContentCount = state => {
    const contentsCount = {
        [CATEGORIES.ALL]: getContentCountByPath(state, [CATEGORIES.ALL, TYPES.PROMOTED])
    };
    _.forEach(state[NAME].categories, category => {
        contentsCount[category.id] = getContentCountByPath(state, [category.id, TYPES.PROMOTED]);
    });
    return contentsCount;
};
export const getRecommendedContent = state => {
    const contents = {
        [CATEGORIES.ALL]: getCarouselContent(state, [CATEGORIES.ALL, TYPES.RECOMMENDED])
    };
    _.forEach(state[NAME].categories, category => {
        contents[category.id] = getCarouselContent(state, [category.id, TYPES.RECOMMENDED]);
    });
    return contents.all;
};
export const getRecommendedContentCountLimited = createSelector(getRecommendedContent, content => _.slice(content, 0, carouselItemsCount));

export const getRecommendedContentCarousel = createSelector(getRecommendedContentCountLimited, groupItemsForCarousel);

export const getRecommendedContentCount = state => {
    const contentsCount = {
        [CATEGORIES.ALL]: getContentCountByPath(state, [CATEGORIES.ALL, TYPES.RECOMMENDED])
    };
    _.forEach(state[NAME].categories, category => {
        contentsCount[category.id] = getContentCountByPath(state, [category.id, TYPES.RECOMMENDED]);
    });
    return contentsCount;
};

export const getContentItem = (state, id) => state[NAME].items[id];
const getContentIdByLinkId = (state, linkId) => _.findKey(state[NAME].items, { link_id: linkId.toString() });
export const getContentItemByLinkId = createSelector(getContentItems, getContentIdByLinkId, (items, id) => items[id]);

export const getCategories = createSelector(getAllCategories, categories => _.map(categories, ({ name: label, id }) => ({ id, label })));

export const getRefreshTime = state => state[NAME].refreshTime || 0;

export const isLoadingContent = state => coreSelectors.isLoading(state, types.GET_CONTENT.NAME);
export const isLoadingContentItem = state => coreSelectors.isLoading(state, types.GET_CONTENT_ITEM.NAME);
export const isSearchingContent = state => coreSelectors.isLoading(state, types.SEARCH_CONTENT.NAME);

export const contentItemIsCMS = createSelector(getContentItem, item => _.get(item, 'external_id', '').length > 0);

export const getContentTags = state => state[NAME].tags;

export const hasVideoContent = state => !_.isEmpty(_.get(state[NAME], 'all.video'));
export const hasExternalContent = state => !_.isEmpty(_.get(state[NAME], 'all.more'));

export const isLoadingContentCount = state => state[NAME].isLoadingContentCount;
export const isLoadingPromotedContent = state => coreSelectors.isLoading(state, types.GET_CONTENT_PROMOTED.NAME);