import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

import { components as Core, ROUTES, CAROUSEL_TYPES, animations } from '../../../core';
import { baseColors, importantClass, spacing, windowSize } from '../../../../styles';
import ContentList from '../ContentList';
import WithContentBase from './ContentBase';
import CarouselItem from '../CarouselItem';
import ContentSearch from '../ContentSearch';
import { CATEGORIES } from '../../constants';

const IMAGE_HEIGHT = 240;
const WEB_HEADER_HEIGHT = 120;
const WINDOW_WIDTH = windowSize.width;

class Content extends PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
        searchCallback: PropTypes.func.isRequired,
        searchText: PropTypes.string,
        isLoading: PropTypes.bool.isRequired,
        title: PropTypes.string.isRequired,
        tabs: PropTypes.array.isRequired,
        onTabPress: PropTypes.func.isRequired,
        activeTab: PropTypes.object.isRequired,
        activeCategory: PropTypes.string.isRequired,
        libraryHeaderDescriptionText: PropTypes.string.isRequired,
        promotedContentToday: PropTypes.object.isRequired,
        promotedContentCount: PropTypes.object.isRequired,
        isRecommendedTab: PropTypes.bool.isRequired,
        selectedTab: PropTypes.object.isRequired,
        categoriesOrTags: PropTypes.array.isRequired,
        isLiveBetter: PropTypes.bool.isRequired,
        scrollToContent: PropTypes.bool,
        hasVideoContent: PropTypes.bool,
        isVideoTab: PropTypes.bool.isRequired,
        isLoadingPromotedContent: PropTypes.bool.isRequired,
        searchTab: PropTypes.string.isRequired
    };

    static defaultProps = {
        searchText: '',
        scrollToContent: false,
        hasVideoContent: false
    };

    componentDidMount() {
        setTimeout(() => this.props.scrollToContent && animations.scrollToSimple(this.userItemNode.getBoundingClientRect().y - WEB_HEADER_HEIGHT), 500);
    }

    filterTagsRef = ref => this.userItemNode = ref;

    goToByType = type => {
        const { activeCategory } = this.props;
        this.props.history.push(ROUTES.contentList(activeCategory, type));
    };

    renderCarouselItem = item => (
        <CarouselItem
            item={item}
            key={item.id}
            isGradientFadedImage={true}
            categoryWrapper={false}
            imageHeight={380}
            imageWidth={980}
            innerContainerStyle={styles.innerContainer}
            isCarouselItem={false}
            customContentClassName={css(styles.customContent)}
            isLibraryPage={true}
            isDisabledVideoControlContent={true}
            isContentHeroCarusel={true}
        />
    );

    get carouselsComponent() {
        const { promotedContentCount, promotedContentToday, isLoadingPromotedContent } = this.props;
        return (
            <React.Fragment>
                <Core.Layout.WiderContainer>
                    <Core.Carousel
                        isLoading={isLoadingPromotedContent}
                        type={CAROUSEL_TYPES.multipleForLibrary}
                        count={promotedContentToday.all.length}
                        renderItem={this.renderCarouselItem}
                        separator={true}
                        isLibraryPage={true}
                        itemsLength={promotedContentCount}
                        arrowsColor={baseColors.white}
                        dotsClassName={styles.dots}>
                        {_.map(promotedContentToday.all, this.renderCarouselItem)}
                    </Core.Carousel>
                </Core.Layout.WiderContainer>
            </React.Fragment>
        );
    }

    onSearchRef = r => this.searchRef = r;

    get searchComponent() {
        const { searchCallback, onFocus, onBlur, searchTab } = this.props;
        return (
            <ContentSearch
                onRef={this.onSearchRef}
                category={CATEGORIES.SEARCH}
                callback={searchCallback}
                isLibraryPage={true}
                onFocus={onFocus}
                onBlur={onBlur}
                type={searchTab}
            />
        );
    }

    get headerComponent() {
        const { title, libraryHeaderDescriptionText } = this.props;
        return (
            <React.Fragment>
                <Core.EntityDetailsHeader
                    hasBackButton={true}
                    title={title}
                />
                <Core.SubHeader
                    title={title}
                    noTopPadding={true}
                    noLeftPadding={true}
                    libraryHeaderDescriptionText={libraryHeaderDescriptionText}
                    className={css(styles.searchBar)}
                />
            </React.Fragment>
        );
    }

    get contentList() {
        const { searchText, isLoading, activeCategory, isLiveBetter, isMoreTab } = this.props;
        return (
            <ContentList
                isPage={false}
                search={searchText}
                isLoading={isLoading}
                category={activeCategory}
                isLiveBetter={isLiveBetter}
                listContentClassName={styles.listContent}
                isVideoTab={this.props.isVideoTab}
                type={isMoreTab && CATEGORIES.MORE}
                isMoreTab={isMoreTab}
            />
        );
    }

    onTabPress = (tab, isCategory = false) => {
        this.props.onTabPress(tab, isCategory);
        _.has(this.searchRef, 'clearSearch') && this.searchRef.clearSearch();
    };

    onCategoryChange = (event, value) => {
        const category = _.find(this.props.categoriesOrTags, { id: value });
        this.onTabPress(category, true);
    };

    get categoriesComponent() {
        const { categoriesOrTags, activeTab, isRecommendedTab, isVideoTab } = this.props;
        return isRecommendedTab || isVideoTab ? (
            <div className={css(styles.categoriesContainer)} ref={this.filterTagsRef}>
                <Core.ScrollableTabs
                    isCategory={true}
                    isTabsWrap={true}
                    isVertical={false}
                    activeTab={activeTab}
                    tabs={categoriesOrTags}
                    onChange={this.onCategoryChange}
                />
            </div>
        ) : null;
    }

    render() {
        const { tabs, selectedTab, isSearchInputFocused, hasVideoContent } = this.props;
        return (
            <Core.Layout.WiderContainer>
                {this.headerComponent}
                {this.carouselsComponent}
                <Core.TabsWithContent
                    tabs={tabs}
                    onChange={this.onTabPress}
                    activeTab={selectedTab}
                    tabClassName={css(styles.tabs)}
                    isStickyUnderNavBar={true}
                    searchComponent={this.searchComponent}
                    tabsContainerClassName={styles.tabsContainer}
                    isHorizontal={true}
                    isLibraryPage={true}
                    isSearchInputFocused={isSearchInputFocused}
                    hasVideoContent={hasVideoContent}
                />
                {this.categoriesComponent}
                {this.contentList}
            </Core.Layout.WiderContainer>
        );
    }
}

export default withRouter(WithContentBase(Content));

const styles = StyleSheet.create({
    tabs: {
        marginBottom: spacing.s5
    },
    searchBar: {
        paddingTop: spacing.s3
    },
    innerContainer: {
        width: WINDOW_WIDTH,
        height: IMAGE_HEIGHT
    },
    dots: importantClass({
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        borderRadius: spacing.s1,
        width: 'fit-content',
        bottom: spacing.s1,
        left: '50%',
        transform: 'translate(-50%, -50%)'
    }),
    tabsContainer: {
        display: 'flex',
        justifyContent: 'centre',
        boxShadow: 'inset 0px -1px 0px rgba(213, 213, 213, 1)'
    },
    customContent: importantClass({
        position: 'absolute',
        bottom: spacing.s19,
        paddingLeft: spacing.s4,
        paddingRight: 0
    }),
    listContent: {
        marginTop: spacing.s6
    },
    categoriesContainer: {
        marginTop: spacing.s3
    }
});
