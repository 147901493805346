import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';

import { components as Core } from '../../../core';
import { spacing, baseColors, layoutStyle, importantStyles, modalStyle } from '../../../../styles';
import WithCongratsFirstTrackChallengeBase, { styles as baseStyles, CHECK_ICON_SIZE, INFO_ICON_SIZE,
    ANIMATION_DURATION, SHADOW } from './CongratsFirstTrackChallengeBase';

class CongratsFirstTrackChallenge extends PureComponent {
    static propTypes = {
        close: PropTypes.func.isRequired,
        next: PropTypes.func.isRequired,
        gotIt: PropTypes.func.isRequired,
        labels: PropTypes.object.isRequired,
        screenIndex: PropTypes.number.isRequired,
        pointsEarned: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    };

    get screenOne() {
        const { pointsEarned, next, labels: { titleFist, subtitleFirst, youEarnedText,
            nextButtonLabel } } = this.props;
        return (
            <>
                <div className={css(styles.body)}>
                    <p className={css(styles.titleText)}>{titleFist}</p>
                    <p className={css(styles.subtitleText)}>{subtitleFirst}</p>
                    <div className={css(styles.pointsWrapper)}>
                        <Core.Icon
                            type="fa"
                            name="check"
                            size={CHECK_ICON_SIZE}
                            color={baseColors.primary}
                        />
                        <p className={css(styles.subtitleText)}>{youEarnedText}</p>
                        <p className={css(styles.pointsText)}>{pointsEarned}</p>
                    </div>
                </div>
                {this.getFooterButton(nextButtonLabel, next)}
            </>
        );
    }

    get screenSecond() {
        const { labels: { titleSecond, cannotTrackText, nothingTrackedText,
            trackedSomethingText, reachedGoalText, closeButtonLabel } } = this.props;
        return (
            <>
                <div className={css(styles.body, styles.bodySecond)}>
                    <p className={css(styles.titleText)}>{titleSecond}</p>
                    <div className={css(styles.squareContainerWeb)}>
                        {this.getItemIcon('lock', baseColors.grey50, styles.disabled)}
                        {this.getItemDescriptionText(cannotTrackText)}
                    </div>
                    <div className={css(styles.squareContainerWeb)}>
                        {this.getItemIcon('plus', baseColors.secondary, styles.trackable)}
                        {this.getItemDescriptionText(nothingTrackedText)}
                    </div>
                    <div className={css(styles.squareContainerWeb)}>
                        {this.getItemIcon('check', baseColors.secondary, styles.inProgress)}
                        {this.getItemDescriptionText(trackedSomethingText)}
                    </div>
                    <div className={css(styles.squareContainerWeb)}>
                        {this.getItemIcon('check', baseColors.white, styles.completed)}
                        {this.getItemDescriptionText(reachedGoalText)}
                    </div>
                </div>
                {this.getFooterButton(closeButtonLabel, this.gotIt)}
            </>
        );
    }

    getFooterButton = (label, action) => (
        <Core.Button
            type="text"
            fullWidth={true}
            onPress={action}
            disableRipple={true}
            disableMainContainer={true}
            className={css(styles.buttonText, styles.button, styles.buttonBorder)}>
            {label}
        </Core.Button>
    );

    getItemIcon = (name, color, className) => (
        <div className={css(styles.defaultSquare, className)}>
            <Core.Icon
                type="fa"
                name={name}
                color={color}
                size={spacing.s4}
            />
        </div>
    );

    getItemDescriptionText = text => (
        <div className={css(layoutStyle.flex1)}>
            <p className={css(styles.descriptionText)}>{text}</p>
        </div>
    );

    gotIt = () => {
        this.props.gotIt();
        this.props.close();
    };

    render() {
        return (
            <div className={css(layoutStyle.flex1, layoutStyle.flexAlignCenter, styles.mainContainer)}>
                <Core.Animated.Zoom duration={ANIMATION_DURATION}>
                    <div className={css(styles.animatedView, styles.iconCircle)}>
                        <Core.Icon
                            name="question"
                            type="fa"
                            size={INFO_ICON_SIZE}
                            color={baseColors.white}
                        />
                    </div>
                </Core.Animated.Zoom>
                <div className={css(styles.container)}>
                    <div className={css(styles.contentWrapper)}>
                        {this.props.screenIndex === 0 ? this.screenOne : this.screenSecond}
                    </div>
                </div>
            </div>
        );
    }
}

export default WithCongratsFirstTrackChallengeBase(CongratsFirstTrackChallenge);

const styles = StyleSheet.create(importantStyles({
    ...baseStyles,
    squareContainerWeb: {
        flexDirection: 'row',
        alignItems: 'center',
        display: 'flex',
        width: '100%',
        marginBottom: spacing.s5
    },
    button: {
        ...baseStyles.button,
        ...modalStyle.bottomButton,
        '&:hover': {
            ...modalStyle.bottomButton,
            backgroundColor: baseColors.grey90,
        },
        '&:after': {
            borderRadius: 0,
        },
    },
    buttonBorder: {
        borderTop: `1px solid ${baseColors.grey80}`,
    },
    mainContainer: {
        ...baseStyles.mainContainer,
        shadowRadius: spacing.s2,
        shadowOpacity: SHADOW.opacity,
        shadowOffset: { width: 0, height: 0 },
        shadowColor: baseColors.black,
        boxShadow: `0px 30px 60px -30px rgba(0, 0, 0, ${SHADOW.opacity})`,
    },
    bodySecond: {
        marginBottom: spacing.s7,
    },
}));
