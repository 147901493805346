import _ from 'lodash';
import I18n from '../../../i18n/i18n';

const MAX_INPUT_NUMBER = 99999;
const NEGATIVE_MESSAGE = () => I18n.t('createEvent.errorMessage.negative');
const LIMIT_MESSAGE = () => I18n.t('createEvent.errorMessage.limit', { maxValue: MAX_INPUT_NUMBER });

export function checkForErrors({ isUserRole, name, startDate, startTime, endTime, maxParticipants, points, cost, isSelectedLocations, locations }) {
    const errors = {};
    if (!name) {
        _.assign(errors, { errorName: I18n.t('errorEventNameMessage') });
    }
    if (!startDate) {
        _.assign(errors, { errorStartDate: I18n.t('errorEventStartDateMessage') });
    }
    if (!startTime) {
        _.assign(errors, { errorStartTime: I18n.t('errorEventStartTimeMessage') });
    }
    if (!endTime) {
        _.assign(errors, { errorEndTime: I18n.t('errorEventEndTimeMessage') });
    }
    if (!isUserRole) {
        if (!_.isEmpty(maxParticipants)) {
            if (parseInt(maxParticipants) < 0) {
                _.assign(errors, { errorMaxParticipants: NEGATIVE_MESSAGE() });
            }
            else if (parseInt(maxParticipants) > MAX_INPUT_NUMBER) {
                _.assign(errors, { errorMaxParticipants: LIMIT_MESSAGE() });
            }
        }

        if (parseInt(points) < 0) {
            _.assign(errors, { errorPoints: NEGATIVE_MESSAGE() });
        }
        else if (parseInt(points) > MAX_INPUT_NUMBER) {
            _.assign(errors, { errorPoints: LIMIT_MESSAGE() });
        }

        if (parseInt(cost) < 0) {
            _.assign(errors, { errorCost: NEGATIVE_MESSAGE() });
        }
        else if (parseInt(cost) > MAX_INPUT_NUMBER) {
            _.assign(errors, { errorCost: LIMIT_MESSAGE() });
        }

        if (isSelectedLocations && !locations.length) {
            _.assign(errors, { errorLocations: I18n.t('createEvent.errorMessage.selectedLocations') });
        }
    }

    return errors;
}