import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';

import { EVENT_ENTITY_TYPES } from '../../constants';
import Events from '../Events';
import EventsList from '../EventsList';
import WithAllTabBase from './AllTabBase';
import { components as Core } from '../../../core';
import { styles as baseStyles } from '../../styles';

class AllTab extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool,
        i18n: PropTypes.object.isRequired,
        onRef: PropTypes.func,
        allEventsLength: PropTypes.number,
        exploreLoadingStatus: PropTypes.object,
    };

    static defaultProps = {
        onRef: () => {},
        isLoading: false,
        allEventsLength: 0,
        exploreLoadingStatus: {}
    };

    onRef = () => {
        if (this.props.onRef) this.props.onRef();
    };

    render() {
        const { allEventsLength, isLoading, i18n } = this.props;
        return (
            <React.Fragment>
                {allEventsLength === 0 && !isLoading ?
                    <div className={css(styles.placeholder)}>
                        <span className={css(styles.placeholderTitle)}>{i18n.t('no_events')}</span>
                        <Core.NotAvailableContent text={i18n.t('no_all_events_placeholder')} />
                    </div> : null
                }
                <Events
                    title={this.props.i18n.t('invitedCommunities')}
                    type={EVENT_ENTITY_TYPES.INVITED}
                    isLoading={{ loading: this.props.isLoading, showSpinner: true }}
                    exploreLoadingStatus={this.props.exploreLoadingStatus}
                />
                <Events
                    title={this.props.i18n.t('recommended')}
                    type={EVENT_ENTITY_TYPES.RECOMMENDED}
                    isLoading={{ loading: this.props.isLoading, showSpinner: false }}
                    exploreLoadingStatus={this.props.exploreLoadingStatus}
                />
                <EventsList
                    type={EVENT_ENTITY_TYPES.ALL_ITEMS}
                    onRef={this.onRef}
                    isLoading={this.props.isLoading}
                    isPage={false}
                    twoColumns={false}
                />
            </React.Fragment>
        );
    }
}

const styles = StyleSheet.create({ ...baseStyles });

export default WithAllTabBase(AllTab);
