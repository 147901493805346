import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { selectors as coreSelectors, translate } from '../../../core';
import { spacing } from '../../../../styles';
import * as selectors from '../../selectors';
import * as actions from '../../actions';
import { PRIVACY_SLUGS_CONFIG, PRIVACY_ON_OFF_ICONS } from '../../constants';

export default function WithPrivacySectionBase(WrappedComponent) {
    class PrivacySectionBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            slug: PropTypes.string.isRequired,
            isEnhancedPrivacyInformationEnabled: PropTypes.bool.isRequired,
            isHiddenPrivacyInformationText: PropTypes.bool.isRequired,
            privacyIcon: PropTypes.string,
            privacyMessage: PropTypes.string,
            isPrivacyVisible: PropTypes.bool.isRequired,
            isDisclaimerOnly: PropTypes.bool,
        };

        static defaultProps = {
            privacyIcon: undefined,
            privacyMessage: undefined,
            isDisclaimerOnly: false
        };

        onHidePrivacyInformationText = () => {
            this.props.actions.hidePrivacyInformationText(this.props.slug);
        };

        get config() {
            return PRIVACY_SLUGS_CONFIG[this.props.slug] || {};
        }

        get message() {
            return this.props.i18n.t(this.config.messageOn);
        }

        get privacyIcon() {
            // If a specific icon is passed to the component, use that.
            if (this.props.privacyIcon) {
                return this.props.privacyIcon;
            }

            return this.props.isPrivacyVisible ? PRIVACY_ON_OFF_ICONS.OFF : PRIVACY_ON_OFF_ICONS.ON;
        }

        get privacyMessage() {
            return this.props.isPrivacyVisible ? this.props.i18n.t(this.config.messageOn) : this.props.i18n.t(this.config.messageOff);
        }

        get managePrivacySettingUrlTitle() {
            return this.props.i18n.t('managePrivacySettingUrlTitle');
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    onHidePrivacyInformationText={this.onHidePrivacyInformationText}
                    message={this.message}
                    privacyIcon={this.privacyIcon}
                    privacyMessage={this.privacyMessage}
                    managePrivacySettingUrlTitle={this.managePrivacySettingUrlTitle}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => ({
        isPrivacyVisible: selectors.isPrivacyVisibleInEntity(state, ownProps.slug),
        isPrivacyNotExisting: selectors.isPrivacyNotExisting(state, ownProps.slug),
        isEnhancedPrivacyInformationEnabled: coreSelectors.isEnhancedPrivacyInformationEnabled(state),
        isHiddenPrivacyInformationText: !!selectors.isHiddenPrivacyInformationText(state, ownProps.slug)
    });

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(PrivacySectionBase));
}

export const styles = {
    disclaimerContainer: {
        borderRadius: spacing.s1
    },
    containerWithPadding: {
        borderRadius: spacing.s1,
        padding: spacing.s3
    },
};
