import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { StyleSheet, css } from 'aphrodite-jss';
import Joyride, { EVENTS, STATUS } from 'react-joyride';
import { isIE } from 'react-device-detect';
import disableScroll from '../../services/DisableScrollService';
import { components as Core, Modal } from '../../../core';
import { baseColors, importantClass } from '../../../../styles';
import TourStep from '../TourStep';
import ToursInfoModal from '../ToursInfoModal';
import SkipTourModal from '../SkipTourModal';
import { TOURS } from '../../constants';
import WithTourLayoutBase, { styles as baseStyles } from './TourLayoutBase';

class TourLayout extends Component {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        tour: PropTypes.func.isRequired,
        type: PropTypes.oneOf([TOURS.PRODUCT_TOUR]).isRequired,
        close: PropTypes.func.isRequired,
        setPosition: PropTypes.func.isRequired,
        initialScreen: PropTypes.object.isRequired,
        finishScreen: PropTypes.object.isRequired,
        onNext: PropTypes.func.isRequired,
        onBack: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        step: PropTypes.object.isRequired,
        position: PropTypes.object.isRequired,
        currentStep: PropTypes.number.isRequired,
        steps: PropTypes.array.isRequired,
        onCancelSkipTour: PropTypes.func.isRequired,
        skipModalVisible: PropTypes.bool,
        isCalculatingPosition: PropTypes.bool,
        hasInitialScreen: PropTypes.bool,
        hasFinishScreen: PropTypes.bool,
        amountSlides: PropTypes.number.isRequired,
        toolTipMethods: PropTypes.object.isRequired,
        tourData: PropTypes.object.isRequired,
        onFinishTour: PropTypes.func
    };

    static defaultProps = {
        skipModalVisible: false,
        isCalculatingPosition: false,
        hasInitialScreen: false,
        hasFinishScreen: false,
        onFinishTour: null
    };

    state = { run: false };

    componentDidMount() {
        this.setState({ run: true });
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.status === STATUS.RUNNING && this.state.status === STATUS.FINISHED && this.props.hasFinishScreen) {
            this.closeModal = Modal.open(
                () => this.renderFinishScreen,
                { ...this.finishScreenProps },
                {
                    isContainer: false,
                    isFullHeight: false,
                    cancelable: false,
                    PaperProps: {
                        classes: {
                            root: css(styles.rootModalStyle)
                        }
                    },
                }
            );
        }
    }

    onFinishTour = () => {
        this.props.onCancelSkipTour();
        this.setState({ run: false });
        disableScroll.off();
    }

    openSkipModal = () => {
        this.props.onClose();
    };

    cancelSkipModal = () => {
        this.props.onCancelSkipTour();
        if (this.state.index === 4 || this.state.index === 5) disableScroll.on();
    };

    renderSkipButton = () => {
        return (
            <Core.Button onPress={this.openSkipModal} type='text' className={css(styles.skipButton)}>
                {this.props.i18n.t('skip')}
            </Core.Button>
        );
    };

    get initialScreenProps() {
        return {
            ...this.props.initialScreen,
            title: undefined,
        };
    }

    get finishScreenProps() {
        return {
            ...this.props.finishScreen,
            onButtonPress: this.closeFinishScreen,
        };
    }

    get renderInitialScreen() {
        return (
            <ToursInfoModal
                {...this.props.initialScreen}
                footerComponent={this.renderSkipButton}
                onButtonPress={this.state.next}
                iconClassName={isIE ? css(styles.ieTopIcon) : null}
            />
        );
    }

    closeFinishScreen = () => {
        this.props.onFinishTour();
        this.closeModal();
    };

    get renderFinishScreen() {
        return (
            <Core.InfoModal
                {...this.props.finishScreen}
                onButtonPress={this.closeFinishScreen}
            />
        );
    }

    renderStep = step => {
        return (
            <TourStep
                {...step}
                {...this.props.toolTipMethods}
                openSkipModal={this.openSkipModal}
                slidesCount={this.slidesCount}
            />
        );
    }

    get renderSkipModal() {
        return <SkipTourModal
            onCancel={this.cancelSkipModal}
            onSkip={this.props.onFinishTour}
        />;
    }

    get steps() {
        const steps = _.get(this.props.tourData, 'steps');
        return _.map(steps, step => ({ ...step, ...this.getStepAdditionals(step) }));
    }

    get initialStep() {
        return {
            ...this.initialScreenProps,
            ...this.getAdditionalsForInfoModals(),
            content: this.renderInitialScreen
        };
    }

    get stepsWithInitial() {
        return this.props.hasInitialScreen ? [this.initialStep, ...this.steps] : this.steps;
    }

    get toolTipStyles() {
        return this.props.skipModalVisible ? 'none' : 'block';
    }

    get arrowStyles() {
        return this.props.skipModalVisible ? 'transparent' : baseColors.white;
    }

    getStepAdditionals = step => {
        return {
            content: this.renderStep(step),
            disableBeacon: true,
            styles: {
                options: {
                    zIndex: 1101,
                    display: this.toolTipStyles
                },
                buttonClose: {
                    display: 'none'
                },
            },
            isFixed: true
        };
    }

    getAdditionalsForInfoModals() {
        return {
            disableBeacon: true,
            placement: 'center',
            styles: {
                options: {
                    zIndex: 1101,
                },
                buttonClose: {
                    display: 'none'
                },
                buttonNext: {
                    display: 'none'
                },
            },
        };
    }

    callback = state => {
        const { index, status, type } = state;
        if (status === STATUS.FINISHED && !this.props.hasFinishScreen) this.props.onFinishTour();
        if (status === STATUS.RUNNING) {
            if (type === EVENTS.STEP_BEFORE && (index === 4 || index === 5)) {
                disableScroll.on();
            } else if (type === EVENTS.STEP_AFTER && (index === 4 || index === 5)) disableScroll.off();
        }
        else disableScroll.off();
        this.setState(state);
    };

    getHelpers = helpers => this.setState(helpers);

    get slidesCount() {
        const { size, index } = this.state;
        if (!size || !index) return null;
        const sizeName = this.props.hasInitialScreen ? size - 1 : size;
        return `${index}/${sizeName}`;
    }

    render() {
        return (
            <React.Fragment>
                {this.props.skipModalVisible ? this.renderSkipModal : null}
                <Joyride
                    steps={this.stepsWithInitial}
                    run={this.state.run}
                    continuous={true}
                    locale={{
                        back: this.props.i18n.t('back'),
                        next: this.props.i18n.t('next'),
                        last: this.props.i18n.t('finish'),
                        close: this.props.i18n.t('close'),
                        skip: this.props.i18n.t('skip')
                    }}
                    callback={this.callback}
                    getHelpers={this.getHelpers}
                    styles={{
                        options: { primaryColor: baseColors.secondary, arrowColor: this.arrowStyles  },
                        tooltip: { display: this.toolTipStyles },
                    }}
                    disableScrolling={true}
                    spotlightPadding={0}
                    floaterProps={{ disableFlip: true }}
                    disableOverlayClose={true}
                />
            </React.Fragment>
        );
    }
}

export default WithTourLayoutBase(TourLayout);

const styles = StyleSheet.create({
    ...baseStyles,
    rootModalStyle: importantClass({
        background: 'transparent',
        boxShadow: 'none'
    }),
    ieTopIcon: {
        transform: 'translateX(-50%)'
    }
});
