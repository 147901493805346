import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { translate, entitiesHelper } from '../../../core';
import * as eventsActions from '../../actions';
import * as selectors from '../../selectors';

export default function WithCommunityMembersBase(WrappedComponent) {
    class CommunityMembersBase extends PureComponent {
        static propTypes = {
            communityId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            members: PropTypes.array,
            actions: PropTypes.object.isRequired,
            i18n: PropTypes.object.isRequired,
            isLoading: PropTypes.bool,
            membersCount: PropTypes.number.isRequired,
            privateMembersCount: PropTypes.number.isRequired
        };

        static defaultProps = {
            members: [],
            isLoading: false
        };

        componentDidMount() {
            this.props.actions.getSingleCommunity(this.props.communityId);
            this.props.actions.getCommunityMembers(this.props.communityId);
        }

        get hasMore() {
            return this.props.members.length < (this.props.membersCount - this.props.privateMembersCount);
        }

        loadMore = () => {
            if (!this.props.isLoading && this.hasMore) {
                this.props.actions.getCommunityMembers(this.props.communityId, { start: this.props.members.length });
            }
        };

        getDescription = item => entitiesHelper.isPrivate(item) ? item.description : undefined;

        get title() { return this.props.i18n.t('members'); }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    loadMore={this.loadMore}
                    isPrivate={entitiesHelper.isPrivate}
                    getDescription={this.getDescription}
                    hasMore={this.hasMore}
                    title={this.title}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        const communityId = ownProps.communityId || _.get(ownProps, 'match.params.id') || _.get(ownProps, 'route.params.communityId') ;
        return {
            members: selectors.getCommunityMembersAndPrivateSummary(state, communityId),
            membersCount: selectors.getCommunityMembersCount(state, communityId),
            privateMembersCount: selectors.getCommunityPrivateMembersCount(state, communityId),
            isLoading: selectors.isLoadingCommunityMembers(state) || selectors.communityLoading(state),
            communityId
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(eventsActions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(CommunityMembersBase));
}