import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { translate, pointsHelper, selectors as coreSelectors } from '../../../core';
import { appFonts, baseColors, spacing } from '../../../../styles';
import { isShowingOnboarding } from '../../selectors';
import { selectors as rewardsSelectors } from '../../../rewards';
import { APPS_AND_DEVICES } from '../../constants';

export default function WithOnboardingInfoScreenBase(WrappedComponent) {
    class OnboardingInfoScreenBase extends PureComponent {
        static propTypes = {
            customButtons: PropTypes.func,
            customBody: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
            title: PropTypes.string,
            bodyText: PropTypes.string,
            icon: PropTypes.string,
            screen: PropTypes.object,
            customPointsUnit: PropTypes.string.isRequired,
            isLoading: PropTypes.bool,
            achievements: PropTypes.array,
            i18n: PropTypes.object.isRequired,
            isLiveBetter: PropTypes.bool
        };

        static defaultProps = {
            title: null,
            screen: {},
            bodyText: null,
            icon: null,
            customButtons: null,
            customBody: null,
            isLoading: false,
            achievements: [],
            isLiveBetter: false
        };

        get bodyText() {
            return this.props.bodyText ? this.props.bodyText : _.get(this.props.screen, 'main_description');
        }

        get title() {
            return this.props.title ? this.props.title : _.get(this.props.screen, 'group_name');
        }

        get icon() {
            const screen = _.get(this.props, 'screen.group_slug');
            if (screen === APPS_AND_DEVICES && !this.props.isLiveBetter) {
                return undefined;
            }

            return this.props.icon ? this.props.icon : _.get(this.props.screen, 'icon_name');
        }

        get timeToComplete() {
            const { screen } = this.props;
            return _.get(screen, 'mins_to_complete') ? screen.mins_to_complete.toString() : '1';
        }

        get points() {
            const { screen, customPointsUnit } = this.props;
            const { points, unit } = pointsHelper.getPointsUnits(screen, customPointsUnit);
            return pointsHelper.formatPoints(points, unit);
        }

        get isLoading() {
            return this.props.isLoading && !this.props.achievements.length;
        }

        get earnText() {
            return this.props.i18n.t('earn');
        }

        get timeText() {
            return `${this.timeToComplete} ${this.props.i18n.t('smallMin')}`;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    bodyText={this.bodyText}
                    points={this.points}
                    title={this.title}
                    icon={this.icon}
                    isLoading={this.isLoading}
                    timeText={this.timeText}
                    earnText={this.earnText}
                />
            );
        }
    }

    const mapStateToProps = state => ({
        customPointsUnit: coreSelectors.getCustomPointsUnit(state),
        isOnboarding: isShowingOnboarding(state),
        isLoading: rewardsSelectors.isLoadingAchievements(state),
        achievements: rewardsSelectors.getAllRewardsAchievements(state),
        isLiveBetter: coreSelectors.isLiveBetter(state)
    });
    return connect(mapStateToProps)(translate()(OnboardingInfoScreenBase));
}

export const styles = {
    header: {
        ...appFonts.lgMedium,
        marginBottom: spacing.s2
    },
    description: {
        ...appFonts.mdRegular,
        textAlign: 'center',
        marginBottom: spacing.s1
    },
    descriptionContainer: {
        flex: 0,
        paddingTop: spacing.s1
    },
    body: {
        flex: 1,
        marginBottom: spacing.s3,
        alignItems: 'center'
    },
    earnSection: {
        flex: 0,
        alignItems: 'center',
        paddingTop: spacing.s2,
    },
    earnText: {
        ...appFonts.lgRegular,
        marginRight: spacing.s0
    },
    points: {
        ...appFonts.lgRegular,
        color: baseColors.primary
    },
    time: {
        ...appFonts.smRegular,
        marginLeft: spacing.s0,
        color: baseColors.grey40
    },
    timeSection: {
        paddingTop: spacing.s3
    }
};