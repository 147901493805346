import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import { StyleSheet, css } from 'aphrodite-jss';
import EntityDetailsHeader from './EntityDetailsHeader';
import TabsWithContent from '../TabsWithContent';
import { WiderContainer } from '../Layout';
import { importantStyles } from '../../../../styles';

class EntityDetailsLayout extends React.PureComponent {
    static propTypes = {
        tabsProps: PropTypes.shape({
            headerComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
            isHeaderSticky: PropTypes.bool
        }).isRequired,
        rightNavIconProps: PropTypes.shape({

        }),
        backUrl: PropTypes.string,
        children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]).isRequired,
        title: PropTypes.string,
        isOnboarding: PropTypes.bool,
        challengeDetailsNavbarProps: PropTypes.object,
    };

    static defaultProps = {
        backUrl: undefined,
        rightNavIconProps: null,
        title: undefined,
        isOnboarding: false,
        challengeDetailsNavbarProps: {},
    };

    get entityDetailsHeader() {
        const { backUrl, rightNavIconProps, title, isOnboarding, challengeDetailsNavbarProps } = this.props;
        return (
            <EntityDetailsHeader
                hasBackButton={true}
                backUrl={backUrl}
                rightNavIconProps={rightNavIconProps}
                title={title}
                isOnboarding={isOnboarding}
                {...challengeDetailsNavbarProps}
            />
        );
    }

    renderTabsHeader = () => {
        const { challengeDetailsNavbarProps: { isChallengeDetailsPage } } = this.props;
        return isChallengeDetailsPage ? (
            <AppBar
                position="sticky"
                classes={{
                    root: css(styles.appBar),
                    positionSticky: css(styles.headerPositionSticky)
                }}>
                <WiderContainer>
                    {this.entityDetailsHeader}
                </WiderContainer>
            </AppBar>
        ) : this.entityDetailsHeader;
    }



    render() {
        const { tabsProps, children } = this.props;
        return (
            <div>
                {this.renderTabsHeader()}
                <TabsWithContent {...tabsProps} isStickyUnderNavBar={true}>
                    {children}
                </TabsWithContent>
            </div>
        );
    }
}

const TOP_HEADER_OFFSET = 64;

const styles = StyleSheet.create({
    ...importantStyles({
        headerPositionSticky: {
            top: TOP_HEADER_OFFSET,
        },
        appBar: {
            boxShadow: 'none',
            backgroundColor: 'rgba(0,0,0,0.0)',
        },
    }),
});

export default EntityDetailsLayout;
