import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { translate } from '../../../../core';
import { appFonts, baseColors, spacing } from '../../../../../styles';
import * as wellbeingImages from '../../../../../image/wellbeing';

export default function WithWellbeingInfoBase(WrappedComponent) {
    class WellbeingInfoBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired
        };

        constructor(props) {
            super(props);
            const { i18n } = props;
            this.resultCategories = [
                {
                    description: i18n.t('wellbeing.description.excellent'),
                    label: i18n.t('wellbeing.label.excellent'),
                    image: wellbeingImages.excellent
                }, {
                    description: i18n.t('wellbeing.description.very_good'),
                    label: i18n.t('wellbeing.label.very_good'),
                    image: wellbeingImages.veryGood
                }, {
                    description: i18n.t('wellbeing.description.good'),
                    label: i18n.t('wellbeing.label.good'),
                    image: wellbeingImages.good
                }, {
                    description: i18n.t('wellbeing.description.fair'),
                    label: i18n.t('wellbeing.label.fair'),
                    image: wellbeingImages.fair
                }, {
                    description: i18n.t('wellbeing.description.poor'),
                    label: i18n.t('wellbeing.label.poor'),
                    image: wellbeingImages.poor
                }
            ];
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    resultCategories={this.resultCategories}
                />
            );
        }
    }

    return translate()(WellbeingInfoBase);
}

const IMAGE_SIZE = 64;
const BORDER_SIZE = 1;

export const styles = {
    headerContainer: {
        marginTop: spacing.s1,
        marginBottom: spacing.s1
    },
    bodyContainer: {
        flex: 1,
        marginLeft: spacing.s3,
        marginRight: spacing.s3,
        paddingBottom: spacing.s5
    },
    item: {
        flex: 1,
        flexDirection: 'row',
        paddingTop: spacing.s3,
        paddingBottom: spacing.s3
    },
    itemBorder: {
        borderBottomWidth: BORDER_SIZE,
        borderBottomColor: baseColors.grey80
    },
    itemImage: {
        width: IMAGE_SIZE,
        height: IMAGE_SIZE,
        marginRight: spacing.s3
    },
    itemLabel: {
        ...appFonts.lgMedium,
        marginBottom: spacing.s0
    },
    itemDescription: {
        ...appFonts.smRegular
    }
};