import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as actions from '../../actions';
import { appFonts, spacing, baseColors } from '../../../../styles/commonCSS';
import { actions as coreActions, translate } from '../../../core';
import * as selectors from '../../selectors';
import { EXTERNAL_SERVICES } from '../../constants';

export const ICON_SIZE = 100;

export default function WithCorporateAccountLinkVerifyBase(WrappedComponent) {
    class CorporateAccountLinkVerifyBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            i18n: PropTypes.object.isRequired,
            email: PropTypes.string,
            isLoading: PropTypes.bool,
            externalAccounts: PropTypes.array.isRequired,
            corporateAccountData: PropTypes.object.isRequired,
            corporateAccountEmail: PropTypes.string.isRequired,
            quit: PropTypes.func
        };

        static defaultProps = {
            isLoading: false,
            email: '',
            quit: undefined
        };

        constructor(props) {
            super(props);
            this._initActions();
        }

        _initActions = async () => {
            //await this.props.actions.updateUser({ currentRegistrationStep: USER_REGISTRATION_STEPS.verify });
            this._sendEmail();
        };

        isCorporateAccountLinked() {
            return _.includes(this.props.externalAccounts, EXTERNAL_SERVICES.corporate);
        }

        componentDidUpdate() {
            // if it's linked, implies success and that we are done
            if (this.isCorporateAccountLinked(this.props.externalAccounts)) {
                this.quit();
            }
        }

        _sendEmail = toast => {
            this.props.actions.generateCorporateAccountLinkEmail(this.props.corporateAccountData, toast);
        };

        resendEmail = () => {
            this._sendEmail({
                title: this.props.i18n.t('resendEmailTitle'),
                msg: this.props.i18n.t('resendEmailMsg', { userEmail: this.props.corporateAccountEmail })
            });
        };

        quit = () => {
            this.props.actions.handleLinking();
            this.props.quit();
        };

        checkVerification = () => {
            this.props.actions.verifyEmail(true);
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    checkVerification={this.checkVerification}
                    quit={this.quit}
                    resendEmail={this.resendEmail}
                    userEmail={this.props.corporateAccountEmail}
                />
            );
        }
    }

    function mapStateToProps(state) {
        return {
            email: selectors.getUserInfo(state).email,
            isLoading: selectors.isCheckingVerification(state),
            corporateAccountData: selectors.getCorporateAccountData(state),
            corporateAccountEmail: _.get(selectors.getCorporateAccountData(state), 'email'),
            externalAccounts: selectors.getEnabledExternalAccountNames(state)
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators({ ...actions, ...coreActions }, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(CorporateAccountLinkVerifyBase));
}

export const styles = {
    alignItemsCenter: {
        alignItems: 'center'
    },
    textCenter: {
        textAlign: 'center'
    },
    email: {
        marginTop: spacing.s4
    },
    icon: {
        marginTop: spacing.s6,
        marginBottom: spacing.s1
    },
    button: {
        ...appFonts.lgMedium,
        marginBottom: spacing.s1,
        color: baseColors.secondary
    }
};
