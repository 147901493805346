import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { components as Core } from '../../../core';
import { components as Tours } from '../../../tours';
import WithPrivacyTooltipBase, { styles as baseStyles, ICON_NAME } from './PrivacyTooltipBase';

class PrivacyTooltip extends PureComponent {
    static propTypes = {
        position: PropTypes.object.isRequired,
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
        onConfirmPrivacyChange: PropTypes.func.isRequired,
        tooltipClassName: PropTypes.object,
        arrow: PropTypes.string,
    };

    static defaultProps = {
        tooltipClassName: null,
        arrow: undefined
    };

    get disclaimer() {
        return (
            <div className={css(styles.disclaimerContainer)}>
                <Core.DisclaimerSection
                    className={css(styles.disclaimer)}
                    iconName={ICON_NAME}
                    message={this.props.message}
                />
            </div>
        );
    }

    render() {
        return (
            <Tours.ToolTipModal
                {...this.props}
                hasCloseButton={false}
                onGotIt={this.props.onConfirmPrivacyChange}
                buttonGotItContainer={styles.buttonGotItContainer}
                disclaimer={this.disclaimer}
            />
        );
    }
}

export default WithPrivacyTooltipBase(PrivacyTooltip);

export const styles = StyleSheet.create({
    ...baseStyles
});
