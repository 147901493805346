import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { translate, getUserName, entitiesHelper, MAX_COUNT_MORE } from '../../../core';
import * as selectors from '../../selectors';
import * as helper from '../../services/helper';
import * as actions from '../../actions';

export default function WithChallengeMembersBase(WrappedComponent) {
    class ChallengeMembersBase extends PureComponent {
        static propTypes = {
            challengeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            members: PropTypes.array,
            inviters: PropTypes.array,
            actions: PropTypes.object.isRequired,
            i18n: PropTypes.object.isRequired,
            isLoading: PropTypes.bool,
            isTeamChallenge: PropTypes.bool,
            isModal: PropTypes.bool,
            membersCount: PropTypes.number.isRequired,
            privateMembersCount: PropTypes.number.isRequired,
            isRegionsDepartmentsChallenge: PropTypes.bool,
            participantsCompletedPeriod: PropTypes.object,
            shouldShowInviter: PropTypes.bool,
            challenge: PropTypes.object.isRequired,
        };

        static defaultProps = {
            members: [],
            inviters: [],
            isLoading: false,
            isTeamChallenge: false,
            isModal: false,
            isRegionsDepartmentsChallenge: false,
            participantsCompletedPeriod: {},
            shouldShowInviter: false
        };

        constructor(props) {
            super(props);
            props.actions.getChallengeMembers(props.challengeId, this.isTeamRegionsDepartmens);
        }

        get isTeamRegionsDepartmens() {
            return this.props.isTeamChallenge || this.props.isRegionsDepartmentsChallenge;
        }

        get hasMore() {
            // this.props.members may contain an extra item representing all the privateMembers
            return this.props.members.length - (this.props.privateMembersCount ? 1 : 0) < (this.props.membersCount - this.props.privateMembersCount);
        }

        loadMore = () => {
            if (!this.props.isLoading && this.hasMore) {
                this.props.actions.getChallengeMembers(
                    this.props.challengeId, this.isTeamRegionsDepartmens, { start: this.props.members.length - (this.props.privateMembersCount ? 1 : 0), maxCount: MAX_COUNT_MORE });
            }
        };

        getItemId = item => this.isTeamRegionsDepartmens ? item.entityId : item.id;

        getImageUrl = item => this.isTeamRegionsDepartmens ? item.imageURL : item.imageUrl;

        getTitle = item => {
            if (entitiesHelper.isPrivate(item)) return `${item.firstName} ${item.lastName}`;
            return this.isTeamRegionsDepartmens ? item.name : getUserName(item);
        };

        getDescription = item => {
            if (entitiesHelper.isPrivate(item)) return item.description;
            return this.isTeamRegionsDepartmens
                ? _.has(item, 'groupInfo.rank') && helper.formatRank(item.groupInfo.rank)
                : `${item.challengeScore}${item.challengeResultType}`;
        };

        isInviter = item => this.props.isTeamChallenge
            ? _.get(item, 'groupInfo.invitedBy') : _.includes(this.props.inviters, Number(item.id));

        get title() {
            const { isTeamChallenge, i18n } = this.props;
            return isTeamChallenge ? i18n.t('teamsTitle') : i18n.t('participants');
        }

        get formattedMembers() {
            const { participantsCompletedPeriod: { participants }, members } = this.props;
            return (participants && participants.length) ? participants : members;
        }

        shouldShowInvitedBy = item => {
            const { challenge, isTeamChallenge } = this.props;

            if (!isTeamChallenge) return false;

            return !helper.isMember(challenge) && _.has(item.groupInfo, 'invitedBy');
        }

        getInvitedByDetails = item => {
            const { isTeamChallenge } = this.props;

            const invitedBy = _.get(item.groupInfo, 'invitedBy');

            if (!invitedBy || !isTeamChallenge) return undefined;

            return helper.inivitedByDetails(invitedBy);
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    getImageUrl={this.getImageUrl}
                    getTitle={this.getTitle}
                    getDescription={this.getDescription}
                    isInviter={this.isInviter}
                    loadMore={this.loadMore}
                    hasMore={this.hasMore}
                    getItemId={this.getItemId}
                    isPrivate={entitiesHelper.isPrivate}
                    isTeamRegionsDepartmens={this.isTeamRegionsDepartmens}
                    title={this.title}
                    formattedMembers={this.formattedMembers}
                    shouldShowInvitedBy={this.shouldShowInvitedBy}
                    getInvitedByDetails={this.getInvitedByDetails}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const challengeId = ownProps.challengeId || _.get(ownProps, 'match.params.challengeId') || _.get(ownProps, 'route.params.challengeId');
        const params = _.get(ownProps, 'route.params') || _.get(ownProps, 'location.state');
        return {
            challengeId,
            challenge: selectors.getChallenge(state, challengeId),
            members: selectors.getChallengeMembersAndPrivateSummary(state, challengeId),
            inviters: selectors.getChallengeInvitersIds(state, challengeId),
            isLoading: selectors.isLoadingChallengeMembers(state, challengeId),
            isTeamChallenge: selectors.isTeamChallenge(state, challengeId),
            isRegionsDepartmentsChallenge: selectors.isRegionsOrDepartmentsChallenge(state, challengeId),
            membersCount: selectors.getChallengeMembersCount(state, challengeId),
            privateMembersCount: selectors.getChallengePrivateMembersCount(state, challengeId),
            ...(params || {})
        };
    };

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(ChallengeMembersBase));
}
