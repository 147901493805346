import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { StyleSheet, css } from 'aphrodite-jss';
import { isIE } from 'react-device-detect';
import Icon from '../Icon';
import Avatar from '../Avatar';
import ImageUploadGeneral from '../ImageUploadGeneral';
import { baseColors, spacing, importantStyles, containerStyle } from '../../../../styles';
import WithAvatarUploadBase, { styles as baseStyles, ICON_SIZE } from './AvatarUploadBase';

class AvatarUpload extends PureComponent {
    static propTypes = {
        fileChanged: PropTypes.func.isRequired,
        title: PropTypes.string,
        picture: PropTypes.string,
        isDeleting: PropTypes.bool,
    };

    static defaultProps = {
        title: '',
        picture: '',
        isDeleting: false,
    };

    showPicker = () => this.imageUpload.showPicker();

    saveRef = ref => (this.imageUpload = ref);

    render() {
        const iconStyles = this.props.picture ? styles.userAvatar : styles.noAvatar;
        return (
            <ImageUploadGeneral
                picture={this.props.picture}
                fileChanged={this.props.fileChanged}
                isDeleting={this.props.isDeleting}
                onRef={this.saveRef}>
                <div>
                    <button tabIndex="0" onClick={this.showPicker} className={classnames(css(containerStyle.listItemfocusVisible))}>
                        <Avatar
                            url={this.props.picture}
                            name={this.props.title}
                            size="large"
                            className={css(styles.profileImage)}
                            disablePress={true}
                        />
                    </button>
                    <button tabIndex="-1" className={classnames(css(styles.iconContainer, !isIE ? iconStyles : null), isIE ? css(styles.cssIconButton) : null)} onClick={this.showPicker} aria-label="avatar upload button">
                        <Icon
                            type="fa"
                            fill="solid"
                            name="camera"
                            size={ICON_SIZE}
                            color={baseColors.white}
                        />
                    </button>
                </div>
            </ImageUploadGeneral>
        );
    }
}

const styles = StyleSheet.create(importantStyles({
    ...baseStyles,
    iconContainer: {
        ...baseStyles.iconContainer,
        position: 'relative',
        left: -spacing.s5,
        textAlign: 'center'
    },
    userAvatar: {
        top: -spacing.s1
    },
    noAvatar: {
        top: spacing.s4
    },
    cssIconButton: {

    }
}));

export default WithAvatarUploadBase(AvatarUpload);
