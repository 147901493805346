import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { constants as coreConstants, translate } from '../../../core';
import { appFonts, baseColors, spacing } from '../../../../styles';


export default function OverTrackingModalBase(WrappedComponent) {
    class OverTrackingModalBase extends PureComponent {

        get checkboxesWithWarnings() {
            const arr = [];
            _.map((this.props.checkboxes), checkbox => this.shouldShowWarning(checkbox) ? arr.push(checkbox) : null);
            return arr;
        }

        overTrackingModalProps = () => ({
            iconName: 'exclamation-triangle',
            title: this.props.i18n.t('overTracking.title'),
            titleStyle: styles.titleStyle,
            iconBackgroundColor: baseColors.warn,
            greyText: true,
            isButtonVisible: false,
            iconType: 'solid',
            iconFill: 'solid'
        });

        openOverTrackingModal = submitAfterWarning => {
            this.wrapped.openOverTrackingModal(submitAfterWarning);
        };

        shouldShowWarning = checkbox => {
            const unitsNumber = _.toNumber(checkbox.value);
            const unitPoints = _.get(this.props, 'selectedActivity.unitPoints') || _.get(this.props, 'route.params.selectedActivity.unitPoints');
            return (unitsNumber * unitPoints >= coreConstants.WARNING_POINTS_NUMBER) && checkbox.checked;
        };

        get overTrackingDescBold() {
            const { i18n, checkboxes } = this.props;
            const checkboxWarnings = _.filter(checkboxes, checkbox => this.shouldShowWarning(checkbox));
            const checkboxWarningsText = checkboxWarnings.length === 1 ?
                i18n.t('overTracking.desc1.singular') :
                i18n.t('overTracking.desc1.plural');
            return checkboxes ? checkboxWarningsText : i18n.t('overTracking.desc1.singular');
        }

        get overTrackingDesc() {
            return this.props.i18n.t('overTracking.desc2');
        }

        get overTrackingButtonEdit() {
            return this.props.i18n.t('overTracking.button_edit');
        }

        get overTrackingButtonConfirm() {
            return this.props.i18n.t('overTracking.button_confirm');
        }

        render() {

            return (
                <WrappedComponent
                    {...this.props}
                    overTrackingModalProps={this.overTrackingModalProps}
                    overTrackingDesc={this.overTrackingDesc}
                    overTrackingDescBold={this.overTrackingDescBold}
                    overTrackingButtonEdit={this.overTrackingButtonEdit}
                    overTrackingButtonConfirm={this.overTrackingButtonConfirm}
                    shouldShowWarning={this.shouldShowWarning}
                    checkboxesWithWarnings={this.checkboxesWithWarnings}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        return {
            checkboxes: ownProps.checkboxes || _.get(ownProps, 'route.params.checkboxes'),
            inputUnit: ownProps.inputUnit || _.get(ownProps, 'route.params.inputUnit'),
            quantity: ownProps.quantity || _.get(ownProps, 'route.params.quantity'),
            selectedActivity: ownProps.selectedActivity || _.get(ownProps, 'route.params.selectedActivity'),
        };
    }


    return connect(mapStateToProps)(translate()(OverTrackingModalBase));
}


export const styles = {
    overTrackingEntityWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: baseColors.warnLightest,
        borderRadius: spacing.s1,
        paddingTop: spacing.s1,
        paddingBottom: spacing.s1,
        marginBottom: spacing.s3
    },
    overTrackingDay: {
        ...appFonts.smRegular,
        color: baseColors.warnDarkest,
    },
    overTrackingValue: {
        ...appFonts.mdMedium,
        color: baseColors.grey20,
    },
    overTrackingDescriptionWrapper: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        marginTop: spacing.s3
    },
    overTrackingDescriptionBold: {
        ...appFonts.smBold,
        color: baseColors.grey20
    },
    overTrackingDescription: {
        ...appFonts.smRegular,
        color: baseColors.grey20,
        textAlign: 'center'
    },
    overTrackingButtonConfirm: {
        margin: spacing.s3,
        marginTop: spacing.s1,
        color: baseColors.secondary
    },
    overTrackingButtonEdit: {
        marginRight: spacing.s3,
        marginLeft: spacing.s3
    },
    titleStyle: {
        color: baseColors.grey20,
    },
    noBottomMargin: {
        marginBottom: 0
    }
};
