import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import { baseColors, spacing, appFonts } from '../../../../styles';
import * as selectors from '../../selectors';
import { FIREBASE_NAMES } from '../../constants';
import { translate, tracker } from '../../../core';
import * as actions from '../../actions';
import { selectors as settingsSelectors, actions as settingsActions } from '../../../settings';

export default function WithHealthSurveyBase(WrappedComponent) {
    class HealthSurveyBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            healthSurvey: PropTypes.object,
            step: PropTypes.string,
            onNext: PropTypes.func,
            taskFlowBack: PropTypes.func,
            close: PropTypes.func,
            isSavingAnswer: PropTypes.bool,
            showQuit: PropTypes.bool,
            carousel: PropTypes.bool,
            rightButtonLabel: PropTypes.string,
            i18n: PropTypes.object.isRequired,
            isOnboarding: PropTypes.bool,
            isLoading: PropTypes.bool,
            navigation: PropTypes.object
        };

        static defaultProps = {
            healthSurvey: {},
            onNext: null,
            step: null,
            close: null,
            taskFlowBack: null,
            isSavingAnswer: false,
            showQuit: false,
            carousel: false,
            rightButtonLabel: '',
            isOnboarding: false,
            isLoading: false,
            navigation: {},
        };

        constructor(props) {
            super(props);
            this.state = {
                allAnswers: {},
                index: 0,
                questions: props.healthSurvey.questions
            };
        }

        componentDidUpdate(prevProps) {
            if (prevProps.isSavingAnswer && !this.props.isSavingAnswer) {
                this.goForward();
            }
            if (prevProps.healthSurvey.questions !== this.props.healthSurvey.questions) {
                this.updateQuestions();
            }
        }

        componentDidMount() {
            // load personal settings to get if dob field enabled
            this.props.actions.getUserSettings();
            this.props.actions.getHealthSurveyQuestions();
        }

        static getDerivedStateFromProps(nextProps, prevState) {
            if (_.isEmpty(prevState.questions) && !_.isEmpty(nextProps.healthSurvey.questions)) {
                return { questions: nextProps.healthSurvey.questions };
            }
            return null;
        }

        updateQuestions = () => {
            this.setState(prev => ({ ...prev, questions: this.props.healthSurvey.questions }));
        }

        answerSelected = val => {
            const currentQuestion = this.question;
            this.setState({
                currentAnswerSelected: val
            }, () => {
                if (val) {
                    const currentOption = _.find(currentQuestion.options, { id: val });
                    const actionText = _.get(currentOption, 'action_text');

                    if (actionText && this.wrapped) {
                        this.wrapped.openActionTextModal(actionText);
                    } else {
                        this.onActionTextModalClose();
                    }
                } else {
                    this.goForward();
                }
            });
        };

        onActionTextModalClose = () => {
            const { index, questions, currentAnswerSelected } = this.state;
            const currentQuestion = this.question;
            const { id } = currentQuestion;
            const lastPage = index === questions.length - 1 ? 1 : 0;

            this.triggeredFollowupQuestion(currentAnswerSelected);
            this.setState(oldState => ({
                allAnswers: {
                    ...oldState.allAnswers,
                    [id]: currentAnswerSelected
                }
            }), () => {
                this.props.actions.setHealthSurveyAnswers(this.state.allAnswers, lastPage);
            });
        };

        triggeredFollowupQuestion = answer => {
            const { index, questions } = this.state;
            const currentQuestion = this.question;

            if (_.get(currentQuestion, 'followup_question')) {
                const nextQuestion = (index !== questions.length - 1) ? questions[index + 1] : null;

                if (_.get(nextQuestion, 'baseQuestionTag') === currentQuestion.label) {
                    this.setState(oldState => {
                        const { allAnswers, questions } = oldState;
                        delete allAnswers[nextQuestion.id];
                        const updatedQuestions = [...questions];
                        updatedQuestions.splice(index + 1, 1);
                        return ({ questions: updatedQuestions, allAnswers });
                    });
                }

                const followUp = _.find(currentQuestion.followup_question, { option_value: answer });
                if (followUp) {
                    this.setState(oldState => {
                        const { questions } = oldState;
                        const item = { ...followUp, baseQuestionTag: currentQuestion.label };
                        const updatedQuestions = [...questions];
                        updatedQuestions.splice(index + 1, 0, item);
                        return ({ questions: updatedQuestions });
                    });
                }
            }
        };

        goBack = () => {
            const newIndex = this.state.index - 1;
            this.setState({
                index: (newIndex < 0) ? 0 : newIndex
            });
            if (this.props.carousel && newIndex < 0) {
                this.close();
            }
            else if (this.props.taskFlowBack && newIndex < 0) {
                this.props.taskFlowBack();
            }
        };

        goForward = () => {
            const { index, questions } = this.state;
            const { onNext, step, isOnboarding, actions: { setHealthSurveyComplete } } = this.props;
            if (index !== questions.length - 1) {
                tracker.logEvent('Onboarding_HRA_CompleteQuestion', { isOnboarding, index });
                this.setState({ index: index + 1 });
            } else if (onNext) {
                tracker.logEvent('healthSurvey', { isOnboarding, state: 'goForward', step });
                tracker.logEvent('Onboarding_HRA_CompleteQuestion', { isOnboarding, index });
                onNext(step);
            } else {
                if (!isOnboarding) {
                    setHealthSurveyComplete();
                }
                tracker.logEvent('Onboarding_HRA_CompleteQuestion', { isOnboarding, state: 'goForward', index: this.state.index });
                this.close();
            }
        };

        close = event => {
            const goBackMobile = _.get(this.props, 'navigation.goBack');
            _.has(this, 'wrapped.close') && this.wrapped.close(event, goBackMobile);
        }

        get isFirstPage() {
            return this.state.index === 0;
        }

        get percentageComplete() {
            return (this.state.index + 1) / this.state.questions.length * 100;
        }

        get question() {
            return this.state.questions[this.state.index];
        }

        getModalProps = () => ({
            iconName: 'exclamation-circle',
            title: this.props.i18n.t('healthSurveySkipAllTitle'),
            text: this.props.i18n.t('healthSurveySkipAllAlert'),
            isButtonVisible: false
        });

        saveRef = ref => this.wrapped = ref;

        get componentType() {
            return _.get(this.question, 'type');
        }

        get rightButtonLabel() {
            const { isOnboarding, rightButtonLabel } = this.props;
            return isOnboarding
                ? this.props.i18n.t('skip')
                : rightButtonLabel;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    {...this.state}
                    ref={this.saveRef}
                    onActionTextModalClose={this.onActionTextModalClose}
                    hasRightButton={this.hasRightButton}
                    rightButtonLabel={this.rightButtonLabel}
                    getModalProps={this.getModalProps}
                    answerSelected={this.answerSelected}
                    percentageComplete={this.percentageComplete}
                    goBack={this.goBack}
                    goForward={this.goForward}
                    isFirstPage={this.isFirstPage}
                    componentType={this.componentType}
                    question={this.question}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const locationState = _.get(ownProps, 'history.location.state', {});
        const routeParams = _.get(ownProps, 'route.params');
        const isFromWellbeingTab = _.get(ownProps, 'route.params.isFromWellbeingTab');
        return ({
            ...locationState,
            healthSurvey: selectors.getHealthSurvey(state),
            isLoading: selectors.isLoadingHealthSurvey(state) || settingsSelectors.isLoadingPersonalSettings(state),
            isSavingAnswer: selectors.isSavingAnswer(state),
            startTime: selectors.getStartTime(state, FIREBASE_NAMES.health_survey),
            isSettingUserTaskComplete: selectors.isSettingUserTaskComplete(state),
            ...(routeParams || {}),
            isFromWellbeingTab
        });
    };

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators({ ...settingsActions, ...actions }, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(HealthSurveyBase));
}

export const styles = {
    mainContainer: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: baseColors.white
    },
    barContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: baseColors.white,
        minHeight: spacing.s12
    },
    infoContainer: {
        flexDirection: 'column',
        backgroundColor: baseColors.white,
        alignItems: 'center'
    },
    skipText: {
        ...appFonts.mdRegular,
        color: baseColors.secondary,
        textAlign: 'right'
    },
    personalizeText: {
        ...appFonts.mdMedium
    },
    progressBarOffset: {
        marginTop: spacing.s6
    }
};
