import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from '../../../core';
import * as partnersActions from '../../actions';
import { spacing, baseColors, appFonts, windowSize } from '../../../../styles';
import * as selectors from '../../selectors';

export default function WithEarnPartnersBase(WrappedComponent) {
    class EarnPartnersBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            isLoadingPartners: PropTypes.bool,
            onRef: PropTypes.func,
            i18n: PropTypes.object.isRequired,
            earnPartnersDescription: PropTypes.string,
            earnPartnersProgramName: PropTypes.string
        };

        static defaultProps = {
            isLoadingPartners: false,
            onRef: null,
            earnPartnersProgramName: null,
            earnPartnersDescription: null
        };

        componentDidMount() {
            this.props.actions.getEarnPartners(true);
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                />
            );
        }
    }

    function mapStateToProps(state) {
        return {
            partnersArray: selectors.getEarnPartnersCarouselArray(state),
            earnPartnersDescription: selectors.getEarnPartnersDescription(state),
            earnPartnersProgramName: selectors.getEarnPartnersProgramName(state),
            isEarnPartnersEnabled: selectors.isEarnPartnersEnabled(state)
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(partnersActions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(EarnPartnersBase));
}

export const styles = {
    container: {
        paddingLeft: spacing.s0,
        paddingRight: spacing.s1,
        paddingTop: spacing.s3,
        paddingBottom: spacing.s3
    },
    titleContainer: {
        marginLeft: spacing.s1
    },
    partnerTitle: {
        ...appFonts.lgRegular
    },
    subHeaderValue: {
        ...appFonts.smRegular
    },
    carouselContainer: {
        paddingTop: spacing.s2,
        marginTop: spacing.s0
    },
    footerButtonContainer: {
        marginLeft: spacing.s1,
        marginRight: spacing.s1
    },
    cardContainer: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    card: {
        backgroundColor: baseColors.white,
        width: windowSize.width,
        minHeight: (50 / 375) * windowSize.width,
        marginBottom: spacing.s2,
        overflow: 'hidden',
        paddingLeft: spacing.s0,
        paddingRight: spacing.s1,
        paddingTop: spacing.s3,
        paddingBottom: spacing.s3
    }
};
