import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MUISwitch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
// import { StyleSheet, css } from 'aphrodite-jss';
// import { baseColors } from '../../../styles/commonCSS';

const ICON_SIZE = 24;
const BAR = {
    BORDER_RADIUS: 13,
    WIDTH: 42,
    HEIGHT: 26,
    OFFSET_TOP: -13,
    OFFSET_LEFT: -21,
};

class Switch extends Component {
    static propTypes = {
        classes: PropTypes.object,
        onChange: PropTypes.func,
        value: PropTypes.bool,
        disableRipple: PropTypes.bool
    };

    static defaultProps = {
        classes: null,
        onChange: null,
        value: false,
        disableRipple: false
    };

    render() {
        const { value, onChange, disableRipple = true, classes, ...props } = this.props;
        return (
            <MUISwitch
                classes={{
                    switchBase: classes.iOSSwitchBase,
                    checked: classes.iOSChecked,
                    track: `${classes.track} ${value ? classes.switchTracked : ''}`,
                }}
                // classes={{
                //     switchBase: css(styles1.iOSSwitchBase),
                //     bar: css(styles1['@iOSBar']),
                //     icon: css(styles1.iOSIcon),
                //     iconChecked: css(styles1.iOSIconChecked),
                //     checked: css(styles1.iOSChecked),
                // }}
                disableRipple={disableRipple}
                onChange={onChange}
                checked={value}
                {...props}
            />
        );
    }
}

// const styles1 = StyleSheet.create({
//     iOSSwitchBase: {
//         '&$iOSChecked': {
//             color: baseColors.white,
//             '& + $iOSBar': {
//                 backgroundColor: baseColors.secondary,
//             },
//         },
//         // transition: theme.transitions.create('transform', {
//         //     duration: theme.transitions.duration.shortest,
//         //     easing: theme.transitions.easing.sharp,
//         // }),
//     },
//     iOSChecked: {
//         transform: 'translateX(15px)',
//         ['& + @iOSBar']: {
//             opacity: 1,
//             border: 'none',
//             backgroundColor: 'red !important'
//         },
//     },
//     ['@iOSBar']: {
//         borderRadius: 13,
//         width: 42,
//         height: 26,
//         marginTop: -13,
//         marginLeft: -21,
//         border: 'solid 1px',
//         borderColor: baseColors.grey40,
//         backgroundColor: baseColors.grey40,
//         opacity: 1,
//         // transition: theme.transitions.create(['background-color', 'border']),
//     },
//     iOSIcon: {
//         width: 24,
//         height: 24,
//     },
//     iOSIconChecked: {
//         // boxShadow: theme.shadows[1],
//     },
// });

const styles = theme => ({
    colorBar: {},
    colorChecked: {},
    iOSSwitchBase: {
        '&$iOSChecked': {
            color: theme.palette.common.white,
            '& + $iOSBar': {
                backgroundColor: theme.palette.common.secondary,
            },
        },
        transition: theme.transitions.create('transform', {
            duration: 75,
            easing: theme.transitions.easing.sharp,
        }),
    },
    iOSChecked: {
        transform: 'translateX(15px)',
        '& + $iOSBar': {
            opacity: 1,
            border: 'none',
        }
    },
    iOSBar: {
        borderRadius: BAR.BORDER_RADIUS,
        width: BAR.WIDTH,
        height: BAR.HEIGHT,
        marginTop: BAR.OFFSET_TOP,
        marginLeft: BAR.OFFSET_LEFT,
        border: 'solid 1px',
        borderColor: theme.palette.grey[400],
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    iOSIcon: {
        width: ICON_SIZE,
        height: ICON_SIZE,
    },
    iOSIconChecked: {
        boxShadow: theme.shadows[1],
    },
    switchTracked: {
        opacity: '1 !important'
    }
});

export default withStyles(styles)(Switch);

