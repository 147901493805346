import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import { appFonts, baseColors, spacing } from '../../../../styles';
import { SELECT_HEIGHT } from '../../../core/components/SelectField/SelectFieldBase';
import {
    components as Core, ROUTES, Modal, translate, getNumberOfLastDays, DATE_FORMATS,
    selectors as coreSelectors, ALERT_TIMEOUT, timeout, validation, tracker,
    constants as coreConstants, actions as coreActions, Platform, PLATFORMS, AsyncComponent
} from '../../../core';
import { MINIMUM_QUANTITY_VALUE } from '../ActivityTrackingModal/ActivityTrackingModalBase';

export const INPUT_VALUE_MAX_LENGTH = 5;
const NUMBER_OF_LAST_DAYS_TO_SHOW = 5;
const REQUEST_ID = 'calendar';

export default function withTrackActivityFormBase(WrappedComponent) {
    class TrackActivityFormBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            onlyMeFilter: PropTypes.string.isRequired,
            setTimeout: PropTypes.func.isRequired,
            isLoading: PropTypes.bool,
            error: PropTypes.object,
            navigation: PropTypes.object,
            customPointsName: PropTypes.string.isRequired
        };

        static defaultProps = {
            isLoading: false,
            error: undefined,
            navigation: {},
        };

        constructor(props) {
            super(props);
            this.state = {
                selectedActivity: null,
                checkboxes: [],
            };
        }

        componentDidUpdate(prevProps) {
            if (prevProps.isLoading && !this.props.isLoading) {
                this.props.error ?
                    this.props.setTimeout(() => {
                        this.props.actions.clearTrackError(REQUEST_ID);
                    }, ALERT_TIMEOUT)
                    : this.dismissModal();
            }
        }

        onSelectActivity = activity => this.setState({
            selectedActivity: activity,
            checkboxes: this.getCheckboxesInfo(getNumberOfLastDays(NUMBER_OF_LAST_DAYS_TO_SHOW))
        });

        getCheckboxesInfo = (days, isFromDatepicker = false) => {
            return _.map(days, day => {
                const checkboxInfo = {
                    date: day,
                    value: '',
                    checked: isFromDatepicker,
                    dateString: moment(day).format(DATE_FORMATS.monthDayYearFull),
                    dateStringHidden: true,
                };
                const isToday = moment(day).isSame(moment(), 'day');
                const isYesterday = moment(day).isSame(moment().subtract(1, 'd'), 'day');
                if (isToday) {
                    checkboxInfo.label = this.props.i18n.t('today');
                } else if (isYesterday) {
                    checkboxInfo.label = this.props.i18n.t('yesterday');
                } else {
                    checkboxInfo.label = moment(day).format(DATE_FORMATS.dayFullString);
                    checkboxInfo.dateStringHidden = false;
                }
                return checkboxInfo;
            });
        };

        onDatepickerSubmit = days => {
            this.setState(prevState => {
                const existingAndFreshDays = _.partition(days, day => (
                    _.some(prevState.checkboxes, ch => ch.date.isSame(day, 'day'))
                ));
                const [existingDays, freshDays] = existingAndFreshDays;
                const existingCheckboxes = _.map(existingDays, day => {
                    const existingCheckbox = _.find(prevState.checkboxes, ch => ch.date.isSame(day, 'day'));
                    existingCheckbox.checked = true;
                    return existingCheckbox;
                });
                const freshCheckboxes = this.getCheckboxesInfo(freshDays, true);
                return {
                    checkboxes: _.orderBy([...existingCheckboxes, ...freshCheckboxes], 'date', 'desc')
                };
            });
        };

        getCheckboxIndexByDateString = dateString => _.findIndex(
            this.state.checkboxes, checkbox => checkbox.dateString === dateString
        );

        onChangeInputValue = dateString => newValue => {
            if (validation.isTextNaN(newValue)) return;
            this.setState(prevState => {
                const checkboxes = prevState.checkboxes;
                const activeCheckboxIndex = this.getCheckboxIndexByDateString(dateString);
                checkboxes[activeCheckboxIndex].value = newValue;
                return { checkboxes: [...checkboxes] };
            });
        };

        onCheckboxPress = dateString => () => {
            this.setState(prevState => {
                const checkboxes = prevState.checkboxes;
                const activeCheckboxIndex = this.getCheckboxIndexByDateString(dateString);
                checkboxes[activeCheckboxIndex].checked = !prevState.checkboxes[activeCheckboxIndex].checked;
                return { checkboxes: [...checkboxes] };
            });
        };

        getInfoModalProps = onDiscard => {
            const { i18n } = this.props;
            const discard = () => {
                onDiscard();
                this.isWeb && this.closeInfoModal();
            };
            return {
                title: i18n.t('trackActivity.discardChanges'),
                text: i18n.t('trackActivity.discardMessage'),
                isButtonVisible: false,
                buttons: [
                    { text: i18n.t('trackActivity.discard'), onPress: () => discard(), isDangerText: true },
                    { text: i18n.t('button_cancel'), onPress: this.isWeb ? () => this.closeInfoModal() : null },
                ],
            };
        }

        showDiscardAlert = onDiscard => {
            const infoModalProps = this.getInfoModalProps(onDiscard);
            if (this.isWeb) {
                this.closeInfoModal = Modal.open(
                    Core.InfoModal,
                    infoModalProps,
                    { isContainer: true, isNoPadding: true, isMaxWidthLimited: true, fadeTransition: true }
                );
            } else {
                this.props.navigation.push(ROUTES.infoModal(), infoModalProps);
            }
        };

        prepareAndTrackActivity = () => {
            const { selectedActivity, checkboxes } = this.state;
            const { actions, i18n } = this.props;
            const warnValueDaysNum = _.filter(checkboxes, checkbox => this.shouldShowWarning(checkbox)).length;
            const datesWithValues = _.map(_.filter(checkboxes, checkbox => checkbox.value && checkbox.checked), checkbox => ({
                date: moment(checkbox.date).format(DATE_FORMATS.full),
                quantity: checkbox.value
            }));
            const preparedActivities = _.map(datesWithValues, dateWithValue => ({ ...selectedActivity, ...dateWithValue }));
            if (_.some(preparedActivities, activity => _.toNumber(activity.quantity) < MINIMUM_QUANTITY_VALUE)) {
                return actions.addToast(
                    coreConstants.TOAST_TYPES.INFO,
                    undefined,
                    i18n.t('createChallenge.quantityMinimum.message', { quantity: MINIMUM_QUANTITY_VALUE }),
                    i18n.t('unable_to_track')
                );
            }
            if (warnValueDaysNum) {
                const submitAfterWarning = () => this.submitAfterWarning(datesWithValues, preparedActivities);
                return this.openOverTrackingModal(submitAfterWarning);
            }
            this.onSubmit(datesWithValues, preparedActivities);
        };

        openOverTrackingModal = submitAfterWarning => {
            if (this.isWeb) {
                this.closeOverTrackingModal = Modal.open(
                    AsyncComponent(() => import('../../components/OverTrackingModal')),
                    {
                        closeModal: () => this.closeOverTrackingModal(),
                        submitFunc: submitAfterWarning,
                        checkboxes: this.state.checkboxes,
                        selectedActivity: this.state.selectedActivity,
                        inputUnit: this.inputUnit
                    },
                    {
                        isTransparent: true,
                        isContainer: false,
                        isNoPadding: true,
                        fadeTransition: true
                    }
                );
            }
            else {
                this.props.navigation.navigate(ROUTES.overTrackingModal(),
                    {
                        submitFunc: submitAfterWarning,
                        checkboxes: this.state.checkboxes,
                        selectedActivity: this.state.selectedActivity,
                        inputUnit: this.inputUnit
                    });
            }
        };

        submitAfterWarning = (datesWithValues, preparedActivities) => {
            tracker.logEvent('Activity_Overtracking_Confirm');
            this.onSubmit(datesWithValues, preparedActivities);
        }

        onSubmit = (datesWithValues, preparedActivities) => {
            const { actions, onlyMeFilter } = this.props;
            tracker.logEvent('TrackActivityForm_Submit', { qty_days_tracked: datesWithValues.length });
            actions.trackActivity(REQUEST_ID, preparedActivities, onlyMeFilter, false, false, false);
        };

        dismissModal = () => _.has(this, 'wrapped.dismissModal') && this.wrapped.dismissModal();

        onCloseModal = () => {
            this.hasAnyValues
                ? this.showDiscardAlert(this.dismissModal)
                : this.dismissModal();
        };

        saveRef = ref => (this.wrapped = ref);

        shouldShowWarning = checkbox => {
            const unitsNumber = _.toNumber(checkbox.value);
            const unitPoints = _.get(this.state, 'selectedActivity.unitPoints');
            return (unitsNumber * unitPoints >= coreConstants.WARNING_POINTS_NUMBER) && checkbox.checked;
        };

        get isWeb() {
            return Platform.OS === PLATFORMS.web;
        }

        get chooseActivityTitle() {
            return this.props.i18n.t('chooseActivity');
        }

        get selectFieldName() {
            return this.props.i18n.t('activity');
        }

        get selectFieldValue() {
            const { selectedActivity } = this.state;
            return selectedActivity ?
                `${selectedActivity.activityName} (${selectedActivity.unitName})`
                : '';
        }

        get datesTitle() {
            return this.props.i18n.t('dates');
        }

        get bottomButtonText() {
            return this.props.i18n.t('done');
        }

        get pickDatesText() {
            return this.props.i18n.t('pickDates');
        }

        get hasAnyValues() {
            return _.some(this.state.checkboxes, checkbox => checkbox.checked && _.toNumber(checkbox.value) > 0);
        }

        get checkedOffDates() {
            const checkedCheckboxes = _.filter(this.state.checkboxes, ch => ch.checked);
            return _.map(checkedCheckboxes, ch => ch.date);
        }

        get inputUnit() {
            const { selectedActivity } = this.state;
            return selectedActivity ? selectedActivity.unitName || selectedActivity.unitSingular : '';
        }

        get warningText() {
            const { i18n } = this.props;
            return `${i18n.t('thisIsALot')} ${i18n.t('adjust_entry')}`;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    ref={this.saveRef}
                    inputUnit={this.inputUnit}
                    datesTitle={this.datesTitle}
                    warningText={this.warningText}
                    onCloseModal={this.onCloseModal}
                    hasAnyValues={this.hasAnyValues}
                    checkboxes={this.state.checkboxes}
                    pickDatesText={this.pickDatesText}
                    selectFieldName={this.selectFieldName}
                    onCheckboxPress={this.onCheckboxPress}
                    checkedOffDates={this.checkedOffDates}
                    selectFieldValue={this.selectFieldValue}
                    onSelectActivity={this.onSelectActivity}
                    bottomButtonText={this.bottomButtonText}
                    shouldShowWarning={this.shouldShowWarning}
                    onChangeInputValue={this.onChangeInputValue}
                    onDatepickerSubmit={this.onDatepickerSubmit}
                    chooseActivityTitle={this.chooseActivityTitle}
                    selectedActivity={this.state.selectedActivity}
                    prepareAndTrackActivity={this.prepareAndTrackActivity}
                />
            );
        }
    }

    const mapStateToProps = state => ({
        onlyMeFilter: _.get(coreSelectors.getOnlyMeFilter(state), 'filterId'),
        isLoading: selectors.isTracking(state, REQUEST_ID),
        error: selectors.getTrackError(state, REQUEST_ID),
        customPointsName: coreSelectors.getCustomPointsName(state)
    });

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators({ ...actions, addToast: coreActions.addToast }, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(timeout(TrackActivityFormBase)));
}

export const styles = {
    datesTitle: {
        ...appFonts.mdBold,
        color: baseColors.black,
        marginTop: spacing.s7,
        marginBottom: spacing.s3,
    },
    checkboxLabel: {
        ...appFonts.smMedium,
        color: baseColors.grey20,
    },
    checkboxSubLabel: {
        ...appFonts.smRegular,
        color: baseColors.grey40,
        paddingLeft: spacing.s1,
    },
    pickDatesBtn: {
        marginTop: spacing.s3,
        marginBottom: spacing.s3,
    },
    pickDatesText: {
        ...appFonts.smRegular,
        marginLeft: spacing.s0,
    },
    selectFieldWrapper: {
        height: 'auto',
        minHeight: SELECT_HEIGHT,
    },

    warningText: {
        ...appFonts.smRegular,
        marginLeft: spacing.s1,
        marginBottom: spacing.s1,
        color: baseColors.grey40,
    },
    datesWrapper: {
        justifyContent: 'space-between',
        alignItems: 'baseline',
        flexDirection: 'row',
    },
};
