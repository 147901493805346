import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';
import classnames from 'classnames';
import { importantClass } from '../../../../../styles/style-util';

import { components as Core, ENTITIES, onKeyPress } from '../../../../core';
import { baseColors, layoutStyle, spacing, appFonts } from '../../../../../styles';
import JoinButton from '../JoinButton';
import WithFeedItemEntityCardBaseBase, { styles as baseStyles, SHADOW } from './FeedItemEntityCardBase';
import FeedItemImage from '../FeedItemImage';

class FeedItemEntityCard extends PureComponent {
    static propTypes = {
        streamItemId: PropTypes.number.isRequired, // eslint-disable-line
        stream: PropTypes.object,
        onEntityDetails: PropTypes.func.isRequired,
        isMember: PropTypes.bool,
        content: PropTypes.object
    };

    static defaultProps = {
        stream: undefined,
        isMember: false,
        content: undefined
    };

    render() {
        const { onEntityDetails, stream, content, isMember } = this.props;
        return content.name || content.additionalData || content.image ? (
            <div
                tabIndex="0"
                role="button"
                onClick={onEntityDetails}
                onKeyDown={onKeyPress.enter(onEntityDetails)}
                className={css(layoutStyle.db, layoutStyle.textLeft, layoutStyle.cp)}>
                <div className={css(styles.entityCard)}>
                    {content.image ? (
                        <div style={styles.entityCardImageContainer}>
                            {stream.relationTypeSlug === ENTITIES.challenge ? (
                                <Core.Image
                                    isFadeIn={true}
                                    alt={content.link}
                                    src={content.image}
                                    className={css(styles.entityCardImage)}
                                />
                            ) : (
                                <FeedItemImage
                                    stream={stream}
                                    isFadeIn={true}
                                    hasRoundBorder={false}
                                    alt={content.link}
                                    className={css(styles.entityCardImage)}
                                />
                            )}
                        </div>
                    ) : null
                    }
                    <Core.CardContent>
                        <div className={classnames(css(styles.entityContainer), { [css(styles.imageTop)]: content.image })}>
                            {content.icon ? (
                                <Core.Icon
                                    type="fa"
                                    name={content.icon}
                                    size={spacing.s5}
                                    className={css(styles.entityIcon)}
                                />
                            ) : null}
                            <div className={css(styles.entityInnerContainer)}>
                                {content.name ? <p className={css(styles.entityName)}>{content.name}</p> : null}
                                {content.additionalData ? (
                                    <p className={css(styles.entityAdditionalData)}>
                                        {content.additionalData}
                                    </p>
                                ) : null}
                            </div>
                        </div>
                        <JoinButton
                            stream={stream}
                            isMember={isMember}
                            className={css(styles.joinButton)}
                        />
                    </Core.CardContent>
                </div>
            </div>
        ) : null;
    }
}

export default WithFeedItemEntityCardBaseBase(FeedItemEntityCard);

const MAX_IMAGE_HEIGHT = 410;
export const styles = StyleSheet.create({
    ...baseStyles,
    entityCard: {
        ...baseStyles.entityCard,
        boxShadow: `0px 2px ${SHADOW.height}px rgba(0, 0, 0, ${SHADOW.opacity})`,
    },
    entityCardImage: {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        maxHeight: MAX_IMAGE_HEIGHT
    },
    entityContainer: {
        ...baseStyles.entityContainer,
        display: 'flex',
    },
    entityInnerContainer: {
        flex: 1
    },
    entityName: {
        ...appFonts.lgMedium,
    },
    entityAdditionalData: {
        ...appFonts.smRegular,
        color: baseColors.grey40
    },
    joinButton: importantClass({
        ...baseStyles.joinButton
    }),
});
