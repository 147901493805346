import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Checkbox from '../Checkbox';
import { baseColors, spacing } from '../../../../styles';

export default function WithMultiSelectBase(WrappedComponent) {
    class MultiSelectCheckBase extends Component {
        static propTypes = {
            options: PropTypes.array,
            onPressCallback: PropTypes.func,
            initValues: PropTypes.bool,
            error: PropTypes.string,
            singleSelect: PropTypes.bool,
            selected: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
            checkboxType: PropTypes.string
        };

        static defaultProps = {
            options: [],
            onPressCallback: null,
            initValues: false,
            error: undefined,
            singleSelect: false,
            selected: {},
            checkboxType: undefined
        };

        toggleSelectedValue = option => {
            const { selected, singleSelect, onPressCallback } = this.props;
            if (singleSelect) {
                onPressCallback(option);
            } else {
                const selectedOptions = _.some(selected, so => so.id === option.id)
                    ? _.reject(selected, so => so.id === option.id)
                    : [...selected, option];
                onPressCallback(selectedOptions);
            }
        };

        isSelected = option => {
            const { singleSelect, selected } = this.props;
            return singleSelect ? selected && option.id === selected.id
                : _.some(selected || [], so => so.id === option.id);
        };

        get isButtonType() { return this.props.checkboxType === Checkbox.TYPES.button; }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    isSelected={this.isSelected}
                    toggleSelectedValue={this.toggleSelectedValue}
                    isButtonType={this.isButtonType}
                />
            );
        }
    }

    return MultiSelectCheckBase;
}

export const styles = {
    buttonContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: spacing.s3,
        paddingRight: spacing.s1,
        paddingTop: spacing.s5,
        paddingBottom: spacing.s5,
        marginTop: spacing.s1,
        marginBottom: spacing.s1,
        borderRadius: spacing.s1,
        borderWidth: 1,
        borderColor: baseColors.grey70
    }
};
