import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { StyleSheet, css } from 'aphrodite-jss';

import { spacing, importantClass } from '../../../../styles';
import { components as Core } from '../../../core';
import WithAppsDevicesListBase, { styles as baseStyles } from './AppsDevicesListBase';
import DeviceItem from '../DeviceItem';

class AppsDevicesList extends PureComponent {
    static propTypes = {
        sections: PropTypes.array.isRequired,
        getHeaderText: PropTypes.func.isRequired,
        isOnboarding: PropTypes.bool,
    };

    static defaultProps = {
        isOnboarding: false,
    };

    renderDeviceItem = device => (
        <DeviceItem
            vendorId={device.vendorId}
            key={device.vendorId}
            id={_.camelCase(`${device.displayName}DeviceItem`)}
        />
    )

    renderSection = (section, index) => {
        const { data } = section;
        const headerText = this.props.getHeaderText(data);
        const { sections, isOnboarding } = this.props;
        const isLastSection = index === sections.length - 1;
        return (
            <div key={data.vendorId}>
                {data.length ?
                    <Core.List component="div" className={css(isLastSection && styles.bottomOffset, styles.padding0)}>
                        {headerText ?
                            <div className={css(styles.connectedDataNew, isOnboarding ? styles.headerOnb : styles.header)}>
                                <h3 className={css(styles.headerText)}>
                                    {headerText}
                                </h3>
                            </div>: null
                        }
                        {_.map(data, dev => this.renderDeviceItem(dev))}
                    </Core.List> : null
                }
            </div>
        );
    };

    render() {
        return _.map(this.props.sections, this.renderSection);
    }
}

const styles = StyleSheet.create({
    ...baseStyles,
    headerText: {
        ...baseStyles.headerText,
        marginBottom: 0
    },
    bottomOffset: importantClass({
        marginBottom: spacing.s7
    }),
    connectedDataNew: {
        ...baseStyles.connectedDataNew,
        borderTopStyle: 'solid',
        borderBottomStyle: 'solid',
    },
    header: {
        marginLeft: `calc(25% + ${spacing.s0}px)`,
        marginRight: `calc(25% + ${spacing.s0}px)`,
    },
    headerOnb: {
        marginLeft: spacing.s7,
        marginRight: spacing.s7,
    },
    padding0: importantClass({
        paddingTop: 0,
        paddingBottom: 0
    })
});

export default WithAppsDevicesListBase(AppsDevicesList);
