import theme from './theme';

const up_xs = theme.breakpoints.up('xs'); // @media (min-width:0px)
const up_sm = theme.breakpoints.up('sm'); // @media (min-width:600px)
const up_md = theme.breakpoints.up('md'); // @media (min-width:960px)
const up_lg = theme.breakpoints.up('lg'); // @media (min-width:1280px)
const up_xl = theme.breakpoints.up('xl'); // @media (min-width:1920px)

const xs = theme.breakpoints.down('xs'); // @media (max-width:599.95px)
const sm = theme.breakpoints.down('sm'); // @media (max-width:959.95px)
const md = theme.breakpoints.down('md'); // @media (max-width:1279.95px)
const lg = theme.breakpoints.down('lg'); // @media (max-width:1919.95px)
const xl = theme.breakpoints.down('xl'); // @media (min-width:0px)

const xsOnly = theme.breakpoints.only('xs'); // @media (min-width:0px) and (max-width:599.95px)
const smOnly = theme.breakpoints.only('sm'); // @media (min-width:600px) and (max-width:959.95px)
const mdOnly = theme.breakpoints.only('md'); // @media (min-width:960px) and (max-width:1279.95px)
const lgOnly = theme.breakpoints.only('lg'); // @media (min-width:1280px) and (max-width:1919.95px)
const xlOnly = theme.breakpoints.only('xl'); // @media (max-width:1920px)

const xs_sm = theme.breakpoints.between('xs', 'sm');
const xs_md = theme.breakpoints.between('xs', 'md');
const xs_lg = theme.breakpoints.between('xs', 'lg');

const sm_md = theme.breakpoints.between('sm', 'md');
const sm_lg = theme.breakpoints.between('sm', 'lg');
const sm_xl = theme.breakpoints.between('sm', 'xl');

const md_lg = theme.breakpoints.between('md', 'lg');
const md_xl = theme.breakpoints.between('md', 'xl');

const lg_xl = theme.breakpoints.between('lg', 'xl');
const media = { xs, sm, md, lg, xl, xsOnly, smOnly, mdOnly, lgOnly, xlOnly, xs_sm, xs_md, xs_lg, sm_md, sm_lg, sm_xl, md_lg, md_xl, lg_xl, up_xs, up_sm, up_md, up_lg, up_xl, values: theme.breakpoints.values };
export const isWiderScreen = () => window.innerWidth >= media.values.sm;

export default media;