const getFirstLoadingWrapper = (nextProps, prevState, loadingPropertyName = 'isLoading') => {
    if (nextProps[loadingPropertyName] && !prevState.isLoading) {
        return {
            isLoading: nextProps[loadingPropertyName]
        };
    }
    if (!prevState.isFirstLoaded && !nextProps[loadingPropertyName] && prevState.isLoading) {
        return {
            isFirstLoaded: true,
            isLoading: false
        };
    }
    return null;
};

export default getFirstLoadingWrapper;