import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { parsers, selectors as coreSelectors, translate } from '../../../../core';
import { selectors as onboardingSelectors } from '../../../../onboarding';
import { selectors as challengeSelectors, services as challengesServices } from '../../../../challenges';
import { appFonts, baseColors, spacing } from '../../../../../styles';
import { CATEGORIES, SLUGS } from '../../../constants';

export const CATEGORY_ICON_SIZE = spacing.s11;
export const CATEGORY_MODAL_ICON_SIZE = spacing.s15;
export const GOAL_ICON_SIZE = spacing.s3;
export const EXCLAMATION_ICON_SIZE = spacing.s3;

export default function WithWellbeingCategoryProgressBase(WrappedComponent) {
    class WellbeingCategoryProgressBase extends PureComponent {
        static propTypes = {
            slug: PropTypes.string.isRequired,
            category: PropTypes.object,
            categoryGoals: PropTypes.array,
            onToggle: PropTypes.func.isRequired,
            isExpanded: PropTypes.func.isRequired,
            i18n: PropTypes.object.isRequired,
            language: PropTypes.string,
        };

        static defaultProps = {
            categoryGoals: [],
            category: {},
            language: 'en-CA'
        };

        constructor(props) {
            super(props);
            this.categoryNames = {
                [SLUGS.move]: props.i18n.t('move'),
                [SLUGS.eat]: props.i18n.t('eat'),
                [SLUGS.feel]: props.i18n.t('feel')
            };
        }

        onItemClick = () => this.props.onToggle(this.props.slug);

        get mainColor() {
            return CATEGORIES[this.props.slug].mainColor;
        }

        get categoryGoalsCount() {
            return this.props.categoryGoals.length;
        }

        get categoryName() {
            return this.categoryNames[this.props.slug];
        }

        get score() {
            return parsers.convertToLocal(_.get(this.props.category, 'score'), this.props.language);
        }

        get averageAgeGenderScore() {
            return parsers.convertToLocal(Number(_.get(this.props.category, 'age_gender_avg', 0)), this.props.language);
        }

        get deltaAgeGenderScore() {
            return (this.score - this.averageAgeGenderScore).toFixed(2);
        }

        get positiveDeltaAgeGenderScore() {
            return parsers.convertToLocal(Math.abs(this.deltaAgeGenderScore), this.props.language);
        }

        get scoreDetails() {
            return ` ${this.props.i18n.t(this.deltaAgeGenderScore > 0 ? 'wellbeing.scoreHigher' : 'wellbeing.scoreLower')} `;
        }

        get goalsDescription() {
            return challengesServices.helper.getSetGoalsString(this.categoryGoalsCount);
        }

        get itemIconName() {
            return this.props.isExpanded() ? 'angle-up' : 'angle-down';
        }

        getDescriptionModalProps = () => ({
            title: this.categoryName,
            text: this.props.category.description,
            isButtonVisible: false,
            buttonTitle: this.props.i18n.t('got_it')
        });

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    categoryName={this.categoryName}
                    goalsDescription={this.goalsDescription}
                    score={this.score}
                    scoreDetails={this.scoreDetails}
                    onItemClick={this.onItemClick}
                    mainColor={this.mainColor}
                    averageAgeGenderScore={this.averageAgeGenderScore}
                    positiveDeltaAgeGenderScore={this.positiveDeltaAgeGenderScore}
                    itemIconName={this.itemIconName}
                    getDescriptionModalProps={this.getDescriptionModalProps}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => ({
        category: onboardingSelectors.getCurrentHealthSurveyResultBySlug(state, ownProps.slug),
        categoryGoals: challengeSelectors.getUncompletedPersonalGoalsByCategory(state, ownProps.slug),
        language: coreSelectors.getCurrentUserLanguage(state)
    });

    return connect(mapStateToProps)(translate()(WellbeingCategoryProgressBase));
}

export const styles = {
    mainContainer: {
        borderBottomWidth: 2,
        borderBottomColor: baseColors.grey85
    },
    healthScoreContainer: {
        flexDirection: 'row',
        paddingTop: spacing.s3,
        paddingBottom: spacing.s3,
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3
    },
    titleContainer: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    title: {
        ...appFonts.lgMedium,
        paddingRight: spacing.s1
    },
    goalsInfo: {
        flexDirection: 'row',
        marginTop: spacing.s0,
        alignItems: 'center'
    },
    goalsText: {
        ...appFonts.mdMedium,
        marginLeft: spacing.s0
    },
    expandedContainerHeader: {
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3
    },
    scoreText: {
        ...appFonts.mdRegular,
        marginTop: spacing.s1
    },
    scoreTextBold: {
        ...appFonts.mdBold
    },
    scoreDescription: {
        ...appFonts.smRegular,
        marginTop: spacing.s1,
        marginBottom: spacing.s3
    },
    scoreDescriptionBold: {
        ...appFonts.smBold
    },
    scoreLearnMore: {
        ...appFonts.smMedium,
        color: baseColors.secondary,
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3
    },
    attentionText: {
        flexDirection: 'row',
        color: baseColors.secondary
    },
    categoryName: {
        flexDirection: 'row'
    }
};
