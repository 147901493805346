import PropTypes from 'prop-types';
import React from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import classnames from 'classnames';
import { components as Core } from '../../../core';
import WithProductsListBase, { styles as baseStyles } from './ProductItemBase';
import { layoutStyle, imageSize, importantClass } from '../../../../styles';

const ProductItem = props => (props.item ? (
    <Core.TouchableItem
        onPressSingle={props.onDetailsPress}
        separator={props.separator}
        containerClassName={classnames(css(styles.item), props.mainContainerClassName)}
        innerContainerClassName={css(styles.innerContainer, layoutStyle.flex)}
        image={props.item.smallImageUrl ? props.item.smallImageUrl : null}
        imageWidth={imageSize.xs}
        imageHeight="auto"
        hasTouchableAnimation={!props.isDisabled}
        hasImageContainMode={true}>
        <div className={css(styles.itemContainer)}>
            <Core.TextSeeMore numberOfLines={3} className={css(styles.title)}>{props.item.productName}</Core.TextSeeMore>
            <div className={css(styles.details)}>{`${props.item.pointsRequired} ${props.externalRewardName}`}</div>
        </div>
    </Core.TouchableItem>
) : null);

ProductItem.propTypes = {
    item: PropTypes.object.isRequired,
    onDetailsPress: PropTypes.func,
    externalRewardName: PropTypes.string.isRequired,
    separator: PropTypes.bool,
    mainContainerClassName: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.array]),
    isDisabled: PropTypes.bool
};

ProductItem.defaultProps = {
    separator: true,
    mainContainerClassName: null,
    isDisabled: false,
    onDetailsPress: null
};

export default WithProductsListBase(ProductItem);

const styles = StyleSheet.create({
    ...baseStyles,
    title: importantClass({
        ...baseStyles.title,
        textAlign: 'left'
    })
});