import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import translate from '../../services/translate';
import { appFonts, baseColors, spacing, fontSize } from '../../../../styles/commonCSS';

export const SELECT_HEIGHT = 54;
export const LOGO_SIZE = 25;

export default function WithSelectFieldBase(WrappedComponent) {
    class SelectFieldBase extends PureComponent {
        static propTypes = {
            message: PropTypes.string,
            i18n: PropTypes.object.isRequired,
            shouldShowMessage: PropTypes.bool
        };

        static defaultProps = {
            message: undefined,
            shouldShowMessage: true
        };

        get message() {
            const { i18n, message, shouldShowMessage } = this.props;
            if (!shouldShowMessage) return undefined;
            return message || i18n.t('makeSelection');
        }

        render() {
            return (
                <WrappedComponent {...this.props} message={this.message} />
            );
        }
    }

    return translate()(SelectFieldBase);
}

export const styles = {
    overallWrapper: {
        marginTop: spacing.s3,
        height: SELECT_HEIGHT,
        paddingBottom: spacing.s0,
        flexDirection: 'row',
        borderWidth: 1,
        borderColor: baseColors.grey70,
        borderRadius: spacing.s1,
    },
    modifiedWrapper: {
        borderColor: baseColors.grey70,
        paddingBottom: 0,
    },
    titleText: {
        fontSize: fontSize.xSmall,
        color: baseColors.grey40,
        paddingTop: spacing.s0,
        paddingBottom: spacing.s0,
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3
    },
    titleTextDefault: {
        fontSize: fontSize.xSmall,
        color: baseColors.grey40,
        paddingTop: spacing.s0,
        paddingBottom: spacing.s0,
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3
    },
    valueText: {
        ...appFonts.mdRegular,
        color: baseColors.black,
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3,
        flex: 1
    },
    valueTextDefault: {
        ...appFonts.mdRegular,
        color: baseColors.grey40,
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3,
        flex: 1
    },
    emptyValueText: {
        ...appFonts.mdRegular,
        color: baseColors.grey40,
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3,
        flex: 1
    },
    iconWrapper: {
        justifyContent: 'center',
        alignItems: 'flex-end',
        flex: 1,
        paddingRight: spacing.s2,
    },
    body: {
        flex: 6
    },
    error: {
        ...appFonts.xsRegular,
        marginTop: spacing.s0,
        color: baseColors.dangerDarker
    },
    fieldContainerEmpty: {
        paddingTop: spacing.s3,
        paddingBottom: spacing.s1
    },
    modifiedFieldValue: {
        ...appFonts.smRegular,
        color: baseColors.grey20,
    },
    disabledField: {
        backgroundColor: baseColors.grey90
    },
    disabledText: {
        color: baseColors.grey40
    }
};
