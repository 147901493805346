import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { components as Core, ROUTES } from '../../../core';
import { appFonts, baseColors, importantClass, spacing } from '../../../../styles';
import PreviousGoalItem from '../PreviousGoalItem';
import WithPreviousGoalsBase from './PreviousGoalsBase';

class PreviousGoals extends Component {
    static propTypes = {
        goals: PropTypes.array,
        isLoadingMore: PropTypes.bool,
        history: PropTypes.object.isRequired,
        canLoadMore: PropTypes.bool.isRequired,
        loadMore: PropTypes.func.isRequired,
        keyExtractor: PropTypes.func.isRequired,
        i18n: PropTypes.object.isRequired,
        title: PropTypes.string.isRequired,
    };

    static defaultProps = {
        isLoadingMore: false,
        goals: []
    };

    goToDetails = challenge => this.props.history.push(ROUTES.challengeDetails(challenge.challengeId));

    get emptyComponent() {
        return (
            <Core.EmptyListSimple message={this.props.i18n.t('previousGoalsEmptyMessage')} />
        );
    }

    renderItem = goal => <PreviousGoalItem onPress={this.goToDetails} challengeId={goal.item.challengeId} />;

    render() {
        return (
            <Core.SmallerContainer
                widerHeaderComponent={(
                    <Core.EntityDetailsHeader
                        hasBackButton={true}
                        title={this.props.title}
                    />)
                }>
                <Core.SubHeader
                    title={this.props.title}
                    noTopPadding={true}
                    titleClassName={css(styles.subheaderTitle)}
                />
                <Core.InfiniteLazyListFadeIn
                    data={this.props.goals}
                    keyExtractor={this.props.keyExtractor}
                    renderItem={this.renderItem}
                    onEndReached={this.props.loadMore}
                    onEndReachedThreshold={0.1}
                    hasMore={this.props.canLoadMore}
                    isLoading={this.props.isLoadingMore}
                    ListEmptyComponent={this.emptyComponent}
                />
            </Core.SmallerContainer>
        );
    }
}

const styles = StyleSheet.create({
    subheaderTitle: importantClass({
        ...appFonts.xxxlBold,
        color: baseColors.black,
        paddingLeft: spacing.s5,
    }),
});

export default WithPreviousGoalsBase(PreviousGoals);
