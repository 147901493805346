import { NAME as name } from './constants';
import { createActionTypes } from '../core';

const NAME = name.toUpperCase();

export const GET_PRIVACY_SETTINGS = createActionTypes(`${NAME}/GET_PRIVACY_SETTINGS`);
export const SET_PRIVACY_SETTINGS = createActionTypes(`${NAME}/SET_PRIVACY_SETTINGS`);

export const GET_NOTIFICATIONS_SETTINGS = createActionTypes(`${NAME}/GET_NOTIFICATIONS_SETTINGS`);
export const SET_NOTIFICATIONS_SETTINGS = createActionTypes(`${NAME}/SET_NOTIFICATIONS_SETTINGS`);

export const SET_FEEDBACK_METADATA = createActionTypes(`${NAME}/SET_FEEDBACK_METADATA`);

export const POST_FEEDBACK = createActionTypes(`${NAME}/POST_FEEDBACK`);

export const GET_BIOMETRICS = createActionTypes(`${NAME}/GET_BIOMETRICS`);
export const SAVE_BIOMETRICS = createActionTypes(`${NAME}/SAVE_BIOMETRICS`);

export const GET_PERSONAL_SETTINGS = createActionTypes(`${NAME}/GET_PERSONAL_SETTINGS`);
export const SAVE_PERSONAL_SETTINGS = createActionTypes(`${NAME}/SAVE_PERSONAL_SETTINGS`);

export const UPDATE_USER_AVATAR = createActionTypes(`${NAME}/UPDATE_USER_AVATAR`);
export const DELETE_USER_AVATAR = createActionTypes(`${NAME}/DELETE_USER_AVATAR`);

export const GET_COMPANY_TASKS = createActionTypes(`${NAME}/GET_COMPANY_TASKS`);
export const GET_COMPANY_FEATURES = createActionTypes(`${NAME}/GET_COMPANY_FEATURES`);

export const GET_CURRENT_USER_PRIVACY_SETTINGS = createActionTypes(`${NAME}/GET_CURRENT_USER_PRIVACY_SETTINGS`);
export const SET_CURRENT_USER_PRIVACY_SETTINGS = createActionTypes(`${NAME}/SET_CURRENT_USER_PRIVACY_SETTINGS`);

export const SEND_HEALTH_SCORE_FEEDBACK = createActionTypes(`${NAME}/SEND_HEALTH_SCORE_FEEDBACK`);
export const SEND_USER_FEEDBACK = createActionTypes(`${NAME}/SEND_USER_FEEDBACK`);
export const SEND_FAMILY_MEMBER_INVITATION = createActionTypes(`${NAME}/SEND_FAMILY_MEMBER_INVITATION`);

export const HIDE_PRIVACY_INFORMATION_TEXT = `${NAME}/HIDE_PRIVACY_INFORMATION_TEXT`;
export const UPDATE_PRIVACY_INFORMATION_TEXT = `${NAME}/UPDATE_PRIVACY_INFORMATION_TEXT`;
export const RESET_IS_LANGUAGE_CHANGED = `${NAME}/RESET_IS_LANGUAGE_CHANGED`;
export const SELECT_WELLBEING_SCORE_TAB = `${NAME}/SELECT_WELLBEING_SCORE_TAB`;
export const RESET_WELLBEING_SCORE_TAB = `${NAME}/RESET_WELLBEING_SCORE_TAB`;
