import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { translate, tracker } from '../../../core';
import { appFonts } from '../../../../styles';

export default function WithRecommendedChallengesCardBase(WrappedComponent) {
    class RecommendedChallengesCardBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
        };

        get description() {
            return this.props.i18n.t('challengesCard.emptyStateTitle');
        }

        get buttonLabel() {
            return this.props.i18n.t('challengesCard.buttonLabel');
        }

        onButtonPress = () => {
            tracker.logEvent('SetMoreChallengesTile_ClickAction');
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    description={this.description}
                    buttonLabel={this.buttonLabel}
                    onButtonPress={this.onButtonPress}
                />
            );
        }
    }

    return (translate()(RecommendedChallengesCardBase));
}

export const styles = {
    description: {
        ...appFonts.mdRegular,
        textAlign: 'center'
    },
};