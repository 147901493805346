import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import classnames from 'classnames';
import Button from '../Button';
import Image from '../Image';
import { importantClass, spacing } from '../../../../styles';
import WithCreateEntityBase, { styles as baseStyles } from './CreateEntityBase';

class CreateEntity extends PureComponent {
    static propTypes = {
        title: PropTypes.string.isRequired,
        buttonLabel: PropTypes.string.isRequired,
        buttonHandler: PropTypes.func.isRequired,
        image: PropTypes.string.isRequired,
        sepatator: PropTypes.bool,
        subtitle: PropTypes.string,
        containerClassName: PropTypes.string,
    };

    static defaultProps = {
        sepatator: false,
        subtitle: null,
        containerClassName: null,
    };

    render() {
        const { title, subtitle, buttonLabel, buttonHandler, image, sepatator, containerClassName } = this.props;
        return (
            <>
                <div className={classnames(css(styles.cardContainer), containerClassName)}>
                    <Image
                        src={image}
                        className={css(styles.image)}
                        resizeMode="contain"
                    />
                    <p className={css(styles.title)}>{title}</p>
                    {subtitle ? <p className={css(styles.subtitle)}>{subtitle}</p> : null}
                    <Button
                        size="medium"
                        className={css(styles.button)}
                        onPress={buttonHandler}>
                        {buttonLabel}
                    </Button>
                </div>
                {sepatator ? <div className={css(styles.separator)} /> : null}
            </>
        );
    }
}

export default WithCreateEntityBase(CreateEntity);

const COMMON_MARGIN = spacing.s1;

export const styles = StyleSheet.create({
    ...baseStyles,
    button: importantClass(baseStyles.button),
    separator: {
        ...baseStyles.separator,
        marginTop: spacing.s5,
        marginBottom: COMMON_MARGIN,
        marginLeft: COMMON_MARGIN,
        marginRight: COMMON_MARGIN,
    },
});
