import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { appFonts, spacing, baseColors } from '../../../../../styles';
import { translate } from '../../../../core';
import * as selectors from '../../../selectors';

export default function WithGoalCardBase(WrappedComponent) {
    class GoalCardBase extends PureComponent {
        static propTypes = {
            isSelected: PropTypes.bool,
            buttonPress: PropTypes.func.isRequired,
            i18n: PropTypes.object.isRequired,
            challengeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            challenge: PropTypes.object.isRequired,
            headerIcon: PropTypes.object.isRequired,
            isSetting: PropTypes.bool,
            isSet: PropTypes.bool.isRequired,
        };

        static defaultProps = {
            isSelected: false,
            isSetting: false,
        };

        buttonPress = (challenge, isSet, setChallengeId) => {
            if (this.props.buttonPress) {
                this.props.buttonPress(challenge, this.node, isSet, setChallengeId);
            }
        };

        saveRef = ref => (this.node = ref);

        render() {
            return (
                <WrappedComponent {...this.props} buttonPress={this.buttonPress} saveRef={this.saveRef} />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => ({
        challenge: selectors.getRecommendedGoal(state, ownProps.challengeId),
        headerIcon: selectors.getHeaderIconRecommendedGoal(state, ownProps.challengeId),
    });

    return connect(mapStateToProps)(translate()(GoalCardBase));
}

export const styles = {
    cardDarkBodyTop: {
        paddingTop: spacing.s0,
        paddingBottom: spacing.s1,
    },
    cardDarkHeadingSub: {
        ...appFonts.smRegular,
        color: baseColors.grey20
    },
};
