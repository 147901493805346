import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { css, } from 'aphrodite-jss';
import { withRouter } from 'react-router-dom';
import VisibilitySensor from 'react-visibility-sensor';
import { components as Core } from '../../../../core';
import { layoutStyle } from '../../../../../styles';
import WithLeaderboardBase from './LeaderboardBase';
import LeaderboardItem from '../../LeaderboardItem';

const TOB_BAR_HEIGHT = 80;

class Leaderboard extends PureComponent {
    static propTypes = {
        leaderboard: PropTypes.object,
        challengeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        isCurrentUser: PropTypes.func.isRequired,
        itemLeaderboardRef: PropTypes.func.isRequired,
        isItemVisibleOnScroll: PropTypes.func.isRequired,
        nothingToShowText: PropTypes.string.isRequired,
        leaderboardItemsCount: PropTypes.number.isRequired,
    };

    static defaultProps = {
        leaderboard: undefined,
    };

    renderListItem = (item, index) => {
        const { challengeId, isItemVisibleOnScroll, isCurrentUser, itemLeaderboardRef, leaderboardItemsCount } = this.props;
        const isViewer = isCurrentUser(item);
        const listItem = (
            <LeaderboardItem
                itemIndex={index}
                leaderboard={item}
                isChallenge={true}
                challengeId={challengeId}
                isCurrentUser={isViewer}
                leaderboardItemsCount={leaderboardItemsCount}
                team={item}
            />
        );
        return isCurrentUser(item) ? (
            <VisibilitySensor
                onChange={isVisible => {
                    isItemVisibleOnScroll(isVisible);
                }}
                offset={{ top: TOB_BAR_HEIGHT }}>
                <div ref={itemLeaderboardRef}>
                    {listItem}
                </div>
            </VisibilitySensor>
        ) : listItem;
    };

    render() {
        const { leaderboard, nothingToShowText } = this.props;
        return (
            <Fragment>
                {_.get(leaderboard, 'items', []).length ? (
                    <Fragment>
                        <Core.List className={css(layoutStyle.listBorder)}>
                            {_.map(leaderboard.items, this.renderListItem)}
                        </Core.List>
                    </Fragment>
                ) : <Core.EmptyListSimple message={nothingToShowText} isIconHidden={true} />
                }
            </Fragment>
        );
    }
}

export default withRouter(WithLeaderboardBase(Leaderboard));
