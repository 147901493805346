import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import { baseColors, spacing, appFonts } from '../../../../styles';
import { translate, DATE_FORMATS, selectors as coreSelectors } from '../../../core';
import { getDeviceByVendorId, getDeviceWarningDatesByVendorId, getPlatformDevice, isSyncingDevice } from '../../selectors';
import { appsDevicesHelper } from '../../services';
import { selectors as challengeSelectors } from '../../../challenges';
import * as actions from '../../actions';
import { selectors as onboardingSelectors } from '../../../onboarding';

export default function WithDeviceItemBase(WrappedComponent) {
    class DeviceItemBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            deviceWarning: PropTypes.object.isRequired,
            device: PropTypes.object.isRequired,
            i18n: PropTypes.object.isRequired,
            deviceActivity: PropTypes.object,
            defaultDevice: PropTypes.object.isRequired,
            vendorId: PropTypes.string.isRequired,
            isChallenge: PropTypes.bool,
            isLiveBetter: PropTypes.bool
        };

        static defaultProps = {
            deviceActivity: null,
            isChallenge: false,
            isLiveBetter: false
        };

        formatLastSyncDate = (localization = undefined, options = undefined) => {
            if (this.getDeviceHasError()) {
                return this.props.i18n.t('appsDevices.deviceItem.unableToSync');
            }

            const { i18n, device, isLiveBetter } = this.props;
            if (!device) return i18n.t('not_synced');
            const tempDate = new Date(device.lastSyncDate);
            const date = tempDate.toLocaleDateString(isLiveBetter ? 'en-AU': localization, options);
            return moment(tempDate).isValid() ?
                `${date}, ${moment(tempDate).format(DATE_FORMATS.twelveHoursTime)}` :
                i18n.t('inProgress');
        };

        formatActivtyString = () => {
            const { deviceActivity } = this.props;

            if (deviceActivity) {
                return `${deviceActivity.activityName}, ${deviceActivity.quantity} ${deviceActivity.unit}`;
            }

            return null;
        };

        onDisconnect = () => this.props.actions.disconnectDevice(this.props.device);

        getDeviceHasWarning = () => {
            const { device } = this.props;
            return appsDevicesHelper.deviceHasWarning(device);
        };

        getDeviceHasError = () => {
            const { device } = this.props;
            return appsDevicesHelper.deviceHasError(device);
        };

        getDeviceStatusMuteHasExpired = () => {
            const { device, deviceWarning } = this.props;
            return appsDevicesHelper.deviceStatusMuteHasExpired(device, deviceWarning);
        };

        getShowDeviceErrorWarning = () => !!((this.getDeviceHasError() || this.getDeviceHasWarning()) && this.getDeviceStatusMuteHasExpired());

        getErrorWarningString = () => {
            if (this.getDeviceHasError()) {
                return this.props.i18n.t('appsDevices.deviceItem.error');
            } else if (this.getDeviceHasWarning()) {
                return this.props.i18n.t('appsDevices.deviceItem.warning');
            }

            return '';
        };

        get isDefaultDevice() {
            return !this.props.isChallenge && _.get(this.props.defaultDevice, 'vendorId') === this.props.vendorId;
        }

        get isConnected() {
            return this.props.device.connected;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    formatLastSyncDate={this.formatLastSyncDate}
                    onDisconnect={this.onDisconnect}
                    getDeviceHasError={this.getDeviceHasError}
                    getShowDeviceErrorWarning={this.getShowDeviceErrorWarning}
                    getErrorWarningString={this.getErrorWarningString}
                    formatActivtyString={this.formatActivtyString}
                    isDefaultDevice={this.isDefaultDevice}
                    isConnected={this.isConnected}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => ({
        device: getDeviceByVendorId(state, ownProps.vendorId),
        deviceWarning: getDeviceWarningDatesByVendorId(state, ownProps.vendorId),
        deviceActivity: challengeSelectors.getDeviceMostRecentActivity(state, ownProps.vendorId),
        isOnboarding: onboardingSelectors.isShowingOnboarding(state),
        isLiveBetter: coreSelectors.isLiveBetter(state),
        defaultDevice: getPlatformDevice(state),
        isSyncing: isSyncingDevice(state, ownProps.vendorId)
    });

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(DeviceItemBase));
}

const ICON_SIZE = spacing.s10;
const ICON_SIZE_CHALLENGE = spacing.s7;

export const styles = {
    mainContainer: {
        flex: 1,
        borderBottomWidth: 1,
        borderBottomColor: baseColors.grey70
    },
    card: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: baseColors.white,
        paddingTop: spacing.s3,
        paddingBottom: spacing.s3,
    },
    cardDetails: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'flex-start',
        paddingRight: spacing.s3
    },
    deviceName: {
        ...appFonts.mdMedium,
        color: baseColors.grey20
    },
    deviceDescription: {
        ...appFonts.xsRegular,
        paddingTop: spacing.s0
    },
    deviceIcon: {
        height: ICON_SIZE,
        width: ICON_SIZE,
        marginRight: spacing.s3
    },
    graphicContainer: {
        height: ICON_SIZE,
        alignSelf: 'flex-start'
    },
    animatedContainerStyle: {
        height: ICON_SIZE,
        alignItems: 'center',
        justifyContent: 'center'
    },
    disconnectContainer: {
        flex: 1,
        backgroundColor: baseColors.white,
        flexDirection: 'row',
        flexWrap: 'nowrap',
        paddingTop: spacing.s2,
        paddingBottom: spacing.s2
    },
    disconnectInnerContainer: {
        marginLeft: spacing.s1,
        backgroundColor: baseColors.danger,
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3,
        justifyContent: 'center'
    },
    disconnectText: {
        color: baseColors.white,
        ...appFonts.xsRegular,
        width: '100%'
    },
    statusSection: {
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: spacing.s0,
        paddingTop: spacing.s0,
        alignItems: 'center'
    },
    statusText: {
        ...appFonts.smRegular,
        color: baseColors.grey40,
    },
    warningText: {
        color: baseColors.warnDarker
    },
    errorText: {
        ...appFonts.smRegular,
        color: baseColors.dangerDarker
    },
    activityString: {
        ...appFonts.xsRegular,
        paddingTop: spacing.s0
    },
    default: {
        color: baseColors.grey40,
        ...appFonts.smRegular
    },
    deviceItemName: {
        ...appFonts.smBold,
        color: baseColors.black,
    },
    deviceIconChallenge: {
        height: ICON_SIZE_CHALLENGE,
        width: ICON_SIZE_CHALLENGE
    },
};
