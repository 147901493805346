import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { spacing, importantStyles } from '../../../../styles';
import { components as Core } from '../../../core';
import WithAddedUserItem, { styles as baseStyles } from './AddedUserItemBase';

class AddedUserItem extends PureComponent {
    static propTypes = {
        userName: PropTypes.string,
        removeUserHandler: PropTypes.func,
        item: PropTypes.object
    };

    static defaultProps = {
        userName: undefined,
        removeUserHandler: null,
        item: {}
    };

    get icon() {
        return this.props.item.inviteeEmail ? (
            <Core.Icon
                name="envelope"
                type="fa"
                size={spacing.s3}
                className={css(styles.envelope)}
            />
        ) : (
            <Core.Avatar
                disablePress={true}
                size="xmsmall"
                url={this.props.item.avatarUrl}
                id={this.props.item.id}
                name={this.props.userName}
            />
        );
    }

    render() {
        const classes = {
            deleteIcon: css(styles.deleteIcon),
            label: css(styles.label)
        };
        return (
            <Core.Chip
                classes={classes}
                onDelete={this.props.removeUserHandler}
                label={this.props.item.inviteeEmail || this.props.userName}
                icon={this.icon}
            />
        );
    }
}

const styles = StyleSheet.create(importantStyles({
    ...baseStyles,
    deleteIcon: {
        marginRight: spacing.s1
    },
    label: {
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3
    }
}));

export default WithAddedUserItem(AddedUserItem);
