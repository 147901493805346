import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { withRouter } from 'react-router-dom';
import { components as Core, ROUTES, tracker } from '../../../core';
import { spacing, baseColors, layoutStyle, important, importantClass } from '../../../../styles';
import WithGoalCarouselItemBase, { styles as baseStyles, CAROUSEL_ITEM_HEIGHT } from './GoalCarouselItemBase';
import * as Card from '../Card';
import BonusChallengeMark from '../BonusChallengeMark';
import WeeklyGoalTag from '../WeeklyGoalTag';
import GoalIcon from '../GoalIcon';

const CATEGORY_ICON_SIZE = spacing.s7;


class GoalCarouselItem extends PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
        goal: PropTypes.object.isRequired,
        buttonLabel: PropTypes.string.isRequired,
        isGoalSet: PropTypes.bool.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        isSetting: PropTypes.bool,
        setGoal: PropTypes.func.isRequired,
        slug: PropTypes.string.isRequired,
        isSettingGoals: PropTypes.bool
    };

    static defaultProps = {
        isSetting: false,
        isSettingGoals: false
    }

    onPress = () => {
        if (this.props.isGoalSet) {
            tracker.logEvent('MePage_Goal_View');
            this.props.history.push(ROUTES.challengeDetails(this.props.goal.challengeId));
        } else {
            this.props.setGoal();
        }
    };

    get cardHeader() {
        const { goal, slug, name } = this.props;
        return (
            <div className={css(styles.iconContainer, layoutStyle.cardPadding)}>
                <GoalIcon goal={goal} slug={slug} size={CATEGORY_ICON_SIZE} />
                <div className={css(layoutStyle.flexColumn, layoutStyle.flexJustifyCenter)}>
                    <Core.TextSeeMore
                        className={css(styles.name)}
                        numberOfLines={1}
                        isExpandHidden={true}
                        isCollapseHidden={true}>
                        {name}
                    </Core.TextSeeMore>
                    <WeeklyGoalTag goalName={goal.challengeId || name} containerClassName={css(styles.weeklyGoalContainer)} />
                </div>
            </div>
        );
    }

    get cardBody() {
        const { description, goal: { challengeId, goalName } } = this.props;
        return (
            <div className={css(styles.bodyContainer)}>
                <Core.TextSeeMore
                    className={css(styles.description)}
                    numberOfLines={2}
                    isExpandHidden={true}
                    isCollapseHidden={true}>
                    {description}
                </Core.TextSeeMore>
                <BonusChallengeMark
                    challengeId={challengeId || goalName}
                    isSmallerSize={true}
                    containerClassName={css(styles.bonusMarkContainer)}
                />
            </div>
        );
    }

    render() {
        return (
            <Card.Container hasPointer={false} containerClassName={css(styles.cardContainer)} >
                {this.cardHeader}
                <Card.Body isGrey={false} className={css(styles.cardBody)}>
                    {this.cardBody}
                </Card.Body>
                <Card.Footer isGrey={false}>
                    <Core.Button
                        type={this.props.isGoalSet || this.props.isSettingGoals ? 'outlined' : 'contained'}
                        className={css(layoutStyle.cardFooterButton)}
                        onPress={this.onPress}
                        circularColor={baseColors.white}
                        isLoading={this.props.isSetting}
                        disabled={this.props.isSettingGoals}
                        size="medium">
                        {this.props.buttonLabel}
                    </Core.Button>
                </Card.Footer>
            </Card.Container>
        );
    }
}

export default withRouter(WithGoalCarouselItemBase(GoalCarouselItem));

const styles = StyleSheet.create({
    ...baseStyles,
    name: {
        ...baseStyles.name,
        paddingTop: spacing.s0
    },
    description: {
        ...baseStyles.description,
        marginTop: important(0),
        textAlign: 'left'
    },
    iconContainer: {
        paddingTop: spacing.s3,
        paddingBottom: spacing.s0,
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center'
    },
    bodyContainer: {
        flexDirection: 'column'
    },
    cardContainer: importantClass({
        height: CAROUSEL_ITEM_HEIGHT,
        flex: 1
    }),
    weeklyGoalContainer: {
        marginLeft: spacing.s1
    },
    cardBody: importantClass({
        flex: 'none',
        marginBottom: 'auto',
        paddingTop: spacing.s0,
    }),
});
