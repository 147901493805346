import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { css, StyleSheet } from 'aphrodite-jss';
import { components as Core } from '../../../../core';
import WithNameEditorBase, { styles as baseStyles } from './NameEditorBase';
import { layoutStyle, importantStyles } from '../../../../../styles';

class NameEditor extends Component {
    static propTypes = {
        saveChanges: PropTypes.func,
        onRef: PropTypes.func,
        updateUserValue: PropTypes.func,
        isLoading: PropTypes.bool,
        isSaving: PropTypes.bool,
        profileFields: PropTypes.array.isRequired,
        isHRIS: PropTypes.bool,
        errors: PropTypes.object,
        i18n: PropTypes.object.isRequired
    };

    static defaultProps = {
        isLoading: false,
        isSaving: false,
        isHRIS: false,
        onRef: null,
        saveChanges: null,
        updateUserValue: null,
        errors: {}
    };

    componentDidMount() {
        if (this.props.onRef) this.props.onRef(this);
    }

    componentWillUnmount() {
        if (this.props.onRef) this.props.onRef(null);
    }

    render() {
        const { i18n, profileFields, errors, isLoading, isSaving } = this.props;
        return (
            <div className={css(layoutStyle.flex1)}>
                <Core.SubHeader
                    title={i18n.t('name')}
                    noTopPadding={true}
                    right={<Core.Button size={Core.Button.SIZES.medium} onPress={this.props.saveChanges} className={css(styles.saveButton)}>{this.props.i18n.t('save')}</Core.Button>}
                />
                <div className={css(styles.innerContainer)}>
                    {_.map(profileFields, item => (
                        <Core.Input
                            label={i18n.t(`${item.name}`)}
                            onChangeText={this.props.updateUserValue(item)}
                            disabled={item.readOnly}
                            fullWidth={true}
                            value={item.value}
                            error={!!errors[item.stateKey]}
                            helperText={errors[item.stateKey]}
                            key={item.name}
                        />
                    ))}
                </div>
                <Core.BlockingLoading isLoading={isLoading || isSaving} />
            </div>
        );
    }
}

const styles = StyleSheet.create({
    ...baseStyles,
    ...importantStyles(
        {
            saveButton: {
                minWidth: 100
            }
        })
});

export default WithNameEditorBase(NameEditor);
