import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { spacing, baseColors, appFonts } from '../../../../styles';
import { translate, getImageProp, tracker, openUrl } from '../../../core';

export default function WithResourceLinkItemBase(WrappedComponent) {
    class ResourceLinkItemBase extends PureComponent {
        static propTypes = {
            item: PropTypes.object.isRequired,
            i18n: PropTypes.object.isRequired
        };

        get image() {
            const { previewImageURL } = this.props.item;
            return getImageProp(previewImageURL);
        }

        get title() {
            return this.props.item.title;
        }

        goToResourceLinkItem = () => {
            const { id, url } = this.props.item; 
            tracker.logEvent('resources', { event: 'goToResourceLinkItem', from: 'resourcesList', id });
            openUrl(url);
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    image={this.image}
                    title={this.title}
                    goToResourceLinkItem={this.goToResourceLinkItem}
                />
            );
        }
    }

    return translate()(ResourceLinkItemBase);
}

export const styles = {
    card: {
        backgroundColor: baseColors.white,
        marginLeft: spacing.s3,
        marginRight: spacing.s3
    },
    title: {
        ...appFonts.mdMedium,
        color: baseColors.black
    }
};
