import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { translate } from '../../../core';
import { appFonts, baseColors, spacing } from '../../../../styles';

export default function WithHighestProgressIndicatorBase(WrappedComponent) {
    class HighestProgressIndicatorBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired
        };

        get highestLevelIndicator() {
            return this.props.i18n.t('highestLevelIndicatorNew');
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    highestLevelIndicatorText={this.highestLevelIndicator}
                />
            );
        }
    }

    return translate()(HighestProgressIndicatorBase);
}
export const styles = {
    indicatorText: {
        ...appFonts.mdMedium,
        marginLeft: spacing.s1,
        color: baseColors.successDarker
    }
};
