import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { css, StyleSheet } from 'aphrodite-jss';
import NotificationSection from '../NotificationSection';

import BonusChallengeMark from '../../BonusChallengeMark';
import ChallengeInstruction from '../ChallengeInstruction';
import { components as Core, ROUTES } from '../../../../core';
import { baseColors, layoutStyle, importantClass, spacing } from '../../../../../styles';
import WithChallengeSubHeaderBase, { styles as baseStyles, DISCLAIMER_ICON_SIZE } from './ChallengeSubHeaderBase';

class ChallengeSubHeader extends PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
        isTeamSizeIncorrect: PropTypes.bool.isRequired,
        isPersonal: PropTypes.bool.isRequired,
        hasDisclaimer: PropTypes.bool.isRequired,
        challengeName: PropTypes.string.isRequired,
        disclaimerTitle: PropTypes.string.isRequired,
        disclaimerSubtitle: PropTypes.string.isRequired,
        teamSizeMessage: PropTypes.string.isRequired,
        challengeDateInfo: PropTypes.object.isRequired,
        useDangerColor: PropTypes.bool.isRequired,
    };

    get title() {
        return (
            <Core.FixedHeader
                fullWidth={true}
                subheader={(
                    <Core.SubHeader
                        title={this.props.challengeName}
                        className={css(styles.fixedHeader)}
                    />
                )}
            />
        );
    }

    get disclaimer() {
        const { hasDisclaimer, disclaimerTitle, disclaimerSubtitle } = this.props;
        return hasDisclaimer ? (
            <button className={css(layoutStyle.fw)} onClick={this.openDevices}>
                <NotificationSection
                    title={disclaimerTitle}
                    text={disclaimerSubtitle}
                    bg={baseColors.dangerLightest}
                    leftIcon={this.disclaimerIcon()}
                    detailColor={baseColors.dangerDarkest}
                    titleClassName={css(styles.disclaimerTitle)}
                    textClassName={css(styles.disclaimerText)}
                    customNotificationClassName={styles.notificationBlockContainer}
                />
            </button>
        ) : null;
    }

    get appDeviceNotification() {
        const { isPersonal } = this.props;
        if (isPersonal) {
            return (
                <div>
                    {this.disclaimer}
                </div>
            );
        }
        return this.disclaimer;
    }

    get teamSizeNotification() {
        const { isTeamSizeIncorrect, teamSizeMessage } = this.props;
        if (isTeamSizeIncorrect) {
            return (
                <NotificationSection
                    title={teamSizeMessage}
                    bg={baseColors.dangerLightest}
                    leftIcon={this.disclaimerIcon()}
                    detailColor={baseColors.dangerDarkest}
                    titleClassName={css(styles.disclaimerTitle)}
                    customNotificationClassName={styles.notificationBlockContainer}
                />
            );
        }
        return null;
    }

    get challengeDateInfo() {
        const { challengeDateInfo: { duration, details }, useDangerColor } = this.props;
        return (
            (duration || details) &&
            (
                <div className={css(styles.dateInfo)}>
                    {duration && <p className={css(styles.duration)}>{duration}</p>}
                    {details && <p className={useDangerColor && css(styles.dangerColor)}>{details}</p>}
                </div>
            )
        );
    }

    get bonusChallengeMark() {
        return (
            <BonusChallengeMark
                shouldShowMoreInfo={true}
                challengeId={this.props.challengeId}
                isChallengeDetailsPage={true}
                containerClassName={css(styles.bonusMarkContainer)}
            />
        );
    }

    openDevices = () => this.props.history.push(ROUTES.appsDevices());

    disclaimerIcon = () => (
        <Core.Icon
            type="fa"
            fill="solid"
            name="exclamation-circle"
            color={baseColors.danger}
            size={DISCLAIMER_ICON_SIZE}
            className={css(styles.disclaimerIcon)}
        />
    );

    render() {
        return (
            <Fragment>
                {/*{this.title}*/}
                {this.challengeDateInfo}
                <ChallengeInstruction challengeId={this.props.challengeId} />
                {this.bonusChallengeMark}
                {this.appDeviceNotification}
                {this.teamSizeNotification}
            </Fragment>
        );
    }
}

export default withRouter(WithChallengeSubHeaderBase(ChallengeSubHeader));

const styles = StyleSheet.create({
    ...baseStyles,
    notificationBlockContainer: {
        marginLeft: 0,
        marginRight: 0,
        marginTop: spacing.s3,
        marginBottom: 0,
        justifyContent: 'flex-start',
    },
    disclaimerTitle: importantClass({
        ...baseStyles.disclaimerTitle,
    }),
    disclaimerText: importantClass({
        ...baseStyles.disclaimerText,
    }),
    bonusMarkContainer: {
        marginTop: spacing.s3,
    },
});