import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { translate } from '../../../core';
import * as actions from '../../actions';
import * as selectors from '../../selectors';

export default function WithExploreCommunitiesBase(WrappedComponent) {
    class ExploreCommunitiesBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            communities: PropTypes.array,
            i18n: PropTypes.object.isRequired
        };

        static defaultProps = {
            communities: []
        };

        constructor(props) {
            super(props);
            this.props.actions.getRecommendedCommunities();
        }

        joinCommunity = (item, isInvitation) => this.props.actions.joinCommunity(item.id, isInvitation,
            {
                successToast: true,
                dangerToast: true,
                successToastMessage: this.props.i18n.t('joining.success.message', { name: item.name })
            });

        get hasCommunities() {
            return !!this.props.communities.length;
        }

        get communitiesTitle() { return this.props.i18n.t('communities'); }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    hasCommunities={this.hasCommunities}
                    joinCommunity={this.joinCommunity}
                    communitiesTitle={this.communitiesTitle}
                />
            );
        }
    }

    const mapStateToProps = state => (
        {
            communities: selectors.getRecommendedCommunities(state, true),
            communitiesLoading: selectors.communitiesLoading(state)
        });

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(ExploreCommunitiesBase));
}