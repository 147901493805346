import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { getUserName, translate } from '../../../core';
import { appFonts, baseColors, spacing } from '../../../../styles';


export default function WithInvitationItemBase(WrappedComponent) {
    class InvitationItemBase extends PureComponent {
        static propTypes = {
            item: PropTypes.object.isRequired,
            onToggle: PropTypes.func,
            containerStyles: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
            invitedMembers: PropTypes.array.isRequired,
            i18n: PropTypes.object.isRequired,
            isUserInvitation: PropTypes.bool
        };

        static defaultProps = {
            onToggle: null,
            containerStyles: {},
            isUserInvitation: true
        };

        constructor(props) {
            super(props);
            this.item = {
                name: props.isUserInvitation ? this.userName : props.item.label,
                id: props.item.id,
                url: props.isUserInvitation ? props.item.avatarUrl : undefined,
            };
        }


        onToggle = () => {
            this.props.onToggle(this.props.item, !!this.isSelected);
        };

        get isSelected() {
            return _.find(this.props.invitedMembers, { id: this.props.item.id });
        }

        get userName() {
            return getUserName(this.props.item);
        }

        get department() {
            return this.props.item.department || this.props.i18n.t('no_department');
        }

        get location() {
            return this.props.item.location || this.props.i18n.t('no_location');
        }

        get color() {
            return this.isSelected ? baseColors.primary : baseColors.secondary;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    onToggle={this.onToggle}
                    isSelected={this.isSelected}
                    userName={this.userName}
                    department={this.department}
                    location={this.location}
                    color={this.color}
                    item={this.item}
                />
            );
        }
    }

    return translate()(InvitationItemBase);
}

export const styles = {
    mainLikeContainer: {
        backgroundColor: baseColors.white,
        flexDirection: 'row',
        flex: 1,
    },
    userPanel: {
        flexDirection: 'row',
        flex: 1,
        paddingTop: spacing.s3,
        paddingBottom: spacing.s3,
        borderBottomWidth: 1,
        borderBottomColor: baseColors.grey80,
        alignItems: 'center',
    },
    userContainer: {
        flexDirection: 'row',
        flex: 1,
    },
    cardHeader: {
        marginLeft: spacing.s2,
        flexDirection: 'column',
        flex: 1,
        marginRight: spacing.s3
    },
    userNameText: {
        flex: 1,
        ...appFonts.lgRegular
    },
    userNameSelectedText: {
    },
    additionalInfoContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    additionalInfoText: {
        paddingRight: spacing.s3,
        ...appFonts.smRegular
    },
    locationIconWrapper: {
        paddingRight: spacing.s0
    },
    locationIcon: {
        alignSelf: 'center'
    }
};
