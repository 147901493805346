import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ReactApexChart from 'react-apexcharts';
import classnames from 'classnames';
import { baseColors, appFonts } from '../../../../styles';
import { numberWithCommas } from '../../services/numbers';

const MAX_WIDTH_YAXIS = 160;
const DAILY_MAX= 100;
const COLUMN_WIDTH = '70%';

const BarChart = ({ data, isDaily, dayState, height, tooltipStyles, dailyStateStyles, changeBarIndex, unit, hasProgressPerPeriod }) => {
    const apex = {
        series: [{ data }],
        options: {
            chart: {
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false,
                },
                selection: {
                    enabled: false,
                },
            },
            plotOptions: {
                bar: {
                    columnWidth: COLUMN_WIDTH
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: false
            },
            grid: {
                show: hasProgressPerPeriod,
                row: {
                    colors: [baseColors.white]
                }
            },
            xaxis: {
                labels: {
                    show: false
                },
                categories: [],
                tickPlacement: 'on',
                axisTicks: {
                    show: false,
                },
                axisBorder: {
                    height: 1,
                    show: false,
                    color: baseColors.grey70,
                },
            },
            states: {
                hover: {
                    filter: {
                        type: 'darken',
                        value: 0.95,
                    },
                },
                active: {
                    filter: {
                        type: 'darken',
                        value: 1,
                    },
                },
            },
            yaxis: {
                opposite: true,
                max: isDaily ? DAILY_MAX : Math.max(...data),
                min: 0,
                tickAmount: isDaily ? 2 : undefined,
                forceNiceScale: true,
                labels: {
                    show: hasProgressPerPeriod,
                    align: 'right',
                    minWidth: 0,
                    maxWidth: MAX_WIDTH_YAXIS,
                    style: {
                        colors: [baseColors.grey40],
                        cssClass: 'apexcharts-yaxis-label',
                        ...appFonts.smRegular,
                    },
                    offsetX: 0,
                    offsetY: 0,
                    rotate: 0,
                    formatter: value => `${numberWithCommas(value.toFixed())}${isDaily ? '%' : ''}`,
                },
            },
            tooltip: {
                enabled: true,
                enabledOnSeries: undefined,
                shared: true,
                followCursor: false,
                intersect: false,
                inverseOrder: false,
                custom: ({ series, dataPointIndex, w }) => {
                    changeBarIndex(dataPointIndex);
                    let value = '';
                    const hasValue = series[0][dataPointIndex] > 0;
                    if (!hasValue && !parseInt(dayState)) return value;
                    const pointValue = series[0][dataPointIndex];
                    const YValue = (pointValue > 100 && isDaily) ? 100 : pointValue;
                    if (hasValue) {
                        value = `${(numberWithCommas(_.round(YValue)))}${isDaily ? `${'%'}` : ` ${unit}`} `;
                    }
                    const elements = `
                        <div class=${hasValue && classnames(tooltipStyles)}>
                                <span>${value}</span>
                                ${isDaily ? `<span class=${classnames(dailyStateStyles)}>${dayState}</span>` : ''}
                        </div>
                    `;
                    return elements;
                },
                fillSeriesColor: false,
                theme: false,
                onDatasetHover: {
                    highlightDataSeries: false,
                },
                x: {
                    show: false,
                },
                y: {
                    formatter: undefined,
                    title: {
                        formatter: () => ''
                    },
                },
                marker: {
                    show: false,
                },
                items: {
                    display: 'flex',
                },
                fixed: {
                    enabled: false,
                    position: 'topRight',
                },
            },
            fill: {
                colors: baseColors.primary,
                opacity: 1,
            },
        },
    };
    return <ReactApexChart options={apex.options} series={apex.series} type="bar" height={height} />;
};

BarChart.propTypes = {
    tooltipStyles: PropTypes.string,
    data: PropTypes.array.isRequired,
    unit: PropTypes.string.isRequired,
    isDaily: PropTypes.bool.isRequired,
    height: PropTypes.number.isRequired,
    changeBarIndex: PropTypes.func.isRequired,
    hasProgressPerPeriod: PropTypes.bool.isRequired,
};

BarChart.defaultProps = {
    tooltipStyles: null,
};

export default BarChart;
