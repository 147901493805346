import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { translate } from '../../../core';
import { EVENT_ENTITY_TYPES } from '../../constants';

export default function WithAllTabBase(WrappedComponent) {
    class AllTabBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
        };

        onRef = ref => (this[EVENT_ENTITY_TYPES.ALL_ITEMS] = ref);

        render() {
            return (
                <WrappedComponent
                    onRef={this.onRef}
                    {...this.props}
                />
            );
        }
    }

    return translate()(AllTabBase);
}