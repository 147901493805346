export const enter = handler => event => generalHandler(event, 13, handler);
export const esc = handler => event => generalHandler(event, 27, handler);

const TAB_KEY = 9;

const generalHandler = (event, code, handler) => {
    if (event.keyCode === TAB_KEY) return;
    event.preventDefault();
    event.stopPropagation();
    if (event.keyCode === code) handler(event);
};
