import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { css, StyleSheet } from 'aphrodite-jss';
import { withRouter } from 'react-router-dom';
import { layoutStyle, spacing, baseColors } from '../../../../../styles';
import Avatar from '../../Avatar';
import WithAttendeeItemBase, { styles as baseStyles } from './AttendeeItemBase';
import { onKeyPress } from '../../../services';
import Icon from '../../Icon';

class AttendeeItem extends PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        imageUrl: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        isInviter: PropTypes.bool,
        onPress: PropTypes.func,
        isPrivate: PropTypes.bool,
        shouldShowInvitedBy: PropTypes.bool,
        invitedBy: PropTypes.object,
        hasChevron: PropTypes.bool
    };

    static defaultProps = {
        imageUrl: undefined,
        isInviter: false,
        onPress: null,
        isPrivate: false,
        shouldShowInvitedBy: false,
        invitedBy: undefined,
        hasChevron: false
    };

    onPress = () => {
        if (this.props.onPress && !this.props.isPrivate) {
            this.props.onPress(this.props.id);
        }
    };

    get invitedByDetails() {
        const { shouldShowInvitedBy, invitedBy } = this.props;
        if (shouldShowInvitedBy && invitedBy) {
            return (
                <div className={css(styles.invitedByRow)}>
                    <Avatar
                        url={invitedBy.image}
                        name={invitedBy.name}
                        size="xsmall"
                        className={css(styles.smallAvatar)}
                        disablePress={true}
                    />
                    <p className={css(styles.invitedByString)}>{invitedBy.string}</p>
                </div>
            );
        }

        return null;
    }

    render() {
        return (
            <div
                tabIndex="0"
                className={css(layoutStyle.db, styles.itemContainer, this.props.isPrivate ? layoutStyle.noCursor : null)}
                onClick={this.onPress}
                role="button"
                onKeyDown={onKeyPress.enter(this.onPress)}>
                {this.props.isPrivate ? (
                    <div className={css(styles.iconAvatarContainer)}>
                        <Icon name="user" type="fa" fill="light" size={spacing.s4} className={css(styles.icon)} />
                    </div>
                ) : (
                    <Avatar
                        size="small"
                        url={this.props.imageUrl}
                        name={this.props.title}
                        disablePress={true}
                    />
                )}
                <div className={css(styles.itemDetails)}>
                    <p className={css(styles.title)}>{this.props.title}</p>
                    <p className={css(styles.description)}>{this.props.description}</p>
                    {this.invitedByDetails}
                </div>
                {this.props.hasChevron ? (
                    <Icon
                        type="fa"
                        size={spacing.s3}
                        name="chevron-right"
                        color={baseColors.grey40}
                    />
                )
                    : null}
            </div>
        );
    }
}

export default withRouter(WithAttendeeItemBase(AttendeeItem));

const ICON_BACKGROUND_SIZE = 40;

const styles = StyleSheet.create({
    ...baseStyles,
    itemContainer: {
        ...baseStyles.itemContainer,
        display: 'flex',
        borderBottomStyle: 'solid',
        '&:hover': {
            backgroundColor: baseColors.grey90
        }
    },
    iconAvatarContainer: {
        ...baseStyles.iconAvatarContainer,
        display: 'flex',
        height: ICON_BACKGROUND_SIZE,
        width: ICON_BACKGROUND_SIZE
    },
    icon: {
        alignSelf: 'center'
    },
    invitedByRow: {
        ...baseStyles.invitedByRow,
        display: 'flex',
        alignItems: 'center',
    },
});