import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../actions';
import { translate } from '../../../core';
import * as selectors from '../../selectors';

export default function JoinChallengeByTeamIdBase(WrappedComponent) {
    class JoinChallengeByTeamId extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            onRef: PropTypes.func.isRequired,
            isJoiningChallengeByTeamId: PropTypes.bool,
            joiningChallengeByTeamIdError: PropTypes.object,
            teamId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        };

        static defaultProps = {
            isJoiningChallengeByTeamId: false,
            joiningChallengeByTeamIdError: undefined,
            teamId: undefined,
        };

        componentDidUpdate(prevProps) {
            if (prevProps.isJoiningChallengeByTeamId && !this.props.isJoiningChallengeByTeamId) {
                if (this.props.joiningChallengeByTeamIdError) {
                    this.props.actions.clearJoinChallengeByTeamIdError(this.props.teamId);
                    _.has(this, 'wrapped.goToChallenges') && this.wrapped.goToChallenges();
                } else {
                    _.has(this, 'wrapped.goToChallengeDetails') && this.wrapped.goToChallengeDetails();
                }
            }
        }

        joinChallengeByTeamId = () => this.props.teamId && this.props.actions.joinChallengeByTeamId(this.props.teamId);

        saveRef = ref => (this.wrapped = ref);

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    ref={this.saveRef}
                    joinChallengeByTeamId={this.joinChallengeByTeamId}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const teamId = ownProps.teamId || _.get(ownProps, 'match.params.id');
        return {
            teamId,
            isJoiningChallengeByTeamId: selectors.isJoiningChallengeByTeamId(state, teamId),
            joiningChallengeByTeamIdError: selectors.getJoiningByTeamIdError(state, teamId),
            challengeId: selectors.getChallengeIdByTeamId(state, teamId)
        };
    };
    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(JoinChallengeByTeamId));
}
