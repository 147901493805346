import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { translate, ENTITIES, getImageProp } from '../../../../core';
import { imageSize } from '../../../../../styles';

export default function WithFeedItemImageBase(WrappedComponent) {
    class FeedItemImageBase extends PureComponent {
        static propTypes = {
            stream: PropTypes.object.isRequired,
        };

        get isChallenge() {
            return this.props.stream.relationTypeSlug === ENTITIES.challenge;
        }

        get entity() {
            return this.props.stream.relatedItemDetails;
        }

        get entityType() {
            return this.props.stream.relationTypeSlug;
        }

        get challengeId() {
            return this.entity.challengeId;
        }

        get entityImageSource() {
            switch (this.entityType) {
                case ENTITIES.group: return getImageProp(this.entity.groupImageURL);
                case ENTITIES.challenge: return getImageProp(this.entity.challengeImageURL);
                case ENTITIES.event: return getImageProp(this.entity.eventImageURL);
                default: return getImageProp();
            }
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    isChallenge={this.isChallenge}
                    challengeId={this.challengeId}
                    entityImageSource={this.entityImageSource}
                />
            );
        }
    }

    return translate()(FeedItemImageBase);
}

export const styles = {
    exploreHeightImage: {
        height: imageSize.lg
    }
};