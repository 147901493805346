import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { translate, constants as coreConstants } from '../../../core';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import { CHALLENGE_TYPES, CREATE_CHALLENGE_STEPS as STEPS, FREQUENCY, CHALLENGE_REQUIREMENTS,
    CREATE_CHALLENGE_ITEM_IDS as ITEM_IDS, CREATE_CHALLENGE_DEFAULT_PROPS as DEFAULT_PROPS } from '../../constants';

export const NEXT_SCREENS_CONFIG = {
    [ITEM_IDS.goal]: {
        step: STEPS.frequency,
        getData: { type: CHALLENGE_TYPES.goal, requirement: CHALLENGE_REQUIREMENTS.combo }
    },
    [ITEM_IDS.competition]: {
        step: STEPS.unitType,
        getData: { type: CHALLENGE_TYPES.competition, requirement: CHALLENGE_REQUIREMENTS.all, frequency: FREQUENCY.total }
    },

    [ITEM_IDS.personalGoal]: {
        step: STEPS.frequency,
        getData: DEFAULT_PROPS.personalGoal(),
    },

    [ITEM_IDS.teamSize]: {
        step: STEPS.type
    },

    [ITEM_IDS.frequency]: {
        step: STEPS.unitType
    },

    [ITEM_IDS.category]: {
        step: STEPS.category
    },
    [ITEM_IDS.activities]: {
        step: STEPS.activities
    },

    [ITEM_IDS.categorySelected]: {
        step: STEPS.categoryQuantity
    },
    [ITEM_IDS.categoryQuantity]: {
        step: STEPS.date
    },
    [ITEM_IDS.categoryCompetitionSelected]: {
        step: STEPS.date
    },
    [ITEM_IDS.activitiesSelected]: {
        step: STEPS.date
    },

    [ITEM_IDS.date]: {
        step: STEPS.image
    },

    [ITEM_IDS.image]: {
        step: STEPS.finalize
    },

    [ITEM_IDS.finalize]: {
    },

    [ITEM_IDS.discard]: {
        action: 'discard'
    },

    [ITEM_IDS.created]: {
        action: '_finish'
    },
    [ITEM_IDS.department]: {
        step: STEPS.type
    },
    [ITEM_IDS.region]: {
        step: STEPS.type
    }
};

export default function WithCreateChallengeBase(WrappedComponent) {
    class CreateChallengeBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            isOnboarding: PropTypes.bool,
            step: PropTypes.string,
            newChallenge: PropTypes.object,
            onClose: PropTypes.func,
            finishRedirect: PropTypes.string,
            dismissModal: PropTypes.bool,
        };

        static defaultProps = {
            step: undefined,
            isOnboarding: false,
            newChallenge: null,
            onClose: null,
            finishRedirect: undefined,
            dismissModal: false,
        };

        constructor(props) {
            super(props);
            const { onClose, step } = props;
            this.propsToPass = {
                onPress: this.openNextPage,
                onClose,
                title: this.title,
                step,
                challengeTypeTitle: this.challengeTypeTitle,
                challengeType: this.challengeType
            };
            this.wrapped = React.createRef();
        }

        get title() {
            if (this.props.isOnboarding) return '';
            return this.props.newChallenge.solo === 1
                ? this.props.i18n.t('createChallenge.navigatorTitleGoal')
                : this.props.i18n.t('createChallenge.navigatorTitle');
        }

        get challengeTypeTitle() {
            return this.props.i18n.t('createChallenge.typeTitle', { challengeType: this.challengeType });
        }

        get challengeType() {
            const { newChallenge, i18n } = this.props;
            return _.toLower(i18n.t(newChallenge.solo === 1 ? coreConstants.ENTITIES.goal : coreConstants.ENTITIES.challenge));
        }

        openNextPage = item => {
            const screenConfig = NEXT_SCREENS_CONFIG[item.id];
            const data = _.get(screenConfig, 'getData', item.data);
            if (data) this.props.actions.updateNewChallenge(data);
            if (screenConfig.action) this[screenConfig.action]();
            if (screenConfig.step && this.wrapped.current) {
                this.updateLocalRegistrationStep(screenConfig.step);
                this.wrapped.current.openNextPage(screenConfig);
            }
        };

        discard = () => {
            this.clear();
            this.wrapped.current.discard();
        };

        _finish = () => {
            this.clear();
            this.wrapped.current.finish();
        };

        clear = () => {
            this.props.actions.updateNewChallenge();
            this.props.actions.updateCreateChallengeStep(undefined, undefined, true);
        };

        updateLocalRegistrationStep = nextStep => {
            this.props.actions.updateCreateChallengeStep(nextStep);
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    ref={this.wrapped}
                    updateLocalRegistrationStep={this.updateLocalRegistrationStep}
                    originStep={this.props.step}
                    propsToPass={this.propsToPass}
                    title={this.title}
                    discard={this.discard}
                    clear={this.clear}
                />
            );
        }
    }


    function mapStateToProps(state, ownProps) {
        return {
            newChallenge: selectors.getNewChallenge(state),
            step: ownProps.step || _.get(ownProps, 'route.params.step'),
            onClose: ownProps.onClose || (_.get(ownProps, 'route.params.dismissModal') ? ownProps.navigation.goBack : null),
            localStep: selectors.getCreateChallengeStep(state),
            localSteps: selectors.getCreateChallengeSteps(state),
            finishRedirect: ownProps.finishRedirect || _.get(ownProps, 'location.state.finishRedirect'),
            lastCreatedChallengeId: selectors.getLastCreatedChallengeId(state)
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(CreateChallengeBase));
}
