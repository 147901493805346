import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite-jss';
import { components as Core } from '../../../core';
import { layoutStyle, spacing, importantClass } from '../../../../styles';
import WithCompetitionActivityHistoryBase, { styles as baseStyles,
    MAX_PERCENTAGE_STRING, MIN_PERCENTAGE_STRING, LOAD_NUMBER } from './CompetitionActivityHistoryBase';

class CompetitionActivityHistory extends PureComponent {
    static propTypes = {
        progressDetailsText: PropTypes.string.isRequired,
        noActivitiesText: PropTypes.string.isRequired,
        challenge: PropTypes.object.isRequired,
        isLoading: PropTypes.bool,
        activityLogs: PropTypes.object.isRequired,
        activityItemHeaderProps: PropTypes.object.isRequired,
        activityItemDetailsProps: PropTypes.object.isRequired,
        activityDetailsProps: PropTypes.object.isRequired,
        isWeeklyPeriod: PropTypes.bool.isRequired,
        isDailyPeriod: PropTypes.bool.isRequired,
        getFormattedDate: PropTypes.func.isRequired,
        getDatesInPeriod: PropTypes.func.isRequired,
        daysWithActivities: PropTypes.array.isRequired,
        challengePreviousDays: PropTypes.array.isRequired,
        getDatesInWeekPeriod: PropTypes.func.isRequired,
        isRestartGoal: PropTypes.bool.isRequired
    };

    static defaultProps = {
        isLoading: false
    };

    constructor(props) {
        super(props);
        const periods = this.isWeekly ? props.activityLogs : this.dailyLogs;
        this.state = {
            activeIndex: null,
            periodsToRender: _.take(periods, LOAD_NUMBER),
            loadsQuantity: periods && periods.length ? 1 : 0,
        };
    }

    get dailyLogs() {
        const { challenge, activityLogs } = this.props;
        return challenge && this.getDailyLogs(activityLogs);
    }

    get isWeekly() {
        const { isWeeklyPeriod, isRestartGoal } = this.props;
        return isWeeklyPeriod || isRestartGoal;
    }

    onEndReached = () => this.setState(prevState => {
        const { activityLogs } = this.props;
        const periods = this.isWeekly ? activityLogs : this.dailyLogs;
        const renderedLength = prevState.loadsQuantity * LOAD_NUMBER;
        const nextRenderedLength = renderedLength + LOAD_NUMBER;
        const end = nextRenderedLength > periods.length ? periods.length : nextRenderedLength;
        return {
            periodsToRender: [...prevState.periodsToRender, ..._.slice(periods, renderedLength, end)],
            loadsQuantity: prevState.loadsQuantity + 1,
        };
    });

    getActiveIndex = activeIndex => {
        activeIndex !== null && this.setState({ activeIndex });
    }

    renderActivities = logs => (
        <div className={css(styles.itemContainer)}>
            {_.map(logs, activity => {
                const { activityDetails, activityPoints } = this.props.activityDetailsProps(activity);
                return (
                    <div className={css(styles.activity)}>
                        <div className={css(layoutStyle.flexColumn)}>
                            <p className={css(styles.activityName)}>{activity.activityName}</p>
                            <p className={css(styles.itemDetails)}>
                                {activityDetails}
                            </p>
                        </div>
                        <p className={css(styles.itemValue, styles.alignRight)}>{activityPoints}</p>
                    </div>
                );
            })}
        </div>
    );

    renderDailyHeader = (date, points, completionPercentage) => {
        const isPeriodCompleted = completionPercentage === MAX_PERCENTAGE_STRING;
        return (
            <div className={css(styles.subheader, !this.props.isWeeklyPeriod && styles.dailyHeaderHeight)}>
                <p>{date}</p>
                <p className={css(styles.alignRight)}>{points}</p>
                {completionPercentage && (
                    <Core.Tag
                        label={completionPercentage}
                        chipClassName={isPeriodCompleted ? styles.dailyTag : styles.dailyTagBgGrey}
                        chipTextClassName={isPeriodCompleted ? styles.tagText : styles.tagTextGrey}
                    />
                )}
            </div>
        );
    };

    renderNoDailyActivities = (date, points) => (
        <>
            <div className={css(styles.subheader, !this.props.isWeeklyPeriod && styles.dailyHeaderHeight)}>
                <p>{date}</p>
                <p className={css(styles.alignRight)}>{points}</p>
                {this.props.isDailyPeriod ? (
                    <Core.Tag
                        label={MIN_PERCENTAGE_STRING}
                        chipClassName={styles.dailyTagBgGrey}
                        chipTextClassName={styles.tagTextGrey}
                    />
                ) : null}
            </div>
            <div className={css(styles.noActivityBody)}>
                <p className={css(styles.noTrackedText)}>{this.props.noTrackedText}</p>
            </div>
        </>
    );

    renderWeeklyHeader = item => {
        const { isDailyPeriod, isSmartModeGoal, dailyTargetText } = this.props;
        const { date, points, completionPercentage, weeklyTarget } = this.props.activityItemHeaderProps(item);
        const periodCompleted = completionPercentage === MAX_PERCENTAGE_STRING;

        let rightString;
        if (isSmartModeGoal === undefined) {
            rightString = <p className={css(styles.secondaryHeading, layoutStyle.flexAlignCenter, isDailyPeriod && styles.mRight)}>{points}</p>;
        } else {
            rightString = (
                <div className={css(styles.smartTargetSection)}>
                    {weeklyTarget ? (
                        <>
                            <p className={css(styles.dailyTarget)}>{dailyTargetText}</p>
                            <p className={css(styles.secondaryHeading, layoutStyle.flexAlignCenter, styles.smartTargetValue)}>{weeklyTarget}</p>
                        </>
                    ) : null}
                </div>
            );
        }

        return (
            <>
                <p className={css(styles.heading, layoutStyle.flexAlignCenter)}>{date}</p>
                {rightString}
                {isDailyPeriod ? null : (
                    <Core.Tag
                        label={completionPercentage}
                        chipClassName={periodCompleted ? styles.tag : styles.tagBgGrey}
                        chipTextClassName={periodCompleted ? styles.tagText : styles.tagTextGrey}
                    />
                )}
            </>
        );
    };

    renderWeeklyItemDetails = (item, index) => {
        const { daysWithActivities, activityItemDetailsProps, getFormattedDate, getDatesInPeriod, isRestartGoal, getDatesInWeekPeriod } = this.props;

        if (item.logs === undefined) {
            return null;
        }

        let datesInPeriod = [];
        if (isRestartGoal) {
            datesInPeriod = getDatesInPeriod(item.logs[0].date, index);
        } else {
            const index = item.logs.length > 1 ? item.logs.length - 1 : 0;
            datesInPeriod = getDatesInWeekPeriod(item.logs[index]);
        }
        let logIndex = -1;

        return _.map(datesInPeriod, periodDate => {
            const hasLog = _.includes(daysWithActivities, periodDate);
            if (hasLog) logIndex += 1;

            const log = (hasLog && logIndex >= 0) ? item.logs[logIndex] : {};
            const { date, points, completionPercentage } = activityItemDetailsProps(log, isRestartGoal, item);
            const hasActivityTracked = hasLog && log.logs[0].activityLogId;
            return (
                hasActivityTracked ? (
                    <>
                        {this.renderDailyHeader(date, points, completionPercentage)}
                        {this.renderActivities(log.logs)}
                    </>
                ) : this.renderNoDailyActivities(getFormattedDate(periodDate), points)
            );
        });
    };

    getDailyLogs = activityLogs => {
        const { daysWithActivities, activityItemDetailsProps, getFormattedDate, challengePreviousDays } = this.props;
        let logIndex = -1;

        return _.map(challengePreviousDays, day => {
            const hasLog = _.includes(daysWithActivities, day);
            if (hasLog) logIndex += 1;

            const log = (hasLog && logIndex >= 0) ? activityLogs[logIndex] : {};
            const { date, points, completionPercentage } = activityItemDetailsProps(log);
            return (
                log.date ? (
                    <>
                        {this.renderDailyHeader(date, points, completionPercentage)}
                        {this.renderActivities(log.logs)}
                    </>
                ) : this.renderNoDailyActivities(getFormattedDate(day), points)
            );
        });
    };

    renderWeeklyItem = ({ item, index }) => (
        <Core.AccordionListItem
            itemHeader={this.renderWeeklyHeader(item)}
            itemDetails={this.renderWeeklyItemDetails(item, index)}
            itemClassName={css(styles.item)}
            getActiveIndex={this.getActiveIndex}
            checkExpanded={index === this.state.activeIndex}
            index={index}
        />
    );

    renderDailyItem = ({ item, index }) => <div key={index}>{item}</div>

    keyExtractor = item => item.period;

    activityHistoryList = () => {
        const { activityLogs } = this.props;
        const logs = this.isWeekly ? activityLogs : this.dailyLogs;
        const renderItem = this.isWeekly ? this.renderWeeklyItem : this.renderDailyItem;
        return (
            <div className={classNames(this.isWeekly && css(styles.containerBottomBorder), css(styles.container))}>
                <Core.InfiniteLazyListFadeIn
                    keyExtractor={this.keyExtractor}
                    onEndReachedThreshold={0.1}
                    hasMarginHorizontal={false}
                    renderItem={renderItem}
                    onEndReached={this.onEndReached}
                    data={this.state.periodsToRender}
                    hasMore={logs.length !== this.state.periodsToRender.length}
                />
            </div>
        );
    };

    render() {
        const { progressDetailsText, isLoading, activityLogs, noActivitiesText } = this.props;
        return (
            <Core.SmallerContainer
                widerHeaderComponent={(<Core.EntityDetailsHeader hasBackButton={true} />)}>
                <Core.SubHeader title={progressDetailsText} noTopPadding={true} />
                {isLoading ?
                    <Core.ListLoading /> :
                    (
                        <Fragment>
                            {activityLogs && activityLogs.length ?
                                this.activityHistoryList() :
                                (
                                    <p className={css(layoutStyle.commonPaddingHorizontal, styles.noActivitiesTrackedText)}>
                                        {noActivitiesText}
                                    </p>
                                )
                            }
                        </Fragment>
                    )
                }
            </Core.SmallerContainer>
        );
    }
}

export default WithCompetitionActivityHistoryBase(CompetitionActivityHistory);

const styles = StyleSheet.create({
    ...baseStyles,
    item: importantClass({
        margin: 0,
    }),
    container: {
        marginBottom: spacing.s7,
    },
    containerBottomBorder: {
        ...baseStyles.containerBottomBorder,
        borderBottomStyle: 'solid',
    },
    smartTargetSection: {
        ...baseStyles.smartTargetSection,
        display: 'flex',
        marginRight: spacing.s1
    }
});