import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { appFonts, baseColors, spacing } from '../../../../styles';
import * as selectors from '../../selectors';
import defaultImage from '../../../../image/placeholder_group_o.jpg';


export default function WithRewardCarouselItemBase(WrappedComponent) {
    class RewardCarouselItemBase extends PureComponent {
        static propTypes = {
            item: PropTypes.object.isRequired,
            externalRewardName: PropTypes.string.isRequired,
            onPressSingle: PropTypes.func
        };

        static defaultProps = {
            onPressSingle: null
        };

        get imageSource() {
            return _.get(this.props.item, 'largeImageUrl', null) || defaultImage;
        }

        onPressSingle = () => {
            if (this.props.onPressSingle) {
                this.props.onPressSingle(this.props.item.id);
            }
        };

        get productName() {
            return this.props.item.productName || this.props.item.name;
        }

        get details() {
            return `${this.props.item.pointsRequired} ${this.props.externalRewardName}`;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    image={this.imageSource}
                    productName={this.productName}
                    details={this.details}
                    onPressSingle={this.onPressSingle}
                />
            );
        }
    }
    function mapStateToProps(state) {
        return {
            externalRewardName: selectors.externalRewardName(state)
        };
    }

    return connect(mapStateToProps)(RewardCarouselItemBase);
}

export const styles = {
    productTitle: {
        backgroundColor: 'transparent',
        color: baseColors.black,
        ...appFonts.mdBold,
        paddingTop: spacing.s1
    },
    rewardsDetails: {
        ...appFonts.smRegular,
        color: baseColors.primary
    }
};