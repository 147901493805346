import React, { PureComponent } from 'react';
import { css, StyleSheet } from 'aphrodite-jss';
import PropTypes from 'prop-types';
import _ from 'lodash';
import GoalItem from '../GoalItem';
import WithPersonalGoalsListBase from './PersonalGoalsListBase';
import { spacing } from '../../../../../styles';

class PersonalGoalsList extends PureComponent {
    static propTypes = {
        items: PropTypes.array.isRequired,
        setGoal: PropTypes.func,
        chooseGoal: PropTypes.func,
        onboardingNewDesign: PropTypes.bool,
        hasBorder: PropTypes.bool,
        containerStyle: PropTypes.object,
        canShowSuccessCards: PropTypes.bool,
        isSetButtonDisabled: PropTypes.bool.isRequired,
        isSettingStarterGoal: PropTypes.bool,
    };

    static defaultProps = {
        setGoal: null,
        chooseGoal: null,
        onboardingNewDesign: false,
        hasBorder: false,
        containerStyle: null,
        canShowSuccessCards: false,
        isSettingStarterGoal: false,
    };

    render() {
        const { items, hasBorder, chooseGoal, setGoal, onboardingNewDesign, containerStyle,
            canShowSuccessCards, isSetButtonDisabled, isSettingStarterGoal } = this.props;
        return (
            <div className={css(styles.goalListContainer, containerStyle)}>
                { _.map(items, (item, index) => (
                    <div key={item.goalName} role="list">
                        <GoalItem
                            goal={item}
                            buttonPress={setGoal}
                            hasBorder={hasBorder}
                            isFirstItem={index === 0}
                            challengeId={item.goalName}
                            goalCardPress={chooseGoal}
                            onboardingNewDesign={onboardingNewDesign}
                            canShowSuccessCards={canShowSuccessCards}
                            isSetButtonDisabled={isSetButtonDisabled}
                            isSettingStarterGoal={isSettingStarterGoal}
                        />
                    </div>
                ))}
            </div>
        );
    }
}

export default WithPersonalGoalsListBase(PersonalGoalsList);

const styles = StyleSheet.create({
    goalListContainer: {
        marginTop: -spacing.s5,
    }
});