import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { appFonts, baseColors, spacing } from '../../../../styles';
import { translate, parsers, tracker } from '../../../core';
import { services as authServices, selectors as authSelectors } from '../../../auth';
import * as rewardsActions from '../../actions';
import { REDEEM_STATES, SSO_REWARDS } from '../../constants';
import * as selectors from '../../selectors';

export const FIXED_HEADER_MARGIN_TOP = 120;

export default function WithProductDetailsBase(WrappedComponent) {
    class ProductDetailsBase extends Component {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            company: PropTypes.object.isRequired,
            rewards: PropTypes.object,
            product: PropTypes.object,
            productId: PropTypes.number.isRequired,
            companyConfigSSO: PropTypes.object,
            userSSOData: PropTypes.object,
            rewardRedemptionToken: PropTypes.string,
            isRedemptionTokenError: PropTypes.bool,
            i18n: PropTypes.object.isRequired,
            partnerSsoUri: PropTypes.string,
            isWebGetToken: PropTypes.bool
        };

        static defaultProps = {
            rewards: {},
            product: {},
            companyConfigSSO: {},
            userSSOData: {},
            rewardRedemptionToken: '',
            isRedemptionTokenError: false,
            partnerSsoUri: '',
            isWebGetToken: false
        };

        constructor(props) {
            super(props);
            this.state = {
                isLoading: false,
                currentStep: REDEEM_STATES.initial,
                token: props.rewardRedemptionToken
            };

            props.actions.getRewardsProduct(props.productId);
        }

        static getDerivedStateFromProps(nextProps, prevState) {
            switch (prevState.currentStep) {
                case REDEEM_STATES.getToken: {
                    if (nextProps.rewardRedemptionToken !== prevState.token) {
                        return { currentStep: REDEEM_STATES.goToOrderConfirmation, isLoading: false };
                    }
                    else if (nextProps.isRedemptionTokenError) {
                        return { currentStep: REDEEM_STATES.redeem, isLoading: false };
                    }
                    break;
                }
                case REDEEM_STATES.goToOrderConfirmation: {
                    return { currentStep: REDEEM_STATES.redeem };
                }
                default:
                    break;
            }
            return null;
        }

        componentDidUpdate(prevProps, prevState) {
            switch (prevState.currentStep) {
                case REDEEM_STATES.getToken: {
                    if (this.state.currentStep === REDEEM_STATES.redeem) {
                        this.clearTokenError();
                    }
                    else if (this.state.currentStep === REDEEM_STATES.goToOrderConfirmation) {
                        if (this.wrappedComponent) {
                            this.wrappedComponent.goToOrder();
                            this.clearToken();
                        }
                    }
                    break;
                }
                default:
                    break;
            }
        }

        setLoading = isLoading => this.setState({ isLoading });

        getToken = () => this.props.actions.getTemporaryRewardRedemptionToken(this.props.userSSOData);

        clearToken = () => this.props.actions.clearTemporaryRewardRedemptionToken();

        clearTokenError = () => this.props.actions.clearTemporaryRewardRedemptionTokenError();

        setStep = currentStep => this.setState({ currentStep });

        setToken = token => this.setState({ token });

        onRedeem = handler => {
            if (this.props.rewards.reauthRequiredToRedeem) {
                tracker.logEvent('rewards', { event: 'reauth' });
                this.clearTokenError();
                authServices.sso.triggerSSO({ target: this.props.partnerSsoUri }, SSO_REWARDS.authentication, this.props.company);
                this.setStep(REDEEM_STATES.sso);
                this.props.actions.saveProductId(this.props.productId);
                this.setToken(this.props.rewardRedemptionToken); // this relies on the above sso and a subsequent action, there's no way this token is available here
            }
            else {
                tracker.logEvent('rewards', { event: 'redeem', id: this.props.productId });
                handler();
                this.setStep(REDEEM_STATES.redeem);
            }
        };

        onOrder = () => {
            const { productId, actions } = this.props;
            actions.clearRedeemProductError();
            const token = this.state.token;
            tracker.logEvent('rewards', { event: 'submit', id: productId });
            actions.redeemProduct({ token, address: this.formattedAddress, productId, quantity: 1 });
            if (this.state.currentStep === REDEEM_STATES.submitOrder || this.state.currentStep === REDEEM_STATES.sso) {
                this.setState({ currentStep: REDEEM_STATES.submitting, isLoading: true });
            }
        };

        get item() {
            return this.props.product;
        }

        get numRedeemable() {
            return _.get(this.props.rewards, 'numRedeemable', 0);
        }

        get needPointsToRedeem() {
            return this.item.pointsRequired - this.numRedeemable;
        }

        get title() {
            return this.item.requireAddress ? this.props.i18n.t('shippingAddress') : this.props.i18n.t('redemptionConfirmation');
        }

        setRef = ref => this.wrappedComponent = ref;

        decodeHTML = text => parsers.htmlDecode(text);

        get description() {
            return this.decodeHTML(this.item.description);
        }

        get disclaimer() {
            return this.decodeHTML(this.item.disclaimer);
        }

        get filledPercentage() {
            return this.numRedeemable * 100 / this.item.pointsRequired;
        }

        get rewardText() {
            return `${this.item.pointsRequired} ${this.props.externalRewardName}`;
        }

        get isEnoughPoints() {
            return this.numRedeemable >= this.item.pointsRequired;
        }

        get descriptionLabels() {
            const { i18n } = this.props;
            return {
                disclaimer: i18n.t('disclaimer'),
                terms: i18n.t('terms')
            };
        }

        get needPointsText() {
            const { i18n, externalRewardName } = this.props;
            return i18n.t('needPointsToRedeem', {
                points: this.needPointsToRedeem,
                rewardName: externalRewardName
            });
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    item={this.item}
                    numRedeemable={this.numRedeemable}
                    needPointsToRedeem={this.needPointsToRedeem}
                    getToken={this.getToken}
                    setStep={this.setStep}
                    title={this.title}
                    onRedeem={this.onRedeem}
                    setLoading={this.setLoading}
                    isLoading={this.state.isLoading}
                    ref={this.setRef}
                    decodeHTML={this.decodeHTML}
                    filledPercentage={this.filledPercentage}
                    description={this.description}
                    disclaimer={this.disclaimer}
                    rewardText={this.rewardText}
                    isEnoughPoints={this.isEnoughPoints}
                    descriptionLabels={this.descriptionLabels}
                    needPointsText={this.needPointsText}
                    onOrder={this.onOrder}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        const productId = ownProps.productId || _.get(ownProps, 'match.params.productId') || _.get(ownProps, 'route.params.productId');
        const isWebGetToken = ownProps.isWebGetToken || _.get(ownProps, 'location.state.isWebGetToken');
        return {
            rewards: selectors.getRewards(state),
            product: selectors.getProduct(state, productId),
            partnerSsoUri: authSelectors.getPartnerSsoUri(state),
            userSSOData: authSelectors.getSsoDetails(state),
            company: authSelectors.getCompany(state),
            rewardRedemptionToken: selectors.getRedemptionToken(state),
            isRedemptionTokenError: selectors.isRedemptionTokenError(state),
            externalRewardName: selectors.externalRewardName(state),
            orderId: selectors.getCurrentOrderId(state),
            productId,
            isWebGetToken
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(rewardsActions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(ProductDetailsBase));
}

export const styles = {
    headerContainer: {
        flexDirection: 'column',
        marginBottom: spacing.s3
    },
    imageBackground: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    innerContainer: {
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3,
    },
    title: {
        ...appFonts.xlBold,
        marginLeft: 0,
        marginRight: 0,
        marginBottom: spacing.s0
    },
    company: {
        ...appFonts.smRegular,
        color: baseColors.grey40,
        marginBottom: spacing.s3
    },
    redeemLargeText: {
        color: baseColors.primary,
        ...appFonts.xxlMedium
    },
    redeemCommonText: {
        ...appFonts.lgMedium,
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: 0,
        paddingLeft: spacing.s1,
    },
    enoughPointContainer: {
        flex: 1,
        flexDirection: 'column'
    },
    enoughPointInfo: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: spacing.s3
    },
    progress: {
        marginTop: spacing.s1,
        marginBottom: spacing.s1,
    },
    descriptionContainer: {
        marginTop: spacing.s3
    },
    description: {
        ...appFonts.smRegular,
        color: baseColors.grey20
    },
    link: {
        color: baseColors.secondary
    },
    moreContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        marginBottom: spacing.s3
    },
    morePointsText: {
        ...appFonts.mdRegular,
        marginBottom: spacing.s0,
        color: baseColors.grey40
    },
    learnMoreLink: {
        ...appFonts.mdMedium,
        color: baseColors.secondary
    },
    continueButton: {
        ...appFonts.smBold
    },
    descriptionTitle: {
        ...appFonts.mdBold,
        marginBottom: spacing.s1
    }
};