import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { components as Core, ROUTES } from '../../../core';
import WithChallengeMembersBase from './ChallengeMembersBase';

class ChallengeMembers extends PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
        isLoading: PropTypes.bool,
        isTeamRegionsDepartmens: PropTypes.bool,
        getImageUrl: PropTypes.func.isRequired,
        getTitle: PropTypes.func.isRequired,
        getDescription: PropTypes.func.isRequired,
        loadMore: PropTypes.func.isRequired,
        hasMore: PropTypes.bool.isRequired,
        challengeId: PropTypes.number.isRequired,
        isPrivate: PropTypes.func.isRequired,
        getItemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        title: PropTypes.string.isRequired,
        formattedMembers: PropTypes.array.isRequired,
        shouldShowInvitedBy: PropTypes.func.isRequired,
        getInvitedByDetails: PropTypes.func.isRequired
    };

    static defaultProps = {
        isLoading: false,
        isTeamRegionsDepartmens: false
    };

    onPressTeam = id => {
        const { challengeId } =this.props;
        this.props.history.push(ROUTES.teamDetails(challengeId, id));
    };

    renderAttendeeItem = (item, onPress) => {
        if (this.props.isTeamRegionsDepartmens && this.props.isPrivate(item)) return null;
        const onPressFunc = this.props.isTeamRegionsDepartmens ? this.onPressTeam : onPress;
        return (
            <Core.AttendeeItem
                onPress={onPressFunc}
                imageUrl={this.props.getImageUrl(item)}
                title={this.props.getTitle(item)}
                description={this.props.isUnstartedChallenge ? null : this.props.getDescription(item)}
                id={this.props.getItemId(item)}
                isDisabled={this.props.isTeamRegionsDepartmens}
                isPrivate={this.props.isPrivate(item)}
                shouldShowInvitedBy={this.props.shouldShowInvitedBy(item)}
                invitedBy={this.props.getInvitedByDetails(item)}
                hasChevron={true}
            />
        );
    };

    render() {
        const { title, loadMore, isLoading, hasMore, formattedMembers } = this.props;
        return (
            <Core.SmallerContainer
                widerHeaderComponent={(
                    <Core.EntityDetailsHeader
                        hasBackButton={true}
                        title={title}
                    />)
                }>
                <Core.SubHeader
                    title={title}
                    noTopPadding={true}
                />
                <Core.AttendeesList
                    members={formattedMembers}
                    loadMore={loadMore}
                    isLoading={isLoading}
                    renderAttendee={this.renderAttendeeItem}
                    hasMore={hasMore}
                />
            </Core.SmallerContainer>
        );
    }
}

export default WithChallengeMembersBase(ChallengeMembers);
