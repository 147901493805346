/* eslint-disable camelcase */
import _ from 'lodash';
import global from '../../config/globals';
import * as types from './actionTypes';
import * as api from './api';
import { actions as coreActions, constants as coreConstants, normalize, Storage, Platform, PLATFORMS, tracker } from '../core';
import { actions as contentActions } from '../content';
import i18n from '../../i18n/i18n';
import { validateError } from '../../config';
import { getPlatformInvitations } from '../invitations/actions';
import { PRIVACY_SLUGS } from './constants';
import { PromoterScoreService } from '../feeds';

const { addToast } = coreActions;

export function getNotificationsSettings() {
    return function (dispatch) {
        dispatch({ type: types.GET_NOTIFICATIONS_SETTINGS.REQUEST });
        return api.getNotificationsSettings()
            .then(res => {
                dispatch({
                    type: types.GET_NOTIFICATIONS_SETTINGS.SUCCESS,
                    payload: normalize.normalizeArray(res.data.notificationSettings[0].settings, 'notificationSettingTypeId')
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.GET_NOTIFICATIONS_SETTINGS.ERROR });
            }));
    };
}

export function setNotificationsSetting(notificationSettingTypeId, value) {
    return function (dispatch) {
        dispatch({ type: types.SET_NOTIFICATIONS_SETTINGS.REQUEST });
        return api.setNotificationsSetting(notificationSettingTypeId, value)
            .then(res => {
                dispatch({
                    type: types.SET_NOTIFICATIONS_SETTINGS.SUCCESS,
                    payload: { typeId: notificationSettingTypeId, value }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.SET_NOTIFICATIONS_SETTINGS.ERROR });
            }));
    };
}

export function getUserPrivacySettings(type) {
    return function (dispatch) {
        dispatch({ type: types.GET_PRIVACY_SETTINGS.REQUEST });
        return api.getUserPrivacySettings(type)
            .then(res => {
                dispatch({
                    type: types.GET_PRIVACY_SETTINGS.SUCCESS,
                    payload: normalize.normalizeArray(res.data.privacySettings[0].email, 'privacySettingTypeId')
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.GET_PRIVACY_SETTINGS.ERROR });
            }));
    };
}

export function setUserPrivacySetting(type, privacySettingTypeId, value) {
    return function (dispatch) {
        dispatch({ type: types.SET_PRIVACY_SETTINGS.REQUEST });
        return api.setUserPrivacySetting(type, privacySettingTypeId, value)
            .then(res => {
                dispatch({
                    type: types.SET_PRIVACY_SETTINGS.SUCCESS,
                    payload: { typeId: privacySettingTypeId, value: !value }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.SET_PRIVACY_SETTINGS.ERROR });
            }));
    };
}

export function getUnsubscribeSettings(token) {
    return function (dispatch) {
        dispatch({ type: types.GET_PRIVACY_SETTINGS.REQUEST });
        return api.getUnsubscribeSettings(token)
            .then(res => {
                dispatch({
                    type: types.GET_PRIVACY_SETTINGS.SUCCESS,
                    payload: {
                        ...normalize.normalizeArray(res.data.privacySettings[0].email, 'privacySettingTypeId'),
                        userEmail: res.data.userEmail,
                    }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.GET_PRIVACY_SETTINGS.ERROR });
            }));
    };
}

export function setUnsubscribeSetting(privacySettingTypeId, value, token) {
    return function (dispatch) {
        dispatch({ type: types.SET_PRIVACY_SETTINGS.REQUEST });
        return api.setUnsubscribeSetting(privacySettingTypeId, value, token)
            .then(res => {
                dispatch({
                    type: types.SET_PRIVACY_SETTINGS.SUCCESS,
                    payload: { typeId: privacySettingTypeId, value: !value }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({
                    type: types.SET_PRIVACY_SETTINGS.ERROR,
                    payload: {
                        error,
                        isErrorStore: true
                    }
                });
            }));
    };
}

export function getFeedbackMetadata(partnerId, actionType='gettingFeedbackMetadata') {
    return function (dispatch) {
        dispatch({ type: types.SET_FEEDBACK_METADATA.REQUEST });
        return api.getFeedbackMetadata(partnerId)
            .then(res => {
                dispatch({
                    type: types.SET_FEEDBACK_METADATA.SUCCESS,
                    payload: { data: res.data }
                });
            }).catch(error => validateError(error, error => {
                dispatch({
                    type: types.SET_FEEDBACK_METADATA.ERROR,
                    payload: {
                        toast: { actionType },
                        error,
                        isErrorStore: true
                    }
                });
            }));
    };
}

export function clearFeedbackError() {
    return function (dispatch) {
        dispatch(coreActions.removeError(types.SET_FEEDBACK_METADATA.NAME));
    };
}

export function postFeedback(feedbackText, feedback, feedback_title, feedback_msg, feedback_error, client, partner_id, feedback_id, actionType='feedbackSubmission') {
    return function (dispatch) {
        dispatch({ type: types.POST_FEEDBACK.REQUEST });
        return api.postFeedback(feedback, client, partner_id, feedback_id)
            .then(res => {
                dispatch({
                    type: types.POST_FEEDBACK.SUCCESS,
                    payload: {
                        toast: {
                            actionType,
                            type: coreConstants.TOAST_TYPES.SUCCESS,
                            msg: feedback_msg,
                            title: feedback_title
                        }
                    }
                });
            }).catch(error => validateError(error, error => {
                dispatch({ type: types.POST_FEEDBACK.ERROR,
                    payload: {
                        error,
                        toast: {
                            actionType,
                            type: coreConstants.TOAST_TYPES.DANGER,
                            msg: feedback_error,
                            title: i18n.t('feedback_failure_title')
                        }
                    } });
            }));
    };
}

export function getUserBiometrics(actionType='gettingUserBiometrics') {
    return function (dispatch) {
        dispatch({ type: types.GET_BIOMETRICS.REQUEST });
        return api.getUserBiometrics()
            .then(res => {
                dispatch({
                    type: types.GET_BIOMETRICS.SUCCESS,
                    payload: { data: res.data }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({
                    type: types.GET_BIOMETRICS.ERROR,
                    payload: {
                        error,
                        toast: { actionType }
                    }
                });
            }));
    };
}

export function getUserSettings(actionType='gettingUserPersonal') {
    return function (dispatch) {
        dispatch({ type: types.GET_PERSONAL_SETTINGS.REQUEST });
        return api.getUserSettings()
            .then(res => {
                dispatch({
                    type: types.GET_PERSONAL_SETTINGS.SUCCESS,
                    payload: { data: res.data }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({
                    type: types.GET_PERSONAL_SETTINGS.ERROR,
                    payload: {
                        error,
                        toast: { actionType }
                    }
                });
            }));
    };
}

export function saveUserBiometrics(params, actionType='savingUser') {
    return function (dispatch) {
        dispatch({ type: types.SAVE_BIOMETRICS.REQUEST });
        return api.saveUserBiometrics(params)
            .then(res => {
                dispatch({
                    type: types.SAVE_BIOMETRICS.SUCCESS,
                    payload: {
                        data: res.data,
                        toast: { actionType }
                    }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({
                    type: types.SAVE_BIOMETRICS.ERROR,
                    payload: {
                        error,
                        toast: { actionType }
                    }
                });
            }));
    };
}

export function savePersonal(params, actionType='savingUser') {
    return function (dispatch) {
        dispatch({ type: types.SAVE_PERSONAL_SETTINGS.REQUEST });
        return api.savePersonal(params)
            .then(res => {
                dispatch({
                    type: types.SAVE_PERSONAL_SETTINGS.SUCCESS,
                    payload: {
                        data: res.data,
                        toast: { actionType }
                    }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({
                    type: types.SAVE_PERSONAL_SETTINGS.ERROR,
                    payload: {
                        error,
                        toast: { actionType }
                    }
                });
            }));
    };
}

export function validateProfileDataError() {
    return function (dispatch) {
        dispatch(addToast(coreConstants.TOAST_TYPES.DANGER, 'savingUserToast', i18n.t('edit_profile_user_error_msg')));
    };
}

export function validateFamilyInvitationDataError() {
    return function (dispatch) {
        dispatch(addToast(coreConstants.TOAST_TYPES.DANGER, 'savingUserToast', i18n.t('inviteFamilyMember.errorMsg')));
    };
}

export function getCompanyTasks(companyId, actionType='getCompanyTasks') {
    return function (dispatch) {
        dispatch({ type: types.GET_COMPANY_TASKS.REQUEST });
        return api.getCompanyTasks(companyId)
            .then(res => {
                dispatch({
                    type: types.GET_COMPANY_TASKS.SUCCESS,
                    payload: { data: res.data }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({
                    type: types.GET_COMPANY_TASKS.ERROR,
                    payload: {
                        error,
                        toast: { actionType }
                    }
                });
            }));
    };
}

export function getCompanyFeatures(companyId, actionType='getCompanyFeatures') {
    return function (dispatch) {
        dispatch({ type: types.GET_COMPANY_FEATURES.REQUEST });
        return api.getCompanyFeatures(companyId)
            .then(res => {
                dispatch({
                    type: types.GET_COMPANY_FEATURES.SUCCESS,
                    payload: { data: res.data }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({
                    type: types.GET_COMPANY_FEATURES.ERROR,
                    payload: {
                        error,
                        toast: { actionType }
                    }
                });
            }));
    };
}

export function updateAvatar(data, actionType='updatingAvatar') {
    return function (dispatch) {
        dispatch({ type: types.UPDATE_USER_AVATAR.REQUEST });
        return api.updateAvatar(data)
            .then(res => {
                dispatch({
                    type: types.UPDATE_USER_AVATAR.SUCCESS,
                    payload: {
                        data: res.data.avatarURL,
                        toast: {
                            actionType,
                            type: coreConstants.TOAST_TYPES.SUCCESS
                        }
                    }
                });
                dispatch(coreActions.retrieveCurrentUserInfoSuccess(res.data));
            })
            .catch(error => validateError(error, error => {
                dispatch({
                    type: types.UPDATE_USER_AVATAR.ERROR,
                    payload: {
                        error,
                        toast: {
                            actionType,
                            type: coreConstants.TOAST_TYPES.DANGER,
                            msg: error.displayMessage || error.message
                        }
                    }
                });
            }));
    };
}

export function deleteAvatar(actionType='deletingAvatar') {
    return function (dispatch) {
        dispatch({ type: types.DELETE_USER_AVATAR.REQUEST });
        return api.deleteAvatar()
            .then(res => {
                dispatch({
                    type: types.DELETE_USER_AVATAR.SUCCESS,
                    payload: {
                        toast: {
                            actionType,
                            type: coreConstants.TOAST_TYPES.SUCCESS
                        }
                    }
                });
                dispatch(coreActions.retrieveCurrentUserInfoSuccess({ avatarURL: '' }));
            })
            .catch(error => validateError(error, error => {
                dispatch({
                    type: types.DELETE_USER_AVATAR.ERROR,
                    payload: {
                        error,
                        toast: {
                            actionType,
                            type: coreConstants.TOAST_TYPES.DANGER,
                            msg: error.displayMessage || error.message
                        }
                    }
                });
            }));
    };
}

export function getCurrentUserPrivacySettings(userId, privacyTypeSlug) {
    return function (dispatch) {
        dispatch({ type: types.GET_CURRENT_USER_PRIVACY_SETTINGS.REQUEST });

        return api.getCurrentUserPrivacySettings(userId, privacyTypeSlug)
            .then(res => {
                const payload = normalize.normalizeArray(res.data, 'privacyTypeId');

                dispatch({
                    type: types.GET_CURRENT_USER_PRIVACY_SETTINGS.SUCCESS,
                    payload
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.GET_CURRENT_USER_PRIVACY_SETTINGS.ERROR });
            }));
    };
}

export function setCurrentUserPrivacySetting(userId, privacyTypeSlug, privacyTypeId, value) {
    const actionType='updatingPrivacySettings';

    return function (dispatch) {
        if (privacyTypeSlug === PRIVACY_SLUGS.DATA_COLLECTION) {
            dispatch(setDataCollection(value));
        }
        dispatch({ type: types.SET_CURRENT_USER_PRIVACY_SETTINGS.REQUEST });
        return api.setCurrentUserPrivacySetting(userId, privacyTypeSlug, value)
            .then(res => {
                dispatch({
                    type: types.SET_CURRENT_USER_PRIVACY_SETTINGS.SUCCESS,
                    payload: { privacyTypeId, value }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({
                    type: types.SET_CURRENT_USER_PRIVACY_SETTINGS.ERROR,
                    payload: {
                        toast: { actionType },
                        error,
                        isErrorStore: true
                    }
                });
            }));
    };
}

export function setDataCollection(value) {
    return function (dispatch) {
        if (value === false) {
            Storage.setItem('dataCollectionDisabled', false);

            if (Platform.OS !== PLATFORMS.web) {
                tracker.setAnalyticsEnabled();
            }

            PromoterScoreService.enableSurvey();
        } else {
            Storage.setItem('dataCollectionDisabled', true);

            if (Platform.OS !== PLATFORMS.web) {
                tracker.setAnalyticsDisabled();
            }

            PromoterScoreService.disableSurvey();
        }
    };
}

export function sendHealthScoreFeedback(params, showThankYouModal) {
    return function (dispatch) {
        dispatch({ type: types.SEND_HEALTH_SCORE_FEEDBACK.REQUEST });
        return api.sendUserFeedback(params)
            .then(res => {
                showThankYouModal();
                dispatch({
                    type: types.SEND_HEALTH_SCORE_FEEDBACK.SUCCESS
                });
            })
            .catch(error => {
                dispatch({
                    type: types.SEND_HEALTH_SCORE_FEEDBACK.ERROR
                });
            });
    };
}

export function sendUserFeedback(params) {
    return function (dispatch) {
        dispatch({ type: types.SEND_USER_FEEDBACK.REQUEST });
        return api.sendUserFeedback(params)
            .then(res => {
                dispatch({
                    type: types.SEND_USER_FEEDBACK.SUCCESS
                });
            })
            .catch(error => {
                dispatch({
                    type: types.SEND_USER_FEEDBACK.ERROR
                });
            });
    };
}

export function updatePrivacyInformationText(payload) {
    return {
        type: types.UPDATE_PRIVACY_INFORMATION_TEXT,
        payload
    };
}

export function hidePrivacyInformationText(slug) {
    return function (dispatch) {
        if (!_.find(global.isHiddenPrivacyInformationText, { slug })) {
            global.isHiddenPrivacyInformationText.push({ slug });
        }

        Storage.setItem('isHiddenPrivacyInformation', global.isHiddenPrivacyInformationText);

        dispatch({
            type: types.UPDATE_PRIVACY_INFORMATION_TEXT,
            payload: [...global.isHiddenPrivacyInformationText]
        });
    };
}

export function resetIsLanguageChanged() {
    return function (dispatch) {
        dispatch({ type: types.RESET_IS_LANGUAGE_CHANGED });
        dispatch(contentActions.clearAndUpdateContent());
    };
}

export function selectWellbeingScoreTab() {
    return function (dispatch) {
        dispatch({ type: types.SELECT_WELLBEING_SCORE_TAB });
    };
}

export function resetWellbeingScoreTab() {
    return function (dispatch) {
        dispatch({ type: types.RESET_WELLBEING_SCORE_TAB });
    };
}

export function sendFamilyMemberInvitation(params) {
    const actionType = 'sendFamilyMemberInvitation';
    return function (dispatch) {
        dispatch({ type: types.SEND_FAMILY_MEMBER_INVITATION.REQUEST });
        return api.sendFamilyMemberInvitation(params)
            .then(res => {
                setTimeout(() => dispatch(getPlatformInvitations(), 1000));
                dispatch({
                    type: types.SEND_FAMILY_MEMBER_INVITATION.SUCCESS,
                    payload: {
                        toast: {
                            actionType,
                            type: coreConstants.TOAST_TYPES.SUCCESS,
                            msg: i18n.t('familyInvitationSent.desc', { fullName: params[0].email }),
                            title: i18n.t('familyInvitationSent.title')
                        }
                    }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({
                    type: types.SEND_FAMILY_MEMBER_INVITATION.ERROR,
                    payload: {
                        error,
                        toast: {
                            actionType,
                            type: coreConstants.TOAST_TYPES.DANGER,
                            msg: error.displayMessage || error.message
                        }
                    }
                });
            }));
    };
}
