import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { spacing } from '../../../../styles';

export default function WithTouchableItemBase(WrappedComponent) {
    class TouchableItemBase extends PureComponent {
        static propTypes = {
            separator: PropTypes.bool,
            imageWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            imageHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            hasImageContainMode: PropTypes.bool,
            hasShadow: PropTypes.bool
        };

        static defaultProps = {
            separator: false,
            hasImageContainMode: false,
            imageWidth: '100%',
            imageHeight: '100%',
            hasShadow: true
        };

        state = {};

        updateIsImageTaller = isImageTaller => this.setState({ isImageTaller });

        get imageSize() {
            if (this.props.hasImageContainMode) {
                if (this.state.isImageTaller) {
                    return { width: this.props.imageWidth, height: this.props.imageHeight };
                }
                return { width: this.props.imageWidth };
            }
            return { height: this.props.imageHeight, width: this.props.imageWidth };
        }


        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    borderColor={this.borderColor}
                    imageSize={this.imageSize}
                    updateIsImageTaller={this.updateIsImageTaller}
                />
            );
        }
    }

    return TouchableItemBase;
}

const BORDER_RADIUS = 8;

export const styles = {
    mainContainer: {
        flex: 1,
        borderBottomWidth: 1
    },
    carouselImageArea: {
        marginLeft: spacing.s1,
        marginRight: spacing.s1,
    },
    imageArea: {
        marginRight: spacing.s3,
    },
    imageContainer: {
        width: '100%',
        height: '100%',
        borderRadius: BORDER_RADIUS,
        shadowRadius: BORDER_RADIUS,
    },
    imageContainerWithShadow: {
        width: '100%',
        height: '100%',
        borderRadius: BORDER_RADIUS,
        shadowRadius: BORDER_RADIUS,
        shadowOpacity: 0.15,
        shadowOffset: { width: 0, height: 8 },
        shadowColor: '#000'
    },
    image: {
        flex: 1,
        width: '100%',
        height: '100%',
        borderRadius: BORDER_RADIUS
    },
    fadedImageBackground: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.55)',
        borderRadius: BORDER_RADIUS
    },
    featuredTag: {
        position: 'absolute',
        zIndex: 3
    },
};
