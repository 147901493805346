import _ from 'lodash';

export default (eventPhrase, isTimeFilter = false) => {
    let name = eventPhrase;
    const namesWithThis = ['week', 'month', 'quarter', 'year'];

    if (isTimeFilter && namesWithThis.includes(eventPhrase)) {
        name = `This ${eventPhrase}`;
    }

    return _.upperFirst(_.camelCase(name));
};
