import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import * as actions from '../../../actions';
import { translate, selectors as coreSelectors, ENTITIES, tracker } from '../../../../core';
import { appFonts, baseColors, spacing } from '../../../../../styles';
import {
    getChallenge, isTrackByDevice, isTeamSizeIncorrect, isDismissing, isUpdatingAfterTracking,
    getAllConnectedDevicesNames
} from '../../../selectors';
import { CHALLENGE_ENTITY_TYPES, CHALLENGE_SUBENTITY_TYPES } from '../../../constants';
import {
    isInvite, isUnstarted, isInGracePeriod, isCompletedNotInGracePeriod, isPersonal,
    isGoalType, getTeamSizeIncorrectMessage, inGracePeriodInfo, isTeamChallenge, isFeaturedChallenge
} from '../../../services/helper';
import * as selectors from '../../../selectors';

export const ICON_SIZE = 14;

export default function WithChallengeCardFooterBase(WrappedComponent) {
    class ChallengeCardFooterBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            challenge: PropTypes.object.isRequired,
            user: PropTypes.object.isRequired,
            isDismissing: PropTypes.bool,
            isUpdating: PropTypes.bool,
            snapToPrev: PropTypes.func,
            challengeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            isTrackByDevice: PropTypes.bool.isRequired,
            isTeamSizeIncorrect: PropTypes.bool.isRequired,
            connectedDevices: PropTypes.string,
            customPointsName: PropTypes.string.isRequired,
            activeSlideType: PropTypes.string
        };

        static defaultProps = {
            isDismissing: false,
            isUpdating: false,
            snapToPrev: null,
            connectedDevices: undefined,
            activeSlideType: null
        };

        joinChallenge = () => {
            const challenge = this.props.challenge;
            const entityId = this.props.user.userId;
            this.goToChallengeDetails(true);
            if (challenge.challengeEntityType !== CHALLENGE_ENTITY_TYPES.group &&
                challenge.challengeSubentityType !== CHALLENGE_SUBENTITY_TYPES.userTeam) {
                this.props.actions.joinChallenge(challenge.challengeId, entityId, false, null, challenge.challengeName);
            }
        };

        joinFeaturedChallenge = () => {
            const { challenge, user: { userId }, actions } = this.props;
            tracker.logEvent('ActivityTile_JoinChallenge_Join');
            this.goToChallengeDetails(true);
            actions.joinChallenge(challenge.challengeId, userId, false, null, challenge.challengeName);
        }

        get isTeamChallenge() {
            return isTeamChallenge(this.props.challenge);
        }

        goToChallengeDetails = () => _.has(this, 'wrapped.goToChallengeDetails') && this.wrapped.goToChallengeDetails();

        dismissChallenge = e => {
            const { actions, challengeId, snapToPrev, activeSlideType } = this.props;
            e.stopPropagation();
            if (snapToPrev) snapToPrev(activeSlideType);
            actions.setSingleGoalOrChallengeDismissed(challengeId);
        };

        get challengeTypeLabel() {
            const { i18n, challenge } = this.props;
            return _.toLower(i18n.t(this.isChallengeType ? ENTITIES.challenge : challenge.challengeType));
        }

        get isChallengeType() {
            const { challenge } = this.props;
            return !isPersonal(challenge) && isGoalType(challenge);
        }

        get inviteButtonText() {
            return isPersonal(this.props.challenge) ? this.props.i18n.t('set_this_goal') : this.props.i18n.t('join');
        }

        get inGraceButtonText() {
            return this.props.i18n.t('viewProgress.progress');
        }

        get deviceRequiredText() {
            return this.props.i18n.t('device_required.text', { challengeType: this.challengeTypeLabel });
        }

        get dismissButtonLabel() {
            return this.props.i18n.t('button_dismiss');
        }

        get deviceRequiredButtonText() {
            return this.props.i18n.t('device_required.button');
        }

        saveRef = ref => (this.wrapped = ref);

        dismissFeaturedChallenge = e => {
            const { actions, snapToNext, activeSlideType } = this.props;
            tracker.logEvent('ActivityTile_JoinChallenge_Dismiss');
            e.stopPropagation();
            if (snapToNext) snapToNext(activeSlideType);
            actions.setFeaturedChallengeDismissed(this.props.challenge.challengeId);
        }

        render() {
            const { challenge, customPointsName } = this.props;

            return (
                <WrappedComponent
                    {...this.props}
                    saveRef={this.saveRef}
                    joinChallenge={this.joinChallenge}
                    dismissChallenge={this.dismissChallenge}
                    isInvite={isInvite(challenge)}
                    isUnstarted={isUnstarted(challenge)}
                    isInGracePeriod={isInGracePeriod(challenge)}
                    isCompletedNotInGracePeriod={isCompletedNotInGracePeriod(challenge)}
                    deviceRequiredText={this.deviceRequiredText}
                    inviteButtonText={this.inviteButtonText}
                    inGraceButtonText={this.inGraceButtonText}
                    teamSizeIncorrectMessage={getTeamSizeIncorrectMessage(challenge, customPointsName)}
                    dismissButtonLabel={this.dismissButtonLabel}
                    inGracePeriodInfo={inGracePeriodInfo(challenge)}
                    deviceRequiredButtonText={this.deviceRequiredButtonText}
                    joinFeaturedChallenge={this.joinFeaturedChallenge}
                    dismissFeaturedChallenge={this.dismissFeaturedChallenge}
                    isFeaturedChallenge={isFeaturedChallenge(challenge)}
                    isTeamChallenge={this.isTeamChallenge}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => ({
        challenge: getChallenge(state, ownProps.challengeId),
        user: coreSelectors.getCurrentUser(state),
        isDismissing: isDismissing(state, ownProps.challengeId),
        isUpdating: isUpdatingAfterTracking(state, ownProps.challengeId),
        isTrackByDevice: isTrackByDevice(state, ownProps.challengeId),
        isTeamSizeIncorrect: isTeamSizeIncorrect(state, ownProps.challengeId),
        connectedDevices: getAllConnectedDevicesNames(state, ownProps.challengeId),
        customPointsName: coreSelectors.getCustomPointsUnit(state),
        isBonusChallenge: selectors.isBonusChallenge(state, ownProps.challengeId),
    });

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(ChallengeCardFooterBase));
}

const GRACE_INFO_WIDTH = 240;

export const styles = {
    footerMessage: {
        ...appFonts.xsRegular,
        paddingBottom: spacing.s1,
        color: baseColors.grey40
    },
    footerMessageDanger: {
        color: baseColors.dangerDarker,
    },
    inGraceInfo: {
        display: 'flex',
        ...appFonts.smRegular,
        color: baseColors.grey40,
        maxWidth: GRACE_INFO_WIDTH,
    },
    boldText: {
        color: baseColors.black,
        fontWeight: 'bold',
        marginRight: spacing.s0,
    },
    dismissBtnText: {
        ...appFonts.smBold,
        color: baseColors.secondary,
        padding: spacing.s3
    },
    featuredBtnsContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: spacing.s5,
        marginRight: spacing.s3,
        marginLeft: spacing.s3
    }
};
