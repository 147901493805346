import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import { connect } from 'react-redux';
import { getUnitSingularById } from '../../selectors';
import { spacing, baseColors, appFonts, layoutStyle } from '../../../../styles';
import { pointsHelper, translate, searchAndHighlightEntities, DATE_FORMATS, PLATFORMS, selectors as coreSelectors } from '../../../core';

export const ACTIVITY_STATUSES = Object.freeze({
    pending: 'pending',
    untracked: 'untracked',
    tracked: 'tracked',
});

export const ANIMATION_DURATION = 400;

export default function WithActivityTrackItemBase(WrappedComponent) {
    class ActivityTrackItemBase extends PureComponent {
        static propTypes = {
            onDelete: PropTypes.func,
            status: PropTypes.string,
            isAnimated: PropTypes.bool,
            isSelected: PropTypes.bool,
            i18n: PropTypes.object.isRequired,
            onClick: PropTypes.func.isRequired,
            activity: PropTypes.object.isRequired,
            unitSingular: PropTypes.string.isRequired,
            customPointsName: PropTypes.string.isRequired,
            customPointsUnit: PropTypes.string.isRequired
        };

        static defaultProps = {
            isAnimated: false,
            isSelected: false,
            onDelete: undefined,
            status: '',
        };

        componentDidMount() {
            // animate all pending
            if (this.hasAnimation) this.shiftRight();
        }

        componentDidUpdate(prevProps) {
            // animate pending activities that have been updated (quantity changed)
            if (this.hasAnimation && this.props.activity.quantity !== prevProps.activity.quantity) {
                this.shiftRight();
            }
        }

        shiftRight = () => {
            _.has(this, 'wrapped.shiftRight') && this.wrapped.shiftRight();
        };

        onDelete = () => {
            this.props.onDelete(this.props.activity);
        };

        get hasAnimation() {
            return this.props.isAnimated && this.props.status === ACTIVITY_STATUSES.pending;
        }

        get quantityPoints() {
            return pointsHelper.formatPointsByName(
                pointsHelper.roundedPoints(this.props.activity.unitPoints * this.props.activity.quantity),
                this.props.customPointsName
            );
        }

        get points() {
            return pointsHelper.formatPoints(pointsHelper.roundedPoints(this.props.activity.unitPoints), this.props.customPointsUnit);
        }

        get date() {
            return moment(this.props.activity.date).format(DATE_FORMATS.dayFullMonthFullDay);
        }

        openTracker = () => {
            // todo: find better solution to store highlight component, not inside activity object
            // for now need to be removed as we pass activity as navigation prop in parent - cause a crash
            this.props.onClick(_.omit(this.props.activity, [
                `activityName${searchAndHighlightEntities.SEARCH_NAME}`,
                `unitName${searchAndHighlightEntities.SEARCH_NAME}`
            ]));
        };

        get activityName() {
            return this.props.activity[`activityName${searchAndHighlightEntities.SEARCH_NAME}`] ||
                this.props.activity.activityName;
        }

        get unitName() {
            return this.props.activity[`unitName${searchAndHighlightEntities.SEARCH_NAME}`] ||
                this.props.activity.unitName;
        }

        get subtitle() {
            const { i18n, unitSingular } = this.props;
            return `${i18n.t('earn')} ${this.points}/${unitSingular}`;
        }

        get title() {
            return <Fragment>{this.activityName} ({this.unitName})</Fragment>;
        }

        get subtitleTracked() {
            return `${this.props.status === ACTIVITY_STATUSES.pending
                ? this.props.i18n.t('earn') : this.props.i18n.t('earned')} ${this.quantityPoints} - ${this.date}`;
        }

        get titleTracked() {
            return `${this.props.activity.activityName} (${this.props.activity.quantity} ${this.props.activity.unitName})`;
        }

        get colorTracked() {
            return this.props.status === ACTIVITY_STATUSES.pending
                ? layoutStyle.colorSecondary : layoutStyle.colorBlack;
        }

        get iconName() {
            return this.props.isSelected ? 'check' : 'plus';
        }

        get iconColor() {
            return this.props.isSelected ? baseColors.primary : baseColors.secondary;
        }

        get isAuto() {
            return !!this.props.activity.inputDevice && this.props.activity.inputDevice !== PLATFORMS.web;
        }

        saveRef = ref => (this.wrapped = ref);

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    ref={this.saveRef}
                    openTracker={this.openTracker}
                    titleTracked={this.titleTracked}
                    subtitleTracked={this.subtitleTracked}
                    title={this.title}
                    iconName={this.iconName}
                    iconColor={this.iconColor}
                    subtitle={this.subtitle}
                    colorTracked={this.colorTracked}
                    onDelete={this.onDelete}
                    isAuto={this.isAuto}
                    hasAnimation={this.hasAnimation}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const activity = ownProps.activity || {};
        return {
            customPointsUnit: coreSelectors.getCustomPointsUnit(state),
            customPointsName: coreSelectors.getCustomPointsName(state),
            unitSingular: activity.unitSingular || getUnitSingularById(state, activity.activityUnitId),
        };
    };

    return connect(mapStateToProps)(translate()(ActivityTrackItemBase));
}

const DOT_SIZE = spacing.s1;

export const styles = {
    titleStyle: {
        ...appFonts.mdBold,
    },
    titleMargin: {
        marginRight: spacing.s1
    },
    subtitle: {
        ...appFonts.smRegular,
        color: baseColors.grey40,
        marginTop: spacing.s0,
    },
    pendingDot: {
        backgroundColor: baseColors.secondary,
        width: DOT_SIZE,
        height: DOT_SIZE,
        borderRadius: DOT_SIZE / 2,
        marginTop: spacing.s0,
        marginRight: spacing.s2
    },
    center: {
        flexDirection: 'row',
        alignItems: 'center',

        display: 'flex'
    },
    icon: {
        marginBottom: spacing.s0
    },
};
