import React, { PureComponent } from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classnames from 'classnames';
import { windowSize, spacing, layoutStyle, media } from '../../../../styles';
import { ICON_SIZE, tourStyles, webStyles } from '../../styles';
import { components as Core } from '../../../core';
import WithToolTipModalBase, { styles as baseStyles } from './ToolTipModalBase';
import { ARROWS, UP_ARROW, DOWN_ARROW, ARROW_SIZE, isXSScreenResolution } from '../../constants';

const DEFAULT_CONTAINER_HEIGHT = 238;
const xSMenuWidth = 224;
const HEADER_HEIGHT = 48; //same as offset on large screens

class ToolTipModal extends PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string,
        text: PropTypes.string,
        onClose: PropTypes.func,
        onBack: PropTypes.func,
        onNext: PropTypes.func,
        onGotIt: PropTypes.func,
        nextButtonTitle: PropTypes.string,
        arrow: PropTypes.oneOf([UP_ARROW, DOWN_ARROW]),
        position: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
            pageX: PropTypes.number,
            pageY: PropTypes.number,
            isTabBar: PropTypes.bool
        }),
        hasSubtitle: PropTypes.bool,
        hasCloseButton: PropTypes.bool,
        buttonGotItContainer: PropTypes.object,
        disclaimer: PropTypes.node,
        tooltipClassName: PropTypes.object,
        hasBackDrop: PropTypes.bool
    };

    static defaultProps = {
        subtitle: undefined,
        onClose: null,
        onBack: null,
        onNext: null,
        onGotIt: null,
        nextButtonTitle: undefined,
        arrow: UP_ARROW,
        hasSubtitle: true,
        hasCloseButton: true,
        buttonGotItContainer: null,
        disclaimer: undefined,
        text: undefined,
        position: undefined,
        tooltipClassName: null,
        hasBackDrop: false
    };

    constructor(props) {
        super(props);
        this.state = {
            // if tooltip is cropped user will see redrawing tooltip, we need DEFAULT_CONTAINER_HEIGHT to avoid this
            containerHeight: DEFAULT_CONTAINER_HEIGHT
        };
    }

    // max value when tool tip modal is visible
    get limitedPosition() {
        return windowSize.height - this.state.containerHeight;
    }

    // get function returns true if ToolTip has top arrow
    // if ToolTipModal is cropped, function will returns another arrow and ToolTipModal will be displayed correctly
    get isTopArrow() {
        return this.props.arrow === ARROWS.UP;
    }

    get isXSScreenResolutionAndMenuItem() {
        return isXSScreenResolution() && this.props.position.isTabBar;
    }

    get containerStyles() {
        const { position } = this.props;
        const menuItemsXsStyles = this.isXSScreenResolutionAndMenuItem ? { top: HEADER_HEIGHT } : null;
        const bottomOffset =  _.min([windowSize.height - position.pageY - (ARROW_SIZE/2), this.limitedPosition]);
        const noBottomOffset = (bottomOffset + this.state.containerHeight) > windowSize.height;
        return this.isTopArrow ? { top: _.min([position.pageY + position.height, this.limitedPosition]), ...menuItemsXsStyles } :
            { bottom: noBottomOffset ? 'auto' : bottomOffset,
                position: 'fixed',
                width: position.width,
                paddingBottom: spacing.s3 };
    }

    get arrowStyles() {
        const styles = this.isXSScreenResolutionAndMenuItem ? { marginLeft: (windowSize.width - xSMenuWidth)/2 + spacing.s3 } : null;
        return { left: this.props.position.pageX || this.props.position.width/2 - ARROW_SIZE, ...styles };
    }

    render() {
        const { disclaimer, buttonGotItContainer, tooltipClassName } = this.props;
        return (
            <div className={css(styles.mainContainer)}>
                <div
                    style={this.containerStyles}
                    className={classnames(css(styles.toolTipContainer), tooltipClassName)}
                    ref={ref => this.container = ref}>
                    <div className={css(styles.elementsContainer)}>
                        <div className={css(styles.innerContainerTop)}>
                            <div className={css(styles.headerContainer, layoutStyle.flex)}>
                                <div className={css(styles.headerContainerTitle)}>
                                    <p className={css(styles.title)}>{this.props.title}</p>
                                </div>

                                {this.props.hasCloseButton ?
                                    <Core.IconButton name="times" type="light" size={ICON_SIZE} onClick={this.props.onClose} className={css(layoutStyle.noPadding)} /> : null
                                }
                            </div>

                            {this.props.hasSubtitle && this.props.subtitle ? (
                                <div className={css(styles.headerContainerTitle)}>
                                    <p className={css(styles.subtitle)}>{this.props.subtitle}</p>
                                </div>
                            ) : null}

                            {this.props.text ? (
                                <p className={css(styles.text)}>{this.props.text}</p>
                            ) : null}
                        </div>
                        {disclaimer}

                        <div className={css(styles.innerContainerBottom)}>
                            {this.props.onGotIt ?
                                <div className={css(styles.buttonContainer, styles.buttonGotItContainer, buttonGotItContainer)}>
                                    <Core.Button
                                        onPress={this.props.onGotIt}
                                        isSmall={true}
                                        className={css(styles.button)}>
                                        {this.props.i18n.t('got_it')}
                                    </Core.Button>
                                </div> :
                                <div className={css(styles.buttonContainer, layoutStyle.flex)}>
                                    <Core.Button
                                        onPress={this.props.onBack}
                                        size={Core.Button.SIZES.small}
                                        type="text"
                                        className={css(styles.backButton, styles.button)}>
                                        {this.props.i18n.t('back')}
                                    </Core.Button>
                                    <Core.Button
                                        onPress={this.props.onNext}
                                        size={Core.Button.SIZES.small}
                                        className={css(styles.button)}>
                                        {this.props.nextButtonTitle || this.props.i18n.t('next')}
                                    </Core.Button>
                                </div>
                            }
                        </div>
                    </div>
                    <div
                        style={this.arrowStyles}
                        className={css(styles.arrowContainer,
                            this.isTopArrow ? styles.arrowTopContainer : styles.arrowBottomContainer)}>
                        <div className={css(styles.triangle, this.isTopArrow ? styles.topArrow : styles.bottomArrow)} />
                    </div>
                    <div className={css(styles.measure)} />
                </div>
                {this.props.hasBackDrop ? <div className={css(styles.backDrop)}></div> : null}
            </div>
        );
    }
}


export default WithToolTipModalBase(ToolTipModal);


const styles = StyleSheet.create({
    ...tourStyles,
    ...baseStyles,
    ...webStyles,
    toolTipContainer: {
        [media.xs]: {
            left: 0,
            marginLeft: spacing.s3,
            marginRight: spacing.s3,
            width: `calc(100% - ${spacing.s7}px) !important`,
            position: 'fixed',
            paddingTop: spacing.s3,
            paddingBottom: spacing.s3
        }
    },
    backDrop: {
        position: 'fixed',
        width: '100%',
        background: 'rgba(0, 0, 0, 0.5)',
        height: '100%',
        top: 0,
        left: 0,
        zIndex: -1
    }
});
