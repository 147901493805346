import axios from 'axios';
import { getBaseUrl, MAX_COUNT_MORE, MAX_COUNT } from '../core';
import { ACHIEVEMENTS_STATUSES } from './constants';

const getUrl = endpoint => (endpoint ? getBaseUrl(`rewards${endpoint}`) : getBaseUrl('rewards'));
const getPartnersUrl = endpoint => (endpoint ? getBaseUrl(`earn_partners${endpoint}`) : getBaseUrl('earn_partners'));

export function getRewards() {
    return axios.get(getUrl());
}

export function getHeroProducts(parameters) {
    const params = { isHero: 1, ...parameters };
    return axios.get(getUrl('/catalog'), { params });
}

export function getProducts(parameters) {
    const params = { offset: 0, maxCount: MAX_COUNT, ...parameters };
    return axios.get(getUrl('/catalog'), { params });
}

export function getProduct(productId) {
    return axios.get(getUrl(`/catalog/${productId}`));
}

export function getRewardsCategories(parameters) {
    const params = { offset: 0, maxCount: MAX_COUNT_MORE, ...parameters };
    return axios.get(getUrl('/catalog_categories'), { params });
}

export function redeemProduct(data) {
    return axios.post(getUrl('/orders'), data);
}

export function getTransactions(parameters) {
    const params = { offset: 0, maxCount: MAX_COUNT, sort: 'timestampIssued-desc', ...parameters };
    return axios.get(getUrl('/transactions'), { params });
}

export function getOrders(parameters) {
    const params = { offset: 0, maxCount: MAX_COUNT, ...parameters };
    return axios.get(getUrl('/orders'), { params });
}

export function getOrder(orderId) {
    return axios.get(getUrl(`/orders/${orderId}`));
}

export function getLevels(period) {
    return axios.get(getBaseUrl(`users/user_levels/${period}`));
}

export function getAchievements(params) {
    return axios.get(getBaseUrl('achievements'), { params });
}

export function getUpcomingAchievements() {
    return getAchievements({ filter: ACHIEVEMENTS_STATUSES.upcoming });
}

export function getRedemptionToken(parameters) {
    const data = { ...parameters, context: 'relogin' };
    return axios.post(getBaseUrl('auth/login_sso'), data);
}

export function getShippingAddress() {
    return axios.get(getBaseUrl('users/shipping_address'));
}

export function getEarnPartners(params) {
    return axios.get(getPartnersUrl(), { params });
}

export function getEarnPartner(partnerId) {
    return axios.get(getPartnersUrl(`/${partnerId}`));
}

export function getEarnPartnerCategories(parameters) {
    return axios.get(getPartnersUrl('/categories'));
}

export function postEarnPartnerLink(parameters) {
    const partnerID = parameters.earnPartnerId;
    return axios.post(getPartnersUrl(`/${partnerID}/linked_accounts`), parameters);
}

export function putEarnPartnerLink(parameters) {
    const partnerID = parameters.earnPartnerId;
    return axios.put(getPartnersUrl(`/${partnerID}/linked_accounts`), parameters);
}

export function deleteEarnPartnerLink(parameters) {
    const partnerID = parameters.earnPartnerId;
    return axios.delete(getPartnersUrl(`/${partnerID}/linked_accounts`), parameters);
}