import PropTypes from 'prop-types';
import React, { PureComponent, Fragment } from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import { components as Core } from '../../../core';
import WithOrderAddressItemBase, { styles as baseStyles } from './OrderAddressItemBase';
import { layoutStyle, appFonts } from '../../../../styles';

class OrderAddressItem extends PureComponent {
    static propTypes = {
        address: PropTypes.object.isRequired,
        onEdit: PropTypes.func.isRequired,
        i18n: PropTypes.object.isRequired,
        userName: PropTypes.string
    };

    static defaultProps = {
        userName: undefined
    };

    render() {
        const { address: { address1, address2, city, country, region, postalCode }, userName, onEdit, i18n } = this.props;
        return (
            <Fragment>
                <span className={css(styles.fadedText)}>{i18n.t('shipToAddress')}</span>
                <div className={css(styles.shipContainer, layoutStyle.flex)}>
                    <div>
                        <p className={css(styles.shipLargeText)}>{userName}</p>
                        <p className={css(styles.shipText)}>{address1}</p>
                        {address2 ? <p className={css(styles.shipText)}>{address2}</p> : null}
                        <p className={css(styles.shipText)}>{`${city}, ${region}`}</p>
                        <p className={css(styles.shipText)}>{country}</p>
                        <p className={css(styles.shipText)}>{postalCode}</p>
                    </div>
                    {onEdit ?
                        <Core.Button
                            onPress={onEdit}
                            size={Core.Button.SIZES.medium}
                            className={css(layoutStyle.noShadow)}
                            color="secondary">
                            <span className={css(styles.shipLargeText, styles.editButton, styles.button)}>{i18n.t('edit')}</span>
                        </Core.Button> : null
                    }
                </div>
            </Fragment>
        );
    }
}

export default WithOrderAddressItemBase(OrderAddressItem);

const styles = StyleSheet.create({
    ...baseStyles,
    button: {
        ...appFonts.mdRegular
    },
    editButton: {
        ...baseStyles,
        padding: 0
    },
    shipContainer: {
        ...baseStyles.shipContainer,
        flex: 1
    }
});