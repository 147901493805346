import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import Calendar from 'react-calendar';
import classnames from 'classnames';
import moment from 'moment';
import _ from 'lodash';
import withTrackActivityDatepickerBase, {
    styles as baseStyles, INITIAL_MONTH_INDEX, SLIDING_DISTANCE
} from './TrackActivityDatepickerBase';
import { appFonts, baseColors, importantStyles, layoutStyle, spacing } from '../../../../styles';
import { CAROUSEL_TYPES, components as Core, CSS_CLASSES, DATE_FORMATS } from '../../../core';

class TrackActivityDatepicker extends PureComponent {
    static propTypes = {
        activityTrackedText: PropTypes.string.isRequired,
        isRightArrowHidden: PropTypes.bool.isRequired,
        areMaxDaysSelected: PropTypes.bool.isRequired,
        limitReachedText: PropTypes.string.isRequired,
        isLeftArrowHidden: PropTypes.bool.isRequired,
        datepickerTitle: PropTypes.string.isRequired,
        footerBtnText: PropTypes.string.isRequired,
        selectedText: PropTypes.string.isRequired,
        onSwitchMonths: PropTypes.func.isRequired,
        selectedDates: PropTypes.array.isRequired,
        activityLogs: PropTypes.array.isRequired,
        calendarData: PropTypes.array.isRequired,
        onDatePress: PropTypes.func.isRequired,
        language: PropTypes.string.isRequired,
        onSubmit: PropTypes.func.isRequired,
        close: PropTypes.func.isRequired,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.areMaxDaysSelected && !prevState.isLimitReachedTextShown) {
            return { isLimitReachedTextShown: true };
        }
        return null;
    }

    constructor(props) {
        super(props);
        this.state = {
            isLimitReachedTextShown: false,
            slideIndex: INITIAL_MONTH_INDEX
        };
    }

    toggleLimitReachedText = () =>
        this.setState(prevState => ({ isLimitReachedTextShown: !prevState.isLimitReachedTextShown }));

    dismissModal = () => this.props.close();

    tileClassName = date => {
        const { areMaxDaysSelected } = this.props;
        const isDateDisabled = moment(date).isAfter(moment()) || (areMaxDaysSelected && !this.isSelectedDate(date));
        return css(
            layoutStyle.flexColumn,
            styles.dayCell,
            this.isSelectedDate(date) && styles.selectedDayCell,
            isDateDisabled && styles.disabledDayCell,
            (!this.isSelectedDate(date) && !isDateDisabled) && styles.availableDayCell,
        );
    };

    isSelectedDate = date =>
        _.some(this.props.selectedDates, selectedDate => selectedDate.isSame(moment(date), 'day'));

    dotIcon = className => (
        <Core.Icon
            name="circle"
            fill="solid"
            type="fa"
            className={className}
            color={baseColors.primary}
            size={6}
        />
    );

    tileContent = ({ date }) => {
        const day = moment(date).format(DATE_FORMATS.full);
        if (_.find(this.props.activityLogs, ac => ac.date === day)) {
            return this.dotIcon(css(styles.dotInCell));
        }
        return null;
    };

    tileDisabled = ({ date }) => this.props.areMaxDaysSelected && !this.isSelectedDate(date);

    renderMonth = item => (
        <div key={item.month}>
            <p className={css(layoutStyle.textCenter, styles.monthName)}>
                {moment(item.month).format(DATE_FORMATS.monthFull)}
            </p>
            <Calendar
                className={classnames(css(styles.calendar), CSS_CLASSES.reactCalendar)}
                tileClassName={({ date }) => this.tileClassName(date)}
                activeStartDate={moment(item.month).toDate()}
                onClickDay={this.props.onDatePress}
                tileDisabled={this.tileDisabled}
                tileContent={this.tileContent}
                showNeighboringMonth={false}
                locale={this.props.language}
                showNavigation={false}
                maxDate={new Date()}
            />
        </div>
    );

    onCarouselRef = ref => {
        this.carouselRef = ref;
    };

    onClickNext = () => {
        if (this.carouselRef) this.carouselRef.slickGoTo(this.state.slideIndex + 1);
        this.setState({ slideIndex: this.state.slideIndex + 1 });
    };

    onClickPrev = () => {
        if (this.carouselRef) this.carouselRef.slickGoTo(this.state.slideIndex - 1);
        this.setState({ slideIndex: this.state.slideIndex - 1 });
    };

    beforeChange = (current, next) => this.props.onSwitchMonths(next);

    get arrows() {
        const { isLeftArrowHidden, isRightArrowHidden } = this.props;
        return (
            <div className={css(layoutStyle.flexSpaceBetween, styles.arrows)}>
                {isLeftArrowHidden ? <div /> : (
                    <Core.IconButton
                        name="angle-left"
                        fill="light"
                        type="fa"
                        className={css(styles.arrowButton)}
                        color={baseColors.secondary}
                        onClick={this.onClickPrev}
                        size={spacing.s7}
                    />)
                }
                {isRightArrowHidden ? <div /> : (
                    <Core.IconButton
                        name="angle-right"
                        fill="light"
                        type="fa"
                        className={css(styles.arrowButton)}
                        color={baseColors.secondary}
                        onClick={this.onClickNext}
                        size={spacing.s7}
                    />)
                }
            </div>
        );
    }

    get subheader() {
        const { datepickerTitle, close } = this.props;
        return (
            <div className={css(styles.subheader)}>
                <span />
                <p className={css(styles.title)}>{datepickerTitle}</p>
                <div className={css(styles.closeButton)}>
                    <Core.CloseButton onClick={close} />
                </div>
            </div>
        );
    }

    get calendarCarousel() {
        const { calendarData } = this.props;
        return (
            <div className={css(layoutStyle.pRelative)}>
                {this.arrows}
                <Core.Carousel
                    dotsClassName={css(styles.carouselDots)}
                    activeSlide={INITIAL_MONTH_INDEX}
                    itemsLength={calendarData.length}
                    beforeChange={this.beforeChange}
                    type={CAROUSEL_TYPES.single}
                    onRef={this.onCarouselRef}
                    adaptiveHeight={true}
                    noBottomMargin={true}
                    arrows={false}
                >
                    {_.map(calendarData, this.renderMonth)}
                </Core.Carousel>
            </div>
        );
    }

    get footer() {
        const { selectedDates, footerBtnText, selectedText, onSubmit, limitReachedText, areMaxDaysSelected } = this.props;
        if (!areMaxDaysSelected && this.state.isLimitReachedTextShown) setTimeout(this.toggleLimitReachedText, 200);
        return (
            <div className={css(layoutStyle.fixedFooterModalContainer, styles.footer)}>
                <div className={css(layoutStyle.fw, areMaxDaysSelected ? styles.slideUp : styles.slideDown)}>
                    <p className={css(styles.selectedText)}>{selectedText}</p>
                    <p className={css(
                        (areMaxDaysSelected || this.state.isLimitReachedTextShown) ? styles.limitReachedText : layoutStyle.hidden
                    )}>
                        {limitReachedText}
                    </p>
                </div>
                <div className={css(styles.submitButtonContainer, layoutStyle.fw)}>
                    <Core.Button
                        size="medium"
                        className={css(layoutStyle.fw)}
                        disabled={!selectedDates.length}
                        halfOpacityButton={true}
                        onPress={onSubmit}
                    >
                        {footerBtnText}
                    </Core.Button>
                </div>
            </div>
        );
    }

    get activityTrackedLegend() {
        return (
            <p className={css(styles.activityTrackedLegend, layoutStyle.flexCenter)}>
                {this.dotIcon(css(styles.dot))} {this.props.activityTrackedText}
            </p>
        );
    }

    render() {
        return (
            <Core.Layout.FHFlex>
                {this.subheader}
                {this.calendarCarousel}
                {this.activityTrackedLegend}
                {this.footer}
            </Core.Layout.FHFlex>
        );
    }
}

export default withTrackActivityDatepickerBase(TrackActivityDatepicker);

const styles = StyleSheet.create({
    ...baseStyles,
    subheader: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: spacing.s3,
        alignItems: 'center',
    },
    closeButton: {
        marginLeft: 'auto',
    },
    title: {
        ...appFonts.lgBold,
        textAlign: 'center',
        width: '100%',
        display: 'flex', 
        justifyContent: 'center',
        marginLeft: spacing.s7
    },
    monthName: {
        ...appFonts.lgRegular,
        marginBottom: spacing.s1,
        padding: spacing.s2,
    },
    dotInCell: {
        marginTop: spacing.s0,
    },
    dot: {
        marginRight: spacing.s1,
    },
    //eslint-disable-next-line
    '@keyframes slideUp': {
        from: {
            transform: `translateY(${SLIDING_DISTANCE}px)`,
            animationTimingFunction: 'ease-in',
        },
        to: { transform: 'translateY(0)' }
    },
    slideUp: {
        animationName: 'slideUp',
        animationDuration: '0.2s',
    },
    //eslint-disable-next-line
    '@keyframes slideDown': {
        from: {
            transform: 'translateY(0)',
            animationTimingFunction: 'ease-out',
        },
        to: { transform: `translateY(${SLIDING_DISTANCE}px)` }
    },
    slideDown: {
        animationName: 'slideDown',
        animationDuration: '0.2s',
    },
    submitButtonContainer: {
        backgroundColor: baseColors.white,
        paddingTop: spacing.s3,
        zIndex: 1,
    },
    ...importantStyles({
        arrowButton: {
            paddingBottom: spacing.s1,
            paddingTop: spacing.s1,
        },
        calendar: {
            border: 'none',
            margin: 'auto',
            width: '100%',
        },
        dayCell: {
            ...appFonts.smMedium,
            transition: 'background-color 0.3s, color 0.2s',
            backgroundColor: baseColors.white,
            paddingBottom: spacing.s0 - 2,
            color: baseColors.grey20,
            alignItems: 'center',
            height: spacing.s12,
            padding: spacing.s0,
        },
        availableDayCell: {
            '&:hover': {
                backgroundColor: baseColors.grey80,
            },
        },
        selectedDayCell: {
            backgroundColor: baseColors.contextLightest,
        },
        disabledDayCell: {
            color: baseColors.grey50,
            cursor: 'not-allowed',
        },
        carouselDots: {
            marginTop: -spacing.s2,
        },
        footer: {
            padding: spacing.s8,
            paddingTop: 0,
        },
    }),
});
