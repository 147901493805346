import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import _ from 'lodash';
import WithReachGoalBase, { styles as baseStyles } from './ReachGoalBase';
import OnboardingInfoScreen from '../OnboardingInfoScreen';
import { components as Core, ROUTES } from '../../../core';
import InfoScreen from '../InfoScreen';
import { spacing, layoutStyle } from '../../../../styles';

class ReachGoal extends PureComponent {
    static propTypes = {
        actions: PropTypes.object.isRequired,
        i18n: PropTypes.object.isRequired,
        task: PropTypes.object.isRequired,
        isLoading: PropTypes.bool,
        onNext: PropTypes.func,
        onSkip: PropTypes.func,
        screen: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        isOnboarding: PropTypes.bool,
        rightButtonLabel: PropTypes.string.isRequired,
        step: PropTypes.string
    };

    static defaultProps = {
        isLoading: false,
        onSkip: null,
        onNext: null,
        isOnboarding: false,
        step: null
    };

    onNext = () => {
        if (this.props.onNext) return this.props.onNext(this.props.step);
        this.props.history.push(ROUTES.EAPSelectOptions());
    };

    onSkip = () => {
        if (this.props.onSkip) {
            this.props.onSkip();
        } else {
            this.props.actions.setOnboardingComplete();
            this.props.history.push(ROUTES.me());
        }
    };

    get innerContent() {
        return (
            <p clasName={css(styles.description)}>
                {this.props.i18n.t('EAP.reachGoal.description', { providerName: this.props.task.name })}
            </p>
        );
    }

    get body() {
        return (
            <Core.Layout.PageWrapper isModal={this.props.isOnboarding}>
                {this.innerContent}
            </Core.Layout.PageWrapper>
        );
    }

    renderPage = () => {
        const icon = _.get(this.props.screen, 'icon_name');
        const title = _.get(this.props.screen, 'group_name');
        const bodyText = _.get(this.props.screen, 'main_description');
        return (
            <Core.Layout.WiderContainer className={css(styles.bottomPadding)}>
                <Core.EntityDetailsHeader hasBackButton={true} />
                <InfoScreen
                    {...this.props}
                    isOnboarding={false}
                    title={title}
                    icon={icon}
                    bodyText={bodyText}
                    hasNextButton={false}
                    customBody={this.body}
                    onClick={this.onNext}
                />
                <Core.Layout.FixedFooterWiderContainer>
                    <div className={css(layoutStyle.bgWhite, layoutStyle.flexJustifyCenter)}>
                        <Core.Button
                            type="contained"
                            size="large"
                            className={css(layoutStyle.halfWidth)}
                            onPress={this.onNext}>
                            {this.props.i18n.t('next')}
                        </Core.Button>
                    </div>
                </Core.Layout.FixedFooterWiderContainer>
            </Core.Layout.WiderContainer>
        );
    };

    renderInfoScreen = () => (
        <OnboardingInfoScreen
            hasBorder={true}
            screen={this.props.screen}
            isLoading={this.props.isLoading}
            customBody={this.body}
            rightButtonLabel={this.props.rightButtonLabel}
            go={this.onNext}
            skip={this.onSkip}
            isEAP={true}
            hasSkipGoBtns={true}
            onboardingNewDesign={true}
        />
    );

    render() {
        return this.props.isOnboarding ? this.renderInfoScreen() : this.renderPage();
    }
}

const styles = StyleSheet.create({
    ...baseStyles,
    bottomPadding: {
        paddingBottom: spacing.s3
    }
});

export default WithReachGoalBase(ReachGoal);