import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { spacing, baseColors, containerStyle, layoutStyle } from '../../../../styles/commonCSS';
import { components as Core } from '../../../core';
import Logo from '../Logo';
import WithCorporatePinBase, { styles as baseStyles, ANIMATION_DURATION } from './CorporatePinBase';

class CorporatePin extends PureComponent {
    static propTypes = {
        numOfDigits: PropTypes.number,
        error: PropTypes.object,
        isLoading: PropTypes.bool,
        i18n: PropTypes.object.isRequired,
        pinReturned: PropTypes.func.isRequired,
        onAnimationEnd: PropTypes.func.isRequired,
        showCheck: PropTypes.bool.isRequired,
        pin: PropTypes.string.isRequired,
    };

    static defaultProps = {
        numOfDigits: 4,
        error: undefined,
        isLoading: false
    };

    clearText = () => {
        this._pin.clearText();
    };

    render() {
        const { i18n } = this.props;
        return (
            <div className={css(layoutStyle.fw)}>
                <div className={css(containerStyle.container, layoutStyle.textCenter)}>
                    <div className={css(containerStyle.header)}>
                        <Logo isSaved={true} colorLogo={true} />
                    </div>
                    <div className={css(containerStyle.main)}>
                        <div className={css(styles.question)}>
                            <p className={css(styles.text)}>
                                {i18n.t('enterPIN')}
                            </p>
                        </div>
                        <div>
                            <Core.PinDots
                                ref={c => this._pin = c}
                                numOfDigits={this.props.numOfDigits}
                                callback={this.props.pinReturned}
                                obfuscate={false}
                            />
                            {this.props.error ?
                                <p className={css(styles.errorMessage, styles.inputValidationError)}>
                                    {i18n.t('pinError', { pin: this.props.pin })}
                                </p> : null
                            }
                        </div>
                    </div>
                    {(this.props.isLoading || this.props.showCheck) ?
                        <>
                            {this.props.showCheck ? null : <Core.ListLoading />}
                            <div className={css(containerStyle.overlay, styles.loadingContainer)}></div>
                            <Core.FadeIn
                                exit={false}
                                in={this.props.showCheck}
                                duration={ANIMATION_DURATION}
                                onEntered={this.props.onAnimationEnd}>
                                <Core.Icon
                                    type="fa"
                                    name="check"
                                    size={spacing.s9}
                                    color={baseColors.secondary}
                                />
                            </Core.FadeIn>
                        </> : null
                    }
                </div>
            </div>
        );
    }
}

export default WithCorporatePinBase(CorporatePin);

const styles = StyleSheet.create({
    ...baseStyles,
    loadingContainer: {
        ...baseStyles.loadingContainer,
        display: 'flex',
        flexDirection: 'column'
    }
});
