import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { bindActionCreators } from 'redux';

import { baseColors, spacing, appFonts } from '../../../../styles';
import { selectors as authSelectors } from '../../../auth';
import { translate, Platform, PLATFORMS } from '../../../core';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import * as constants from '../../constants';
import { services as settingsServices, selectors as settingsSelectors } from '../../../settings';
import { checkForErrors, formatTypedDateForWeb } from '../../services/validationHelper';

export const ICON_SIZE = 64;
const TIME = {
    hour: 12,
    minute: 0,
    second: 0
};
export const NUMBER_INPUT_MAX_LENGTH = 3;
const HEIGHT_FIELDS = [constants.VITAL_STATS.heightValue, constants.VITAL_STATS.feetValue];

export default function WithVitalStatsBase(WrappedComponent) {
    class VitalStatsBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            question: PropTypes.object.isRequired,
            onClick: PropTypes.func.isRequired,
            dobEnabled: PropTypes.bool,
            isSaving: PropTypes.bool,
            settingStatsError: PropTypes.object,
            allFieldsRequired: PropTypes.bool,
            isPrivacyMessageEnabled: PropTypes.bool,
            i18n: PropTypes.object.isRequired
        };

        static defaultProps = {
            settingStatsError: undefined,
            isSaving: false,
            dobEnabled: true,
            allFieldsRequired: true,
            isPrivacyMessageEnabled: false
        };

        constructor(props) {
            super(props);
            this.state = {
                ...VitalStatsBase.initState(props.question),
                errors: {
                    height: null,
                    weight: null,
                    dateOfBirth: null
                }
            };
        }

        static getDerivedStateFromProps(nextProps, prevState) {
            if (!nextProps.isLoading && prevState.isLoading) {
                return {
                    isLoading: nextProps.isLoading,
                    ...VitalStatsBase.initState(nextProps.question)
                };
            }
            if (!nextProps.isSaving && prevState.isSaving && !nextProps.settingStatsError) {
                nextProps.onClick();
            }
            return { isLoading: nextProps.isLoading, isSaving: nextProps.isSaving };
        }

        componentWillUnmount() {
            if (this.props.settingStatsError) this.props.actions.clearVitalStatsError();
        }

        onChange = stateKey => text => {
            const { heightValue, feetValue } = constants.VITAL_STATS;
            if (_.isNaN(text)) return;
            const value = _.toString(text);
            if (!_.includes(HEIGHT_FIELDS, stateKey)) {
                const { unit, unitToCompare } = this.state.storedInputs[stateKey];
                this.setState(oldState => ({
                    [stateKey]: value,
                    storedInputs: {
                        ...oldState.storedInputs,
                        [stateKey]: {
                            ...oldState.storedInputs[stateKey],
                            ...((unit !== oldState[unitToCompare]) && { unit: oldState[unitToCompare] }),
                            value
                        }
                    }
                }));
            } else {
                const { unit, unitToCompare } = this.state.storedInputs.heightValue;
                this.setState(oldState => ({
                    ...(stateKey === feetValue && { feetValue: value }),
                    ...(stateKey === heightValue && { heightValue: value }),
                    storedInputs: {
                        ...oldState.storedInputs,
                        heightValue: {
                            ...oldState.storedInputs.heightValue,
                            ...((unit !== oldState[unitToCompare]) && { unit: oldState[unitToCompare] }),
                            ...(stateKey === heightValue && { value, ftValue: oldState.feetValue }),
                            ...(stateKey === feetValue && {
                                ftValue: value,
                                value: oldState.heightValue
                            })
                        }
                    }
                }));
            }
        };

        static formatDate = date => new Date(moment(date).set(TIME).format());

        onDateChange = event => {
            if (Platform.OS === PLATFORMS.web) {
                const dob = event.currentTarget.value;
                if (dob instanceof Date && !_.isNaN(dob)) {
                    const dateOfBirth = VitalStatsBase.formatDate(dob);
                    this.setState({ dateOfBirth });
                } else {
                    const updatedString = formatTypedDateForWeb({ dob });

                    this.setState({ dateOfBirth: updatedString });
                }
            } else {
                const dob = event;
                const dateOfBirth = VitalStatsBase.formatDate(dob);
                this.setState({ dateOfBirth });
            }
        };

        onGenderChange = gender => this.setState({ [constants.VITAL_STATS.gender]: gender.id });

        onHeightUnit = unit => {
            const isCmUnit = (unit === constants.CM);
            const converted = this.convertValueOnUnitChange(constants.VITAL_STATS.heightValue, 'userPreferredHeightUnit', unit);
            let newState = null;
            if (converted) {
                const heightValue = isCmUnit ? converted : converted.inches;
                newState = {
                    heightValue: _.toString(heightValue),
                    ...(!isCmUnit && { feetValue: _.toString(converted.ft) })
                };
            }
            this.setState(oldState => ({
                ...newState,
                [constants.VITAL_STATS.userPreferredHeightUnit]: unit,
                errors: {
                    ...oldState.errors,
                    height: null
                }
            }));
        };

        onWeightUnit = unit => {
            const convertedValue = this.convertValueOnUnitChange(constants.VITAL_STATS.weightValue, 'userPreferredWeightUnit', unit);
            this.setState(oldState => ({
                ...(convertedValue && { [constants.VITAL_STATS.weightValue]: _.toString(convertedValue) }),
                [constants.VITAL_STATS.userPreferredWeightUnit]: unit,
                errors: {
                    ...oldState.errors,
                    weight: null
                }
            }));
        };

        convertValueOnUnitChange = (stateKey, unitName, unitValue) => {
            const { value, unit } = this.state.storedInputs[stateKey];
            if (unit === unitValue) {
                // if user toggles the unit without previously changing the field value, we show the stored value
                const { heightValue } = this.state.storedInputs;
                return (unitValue !== constants.CM && stateKey === constants.VITAL_STATS.heightValue)
                    ? {
                        ft: heightValue.ftValue,
                        inches: heightValue.value
                    } : value;
            }

            if (stateKey === constants.VITAL_STATS.heightValue) {
                const { feetValue, heightValue } = this.state;
                return (unitValue === constants.CM)
                    ? settingsServices.convertFtAndInchesToCm(feetValue || 0, heightValue || 0)
                    : settingsServices.convertCmToFtAndInches(heightValue || 0);
            }

            const oldValue = _.toString(this.state[stateKey]);
            if (oldValue) {
                switch (stateKey) {
                    case constants.VITAL_STATS.weightValue:
                        return ((unitValue === constants.KG)
                            ? settingsServices.convertLbsToKg(oldValue)
                            : settingsServices.convertKgToLbs(oldValue)).toFixed(2);
                    default:
                        return oldValue;
                }
            }
            return null;
        };

        static initState = question => {
            const stateStore = {};
            _.mapKeys(question, (val, key) => {
                switch (key) {
                    case constants.VITAL_STATS.dateOfBirth:
                        if (val.enabled) stateStore.dateOfBirth = val.id === null ? null : VitalStatsBase.formatDate(val.id);
                        break;
                    case constants.VITAL_STATS.gender:
                    case constants.VITAL_STATS.weightLbs:
                    case constants.VITAL_STATS.heightInches:
                    case constants.VITAL_STATS.userPreferredHeightUnit:
                    case constants.VITAL_STATS.userPreferredWeightUnit:
                        if (val.enabled) stateStore[key] = val.id;
                        break;
                    default:
                }
            });

            stateStore.heightValue = '';
            stateStore.feetValue = '';
            stateStore.weightValue = '';
            let initialHeightValue = '';
            let initialFeetValue = '';
            let initialWeightValue = '';

            if (_.get(stateStore, constants.VITAL_STATS.heightInches)) {
                if (stateStore.userPreferredHeightUnit === constants.CM) {
                    stateStore.heightValue = _.toString(Math.round(settingsServices.convertInchesToCm(stateStore.heightInches)));
                    stateStore.feetValue = '0';
                }
                else {
                    stateStore.heightValue = _.toString(Math.round(settingsServices.convertInchesToFtAndInches(stateStore.heightInches, constants.IN)));
                    stateStore.feetValue = _.toString(Math.round(settingsServices.convertInchesToFtAndInches(stateStore.heightInches)));
                }
                initialHeightValue = stateStore.heightValue;
                initialFeetValue = stateStore.feetValue;
            }
            if (_.get(stateStore, constants.VITAL_STATS.weightLbs)) {
                stateStore.weightValue = _.toString(Math.round(stateStore.userPreferredWeightUnit === constants.KG ?
                    settingsServices.convertLbsToKg(stateStore.weightLbs) :
                    stateStore.weightLbs
                ));
                initialWeightValue = stateStore.weightValue;
            }
            return {
                ...stateStore,
                storedInputs: {
                    [constants.VITAL_STATS.heightValue]: {
                        unit: stateStore.userPreferredHeightUnit,
                        unitToCompare: 'userPreferredHeightUnit',
                        value: initialHeightValue,
                        ftValue: initialFeetValue
                    },
                    [constants.VITAL_STATS.weightValue]: {
                        unit: stateStore.userPreferredWeightUnit,
                        unitToCompare: 'userPreferredWeightUnit',
                        value: initialWeightValue
                    },
                }
            };
        };

        setErrorString = errors => {
            const emptyErrors = {
                height: null,
                weight: null,
                dateOfBirth: null
            };
            this.setState({ errors: { ...emptyErrors, ...errors } });
        };

        get genderError() {
            return (_.get(this.props.settingStatsError, 'errorDetails.fieldName') === constants.VITAL_STATS.gender)
                ? this.props.settingStatsError.displayMessage : null;
        }

        submitAnswers = () => {
            if (this.props.settingStatsError) this.props.actions.clearVitalStatsError();

            const { weightValue, feetValue, userPreferredHeightUnit, userPreferredWeightUnit } = this.state;
            let dateOfBirth = this.state.dateOfBirth;
            const { question, allFieldsRequired } = this.props;
            let heightValue = this.state.heightValue;
            if (userPreferredHeightUnit !== constants.CM) {
                const feetValueInInches = feetValue ? settingsServices.convertFtToInches(parseInt(feetValue)) : 0;
                const inchesValue = heightValue ? parseInt(heightValue) : 0;
                heightValue = feetValueInInches + inchesValue;
            }

            // Add missing 0 in day portion of date if necessary
            if (dateOfBirth.length === 9) {
                dateOfBirth = `${dateOfBirth.substring(0, 8)}0${dateOfBirth.substring(8)}`;
            }

            const errors = checkForErrors({
                heightValue,
                weightValue,
                dateOfBirth,
                question,
                allFieldsRequired,
                userPreferredHeightUnit,
                userPreferredWeightUnit,
            });
            if (!_.isEmpty(errors)) {
                this.setErrorString(errors);
            } else {
                const answers = {};
                _.mapKeys(this.state, (val, key) => {
                    switch (key) {
                        case constants.VITAL_STATS.dateOfBirth:
                            answers.dateOfBirth = VitalStatsBase.formatDate(val);
                            break;
                        case constants.VITAL_STATS.gender:
                        case constants.VITAL_STATS.userPreferredHeightUnit:
                        case constants.VITAL_STATS.userPreferredWeightUnit:
                            answers[key] = val;
                            break;
                        case constants.VITAL_STATS.heightValue:
                            answers.heightInches = (userPreferredHeightUnit === constants.CM)
                                ? settingsServices.convertCmToInches(val)
                                : parseFloat(val);
                            break;
                        case constants.VITAL_STATS.feetValue:
                            if (this.state.userPreferredHeightUnit !== constants.CM) {
                                answers.heightInches = heightValue;
                            }
                            break;
                        case constants.VITAL_STATS.weightValue:
                            answers.weightLbs = (userPreferredWeightUnit === constants.KG)
                                ? settingsServices.convertKgToLbs(val)
                                : parseFloat(val);
                            break;
                        default:
                    }
                });
                this.props.actions.setVitalStats(answers);
            }
        };

        getRequiredFieldName = (name, obj) => {
            const { i18n, allFieldsRequired } = this.props;
            return i18n.t(name).concat((obj.required || allFieldsRequired) ? '*' : '');
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    {...this.state}
                    genderError={this.genderError}
                    onChange={this.onChange}
                    onGenderChange={this.onGenderChange}
                    submitAnswers={this.submitAnswers}
                    onDateChange={this.onDateChange}
                    onHeightUnit={this.onHeightUnit}
                    onWeightUnit={this.onWeightUnit}
                    getRequiredFieldName={this.getRequiredFieldName}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => ({
        question: selectors.getHealthSurveyQuestionById(state, ownProps.id),
        dobEnabled: settingsSelectors.isDateOfBirthEnabled(state),
        isPrivacyMessageEnabled: authSelectors.getCompanyConfiguration(state).isPrivacyMessageEnabled,
        settingStatsError: selectors.getVitalStatsError(state),
        isLoading: selectors.isLoadingHealthSurvey(state) || settingsSelectors.isLoadingPersonalSettings(state),
        isSaving: selectors.isSavingVitalStats(state)
    });

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(VitalStatsBase));
}

export const styles = {
    header: {
        flexDirection: 'row',
        alignSelf: 'center'
    },
    textStyle: {
        color: baseColors.black
    },
    headerText: {
        ...appFonts.xxxlBold,
        marginBottom: spacing.s5
    },
    genderQuestionSeparator: {
        marginTop: spacing.s0,
        height: spacing.s12,
        marginBottom: spacing.s6
    },
    icon: {
        alignSelf: 'center',
        marginTop: spacing.s5,
        marginBottom: spacing.s3
    }
};
