import React, { PureComponent } from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { baseColors, layoutStyle, spacing, containerStyle } from '../../../../styles';
import { AsyncComponent, components as Core, Modal } from '../../../core';
import WithFindFriendsAndFamilyBase, { styles as baseStyles } from './FindFriendsAndFamilyBase';
import PlatformUserItem from '../PlatformUserItem';
import { components as Tours, constants as tourConstants } from '../../../tours';
import { ARROW_ICON, USER_CHECK_ICON, USER_PLUS_ICON } from '../../constants';
import { components as Settings, constants as settingsConstants } from '../../../settings';

class FindFriendsAndFamily extends PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        search: PropTypes.string.isRequired,
        debounceSearch: PropTypes.string.isRequired,
        onSearch: PropTypes.func.isRequired,
        platformUsers: PropTypes.array.isRequired,
        isLoading: PropTypes.bool.isRequired,
        isLoadingMore: PropTypes.bool.isRequired,
        onClearSearch: PropTypes.func.isRequired,
        programName: PropTypes.string.isRequired,
        isShownLearnFriendsAndFamilyModal: PropTypes.bool.isRequired,
        showLearnFriendsAndFamilyModal: PropTypes.func.isRequired,
        acceptedPlatformUsers: PropTypes.array.isRequired,
        isLoadingPlatformUsers: PropTypes.bool.isRequired,
        isFinishedFriendsAndFamilyTour: PropTypes.bool.isRequired,
        isAbleToShowTour: PropTypes.bool.isRequired,
        onCloseLearnModal: PropTypes.func.isRequired,
        learnMoreModalProps: PropTypes.object.isRequired,
        title: PropTypes.string.isRequired,
        isOpenedFromSettings: PropTypes.bool,
        inviteText: PropTypes.string.isRequired,
    };

    static defaultProps = {
        isOpenedFromSettings: false,
    };

    componentDidMount() {
        if (!this.props.isShownLearnFriendsAndFamilyModal) {
            this.onLearnMore();
            this.props.showLearnFriendsAndFamilyModal();
        }
    }

    onInviteWithEmail = () => {
        Modal.open(
            AsyncComponent(() => import('../InviteByEmail')),
            {
                isModal: true,
                isOpenedFromSettings: this.props.isOpenedFromSettings,
                title: this.props.inviteText
            },
            { isContainer: true, isNoPadding: true, isFullHeight: true }
        );
    };

    onCloseLearnModal = () => this.props.onCloseLearnModal(this.closeModal);

    renderLearnModalText = () => {
        const { i18n, programName } = this.props;
        return (
            <div>
                <p className={css(styles.learnText, layoutStyle.commonPaddingBottom)}>{i18n.t('friendsAndFamily.learnModal.description_line1', { programName })}</p>
                <p className={css(styles.learnText)}>{i18n.t('friendsAndFamily.learnModal.description_line2', { programName })}</p>
                <p className={css(styles.learnIconsMean)}>{i18n.t('friendsAndFamily.learnModal.whatIconsMean')}</p>
                <div className={css(styles.learnIconsContainer, layoutStyle.flex)}>
                    <div className={css(styles.learnIconItem)}>
                        <Core.Icon name={USER_PLUS_ICON} type="fa" size={spacing.s5} color={baseColors.secondary} />
                        <p className={css(styles.learnIconText)}>{i18n.t('friendsAndFamily.learnModal.canAdd')}</p>
                    </div>
                    <div className={css(styles.learnIconItem)}>
                        <Core.Icon name={USER_CHECK_ICON} type="fa" size={spacing.s5} />
                        <p className={css(styles.learnIconText)}>{i18n.t('friendsAndFamily.learnModal.added')}</p>
                    </div>
                </div>
            </div>
        );
    };

    onLearnMore = () => {
        this.closeModal = Modal.open(
            Core.InfoModal,
            {
                ...this.props.learnMoreModalProps(),
                renderText: this.renderLearnModalText,
                onButtonPress: this.onCloseLearnModal
            },
            { isContainer: true, isTransparent: true, isNoPadding: true }
        );
    };

    get loadingComponent() {
        return <Core.ListLoading key="loading" />;
    }

    goBack = () => this.props.history.goBack();

    get renderEmptyMessage() {
        const { i18n, isLoadingPlatformUsers } = this.props;
        if (isLoadingPlatformUsers) return null;
        return (
            <div className={css(styles.descriptionContainer, layoutStyle.flexColumn)}>
                <Core.Icon name={ARROW_ICON} type="fa" size={spacing.s5} />
                <p className={css(styles.descriptionItem)}>{i18n.t('friendsAndFamily.searchDescription1')}</p>
                <p className={css(styles.descriptionItem)}>{i18n.t('friendsAndFamily.searchDescription2')}</p>
            </div>
        );
    }

    get renderInviteByEmail() {
        return (
            <div className={css(styles.emailContainer, layoutStyle.flexColumn)}>
                <p className={css(styles.emailContainerText)}>{this.props.i18n.t('invitation.whoYouAreLookingFor')}</p>
                <Core.Button
                    onPress={this.onInviteWithEmail}
                    className={css(styles.emailButton)}>
                    {this.props.i18n.t('invitation.inviteWithEmail')}
                </Core.Button>
            </div>
        );
    }

    renderItem = (item, index) => (
        <PlatformUserItem
            userId={this.props.debounceSearch ? item : item.id}
            key={this.keyExtractor}
            isSuggestedUser={this.props.debounceSearch}
            hasIconRef={!this.props.debounceSearch && !this.props.isFinishedFriendsAndFamilyTour && !index}
        />
    );

    renderFooter = () => this.props.isLoadingMore || this.props.isLoadingPlatformUsers ? this.loadingComponent : null;

    get data() {
        if (this.props.isLoadingPlatformUsers) return [];
        return this.props.debounceSearch ? this.props.platformUsers : this.props.acceptedPlatformUsers;
    }

    keyExtractor = item => this.props.debounceSearch ? item : item.id;

    get emptyMessage() {
        return this.props.debounceSearch ? this.renderInviteByEmail : this.renderEmptyMessage;
    }

    get content() {
        return (
            <>
                <Core.SubHeader
                    title={this.props.title}
                    noTopPadding={true}
                />
                <div className={css(styles.learnMore, layoutStyle.flexSpaceBetween)}>
                    <Core.Button
                        type="text"
                        className={(styles.learnMoreText)}
                        onPress={this.onLearnMore}>
                        {this.props.i18n.t('friendsAndFamily.learnMore')}
                    </Core.Button>
                    {this.props.isOpenedFromSettings && (
                        <Core.Button
                            type="text"
                            className={css(styles.navButton)}
                            onPress={this.onInviteWithEmail}>
                            {this.props.inviteText}
                        </Core.Button>
                    )}
                </div>
                <Settings.PrivacySection
                    slug={settingsConstants.PRIVACY_SLUGS.SEARCH_RESULTS}
                    modalLightDesign={true}
                    isLightStyle={true}
                />
                <div className={css(containerStyle.inputContainer)}>
                    <Core.SearchBar
                        value={this.props.search}
                        onChangeText={this.props.onSearch}
                        onClear={this.props.onClearSearch}
                        label={this.props.i18n.t('search')}
                    />
                </div>
                {this.props.isLoading ? this.loadingComponent : null}
                <Core.List
                    component="div"
                    className={css(layoutStyle.flex1)}>
                    {_.map(this.data, this.renderItem)}
                </Core.List>
                {this.props.isAbleToShowTour ?
                    <Tours.TourLayout tour={Tours.FriendsAndFamilyTour} type={tourConstants.TOURS.FRIENDS_AND_FAMILY_TOUR} />
                    : null }
                {this.renderFooter}
                {!this.data.length && this.emptyMessage}
            </>
        );
    }

    render() {
        return !this.props.isOpenedFromSettings
            ? (
                <Core.SmallerContainer
                    widerHeaderComponent={(
                        <Core.EntityDetailsHeader
                            hasBackButton={true}
                            rightComponent={
                                <Core.Button
                                    type="text"
                                    className={css(styles.navButton)}
                                    onPress={this.onInviteWithEmail}>
                                    {this.props.inviteText}
                                </Core.Button>
                            }
                            title={this.props.title}
                        />)
                    }>
                    {this.content}
                </Core.SmallerContainer>
            )
            : (
                <Core.Layout.WiderContainer>
                    {this.content}
                </Core.Layout.WiderContainer>
            );
    }
}

const styles = StyleSheet.create({
    ...baseStyles
});

export default withRouter(WithFindFriendsAndFamilyBase(FindFriendsAndFamily));
