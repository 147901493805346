import _ from 'lodash';

class PubSub {
    constructor() {
        this.subIds = 0;
        this.subscriptions = {};
    }

    subscribe = (topic, fn) => {
        if (!this.subscriptions[topic]) this.subscriptions[topic] = {};
        const token = ++this.subIds; // eslint-disable-line
        // Validate topic name and function constructor
        this.subscriptions[topic][token] = fn;

        return () => this.unsubscribe(topic, token);
    };

    publish = (topic, ...args) => {
        const subs = this.subscriptions[topic];
        if (!subs) return false;
        setTimeout(() => _.forEach(subs, sub => sub(...args)), 0);
        return true;
    };

    publishSync = (topic, ...args) => {
        const subs = this.subscriptions[topic];
        if (!subs) return false;
        Object.values(subs).forEach(sub => sub(...args));
    };

    unsubscribe = (topic, token) => {
        this.subscriptions[topic] && (delete this.subscriptions[topic][token]); // Delete specific subscription
    };
}

const pubSub = new PubSub();
export default pubSub;
