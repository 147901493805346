export const NAME = 'notifications';
export const NOT_PERSISTED = true;

export const NOTIFICATION_ENTITY_TYPES = {
    stream: 'stream',
    streams: 'streams',
    challenge: 'challenge',
    challenges: 'challenges',
    event: 'event',
    group: 'group',
    groups: 'groups',
    user_reward: 'user_reward',
    apps_devices: 'apps_devices',
    user: 'user'
};

export const NOTIFICATIONS_WITH_DEFAULT_AVATAR = [
    'event_post',
    'group_post',
    'thumbsup_add',
    'comment_post',
    'group_invite',
    'challenge_post',
    'comment_comment',
    'challenge_team_member_join',
    'challenge_team_member_left'
];

export const NOTIFICATIONS_DEEP_LINK = 'notificationsDeepLink';
