import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions as coreActions, constants as coreConstants, timeout, translate, tracker } from '../../../core';
import { services as authServices, selectors as authSelectors } from '../../../auth';
import * as rewardsActions from '../../actions';
import { REDEEM_STATES, SSO_REWARDS } from '../../constants';
import * as rewardSelectors from '../../selectors';

const ORDER_SESSION_EXPIRED = 'redemptionSessionExpired';
const TRIGGER_SSO_TIMEOUT = 1800;

export default function WithOrderRedemptionConfirmationBase(WrappedComponent) {
    class OrderRedemptionConfirmationBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            company: PropTypes.object.isRequired,
            rewards: PropTypes.object,
            product: PropTypes.object,
            productId: PropTypes.number.isRequired,
            address: PropTypes.object,
            token: PropTypes.string,
            reAuthRequired: PropTypes.bool,
            userSSOData: PropTypes.object,
            orderId: PropTypes.number,
            rewardRedemptionToken: PropTypes.string,
            partnerSsoUri: PropTypes.string,
            setTimeout: PropTypes.func.isRequired
        };

        static defaultProps = {
            rewards: {},
            product: {},
            address: null,
            token: '',
            rewardRedemptionToken: '',
            reAuthRequired: false,
            userSSOData: {},
            orderId: undefined,
            partnerSsoUri: ''
        };

        constructor(props) {
            super(props);
            this.state = {
                currentStep: _.isEmpty(this.props.address) ? REDEEM_STATES.submitOrder : REDEEM_STATES.initial,
                isLoading: false,
                reloginToken: this.props.token
            };
        }

        static getDerivedStateFromProps(nextProps, prevState) {
            switch (prevState.currentStep) {
                case REDEEM_STATES.initial:
                    return { currentStep: REDEEM_STATES.submitOrder };
                case REDEEM_STATES.submitting: {
                    if (nextProps.reAuthRequired && nextProps.rewards.reauthRequiredToRedeem) {
                        return { currentStep: REDEEM_STATES.sso, isLoading: false };
                    }
                    else if (nextProps.orderId) {
                        return { currentStep: REDEEM_STATES.orderConfirmation, isLoading: false };
                    }
                    else if (nextProps.isOrderError) {
                        return { currentStep: REDEEM_STATES.orderError, isLoading: false };
                    }
                    break;
                }
                case REDEEM_STATES.reAuthentication: {
                    if (nextProps.reAuthRequired) {
                        return { currentStep: REDEEM_STATES.submitOrder, isLoading: false };
                    }
                    else if (nextProps.getRedemptionTokenHasError) {
                        return { currentStep: REDEEM_STATES.submitOrder, isLoading: false };
                    }
                    else if (nextProps.rewardRedemptionToken !== prevState.reloginToken) {
                        return { currentStep: REDEEM_STATES.autoSubmit, reloginToken: nextProps.rewardRedemptionToken };
                    }
                    break;
                }
                case REDEEM_STATES.orderError: {
                    if (!nextProps.isOrderError) {
                        return { currentStep: REDEEM_STATES.submitOrder };
                    }
                    break;
                }
                case REDEEM_STATES.autoSubmit: {
                    if (nextProps.orderId) {
                        return { currentStep: REDEEM_STATES.orderConfirmation, isLoading: false };
                    }
                    else if (nextProps.reAuthRequired) {
                        return { currentStep: REDEEM_STATES.submitOrder, isLoading: false };
                    }
                    else if (nextProps.isOrderError) {
                        return { currentStep: REDEEM_STATES.submitOrder, isLoading: false };
                    }
                    break;
                }
                default:
                    break;
            }
            return null;
        }

        get formattedAddress() {
            return _.omit(this.props.address, ['displayCountry', 'displayRegion']);
        }

        onOrder = () => {
            this.props.actions.clearRedeemProductError();
            const { productId } = this.props;
            const token = this.state.reloginToken;
            tracker.logEvent('rewards', { event: 'submit', id: this.props.productId });
            this.props.actions.redeemProduct({ token, address: this.formattedAddress, productId, quantity: 1 });
            if (this.state.currentStep === REDEEM_STATES.submitOrder || this.state.currentStep === REDEEM_STATES.sso) {
                this.setState({ currentStep: REDEEM_STATES.submitting, isLoading: true });
            }
        };

        get item() {
            return this.props.product;
        }

        setOrderConfirmation = () => {
            if (this.state.currentStep === REDEEM_STATES.sso) {
                this.setState({ currentStep: REDEEM_STATES.orderConfirmation, isLoading: true });
                this.props.actions.getTemporaryRewardRedemptionToken(this.props.userSSOData);
            }
        };

        clearRedeemProductError = () => this.props.actions.clearRedeemProductError();

        clearTemporaryRewardRedemptionToken = () => this.props.actions.clearTemporaryRewardRedemptionToken();

        addErrorToast = () => this.props.actions.addToast(coreConstants.TOAST_TYPES.DANGER, ORDER_SESSION_EXPIRED);

        triggerSSO = () => {
            // Timeout is set to avoid user getting stuck on broken loading screen
            // circles spinner takes 500ms to finish animating each of the 3 dots
            this.props.setTimeout(() => {
                authServices.sso.triggerSSO({ target: this.props.partnerSsoUri }, SSO_REWARDS.reauthentication, this.props.company);
            }, TRIGGER_SSO_TIMEOUT);
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    item={this.item}
                    onOrder={this.onOrder}
                    setOrderConfirmation={this.setOrderConfirmation}
                    clearRedeemProductError={this.clearRedeemProductError}
                    clearTemporaryRewardRedemptionToken={this.clearTemporaryRewardRedemptionToken}
                    addErrorToast={this.addErrorToast}
                    triggerSSO={this.triggerSSO}
                    currentStep={this.state.currentStep}
                    isLoading={this.state.isLoading}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        const address = ownProps.address || _.get(ownProps, 'location.state.address') || _.get(ownProps, 'route.params.address');
        const title = ownProps.title || _.get(ownProps, 'location.state.title') || _.get(ownProps, 'route.params.title');
        const token = ownProps.token || _.get(ownProps, 'location.state.token') || _.get(ownProps, 'route.params.token');
        const productId = ownProps.productId || _.get(ownProps, 'location.state.productId') || _.get(ownProps, 'route.params.productId');
        return {
            rewards: rewardSelectors.getRewards(state),
            product: rewardSelectors.getProduct(state, productId),
            rewardRedemptionToken: rewardSelectors.getRedemptionToken(state),
            reAuthRequired: rewardSelectors.isRedemptionTokenExpired(state),
            isOrderError: rewardSelectors.isOrderError(state),
            partnerSsoUri: authSelectors.getPartnerSsoUri(state),
            userSSOData: authSelectors.getSsoDetails(state),
            company: authSelectors.getCompany(state),
            getRedemptionTokenHasError: rewardSelectors.isRedemptionTokenError(state),
            orderId: rewardSelectors.getCurrentOrderId(state),
            externalRewardName: rewardSelectors.externalRewardName(state),
            address,
            title,
            productId,
            token
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators({ ...rewardsActions, ...coreActions }, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(timeout(translate()(OrderRedemptionConfirmationBase)));
}