import React, { PureComponent } from 'react';
import { css, StyleSheet } from 'aphrodite-jss';
import _ from 'lodash';
import { components as Core, tracker } from '../../../core';
import { numberWithCommas } from '../../../core/services/numbers';
import { importantStyles, spacing } from '../../../../styles';
import OverTrackingModalBase, { styles as baseStyles } from './OverTrackingModalBase';
import { overTrackedDateFormatted } from '../../services/activitiesHelper';

class OverTrackingModal extends PureComponent {

    constructor(props) {
        super(props);
        this.listRef = React.createRef();
        this.state = {
            shadowVisible: this.props.checkboxesWithWarnings?.length > 3
        };
    }

    onScroll = () => {
        if (this.listRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = this.listRef.current;
            const isBottom = clientHeight === scrollHeight - scrollTop;
            this.setState({ shadowVisible: !isBottom });
        }
    }

    overTrackingModalText = () => {

        const { checkboxes, overTrackingDescBold, overTrackingDesc, quantity } = this.props;
        return (
            <div className={css(styles.wrapper)}>
                <div className={css(styles.overTrackingWrapper)} ref={this.listRef} onScroll={() => this.onScroll()}>
                    {checkboxes ? this.overTrackingInForm() : null}
                    {quantity ? this.overTrackingGoalsChallenges() : null}
                </div>
                {this.state.shadowVisible && <div className={css(styles.linearGradient)} />}
                <div className={css(styles.overTrackingDescriptionWrapper)}>
                    <p className={css(styles.overTrackingDescriptionBold)}>{overTrackingDescBold}</p>
                    <p className={css(styles.overTrackingDescription)}>{overTrackingDesc}</p>
                </div>
            </div>
        );
    }

    overTrackingInForm = () => {
        const { inputUnit, i18n, checkboxesWithWarnings } = this.props;
        return (
            _.map(checkboxesWithWarnings, (checkbox, index) => {
                const overTrackingDate = checkbox.label === i18n.t('today') || checkbox.label === i18n.t('yesterday') ? null : checkbox.dateString;
                const isLastItem = index === checkboxesWithWarnings.length - 1;

                return (
                    <div className={css(styles.overTrackingEntityWrapper, isLastItem && styles.noBottomMargin)}>
                        <p className={css(styles.overTrackingDay)}>{checkbox.label} {overTrackingDate}</p>
                        <p className={css(styles.overTrackingValue)}>{numberWithCommas(checkbox.value)} {inputUnit}</p>
                    </div>
                ); })
        );
    }

    overTrackingGoalsChallenges = () => {
        const { selectedActivity, quantity, i18n } = this.props;
        return (
            <div className={css(styles.overTrackingEntityWrapper, styles.noBottomMargin)}>
                <p className={css(styles.overTrackingDay)}>{overTrackedDateFormatted(selectedActivity.date, i18n)}</p>
                <p className={css(styles.overTrackingValue)}>{numberWithCommas(quantity)} {selectedActivity.unitName}</p>
            </div>
        );
    }

    overTrackingModalButtons = submitAfterWarning => {
        const { overTrackingButtonEdit, overTrackingButtonConfirm } = this.props;
        return (
            <>
                <Core.Button
                    size="medium"
                    className={css(styles.overTrackingButtonEdit)}
                    onPress={() => this.closeAfterWarning()}>
                    {overTrackingButtonEdit}
                </Core.Button>
                <Core.Button
                    size="medium"
                    type="text"
                    className={css(styles.overTrackingButtonConfirm)}
                    onPress={() => {
                        submitAfterWarning();
                        this.props.closeModal();
                    }}>
                    {overTrackingButtonConfirm}
                </Core.Button>
            </>
        );
    }

    closeAfterWarning = () => {
        tracker.logEvent('Activity_Overtracking_Cancel');
        this.props.closeModal();
    }
    render() {
        const { overTrackingModalProps, submitFunc } = this.props;
        return (
            <Core.InfoModal
                {...overTrackingModalProps()}
                text={this.overTrackingModalText()}
                customButtons={this.overTrackingModalButtons(submitFunc)}
            />
        );
    }
}
export default OverTrackingModalBase(OverTrackingModal);

const MAX_SCROLLABLE_HEIGHT = 260;
const MIN_WIDTH = 410;

const styles = StyleSheet.create({
    ...baseStyles,
    ...importantStyles({
        overTrackingButtonConfirm: {
            ...baseStyles.overTrackingButtonConfirm
        },
        overTrackingButtonEdit: {
            ...baseStyles.overTrackingButtonEdit
        }
    }),
    overTrackingWrapper: {
        maxHeight: MAX_SCROLLABLE_HEIGHT,
        minWidth: MIN_WIDTH,
        overflow: 'auto',
    },
    linearGradient: {
        background: 'linear-gradient(0deg, rgba(0,0,0,0.15) 0%, rgba(0,212,255,0) 100%)',
        height: spacing.s5,
        width: '100%',
        position: 'absolute',
        bottom: spacing.s12
    },
    wrapper: {
        position: 'relative'
    }
});
