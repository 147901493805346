import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { translate, color } from '../../services';
import { appFonts, baseColors, spacing } from '../../../../styles';
import { BRAND_COLOR } from '../../constants';

export default function WithTagBase(WrappedComponent) {
    class TagBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            onDelete: PropTypes.func.isRequired,
            item: PropTypes.object.isRequired
        };

        render() {
            return (
                <WrappedComponent {...this.props} />
            );
        }
    }

    return translate()(TagBase);
}

export const styles = {
    chipText: {
        ...appFonts.mdMedium,
        color: color.brandColorMaker(baseColors.primary, BRAND_COLOR.DARK_SHADE),
    },
    chip: {
        padding: spacing.s1,
        paddingTop: spacing.s0,
        paddingBottom: spacing.s0,
        backgroundColor: color.brandColorMaker(baseColors.primary, BRAND_COLOR.LIGHT_SHADE),
        borderRadius: spacing.s0,
    }
};
