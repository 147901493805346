import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { StyleSheet, css } from 'aphrodite-jss';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { spacing, baseColors, importantStyles, appFonts } from '../../../../styles';

const SelectDropdown = (props) => {
    const { tabs, activeTab, i18n } = props;
    const [open, setOpen] = useState(false);

    const handleChange = ({ target: { value } }) => {
        const newValue = _.find(tabs, { label: value });
        const method = props.onChange || props.onPress;
        method && method(newValue);
    };

    const handleClose = useCallback(() => setOpen(false), []);

    const handleOpen = useCallback(() => setOpen(true), []);

    const dropdownTabs = () => (
        tabs && _.map(tabs, tab => (
            <MenuItem
                key={tab.id}
                value={tab.label}
                className={classes.menuStyle}
            >
                {i18n ? i18n.t(tab.label) : tab.label}
            </MenuItem>
        ))
    );

    const classes = {
        formControl: css(styles.formControl),
        select: css(styles.select),
        icon: css(styles.icon),
        input: css(styles.input),
        menuStyle: css(styles.menuStyle)
    };

    const label = activeTab ? activeTab.label : '';
    return (
        <FormControl className={classes.formControl}>
            <Select
                className={classes.select}
                disableUnderline
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                value={label}
                onChange={handleChange}
                inputProps={{
                    classes: { icon: classes.icon },
                    className: classes.input
                }}
            >
                {dropdownTabs()}
            </Select>
        </FormControl>
    );
};

SelectDropdown.propTypes = {
    tabs: PropTypes.array.isRequired,
    activeTab: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onPress: PropTypes.func.isRequired,
};

export default SelectDropdown;

const styles = StyleSheet.create(importantStyles({
    formControl: {
        margin: 0,
        padding: 0,
        paddingLeft: spacing.s3,
        paddingRight: spacing.s1
    },
    select: {
        color: baseColors.secondary,
        marginLeft: spacing.s0,
    },
    icon: {
        fill: baseColors.secondary
    },
    input: {
        paddingTop: spacing.s1,
        ...appFonts.mdRegular,
        '&:focus': {
            backgroundColor: baseColors.white
        }
    },
    menuStyle: {
        ...appFonts.mdRegular,
    }
}));