import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { components as Core, Modal } from '../../../core';
import WithPrivacyInfoModalBase from './PrivacyInfoModalBase';

class PrivacyInfoModal extends PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        onConfirmPrivacyChange: PropTypes.func.isRequired,
        getModalProps: PropTypes.func.isRequired,
        label: PropTypes.string.isRequired,
    };

    static defaultProps = {};

    onConfirmPrivacyChange = async () => {
        await this.closeModal();
        return this.props.onConfirmPrivacyChange();
    };

    /**
     * we call it from parent
     */
    showModal = () => {
        const { i18n, getModalProps, label } = this.props;

        this.closeModal = Modal.open(
            Core.InfoModal,
            {
                ...getModalProps(),
                buttons: [
                    { text: label, onPress: () => this.onConfirmPrivacyChange(), isBoldText: true },
                    { text: i18n.t('button_cancel'), onPress: () => this.closeModal() }
                ]
            },
            {
                cancelable: true,
                fadeTransition: true,
                PaperProps: { elevation: 0 },
                isTransparent: true
            }
        );
    };

    render() {
        return null;
    }
}

export default WithPrivacyInfoModalBase(PrivacyInfoModal);
