import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { getUserName, translate, selectors as coreSelectors, componentHelper } from '../../../core';
import * as actions from '../../actions';
import { baseColors, spacing, appFonts } from '../../../../styles';
import { isAfterDeadline, isGoalType, isJoinTeamVisible, teamMembersCountText, teamIsInvitedByVisible, teamInvitedByDetails, isMember, isStarted, isTeamFull } from '../../services/helper';
import { SCORE_DISPLAY_TYPES, CHALLENGE_TYPES, CHALLENGE_ENTITY_TYPES } from '../../constants';
import { getChallengeTeamById, getChallenge, getChallengeLeaderboardForEntityId, getTeamCurrentMembers,
    getTeamFormerMembers, getChallengeLeaderboard, getChallengeUserEntityId, getTeamData, isJoiningChallenge } from '../../selectors';

const formerMemberAvatar = require('../../../../image/former_member_avatar.png');

export default function WithTeamDetailsBase(WrappedComponent) {
    class TeamDetailsBase extends PureComponent {
        static propTypes = {
            challengeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            entityId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            challenge: PropTypes.object.isRequired,
            team: PropTypes.object.isRequired,
            isPage: PropTypes.bool,
            leaderboard: PropTypes.object, // eslint-disable-line
            userEntityId: PropTypes.number,
            teamInfo: PropTypes.object.isRequired,
            userId: PropTypes.number.isRequired,
            teamLeaderboard: PropTypes.object,
            currentMembers: PropTypes.array.isRequired,
            formerMembers: PropTypes.array.isRequired,
            customPointsUnit: PropTypes.string.isRequired
        };

        static defaultProps = {
            isPage: true,
            leaderboard: undefined,
            userEntityId: undefined,
            teamLeaderboard: undefined,
        };

        constructor(props) {
            super(props);
            this.props.actions.getChallenge(props.challengeId);
            this.props.actions.getChallengeTeam(props.challengeId, props.entityId);
        }

        componentDidMount() {
            if (!this.props.team) {
                this.props.actions.getChallengeMembers(this.props.challengeId, true);
            }
        }

        goBack = () => {
            componentHelper.goBack(this.props);
        };

        // Commenting out as we have removed numeral but we don't currently use this
        // get rank() {
        //     return this.isScoreShown && numeral(this.props.teamLeaderboard.rank).format('0o');
        // }

        isCurrentUser = item => item.id === _.toString(this.props.userId);

        isTeamCreator = item => {
            if (!this.props.teamInfo) return false;
            return item.id === this.props.teamInfo.creatorId;
        }

        get isChallengeCompleted() {
            if (this.props.challenge) return isAfterDeadline(this.props.challenge);
            return null;
        }

        get isUserCreator() {
            if (!this.props.teamInfo) return false;
            return _.toString(this.props.userId) === this.props.teamInfo.creatorId;
        }

        get isUserInTeam() {
            return this.props.userEntityId === this.props.entityId;
        }

        get isScoreShown() {
            return !this.props.isPage && this.props.teamLeaderboard;
        }

        get scoreUnit() {
            return _.get(this.props, 'leaderboard.displayUnit') === SCORE_DISPLAY_TYPES.percentage
                || _.get(this.props, 'challenge.challengeType') === CHALLENGE_TYPES.goal
                ? '%' : this.props.customPointsUnit;
        }

        get isRankShown() {
            const { challenge } = this.props;
            return this.isScoreShown && !isGoalType(challenge);
        }

        getMemberTitle = (item, isFormerMember) => {
            const { i18n } = this.props;
            const userName = this.isCurrentUser(item) ? i18n.t('you') : getUserName(item);
            const title = isFormerMember ? i18n.t('formerMember.label') : userName;
            return `${title} ${this.isTeamCreator(item) ? `(${i18n.t('captain')})` : ''}`;
        }

        getMemberDepartmentLocation = item => `${item.department}, ${item.location}`;

        getUserAvatar = (item, isFormerMember) => isFormerMember ? formerMemberAvatar : item.imageUrl;

        getScore = item => `${item.score} ${this.scoreUnit}`;

        keyExtractor= item => item.id;

        get inviteToTeamText() {
            return this.props.i18n.t('inviteOthersToYourTeam');
        }

        get manageTeamText() {
            return this.props.i18n.t('manageTeam');
        }

        get leaderboardScoreText() {
            const { teamLeaderboard, i18n } = this.props;
            return `${_.get(teamLeaderboard, 'score', '')} ${i18n.t('avg')}`;
        }

        get teamMembersText() {
            return this.props.i18n.t('team_members');
        }

        get isJoinVisible() {
            return isJoinTeamVisible(this.props.challenge, this.props.team) && !isMember(this.props.challenge);
        }

        get membersCount() {
            if (this.props.team) {
                return teamMembersCountText(this.props.team);
            }

            return undefined;
        }

        get isInvitedByVisible() {
            if (this.props.team) {
                return teamIsInvitedByVisible(this.props.team) && !isMember(this.props.challenge);
            }

            return false;
        }

        get invitedByDetails() {
            if (this.props.team) {
                return teamInvitedByDetails(this.props.team);
            }

            return undefined;
        }

        get challengeHasStarted() {
            return isStarted(this.props.challenge);
        }

        get isFull() {
            if (this.props.team) {
                return isTeamFull(this.props.team);
            }

            return false;
        }

        get isUserAMember() {
            return isMember(this.props.challenge);
        }

        get teamHasGroupInfo() {
            if (this.props.team && this.props.team.groupInfo) return true;

            return false;
        }

        getMemberItemTextStyles = (item, styles, isFormerMember = false, isTitle = false) =>
            _.compact([
                (this.isCurrentUser(item) && !isFormerMember) ? styles.user : styles.member,
                (isFormerMember && isTitle) ? styles.formerMember : null,
            ]);

        joinTeam = () => {
            const { team } = this.props;
            this.teamName = team.name;
            this.props.actions.joinChallengeByTeamId(team.entityId, this.teamName);
        };

        actionButtonOptions = (manageTeamCallback, inviteToTeamCallback, leaveTeamCallback) => {
            const options = [];

            if (this.isUserInTeam) {
                if (this.isUserCreator) {
                    options.push({ title: this.props.i18n.t('edit_team'), onPress: manageTeamCallback });
                }

                if (!this.isChallengeCompleted) {
                    options.push({ title: this.inviteToTeamText, onPress: inviteToTeamCallback });
                    options.push({ title: this.props.i18n.t('leaveTeamAction'), onPress: leaveTeamCallback });
                }
            }

            return options;
        }

        get leaveChallengeMessage() {
            const { challenge, i18n } = this.props;
            let leaveMessage = i18n.t('leave_goal_confirmation');
            //check if team challenge
            if (_.get(challenge, 'userEntity.entityType') !== CHALLENGE_ENTITY_TYPES.user) {
                if (_.get(challenge, 'userEntity.numberOfUsers', 0) === '1') {
                    leaveMessage = `${i18n.t('leaveTeam')} ${challenge.userEntity.name} ${i18n.t('leaveTeamLastMember')}`;
                } else {
                    leaveMessage = `${i18n.t('leaveTeamLeaveChallenge')}`;
                }
            }

            return leaveMessage;
        }

        render() {
            if (!this.props.teamInfo || !this.props.team) return null;
            return (
                <WrappedComponent
                    {...this.props}
                    getScore={this.getScore}
                    getMemberTitle={this.getMemberTitle}
                    isUserCreator={this.isUserCreator}
                    isChallengeCompleted={this.isChallengeCompleted}
                    isUserInTeam={this.isUserInTeam}
                    isScoreShown={this.isScoreShown}
                    getUserName={getUserName}
                    getUserAvatar={this.getUserAvatar}
                    isCurrentUser={this.isCurrentUser}
                    keyExtractor={this.keyExtractor}
                    inviteToTeamText={this.inviteToTeamText}
                    manageTeamText={this.manageTeamText}
                    leaderboardScoreText={this.leaderboardScoreText}
                    teamMembersText={this.teamMembersText}
                    isRankShown={this.isRankShown}
                    getMemberItemTextStyles={this.getMemberItemTextStyles}
                    isJoinVisible={this.isJoinVisible}
                    membersCount={this.membersCount}
                    isInvitedByVisible={this.isInvitedByVisible}
                    invitedByDetails={this.invitedByDetails}
                    joinTeam={this.joinTeam}
                    challengeHasStarted={this.challengeHasStarted}
                    getMemberDepartmentLocation={this.getMemberDepartmentLocation}
                    isTeamFull={this.isFull}
                    isUserAMember={this.isUserAMember}
                    actionButtonOptions={this.actionButtonOptions}
                    leaveChallengeMessage={this.leaveChallengeMessage}
                    teamHasGroupInfo={this.teamHasGroupInfo}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const entityId = ownProps.entityId || _.get(ownProps, 'match.params.entityId') || _.get(ownProps, 'route.params.entityId');
        const challengeId = ownProps.challengeId || _.get(ownProps, 'match.params.challengeId') || _.get(ownProps, 'route.params.challengeId');
        const isFeaturedChallenge = ownProps.isFeaturedChallenge || _.get(ownProps, 'match.params.isFeaturedChallenge') || _.get(ownProps, 'route.params.isFeaturedChallenge');
        const stateTeam = ownProps.team || _.get(ownProps, 'match.params.team') || _.get(ownProps, 'route.params.team') || _.get(ownProps, 'location.state.team');
        return {
            entityId,
            challengeId,
            isFeaturedChallenge,
            team: stateTeam ? stateTeam : getTeamData(state, challengeId, entityId),
            teamInfo: getChallengeTeamById(state, entityId),
            userId: coreSelectors.getCurrentUserId(state),
            challenge: getChallenge(state, challengeId),
            leaderboard: getChallengeLeaderboard(state, challengeId),
            teamLeaderboard: getChallengeLeaderboardForEntityId(state, challengeId, entityId),
            currentMembers: getTeamCurrentMembers(state, entityId),
            formerMembers: getTeamFormerMembers(state, entityId),
            userEntityId: getChallengeUserEntityId(state, challengeId),
            customPointsUnit: coreSelectors.getCustomPointsUnit(state),
            isJoining: isJoiningChallenge(state, challengeId),
        };
    };

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(TeamDetailsBase));
}

const MEMBERS_TITLE_PADDING = 6;

export const styles = {
    header: {
        ...appFonts.xsBold,
        color: baseColors.grey20,
    },
    teamSummaryRow: {
        padding: spacing.s3,
        display: 'flex',
    },
    pointsLarge: {
        ...appFonts.mdBold,
        color: baseColors.grey20
    },
    buttonsWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        display: 'flex'
    },
    name: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        display: 'flex'
    },
    avatar: {
        marginRight: spacing.s2
    },
    nameScoreSection: {
        flexDirection: 'column',
        flex: 1,
    },
    buttonsSection: {
        flex: 1,
        paddingLeft: spacing.s1,
        paddingRight: spacing.s1,
        paddingBottom: spacing.s3
    },
    teamName: {
        ...appFonts.lgBold
    },
    listItem: {
        padding: 0,
        borderBottomWidth: 1,
        borderBottomColor: baseColors.grey70,
    },
    memberItemText: {
        ...appFonts.mdRegular,
        color: baseColors.black,
    },
    userItemText: {
        ...appFonts.mdBold,
        color: baseColors.black,
    },
    formerMemberTitleText: {
        fontStyle: 'italic',
        color: baseColors.grey40,
    },
    memberAvatar: {
        marginRight: spacing.s3,
        marginLeft: spacing.s3,
        marginTop: spacing.s1,
        marginBottom: spacing.s1,
    },
    membersTitle: {
        ...appFonts.xsBold,
        marginBottom: spacing.s1,
        paddingTop: MEMBERS_TITLE_PADDING,
        paddingBottom: MEMBERS_TITLE_PADDING,
        paddingRight: spacing.s3,
        paddingLeft: spacing.s3,
        color: baseColors.grey20,
        backgroundColor: baseColors.grey85,
    },
    rowTitleStyle: {
        ...appFonts.mdMedium,
        color: baseColors.black,
    },
    rowSubtitle: {
        color: baseColors.grey40,
        ...appFonts.smRegular,
    },
    smallAvatar: {
        marginRight: spacing.s0
    },
    teamDetailsRow: {
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
    },
    dotIcon: {
        marginLeft: spacing.s1,
        marginRight: spacing.s1,
    },
    detailsPadding: {
        paddingTop: spacing.s0
    },
    subtitleStyle: {
        ...appFonts.xsRegular,
        color: baseColors.grey40
    },
    joinButton: {
        marginHorizontal: spacing.s3,
    },
    actionButton: {
        borderColor: baseColors.secondary,
        borderWidth: 1,
        width: spacing.s7,
        height: spacing.s7,
        borderRadius: spacing.s4,
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: spacing.s3
    }
};
