import React from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';
import WithContentOnFeedsRecommendedBase from './ContentOnFeedsRecommendedBase';
import { spacing, importantStyles } from '../../../../styles';
import ContentOnFeeds from '../ContentOnFeeds';
import { TYPES } from '../../constants';

const ContentOnFeedsRecommended = ({ infoText, itemsCount, ...props }) => (
    itemsCount ? (
        <ContentOnFeeds
            {...props}
            titleSize="large"
            infoText={infoText}
            hideCoreButton={true}
            showThreeItems={true}
            itemsCount={itemsCount}
            type={TYPES.RECOMMENDED}
            hasHorizontalMargin={true}
            cardContainerClassName={css(styles.cardContainer)}
            headerContainerClassName={css(styles.headerContainer)}
            carouselContainerClassName={css(styles.carouselContainer)}
        />
    ) : null
);

ContentOnFeedsRecommended.propTypes = {
    i18n: PropTypes.object.isRequired,
    infoText: PropTypes.string.isRequired,
    itemsCount: PropTypes.number.isRequired
};

const styles = StyleSheet.create(importantStyles({
    headerContainer: {
        paddingLeft: spacing.s3,
        marginBottom: spacing.s1,
        minHeight: 'auto',
        height: 'auto',
        marginTop: spacing.s3,
        alignItems: 'baseline'
    },
    carouselContainer: {
        marginTop: spacing.s5,
        marginBottom: spacing.s5,
        marginRight: spacing.s1,
        marginLeft: spacing.s1,
        paddingBottom: 0
    },
    cardContainer: {
        marginBottom: spacing.s5
    }
}));

export default WithContentOnFeedsRecommendedBase(ContentOnFeedsRecommended);
