import _ from 'lodash';
import * as types from './actionTypes';
import { actionTypes as coreActionTypes } from '../core';

export const initialState = {
    items: {},
    ids: [],
    isConnecting: false,
    isSuccessfullyConnected: false,
    isHiddenAppsDevicesInformationText: false,
    warningDates: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.GET_DEVICE.SUCCESS:
        case types.GET_APPS_DEVICES.SUCCESS: {
            const { items, ids } = action.payload;
            const uniqueIds = _.uniq([...state.ids, ...ids]);
            return {
                ...state,
                items: {
                    ...state.items,
                    ...items
                },
                ids: uniqueIds
            };
        }
        case types.DISCONNECT_DEVICE.SUCCESS: {
            const { vendorId } = action.payload;
            const connected = false;
            const items = {
                ...state.items,
                [vendorId]: { ...state.items[vendorId], connected }
            };
            return {
                ...state,
                items
            };
        }
        case types.SYNC_DEVICE.SUCCESS: {
            const { vendorId, data } = action.payload;
            const index = _.findIndex(data, { vendorId });
            const { lastSyncDate } = data[index];
            const items = {
                ...state.items,
                [vendorId]: { ...state.items[vendorId], lastSyncDate }
            };
            return {
                ...state,
                items
            };
        }
        case types.UPDATE_DEVICE_WARNING_DATE: {
            const { vendorId, nextWarningDate, nextWarningInterval } = action.payload;
            const warningDates = {
                ...state.warningDates,
                [vendorId]: { ...state.warningDates[vendorId], vendorId, nextWarningDate, nextWarningInterval }
            };
            return {
                ...state,
                warningDates
            };
        }
        case types.START_CONNECTING: {
            const { isConnecting } = action.payload;
            return {
                ...state,
                isConnecting
            };
        }
        case types.FINISH_CONNECTING: {
            const { isConnecting, isSuccessfullyConnected } = action.payload;
            return {
                ...state,
                isConnecting,
                isSuccessfullyConnected
            };
        }
        case types.HIDE_APPS_DEVICES_INFORMATION_TEXT: {
            return {
                ...state,
                isHiddenAppsDevicesInformationText: true
            };
        }
        case coreActionTypes.RESET:
            return {
                ...initialState,
                isHiddenAppsDevicesInformationText: state.isHiddenAppsDevicesInformationText,
            };
        default:
            return state;
    }
};