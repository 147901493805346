import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import _ from 'lodash';
import AppBar from '@material-ui/core/AppBar';
import translate from '../../services/translate';
import { baseColors, headerHeight, importantStyles } from '../../../../styles';
import { Layout } from '../../components';
import { HEADER_TRANSITION_DURATION, SCROLL_DELAY } from '../../constants';

class FixedHeader extends PureComponent {
    static propTypes = {
        fullWidth: PropTypes.bool,
        subheader: PropTypes.node,
        isTop: PropTypes.bool
    };

    static defaultProps = {
        fullWidth: false,
        subheader: null,
        isTop: false
    };

    constructor(props) {
        super(props);
        this.detectScroll = _.throttle(this.handleScroll.bind(this), SCROLL_DELAY);
        this.state = {
            headerBorder: false
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.detectScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.detectScroll);
    }

    handleScroll = () => this.setState({ headerBorder: window.scrollY > 0 });

    get headerContent() {
        return this.props.subheader;
    }

    get layoutContainer() {
        return (
            <Layout.Container>
                {this.headerContent}
            </Layout.Container>
        );
    }

    render() {
        const { isTop } = this.props;
        return (
            <AppBar
                position="sticky"
                color={baseColors.white}
                classes={{
                    root: css(styles.appBar, styles.zIndex, this.state.headerBorder ? styles.shadow : null),
                    positionSticky: !isTop && css(styles.headerPositionSticky)
                }}
            >
                {this.props.fullWidth ? this.headerContent : this.layoutContainer}
            </AppBar>
        );
    }
}

const styles = StyleSheet.create(
    {
        ...importantStyles({
            headerPositionSticky: {
                top: headerHeight,
            },
            appBar: {
                boxShadow: 'none',
            },
            shadow: {
                boxShadow: `0 8px 6px -6px rgba(0, 0, 0, ${0.3})`,
                transition: `all ${HEADER_TRANSITION_DURATION}ms ease-out 0ms`
            }
        }),
        zIndex: {
            zIndex: '2 !important'
        }
    });

export default translate()(FixedHeader);
