import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import * as actions from '../../../actions';
import { getUserName, translate, pointsHelper, selectors as coreSelectors, numbers } from '../../../../core';
import { appFonts, baseColors, spacing } from '../../../../../styles';
import {
    getGoalProgress,
    isInvite,
    isUnstarted,
    isInGracePeriod,
    isCompletedNotInGracePeriod,
    isCompetition,
    isPersonal,
    isChallengeUpdating,
    isGoalType,
    isTeamChallenge,
    getPeriodsUnit,
    isAfterDeadline,
    isFeatured,
    isMember
} from '../../../services/helper';
import * as selectors from '../../../selectors';
import { CHALLENGE_TYPES, FREQUENCY } from '../../../constants';

const BONUS_POINTS_PERCENTAGE = 85;

export default function WithChallengeCardBodyBase(WrappedComponent) {
    class ChallengeCardBodyBase extends PureComponent {
        static propTypes = {
            challengeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            competitionScore: PropTypes.string.isRequired,
            customPointsUnit: PropTypes.string.isRequired,
            isBonusChallenge: PropTypes.number.isRequired,
            remainingInfo: PropTypes.object.isRequired,
            isRestartGoal: PropTypes.bool.isRequired,
            challenge: PropTypes.object.isRequired,
            i18n: PropTypes.object.isRequired,
            isUpdating: PropTypes.bool,
        };

        static defaultProps = {
            isUpdating: false,
        };

        get progressList() {
            return getGoalProgress(this.props.challenge);
        }

        get invitedBy() {
            const { challenge } = this.props;
            return _.get(challenge, 'invitation.invitedBy.0') ? {
                name: getUserName(challenge.invitation.invitedBy[0]),
                url: challenge.invitation.invitedBy[0].imageURL,
                id: challenge.invitation.invitedBy[0].id
            } : null;
        }

        get invitedByText() {
            return this.invitedBy ? `${this.props.i18n.t('invited_by')} ${this.invitedBy.name}` : null;
        }

        get cardType() {
            return isPersonal(this.props.challenge) ? CHALLENGE_TYPES.goal : CHALLENGE_TYPES.competition;
        }

        getInGraceInfo = () => {
            const { challenge, i18n, isRestartGoal } = this.props;
            const daysLeft = challenge.track_deadline.days_left;
            const progressFill = daysLeft / 3 * 100;
            const progressText = _.toLower(i18n.t(daysLeft > 1 ? 'days' : 'day'));
            const weekFinishedText = i18n.t('weekFinished');
            const endedGoalMessage = i18n.t(
                isRestartGoal ? 'restartGoal.daysLeftToTrack' : 'endedGoal.grace',
                isRestartGoal ? { quantity: daysLeft, days: progressText } : { type: this.challengeType, n: daysLeft, text: progressText }
            );
            const timeToTrackText = i18n.t('endedGoal.timeToTrack.yes');
            return { daysLeft, progressFill, progressText, weekFinishedText, endedGoalMessage, timeToTrackText };
        };

        get challengeType() {
            const { i18n, challenge } = this.props;
            let type = _.toLower(i18n.t(this.cardType));
            if (!isPersonal(challenge) && isGoalType(challenge)) type = _.toLower(i18n.t('challenge'));
            return type;
        }

        get challengeName() {
            return _.get(this.props, 'challenge.challengeName');
        }

        get timeToTrackText() {
            return this.props.i18n.t('endedGoal.timeToTrack.yes');
        }

        get overallUserValue() {
            return _.get(this.props, 'challenge.progress.overallUserValue');
        }

        get userTotalCompletedPeriods() {
            return _.get(this.props, 'challenge.userTotalCompletedPeriods') || 0;
        }

        get userTotalValue() {
            return this.hideTotalPeriods
                ? `${this.overallUserValue}%`
                : `${numbers.numberWithCommas(this.userTotalCompletedPeriods)}`;
        }

        get hasSuccessPeriods() {
            return this.hideTotalPeriods ? this.overallUserValue > 0 : this.userTotalCompletedPeriods > 0;
        }

        get hideTotalPeriods() {
            const { challenge } = this.props;
            return challenge.frequency === FREQUENCY.total || isTeamChallenge(challenge);
        }

        get totalPeriodsString() {
            if (this.hideTotalPeriods) return null;
            const { challenge: { totalPeriods, frequency }, i18n } = this.props;
            const totalPeriodsFormatted = numbers.numberWithCommas(totalPeriods || 0);
            const periodsUnit = _.toLower(i18n.t(getPeriodsUnit(frequency, totalPeriods)));
            return `${i18n.t('of')} ${totalPeriodsFormatted} ${periodsUnit}`;
        }

        get successLabel() {
            const { challenge, i18n } = this.props;
            return _.toUpper(i18n.t(isTeamChallenge(challenge) ? 'teamSuccessAverage' : 'overallSuccess'));
        }

        get bonusEarnedText() {
            const { i18n } = this.props;
            return this.achievedDoubleBonus ? i18n.t('doubleBonusShort') : `${i18n.t('bonusEarned')}!`;
        }

        get achievedDoubleBonus() {
            if (this.isBonusEarned) {
                return !!_.get(this.props, 'challenge.achievedDoubleBonus');
            }

            return false;
        }

        get userTotalPercentage() {
            return Math.round(_.get(this.props, 'challenge.progress.overallUserTotalPercentage'));
        }

        get isBonusEarned() {
            return !!(this.props.isBonusChallenge && (this.userTotalPercentage >= BONUS_POINTS_PERCENTAGE));
        }

        render() {
            const { challenge } = this.props;
            return (
                <WrappedComponent
                    {...this.props}
                    progressList={this.progressList}
                    invitedBy={this.invitedBy}
                    isInvite={isInvite(challenge)}
                    isUnstarted={isUnstarted(challenge)}
                    isFeatured={isFeatured(challenge)}
                    isMember={isMember(challenge)}
                    isInGracePeriod={isInGracePeriod(challenge)}
                    isCompletedNotInGracePeriod={isCompletedNotInGracePeriod(challenge)}
                    isCompetition={isCompetition(challenge)}
                    isEnded={isAfterDeadline(challenge)}
                    getInGraceInfo={this.getInGraceInfo}
                    formatPoints={pointsHelper.formatPoints}
                    competitionScore={this.props.competitionScore}
                    invitedByText={this.invitedByText}
                    challengeName={this.challengeName}
                    successLabel={this.successLabel}
                    isBonusEarned={this.isBonusEarned}
                    userTotalValue={this.userTotalValue}
                    bonusEarnedText={this.bonusEarnedText}
                    totalPeriodsString={this.totalPeriodsString}
                    achievedDoubleBonus={this.achievedDoubleBonus}
                    hasSuccessPeriods={this.hasSuccessPeriods}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const challenge = selectors.getChallenge(state, ownProps.challengeId);
        return {
            challenge,
            isUpdating: isChallengeUpdating(challenge),
            customPointsUnit: coreSelectors.getCustomPointsUnit(state),
            isRestartGoal: selectors.isRestartGoal(state, ownProps.challengeId),
            remainingInfo: selectors.getRemainingInfo(state, ownProps.challengeId),
            isBonusChallenge: selectors.isBonusChallenge(state, ownProps.challengeId),
            competitionScore: selectors.getCompetitionScore(state, ownProps.challengeId),
        };
    };

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(ChallengeCardBodyBase));
}

const ICON_SIZE = 40;
const COMMON_MARGIN_TOP = 2;
export const CHECK_ICON_SIZE = 18;

export const styles = {
    flexRowValueText: {
        ...appFonts.lgBold,
        color: baseColors.black
    },
    flexRowTitleText: {
        ...appFonts.xsBold,
        color: baseColors.grey40
    },
    invitedByContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: spacing.s2,
        marginBottom: spacing.s2,
        display: 'flex'
    },
    invitedByText: {
        ...appFonts.smRegular,
        marginLeft: spacing.s1,
        color: baseColors.grey40
    },
    inGracePeriodInnerContainer: {
        marginTop: 'auto',
        marginBottom: 'auto',
        textAlign: 'center',
    },
    timeToTrackText: {
        ...appFonts.smBold,
        textAlign: 'center',
        paddingTop: spacing.s1
    },
    daysLeftText: {
        ...appFonts.xlMedium
    },
    progressText: {
        ...appFonts.smMedium
    },
    inGraceMessage: {
        ...appFonts.smRegular,
        textAlign: 'center',
        marginHorizontal: spacing.s3,
        paddingBottom: spacing.s2,
        color: baseColors.grey20
    },
    completedMessage: {
        ...appFonts.smRegular,
        marginHorizontal: spacing.s3,
        marginBottom: spacing.s1,
        textAlign: 'center',
        color: baseColors.grey20
    },
    overallScore: {
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: spacing.s3,
        marginTop: spacing.s3,
        width: '100%'
    },
    score: {
        color: baseColors.secondary,
        ...appFonts.xlMedium
    },
    descriptionUnderScore: {
        ...appFonts.lgMedium
    },
    defaultRemainingInfo: {
        marginTop: spacing.s2
    },
    cardContentActiveCompetitionRow: {
        flexDirection: 'row',
        alignItems: 'flex-end',
        flex: 1,
        marginTop: spacing.s0,
        marginBottom: spacing.s0,
        display: 'flex'
    },

    cardDarkHeadingSub: {
        ...appFonts.smRegular
    },
    cardDarkBodyTop: {
        paddingTop: spacing.s0,
        paddingBottom: spacing.s1,
    },
    smGreyFont: {
        ...appFonts.smRegular,
        color: baseColors.grey20,
        marginTop: spacing.s5,
    },
    challengeName: {
        ...appFonts.mdBold,
        marginBottom: spacing.s1,
        textAlign: 'center',
    },
    bonusMarkContainer: {
        marginTop: spacing.s2
    },
    confettiIcon: {
        height: ICON_SIZE,
        width: ICON_SIZE,
    },
    userTotalValue: {
        ...appFonts.xxxlRegular,
        fontSize: 48,
        marginLeft: spacing.s1,
        marginRight: spacing.s1,
    },
    periodsString: {
        ...appFonts.xlRegular,
        marginTop: COMMON_MARGIN_TOP,
        textAlign: 'center',
    },
    successLabel: {
        ...appFonts.xsBold,
        color: baseColors.grey40,
        letterSpacing: 1,
        marginTop: COMMON_MARGIN_TOP,
        textAlign: 'center',
    },
    mTop: {
        marginTop: spacing.s1,
    },
    checkIcon: {
        marginRight: spacing.s0,
    },
    watchIcon: {
        marginRight: spacing.s0,
        marginTop: spacing.s0,
    },
    bonusEarned: {
        ...appFonts.smRegular,
        color: baseColors.grey40,
    },
    graceBonusWrapper: {
        marginTop: spacing.s2,
    },
    competitionCardBody: {
        position: 'relative',
        marginTop: spacing.s1,
    },
};
