import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { createBrowserHistory as createHistory } from 'history';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import thunk from 'redux-thunk';
import _ from 'lodash';
import { persistReducer, persistStore } from 'redux-persist';
import ReactGA from 'react-ga';
import middlewares from './middlewares';
import reducers, { persistConfig } from './reducers';
import initialize from './initialize';
import global from '../config/globals';

const history = createHistory();
const routeMiddleware = routerMiddleware(history);

const rootReducer = persistReducer(persistConfig, combineReducers({
    router: connectRouter(history),
    ...reducers
}));

let middleware = [thunk, routeMiddleware, ...middlewares];

if (process.env.NODE_ENV !== 'production') {
    const reduxImmutableStateInvariant = require('redux-immutable-state-invariant').default();
    const createLogger = require('redux-logger').createLogger;
    const logger = createLogger({ collapsed: true });

    middleware = [...middleware, reduxImmutableStateInvariant, logger];
} else if (_.includes(global.systemEnvironment.domain, 'livebetter')) { //TODO: Define correct logic to recognize domain or subdomain?!?
    history.listen(location => {
        ReactGA.set({ page: location.pathname });
        ReactGA.pageview(location.pathname, ['livebetter']);
    });
} else {
    history.listen(location => {
        ReactGA.set({ page: location.pathname });
        ReactGA.pageview(location.pathname, ['sprout']);
    });
}

const composeEnhancers =
    process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(routerMiddleware(history), ...middleware))
);

// initialize app after REHYDRATE, check if logged in to render correct page at once
const persistor = persistStore(store, null, () => store.dispatch(initialize()));

export { store, history, persistor };

export default store;
