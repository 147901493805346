/* eslint-disable camelcase */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { translate } from '../../../../core';
import { selectors as authSelectors, actions as authActions } from '../../../../auth';
import { spacing } from '../../../../../styles';

const FIELDS = {
    country: 'country',
    region: 'region',
    city: 'city',
    location: 'location',
    department: 'department'
};

export default function WithLocationProfileEditorBase(WrappedComponent) {
    class LocationProfileEditorBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            i18n: PropTypes.object.isRequired,
            hasUnsavedChanges: PropTypes.func,
            userLocations: PropTypes.array,
            isSaving: PropTypes.bool,
            isLoadingLocations: PropTypes.bool,
            isLoadingUserLocations: PropTypes.bool,
            isUpdatingUser: PropTypes.bool,
            isCorporateLocation: PropTypes.bool.isRequired
        };

        static defaultProps = {
            hasUnsavedChanges: null,
            userLocations: [],
            isSaving: false,
            isLoadingLocations: false,
            isLoadingUserLocations: false,
            isUpdatingUser: false
        };

        constructor(props) {
            super(props);
            this.state = {
                errors: {}
            };
        }

        get isFilled() {
            return ((_.get(this.currentLocation, 'isCorporateLocation') && _.get(this.currentLocation, 'department.id')) ||
                (!_.get(this.currentLocation, 'isCorporateLocation') && _.get(this.currentLocation, 'location.id')));
        }

        get isFirstLoading() {
            return this.props.isUpdatingUser || this.props.isLoadingLocations || this.props.isLoadingUserLocations;
        }

        get subtitle() {
            return this.props.isCorporateLocation ?
                this.props.i18n.t('Work location') :
                this.props.i18n.t('location');
        }

        getErrorByField = field => {
            switch (field) {
                case FIELDS.department: return this.props.i18n.t('edit_profile_department_error');
                case FIELDS.location: return this.props.i18n.t('edit_profile_location_error');
                default: return this.props.i18n.t('edit_profile_unspecified_value');
            }
        };

        submitFilled = () => {
            const { location_name, location_id } = _.get(this.currentLocation, 'location');
            this.props.hasUnsavedChanges && this.props.hasUnsavedChanges(false);
            if (!_.get(this.currentLocation, 'isCorporateLocation')) {
                if (_.find(this.props.userLocations, l => location_id === l.location_id)) {
                    this.props.actions.updateUserLocation(
                        { location_name, location_id });
                } else {
                    this.props.actions.setUserLocation(
                        { location_name, location_id }, true);
                }
            }
            else {
                const { department_name, department_id } = _.get(this.currentLocation, 'department');
                if (_.find(this.props.userLocations, l => location_id === l.location_id)) {
                    this.props.actions.updateUserLocationDepartment(
                        { location_name, location_id }, { department_name, department_id });
                } else {
                    this.props.actions.setUserLocationDepartment(
                        { location_name, location_id }, { department_name, department_id }, true);
                }
            }
            return true;
        };

        submitNotFilled = () => {
            const errors = {};
            _.forEach(FIELDS, field => {
                if (!_.get(this.currentLocation, [field, 'id'])) {
                    errors[field] = this.getErrorByField(field);
                }
            });
            this.setState({ errors });
        };

        changeLocation = currentLocation => {
            this.currentLocation = currentLocation;
            this.setState({ errors: {} });
        };

        submit = () => {
            if (!this.currentLocation && _.has(this, 'wrapped.goBack')) this.wrapped.goBack();
            else if (this.isFilled) return this.submitFilled();
            this.submitNotFilled();
        };

        saveRef = ref => (this.wrapped = ref);

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    ref={this.saveRef}
                    changeLocation={this.changeLocation}
                    isLoading={this.isFirstLoading}
                    submit={this.submit}
                    getErrorByField={this.getErrorByField}
                    subtitle={this.subtitle}
                    errors={this.state.errors}
                />
            );
        }
    }

    const mapStateToProps = state => ({
        userLocations: authSelectors.getUserLocations(state),
        isSaving: authSelectors.isSettingLocation(state),
        isLoadingLocations: authSelectors.isLoadingLocations(state),
        isLoadingUserLocations: authSelectors.isLoadingUserLocations(state),
        isUpdatingUser: authSelectors.isUpdatingUser(state),
        isCorporateLocation: authSelectors.getCompany(state).companyId && authSelectors.getCompany(state).companyId !== '0'
    });

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(authActions, dispatch) });


    return connect(mapStateToProps, mapDispatchToProps)(translate()(LocationProfileEditorBase));
}

export const styles = {
    innerContainer: {
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3
    }
};