import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { css } from 'aphrodite-jss';
import _ from 'lodash';
import { CORPORATE_LINK_CONTEXT, REGISTRATION_STEPS as STEPS } from '../../constants';
import { ROUTES, components as Core } from '../../../core';
import WithCorporateAccountLinkFlowBase from './CorporateAccountLinkFlowBase';
import { layoutStyle } from '../../../../styles';
import CorporatePin from '../CorporatePin';
import ProgramDomain from '../ProgramDomain';
import FindDomainName from '../FindDomainName';
import PartnerCustomSSO from '../PartnerCustomSSO';
import SignUpSelection from '../SignUpSelection';
import Register from '../Register';
import CDLSelection from '../CDLSelection';
import Disclaimer from '../Disclaimer';
import CorporateAccountLinkVerify from '../CorporateAccountLinkVerify';

class CorporateAccountLinkFlow extends PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
        step: PropTypes.string,
        originStep: PropTypes.string,
        openNextPage: PropTypes.func.isRequired,
        localStep: PropTypes.string,
        updateLocalRegistrationStep: PropTypes.func.isRequired,
        close: PropTypes.func.isRequired,
        getAdditionalProps: PropTypes.func.isRequired
    };

    static defaultProps = {
        step: STEPS.corporatePin,
        localStep: STEPS.corporatePin,
        originStep: undefined
    };

    static COMPONENTS = {
        [STEPS.programDomain]: ProgramDomain,
        [STEPS.findProgramDomain]: FindDomainName,
        [STEPS.corporatePin]: CorporatePin,
        [STEPS.partnerCustomSSO]: PartnerCustomSSO,
        [STEPS.signUpSelection]: SignUpSelection,
        [STEPS.register]: Register,
        [STEPS.location]: CDLSelection,
        [STEPS.disclaimer]: Disclaimer,
        [STEPS.verifyCorporateLink]: CorporateAccountLinkVerify,
    };

    constructor(props) {
        super(props);
        this._checkStep();
        this.state = { additionalProps: {}, localSteps: [props.step] };
        this.propsToPass = {
            onPress: props.openNextPage,
            onClose: props.close,
            callback: props.openNextPage,
            quit: this.cancelFlow,
            ignoreSSO: true
        };
    }

    _checkStep = () => {
        if (this.props.originStep && this.props.originStep !== this.props.localStep) this._redirect();
    };

    _redirect = () => {
        this.props.history.replace(ROUTES.signup());
    };

    get component() {
        return CorporateAccountLinkFlow.COMPONENTS[this.componentType];
    }

    updateLocalSteps = step => {
        this.setState(prevState => {
            const index = _.findIndex(prevState.localSteps, s => s === step);
            const localSteps = index === -1 ? [...prevState.localSteps, step] : _.take(prevState.localSteps, index + 1);
            return ({ localSteps });
        });
    };

    // is used from base
    openNextPage = (nextStep, userSelection = '', props = {}) => {
        if (!CorporateAccountLinkFlow.COMPONENTS[nextStep]) this._redirect();
        this.props.updateLocalRegistrationStep(nextStep);
        this.updateLocalSteps(nextStep);
        this.setState({ additionalProps: props });
    };

    onGoBack = () => {
        const { localStep, getAdditionalProps, updateLocalRegistrationStep } = this.props;
        if (!localStep || !_.includes(this.state.localSteps, localStep)) return;
        const prevStep = this.state.localSteps[_.indexOf(this.state.localSteps, localStep) - 1];
        if (prevStep) {
            updateLocalRegistrationStep(prevStep);
            this.updateLocalSteps(prevStep);
            const props = getAdditionalProps(prevStep);
            this.setState({ additionalProps: props });
        }
    };

    renderStep = props => {
        const LocalComponent = this.component;
        return (
            <LocalComponent
                {...this.state.additionalProps}
                {...this.props}
                {...props}
                step={this.componentType}
                context={CORPORATE_LINK_CONTEXT}
            />
        );
    };

    get componentType() {
        return this.props.localStep;
    }

    cancelFlow = () => {
        this.props.updateLocalRegistrationStep();
        this.props.close();
    };

    render() {
        return this.component ? (
            <Core.Steps
                propsToPass={this.propsToPass}
                componentType={this.componentType}
                cancelFlow={this.cancelFlow}
                isCloseButtonShown={true}
                showNextScreens={[]}
                showCreateScreens={[]}
                needTrackChanges={true}
                headerClassName={css(layoutStyle.bgTransparent)}
                goBack={this.onGoBack}
                showArrowBack={true}
                modalWithScrollbar={true}
                noFooter={true}>
                {this.renderStep}
            </Core.Steps>
        ) : null;
    }
}

export default withRouter(WithCorporateAccountLinkFlowBase(CorporateAccountLinkFlow));
