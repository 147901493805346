import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Avatar from '../Avatar';
import { ROUTES } from '../../constants';

class TeamAvatar extends PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        challengeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        leaderboard: PropTypes.object,
    };

    static defaultProps = {
        id: undefined,
        challengeId: undefined,
        leaderboard: undefined,
    };

    showTeamDetails = () => {
        const { challengeId, id } = this.props;
        this.props.history.push(ROUTES.teamDetails(challengeId, id));
    };

    render() {
        return (
            <Avatar
                {...this.props}
                onPress={this.showTeamDetails}
            />
        );
    }
}

export default withRouter(TeamAvatar);