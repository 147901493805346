import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import * as actions from '../../actions';
import { spacing } from '../../../../styles';
import { translate, selectors as coreSelectors, parsers } from '../../../core';
import { getChallenge, getRemainingInfo, getEligableActivities, getCategoryColor, getIsTeamChallenge } from '../../selectors';
import { getGoalProgress, isChallengeUpdating, getCompletedPercent } from '../../services/helper';
import { FREQUENCY } from '../../constants';

const PROGRESS_CONTAINER_HEIGHT = 114;

export default function WithGoalProgressBase(WrappedComponent) {
    class GoalProgressBase extends PureComponent {
        static propTypes = {
            challengeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // eslint-disable-line
            i18n: PropTypes.object.isRequired,
            remainingInfo: PropTypes.object.isRequired,
            onPress: PropTypes.func,
            isUpdating: PropTypes.bool,
            challenge: PropTypes.object.isRequired,
            isCurrentPeriod: PropTypes.bool,
            eligableActivities: PropTypes.object.isRequired,
            categoryColor: PropTypes.string.isRequired
        };

        static defaultProps = {
            onPress: undefined,
            isUpdating: false,
            isCurrentPeriod: false
        };

        state = { activeSlide: 0 };

        get progressList() {
            return getGoalProgress(this.props.challenge) || [];
        }

        get currentPeriod() {
            return this.progressList[0];
        }

        get isShowRightStats() {
            const { challenge, isTeamChallenge } = this.props;
            if (challenge.totalPeriods === 1 && !isTeamChallenge) {
                return false;
            }
            return challenge.frequency !== FREQUENCY.total || (challenge.frequency === FREQUENCY.total && isTeamChallenge) || challenge.totalPeriods !== 1 || (challenge.frequency === FREQUENCY.overall && isTeamChallenge);
        }

        get periodTotalPoints() {
            const { challenge: { progress, userActivityLogs } } = this.props;
            const periodActivitiesLogsArr = _.filter(userActivityLogs, activityLog => moment(activityLog.date).isBetween(progress.currentPeriodStartDate, progress.currentPeriodEndDate, 'days', '[]'));
            let periodTotalPoints = 0;
            _.forEach(periodActivitiesLogsArr, activity => {
                periodTotalPoints += activity.points;
            });
            return periodTotalPoints;
        }

        get filledTotalPeriods() {
            const { challenge: { totalPeriods, userTotalCompletedPeriods } } = this.props;
            const filledPercentages = Math.round(userTotalCompletedPeriods / totalPeriods * 100);
            const totalPeriodPercentages = _.get(this.currentPeriod, 'percentFilled', 0);
            return Number.isNaN(filledPercentages) ? totalPeriodPercentages : filledPercentages;
        }

        onSlideChange = index => this.setState({ activeSlide: index });

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    onSlideChange={this.onSlideChange}
                    activeSlide={this.state.activeSlide}
                    progressList={this.progressList}
                    filledTotalPeriods={this.filledTotalPeriods}
                    isShowRightStats={this.isShowRightStats}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const challenge = getChallenge(state, ownProps.challengeId);
        const isTeamChallenge = getIsTeamChallenge(state, ownProps.challengeId);
        return {
            challenge,
            remainingInfo: getRemainingInfo(state, ownProps.challengeId),
            eligableActivities: getEligableActivities(state, ownProps.challengeId),
            categoryColor: getCategoryColor(state, ownProps.challengeId),
            isUpdating: isChallengeUpdating(challenge),
            language: parsers.convertLangString(coreSelectors.getCurrentUserLanguage(state)),
            isTeamChallenge,
            completedPercent: getCompletedPercent(challenge)
        };
    };

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(GoalProgressBase));
}

export const styles = {
    cardPeriodProgress: {
        backgroundColor: 'transparent',
        height: PROGRESS_CONTAINER_HEIGHT,
        paddingBottom: spacing.s1
    },
    progressPadding: {
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3
    },
    progressLine: {
        width: '100%'
    },
    progressBar: {
        paddingRight: spacing.s2,
        marginTop: spacing.s1
    },
    leftStatsMR: {
        marginRight: spacing.s3
    }
};
