import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { getReaction, getAvailableReactions } from '../../selectors';
import { appFonts, baseColors, spacing } from '../../../../styles';

export default function WithReactionItemBase(WrappedComponent) {
    class ReactionItemBase extends PureComponent {
        static propTypes = {
            reactionId: PropTypes.number.isRequired, // eslint-disable-line
            reaction: PropTypes.object.isRequired,
            availableReactions: PropTypes.array
        };

        static defaultProps = {
            availableReactions: []
        };

        get user() {
            const { reaction: { firstname, lastname, image_url, user_id } } = this.props;
            return {
                firstNameDisplay: firstname,
                lastNameDisplay: lastname,
                avatarURL: image_url,
                userId: user_id
            };
        }

        get locationString() {
            const { reaction: { department, location } } = this.props;

            if (location && department) {
                return `${location} ${String.fromCharCode(8226)} ${department}`;
            }
            else if (location) {
                return `${location}`;
            }
            else if (department) {
                return `${department}`;
            }

            return undefined;
        }

        get reactionImage() {
            const { availableReactions, reaction: { slug } } = this.props;
            const matchingReaction = _.find(availableReactions, reaction => reaction.slug === slug);

            if (matchingReaction) {
                return _.get(matchingReaction, 'emoji');
            }

            return undefined;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    user={this.user}
                    reactionImage={this.reactionImage}
                    locationString={this.locationString}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        return {
            reaction: getReaction(state, ownProps.reactionId),
            availableReactions: getAvailableReactions(state)
        };
    }

    return connect(mapStateToProps)(ReactionItemBase);
}

export const styles = {
    mainReactionContainer: {
        backgroundColor: baseColors.white,
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: spacing.s1,
        paddingBottom: spacing.s2,
    },
    avatarContainer: {
        alignItems: 'flex-end',
        flexDirection: 'row',
        position: 'relative',
        width: spacing.s9,
        height: spacing.s9
    },
    emojiContainer: {
        width: spacing.s4,
        height: spacing.s4,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: baseColors.white,
        borderColor: baseColors.grey80,
        borderWidth: 1,
        borderRadius: spacing.s2,
        position: 'absolute',
        bottom: -5,
        right: -5,
    },
    emojiImage: {
        width: spacing.s2,
        height: spacing.s2,
    },
    username: {
        ...appFonts.mdMedium,
    },
    location: {
        ...appFonts.smRegular,
        color: baseColors.grey40,
    },
    textSection: {
        marginLeft: spacing.s4,
        marginRight: spacing.s3,
        flexDirection: 'column'
    }
};
