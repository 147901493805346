import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { StyleSheet, css } from 'aphrodite-jss';

import { baseColors, elements, importantStyles, layoutStyle, spacing } from '../../../../../styles';
import { components as Core } from '../../../../core';
import WithWellbeingInfoBase, { styles as baseStyles } from './WellbeingInfoBase';

class WellbeingInfo extends PureComponent {
    static propTypes = {
        resultCategories: PropTypes.array,
        closeModal: PropTypes.func.isRequired,
        i18n: PropTypes.object.isRequired
    };

    static defaultProps = {
        resultCategories: []
    };

    renderItem = (item, index) => (
        <div key={item.label} className={css(styles.item, index !== this.props.resultCategories.length - 1 ? styles.itemBorder : null)}>
            <Core.Image resizeMode="contain" src={item.image} className={css(styles.itemImage)} />
            <div className={css(layoutStyle.flex1)}>
                <div className={css(styles.itemLabel)}>{item.label}</div>
                <div className={css(styles.itemDescription)}>{item.description}</div>
            </div>
        </div>
    );

    render() {
        return (
            <div className={css(layoutStyle.flex1, styles.mainContainer)}>
                <Core.SubHeader
                    title={this.props.i18n.t('wellbeing.score_info_title')}
                    titleClassName={css(elements.header1)}
                    className={css(styles.headerContainer)}
                />
                <div className={css(styles.bodyContainer)}>
                    {_.map(this.props.resultCategories, this.renderItem)}
                </div>
                <div className={css(styles.buttonContainer)}>
                    <Core.Button onPress={this.props.closeModal} size="medium">
                        {this.props.i18n.t('ok')}
                    </Core.Button>
                </div>
            </div>
        );
    }
}

export default WithWellbeingInfoBase(WellbeingInfo);

const styles = StyleSheet.create(importantStyles({
    ...baseStyles,
    headerContainer: {
        ...baseStyles.headerContainer,
        justifyContent: 'center'
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: spacing.s0,
        flex: 1
    },
    item: {
        ...baseStyles.item,
        display: 'flex'
    },
    mainContainer: {
        backgroundColor: baseColors.white,
        borderRadius: spacing.s1,
        padding: spacing.s2
    },
    itemImage: {
        ...baseStyles.itemImage,
        objectFit: 'contain'
    }
}));