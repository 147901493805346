import { connect } from 'react-redux';
import { getCurrentUser } from '../../selectors';
import { translate } from '../../services';
import { appFonts, baseColors, spacing } from '../../../../styles';

export default function WithUserAvatarHeaderBase(WrappedComponent) {
    const mapStateToProps = (state, ownProps) => ({ user: ownProps.user || getCurrentUser(state) });

    return connect(mapStateToProps)(translate()(WrappedComponent));
}

export const styles = {
    userPanel: {
        flexDirection: 'row',
        alignItems: 'center',
        margin: spacing.s0,
        marginRight: spacing.s1,
        marginLeft: spacing.s2,
    },
    avatar: {
        marginRight: spacing.s2
    },
    body: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    title: {
        ...appFonts.mdBold,
        color: baseColors.black,
    },
    subtitle: {
        ...appFonts.smRegular
    },
};
