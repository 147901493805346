import _ from 'lodash';
import packageJson from '../../../../../package.json';
import { appStoreUrl } from '../../../../config/buildVariant';
import openUrl from '../openUrl';

export const getAppVersion = () => {
    const version = getFullAppVersion();
    let position = -1;
    for (let i = 0; i < 3; i++) {
        const index = _.indexOf(version, '.', position + 1);
        position = index === -1 ? version.length : index;
    }
    return version.substring(0, position);
};

export const getFullAppVersion = () => packageJson.version;

export const openAppStoreUrl = () => {
    openUrl(appStoreUrl);
};