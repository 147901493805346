import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { isIE } from 'react-device-detect';
import _ from 'lodash';

import classnames from 'classnames';
import { components as Core, Modal, onKeyPress } from '../../../../core';
import { baseColors, importantStyles, spacing, layoutStyle } from '../../../../../styles';
import WithWellbeingOverallProgressBase, { styles as baseStyles, HELP_ICON_SIZE, SURVEY_ICON_SIZE } from './WellbeingOverallProgressBase';
import WellbeingProgressIndicator from '../WellbeingProgressIndicator';
import { openHealthSurveyInfo } from '../../../services';

class WellbeingOverallProgress extends PureComponent {
    static propTypes = {
        score: PropTypes.number.isRequired,
        scoreDescription: PropTypes.string.isRequired,
        showStatus: PropTypes.bool.isRequired,
        status: PropTypes.string.isRequired,
        updatedPercentage: PropTypes.string.isRequired,
        lastUpdatedText: PropTypes.string.isRequired,
        className: PropTypes.string,
        surveyResults: PropTypes.array,
        getHealthSurveyModalProps: PropTypes.func.isRequired,
        i18n: PropTypes.object.isRequired,
        howScoreCalculatedTitle: PropTypes.string.isRequired,
        howScoreCalculatedDescription: PropTypes.string.isRequired,
        improveScoreTitle: PropTypes.string.isRequired,
        improveScoreDescription: PropTypes.string.isRequired,
    };

    static defaultProps = {
        className: null,
        surveyResults: [],
    };

    constructor(props) {
        super(props);
        this.state = {
            activeIndex: null
        };
    }

    getActiveIndex = activeIndex => {
        activeIndex !== null && this.setState({ activeIndex });
    }

    openHealthSurveyMeaning = () => {
        const healthSurveyModalProps = this.props.getHealthSurveyModalProps();
        this.closeModal = Modal.open(
            Core.InfoModal,
            {
                ...healthSurveyModalProps,
                buttons: [{ text: healthSurveyModalProps.buttonTitle, onPress: () => this.closeModal() }],
                iconClassName: css(styles.iconClassName)
            },
            {
                fadeTransition: true,
                PaperProps: { elevation: 0 },
                isTransparent: true
            }
        );
    };

    get renderDescription() {
        const { lastUpdatedText, surveyResults } = this.props;
        return (
            <div className={css(styles.descriptionContainer)}>
                <div className={css(styles.surveyResultsWrapper)}>
                    {_.map(surveyResults, this.surveyResultsItem)}
                </div>
                <div className={css(styles.decorativeLine)} />
                <div className={css(styles.text, styles.updatedDate)}>{lastUpdatedText}</div>
            </div>
        );
    }

    get detailsDropdowns() {
        const { howScoreCalculatedTitle, howScoreCalculatedDescription, improveScoreTitle, improveScoreDescription } = this.props;

        return (
            <div className={css(styles.dropdownsSection)}>
                <div className={css(styles.dropdownRowPadding)}>
                    <Core.AccordionListItem
                        itemHeader={(
                            <p className={css(styles.dropdownTitle)}>{howScoreCalculatedTitle}</p>
                        )}
                        itemDetails={(
                            <div className={css(styles.dropdownExpanded)}>
                                <p className={css(styles.expdandedText)}>{howScoreCalculatedDescription}</p>
                            </div>
                        )}
                        itemClassName={css(styles.dropdownRow)}
                        chevronColor={baseColors.grey20}
                        hasSeparator={false}
                        useRootBorder={false}
                        getActiveIndex={this.getActiveIndex}
                        checkExpanded={this.state.activeIndex === 0}
                        index={0}
                    />
                </div>
                <Core.AccordionListItem
                    itemHeader={(
                        <p className={css(styles.dropdownTitle)}>{improveScoreTitle}</p>
                    )}
                    itemDetails={(
                        <div className={css(styles.dropdownExpanded)}>
                            <p className={css(styles.expdandedText)}>{improveScoreDescription}</p>
                        </div>
                    )}
                    itemClassName={css(styles.dropdownRow)}
                    chevronColor={baseColors.grey20}
                    hasSeparator={false}
                    useRootBorder={false}
                    getActiveIndex={this.getActiveIndex}
                    checkExpanded={this.state.activeIndex === 1}
                    index={1}
                />
            </div>
        );
    }

    get indicatorContent() {
        const { score, scoreDescription, isFrenchLang } = this.props;
        return (
            <div className={css(layoutStyle.flexColumn, layoutStyle.fh)}>
                <div
                    tabIndex="0"
                    role="button"
                    onKeyDown={onKeyPress.enter(openHealthSurveyInfo)}
                    className={css(styles.indicatorContainer, layoutStyle.flexColumn, layoutStyle.cp)}
                    onClick={openHealthSurveyInfo}>
                    <span className={css(styles.indicatorScore)}>{score}</span>
                    <div className={css(styles.indicatorDescriptionContainer)}>
                        <span className={classnames(css(styles.indicatorScoreDescription, isFrenchLang ? styles.largeFont : null))}>{scoreDescription}</span>
                        <Core.Icon
                            type="fa"
                            name="question-circle"
                            size={HELP_ICON_SIZE}
                            color={baseColors.secondary}
                            className={css(styles.questionCircleIcon)}
                        />
                    </div>
                </div>
                {/* Status section is hidden for now */}
                {/* <div className={css(styles.statusContainer)}>
                    {showStatus ? (
                        <Core.Icon
                            className={css(status === SCORE_STATUSES.dec.id ? styles.rotatedIcon : null)}
                            fill="solid"
                            type="fa"
                            name="triangle"
                            size={ARROW_ICON_SIZE}
                            color={SCORE_STATUSES[status].color}
                        />
                    ): null}
                    <span className={css(styles.indicatorDelta)}>{updatedPercentage}</span>
                </div> */}
            </div>
        );
    }

    surveyResultsItem = ({ iconName, iconColor, text }) => (
        <div className={css(layoutStyle.flexRow, styles.surveyResultsItem)}>
            <Core.Icon
                type="fa"
                fill="solid"
                name={iconName}
                size={SURVEY_ICON_SIZE}
                color={iconColor}
                className={css(styles.surveyIcon)}
            />
            <p className={css(styles.surveyResultsText)}>{text}</p>
        </div>
    );


    render() {
        return (
            <div className={css(styles.mainContainer, this.props.className)}>
                <WellbeingProgressIndicator
                    onClick={isIE ? openHealthSurveyInfo : undefined}>
                    {this.indicatorContent}
                </WellbeingProgressIndicator>
                {this.renderDescription}
                {this.detailsDropdowns}
            </div>
        );
    }
}

export default WithWellbeingOverallProgressBase(WellbeingOverallProgress);

const INDICATOR_CONTAINER_WIDTH = 150;

const styles = StyleSheet.create(importantStyles({
    ...baseStyles,
    mainContainer: {
        ...baseStyles.mainContainer,
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 0
    },
    indicatorContainer: {
        ...baseStyles.indicatorContainer,
        width: INDICATOR_CONTAINER_WIDTH,
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: spacing.s0,
        margin: '0 auto',
        marginTop: spacing.s5,
        flex: 1
    },
    description: {
        ...baseStyles.description,
        padding: `0 ${spacing.s2}px`
    },
    indicatorDescriptionContainer: {
        ...baseStyles.indicatorDescriptionContainer,
        display: 'flex'
    },
    statusContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    root: {
        margin: '0 auto'
    },
    iconClassName: {
        backgroundColor: baseColors.primary
    },
    indicatorContent: {
        textAlign: 'center'
    },
    rotatedIcon: {
        transform: 'rotateX(180deg)'
    },
    descriptionContainer: {
        ...baseStyles.descriptionContainer,
        marginTop: -spacing.s1,
    },
    decorativeLine: {
        ...baseStyles.decorativeLine,
        margin: '0 auto',
        marginTop: spacing.s5,
    },
    surveyResultsWrapper: {
        display: 'inline-flex',
        flexDirection: 'column',
    },
    surveyIcon: {
        marginTop: spacing.s0,
        marginRight: spacing.s1,
    },
    surveyResultsText: {
        ...baseStyles.surveyResultsText,
        whiteSpace: 'nowrap',
    },
    dropdownRow: {
        backgroundColor: baseColors.grey90,
        borderRadius: spacing.s1,
    },
    dropdownsSection: {
        ...baseStyles.dropdownsSection,
        paddingBottom: spacing.s2
    },
    dropdownRowPadding: {
        marginBottom: spacing.s0,
    },
    dropdownExpanded: {
        backgroundColor: baseColors.white,
        paddingLeft: spacing.s7,
        paddingRight: spacing.s7,
        paddingTop: spacing.s5,
        paddingBottom: spacing.s5
    },
    expdandedText: {
        ...baseStyles.expdandedText,
        whiteSpace: 'pre-line'
    },
    chevonColor: {
        color: baseColors.grey20
    }
}));
