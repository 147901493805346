import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite-jss';
import classnames from 'classnames';
import Grid from '@material-ui/core/Grid';
import { layoutStyle } from '../../../../styles';
import SmallerContainer from '../web/SmallerContainer';

const COMMON_PROP_TYPES = {
    children: PropTypes.node
};

export const Page = ({ children, className }) => <div className={classnames(css(layoutStyle.page), className)}>{children}</div>;
Page.propTypes = { ...COMMON_PROP_TYPES };

export const Content = ({ children, className }) =>
    <div className={classnames(css(layoutStyle.content), className)}>{children}</div>;
Content.propTypes = { ...COMMON_PROP_TYPES };

export const Centered = ({ children }) => <div className={css(layoutStyle.wrapper)}>{children}</div>;
Centered.propTypes = { ...COMMON_PROP_TYPES };

export const Container = ({ children, isFixedFooter, itemProps, isBorder = false, ...props }) => (
    <Grid
        container={true}
        justify="center"
        className={classnames({ [css(layoutStyle.containerWithFixedFooter)]: isFixedFooter })} {...props}>
        <Grid item={true} xs={12} sm={6} {...itemProps} className={isBorder ? css(layoutStyle.fixedFooterBorder) : null}>{children}</Grid>
    </Grid>
);
Container.propTypes = { ...COMMON_PROP_TYPES };

export const WiderContainer = ({ children, itemClassName, itemProps, isBorder = false, ...props }) => (
    <Grid container={true} justify="center" {...props}>
        <Grid
            item={true}
            className={classnames(css(layoutStyle.commonLayout), itemClassName, { [css(layoutStyle.fixedFooterBorder)]: isBorder })}
            {...itemProps}>
            {children}
        </Grid>
    </Grid>
);
WiderContainer.propTypes = { ...COMMON_PROP_TYPES };

export const CenteredContainer = ({ children }) => (
    <Centered>
        <Grid container={true} justify="center">
            <Grid item={true} xs={12} sm={6}>{children}</Grid>
        </Grid>
    </Centered>
);
CenteredContainer.propTypes = { ...COMMON_PROP_TYPES };

export const FH = ({ children }) => (
    <div className={css(layoutStyle.fh)}>{children}</div>
);
FH.propTypes = { ...COMMON_PROP_TYPES };

export const FHCentered = ({ children }) => (
    <div className={css(layoutStyle.fh)}>
        <Centered>{children}</Centered>
    </div>
);
FHCentered.propTypes = { ...COMMON_PROP_TYPES };

export const FHCenteredContainer = ({ children }) => (
    <div className={css(layoutStyle.fh)}>
        <CenteredContainer>{children}</CenteredContainer>
    </div>
);
FHCenteredContainer.propTypes = { ...COMMON_PROP_TYPES };

export const FHFlex = ({ children, className }) => (
    <div className={classnames(css(layoutStyle.fhFlex), className)}>{children}</div>
);
FHFlex.propTypes = { ...COMMON_PROP_TYPES };

export const FHFlexContainer = ({ children, className }) => (
    <Container className={css(layoutStyle.fh)}>
        <div className={classnames(css(layoutStyle.fhFlex), className)}>{children}</div>
    </Container>
);
FHFlexContainer.propTypes = { ...COMMON_PROP_TYPES };

export const PageContent = ({ children }) => (
    <Page><Content>{children}</Content></Page>
);
PageContent.propTypes = { ...COMMON_PROP_TYPES };

export const PageContentCentered = ({ children }) => (
    <Page><Content><Centered>{children}</Centered></Content></Page>
);
PageContentCentered.propTypes = { ...COMMON_PROP_TYPES };

export const PageContentCenteredContainer = ({ children }) => (
    <Page><Content><CenteredContainer>{children}</CenteredContainer></Content></Page>
);
PageContentCenteredContainer.propTypes = { ...COMMON_PROP_TYPES };

export const FixedFooter = ({ children, className, fixedFooterClassName, isTransparent }) => (
    <div className={classnames(css(layoutStyle.fixedFooter, !isTransparent && layoutStyle.bgWhite), fixedFooterClassName)}>
        <div className={classnames(css(layoutStyle.fixedFooterContent), className)}>
            {children}
        </div>
    </div>

);
FixedFooter.propTypes = { ...COMMON_PROP_TYPES };

export const FixedFooterContainer = ({ children, isBorder = false, ...props }) => (
    <FixedFooter {...props}>
        <Container isBorder={isBorder}>{children}</Container>
    </FixedFooter>
);
FixedFooterContainer.propTypes = { ...COMMON_PROP_TYPES };

export const FixedFooterWiderContainer = ({ children, isBorder = false, itemClassName, ...props }) => (
    <FixedFooter {...props}>
        <WiderContainer isBorder={isBorder} itemClassName={itemClassName}>{children}</WiderContainer>
    </FixedFooter>
);
FixedFooterWiderContainer.propTypes = { ...COMMON_PROP_TYPES };

export const FixedFooterSmallerContainer = ({ children, isBorder = false, isOnboarding = false, ...props }) => (
    <FixedFooter {...props}>
        <SmallerContainer isBorder={isBorder} isOnboarding={isOnboarding}>{children}</SmallerContainer>
    </FixedFooter>
);
FixedFooterSmallerContainer.propTypes = { ...COMMON_PROP_TYPES };

export const PageWrapper = ({ children, isModal, ...props }) =>
    isModal ? <Fragment>{children}</Fragment> :
        <Grid container={true} justify="center" {...props}>
            <Grid
                item={true}
                className={classnames(css(layoutStyle.commonLayout))}
                {...props}>
                {children}
            </Grid>
        </Grid>;
PageWrapper.propTypes = { ...COMMON_PROP_TYPES, isModal: PropTypes.bool };
PageWrapper.defaultProps = { isModal: false };

export const FixedModalFooterButton = ({ children, isBorder, ...props }) => (
    <div {...props} className={classnames(css(layoutStyle.fixedFooterModalContainer), props.className)} >{children}</div>
);
FixedModalFooterButton.propTypes = { ...COMMON_PROP_TYPES };
