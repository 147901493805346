import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite-jss';
import _ from 'lodash';
import PropTypes from 'prop-types';
import WithFamilyMembersBase, { styles as baseStyles } from './FamilyMembersBase';
import { components as Core, Modal, ROUTES } from '../../../core';
import { spacing, importantClass, baseColors } from '../../../../styles';

class FamilyMembers extends Component {
    static propTypes = {
        history: PropTypes.object.isRequired,
        deleteFamilyMember: PropTypes.func.isRequired,
        getWarningProps: PropTypes.func.isRequired,
        getInfoModalText: PropTypes.func.isRequired,
        invitationStatusActive: PropTypes.string.isRequired,
        invitationStatusSent: PropTypes.string.isRequired,
        getFullName: PropTypes.func.isRequired,
        title: PropTypes.string,
        subtitle: PropTypes.string,
        inviteBtnLabel: PropTypes.string,
        platformInvitations: PropTypes.array.isRequired,
        isLoading: PropTypes.bool.isRequired
    };

    static defaultProps = {
        title: '',
        subtitle: '',
        inviteBtnLabel: '',
    };
    onDeleteItem = item => {
        const onDelete = () => {
            this.props.deleteFamilyMember(item);
            this.closeWarning();
        };

        const onCloseWarning = () => this.closeWarning();

        this.closeWarning = Modal.open(
            Core.InfoModal,
            this.props.getWarningProps(this.props.getInfoModalText(item), onDelete, onCloseWarning),
            { isContainer: true, isNoPadding: true, isMaxWidthLimited: true, fadeTransition: true }
        );
    };
    onInvitePress = () => {
        this.props.history.push(ROUTES.inviteFamilyMember());
    }

    renderItem = item => {
        const { invitationStatusActive, invitationStatusSent, getFullName } = this.props;
        return (
            <div className={css(styles.itemContainer)}>
                <div className={css(styles.infoWrapper)}>
                    <div className={css(styles.inviteeName)}>{getFullName(item)}</div>
                    <div className={css(styles.inviteeEmail)}>{item.inviteeEmail}</div>
                    {item.accepted ? <div className={css(styles.activeInviteStatus)}>{invitationStatusActive}</div>
                        : <div className={css(styles.pendingInviteStatus)}>{invitationStatusSent}</div>}
                </div>
                <Core.IconButton
                    size={spacing.s3}
                    type="fa"
                    fill="regular"
                    name="trash-alt"
                    color="danger"
                    onClick={() => this.onDeleteItem(item)}
                />
            </div>
        );
    }
    render() {
        const { title, subtitle, inviteBtnLabel, platformInvitations, isLoading } = this.props;
        return (
            <div>
                <Core.SubHeader
                    title={title}
                    noTopPadding={true}
                    noLeftPadding={true}
                    className={css(styles.subHeaderClassName)}
                />
                <div className={css(styles.contentContainer)}>
                    <div className={css(styles.subtitle)}>{subtitle}</div>
                    <Core.Button
                        size="medium"
                        onPress={this.onInvitePress}
                        className={css(styles.inviteButton)}>
                        {inviteBtnLabel}
                    </Core.Button>
                </div>
                <div className={css(styles.wrapper)}>
                    {isLoading ? <Core.ListLoading key="Loading" /> : null}
                    {platformInvitations ? _.map(platformInvitations, item => this.renderItem(item))
                        : null}
                </div>
            </div>
        );
    }
}

export default withRouter(WithFamilyMembersBase(FamilyMembers));

const styles = StyleSheet.create({
    ...baseStyles,
    contentContainer: {
        marginLeft: 0
    },
    subtitle: {
        ...baseStyles.subtitle,
        marginLeft: 0,
        marginTop: 0,
        marginBottom: spacing.s5
    },
    inviteButton: {
        marginLeft: spacing.s1
    },
    wrapper: {
        marginLeft: spacing.s4,
        marginTop: spacing.s4
    },
    itemContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${baseColors.grey80}`,
        padding: spacing.s3
    },
    infoWrapper: {
        flexDirection: 'column'
    },
    inviteeEmail: {
        ...baseStyles.inviteeEmail,
        paddingTop: spacing.s0
    },
    subHeaderClassName: importantClass({
        marginBottom: 0
    })
});
