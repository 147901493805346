import React from 'react';
import { css, StyleSheet } from 'aphrodite-jss';
import PropTypes from 'prop-types';
import { baseColors, layoutStyle, spacing, importantClass } from '../../../../styles';
import Button from '../Button';
import Icon from '../Icon';
import { translate } from '../../services';

const BackButton = ({ onClick, size, i18n }) => {
    return (
        <Button
            size={size}
            onPress={onClick}
            type="outlined"
            className={css(layoutStyle.createEntityButton, styles.backButton)}>
            <Icon
                name="angle-left"
                type="fa"
                size={spacing.s5}
                color={baseColors.secondary}
            />
            <span>{i18n.t('back')}</span>
        </Button>
    );
};

BackButton.propTypes = { onClick: PropTypes.func.isRequired, size: PropTypes.string, i18n: PropTypes.object.isRequired };
BackButton.defaultProps = { size: undefined };

export default translate()(BackButton);

const styles = StyleSheet.create({
    backButton: importantClass({
        paddingLeft: spacing.s1
    })
});
