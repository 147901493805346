export * from './constantsBase';

export const isAndroid = false;

export const APP_STORE_LINK = 'https://apps.apple.com/us/app/sprout-at-work/id904741273';
export const GOOGLE_PLAY_LINK = 'https://play.google.com/store/apps/details?id=com.sproutatwork.sproutmobileapp';
export const APP_GALLERY_LINK = 'https://appgallery.huawei.com/app/C104993765';

export const APP_STORE_IMAGES = {
    en: require('../../../image/appStoreBadge/appStore-en.png'),
    fr: require('../../../image/appStoreBadge/appStore-fr.png'),
    de: require('../../../image/appStoreBadge/appStore-de.png'),
    pt: require('../../../image/appStoreBadge/appStore-pt.png'),
    zh: require('../../../image/appStoreBadge/appStore-zh.png'),
    vi: require('../../../image/appStoreBadge/appStore-vi.png'),
    th: require('../../../image/appStoreBadge/appStore-th.png'),
    sv: require('../../../image/appStoreBadge/appStore-sv.png'),
    da: require('../../../image/appStoreBadge/appStore-da.png'),
    es: require('../../../image/appStoreBadge/appStore-es.png'),
};

export const GOOGLE_PLAY_IMAGES = {
    en: require('../../../image/googlePlayBadge/googlePlay-en.png'),
    fr: require('../../../image/googlePlayBadge/googlePlay-fr.png'),
    es: require('../../../image/googlePlayBadge/googlePlay-es.png'),
    de: require('../../../image/googlePlayBadge/googlePlay-de.png'),
    pt: require('../../../image/googlePlayBadge/googlePlay-pt.png'),
    zh: require('../../../image/googlePlayBadge/googlePlay-zh.png'),
    vi: require('../../../image/googlePlayBadge/googlePlay-vi.png'),
    th: require('../../../image/googlePlayBadge/googlePlay-th.png'),
    sv: require('../../../image/googlePlayBadge/googlePlay-sv.png'),
    da: require('../../../image/googlePlayBadge/googlePlay-da.png'),
};

export const APP_GALLERY_IMAGES = {
    en: require('../../../image/appGalleryBadge/appGallery-en.png'),
    fr: require('../../../image/appGalleryBadge/appGallery-fr.png'),
    es: require('../../../image/appGalleryBadge/appGallery-es.png'),
    da: require('../../../image/appGalleryBadge/appGallery-da.png'),
    pt: require('../../../image/appGalleryBadge/appGallery-pt.png'),
};
