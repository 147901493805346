import { actions as authActions, selectors as authSelectors } from '../../modules/auth';
import { actions as settingsActions } from '../../modules/settings';
import { Storage } from '../../modules/core';
import { global } from '../../config';

export default function initialize() {
    return async function (dispatch, getState) {
        const { login_info = {}, rememberMe } = authSelectors.getUser(getState());
        const credentials = await Storage.getGenericSecureItem('loginUser');
        if (credentials) {
            global.savedLoginUser = credentials;
        }

        const loginUser = await Storage.getItem('loginUser');
        if (loginUser) {
            // loginUser -- No longer being used as of 3.0.16.06
            global.savedLoginUser = loginUser;
            // localStorage is not secure, so we will move it to new secure location
            Storage.setGenericSecureItem('loginUser', global.savedLoginUser);
            Storage.removeItem('loginUser');
        }

        const dataCollectionDisabled = await Storage.getItem('dataCollectionDisabled');
        if (dataCollectionDisabled) {
            dispatch(settingsActions.setDataCollection(dataCollectionDisabled));
        }

        // eslint-disable-next-line
        global.savedRememberMe = await Storage.getItem('rememberMe'); //thank you for this perfect code dear teammates

        // if persisted
        if (login_info.token && rememberMe) {
            dispatch(authActions.restoreLogin(login_info));
        }
        // if not persisted but stored
        else if (global.savedLoginUser && global.savedLoginUser.login_info.registration === '' && global.savedRememberMe) {
            dispatch(authActions.relogin(global.savedLoginUser.login_info));
        }
        // if no info - logout to set logged out status and clear store
        else {
            dispatch(authActions.logoutInternal());
        }
    };
}
