import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { translate, Alert, selectors as coreSelectors } from '../../../../core';
import { getChallenge, isAllPossibleGoalsSet, isCreatingChallenge, isBonusChallenge } from '../../../selectors';
import * as actions from '../../../actions';
import { UNIT_TYPES } from '../../../constants';
import { isPersonal, isCompletedNotInGracePeriod } from '../../../services/helper';

export default function WithRestartButtonBase(WrappedComponent) {
    class RestartButtonBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            challenge: PropTypes.object.isRequired,
            isLoading: PropTypes.bool,
            isAllPossibleGoalsSet: PropTypes.bool.isRequired,
            isBonusChallenge: PropTypes.number.isRequired,
            customPointsUnit: PropTypes.string.isRequired,
        };

        static defaultProps = {
            isLoading: false
        };

        get isRestartRendered() {
            const { challenge } = this.props;
            return isPersonal(challenge) && isCompletedNotInGracePeriod(challenge);
        }

        // todo: refactor this
        restartPersonalGoal = () => {
            if (this.props.isAllPossibleGoalsSet) {
                Alert.alert(this.props.i18n.t('personalGoal.all_goals.errorHeading'),
                    this.props.i18n.t('personalGoal.all_goals.error'));
                return;
            }
            const item = this.props.challenge;

            // Add 10 to the percent complete for the new target value
            const newGoalPercentage = (item.progress.overallUserValue + 10)/100;
            let newComboPoints = 0;
            const start = moment(item.challengeDeadline);
            const end = moment(item.startDate);
            // add 1 to include starting date
            const diff = start.diff(end, 'days') + 1;
            const recommendedGoalsId = _.get(item, 'recommendedGoalsId', undefined);
            const activityQuantity = [];
            const categoryQuantity = [];
            let instruction = '';
            let activityCategory = false;
            _.forEach(item.activityUnitDetails, (activity, index) => {
                if (activity.unit_type === 'activity_category') {
                    categoryQuantity.push({ categoryId: activity.unit_id, quantityPoints: this.newActivityAmount(item, activity, newGoalPercentage) });
                } else {
                    activityQuantity.push({ activityUnitId: activity.unit_id, quantityUnits: this.newActivityAmount(item, activity, newGoalPercentage) });
                }
                if (index === 0) {
                    if (activity.unit_type === UNIT_TYPES.category) {
                        activityCategory = true;
                        instruction = instruction.concat(this.props.i18n.t('createChallenge.finalize.instructionCategory', {
                            points: this.newCategoryPointsValue(item, newGoalPercentage),
                            frequency: item.frequency,
                            category: activity.name,
                            customPoints: this.props.customPointsUnit
                        }));
                        newComboPoints = this.newCategoryPointsValue(item, newGoalPercentage);
                    } else {
                        const points = _.get(activity, 'points', 1);
                        const quantity = this.newInstructionQuantity(item, activity, points, newGoalPercentage);
                        instruction = instruction.concat(this.props.i18n.t('resetGoalInstructions.start', {
                            quantity,
                            units: activity.unit,
                            activity: activity.name
                        }));
                        newComboPoints += this.pointsForActivity(activity, quantity);
                    }
                } else {
                    const points = _.get(activity, 'points', 1);
                    const quantity = this.newInstructionQuantity(item, activity, points, newGoalPercentage);
                    instruction = instruction.concat(this.props.i18n.t('resetGoalInstructions.additional', {
                        quantity,
                        units: activity.unit,
                        activity: activity.name
                    }));
                    newComboPoints += this.pointsForActivity(activity, quantity);
                }
            });
            if (!activityCategory) {
                instruction = instruction.concat(` ${this.props.i18n.t('resetGoalInstructions.end', {
                    frequency: item.frequency,
                    points: newComboPoints,
                    customPoints: this.props.customPointsUnit
                })}`);
            }
            this.props.actions.createCustomChallenge(
                {
                    activityQuantity,
                    categoryQuantity,
                    challenger: item.userEntity.id,
                    comboPoints: newComboPoints,
                    description: instruction,
                    duration: diff,
                    entity: item.userEntity.entityType,
                    frequency: item.frequency,
                    instruction,
                    name: item.challengeName,
                    imageURL: item.challengeImageURL,
                    iconURL: item.challengeIconURL,
                    public: item.challengePublic,
                    published: 0,
                    recommended: item.isRecommended,
                    requirement: 'combo',
                    solo: item.isSolo,
                    start: moment().toDate().toISOString().substring(0, 10),
                    type: item.challengeType,
                    isRecommended: item.isRecommended,
                    isBonusChallenge: this.props.isBonusChallenge,
                    potentialBonusPoints: item.potentialBonusPoints,
                    potentialBonusCurrency: item.potentialBonusCurrency,
                    recommendedGoalsId,
                    restartDay: 'none'
                },
                undefined,
                true
            );
        };

        newInstructionQuantity(item, activity, points, newGoalPercentage) {
            if (item.progress.overallUserValue === 0) {
                return Math.round(activity.amount / points);
            }

            const quantity = Math.round((activity.amount / points) * newGoalPercentage);

            return quantity >= 1 ? quantity: 1;
        }

        newActivityAmount(item, activity, newGoalPercentage) {
            if (item.progress.overallUserValue === 0) {
                return activity.amount;
            }

            return activity.amount * newGoalPercentage;
        }

        newCategoryPointsValue(item, newGoalPercentage) {
            if (item.progress.overallUserValue === 0) {
                return item.progress.overallPointsTargetValue;
            }

            return Math.floor(parseInt(item.progress.overallPointsTargetValue) * newGoalPercentage);
        }

        pointsForActivity(activity, quantity) {
            return Math.floor(parseInt(_.get(activity, 'points', 1) * quantity));
        }

        render() {
            return <WrappedComponent {...this.props} restartPersonalGoal={this.restartPersonalGoal} />;
        }
    }

    const mapStateToProps = (state, ownProps) => ({
        challenge: getChallenge(state, ownProps.challengeId),
        isBonusChallenge: isBonusChallenge(state, ownProps.challengeId),
        isAllPossibleGoalsSet: isAllPossibleGoalsSet(state),
        customPointsUnit: coreSelectors.getCustomPointsUnit(state),
        isLoading: isCreatingChallenge(state)
    });

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(RestartButtonBase));
}

export const styles = {
    cardBodyButton: {
        alignItems: 'center'
    },
};
