import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { css, StyleSheet } from 'aphrodite-jss';
import { withRouter } from 'react-router-dom';
import { withLastLocation } from 'react-router-last-location';
import { AsyncComponent, components as Core, Modal, ROUTES } from '../../../core';
import WithJoinTeamsListBase, { styles as baseStyles } from './JoinTeamsListBase';
import JoinTeamsItem from './JoinTeamsItem';
import { components as Settings, constants as settingsConstants } from '../../../settings';
import { layoutStyle, importantStyles, spacing, windowSize } from '../../../../styles';

class JoinTeamsList extends PureComponent {
    static propTypes = {
        challengeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        i18n: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        joinedTeamCallback: PropTypes.func,
        isModal: PropTypes.bool,
        teamsMembershipInfo: PropTypes.array,
        isLoading: PropTypes.bool,
        isFirstLoading: PropTypes.bool,
        isPage: PropTypes.bool,
        joinTeam: PropTypes.func.isRequired,
        close: PropTypes.func,
        loadMore: PropTypes.func.isRequired,
        hasMore: PropTypes.bool.isRequired,
        isJoining: PropTypes.bool.isRequired,
        searchPlaceHolder: PropTypes.string.isRequired,
        onChangeSearchText: PropTypes.func.isRequired,
        searchText: PropTypes.string.isRequired,
        clear: PropTypes.func.isRequired,
        noTeamsFoundText: PropTypes.string.isRequired,
        hasSearchBox: PropTypes.bool,
    };

    static defaultProps = {
        isLoading: false,
        isFirstLoading: false,
        isModal: false,
        joinedTeamCallback: null,
        close: null,
        teamsMembershipInfo: [],
        isPage: true,
        hasSearchBox: true
    };

    renderTeamRow = ({ item, index }) => {
        const isLastItem = index === this.props?.teamsMembershipInfo?.length - 1;
        return (
            <JoinTeamsItem
                challengeId={this.props.challengeId}
                joinTeam={this.props.joinTeam}
                team={item}
                close={this.props.close}
                hasLargeAvatar={this.props.isPage}
                isLastItem={isLastItem}
            />
        );
    }

    goBack = () => this.props.history.push(
        _.get(this.props, 'lastLocation.pathname') || ROUTES.challengeDetails(this.props.challengeId));

    createTeam = () => {
        if (this.props.close) this.props.close();
        this.close = Modal.open(AsyncComponent(() => import('../CreateEditTeam')), {
            createdCallback: this.props.joinedTeamCallback,
            challengeId: this.props.challengeId,
            isModal: true
        }, {
            cancelable: false,
            isNoPadding: true,
            isContainer: true,
            isFullHeight: true
        });
    };

    get renderEmptyComponent() {
        const { searchText, noTeamsFoundText } = this.props;
        let emptyComponentContent = null;

        if(searchText) {
            emptyComponentContent = (
                <Core.EmptyListSimple message={noTeamsFoundText} iconName='users' />
            );
        } else {
            emptyComponentContent = (
                <>
                    <Core.EmptyListSimple message={this.props.i18n.t('no_teams_create')} />
                    {this.createTeamButton}
                </>
            );
        }

        return (
            <div className={css(styles.emptyComponent, layoutStyle.commonPadding)}>
                {emptyComponentContent}
            </div>
        );
    }

    get createTeamButton() {
        return (
            <Core.Button
                className={css(styles.createButton)}
                type="outlined"
                size={Core.Button.SIZES.medium}
                fullWidth={true}
                onPress={this.createTeam}>
                {this.props.i18n.t('create_team')}
            </Core.Button>
        );
    }

    get renderDisclaimerSection() {
        return (
            <Settings.PrivacySection
                slug={settingsConstants.PRIVACY_SLUGS.CHALLENGE_TEAMS}
                privacyIcon="hand-paper"
                isDisclaimerOnly={true}
            />
        );
    }

    get wrapper() {
        if (this.props.returnTeamList) {
            return 'div';
        } else return Core.Layout.PageWrapper;
    }

    get secondaryWrapper() {
        return this.props.isPage ? Core.SmallerContainer : 'div';
    }

    get loadingComponent() {
        return this.props.isLoading && !this.props.isJoining && !this.props.isFirstLoading ? <Core.ListLoading /> : null;
    }

    get searchComponent() {
        const { searchPlaceHolder, onChangeSearchText, searchText, clear } = this.props;

        return (
            <div>
                <Core.SearchBar
                    onChangeText={onChangeSearchText}
                    value={searchText}
                    onClear={clear}
                    label={searchPlaceHolder}
                />
            </div>
        );
    }

    render() {
        const { i18n, isModal, isPage, close, teamsMembershipInfo, isLoading, loadMore, isFirstLoading, hasMore, hasSearchBox } = this.props;
        return (
            <this.wrapper isModal={isModal}>
                {isPage ? <Core.EntityDetailsHeader hasBackButton={true} /> : null}
                <this.secondaryWrapper>
                    {isPage ?
                        <React.Fragment>
                            <Core.SubHeader title={i18n.t('teams.findTeam')} noLeftPadding={true} className={css(styles.titlePadding)} />
                        </React.Fragment> : null
                    }
                    {isModal ?
                        <Core.SubHeader
                            right={<Core.CloseButton onClick={close} isModal={isModal} />}
                            noTopPadding={true}
                            titleSize={Core.SubHeader.SIZES.large}
                            title={i18n.t('teams.findTeam')}
                        /> : null
                    }
                    {isLoading && isFirstLoading && <Core.ListLoading />}
                    <React.Fragment>
                        {hasSearchBox ? this.searchComponent : null}
                        <Core.InfiniteLazyListFadeIn
                            data={teamsMembershipInfo}
                            keyExtractor={item => item.entityId}
                            renderItem={this.renderTeamRow}
                            onEndReached={loadMore}
                            onEndReachedThreshold={0.5}
                            isLoading={isLoading}
                            hasMore={hasMore}
                            ListHeaderComponent={this.renderDisclaimerSection}
                            ListEmptyComponent={this.renderEmptyComponent}
                            hideLoader={true}
                            isInModal={isModal}
                            offset={windowSize.height}
                            hasMarginHorizontal={false}
                        />
                        {this.loadingComponent}
                    </React.Fragment>
                </this.secondaryWrapper>
            </this.wrapper>
        );
    }
}

const styles = StyleSheet.create(importantStyles({
    ...baseStyles,
    createButton: {
        marginTop: spacing.s3
    },
    titlePadding: {
        marginLeft: spacing.s1
    },
}));

export default withRouter(withLastLocation(WithJoinTeamsListBase(JoinTeamsList)));
