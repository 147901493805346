import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { bindActionCreators } from 'redux';

import { translate } from '../../../core';
import { appFonts, baseColors, spacing } from '../../../../styles';
import { getSetGoalsString } from '../../services/helper';
import * as selectors from '../../selectors';
import * as actions from '../../actions';

export default function WithGoalsInfoBase(WrappedComponent) {
    class GoalsInfoBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            i18n: PropTypes.object.isRequired,
            goalsCount: PropTypes.number,
            isTitleVisible: PropTypes.bool,
            isAllPossibleGoalsSet: PropTypes.bool,
            goals: PropTypes.array
        };

        static defaultProps = {
            goalsCount: 0,
            isTitleVisible: true,
            isAllPossibleGoalsSet: false,
            goals: []
        };

        constructor(props) {
            super(props);
        }

        get recommendedGoals() {
            return _.toLower(getSetGoalsString(
                _.isEmpty(this.props.goals) ? this.props.goalsCount : this.props.goals.length)
            );
        }

        get settingGoalsTitle() {
            const { goalsCount, i18n } = this.props;
            return goalsCount === 1 ? i18n.t('settingGoals.title.one')
                : i18n.t('settingGoal.recommendedDescription.maximumSetTitle', { count: goalsCount });
        }

        get descriptionOne() {
            const { isAllPossibleGoalsSet, i18n } = this.props;
            if (isAllPossibleGoalsSet) return i18n.t('goalsInfo.desc2');
            const prefix = 'wellbeing.recommendedDescription.';
            return `${i18n.t(`${prefix}desc1`)} ${i18n.t(`${prefix}desc2`)} ${i18n.t('goalsInfo.desc1')}`;
        }

        get descriptionTwo() {
            const { isAllPossibleGoalsSet, i18n } = this.props;
            return isAllPossibleGoalsSet ? ` ${i18n.t('settingGoals.description')}` : null;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    descriptionOne={this.descriptionOne}
                    descriptionTwo={this.descriptionTwo}
                    recommendedGoals={this.recommendedGoals}
                    settingGoalsTitle={this.settingGoalsTitle}
                />
            );
        }
    }

    const mapStateToProps = state => ({
        goalsCount: selectors.getUncompletedPersonalGoalsCount(state),
        isAllPossibleGoalsSet: selectors.isAllPossibleGoalsSet(state)
    });

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(GoalsInfoBase));
}

const ICON_MARGIN = 6;

export const styles = {
    mainContainer: {
        flex: 1
    },
    recommendedIconsContainer: {
        marginTop: spacing.s7
    },
    recommendedIcon: {
        marginLeft: ICON_MARGIN,
        marginRight: ICON_MARGIN,
    },
    recommendedTitle: {
        ...appFonts.lgMedium,
        color: baseColors.grey20,
    },
    recommendedDescription: {
        ...appFonts.mdRegular,
        marginTop: spacing.s1,
        color: baseColors.grey40,
    },
    recommendedDescriptionMedium: {
        ...appFonts.mdMedium
    }
};