import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { appFonts, spacing, baseColors } from '../../../../../styles';
import { translate } from '../../../../core';
import { EARN_PARTNERS_STATUS_TYPES } from '../../../constants';

export const NUMBER_OF_LINES = 3;
export const CARD_IMAGE_SIZE = 80;

export default function WithEarnPartnersItemBase(WrappedComponent) {
    class EarnPartnersItemBase extends PureComponent {
        static propTypes = {
            partner: PropTypes.object.isRequired
        };

        static defaultProps = {};

        get item() {
            return this.props.partner;
        }

        get statusImageProps() {
            let iconName = 'link';
            let colorStyle = styles.linkedCircle;
            let type = 'regular';
            if (this.item.status === EARN_PARTNERS_STATUS_TYPES.PENDING) {
                iconName = 'clock';
                colorStyle = styles.pendingCircle;
            } else if (this.item.status === EARN_PARTNERS_STATUS_TYPES.ERROR) {
                iconName = 'exclamation-circle';
                colorStyle = styles.errorCircle;
                type = 'light';
            }
            return { iconName, colorStyle, type };
        }

        get isStatusIconHidden() {
            let isStatusIconHidden = true;
            if (this.item.status === EARN_PARTNERS_STATUS_TYPES.LINKED || this.item.status === EARN_PARTNERS_STATUS_TYPES.PENDING || this.item.status === EARN_PARTNERS_STATUS_TYPES.ERROR) {
                isStatusIconHidden = false;
            }
            return isStatusIconHidden;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    item={this.item}
                    statusImageProps={this.statusImageProps}
                    isStatusIconHidden={this.isStatusIconHidden}
                />
            );
        }
    }

    return translate()(EarnPartnersItemBase);
}

const IMAGE_SIZE = spacing.s9;
const CIRCLE_SIZE = spacing.s4;
const BORDER_RADIUS = spacing.s1;
const BORDER_RADIUS_RECT = 5;

export const styles = {
    cardContainer: {
        flex: 1,
        flexDirection: 'row',
        paddingRight: spacing.s1
    },
    card: {
        flexDirection: 'row',
        flexGrow: 1,
        flex: 1,
        alignItems: 'center',
        borderBottomWidth: 1,
        paddingTop: spacing.s1,
        paddingBottom: spacing.s1,
        minHeight: IMAGE_SIZE + spacing.s6,
        borderBottomColor: baseColors.grey70
    },
    circle: {
        width: CIRCLE_SIZE,
        height: CIRCLE_SIZE,
        borderRadius: CIRCLE_SIZE / 2,
        position: 'absolute',
        top: 0,
        right: 5,
        zIndex: 10,
        paddingLeft: spacing.s0,
        paddingTop: spacing.s0
    },
    roundedRectImage: {
        width: IMAGE_SIZE,
        height: IMAGE_SIZE,
        borderRadius: BORDER_RADIUS_RECT
    },
    cardInner: {
        flex: 1,
        paddingLeft: spacing.s1,
        paddingRight: spacing.s2,
        paddingTop: spacing.s0,
        paddingBottom: spacing.s1
    },
    image: {
        height: IMAGE_SIZE,
        width: IMAGE_SIZE,
        marginRight: spacing.s2,
        borderRadius: BORDER_RADIUS
    },
    partnerTitle: {
        ...appFonts.lgRegular
    },
    partnerHeader: {
        ...appFonts.smRegular
    },
    defaultImage: {
        justifyContent: 'center',
        alignItems: 'center'
    },
    imageContainer: {
        paddingTop: spacing.s0,
        borderRadius: BORDER_RADIUS
    },
    linkedCircle: {
        backgroundColor: baseColors.success
    },
    pendingCircle: {
        backgroundColor: baseColors.warn
    },
    errorCircle: {
        backgroundColor: baseColors.danger
    },
    cardItemInnerContainer: {
        paddingRight: spacing.s1,
        marginRight: spacing.s5,
    },
    cardTitle: {
        ...appFonts.mdBold,
        color: baseColors.black,
        marginBottom: spacing.s0,
    },
    cardDescription: {
        ...appFonts.smRegular,
        color: baseColors.grey20,
    },
};