import storage from 'redux-persist/lib/storage';
import { sha256, aes, encUtf8 } from '../crypto';

const KEY = 'setItem(name, value)';

export default class Storage {
    static getItemSync(name) {
        const item = localStorage.getItem(name);
        let parsed = null;
        try {
            parsed = JSON.parse(item);
        } catch (e) {
            return item;
        }
        return item && parsed;
    }

    static setItemSync(name, value) {
        return localStorage.setItem(name, JSON.stringify(value));
    }

    static removeItemSync(name) {
        return localStorage.removeItem(name);
    }

    static async getItem(name) {
        const item = localStorage.getItem(name);
        return new Promise(resolve => resolve(item && JSON.parse(item)));
    }

    static async setItem(name, value) {
        localStorage.setItem(name, JSON.stringify(value));
        return new Promise(resolve => resolve());
    }

    static async removeItem(name) {
        localStorage.removeItem(name);
        return new Promise(resolve => resolve());
    }

    static async setGenericSecureItem(key, value) {
        return Storage.setItem(Storage.hash(key), aes.encrypt(JSON.stringify(value), KEY).toString());
    }

    static async getGenericSecureItem(key) {
        const value = await Storage.getItem(Storage.hash(key));
        const valueDecrypted = typeof value !== 'string' ? value : JSON.parse(aes.decrypt(value, KEY).toString(encUtf8));
        return new Promise(resolve => resolve(valueDecrypted));
    }

    static async resetGenericSecureItem(key) {
        return Storage.removeItem(Storage.hash(key));
    }

    static hash(key) {
        return sha256(key, KEY).toString();
    }

    static storageType() {
        return storage;
    }
}