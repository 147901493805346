import { css, StyleSheet } from 'aphrodite-jss';
import { importantClass } from '../../../../styles';

const styles = StyleSheet.create({
    modal: importantClass({
        overflow: 'visible'
    }),
});

const modalProps = {
    isContainer: true,
    cancelable: false,
    isNoPadding: true,
    fadeTransition: true,
    PaperProps: { classes: { root: css(styles.modal) } },
};

export default modalProps;