// You do not need to change this anymore to switch servers
// Either set the default in globals.js, or use the backdoor by long-pressing the LOGIN button on welcome screen
/* eslint-disable no-nested-ternary */

import systemBuildVariant, { BUILDS } from './buildVariant/buildVariant';

const _systemURLs = (systemBuildVariant === BUILDS.mylivebetter) ? {
    live: {
        SPROUT_LOGIN_URL: 'https://login.platform.au.sproutatwork.com/v1/',
        SPROUT_BASE_URL: 'https://{subdomain}.platform.au.sproutatwork.com/v1/'
    },
    test: {
        SPROUT_LOGIN_URL: 'https://login.platform.test.au.sproutatwork.com/v1/',
        SPROUT_BASE_URL: 'https://{subdomain}.platform.test.au.sproutatwork.com/v1/'
    },
    dev: {
        SPROUT_LOGIN_URL: 'https://login.platform.dev.au.sproutatwork.com/v1/',
        SPROUT_BASE_URL: 'https://{subdomain}.platform.dev.au.sproutatwork.com/v1/'
    },
    local: {
        SPROUT_LOGIN_URL: 'http://login.vag.sproutatwork.com/v1/',
        SPROUT_BASE_URL: 'http://{subdomain}.vag.sproutatwork.com/v1/'
    }
} : (systemBuildVariant === BUILDS.telus) ?
    {
        live: {
            SPROUT_LOGIN_URL: 'https://login.platform.ca.sproutatwork.com/v1/',
            SPROUT_BASE_URL: 'https://{subdomain}.platform.ca.sproutatwork.com/v1/'
        },
        staging: {
            SPROUT_LOGIN_URL: 'https://login.platform.staging.ca.sproutatwork.com/v1/',
            SPROUT_BASE_URL: 'https://{subdomain}.platform.staging.ca.sproutatwork.com/v1/'
        },
        test: {
            SPROUT_LOGIN_URL: 'https://login.platform.test.ca.sproutatwork.com/v1/',
            SPROUT_BASE_URL: 'https://{subdomain}.platform.test.ca.sproutatwork.com/v1/'
        },
        dev: {
            SPROUT_LOGIN_URL: 'https://login.platform.dev.ca.sproutatwork.com/v1/',
            SPROUT_BASE_URL: 'https://{subdomain}.platform.dev.ca.sproutatwork.com/v1/'
        },
        local: {
            SPROUT_LOGIN_URL: 'http://login.vag.sproutatwork.com/v1/',
            SPROUT_BASE_URL: 'http://{subdomain}.vag.sproutatwork.com/v1/'
        }
    } :
    {
        live: {
            SPROUT_LOGIN_URL: 'https://login.platform.sproutatwork.com/v1/',
            SPROUT_BASE_URL: 'https://{subdomain}.platform.sproutatwork.com/v1/'
        },
        staging: {
            SPROUT_LOGIN_URL: 'https://login.platform.staging.sproutatwork.com/v1/',
            SPROUT_BASE_URL: 'https://{subdomain}.platform.staging.sproutatwork.com/v1/'
        },
        test: {
            SPROUT_LOGIN_URL: 'https://login.platform.test.sproutatwork.com/v1/',
            SPROUT_BASE_URL: 'https://{subdomain}.platform.test.sproutatwork.com/v1/'
        },
        demo: {
            SPROUT_LOGIN_URL: 'https://login.platform.demo.sproutatwork.com/v1/',
            SPROUT_BASE_URL: 'https://{subdomain}.platform.demo.sproutatwork.com/v1/'
        },
        dev: {
            SPROUT_LOGIN_URL: 'https://login.platform.dev.sproutatwork.com/v1/',
            SPROUT_BASE_URL: 'https://{subdomain}.platform.dev.sproutatwork.com/v1/'
        },
        testie: {
            SPROUT_LOGIN_URL: 'https://login.platform.test.ie.sproutatwork.com/v1/',
            SPROUT_BASE_URL: 'https://{subdomain}.platform.test.ie.sproutatwork.com/v1/'
        },
        local: {
            SPROUT_LOGIN_URL: 'http://login.vag.sproutatwork.com/v1/',
            SPROUT_BASE_URL: 'http://{subdomain}.vag.sproutatwork.com/v1/'
        }
    };

export default _systemURLs;
