const DEFAULT_LOGIN_API_URL = 'https://login.platform.sproutatwork.com/v1/';
const DEFAULT_API_URL = 'https://{subdomain}.platform.sproutatwork.com/v1/';

const getApiUrlByProgramName = programName => `https://${programName}.platform.sproutatwork.com/v1/`;

const getMedibankAUApiUrlByProgramName = programName => 'https://livebetter.platform.medibank.com.au/v1/';

const getTelusCAApiUrlByProgramName = programName => `https://${programName}.platform.au.wellbeing.telushealth.com/v1/`;

const getLoginApiUrlByProgramName = programName => 'https://login.platform.sproutatwork.com/v1/';

const getMedibankAULoginApiUrlByProgramName = programName => 'https://livebetter.platform.medibank.com.au/v1/';

const getTelusCALoginApiUrlByProgramName = programName => 'https://login.platform.au.wellbeing.telushealth.com/v1/';

const getApiUrlByProgramNameAndEnv = (programName, env) => `https://${programName}.platform.${env}.sproutatwork.com/v1/`;

const getMedibankAUApiUrlByProgramNameAndEnv = (programName, env) => `https://${programName}.platform.${env}.medibank.com.au/v1/`;

const getTelusCAApiUrlByProgramNameAndEnv = (programName, env) => `https://${programName}.platform.${env}.au.wellbeing.telushealth.com/v1/`;

const getLoginApiUrlByProgramNameAndEnv = (programName, env) => `https://login.platform.${env}.sproutatwork.com/v1/`;

const getMedibankAULoginApiUrlByProgramNameAndEnv = (programName, env) => `https://login.platform.${env}.au.sproutatwork.com/v1/`;

const getTelusCALoginApiUrlByProgramNameAndEnv = (programName, env) => `https://login.platform.${env}.au.wellbeing.telushealth.com/v1/`;

export {
    DEFAULT_LOGIN_API_URL,
    DEFAULT_API_URL,
    getApiUrlByProgramName,
    getLoginApiUrlByProgramName,
    getApiUrlByProgramNameAndEnv,
    getLoginApiUrlByProgramNameAndEnv,
    getMedibankAUApiUrlByProgramName,
    getMedibankAULoginApiUrlByProgramName,
    getMedibankAUApiUrlByProgramNameAndEnv,
    getMedibankAULoginApiUrlByProgramNameAndEnv,
    getTelusCAApiUrlByProgramName,
    getTelusCALoginApiUrlByProgramName,
    getTelusCAApiUrlByProgramNameAndEnv,
    getTelusCALoginApiUrlByProgramNameAndEnv
};
