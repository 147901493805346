import _ from 'lodash';
import * as types from './actionTypes';
import { EVENT_ENTITY_TYPES } from './constants';
import { actionTypes as coreTypes } from '../core';

export const initialState = {
    refreshTime: 0,
    allItems: [],
    myItems: [],
    recommended: [],
    invited: [],
    items: {},
    user: [],
    fetchedEventsCount: {},
    members: {
        items: {},
        ids: {}
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.GET_EVENTS.SUCCESS: {
            const { data: { items, ids }, type, isReplace } = action.payload;
            const idsArray = isReplace ? ids : _.uniq([...state[type], ...ids]);
            return {
                ...state,
                fetchedEventsCount: {
                    ...state.fetchedEventsCount,
                    [type]: ids ? ids.length : 0
                },
                items: {
                    ...state.items,
                    ...items
                },
                [type]: idsArray
            };
        }
        case types.GET_EVENT_BY_ID.SUCCESS: {
            const { data } = action.payload;
            return {
                ...state,
                items: {
                    ...state.items,
                    [data.eventId]: {
                        ...data,
                        isNotificationShown: _.get(state.items, [data.eventId, 'isNotificationShown'], false)
                    }
                }
            };
        }
        case types.JOIN_EVENT.SUCCESS: {
            const { data } = action.payload;
            return {
                ...state,
                items: {
                    ...state.items,
                    [data.eventId]: { ...state.items[data.eventId], ...data, isMember: 1 }
                },
                [EVENT_ENTITY_TYPES.MY_ITEMS]: [...state[EVENT_ENTITY_TYPES.MY_ITEMS], data.eventId]
            };
        }
        case types.DELETE_EVENT.SUCCESS: {
            const { eventId } = action.payload;
            const eventTypes = _.values(EVENT_ENTITY_TYPES);
            const newState = _.mapValues(_.pick(state, eventTypes),
                eventType => _.includes(eventType, eventId) ? _.filter(eventType, item => item !== eventId) : eventType);
            return {
                ...state,
                ...newState,
                items: _.omit(state.items, [eventId])
            };
        }
        case types.EDIT_EVENT.SUCCESS: {
            const { data } = action.payload;
            return {
                ...state,
                items: {
                    ...state.items,
                    [data.eventId]: { ...state.items[data.eventId], ...data }
                }
            };
        }
        case types.CREATE_EVENT.SUCCESS: {
            const { data } = action.payload;
            return {
                ...state,
                items: {
                    ...state.items,
                    [data.eventId]: data
                },
                [EVENT_ENTITY_TYPES.MY_ITEMS]: [...state[EVENT_ENTITY_TYPES.MY_ITEMS], data.eventId],
                [EVENT_ENTITY_TYPES.ALL_ITEMS]: [...state[EVENT_ENTITY_TYPES.ALL_ITEMS], data.eventId],
                lastCreatedId: data.eventId
            };
        }
        case types.LEAVE_EVENT.SUCCESS: {
            const { eventId, userId } = action.payload.data;
            const item = state.items[eventId];
            const newItem = {
                ...item,
                isMember: 0,
                eventInviters: [],
                numEventAttendees: item.numEventAttendees - 1,
                eventAttendees: _.filter(item.eventAttendees, member => member.userId !== userId)
            };
            return {
                ...state,
                items: {
                    ...state.items,
                    [eventId]: newItem

                },
                [EVENT_ENTITY_TYPES.MY_ITEMS]: _.filter(state[EVENT_ENTITY_TYPES.MY_ITEMS], item => item !== eventId)
            };
        }
        case types.GET_EVENT_MEMBERS.SUCCESS: {
            const { eventId, data: { items, ids }, isReplace } = action.payload;
            return {
                ...state,
                members: {
                    ...state.members,
                    items: {
                        ...state.members.items,
                        ...items
                    },
                    ids: {
                        ...state.members.ids,
                        [eventId]: isReplace ? ids : [
                            ...state.members.ids[eventId],
                            ...ids
                        ]
                    }
                }
            };
        }
        case coreTypes.RESET: {
            return initialState;
        }
        default:
            return state;
    }
};