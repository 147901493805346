import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { StyleSheet, css } from 'aphrodite-jss';
import { withRouter } from 'react-router-dom';
import FeedItemHeader from './FeedItemHeader';
import FeedItemEntityCard from './FeedItemEntityCard';
import FeedItemContent from './FeedItemContent';
import FeedItemComments from './FeedItemComments';
import WithFeedItemBase from './FeedItemBase';
import { AsyncComponent, components as Core, Modal, ROUTES, tracker } from '../../../core';
import { spacing, importantClass } from '../../../../styles';

class FeedItem extends PureComponent {
    static propTypes = {
        streamItemId: PropTypes.number.isRequired,
        relationTypeSlug: PropTypes.string.isRequired,
        commentProps: PropTypes.object,
        isDetails: PropTypes.bool,
        entityDetailsInfo: PropTypes.func,
        userDetailsInfo: PropTypes.func,
        history: PropTypes.object.isRequired,
        stream: PropTypes.object
    };

    static defaultProps = {
        commentProps: {},
        isDetails: false,
        entityDetailsInfo: null,
        userDetailsInfo: null,
        stream: undefined
    };

    onEntityDetails = (event, key) => {
        event.stopPropagation();
        event.preventDefault();
        tracker.logEvent('view', { entity: this.props.relationTypeSlug, stream: true });
        const { url, screen, props } = this.props.entityDetailsInfo(key);
        if(screen === ROUTES.userProfile()) {
            this.onUserDetails(props.id);
            return;
        }
        url && this.props.history.push(url);
    };

    onUserDetails = id => {
        Modal.open(
            AsyncComponent(() => import('../../../other/components/UserProfile')),
            { id },
            { isContainer: true, isFullHeight: false }
        );
    };

    get ariaLabelString() {
        const { owner, streamItemTypeSlug } = this.props.stream;
        let actionStr = streamItemTypeSlug;
        actionStr = _.join(_.split(actionStr, '_'), ' ');

        return `${owner.firstName} ${owner.lastName} ${actionStr}`;
    }

    render() {
        const { streamItemId, commentProps, isDetails, stream } = this.props;
        return stream ? (
            <Core.Card className={css(styles.additionalBottomSpaces)} title='' aria-label={this.ariaLabelString}>
                <FeedItemHeader
                    streamItemId={streamItemId}
                    onEntityDetails={this.onEntityDetails}
                    onUserDetails={this.onUserDetails}
                />
                <FeedItemContent streamItemId={streamItemId}>
                    <FeedItemEntityCard streamItemId={streamItemId} onEntityDetails={this.onEntityDetails} />
                </FeedItemContent>
                <FeedItemComments streamItemId={streamItemId} {...commentProps} isDetails={isDetails} />
            </Core.Card>
        ) : null;
    }
}

export default withRouter(WithFeedItemBase(FeedItem));

export const styles = StyleSheet.create({
    additionalBottomSpaces: importantClass({
        marginBottom: spacing.s5,
    }),
});