import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { css, StyleSheet } from 'aphrodite-jss';
import PropTypes from 'prop-types';
import { importantStyles, spacing, layoutStyle } from '../../../../styles';

class CarouserItemLoadingSkeleton extends React.PureComponent {
    render() {
        return (
            <div className={css(this.props.isHalfWidth ? layoutStyle.halfWidth: styles.skeletonItemContainer, styles.padding)}>
                <Skeleton count={1} height={120} />
                <div className={css(styles.skeletonWrapper)}>
                    <Skeleton count={1} />
                </div>
                <div className={css(styles.skeletonWrapper)}>
                    <Skeleton count={1} width="60%" />
                </div>
            </div>
        );
    }
}

CarouserItemLoadingSkeleton.propTypes = {
    isHalfWidth: PropTypes.bool
};

CarouserItemLoadingSkeleton.defaultProps = {
    isHalfWidth: false
};

const styles = StyleSheet.create(importantStyles({
    skeletonItemContainer: {
        width: '30%'
    },
    padding: {
        padding: spacing.s2,
    },
    skeletonWrapper: {
        marginTop: spacing.s1,
    },
}));


export default CarouserItemLoadingSkeleton;
