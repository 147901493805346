import _ from 'lodash';
import * as types from './actionTypes';
import * as api from './api';
import { normalize, selectors as coreSelectors, actions as coreActions, getInapropriateErrors, Storage, constants as coreConstants } from '../core';
import { NAME, STREAM_ENTITY_ID_DEFAULT, STREAM_ENTITY_TYPES } from './constants';
import { actionTypes as CLTypes, constants as CLConstants } from '../commentslikes';
import { validateError } from '../../config';

export function getStreams(idLessThan, maxCount = 20, entityId = STREAM_ENTITY_ID_DEFAULT,
    entityType = STREAM_ENTITY_TYPES.feed, filterId = 0, disableStreamsWeights = 0) {
    return function (dispatch) {
        let params = { maxCount, relatedToEntityId: entityId, relatedToEntityType: entityType, disableStreamsWeights };
        if (filterId) params = { ...params, filterId };
        if (idLessThan) params.idLessThan = idLessThan;

        const GET_STREAMS = idLessThan ? types.GET_MORE_STREAMS : types.GET_STREAMS;

        dispatch({ type: GET_STREAMS.REQUEST });
        return api.getStreams(params)
            .then(response => {
                const data = normalize.normalizeArray(
                    response.data, 'streamItemId', { entities: 'comments', idAttribute: 'commentId' });
                dispatch({
                    type: GET_STREAMS.SUCCESS,
                    payload: {
                        ..._.pick(data, ['items', 'ids']),
                        entityType,
                        entityId,
                    }
                });
                dispatch(addCommentsForType(data.comments, _.mapValues(data.items, item => item.comments)));
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: GET_STREAMS.ERROR, payload: { error } });
            }));
    };
}

export function deleteStream(postId) {
    const actionType = 'deletingPost'; // for toast
    return function (dispatch) {
        dispatch({ type: types.DELETE_STREAM.REQUEST });
        return api.deleteStream(postId)
            .then(() => {
                dispatch({
                    type: types.DELETE_STREAM.SUCCESS,
                    payload: { toast: { actionType }, postId }
                });
                dispatch(deleteComments(postId));
            })
            .catch(error => validateError(error, error => {
                dispatch({
                    type: types.DELETE_STREAM.ERROR,
                    payload: { toast: { actionType }, error }
                });
            }));
    };
}

export function moderateStream(postId, isHidingUser = false) {
    let actionType = 'moderatingPost'; // for toast
    if (isHidingUser) {
        actionType = 'hidingUserPost';
    }
    return function (dispatch) {
        dispatch({ type: types.MODERATE_STREAM.REQUEST });
        return api.modarateStream(postId)
            .then(() => {
                dispatch({
                    type: types.MODERATE_STREAM.SUCCESS,
                    payload: { toast: { actionType }, postId }
                });
                dispatch(deleteComments(postId));
            })
            .catch(error => validateError(error, error => {
                dispatch({
                    type: types.MODERATE_STREAM.ERROR,
                    payload: { toast: { actionType }, error }
                });
            }));
    };
}

export function getStreamItem(postId, entityType = STREAM_ENTITY_TYPES.individuals, entityId = STREAM_ENTITY_ID_DEFAULT, isFirst) {
    return function (dispatch) {
        dispatch({ type: types.GET_STREAM.REQUEST });
        return api.getStream(postId)
            .then(response => {
                dispatch({
                    type: types.GET_STREAM.SUCCESS,
                    payload: { item: response.data, entityType, entityId, isFirst }
                });
                dispatch(addComments(response.data));
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.GET_STREAM.ERROR, payload: { error } });
            }));
    };
}

export function postStream(streamItem, isFirst = true) {
    const actionType = 'creatingStream';
    return function (dispatch) {
        dispatch({ type: types.POST_STREAM.REQUEST });
        return api.postStream(streamItem)
            .then(response => {
                dispatch({
                    type: types.POST_STREAM.SUCCESS,
                    payload: {
                        toast: { actionType },
                        item: response.data,
                        entityType: streamItem.postToEntityType || STREAM_ENTITY_TYPES.feed,
                        entityId: streamItem.postToEntityId || STREAM_ENTITY_ID_DEFAULT,
                        isFirst
                    }
                });
                dispatch(addComments(response.data));
                setTimeout(() => dispatch(coreActions.askUserForAppStoreReviewIfPossible()), coreConstants.APP_RATING_DELAY);
            })
            .catch(error => validateError(error, error => {
                const inapropriateErrors = getInapropriateErrors(error);
                dispatch({
                    type: types.POST_STREAM.ERROR,
                    payload: {
                        toast: inapropriateErrors ? null : { actionType },
                        error: inapropriateErrors ? _.assign(error.response.data, { type: inapropriateErrors }) : error,
                        isErrorStore: inapropriateErrors
                    }
                });
            }));
    };
}


export function clearPostStreamError() {
    return coreActions.removeError(types.POST_STREAM.NAME);
}

export function clearUpdateStreamError() {
    return coreActions.removeError(types.UPDATE_STREAM.NAME);
}

export function updateStream(item) {
    const actionType = 'editingStream';
    return function (dispatch) {
        dispatch({ type: types.UPDATE_STREAM.REQUEST });
        return api.updateStream(item)
            .then(response => {
                dispatch({
                    type: types.UPDATE_STREAM.SUCCESS,
                    payload: { toast: { actionType }, item: response.data }
                });
                dispatch(addComments(response.data));
            })
            .catch(error => validateError(error, error => {
                const inapropriateErrors = getInapropriateErrors(error);
                dispatch({
                    type: types.UPDATE_STREAM.ERROR,
                    payload: {
                        toast: inapropriateErrors ? null : { actionType },
                        error: inapropriateErrors ? _.assign(error.response.data, { type: inapropriateErrors }) : error,
                        isErrorStore: inapropriateErrors
                    }
                });
            }));
    };
}

function addCommentsForType(comments, byType) {
    return {
        type: CLTypes.GET_COMMENTS_FOR_TYPE.SUCCESS,
        payload: { comments, byType, commentedItemType: CLConstants.LIKES_ENTITY_TYPES.stream }
    };
}

function addComments(stream) {
    return {
        type: CLTypes.GET_COMMENTS.SUCCESS,
        payload: {
            commentedItemId: stream.streamItemId,
            commentedItemType: CLConstants.LIKES_ENTITY_TYPES.stream,
            ...normalize.normalizeArray(stream.comments, 'commentId')
        }
    };
}

function deleteComments(streamId) {
    return {
        type: CLTypes.DELETE_COMMENTS.SUCCESS,
        payload: {
            commentedItemId: streamId,
            commentedItemType: CLConstants.LIKES_ENTITY_TYPES.stream
        }
    };
}

export function setStreamViewerMember(relatedToId) {
    return {
        type: types.SET_STREAM_VIEWER_MEMBER,
        payload: { relatedToId }
    };
}

export function removeStreamViewerMember(relatedToId) {
    return {
        type: types.REMOVE_STREAM_VIEWER_MEMBER,
        payload: { relatedToId }
    };
}

export function deleteStreamsForId(entityId, entityType = STREAM_ENTITY_TYPES.group) {
    return {
        type: types.DELETE_STREAMS_FOR_ID,
        payload: { entityId, entityType }
    };
}

export function deleteStreamsRelatedItemsDetails(entityId, entityType) {
    return {
        type: types.DELETE_STREAMS_RELATED_ITEMS_DETAILS,
        payload: { entityId, entityType }
    };
}

// todo: move to user module
export function getDailyMetrics() {
    return function (dispatch, getState) {
        const userId = coreSelectors.getCurrentUser(getState()).userId;
        dispatch({ type: types.GET_DAILY_METRICS.REQUEST });
        return api.getDailyMetrics(userId)
            .then(response => {
                dispatch({
                    type: types.GET_DAILY_METRICS.SUCCESS,
                    payload: { data: response.data }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.GET_DAILY_METRICS.ERROR, payload: { error } });
            }));
    };
}

const FILTER_STORAGE_KEY = `${NAME}/filter`;
const SECONDARY_FILTER_STORAGE_KEY = `${NAME}/disableStreamsWeightsFilter`;

export function setFilter(filter) {
    return function (dispatch) {
        Storage.setItem(FILTER_STORAGE_KEY, filter);
        dispatch({
            type: types.SET_FILTER,
            payload: { filter }
        });
    };
}

export function setDisableStreamsWeights(disableStreamsWeights) {
    return function (dispatch) {
        Storage.setItem(SECONDARY_FILTER_STORAGE_KEY, disableStreamsWeights);
        dispatch({
            type: types.SET_STREAM_WEIGHT,
            payload: { disableStreamsWeights }
        });
    };
}

export function getFilter() {
    return function (dispatch) {
        Storage.getItem(FILTER_STORAGE_KEY).then(filter => dispatch({
            type: types.SET_FILTER,
            payload: { filter }
        }));
    };
}


export function getDisableStreamsWeights() {
    return function (dispatch) {
        Storage.getItem(SECONDARY_FILTER_STORAGE_KEY).then(disableStreamsWeights => dispatch({
            type: types.SET_STREAM_WEIGHT,
            payload: { disableStreamsWeights }
        }));
    };
}

export function tempAddReaction(likedItemId, reactions, reactionsCount, userReactionList) {
    return function (dispatch) {
        dispatch({
            type: types.TEMP_ADD_REACTION,
            payload: { likedItemId, reactions, reactionsCount, userReactionList }
        });
    };
}