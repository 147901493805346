import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { appFonts, baseColors, spacing } from '../../../../styles';
import translate from '../../services/translate';

export default function WithExploreItemMembersBase(WrappedComponent) {
    class ExploreItemMembersBase extends PureComponent {
        static propTypes = {
            text: PropTypes.string,
            members: PropTypes.array,
            isChallenge: PropTypes.bool,
            isCommunity: PropTypes.bool,
            isEvent: PropTypes.bool,
            i18n: PropTypes.object.isRequired,
            isTeamChallenge: PropTypes.bool
        };

        static defaultProps = {
            text: undefined,
            members: [],
            isChallenge: false,
            isCommunity: false,
            isEvent: false,
            isTeamChallenge: false
        };

        get membersToRender() {
            const { members } = this.props;

            if (members && members.length > 0) {
                return _.take(members, 3);
            }

            return [];
        }

        get membersString() {
            const { members, isChallenge, isEvent, i18n, numParticipants, isTeamChallenge } = this.props;
            const membersNum = numParticipants || members.length;

            if (members && members.length > 0) {
                if (isChallenge) {
                    if (isTeamChallenge) {
                        return members.length === 1 ? i18n.t('one_team') : i18n.t('number_teams', { num: membersNum });
                    }
                    return members.length === 1 ? i18n.t('oneParticipant') : i18n.t('numberOfParticipants', { num: membersNum });
                } else if (isEvent) {
                    return members.length === 1 ? i18n.t('oneEventAttendee') : i18n.t('multipleEventAttendees', { num: membersNum });
                }

                return members.length === 1 ? i18n.t('oneMember') : i18n.t('numberOfMembers', { num: membersNum });
            }

            return '';
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    membersToRender={this.membersToRender}
                    membersString={this.membersString}
                />
            );
        }
    }

    return translate()(ExploreItemMembersBase);
}

export const styles = {
    mainContainer: {
        marginTop: spacing.s1,
    },
    text: {
        color: baseColors.grey40,
        ...appFonts.smRegular,
    },
    avatarGroup: {
        marginRight: spacing.s0,
    },
    avatar: {
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: baseColors.white,
        marginLeft: -spacing.s0
    },
    whiteText: {
        color: baseColors.white
    }
};
