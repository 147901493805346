import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite-jss';
import { containerStyle, layoutStyle } from '../../../../styles/commonCSS';
import { components as Core } from '../../../core/index';
import WithFindDomainNameBase, { INPUT_LENGTH } from './FindDomainNameBase';

class FindDomainName extends PureComponent {
    static propTypes = {
        email: PropTypes.string.isRequired,
        errorMessage: PropTypes.string.isRequired,
        isLoading: PropTypes.bool,
        i18n: PropTypes.object.isRequired,
        onChangeEmail: PropTypes.func.isRequired,
        sendEmailProgramName: PropTypes.func.isRequired
    };

    static defaultProps = {
        isLoading: false
    };

    onChangeEmail = event => {
        this.props.onChangeEmail(event.target.value);
    };

    sendEmailProgramName = event => {
        event.preventDefault();
        this.props.sendEmailProgramName();
    };

    render() {
        const { i18n, email, errorMessage, isLoading } = this.props;
        return (
            <div className={css(layoutStyle.fw)}>
                <form className={css(containerStyle.container)} onSubmit={this.sendEmailProgramName} noValidate={true}>
                    <div className={css(containerStyle.header)}>
                        <Core.SubHeader titleAlign={Core.SubHeader.ALIGN.center} left={<span />} title={i18n.t('findProgramNameTitle')} />
                    </div>
                    <div className={css(containerStyle.main)}>
                        <Core.Input
                            fullWidth={true}
                            id="email"
                            label={i18n.t('findProgramNameDescription')}
                            value={email}
                            onChange={this.onChangeEmail}
                            helperText={errorMessage}
                            error={!!errorMessage}
                            maxLength={INPUT_LENGTH}
                        />
                    </div>
                    <div className={css(containerStyle.footerButtonContainer)}>
                        <Core.Button
                            isSubmit={true}
                            fullWidth={true}
                            disabled={isLoading}
                            isLoading={isLoading}
                            onPress={this.sendEmailProgramName}>
                            {i18n.t('findProgramNameButton')}
                        </Core.Button>
                    </div>
                </form>
            </div>
        );
    }
}

export default WithFindDomainNameBase(FindDomainName);
