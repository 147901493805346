import { NAME as name } from './constants';
import { createActionTypes } from '../core';

const NAME = name.toUpperCase();

export const GET_APPS_DEVICES = createActionTypes(`${NAME}/GET_APPS_DEVICES`);
export const GET_DEVICE = createActionTypes(`${NAME}/GET_DEVICE`);
export const DISCONNECT_DEVICE = createActionTypes(`${NAME}/DISCONNECT_DEVICE`);
export const SYNC_DEVICE = createActionTypes(`${NAME}/SYNC_DEVICE`);

export const UPDATE_DEVICE_WARNING_DATE = `${NAME}/UPDATE_DEVICE_WARNING_DATE`;
export const START_CONNECTING = `${NAME}/START_CONNECTING`;
export const FINISH_CONNECTING = `${NAME}/FINISH_CONNECTING`;
export const HIDE_APPS_DEVICES_INFORMATION_TEXT = `${NAME}/HIDE_APPS_DEVICES_INFORMATION_TEXT`;