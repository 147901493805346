import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite-jss';
import { isIE } from 'react-device-detect';
import classnames from 'classnames';
import { spacing, layoutStyle, appFonts, baseColors, media, important } from '../../../../../styles';
import { components as Core, CSS_CLASSES, ROUTES, tracker } from '../../../../core';
import WithDashboardBase, { styles as baseStyles } from './DashboardBase';
import DashboardLevelItem from '../DashboardLevelItem';
import DashboardShopPointsItem from '../DashboardShopPointsItem';
import { components as AppsDevices } from '../../../../appsdevices';
import { components as Feeds } from '../../../../feeds';

const LEVEL_ITEM_CROP_DEGREE = 110;

class Dashboard extends PureComponent {
    static propTypes = {
        score: PropTypes.bool,
        carouselData: PropTypes.array.isRequired,
        updateLevelInformation: PropTypes.func.isRequired,
        initialLevelIndex: PropTypes.number.isRequired,
        history: PropTypes.object.isRequired,
        currentLevelIndex: PropTypes.number.isRequired,
        welcomeText: PropTypes.string.isRequired,
        daysLeft: PropTypes.string.isRequired,
        levelDuration: PropTypes.string.isRequired,
        isLoading: PropTypes.bool,
        isFirstLoaded: PropTypes.bool,
        loadData: PropTypes.func.isRequired,
        isLiveBetter: PropTypes.bool.isRequired
    };

    static defaultProps = {
        score: false,
        isFirstLoaded: false,
        isLoading: false,
    };

    state = {};

    componentDidMount() {
        this.props.loadData();
    }

    get isFirstLoaded() {
        return this.props.score || this.props.isFirstLoaded;
    }

    afterChange = index => {
        this.props.updateLevelInformation(index);
    };

    goToScore = (tabIndex = 0, isLeaderboard, isHighestScoreReached) => {
        if (!this.state.isSliding) {
            this.props.history.push(isLeaderboard ? ROUTES.leaderboard() : ROUTES.yourProgress(), { isLeaderboard, isHighestScoreReached });
            tracker.logEvent('dashboard', { levelIndex: this.props.currentLevelIndex });
        }
    };

    onPress = isHighestProgress => this.goToScore(0, false, isHighestProgress);

    onButtonPress = () => {
        const { isLiveBetter } = this.props;

        if (!isLiveBetter) {
            this.goToScore(1, true);
        } else {
            this.props.history.push(ROUTES.howToEarn());
        }
    }

    get renderCurrentLevelItem() {
        const { carouselData, daysLeft, levelDuration, score, isLoading, loadData } = this.props;
        const item = carouselData[this.props.initialLevelIndex];
        return (
            <DashboardLevelItem
                key={item.name}
                cropDegree={LEVEL_ITEM_CROP_DEGREE}
                onPress={this.onPress}
                onButtonPress={this.onButtonPress}
                currentLevel={item}
                isFirstLoaded={this.isFirstLoaded}
                isLoading={isLoading}
                daysLeft={daysLeft}
                levelDuration={levelDuration}
                isScorePage={score}
                loadData={loadData}
            />
        );
    }

    get renderProgressSection() {
        return (
            <div
                className={css(!this.props.score && styles.progressSection, styles.mb3)}
                id="progress-section">
                <Core.Card className={classnames(css(styles.levelItemContainer), CSS_CLASSES.dashboardTourTarget)}>
                    {this.renderCurrentLevelItem}
                </Core.Card>
                {!this.props.score && (
                    <div className={css(styles.yourDaySection)}>
                        <Feeds.MyDay onButtonPress={this.onButtonPress} />
                    </div>
                )}
            </div>
        );
    }


    get appsPointsSection() {
        return (
            <div className={css(styles.appsPointsSection, layoutStyle.pRelative)} id="apps-points-section">
                <AppsDevices.AppsDevicesDashboardStatus />
                <p id="welcome-text" className={css(styles.welcomeText, layoutStyle.flexCenter)}>
                    {this.props.welcomeText}
                </p>
                <DashboardShopPointsItem />
            </div>
        );
    }

    render() {
        return (
            <Core.SkeletonContent>
                {!this.props.score ? this.appsPointsSection : null}
                {this.renderProgressSection}
            </Core.SkeletonContent>
        );
    }
}

export default withRouter(WithDashboardBase(Dashboard));

const styles = StyleSheet.create({
    ...baseStyles,
    welcomeText: {
        ...appFonts.xlRegular,
        color: baseColors.black,
        '-ms-grid-column': 3,
        [media.sm]: {
            ...appFonts.lgRegular,
        },
    },
    appsPointsSection: {
        display: isIE ? '-ms-grid' : 'grid',
        '-ms-grid-columns': `1fr ${spacing.s3}px 1fr ${spacing.s3}px 1fr`,
        gridTemplateColumns: 'repeat(3, 1fr)',
        alignItems: 'center',
        columnGap: spacing.s3,
        paddingTop: spacing.s5,
        marginBottom: spacing.s5,
        paddingLeft: spacing.s0,
        paddingRight: spacing.s0,
    },
    progressSection: {
        display: isIE ? '-ms-grid' : 'grid',
        '-ms-grid-columns': `2fr ${spacing.s4}px 1fr`,
        gridTemplateColumns: '2fr 1fr',
        columnGap: spacing.s4,
        paddingLeft: spacing.s0,
        paddingRight: spacing.s0,
    },
    levelItemContainer: {
        paddingRight: spacing.s5,
        paddingLeft: spacing.s3,
        paddingTop: spacing.s3,
        paddingBottom: spacing.s3,
        marginBottom: important(0),
        '-ms-grid-column': 1,
        cursor: 'pointer'
    },
    mb3: {
        marginBottom: spacing.s3,
    },
    yourDaySection: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        '-ms-grid-column': 3,
    },
});
