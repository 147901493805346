import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { StyleSheet, css } from 'aphrodite-jss';
import { components as Tours, constants as tourConstants } from '../../../tours';
import { windowSize } from '../../../../styles';
import WithAppsDevicesStatusAlertBase, { styles as baseStyles } from './AppsDevicesStatusAlertBase';

const { isXSScreenResolution } = tourConstants;

class AppsDevicesStatusAlert extends Component {
    static propTypes = {
        title: PropTypes.string,
        linkText: PropTypes.string,
        iconName: PropTypes.string,
        onClose: PropTypes.func,
        onPress: PropTypes.func,
        iconRef: PropTypes.func.isRequired,
        type: PropTypes.object.isRequired
    };

    static defaultProps = {
        title: null,
        linkText: null,
        iconName: null,
        onClose: null,
        onPress: null
    };

    constructor(props) {
        super(props);
        this.state = {
            position: null,
            isCalculatingPosition: true
        };
    }

    componentWillMount() {
        this.getPosition();
    }

    getPosition = () => {
        if (this.props.iconRef) {
            const { offsetParent, offsetWidth, offsetLeft, offsetTop, offsetHeight } = this.props.iconRef;
            const res = this.addOffset(offsetParent, offsetLeft, offsetTop);
            const pageX = res.left;
            const pageY = res.top + offsetHeight;
            const width = isXSScreenResolution() ? offsetWidth : windowSize.width/2;

            this.setState(() => ({
                position: { width, pageX, pageY },
                isCalculatingPosition: false
            }));
        }
    }

    addOffset = (offsetParent, offsetLeft, offsetTop) => {
        let left = offsetLeft;
        let top = offsetTop;
        left += offsetParent.offsetLeft;
        top += offsetParent.offsetTop;
        if (_.get(offsetParent, 'offsetParent')) {
            const result = this.addOffset(offsetParent.offsetParent, left, top);
            left = result.left;
            top = result.top;
        }
        return { left, top };
    };

    componentDidMount() {
        window.addEventListener('resize', this.getPosition);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.getPosition);
    }


    render() {
        return (
            <div className={css(styles.mainContainer)}>
                {!this.state.isCalculatingPosition ?
                    <Tours.DeviceToolTip
                        position={this.state.position}
                        title={this.props.title}
                        subtitle={this.props.linkText}
                        iconName={this.props.iconName}
                        hasCloseButton={true}
                        onClose={this.props.onClose}
                        onGotIt={this.props.onPress}
                        type={this.props.type}
                    />
                    : null}
            </div>
        );
    }
}

export default WithAppsDevicesStatusAlertBase(AppsDevicesStatusAlert);

const styles = StyleSheet.create({
    ...baseStyles,
});
