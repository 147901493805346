import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import classNames from 'classnames';
import { baseColors, spacing, importantStyles, layoutStyle } from '../../../../styles';
import { components as Core } from '../../../core';
import WithInvitationItemBase, { styles as baseStyles } from './InvitationItemBase';

class InvitationItem extends PureComponent {
    static propTypes = {
        item: PropTypes.object,
        onToggle: PropTypes.func,
        containerClassName: PropTypes.string,
        isSelected: PropTypes.bool,
        department: PropTypes.string,
        location: PropTypes.string,
        color: PropTypes.string,
        isUserInvitation: PropTypes.bool
    };

    static defaultProps = {
        item: {},
        onToggle: null,
        containerClassName: null,
        isSelected: false,
        isUserInvitation: true,
        department: undefined,
        location: undefined,
        color: undefined
    };

    render() {
        const { isUserInvitation, isSelected, onToggle, containerClassName, item, color, department, location } = this.props;
        return (
            <div
                role="button"
                tabIndex={0}
                onKeyDown={onToggle}
                className={classNames(css(styles.mainLikeContainer), containerClassName)}
                onClick={onToggle}>
                <div className={css(styles.userPanel)}>
                    <div className={css(styles.userContainer)}>
                        <Core.Avatar
                            disablePress={true}
                            url={item.url}
                            id={item.id}
                            name={item.name}
                        />
                        <div className={css(styles.cardHeader)}>
                            <div
                                className={css(styles.userNameText, isSelected && styles.userNameSelectedText, !isUserInvitation && layoutStyle.alignItemsCenter)}>
                                {item.name}
                            </div>
                            {isUserInvitation ?
                                <div className={css(styles.additionalInfoContainer)}>
                                    <Core.TextSeeMore className={css(styles.additionalInfoText)} numberOfLines={1} isExpandHidden={true}>
                                        {(item.department && item.location) ?
                                            <span className={css(styles.locationIconWrapper)}>
                                                <Core.Icon
                                                    name="briefcase"
                                                    type="fa"
                                                    size={spacing.s2}
                                                    color={baseColors.grey50}
                                                />
                                            </span> : null}
                                        {department}
                                        {department && location && <span> | </span>}
                                        {location}
                                    </Core.TextSeeMore>
                                </div> : null
                            }
                        </div>
                    </div>
                    <Core.Icon
                        name={isSelected ? 'check' : 'plus'}
                        type="fa"
                        size={spacing.s4}
                        color={color}
                        onPress={onToggle}
                    />
                </div>
            </div>
        );
    }
}

const styles = StyleSheet.create(importantStyles({
    ...baseStyles,
    userPanel: {
        ...baseStyles.userPanel,
        borderBottomStyle: 'solid',
        display: 'flex',
    },
    mainLikeContainer: {
        ...baseStyles.mainLikeContainer,
        display: 'flex',
    },
    userContainer: {
        ...baseStyles.userContainer,
        display: 'flex'
    },
    cardHeader: {
        ...baseStyles.cardHeader,
        display: 'flex',
    },
    userNameText: {
        ...baseStyles.userNameText,
        display: 'flex'
    },
}));

export default WithInvitationItemBase(InvitationItem);
