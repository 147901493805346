import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import classnames from 'classnames';
import { spacing } from '../../../../styles';
import MultiSelectCheck from '../MultiSelectCheck';
import * as Layout from '../Layout';
import SubHeader from '../SubHeader';
import { NavButtonText } from '../NavButton';
import CloseButton from '../CloseButton';
import WithSelectModalBase, { styles as baseStyles } from './SelectModalBase';
import { CSS_CLASSES } from '../../constants';

class SelectModal extends PureComponent {
    static propTypes = {
        title: PropTypes.string,
        subtitle: PropTypes.string,
        options: PropTypes.array,
        onPressCallback: PropTypes.func,
        singleSelect: PropTypes.bool,
        open: PropTypes.bool,
        onClose: PropTypes.func,
        i18n: PropTypes.object.isRequired,
        checkboxType: PropTypes.string,
        close: PropTypes.func,
        selected: PropTypes.object,
        isDoneUsed: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
        onDone: PropTypes.func.isRequired,
        backButtonVisible: PropTypes.bool,
        isIconBack: PropTypes.bool,
        modalWithScrollbar: PropTypes.bool,
    };

    static defaultProps = {
        title: undefined,
        subtitle: undefined,
        options: [],
        onPressCallback: null,
        singleSelect: false,
        open: false,
        onClose: null,
        close: null,
        checkboxType: undefined,
        selected: {},
        isDoneUsed: false,
        backButtonVisible: false,
        isIconBack: false,
        modalWithScrollbar: false,
    };

    render() {
        const { title, subtitle, i18n, isIconBack, ...props } = this.props;
        return (
            <Layout.PageWrapper isModal={true}>
                <SubHeader
                    left={this.props.backButtonVisible ?
                        <CloseButton isModal={!isIconBack} onClick={this.props.close} /> : null}
                    title={title}
                    right={this.props.isDoneUsed ?
                        <NavButtonText text={i18n.t('done')} onClick={this.props.onDone} /> : null}
                />
                {subtitle ? <p style={styles.subtitle}>{subtitle}</p> : null}
                <MultiSelectCheck
                    {...props}
                    onPressCallback={this.props.onChange}
                    className={classnames(css(styles.modalBody), CSS_CLASSES.modalBody)}
                    selected={this.props.selected}
                />
            </Layout.PageWrapper>
        );
    }
}

export default WithSelectModalBase(SelectModal);

const MODAL_PADDING = spacing.s3;

const styles = StyleSheet.create({
    ...baseStyles,
    modalBody: {
        ...baseStyles.modalBody,
        maxHeight: 'calc(100vh - 198px)',
        marginRight: -MODAL_PADDING,
        paddingRight: MODAL_PADDING,
    }
});
