import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as actions from '../../actions';
import { spacing, appFonts, baseColors } from '../../../../styles';
import { selectors as coreSelectors, translate, openUrl } from '../../../core';
import { TELUS_CHANGE_PASSWORD_URL } from '../../constants';
import { getChangePasswordError, isChangingPassword, isTHVCSSOEnabled } from '../../selectors';

export const PASSWORDS = {
    currentPassword: 'currentPassword',
    newPassword: 'newPassword',
    confirmNewPassword: 'confirmNewPassword'
};

export default function WithChangePasswordBase(WrappedComponent) {
    class ChangePasswordBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            isLoading: PropTypes.bool,
            i18n: PropTypes.object.isRequired,
            error: PropTypes.object,
            isTelus: PropTypes.bool,
            isTHVCSSOEnabled: PropTypes.bool
        };

        static defaultProps = {
            isLoading: false,
            error: undefined,
            isTelus: false,
            isTHVCSSOEnabled: false
        };

        constructor(props) {
            super(props);
            this.state = {
                currentPassword: '',
                newPassword: '',
                confirmNewPassword: '',
                fieldErrors: { currentPassword: '', newPassword: '', confirmNewPassword: '' }
            };
        }

        UNSAFE_componentWillReceiveProps(nextProps) {
            if (this.props.isLoading && !nextProps.isLoading) {
                this.setErrorString(nextProps.error);
            }
        }

        submitChangePassword = () => {
            this.clearError();
            const { currentPassword, newPassword, confirmNewPassword } = this.state;
            if (this.validateFields(currentPassword, newPassword, confirmNewPassword)) {
                this.props.actions.changeUserPassword(currentPassword, newPassword);
            }
        };

        clearError = () => {
            if (this.props.error) this.props.actions.clearChangePasswordError();
            this.setState({ fieldErrors: { currentPassword: '', newPassword: '', confirmNewPassword: '' } });
        };

        validateFields = (currentPassword, newPassword, confirmNewPassword) => {
            if (currentPassword === '') {
                this.setErrorString(this.createErrorMessage(
                    'current_password_missing', this.props.i18n.t('passwordChangeError.currentPasswordMissing')));
                return false;
            }
            if (newPassword === '') {
                this.setErrorString(this.createErrorMessage(
                    'new_password_missing', this.props.i18n.t('passwordChangeError.newPasswordMissing')));
                return false;
            }
            if (confirmNewPassword === '') {
                this.setErrorString(this.createErrorMessage(
                    'confirm_password_missing', this.props.i18n.t('passwordChangeError.confirmPasswordMissing')));
                return false;
            }
            if (newPassword !== confirmNewPassword) {
                this.setErrorString(this.createErrorMessage(
                    'passwords_not_matching', this.props.i18n.t('passwordChangeError.passwordsMatch')));
                return false;
            }
            return true;
        };

        createErrorMessage = (errorCode, displayMessage) => {
            return { errorCode, displayMessage };
        };

        setErrorString = (error = {}) => {
            switch (error.errorCode) {
                case 'current_password_missing':
                    this.setState({
                        fieldErrors: {
                            ...this.state.fieldErrors,
                            currentPassword: error.displayMessage,
                            newPassword: '',
                            confirmNewPassword: ''
                        } });
                    break;
                case 'new_password_missing':
                    this.setState({
                        fieldErrors: {
                            ...this.state.fieldErrors,
                            currentPassword: '',
                            newPassword: error.displayMessage,
                            confirmNewPassword: ''
                        } });
                    break;
                case 'confirm_password_missing':
                    this.setState({
                        fieldErrors: {
                            ...this.state.fieldErrors,
                            currentPassword: '',
                            newPassword: '',
                            confirmNewPassword: error.displayMessage
                        } });
                    break;
                case 'passwords_not_matching':
                    this.setState({
                        fieldErrors: {
                            ...this.state.fieldErrors,
                            currentPassword: '',
                            newPassword: '',
                            confirmNewPassword: error.displayMessage
                        } });
                    break;
                case 'invalid_reference':
                    this.setState({
                        fieldErrors: {
                            ...this.state.fieldErrors,
                            currentPassword: this.props.i18n.t('passwordChangeError.passwordIncorrect'),
                            newPassword: '',
                            confirmNewPassword: ''
                        } });
                    break;
                default:
                    this.setState({
                        fieldErrors: {
                            ...this.state.fieldErrors,
                            currentPassword: '',
                            newPassword: '',
                            confirmNewPassword: ''
                        } });
                    break;
            }
        };

        onChange = prop => value => {
            this.setState({ [prop]: value });
        };

        openTelusPasswordURL = () => {
            openUrl(TELUS_CHANGE_PASSWORD_URL);
        }

        get title() {
            return this.props.i18n.t('changePasswordTitle');
        }

        get telusInfoText() {
            return this.props.i18n.t('changePassword.telus.info', { telusVC: this.props.i18n.t('telusHealthVirtualCare') });
        }

        get telusButonText() {
            return this.props.i18n.t('changePassword.telus.button', { virtualCare: this.props.i18n.t('virtualCare') });
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    onChange={this.onChange}
                    currentPassword={this.state.currentPassword}
                    newPassword={this.state.newPassword}
                    confirmNewPassword={this.state.confirmNewPassword}
                    fieldErrors={this.state.fieldErrors}
                    submitChangePassword={this.submitChangePassword}
                    title={this.title}
                    telusInfoText={this.telusInfoText}
                    telusButonText={this.telusButonText}
                    openTelusPasswordURL={this.openTelusPasswordURL}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        const currentUser = coreSelectors.getCurrentUser(state);
        return {
            isLoading: isChangingPassword(state),
            error: getChangePasswordError(state),
            userEmail: _.get(currentUser, 'email', ''),
            isTelus: coreSelectors.isTelus(state),
            isTHVCSSOEnabled: isTHVCSSOEnabled(state)
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(ChangePasswordBase));
}

export const styles = {
    innerContainer: {
        marginLeft: spacing.s3,
        marginRight: spacing.s3
    },
    header: {
        marginBottom: spacing.s3
    },
    headerTitle: {
        marginLeft: 0
    },
    button: {
        marginTop: spacing.s2,
        marginBottom: spacing.s5
    },
    telusButton: {
        marginTop: spacing.s7
    },
    telusButtonContent: {
        flexDirection: 'row-reverse',
    },
    telusButtonText: {
        ...appFonts.mdRegular,
    },
    telusText: {
        ...appFonts.mdRegular,
        color: baseColors.grey40
    },
    externalIcon: {
        marginLeft: spacing.s3
    }
};
