import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Events from '../Events';
import WithProfileEventsBase from './ProfileEventsBase';

class ProfileEvents extends PureComponent {
    static propTypes = {
        userId: PropTypes.number,
        callback: PropTypes.func,
        eventType: PropTypes.string,
    };

    static defaultProps = {
        callback: null,
        userId: null,
        eventType: null,
    };

    render() {
        const { callback, eventType, userId } = this.props;
        return (
            <Events
                callback={callback}
                type={eventType}
                userId={userId}
                isModal={true}
            />
        );
    }
}


export default WithProfileEventsBase(ProfileEvents);
