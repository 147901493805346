import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as selectors from '../../selectors';
import * as actions from '../../actions';
import { DISPLAY_TYPES } from '../../constants';
import {
    translate,
    selectors as coreSelectors,
    MAX_COUNT,
    Storage,
    Platform,
    PLATFORMS,
    ENTITIES_ACTIVE_TAB,
    components as Core
} from '../../../core';
import { appFonts, baseColors, spacing } from '../../../../styles';

const { getChallengesList, updateNewChallenge, updateCreateChallengeStep, getInvitations } = actions;

const CHALLENGES_PROPS_MAP = {
    my: { count: 'myChallengesCount', items: 'myChallenges' },
    recent: { count: 'recentChallengesCount', items: 'recentChallenges' },
    completed: { count: 'completedChallengesCount', items: 'completedChallenges' },
    createdByUser: { count: 'getChallengesCreatedByUserCount', items: 'getChallengesCreatedByUser' }
};
export const SKELETON_IMAGE_SIZE = 80;

export default function WithChallengesBase(WrappedComponent) {
    class ChallengesBase extends Component {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            activeTab: PropTypes.string,
            isLoading: PropTypes.bool,
            actions: PropTypes.object.isRequired,
            recommendedChallenges: PropTypes.array,
            invitedChallenges: PropTypes.array,
            recentChallenges: PropTypes.array,
            companyChallenges: PropTypes.array,
            myChallenges: PropTypes.array,
            completedChallenges: PropTypes.array,
            userId: PropTypes.number.isRequired,
            myChallengesCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            recentChallengesCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            completedChallengesCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        };

        static defaultProps = {
            activeTab: undefined,
            isLoading: false,
            recommendedChallenges: undefined,
            invitedChallenges: undefined,
            recentChallenges: undefined,
            companyChallenges: undefined,
            myChallenges: undefined,
            completedChallenges: undefined,
            myChallengesCount: undefined,
            recentChallengesCount: undefined,
            completedChallengesCount: undefined,
        };

        constructor(props) {
            super(props);
            this.TABS = {
                ON_AND_UPCOMING: { id: DISPLAY_TYPES.ON_AND_UPCOMING, label: props.i18n.t('onAndUpcoming') },
                MY: { id: DISPLAY_TYPES.ATTENDED, label: props.i18n.t('yourChallenges') },
            };

            this.tabs = [this.TABS.ON_AND_UPCOMING, this.TABS.MY];
            const activeTab = props.activeTab ? _.find(this.tabs, { id: props.activeTab }, this.TABS.ON_AND_UPCOMING) : this.TABS.ON_AND_UPCOMING;
            const isMyTab = !(Platform.OS === PLATFORMS.web) ? _.get(props?.route.params, 'myTab') : null;
            this.state = { activeTab: isMyTab ? this.TABS.MY : activeTab };

            this.isLoading = true;
            this.challengePropsMap = {
                [DISPLAY_TYPES.ATTENDED]: CHALLENGES_PROPS_MAP.my,
                [DISPLAY_TYPES.RECENT]: CHALLENGES_PROPS_MAP.recent,
                [DISPLAY_TYPES.COMPLETED]: CHALLENGES_PROPS_MAP.completed,
                [DISPLAY_TYPES.USER]: CHALLENGES_PROPS_MAP.createdByUser
            };

            props.actions.getChallengesList({ filter: DISPLAY_TYPES.ATTENDED, maxCount: MAX_COUNT, start: 0, isEnded: 0 }, true);
            props.actions.getChallengesList({ filter: DISPLAY_TYPES.ALL, maxCount: MAX_COUNT, start: 0, isEnded: 0 }, true);
            props.actions.getChallengesList({ filter: DISPLAY_TYPES.INVITATIONS, maxCount: MAX_COUNT, start: 0, isEnded: 0 }, true);
            props.actions.getInvitations();
            props.actions.getChallengesList({ filter: DISPLAY_TYPES.COMPANY, maxCount: MAX_COUNT, start: 0, isEnded: 0 }, true);
            props.actions.getChallengesList({ filter: DISPLAY_TYPES.RECOMMENDED, maxCount: MAX_COUNT, start: 0, isMember: 0, isEnded: 0 }, true);
            props.actions.getChallengesList({ filter: DISPLAY_TYPES.RECENT, maxCount: MAX_COUNT, start: 0, isEnded: 0 }, true);
            props.actions.getChallengesList({ filter: DISPLAY_TYPES.COMPLETED, maxCount: MAX_COUNT, start: 0 }, true);
            props.actions.getChallengesList({ filter: DISPLAY_TYPES.USER, isAdminChallenge: 0, maxCount: MAX_COUNT, start: 0, isEnded: 0 }, true);
        }

        async componentDidMount() {
            if (Platform.OS === PLATFORMS.web) {
                const activeTab = await Storage.getItem(ENTITIES_ACTIVE_TAB.EXPLORE_CHALLENGES);
                if (activeTab && !this.props.location.state?.showOnAndUpcomingChallenges) {
                    this.setState({ activeTab });
                }
            }
        }

        componentDidUpdate() {
            if (this.props.myChallenges && this.props.completedChallenges && !this.props.isLoading) {
                this.setIsLoadingValue(false);
            }
        }

        setIsLoadingValue = isLoading => {
            this.isLoading = isLoading;
        }

        get myTabId() {
            return this.TABS.MY.id;
        }

        get onAndUpcomingId() {
            return this.TABS.ON_AND_UPCOMING.id;
        }

        get isDisplayingCarousels() {
            return true;
        }

        challengeCount = activeId => {
            if (!this.props.myChallenges.length && Platform.OS !== PLATFORMS.web) return 0;
            const propName = _.get(this.challengePropsMap, [activeId, 'count']);
            return this.props[propName] || 0;
        }

        challengeItemsToRender = activeId => {
            const propName = _.get(this.challengePropsMap, [activeId, 'items']);
            return this.props[propName] || [];
        }

        get challengeFilter() {
            return this.state.activeTab.id;
        }

        handleChange = activeTab => {
            this.setState({ activeTab });

            if (Platform.OS === PLATFORMS.web) {
                Storage.setItem(ENTITIES_ACTIVE_TAB.EXPLORE_CHALLENGES, activeTab);
            }
        };

        loadMoreContent = activeId => {
            if (!this.props.isLoading && this.hasMore(activeId)) {
                this.props.actions.getChallengesList(
                    {
                        filter: activeId,
                        maxCount: MAX_COUNT,
                        start: this.challengeItemsToRender(activeId).length,
                        isEnded: activeId === DISPLAY_TYPES.COMPLETED ? 1 : 0
                    },
                    false
                );
            }
        };

        hasMore = activeId => {
            return this.challengeItemsToRender(activeId).length < this.challengeCount(activeId);
        }

        get isMyTab() {
            return this.state.activeTab.id === this.myTabId;
        }

        get isOnAndUpcomingTab() {
            return this.state.activeTab.id === this.onAndUpcomingId;
        }

        keyExtractor = item => item.challengeId;

        saveRef = ref => (this.wrapped = ref);

        get title() {
            return this.props.i18n.t('challenges');
        }

        get subHeaderText() {
            return this.props.i18n.t('challengesSubtitle');
        }

        get isYourChallengesEmpty() {
            return this.challengeItemsToRender(DISPLAY_TYPES.ATTENDED).length === 0
                && this.challengeItemsToRender(DISPLAY_TYPES.COMPLETED).length === 0 ;
        }

        get isOnAndUpcomingEmpty() {
            return this.challengeItemsToRender(DISPLAY_TYPES.RECENT).length === 0;
        }


        get emptyChallengesComponent() {
            const { i18n, isLoading } = this.props;

            const shouldDisplayEmptyOnAndUpcoming = this.isOnAndUpcomingTab && this.isOnAndUpcomingEmpty;
            const shouldDisplayEmptyYourTab = this.isMyTab && this.isYourChallengesEmpty;

            const icon = <Core.Icon name="search" type="fa" size={spacing.s4} color={baseColors.grey50} />;

            if ((shouldDisplayEmptyYourTab || shouldDisplayEmptyOnAndUpcoming) && !isLoading) {
                return <Core.EmptyListSimple message={i18n.t('nothing_to_show')} icon={icon} />;
            }
            return null;
        }

        get topChallenge() {
            const { companyChallenges } = this.props;
            const companyChallengesArr = _.flatten(companyChallenges);
            const topChallenge = _.find(companyChallengesArr, challenge => _.toNumber(challenge.isFeatured)) || undefined;
            return topChallenge;
        }

        get sortCompanyChallenges() {
            const { companyChallenges } = this.props;
            const challenges = _.flatten(companyChallenges);
            const topChallenge = this.topChallenge;
            const filteredCompanyChallenges = challenges.length > 0 && topChallenge ? _.filter(challenges, challenge => challenge.challengeId !== topChallenge.challengeId) : challenges;
            return topChallenge ? [topChallenge, ...filteredCompanyChallenges] : filteredCompanyChallenges;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    ref={this.saveRef}
                    challengeItemsToRender={this.challengeItemsToRender}
                    handleChange={this.handleChange}
                    tabs={this.tabs}
                    activeTab={this.state.activeTab}
                    isDisplayingCarousels={this.isDisplayingCarousels}
                    onAndUpcomingId={this.onAndUpcomingId}
                    isMyTab={this.isMyTab}
                    isOnAndUpcomingTab={this.isOnAndUpcomingTab}
                    isCompletedTab={this.isCompletedTab}
                    hasMore={this.hasMore}
                    loadMoreContent={this.loadMoreContent}
                    keyExtractor={this.keyExtractor}
                    title={this.title}
                    subHeaderText={this.subHeaderText}
                    emptyChallengesComponent={this.emptyChallengesComponent}
                    topChallenge={this.topChallenge}
                    companyChallenges={this.sortCompanyChallenges}
                    isLoading={this.isLoading}
                />
            );
        }
    }

    const mapStateToProps = state => ({
        myChallenges: selectors.getMyChallenges(state),
        myChallengesCount: selectors.getMyChallengesCount(state),
        completedChallenges: selectors.getCompletedChallengesList(state),
        completedChallengesCount: selectors.getCompletedChallengesListCount(state),
        recentChallenges: selectors.getRecentChallenges(state),
        recentChallengesCount: selectors.getRecentChallengesCount(state),
        companyChallenges: selectors.getCompanyChallengesCarousel(state),
        recommendedChallenges: selectors.getRecommendedChallengesCarousel(state),
        invitedChallenges: selectors.getActiveInvites(state),
        isLoading: selectors.isLoadingChallengesList(state),
        isExtendedRole: coreSelectors.isUserExtendedRole(state),
        userId: coreSelectors.getCurrentUserId(state),
        getChallengesCreatedByUser: selectors.getChallengesCreatedByUser(state),
        getChallengesCreatedByUserCount: selectors.getChallengesCreatedByUserCount(state)
    });

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators({ getChallengesList, updateNewChallenge, updateCreateChallengeStep, getInvitations }, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(ChallengesBase));
}
export const styles = {
    subHeader: {
        ...appFonts.xlBold
    },
    invitationTitle: {
        marginTop: 0
    },
    listContainer: {
        marginLeft: spacing.s1,
        marginRight: spacing.s1
    },
    subHeaderText: {
        margin: spacing.s3,
        marginTop: spacing.s1,
        ...appFonts.mdRegular,
        color: baseColors.grey40
    }
};
