import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { css, StyleSheet } from 'aphrodite-jss';
import { withLastLocation } from 'react-router-last-location';
import { components as Core, ROUTES, Modal, AsyncComponent, Storage, ActionSheet } from '../../../core';
import { layoutStyle, important, spacing, baseColors } from '../../../../styles';
import { constants as invitationConstants } from '../../../invitations';
import WithTeamDetailsBase, { styles as baseStyles } from './TeamDetailsBase';
import { SHOULD_REMEMBER_FILTER, IS_TEAM_TAB } from '../../constants';

class TeamDetails extends PureComponent {
    static propTypes = {
        challengeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        entityId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        history: PropTypes.object.isRequired,
        isPage: PropTypes.bool.isRequired,
        leaderboard: PropTypes.object, // eslint-disable-line
        teamInfo: PropTypes.object.isRequired,
        currentMembers: PropTypes.array.isRequired,
        formerMembers: PropTypes.array.isRequired,
        getScore: PropTypes.func.isRequired,
        getMemberTitle: PropTypes.func.isRequired,
        getUserName: PropTypes.func.isRequired,
        getUserAvatar: PropTypes.func.isRequired,
        isUserCreator: PropTypes.bool.isRequired,
        isChallengeCompleted: PropTypes.bool.isRequired,
        isUserInTeam: PropTypes.bool.isRequired,
        keyExtractor: PropTypes.func.isRequired,
        inviteToTeamText: PropTypes.string.isRequired,
        manageTeamText: PropTypes.string.isRequired,
        teamMembersText: PropTypes.string.isRequired,
        getMemberItemTextStyles: PropTypes.func.isRequired,
        challengeHasStarted: PropTypes.bool.isRequired,
        getMemberDepartmentLocation: PropTypes.string.isRequired,
        isTeamFull: PropTypes.bool.isRequired,
        isInvitedByVisible: PropTypes.bool.isRequired,
        invitedByDetails: PropTypes.object.isRequired,
        isJoinVisible: PropTypes.bool.isRequired,
        i18n: PropTypes.object.isRequired,
        joinTeam: PropTypes.func.isRequired,
        isJoining: PropTypes.bool.isRequired,
        isTab: PropTypes.bool,
        isUserAMember: PropTypes.bool.isRequired,
        actionButtonOptions: PropTypes.func.isRequired,
        leaveChallengeMessage: PropTypes.string.isRequired,
        teamHasGroupInfo: PropTypes.bool.isRequired
    };

    static defaultProps = {
        leaderboard: undefined,
        isTab: false
    };

    componentDidUpdate(prevProps) {
        if (prevProps.isUserAMember !== this.props.isUserAMember && this.props.isUserAMember) {
            this.props.history.push(ROUTES.challengeDetails(this.props.challengeId));
        }
    }

    inviteToTeam = () => {
        const { challengeId, entityId } = this.props;
        Storage.setItem(IS_TEAM_TAB, true);
        this.props.history.push(ROUTES.inviteToTeam(challengeId, entityId, invitationConstants.ENTITIES.TEAM));
    };

    viewManageTeamScreen = () => {
        this.close = Modal.open(AsyncComponent(() => import('../CreateEditTeam')), {
            challengeId: this.props.challengeId,
            isEditing: true,
            teamId: this.props.entityId,
            isModal: true
        }, {
            cancelable: false,
            isNoPadding: true,
            isContainer: true,
            isFullHeight: true
        });
    };

    leaveTeam = () => {
        const { challenge, i18n, actions, leaveChallengeMessage } = this.props;

        const onLeaveChallenge = () => {
            actions.leaveChallenge(challenge.challengeId);
            this.closeInfoModal();
        };

        this.closeInfoModal = Modal.open(
            Core.InfoModal,
            {
                title: i18n.t('areYouSure'),
                text: leaveChallengeMessage,
                isButtonVisible: false,
                buttons: [
                    { text: i18n.t('button_leave_challenge'), onPress: () => onLeaveChallenge(), isDangerText: true },
                    { text: i18n.t('button_cancel'), onPress: () => this.closeInfoModal() },
                ],
            },
            { isContainer: true, isNoPadding: true, isMaxWidthLimited: true, fadeTransition: true }
        );
    }

    goBack = () => {
        const { history } =this.props;
        if (_.get(this.props, 'lastLocation.pathname') === '/leaderboard') {
            Storage.setItem(SHOULD_REMEMBER_FILTER, true);
        }
        history.goBack();
    }

    actionButtonPressed = event => {
        const { actionButtonOptions } = this.props;

        const options = actionButtonOptions(this.viewManageTeamScreen, this.inviteToTeam, this.leaveTeam);

        ActionSheet.open(options, options.length - 1, undefined, event);
    }

    get renderMemberCount() {
        const { i18n, membersCount, isTeamFull } = this.props;

        if (!isTeamFull) {
            return (
                <p className={css(styles.rowSubtitle)}>{membersCount}</p>
            );
        }

        return (
            <div className={css(styles.teamDetailsRow)}>
                <p className={css(styles.rowSubtitle)}>{membersCount}</p>
                <Core.Icon
                    type="fa"
                    fill="solid"
                    name="circle"
                    size={spacing.s0}
                    color={baseColors.grey40}
                    className={css(styles.dotIcon)}
                />
                <p className={css(styles.rowSubtitle)}>{i18n.t('teams.teamFull')}</p>
            </div>
        );
    }

    get renderInvitedBy() {
        const { isInvitedByVisible, invitedByDetails } = this.props;

        if (!isInvitedByVisible) return null;

        return (
            <div className={css(styles.teamDetailsRow, styles.detailsPadding)}>
                <Core.Avatar
                    url={invitedByDetails.image}
                    name={invitedByDetails.name}
                    size="xsmall"
                    className={css(styles.smallAvatar)}
                    disablePress={true}
                />
                <p className={css(styles.rowSubtitle)}>{invitedByDetails.string}</p>
            </div>
        );
    }

    get teamHeader() {
        const { teamInfo, isJoinVisible, i18n, joinTeam, isJoining, isUserInTeam, teamHasGroupInfo } = this.props;
        return (
            <div className={css(layoutStyle.flexColumn)}>

                <div className={css(layoutStyle.flexAlignCenter, layoutStyle.flexRow, styles.teamSummaryRow)}>
                    <div className={css(layoutStyle.flexAlignCenter, layoutStyle.flexRow)}>
                        <Core.Avatar
                            disablePress={true}
                            url={teamInfo.entityImageUrl}
                            name={teamInfo.entityName}
                            size="large"
                            className={css(styles.avatar)}
                        />
                        <div className={css(styles.nameScoreSectionWeb)}>
                            <p className={css(styles.teamName)}>{teamInfo.entityName}</p>
                            {teamHasGroupInfo ?
                                (
                                    <>
                                        {this.renderMemberCount}
                                        {this.renderInvitedBy}
                                    </>
                                )
                                : null}
                        </div>
                    </div>
                    {isUserInTeam ? (
                        <div className={css(styles.actionButton)} onClick={this.actionButtonPressed}>
                            <Core.Icon
                                type="fa"
                                fill="regular"
                                name="ellipsis-h"
                                size={spacing.s4}
                                color={baseColors.secondary}
                            />
                        </div>
                    ) : null}
                </div>
                {isJoinVisible ? (
                    <div className={css(styles.joinButtonsSection, layoutStyle.fw)}>
                        <Core.Button
                            onPress={joinTeam}
                            size="large"
                            isLoading={isJoining}
                            fullWidth={true}>
                            {i18n.t('joinTeam.title')}
                        </Core.Button>
                    </div>
                )
                    : null}

            </div>
        );
    }

    get renderTabBarHeader() {
        const { isPage } = this.props;
        return (
            <React.Fragment>
                {isPage ? (
                    <Core.FixedHeader
                        fullWidth={true}
                        subheader={this.teamHeader}
                    />
                ) : this.teamHeader
                }
            </React.Fragment>
        );
    }

    getListItemClasses = () => ({
        root: css(layoutStyle.flexSpaceBetween),
    })

    getAvatarClasses = () => ({
        img: css(layoutStyle.bgWhite),
    })

    itemTextClasses = () => ({
        user: styles.userItemText,
        member: styles.memberItemText,
        formerMember: styles.formerMemberTitleText
    });

    itemPrimaryText = (item, isFormerMember) => {
        const { getMemberItemTextStyles, getMemberTitle, getMemberDepartmentLocation } = this.props;
        return (
            <span className={css(styles.memberDetails)}>
                <span className={css(...getMemberItemTextStyles(item, this.itemTextClasses(), isFormerMember, true))}>
                    {getMemberTitle(item, isFormerMember)}
                </span>
                {!isFormerMember ? (
                    <span className={css(styles.subtitleStyle)}>
                        {getMemberDepartmentLocation(item)}
                    </span>
                )
                    : null}
            </span>
        );
    }

    itemSecondaryText = (item, isFormerMember) => {
        const { getMemberItemTextStyles, getScore, challengeHasStarted } = this.props;

        if (!challengeHasStarted) return null;

        return (
            <span className={css(...getMemberItemTextStyles(item, this.itemTextClasses(), isFormerMember))}>
                {getScore(item)}
            </span>
        );
    }

    renderMembers = (item, isFormerMember = false) => {
        const { keyExtractor, getUserName, getUserAvatar } = this.props;
        const url = getUserAvatar(item, isFormerMember);
        const defaultImage = isFormerMember ? url.default : null;
        return (
            <Core.ListItem
                key={keyExtractor(item)}
                className={css(styles.listItem)}>
                <Core.Avatar
                    size="small"
                    id={item.id}
                    name={getUserName(item)}
                    disablePress={isFormerMember}
                    classes={this.getAvatarClasses()}
                    className={css(styles.memberAvatar)}
                    url={isFormerMember ? defaultImage : url}
                />
                <Core.ListItemText
                    classes={this.getListItemClasses()}
                    secondary={this.itemSecondaryText(item, isFormerMember)}
                    primary={this.itemPrimaryText(item, isFormerMember)}

                />
            </Core.ListItem>
        );
    };

    get renderMembersSection() {
        const { currentMembers, teamMembersText, formerMembers } = this.props;

        return (
            <>
                {currentMembers.length ? (
                    <Fragment>
                        <p className={css(styles.membersTitle)}>
                            {teamMembersText}
                        </p>
                        <div className={css(styles.list)}>
                            {_.map(currentMembers, item => this.renderMembers(item))}
                        </div>
                    </Fragment>
                ) : null}
                {formerMembers.length ? (
                    <Fragment>
                        {_.map(formerMembers, item => this.renderMembers(item, true))}
                    </Fragment>
                ) : null}
            </>
        );
    }

    render() {
        const { isPage, isTab } = this.props;

        if (isTab) {
            return (
                <Core.Layout.PageWrapper isModal={!isPage}>
                    {this.renderTabBarHeader}
                    {this.renderMembersSection}
                </Core.Layout.PageWrapper>
            );
        }

        return (
            <Core.Layout.PageWrapper isModal={!isPage}>
                <Core.EntityDetailsLayout
                    tabsProps={{ headerComponent: this.renderTabBarHeader }}>
                    {this.renderMembersSection}
                </Core.EntityDetailsLayout>
            </Core.Layout.PageWrapper>
        );
    }
}

const styles = StyleSheet.create({
    ...baseStyles,
    nameScoreSectionWeb: {
        maxWidth: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    listItem: {
        ...baseStyles.listItem,
        padding: important(0),
        paddingRight: important(spacing.s3),
        paddingTop: important(spacing.s1),
        paddingBottom: important(spacing.s1),
        borderBottomStyle: 'solid',
        borderBottomColor: baseColors.grey80
    },
    memberDetails: {
        display: 'flex',
        flexDirection: 'column'
    },
    teamDetailsRow: {
        ...baseStyles.teamDetailsRow,
        display: 'flex'
    },
    teamSummaryRow: {
        ...baseStyles.teamSummaryRow,
        display: 'flex',
        justifyContent: 'space-between',
    },
    joinButtonsSection: {
        alignItems: 'center',
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3,
    },
    actionButton: {
        ...baseStyles.actionButton,
        display: 'flex',
        borderStyle: 'solid',
        width: spacing.s9,
        height: spacing.s9,
        '&:hover': {
            cursor: 'pointer',
        }
    },
});

export default withLastLocation(withRouter(WithTeamDetailsBase(TeamDetails)));
