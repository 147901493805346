import _ from 'lodash';
import { actions as coreActions, constants as coreConstants } from '../modules/core';

const { API_CALL_STATES, TOAST_TYPES } = coreConstants;

const TYPES = {
    SUCCESS: TOAST_TYPES.SUCCESS,
    ERROR: TOAST_TYPES.DANGER
};

const requestMiddleWare = store => next => action => {
    if (action && action.type) {
        const matches = new RegExp(`(.*)_(${API_CALL_STATES.SUCCESS}|${API_CALL_STATES.ERROR})`).exec(action.type);
        if (matches && action.payload) {
            const [, requestName, requestState] = matches;
            const { requestId, error, toast, isErrorStore } = action.payload;

            if (requestState === API_CALL_STATES.SUCCESS && toast) {
                const { type, actionType, msg, title } = toast;
                store.dispatch(coreActions.addToast(type || TYPES[requestState], actionType, msg, title));
            }

            if (requestState === API_CALL_STATES.ERROR) {
                const err = _.get(error, 'response.data', error);

                if (toast) {
                    const { type, actionType, msg, title } = toast;
                    const message = err.displayMessage || err.message || msg;
                    store.dispatch(coreActions.addToast(type || TYPES[requestState], actionType, message, title));
                }
                if (isErrorStore) {
                    store.dispatch(coreActions.addError(requestId || requestName, err));
                }
            }
        }
    }

    next(action);
};

export default [requestMiddleWare];
