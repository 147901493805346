import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { withRouter } from 'react-router-dom';
import WithWellbeingScorePopupBase from './MoreWebPopupBase';
import { baseColors, spacing } from '../../../../styles';
import More from '../More';

export class RefHolder extends PureComponent {
    static OFFSET = null;
    render() { return null; }
}

class WellbeingScorePopup extends PureComponent {
    static propTypes = {
        openMoreWebPopup: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired,
        isMoreWebPopupOpen: PropTypes.bool
    };

    static defaultProps = {
        isMoreWebPopupOpen: false
    };

    render() {
        const { isMoreWebPopupOpen, history, openMoreWebPopup } = this.props;
        return isMoreWebPopupOpen ? (
            <div className={css(styles.container)}>
                <RefHolder />
                <div className={css(styles.bg)}>
                    <More history={history} openMoreWebPopup={openMoreWebPopup} />
                </div>
            </div>
        ) : null;
    }
}

export default withRouter(WithWellbeingScorePopupBase(WellbeingScorePopup));

const TOP = 48;
const WIDTH = 400;
const styles = StyleSheet.create({
    container: {
        position: 'absolute',
        top: TOP,
        width: WIDTH,
        color: baseColors.black,
        right: 0,
        paddingTop: spacing.s3
    },
    bg: {
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.06), 0px 4px 16px rgba(0, 0, 0, 0.15), 0px 4px 40px rgba(0, 0, 0, 0.15)',
        borderRadius: 3,
        backgroundColor: baseColors.white,
    }
});
