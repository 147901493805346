import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { css, StyleSheet } from 'aphrodite-jss';
import Calendar from 'react-calendar';
import { isIE } from 'react-device-detect';
import { spacing, baseColors, appFonts, layoutStyle, importantStyles, modalPadding } from '../../../../styles';
import { components as Core, Modal, AsyncComponent, DATE_FORMATS } from '../../../core';
import ActivityList, { ACTIVITY_STATUSES } from '../ActivityList';
import WithActivityTrackingCalendarBase, { styles as baseStyles } from './ActivityTrackingCalendarBase';

class ActivityTrackingCalendar extends PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        isLoading: PropTypes.bool,
        activityLogs: PropTypes.array.isRequired,
        logsWithoutDeleted: PropTypes.array.isRequired,
        removePendingActivity: PropTypes.func.isRequired,
        close: PropTypes.func.isRequired,
        calendarData: PropTypes.array.isRequired,
        isChangesPresent: PropTypes.bool.isRequired,
        exitScreen: PropTypes.func.isRequired,
        submitActivities: PropTypes.func.isRequired,
        deleteActivityData: PropTypes.func.isRequired,
        getActivityTrackingModalProps: PropTypes.func.isRequired,
        getTrackActivitiesListProps: PropTypes.func.isRequired,
        onPressCalendarDate: PropTypes.func.isRequired,
        onSwitchMonths: PropTypes.func.isRequired,
        isAnimated: PropTypes.func.isRequired,
        pendingActivities: PropTypes.array.isRequired,
        updateActivitiesCallback: PropTypes.func.isRequired,
        getFirstActivityTrackModalProps: PropTypes.func.isRequired,
        dotsInfo: PropTypes.array.isRequired,
        isHiddenActivityInformationText: PropTypes.bool.isRequired,
        onHideActivityInformationText: PropTypes.func.isRequired,
        language: PropTypes.string.isRequired
    };

    static defaultProps = {
        isLoading: false,
    };

    constructor(props) {
        super(props);
        this.state = { isAddButtonVisible: false };
        this._today = moment().format(DATE_FORMATS.full);
    }

    toggleNavigatorButton = () => {
        if (this.props.isChangesPresent) {
            this.setState({ isAddButtonVisible: true });
        } else {
            this.setState({ isAddButtonVisible: false });
        }
    };

    dismissModal = () => {
        this.props.close();
    };

    showFirstActivityTrackModal = () => {
        Modal.open(
            Core.InfoModal,
            {
                ...this.props.getFirstActivityTrackModalProps(),
                renderText: this.firstActivityModalDescription
            },
            {
                isTransparent: true,
                PaperProps: {
                    elevation: 0
                },
                fadeTransition: true,
            }
        );
    };

    firstActivityModalDescription = () => (
        <p className={css(styles.firstActivityModalText, layoutStyle.textCenter)}>
            {`${this.props.i18n.t('activityTracking.firstActivityModal.description1')} `}
            <span style={styles.firstActivityModalBold}>{this.props.i18n.t('next')}</span>
            {` ${this.props.i18n.t('activityTracking.firstActivityModal.description2')}`}
        </p>
    );

    updateActivitiesCallback = (pendingActivitiesSubmit, changedActivitiesSubmit, deletedActivitiesSubmit) => {
        this.props.updateActivitiesCallback(pendingActivitiesSubmit, changedActivitiesSubmit, deletedActivitiesSubmit, this.showFirstActivityTrackModal);
        this.closeTrackingList();
    };

    showActivityTrackingModal = (isPending = false) => activity => {
        this.close = Modal.open(
            AsyncComponent(() => import('../ActivityTrackingModal')),
            this.props.getActivityTrackingModalProps(activity, isPending),
            { isContainer: true, fadeTransition: true }
        );
    };

    showTrackActivitiesList = () => {
        this.closeTrackingList = Modal.open(
            AsyncComponent(() => import('../ActivityTrackingList')),
            { ...this.props.getTrackActivitiesListProps(), callback: this.updateActivitiesCallback },
            { isContainer: true, isFullHeight: true, fadeTransition: true }
        );
    };

    onPressCalendarDate = async date => {
        if (this.state.isSliding) return;
        await this.props.onPressCalendarDate(date);
        this.showTrackActivitiesList(date);
    };

    afterChange = index => {
        this.props.onSwitchMonths(index);
    };

    tileContent = ({ date }) => {
        const day = moment(date).format(DATE_FORMATS.full);
        if (_.find(this.props.pendingActivities, ac => ac.date === day)) {
            return <div className={css(styles.dot, layoutStyle.bgSecondary)} />;
        }
        if (_.find(this.props.logsWithoutDeleted, ac => ac.date === day)) {
            return <div className={css(styles.dot, layoutStyle.black)} />;
        }
        return null;
    };

    tileClassName = ({ date }) => moment(date).isSame(this._today, 'day')
        ? css(styles.calendarCell, layoutStyle.flexColumnCenter, styles.calendarCellSelected)
        : css(styles.calendarCell, layoutStyle.flexColumnCenter);

    renderItem = item => (
        <div key={item.month}>
            <p className={css(styles.calendarMonth)}>{moment(item.month).format(DATE_FORMATS.monthFull)}</p>
            <Calendar
                className={css(styles.calendar)}
                tileClassName={this.tileClassName}
                onChange={this.onPressCalendarDate}
                activeStartDate={moment(item.month).toDate()}
                showNavigation={false}
                tileContent={this.tileContent}
                maxDate={new Date()}
                showNeighboringMonth={false}
                locale={this.props.language}
            />
        </div>
    );

    renderDotInfo = item => (
        <div key={item.id} className={css(styles.dotContainerItem)}>
            <div className={css(styles[item.dotStyle])} />
            <p className={css(styles.dotContainerText)}>{item.text}</p>
        </div>
    );

    render() {
        return (
            <div className={css(styles.mainContainer)}>
                <Core.SubHeader
                    left={<Core.CloseButton onClick={this.props.exitScreen} />}
                    title={this.props.i18n.t('track_activity')}
                    titleSize="small"
                    isActivityTrackingSubheader={true}
                    right={this.state.isAddButtonVisible ? (
                        <Core.Button
                            type="text"
                            isWidthAuto={true}
                            onPress={this.props.submitActivities}>
                            {this.props.i18n.t('next')}
                        </Core.Button>
                    ) : null}
                />
                <div className={css(styles.calendarWrapper)}>
                    {!this.props.isHiddenActivityInformationText ?
                        <Core.ClosableInfoSection
                            onClose={this.props.onHideActivityInformationText}
                            text={this.props.i18n.t('activity_tracker_instruction_header')}
                            className={css(styles.trackedInformationText)}
                        /> : null
                    }
                    <Core.Carousel
                        type="single"
                        arrows={true}
                        afterChange={this.afterChange}
                        initialSlide={3}
                        itemsLength={this.props.calendarData.length}>
                        {_.map(this.props.calendarData, this.renderItem)}
                    </Core.Carousel>
                    <div className={css(styles.dotContainer)}>
                        {_.map(this.props.dotsInfo, this.renderDotInfo)}
                    </div>
                </div>
                {this.props.pendingActivities.length ? (
                    <div>
                        <p className={css(styles.header)}>{this.props.i18n.t('activityTracking.addedPending')}</p>
                        <ActivityList
                            activities={this.props.pendingActivities}
                            status={ACTIVITY_STATUSES.pending}
                            onItemClick={this.showActivityTrackingModal(true)}
                            onItemDelete={this.props.removePendingActivity}
                            isAnimated={isIE ? false : this.props.isAnimated}
                            isInModal={true}
                        />
                    </div>
                ) : null}
                {this.props.activityLogs.length ? (
                    <div>
                        <p className={css(styles.header)}>{this.props.i18n.t('recently_tracked_activities')}</p>
                        <ActivityList
                            activities={this.props.activityLogs}
                            status={ACTIVITY_STATUSES.tracked}
                            onItemClick={this.showActivityTrackingModal(false)}
                            onItemDelete={this.props.deleteActivityData}
                            isAnimated={isIE ? false : this.props.isAnimated}
                            isInModal={true}
                        />
                    </div>
                ) : null}
                {!this.props.activityLogs.length && !this.props.pendingActivities.length ? (
                    <div className={css(styles.noActivities)}>
                        <p className={css(styles.placeholder)}>
                            {this.props.i18n.t('havent_tracked_in_a_while')}
                        </p>
                    </div>
                ) : null}
                <Core.BlockingLoading isLoading={this.props.isLoading} />
            </div>
        );
    }
}

const CELL_HEIGHT = 40;

const styles = StyleSheet.create({
    ...baseStyles,
    noActivities: {
        ...baseStyles.noActivities,
        borderTopStyle: 'solid'
    },
    header: {
        ...baseStyles.header,
        marginLeft: -modalPadding,
        marginRight: -modalPadding,
    },
    ...importantStyles({
        calendar: {
            border: 'none',
            margin: 'auto'
        },
        calendarCell: {
            height: CELL_HEIGHT,
            ...appFonts.xsRegular,
            color: baseColors.black,
            backgroundColor: 'transparent',
            borderRadius: spacing.s0,
            '&:hover': {
                backgroundColor: baseColors.grey85,
            },
            '&:disabled': {
                backgroundColor: 'transparent',
                color: baseColors.grey50,
            }
        },
        calendarCellSelected: {
            border: `1px solid ${baseColors.secondary}`
        }
    })
});

export default WithActivityTrackingCalendarBase(ActivityTrackingCalendar);

