import { Storage } from '../../../core';
import { getSsoUrlParams, triggerSamlSSO, autoLogin, checkSSOResult as checkSSOResultBase,
    triggerSSO as triggerSSOBase, openTelusHealthSignUpUrl, openTelusLoginUrl } from './ssoBase';

export { getSsoUrlParams, triggerSamlSSO, autoLogin, openTelusHealthSignUpUrl, openTelusLoginUrl };

const COMPANY_STORAGE_NAME = 'company';

export function checkSSOResult(url, actions, currentUser) {
    actions.getCompanyConfigurationSuccess(Storage.getItemSync(COMPANY_STORAGE_NAME) || {});
    Storage.removeItemSync(COMPANY_STORAGE_NAME);

    return checkSSOResultBase(url, actions, currentUser);
}

export function triggerSSO(param, context, company) {
    Storage.setItem(COMPANY_STORAGE_NAME, company);
    triggerSSOBase(param, context);
}

// v.demchuk+oauth1@sproutatwork.com
// sproutatwork://sso?ssotype=MockMedibank&email=v.demchuk%2Boauth1%40sproutatwork.com&id=2000&token=EllPc-TxTlyzzi0COy8Dr_s5JjxwW7Th&tokenExpires=1536337739&firstName=Volodymyr&lastName=Demchuk&context=signUpSSO
// http://localhost:3000/sso?ssotype=MockMedibank&email=v.demchuk%2Boauth1%40sproutatwork.com&id=2000&token=qJkZy5X2VktAHOkBl_AoMUaetDRuOMl_AoMUaetDRuOMbd&tokenExpires=1536405747&firstName=Volodymyr&lastName=Demchuk&context=signUpSSO
