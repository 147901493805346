import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { getPlatformUser, isSendingPlatformInvitation, getAcceptedPlatformUser, isDeletingPlatformInvitation } from '../../selectors';
import { getUserName, translate, selectors as coreSelectors, parsers, ActionSheet } from '../../../core';
import { appFonts, spacing, baseColors } from '../../../../styles';
import * as actions from '../../actions';
import { USER_CHECK_ICON, USER_PLUS_ICON } from '../../constants';

const DESTRUCTIVE_BUTTON_INDEX = 0;

export default function WithPlatformUserItemBase(WrappedComponent) {
    class PlatformUserItemBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            user: PropTypes.object.isRequired,
            i18n: PropTypes.object.isRequired,
            isSuggestedUser: PropTypes.bool,
            companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            isDeleting: PropTypes.bool,
            navigation: PropTypes.object
        };

        static defaultProps = {
            isSuggestedUser: false,
            companyId: undefined,
            isDeleting: false,
            navigation: null
        };

        onUserInvite = () => {
            if (this.props.user.is_invited || !this.props.isSuggestedUser) return;
            this.props.actions.sendPlatformInvitations(this.props.user.email, this.id);
        };

        onDeleteInvitation = () => {
            this.props.actions.deletePlatformInvitation(this.id, this.props.user.invitee.firstname);
        };

        get id() {
            return _.get(this.props.user, 'id');
        }

        get name() {
            return getUserName(this.props.isSuggestedUser ? this.props.user : this.props.user.invitee);
        }

        get locationDescription() {
            const { user } = this.props;
            const { department, location } = this.props.isSuggestedUser ? user : user.invitee;
            let details;
            if (department && location) {
                details = `${department}, ${location}`;
            } else if (location) {
                details = `${location}`;
            }
            return details;
        }

        get countEntities() {
            const { user } = this.props;
            let count = 0;
            if (_.has(user, 'challenges.length')) count += user.challenges.length;
            if (_.has(user, 'groups.length')) count += user.groups.length;
            if (_.has(user, 'events.length')) count += user.events.length;
            return count;
        }

        get isSameCompany() {
            return this.props.companyId &&
                this.props.companyId.toString() ===
                _.get(this.props.user, this.props.isSuggestedUser ? 'company_id' : 'invitee.companyId', '').toString();
        }

        get firstEntityName() {
            const { user } = this.props;
            return _.get(user, 'challenges.0.name') || _.get(user, 'groups.0.name') || _.get(user, 'events.0.name');
        }

        get entityDescription() {
            if (!this.firstEntityName) return '';
            return this.countEntities === 1 ? this.props.i18n.t('invitation.youAreBoth', { entityName: this.firstEntityName }) :
                this.props.i18n.t('invitation.youAreBothMore', { entityName: this.firstEntityName, n: this.countEntities });
        }

        get description() {
            return parsers.htmlDecode(
                this.isSameCompany ? this.locationDescription : (this.entityDescription || this.locationDescription));
        }

        get iconProps() {
            return this.props.user.is_invited || !this.props.isSuggestedUser ? { name: USER_CHECK_ICON, color: baseColors.black } :
                { name: USER_PLUS_ICON, color: baseColors.secondary };
        }

        get imageUrl() {
            return this.props.isSuggestedUser ? this.props.user.imageUrl : this.props.user.invitee.imageUrl;
        }

        get userId() {
            return this.props.isSuggestedUser ? this.id : this.props.user.inviteeUserId;
        }

        get deleteOptions() {
            return [{
                title: this.props.i18n.t('friendsAndFamily.removeOption'),
                onPress: this.onDeleteInvitation
            }];
        }

        onPressIcon = () => {
            if (this.props.isSuggestedUser) {
                this.onUserInvite();
            } else {
                ActionSheet.open(this.deleteOptions, DESTRUCTIVE_BUTTON_INDEX, undefined, this.props.navigation);
            }
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    name={this.name}
                    isSameCompany={this.isSameCompany}
                    description={this.description}
                    iconProps={this.iconProps}
                    imageUrl={this.imageUrl}
                    userId={this.userId}
                    deleteOptions={this.deleteOptions}
                    onPressIcon={this.onPressIcon}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        const user = ownProps.isSuggestedUser ? getPlatformUser(state, ownProps.userId) : getAcceptedPlatformUser(state, ownProps.userId);
        return {
            user,
            isLoading: isSendingPlatformInvitation(state, ownProps.userId),
            isDeleting: isDeletingPlatformInvitation(state, user.id),
            companyId: coreSelectors.getCurrentUserCompanyId(state)
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(PlatformUserItemBase));
}

export const styles = {
    container: {
        flex: 1,
        paddingLeft: spacing.s3,
        paddingTop: spacing.s3,
        paddingRight: spacing.s3,
        paddingBottom: spacing.s3,
        flexDirection: 'row'
    },
    border: {
        borderBottomWidth: 1,
        borderBottomColor: baseColors.grey80,
    },
    innerContainer: {
        marginLeft: spacing.s3,
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    name: {
        ...appFonts.mdMedium,
        marginBottom: spacing.s0
    },
    description: {
        ...appFonts.smRegular,
        flex: 1
    },
    textContainer: {
        flex: 1,
        paddingRight: spacing.s3
    },
    icon: {
        marginRight: spacing.s0,
    }
};
