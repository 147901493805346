import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { Virtuoso } from 'react-virtuoso';
import MUIList from '@material-ui/core/List';
import MUIListItem from '@material-ui/core/ListItem';
import MUIListItemText from '@material-ui/core/ListItemText';
import MUIListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import classnames from 'classnames';
import { isIE } from 'react-device-detect';
import { appFonts, baseColors, spacing, layoutStyle, importantClass, windowSize } from '../../../../styles';
import Checkbox from '../Checkbox';
import WithMultiSelectBase, { styles as baseStyles } from './MultiSelectCheckBase';
import { onKeyPress, components as Core } from '../..';

class MultiSelectCheck extends PureComponent {
    static propTypes = {
        error: PropTypes.string,
        options: PropTypes.array,
        toggleSelectedValue: PropTypes.func,
        isSelected: PropTypes.func,
        checkboxType: PropTypes.string,
        horizontalButtons: PropTypes.bool,
        className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        hasContainerStyles: PropTypes.bool,
        showSelectedTitle: PropTypes.bool,
        titleStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
        itemContainer: PropTypes.oneOfType([PropTypes.object, PropTypes.number])
    };

    static defaultProps = {
        checkboxType: Checkbox.TYPES.rounded,
        error: undefined,
        options: [],
        toggleSelectedValue: null,
        isSelected: null,
        horizontalButtons: false,
        className: null,
        hasContainerStyles: true,
        showSelectedTitle: true,
        titleStyle: null,
        itemContainer: null
    };

    get errorComponent() {
        return this.props.error ? <span className={css(styles.errorText)}>{this.props.error}</span> : null;
    }

    get renderButtonsVertical() {
        const { toggleSelectedValue, isSelected, options, checkboxType, className } = this.props;
        return (
            <Grid className={className}>
                {this.errorComponent}
                <MUIList>
                    {options.map((option, i) => {
                        const listItemVerticalStyle = css(styles.buttonContainer, isSelected(option) && layoutStyle.bgSecondary);
                        const labelButtonStyle = isSelected(option) ? css(styles.btnRadioSelectedLabel) : css(styles.btnRadioLabel, layoutStyle.colorBlack);
                        return (
                            // eslint-disable-next-line react/no-array-index-key
                            <React.Fragment key={i.toString()}>
                                <MUIListItem
                                    dense={true}
                                    className={listItemVerticalStyle}
                                    onClick={() => toggleSelectedValue(option)}
                                    tabIndex={checkboxType === Core.Checkbox.TYPES.simple ? '0' : '-1'}>
                                    <MUIListItemText
                                        disableTypography={true}
                                        secondary={<p className={classnames(labelButtonStyle, css(styles.buttonTitle))}>{option.label}</p>}
                                    />
                                    <MUIListItemSecondaryAction className={css(styles.buttonTypeIcon)}>
                                        <Checkbox
                                            id={this.createItemId(i)}
                                            type={checkboxType}
                                            checked={isSelected(option)}
                                            onChange={() => toggleSelectedValue(option)}
                                            onKeyDown={onKeyPress.enter(() => toggleSelectedValue(option))}
                                            className={css(styles.labelStyle)}
                                            inputProps={{ 'aria-label': option.label }}
                                        />
                                    </MUIListItemSecondaryAction>
                                </MUIListItem>
                            </React.Fragment>
                        );
                    }
                    )}
                </MUIList>
            </Grid>
        );
    }

    createItemId = index => `selectItem-${index + 1}`;

    itemContent(index) {
        const { toggleSelectedValue, isSelected, options, checkboxType, hasContainerStyles, showSelectedTitle, titleStyle, itemContainer } = this.props;
        const option = options[index];
        const firstButtonStyles = index === 0 ?
            css(styles.btn, styles.btnRadioSelected, styles.firstButton)
            :
            css(styles.btn, styles.btnRadioSelected);
        const lastButtonStyles = index === 0 ?
            css(styles.btn, styles.btnRadio, styles.firstButton)
            :
            css(styles.btn, styles.btnRadio);
        const listItemHorizontalStyle = isSelected(option) ? firstButtonStyles : lastButtonStyles;
        const labelButtonStyle = isSelected(option) ? css(styles.btnRadioSelectedLabel) : css(styles.btnRadioLabel);
        const labelStyle = isSelected(option) && showSelectedTitle ? css(styles.titleSelected) : css(styles.title, titleStyle && titleStyle);
        const listItemClassName = this.props.horizontalButtons ? listItemHorizontalStyle : css(styles.listItemContainer, itemContainer && itemContainer);
        return (
            <React.Fragment key={index.toString()}>
                <MUIListItem
                    button={true}
                    dense={true}
                    className={hasContainerStyles ? listItemClassName : null}
                    onClick={() => toggleSelectedValue(option)}
                    ContainerComponent="li"
                    tabIndex={checkboxType === Core.Checkbox.TYPES.simple ? '0' : '-1'}>
                    <MUIListItemText
                        disableTypography={true}
                        classes={this.props.horizontalButtons && { root: css(styles.listItemTextHorizontal) }}
                        primary={
                            <p className={css(styles.listItemHeadingContainerStyle)}>{option.headTitle}</p>
                        }
                        secondary={
                            <p className={this.props.horizontalButtons ? labelButtonStyle : labelStyle}>{option.label}</p>
                        }>
                        {option.label}
                    </MUIListItemText>
                    {!this.props.horizontalButtons && (
                        <MUIListItemSecondaryAction className={css(styles.secondaryAction)}>
                            <Checkbox
                                id={this.createItemId(index)}
                                checkBoxClassName={css(styles.checkBoxPadding)}
                                type={checkboxType}
                                checked={isSelected(option)}
                                onChange={() => toggleSelectedValue(option)}
                                className={css(styles.labelStyle)}
                                color={isSelected(option) ? baseColors.secondary : baseColors.grey50}
                                onKeyDown={onKeyPress.enter(() => toggleSelectedValue(option))}
                                inputProps={{ 'aria-label': option.label }}
                            />
                        </MUIListItemSecondaryAction>
                    )}
                </MUIListItem>
                {!this.props.horizontalButtons && <Divider />}
            </React.Fragment>
        );
    }

    render() {
        const { options, checkboxType, className } = this.props;
        const isButtonType = checkboxType === Checkbox.TYPES.button;
        return isButtonType ? this.renderButtonsVertical : (
            <Grid className={classnames(this.props.horizontalButtons && css(styles.grid), className)}>
                {this.errorComponent}
                {options.length > 200 && !isIE ? (
                    <Virtuoso
                        style={{ height: windowSize.height }}
                        totalCount={options.length}
                        itemContent={index => this.itemContent(index)}
                    />

                ) : (
                    <MUIList
                        className={this.props.horizontalButtons && css(styles.horizontal)}>
                        {options.map((option, i) => this.itemContent(i))}
                    </MUIList>
                )}
            </Grid>
        );
    }
}

const styles = StyleSheet.create({
    errorText: {
        ...appFonts.xsRegular,
        marginTop: spacing.s0,
        marginBottom: spacing.s0,
        marginLeft: 0,
        paddingLeft: 0,
        color: baseColors.dangerDarker,
        textAlign: 'center'
    },
    listItemContainer: importantClass({
        paddingHorizontal: spacing.s1,
        paddingTop: spacing.s3,
        paddingBottom: spacing.s3,
        paddingLeft: spacing.s1,
    }),
    title: {
        ...appFonts.lgRegular,
        marginLeft: 0,
        color: baseColors.black
    },
    titleSelected: {
        ...appFonts.lgRegular,
        marginLeft: 0,
        color: baseColors.secondary
    },
    secondaryAction: {
        padding: 0
    },
    labelStyle: importantClass({
        width: 30,
        marginRight: 0,
        marginBottom: 0
    }),
    checkBoxPadding: importantClass({
        paddingLeft: 0
    }),
    listItemHeadingContainerStyle: {
        ...appFonts.smRegular,
        marginLeft: 0,
        color: baseColors.grey40,
        paddingTop: spacing.s1,
    },
    listItemTextHorizontal: {
        '&:first-child': {
            padding: 0,
        }
    },
    btn: importantClass({
        ...appFonts.xsBold,
        color: baseColors.secondary,
        flex: 1,
        padding: 0,
        paddingTop: spacing.s3,
        paddingBottom: spacing.s3,
        borderRadius: 0,
        borderStyle: 'solid',
        borderWidth: 1,
        marginLeft: -1,
        textAlign: 'center'
    }),
    btnRadio: importantClass({
        borderColor: baseColors.secondary,
        backgroundColor: baseColors.white,
        color: baseColors.secondary,
    }),
    btnRadioSelected: importantClass({
        borderColor: baseColors.secondary,
        backgroundColor: baseColors.secondary,
        color: baseColors.white,
    }),
    btnRadioLabel: importantClass({
        color: baseColors.secondary,
    }),
    btnRadioSelectedLabel: importantClass({
        color: baseColors.white,
    }),
    firstButton: importantClass({
        marginLeft: 0
    }),
    grid: {
        display: 'flex',
        flex: 1,
    },
    buttonContainer: importantClass({
        ...baseStyles.buttonContainer,
        borderStyle: 'solid',
        display: 'flex'
    }),
    buttonTypeIcon: importantClass({
        right: spacing.s3
    }),
    buttonTitle: {
        ...appFonts.mdMedium,
        color: baseColors.black
    },
});

export default WithMultiSelectBase(MultiSelectCheck);
