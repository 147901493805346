import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import _ from 'lodash';

import Button from '../Button';
import { baseColors, spacing, appFonts, importantStyles, layoutStyle } from '../../../../styles';
import WithMultiRowButtonsBase, { styles as baseStyles } from './MultiRowButtonsBase';

class MultiRowButtons extends Component {
    static propTypes = {
        selectedValue: PropTypes.string.isRequired,
        selectOption: PropTypes.func.isRequired,
        radioValues: PropTypes.array.isRequired
    };

    renderButtons = (metadata, index) => {
        let buttonStyle = null;
        if (index === 0) {
            buttonStyle = styles.firstButton;
        } else if (index === this.props.radioValues.length - 1) {
            buttonStyle = styles.lastButton;
        }
        const type = this.props.selectedValue === metadata.value ? 'contained' : 'outlined';
        return (
            <Button
                key={index}
                size="medium"
                className={css(styles.btn, buttonStyle)}
                type={type}
                onPress={() => this.props.selectOption(metadata)}>
                {metadata.text}
            </Button>
        );
    };

    render() {
        return (
            <div className={css(layoutStyle.flexRow)}>
                {_.map(this.props.radioValues, this.renderButtons)}
            </div>
        );
    }
}

const styles = StyleSheet.create(importantStyles({
    ...baseStyles,
    btn: {
        flex: 1,
        textAlign: 'center',
        borderRadius: 0,
        ...appFonts.xsBold,
        height: spacing.s10,
        border: `1px solid ${baseColors.secondary}`,
        boxShadow: 'none'
    },
    firstButton: {
        marginRight: spacing.s1
    },
    lastButton: {
        marginLeft: spacing.s1
    }
}));

export default WithMultiRowButtonsBase(MultiRowButtons);
