import React, { PureComponent } from 'react';
import { Prompt } from 'react-router-dom';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import _ from 'lodash';

import { components as Core, ROUTES, Storage, Platform, PLATFORMS, ENTITIES_ACTIVE_TAB } from '../../../../core';
import WithProfileEditorBase, { styles as baseStyles } from './ProfileEditorBase';
import WithUnsavedChangesWrapper from '../UnsavedChangesWrapper';
import BiometricsEditor from '../BiometricsEditor';
import HealthObjectives from '../../../../onboarding/components/HealthObjectives';
import HealthTopics from '../../../../onboarding/components/HealthTopics';
import PersonalEditor from '../PersonalEditor';
import { spacing, important } from '../../../../../styles';
import { EDITOR_TABS as TABS } from '../../../constants';

class ProfileEditor extends PureComponent {
    static propTypes = {
        hasChanges: PropTypes.bool.isRequired,
        history: PropTypes.object.isRequired,
        showAlert: PropTypes.func.isRequired,
        hasUnsavedChanges: PropTypes.func.isRequired,
        i18n: PropTypes.object.isRequired,
        currentUser: PropTypes.object.isRequired,
        isLanguageChanged: PropTypes.bool.isRequired,
        actions: PropTypes.object.isRequired,
        userEmail: PropTypes.string.isRequired,
        isBiometricsEnabled: PropTypes.bool.isRequired
    };

    constructor(props) {
        super(props);

        const allTabs = [
            { label: 'basicPhysicalDetails', id: 'personal', translate: true },
            { label: 'myWellbeingInterests', id: 'interests', translate: true },
            { label: 'my_heatlh_obj', id: 'objectives', translate: true }
        ];

        this.tabs = props.isBiometricsEnabled ? [...allTabs, { label: 'biometrics', id: 'biometrics', translate: true }] : allTabs;

        if(props.isLanguageChanged) {
            this.props.actions.getUser();
        }

        this.state = {
            activeTab: props.isLanguageChanged ? this.tabs[1] : this.tabs[0],
            confirmedNavigation: false
        };
    }

    static TABS_COMPONENTS = {
        [TABS.personal]: PersonalEditor,
        [TABS.interests]: HealthTopics,
        [TABS.objectives]: HealthObjectives,
        [TABS.biometrics]: BiometricsEditor
    };

    async componentDidMount() {
        if (Platform.OS === PLATFORMS.web) {
            const activeTab = await Storage.getItem(ENTITIES_ACTIVE_TAB.PROFILE_EDITOR);
            if (activeTab && _.find(this.tabs, tab => activeTab.id)) {
                this.setState({ activeTab });
            }
        }
    }

    discardChanges = (tab, location = null) => () => {
        this.props.hasUnsavedChanges(false);
        if (tab) this.setState({ activeTab: tab });
        else this.changeLocation(location);
    };

    saveAndContinue = (tab, location = null) => () => {
        let changesSaved = false;
        if (_.has(this.editorRef, 'props.saveChanges')) {
            changesSaved = this.editorRef.props.saveChanges();
        }
        else if (_.has(this.editorRef, 'props.submit')) {
            // location editor's function name is different
            changesSaved = this.editorRef.props.submit();
        }
        if (changesSaved && tab) this.setState({ activeTab: tab });
        else if (changesSaved && !tab) this.changeLocation(location);
    };

    onTabPress = tab => {
        const { activeTab } = this.state;
        if (!_.isEqual(activeTab, tab) && this.props.hasChanges) {
            this.props.showAlert(this.discardChanges(tab), this.saveAndContinue(tab));
        }
        else if (!_.isEqual(activeTab, tab) && !this.props.hasChanges) {
            this.setState({ activeTab: tab });

            if (Platform.OS === PLATFORMS.web) {
                Storage.setItem(ENTITIES_ACTIVE_TAB.PROFILE_EDITOR, tab);
            }
        }
    };

    handleBlockedNavigation = nextLocation => {
        const { confirmedNavigation } = this.state;
        if (!confirmedNavigation) {
            this.props.showAlert(this.discardChanges(null, nextLocation), this.saveAndContinue(null, nextLocation));
            return false;
        }
        return true;
    };

    changeLocation = location => {
        if (location && location.pathname) {
            this.setState({ confirmedNavigation: true }, () => this.props.history.push(location.pathname));
        } else this.props.history.replace(ROUTES.me());
    };

    onRef = r => this.editorRef = r;

    get component() {
        return ProfileEditor.TABS_COMPONENTS[this.state.activeTab.id];
    }

    goBack = () => this.props.history.goBack();

    render() {
        const { i18n } = this.props;
        return (
            <Core.Layout.WiderContainer>
                <Core.EntityDetailsHeader
                    hasBackButton={true}
                    backUrl={this.goBack}
                />
                <Core.SubHeader
                    title={i18n.t('myWellbeingProfile')}
                    noTopPadding={true}
                    className={css(styles.editProfileSubheader)}>
                </Core.SubHeader>
                <Core.TabsWithContent
                    barStyles={styles.stickyBar}
                    onChange={this.onTabPress}
                    tabs={this.tabs}
                    activeTab={this.state.activeTab}
                    isStickyUnderNavBar={true}>
                    <div className={css(styles.tabContentContainer)}>
                        <this.component onRef={this.onRef} hasUnsavedChanges={this.props.hasUnsavedChanges} />
                    </div>
                </Core.TabsWithContent>
                <Prompt
                    key="block-nav"
                    when={this.props.hasChanges}
                    message={this.handleBlockedNavigation}
                />
            </Core.Layout.WiderContainer>
        );
    }
}

const styles = StyleSheet.create({
    ...baseStyles,
    tabContentContainer: {
        marginBottom: spacing.s7
    },
    editProfileSubheader: {
        marginTop: spacing.s2,
        marginBottom: important(spacing.s7),
        height: important('auto'),
        minHeight: important('auto'),
    },
});

export default WithUnsavedChangesWrapper(WithProfileEditorBase(ProfileEditor));
