import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { appFonts, exploreImageHeight, spacing } from '../../../../../styles';
import { translate } from '../../../../core';
import { daysLeft, getImage, isInGracePeriod } from '../../../services/helper';
import * as selectors from '../../../selectors';
import * as actions from '../../../actions';
import { selectors as onboardingSelectors } from '../../../../onboarding';

export const INSTRUCTION_LINES = 4;

export default function WithGoalInfoBase(WrappedComponent) {
    class GoalInfoBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            challengeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            challenge: PropTypes.object.isRequired,
            headerIcon: PropTypes.object.isRequired
        };

        static defaultProps = {};

        get image() {
            return getImage(this.props.challenge);
        }

        get daysLeft() {
            return daysLeft(this.props.challenge.challengeDeadline);
        }

        get subtitle() {
            const { i18n, challenge } = this.props;
            return !isInGracePeriod(challenge) && this.daysLeft >= 0
                ? `${i18n.t('ends_in')} ${i18n.t('number_days', { num: this.daysLeft })}`
                : '';
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    subtitle={this.subtitle}
                    image={this.image}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const challengeId = _.get(ownProps, 'challengeId')
            || _.get(ownProps, 'match.params.challengeId')
            || _.get(ownProps, 'route.params.challengeId');
        const onPress = _.get(ownProps, 'onPress') || _.get(ownProps, 'match.params.onPress') || _.get(ownProps, 'route.params.onPress');
        return {
            challenge: selectors.getChallenge(state, challengeId),
            headerIcon: selectors.getHeaderIcon(state, challengeId),
            isOnboarding: onboardingSelectors.isShowingOnboarding(state),
            onPress,
            challengeId
        };
    };

    const mapDispatchToProps = dispatch => ({
        actions: bindActionCreators(actions, dispatch)
    });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(GoalInfoBase));
}

export const styles = {
    image: {
        height: exploreImageHeight,
    },
    cardDarkBodyTop: {
        paddingTop: spacing.s0,
        paddingBottom: spacing.s1,
    },
    cardDarkHeadingSub: {
        ...appFonts.smRegular
    },
};
