import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite-jss';
import { importantStyles, baseColors, spacing } from '../../../../styles';
import { components as Core, Modal } from '../../../core';
import WithEventBonusBase, { styles as baseStyles, ICON_NAME, ICON_SIZE, INFO_ICON_SIZE } from './EventBonusBase';

class EventBonus extends PureComponent {
    static propTypes = {
        hasReward: PropTypes.bool,
        isCompleted: PropTypes.bool.isRequired,
        bonusLabel: PropTypes.string.isRequired,
        showInfoButton: PropTypes.bool,
        getInfoModalProps: PropTypes.func.isRequired,
    };

    static defaultProps = {
        hasReward: false,
        showInfoButton: false
    };

    get checkIconSection() {
        const { isCompleted } = this.props;
        return isCompleted ? (
            <Core.Icon type="regular" name={ICON_NAME} size={ICON_SIZE} color={baseColors.success} />
        ) : null;
    }

    get infoSection() {
        const { showInfoButton, isCompleted } = this.props;
        return showInfoButton && !isCompleted ? (
            <Core.IconButton
                onClick={this.showMoreInfo}
                isSmall={true}
                name="question-circle"
                size={INFO_ICON_SIZE}
                type="fa"
                fill="solid"
                color={baseColors.grey50}
            />
        ) : null;
    }

    showMoreInfo = event => {
        event.stopPropagation();
        const infoModalProps = this.props.getInfoModalProps();
        this.closeInfoModal = Modal.open(
            Core.InfoModal,
            {
                ...infoModalProps,
                buttons: [{ text: infoModalProps.buttonTitle, onPress: () => this.closeInfoModal() }],
                textStyle: styles.additionalInfo,
                iconClassName: css(styles.iconClassName),
            },
            {
                isContainer: true,
                isTransparent: true,
                isNoPadding: true,
                fadeTransition: true,
                isMaxWidthLimited: true,
            }
        );
    }

    render() {
        const { hasReward, bonusLabel } = this.props;
        return (
            hasReward ? (
                <div className={classNames(css(styles.bonusWrapper))}>
                    <div className={classNames(css(styles.bonusText, styles.bonusBg))}>
                        {bonusLabel}
                    </div>
                    {this.checkIconSection}
                    {this.infoSection}
                </div>
            ) : null
        );
    }
}

export default WithEventBonusBase(EventBonus);

const styles = StyleSheet.create(importantStyles({
    ...baseStyles,
    iconClassName: {
        backgroundColor: baseColors.primary
    },
    bonusBg: {
        ...baseStyles.bonusBg,
        paddingLeft: spacing.s1
    }
}));
