import _ from 'lodash';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate, actions as coreActions, selectors as coreSelectors, constants as coreConstants } from '../../../core';
import * as eventsActions from '../../actions';
import * as selectors from '../../selectors';
import * as constants from '../../constants';
import { checkForErrors } from '../../services/createEventHelper';
import { appFonts, spacing } from '../../../../styles';

export const EVENT_FIELDS = {
    eventName: 'event_name',
    location: 'location',
    whatEvent: 'what_event'
};

export default function WithCreateEventBase(WrappedComponent) {
    class CreateEventBase extends PureComponent {
        static propTypes = {
            event: PropTypes.object,
            actions: PropTypes.object,
            locationsList: PropTypes.array,
            departments: PropTypes.array,
            id: PropTypes.number,
            i18n: PropTypes.object.isRequired,
            isLoading: PropTypes.bool,
            isEditing: PropTypes.bool,
            isUserRole: PropTypes.bool,
            entityType: PropTypes.string,
            isAdminCreated: PropTypes.bool
        };

        static defaultProps = {
            id: null,
            locationsList: [],
            departments: [],
            event: {},
            actions: {},
            isLoading: false,
            isEditing: false,
            isUserRole: true,
            entityType: null,
            isAdminCreated: false
        };

        constructor(props) {
            super(props);
            this.state = {
                name: this.item.eventName || '',
                picture: this.item.eventImageURL || null,
                privacy: this.privacy,
                moderatorData: {
                    maxParticipants: this.item.eventMaxParticipants || '',
                    type: constants.EVENT_TYPES.user,
                    associatedLocation: constants.ALL_ID,
                    locations: [],
                    department: constants.ALL_ID,
                    points: this.item.eventPoints || '',
                    cost: '',
                },
                startDate: this.startDate,
                startTime: this.startTime,
                endTime: this.endTime,
                location: this.item.eventAddress || '',
                description: this.item.eventDescription || '',
                errors: {
                    errorName: '',
                    errorMaxParticipants: '',
                    errorPoints: '',
                    errorCost: '',
                    errorStartDate: '',
                    errorStartTime: '',
                    errorEndTime: '',
                    errorLocations: ''
                },
                edited: false
            };
            this.startPickersData = {
                eventHandler: this.onDateChange(constants.CREATE_INPUTS.startDate),
                fieldNameDate: this.props.i18n.t('start_date'),
            };
            this.startPickersTimeData = {
                eventHandler: this.onDateChange(constants.CREATE_INPUTS.startTime),
                fieldNameTime: this.props.i18n.t('start_time')
            };
            this.endPickersTimeData = {
                eventHandler: this.onDateChange(constants.CREATE_INPUTS.endTime),
                fieldNameTime: this.props.i18n.t('end_time')
            };
        }

        get item() {
            return this.props.event;
        }

        get privacy() {
            return this.item && !!this.item.isPrivate || !this.isEditing
                ? constants.PRIVACY_FIELDS.private
                : constants.PRIVACY_FIELDS.public;
        }

        get startDate() {
            return moment(this.item.eventDateTime).toDate() || moment().toDate();
        }

        get startTime() {
            return moment(this.item.eventDateTime).toDate() || moment(this.item.eventEndDateTime).toDate();
        }

        get endTime() {
            return moment(this.item.eventEndDateTime).toDate() || moment(this.item.eventDateTime).toDate();
        }

        get isEditing() {
            return this.props.id;
        }

        get title() {
            return this.isEditing
                ? this.props.i18n.t('edit_event')
                : this.props.i18n.t('create_event');
        }

        get buttonLabel() {
            return this.isEditing
                ? this.props.i18n.t('save')
                : this.props.i18n.t('create');
        }

        onUploadPhoto = (picture, pictureFull) => {
            this.setState({ picture, pictureFull, edited: true });
        };

        onModeratorSelectChange = name => data => {
            if (name === constants.CREATE_INPUTS.associatedLocation && this.isSelectedLocations) {
                this.setState(oldState => ({
                    moderatorData: {
                        ...oldState.moderatorData,
                        [name]: data.id,
                        locations: []
                    },
                    errors: {
                        ...oldState.errors,
                        errorLocations: ''
                    },
                    edited: true
                }));
            } else {
                this.setState(oldState => ({
                    moderatorData: {
                        ...oldState.moderatorData,
                        [name]: data.id
                    },
                    edited: true
                }));
            }
        };

        onLocationsSelect = locations => {
            this.setState(oldState => ({
                moderatorData: {
                    ...oldState.moderatorData,
                    locations
                },
                edited: true
            }));
        };

        onModeratorTextChange = field => text => {
            this.setState(oldState => ({
                moderatorData: {
                    ...oldState.moderatorData,
                    [field]: text
                },
                edited: true
            }));
        };

        onChangeText = field => text => this.setState({ [field]: text, edited: true });

        removeLocation = id => () => {
            const locations = _.filter(this.state.moderatorData.locations, l => l.id !== id);
            this.setState(oldState => ({ moderatorData: { ...oldState.moderatorData, locations, edited: true } }));
        };

        getSelectedValue = (arr, value) => _.find(arr, item => item.id === value);

        get isSelectedLocations() {
            return this.state.moderatorData.associatedLocation !== constants.ALL_ID;
        }

        makePublic = () => {
            this.setState(state => ({ privacy: state.privacy === 1 ? 0 : 1, edited: true }));
        };

        get moderatorFieldsData() {
            const { moderatorData, errors } = this.state;
            return ({
                ...moderatorData,
                errors,
                isEditing: this.isEditing,
                onLocationsSelect: this.onLocationsSelect,
                removeLocation: this.removeLocation,
                onSelectChange: this.onModeratorSelectChange,
                getSelectedValue: this.getSelectedValue,
                isSelectedLocations: this.isSelectedLocations,
                onChangeText: this.onModeratorTextChange
            });
        }

        submitData = () => {
            const { name, startDate, startTime, endTime, picture, description } = this.state;
            const { actions, isUserRole } = this.props;
            const errors = checkForErrors({
                ...this.state.moderatorData,
                isUserRole,
                name,
                startDate,
                startTime,
                endTime,
                isSelectedLocations: this.isSelectedLocations
            });
            if (!_.isEmpty(errors)) {
                this.setErrorString(errors);
            }
            else if (!this.isEndTimeLaterStartTime) {
                this.showToast(this.props.i18n.t('errorEntityTimeMessage'));
            } else {
                const locations = _.map(this.state.moderatorData.locations, l => l.id);
                const department = this.state.moderatorData.department !== constants.ALL_ID ? this.state.moderatorData.department : null;
                const moderatorData = !isUserRole
                    ? {
                        ..._.omit(this.state.moderatorData, 'associatedLocation'),
                        locations,
                        department
                    } : null;
                const data = {
                    name,
                    start_date: moment(startDate).format(coreConstants.DATE_FORMATS.full),
                    end_date: moment(startDate).format(coreConstants.DATE_FORMATS.full),
                    start_time: moment(startTime).format(coreConstants.DATE_FORMATS.twentyFourHoursTime),
                    end_time: moment(endTime).format(coreConstants.DATE_FORMATS.twentyFourHoursTime),
                    description,
                    location_address: this.state.location,
                    privacy: this.state.privacy === constants.PRIVACY_FIELDS.public ? 0 : 1,
                    ...moderatorData
                };

                if (this.state.pictureFull) {
                    data.picture = picture;
                } else if (this.state.pictureFull === null) {
                    data.picture = null;
                }

                if (this.isEditing) {
                    actions.editEvent({ eventId: this.props.id, data });
                } else {
                    actions.createEvent(data);
                }
            }
        };

        get isEndTimeLaterStartTime() {
            return moment(this.state.endTime).isSameOrAfter(this.state.startTime);
        }

        showToast = message => {
            const { i18n, actions } = this.props;
            const title = this.isEditing ? i18n.t('discardEditedEventTitle') : i18n.t('discardCreatingEventTitle');
            actions.addToast(coreConstants.TOAST_TYPES.INFO, '', message || i18n.t('discardCreatingEventMessage'), title);
        };

        setErrorString = errors => {
            const emptyErrors = {
                errorName: '',
                errorMaxParticipants: '',
                errorPoints: '',
                errorCost: '',
                errorStartDate: '',
                errorStartTime: '',
                errorEndTime: '',
                errorLocations: ''
            };
            this.setState(() => ({ errors: { ...emptyErrors, ...errors } }), this.showToast);
        };

        resetDateTime = date => moment(date)
            .hours(0)
            .minutes(0)
            .milliseconds(0)
            .toDate();

        /**
         *  The problem if I want to  create challenge at 1 november 19:00
         *  and change the start date to the 6 november
         *  I cannot choose start time before 19:00
         *  So added resetDateTime func which reset time of some date to the 00:00:00
         *  */
        onDateChange = timePeriod => correctDate => {
            if (timePeriod === constants.CREATE_INPUTS.startDate) {
                const updatedDateTime = this.resetDateTime(correctDate);
                this.setState(oldState => ({
                    [constants.CREATE_INPUTS.startDate]: updatedDateTime,
                    [constants.CREATE_INPUTS.startTime]: updatedDateTime,
                    [constants.CREATE_INPUTS.endTime]: updatedDateTime,
                    errors: {
                        ...oldState.errors,
                        errorStartTime: '',
                        errorEndTime: ''
                    },
                    edited: true
                }));
            } else {
                this.setState(oldState => ({
                    [timePeriod]: correctDate,
                    errors: {
                        ...oldState.errors,
                        errorStartTime: '',
                        errorEndTime: ''
                    },
                    edited: true
                }));
            }
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    {...this.state}
                    title={this.title}
                    buttonLabel={this.buttonLabel}
                    makePublic={this.makePublic}
                    moderatorFieldsData={this.moderatorFieldsData}
                    startPickersData={this.startPickersData}
                    startPickersTimeData={this.startPickersTimeData}
                    endPickersTimeData={this.endPickersTimeData}
                    onLocationsSelect={this.onLocationsSelect}
                    formDataImage={this.state.pictureFull || this.state.picture}
                    startDate={this.startDate}
                    onChangeText={this.onChangeText}
                    submitData={this.submitData}
                    onUploadPhoto={this.onUploadPhoto}
                    startPickersValue={this.state.startDate}
                    startPickersTimeValue={this.state.startTime}
                    endPickersTimeValue={this.state.endTime}
                    entityType={coreConstants.ENTITIES.event}
                    hasDeleteOption={!this.isEditing}
                    isEditingEvent={!!this.isEditing}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        const id = ownProps.id || _.get(ownProps, 'match.params.id') || _.get(ownProps, 'route.params.id');
        const isAdminCreated = ownProps.isAdminCreated || _.get(ownProps, 'match.params.isAdminCreated') || _.get(ownProps, 'route.params.isAdminCreated');
        const type = _.get(ownProps, 'route.params.selectedTab');
        return {
            id,
            event: selectors.getEvent(state, id),
            isCreating: selectors.isCreating(state),
            isLoading: selectors.isEditing(state) || selectors.isCreating(state),
            isEditing: selectors.isEditing(state),
            createdEventId: selectors.getLastCreatedEventId(state),
            isUserRole: coreSelectors.isUserRole(state),
            currentUserId: coreSelectors.getCurrentUserId(state),
            isAdminCreated,
            type
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators({ ...eventsActions, ...coreActions }, dispatch),
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(CreateEventBase));
}

export const styles = {
    input: {
        paddingBottom: spacing.s3
    },
    descriptionInput: {
        paddingBottom: spacing.s0
    },
    datePickerWrapper: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    },
    datePickerWide: {
        width: '100%'
    },
    title: {
        ...appFonts.lgMedium,
        marginBottom: spacing.s0,
        width: '100%'
    },
    inputHeight: {
        height: constants.CREATE_EVENT_TEXTAREA_HEIGHT
    },
    selectField: {
        marginBottom: spacing.s5
    }
};

