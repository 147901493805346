import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';
import { components as Core } from '../../../../core';
import WithRestartButtonBase, { styles as baseStyles } from './RestartButtonBase';

class RestartButton extends PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        restartPersonalGoal: PropTypes.func.isRequired,
        isLoading: PropTypes.bool,
    };

    static defaultProps = {
        isLoading: false
    };

    render() {
        return (
            <div className={css(styles.cardBodyButton)}>
                <Core.Button
                    fullWidth={true}
                    isLoading={this.props.isLoading}
                    disabled={this.props.isLoading}
                    onPress={this.props.restartPersonalGoal}>
                    {this.props.i18n.t('restartPersonalGoal')}
                </Core.Button>
            </div>
        );
    }
}

export default WithRestartButtonBase(RestartButton);

const styles = StyleSheet.create({
    ...baseStyles
});
