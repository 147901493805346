const fontSize = {
    xxSmall: 10,
    xSmall: 12,
    small: 14,
    medium: 16,
    large: 18,
    xLarge: 24,
    xxLarge: 28,
    xxxLarge: 32,
    xxxxLarge: 44,
};

export default fontSize;
