import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { withRouter } from 'react-router-dom';
import * as Card from '../Card';
import { appFonts, layoutStyle, baseColors, spacing, importantClass } from '../../../../styles';
import { components as Core, ROUTES } from '../../../core';
import WithRecommendedGoalsCardBase, { styles as baseStyles } from './RecommendedGoalsCardBase';
import GoalIconsSection from '../GoalIconsSection';
import { checkItemBounds } from '../../services/helper';

class RecommendedGoalsCard extends PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
        i18n: PropTypes.object.isRequired,
        description: PropTypes.string.isRequired,
        onActionPress: PropTypes.func.isRequired,
        onBodyPress: PropTypes.func.isRequired,
        isSingle: PropTypes.bool,
    };

    static defaultProps = {
        isSingle: false,
    };

    constructor(props) {
        super(props);
        this.childRef = React.createRef();
    }

    goToPersonalGoal = e => {
        e.stopPropagation();
        this.props.onActionPress();
        this.props.history.push(ROUTES.setPersonalGoal());
    };

    render() {
        const { onBodyPress, isSingle, description, i18n, title, isEmptyState } = this.props;
        const tabIndexValue = checkItemBounds(this.props.carouselRef, this.childRef);

        return (
            <div ref={this.childRef}>
                <Card.Container
                    hasPointer={false}
                    onPress={onBodyPress}
                    className={css(styles.cardContainer)}
                    containerClassName={css(isSingle && styles.cardHeight)}
                    tabIndex="-1">
                    <Card.Body isGrey={false} className={css(layoutStyle.flexColumnCenter, layoutStyle.flex1)}>
                        {isEmptyState ? (
                            <Core.Icon
                                type="fa"
                                name="bullseye-arrow"
                                color={baseColors.grey70}
                                size={spacing.s7}
                                containerClassName={styles.iconWrapper}
                            />
                        )
                            : <GoalIconsSection className={css(styles.iconContainer)} iconClassName={css(styles.icon)} iconSize={spacing.s7} />

                        }
                        <div className={css(styles.contentMaxWidth)}>
                            {!isEmptyState && <p className={css(styles.title)}>{title}</p>}
                            <p className={css(styles.descriptionWeb)}>{description}</p>
                        </div>
                        <Core.Button
                            type="outlined"
                            size="medium"
                            onPress={this.goToPersonalGoal}
                            tabIndex={tabIndexValue}>
                            {i18n.t('personal_goal.carousel.action_text')}
                        </Core.Button>
                    </Card.Body>
                </Card.Container>
            </div>
        );
    }
}

export default withRouter(WithRecommendedGoalsCardBase(RecommendedGoalsCard));

const CONTENT_MAX_WIDTH = 540;

const styles = StyleSheet.create({
    ...baseStyles,
    descriptionWeb: {
        ...appFonts.smRegular,
        color: baseColors.grey20,
        textAlign: 'center',
        maxWidth: '100%',
        marginBottom: spacing.s2,
        marginTop: spacing.s2,
        marginRight: spacing.s4,
        marginLeft: spacing.s4
    },
    cardHeight: importantClass({
        height: 'auto',
    }),
    cardContainer: importantClass({
        boxShadow: 'none',
        paddingTop: spacing.s7,
        paddingBottom: spacing.s7,
        backgroundColor: baseColors.grey90,
    }),
    contentMaxWidth: {
        width: '100%',
        maxWidth: CONTENT_MAX_WIDTH,
    },
    iconWrapper: {
        alignSelf: 'center'
    },
    icon: {
        marginLeft: spacing.s0,
        marginRight: spacing.s0,
    }
});
