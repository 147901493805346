import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { AsyncComponent, ROUTES, components as Core } from '../../../core';
import { CREATE_CHALLENGE_STEPS as STEPS, CREATE_CHALLENGE_ITEM_IDS as ITEM_IDS } from '../../constants';
import WithCreateChallengeBase from './CreateChallengeBase';

const SHOW_NEXT_BUTTON = [ITEM_IDS.categoryQuantity, ITEM_IDS.date, ITEM_IDS.image, ITEM_IDS.activities,
    ITEM_IDS.teamSize, ITEM_IDS.region, ITEM_IDS.department];
const SHOW_CREATE_BUTTON = [ITEM_IDS.finalize];

class CreateChallenge extends PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
        localStep: PropTypes.string,
        propsToPass: PropTypes.object.isRequired,
        localSteps: PropTypes.array.isRequired,
        updateLocalRegistrationStep: PropTypes.func.isRequired,
        lastCreatedChallengeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        title: PropTypes.string,
        close: PropTypes.func.isRequired,
        discard: PropTypes.func.isRequired,
        i18n: PropTypes.object.isRequired,
        step: PropTypes.string,
        clear: PropTypes.func.isRequired,
        newChallenge: PropTypes.object.isRequired
    };

    static defaultProps = {
        localStep: STEPS.frequency,
        lastCreatedChallengeId: undefined,
        title: undefined,
        step: STEPS.personalGoal,
    };

    static COMPONENTS = {
        [STEPS.personalGoal]: AsyncComponent(() => import('./CreateChallengePersonalGoal')),
        [STEPS.teamSize]: AsyncComponent(() => import('./CreateChallengeTeamSize')),
        [STEPS.type]: AsyncComponent(() => import('./CreateChallengeType')),
        [STEPS.frequency]: AsyncComponent(() => import('./CreateChallengeFrequency')),
        [STEPS.unitType]: AsyncComponent(() => import('./CreateChallengeUnitType')),
        [STEPS.category]: AsyncComponent(() => import('./CreateChallengeCategory')),
        [STEPS.activities]: AsyncComponent(() => import('./CreateChallengeActivities')),
        [STEPS.categoryQuantity]: AsyncComponent(() => import('./CreateChallengeCategoryQuantity')),
        [STEPS.date]: AsyncComponent(() => import('./CreateChallengeStartDateAndDuration')),
        [STEPS.image]: AsyncComponent(() => import('./CreateChallengeImage')),
        [STEPS.finalize]: AsyncComponent(() => import('./CreateChallengeFinalize')),
        [STEPS.additional]: AsyncComponent(() => import('./CreateChallengeAdditional')),
        [STEPS.department]: AsyncComponent(() => import('./CreateChallengeInvite')),
        [STEPS.region]: AsyncComponent(() => import('./CreateChallengeInvite')),
    };

    get cancelModalData() {
        const { i18n, newChallenge: { solo } } = this.props;
        const actionType = i18n.t('createEntity.action.creating');
        const entity = solo === 1 ? i18n.t('createEntity.type.goal') : i18n.t('createEntity.type.challenge');
        return {
            title: i18n.t('createEntity.cancel.title', { entity, actionType }),
            text: i18n.t('createEntity.cancel.message', { entity, actionType })
        };
    }

    get componentType() {
        return this.props.localStep;
    }

    // from parent
    openNextPage = screenConfig => {
        if (!CreateChallenge.COMPONENTS[screenConfig.step]) this._redirect();
    };

    onGoBack = () => {
        const { localSteps, localStep, updateLocalRegistrationStep } = this.props;
        if (!localStep || !_.includes(localSteps, localStep)) return;
        const prevStep = localSteps[_.indexOf(localSteps, localStep) - 1];
        if (prevStep) updateLocalRegistrationStep(prevStep);
    }

    // from parent
    discard = () => { if (this.props.close) this.props.close(); };

    // from parent
    finish = () => {
        if (this.props.close) this.props.close();
        this.props.history.push(ROUTES.challengeDetails(this.props.lastCreatedChallengeId));
    }

    cancelFlow = () => this.props.discard();

    get component() {
        return _.get(CreateChallenge.COMPONENTS, this.componentType);
    }

    renderStep = props => {
        const LocalComponent = this.component;
        return (<LocalComponent
            step={this.componentType}
            {...props}
        />);
    }

    render() {
        const { propsToPass, close, title } = this.props;
        return (
            <Core.Steps
                propsToPass={{ ...propsToPass, onClose: close }}
                componentType={this.componentType}
                cancelFlow={this.cancelFlow}
                title={title}
                isCloseButtonShown={true}
                goBack={this.onGoBack}
                showNextScreens={SHOW_NEXT_BUTTON}
                showCreateScreens={SHOW_CREATE_BUTTON}
                cancelModalData={this.cancelModalData}
                modalWithScrollbar={true}
            >
                {this.renderStep}
            </Core.Steps>
        );
    }
}

export default withRouter(WithCreateChallengeBase(CreateChallenge));
