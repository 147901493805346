import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { StyleSheet, css } from 'aphrodite-jss';
import { spacing, baseColors, layoutStyle, importantStyles } from '../../../../styles';
import { components as Core, constants as coreConstants } from '../../../core';
import WithVitalStatsBase, { styles as baseStyles, ICON_SIZE, NUMBER_INPUT_MAX_LENGTH } from './VitalStatsBase';
import * as constants from '../../constants';

const NUMERIC_FILEDS = [
    constants.VITAL_STATS.heightValue,
    constants.VITAL_STATS.feetValue,
    constants.VITAL_STATS.weightValue,
];

class VitalStats extends PureComponent {
    static propTypes = {
        question: PropTypes.object.isRequired,
        submitAnswers: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        onGenderChange: PropTypes.func.isRequired,
        onDateChange: PropTypes.func.isRequired,
        onHeightUnit: PropTypes.func.isRequired,
        onWeightUnit: PropTypes.func.isRequired,
        isSaving: PropTypes.bool,
        genderError: PropTypes.string,
        isModal: PropTypes.bool,
        dobEnabled: PropTypes.bool,
        isPrivacyMessageEnabled: PropTypes.bool,
        getRequiredFieldName: PropTypes.func.isRequired,
        errors: PropTypes.object.isRequired,
        gender: PropTypes.string.isRequired,
        userPreferredHeightUnit: PropTypes.string,
        userPreferredWeightUnit: PropTypes.string,
        weightValue: PropTypes.string.isRequired,
        feetValue: PropTypes.string.isRequired,
        heightValue: PropTypes.string.isRequired,
        dateOfBirth: PropTypes.instanceOf(Date).isRequired,
        i18n: PropTypes.object.isRequired
    };

    static defaultProps = {
        dobEnabled: true,
        isModal: false,
        isPrivacyMessageEnabled: false,
        userPreferredHeightUnit: '',
        isSaving: false,
        genderError: null,
        userPreferredWeightUnit: '',
    };

    onChange = prop => event => {
        const { value } = event.target;
        const text = value && _.includes(NUMERIC_FILEDS, prop) ? parseFloat(value) : value;
        this.props.onChange(prop)(text);
    };

    get genderQuestion() {
        const { onGenderChange, gender, i18n } = this.props;
        const selected = _.find(constants.GENDER_VALUES(), ['id', gender]);
        return (
            <div>
                <Core.SelectField
                    id="genderSelectField"
                    error={this.props.genderError}
                    options={constants.GENDER_VALUES()}
                    message="choose_gender"
                    fieldName={i18n.t('Gender')}
                    selected={selected}
                    onChange={onGenderChange}
                    checkboxType={Core.Checkbox.TYPES.simple}
                    translateOptions={true}
                />
            </div>
        );
    }

    get DOBQuestion() {
        const { dateOfBirth, onDateChange, dobEnabled, question, errors } = this.props;
        return (
            <Core.DateField
                id="dobHealthSurvey"
                value={dateOfBirth}
                onChange={onDateChange}
                error={!!errors.dateOfBirth}
                helperText={errors.dateOfBirth}
                dateFormat={coreConstants.DATE_FORMATS.fullSlash}
                label={this.props.getRequiredFieldName('date_of_birth', question.dateOfBirth)}
                fullWidth={true}
                disabled={!dobEnabled}
                type={coreConstants.DATE_FIELD_TYPE.text}
                placeholder="yyyy/mm/dd"
            />
        );
    }

    get heightQuestion() {
        const { userPreferredHeightUnit, feetValue, heightValue, question, onHeightUnit, errors, i18n } = this.props;
        const showTwoFields = userPreferredHeightUnit !== constants.CM;
        const inchesOrCm = (userPreferredHeightUnit === constants.INCHES) ? constants.IN : userPreferredHeightUnit;
        const fieldName = showTwoFields ? 'onboarding.vital_stats.height_in' : 'height';

        const leftInput = { root: css(layoutStyle.twoButtonsLeft) };
        const rightInput = { root: css(layoutStyle.flex1) };

        return (
            <div className={css(layoutStyle.flex)}>
                {showTwoFields ? (
                    <Core.UnitField
                        id={_.camelCase(`height ${constants.FT}Input`)}
                        classes={leftInput}
                        unitOptions={_.map(constants.UNIT_OPTIONS.HEIGHT_FT, unit => unit)}
                        unit={i18n.t(constants.FT)}
                        label={this.props.getRequiredFieldName('onboarding.vital_stats.height_ft', question.heightInches)}
                        onChange={this.onChange(constants.VITAL_STATS.feetValue)}
                        value={feetValue ? feetValue.toString() : ''}
                        helperText={errors.height}
                        error={!!errors.height}
                        onUnitChange={onHeightUnit}
                        inputProps={{ maxLength: NUMBER_INPUT_MAX_LENGTH }}
                    />
                ) : null}
                <Core.UnitField
                    id={_.camelCase(`height ${inchesOrCm}Input`)}
                    fullWidth={true}
                    classes={rightInput}
                    unitOptions={_.map(constants.UNIT_OPTIONS.HEIGHT_IN, unit => unit)}
                    unit={i18n.t(inchesOrCm)}
                    label={this.props.getRequiredFieldName(fieldName, question.heightInches)}
                    onChange={this.onChange(constants.VITAL_STATS.heightValue)}
                    value={heightValue ? heightValue.toString() : ''}
                    helperText={!showTwoFields ? errors.height : null}
                    error={!showTwoFields && !!errors.height}
                    onUnitChange={onHeightUnit}
                    inputProps={{ maxLength: NUMBER_INPUT_MAX_LENGTH }}
                />
            </div>
        );
    }

    get weightQuestion() {
        const { userPreferredWeightUnit, weightValue, question, onWeightUnit, errors, i18n } = this.props;
        return (
            <Core.UnitField
                id={_.camelCase(`weight ${userPreferredWeightUnit}Input`)}
                unitOptions={_.map(constants.UNIT_OPTIONS.WEIGHT, unit => unit)}
                unit={i18n.t(userPreferredWeightUnit)}
                label={this.props.getRequiredFieldName('weight', question.weightLbs)}
                onChange={this.onChange(constants.VITAL_STATS.weightValue)}
                value={weightValue ? weightValue.toString() : ''}
                helperText={errors.weight}
                error={!!errors.weight}
                onUnitChange={onWeightUnit}
                fullWidth={true}
                inputProps={{ maxLength: NUMBER_INPUT_MAX_LENGTH }}
            />
        );
    }

    get modalFooter() {
        return (
            <Core.Layout.FixedModalFooterButton isBorder={true}>
                {this.footerInnerContent}
            </Core.Layout.FixedModalFooterButton>
        );
    }

    get footer() {
        return (
            <div className={css(layoutStyle.bgWhite, layoutStyle.footerPadding1, layoutStyle.flexJustifyCenter, styles.footerPadding)}>
                {this.footerInnerContent}
            </div>
        );
    }

    get footerInnerContent() {
        return (
            <Core.Button
                id="nextBtnOnHealthSurvey"
                isLoading={this.props.isSaving}
                type="contained"
                size="medium"
                className={css(layoutStyle.halfWidth)}
                onPress={this.props.submitAnswers}>
                {this.props.i18n.t('next')}
            </Core.Button>
        );
    }

    render() {
        const { question, i18n, isPrivacyMessageEnabled } = this.props;
        return (
            <div>
                <div className={css(this.props.isModal ? styles.spaceUnderModalFooterBtn : null)}>
                    <div className={css(layoutStyle.flexColumn, styles.mainContainer)}>
                        <div className={css(styles.header)}>
                            <div className={css(styles.icon)}>
                                <Core.Icon
                                    type="fa"
                                    name="user-chart"
                                    size={ICON_SIZE}
                                    color={baseColors.primary}
                                />
                            </div>
                            <div className={css(styles.textStyle, styles.headerText)}>
                                {_.get(question, 'text', '')}
                            </div>
                        </div>
                        <div>
                            {this.genderQuestion}
                            {this.DOBQuestion}
                            {this.heightQuestion}
                            {this.weightQuestion}
                        </div>
                    </div>
                    <div className={css(styles.disclaimerContainer)}>
                        {isPrivacyMessageEnabled ? (
                            <Core.DisclaimerSection
                                title={i18n.t('onboarding.welcome.disclaimerTitle')}
                                message={i18n.t('vitalStats.disclaimer.message')}
                            />
                        ) : null
                        }
                    </div>
                </div>
                {this.props.isModal ? this.modalFooter : this.footer}
            </div>
        );
    }
}

const styles = StyleSheet.create(importantStyles({
    ...baseStyles,
    spaceUnderModalFooterBtn: {
        paddingBottom: spacing.s17
    },
    mainContainer: {
        padding: `0 ${spacing.s2}px`
    },
    icon: {
        ...baseStyles.icon,
        textAlign: 'center'
    },
    disclaimerContainer: {
        paddingTop: spacing.s2
    },
    footerPadding: {
        paddingTop: spacing.s8,
        paddingBottom: spacing.s7
    }
}));

export default WithVitalStatsBase(VitalStats);
