import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { translate, selectors as coreSelector, ENTITIES } from '../../../core';
import { baseColors } from '../../../../styles';
import { CHALLENGE_ENTITY_TYPES, CHALLENGE_SUBENTITY_TYPES } from '../../constants';
import { getChallenge } from '../../selectors';
import { selectors as authSelectors } from '../../../auth';

export default function WithChallengeImage(WrappedComponent) {
    class ChallengeImageBase extends PureComponent {
        static propTypes = {
            challengeTypeProps: PropTypes.object,
            isCarouselItem: PropTypes.bool,
            hasImageContainMode: PropTypes.bool,
            imageWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            imageHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            labelText: PropTypes.string,
            // itemDetails come from stream item's relatedItemDetails
            itemDetails: PropTypes.object,
            i18n: PropTypes.object.isRequired,
        };

        static defaultProps = {
            challengeTypeProps: null,
            isCarouselItem: false,
            hasImageContainMode: false,
            imageWidth: '100%',
            imageHeight: '100%',
            labelText: ENTITIES.challenge,
            itemDetails: undefined
        };

        get isProgramChallenge() {
            const adminChallengeId = _.get(this.props, 'challenge.adminChallengeId', null) || _.get(this.props, 'itemDetails.adminChallengeId', null);
            return !_.isNull(adminChallengeId);
        }

        get isCorpChallenge() {
            const entityType = _.get(this.props, 'challenge.challengeEntityType') || _.get(this.props, 'itemDetails.challengeEntityType');
            const subentityType = _.get(this.props, 'challenge.challengeSubentityType') || _.get(this.props, 'itemDetails.challengeSubentityType');
            return (entityType === CHALLENGE_ENTITY_TYPES.company
                || entityType === CHALLENGE_ENTITY_TYPES.department
                || entityType === CHALLENGE_ENTITY_TYPES.region
                || subentityType === CHALLENGE_SUBENTITY_TYPES.companyTeam
            );
        }

        get companyName() {
            return _.get(this.props, 'currentUser.companyName');
        }

        get programName() {
            return _.get(this.props, 'programName');
        }

        get labelIconName() {
            if (this.isProgramChallenge) { return 'trophy'; }
            return '';
        }

        // return  or corporation name
        // example:
        // programName - 'Sprout'
        // coorName - 'Optime International'
        get labelTitle() {
            if (this.isProgramChallenge) { return this.programName; }
            return '';
        }

        get imageSize() {
            return { height: this.props.imageHeight, width: this.props.imageWidth };
        }

        get labelBackgroundColor() {
            let backgroundColor;
            if (this.isProgramChallenge) { backgroundColor = styles.programBackgroundColor; }
            else { backgroundColor = styles.transparentBackgroundColor; }
            return backgroundColor;
        }

        // is challenge or another entity?
        get labelText() {
            const { i18n } = this.props;
            const entityType = _.get(this.props, 'challenge.challengeEntityType') || _.get(this.props, 'itemDetails.challengeEntityType');
            if (entityType === CHALLENGE_ENTITY_TYPES.group) {
                return _.toLower(i18n.t('team_challenge'));
            }

            return _.toLower(i18n.t(ENTITIES.challenge));
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    labelText={this.labelText}
                    labelTitle={this.labelTitle}
                    labelIconName={this.labelIconName}
                    imageSize={this.imageSize}
                    isProgramChallenge={this.isProgramChallenge}
                    isCorpChallenge={this.isCorpChallenge}
                    labelBackgroundColor={this.labelBackgroundColor}
                    entityType={this.entityType}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        const challengeId = ownProps.challengeId || _.get(ownProps, 'match.params.challengeId');
        return {
            challenge: getChallenge(state, challengeId),
            currentUser: coreSelector.getCurrentUser(state),
            programName: authSelectors.getProgramName(state)
        };
    }

    return connect(mapStateToProps)(translate()(ChallengeImageBase));
}

export const styles = {
    programBackgroundColor: {
        backgroundColor: baseColors.primaryImageLabel
    },
    corporationBackgroundColor: {
        backgroundColor: baseColors.corporateImageLabel
    },
    transparentBackgroundColor: {
        backgroundColor: 'transparent'
    }
};
