import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite-jss';
import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import WithInviteFamilyMemberBase, { FIELDS, styles as baseStyles } from './InviteFamilyMemberBase';
import { components as Core } from '../../../core';
import { spacing, importantClass, baseColors } from '../../../../styles';
import PrivacyPolicyModal from '../../../core/components/TermsAndPolicyModal';

class InviteFamilyMember extends Component {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        checkboxApproval: PropTypes.bool.isRequired,
        multiSelect1: PropTypes.object,
        multiSelect2: PropTypes.object,
        onCheckboxApprovalChange: PropTypes.func.isRequired,
        toggleMultiSelect1: PropTypes.func.isRequired,
        toggleMultiSelect2: PropTypes.func.isRequired,
        onCheckboxPolicyChange: PropTypes.func.isRequired,
        onInvitePress: PropTypes.func.isRequired,
        sendInvitationLabel: PropTypes.string.isRequired,
        isSubmitBtnActive: PropTypes.bool.isRequired,
        options: PropTypes.object.isRequired,
        termsOfService: PropTypes.string,
        privacyPolicy: PropTypes.string,
        multiSelect1Question: PropTypes.string.isRequired,
        multiSelect2Question: PropTypes.string.isRequired,
        confirmTermsPolicy: PropTypes.string.isRequired,
        inviteAgeWarning: PropTypes.string.isRequired,
    };
    static defaultProps = {
        termsOfService: undefined,
        privacyPolicy: undefined,
        multiSelect1: {},
        multiSelect2: {},
    };
    get approvalToInvite() {
        const { checkboxApproval, onCheckboxApprovalChange, approvalToInviteLabel } = this.props;
        return (
            <Core.Checkbox
                color={baseColors.secondary}
                type="filled"
                checked={checkboxApproval}
                onChange={onCheckboxApprovalChange}
                className={css(styles.checkboxClassName)}
                label={approvalToInviteLabel}
            />
        );
    }
    get multiSelect1() {
        const { checkboxApproval, multiSelect1, toggleMultiSelect1, options, multiSelect1Question } = this.props;
        return (
            checkboxApproval ? (
                <div className={css(styles.multiSelectWrapper)}>
                    <div className={css(styles.multiSelectText)}>{multiSelect1Question}</div>
                    {_.map(options, item => this.renderOptionItem(item, multiSelect1, toggleMultiSelect1))}
                </div>
            ) : null
        );
    }

    get multiSelect2() {
        const { checkboxApproval, multiSelect1, isMultiSelect1Visible, multiSelect2, toggleMultiSelect2, options, multiSelect2Question } = this.props;
        return (
            checkboxApproval && isMultiSelect1Visible && multiSelect1.id === 0 ? (
                <div className={css(styles.multiSelectWrapper)}>
                    <div className={css(styles.multiSelectText)}>{multiSelect2Question}</div>
                    {_.map(options, item => this.renderOptionItem(item, multiSelect2, toggleMultiSelect2))}
                </div>
            ) : null
        );
    }
    get termsAndPolicy() {
        const { multiSelect2, checkboxPolicy, onCheckboxPolicyChange, i18n, isTermsAndPolicyVisible, confirmTermsPolicy, inviteAgeWarning } = this.props;
        return (
            // eslint-disable-next-line no-nested-ternary
            !isTermsAndPolicyVisible ? null : (
                multiSelect2.id ? (
                    <div className={css(styles.termsAndPolicyContainer)}>
                        <div className={css(styles.policyText)}>{confirmTermsPolicy}</div>
                        <PrivacyPolicyModal isInvitationPage={true} />
                        <Core.Checkbox
                            checked={checkboxPolicy}
                            type="filled"
                            onChange={onCheckboxPolicyChange}
                            label={i18n.t('inviteFamilyMember.confirm')}
                        />
                    </div>
                ) : (
                    <div className={css(styles.warningContainer)}>
                        <div className={css(styles.warningText)}>{inviteAgeWarning}</div>
                    </div>
                )
            )
        );
    }
    renderOptionItem = (item, selected, onPressFunc) => {
        const handleCheckboxChange = () => {
            onPressFunc(item);
        };
        const isSelected = _.get(item, 'id', item) === _.get(selected, 'id', selected);
        return (
            <div>
                <Core.Checkbox
                    checked={isSelected}
                    type="roundedRadio"
                    onChange={handleCheckboxChange}
                    textStyle={styles.textStyle}
                    label={item.label}
                    className={css(styles.checkboxMultiSelect)}
                />
            </div>
        );
    }
    renderInputField = item => (
        <Core.Input
            label={this.props.i18n.t(item.label)}
            type="email"
            value={this.props[item.value]}
            fullWidth={true}
            onChangeText={this.props[item.func]}
            textFieldClassName={css(styles.textFieldClassName)}
            error={!!this.props.errors[item.value]}
            helperText={this.props.errors[item.value]}
        />
    )
    render() {
        const { onInvitePress, sendInvitationLabel, isSubmitBtnActive } = this.props;
        return (
            <div>
                <Core.EntityDetailsHeader
                    hasBackButton={true}
                />
                <div className={css(styles.pageWrapper)}>
                    <Core.SubHeader
                        title={this.props.i18n.t('inviteFamilyMember.label')}
                        className={css(styles.subHeader)}
                        noLeftPadding={true}
                    />
                    <div className={css(styles.container)}>
                        {_.map(FIELDS, item => this.renderInputField(item))}
                        {this.approvalToInvite}
                        {this.multiSelect1}
                        {this.multiSelect2}
                        {this.termsAndPolicy}
                    </div>
                    <Core.Button
                        size="medium"
                        onPress={onInvitePress}
                        disabled={!isSubmitBtnActive}
                        halfOpacityButton={true}
                        className={classnames(css(styles.invitationBtn, isSubmitBtnActive && styles.cursorNotAllowed))}>
                        {sendInvitationLabel}
                    </Core.Button>
                </div>
            </div>
        );
    }
}

export default withRouter(WithInviteFamilyMemberBase(InviteFamilyMember));
const CONTAINER_WIDTH = 470;
const styles = StyleSheet.create({
    ...baseStyles,
    pageWrapper: {
        display: 'grid',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'column'
    },
    container: {
        alignItems: 'flex-start',
        flexDirection: 'column',
        justifySelf: 'center',
        display: 'flex',
        margin: spacing.s3,
        width: CONTAINER_WIDTH
    },
    invitationBtn: importantClass({
        marginTop: spacing.s4,
        marginLeft: spacing.s3,
        height: spacing.s12 - 2,
        width: '40%'
    }),
    subHeader: importantClass({
        marginLeft: spacing.s3,
        marginBottom: -spacing.s1
    }),
    textFieldClassName: importantClass({
        height: spacing.s13
    }),
    checkboxClassName: importantClass({
        marginTop: spacing.s3,
        ...baseStyles.textStyle
    }),
    termsAndPolicyContainer: {
        border: `1px solid ${baseColors.grey70}`,
        borderRadius: spacing.s1,
        padding: spacing.s3,
        paddingBottom: spacing.s1
    },
    cursorNotAllowed: {
        cursor: 'not-allowed'
    },
    checkboxMultiSelect: importantClass({
        height: spacing.s7,
        marginRight: 0,
        marginLeft: 0,
        ...baseStyles.textStyle
    }),
    multiSelectWrapper: {
        marginTop: spacing.s5
    },
    multiSelectText: {
        ...baseStyles.textStyle,
        marginBottom: spacing.s1
    },
    warningContainer: {
        ...baseStyles.warningContainer,
        marginTop: spacing.s3
    },
});
