import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getSingleCommunity } from '../../selectors';
import { translate, getImageProp } from '../../../core';
import { appFonts, baseColors, spacing } from '../../../../styles';

export default function WithCommunityItemBase(WrappedComponent) {
    class CommunityItemBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            onDetailsPress: PropTypes.func,
            item: PropTypes.object.isRequired,
        };

        static defaultProps = {
            onDetailsPress: null
        };

        get imageUrl() {
            return getImageProp(this.props.item.groupImageURL || this.props.item.imageURL);
        }

        get inviteCommunityType() {
            return this.props.i18n.t('invitationCommunity');
        }

        get communityEventsText() {
            return this.props.i18n.t('communityEvents', { numberOfEvents: this.props.item.numEvents });
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    imageUrl={this.imageUrl}
                    inviteCommunityType={this.inviteCommunityType}
                    communityEventsText={this.communityEventsText}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        return {
            item: getSingleCommunity(state, ownProps.id)
        };
    }

    return connect(mapStateToProps)(translate()(CommunityItemBase));
}

export const IMAGE_SIZE = 80;
export const styles = {
    mainContainer: {
        flex: 1,
        paddingTop: spacing.s3,
        paddingBottom: spacing.s3,
        marginLeft: spacing.s1,
        marginRight: spacing.s1,
        borderColor: baseColors.grey80,
        borderBottomWidth: 1
    },
    communityContainer: {
        flexDirection: 'row',
        display: 'flex',
    },
    communityItemContainer: {
        backgroundColor: 'transparent',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    communityTitle: {
        color: baseColors.black,
        marginBottom: spacing.s0,
        ...appFonts.lgBold
    },
    communityDetails: {
        ...appFonts.smRegular,
        color: baseColors.grey40,
    },
    overlapAvatarList: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    invitationContainer: {
        marginBottom: spacing.s1
    },
    imageStyle: {
        width: IMAGE_SIZE,
        height: IMAGE_SIZE
    },
    communityInvitationContainer: {
        marginBottom: spacing.s1
    },
};
