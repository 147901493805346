import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { translate, parsers, pointsHelper, selectors as coreSelectors } from '../../../../core';
import { baseColors, spacing, appFonts } from '../../../../../styles';
import * as selectors from '../../../selectors';
import { isMember, isAfterDeadline, isPersonal, isGoalType } from '../../../services/helper';

export const NUMBER_OF_LINES = 3;
const ACTIVITIES_TO_SHOW = 3;

export default function WithChallengeInfoBase(WrappedComponent) {
    class ChallengeInfoBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            history: PropTypes.object.isRequired,
            challenge: PropTypes.object.isRequired,
            navigation: PropTypes.object.isRequired,
            challengeActivities: PropTypes.array.isRequired,
            challengeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            customPointsUnit: PropTypes.string.isRequired
        };

        state = { isAllActivitiesShown: false };

        get description() {
            return parsers.htmlDecode(this.props.challenge.challengeDescription);
        }

        get isToggleShown() {
            return this.props.challengeActivities.length > 3;
        }

        get isGoal() {
            return isGoalType(this.props.challenge);
        }

        get activities() {
            return this.isToggleShown && !this.state.isAllActivitiesShown
                ? _.take(this.props.challengeActivities, ACTIVITIES_TO_SHOW) : this.props.challengeActivities;
        }

        get moreButtonText() {
            return this.state.isAllActivitiesShown ? this.props.i18n.t('showLess') : `${this.props.i18n.t('showAll')} (${this.props.challengeActivities.length})`;
        }

        getEarnPoints = item =>
            `${this.props.i18n.t('earn')} ${pointsHelper.formatPoints(pointsHelper.roundedPoints(item.unitPoints), this.props.customPointsUnit)}/${item.unitSingular}`;

        toggleActivities = () => this.setState(state => ({ isAllActivitiesShown: !state.isAllActivitiesShown }));

        get isEnded() {
            return isAfterDeadline(this.props.challenge);
        }

        get challengeTypeLabel() {
            const { i18n, challenge } = this.props;
            return _.toLower(i18n.t(this.isChallengeType ? 'challenge' : challenge.challengeType));
        }

        get isChallengeType() {
            const { challenge } = this.props;
            return !isPersonal(challenge) && isGoalType(challenge);
        }

        get activityListTitle() {
            return this.props.i18n.t('activitiesToTrack.title');
        }

        get activityListSubtitle() {
            const { challengeActivities, i18n } = this.props;
            return challengeActivities.length > 1 ? i18n.t('challengeDetails.trackTheseActivities', { challengeType: this.challengeTypeLabel }) : i18n.t('challengeInstructionText', { challengeType: this.challengeTypeLabel });
        }

        get disclaimerSectionTitle() {
            return this.props.i18n.t('disclamerSectionTitle');
        }

        get disclaimerSectionMessage() {
            return this.props.i18n.t('disclamerSectionMessage');
        }

        get chevronDirectText() {
            const { isAllActivitiesShown } = this.state;
            return isAllActivitiesShown ? 'chevron-up' : 'chevron-down';
        }

        get smartModeStatusText() {
            const { i18n, isSmartModeGoal } = this.props;
            const fullText = i18n.t('goalSmartMode.status', { status: isSmartModeGoal ? i18n.t('On') : i18n.t('Off') });
            const boldSmartText = this.props.i18n.t('goalSmartMode.title');
            const htmlText = `${fullText.replace(boldSmartText, `<strong>${boldSmartText}</strong>`)}`;
            return htmlText;
        }

        getSmartModeInfoModalProps = () => {
            const { i18n } = this.props;
            const descriptionText = i18n.t('goalSmartMode.infoModal.descriptionOne');
            const boldSmartText = this.props.i18n.t('goalSmartMode.title');
            const smartText = `${descriptionText.replace(boldSmartText, `<strong>${boldSmartText}</strong>`)}`;
            const instructionText = i18n.t('goalSmartMode.infoModal.instructions', { settings: i18n.t('settings'), me: i18n.t('me') });
            const htmlText =`${smartText} \n\n${i18n.t('goalSmartMode.infoModal.descriptionTwo')} \n\n${instructionText}`;

            return {
                htmlText,
                iconName: 'question',
                isButtonVisible: false,
                buttonTitle: i18n.t('got_it'),
            };
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    isEnded={this.isEnded}
                    toggleActivities={this.toggleActivities}
                    getEarnPoints={this.getEarnPoints}
                    moreButtonText={this.moreButtonText}
                    activities={this.activities}
                    isToggleShown={this.isToggleShown}
                    description={this.description}
                    challengeTypeLabel={this.challengeTypeLabel}
                    activityListTitle={this.activityListTitle}
                    activityListSubtitle={this.activityListSubtitle}
                    disclaimerSectionTitle={this.disclaimerSectionTitle}
                    disclaimerSectionMessage={this.disclaimerSectionMessage}
                    chevronDirectText={this.chevronDirectText}
                    isGoal={this.isGoal}
                    isMember={isMember(this.props.challenge)}
                    smartModeStatusText={this.smartModeStatusText}
                    getSmartModeInfoModalProps={this.getSmartModeInfoModalProps}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => ({
        challenge: selectors.getChallenge(state, ownProps.challengeId),
        challengeActivities: selectors.getVisibleInChallengeCreationForCategoryWithName(state, ownProps.challengeId),
        customPointsUnit: coreSelectors.getCustomPointsUnit(state),
        isLoadingMembership: selectors.isLoadingMembership(state, ownProps.challengeId),
        isSmartModeGoal: selectors.isSmartModeGoal(state, ownProps.challengeId)
    });

    return connect(mapStateToProps)(translate()(ChallengeInfoBase));
}

export const styles = {
    cardBodyTitle: {
        alignItems: 'flex-start',
        marginTop: spacing.s7,
        marginBottom: spacing.s5
    },
    cardBodyTitleText: {
        ...appFonts.lgBold,
    },
    flexRowTitleText: {
        ...appFonts.smRegular,
        color: baseColors.grey40,
    },
    cardBodyDays: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingBottom: spacing.s1,
    },
    seeMoreStyle: {
        ...appFonts.mdBold,
    },
    descriptionText: {
        ...appFonts.mdRegular,
        color: baseColors.grey20,
    },
    activityListTitleText: {
        ...appFonts.mdRegular,
        color: baseColors.grey40,
    },
    activityListItemText: {
        ...appFonts.mdMedium,
        color: baseColors.grey20,
    },
    moreActivitiesContainer: {
        paddingTop: spacing.s2,
        paddingBottom: spacing.s2,
        color: baseColors.secondary,
    },
    cardBodyButton: {
        paddingBottom: spacing.s2
    },
    disclaimerSection: {
        marginBottom: spacing.s7,
        marginTop: spacing.s5
    },
    instructionsText: {
        marginTop: spacing.s1
    },
    moreActivitiesContainerText: {
        ...appFonts.mdRegular,
        marginRight: spacing.s1
    },
    smartModeSection: {
        paddingTop: spacing.s3,
        paddingBottom: spacing.s3,
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: baseColors.contextLightest,
        borderRadius: spacing.s1
    },
    smartModePadding: {
        paddingBottom: spacing.s4
    },
    smartModeText: {
        ...appFonts.mdRegular
    },
    question: {
        paddingRight: spacing.s3
    }
};
