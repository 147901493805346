import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { selectors as coreSelectors, translate } from '../../../core';
import { EVENT_ENTITY_TYPES } from '../../constants';

export default function WithProfileEventsBase(WrappedComponent) {
    class ProfileEventsBase extends PureComponent {
        static propTypes = {
            userId: PropTypes.number,
            currentUserId: PropTypes.number.isRequired
        };

        static defaultProps = {
            userId: null
        };

        get eventType() {
            return this.props.userId === this.props.currentUserId ? EVENT_ENTITY_TYPES.MY_ITEMS : EVENT_ENTITY_TYPES.USER;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    eventType={this.eventType}
                />
            );
        }
    }

    function mapStateToProps(state) {
        return {
            currentUserId: coreSelectors.getCurrentUserId(state)
        };
    }

    return connect(mapStateToProps)(translate()(ProfileEventsBase));
}
