import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { translate } from '../../../core';
import { appFonts, spacing, baseColors, listItemImageSize } from '../../../../styles';
import { challengeDateText, isTeamChallenge } from '../../services/helper';

export const NUMBER_OF_LINES = 2;

export default function WithChallengeListItemBase(WrappedComponent) {
    class ChallengeListItemBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            challenge: PropTypes.object.isRequired
        };

        static defaultProps = {};

        get participantsText() {
            const { challenge, i18n } = this.props;
            return `${challenge.numChallengeParticipants} ${i18n.t('participants')}`;
        }

        get challengeDateIcon() {
            return this.props.challenge.isEnded ? 'flag-checkered' : 'calendar-alt';
        }

        get challengeDateText() {
            return challengeDateText(this.props.challenge);
        }

        get numMembersText() {
            const { challenge, i18n } = this.props;
            const num = _.get(challenge, 'numChallengeParticipants');
            return (num > 1) ?
                _.toLower(i18n.t('numberOfParticipants', { num }))
                : i18n.t('oneParticipant');
        }

        get challengeIconName() {
            return this.props.challenge.isEnded ? 'flag-checkered' : 'calendar-alt';
        }

        get isTeamChallenge() {
            return isTeamChallenge(this.props.challenge);
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    participantsText={this.participantsText}
                    challengeDateIcon={this.challengeDateIcon}
                    challengeDateText={this.challengeDateText}
                    numMembersText={this.numMembersText}
                    challengeIconName={this.challengeIconName}
                    isTeamChallenge={this.isTeamChallenge}
                />
            );
        }
    }

    return (translate()(ChallengeListItemBase));
}

export const styles = {
    cardContainer: {
        flex: 1,
        flexDirection: 'row',
        paddingRight: spacing.s1,
        paddingLeft: spacing.s1,
        display: 'flex'
    },
    card: {
        flexDirection: 'row',
        flexGrow: 1,
        flex: 1,
        alignItems: 'center',
        borderBottomWidth: 1,
        borderBottomColor: baseColors.grey80,
        paddingTop: spacing.s1,
        paddingBottom: spacing.s1,
        display: 'flex'
    },
    cardInner: {
        flex: 1,
        paddingLeft: spacing.s1,
        paddingRight: spacing.s2,
        paddingTop: spacing.s0,
        paddingBottom: spacing.s1,
        marginTop: -spacing.s1
    },
    challengeTitle: {
        ...appFonts.lgBold,
        paddingBottom: spacing.s0
    },
    challengeHeader: {
        ...appFonts.mdRegular,
        color: baseColors.grey40,
        marginBottom: spacing.s0
    },
    image: {
        backgroundColor: baseColors.grey85,
    },
    detailsText: {
        ...appFonts.smRegular,
        color: baseColors.grey40
    },
    detailsSection: {
        paddingTop: spacing.s0,
        flexDirection: 'row',
        display: 'flex'
    },
    leftDetails: {
        paddingRight: spacing.s0
    },
    rightDetails: {
        paddingLeft: spacing.s0
    },
    detailsSpacer: {
        paddingLeft: spacing.s0,
        paddingRight: spacing.s0,
        color: baseColors.grey40
    },
    imageContainer: {
        borderRadius: spacing.s1,
        overflow: 'hidden'
    },
    entityCardImage: {
        height: listItemImageSize
    },
    bonusMarkContainer: {
        marginTop: spacing.s1,
    },
    membersAvatar: {
        marginLeft: -spacing.s0,
        borderWidth: 2,
        borderColor: baseColors.white
    },
    membersText: {
        ...appFonts.smRegular,
        color: baseColors.grey40,
        paddingLeft: spacing.s0
    }
};
