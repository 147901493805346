import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';

import translate from '../../services/translate';
import { baseColors, spacing, appFonts } from '../../../../styles';

const TOOLTIP_MAX_WIDTH = 300;
const defaultThemeProps = {
    ...appFonts.smRegular,
    paddingLeft: spacing.s3,
    paddingRight: spacing.s3,
    paddingTop: spacing.s2,
    paddingBottom: spacing.s2,
    backgroundColor: baseColors.grey20,
    maxWidth: TOOLTIP_MAX_WIDTH,
    borderRadius: spacing.s1,
};

const CustomTooltip = ({ children, title, themeProps = defaultThemeProps, className }) => {
    const getTheme = themeProps => createTheme({
        overrides: {
            MuiTooltip: {
                tooltip: {
                    ...themeProps,
                }
            }
        }
    });
    return (
        <MuiThemeProvider theme={getTheme(themeProps)}>
            <Tooltip title={title}>
                <div className={className}>
                    {children}
                </div>
            </Tooltip>
        </MuiThemeProvider>

    );
};

CustomTooltip.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    themeProps: PropTypes.object,
    className: PropTypes.string,
};

CustomTooltip.defaultProps = {
    themeProps: undefined,
    className: undefined,
};

export default translate()(CustomTooltip);
