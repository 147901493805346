import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { css } from 'aphrodite-jss';
import { withRouter } from 'react-router-dom';

import { layoutStyle } from '../../../../styles';
import { components as Core } from '../../../core';
import WithDeviceSupportedActivitiesListBase from './DeviceSupportedActivitiesListBase';

class DeviceSupportedActivitiesList extends PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        activities: PropTypes.array.isRequired,
        isOnboarding: PropTypes.bool
    };

    static defaultProps = {
        isOnboarding: false
    };

    render() {
        const { i18n, activities, isOnboarding } = this.props;
        return (
            <Core.SmallerContainer
                widerHeaderComponent={(
                    <Core.EntityDetailsHeader
                        hasBackButton={true}
                        isOnboarding={isOnboarding}
                        title={i18n.t('supported_activities')}
                    />)
                }>
                <Core.SubHeader
                    title={i18n.t('supported_activities')}
                    noTopPadding={true}
                    noLeftPadding={true}
                />
                <Core.List component="ul">
                    {_.map(activities, activityItem => (
                        <Core.ListItem
                            hideChevron={true}
                            className={css(layoutStyle.listItemBig)}>
                            <Core.ListItemText primary={activityItem} />
                        </Core.ListItem>
                    ))}
                </Core.List>
            </Core.SmallerContainer>
        );
    }
}

export default withRouter(WithDeviceSupportedActivitiesListBase(DeviceSupportedActivitiesList));
