import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite-jss';
import { withLastLocation } from 'react-router-last-location';
import _ from 'lodash';
import { components as Core, ROUTES } from '../../../core';
import { containerStyle } from '../../../../styles';
import WithResetPasswordBase from './ResetPasswordBase';
import AuthWrapperWeb from '../AuthWrapperWeb';

class ResetPassword extends PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
        isLoading: PropTypes.bool.isRequired,
        i18n: PropTypes.object.isRequired,
        onChangeEmail: PropTypes.func.isRequired,
        sendResetEmail: PropTypes.func.isRequired,
        email: PropTypes.string.isRequired,
        errorMessage: PropTypes.string
    };

    static defaultProps = {
        errorMessage: '',
    };

    // is used from base
    goBack = () => {
        this.props.history.push(_.get(this.props, 'lastLocation.pathname') || ROUTES.signin);
    };

    onChangeEmail = event => {
        this.props.onChangeEmail(event.target.value);
    };

    render() {
        return (
            <AuthWrapperWeb>
                <div className={css(containerStyle.container)}>
                    <Core.SubHeader titleAlign={Core.SubHeader.ALIGN.center} left={<span />} title={this.props.i18n.t('reset_password')} />
                    <div className={css(containerStyle.main)}>
                        <Core.Input
                            maxLength={200}
                            fullWidth={true}
                            id="email"
                            label={this.props.i18n.t('email')}
                            value={this.props.email}
                            onChange={this.onChangeEmail}
                            helperText={this.props.errorMessage}
                            error={!!this.props.errorMessage}
                        />
                    </div>
                    <div className={css(containerStyle.footerButtonContainer)}>
                        <Core.Button
                            fullWidth={true}
                            onPress={this.props.sendResetEmail}
                            isLoading={this.props.isLoading}
                            disabled={this.props.isLoading}>
                            {this.props.i18n.t('sendResetEmail')}
                        </Core.Button>
                    </div>
                </div>
            </AuthWrapperWeb>
        );
    }
}

export default withLastLocation(WithResetPasswordBase(ResetPassword));