import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as actions from '../../actions';
import { getHealthTopics, isSavingTopics } from '../../selectors';
import PreferencesBase from '../PreferencesBase';

export default function WithHealthTopicsBase(WrappedComponent) {
    class HealthTopicsBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            healthTopics: PropTypes.object.isRequired,
            hideBottomButton: PropTypes.bool,
            isSaving: PropTypes.bool,
            error: PropTypes.bool,
            i18n: PropTypes.object.isRequired,
        };

        static defaultProps = {
            hideBottomButton: false,
            isSaving: false,
            error: false
        };

        constructor(props) {
            super(props);
            this.props.actions.getHealthTopics();
        }

        get title() {
            return this.props.i18n.t('personalize.topics.title');
        }

        get description() {
            return this.props.i18n.t('personalize.topics.description');
        }

        render() {
            return (
                <PreferencesBase
                    {...this.props}
                    selectData={this.props.healthTopics.answers}
                    wrappedComponent={WrappedComponent}
                    saveData={this.props.actions.saveHealthTopics}
                    title={this.title}
                    description={this.description}
                />
            );
        }
    }
    function mapStateToProps(state, ownProps) {
        const routeParams = _.get(ownProps, 'route.params');
        return {
            healthTopics: getHealthTopics(state),
            isSaving: isSavingTopics(state),
            ...(routeParams || {})
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        };
    }
    return connect(mapStateToProps, mapDispatchToProps)(HealthTopicsBase);
}