import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { getUserName, translate } from '../../services';
import { appFonts } from '../../../../styles';
import { isLiveBetter } from '../../selectors';
import { ICON_CATEGORIES, DEFAULT_ICON_CATEGORY as DEFAULT_ICON } from '../../constants';

export default function WithAvatarListBase(WrappedComponent) {
    class AvatarListBase extends PureComponent {
        static propTypes = {
            isLiveBetter: PropTypes.bool
        };

        static defaultProps = {
            isLiveBetter: false
        };


        getName = member => member.name || getUserName(member);
        get isPressDisabled() {
            return true;
        }

        categoryColor = goal => {
            const category = _.get(goal.activityUnitDetails[0], 'activityCategory', undefined);
            if (category) {
                const bgr = ICON_CATEGORIES[_.toLower(category)].bgr;
                if (bgr) {
                    return bgr;
                }
            }

            return DEFAULT_ICON.bgr;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    getName={this.getName}
                    isPressDisabled={this.isPressDisabled}
                    categoryColor={this.categoryColor}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        return {
            isLiveBetter: isLiveBetter(state)
        };
    };

    return connect(mapStateToProps)(translate()(AvatarListBase));
}

export const styles = {
    membersContainer: {
        alignItems: 'center',
        flexDirection: 'row',
        display: 'flex',
        minWidth: 0
    },
    avatarsContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    numberOfMembers: {
        ...appFonts.mdRegular
    },
    firstAvatar: {
        marginLeft: 0
    }
};
