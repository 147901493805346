import moment from 'moment';
import _ from 'lodash';
import I18n from '../../../i18n/i18n';
import { constants as coreConstants, languageHelper } from '../../core';

export function eventStartEndString(event) {
    if (!event) return undefined;

    const startDate = moment(event.eventDateTime).format(coreConstants.DATE_FORMATS.dayFullMonthDay);
    const startTime = _.replace(moment(event.eventDateTime).format(coreConstants.DATE_FORMATS.twelveHoursTime), ':00', '');

    const endDate = moment(event.eventEndDateTime).format(coreConstants.DATE_FORMATS.dayFullMonthDay);
    const endTime = _.replace(moment(event.eventEndDateTime).format(coreConstants.DATE_FORMATS.twelveHoursTime), ':00', '');

    if (languageHelper.isFrenchLanguage(I18n)) {
        const formattedStartDate = moment(event.eventDateTime).format('ddd [le] D MMM');

        const frenchHelperWord = 'jusqu';
        return `${formattedStartDate} de ${frenchTimeStringBuilder(event.eventDateTime)} ${frenchHelperWord} à ${frenchTimeStringBuilder(event.eventEndDateTime)}`;
    }

    if (startDate === endDate) {
        return `${startDate} ${I18n.t('at')} ${startTime} ${I18n.t('to')} ${endTime}`;
    }

    return `${startDate} ${I18n.t('at')} ${startTime} to \n${endDate} ${I18n.t('at')} ${endTime}`;
}

export const frenchTimeStringBuilder = time => {
    const hours = moment(time).format('H');
    const minutes = moment(time).format('mm');
    return minutes === '00' ? `${hours}h` : `${hours}h${minutes}`;
};