import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { translate, selectors as coreSelectors, parsers, Platform, PLATFORMS, Storage } from '../../../core';
import { baseColors, spacing } from '../../../../styles';
import { isMember, isStarted, isUserCompanyPartner, isPersonal, isPreviousNotWeeklyGoal, isTeamChallenge, isGoalType } from '../../services/helper';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import { constants as settingsConstants, selectors as settingsSelectors } from '../../../settings';
import { selectors as onboardingSelectors } from '../../../onboarding';
import { CHALLENGE_TYPES, IS_ABOUT_TAB, IS_TEAM_TAB } from '../../constants';

export default function WithChallengeDetailsBase(WrappedComponent) {
    class ChallengeDetailsBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            history: PropTypes.object.isRequired,
            challenge: PropTypes.object,
            challengeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            isLoading: PropTypes.bool,
            isDeleting: PropTypes.bool,
            isLeaving: PropTypes.bool,
            challengeInfoError: PropTypes.object,
            isPrivacyVisible: PropTypes.bool,
            role: PropTypes.string.isRequired,
            isExtendedRole: PropTypes.bool.isRequired,
            isShowingOnboarding: PropTypes.bool,
            isTaskCard: PropTypes.bool,
            isBonusChallenge: PropTypes.number.isRequired,
            leaderboard: PropTypes.object.isRequired,
            userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
        };

        static defaultProps = {
            isLoading: false,
            isDeleting: false,
            isLeaving: false,
            challengeInfoError: false,
            challenge: null,
            isPrivacyVisible: false,
            isShowingOnboarding: false,
            isTaskCard: false,
        };

        constructor(props) {
            super(props);

            const { i18n, actions, challengeId, challenge } = this.props;
            this.DEFINED_TABS = Object.freeze({
                about: {
                    label: i18n.t('about'),
                    id: 'about'
                },
                progress: {
                    label: i18n.t('progress'),
                    id: 'progress'
                },
                stream: {
                    label: i18n.t('feed'),
                    id: 'stream'
                },
                leaderboard: {
                    label: i18n.t('leaderboard'),
                    id: 'leaderboard'
                },
                myTeam: {
                    label: i18n.t('my_team'),
                    id: 'myTeam'
                },
            });
            this.updateMyTeamScore = true;
            if (this.isStartedAndMember) {
                this.state = { selectedTab: this.progressTab };
                if (this.props.isLeaderboard && this.props.challenge.challengeType !== CHALLENGE_TYPES.goal) {
                    this.state = { selectedTab: this.leaderboardTab };
                }
            } else {
                this.state = { selectedTab: this.aboutTab };
            }
            actions.getChallenge(challengeId);
            if (challenge) {
                if (isPreviousNotWeeklyGoal(challenge)) this.getPersonalGoals();
                actions.getActivityLogsExtended(challenge.startDate, challenge.challengeDeadline);
            }
        }

        async componentDidMount() {
            const isAboutTab = await Storage.getItem(IS_ABOUT_TAB);
            if (isAboutTab) {
                await Storage.removeItem(IS_ABOUT_TAB);
                this.setState({ selectedTab: this.aboutTab });
            }

            const isTeamTab = await Storage.getItem(IS_TEAM_TAB);
            if (isTeamTab) {
                await Storage.removeItem(IS_TEAM_TAB);
                this.setState({ selectedTab: this.teamTab });
            }

            if (this.props.challenge) {
                if (isTeamChallenge(this.props.challenge)) {
                    this.props.actions.getChallengeMembers(this.props.challengeId, true);
                    const teamId = _.get(this.props.challenge, 'userEntity.entityId');
                    teamId && this.props.actions.getChallengeTeam(this.props.challengeId, teamId);
                }
            }
        }

        componentDidUpdate(prevProps) {
            const { isDeleting, isLeaving, history, challengeInfoError, actions, challengeId, isPrivacyVisible, challenge } = this.props;
            if ((!isDeleting && prevProps.isDeleting) || (!isLeaving && prevProps.isLeaving)) {
                if (prevProps.isShowingOnboarding || prevProps.isTaskCard) {
                    _.has(this, 'wrapped.goBack') && this.wrapped.goBack();
                }
                else {
                    _.has(this, 'wrapped.close') && this.wrapped.close();
                    this.isWeb && history.push({ isChallengeDeleted: true });
                }
            }

            // user opened a deleted challenge via deep link or notification, send them back
            if (challengeInfoError) {
                actions.clearGetChallengeInfoError(challengeId);
            }

            if (isPrivacyVisible !== prevProps.isPrivacyVisible) {
                challenge && actions.getChallenge(challengeId);
            }
            if (!prevProps.challenge && challenge && isPreviousNotWeeklyGoal(challenge)) {
                this.getPersonalGoals();
            }
            if (this.updateMyTeamScore && challenge && isTeamChallenge(challenge) && isGoalType(challenge)) {
                actions.getChallengeLeaderboard(challengeId);
                this.updateMyTeamScore = false;
            }
        }

        getPersonalGoals = () => this.props.actions.getPersonalGoalsAndChallenges({ isSolo: 1, isEnded: 0, isCompleted: undefined });

        get aboutTab() {
            return _.find(this.tabs, tab => tab.id === this.DEFINED_TABS.about.id);
        }

        get leaderboardTab() {
            return _.find(this.tabs, tab => tab.id === this.DEFINED_TABS.leaderboard.id);
        }

        get progressTab() {
            return _.find(this.tabs, tab => tab.id === this.DEFINED_TABS.progress.id);
        }

        get teamTab() {
            return _.find(this.tabs, tab => tab.id === this.DEFINED_TABS.myTeam.id);
        }

        onTabChange = tab => {
            this.setState({ selectedTab: tab });
        };

        get isUserCompanyPartner() {
            return !!this.props.challenge && isUserCompanyPartner(this.props.challenge);
        }

        get isPersonal() {
            return !!this.props.challenge && isPersonal(this.props.challenge);
        }

        get tabs() {
            return [
                this.DEFINED_TABS.about,
                ...(this.isStartedAndMember ? [this.DEFINED_TABS.progress] : []),
                ...((!this.isPersonal && this.isStartedAndMember && this.props.challenge.challengeType !== CHALLENGE_TYPES.goal) ? [this.DEFINED_TABS.leaderboard] : []),
                ...(!this.isUserCompanyPartner ? [this.DEFINED_TABS.myTeam] : []),
                ...(!this.isPersonal ? [this.DEFINED_TABS.stream] : [])
            ];
        }

        get isStartedAndMember() {
            return !!this.props.challenge && isMember(this.props.challenge) && isStarted(this.props.challenge);
        }

        saveRef = ref => (this.wrapped = ref);

        get contentDeletedText() {
            return this.props.i18n.t('contentIsDeletedOrInappropriate');
        }

        get challengeName() {
            return parsers.htmlDecode(_.get(this.props.challenge, 'challengeName', ''));
        }

        get isWeb() {
            return Platform.OS === PLATFORMS.web;
        }

        get youButtonLabel() {
            const { i18n, challenge } = this.props;
            return i18n.t(challenge && isTeamChallenge(challenge) ? 'yourTeam' : 'you');
        }

        get isLeaderboardTab() {
            return this.state.selectedTab.id === this.DEFINED_TABS.leaderboard.id;
        }

        get zeroParticipants() {
            const { i18n, challenge } = this.props;
            const teamsOrParticipantsText = challenge && i18n.t(isTeamChallenge(challenge) ? 'number_teams' : 'numberOfParticipants', { num: 0 });
            return challenge && challenge.numChallengeParticipants < 1 ? teamsOrParticipantsText : null;
        }

        get isYouOnLeaderboard() {
            const { leaderboard, userId } = this.props;
            // eslint-disable-next-line
            return leaderboard && !!leaderboard.items.filter(user => user.itemEntityId == userId)[0];
        }

        get isMyTeam() {
            const { leaderboard } = this.props;
            // eslint-disable-next-line
            return leaderboard && !!leaderboard.items.filter(i => i.isViewer == 1)[0];
        }

        get smartModeStatusText() {
            const { i18n, isSmartModeGoal } = this.props;
            const fullText = i18n.t('goalSmartMode.status', { status: isSmartModeGoal ? i18n.t('On') : i18n.t('Off') });
            const boldSmartText = this.props.i18n.t('goalSmartMode.title');
            const htmlText = `${fullText.replace(boldSmartText, `<strong>${boldSmartText}</strong>`)}`;
            return htmlText;
        }

        getSmartModeInfoModalProps = () => {
            const { i18n } = this.props;
            const descriptionText = i18n.t('goalSmartMode.infoModal.descriptionOne');
            const boldSmartText = this.props.i18n.t('goalSmartMode.title');
            const smartText = `${descriptionText.replace(boldSmartText, `<strong>${boldSmartText}</strong>`)}`;
            const instructionText = i18n.t('goalSmartMode.infoModal.instructions', { settings: i18n.t('settings'), me: i18n.t('me') });
            const htmlText =`${smartText} \n\n${i18n.t('goalSmartMode.infoModal.descriptionTwo')} \n\n${instructionText}`;

            return {
                htmlText,
                iconName: 'question',
                isButtonVisible: false,
                buttonTitle: i18n.t('got_it'),
            };
        };

        deleteChallenge = () => this.props.actions.deleteChallenge(this.props.challenge.challengeId);

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    ref={this.saveRef}
                    tabs={this.tabs}
                    isMyTeam={this.isMyTeam}
                    onTabChange={this.onTabChange}
                    DEFINED_TABS={this.DEFINED_TABS}
                    selectedTab={this.state.selectedTab}
                    isStartedAndMember={this.isStartedAndMember}
                    isMember={isMember(this.props.challenge)}
                    contentDeletedText={this.contentDeletedText}
                    challengeName={this.challengeName}
                    deleteChallenge={this.deleteChallenge}
                    youButtonLabel={this.youButtonLabel}
                    isLeaderboardTab={this.isLeaderboardTab}
                    zeroParticipants={this.zeroParticipants}
                    isYouOnLeaderboard={this.isYouOnLeaderboard}
                    smartModeStatusText={this.smartModeStatusText}
                    getSmartModeInfoModalProps={this.getSmartModeInfoModalProps}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const challengeId = ownProps.challengeId || _.get(ownProps, 'match.params.challengeId') || _.get(ownProps, 'route.params.challengeId');
        const challenge = selectors.getChallenge(state, challengeId);
        const isDeleting = selectors.isDeletingChallenge(state, challengeId);
        const isLeaving = selectors.isLeavingChallenge(state, challengeId);
        const isTaskCard = _.get(ownProps, 'location.state.isTaskCard');
        const locationState = _.get(ownProps, 'history.location.state', {});
        if (!challenge) {
            return {
                challengeId,
                challengeInfoError: selectors.getChallengeInfoError(state, challengeId),
                isLoading: selectors.isLoadingChallengeInfo(state, challengeId)
            };
        }
        return {
            challengeId,
            challenge,
            isTaskCard,
            isShowingOnboarding: onboardingSelectors.isShowingOnboarding(state),
            user: coreSelectors.getCurrentUser(state),
            activities: selectors.getUnitsForActivityCategory(state, challengeId),
            remainingInfo: selectors.getRemainingInfo(state, challengeId),
            competitionScore: selectors.getCompetitionScore(state, challengeId),
            isLoading: selectors.isLoadingChallengeInfo(state, challengeId) ||
                isLeaving || isDeleting,
            isDeleting,
            isLeaving,
            isPrivacyVisible: settingsSelectors.isPrivacyVisibleInEntity(state, settingsConstants.PRIVACY_SLUGS.CHALLENGES),
            role: coreSelectors.getCurrentUserRole(state),
            isExtendedRole: coreSelectors.isUserExtendedRole(state),
            isBonusChallenge: ownProps.challengeId
                ? selectors.isBonusChallenge(state, ownProps.challengeId)
                : _.get(ownProps, 'goal.isBonusChallenge'),
            leaderboard: selectors.getChallengeLeaderboard(state, challengeId),
            userId: coreSelectors.getCurrentUserId(state),
            challengeType: selectors.getChallengeType(state, challengeId),
            isSmartModeGoal: selectors.isSmartModeGoal(state, challengeId),
            ...locationState
        };
    };

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(ChallengeDetailsBase));
}

export const styles = {
    cardBody: {
        flex: 1,
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'stretch'
    },
    zeroParticipantsText: {
        color: baseColors.grey40,
        paddingTop: spacing.s5,
        paddingBottom: spacing.s5
    }
};
