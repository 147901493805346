import React, { PureComponent } from 'react';
import MUICard from '@material-ui/core/Card';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { StyleSheet, css } from 'aphrodite-jss';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { withTheme } from '@material-ui/styles';
import { spacing } from '../../../../styles';

class Card extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        customTheme: PropTypes.object
    };

    static defaultProps = {
        className: null,
        customTheme: null
    };


    get theme() {
        return {
            ...this.props.theme,
            palette: {
                ...this.props.theme.palette,
                primary: this.props.theme.palette.secondary
            },
            overrides: {
                MuiPaper: {
                    rounded: {
                        borderRadius: spacing.s1
                    },
                    elevation1: {
                        boxShadow: '0 1px 2px 0 rgba(0,0,0,.2), 0 0 0 1px rgba(0,0,0,.05)'
                    },
                },
            },
        };
    }
    render() {
        return (
            <ThemeProvider theme={this.props.customTheme ? this.props.customTheme : this.theme}>
                <MUICard {...this.props} className={classnames(css(styles.card), this.props.className)} />
            </ThemeProvider>
        );
    }
}

export default withTheme(Card);

export const styles = StyleSheet.create({
    card: {
        marginBottom: spacing.s2,
        textAlign: 'left'
    },
});