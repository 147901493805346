import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite-jss';
import classNames from 'classnames';
import { baseColors, appFonts, fontSize, media, spacing, subHeaderMinHeight, commonPadding, layoutStyle } from '../../../styles';
import CloseButton from './CloseButton';

const SubHeader = ({ left, right, titleAlign, hasBackButton, backButtonColor, history, title, children, titleClassName, isDeletionWarning,
    className, hasBorder, additionalSearchBar, titleSize, isModal, onModalClose, noLeftPadding, noTopPadding, isActivityTrackingSubheader, libraryHeaderDescriptionText }) => {
    const titleNode = (
        <div className={classNames(css(styles.title, styles[SubHeader.SIZES[titleSize]], styles[SubHeader.ALIGN[titleAlign]]),
            { [css(styles.leftPadding)]: (!left && !hasBackButton && !noLeftPadding) },
            { [css(styles.headerTopPadding)]: !noTopPadding },
            { [css(styles.activityTrackingSubheaderText)]: isActivityTrackingSubheader },
            titleClassName)}>
            {title}
        </div>
    );
    return (
        <React.Fragment>
            <div className={classNames(css(styles.subHeader, isActivityTrackingSubheader && styles.activityTrackingSubheader, libraryHeaderDescriptionText && styles.subHeaderWithSearch),
                { [css(styles.border)]: hasBorder }, !libraryHeaderDescriptionText && { [css(styles.maxHeight)]: !additionalSearchBar }, className)}>
                <div className={css(styles.leftContainer, isDeletionWarning && layoutStyle.fw)}>
                    {hasBackButton ? (
                        <CloseButton onClick={onModalClose || history.goBack} isModal={isModal} color={backButtonColor} />
                    ) : (
                        <React.Fragment>
                            {left ? left : titleNode}
                        </React.Fragment>
                    )}
                    {title && (hasBackButton || left) ? titleNode : null}
                </div>
                <div className={css(libraryHeaderDescriptionText && styles.searchContainer)}>
                    {libraryHeaderDescriptionText && <p className={css(styles.descriptionHeaderText)}>{libraryHeaderDescriptionText}</p>}
                    {right ? right : <span />}
                </div>
            </div>
            {children ? children : null}
            {additionalSearchBar ? (
                <div className={css(styles.additionalSearchBar)}>
                    {additionalSearchBar}
                </div>
            ) : null}
        </React.Fragment>
    );
};

SubHeader.SIZES = Object.freeze({
    small: 'small',
    medium: 'medium',
    large: 'large',
    xlarge: 'xlarge',
});

SubHeader.ALIGN = Object.freeze({
    left: 'left',
    center: 'center',
    right: 'right'
});

SubHeader.propTypes = {
    left: PropTypes.node,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    children: PropTypes.node,
    right: PropTypes.node,
    history: PropTypes.object.isRequired,
    titleAlign: PropTypes.oneOf(_.values(SubHeader.ALIGN)),
    hasBackButton: PropTypes.bool,
    backButtonColor: PropTypes.string,
    hasBorder: PropTypes.bool,
    titleClassName: PropTypes.string,
    className: PropTypes.string,
    additionalSearchBar: PropTypes.node,
    titleSize: PropTypes.oneOf(_.values(SubHeader.SIZES)),
    isModal: PropTypes.bool,
    onModalClose: PropTypes.func,
    noLeftPadding: PropTypes.bool,
    noTopPadding: PropTypes.bool,
    isActivityTrackingSubheader: PropTypes.bool,
    isDeletionWarning: PropTypes.bool,
    libraryHeaderDescriptionText: PropTypes.string.isRequired
};

SubHeader.defaultProps = {
    left: undefined,
    title: '',
    children: null,
    backButtonColor: baseColors.secondary,
    right: undefined,
    titleAlign: SubHeader.ALIGN.left,
    hasBackButton: false,
    hasBorder: false,
    titleClassName: '',
    className: '',
    additionalSearchBar: undefined,
    titleSize: SubHeader.SIZES.xlarge,
    isModal: false,
    onModalClose: null,
    noLeftPadding: false,
    noTopPadding: false,
    isActivityTrackingSubheader: false,
    isDeletionWarning: false,
};

export const MIN_HEIGHT = subHeaderMinHeight; // equals android navbar height

/* eslint-disable */  //new eslint version doesnt include ingore unused styles rule. Should be fixed by not saving unused styles :/
const styles = StyleSheet.create({
    subHeader: {
        display: 'flex',
        color: baseColors.white,
        minHeight: MIN_HEIGHT,
        marginBottom: spacing.s3,
        alignItems: 'center'
    },
    subHeaderWithSearch: {
        flexDirection: 'column',
        alignItems: 'unset'
    },
    activityTrackingSubheader: {
        position: 'sticky',
        top: -spacing.s3,
        backgroundColor: 'white',
        paddingBottom: 0,
        zIndex: 1
    },
    activityTrackingSubheaderText: {
        paddingTop: 0,
        fontSize: `${fontSize.medium}px !important`,
        height: 15
    },
    headerTopPadding: {
        paddingTop: spacing.s3,
    },
    leftContainer: {
        display: 'flex',
        alignItems: 'center',
        flexGrow: 2
    },
    leftPadding: {
        paddingLeft: commonPadding
    },
    maxHeight: {
        height: MIN_HEIGHT,
    },
    title: {
        display: 'flex',
        color: baseColors.black,
        flexGrow: 2,
    },
    [SubHeader.SIZES.xlarge]: {
        ...appFonts.xlBold
    },
    [SubHeader.SIZES.small]: {
        ...appFonts.smBold,
    },
    [SubHeader.SIZES.medium]: {
        ...appFonts.mdBold,
    },
    [SubHeader.SIZES.large]: {
        ...appFonts.lgBold,
    },
    [SubHeader.ALIGN.left]: {
        justifyContent: 'flex-start'
    },
    [SubHeader.ALIGN.center]: {
        justifyContent: 'center'
    },
    [SubHeader.ALIGN.right]: {
        justifyContent: 'flex-end'
    },
    border: {
        borderBottom: `1px solid ${baseColors.grey80}`,
    },
    additionalSearchBar: {
        display: 'none',
        [media.xsOnly]: {
            display: 'block'
        }
    },
    searchContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
        marginTop: spacing.s3,
        marginBottom: spacing.s5
    },
    descriptionHeaderText: {
        ...appFonts.mdMedium,
        color: baseColors.grey40,
        marginRight: spacing.s3
    }
});
/* eslint-enable */

export default withRouter(SubHeader);
