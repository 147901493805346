import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite-jss';
import { baseColors, spacing, appFonts, media } from '../../../../styles';
import { ROUTES, components as Core, onKeyPress, AsyncComponent, Modal } from '../../../core';
import WithAppsDevicesDashboardStatusBase, { styles as baseStyles } from './AppsDevicesDashboardStatusBase';

class AppsDevicesDashboardStatus extends Component {
    static propTypes = {
        history: PropTypes.object.isRequired,
        isAppOrDeviceConnected: PropTypes.bool.isRequired,
        isAppsOrDevicesAvailable: PropTypes.bool.isRequired,
        isAppOrDeviceInError: PropTypes.bool.isRequired,
        isAppOrDeviceInWarning: PropTypes.bool.isRequired,
        appsDevicesStatusIcon: PropTypes.object.isRequired,
        statusAlertType: PropTypes.string.isRequired,
        isFirstLoadedAppsDevices: PropTypes.bool.isRequired,
        i18n: PropTypes.object.isRequired,
        lng: PropTypes.string.isRequired,
        lastSyncDate: PropTypes.string
    };

    static defaultProps = {
        lastSyncDate: '',
    };

    onAppsDevicesPress = () => {
        const { isAppOrDeviceConnected, isAppOrDeviceInError, isAppOrDeviceInWarning } = this.props;
        if (isAppOrDeviceConnected) {
            if (isAppOrDeviceInError || isAppOrDeviceInWarning) {
                this.onPresentErrorWarningToolTip();
            } else {
                this.props.history.push(ROUTES.appsDevices());
            }
        } else {
            this.onPresentConnectADeviceToolTip();
        }
    };


    onPresentErrorWarningToolTip = () => {
        this.closeModal = Modal.open(
            AsyncComponent(() => import('../AppsDevicesStatusAlert')),
            {
                type: this.props.statusAlertType,
                actionCallback: this.onPerfomAppsDevicesAction,
                closeCallback: this.onDismissAppsDevices,
                iconRef: this.statusIconRef
            },
            { isContainer: true, isTransparent: true, isNoPaddingHorizontal: true, isFlexAlignStart: true, isNoWidthLimit: true }
        );
    }

    onPresentConnectADeviceToolTip = () => {
        this.closeModal = Modal.open(
            AsyncComponent(() => import('../AppsDevicesStatusAlert')),
            {
                type: this.props.statusAlertType,
                actionCallback: this.onPerfomAppsDevicesAction,
                closeCallback: this.onDismissAppsDevices,
                iconRef: this.statusIconRef
            },
            { isContainer: true, isTransparent: true, isNoPaddingHorizontal: true, isFlexAlignStart: true, isNoWidthLimit: true }
        );
    }

    onDismissAppsDevices = () => {
        this.closeModal();
    };

    onPerfomAppsDevicesAction = () => {
        this.props.history.push(ROUTES.appsDevices());
    };

    saveStatusIconRef = ref => (this.statusIconRef = ref);

    formatLastSyncDate = () => {
        const syncDate = this.props.lastSyncDate;
        const timestamp = syncDate ? moment(syncDate).fromNow() : '--';

        return `${timestamp}`;
    };

    get lastSyncStatus() {
        const { isAppOrDeviceConnected, isAppOrDeviceInError, isAppOrDeviceInWarning, i18n } = this.props;
        if (isAppOrDeviceConnected) {
            if (isAppOrDeviceInError) {
                return i18n.t('appsDevices.statusAlert.syncingError');
            } else if (isAppOrDeviceInWarning) {
                return i18n.t('appsDevices.statusAlert.syncingIssue');
            }

            return null;
        }
        return i18n.t('appsDevices.statusAlert.connectAction');
    }

    get renderStatusDescription() {
        const { isAppOrDeviceConnected, lastSyncDate, isAppOrDeviceInError, isAppOrDeviceInWarning } = this.props;

        let color = baseColors.grey20;
        if (isAppOrDeviceInError) {
            color = baseColors.dangerDarker;
        } else if (isAppOrDeviceInWarning) {
            color = baseColors.warnDarker;
        }

        return (
            <div className={css(styles.statusDescriptionContainer)}>
                <p
                    className={css(isAppOrDeviceConnected ? styles.syncStatus : styles.connectDevice)}
                    style={{ color }}
                    id="sync-status"
                >
                    {this.lastSyncStatus}
                </p>
                <p id="last-sync" className={css(styles.syncDate)} style={{ color: baseColors.grey40 }}>
                    {lastSyncDate && (`${this.props.i18n.t('lastSync')} ${this.formatLastSyncDate()}`)}
                </p>
            </div>
        );
    }

    render() {
        const { isAppsOrDevicesAvailable, appsDevicesStatusIcon, isFirstLoadedAppsDevices } = this.props;

        if (!isAppsOrDevicesAvailable) {
            return <div />;
        }

        return (
            <div
                tabIndex="0"
                id="dashboard-devices-section"
                role="button"
                onKeyDown={onKeyPress.enter(this.onAppsDevicesPress)}
                className={css(styles.appsDevicesSection)}
                onClick={isFirstLoadedAppsDevices ? this.onAppsDevicesPress : null}>
                {isFirstLoadedAppsDevices ?
                    <React.Fragment>
                        <div
                            id="watch-icon-container"
                            ref={this.saveStatusIconRef}
                            className={css(styles.appsDevicesIcon, styles.appsDevicesIconPadding)}
                        >
                            <Core.Icon
                                type="fa"
                                name="watch-fitness"
                                size={spacing.s6}
                                color={baseColors.black}
                            />
                        </div>
                        <Core.Icon
                            type="fa"
                            name={appsDevicesStatusIcon.name}
                            fill={appsDevicesStatusIcon.type}
                            size={spacing.s3}
                            color={appsDevicesStatusIcon.color}
                        />
                        {this.renderStatusDescription}
                    </React.Fragment> :
                    <Core.SkeletonRect
                        width={64}
                        height={spacing.s5}
                    />
                }
            </div>
        );
    }
}

export default withRouter(WithAppsDevicesDashboardStatusBase(AppsDevicesDashboardStatus));

const styles = StyleSheet.create({
    ...baseStyles,
    appsDevicesSection: {
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
        alignItems: 'center',
        '-ms-grid-column': 1,
    },
    appsDevicesIcon: {
        display: 'flex',
        alignItems: 'center',
    },
    statusDescriptionContainer: {
        marginLeft: spacing.s2,
    },
    syncStatus: {
        ...appFonts.smMedium,
    },
    syncDate: {
        ...appFonts.smRegular,
        [media.sm]: {
            ...appFonts.xsRegular,
        },
    },
    connectDevice: {
        ...appFonts.smRegular,
    },
});
