import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { withRouter } from 'react-router-dom';
import { isIE } from 'react-device-detect';
import { spacing, baseColors, layoutStyle, importantClass, appFonts } from '../../../../styles';
import { AsyncComponent, components as Core, Modal, tracker } from '../../../core';
import WithMyDayBase, { styles as baseStyles } from './MyDayBase';

class MyDay extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool,
        myDayHeading: PropTypes.string.isRequired,
        earnedToday: PropTypes.number.isRequired,
        stepsToday: PropTypes.number.isRequired,
        stepsUnit: PropTypes.string.isRequired,
        totalPtsUnit: PropTypes.string.isRequired,
        trackActivityText: PropTypes.string.isRequired,
        skeletonProps: PropTypes.object.isRequired,
        isLiveBetter: PropTypes.bool,
        onButtonPress: PropTypes.func,
        i18n: PropTypes.object.isRequired
    };

    static defaultProps = {
        isLoading: false,
        isLiveBetter: false,
        onButtonPress: null
    };

    onTrackActivity = () => {
        tracker.logEvent('track_activity');
        Modal.open(
            AsyncComponent(() => import('../../../challenges/components/TrackActivityForm')),
            {
                isModal: true,
                trackActivityTitle: this.props.trackActivityText
            },
            {
                isNoPadding: true,
                isContainer: true,
                modalHidden: true,
                isMaxWidthLimited: true,
                fadeTransition: true,
            },
        );
    };

    get pointsStepsSummary() {
        const { earnedToday, totalPtsUnit, stepsToday, stepsUnit } = this.props;

        return (
            <div className={css(styles.ptsStepsContainer, isIE && styles.ptsStepsContainerIE)}>
                <div className={css(layoutStyle.flexColumnCenter, isIE && styles.totalPtsIE)}>
                    <Core.Icon
                        type="fa"
                        name="stars"
                        fill="light"
                        color={baseColors.primary}
                        size={spacing.s5}
                    />
                    {this.props.isLoading ? <Core.SkeletonRect {...this.props.skeletonProps} className={css(styles.skeleton)} /> :
                        <span className={css(styles.value)}>{earnedToday}</span>}
                    <span className={css(styles.valueUnit)}>{totalPtsUnit}</span>
                </div>
                <div className={css(layoutStyle.flexColumnCenter)}>
                    <Core.Icon
                        type="fa"
                        name="shoe-prints"
                        fill="light"
                        color={baseColors.primary}
                        size={spacing.s5}
                    />
                    {this.props.isLoading ? <Core.SkeletonRect {...this.props.skeletonProps} className={css(styles.skeleton)} /> :
                        <span className={css(styles.value)}>{stepsToday}</span>}
                    <span className={css(styles.valueUnit)}>{stepsUnit}</span>
                </div>
            </div>
        );
    }

    get trackActivityBtn() {
        return (
            <Core.Button
                size={Core.Button.SIZES.medium}
                className={css(styles.button)}
                onPress={this.onTrackActivity}
                disableRipple={true}
                fullWidth={true}>
                <Core.Icon name="plus-circle" type="light" size={spacing.s5} color={baseColors.white} className={css(styles.plusIcon)} />
                {this.props.trackActivityText}
            </Core.Button>
        );
    }

    render() {
        const { isLiveBetter, onButtonPress, i18n } = this.props;
        return (
            <div className={css(styles.myDayContainer)}>
                {this.trackActivityBtn}
                <Core.Button
                    fullWidth={true}
                    type="outlined"
                    className={css(styles.button)}
                    onPress={onButtonPress}
                    size="medium"
                    id="leaderboard-btn-content"
                    disableRipple={true}>
                    {!isLiveBetter ? (
                        <>
                            <p id="leaderboard-btn-text" className={css(styles.leaderboardBtnText)}>
                                {i18n.t('dashboard.viewLeaderboard')}
                            </p>
                        </>
                    )
                        : (
                            <>
                                <span className={css(styles.textWrapper, layoutStyle.flexJustifyCenter)} />
                                <p id="leaderboard-btn-text" className={css(styles.leaderboardBtnText)}>
                                    {i18n.t('dashboard.howToEarn')}
                                </p>
                            </>
                        )}
                </Core.Button>
            </div>
        );
    }
}

export default withRouter(WithMyDayBase(MyDay));

const styles = StyleSheet.create({
    ...baseStyles,
    myDayContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
    },
    ptsStepsContainerIE: {
        justifyContent: 'center',
    },
    totalPtsIE: {
        marginRight: spacing.s11,
    },
    button: importantClass({
        height: spacing.s12-2,
        marginTop: spacing.s1,
        ...appFonts.mdMedium
    }),
    textWrapper: {
        paddingLeft: spacing.s4
    },
    plusIcon: {
        marginRight: spacing.s2
    }
});
