import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { appFonts, baseColors, spacing } from '../../../../styles';
import { translate } from '../../../core';
import { isRewardsEnabled, getRewards } from '../../selectors';
import { selectors as authSelectors } from '../../../auth';

export const ARROW_ICON_SIZE = 28;

export default function WithRewardsBalanceBase(WrappedComponent) {
    class RewardsBalanceBase extends PureComponent {
        static propTypes = {
            rewards: PropTypes.object.isRequired,
            isRewardsEnabled: PropTypes.bool.isRequired,
            rewardName: PropTypes.string.isRequired,
            i18n: PropTypes.object.isRequired,
            fullProgramName: PropTypes.string,
            programName: PropTypes.string
        };

        static defaultProps = {
            fullProgramName: '',
            programName: ''
        };

        get numRedeemable() {
            return _.get(this.props.rewards, 'numRedeemable', 0);
        }

        get rewardName() {
            return _.get(this.props.rewards, 'externalRewardName', '');
        }

        get rewardsButtonText() {
            return this.props.i18n.t('rewardsHowDoIEarn', { currencyName: this.rewardName });
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    numRedeemable={this.numRedeemable}
                    rewardsButtonText={this.rewardsButtonText}
                />
            );
        }
    }

    function mapStateToProps(state) {
        return {
            rewards: getRewards(state),
            isRewardsEnabled: isRewardsEnabled(state),
            programName: authSelectors.getProgramName(state)
        };
    }

    return connect(mapStateToProps)(translate()(RewardsBalanceBase));
}

const CONTAINER_BORDER_RADIUS = 8;

export const SHADOW = { opacity: 0.15, height: 8 };
export const styles = {
    rewardsBalanceContainer: {
        backgroundColor: baseColors.primary,
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3,
        paddingTop: spacing.s2,
        paddingBottom: spacing.s2,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginLeft: spacing.s3,
        marginRight: spacing.s3,
        borderRadius: CONTAINER_BORDER_RADIUS
    },
    rewardsBalance: {
        alignItems: 'flex-start'
    },
    rewardsBalanceLearn: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    rewardsMainText: {
        ...appFonts.smBold,
        marginRight: spacing.s0
    },
    rewardsCommonText: {
        ...appFonts.xsMedium
    },
    whiteText: {
        color: baseColors.white
    }
};
