import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import _ from 'lodash';
import classNames from 'classnames';
import AddedUserItem from '../AddedUserItem/AddedUserItem';
import { components as Core } from '../../../core/index';
import WithAddedUsersList, { styles as baseStyles } from './AddedUsersListBase';

class AddedUsersList extends PureComponent {
    static propTypes = {
        mainContainerClassName: PropTypes.string,
        showLoader: PropTypes.bool,
        data: PropTypes.array,
        removeUserHandler: PropTypes.func,
        isUserInvitation: PropTypes.bool
    };

    static defaultProps = {
        mainContainerClassName: null,
        showLoader: false,
        data: [],
        removeUserHandler: null,
        isUserInvitation: true
    }

    renderItem = item => <AddedUserItem
        key={item.id}
        item={item}
        removeUserHandler={this.props.removeUserHandler}
        isUserInvitation={this.props.isUserInvitation}
    />;

    render() {
        return (
            <div className={css(styles.container)}>
                {
                    this.props.showLoader ?
                        <div className={css(styles.loaderWrapper)}>
                            <Core.ListLoading />
                        </div> : null
                }
                <div className={classNames(css(styles.mainContainer, styles.list, this.props.data.length && styles.offsetTop), this.props.mainContainerClassName)} >
                    {_.map(this.props.data, this.renderItem)}
                </div>
            </div>);
    }
}

const styles = StyleSheet.create({
    ...baseStyles,
    mainContainer: {
        ...baseStyles.mainContainer,
        display: 'flex',
        flexWrap: 'wrap'
    },
    loaderWrapper: {
        ...baseStyles.loaderWrapper,
        width: '100%'
    }
});

export default WithAddedUsersList(AddedUsersList);
