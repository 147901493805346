import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { selectors as coreSelectors, translate } from '../../../core';
import { isSendingPlatformInvitation, isDeletingPlatformInvitation }  from '../../selectors';
import { selectors as otherSelectors } from '../../../other';
import * as actions from '../../actions';
import { appFonts, baseColors, spacing } from '../../../../styles';
import { selectors as authSelectors } from '../../../auth';

export const USER_PLUS_ICON = 'user-plus';
export const USER_CHECK_ICON = 'user-check';

export default function WithFriendsAndFamilyBase(WrappedComponent) {
    class FriendsAndFamilyBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            userData: PropTypes.object,
            isFriendsAndFamilyEnabled: PropTypes.bool
        };

        static defaultProps = {
            userData: {},
            isFriendsAndFamilyEnabled: false
        };

        constructor(props) {
            super(props);
            this.state = {
            };
        }

        addToFriendsAndFamily = () => {
            const { userData } = this.props;
            this.props.actions.sendPlatformInvitations(null, userData.userId);
        };

        removeFromFriendsAndFamily = () => {
            const { userData } = this.props;
            this.props.actions.deletePlatformInvitation(userData.friendsAndFamilyInviteId, userData.firstName, userData.userId);
        };


        get modalProps() {
            return {
                iconName: USER_PLUS_ICON,
                title: this.props.i18n.t('friendsAndFamily.learnModal.title'),
                buttonTitle: this.props.i18n.t('got_it'),
                iconBackgroundColor: baseColors.primary,
            };
        }

        render() {
            return this.props.isFriendsAndFamilyEnabled ? (
                <WrappedComponent
                    {...this.props}
                    modalProps={this.modalProps}
                    addToFriendsAndFamily={this.addToFriendsAndFamily}
                    removeFromFriendsAndFamily={this.removeFromFriendsAndFamily}
                />
            ) : null;
        }
    }

    function mapStateToProps(state, ownProps) {
        const userData = otherSelectors.getUserDetails(state, ownProps.id);
        return {
            isAdding: isSendingPlatformInvitation(state, ownProps.id),
            isDeleting: isDeletingPlatformInvitation(state, _.get(userData, 'friendsAndFamilyInviteId')),
            id: ownProps.id,
            userData,
            isFriendsAndFamilyEnabled: coreSelectors.isFriendsAndFamilyEnabled(state),
            programName: authSelectors.getProgramName(state),
            currentUserId: coreSelectors.getCurrentUserId(state),
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        };
    }
    return connect(mapStateToProps, mapDispatchToProps)(translate()(FriendsAndFamilyBase));
}

export const styles = {
    learnMore: {
        marginTop: spacing.s1
    },
    learnMoreText: {
        color: baseColors.secondary,
        ...appFonts.smMedium
    },
    learnText: {
        ...appFonts.mdRegular
    },
    learnIconsMean: {
        ...appFonts.mdMedium,
        textAlign: 'center',
        marginTop: spacing.s4
    },
    learnIconsContainer: {
        flexDirection: 'row',
        justifyContent: 'center'
    },
    learnIconItem: {
        marginTop: spacing.s3,
        marginLeft: spacing.s3,
        marginRight: spacing.s3,
        alignItems: 'center'
    },
    learnIconText: {
        ...appFonts.smRegular,
        marginTop: spacing.s0
    },
    friendsContainer: {
        flex: 0,
        alignItems: 'flex-start'
    },
    friendsButton: {
        marginTop: spacing.s3,
        marginBottom: spacing.s1,
    },
    friendIcon: {
        marginRight: spacing.s1
    }
};
