import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from './Icon';
import { navButtonSize } from '../../../styles';
import Button from './Button';

export const NavButtonIcon = ({ onClick, isSmall, name, ...props }) => (
    <IconButton
        type="fa"
        name={name}
        size={navButtonSize}
        color="secondary"
        onClick={onClick}
        isSmall={isSmall}
        {...props}
    />
);
NavButtonIcon.propTypes = { onClick: PropTypes.func, isSmall: PropTypes.bool, name: PropTypes.string.isRequired };
NavButtonIcon.defaultProps = { onClick: undefined, isSmall: false };

export const NavButtonText = ({ onClick, text }) => (
    <Button type="text" size="medium" onPress={onClick}>{text}</Button>
);
NavButtonText.propTypes = { onClick: PropTypes.func, text: PropTypes.string.isRequired };
NavButtonText.defaultProps = { onClick: undefined };
