import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import { css, StyleSheet } from 'aphrodite-jss';
import { withRouter } from 'react-router-dom';
import * as Card from '../Card';
import { ROUTES } from '../../../core';
import ChallengeCardBody from './ChallengeCardBody';
import ChallengeCardFooter from './ChallengeCardFooter';
import ChallengeCardHeader from './ChallengeCardHeader';
import WithChallengeCardBase, { styles as baseStyles } from './ChallengeCardBase';
import { containerStyle, layoutStyle, spacing } from '../../../../styles';
import { checkItemBounds } from '../../services/helper';

const ANIMATION_STYLES = {
    cursor: 'pointer',
    position: 'absolute',
    top: spacing.s0,
    paddingBottom: spacing.s1,
};

const ITEM_OFFSET = 5;
class ChallengeCard extends PureComponent {
    static propTypes = {
        challengeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        onWeeklyGoalGraceCardClick: PropTypes.func.isRequired,
        isRestartGoalInGracePeriod: PropTypes.bool.isRequired,
        onWeeklyGoalGraceCardView: PropTypes.func.isRequired,
        animation: PropTypes.object.isRequired,
        isDisabled: PropTypes.bool.isRequired,
        history: PropTypes.object.isRequired,
        activeSlideIndexes: PropTypes.array,
        onPress: PropTypes.func.isRequired,
        isHomeCarousel: PropTypes.bool,
        showAnimation: PropTypes.bool,
        slideIndex: PropTypes.number,
        streakDetails: PropTypes.string,
        isBonusChallenge: PropTypes.number
    };

    static defaultProps = {
        activeSlideIndexes: [],
        isHomeCarousel: false,
        showAnimation: false,
        slideIndex: 0,
        streakDetails: null,
        isBonusChallenge: 0
    };
    constructor(props) {
        super(props);
        this.childRef = React.createRef();
    }

    componentDidUpdate() {
        const { isRestartGoalInGracePeriod, activeSlideIndexes, slideIndex, onWeeklyGoalGraceCardView } = this.props;
        const [min, max] = activeSlideIndexes;
        if (isRestartGoalInGracePeriod && slideIndex >= min && slideIndex <= max) {
            onWeeklyGoalGraceCardView(slideIndex);
        }
    }

    onPress = () => {
        const { isDisabled, onPress, history, challengeId, isRestartGoalInGracePeriod, onWeeklyGoalGraceCardClick } = this.props;
        if (isDisabled) return;
        onPress();
        if (isRestartGoalInGracePeriod) onWeeklyGoalGraceCardClick();
        history.push(ROUTES.challengeDetails(challengeId));
    };

    get renderStreak() {
        const { streakDetails, isBonusChallenge } = this.props;
        if (!streakDetails) return null;

        return <p className={css(styles.streakText, isBonusChallenge? styles.mTop: null)}>{streakDetails}</p>;
    }

    render() {
        const { challengeId, isHomeCarousel, isRestartGoalInGracePeriod, animation, showAnimation } = this.props;
        const lottieDefaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animation,
            rendererSettings: { preserveAspectRatio: 'none' },
        };
        const tabIndexValue = checkItemBounds(this.props.carouselRef, this.childRef, ITEM_OFFSET);

        return (
            <div ref={this.childRef}>
                <Card.Container onPress={this.onPress} containerClassName={css(layoutStyle.pRelative, containerStyle.listItemfocusVisibleWithSmallerOutlineOffset)} tabIndex={tabIndexValue}>
                    {!isRestartGoalInGracePeriod && <ChallengeCardHeader challengeId={challengeId} />}
                    {this.renderStreak}
                    <ChallengeCardBody challengeId={challengeId} />
                    {showAnimation ? (
                        <Lottie
                            isClickToPauseDisabled={true}
                            options={lottieDefaultOptions}
                            style={ANIMATION_STYLES}
                        />
                    ) : null}
                    <ChallengeCardFooter challengeId={challengeId} isHomeCarousel={isHomeCarousel} datesSwiperTabIndex={null} buttonsTabIndex={null} />
                </Card.Container>
            </div>
        );
    }
}

export default withRouter(WithChallengeCardBase(ChallengeCard));

const styles = StyleSheet.create({
    ...baseStyles,
    mTop: {
        marginTop: spacing.s1
    },
});
