import React, { PureComponent } from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import PropTypes from 'prop-types';
import { modalStyle, layoutStyle, importantClass, media } from '../../../../styles';
import { components as Core } from '../../../core';
import WithSkipToutModalBase, { styles as baseStyles } from './SkipTourModalBase';

class SkipTourModal extends PureComponent {
    static propTypes = {
        onCancel: PropTypes.func.isRequired,
        onSkip: PropTypes.func.isRequired,
        i18n: PropTypes.object.isRequired,
    };

    render() {
        const { i18n, onCancel, onSkip } = this.props;
        return (
            <div className={css(styles.containerCenter)}>
                <div className={css(modalStyle.bodyContainer, styles.screenContainer)}>
                    <p className={css(styles.title)}>{this.props.i18n.t('productTour.skip.title')}</p>
                    <p className={css(styles.description)}>{this.props.i18n.t('productTour.skip.body')}</p>
                    <div className={css(styles.buttonContainer, layoutStyle.flex)}>
                        <Core.Button
                            onPress={onCancel}
                            type="outlined"
                            className={css(styles.cancelButton)}>
                            {i18n.t('button_cancel')}
                        </Core.Button>
                        <Core.Button
                            onPress={onSkip}
                            className={css(styles.skipButton)}>
                            {i18n.t('skip')}
                        </Core.Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default WithSkipToutModalBase(SkipTourModal);

const styles = StyleSheet.create({
    ...baseStyles,
    cancelButton: importantClass({
        ...baseStyles.cancelButton,
    }),
    skipButton: importantClass({
        ...baseStyles.skipButton,
    }),
    containerCenter: {
        ...importantClass({
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            [media.sm]: {
                width: '100%',
            }
        }),
        zIndex: 2000
    },
    screenContainer: {
        width: '50%',
        alignSelf: 'center',
        justifyContent: 'center',
    }
});
