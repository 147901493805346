import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import _ from 'lodash';

import WithPrivacySettingsBase, { styles as baseStyles } from './PrivacySettingsBase';
import { spacing, appFonts } from '../../../../styles';
import { AsyncComponent, components as Core, Modal } from '../../../core';

class PrivacySettings extends PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        privacyOptionsList: PropTypes.array.isRequired,
        isPage: PropTypes.bool,
        isLoading: PropTypes.bool,
        title: PropTypes.string.isRequired,
    };

    static defaultProps = {
        isPage: true,
        isLoading: false
    };

    onPrivacySettingsItemClick = item => () => {
        Modal.open(AsyncComponent(() => import('./PrivacySettingsItem')), { slug: item.slug, item }, {
            cancelable: true,
            isNoPadding: false,
            isContainer: true,
            isFullHeight: false
        });
    };

    get emptyListMessage() {
        return (
            <Core.EmptyListSimple
                message={this.props.i18n.t('noPrivacySettings')}
                className={css(styles.emptyList)}
            />
        );
    }

    renderListItem = ({ item }) => {
        const { data, title } = item;
        return (
            <div className={css(styles.blockItemsContainer)}>
                {this.renderHeader(title)}
                {_.map(data, this.renderItem)}
            </div>
        );
    };

    renderItem = item => (
        <Core.ListOptionPopup
            key={item.slug}
            title={item.title}
            action={this.onPrivacySettingsItemClick(item)}
            rightIcon={(
                <Core.Icon
                    name={item.rightTitleIcon}
                    type="fa"
                    fill={item.iconType}
                    color={item.iconColor}
                    size={spacing.s3}
                />
            )}
        />
    );

    renderHeader = title => (<div className={css(styles.titleItem)}>{title}</div>);

    keyExtractor = item => item.slug;

    get wrapper() {
        return this.props.isPage ? Core.SmallerContainer : React.Fragment;
    }
    render() {
        const { title } = this.props;
        return (
            <this.wrapper widerHeaderComponent={<Core.EntityDetailsHeader hasBackButton={this.props.isPage} title={title} />}>
                <Core.SubHeader
                    title={title}
                    noTopPadding={true}
                    noLeftPadding={true}
                />
                <Core.InfiniteLazyListFadeIn
                    data={this.props.privacyOptionsList}
                    keyExtractor={this.keyExtractor}
                    renderItem={this.renderListItem}
                    onEndReachedThreshold={0.5}
                    isLoading={this.props.isLoading}
                    ListEmptyComponent={this.emptyListMessage}
                    ListHeaderComponent={this.tabsComponent}
                    hasMarginHorizontal={false}
                />
            </this.wrapper>
        );
    }
}

const styles = StyleSheet.create({
    ...baseStyles,
    titleItem: {
        ...appFonts.mdBold,
        justifyContent: 'flex-start',
    },
    blockItemsContainer: {
        marginBottom: spacing.s4
    }
});

export default WithPrivacySettingsBase(PrivacySettings);
