import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite-jss';
import Grid from '@material-ui/core/Grid';
import { components as Core, ROUTES, openUrl, tracker, onKeyPress } from '../../../core';
import { PRODUCT_TYPES } from '../../constants';
import ProductsList from '../ProductsList';
import RewardCarouselItem from '../RewardCarouselItem';
import WithRewardsBase, { styles as baseStyles } from './RewardsBase';
import { layoutStyle, spacing, importantClass, baseColors } from '../../../../styles';
import DashboardShopPointsItem from '../Dashboard/DashboardShopPointsItem';

class Rewards extends PureComponent {
    static propTypes = {
        rewards: PropTypes.object.isRequired,
        activeTab: PropTypes.object.isRequired,
        isVisibleMarketingUrl: PropTypes.bool,
        i18n: PropTypes.object.isRequired,
        tabs: PropTypes.array.isRequired,
        onTabPress: PropTypes.func.isRequired,
        listCategory: PropTypes.string.isRequired,
        listType: PropTypes.string,
        marketingUrl: PropTypes.string.isRequired,
        productsAmount: PropTypes.number.isRequired,
        isExternalCatalog: PropTypes.bool.isRequired,
        history: PropTypes.object.isRequired,
        featuredProductsCount: PropTypes.number,
        featuredProducts: PropTypes.array,
        headerTitle: PropTypes.string.isRequired,
        linksText: PropTypes.object.isRequired,
        categoryId: PropTypes.number.isRequired
    };

    static defaultProps = {
        listType: null,
        isVisibleMarketingUrl: false,
        featuredProductsCount: 0,
        featuredProducts: []
    };

    goToProductsList = (type, title) => {
        this.props.history.push(ROUTES.productsList(), { type, title });
    };

    goFullListFeaturedProducts = () => {
        this.goToProductsList(PRODUCT_TYPES.FEATURED, this.props.i18n.t('promoted'));
    };

    // TODO - this feature was temporarily disable. Maybe will be enabled in future.
    goFullListRecentlyProducts = () => {
        this.goToProductsList(PRODUCT_TYPES.RECENT, this.props.i18n.t('recent'));
    };

    goProductDetails = item => {
        tracker.logEvent('rewards', { event: 'products_details', id: item.id });
        this.props.history.push(ROUTES.productDetails(item.id));
    };

    goToRewardsDetails = () => {
        tracker.logEvent('rewards', { event: 'rewards_details' });
        this.props.history.push(ROUTES.rewardsDetails());
    };

    goHowToEarn = () => {
        this.props.history.push(ROUTES.howToEarn());
    };

    get webViewComponent() {
        return (
            <React.Fragment>
                <Core.TabsWithContent
                    isStickyUnderNavBar={true}
                    isTabsContentVisible={false}
                    afterTabsComponent={this.linksComponent}
                />
                <iframe title="sprout" src={this.props.marketingUrl} className={css(styles.webView)} />
            </React.Fragment>
        );
    }

    onLearn = () => {
        const { rewards } = this.props;
        if (rewards.learnUrl) {
            openUrl(this.props.rewards.learnUrl);
        }
    }

    get headerComponent() {
        return (
            <React.Fragment>
                <Core.EntityDetailsHeader hasBackButton={true} />
                <Core.SubHeader
                    title={this.props.headerTitle}
                    noTopPadding={true}
                    right={this.topNumPoints}
                    className={styles.marginBottom}
                />
            </React.Fragment>
        );
    }

    get topNumPoints() {
        return (
            <DashboardShopPointsItem hasShoppingCart={false} hasRedirection={false} />
        );
    }

    get linksComponent() {
        const { rewards, linksText: { howToEarn, balanceHistory, learnMore } } = this.props;
        return (
            <div className={css(layoutStyle.flexColumn, styles.linksWrapper)}>
                {this.renderLink('coins', howToEarn, this.goHowToEarn)}
                {this.renderLink('history', balanceHistory, this.goToRewardsDetails)}
                {rewards.learnUrl ? this.renderLink('question-circle', learnMore, this.onLearn) : null}
            </div>
        );
    }

    renderLink = (icon, text, action) => (
        <div className={css(layoutStyle.flexRow, styles.link)}>
            <Core.Icon
                type="light"
                name={icon}
                size={spacing.s3}
                color={baseColors.secondary}
            />
            <div
                tabIndex="0"
                id={`${icon}-link`}
                role="button"
                className={css(styles.linkText)}
                onKeyDown={onKeyPress.enter(action)}
                onClick={action}>
                {text}
            </div>
        </div>
    )

    renderCarouselItem = rowData => (
        <RewardCarouselItem
            item={rowData.item}
            index={rowData.index}
            onPressSingle={this.goProductDetails}
        />
    );

    get afterTabsComponent() {
        const { featuredProducts, i18n, featuredProductsCount, recentCarousel } = this.props;
        return (
            featuredProducts.length > 0 ? (
                <Core.Carousel
                    title={i18n.t('promoted')}
                    count={featuredProductsCount}
                    mainContainerStyle={recentCarousel}
                    viewAllPress={this.goFullListFeaturedProducts}
                    separator={true}
                    itemsLength={featuredProducts.length}>
                    {_.map(featuredProducts, this.renderCarouselItem)}
                </Core.Carousel>
            )
                : null
        );
    }

    get rewardsStoreNotAvailable() {
        return (
            <React.Fragment>
                <Core.TabsWithContent
                    isStickyUnderNavBar={true}
                    isTabsContentVisible={false}
                    afterTabsComponent={this.linksComponent}>
                    <Core.EmptyListSimple message={this.props.i18n.t('rewardsStoreNotAvailable')} className={css(styles.emptyList)} />
                </Core.TabsWithContent>
            </React.Fragment>
        );
    }

    get productsListComponent() {
        const { listCategory, categoryId, listType } = this.props;
        return (
            <ProductsList
                isInline={true}
                category={listCategory}
                categoryId={categoryId}
                onPressSingle={this.goProductDetails}
                type={listType}
                twoColumns={true}
            />
        );
    }

    get footerComponent() {
        return (
            this.props.rewards.disclaimer || this.props.productsAmount ? (
                <Core.AutoLink
                    text={this.props.rewards.disclaimer}
                    className={css(styles.autoLink)}
                    renderLink={this.renderLink}
                    onPress={this.onPress}
                />
            ) : null
        );
    }

    get renderCatalog() {
        const { rewards, marketingUrl, onTabPress, tabs, activeTab } = this.props;
        return (
            !rewards.isEligible && _.isNil(marketingUrl) ?
                this.rewardsStoreNotAvailable : (
                    <React.Fragment>
                        <Core.TabsWithContent
                            onChange={onTabPress}
                            tabs={tabs}
                            barStyles={styles.stickyBar}
                            activeTab={activeTab}
                            isStickyUnderNavBar={true}
                            afterTabsComponent={this.linksComponent}>
                            {this.productsListComponent}
                        </Core.TabsWithContent>
                        <Grid container={true} justify="flex-end">
                            <Grid sm={8}>
                                {this.afterTabsComponent}
                                {this.footerComponent}
                            </Grid>
                        </Grid>
                    </React.Fragment>
                )
        );
    }

    onExternalCatalog = () => {
        tracker.logEvent('rewards', { event: 'external_catalog', url: this.props.rewards.catalogUrl });
        openUrl(this.props.rewards.catalogUrl);
    };

    get renderExternalCatalog() {
        return (
            <React.Fragment>
                <Core.TabsWithContent
                    isStickyUnderNavBar={true}
                    isTabsContentVisible={false}
                    afterTabsComponent={this.linksComponent}
                />
            </React.Fragment>
        );
    }

    get renderContent() {
        if (this.props.isExternalCatalog) {
            return this.renderExternalCatalog;
        }
        if (this.props.isVisibleMarketingUrl) {
            return this.webViewComponent;
        }
        return this.renderCatalog;
    }

    render() {
        return (
            <Core.Layout.WiderContainer>
                {this.headerComponent}
                {this.renderContent}
            </Core.Layout.WiderContainer>
        );
    }
}

export default withRouter(WithRewardsBase(Rewards));

const styles = StyleSheet.create({
    ...baseStyles,
    webView: {
        ...baseStyles.webView,
        display: 'flex',
        width: '100%',
        height: '100%',
    },
    stickyBar: importantClass({
        top: Core.TabsWithContent.TABS_OFFSET + spacing.s3
    }),
    linksWrapper: {
        marginLeft: spacing.s3,
        color: baseColors.secondary,
    },
    link: {
        marginTop: spacing.s4,
        marginBottom: spacing.s0,
    }
});