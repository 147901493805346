import _ from 'lodash';
import moment from 'moment';
import * as types from './actionTypes';
import i18n from '../../i18n/i18n';
import * as api from './api';
import * as selectors from './selectors';
import { DATE_FORMATS, PLATFORMS } from './constants';
import { AppRatingService, Platform, Storage } from './services';
import { validateError } from '../../config';

export function addError(requestId, error) {
    return {
        type: types.ADD_ERROR,
        payload: { requestId, error }
    };
}

export function removeError(requestId) {
    return {
        type: types.REMOVE_ERROR,
        payload: { requestId }
    };
}

export function addToast(type, actionType, msg, _title) {
    let toast = i18n.t(`${actionType}Toast.${type}`, { returnObjects: true });
    toast = _.isObject(toast) ? toast : {};
    const title = _title || toast.title || i18n.t(`${type}ToastTitle`);
    const message = msg || toast.message || i18n.t(`${type}ToastMessage`);
    return {
        type: types.ADD_TOAST,
        payload: { type, title, message }
    };
}

export function removeToast() {
    return { type: types.REMOVE_TOAST };
}

export function resetState() {
    return { type: types.RESET };
}

export function setTimer() {
    const time = moment();
    return { type: types.SET_INIT_TIME, payload: { time } };
}

export function loadInitData() {
    return function (dispatch) {
        dispatch(setTimer());
        return Promise.resolve(dispatch(retrieveCurrentUserInfo()))
            .then(() => { dispatch(getFilters()); });
    };
}

export function getFilters() {
    return function (dispatch) {
        dispatch({ type: types.GET_FILTERS.REQUEST });
        return api.getFilters()
            .then(res => {
                dispatch({
                    type: types.GET_FILTERS.SUCCESS,
                    payload: { filters: _.groupBy(res.data, 'filterType'), data: res.data }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.GET_FILTERS.ERROR, payload: { error } });
                throw error;
            }));
    };
}

export function retrieveCurrentUserInfo() {
    return function (dispatch) {
        dispatch({ type: types.GET_CURRENT_USER.REQUEST });
        return api.retrieveCurrentUserInfo()
            .then(response => {
                dispatch(retrieveCurrentUserInfoSuccess(response.data));
                const lang = _.get(response.data, 'language', null);
                if (lang) dispatch(changeLanguage(lang));
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.GET_CURRENT_USER.ERROR });
                throw error;
            }));
    };
}

export function retrieveCurrentUserInfoSuccess(data) {
    return {
        type: types.GET_CURRENT_USER.SUCCESS,
        payload: { data }
    };
}

export function getUrlPreview(entityType, url) {
    return function (dispatch) {
        return api.getUrlPreview(url)
            .then(response => {
                dispatch(getUrlPreviewSuccess(entityType, response.data));
            })
            .catch(error => console.warn('getUrlPreview', error));
    };
}

export function clearUrlPreview(entityType) {
    return getUrlPreviewSuccess(entityType);
}

function getUrlPreviewSuccess(entityType, preview) {
    return {
        type: types.GET_URL_PREVIEW,
        payload: { entityType, preview }
    };
}

export function updateConnectionState(isOnline) {
    return {
        type: types.UPDATE_CONNECTION_STATE,
        payload: { isOnline }
    };
}

//// NO DISPATCH
export function logMessage(message) {
    return api.logMessage(message);
}

export function checkAppVersion() {
    return api.checkAppVersion()
        .then(response => {
            return _.get(response, 'data.forceUpdate');
        });
}


export const changeLanguage = language => dispatch => {
    const lang = language.replace('_', '-');
    i18n.changeLanguage(lang, err => {
        if (err) return;
        dispatch({ type: types.CHANGE_LANGUAGE, payload: { language } });
    });
    if (moment.locales().includes(lang.toLowerCase())) {
        moment.locale(lang.toLowerCase());
    } else if (moment.locales().includes(lang.substring(0, 2))) {
        moment.locale(lang.substring(0, 2));
    } else {
        moment.locale('en');
    }

    // Save language for next app launch so the app starts in that language
    if (Platform.OS !== PLATFORMS.web) {
        Storage.setItem('userSelectedLanguage', lang);
    }
};

export const changeScreenSize = () => dispatch => {
    dispatch({ type: types.CHANGE_SCREEN_SIZE });
};

export function updateLastRatingRequestDate(date) {
    return {
        type: types.REQUEST_RATING.SUCCESS,
        payload: {
            lastRatingRequestDate: date
        }
    };
}

export function askUserForAppStoreReviewIfPossible() {
    return function (dispatch, getState) {
        // call this function: When a user successfully posted to the stream, joined an event, challenge or community, tracked an activity manually

        if (Platform.OS !== PLATFORMS.web) {
            const userDateRegistered = selectors.getUserDateRegistered(getState());
            const registeredLongEnough = moment().diff(userDateRegistered, 'days') >= 14;

            const lastRatingRequestDate = selectors.getLastRatingRequestDate(getState());
            const longEnoughSinceLastReview = moment().diff(moment(lastRatingRequestDate), 'days') >= 120 || !lastRatingRequestDate;

            if (registeredLongEnough && longEnoughSinceLastReview) {
                const today = moment(new Date()).format(DATE_FORMATS.full);
                Storage.setItem('lastRatingRequestDate', today);
                dispatch(updateLastRatingRequestDate(today));
                AppRatingService.showAppRatingRequest();
            }
        }
    };
}

export function saveSmartModeStatus(status) {
    return {
        type: types.SAVE_SMART_MODE_STATUS,
        payload: { data: status }
    };
}
