import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';
import { withRouter } from 'react-router-dom';

import { components as Core, onKeyPress } from '../../../core';
import WithResourceLinkFeedItemBase, { styles as baseStyles } from './ResourceLinkFeedItemBase';
import { layoutStyle } from '../../../../styles';

class ResourceLinkFeedItem extends PureComponent {
    static propTypes = {
        item: PropTypes.object.isRequired,
        goToResourceLinkItem: PropTypes.func.isRequired,
        textClassName: PropTypes.string,
        onClick: PropTypes.func
    };

    static defaultProps = {
        textClassName: null,
        onClick: null
    };

    get title() {
        const { item } = this.props;
        return item.title || item.tag_name;
    }

    onClick = () => {
        const { onClick, goToResourceLinkItem, item } = this.props;
        onClick ? onClick(item) : goToResourceLinkItem();
    }

    render() {
        const { goToResourceLinkItem, textClassName } = this.props;
        return (
            <div
                tabIndex="0"
                role="button"
                onClick={this.onClick}
                onKeyDown={onKeyPress.enter(goToResourceLinkItem)}
                className={css(styles.title, layoutStyle.cp, textClassName)}>
                <Core.InnerHtml htmlText={this.title} />
            </div>
        );
    }
}

export default withRouter(WithResourceLinkFeedItemBase(ResourceLinkFeedItem));

const styles = StyleSheet.create({
    ...baseStyles
});