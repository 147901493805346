import React from 'react';
import PropTypes from 'prop-types';
import { translate } from '../../services';
import { appFonts, baseColors, spacing } from '../../../../styles';

export default function WithJoinedTagBase(WrappedComponent) {
    const JoinedTagBase = props => (
        <WrappedComponent {...props} text={props.i18n.t('joinSuccess')} />
    );

    JoinedTagBase.propTypes = {
        showTag: PropTypes.bool,
        text: PropTypes.string
    };

    JoinedTagBase.defaultProps = {
        showTag: false,
        text: ''
    };

    return translate()(JoinedTagBase);
}

export const styles = {
    container: {
        borderRadius: spacing.s0,
        position: 'absolute',
        left: spacing.s1,
        bottom: spacing.s1,
        backgroundColor: 'rgba(0, 0, 0, 0.54)',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end'
    },
    text: {
        ...appFonts.smMedium,
        color: baseColors.white,
    }
};
