import React from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';
import classnames from 'classnames';
import WithLogoBase from './LogoBase';

const Logo = ({ className, imageClassName, src }) => src ? (
    <div className={classnames(css(styles.logo), className)}>
        <img className={classnames(css(styles.logoImg), imageClassName)} src={src.uri || src.default || src} alt="Logo" />
    </div>
) : null;

Logo.propTypes = {
    className: PropTypes.string,
    imageClassName: PropTypes.string,
    src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

Logo.defaultProps = {
    className: undefined,
    imageClassName: undefined,
};

export default WithLogoBase(Logo);

const HEIGHT_LOGO = 40;

const styles = StyleSheet.create({
    logo: {
        height: HEIGHT_LOGO,
    },
    logoImg: {
        height: '100%',
    }
});
