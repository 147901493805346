import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { translate } from '../../../core';
import { appFonts, baseColors, spacing } from '../../../../styles';

export default function WithActivityLogBase(WrappedComponent) {
    class ActivityLogBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
        };

        get title() {
            return this.props.i18n.t('activityLog');
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    title={this.title}
                />
            );
        }
    }

    return (translate()(ActivityLogBase));
}

const EMPTY_MESSAGE_MARGIN_TOP = spacing.s9 * 2;

export const styles = {
    subtitle: {
        backgroundColor: baseColors.grey85,
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3,
        paddingTop: spacing.s0,
        paddingBottom: spacing.s0,
        ...appFonts.smBold
    },
    emptyContainer: {
        flex: 1,
        marginTop: EMPTY_MESSAGE_MARGIN_TOP,
        marginLeft: spacing.s3,
        marginRight: spacing.s3
    },
    listContainer: {
        borderColor: baseColors.white,
        marginTop: 0
    }
};
