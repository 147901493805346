import React, { PureComponent } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import classnames from 'classnames';

import { baseColors, containerStyle, layoutStyle, spacing, headerHeight } from '../../../../styles';
import { components as Core } from '../../../core';
import WithInfoScreenBase, { styles as baseStyles, INFO_SCREEN_ICON_SIZE } from './InfoScreenBase';

class InfoScreen extends PureComponent {
    static propTypes = {
        icon: PropTypes.string,
        title: PropTypes.string,
        bodyText: PropTypes.string,
        onClick: PropTypes.func,
        customButtons: PropTypes.func,
        customBody: PropTypes.object,
        headerStyles: PropTypes.string,
        textStyle: PropTypes.string,
        isOnboarding: PropTypes.bool,
        hasNextButton: PropTypes.bool,
        textContainerStyle: PropTypes.string,
        i18n: PropTypes.object.isRequired,
        hasPadding: PropTypes.bool,
        isLiveBetter: PropTypes.bool.isRequired,
        onboardingNewDesign: PropTypes.bool,
        onboardingNextButtonLabel: PropTypes.string,
        isOnboardingInfoScreen: PropTypes.bool,
        goalsPageNew: PropTypes.bool,
        isWellbeingSurvey: PropTypes.bool
    };

    static defaultProps = {
        customButtons: null,
        customBody: null,
        onClick: null,
        headerStyles: null,
        textStyle: null,
        isOnboarding: false,
        hasNextButton: true,
        bodyText: null,
        textContainerStyle: null,
        title: null,
        icon: null,
        hasPadding: true,
        onboardingNewDesign: false,
        onboardingNextButtonLabel: undefined,
        isOnboardingInfoScreen: false,
        goalsPageNew: false,
        isWellbeingSurvey: false
    };

    render() {
        const { title, icon, textContainerStyle, headerStyles, hasPadding,
            textStyle, bodyText, isOnboarding, isOnboardingInfoScreen, isLiveBetter, onboardingNewDesign, hasNextButton,
            customBody, customButtons, onboardingNextButtonLabel, onClick, i18n, goalsPageNew, isWellbeingSurvey } = this.props;
        const scrollContainerStyle = hasPadding ? containerStyle.containerPadding : null;
        const onboardingScreenStyle = onboardingNewDesign ? styles.screenContainerNew : styles.screenContainer;
        return (
            <div className={css(containerStyle.containerNoPadding, isOnboarding || isOnboardingInfoScreen || goalsPageNew ? onboardingScreenStyle : styles.notOnboardingContainer,
                goalsPageNew && styles.goalsPageNewStyle)}>
                <div className={css(scrollContainerStyle)}>
                    <div className={css(isLiveBetter ? styles.headerLB : styles.header, goalsPageNew && headerStyles)}>
                        {!_.isEmpty(icon) ?
                            <Core.Icon
                                type="fa"
                                name={icon}
                                size={INFO_SCREEN_ICON_SIZE}
                                color={baseColors.primary}
                            /> : null
                        }
                        <div className={classnames(css(isLiveBetter ? styles.headerTextLB : styles.headerText, headerStyles))}>{title}</div>
                    </div>
                    {(!_.isEmpty(bodyText) && !onboardingNewDesign) || goalsPageNew ?
                        <div className={css(styles.body, textContainerStyle)}>
                            <div className={css(styles.description, textStyle)}>{bodyText}</div>
                        </div> : null
                    }
                    {customBody}
                </div>
                {customButtons}
                {hasNextButton ?
                    <>
                        {isOnboardingInfoScreen ?
                            <Core.Layout.FixedFooterSmallerContainer
                                isOnboarding={true}
                                isBorder={true}
                                isTransparent={true}
                                className={css(layoutStyle.noPadding)}>
                                <div className={css(layoutStyle.bgWhite, layoutStyle.footerPadding1, layoutStyle.flexJustifyCenter)}>
                                    <Core.Button
                                        id="nextBtnOnboarding"
                                        type="contained"
                                        size="large"
                                        className={css(layoutStyle.halfWidth)}
                                        onPress={onClick}>
                                        {onboardingNextButtonLabel}
                                    </Core.Button>
                                </div>
                            </Core.Layout.FixedFooterSmallerContainer> :
                            <Core.Layout.FixedModalFooterButton>
                                <Core.Button
                                    type="contained"
                                    size="medium"
                                    className={css(layoutStyle.halfWidth, isWellbeingSurvey && styles.wellbeingSurveyContainer)}
                                    onPress={onClick}>
                                    {i18n.t('next')}
                                </Core.Button>
                            </Core.Layout.FixedModalFooterButton>}
                    </> : null}
            </div>

        );
    }
}

const FIXED_FOOTER_HEIGHT = 65;
const PADDING_BOTTOM = 43;
const CONTAINER_WIDTH_FOR_WELLBEING_SURVEY= 400;
const styles = StyleSheet.create({
    ...baseStyles,
    header: {
        ...baseStyles.header,
        textAlign: 'center',
        marginTop: spacing.s9
    },
    headerLB: {
        ...baseStyles.headerLB,
        textAlign: 'center'
    },
    description: {
        ...baseStyles.description,
        paddingBottom: spacing.s7
    },
    screenContainerNew: {
        padding: spacing.s9,
        borderRadius: spacing.s1
    },
    screenContainer: {
        paddingBottom: FIXED_FOOTER_HEIGHT
    },
    notOnboardingContainer: {
        minHeight: `calc(100vh - ${spacing.s7}px - (${headerHeight}px*2))`,
        display: 'flex'
    },
    goalsPageNewStyle: {
        paddingLeft: spacing.s11,
        paddingRight: spacing.s11,
        paddingBottom: PADDING_BOTTOM
    },
    wellbeingSurveyContainer: {
        maxWidth: CONTAINER_WIDTH_FOR_WELLBEING_SURVEY
    }
});

export default WithInfoScreenBase(InfoScreen);
