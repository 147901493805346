import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { parsers, translate, constants as coreConstants, selectors as coreSelectors, Platform, PLATFORMS } from '../../../core';
import * as contentActions from '../../actions';
import { spacing, appFonts, baseColors, fontSize } from '../../../../styles';
import * as selectors from '../../selectors';
import { isVideo, isVideoMP4, itemIsCMSContent, secondsIntoTime } from '../../services';
import { PLAY_BUTTON_HEIGHT, PLAY_BUTTON_WIDTH, VIDEO_DURATION_CONTAINER_HORIZONTAL_PADDING } from '../../constants';

const fontFamily = Platform.OS === PLATFORMS.web ? '“Helvetica Now Text SA", "Helvetica Neue", Helvetica, Arial, sans-serif' : '-apple-system, Roboto, sans-serif';

export const HTMLStyle = `
    <style>
        #height-calculator {
            margin: 0;
            padding: 0;
        }
        #height-calculator {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: 0;
            padding: 0;
        }
        body {
            width:99%;
            font-family: ${fontFamily};
            margin-left: 2;
            margin-right: 0;
        }
        #cmsStyles h2 {
            font-size: ${fontSize.xLarge}px;
            color: ${baseColors.black};
            margin-top: ${spacing.s7}px;
            margin-bottom: ${spacing.s3}px;
        }
        #cmsStyles h3 {
            font-size: ${fontSize.large}px;
            color: ${baseColors.black};
            margin-top: ${spacing.s7}px;
            margin-bottom: ${spacing.s3}px;
        }
        #cmsStyles h4 {
            font-size: ${fontSize.small}px;
            color: ${baseColors.grey40};
            margin-top: ${spacing.s7}px;
            margin-bottom: ${spacing.s1}px;
            text-transform: uppercase;
            letter-spacing: 0.5px
        }
        #cmsStyles p {
            font-size: ${spacing.s3}px;
            line-height: ${spacing.s5}px;
            color: ${baseColors.grey20};
            margin-bottom: ${spacing.s3}px;
        }
        #cmsStyles strong {
            font-weight: bold;
        }
        #cmsStyles em {
            font-style: italic;
        }
        #cmsStyles img {
            width: 100%;
            height: auto;
        }
        #cmsStyles ul {
            padding-left: ${spacing.s7}px;
            margin-bottom: ${spacing.s3}px;
            list-style: disc;
        }
        #cmsStyles ol {
            padding-left: ${spacing.s7}px;
            margin-bottom: ${spacing.s3}px;
            list-style: decimal;
        }
        #cmsStyles li {
            margin-bottom: ${spacing.s2}px
        }
        #cmsStyles a {
            color: ${baseColors.primary}
        }
    </style>
`;

export default function WithContentItemDetailsBase(WrappedComponent) {
    class ContentItemDetails extends PureComponent {
        static propTypes = {
            linkId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            actions: PropTypes.object.isRequired,
            item: PropTypes.object,
            i18n: PropTypes.object.isRequired,
            isLoading: PropTypes.bool,
            customPointsUnit: PropTypes.string.isRequired
        };

        static defaultProps = {
            linkId: undefined,
            id: undefined,
            item: undefined,
            isLoading: false
        };

        constructor(props) {
            super(props);
            if (!this.props.item && this.props.linkId) {
                this.props.actions.getContentItem(this.props.linkId);
            }
        }

        get item() {
            return this.props.item;
        }

        get itemIsCMSContent() {
            return (itemIsCMSContent(this.item));
        }

        get isBookmarked() {
            return _.get(this.props.item, 'bookmarked') === '1';
        }

        get preview() {
            return _.get(this.item, 'preview_metadata', {});
        }

        get image() {
            if (this.itemIsCMSContent) {
                const url = _.get(this.item, 'image_url');
                if (url) {
                    return parsers.getSecureImageUrl(url);
                }
            }

            const src = this.preview.src || this.preview.photo_path;
            return src || _.get(this.item, 'thumbnail_url') ? parsers.getSecureImageUrl(src || _.get(this.item, 'thumbnail_url')) : null;
        }

        get buttonText() {
            return this.props.i18n.t('readArticle');
        }

        get isRead() {
            return _.get(this.item, 'already_read') === '1';
        }

        get categoryColor() {
            const slug = coreConstants.ICON_CATEGORIES[_.get(this.item, 'category_slug')];
            return this.props.isLiveBetter ? _.get(slug, 'bgr') : baseColors.secondary;
        }

        get isVideo() {
            return isVideo(_.get(this.props, 'item.url')) || isVideo(_.get(this.props, 'item.preview_metadata.hls'));
        }

        get isVideoMP4() {
            return isVideoMP4(_.get(this.props, 'item.preview_metadata'));
        }

        get timeToReadLabel() {
            const { item, i18n } = this.props;
            const readingTime = _.get(item, 'reading_time');
            if (readingTime) {
                return i18n.t('content.min_read', { readingTime });
            }

            return undefined;
        }

        get itemLabel() {
            const { isLiveBetter, item } = this.props;
            return isLiveBetter ? _.get(item, 'category') : _.get(item, 'tags');
        }

        get hasSeveralLabels() {
            const { isLiveBetter, item } = this.props;
            const tags = _.get(item, 'tagsArr', []);
            return !isLiveBetter && tags.length > 1;
        }

        toggleBookmark = () => this.props.actions.bookmarkContent(this.item, !this.isBookmarked);

        markAsReadWatched = () => {
            const { item } = this.props;
            if (item) {
                this.props.actions.markAsReadWatched(item.id);
            }
        }

        markAsOpened = () => {
            const { item } = this.props;
            if (item) {
                this.props.actions.markAsOpened(item.id);
            }

        }

        get videoDuration() {
            const { item } = this.props;
            const reading_time = _.get(item, 'reading_time');
            return secondsIntoTime(reading_time);
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    image={this.image}
                    isVideo={this.isVideo}
                    isVideoMP4={this.isVideoMP4}
                    buttonText={this.buttonText}
                    isBookmarked={this.isBookmarked}
                    categoryColor={this.categoryColor}
                    onToggleBookmark={this.toggleBookmark}
                    markAsReadWatched={this.markAsReadWatched}
                    markAsOpened={this.markAsOpened}
                    timeToReadLabel={this.timeToReadLabel}
                    isRead={this.isRead}
                    itemIsCMSContent={this.itemIsCMSContent}
                    itemLabel={this.itemLabel}
                    hasSeveralLabels={this.hasSeveralLabels}
                    videoDuration={this.videoDuration}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        const id = ownProps.id || (_.get(ownProps, 'match.params.id')) || _.get(ownProps, 'route.params.id');
        const linkId = ownProps.linkId || (_.get(ownProps, 'match.params.linkId')) || _.get(ownProps, 'route.params.linkId');
        return {
            item: ownProps.linkId ? selectors.getContentItemByLinkId(state, ownProps.linkId) :
                selectors.getContentItem(state, id),
            isLoading: selectors.isLoadingContentItem(state),
            customPointsUnit: coreSelectors.getCustomPointsUnit(state),
            isLiveBetter: coreSelectors.isLiveBetter(state),
            id,
            linkId
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(contentActions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(ContentItemDetails));
}

export const styles = {
    card: {
        backgroundColor: baseColors.white,
        borderColor: baseColors.grey80
    },
    image: {
        width: '100%',
        height: '100%'
    },
    body: {
        padding: spacing.s3,
        paddingRight: spacing.s3,
        flex: 1
    },
    category: {
        ...appFonts.mdMedium,
    },
    title: {
        ...appFonts.xlBold,
        marginTop: spacing.s1,
        marginBottom: spacing.s1
    },
    description: {
        ...appFonts.mdRegular,
        color: baseColors.grey20,
    },
    viewButton: {
        marginTop: spacing.s7,
    },
    buttonContent: {
        flexDirection: 'row-reverse'
    },
    textStyle: {
        marginRight: spacing.s1
    },
    viewButtonWrapper: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center'
    },
    viewButtonText: {
        paddingRight: spacing.s0,
        color: baseColors.white,
        ...appFonts.smMedium
    },
    headerContainer: {
        flexDirection: 'column',
    },
    timeToReadWrapper: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        marginTop: spacing.s0,
        alignItems: 'center',
        paddingLeft: spacing.s3
    },
    timeToRead: {
        ...appFonts.smRegular,
        color: baseColors.grey40,
        paddingLeft: spacing.s1
    },
    cmsHeader: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: spacing.s2,
        paddingRight: spacing.s3,
        paddingLeft: spacing.s3,
        flex: 1
    },
    cmsBody: {
        display: 'flex',
    },
    cmsImage: {
        marginTop: spacing.s4,
        marginBottom: spacing.s2
    },
    cmsPointsWrapper: {
        paddingTop: spacing.s1,
        display: 'flex',
        flexDirection: 'row',
    },
    bonusMarkWrapper: {
        marginTop: spacing.s5,
    },
    bonusLabel: {
        marginRight: spacing.s2,
    },
    categoryText: {
        ...appFonts.smMedium,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    dotIcon: {
        marginLeft: spacing.s1,
        marginRight: spacing.s1
    },
    labelContainer: {
        flex: 1,
        flexDirection: 'row',
        paddingBottom: spacing.s0,
    },
    categoryWrapper: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    videoControlContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        zIndex: 1,
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        position: 'absolute',
        height: '100%',
        width: '100%',
        paddingBottom: spacing.s2,
        paddingHorizontal: spacing.s2
    },
    playButton: {
        height: PLAY_BUTTON_HEIGHT,
        width: PLAY_BUTTON_WIDTH
    },
    videoDurationContainer: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        borderRadius: spacing.s0,
        paddingVertical: spacing.s0,
        paddingHorizontal: VIDEO_DURATION_CONTAINER_HORIZONTAL_PADDING
    },
    videoDuration: {
        ...appFonts.smBold,
        color: baseColors.white
    }
};
