import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';
import { spacing, baseColors, layoutStyle } from '../../../../styles';
import { components as Core } from '../../../core';
import WithActivityTrackItemBase, { styles as baseStyles, ACTIVITY_STATUSES, ANIMATION_DURATION } from './ActivityTrackItemBase';

export { ACTIVITY_STATUSES };

class ActivityTrackItem extends PureComponent {
    static propTypes = {
        activity: PropTypes.object.isRequired,
        onDelete: PropTypes.func.isRequired,
        status: PropTypes.string,
        openTracker: PropTypes.func.isRequired,
        titleTracked: PropTypes.string.isRequired,
        subtitleTracked: PropTypes.string.isRequired,
        title: PropTypes.node.isRequired,
        subtitle: PropTypes.string.isRequired,
        colorTracked: PropTypes.object.isRequired,
        iconName: PropTypes.string.isRequired,
        iconColor: PropTypes.string.isRequired,
        isAuto: PropTypes.bool.isRequired,
        isCommonPaddingHorizontal: PropTypes.bool,
        hasAnimation: PropTypes.bool,
        noPlusIcon: PropTypes.bool,
    };

    static defaultProps = {
        status: '',
        hasAnimation: false,
        noPlusIcon: false,
        isCommonPaddingHorizontal: false,
    };

    constructor(props) {
        super(props);

        this.renderMethods = {
            [ACTIVITY_STATUSES.pending]: this.renderTrackedActivity,
            [ACTIVITY_STATUSES.tracked]: this.renderTrackedActivity,
            [ACTIVITY_STATUSES.untracked]: this.renderActivity,
            default: this.renderActivity,
        };

        this.state = { isAnimated: false };
    }

    // todo: add animation like on mobile
    shiftRight = () => this.setState({ isAnimated: true });

    onPressDelete = event => {
        event.stopPropagation();
        this.props.onDelete(this.props.activity);
    };

    renderTrackedActivity = () => {
        const { status, subtitleTracked, titleTracked, colorTracked } = this.props;

        const subtitleElement = <span className={css(styles.subtitle)}>{subtitleTracked}</span>;
        let titleElement =
            <span className={css(styles.titleStyle, colorTracked)}>{titleTracked}</span>;

        // if autotracked do not allow users to interact with it
        if (this.props.isAuto) {
            titleElement = (
                <div className={css(styles.center)}>
                    <p className={css(styles.titleStyle, styles.titleMargin, colorTracked)}>{titleTracked}</p>
                    <Core.Icon
                        style={styles.icon}
                        type="fa"
                        name="watch"
                        size={spacing.s2}
                        color={baseColors.primary}
                    />
                </div>
            );
            return (
                <Core.ListItem
                    className={css(layoutStyle.listItem)}
                    button={true}>
                    <Core.ListItemText primary={titleElement} secondary={subtitleElement} />
                </Core.ListItem>
            );
        }

        return (
            <Core.Animated.Slide
                left={true}
                duration={ANIMATION_DURATION}
                in={this.props.hasAnimation ? this.state.isAnimated : true}
                appear={this.state.isAnimated}>
                <Core.ListItem
                    button={true}
                    className={css(layoutStyle.listItem)}
                    onClick={this.props.openTracker}>
                    <Core.ListItemText
                        primary={(
                            <div className={css(layoutStyle.flexRow)}>
                                {status === ACTIVITY_STATUSES.pending ? <div className={css(styles.pendingDot)} /> : null}
                                {titleElement}
                            </div>
                        )}
                        secondary={subtitleElement}
                    />
                    {this.props.status === ACTIVITY_STATUSES.pending ? (
                        <Core.IconButton
                            size={spacing.s4}
                            type="fa"
                            name="trash-alt"
                            color="danger"
                            onClick={this.onPressDelete}
                        />
                    ) : null}
                </Core.ListItem>
            </Core.Animated.Slide>
        );
    };

    renderActivity = () => {
        if (!this.props.activity.activityName) return null;

        const { title, subtitle, iconName, iconColor, noPlusIcon, isCommonPaddingHorizontal } = this.props;

        const titleElement = <span className={css(styles.titleStyle, layoutStyle.colorBlack)}>{title}</span>;
        const subtitleElement = <span className={css(styles.subtitle)}>{subtitle}</span>;

        return (
            <Core.ListItem
                button={true}
                className={css(layoutStyle.listItem, isCommonPaddingHorizontal && layoutStyle.commonPaddingHorizontal)}
                onClick={this.props.openTracker}>
                <Core.ListItemText primary={titleElement} secondary={subtitleElement} />
                {noPlusIcon ? null : (
                    <Core.Icon
                        name={iconName}
                        type="fa"
                        size={spacing.s4}
                        color={iconColor}
                    />)
                }
            </Core.ListItem>
        );
    };

    get renderMethod() {
        return this.renderMethods[this.props.status] || this.renderMethods.default;
    }

    render() {
        return this.props.activity.hidden ? null : this.renderMethod();
    }
}

const styles = StyleSheet.create({
    ...baseStyles
});

export default WithActivityTrackItemBase(ActivityTrackItem);
