import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import AvatarList from '../AvatarList';
import { spacing } from '../../../../styles';
import WithExploreItemMembersBase, { styles as baseStyles } from './ExploreItemMembersBase';


class ExploreItemMembers extends PureComponent {
    static propTypes = {
        text: PropTypes.string.isRequired,
        members: PropTypes.array.isRequired,
        membersToRender: PropTypes.array.isRequired,
        membersString: PropTypes.string.isRequired,
        whiteText: PropTypes.bool,
        noTopMargin: PropTypes.bool,
    };

    static defaultProps = {
        whiteText: false,
        noTopMargin: false
    }

    get avatarIcons() {
        const { membersToRender } = this.props;

        return (
            membersToRender && membersToRender.length > 0 ? (
                <AvatarList
                    sizeAvatar="xsmall"
                    numMembers={membersToRender.length}
                    members={membersToRender}
                    supplementaryComponent={this.supplementaryComponent} // use this for string
                    membersAvatarClassName={css(styles.avatar)}
                    avatarsContainerClassName={css(styles.avatarGroup)}
                />
            ) : null
        );
    }

    get supplementaryComponent() {
        const { members, text, membersString, whiteText } = this.props;

        if (members && members.length > 0) {
            return (
                <p className={css(styles.text, whiteText && styles.whiteText)}>{text ? text : membersString}</p>
            );
        }

        return null;
    }

    render() {
        return (
            <div className={css(styles.mainContainer, !this.props.noTopMargin && styles.noTopMargin)}>
                {this.avatarIcons}
            </div>
        );
    }
}

export default WithExploreItemMembersBase(ExploreItemMembers);

const styles = StyleSheet.create({
    ...baseStyles,
    mainContainer: {
        ...baseStyles.mainContainer,
        marginLeft: -spacing.s0
    },
    noTopMargin: {
        marginTop: 0
    }
});
