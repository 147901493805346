import { baseColors, spacing, cardBorderRadius } from '../../../../../styles';

export const styles = {
    footer: {
        paddingBottom: spacing.s2,
        paddingTop: spacing.s1,
    },
    grey: {
        backgroundColor: baseColors.grey90,
        borderBottomLeftRadius: cardBorderRadius,
        borderBottomRightRadius: cardBorderRadius
    },
    border: {
        borderColor: baseColors.grey70,
        borderTopWidth: 1
    }
};