import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default class ErrorBoundary extends PureComponent {
    static propTypes = {
        children: PropTypes.node,
    };

    static defaultProps = {
        children: undefined
    };

    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        // log error
        console.warn(error, info); // eslint-disable-line
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <h1>Something went wrong.</h1>;
        }

        return this.props.children;
    }
}
