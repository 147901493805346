import React from 'react';
import { css, StyleSheet } from 'aphrodite-jss';
import PropTypes from 'prop-types';
import baseColors from './styles/commonColor';
import spacing from './styles/spacing';
import appFonts from './styles/appFonts';

const ProgramDisabled = props => (
    <div className={css(styles.container)}>
        <div className={css(styles.innerContainer)}>
            <h2 className={css(styles.header)}>
                This program has ended!
            </h2>
            <p className={css(styles.infoText)}>
                { props.errorMessage }
            </p>
        </div>
    </div>
);

export default ProgramDisabled;

ProgramDisabled.propTypes = { errorMessage: PropTypes.string };
ProgramDisabled.defaultProps = { errorMessage: null };

const containerPadding = 80;
const innerMaxWidth = 600;

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        justifyContent: 'center',
        minHeight: '100vh',
        height: '100vh',
        padding: containerPadding,
        backgroundColor: baseColors.grey90,
    },
    innerContainer: {
        maxWidth: innerMaxWidth,
        width: '100vh',
        height: 'fit-content',
        padding: `${spacing.s11}px ${spacing.s7}px`,
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.02)',
        borderRadius: spacing.s0,
        backgroundColor: baseColors.white,
    },
    header: {
        ...appFonts.xlRegular,
        color: baseColors.grey20,
    },
    infoText: {
        ...appFonts.mdRegular,
        paddingTop: spacing.s5,
        color: baseColors.grey40,
    },
});
