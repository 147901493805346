import _ from 'lodash';
import { persistReducer } from 'redux-persist';
import * as types from './actionTypes';
import { actionTypes as coreActionTypes, Storage, tracker } from '../core';
import { NAME } from './constants';

const initialState = {
    items: {},
    ids: [],
    platform: {
        items: {},
        ids: [],
        count: 0
    },
    platformInvitations: {
        items: {},
        ids: [],
        count: 0
    },
    userByEmail: null,
    isShownLearnFriendsAndFamilyModal: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_USERS_TO_INVITATION.SUCCESS: {
            const { isReplace, users: { items, ids }, count } = action.payload;
            const idsArray = isReplace ? ids : _.uniq([...state.ids, ...ids]);
            return {
                ...state,
                items: {
                    ...state.items,
                    ...items,
                },
                ids: idsArray,
                usersCount: count
            };
        }
        case types.GET_USERS_TO_PLATFORM_INVITATION.SUCCESS:
        case types.GET_USERS_TO_PLATFORM_INVITATION_MORE.SUCCESS: {
            const { isReplace, items, ids, count } = action.payload;
            return {
                ...state,
                platform: {
                    ...state.platform,
                    items: isReplace ? items : {
                        ...state.platform.items,
                        ...items
                    },
                    ids: isReplace ? ids : [
                        ...state.platform.ids,
                        ...ids
                    ],
                    count
                },
                userByEmail: null
            };
        }
        case types.GET_PLATFORM_INVITATIONS.SUCCESS: {
            return {
                ...state,
                platformInvitations: action.payload
            };
        }
        case types.SEND_PLATFORM_INVITATIONS.SUCCESS: {
            const { userId, item } = action.payload;
            return {
                ...state,
                platform: _.has(state.platform.items, userId) ? {
                    ...state.platform,
                    items: {
                        ...state.platform.items,
                        [userId]: { ...state.platform.items[userId], is_invited: true }
                    }
                } : state.platform,
                platformInvitations: {
                    ...state.platformInvitations,
                    items: { ...state.platformInvitations.items, [item.id]: item },
                    ids: [item.id, ...state.platformInvitations.ids]
                }
            };
        }
        case types.DELETE_PLATFORM_INVITATION.SUCCESS: {
            const { invitationId } = action.payload;
            return _.has(state.platformInvitations.items, invitationId) ? {
                ...state,
                platformInvitations: {
                    ...state.platformInvitations,
                    items: _.omit(state.platformInvitations.items, [invitationId]),
                    ids: _.filter(state.platformInvitations.ids, item => item.id !== invitationId),
                    count: state.platformInvitations.count - 1
                }
            } : state;
        }
        case types.GET_USER_BY_EMAIL.SUCCESS: {
            const { user } = action.payload;
            if (!user) return state;
            return {
                ...state,
                userByEmail: action.payload.user.id,
                platform: {
                    ...state.platform,
                    items: {
                        ...state.platform.items,
                        [user.id]: user
                    }
                }
            };
        }
        case types.CLEAR_USER_BY_EMAIL: {
            if (!state.userByEmail) return state;
            return {
                ...state,
                userByEmail: null,
                platform: {
                    ...state.platform,
                    items: _.omit(state.platform.items, state.userByEmail)
                }
            };
        }
        case types.SHOW_LEARN_FRIENDS_AND_FAMILY_MODAL: {
            return {
                ...state,
                isShownLearnFriendsAndFamilyModal: true
            };
        }
        case coreActionTypes.RESET:
            return {
                ...initialState,
                isShownLearnFriendsAndFamilyModal: state.isShownLearnFriendsAndFamilyModal
            };
        default:
            return state;
    }
};

const serialize = data => {
    try {
        return JSON.stringify(data);
    } catch (err) {
        const message = 'Failed to serialize invitations data';
        const serializeError = JSON.stringify({
            message,
            err
        });
        tracker.logError(serializeError);
        return JSON.stringify({});
    }
};

const persistConfig = {
    key: NAME,
    storage: Storage.storageType(),
    whitelist: ['isShownLearnFriendsAndFamilyModal'],
    serialize
};

export default persistReducer(persistConfig, reducer);