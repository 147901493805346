import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';

import * as selectors from '../../selectors';
import * as contentActions from '../../actions';
import { getRequestParams } from '../../services';
import { spacing } from '../../../../styles';
import { selectors as coreSelectors, translate } from '../../../core';
import { TYPES, CATEGORIES } from '../../constants';
import { getContentEmptyListParams } from '../../services/contentHelper';

export const ICON_SIZE = spacing.s4;

export default function WithContentListBase(WrappedComponent) {
    class ContentListBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            category: PropTypes.string.isRequired,
            categories: PropTypes.array.isRequired,
            type: PropTypes.string,
            items: PropTypes.array,
            count: PropTypes.number,
            isLoadingSelector: PropTypes.bool,
            isSearching: PropTypes.bool,
            search: PropTypes.string,
            i18n: PropTypes.object.isRequired,
            isLiveBetter: PropTypes.bool.isRequired,
            isMoreTab: PropTypes.bool,
            isLoadingContentCount: PropTypes.number
        };

        static defaultProps = {
            items: [],
            count: 0,
            isLoadingSelector: true,
            isSearching: false,
            search: undefined,
            type: undefined,
            isMoreTab: false,
            isLoadingContentCount: 0
        };

        static searchText = null;

        searchCallback = text => {
            if (text) ContentListBase.searchText = text;
            else ContentListBase.searchText = null;
        };

        get search() {
            return this.props.search || ContentListBase.searchText;
        }

        get category() {
            return this.search ? CATEGORIES.SEARCH : this.props.category;
        }

        loadMoreContent = () => {
            const { isLiveBetter, isLoading, isSearching, type, category, items, actions, isMoreTab } = this.props;
            const isTagFilter = !isLiveBetter;
            if (!isLoading && !isSearching && this.hasMore) {
                if (!isMoreTab) {
                    const params = _.assign(getRequestParams(type, category, items.length, undefined, this.search, isTagFilter));
                    actions.getContent(this.category, params, false, type, null, !!this.search);
                } else {
                    actions.getContent(this.category, { clientExternalOnly: 1, offset: items.length, maxCount: 10 }, false, TYPES.MORE, null, false);
                }
            }
        };

        get hasMore() {
            return this.props.items.length < this.props.count;
        }

        get categoryLabel() {
            const { categories, category, i18n } = this.props;
            if (category === CATEGORIES.ALL) return i18n.t('all');
            return _.get(_.find(categories, ['id', category]), 'label');
        }

        get title() {
            const { type, i18n } = this.props;
            return this.categoryLabel
                ? `${i18n.t(type)} ${i18n.t('from')} ${this.categoryLabel}`
                : null;
        }

        get contentEmptyListParams() {
            const { category, search } = this.props;
            return getContentEmptyListParams(category, search);
        }

        get companyName() {
            return _.get(this.props, 'currentUser.companyName');
        }

        get isLoading() {
            const { isLoadingContentCount, isLoadingSelector } = this.props;
            return isLoadingSelector || isLoadingContentCount > 0;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    title={this.title}
                    searchCallback={this.searchCallback}
                    search={this.search}
                    hasMore={this.hasMore}
                    loadMoreContent={this.loadMoreContent}
                    contentEmptyListParams={this.contentEmptyListParams}
                    companyName={this.companyName}
                    isLoading={this.isLoading}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        const defaultTab = ownProps.isVideoTab ? TYPES.VIDEO : TYPES.ALL;
        const category = ownProps.category || _.get(ownProps, 'match.params.category') || _.get(ownProps, 'route.params.category') || CATEGORIES.ALL;
        const type = ownProps.type || _.get(ownProps, 'match.params.type') || _.get(ownProps, 'route.params.type') || defaultTab;
        const search = ownProps.search || ContentListBase.searchText;
        const path = [search ? CATEGORIES.SEARCH : category, type];
        const items = selectors.getContentByPath(state, path);
        const count = selectors.getContentCountByPath(state, path);
        return {
            type,
            items,
            category,
            categories: selectors.getCategories(state),
            isLoadingSelector: selectors.isLoadingContent(state),
            isSearching: selectors.isSearchingContent(state),
            count,
            currentUser: coreSelectors.getCurrentUser(state),
            isLoadingContentCount: selectors.isLoadingContentCount(state)
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(contentActions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(ContentListBase));
}
