import _ from 'lodash';
import autoMergeLevel3 from './autoMergeLevel3';

import { reducer as authReducer, constants as authConstants } from '../modules/auth';
import { reducer as coreReducer, constants as coreConstants, tracker, Storage } from '../modules/core';
import { reducer as notificationReducer, constants as notificationConstants } from '../modules/notifications';
import { reducer as onboardingReducer, constants as onboardingConstants } from '../modules/onboarding';
import { reducer as communitiesReducer, constants as communitiesConstants } from '../modules/communities';
import { reducer as feedsReducer, constants as feedsConstants } from '../modules/feeds';
import { reducer as CLReducer, constants as CLConstants } from '../modules/commentslikes';
import { reducer as rewardsReducer, constants as rewardsConstants } from '../modules/rewards';
import { reducer as settingsReducer, constants as settingsConstants } from '../modules/settings';
import { reducer as appsDevicesReducer, constants as appsDevicesConstants } from '../modules/appsdevices';
import { reducer as invitationsReducer, constants as invitationsConstants } from '../modules/invitations';
import { reducer as challengesReducer, constants as challengesConstants } from '../modules/challenges';
import { reducer as librariesReducer, constants as librariesConstants } from '../modules/content';
import { reducer as eventsReducer, constants as eventsConstants } from '../modules/events';
import { reducer as toursReducer, constants as toursConstants } from '../modules/tours';
import { reducer as otherReducer, constants as otherConstants } from '../modules/other';
import { reducer as resourcesReducer, constants as resourcesConstants } from '../modules/resources';

const reducers = {
    [coreConstants.NAME]: coreReducer,
    [authConstants.NAME]: authReducer,
    [notificationConstants.NAME]: notificationReducer,
    [settingsConstants.NAME]: settingsReducer,
    [appsDevicesConstants.NAME]: appsDevicesReducer,
    [onboardingConstants.NAME]: onboardingReducer,
    [communitiesConstants.NAME]: communitiesReducer,
    [feedsConstants.NAME]: feedsReducer,
    [CLConstants.NAME]: CLReducer,
    [invitationsConstants.NAME]: invitationsReducer,
    [rewardsConstants.NAME]: rewardsReducer,
    [challengesConstants.NAME]: challengesReducer,
    [librariesConstants.NAME]: librariesReducer,
    [eventsConstants.NAME]: eventsReducer,
    [toursConstants.NAME]: toursReducer,
    [otherConstants.NAME]: otherReducer,
    [resourcesConstants.NAME]: resourcesReducer
};

export default reducers;

// try to serialize but quit if it was too much.
const serialize = data => {
    try {
        return JSON.stringify(data);
    } catch (err) {
        const message = 'Failed to serialize data';
        const serializeError = JSON.stringify({
            message,
            err
        });
        tracker.logError(serializeError);
        return JSON.stringify({});
    }
};

export const persistConfig = {
    key: 'root',
    storage: Storage.storageType(),
    blacklist: _.compact([
        'router',
        coreConstants.NOT_PERSISTED && coreConstants.NAME,
        authConstants.NOT_PERSISTED && authConstants.NAME,
        feedsConstants.NOT_PERSISTED && feedsConstants.NAME,
        CLConstants.NOT_PERSISTED && CLConstants.NAME,
        communitiesConstants.NOT_PERSISTED && communitiesConstants.NAME,
        invitationsConstants.NOT_PERSISTED && invitationsConstants.NAME,
        rewardsConstants.NOT_PERSISTED && rewardsConstants.NAME,
        challengesConstants.NOT_PERSISTED && challengesConstants.NAME,
        eventsConstants.NOT_PERSISTED && eventsConstants.NAME,
        librariesConstants.NOT_PERSISTED && librariesConstants.NAME,
        notificationConstants.NOT_PERSISTED && notificationConstants.NAME,
        onboardingConstants.NOT_PERSISTED && onboardingConstants.NAME,
        settingsConstants.NOT_PERSISTED && settingsConstants.NAME,
        appsDevicesConstants.NOT_PERSISTED && appsDevicesConstants.NAME,
        resourcesConstants.NOT_PERSISTED && resourcesConstants.NAME
    ]),
    stateReconciler: autoMergeLevel3,
    serialize
};