import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import classnames from 'classnames';
import { baseColors, importantStyles, layoutStyle, spacing } from '../../../../styles';
import { components as Core } from '../../../core';
import { TOUCHABLE_TYPES } from '../../constants';
import WithRewardTouchableItemBase, { styles as baseStyles, IMAGE_SIZE } from './RewardTouchableItemBase';
import defaultImage from '../../../../image/exchange_icon.png';

class RewardTouchableItem extends PureComponent {
    static propTypes = {
        item: PropTypes.object.isRequired,
        date: PropTypes.string,
        transactionPoints: PropTypes.string,
        onDetailsPress: PropTypes.func,
        levelProgressValue: PropTypes.number,
        imageUrl: PropTypes.string,
        separator: PropTypes.bool,
        mainContainerClassName: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.array]),
        type: PropTypes.string.isRequired,
        itemDescription: PropTypes.string.isRequired,
        innerRewardComponent: PropTypes.node,
        showChallengeIcon: PropTypes.bool,
    };

    static defaultProps = {
        separator: true,
        mainContainerClassName: null,
        onDetailsPress: null,
        imageUrl: undefined,
        date: undefined,
        transactionPoints: undefined,
        innerRewardComponent: undefined,
        levelProgressValue: 0,
        showChallengeIcon: false,
    };

    get renderItem() {
        const { item, type, date, transactionPoints, levelProgressValue, itemDescription } = this.props;
        switch (type) {
            case TOUCHABLE_TYPES.LEVEL:
                return (
                    <div className={css(styles.itemContainer, layoutStyle.flex)}>
                        <div className={css(styles.levelProgressContainer)}>
                            <Core.TextSeeMore className={css(styles.name)} numberOfLines={1}>{item.name}</Core.TextSeeMore>
                            <Core.ProgressBar
                                variant="determinate"
                                filledPercentage={levelProgressValue}
                            />
                        </div>
                        <div className={css(styles.asideInfo)}>
                            <div className={css(styles.asideRewardsPoints)}>{`+${item.externalRewardPoints}`}</div>
                            <div className={css(styles.asideRewardsName)}>{item.externalRewardName}</div>
                        </div>
                    </div>
                );
            case TOUCHABLE_TYPES.TRANSACTION:
                return (
                    <div className={css(styles.itemContainer, layoutStyle.flex)}>
                        <div className={css(styles.content)}>
                            <Core.TextSeeMore className={css(styles.name)} numberOfLines={1}>{itemDescription}</Core.TextSeeMore>
                            <div className={css(styles.additionalInfo)}>{`${date} • ${item.status}`}</div>
                        </div>
                        <div className={css(styles.asideInfo, styles.asideRewardsPoints)}>{transactionPoints}</div>
                    </div>
                );
            case TOUCHABLE_TYPES.ORDER:
                return (
                    <div className={css(styles.itemContainer, layoutStyle.flex)}>
                        <div className={css(styles.content)}>
                            <Core.TextSeeMore className={css(styles.name)}>{item.productName}</Core.TextSeeMore>
                            <div className={css(styles.additionalInfo)}>{date}</div>
                        </div>
                        <div className={css(styles.asideInfo, styles.asideRewardsPoints)}>{item.status}</div>
                    </div>
                );
            default:
                return null;
        }
    }

    get challengeRewardCustomIcon() {
        return (
            <div className={css(layoutStyle.flexCenter, styles.coinsIconBg)}>
                <Core.Icon
                    type="light"
                    name="coins"
                    size={spacing.s4}
                    color={baseColors.warn}
                />
            </div>
        );
    }

    get image() {
        const { imageUrl } = this.props;
        return imageUrl ? imageUrl : defaultImage;
    }

    render() {
        const { onDetailsPress, separator, mainContainerClassName, innerRewardComponent, showChallengeIcon } = this.props;
        const image = showChallengeIcon ? null : this.image;
        const imageInnerComponent = showChallengeIcon ? this.challengeRewardCustomIcon : null;

        return (innerRewardComponent ? (
            <Core.TouchableItem
                onPressSingle={onDetailsPress}
                separator={separator}
                noShadow={true}
                containerClassName={classnames(css(styles.item, mainContainerClassName))}
                hasImageObjectFitContain={true}>
                {innerRewardComponent}
            </Core.TouchableItem>
        )
            : (
                <Core.TouchableItem
                    onPressSingle={onDetailsPress}
                    separator={separator}
                    containerClassName={classnames(css(styles.item), mainContainerClassName)}
                    innerContainerClassName={css(styles.innerContainer, layoutStyle.flex)}
                    imageInnerComponent={imageInnerComponent}
                    image={image}
                    imageWidth={IMAGE_SIZE}
                    imageHeight={IMAGE_SIZE}
                    hasImageContainMode={true}
                    noShadow={true}
                    hasImageObjectFitContain={true}>
                    {this.renderItem}
                </Core.TouchableItem>
            ));
    }
}

export default WithRewardTouchableItemBase(RewardTouchableItem);

const styles = StyleSheet.create(importantStyles({
    ...baseStyles,
    name: {
        ...baseStyles.name,
        marginRight: spacing.s4
    }
}));
