import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';

import { clearFeedbackError, postFeedback } from '../../actions';
import { appFonts, spacing } from '../../../../styles';
import { selectors as coreSelectors, translate, constants as coreConstants, actions as coreActions } from '../../../core';
import { getFeedbackError, isLoadingFeedback, getFeedbackMetadata } from '../../selectors';
import { INPUT_MAX_LENGTH, LIST_ITEM_TYPES } from '../../constants';

const REPORT_ISSUE = {
    msg: 'report_issue_success_msg',
    title: 'report_issue_success_title',
    error: 'report_issue_failure_msg'
};

const FEEDBACK = {
    msg: 'feedback_success_msg',
    title: 'feedback_success_title',
    error: 'feedback_failure_msg'
};

const ASK_QUESTION = {
    msg: 'ask_question_success_msg',
    title: 'ask_question_success_title',
    error: 'ask_question_failure_msg'
};

const SLUG_TYPES = {
    technical: 'technical',
    feedback: 'feedback'
};

export default function WithFeedbackBase(WrappedComponent) {
    class FeedbackBase extends PureComponent {
        static propTypes = {
            isLoading: PropTypes.bool,
            option: PropTypes.object.isRequired,
            feedbackError: PropTypes.object,    //eslint-disable-line
            actions: PropTypes.object.isRequired,
            i18n: PropTypes.object.isRequired
        };

        static defaultProps = {
            isLoading: false,
            feedbackError: undefined
        };

        constructor(props) {
            super(props);
            this.state = {
                feedbackText: ''
            };
        }

        onChange = feedbackText => this.setState({ feedbackText });

        get description() {
            const { i18n, option } = this.props;
            const { description, type } = option;
            if (type === LIST_ITEM_TYPES.help) {
                return description && description.replace(/\\/g, '');
            }

            return i18n.t('feedbackDescription');
        }

        get title() {
            const { i18n, option } = this.props;
            const { title, type } = option;
            if (type === LIST_ITEM_TYPES.help) {
                return title;
            }

            return i18n.t('feedbackTitle');
        }

        getSubmitParams = slug => {
            const { i18n } = this.props;
            let submitMsg;
            let submitTitle;
            let submitError;
            switch (slug) {
                case SLUG_TYPES.technical:
                    submitMsg = REPORT_ISSUE.msg;
                    submitTitle = REPORT_ISSUE.title;
                    submitError = REPORT_ISSUE.error;
                    break;
                case SLUG_TYPES.feedback:
                    submitMsg = FEEDBACK.msg;
                    submitTitle = FEEDBACK.title;
                    submitError = FEEDBACK.error;
                    break;
                default:
                    submitMsg = ASK_QUESTION.msg;
                    submitTitle = ASK_QUESTION.title;
                    submitError = ASK_QUESTION.error;
            }
            return [i18n.t(submitTitle), i18n.t(submitMsg), i18n.t(submitError)];
        };

        get isCharsExceeded() {
            const { feedbackText } = this.state;
            return feedbackText.length > INPUT_MAX_LENGTH;
        }

        get isFeedbackEmpty() {
            const { feedbackText } = this.state;
            return feedbackText.length === 0;
        }

        get isCharsError() {
            return this.isCharsExceeded || this.isFeedbackEmpty;
        }

        get charsRemainingText() {
            const { i18n } = this.props;
            const { feedbackText } = this.state;
            if (this.isCharsExceeded) {
                return i18n.t('feedback_char_limit');
            }
            return `${i18n.t('feedback_char_length_msg_1')} ${feedbackText.length}${i18n.t('feedback_char_length_msg_2')}`;
        }

        clearError = () => {
            if (this.props.feedbackError) this.props.actions.clearFeedbackError();
        };

        postFeedback = (...params) => {
            const { i18n } = this.props;
            if (this.isCharsExceeded) {
                this.props.actions.addToast(coreConstants.TOAST_TYPES.DANGER, null, i18n.t('feedback_char_limit_toast_msg'), i18n.t('feedback_char_limit_toast_title'));
            }
            else if (this.isFeedbackEmpty) {
                this.props.actions.addToast(coreConstants.TOAST_TYPES.DANGER, null, i18n.t('feedback_empty_toast_msg'), i18n.t('feedback_empty_toast_title'));
            } else this.props.actions.postFeedback(...params);
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    description={this.description}
                    title={this.title}
                    isCharsExceeded={this.isCharsExceeded}
                    charsRemainingText={this.charsRemainingText}
                    postFeedback={this.postFeedback}
                    onChange={this.onChange}
                    getSubmitParams={this.getSubmitParams}
                    feedbackText={this.state.feedbackText}
                    clearError={this.clearError}
                    isCharsError={this.isCharsError}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        let option = ownProps.option || _.get(ownProps, 'location.state.option') || _.get(ownProps, 'route.params.option');
        if (!option) {
            option = _.find(getFeedbackMetadata(state), option => (option.type === LIST_ITEM_TYPES.feedback));
        }
        return {
            userId: coreSelectors.getCurrentUserId(state),
            partnerId: coreSelectors.getCurrentUserPartnerId(state),
            option,
            feedbackError: getFeedbackError(state),
            isLoading: isLoadingFeedback(state)
        };
    };

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators({
        clearFeedbackError,
        postFeedback,
        addToast: coreActions.addToast
    }, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(FeedbackBase));
}


export const styles = {
    feedbackField: {
        paddingTop: spacing.s1,
        flex: 1
    },
    titleContainer: {
        marginLeft: 0,
        marginRight: 0
    },
    charsRemain: {
        ...appFonts.xsRegular,
        flex: 1,
        alignSelf: 'flex-end'
    }
};
