export const NAME = 'commentslikes';

export const CHILD_NAMES = Object.freeze({
    comments: 'comments',
    likes: 'likes',
    reactions: 'reactions'
});

export const LIKES_ENTITY_TYPES = Object.freeze({
    stream: 'stream',
});

export const LIKES_TYPE_IDS_BY_TYPE = Object.freeze({
    [LIKES_ENTITY_TYPES.stream]: 9,
});

export const COMMENT_MODES = Object.freeze({
    full: 'full',
    preview: 'preview'
});

export const NOT_PERSISTED = false;
