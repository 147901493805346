import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { translate, numbers, selectors as coreSelectors } from '../../../../core';
import { selectors as rewardsSelectors } from '../../../../rewards';
import { selectors as challengeSelectors } from '../../..';
import { baseColors, layoutStyle, spacing, appFonts } from '../../../../../styles';

const BONUS_POINTS_PERCENTAGE = 85;

export default function WithCompletionBonusBase(WrappedComponent) {
    class CompletionBonusBase extends PureComponent {
        static propTypes = {
            pointsName: PropTypes.string,
            currencyName: PropTypes.string,
            challengeType: PropTypes.string,
            i18n: PropTypes.object.isRequired,
            progress: PropTypes.object.isRequired,
            challenge: PropTypes.object.isRequired,
            isBonusChallenge: PropTypes.bool.isRequired,
            isConnectedDevice: PropTypes.bool.isRequired,
            isBonusWithPoints: PropTypes.bool.isRequired,
            challengeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
        };

        static defaultProps = {
            pointsName: '',
            currencyName: '',
            challengeType: ''
        };

        get completionBonusTitle() {
            return this.props.i18n.t('challenge_completion_bonus');
        }

        get formatedPercentage() {
            return Math.round(this.props.progress.overallUserTotalPercentage);
        }

        get isBonusEarned() {
            return this.props.isBonusChallenge && (this.formatedPercentage >= BONUS_POINTS_PERCENTAGE);
        }

        get earnedBonusCompleteText() {
            return this.props.i18n.t('youEarnedBonus');
        }

        get earnBonusTextInfo() {
            const { i18n, currencyName, pointsName, challenge, challengeType, isBonusWithPoints } = this.props;
            const textWithReward = 'bonusChallengeInfo.withReward';
            const texNoReward = 'bonusChallengeInfo.noRewardChallengeType';
            let amount;
            let points;
            if (challenge.potentialBonusCurrency) {
                amount = numbers.numberWithCommas(challenge.potentialBonusCurrency);
                points = currencyName;
            } else if (challenge.potentialBonusPoints) {
                amount = numbers.numberWithCommas(challenge.potentialBonusPoints);
                points = pointsName;
            }
            return isBonusWithPoints ? i18n.t(textWithReward, { amount, points, challengeType }) : i18n.t(texNoReward, { challengeType });
        }

        get doubleBonusForConnectedDeviceText() {
            const { i18n, pointsName } = this.props;
            const textName = 'bonusGoal.additionalInfo';
            return i18n.t(textName, { points: pointsName });
        }
        get deviceBonusText() {
            return this.props.i18n.t('doubleBonusLong');
        }

        get achievedDoubleBonus() {
            return !!_.get(this.props, 'challenge.achievedDoubleBonus');
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    isBonusEarned={this.isBonusEarned}
                    deviceBonusText={this.deviceBonusText}
                    earnBonusTextInfo={this.earnBonusTextInfo}
                    formatedPercentage={this.formatedPercentage}
                    achievedDoubleBonus={this.achievedDoubleBonus}
                    completionBonusTitle={this.completionBonusTitle}
                    earnedBonusCompleteText={this.earnedBonusCompleteText}
                    doubleBonusForConnectedDeviceText={this.doubleBonusForConnectedDeviceText}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => ({
        pointsName: coreSelectors.getCustomPointsName(state),
        currencyName: rewardsSelectors.externalRewardName(state),
        challenge: challengeSelectors.getChallenge(state, ownProps.challengeId),
        isBonusChallenge: challengeSelectors.isBonusChallenge(state, ownProps.challengeId),
    });

    return connect(mapStateToProps)(translate()(CompletionBonusBase));
}

export const ICON_SIZE = 18;

export const styles = {
    cardBodyContainer: {
        ...layoutStyle.commonPaddingHorizontal,
        paddingTop: spacing.s5,
        paddingBottom: spacing.s6,
        borderTopColor: baseColors.grey80,
        borderTopWidth: 2
    },
    cardBodyTitleContainer: {
        marginBottom: spacing.s1
    },
    cardBodyTitleText: {
        ...appFonts.lgBold
    },
    bonusMarkContainer: {
        marginTop: spacing.s1,
        marginBottom: spacing.s3,
        marginRight: spacing.s0,
    },
    completeBonusContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingBottom: spacing.s1,
        paddingRight: spacing.s1,
        flexWrap: 'wrap'
    },
    infoIcon: {
        alignSelf: 'center',
        marginRight: spacing.s0
    },
    progressContainer: {
        marginBottom: spacing.s5
    },
    earnBonusInfoText: {
        ...appFonts.smRegular,
        color: baseColors.grey40,
        marginBottom: spacing.s3
    },
    regularTextStyle: {
        ...appFonts.smRegular,
        color: baseColors.grey20
    },
    mBottomSmall: {
        marginBottom: spacing.s0,
    },
    pBottom: {
        paddingBottom: spacing.s0,
    },
};
