import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import _ from 'lodash';
import { Transition, TransitionGroup } from 'react-transition-group';
import { StyleSheet, css } from 'aphrodite-jss';
import InfiniteScroll from 'react-infinite-scroller';
import Image from './Image';
import { windowSize, spacing, media } from '../../../styles';
import { ListLoading as Loading } from './Loading';

export const DURATION = 300;
const HEIGHT = 100;

export class InfiniteLazyListFadeIn extends Component {
    static propTypes = {
        children: PropTypes.node,
        pageStart: PropTypes.number,
        onEndReached: PropTypes.func,
        hasMore: PropTypes.bool,
        loader: PropTypes.node,
        data: PropTypes.array,
        renderItem: PropTypes.func,
        keyExtractor: PropTypes.func,
        isLoading: PropTypes.bool,
        hasMarginHorizontal: PropTypes.bool,
        onEndReachedThreshold: PropTypes.number,
        ListHeaderComponent: PropTypes.node,
        ListFooterComponent: PropTypes.node,
        ListEmptyComponent: PropTypes.node,
        itemHeight: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
        initialLoad: PropTypes.bool,
        isInModal: PropTypes.bool,
        isHorizontal: PropTypes.bool,
        hideLoader: PropTypes.bool,
        isSearching: PropTypes.bool,
        twoColumns: PropTypes.bool,
        listContentClassName: PropTypes.string,
        scrollParentRef: PropTypes.oneOfType([PropTypes.func,
            PropTypes.shape({ current: PropTypes.instanceOf(Element) })
        ])
    };

    static defaultProps = {
        children: undefined,
        pageStart: 0,
        onEndReached: _.noop,
        hasMore: true,
        hasMarginHorizontal: true,
        loader: <Loading key="Loading" />,
        data: undefined,
        renderItem: undefined,
        keyExtractor: undefined,
        isLoading: undefined,
        onEndReachedThreshold: undefined,
        ListHeaderComponent: undefined,
        ListFooterComponent: undefined,
        ListEmptyComponent: undefined,
        itemHeight: undefined,
        initialLoad: false,
        isInModal: false,
        isHorizontal: undefined,
        hideLoader: false,
        isSearching: false,
        twoColumns: undefined,
        listContentClassName: '',
        scrollParentRef: undefined
    };

    static getComponent(component) {
        return component && (_.isFunction(component) ? component() : component);
    }

    constructor(props) {
        super(props);
        this.state = { data: props.data };
        // hack for transition to work
        setTimeout(() => this.setState({}));
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            //to trigger showing the list, because of lazy loading new elements are prevented from loading
            window.scrollBy(0, 1);
            window.scrollBy(0, -1);
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState(() => ({ data: this.props.data }));
        }
        if (!this.props.isLoading && this.props.hasMore && windowSize.height >= document.body.scrollHeight && this.props.onEndReached) {
            this.props.onEndReached();
            //TODO: Review InfiniteScroll component, it doesnt trigger load more when list smaller than page and no scroll on page! this is hot fix for now to make it usable on big screens
        }
    }

    get ListHeaderComponent() {
        if (!this.props.ListHeaderComponent) return null;
        return _.isFunction(this.props.ListHeaderComponent)
            ? this.props.ListHeaderComponent() : this.props.ListHeaderComponent;
    }

    render() {
        const {
            children,
            pageStart,
            onEndReached,
            hasMore,
            loader,
            renderItem,
            keyExtractor,
            isLoading,
            ListHeaderComponent,
            onEndReachedThreshold,
            ListFooterComponent,
            ListEmptyComponent,
            itemHeight,
            isHorizontal,
            initialLoad,
            isInModal,
            hasMarginHorizontal,
            hideLoader,
            isSearching,
            twoColumns,
            listContentClassName,
            scrollParentRef,
            ...props
        } = this.props;
        const { data } = this.state;
        return (
            <React.Fragment>
                {InfiniteLazyListFadeIn.getComponent(ListHeaderComponent)}
                {data && data.length ? (
                    <InfiniteScroll
                        pageStart={pageStart}
                        loadMore={onEndReached}
                        useWindow={!isInModal}
                        hasMore={hasMore}
                        loader={isLoading === undefined ? loader : undefined}
                        threshold={onEndReachedThreshold * windowSize.height}
                        getScrollParent={() => scrollParentRef?.current}
                        initialLoad={initialLoad}>
                        <div className={css(twoColumns ? styles.twoColumnsWrapper : styles.container, hasMarginHorizontal ? styles.marginHorizontal : null, listContentClassName)}>
                            {data && renderItem ? (
                                _.map(data, (item, index) => (
                                    <LazyFadeIn
                                        isHorizontal={isHorizontal}
                                        twoColumns={twoColumns}
                                        {...props}
                                        height={typeof itemHeight === 'function' ? itemHeight(item, index) : itemHeight}
                                        key={(keyExtractor && keyExtractor(item, index))}>
                                        {renderItem({ item, index })}
                                    </LazyFadeIn>
                                ))
                            ) : (
                                _.map(children, child => (
                                    <LazyFadeIn
                                        isHorizontal={isHorizontal}
                                        {...props}
                                        height={child.props.height || itemHeight}
                                        key={child.props.key}>
                                        {child}
                                    </LazyFadeIn>
                                ))
                            )}
                        </div>
                    </InfiniteScroll>
                ) : null}
                {ListEmptyComponent && !isLoading && (!data || !data.length) ? ListEmptyComponent : null}
                {isLoading && !isSearching && !hideLoader ? loader : null}
                {InfiniteLazyListFadeIn.getComponent(ListFooterComponent)}
            </React.Fragment>
        );
    }
}

export class LazyListFadeIn extends Component {
    static propTypes = {
        children: PropTypes.node,
        data: PropTypes.array,
        renderItem: PropTypes.func,
        keyExtractor: PropTypes.func,
        itemHeight: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
        overflow: PropTypes.bool
    };

    static defaultProps = {
        children: undefined,
        data: undefined,
        renderItem: undefined,
        keyExtractor: undefined,
        itemHeight: undefined,
        overflow: undefined
    };

    constructor(props) {
        super(props);
        this.state = {};
        // hack for transition to work
        setTimeout(() => this.setState({}));
    }

    render() {
        const { children, data, renderItem, keyExtractor, itemHeight, ...props } = this.props;
        return (
            <TransitionGroup>
                {data && renderItem ? (
                    _.map(data, (item, index) => (
                        <LazyFadeIn
                            {...props}
                            height={typeof itemHeight === 'function' ? itemHeight(item, index) : itemHeight}
                            key={(keyExtractor && keyExtractor(item, index))}>
                            {renderItem({ item, index })}
                        </LazyFadeIn>
                    ))
                ) : (
                    _.map(children, (child, i) => (
                        <LazyFadeIn {...props} height={child.props.height || itemHeight} key={i}>{child}</LazyFadeIn>
                    ))
                )}
            </TransitionGroup>
        );
    }
}

export const LazyList = ({ children, ...props }) => (
    <div>
        {_.map(children, (child, i) => (
            <LazyLoad {...props} height={child.props.height || props.height} key={i}>{child}</LazyLoad>
        ))}
    </div>
);
LazyList.propTypes = {
    children: PropTypes.node,
    height: PropTypes.number
};
LazyList.defaultProps = {
    children: undefined,
    height: undefined
};

export const LazyFadeIn= ({ height = HEIGHT, offset, once, overflow, children, isHorizontal, twoColumns, ...props }) => (
    <LazyLoad height={height} offset={offset} once={once} overflow={overflow} className={twoColumns ? css(styles.twoColumnsItem) : null}>
        <FadeIn isHorizontal={isHorizontal} {...props}>{children}</FadeIn>
    </LazyLoad>
);
LazyFadeIn.propTypes = {
    children: PropTypes.node,
    height: PropTypes.number,
    offset: PropTypes.number,
    once: PropTypes.bool,
    overflow: PropTypes.bool,
    isHorizontal: PropTypes.bool
};
LazyFadeIn.defaultProps = {
    children: undefined,
    height: undefined,
    offset: undefined,
    once: undefined,
    overflow: undefined,
    isHorizontal: undefined
};

export const FadeIn = ({ in: inProp, duration = DURATION, children, isHorizontal, ...props }) => (
    <Transition in={inProp} timeout={duration} {...props}>
        {state => (
            <div className={getFadeClasses(styles, state, duration, isHorizontal)}>
                { children }
            </div>
        )}
    </Transition>
);
FadeIn.propTypes = {
    children: PropTypes.node,
    duration: PropTypes.number,
    in: PropTypes.bool,
    isHorizontal: PropTypes.bool
};
FadeIn.defaultProps = {
    children: undefined,
    duration: undefined,
    in: true,
    isHorizontal: false
};


const transitionStyles = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 }
};

const getDefaultStyles = (duration = DURATION) => ({
    transition: `transform ${duration}ms, opacity ${duration}ms ease`,
    opacity: 1
});

export const FadeInFadeOut = ({ in: inProp, children, timeout, ...props }) => (
    <Transition
        in={inProp}
        appear={true}
        unmountOnExit={true}
        {...props}>
        {state => (
            <div style={{ ...getDefaultStyles(timeout), ...transitionStyles[state] }}>
                {children}
            </div>
        )}
    </Transition>
);

export const LazyFadeInImage = ({ height = HEIGHT, offset, ...props }) => (
    <LazyLoad height={height} offset={offset}>
        <Image {...props} isFadeIn={true} />
    </LazyLoad>
);
LazyFadeInImage.propTypes = {
    height: PropTypes.number,
    offset: PropTypes.number
};
LazyFadeInImage.defaultProps = {
    height: undefined,
    offset: undefined
};

/* eslint-disable */  //new eslint version doesnt include ingore unused styles rule. Should be fixed by not saving unused styles :/
export const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'space-between',
        flex: 1
    },
    twoColumnsWrapper: {
        flexDirection: 'row',
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1
    },
    twoColumnsItem: {
        flex: '50%',
    },
    marginHorizontal: {
        marginLeft: spacing.s1,
        marginRight: spacing.s1
    },
    default: {
        opacity: 0,
        transitionProperty: 'opacity',
        transitionTimingFunction: 'ease-in-out',
        width: '100%'
    },
    multipleItem: {
        minWidth: 300,
        [media.xsOnly]: {
            width: '100%'
        },
        [media.smOnly]: {
            width: '50%'
        },
        [media.md_lg]: {
            width: '50%'
        },
        [media.xlOnly]: {
            width: '50%'
        },
        width: '25%'
    },
    entering: { opacity: 1 }, // todo: check feeds
    entered: { opacity: 1 },
    td300: { transitionDuration: '300ms' },
    td500: { transitionDuration: '500ms' },
    td1200: { transitionDuration: '1200ms' },
    image: {
        width: '100%',
        display: 'block',
        objectFit: 'cover'
    }
});
/* eslint-enable */

export function getFadeClasses(styles, state, duration, isHorizontal) {
    return css(styles.default, styles[`td${duration}`] || styles[`td${DURATION}`], styles[state], isHorizontal && styles.multipleItem);
}
