import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { css, StyleSheet } from 'aphrodite-jss';
import { components as Core, ROUTES } from '../../../core';
import WithEAPSelectOptionsBase, { styles as baseStyles } from './EAPSelectOptionsBase';
import { containerStyle, layoutStyle, spacing } from '../../../../styles';
import SkipGoButtons from '../SkipGoButtons';

class EAPSelectOptions extends PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        task: PropTypes.object.isRequired,
        options: PropTypes.array,
        selected: PropTypes.array,
        toggleCheckbox: PropTypes.func.isRequired,
        step: PropTypes.string,
        isLoading: PropTypes.bool,
        goToContactInfo: PropTypes.func.isRequired,
        onNext: PropTypes.func,
        onSkip: PropTypes.func,
        history: PropTypes.object.isRequired,
        isOnboarding: PropTypes.bool,
        rightButtonLabel: PropTypes.string
    };

    static defaultProps = {
        options: [],
        selected: [],
        step: null,
        isLoading: false,
        onNext: null,
        onSkip: null,
        isOnboarding: false,
        rightButtonLabel: undefined
    };

    get bottomButtonsHeader() {
        const { task: { name, privacyUrl } } = this.props;
        return (
            <Core.SafeA
                id={_.camelCase(`${name}`)}
                target="_blank"
                href={privacyUrl}
                className={css(styles.policyTextWrapper)}>
                <p className={css(layoutStyle.secondary, styles.policyText)}>{ this.props.i18n.t('EAP.privacyPolicy', { provider: name }) }</p>
            </Core.SafeA>
        );
    }

    onNext = () => {
        this.props.goToContactInfo();
        if (this.props.onNext) return this.props.onNext(this.props.step);
        this.props.history.push(ROUTES.EAPContact());
    };

    onSkip = () => {
        if (this.props.onSkip) this.props.onSkip();
        else this.props.history.push(ROUTES.home());
    };

    get buttons() {
        return (
            <SkipGoButtons
                isEAP={true}
                isOnboarding={this.props.isOnboarding}
                hasBorder={this.props.isOnboarding}
                rightButtonLabel={this.props.rightButtonLabel}
                go={this.onNext}
                skip={this.onSkip}
                customButtons={this.bottomButtonsHeader}
                hasSkipGoBtns={true}
                onboardingNewDesign={true}
            />
        );
    }

    render() {
        const { options, selected, toggleCheckbox, task: { subtitle }, isOnboarding } = this.props;
        const title = this.props.i18n.t('EAP.title');
        return (
            <div className={css(layoutStyle.content, styles.content, layoutStyle.pRelative)}>
                <Core.Layout.PageWrapper isModal={this.props.isOnboarding}>
                    <div className={css(containerStyle.container, styles.container)}>
                        {!isOnboarding ?
                            <>
                                <Core.EntityDetailsHeader hasBackButton={true} title={title} />
                                <Core.SubHeader title={title} titleAlign={Core.SubHeader.ALIGN.center} noLeftPadding={true} />
                            </> : null
                        }
                        <p className={css(styles.subtitle)}>{subtitle}</p>
                        {this.props.isLoading && !options.length ?
                            <Core.ListLoading key="Loading" /> :
                            <Core.MultiSelectCheck
                                options={options}
                                onPressCallback={toggleCheckbox}
                                initValues={true}
                                selected={selected}
                                titleStyle={styles.selectHeader}
                                wrapperStyle={styles.wrapperStyle}
                                subtitleStyle={styles.selectSubheader}
                            />
                        }
                    </div>
                    {this.buttons}
                </Core.Layout.PageWrapper>
            </div>
        );
    }
}

const styles = StyleSheet.create({
    ...baseStyles,
    selectSubheader: {
        ...baseStyles.selectSubheader,
        marginLeft: -spacing.s1
    },
    content: {
        paddingBottom: spacing.s4
    },
    policyTextWrapper: {
        ...baseStyles.policyTextWrapper,
        marginBottom: spacing.s3
    },
    policyText: {
        ...baseStyles.policyText,
        paddingBottom: spacing.s5
    }
});

export default WithEAPSelectOptionsBase(EAPSelectOptions);
