import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';

import * as actions from '../../../actions';
import { getIsTeamChallenge, getChallenge, getMyPersonalGoals, isRestartGoal, getChallengeType, getChallengeProgressByUserActivityLogs, isBonusChallenge } from '../../../selectors';
import { translate, DATE_FORMATS, selectors as coreSelectors, numbers } from '../../../../core';
import { appFonts, baseColors, spacing } from '../../../../../styles';
import { selectors as onboardingSelectors } from '../../../../onboarding';
import {
    isCompletedNotInGracePeriod,
    isTeamSizeIncorrect,
    isInGracePeriod,
    isAfterDeadline,
    getWeeklyProgress,
    getCurrentWeekIndex,
    isGoal,
    isPreviousNotWeeklyGoal,
    getSingleTrackedActivityValueString,
    getCompletedPercent,
    getTodayProgress,
    oneDecimal,
    dailyWeeksProgressHelper,
    getPeriodTotalPoints,
    hasMultipleActivitiesToTrack,
    isGoalType,
    isTodayFirstDayOfChallenge,
    getCustomPointsName
} from '../../../services/helper';
import { CHALLENGE_TYPES, FREQUENCY, UNIT_POINTS, SINGLE_DAILY_AVERAGE_UNIT_TYPES } from '../../../constants';

export default function WithProgressBase(WrappedComponent) {
    class ProgressBase extends PureComponent {
        static propTypes = {
            isUpdating: PropTypes.bool,
            isRestartGoal: PropTypes.bool,
            i18n: PropTypes.object.isRequired,
            challenge: PropTypes.object.isRequired,
            personalGoals: PropTypes.array.isRequired,
            challengeType: PropTypes.string.isRequired,
            challengeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            isTeamChallenge: PropTypes.bool.isRequired
        };

        static defaultProps = {
            isUpdating: false,
            isRestartGoal: false,
        };

        constructor(props) {
            super(props);
            this.state = {
                weeksProgress: [],
                weekIndex: 0,
                barIndex: null,
                isShowBarDetails: false,
                dayState: '',
                dayStateNum: null,
            };
        }

        componentDidMount() {
            this.setWeeksProgress();
        }

        componentDidUpdate(prevProps) {
            if (this.props.challenge.userActivityLogs.length !== prevProps.challenge.userActivityLogs.length) {
                this.setWeeksProgress(true);
            }
        }

        get isTrackingImpossible() {
            return isCompletedNotInGracePeriod(this.props.challenge) || isTeamSizeIncorrect(this.props.challenge);
        }

        get daysLeft() {
            const { challenge } = this.props;
            if (isInGracePeriod(challenge)) return challenge.track_deadline.days_left;
            return 0;
        }

        get daysLeftLabel() {
            return this.props.i18n.t(this.daysLeft > 1 ? 'days' : 'day').toLowerCase();
        }

        get challengeEndedDate() {
            return moment(this.props.challenge.challengeDeadline).format(DATE_FORMATS.monthFullDayShortYearFull);
        }

        get progressTitle() {
            return this.props.i18n.t('dailyProgress');
        }

        get greacePeriodBonusMessageTitle() {
            const { i18n, isRestartGoal, challengeType } = this.props;
            return i18n.t(isRestartGoal ? 'restartGoal.weekEnded' : 'challengeEnded', { challengeType });
        }

        get gracePeriodBonusMessageSubtitle() {
            return this.props.i18n.t('potentialBonusPoints.msgEnded.subtitle', { daysNum: this.daysLeft, daysOrDay: this.daysLeftLabel });
        }

        get endedBonusMessageTitle() {
            return this.props.isRestartGoal ? this.endedRestartGoalTitle : this.props.i18n.t('potentialBonusPoints.msgEnded.title');
        }

        get endedRestartGoalTitle() {
            return `${this.props.i18n.t('restartGoal.timesUp')} ${this.props.i18n.t('restartGoal.weekEnded')}`;
        }
        get goalEndedTitle() {
            const { i18n, challengeType } = this.props;
            return i18n.t('goalEndedModalTitle', { challengeType });
        }

        get goalEndedMessage() {
            return this.props.i18n.t('goalEndedModal.message');
        }

        get isGoalRestarted() {
            const { personalGoals, challenge } = this.props;
            return isPreviousNotWeeklyGoal(challenge) && _.some(personalGoals, goal => {
                if (goal.recommendedGoalsId) return goal.recommendedGoalsId === challenge.recommendedGoalsId;
                return goal.challengeName === challenge.challengeName;
            });
        }

        get goalRestartedText() {
            return this.props.i18n.t('goalRestarted');
        }

        get isBonusWithPoints() {
            const { challenge: { potentialBonusCurrency, potentialBonusPoints } } = this.props;
            return potentialBonusCurrency || potentialBonusPoints;
        }

        get isConnectedDevice() {
            const { challenge: { potentialBonusPoints, potentialBonusCurrency, recommendedTrackingApps } } = this.props;
            return potentialBonusPoints && !potentialBonusCurrency && recommendedTrackingApps.length;
        }

        get competitionTitle() {
            const { isTeamChallenge, i18n } = this.props;
            return i18n.t(isTeamChallenge ? 'your_progress_today' : 'today');
        }

        get goalPeriodTitle() {
            const { i18n, challenge: { frequency } } = this.props;
            switch (true) {
                case frequency === FREQUENCY.monthly:
                    return i18n.t('this_month_progress');
                case frequency === FREQUENCY.weekly:
                    return i18n.t('this_week_progress');
                case frequency === FREQUENCY.total:
                    return i18n.t('overall_progress');
                default:
                    return null;
            }
        }

        get isDailyGoal() {
            const { challenge: { frequency, challengeType } } = this.props;
            return challengeType === CHALLENGE_TYPES.goal && frequency === FREQUENCY.daily;
        }

        get dailyUnitForGoalPeriod() {
            const { challenge: { activityUnitDetails }, customPointsName } = this.props;
            if (activityUnitDetails.length) {
                return activityUnitDetails.length > 1 || (activityUnitDetails[0].unit === UNIT_POINTS) ? customPointsName : activityUnitDetails[0].unit;
            }
            return;
        }

        get dailyAverage() {
            const { i18n, challenge: { dailyAverageAmount: { value, unit } } } = this.props;
            const unitTypesArray = Object.keys(SINGLE_DAILY_AVERAGE_UNIT_TYPES);
            const unitSingleType = unitTypesArray.includes(unit) && SINGLE_DAILY_AVERAGE_UNIT_TYPES[unit];
            const formattedAmountDailyAverageUnit = (value === 1) ? unitSingleType : unit;
            return {
                left: i18n.t('your_daily_avarage'),
                right: ` ${numbers.numberWithCommas(value)} ${formattedAmountDailyAverageUnit || this.dailyUnitForGoalPeriod}`,
                isLeftGrey: true
            };
        }

        get todayProgressResult() {
            const { i18n, completedPercent } = this.props;
            if (this.isDailyGoal) {
                return {
                    right: i18n.t('complete'),
                    left: `${numbers.numberWithCommas(completedPercent)}% `,
                    isRightGrey: true
                };
            }
            return this.dailyAverage;
        }

        get goalPeriodProgress() {
            const { challenge } = this.props;
            if (hasMultipleActivitiesToTrack(challenge) && challenge.activityUnitDetails.length) {
                return {
                    trackedValue: numbers.numberWithCommas(getPeriodTotalPoints(challenge)),
                    unit: this.formatedCustomPointsName,
                    periodValue: numbers.numberWithCommas(_.get(challenge, 'progress.overallPointsTargetValue')),
                };
            }
            return getSingleTrackedActivityValueString(challenge);
        }

        get goalProgressResult() {
            const { i18n, completedPercent } = this.props;
            return {
                right: i18n.t('complete'),
                left: `${numbers.numberWithCommas(completedPercent)}% `,
                isRightGrey: true
            };
        }

        get todayProgressUnit() {
            const { todayProgress } = this.props;
            if (todayProgress) {
                return this.isDailyGoal
                    ? `/ ${numbers.numberWithCommas(todayProgress.periodValue)} ${todayProgress.unit}`
                    : todayProgress.unit;
            }
            return;
        }

        get formatedCustomPointsName() {
            const { customPointsName } = this.props;
            return getCustomPointsName(customPointsName);
        }

        get barChartUnit() {
            const { challenge } = this.props;
            const { dayStateNum } = this.state;
            const barChartUnit = hasMultipleActivitiesToTrack(challenge) ? this.formatedCustomPointsName : this.activityUnit;
            const dayState = dayStateNum && `${numbers.numberWithCommas(dayStateNum)} ${barChartUnit}`;
            this.setState({ dayState });
            return barChartUnit;
        }

        get activityUnit() {
            const { challenge: { activityUnitDetails } } = this.props;
            const { dayStateNum } = this.state;
            if (activityUnitDetails.length) {
                return activityUnitDetails[0][dayStateNum > 1 ? 'unit' : 'unit_singular'];
            }
            return;
        }

        get trackedPoints() {
            if (this.props.todayProgress) {
                const { todayProgress: { trackedValue } } = this.props;
                const valueString = trackedValue && trackedValue.toString();
                return trackedValue && (numbers.numberWithCommas(valueString.includes('.') ? oneDecimal(valueString) : trackedValue));
            }
            return;
        }

        get isShowLeftChevron() {
            const { weekIndex, weeksProgress } = this.state;
            return (weekIndex !== 0 && weeksProgress.length !== 1);
        }

        get isShowRightChevron() {
            const { weekIndex, weeksProgress } = this.state;
            return weeksProgress.length > weekIndex + 1 && weeksProgress.length !== 1;
        }

        get paginationControlTitle() {
            const { weekIndex, weeksProgress } = this.state;
            const currentWeek = weeksProgress[weekIndex];
            const lastIndex = _.findLastIndex(currentWeek);
            const firstEl = currentWeek ? currentWeek[0] : null;
            const lastEl = currentWeek ? currentWeek[lastIndex] : null;
            const startDate = moment(firstEl?.date).date();
            const lastDate = moment(lastEl?.date).date();
            const startDateMonth = moment(firstEl?.date).format(DATE_FORMATS.month);
            const lastDateMonth = moment(lastEl?.date).format(DATE_FORMATS.month);
            const isSameMonth = startDateMonth === lastDateMonth;
            const endDateFormatted = `${isSameMonth ? '-' : ` - ${lastDateMonth}`}${isSameMonth ? '' : ' '}${lastDate}`;
            const text = `${startDateMonth} ${startDate}${endDateFormatted}`;
            return text;
        }

        get streakDetails() {
            const { i18n, challenge: { frequency, userCompletedPeriodsStreak } } = this.props;
            if (userCompletedPeriodsStreak > 1 && frequency === FREQUENCY.daily) return i18n.t('dayStreak', { n: userCompletedPeriodsStreak });
            if (userCompletedPeriodsStreak > 1 && frequency === FREQUENCY.weekly) return i18n.t('weekStreak', { n: userCompletedPeriodsStreak });
            return null;
        }

        get isDaily() {
            const { challenge: { frequency } } = this.props;
            return frequency === FREQUENCY.daily;
        }

        get shouldShowAverage() {
            const { challenge } = this.props;
            return isTodayFirstDayOfChallenge(challenge);
        }

        get participantsSuccessText() {
            const { challenge: { frequency, numChallengeParticipants, participantsCompletedPeriod }, i18n } = this.props;
            const amount = (participantsCompletedPeriod && participantsCompletedPeriod.total) || 0;
            let result;
            switch (true) {
                case frequency === FREQUENCY.monthly:
                    result = 'participantsSuccessCount.month';
                    break;
                case frequency === FREQUENCY.weekly:
                    result = 'participantsSuccessCount.week';
                    break;
                case frequency === FREQUENCY.daily:
                    result = 'participantsSuccessCount.day';
                    break;
                default:
                    result = 'participantsSuccessCount.overall';
            }
            return i18n.t(result, { amount, total: numChallengeParticipants });
        }

        get showParticipantsCompletedCount() {
            const { challenge, isTeamChallenge } = this.props;
            return isGoalType(challenge) && challenge.isSolo !== 1 && !isTeamChallenge;
        }

        get participantsSuccessTextArray() {
            const stringPiecesArray = this.participantsSuccessText.split(/(\d+) /);
            return _.compact(stringPiecesArray).map(item => `${item.trim()} `);
        }

        get dailyWeeksProgress() {
            const { challenge } = this.props;
            const { weeksProgress, weekIndex } = this.state;
            return dailyWeeksProgressHelper(weeksProgress, weekIndex, this.isDaily, challenge);
        }

        get hasProgressPerPeriod() {
            return _.max(this.dailyWeeksProgress) > 0;
        }

        get noActivityTitle() {
            return this.props.i18n.t('no_activity_title');
        }

        setWeeksProgress = (isActivityLogged = false) => {
            const { progressData: { dailyProgress } } = this.props;
            const weeksProgress = getWeeklyProgress(dailyProgress);
            const weekIndexOnChart = isActivityLogged ? this.state.weekIndex : getCurrentWeekIndex(weeksProgress);
            this.setState({ weeksProgress, weekIndex: weekIndexOnChart });
        }

        onChangeWeek = toIncrease => {
            this.setState(prevState => ({ weekIndex: toIncrease ? prevState.weekIndex + 1 : prevState.weekIndex - 1 }));
        }

        changeBarIndex = index => {
            const { weeksProgress, weekIndex } = this.state;
            const { challenge: { activityUnitDetails, progress } } = this.props;
            if (activityUnitDetails.length) {
                const currentWeek = weeksProgress[weekIndex];
                const dayTrackedQuantity = currentWeek ? currentWeek[index] : null;
                const dayTrackedPoints = parseInt(progress.overallPointsTargetValue) / 100 * dayTrackedQuantity.value;
                const dayStateNum = activityUnitDetails.length > 1 || activityUnitDetails[0].unit === UNIT_POINTS
                    ? dayTrackedPoints.toFixed()
                    : dayTrackedQuantity.trackedQuantity;
                this.setState({ barIndex: index, isShowBarDetails: true, dayStateNum });
                return this.barChartUnit;
            }
        }

        hideBarDetails = () => {
            this.setState({ barIndex: null, isShowBarDetails: false });
        }

        showStreak = (renderStreak, period) => {
            const { challenge } = this.props;
            return (challenge.frequency === FREQUENCY[period] && !isAfterDeadline(challenge)) ? renderStreak : null;
        };

        onPressLeftChevron = () => this.isShowLeftChevron ? this.onChangeWeek(false) : null

        onPressRightChevron = () => this.isShowRightChevron ? this.onChangeWeek(true) : null

        render() {
            const { challenge } = this.props;
            const { weeksProgress, weekIndex, barIndex, isShowBarDetails, dayState } = this.state;
            return (
                <WrappedComponent
                    {...this.props}
                    isBonusWithPoints={this.isBonusWithPoints}
                    daysLeft={this.daysLeft}
                    daysLeftLabel={this.daysLeftLabel}
                    progressTitle={this.progressTitle}
                    goalEndedTitle={this.goalEndedTitle}
                    isGoal={isGoal(challenge)}
                    isGoalRestarted={this.isGoalRestarted}
                    goalEndedMessage={this.goalEndedMessage}
                    goalRestartedText={this.goalRestartedText}
                    isInGracePeriod={isInGracePeriod(challenge)}
                    isAfterDeadline={isAfterDeadline(challenge)}
                    challengeEndedDate={this.challengeEndedDate}
                    isConnectedDevice={this.isConnectedDevice}
                    isTrackingImpossible={this.isTrackingImpossible}
                    endedBonusMessageTitle={this.endedBonusMessageTitle}
                    greacePeriodBonusMessageTitle={this.greacePeriodBonusMessageTitle}
                    gracePeriodBonusMessageSubtitle={this.gracePeriodBonusMessageSubtitle}
                    competitionTitle={this.competitionTitle}
                    todayProgressResult={this.todayProgressResult}
                    isDailyGoal={this.isDailyGoal}
                    goalPeriodTitle={this.goalPeriodTitle}
                    goalPeriodProgress={this.goalPeriodProgress}
                    goalProgressResult={this.goalProgressResult}
                    todayProgressUnit={this.todayProgressUnit}
                    trackedPoints={this.trackedPoints}
                    weeksProgress={weeksProgress}
                    weekIndex={weekIndex}
                    dailyWeeksProgress={this.dailyWeeksProgress}
                    isShowLeftChevron={this.isShowLeftChevron}
                    isShowRightChevron={this.isShowRightChevron}
                    paginationControlTitle={this.paginationControlTitle}
                    isDaily={this.isDaily}
                    changeBarIndex={this.changeBarIndex}
                    hideBarDetails={this.hideBarDetails}
                    barIndex={barIndex}
                    isShowBarDetails={isShowBarDetails}
                    dayState={dayState}
                    streakDetails={this.streakDetails}
                    showStreak={this.showStreak}
                    onPressLeftChevron={this.onPressLeftChevron}
                    onPressRightChevron={this.onPressRightChevron}
                    barChartUnit={this.barChartUnit}
                    showParticipantsCompletedCount={this.showParticipantsCompletedCount}
                    participantsSuccessTextArray={this.participantsSuccessTextArray}
                    shouldShowAverage={this.shouldShowAverage}
                    hasProgressPerPeriod={this.hasProgressPerPeriod}
                    dailyAverage={this.dailyAverage}
                    noActivityTitle={this.noActivityTitle}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const challenge = getChallenge(state, ownProps.challengeId);
        const customPointsName = coreSelectors.getCustomPointsUnit(state);
        return {
            personalGoals: getMyPersonalGoals(state),
            isOnboarding: onboardingSelectors.isShowingOnboarding(state),
            challenge,
            isRestartGoal: isRestartGoal(state, ownProps.challengeId),
            challengeType: getChallengeType(state, ownProps.challengeId),
            isTeamChallenge: getIsTeamChallenge(state, ownProps.challengeId),
            completedPercent: getCompletedPercent(challenge),
            todayProgress: getTodayProgress(challenge, customPointsName),
            progressData: getChallengeProgressByUserActivityLogs(state, ownProps.challengeId, false),
            customPointsName,
            isBonusChallenge: ownProps.challengeId
                ? isBonusChallenge(state, ownProps.challengeId)
                : _.get(ownProps, 'goal.isBonusChallenge')
        };
    };

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(ProgressBase));
}

const LINE_BOTTOM_OFFSET = 30;

export const styles = {
    cardBodyDailyProgress: {
        flex: 1,
        marginTop: spacing.s4,
        marginBottom: spacing.s2,
    },
    competitionProgressPadding: {
        paddingLeft: spacing.s2,
        paddingTop: spacing.s3,
        paddingBottom: spacing.s2
    },
    goalRestartedText: {
        ...appFonts.mdRegular,
        marginLeft: spacing.s2 - 2,
        color: baseColors.contextDarkest,
    },
    progressTitleText: {
        ...appFonts.lgBold,
    },
    progressQuantity: {
        ...appFonts.xlRegular,
        color: baseColors.black,
    },
    progressUnit: {
        ...appFonts.lgRegular,
        marginLeft: spacing.s0,
    },
    progressResult: {
        ...appFonts.mdRegular,
        flexWrap: 'wrap'
    },
    progressResultText: {
        ...appFonts.mdRegular,
    },
    mobileProgressWrapper: {
        marginBottom: spacing.s3
    },
    progressQuantityWrap: {
        alignItems: 'baseline'
    },
    paginationChevrons: {
        height: spacing.s5,
        width: spacing.s5,
    },
    paginationControlTitle: {
        ...appFonts.mdRegular,
        marginHorizontal: spacing.s1
    },
    streakText: {
        ...appFonts.mdRegular,
        color: baseColors.primary,
        marginLeft: spacing.s4,
        flexShrink: 0
    },
    participantsTextContainer: {
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        color: baseColors.grey40
    },
    participantsText: {
        ...appFonts.mdRegular
    },
    participantsCount: {
        color: baseColors.grey20
    },
    separator: {
        height: 1,
        marginTop: spacing.s2,
        marginLeft: spacing.s3,
        marginRight: spacing.s3,
        backgroundColor: baseColors.grey70,
    },
    dailyAverageTextContainer: {
        paddingTop: spacing.s0
    },
    dailyAverageText: {
        ...appFonts.mdRegular
    },
    grey40: {
        color: baseColors.grey40
    },
    bottomSeparator: {
        height: 1,
        marginLeft: spacing.s3,
        marginRight: spacing.s3,
        backgroundColor: baseColors.grey70,
        position: 'relative',
        bottom: LINE_BOTTOM_OFFSET
    }
};
