import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import * as actions from '../../actions';
import { baseColors, spacing, appFonts } from '../../../../styles';
import { pointsHelper, translate, selectors as coreSelectors, DATE_FORMATS, numbers } from '../../../core';
import { getEligableActivities, getActivitiesToDisplay, getPeriodSingleTrackedActivityValue, getCategoryColor, getIsTeamChallenge,
    getCompetitionTeamScore, getCompetitionScore, getChallenge, getIsCompletedChallenge, getIsAfterDeadline, getIsPersonalChallenge, isRestartGoal } from '../../selectors';
import {
    getSingleTrackedActivityValueString,
    isChallengeUpdating,
    getPeriodTotalPoints,
    getTodayProgress,
    hasMultipleActivitiesToTrack,
    getCustomPointsName
} from '../../services/helper';
import { FREQUENCY, CHALLENGE_TYPES, UNIT_POINTS } from '../../constants';

export default function WithGoalProgressBase(WrappedComponent) {
    class GoalProgressInfoBase extends PureComponent {
        static propTypes = {
            challengeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // eslint-disable-line
            challenge: PropTypes.object.isRequired,
            period: PropTypes.object.isRequired,
            competitionScore: PropTypes.string.isRequired,
            i18n: PropTypes.object.isRequired,
            eligableActivities: PropTypes.object.isRequired,
            displayActivities: PropTypes.object.isRequired,
            periodTrackedActivityValue: PropTypes.object.isRequired,
            categoryColor: PropTypes.string.isRequired,
            progressType: PropTypes.string,
            isTeamChallenge: PropTypes.bool.isRequired,
            challengeProgress: PropTypes.object,
            isCompleted: PropTypes.bool.isRequired,
            isSolo: PropTypes.bool.isRequired,
            isUpdating: PropTypes.bool,
            customPointsUnit: PropTypes.string.isRequired,
            isMemberPeriod: PropTypes.bool,
            isRestartGoal: PropTypes.bool.isRequired,
            todayProgress: PropTypes.object.isRequired
        };

        static defaultProps = {
            progressType: CHALLENGE_TYPES.goal,
            isUpdating: false,
            challengeProgress: null,
            isMemberPeriod: false
        };

        getChallengeDurationString = () => {
            const { challenge } = this.props;
            return `${this.formatDate(challenge.startDate)} - ${this.formatDate(challenge.challengeDeadline)}`;
        };

        formatDate = dateString => moment(dateString).format(DATE_FORMATS.monthDay);

        getHoursLeft = () => moment(moment().endOf('day')).diff(moment(), 'hours');

        getItemTimeLeft = item => item.frequency === FREQUENCY.daily ? this.getHoursLeft(item) : item.daysLeft;

        getItemOverallDaysLeft = item => item.daysLeft;

        getItemPoints = item => item.currentPeriodValue === 'NaN' ? 0 : item.currentPeriodValue;

        getItemTotalPoints = item => pointsHelper.formatPoints(item.overallPointsTargetValue, this.props.customPointsUnit);

        getPointsUnit = () => this.props.customPointsUnit;

        getGoalTrackedActivityString = () => {
            const { challenge, period } = this.props;
            return period.isOverallProgress && challenge.frequency !== 'total' ? this.getChallengeDurationString()
                : getSingleTrackedActivityValueString(challenge);
        };

        getGoalPointsString = () => {
            const { challenge, period } = this.props;
            if (this.props.isTeamChallenge) {
                return `${_.get(period, 'percentFilled', 0)}%`;
            }

            return period.isOverallProgress && challenge.frequency !== 'total' ? this.getChallengeDurationString() :
                `${numbers.numberWithCommas(this.getItemPoints(period) || 0)}/${numbers.numberWithCommas(this.getItemTotalPoints(period) || 0)}`;
        };

        getCompetitionPointsString = () => {
            const { isTeamChallenge, competitionScore } = this.props;
            if (isTeamChallenge) {
                return `${competitionScore}`;
            }
            const score = competitionScore.replace(/\D/g, '');
            return numbers.numberWithCommas(score);
        };

        getCompetitionTimeString = () => {
            const { period } = this.props;
            if (!_.get(period, 'frequency') && this.props.challenge.challengeType === CHALLENGE_TYPES.competition && this.props.challenge.isEndingToday) {
                if (this.getHoursLeft() === 0) {
                    return this.props.i18n.t('lessThan1HourRemain');
                }
                return `${this.getHoursLeft()} ${this.props.i18n.t('hours')}`;
            }

            if (this.getItemTimeLeft(period) === 0) {
                return (_.get(period, 'frequency') === FREQUENCY.daily) ? this.props.i18n.t('lessThan1HourRemain') : this.props.i18n.t('lessThan1DayRemain');
            }
            return `${this.getItemTimeLeft(period)} ${_.get(period, 'frequency') === FREQUENCY.daily ? this.props.i18n.t('hours') : this.daysString(period)}`;
        };

        get isOverallProgress() {
            const { i18n, period } = this.props;
            return period.title === i18n.t('my_overall_progress');
        }

        get periodDurationString() {
            const { challenge } = this.props;
            const periodStartDate = this.formatDate(_.get(challenge, this.isOverallProgress ? 'startDate' : 'progress.currentPeriodStartDate'));
            const periodEndDate = this.formatDate(_.get(challenge, this.isOverallProgress ? 'challengeDeadline' : 'progress.currentPeriodEndDate'));
            return (periodStartDate !== periodEndDate) || this.isOverallProgress ? `${periodStartDate} - ${periodEndDate}` : '';
        }

        daysString = period => {
            if (period.frequency !== FREQUENCY.daily && this.getItemTimeLeft(period) === 1) {
                return _.toLower(this.props.i18n.t('day'));
            }

            return _.toLower(this.props.i18n.t('days'));
        };

        get pointsRowTitle() {
            const { challenge: { frequency, challengeType, activityUnitDetails, progress, userActivityLogs }, isTeamChallenge, customPointsName } = this.props;
            const isToday = moment().format(DATE_FORMATS.full);
            const todayActivitiesLogsArr = _.filter(userActivityLogs, activityLog => activityLog.date === isToday);
            // eslint-disable-next-line no-unused-vars
            let todayTotalPoints = 0;
            _.forEach(todayActivitiesLogsArr, activity => {
                todayTotalPoints += activity.points;
            });
            const periodActivitiesLogsArr = _.filter(userActivityLogs, activityLog => moment(activityLog.date).isBetween(progress.currentPeriodStartDate, progress.currentPeriodEndDate, 'days', '[]'));
            let periodTotalPoints = 0;
            _.forEach(periodActivitiesLogsArr, activity => {
                periodTotalPoints += activity.points;
            });
            const formatedCustomPointsName = getCustomPointsName(customPointsName);
            if (isTeamChallenge && frequency === FREQUENCY.total && challengeType === CHALLENGE_TYPES.goal && activityUnitDetails.length > 1) {
                return {
                    trackedValue: periodTotalPoints,
                    periodValue: progress.overallPointsTargetValue,
                    unit: formatedCustomPointsName
                };
            }
            if (activityUnitDetails.length > 1 || activityUnitDetails[0].unit === UNIT_POINTS) {
                return {
                    trackedValue: periodTotalPoints,
                    periodValue: progress.overallPointsTargetValue,
                    unit: formatedCustomPointsName
                };
            }
            return this.getGoalTrackedActivityString();
        }

        get competitionScoreTitle() {
            const { isTeamChallenge, i18n } = this.props;
            return isTeamChallenge ? i18n.t('teamScore') : i18n.t('wellbeing.table.score');
        }

        get getGoalMemberTitle() {
            const { isTeamChallenge, i18n } = this.props;
            return i18n.t(isTeamChallenge ? 'team_success' : 'yourProgress');
        }

        get userTotalCompletedPeriods() {
            const { challenge } = this.props;
            return _.get(challenge, 'userTotalCompletedPeriods');
        }

        get getTotalPeriods() {
            const { challenge: { frequency, totalPeriods }, i18n } = this.props;
            switch (true) {
                case frequency === FREQUENCY.monthly && totalPeriods ===1:
                    return `${totalPeriods} ${i18n.t('month')}`;
                case frequency === FREQUENCY.monthly && totalPeriods > 1:
                    return `${totalPeriods} ${i18n.t('months')}`;
                case frequency === FREQUENCY.weekly && totalPeriods === 1:
                    return `${totalPeriods} ${i18n.t('week')}`;
                case frequency === FREQUENCY.weekly && totalPeriods > 1:
                    return `${totalPeriods} ${i18n.t('weeks')}`;
                case frequency === FREQUENCY.daily && totalPeriods === 1:
                    return `${totalPeriods} ${i18n.t('day')}`;
                case frequency === FREQUENCY.daily && totalPeriods > 1:
                    return `${totalPeriods} ${i18n.t('days')}`;
                default:
                    return null;

            }
        }

        get progressHeading() {
            const { period, challenge: { frequency }, isTeamChallenge, i18n } = this.props;
            if ((frequency === FREQUENCY.total || frequency === FREQUENCY.overall) && isTeamChallenge) {
                return i18n.t('yourProgress');
            } else if (frequency === FREQUENCY.total) {
                return period.title;
            } else {
                return period.progressCircleHeading;
            }
        }

        get isTotalTeamChallenge() {
            const { isTeamChallenge, challenge } = this.props;
            return challenge.frequency === FREQUENCY.total && isTeamChallenge;
        }

        get averageTitle() {
            const { i18n } = this.props;
            return _.toLower(i18n.t('average'));
        }

        get pointsPeriodValue() {
            const { isMemberPeriod } = this.props;
            return !isMemberPeriod ? `/ ${numbers.numberWithCommas(this.pointsRowTitle.periodValue || 0)} ${this.pointsRowTitle.unit}` : `/ ${numbers.numberWithCommas(this.getTotalPeriods || 0)}`;
        }

        get pointsRowTitleText() {
            const { challenge, isMemberPeriod } = this.props;
            const periodTotalPoints = hasMultipleActivitiesToTrack(challenge)
                ? getPeriodTotalPoints(challenge)
                : this.singleActivityValue;

            return numbers.numberWithCommas(!isMemberPeriod ? (periodTotalPoints || 0) : (this.userTotalCompletedPeriods || 0));
        }

        get singleActivityValue() {
            const { challenge, todayProgress, isRestartGoal } = this.props;
            return !isRestartGoal || challenge.frequency === FREQUENCY.weekly
                ? getSingleTrackedActivityValueString(challenge).trackedValue
                : Math.round(todayProgress.trackedValue * 10) / 10;
        }

        get goalPeriodTitle() {
            const { isMemberPeriod } = this.props;
            return !isMemberPeriod ? this.progressHeading : this.getGoalMemberTitle;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    getGoalPointsString={this.getGoalPointsString}
                    getCompetitionPointsString={this.getCompetitionPointsString}
                    periodDurationString={this.periodDurationString}
                    pointsRowTitle={this.pointsRowTitle}
                    competitionScoreTitle={this.competitionScoreTitle}
                    getPointsUnit={this.getPointsUnit}
                    isTotalTeamChallenge={this.isTotalTeamChallenge}
                    averageTitle={this.averageTitle}
                    pointsPeriodValue={this.pointsPeriodValue}
                    pointsRowTitleText={this.pointsRowTitleText}
                    goalPeriodTitle={this.goalPeriodTitle}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const isTeamChallenge = getIsTeamChallenge(state, ownProps.challengeId);
        const competitionScore = isTeamChallenge ? getCompetitionTeamScore(state, ownProps.challengeId) : getCompetitionScore(state, ownProps.challengeId);
        const challenge = getChallenge(state, ownProps.challengeId);
        const customPointsName = coreSelectors.getCustomPointsUnit(state);
        return {
            challenge,
            eligableActivities: getEligableActivities(state, ownProps.challengeId),
            displayActivities: getActivitiesToDisplay(state, ownProps.challengeId),
            periodTrackedActivityValue: getPeriodSingleTrackedActivityValue(state, ownProps.challengeId),
            categoryColor: getCategoryColor(state, ownProps.challengeId),
            isTeamChallenge,
            competitionScore,
            isCompleted: getIsCompletedChallenge(state, ownProps.challengeId),
            isAfterDeadline: getIsAfterDeadline(state, ownProps.challengeId),
            isSolo: getIsPersonalChallenge(state, ownProps.challengeId),
            isUpdating: isChallengeUpdating(challenge),
            customPointsUnit: coreSelectors.getCustomPointsUnit(state),
            customPointsName,
            isRestartGoal: isRestartGoal(state, ownProps.challengeId),
            todayProgress: getTodayProgress(challenge, customPointsName)
        };
    };

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(GoalProgressInfoBase));
}

const ARROW_ICON_HEIGHT = 14;
const ARROW_ICON_WIDTH = 16;
const LETTER_SPACING = 0.5;

export const styles = {
    goalPeriodRowTitleText: {
        ...appFonts.xsRegular,
        textTransform: 'uppercase',
        color: baseColors.grey40,
        paddingTop: spacing.s0,
        letterSpacing: LETTER_SPACING,
        marginBottom: spacing.s0
    },
    competitionPeriodRowTitleText: {
        ...appFonts.smMedium,
        color: baseColors.black
    },
    pointsRowTitleText: {
        ...appFonts.lineXlRegular,
        color: baseColors.black
    },
    flexRowValueText: {
        ...appFonts.xsRegular,
        color: baseColors.grey40,
        paddingTop: spacing.s0
    },
    goalRemainingText: {
        ...appFonts.xxsMedium,
        color: baseColors.grey40,
    },
    seeMoreBtnText: {
        ...appFonts.xxsRegular,
        color: baseColors.secondary,
        paddingTop: spacing.s0
    },
    defaultRemainingInfo: {
        marginBottom: spacing.s2
    },
    categoryText: {
        ...appFonts.xxsMedium,
        color: baseColors.grey40,
        paddingTop: spacing.s0
    },
    periodDuration: {
        ...appFonts.smRegular,
        color: baseColors.grey40,
    },
    competitionScoreTitle: {
        ...appFonts.xsRegular,
        textAlign: 'center',
        maxWidth: '100%',
        textTransform: 'uppercase',
        color: baseColors.grey40,
        paddingTop: spacing.s0,
        paddingBottom: spacing.s0,
        letterSpacing: LETTER_SPACING,
        marginBottom: spacing.s0
    },
    pointsUnit: {
        ...appFonts.smRegular,
        paddingBottom: spacing.s0,
        marginLeft: spacing.s0
    },
    pointsPeriodValue: {
        ...appFonts.smRegular,
        marginLeft: spacing.s0,
        textTransform: 'lowercase'
    },
    arrowIcon: {
        height: ARROW_ICON_HEIGHT,
        width: ARROW_ICON_WIDTH,
        borderRadius: 0
    },
    statsMR: {
        marginRight: spacing.s3
    },
    statsWrap: {
        alignItems: 'baseline'
    }
};
