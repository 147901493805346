import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import { baseColors, spacing } from '../../../../styles/commonCSS';
import { ActionSheet, components as Core, Modal, AsyncComponent } from '../../../core';
import WithShareNewsBase, { styles as baseStyles } from './ShareNewsBase';
import { importantClass } from '../../../../styles';

class ShareNews extends PureComponent {
    static propTypes = {
        user: PropTypes.object.isRequired,
        className: PropTypes.string,
        bodyClassName: PropTypes.string,
        includeActivityTrack: PropTypes.bool,
        i18n: PropTypes.object.isRequired,
        getUpdateOptions: PropTypes.func.isRequired,
        shareDayText: PropTypes.string.isRequired,
        optionsTitle: PropTypes.string.isRequired,
        postParams: PropTypes.object,
        hideTitle: PropTypes.bool,
        hideEditIcon: PropTypes.bool,
        hasTopOffset: PropTypes.bool
    };

    static defaultProps = {
        postParams: {},
        includeActivityTrack: false,
        hideTitle: true,
        hideEditIcon: true,
        className: undefined,
        bodyClassName: undefined,
        hasTopOffset: true
    };

    shareOrTrack = () => {
        const { includeActivityTrack, getUpdateOptions, optionsTitle } = this.props;
        if (includeActivityTrack) {
            ActionSheet.open(getUpdateOptions(this.shareYourDay, this.trackActivity), undefined, optionsTitle);
        } else {
            this.shareYourDay();
        }
    };

    shareYourDay = () => {
        const { title } = this.props;
        Modal.open(
            AsyncComponent(() => import('../FeedPostEditor')),
            { showVisibilityControl: true, postParams: this.props.postParams, title },
            { isContainer: true, fadeTransition: true, isNoPadding: true, cancelable: false }
        );
    };

    trackActivity = () => {
        this.close = Modal.open(
            AsyncComponent(() => import('../../../challenges/components/ActivityTrackingCalendar')),
            { customizePost: true },
            { isContainer: true, isFullHeight: true, isNoPaddingTop: true, fadeTransition: true }
        );
    };

    render() {
        const { i18n, user, includeActivityTrack, className, bodyClassName, shareDayText, hideTitle, hideEditIcon, hasTopOffset } = this.props;
        return (
            <Core.Card className={classnames(css(styles.cardContainer), hasTopOffset && css(styles.topOffset))} onClick={this.shareOrTrack}>
                <Core.UserAvatarHeader
                    className={classnames(css(styles.userPanelContainer), className)}
                    bodyClassName={bodyClassName}
                    title={i18n.t('greeting', { firstNameDisplay: user.firstNameDisplay })}
                    hideTitle={hideTitle}
                    subtitle={shareDayText}
                    isAvatarEnabled={false}
                    avatarClassName={css(styles.avatar)}
                    feedLabelClassName={css(styles.feedLabel)}
                    action={(
                        <div className={css(styles.iconSet)}>
                            {!hideEditIcon && (
                                <Core.Icon
                                    type="fa"
                                    name="edit"
                                    className={css(styles.shareDayIcon)}
                                    color="secondary"
                                />
                            )}
                            {includeActivityTrack && (
                                <Core.Icon
                                    type="fa"
                                    name="walking"
                                    size={spacing.s7}
                                    className={css(styles.shareDayIcon)}
                                    color="secondary"
                                />
                            )}
                        </div>
                    )}
                />
            </Core.Card>
        );
    }
}

export default withRouter(WithShareNewsBase(ShareNews));

const styles = StyleSheet.create({
    ...baseStyles,
    iconSet: {
        ...baseStyles.iconSet,
        display: 'flex'
    },
    cardContainer: {
        margin: spacing.s1,
        marginTop: 1,
        cursor: 'pointer'
    },
    topOffset: importantClass({
        marginTop: spacing.s3,
        marginBottom: spacing.s3
    }),
    userPanelContainer: importantClass({
        marginTop: spacing.s4,
        marginBottom: spacing.s4,
        marginLeft: spacing.s3,
        marginRight: spacing.s3,
    }),
    avatar: importantClass({
        marginRight: spacing.s1
    }),
    feedLabel: importantClass({
        paddingLeft: spacing.s5,
        borderRadius: spacing.s19,
        backgroundColor: baseColors.grey85,
        minHeight: spacing.s9,
        display: 'flex',
        alignItems: 'center'
    })
});
