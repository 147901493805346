import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import _ from 'lodash';
import { translate, selectors as coreSelectors } from '../../../../core';
import { selectors as onboardingSelectors } from '../../../../onboarding';
import { selectors as authSelctors } from '../../../../auth';
import { appFonts, fontSize, baseColors, spacing } from '../../../../../styles';
import { HEALTH_SCORE_SLUGS } from '../../../constants';
import { getSurveyResults, getImproveScoreDescription } from '../../../services/profileEditorHelper';
import { getScore, getScoreDescription, getStatus, updatedPercentage, showStatus, getHealthSurveyStatebySlug, getUpdatedFromNowTime } from '../../../selectors';

export const ARROW_ICON_SIZE = spacing.s3;
export const HELP_ICON_SIZE = spacing.s5;
export const SURVEY_ICON_SIZE = spacing.s3;

export default function WithWellbeingOverallProgressBase(WrappedComponent) {
    class WellbeingOverallProgressBase extends PureComponent {
        static propTypes = {
            healthSurveyHra: PropTypes.array.isRequired,
            progress: PropTypes.object.isRequired,
            i18n: PropTypes.object.isRequired,
            className: PropTypes.string,
            language: PropTypes.string,
            score: PropTypes.string,
            status: PropTypes.string,
            overallState: PropTypes.string.isRequired,
            moveState: PropTypes.string.isRequired,
        };

        static defaultProps = {
            className: null,
            language: 'en-CA',
            score: null,
            status: null
        };

        get howScoreCalculatedTitle() {
            return this.props.i18n.t('how_score_calculated');
        }

        get howScoreCalculatedDescription() {
            const { i18n, programName } = this.props;
            return i18n.t('wellbeing.calculateScore.description', { programName });
        }

        get improveScoreTitle() {
            return this.props.i18n.t('wellbeing.improveYourScore');
        }

        get improveScoreDescription() {
            const { overallState, moveState } = this.props;
            return getImproveScoreDescription(moveState, overallState);
        }

        get lastUpdatedText() {
            const { i18n, updatedFromNowTime } = this.props;
            return i18n.t('wellbeing.lastUpdated', { date: updatedFromNowTime });
        }

        get surveyResults() {
            const { overallState, moveState } = this.props;
            return getSurveyResults(overallState, moveState);
        }

        getHealthSurveyModalProps = () => ({
            iconName: 'question-circle',
            title: this.props.i18n.t('wellbeing.meaning.heading'),
            text: this.props.i18n.t('wellbeing.description.overall'),
            isButtonVisible: false,
            buttonTitle: this.props.i18n.t('got_it')
        });

        isDetailExpanded = detail => this.state.expandedDetail === detail;

        get isFrenchLang() {
            const language = _.split(this.props.i18n.language, '-', 2);
            return language[0] === 'fr';
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    surveyResults={this.surveyResults}
                    lastUpdatedText={this.lastUpdatedText}
                    howScoreCalculatedTitle={this.howScoreCalculatedTitle}
                    howScoreCalculatedDescription={this.howScoreCalculatedDescription}
                    improveScoreTitle={this.improveScoreTitle}
                    improveScoreDescription={this.improveScoreDescription}
                    getHealthSurveyModalProps={this.getHealthSurveyModalProps}
                    isFrenchLang={this.isFrenchLang}
                />
            );
        }
    }

    const mapStateToProps = state => ({
        healthSurveyHra: onboardingSelectors.getHealthSurveyStatusHra(state),
        progress: onboardingSelectors.getCurrentHealthSurveyResultBySlug(state, HEALTH_SCORE_SLUGS.overall),
        language: coreSelectors.getCurrentUserLanguage(state),
        score: getScore(state),
        scoreDescription: getScoreDescription(state),
        updatedFromNowTime: getUpdatedFromNowTime(state),
        status: getStatus(state),
        updatedPercentage: updatedPercentage(state),
        showStatus: showStatus(state),
        overallState: getHealthSurveyStatebySlug(state, HEALTH_SCORE_SLUGS.overall),
        moveState: getHealthSurveyStatebySlug(state, HEALTH_SCORE_SLUGS.move),
        programName: authSelctors.getProgramName(state)
    });

    return connect(mapStateToProps)(translate()(WellbeingOverallProgressBase));
}

const ARROW_ICON_MARGIN = 2;

export const styles = {
    mainContainer: {
        marginTop: spacing.s3,
        paddingBottom: spacing.s4
    },
    text: {
        ...appFonts.mdRegular,
        textAlign: 'center'
    },
    description: {
        ...appFonts.mdRegular,
        marginTop: spacing.s5,
        color: baseColors.grey20,
    },
    howScoreButton: {
        ...appFonts.mdMedium,
    },
    updatedDate: {
        marginTop: spacing.s1,
        ...appFonts.smRegular,
        color: baseColors.grey40,
    },
    mdBold: {
        ...appFonts.mdBold
    },
    scoreMeaning: {
        ...appFonts.mdMedium,
        color: baseColors.secondary,
        textAlign: 'center',
        marginTop: spacing.s2,
    },
    wellbeingMeaningModalText: {
        lineHeight: fontSize.xLarge.lineHeight
    },
    indicatorContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        wordBreak: 'break-word',
        textAlign: 'center'
    },
    indicatorScore: {
        marginTop: spacing.s1,
        ...appFonts.xxxxlRegular,
        fontSize: spacing.s9,
        lineHeight: spacing.s11,
        color: baseColors.black,
    },
    indicatorDescriptionContainer: {
        flexDirection: 'column',
        alignItems: 'center'
    },
    indicatorScoreDescription: {
        ...appFonts.lgMedium,
    },
    indicatorProgress: {
        position: 'absolute',
        bottom: 0,
        flexDirection: 'row',
        alignItems: 'center'
    },
    indicatorDelta: {
        marginLeft: ARROW_ICON_MARGIN,
        marginBottom: ARROW_ICON_MARGIN,
        ...appFonts.lgMedium
    },
    questionCircleIcon: {
        marginTop: spacing.s3,
    },
    decorativeLine: {
        height: 1,
        width: spacing.s3,
        backgroundColor: baseColors.grey70,
    },
    descriptionContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginRight: spacing.s3,
        marginLeft: spacing.s3,
    },
    surveyResultsItem: {
        marginBottom: spacing.s1,
        alignItems: 'center'
    },
    surveyResultsText: {
        ...appFonts.lgRegular,
        color: baseColors.grey20,
    },
    surveyIcon: {
        marginTop: spacing.s0,
        marginRight: spacing.s1,
    },
    dropdownRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    dropdownsSection: {
        paddingTop: spacing.s5,
    },
    expdandedText: {
        ...appFonts.mdRegular,
        color: baseColors.grey20
    },
    dropdownTitle: {
        ...appFonts.mdMedium,
        color: baseColors.grey20,
        marginRight: spacing.s2,
    },
    largeFont: {
        fontSize: fontSize.xLarge - 2
    }
};
