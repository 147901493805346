import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { StyleSheet, css } from 'aphrodite-jss';
import classnames from 'classnames';
import { components as Core } from '../../../core';
import WithStreamFilterBase, { styles as baseStyles } from './StreamFilterBase';
import { spacing, baseColors, importantStyles } from '../../../../styles';
import { components as Feeds, constants as feedsConstants } from '../../index';
import { filtersByTimeAddedOptions } from '../../../shared/constants';

class StreamFilter extends PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        appliedFilter: PropTypes.object.isRequired,
        filterText: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        filters: PropTypes.array.isRequired,
        isLoading: PropTypes.bool,
        children: PropTypes.node.isRequired,
        hasShareNewsComponent: PropTypes.bool,
        containerClassName: PropTypes.string
    };

    static defaultProps = {
        isLoading: false,
        hasShareNewsComponent: true,
        containerClassName: null
    };

    get component() {
        const { i18n, filterText, isLoading } = this.props;
        return (
            <Fragment>
                <div className={css(styles.innerContainer)}>
                    <span className={css(styles.shareDayText)}>
                        {i18n.t('feeds.viewingPostsFrom')}
                    </span>
                    <span className={css(styles.filterText)}>
                        {filterText}
                    </span>
                </div>
                {isLoading ?
                    <Core.ListLoading className={css(styles.loading)} size={spacing.s4} /> :
                    <Core.Icon
                        type="fa"
                        name="filter"
                        size={spacing.s3}
                        color={baseColors.secondary}
                        className={css(styles.iconContainer)}
                    />
                }
            </Fragment>
        );
    }

    get activeTab() {
        const { appliedFilter, filters } = this.props;
        return _.find(filters, item => appliedFilter.filterId === item.filterId);
    }

    get renderFilter() {
        const { filters, onChangeFirstFilter, onChangeSecondFilter, appliedFilterSecondary, containerClassName, i18n } = this.props;
        return (
            <div className={classnames(css(styles.viewPostsLabelContainer), containerClassName)}>
                <Core.SelectDropdown
                    tabs={filters}
                    onChange={onChangeFirstFilter}
                    activeTab={this.activeTab}
                />
                <Core.SelectDropdown
                    tabs={filtersByTimeAddedOptions}
                    onChange={onChangeSecondFilter}
                    activeTab={appliedFilterSecondary}
                    i18n={i18n}
                />
            </div>
        );
    }

    render() {
        const { children, isLoading, hasShareNewsComponent } = this.props;

        return (
            <div className={css(styles.container)}>
                {hasShareNewsComponent ?
                    <div className={css(styles.streamHeader)}>
                        <Feeds.ShareNews
                            hideTitle={true}
                            hideEditIcon={true}
                            hasTopOffset={false}
                            postParams={{
                                postToEntityType: feedsConstants.STREAM_ENTITY_TYPES.feed
                            }}
                        />
                    </div>
                    : null}
                {this.renderFilter}
                {isLoading ?
                    <div className={css(styles.loadingContainer)}>
                        <Core.ListLoading className={css(styles.loading)} size={spacing.s5} />
                    </div>
                    :
                    null
                }
                {children}
            </div>
        );
    }
}

export default WithStreamFilterBase(StreamFilter);

const styles = StyleSheet.create({
    ...baseStyles,
    ...importantStyles({
        loading: { padding: 0 },
        userPanel: {
            ...baseStyles.userPanel,
            display: 'flex'
        },
    }),
    viewPostsLabelContainer: {
        marginLeft: spacing.s1,
        paddingTop: spacing.s0,
        paddingBottom: spacing.s1,
        display: 'flex'
    },
    loadingContainer: {
        margin: spacing.s3
    },
    streamHeader: {
        marginBottom: spacing.s2,
        overflow: 'hidden'
    },
    container: {
        '-ms-grid-column': 3,
        minWidth: 0,
    },
});
