import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import * as actions from '../../actions';
import { spacing, baseColors } from '../../../../styles';
import { translate } from '../../../core';
import * as selectors from '../../selectors';
import { performAction } from '../../services';

export default function WithNotificationsBase(WrappedComponent) {
    class NotificationsBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            i18n: PropTypes.object.isRequired,
            notifications: PropTypes.array,
            refreshTime: PropTypes.number,
            unreadCount: PropTypes.number,
            getUnreadNotificationsLength: PropTypes.number,
            isLoading: PropTypes.bool,
            isLoadingMore: PropTypes.bool,
            isMarking: PropTypes.bool,
            isMarkingAll: PropTypes.bool,
            hasHiddenRegular: PropTypes.bool,
        };

        static defaultProps = {
            notifications: [],
            refreshTime: null,
            unreadCount: 0,
            getUnreadNotificationsLength: 0,
            isLoading: false,
            isLoadingMore: false,
            isMarking: false,
            hasHiddenRegular: false,
            isMarkingAll: false
        };

        constructor(props) {
            super(props);
            this.state = { hasMore: true };
            this.loadData();
        }

        get _loadedUnreadCount() {
            return this.props.getUnreadNotificationsLength;
        }

        loadData = () => {
            this.props.actions.getAllNotifications();
            this.props.actions.getUnreadCount();
        };

        loadMoreContent = () => {
            // Fetch more data here.
            const { notifications, unreadCount } = this.props;
            if ((!this.props.isLoadingMore || this.state.hasMore) && this._loadedUnreadCount < unreadCount && notifications.length > 0) {
                this.props.actions.getMoreNotifications(notifications[notifications.length - 1].notificationId);
            }
            else {
                this.setState({ hasMore: false });
            }
        };

        markAllAsRead = () => {
            this.props.actions.markAllNotificationsAsRead();
        };

        markAsClicked = ({ notificationId: id, clicked }) => {
            if (!clicked) this.props.actions.markNotificationAsClicked(id);
        };

        markAllAsClicked = () => {
            const { actions } = this.props;
            actions.markAllNotificationsAsClicked();
        }

        /*
        *  Section list will render section even if array of data for this section is empty
        *  Func is checking if the child array of data has something to render if not do not show this section
        * */
        get sectionsData() {
            const { hasHiddenRegular, i18n, notifications } = this.props;
            const sectionForRender = [
                {
                    title: i18n.t('notification.sectionTitle.all'),
                    data: hasHiddenRegular ? [] : (notifications || [])
                }
            ];

            return _.filter(sectionForRender, arr => arr.data.length);
        }

        performAction = args => performAction(args);

        get title() {
            return this.props.i18n.t('notifications');
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    markAsClicked={this.markAsClicked}
                    markAllAsRead={this.markAllAsRead}
                    markAllAsClicked={this.markAllAsClicked}
                    loadMoreContent={this.loadMoreContent}
                    loadData={this.loadData}
                    notifications={this.props.notifications}
                    unreadCount={this.props.unreadCount}
                    isLoading={this.props.isLoading}
                    hasMore={this.state.hasMore}
                    performAction={this.performAction}
                    sectionsData={this.sectionsData}
                    title={this.title}
                />
            );
        }
    }

    function mapStateToProps(state) {
        return {
            notifications: selectors.getAllNotifications(state),
            getUnreadNotificationsLength: selectors.getUnreadNotificationsLength(state),
            getRefreshTime: selectors.getRefreshTime(state),
            unreadCount: selectors.getUnreadCount(state),
            unseenCount: selectors.getUnseenCount(state),
            unreadPriorityCount: selectors.getUnreadPriorityCount(state),
            isLoading: selectors.isLoadingNotifications(state),
            isLoadingMore: selectors.isLoadingMoreNotifications(state),
            isMarkingAll: selectors.isMarkingAllClicked(state),
            isMarking: selectors.isMarkingRead(state)
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(NotificationsBase));
}

export const styles = {
    container: {
        flex: 1,
        paddingLeft: spacing.s0,
        backgroundColor: baseColors.white,
        paddingRight: 0
    },
};
