import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as actions from '../../actions';
import { translate } from '../../../core';
import { windowSize, spacing, baseColors, appFonts } from '../../../../styles';
import { tourStyles } from '../../styles';
import { DEVICES_DASHBOARD_STATUS } from '../../../appsdevices/constants';

export const APPS_ICON_SIZE = spacing.s9;

export default function WithDeviceToolTipBase(WrappedComponent) {
    class DeviceToolTipBase extends PureComponent {
        static propTypes = {
            position: PropTypes.shape({
                width: PropTypes.number,
                height: PropTypes.number,
                pageX: PropTypes.number,
                pageY: PropTypes.number
            }).isRequired,
            type: PropTypes.object
        };

        static defaultProps = {
            type: DEVICES_DASHBOARD_STATUS.NONE
        };

        defaultPosition = () => ({
            width: windowSize.width,
            height: 0,
            pageX: 0,
            pageY: windowSize.height/2
        })

        //if reference is invalid we set position on center of the screen
        get position() {
            return this.props.position || this.defaultPosition();
        }

        get backgroundColor() {
            const { type } = this.props;
            if (type === DEVICES_DASHBOARD_STATUS.ERROR) {
                return baseColors.danger;
            } else if (type === DEVICES_DASHBOARD_STATUS.WARNING) {
                return baseColors.warn;
            }

            return baseColors.white;
        }

        get titleColor() {
            if (this.props.type === DEVICES_DASHBOARD_STATUS.NONE) {
                return baseColors.black;
            }

            return baseColors.white;
        }

        get actionColor() {
            if (this.props.type === DEVICES_DASHBOARD_STATUS.NONE) {
                return baseColors.secondary;
            }

            return baseColors.white;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    position={this.position}
                    backgroundColor={this.backgroundColor}
                    titleColor={this.titleColor}
                    actionColor={this.actionColor}
                />
            );
        }
    }

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(null, mapDispatchToProps)(translate()(DeviceToolTipBase));
}

export const styles = {
    ...tourStyles,
    appsDevicesTitle: {
        ...appFonts.smRegular,
        flex: 1,
        flexWrap: 'wrap'
    },
    appsDevicesTitleWithIcon: {
        paddingLeft: spacing.s1,
    },
    appsDevicesTitleContainer: {
        paddingRight: spacing.s0
    },
    appsDevicesIcon: {
        marginTop: spacing.s0 / 2
    },
    deviceInnerContainerTop: {
        ...tourStyles.innerContainerTop,
        paddingBottom: spacing.s0
        //
    },
    innerContainerBottom: {
        ...tourStyles.innerContainerBottom,
        paddingTop: 0
    },
    headerContainer: {
        ...tourStyles.headerContainer,
        alignItems: 'flex-start'
    }
};
