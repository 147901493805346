import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { StyleSheet, css } from 'aphrodite-jss';

import { appFonts, spacing, baseColors, importantStyles, layoutStyle } from '../../../../../styles';
import WellbeingIcon from '../WellbeingIcon';
import { components as Challenges } from '../../../../challenges';
import { components as Core, Modal } from '../../../../core';
import WithWellbeingCategoryProgressBase,
{ styles as baseStyles, CATEGORY_ICON_SIZE, CATEGORY_MODAL_ICON_SIZE }
    from './WellbeingCategoryProgressBase';

class WellbeingCategoryProgress extends PureComponent {
    static propTypes = {
        slug: PropTypes.string.isRequired,
        category: PropTypes.object.isRequired,
        i18n: PropTypes.object.isRequired,
        categoryName: PropTypes.string.isRequired,
        score: PropTypes.number.isRequired,
        goalsDescription: PropTypes.string.isRequired,
        isExpanded: PropTypes.func.isRequired,
        itemIconName: PropTypes.string.isRequired,
        scoreDetails: PropTypes.string.isRequired,
        onItemClick: PropTypes.func.isRequired,
        mainColor: PropTypes.string.isRequired,
        averageAgeGenderScore: PropTypes.number.isRequired,
        positiveDeltaAgeGenderScore: PropTypes.number.isRequired,
        getDescriptionModalProps: PropTypes.func.isRequired
    };

    onShowDescriptionModal = () => {
        const descriptionModalProps = this.props.getDescriptionModalProps();

        this.closeModal = Modal.open(
            Core.InfoModal,
            {
                ...descriptionModalProps,
                renderIcon: () => this.renderIcon(CATEGORY_MODAL_ICON_SIZE),
                buttons: [{ text: descriptionModalProps.buttonTitle, onPress: () => this.closeModal() }],
            },
            {
                cancelable: true,
                fadeTransition: true,
                PaperProps: { elevation: 0 },
                isTransparent: true
            }
        );
    };

    renderIcon = size => <WellbeingIcon slug={this.props.slug} size={size} />;

    renderNoScoreItem = () => {
        const { category, categoryName, i18n } = this.props;
        const notAvailableContent = `${i18n.t('wellbeing.yourScoreIsPoor', { category: categoryName })}`;
        return (
            <div>
                <div className={css(styles.attentionText)}>
                    {/* UNCOMMENT TO SHOW WARNING ICON */}
                    {/* <Core.Icon
                        name="exclamation-triangle"
                        type="fa"
                        size={EXCLAMATION_ICON_SIZE}
                        color={baseColors.warn}
                    /> */}
                    <span className={css(styles.noContent)}>
                        {notAvailableContent}
                    </span>
                </div>
                {this.props.score === 0 ?
                    <span className={css(styles.scoreDescription)}>
                        {category.description}
                    </span> : null
                }
            </div>
        );
    };

    renderExpandedItem = () => {
        const {
            categoryName,
            score,
            i18n,
            mainColor,
            positiveDeltaAgeGenderScore,
            scoreDetails,
            averageAgeGenderScore
        } = this.props;
        const scoreExists = `${i18n.t('wellbeing.categoryScore', { category: _.toLower(categoryName) })}`;
        const scoreColor = { color: mainColor };

        return (
            <div className={css(styles.expandedContainerHeader)}>
                {score === 0 ?
                    this.renderNoScoreItem() :
                    <div>
                        <div className={css(styles.scoreText)}>
                            {scoreExists}
                            <span className={css(styles.scoreTextBold)} style={scoreColor}>
                                {score}
                            </span>
                        </div>
                        <div>
                            <span className={css(styles.scoreDescriptionBold)}>
                                {positiveDeltaAgeGenderScore}
                            </span>
                            <span>
                                {scoreDetails}
                            </span>
                            <span className={css(styles.scoreDescriptionBold)}>{averageAgeGenderScore}</span>
                            <span>
                                {` ${i18n.t('wellbeing.scoreDescription.part3')} `}
                            </span>
                            <div className={css(styles.learnMore)}>
                                <Core.Button
                                    type="text"
                                    className={css(styles.scoreLearnMore)}
                                    size="small"
                                    onPress={this.onShowDescriptionModal}>
                                    {i18n.t('wellbeing.learnMore')}
                                </Core.Button>
                            </div>
                        </div>
                    </div>
                }
                <Challenges.RecommendedGoalsCarousel slug={this.props.slug} />
            </div>
        );
    };

    renderCategoryName = () => (
        <div>
            <span className={css(styles.categoryName)}>{this.props.categoryName}</span>
            {/* UNCOMMENT TO SHOW WARNING ICON */}
            {/* {this.props.score === 0 ?
                <Core.Icon
                    name="exclamation-triangle"
                    type="fa"
                    size={EXCLAMATION_ICON_SIZE}
                    color={baseColors.warn}
                /> : null
            } */}
        </div>
    );

    render() {
        const { category, isExpanded, goalsDescription, onItemClick, itemIconName } = this.props;

        return (
            !_.isEmpty(category) ?
                <Core.List disablePadding={true}>
                    <Core.ListItem button={true} onClick={onItemClick} className={css(layoutStyle.listItem)}>
                        <Core.ListItemIcon>{this.renderIcon(CATEGORY_ICON_SIZE)}</Core.ListItemIcon>
                        <Core.ListItemText
                            primary={this.renderCategoryName()}
                            secondary={(
                                <React.Fragment>
                                    <span className={css(styles.goalsText)}>{goalsDescription}</span>
                                </React.Fragment>
                            )}
                        />
                        <Core.Icon
                            type="fa"
                            name={itemIconName}
                            color={baseColors.secondary}
                        />
                    </Core.ListItem>
                    <Core.Collapse in={isExpanded()} unmountOnExit={true}>
                        {this.renderExpandedItem()}
                    </Core.Collapse>
                </Core.List> : null
        );
    }
}


export default WithWellbeingCategoryProgressBase(WellbeingCategoryProgress);

const styles = StyleSheet.create(importantStyles({
    ...baseStyles,
    goalsText: {
        ...appFonts.smMedium,
        paddingLeft: spacing.s0
    },
    learnMore: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    categoryName: {
        ...baseStyles.categoryName,
        paddingRight: spacing.s1
    }
}));
