import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { components as Core } from '../../../core';
import WithReactionItemBase, { styles as baseStyles } from './ReactionItemBase';
import { layoutStyle } from '../../../../styles';

class ReactionItem extends PureComponent {
    static propTypes = {
        user: PropTypes.object.isRequired,
        reactionImage: PropTypes.string,
        locationString: PropTypes.string
    };

    static defaultProps = {
        reactionImage: undefined,
        locationString: undefined
    };

    render() {
        const { user, reactionImage, locationString } = this.props;
        const fullName = `${user.firstNameDisplay} ${user.lastNameDisplay}`;
        return (
            <div className={css(styles.mainReactionContainer)}>
                <div className={css(styles.avatarContainer)}>
                    <Core.Avatar
                        url={user.avatarURL}
                        size="small"
                        name={fullName}
                        disablePress={true}
                    />
                    <div className={css(styles.emojiContainer)}>
                        {reactionImage ? <Core.Image src={reactionImage} className={css(styles.emojiImage)} resizeMode="cover" /> : null}
                    </div>
                </div>
                <div className={css(styles.textSection)}>
                    <p className={css(styles.username, layoutStyle.wordBreak)}>{fullName}</p>
                    <p className={css(styles.location, layoutStyle.wordBreak)}>{locationString}</p>
                </div>
            </div>
        );
    }
}

export default WithReactionItemBase(ReactionItem);

const styles = StyleSheet.create({
    ...baseStyles,
    mainReactionContainer: {
        ...baseStyles.mainReactionContainer,
        flex: 1,
        display: 'flex',
    },
    avatarContainer: {
        ...baseStyles.avatarContainer,
        display: 'flex'
    },
    emojiContainer: {
        ...baseStyles.emojiContainer,
        display: 'flex',
        borderStyle: 'solid',
    },
    textSection: {
        ...baseStyles.textSection,
        display: 'flex'
    }
});
