import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { components as Core, translate } from '../../../../core';

class ProfileEditorContainer extends PureComponent {
    static propTypes = {
        subtitle: PropTypes.string.isRequired,
        isLoading: PropTypes.bool.isRequired,
        isSaving: PropTypes.bool.isRequired,
        onSave: PropTypes.func.isRequired,
        i18n: PropTypes.object.isRequired,
        children: PropTypes.node.isRequired,
        disclaimer: PropTypes.string
    };

    static defaultProps = {
        disclaimer: null
    };

    render() {
        const { isLoading, isSaving, subtitle, children, disclaimer } = this.props;
        return (
            <Core.SmallerContainer
                isFixedFooter={true}
                widerHeaderComponent={(
                    <Core.EntityDetailsHeader
                        hasBackButton={true}
                        title={subtitle}
                    />)
                }>
                <Core.SubHeader
                    title={subtitle}
                    noTopPadding={true}
                />
                {disclaimer ? <div>{disclaimer}</div> : null}
                {children}
                <Core.BlockingLoading isLoading={isLoading || isSaving} />
                <Core.Layout.FixedFooterContainer isBorder={true}>
                    <Core.Button
                        onPress={this.props.onSave}
                        fullWidth={true}>
                        {this.props.i18n.t('save')}
                    </Core.Button>
                </Core.Layout.FixedFooterContainer>
            </Core.SmallerContainer>
        );
    }
}

export default translate()(ProfileEditorContainer);
