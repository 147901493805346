import * as types from './actionTypes';
import * as api from './api';
import { validateError } from '../../config';

export function getUserData(userId) {
    return function (dispatch) {
        dispatch({ type: types.GET_USER_DATA.REQUEST });
        return api.getUserData(userId)
            .then(response => {
                dispatch({
                    type: types.GET_USER_DATA.SUCCESS,
                    payload: response.data
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.GET_USER_DATA.ERROR });
                console.log('getUserData', error);
            }));
    };
}