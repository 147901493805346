import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { StyleSheet, css } from 'aphrodite-jss';

import { baseColors, layoutStyle, spacing } from '../../../../styles';
import Button from '../Button';
import Icon from '../Icon';

// eslint-disable-next-line react/display-name
export const CreateEntityButton = React.memo(({ onPress, text, className, iconProps, ...props }) => (
    <Button
        {...props}
        onPress={onPress}
        type="outlined"
        className={classnames(css(layoutStyle.createEntityButton), className)}>
        <Icon
            name="Add"
            color={baseColors.secondary}
            {...iconProps}
        />
        <span className={css(styles.text)}>{text}</span>
    </Button>
));

CreateEntityButton.propTypes = {
    onPress: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    className: PropTypes.string,
    iconProps: PropTypes.object,
};

CreateEntityButton.defaultProps = {
    className: undefined,
    iconProps: {},
};

export default CreateEntityButton;

const styles = StyleSheet.create({
    text: {
        marginLeft: spacing.s0,
        lineHeight: '1em'
    }
});