import React, { PureComponent } from 'react';
import translate from '../../services/translate';

export default function WithAttendeesListBase(WrappedComponent) {
    class AttendeesListBase extends PureComponent {
        render() {
            return (
                <WrappedComponent {...this.props} />
            );
        }
    }

    return translate()(AttendeesListBase);
}

export const styles = {};
