import { StyleSheet } from 'aphrodite-jss';
import containerStyleBase from '../containerStyle';
import elements from '../elements';
import modalStyleBase from '../modalStyle';


export { default as baseColors, updateColors } from '../commonColor';
export { default as baseIconColors } from '../commonIcons';
export { default as media, isWiderScreen } from '../media';
export { default as theme, themeStyles } from '../theme';
export { default as spacing } from '../spacing';
export { default as appFonts, fontSize } from '../appFonts';
export { default as layoutStyle } from '../layoutStyle';
export { elements };
export * from '../constants';
export const containerStyle = StyleSheet.create(containerStyleBase);
export const modalStyle = StyleSheet.create(modalStyleBase);
