import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { translate } from '../../services';
import { baseColors, spacing } from '../../../../styles';
import ActionSheet from '../ActionSheet';

export const ICON_SIZE = 14;

export default function WithImageUploadGeneralBase(WrappedComponent) {
    class ImageUploadGeneralBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            hasDeleteOption: PropTypes.bool,
            picture: PropTypes.string,
            fileChanged: PropTypes.func.isRequired,
            isDeletedPictureEmpty: PropTypes.bool,
            navigation: PropTypes.object,
        };

        static defaultProps = {
            picture: '',
            hasDeleteOption: true,
            isDeletedPictureEmpty: false,
            navigation: {}
        };

        get deleteTitle() {
            return this.props.i18n.t('deletePhoto');
        }

        deletePhoto = () => this.props.fileChanged(this.props.isDeletedPictureEmpty ? '' : null, null);

        getOptions = (addPhoto, hasTakePhotoMenu) => {
            const options = [];

            if (hasTakePhotoMenu) {
                options.push({
                    title: this.props.i18n.t('takePhoto'),
                    onPress: () => addPhoto(true)
                });
            }

            options.push({
                title: this.props.i18n.t('choosePhoto'),
                onPress: addPhoto
            });

            if (this.props.picture && this.props.hasDeleteOption) {
                options.push({
                    title: this.deleteTitle,
                    onPress: this.deletePhoto
                });
            }

            return options;
        };

        getDestructiveButtonIndex = options => {
            const index = _.findIndex(options, { title: this.deleteTitle });
            return index !== -1 ? index : undefined;
        };

        showPicker = (addPhoto, hasTakePhotoMenu = true) => {
            const options = this.getOptions(addPhoto, hasTakePhotoMenu);
            options.length > 1 ? ActionSheet.open(options, this.getDestructiveButtonIndex(options), undefined, this.props.navigation) : addPhoto();
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    destructiveButtonIndex={this.destructiveButtonIndex}
                    showPicker={this.showPicker}
                />
            );
        }
    }

    return translate()(ImageUploadGeneralBase);
}

export const styles = {
    iconContainer: {
        width: spacing.s5,
        height: spacing.s5,
        backgroundColor: baseColors.secondary,
        borderRadius: spacing.s2,
    },
    profileImage: {
        paddingTop: 0
    }
};
