import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { layoutStyle, spacing } from '../../../../../styles';
import AboutChallenge from '../../AboutChallenge';
import { components as Core } from '../../../../core';
import * as Card from '../../Card';
import ChallengeCardHeaderIcon from '../../ChallengeCard/ChallengeCardHeader/ChallengeCardHeaderIcon';
import WithGoalCardBase, { styles as baseStyles } from './GoalCardBase';
import GoalCardButton from '../GoalCardButton';

class GoalCard extends PureComponent {
    static propTypes = {
        buttonPress: PropTypes.func.isRequired,
        saveRef: PropTypes.func.isRequired,
        challengeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        challenge: PropTypes.object.isRequired,
        headerIcon: PropTypes.object.isRequired,
    };

    get cardHeader() {
        return (
            <Card.Header
                title={this.props.challenge.goalName}
                icon={<ChallengeCardHeaderIcon icon={this.props.headerIcon} />}
            />
        );
    }

    get cardBody() {
        return (
            <React.Fragment>
                <div className={css(layoutStyle.cardPadding)}>
                    <Core.TextSeeMore
                        className={css(styles.cardDarkBodyTop, styles.cardDarkHeadingSub)}
                        numberOfLines={3}
                        isExpandHidden={true}
                        isCollapseHidden={true}>
                        {this.props.challenge.instruction}
                    </Core.TextSeeMore>
                </div>
                <Card.Body isGrey={false}>
                    <AboutChallenge challengeId={this.props.challengeId} isRecommended={true} />
                </Card.Body>
            </React.Fragment>
        );
    }

    get cardFooter() {
        return (
            <Card.Footer isGrey={false} className={css(styles.footerWrapper)}>
                <GoalCardButton
                    onPress={this.props.buttonPress}
                    className={css(layoutStyle.footerButton)}
                    challengeId={this.props.challengeId}
                    fullWidth={true}
                />
            </Card.Footer>
        );
    }

    render() {
        return (
            <div ref={this.props.saveRef}>
                <Card.Container onPress={this.onPress} hasPointer={false}>
                    {this.cardHeader}
                    {this.cardBody}
                    {this.cardFooter}
                </Card.Container>
            </div>
        );
    }
}

export default WithGoalCardBase(GoalCard);

const styles = StyleSheet.create({
    ...baseStyles,
    footerWrapper: {
        marginLeft: spacing.s1,
        marginRight: spacing.s1,
        marginBottom: spacing.s0
    }
});
