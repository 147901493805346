import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import _ from 'lodash';
import { baseColors, layoutStyle, spacing } from '../../../../../styles';
import WithDashboardEarnPointsInfoBase, { styles as baseStyles } from './DashboardEarnPointsInfoBase';
import { components as Core, numbers } from '../../../../core';

class DashboardEarnPointsInfo extends PureComponent {
    static propTypes = {
        buttonTitle: PropTypes.string.isRequired,
        onButtonPress: PropTypes.func.isRequired,
        titleFirstLine: PropTypes.string.isRequired,
        titleSecondLine: PropTypes.string.isRequired,
        isRewardsEnabled: PropTypes.bool.isRequired,
        customPointsUnit: PropTypes.string.isRequired,
        howToLevelUpText: PropTypes.string.isRequired,
        trackActivityText: PropTypes.string.isRequired,
        externalRewardName: PropTypes.string,
        levels: PropTypes.array,
    };

    static defaultProps = {
        levels: [],
        externalRewardName: '',
    };

    renderLevelItem = item => {
        if (!item.reward_id) return null;
        const { customPointsUnit, isRewardsEnabled, externalRewardName } = this.props;
        const levelScore = `${numbers.numberWithCommas(item.score)} ${customPointsUnit}`;
        return (
            <div className={css(layoutStyle.flexRow, styles.levelItem)}>
                <Core.Image src={item.iconUrl} className={css(styles.levelIcon)} resizeMode="contain" />
                <div className={css(styles.levelNameContainer)}>
                    <p className={css(styles.levelName)}>{item.name}</p>
                    {isRewardsEnabled && <p className={css(styles.levelScore)}>{levelScore}</p>}
                </div>
                <div className={css(styles.levelRightContainer)}>
                    {isRewardsEnabled
                        ? (
                            <div className={css(styles.rewardContainer, layoutStyle.flexColumn)}>
                                <div className={css(layoutStyle.flexRowCenter)}>
                                    <Core.Icon
                                        type="light"
                                        name="coins"
                                        size={spacing.s3}
                                        color={baseColors.warn}
                                        className={css(styles.coinsIcon)}
                                    />
                                    <span className={css(styles.rewardAmount)}>+{item.externalRewardPoints || 0}</span>
                                </div>
                                <span className={css(styles.rewardName)}>{externalRewardName}</span>
                            </div>
                        )
                        : <p className={css(styles.levelScore)}>{levelScore}</p>
                    }
                </div>
            </div>
        );
    }

    render() {
        const { buttonTitle, titleFirstLine, titleSecondLine, onButtonPress, levels, trackActivityText, howToLevelUpText } = this.props;
        return (
            <div className={css(styles.innerContainer)}>
                <div className={css(styles.bodyContainer, layoutStyle.flexColumn)}>
                    <div className={css(styles.titleContainer, styles.title)}>
                        <p>{titleFirstLine}</p>
                        <p>{titleSecondLine}</p>
                    </div>
                    <div className={css(styles.levelItemContainer)}>
                        {_.map(levels, this.renderLevelItem)}
                    </div>
                    <div className={css(styles.textContainer)}>
                        <p className={css(styles.textParagraph)}>{trackActivityText}</p>
                        <p className={css(styles.textParagraph)}>{howToLevelUpText}</p>
                    </div>
                    <Core.Button onPress={onButtonPress} className={css(layoutStyle.fw)}>
                        {buttonTitle}
                    </Core.Button>
                </div>
            </div>
        );
    }
}

export default WithDashboardEarnPointsInfoBase(DashboardEarnPointsInfo);

const styles = StyleSheet.create({
    ...baseStyles,
    innerContainer: {
        alignItems: 'center',
        flexDirection: 'column',
        display: 'flex'
    },
    bodyContainer: {
        backgroundColor: baseColors.white,
        padding: spacing.s3,
        borderRadius: spacing.s1,
        shadowRadius: spacing.s2,
        shadowOpacity: 0.3,
        shadowOffset: { width: 0, height: 0 },
        shadowColor: baseColors.black,
        width: '100%'
    },
    coinsIcon: {
        marginTop: -spacing.s0,
    }
});