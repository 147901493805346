import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { selectors as coreSelectors, translate } from '../../../core';
import { spacing } from '../../../../styles';
import * as selectors from '../../selectors';
import * as actions from '../../actions';
import { PRIVACY_SLUGS_CONFIG } from '../../constants';

export const ICON_NAME = 'hand-paper';

export default function WithPrivacyTooltipBase(WrappedComponent) {
    class PrivacyTooltipBase extends PureComponent {
        static propTypes = {
            navigation: PropTypes.object.isRequired,
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            slug: PropTypes.string.isRequired,
            isEnhancedPrivacyInformationEnabled: PropTypes.bool.isRequired,
            isHiddenPrivacyInformationText: PropTypes.bool.isRequired,
            position: PropTypes.object.isRequired,
        };

        static defaultProps = {};

        get config() {
            return PRIVACY_SLUGS_CONFIG[this.props.slug] || {};
        }

        onHidePrivacyInformationText = () => this.props.actions.hidePrivacyInformationText(this.props.slug);

        get message() {
            return this.props.i18n.t(this.config.tooltipMessage);
        }

        get title() {
            return this.props.i18n.t(this.config.tooltipTitle);
        }

        get subtitle() {
            return this.config.tooltipSubtitle && this.props.i18n.t(this.config.tooltipSubtitle);
        }

        render() {
            const { isEnhancedPrivacyInformationEnabled, isHiddenPrivacyInformationText } = this.props;

            if (!isEnhancedPrivacyInformationEnabled || isHiddenPrivacyInformationText) return null;
            return (
                <WrappedComponent
                    {...this.props}
                    onConfirmPrivacyChange={this.onHidePrivacyInformationText}
                    title={this.title}
                    subtitle={this.subtitle}
                    message={this.message}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => ({
        isEnhancedPrivacyInformationEnabled: coreSelectors.isEnhancedPrivacyInformationEnabled(state),
        isHiddenPrivacyInformationText: !!selectors.isHiddenPrivacyInformationText(state, ownProps.slug)
    });

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(PrivacyTooltipBase));
}

export const styles = {
    buttonGotItContainer: {
        justifyContent: 'flex-end'
    },
    disclaimerContainer: {
        width: '100%'
    },
    disclaimer: {
        borderRadius: spacing.s1
    }
};
