import { appFonts, spacing } from '../../styles';

export const styles = {
    placeholder: {
        paddingTop: spacing.s10,
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
    },
    placeholderTitle: {
        ...appFonts.lgMedium
    },
    contentLeftPadding: {
        paddingLeft: spacing.s3,
    }
};