import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import classNames from 'classnames';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';

import { spacing, importantStyles, baseColors, layoutStyle } from '../../../../styles';
import Button from '../Button';
import InfoModal from '../InfoModal';
import { PageWrapper, FHFlex, FixedModalFooterButton } from '../Layout';
import { BlockingLoading } from '../Loading';
import CloseButton from '../CloseButton';
import { CSS_CLASSES, HEADER_TRANSITION_DURATION, SCROLL_DELAY } from '../../constants';
import WithStepsBase, { styles as baseStyles } from './StepsBase';
import Modal from '../Modal';
import Icon from '../Icon';

class Steps extends PureComponent {
    static propTypes = {
        cancelFlow: PropTypes.func,
        goBack: PropTypes.func.isRequired,
        getModalProps: PropTypes.func.isRequired,
        isBackButtonShown: PropTypes.bool.isRequired,
        headerClassName: PropTypes.string,
        isLoading: PropTypes.bool,
        i18n: PropTypes.object.isRequired,
        isModal: PropTypes.bool,
        isCloseButtonShown: PropTypes.bool,
        isNextButtonShown: PropTypes.bool,
        nextButtonLabel: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        propsToPass: PropTypes.object.isRequired,
        children: PropTypes.func.isRequired,
        isSingleStep: PropTypes.bool,
        isCreating: PropTypes.bool,
        edited: PropTypes.bool,
        needTrackChanges: PropTypes.bool,
        noFooter: PropTypes.bool,
        showArrowBack: PropTypes.bool,
        modalWithScrollbar: PropTypes.bool,
    };

    static defaultProps = {
        cancelFlow: null,
        isLoading: false,
        isModal: true,
        isCloseButtonShown: true,
        isNextButtonShown: false,
        isSingleStep: false,
        edited: false,
        needTrackChanges: false,
        headerClassName: undefined,
        noFooter: false,
        isCreating: false,
        showArrowBack: false,
        modalWithScrollbar: false,
    };

    constructor(props) {
        super(props);
        this.modalBody = React.createRef();
        this.detectScroll = _.throttle(this.handleScroll.bind(this), SCROLL_DELAY);
        this.state = { headerBorder: false };
    }

    componentDidMount() {
        this.modalBody.current.addEventListener('scroll', this.detectScroll);
        this.setState({ buttons: this.buttons });
    }

    componentWillUnmount() {
        this.modalBody.current.removeEventListener('scroll', this.detectScroll);
    }

    handleScroll = () => {
        this.setState({ headerBorder: _.get(this.modalBody, 'current.scrollTop') > 0 });
    };

    get buttons() {
        return [
            { text: this.props.i18n.t('invitation.yes'), onPress: this.confirmSkip, isDangerText: true },
            { text: this.props.i18n.t('invitation.no'), onPress: this.closeModal },
        ];
    }

    closeModal = () => this.closeModal();

    close = () => {
        const { needTrackChanges, edited, getModalProps, cancelFlow } = this.props;

        if (!needTrackChanges || (needTrackChanges && edited)) {
            this.closeModal = Modal.open(
                InfoModal,
                {
                    ...getModalProps(),
                    buttons: this.state.buttons,
                    iconClassName: css(styles.iconClassName)
                },
                {
                    cancelable: false,
                    PaperProps: { elevation: 0 },
                    isNoPadding: true,
                    isTransparent: true,
                    fadeTransition: true,
                }
            );
        } else if (cancelFlow) cancelFlow();
    };

    confirmSkip = () => {
        this.closeModal();
        if (this.props.cancelFlow) this.props.cancelFlow();
    };

    goForward = () => {
        const { isSingleStep, propsToPass } = this.props;
        if (_.get(this, 'wrapped.onPress')) this.wrapped.onPress();
        if (isSingleStep) propsToPass.onPress();
    }

    saveRef = ref => this.wrapped = ref;

    render() {
        const { isCloseButtonShown, isModal, propsToPass, isNextButtonShown, nextButtonLabel, isLoading, children,
            isBackButtonShown, headerClassName, noFooter, showArrowBack, title, isCreating, goBack, modalWithScrollbar, i18n } = this.props;
        return (
            <PageWrapper isModal={isModal}>
                <FHFlex className={css(styles.modalContainer)}>
                    <div className={classNames(css(styles.infoContainer, layoutStyle.flex, this.state.headerBorder ? styles.shadow : null), headerClassName)}>
                        {showArrowBack ?
                            <Button
                                onPress={goBack}
                                type="outlined"
                                className={css(layoutStyle.createEntityButton, styles.backButton)}>
                                <Icon
                                    name="angle-left"
                                    type="fa"
                                    size={spacing.s7}
                                    color={baseColors.secondary}
                                />
                                <span>{i18n.t('back')}</span>
                            </Button> : null}
                        <div className={css(styles.personalizeText)}>
                            {title}
                        </div>
                        {isCloseButtonShown ?
                            <CloseButton
                                isModal={isModal}
                                isSmall={true}
                                onClick={this.close}
                            /> : null}
                    </div>
                    <div
                        className={classNames(
                            css(styles.bodyContainer, noFooter ? styles.footerBottomOffset : layoutStyle.largeFooterOffset),
                            modalWithScrollbar ? CSS_CLASSES.modalBodyWithScrollbar : CSS_CLASSES.modalBody
                        )}
                        ref={this.modalBody}
                    >
                        {children ?
                            children({
                                ...propsToPass,
                                onRef: this.saveRef
                            }) : null
                        }
                    </div>
                    { isNextButtonShown || isBackButtonShown ?
                        <FixedModalFooterButton className={css(layoutStyle.commonPadding, styles.footerButton, styles.footerAlignment)}>
                            <div>
                                {
                                    isBackButtonShown ?
                                        <Button
                                            className={css(layoutStyle.twoButtonsLeft)}
                                            type='outlined'
                                            onPress={goBack}>
                                            {i18n.t('back')}
                                        </Button>
                                        : null
                                }
                                {
                                    isNextButtonShown ?
                                        <Button
                                            className={css(layoutStyle.twoButtonsRight)}
                                            onPress={this.goForward}
                                            disabled={isCreating}
                                        >
                                            {nextButtonLabel}
                                        </Button>
                                        : null}
                            </div>
                        </FixedModalFooterButton> : null
                    }
                    <BlockingLoading isLoading={isLoading} />
                </FHFlex>
            </PageWrapper>
        );
    }
}

const styles = StyleSheet.create({
    ...importantStyles({
        ...baseStyles,
        modalContainer: {
            borderRadius: spacing.s1,
            backgroundColor: baseColors.white
        },
        iconClassName: {
            backgroundColor: baseColors.primary
        },
        shadow: {
            boxShadow: `0px 2px ${spacing.s1}px rgba(0, 0, 0, ${0.3})`
        },
        footerAlignment: {
            alignItems: 'flex-end',
        }
    }),
    footerButton: {
        boxShadow: `0px -2px ${spacing.s1}px rgba(0, 0, 0, ${0.3})`,
        zIndex: 2
    },
    infoContainer: {
        ...baseStyles.infoContainer,
        padding: spacing.s3,
        zIndex: 1,
        transition: `all ${HEADER_TRANSITION_DURATION}ms ease-out 0ms`
    },
    bodyContainer: {
        padding: spacing.s3,
    },
    footerBottomOffset: {
        paddingBottom: 0
    }
});

export default withRouter(WithStepsBase(Steps));
