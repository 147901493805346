import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';

import { isVideo, isFeatured, itemIsCMSContent, secondsIntoTime } from '../../services';
import { spacing, baseColors, appFonts } from '../../../../styles';
import { translate, constants as coreConstants, selectors as coreSelectors, pointsHelper } from '../../../core';
import { PLAY_BUTTON_HEIGHT, PLAY_BUTTON_WIDTH } from '../../constants';
import FeaturedTag from '../../../core/components/FeaturedTag';
import JoinedTag from '../../../core/components/JoinedTag/JoinedTag';

export default function WithCarouselItemBase(WrappedComponent) {
    class CarouselItemBase extends PureComponent {
        static propTypes = {
            item: PropTypes.object.isRequired,
            callback: PropTypes.func,
            i18n: PropTypes.object.isRequired,
        };

        static defaultProps = {
            callback: null
        };

        constructor(props) {
            super(props);
            this.wrapped = React.createRef();
        }

        get image() {
            if (itemIsCMSContent(this.item)) {
                const url = _.get(this.item, 'image_url');
                if (url) {
                    return url;
                }
            }

            const { src, photo_path } = this.item.preview_metadata || {};
            return src || photo_path || this.item.thumbnail_url;
        }

        onPressSingle = () => {
            const { item: { id }, callback } = this.props;
            if (callback) callback(id);
            this.wrapped.current.openItemDetails();
        };

        get item() {
            return this.props.item;
        }

        get categoryColor() {
            const slug = coreConstants.ICON_CATEGORIES[_.get(this.item, 'category_slug')];
            return this.props.isLiveBetter ? _.get(slug, 'bgr') : baseColors.secondary;
        }

        get isVideo() {
            return isVideo(_.get(this.props, 'item.url')) || isVideo(_.get(this.props, 'item.preview_metadata.hls'));
        }

        get renderFeaturedTag() {
            const { i18n, item } = this.props;
            const text = i18n.t('promoted');
            return <FeaturedTag showTag={isFeatured(item)} text={text} />;
        }

        get renderJoinedTag() {
            return <JoinedTag showTag={this.props.item.isMember} />;
        }

        get bonusLabel() {
            return `${pointsHelper.formatPoints(this.item.activity_quantity, this.props.customPointsUnit)}`;
        }

        get earnLabelText() {
            const labelText = this.isVideo ? 'watchToEarn' : 'readToEarn';

            return this.item.activity_quantity !== '0'
                ? `${this.props.i18n.t(labelText)} ${this.bonusLabel}`
                : null;
        }

        get itemLabel() {
            const { isLiveBetter, item } = this.props;
            return isLiveBetter ? item.category : item.tags;
        }

        get hasSeveralLabels() {
            const { isLiveBetter, item } = this.props;
            return !isLiveBetter && item.tagsArr.length > 1;
        }

        get videoDuration() {
            const { item: { reading_time } } = this.props;
            return reading_time && secondsIntoTime(reading_time);
        }

        render() {
            return (
                <WrappedComponent
                    ref={this.wrapped}
                    {...this.props}
                    image={this.image}
                    isVideo={this.isVideo}
                    onPressSingle={this.onPressSingle}
                    categoryColor={this.categoryColor}
                    featuredTag={this.renderFeaturedTag}
                    joinedTag={this.renderJoinedTag}
                    earnLabelText={this.earnLabelText}
                    itemLabel={this.itemLabel}
                    hasSeveralLabels={this.hasSeveralLabels}
                    videoDuration={this.videoDuration}
                />
            );
        }
    }

    const mapStateToProps = state => ({
        customPointsUnit: coreSelectors.getCustomPointsUnit(state),
        isLiveBetter: coreSelectors.isLiveBetter(state)
    });

    return connect(mapStateToProps)(translate()(CarouselItemBase));
}

const VIDEO_DURATION_CONTAINER_VERTICAL_PADDING = 2;

export const styles = {
    innerContainer: {
        flex: 1,
        marginLeft: spacing.s3
    },
    title: {
        ...appFonts.lgBold,
        color: baseColors.black,
        marginTop: spacing.s0,
        marginBottom: spacing.s2
    },
    categoryText: {
        ...appFonts.smMedium,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    categoryWrapper: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    labelContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: spacing.s0
    },
    dotIcon: {
        marginLeft: spacing.s1,
        marginRight: spacing.s1
    },
    videoControlContainer: {
        flex: 1,
        display: 'flex',
        zIndex: 1,
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        position: 'absolute',
        height: '100%',
        width: '100%',
        paddingBottom: spacing.s1,
        paddingLeft: spacing.s1,
        paddingRight: spacing.s1
    },
    playButton: {
        height: PLAY_BUTTON_HEIGHT,
        width: PLAY_BUTTON_WIDTH,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -(PLAY_BUTTON_HEIGHT / 2),
        marginLeft: -(PLAY_BUTTON_WIDTH / 2)
    },
    videoDurationWrapper: {
        display: 'flex',
        width: '100%',
        alignItems: 'flex-end',
        justifyContent: 'flex-end'
    },
    videoDurationContainer: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        borderRadius: spacing.s0,
        paddingTop: VIDEO_DURATION_CONTAINER_VERTICAL_PADDING,
        paddingBottom: VIDEO_DURATION_CONTAINER_VERTICAL_PADDING,
        paddingLeft: spacing.s0,
        paddingRight: spacing.s0
    },
    videoDuration: {
        ...appFonts.xsBold,
        color: baseColors.white
    },
    bonusContainer: {
        paddingTop: spacing.s0
    }
};
