import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { css, StyleSheet } from 'aphrodite-jss';
import { withRouter } from 'react-router-dom';

import { layoutStyle, importantStyles } from '../../../../styles';
import { components as Core, ROUTES } from '../../../core';
import WithEventHeaderBase, { styles as baseStyles } from './EventHeaderBase';

class EventHeader extends PureComponent {
    static propTypes = {
        isEnded: PropTypes.bool,
        attendees: PropTypes.array,
        isJoiningEvent: PropTypes.bool,
        item: PropTypes.object.isRequired,
        i18n: PropTypes.object.isRequired,
        joinEvent: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired,
        invitedByText: PropTypes.string.isRequired,
        numMembersText: PropTypes.string.isRequired,
    };

    static defaultProps = {
        attendees: [],
        isEnded: false,
        isJoiningEvent: false,
    };

    onPressAllMembers = () => {
        const { history, item: { eventId } } = this.props;
        history.push(ROUTES.eventMembers(eventId));
    };

    get membersInfo() {
        const { numMembersText, invitedByText } = this.props;
        return (
            <div>
                <div className={css(styles.numMembersText)}>
                    {numMembersText}
                </div>
                {invitedByText ? <div className={css(styles.eventAddress)}>{invitedByText}</div> : null}
            </div>
        );
    }


    get renderAvatarList() {
        const { item: { numEventAttendees }, attendees } = this.props;
        return (
            <div>
                {attendees.length > 0 ?
                    <Core.AvatarList
                        sizeAvatar="small"
                        members={attendees}
                        numMembers={numEventAttendees}
                        onPress={this.onPressAllMembers}
                        supplementaryComponent={this.membersInfo}
                        membersAvatarClassName={css(styles.membersAvatar)}
                        containerClassName={css(styles.avatarListContainer)}
                    /> : null}
            </div>
        );
    }

    render() {
        const { joinEvent, isEnded, i18n, isJoiningEvent, item: { isMember, eventImageURL } } = this.props;
        return (
            <div className={css(styles.header)}>
                <div className={css(styles.eventHeaderContainer)}>
                    <Core.Image
                        className={css(styles.eventImage)}
                        src={eventImageURL ? eventImageURL : null}
                        resizeMode="cover"
                    />
                    <div className={css(layoutStyle.imageOverlay)} />
                </div>
                {!isMember && !isEnded ?
                    <Core.Button
                        color="secondary"
                        fullWidth={true}
                        onPress={joinEvent}
                        className={css(styles.functionalButton, this.props.item.eventPoints ? null : styles.functionalButtonWithPadding)}
                        isLoading={isJoiningEvent}>
                        {i18n.t('joinEventDetail')}
                    </Core.Button> : null}
                {this.renderAvatarList}
            </div>
        );
    }
}

export default withRouter(WithEventHeaderBase(EventHeader));

const styles = StyleSheet.create({
    ...baseStyles,
    eventHeaderContainer: {
        ...baseStyles.eventHeaderContainer,
        position: 'relative'
    },
    membersAvatar: {
        ...baseStyles.membersAvatar,
        borderStyle: 'solid'
    },
    ...importantStyles({
        avatarListContainer: {
            ...baseStyles.avatarListContainer
        },
        functionalButtonWithPadding: {
            ...baseStyles.functionalButtonWithPadding
        },
        functionalButton: {
            margin: 0
        },
    })
});

