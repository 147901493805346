import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import {
    isAllowedToSetGoal,
    isCarouselUnfilled,
    getIsCarouselInstructionalSlideDismissed,
    isLoadingChallenges,
    isLoadingRecommendedChallenges,
    isLoadingRecommendedGoals,
    getMyIsMemberChallengesFiltered,
    getFeaturedChallengesNotHidden,
    getCompletedNotDismissedGoals
} from '../../selectors';
import { CHALLENGE_TYPES, FEATURED_CHALLENGES } from '../../constants';
import { getFirstLoadingWrapper, translate, tracker } from '../../../core';
import { selectors as onboardingSelectors } from '../../../onboarding';
import { selectors as notificationsSelectors } from '../../../notifications';
import { isGoal, isStarted, isUnstarted, isFeatured, isInGracePeriod } from '../../services/helper';
import { baseColors } from '../../../../styles';

export const COMPLETED_NUMBER_TO_SHOW = 3;
export const BASE_CARD_HEIGHT = 380;
export const CIRCLE_SIZE = 64;
export const RECT_HEIGHT = 16;
export const RECT_COLOR = baseColors.grey85;

export default function WithCarouselBase(WrappedComponent) {
    class CarouselBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            challenges: PropTypes.array,
            goalsCompletedNotDismissed: PropTypes.array,
            onlyOnboardingScreen: PropTypes.bool,
            isAllowedToSetGoal: PropTypes.bool.isRequired,
            instructionalSlideDismissed: PropTypes.bool.isRequired,
            isLoading: PropTypes.bool
        };

        static defaultProps = {
            challenges: [],
            goalsCompletedNotDismissed: [],
            onlyOnboardingScreen: false,
            isLoading: false
        };

        constructor(props) {
            super(props);
            this.state = {
                //eslint-disable-next-line
                isLoading: props.isLoading, //used in getFirstLoadingWrapper
                isFirstLoaded: false
            };
            tracker.logEvent('Home_Carousel_View');
        }

        static getDerivedStateFromProps(nextProps, prevState) {
            return getFirstLoadingWrapper(nextProps, prevState);
        }

        get challengesData() {
            const { challenges, featuredChallenges } = this.props;
            const challengesOnly = this.filterChallenges(challenges);

            return [
                ...featuredChallenges,
                ...this.sortByEndDate(this.filterByIsFeatured(this.filterStartedNotGraceChallenges(challengesOnly))),
                ...this.sortByEndDate(this.filterByIsNotFeatured(this.filterStartedNotGraceChallenges(challengesOnly))),
                ...this.sortByEndDate(this.filterByIsFeatured(this.filterStartedIsInGracePeriod(challengesOnly))),
                ...this.sortByEndDate(this.filterByIsNotFeatured(this.filterStartedIsInGracePeriod(challengesOnly))),
                ...this.sortByStartDate(this.filterByIsFeatured(this.filterUpcomingChallenges(challengesOnly))),
                ...this.sortByStartDate(this.filterByIsNotFeatured(this.filterUpcomingChallenges(challengesOnly)))
            ];
        }

        get goalsData() {
            const { goalsCompletedNotDismissed, challenges } = this.props;
            const completedGoals = this.filterGoals(goalsCompletedNotDismissed);
            const goalsOnly = this.filterGoals(challenges);
            const filteredUniqGoals = _.uniqBy([
                ...this.sortByIsInGracePeriod(goalsOnly),
                ...this.completedItemsToShow(completedGoals),
            ], 'challengeId');
            return filteredUniqGoals;
        }

        get challengesTitle() {
            return this.props.i18n.t('challenges');
        }

        get goalsTitle() {
            return this.props.i18n.t('goals');
        }

        filterGoals = challenges => _.filter(challenges, challenge => isGoal(challenge));

        filterChallenges = challenges => _.filter(challenges, challenge => !isGoal(challenge));

        filterStartedNotGraceChallenges = challenges => _.filter(challenges, challenge => isStarted(challenge) && !isInGracePeriod(challenge));

        filterStartedIsInGracePeriod = challenges => _.filter(challenges, challenge => isStarted(challenge) && isInGracePeriod(challenge));

        filterUpcomingChallenges = challenges => _.filter(challenges, challenge => isUnstarted(challenge));

        filterUpcomingFeaturedChallenges = challenges => _.filter(challenges, challenge => isUnstarted(challenge) && isFeatured(challenge));

        filterUpcomingNotFeaturedChallenges = challenges => _.filter(challenges, challenge => isUnstarted(challenge) && !isFeatured(challenge));

        filterByIsFeatured = challenges => _.filter(challenges, challenge => isFeatured(challenge));

        filterByIsNotFeatured = challenges => _.filter(challenges, challenge => !isFeatured(challenge));

        sortByStartDate = challenges => _.sortBy(challenges, challenge => challenge.startDate);

        sortByEndDate = challenges => _.sortBy(challenges, challenge => challenge.challengeDeadline);

        sortByIsInGracePeriod = challenges => _.sortBy(challenges, challenge => isInGracePeriod(challenge));

        completedItemsToShow = items => items.length > COMPLETED_NUMBER_TO_SHOW ? _.slice(items, 0, COMPLETED_NUMBER_TO_SHOW) : items;

        loadData = () => {
            const { challenges } = this.props;
            this.props.actions.getPersonalGoalsAndChallenges({ isMember: 1, maxCount: 40 });
            this.props.actions.getPersonalGoalsAndChallenges({ isMember: 0, isFeatured: 1, isEnded: 0, maxCount: 10 }, FEATURED_CHALLENGES);
            this.props.actions.getPersonalGoalsAndChallengesCompleted();

            const challengeIds = _.map(_.filter(challenges, challenge => challenge.challengeType === CHALLENGE_TYPES.competition), 'challengeId');
            _.forEach(challengeIds, id => {
                this.props.actions.getChallengeLeaderboard(id);
            });
        };

        render() {
            const { isFirstLoaded } = this.state;
            return (
                <WrappedComponent
                    {...this.props}
                    isFirstLoaded={isFirstLoaded}
                    loadData={this.loadData}
                    challengesData={this.challengesData}
                    goalsData={this.goalsData}
                    goalsTitle={this.goalsTitle}
                    challengesTitle={this.challengesTitle}
                />
            );
        }
    }

    const mapStateToProps = state => ({
        challenges: getMyIsMemberChallengesFiltered(state),
        featuredChallenges: getFeaturedChallengesNotHidden(state),
        isAllowedToSetGoal: isAllowedToSetGoal(state),
        goalsCompletedNotDismissed: getCompletedNotDismissedGoals(state),
        slides: onboardingSelectors.getScreensArray(state),
        instructionalSlideDismissed: getIsCarouselInstructionalSlideDismissed(state),
        isLoading: isLoadingChallenges(state) || isLoadingRecommendedChallenges(state) || isLoadingRecommendedGoals(state),
        isFirstPriorityLoad: notificationsSelectors.isFirstPriorityLoad(state),
        isCarouselUnfilled: isCarouselUnfilled(state),
    });

    const mapDispatchToProps = dispatch => ({
        actions: bindActionCreators(actions, dispatch)
    });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(CarouselBase));
}
