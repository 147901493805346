import axios from 'axios';
import _ from 'lodash';
import uuid from 'uuid/v1';
import { getBaseUrl, MEMBERS_MAX_COUNT } from '../core';
import { DISPLAY_TYPES, DEFAULT_TIME_ID } from './constants';

const getChallengesUrl = endpoint => endpoint ? getBaseUrl(`challenges/${endpoint}`) : getBaseUrl('challenges');

export function getChallenges(parameters) {
    const params = _.extend({ isCompleted: 0, order: 'start-asc', maxCount: 20, start: 0 }, parameters);
    return axios.get(getChallengesUrl(), { params });
}

export function getChallengeRecommendations() {
    return axios.get(getChallengesUrl('recommendations'));
}

export function getRecommendedGoals(params) {
    return axios.get(getChallengesUrl('recommendations'), { params });
}

export function getStarterGoals(params) {
    return axios.get(getChallengesUrl('recommendations'), { params });
}

export function getChallenge(challengeId, isLight = false) {
    if (isLight) {
        return axios.get(getChallengesUrl(`${challengeId}?light=1`));
    } else {
        return axios.get(getChallengesUrl(`${challengeId}`));
    }
}

export function getChallengeTeam(challengeId, teamId) {
    return axios.get(getChallengesUrl(`${challengeId}/entities/${teamId}/users`));
}

export function getChallengeLeaderboard(challengeId) {
    return axios.get(getBaseUrl(`leaderboards?challengeId=${challengeId}`));
}

export function getChallengeMembership(challengeId) {
    return axios.get(getBaseUrl(`memberships/challenge_set/${challengeId}?order=firstname-asc&start=0`));
}

export function joinChallenge(challengeId, representedEntityId) {
    return axios.post(getBaseUrl(`memberships/challenges/${challengeId}`), { representedEntityId });
}

export function getChallengeByTeamId(teamId) {
    return axios.get(`${getBaseUrl('challenges/teams/accepts')}/${teamId}`);
}

export function leaveChallenge(challengeId) {
    return axios.delete(getBaseUrl(`memberships/challenges/${challengeId}`));
}

export function createChallenge(challenge) {
    return axios.post(getChallengesUrl(), challenge);
}

export function updateChallenge(challenge) {
    return axios.put(getChallengesUrl(challenge.challengeId), challenge);
}

export function deleteChallenge(challengeId) {
    return axios.delete(getChallengesUrl(challengeId));
}

export function setSingleChallengeDismissed(challengeId) {
    return axios.put(getChallengesUrl(`${challengeId}/entities`), { dismissed: 1 });
}

export function setFeaturedChallengeDismissed(challengeId) {
    return axios.put(getChallengesUrl(`${challengeId}/entities`), { hide: 1 });
}

export function getActivityUnits() {
    return axios.get(getBaseUrl('activity_categories'));
}

export function getActivityLogs(filterId = DEFAULT_TIME_ID) {
    return axios.get(getBaseUrl(`activity_logs/?timePeriodId=${filterId}`));
}

export function getFilteredActivityLogs(params) {
    return axios.get(getBaseUrl('activity_logs'), { params });
}

export function deleteActivityLog(id) {
    return axios.delete(getBaseUrl(`activity_logs/${id}`));
}

export function trackActivity(activities, shareWithFilterId, text, image) {
    return axios.post(
        getBaseUrl('activity_logs'),
        { activities, shareWithFilterId, text, image, uuid: uuid(), preventActivityStreamsCreation: 1 }
    );
}

export function getActivityLogsFromTo(startDate, endDate, maxCount, offset) {
    let url;
    if (!endDate) {
        url = getBaseUrl(`activity_logs/?startDate=${startDate}`);
    } else if (!startDate) {
        url = getBaseUrl(`activity_logs/?endDate=${endDate}`);
    } else {
        url = getBaseUrl(`activity_logs/?startDate=${startDate}&endDate=${endDate}`);
    }
    if (maxCount) {
        url += `&maxCount=${maxCount}`;
    }
    if (offset) {
        url += `&offset=${offset}`;
    }

    return axios.get(url);
}

export const createTeam = (challengeId, name, inviteUserIds, picture) => {
    const url = getBaseUrl(`challenges/${challengeId}/teams`);
    return axios.post(url, { name, inviteUserIds, picture });
};

export const editTeam = (challengeId, teamId, name, inviteUserIds, removeUserIds, picture) => {
    const url = getBaseUrl(`challenges/${challengeId}/teams/${teamId}`);
    return axios.put(url, { name, inviteUserIds, removeUserIds, picture });
};

export const getChallengeMembers = (challengeId, isTeamChallenge, parameters = {}) => {
    const params = { start: 0, maxCount: MEMBERS_MAX_COUNT, order: 'firstname-asc', ...parameters };
    const endpoint = isTeamChallenge ? 'challenge_set' : 'challenges';
    return axios.get(getBaseUrl(`memberships/${endpoint}/${challengeId}`), { params });
};

export function getLeaderboards(params) {
    return axios.get(getBaseUrl('leaderboards'), { params });
}

export function declineInvitation(invitationId) {
    return axios.delete(getBaseUrl(`challenges/${invitationId}/invitations`));
}

const CHALLENGE_LIST_PARAMS = {
    [DISPLAY_TYPES.ATTENDED]: { isCompleted: 0, isMember: 1, order: 'start-asc' },
    [DISPLAY_TYPES.INVITATIONS]: { isMember: 0, isInvited: 1, order: 'start-asc' },
    [DISPLAY_TYPES.COMPANY]: { isCompleted: 0, isCompanyChallenge: 1, order: 'start-asc' },
    [DISPLAY_TYPES.COMPLETED]: { isCompleted: 1, order: 'end-desc' },
    [DISPLAY_TYPES.RECOMMENDED]: { isCompleted: 0, isAdminChallenge: 1, order: 'created-desc' },
    [DISPLAY_TYPES.RECENT]: { isCompleted: 0, order: 'created-desc' },
    [DISPLAY_TYPES.ALL]: { order: 'start-asc' },
    [DISPLAY_TYPES.BONUS]: { isBonusChallenge: 1, isCompleted: 0, order: 'start-asc' },
    [DISPLAY_TYPES]: { isCompleted: 0 }
};

export function getChallengesList(params) {
    const { filter } = params;
    const challengeParams =
        _.assign((CHALLENGE_LIST_PARAMS[filter] || CHALLENGE_LIST_PARAMS[DISPLAY_TYPES.ALL]), { ...params, isSolo: 0 });
    return axios.get(getChallengesUrl(), { params: challengeParams });
}

export function getInvitations() {
    return axios.get(getBaseUrl('challenges/invitations'));
}

export function searchTeamsForGroupChallenge(params) {
    const { searchText, challengeId } = params;
    return axios.get(getBaseUrl(`memberships/challenge_set/${challengeId}?search=${searchText}`));
}
