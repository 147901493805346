import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';

import * as actions from '../../actions';
import { getPlatformUsersIds, getPlatformUsersCount, isLoadingPlatformUsers, isLoadingMorePlatformUsers,
    isShownLearnFriendsAndFamilyModal, getAcceptedPlatformInvitations, isLoadingPlatformInvitations } from '../../selectors';
import { translate, SEARCH_INPUT_DELAY } from '../../../core';
import { appFonts, spacing, baseColors } from '../../../../styles';
import { selectors as authSelectors } from '../../../auth';
import { constants as tourConstants, selectors as toursSelectors } from '../../../tours';
import { USER_PLUS_ICON } from '../../constants';

export default function WithFindFriendsAndFamilyBase(WrappedComponent) {
    class FindFriendsAndFamilyBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            platformUsers: PropTypes.array,
            platformUsersCount: PropTypes.number,
            isLoading: PropTypes.bool,
            isLoadingMore: PropTypes.bool,
            isShownLearnFriendsAndFamilyModal: PropTypes.bool,
            programName: PropTypes.string.isRequired,
            acceptedPlatformUsers: PropTypes.array,
            isLoadingPlatformUsers: PropTypes.bool,
            isFinishedFriendsAndFamilyTour: PropTypes.bool
        };

        static defaultProps = {
            platformUsers: [],
            platformUsersCount: 0,
            isLoading: false,
            isLoadingMore: false,
            isShownLearnFriendsAndFamilyModal: false,
            acceptedPlatformUsers: [],
            isLoadingPlatformUsers: false,
            isFinishedFriendsAndFamilyTour: false
        };

        constructor(props) {
            super(props);
            this.doSearch = _.debounce(this.doSearch.bind(this), SEARCH_INPUT_DELAY);
            this.state = {
                search: '',
                debounceSearch: '',
                isShownFriendsAndFamilyTour: false,
                isShownLearnModal: props.isShownLearnFriendsAndFamilyModal
            };
            this.props.actions.getPlatformInvitations();
        }

        componentDidMount() {
            this.props.actions.clearUsersToPlatformInvitation();
        }

        get isLoading() {
            return this.props.isLoading || this.props.isLoadingMore;
        }

        onSearch = search => {
            this.setState({ search });
            this.doSearch(search);
        };

        onClearSearch = () => {
            this.onSearch('');
        };

        doSearch = search => {
            if (search) {
                this.props.actions.getUsersToPlatformInvitation({ search });
            } else {
                this.props.actions.clearUsersToPlatformInvitation();
            }
            this.setState({ debounceSearch: search });
        };

        loadMore = () => {
            if (!this.isLoading && this.props.platformUsers.length < this.props.platformUsersCount) {
                this.props.actions.getUsersToPlatformInvitation({
                    search: this.state.search,
                    offset: this.props.platformUsers.length
                }, true);
            }
        };

        showLearnFriendsAndFamilyModal = () => {
            this.props.actions.showLearnFriendsAndFamilyModal();
        };

        get isAbleToShowTour() {
            return !this.props.isFinishedFriendsAndFamilyTour && !this.state.debounceSearch &&
                !this.props.isLoading && !this.props.isLoadingMore && !this.props.isLoadingPlatformUsers &&
                this.props.acceptedPlatformUsers.length && !this.state.isShownFriendsAndFamilyTour && this.state.isShownLearnModal;
        }

        showFriendsAndFamilyTour = callback => {
            this.setState({ isShownFriendsAndFamilyTour: true }, callback);
        };

        onCloseLearnModal = callback => {
            this.setState({ isShownLearnModal: true }, callback);
        };

        learnMoreModalProps = () => {
            return {
                iconName: USER_PLUS_ICON,
                iconBackgroundColor: baseColors.primary,
                title: this.props.i18n.t('friendsAndFamily.learnModal.title'),
                buttonTitle: this.props.i18n.t('got_it'),
            };
        }

        get title() {
            return this.props.i18n.t('friendsAndFamily.friends&Family');
        }

        get inviteText() {
            return this.props.i18n.t('inviteByEmail');
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    search={this.state.search}
                    debounceSearch={this.state.debounceSearch}
                    onSearch={this.onSearch}
                    loadMore={this.loadMore}
                    onClearSearch={this.onClearSearch}
                    showLearnFriendsAndFamilyModal={this.showLearnFriendsAndFamilyModal}
                    isAbleToShowTour={this.isAbleToShowTour}
                    showFriendsAndFamilyTour={this.showFriendsAndFamilyTour}
                    onCloseLearnModal={this.onCloseLearnModal}
                    learnMoreModalProps={this.learnMoreModalProps}
                    title={this.title}
                    inviteText={this.inviteText}
                />
            );
        }
    }

    function mapStateToProps(state) {
        return {
            platformUsers: getPlatformUsersIds(state),
            platformUsersCount: getPlatformUsersCount(state),
            isLoading: isLoadingPlatformUsers(state),
            isLoadingMore: isLoadingMorePlatformUsers(state),
            isShownLearnFriendsAndFamilyModal: isShownLearnFriendsAndFamilyModal(state),
            programName: authSelectors.getProgramName(state),
            acceptedPlatformUsers: getAcceptedPlatformInvitations(state),
            isLoadingPlatformUsers: isLoadingPlatformInvitations(state),
            isFinishedFriendsAndFamilyTour: toursSelectors.isFinishedTour(state, tourConstants.TOURS.FRIENDS_AND_FAMILY_TOUR)
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        };
    }
    return connect(mapStateToProps, mapDispatchToProps)(translate()(FindFriendsAndFamilyBase));
}

export const styles = {
    learnMore: {
        marginLeft: spacing.s3,
        marginRight: spacing.s3,
        marginBottom: spacing.s2,
        marginTop: spacing.s0
    },
    learnMoreText: {
        color: baseColors.secondary,
        ...appFonts.smMedium
    },
    searchField: {
        paddingBottom: spacing.s0
    },
    descriptionContainer: {
        margin: spacing.s3,
        marginTop: spacing.s5,
        alignItems: 'center'
    },
    descriptionItem: {
        ...appFonts.mdRegular,
        marginTop: spacing.s3,
        textAlign: 'center'
    },
    emailContainer: {
        margin: spacing.s3,
        paddingTop: spacing.s1
    },
    emailContainerText: {
        ...appFonts.mdRegular,
        textAlign: 'center',
        marginBottom: spacing.s3
    },
    emailButton: {
        height: spacing.s9
    },
    loadingContainer: {
        marginTop: spacing.s3,
    },
    learnText: {
        ...appFonts.mdRegular
    },
    learnIconsMean: {
        ...appFonts.mdMedium,
        textAlign: 'center',
        marginTop: spacing.s4
    },
    learnIconsContainer: {
        flexDirection: 'row',
        justifyContent: 'center'
    },
    learnIconItem: {
        marginTop: spacing.s3,
        marginLeft: spacing.s3,
        marginRight: spacing.s3,
        alignItems: 'center'
    },
    learnIconText: {
        ...appFonts.smRegular,
        marginTop: spacing.s0
    }
};
