import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import _ from 'lodash';
import LeaderboardItem from '../LeaderboardItem';
import { importantClass, layoutStyle } from '../../../../styles';
import { components as Core } from '../../../core';
import LeaderboardsFilters from '../LeaderboardsFilters';
import WithLeaderboardsBase, { styles as baseStyles } from './LeaderboardsBase';

class Leaderboards extends PureComponent {
    static propTypes = {
        title: PropTypes.string,
        isLoading: PropTypes.bool,
        onSnapToItem: PropTypes.func,
        leaderboards: PropTypes.array,
        setTimeFilter: PropTypes.func,
        challengeId: PropTypes.number,
        isDisabledPress: PropTypes.bool,
        actions: PropTypes.object.isRequired,
        activeSlide: PropTypes.number.isRequired,
        setChallengeId: PropTypes.func.isRequired,
        loadingLabel: PropTypes.string.isRequired,
        showLoadingLabel: PropTypes.bool.isRequired,
        emptyLeaderboardText: PropTypes.string.isRequired,
        isLocationLeaderboard: PropTypes.bool.isRequired
    };

    static defaultProps = {
        leaderboards: [],
        isLoading: false,
        setTimeFilter: null,
        onSnapToItem: null,
        title: undefined,
        challengeId: 0,
        isDisabledPress: true
    };

    static LIST_OFFSET_TOP = 50; //because of absolute positioning of dots

    get loadingComponent() {
        const { isLoading, showLoadingLabel, loadingLabel } = this.props;
        return isLoading ? (
            <div className={css(styles.indicator)}>
                <Core.ListLoading key="Loading" />
                {showLoadingLabel && <div className={css(styles.indicatorText)}>{loadingLabel}</div>}
            </div>
        ) : null;
    }

    get emptyListComponent() {
        return (
            <Core.EmptyListSimple
                isIconHidden={true}
                className={css(styles.listEmpty)}
                message={this.props.emptyLeaderboardText}
            />
        );
    }

    get isChallengeFilter() {
        return this.refFilters && this.refFilters.isFilterChallenge;
    }

    renderSlide = item => item.items.length ? (
        <Core.List
            scrollEventThrottle={100}
            keyExtractor={item => item.itemEntityId}
            classes={{ root: css(styles.innerContainer) }}
            scrollEnabled={false}>
            {_.map(item.items, this.renderItem)}
        </Core.List>
    ) : this.emptyListComponent;

    renderItem = (item, index) => (
        <LeaderboardItem
            itemIndex={index}
            leaderboard={item}
            isChallenge={this.isChallengeFilter}
            challengeId={this.props.challengeId}
            isDisabledAvatar={this.props.isDisabledPress}
            isLocationItem={this.props.isLocationLeaderboard}
        />
    );

    onRefFilters = ref => this.refFilters = ref;

    render() {
        const { actions: { getLeaderboards }, setTimeFilter, setChallengeId, isLoading,
            title, onSnapToItem, leaderboards, activeSlide } = this.props;
        return (
            <div className={css(layoutStyle.flex1)}>
                <LeaderboardsFilters
                    isLeaderBoard={true}
                    onRef={this.onRefFilters}
                    fetcher={getLeaderboards}
                    setTimeFilter={setTimeFilter}
                    setChallengeId={setChallengeId}
                />
                {isLoading ? this.loadingComponent : (
                    <React.Fragment>
                        {leaderboards.length ? (
                            <React.Fragment>
                                <div className={css(styles.title)}>{title}</div>
                                <Core.Carousel
                                    activeSlide={activeSlide}
                                    afterChange={onSnapToItem}
                                    isTopAlignedDotsAndArrows={true}
                                    itemsLength={leaderboards.length}>
                                    {_.map(leaderboards, this.renderSlide)}
                                </Core.Carousel>
                            </React.Fragment>
                        ) : this.emptyListComponent}
                    </React.Fragment>
                )}
            </div>
        );
    }
}

export default WithLeaderboardsBase(Leaderboards);

const styles = StyleSheet.create({
    ...baseStyles,
    innerContainer: importantClass({
        ...baseStyles.innerContainer,
        marginTop: Leaderboards.LIST_OFFSET_TOP
    }),
    listEmpty: {
        marginTop: Leaderboards.LIST_OFFSET_TOP
    }
});
