const baseSpacing = 4;
const spacing = {
    s0: baseSpacing, // 4px
    s1: baseSpacing * 2, // 8px
    s2: baseSpacing * 3, // 12px
    s3: baseSpacing * 4, // 16px
    s4: baseSpacing * 5, // 20px
    s5: baseSpacing * 6, // 24px
    s6: baseSpacing * 7, // 28px

    s7: baseSpacing * 8, // 32px 6
    s8: baseSpacing * 9, // 36px 7
    s9: baseSpacing * 10, // 40px 8
    s10: baseSpacing * 11, // 44px 9
    s11: baseSpacing * 12, // 48px 10
    s12: baseSpacing * 13, // 52px
    s13: baseSpacing * 14, // 56px
    s14: baseSpacing * 15, // 60px
    s15: baseSpacing * 16, // 64px
    s16: baseSpacing * 17, // 68px
    s17: baseSpacing * 18, // 72px
    s18: baseSpacing * 19, // 76px
    s19: baseSpacing * 20, // 80px
};

export default spacing;
