import React, { PureComponent } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { getUserName, translate } from '../../../core';
import { appFonts, spacing } from '../../../../styles';

export default function WithInvitationBase(WrappedComponent) {
    class InvitationBase extends PureComponent {
        static propTypes = {
            invitedBy: PropTypes.object.isRequired,
            i18n: PropTypes.object.isRequired,
            type: PropTypes.string.isRequired,
        };

        get imageURl() {
            const { invitedBy } = this.props;
            return _.get(invitedBy, 'image_url') || _.get(invitedBy, 'profile_image_url');
        }

        get userName() {
            return getUserName(this.props.invitedBy);
        }

        get invitationMessage() {
            return `${this.userName} ${this.props.i18n.t('invitationReceivedMessage')} ${this.props.type}`;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    imageURl={this.imageURl}
                    userName={this.userName}
                    invitationMessage={this.invitationMessage}
                />
            );
        }
    }

    return translate()(InvitationBase);
}

export const styles = {
    mainContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingBottom: spacing.s3
    },
    textItem: {
        paddingLeft: spacing.s3,
        flex: 1,
        ...appFonts.xsRegular
    }
};
