const EVENT_NAME_ONLINE = 'online';
const EVENT_NAME_OFFLINE = 'offline';

export default class NetInformation {
    static map = new Map();

    static addEventListener(callback) {
        const callbacks = {
            [EVENT_NAME_ONLINE]: () => callback(true),
            [EVENT_NAME_OFFLINE]: () => callback(false),
        };
        NetInformation.map.set(callback, callbacks);
        window.addEventListener(EVENT_NAME_ONLINE, callbacks[EVENT_NAME_ONLINE]);
        window.addEventListener(EVENT_NAME_OFFLINE, callbacks[EVENT_NAME_OFFLINE]);
    }

    static removeEventListener(callback) {
        const callbacks = NetInformation.map.get(callback);
        window.removeEventListener(EVENT_NAME_ONLINE, callbacks[EVENT_NAME_ONLINE]);
        window.removeEventListener(EVENT_NAME_OFFLINE, callbacks[EVENT_NAME_OFFLINE]);
        NetInformation.map.delete(callback);
    }

    static isConnected() {
        return new Promise(resolve => resolve(window.navigator.onLine));
    }
}