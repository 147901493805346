import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { spacing } from '../../../../styles';
import { translate } from '../../../core';
import * as actions from '../../actions';
import { getAvailableReactions } from '../../selectors';

export default function WithReactionsModal(WrappedComponent) {
    class ReactionsModalBase extends PureComponent {
        static propTypes = {
            reactions: PropTypes.array,
            likedItemId: PropTypes.number.isRequired,
            toUserId: PropTypes.number.isRequired,
            likedItemType: PropTypes.number.isRequired
        };

        static defaultProps = {
            reactions: []
        };

        get availableReactions() {
            const { reactions } = this.props;
            return _.filter(reactions, reaction => reaction.active === '1');
        }

        selectReaction = (item, callback) => {
            const { likedItemId, toUserId, likedItemType } = this.props;

            this.props.actions.addReaction(likedItemId, toUserId, likedItemType, item.id, item);

            if (callback) {
                callback();
            }
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    availableReactions={this.availableReactions}
                    selectReaction={this.selectReaction}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        const likedItemType = ownProps.likedItemType || _.get(ownProps, 'match.params.likedItemType') || _.get(ownProps, 'route.params.likedItemType');
        const likedItemId = ownProps.likedItemId || _.get(ownProps, 'match.params.likedItemId') || _.get(ownProps, 'route.params.likedItemId');
        const toUserId = ownProps.toUserId || _.get(ownProps, 'match.params.toUserId') || _.get(ownProps, 'route.params.toUserId');
        return {
            reactions: getAvailableReactions(state),
            likedItemType,
            likedItemId,
            toUserId
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(ReactionsModalBase));

}

export const styles = {
    emojiRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        paddingTop: spacing.s11,
        paddingBottom: spacing.s17,
    },
    emojiItem: {
        display: 'flex',
        marginLeft: spacing.s1,
        marginRight: spacing.s1,
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
};