//t = current time
//b = start value
//c = change in value
//d = duration

const easeInOutQuad = (ct, b, c, d) => {
    let t = ct / (d / 2);
    if (t < 1) return c / 2 * t * t + b;
    t -= 1;
    return -c / 2 * (t * (t - 2) - 1) + b;
};

export const scrollTo = (to, duration) => {
    const element = document.documentElement;
    const start = element.scrollTop;
    const change = to - start;
    let currentTime = 0;
    const increment = 20;

    const animateScroll = () => {
        currentTime += increment;
        element.scrollTop = easeInOutQuad(currentTime, start, change, duration);
        if (currentTime < duration) {
            setTimeout(animateScroll, increment);
        }
    };
    animateScroll();
};

export const scrollToSimple = to => {
    window.scroll({ top: to, left: 0, behavior: 'smooth' });
};

export const scrollToNode = (node, block = 'center') => {
    node.scrollIntoView({ behavior: 'smooth', block });
};

export const scrollToEnd = () => {
    const scrollingElement = document.documentElement || document.body;
    scrollToSimple(scrollingElement.scrollHeight);
};


const ANIMATION_DURATION = 1000;

export function flyTo(node, targetNode, action, duration = ANIMATION_DURATION, isClone = true) {
    const scrollTop = document.documentElement.scrollTop;

    const nodeBox = node.getBoundingClientRect();
    const clone = isClone ? node.cloneNode(true) : node;

    clone.style.transition = `all ${duration}ms`;
    clone.style.position = 'absolute';
    clone.style.zIndex = 1300;

    clone.style.top = `${nodeBox.top + scrollTop}px`;
    clone.style.left = `${nodeBox.left}px`;
    clone.style.width = `${nodeBox.width}px`;

    document.getElementsByTagName('body')[0].appendChild(clone);

    const targetNodeBox = targetNode.getBoundingClientRect();

    // to get UI updated
    setTimeout(() => {
        const diffV = (nodeBox.height - targetNodeBox.height) / 2;
        const diffH = (nodeBox.width - targetNodeBox.width) / 2;
        clone.style.top = `${targetNodeBox.top + scrollTop - diffV}px`;
        clone.style.left = `${targetNodeBox.left - diffH}px`;
        const translate = `scale(${targetNodeBox.width / nodeBox.width}, ${targetNodeBox.height / nodeBox.height})`;
        clone.style.transform = translate;

        // start opacity animation only on half of way
        setTimeout(() => {
            clone.style.opacity = 0;
            action && action();
            setTimeout(() => clone.remove(), duration);
        }, duration / 2);
    });
}

