import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { StyleSheet, css } from 'aphrodite-jss';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import { spacing, importantClass, baseColors } from '../../../../styles';
import { Flash } from '../Animated';

export { default as CalculatingCircles } from './CalculatingCircles';

export const ListLoading = ({ className, ...props }) => (
    <div className={classnames(css(styles.loading), className)}>
        <CircularProgress {...props} />
    </div>
);
ListLoading.propTypes = { color: PropTypes.string, className: PropTypes.string, size: PropTypes.number };
ListLoading.defaultProps = { color: 'secondary', className: undefined, size: undefined };

export const ListLoadingControlled = ({ isLoading }) => isLoading ? <ListLoading /> : null;
ListLoadingControlled.propTypes = { isLoading: PropTypes.bool };
ListLoadingControlled.defaultProps = { isLoading: false };

export const BlockingLoading = ({ isLoading = false, color, size, message }) => (
    <Dialog disableBackdropClick={true} open={isLoading} PaperProps={{ className: css(styles.blockingPaper) }}>
        <CircularProgress key={0} color={color} size={size} />
        <div>{message}</div>
    </Dialog>
);
BlockingLoading.propTypes = { isLoading: PropTypes.bool, color: PropTypes.string, size: PropTypes.number, message: PropTypes.node };
BlockingLoading.defaultProps = { isLoading: false, color: 'secondary', size: 80, message: undefined };

const ANIMATION_DURATION = 1000;
export const DotsLoading = ({ color }) => (
    <div className={css(styles.dotFlashing)}>
        <Flash
            delay={0}
            forever={true}
            timeout={ANIMATION_DURATION}>
            <div className={css(styles.dot)} style={{ backgroundColor: color }}></div>
        </Flash>
        <Flash
            delay={ANIMATION_DURATION*0.6}
            forever={true}
            timeout={ANIMATION_DURATION}>
            <div className={css(styles.dot)} style={{ backgroundColor: color }}></div>
        </Flash>
        <Flash
            delay={ANIMATION_DURATION*0.2}
            forever={true}
            timeout={ANIMATION_DURATION}>
            <div className={css(styles.dot)} style={{ backgroundColor: color }}></div>
        </Flash>
    </div>
);
DotsLoading.propTypes = { color: PropTypes.string };
DotsLoading.defaultProps = { color: undefined };

const styles = StyleSheet.create({
    loading: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: spacing.s1
    },
    blockingPaper: importantClass({
        background: 'none',
        boxShadow: 'none',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden'
    }),
    dotFlashing: {
        width: spacing.s6,
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    dot: {
        width: spacing.s1,
        height: spacing.s1,
        borderRadius: spacing.s0,
        backgroundColor: baseColors.secondary,
    }
});
