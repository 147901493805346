import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as actions from '../../actions';
import NetInfo from '../../services/NetInfo';
import { TOAST_TYPES } from '../../constants';
import translate from '../../services/translate';
import { spacing, appFonts } from '../../../../styles';

export const ICON_SIZE = 96;

export default function WithNoConnectionBase(WrappedComponent) {
    class NoConnectionBase extends PureComponent {
        static propTypes = {
            navigation: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            i18n: PropTypes.object.isRequired
        };

        state = {};

        onButtonPress = async () => {
            await this.setState({ isChecking: true });
            const isConnected = await NetInfo.isConnected();
            await this.setState({ isChecking: false });
            if (isConnected) {
                this.close();
            } else {
                this.props.actions.addToast(TOAST_TYPES.INFO, undefined,
                    this.props.i18n.t('core.noConnection.title'), this.props.i18n.t('core.noConnection.button'));
            }
        };

        close = () => {
            _.has(this, 'wrapped.close') && this.wrapped.close();
        };

        saveRef = ref => (this.wrapped = ref);

        getText = type => this.props.i18n.t(`core.noConnection.${type}`);

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    ref={this.saveRef}
                    getText={this.getText}
                    isChecking={this.state.isChecking}
                    onButtonPress={this.onButtonPress}
                />
            );
        }
    }

    const mapDispatchToProps = dispatch => {
        return ({ actions: bindActionCreators(actions, dispatch) });
    };

    return connect(undefined, mapDispatchToProps)(translate()(NoConnectionBase));
}

export const styles = {
    bodyContainer: {
        flex: 0.7,
        flexDirection: 'column',
        display: 'flex'
    },
    iconContainer: {
        paddingTop: ICON_SIZE - spacing.s3
    },
    title: {
        ...appFonts.xlMedium,
        textAlign: 'center',
        marginTop: spacing.s9
    },
    description: {
        ...appFonts.smRegular,
        textAlign: 'center',
        marginTop: spacing.s1
    },
    body: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'flex-start',
        display: 'flex',
        flexDirection: 'column'
    }
};
