import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { StyleSheet, css } from 'aphrodite-jss';
import { spacing, baseColors, importantStyles, layoutStyle } from '../../../../../styles';
import { components as Core, ROUTES } from '../../../../core';
import WithEarnPartnersItemBase, { styles as baseStyles, NUMBER_OF_LINES, CARD_IMAGE_SIZE } from './EarnPartnersItemBase';

class EarnPartnersItem extends PureComponent {
    static propTypes = {
        isCard: PropTypes.bool,
        item: PropTypes.object,
        isIconHidden: PropTypes.bool,
        history: PropTypes.object.isRequired,
        statusImageProps: PropTypes.object.isRequired,
        isStatusIconHidden: PropTypes.bool.isRequired
    };

    static defaultProps = {
        isCard: false,
        item: undefined,
        isIconHidden: false
    };

    onPartnerPress = () => {
        const partnerId = this.props.item.earnPartnerId;
        this.props.history.push(ROUTES.earnPartnersDetails(partnerId));
    };

    get statusImage() {
        const { statusImageProps: { colorStyle, iconName, type } } = this.props;
        return (
            <div className={css(styles.circle)} style={colorStyle}>
                <Core.Icon
                    name={iconName}
                    size={spacing.s2}
                    color={baseColors.white}
                    type={type}
                />
            </div>
        );
    }

    get imageInnerComponent() {
        return (this.props.item.logo ?
            <Core.Image
                className={css(styles.image, styles.roundedCardImage)}
                resizeMode="cover"
                src={this.props.item.logo}
            /> :
            <div className={css(styles.image, styles.defaultImage, styles.roundedCardImage)} />
        );
    }

    get cardItem() {
        const { item } = this.props;

        return (
            <Core.TouchableItem
                onPressSingle={this.onPartnerPress}
                innerContainerClassName={css(styles.cardItemInnerContainer, layoutStyle.flex1, layoutStyle.flexRow)}
                containerClassName={css(styles.cardItemContainer)}
                imageInnerComponent={this.imageInnerComponent}>
                <div className={css(styles.cardInner)}>
                    <Core.TextSeeMore
                        className={css(styles.cardTitle)}
                        isExpandHidden={true}
                        isCollapseHidden={true}
                        numberOfLines={1}>
                        {item.name}
                    </Core.TextSeeMore>
                    <Core.TextSeeMore
                        className={css(styles.cardDescription)}
                        isExpandHidden={true}
                        isCollapseHidden={true}
                        numberOfLines={NUMBER_OF_LINES}>
                        {item.description}
                    </Core.TextSeeMore>
                </div>
            </Core.TouchableItem>
        );
    }

    render() {
        const { item, isIconHidden, isStatusIconHidden, isCard } = this.props;
        return (isCard ? this.cardItem : (
            <Core.ListItem button={true} onClick={this.onPartnerPress} className={css(layoutStyle.listItem)}>
                <Core.ListItemIcon className={css(styles.itemIconRoot)}>
                    {!isStatusIconHidden ? this.statusImage : null}
                    {item.logo ?
                        <Core.Image
                            className={css(styles.image, styles.roundedRectImage)}
                            resizeMode="cover"
                            src={item.logo}
                        /> :
                        <div className={css(styles.image, styles.defaultImage, styles.roundedRectImage)} />
                    }
                </Core.ListItemIcon>
                <Core.ListItemText
                    primary={
                        <Core.TextSeeMore
                            className={css(styles.itemText)}
                            isExpandHidden={true}
                            isCollapseHidden={true}>
                            {item.name}
                        </Core.TextSeeMore>
                    }
                    secondary={
                        <Core.TextSeeMore
                            className={css(styles.itemText)}
                            numberOfLines={NUMBER_OF_LINES}
                            isExpandHidden={true}
                            isCollapseHidden={true}>
                            {item.headline}
                        </Core.TextSeeMore>
                    }
                />
                {!isIconHidden ?
                    <Core.Icon
                        name="angle-right"
                        size={spacing.s4}
                        color={baseColors.grey50}
                        type="fa"
                    /> : null
                }
            </Core.ListItem>)
        );
    }
}

export default withRouter(WithEarnPartnersItemBase(EarnPartnersItem));

const styles = StyleSheet.create(importantStyles({
    ...baseStyles,
    itemText: {
        marginRight: spacing.s5,
        wordBreak: 'break-word',
        wordWrap: 'break-word',
    },
    circle: {
        ...baseStyles.circle,
        display: 'flex'
    },
    itemIconRoot: {
        position: 'relative'
    },
    roundedCardImage: {
        width: CARD_IMAGE_SIZE,
        height: CARD_IMAGE_SIZE,
        marginRight: 0,
    },
    cardItemContainer: {
        flex: '0 50%',
        marginBottom: spacing.s3,
    },
}));

