import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import * as actions from '../../actions';
import { appFonts, baseColors, spacing } from '../../../../styles';
import { Alert, ALERT_TIMEOUT, DATE_FORMATS, selectors as coreSelectors, timeout, translate, parsers } from '../../../core';
import { ACTIVITIES_SEARCH_KEYS } from '../../constants';
import * as selectors from '../../selectors';
import { activitiesHelper } from '../../services';

const REQUEST_ID = 'calendar';
const INFO_ICON = 'question';
const DEFAULT_MONTH_INDEX = 3; // carousel supports 4 months, the most recent month is last and therefore has index 3
export const DOT_TYPES = {
    tracked: 'tracked',
    pending: 'pending'
};

export default function WithActivityTrackingCalendarBase(WrappedComponent) {
    class ActivityTrackingCalendarBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            activities: PropTypes.array.isRequired,
            customizePost: PropTypes.bool,
            isLoading: PropTypes.bool,
            onlyMeFilter: PropTypes.string.isRequired,
            allLogs: PropTypes.array.isRequired,
            actions: PropTypes.object.isRequired,
            activityLogs: PropTypes.array.isRequired,
            error: PropTypes.object,
            isShownFirstActivityTrackModal: PropTypes.bool,
            setTimeout: PropTypes.func.isRequired,
            navigation: PropTypes.object,

            // Auto track from URL
            type: PropTypes.string,
            unit: PropTypes.string,
            quantity: PropTypes.string,
            date: PropTypes.string
        };

        static defaultProps = {
            customizePost: false,
            isLoading: false,
            error: undefined,
            isShownFirstActivityTrackModal: false,
            type: null,
            unit: null,
            quantity: null,
            date: null,
            navigation: {}
        };

        constructor(props) {
            super(props);

            this.state = {
                animatedListItems: [],
                pendingActivities: [],
                changedActivities: [],
                selectedDate: moment().format(DATE_FORMATS.full),
                activityLogs: props.activityLogs,
                deletedActivities: [],
                focusedMonth: moment().format(DATE_FORMATS.monthFull)
            };

            this.dotsInfo = [
                { id: DOT_TYPES.tracked, text: this.props.i18n.t('activityTracking.dots.tracked'), dotStyle: 'dotTracked' },
                { id: DOT_TYPES.pending, text: this.props.i18n.t('activityTracking.dots.added'), dotStyle: 'dotPending' }
            ];

            this.props.actions.clearActivityLogsExtended();
            this.onSwitchMonths(DEFAULT_MONTH_INDEX);
            if (this.props.type && this.props.unit && this.props.quantity && this.props.date) {
                // Find the matching type and unit
                const activity = _.find(this.props.activities, a => a.activityName.toLowerCase() === this.props.type.toLowerCase() &&
                    a.unitName.toLowerCase() === this.props.unit.toLowerCase());
                if (activity) {
                    const trackActivity = {
                        ...activity,
                        quantity: this.props.quantity,
                        date: this.props.date === 'today' ? moment().format(DATE_FORMATS.full) : this.props.date
                    };
                    // Find if there's already an activity that is the same to not automatically track the same
                    const dup = _.find(this.props.activityLogs, al => {
                        return (
                            _.get(al, 'activityId') == _.get(trackActivity, 'activityId')               //eslint-disable-line
                            && _.get(al, 'activityUnitId') == _.get(trackActivity, 'activityUnitId')    //eslint-disable-line
                            && _.get(al, 'quantity') == _.get(trackActivity, 'quantity')                //eslint-disable-line
                            && _.get(al, 'date') === _.get(trackActivity, 'date')
                        );
                    });
                    if (!dup) {
                        activitiesHelper.submitActivities(
                            REQUEST_ID,
                            [trackActivity],
                            [],
                            [],
                            this.props.onlyMeFilter,
                            false,
                            false,
                            this.changeOrDeleteActivities,
                            this.trackActivity,
                            this.props.navigation
                        );
                        this.dismissModal();
                    }
                }
            }
        }

        static getDerivedStateFromProps(nextProps, prevState) {
            if (nextProps.activityLogs !== prevState.activityLogs) {
                return {
                    ...prevState,
                    activityLogs: nextProps.activityLogs
                };
            }
        }

        componentDidUpdate(prevProps) {
            if (prevProps.isLoading && !this.props.isLoading) {
                if (!this.props.error) {
                    this.dismissModal();
                } else {
                    this.props.setTimeout(() => {
                        this.props.actions.clearTrackError(REQUEST_ID);
                    }, ALERT_TIMEOUT);
                }
            }
        }

        get calendarData() {
            return [
                { id: 1, month: moment().subtract(3, 'month').startOf('month').format(DATE_FORMATS.full) },
                { id: 2, month: moment().subtract(2, 'month').startOf('month').format(DATE_FORMATS.full) },
                { id: 3, month: moment().subtract(1, 'month').startOf('month').format(DATE_FORMATS.full) },
                { id: 4, month: moment().startOf('month').format(DATE_FORMATS.full) }
            ];
        }

        get isChangesPresent() {
            return this.state.pendingActivities.length > 0 || this.state.deletedActivities.length > 0;
        }

        exitScreen = () => {
            if (this.isChangesPresent) {
                const { i18n } = this.props;
                //if edits have been made then pop up a confirmation modal
                Alert.alert('', i18n.t('discard_activity_edits_confirmation', { date: this.formatedDate }), [
                    { text: i18n.t('yes_discard_edits'), isDangerButton: true, onPress: this.dismissModal },
                    { text: i18n.t('button_cancel'), style: 'cancel' },

                ]);
            } else {
                this.dismissModal();
            }
        };

        submitActivities = () => {
            activitiesHelper.submitActivities(
                REQUEST_ID,
                this.state.pendingActivities,
                this.state.changedActivities,
                this.state.deletedActivities,
                this.props.onlyMeFilter,
                this.props.customizePost,
                false,
                this.changeOrDeleteActivities,
                this.trackActivity,
                this.props.navigation
            );
        };

        changeOrDeleteActivities = (id, ids, hasToast) => {
            this.props.actions.deleteActivityLogs(id, ids, hasToast);
        };

        trackActivity = (id, pendingActivities, filterId, text, image, isSingleChallenge) => {
            this.props.actions.trackActivity(id, pendingActivities, filterId, text, image, isSingleChallenge);
        };

        deleteActivityData = activity => {
            this.props.actions.deleteActivityLog(activity.activityLogId);
        };

        removePendingActivity = activity => {
            const { pendingActivities, changedActivities } = this.state;
            this.setState(
                activitiesHelper.removePendingActivity(activity, pendingActivities, changedActivities),
                this.toggleNavigatorButton
            );
        };

        updateActivityData = activity => {
            const { pendingActivities, changedActivities, deletedActivities } = this.state;
            const { allLogs } = this.props;
            this.setState(
                activitiesHelper.updateActivityData(activity, allLogs, pendingActivities, changedActivities, deletedActivities),
                this.toggleNavigatorButton
            );
        };

        getActivityTrackingModalProps = (activity, isPending = false) => ({
            activity: { ...activity, date: activity.date || this.state.selectedDate },
            callback: this.updateActivityData,
            deleteActivityData: isPending ? this.removePendingActivity : this.deleteActivityData,
        });

        getTrackActivitiesListProps = () => {
            const { selectedDate, pendingActivities, changedActivities, deletedActivities } = this.state;
            return {
                title: this.props.i18n.t('tracking_for_date',
                    { date: moment(selectedDate).format(DATE_FORMATS.monthFullDay) }),
                activities: this.props.activities,
                fromSingleActivityGoal: true,
                pendingActivities: this.getActivitiesForDate(pendingActivities, selectedDate),
                changedActivities: this.getActivitiesForDate(changedActivities, selectedDate),
                deletedActivities: this.getActivitiesForDate(deletedActivities, selectedDate),
                selectedDate,
                searchKey: ACTIVITIES_SEARCH_KEYS
            };
        };

        getFirstActivityTrackModalProps = () => ({
            title: this.props.i18n.t('activityTracking.activityTracking'),
            buttonTitle: this.props.i18n.t('got_it'),
            iconName: INFO_ICON,
            iconBackgroundColor: baseColors.danger,
        });

        updateActivitiesCallback = (pendingActivitiesSubmit, changedActivitiesSubmit, deletedActivitiesSubmit, callback) => {
            const state = {};
            _.assign(state, {
                deletedActivities: [
                    ...deletedActivitiesSubmit,
                    ..._.filter(this.state.deletedActivities, activity => activity.date !== this.state.selectedDate)
                ],
                activityLogs: _.filter(this.state.activityLogs,
                    ac => !_.find(deletedActivitiesSubmit, da => da.activityLogId === ac.activityLogId)),
                pendingActivities: [
                    ...pendingActivitiesSubmit,
                    ..._.filter(this.state.pendingActivities, activity => activity.date !== this.state.selectedDate)
                ],
                changedActivities: [
                    ...changedActivitiesSubmit,
                    ..._.filter(this.state.changedActivities, activity => activity.date !== this.state.selectedDate)
                ],
                animatedListItems: _.differenceBy(pendingActivitiesSubmit, this.state.pendingActivitiesData, 'activityUnitId')
            });

            this.setState(state, this.toggleNavigatorButton);
            if (!this.props.isShownFirstActivityTrackModal) {
                this.props.actions.showFirstActivityTrackModal();
                callback();
            } else {
                this.dismissModalSingle();
            }
        };

        getActivitiesForDate = (activities, date) => _.filter(activities, ac => ac.date === date);

        onPressCalendarDate = date => this.setState({ selectedDate: moment(date).format(DATE_FORMATS.full) });

        onSwitchMonths = index => {
            this.setState({ focusedMonth: moment().subtract(DEFAULT_MONTH_INDEX-index, 'month').format(DATE_FORMATS.monthFull) });
            //note we want to grab activity logs for one month before, and one month after
            const startDate = moment().subtract(4 - index <= 3 ? 4 - index : 3, 'month').startOf('month').format(DATE_FORMATS.full);
            const endDate = moment().subtract(2 - index >= 0 ? 2 - index : 0, 'month').endOf('month').format(DATE_FORMATS.full);
            this.props.actions.getActivityLogsExtended(startDate, endDate);
        };

        isAnimated = activity =>
            _.findIndex(this.state.animatedListItems, item => _.get(activity, 'activityUnitId') === _.get(item, 'activityUnitId')) !== -1;

        dismissModal = () => _.has(this, 'wrapped.dismissModal') && this.wrapped.dismissModal();

        dismissModalSingle = () => _.has(this, 'wrapped.dismissModalSingle') && this.wrapped.dismissModalSingle();

        toggleNavigatorButton = () => {
            _.has(this, 'wrapped.toggleNavigatorButton') && this.wrapped.toggleNavigatorButton();
        };

        onHideActivityInformationText = () => {
            this.props.actions.hideActivityInformationText();
        };

        saveRef = ref => (this.wrapped = ref);

        get logsWithoutDeleted() {
            return _.differenceBy(this.props.allLogs, this.state.deletedActivities, 'activityLogId');
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    ref={this.saveRef}
                    logsWithoutDeleted={this.logsWithoutDeleted}
                    calendarData={this.calendarData}
                    isChangesPresent={this.isChangesPresent}
                    exitScreen={this.exitScreen}
                    submitActivities={this.submitActivities}
                    deleteActivityData={this.deleteActivityData}
                    removePendingActivity={this.removePendingActivity}
                    getActivityTrackingModalProps={this.getActivityTrackingModalProps}
                    getTrackActivitiesListProps={this.getTrackActivitiesListProps}
                    onPressCalendarDate={this.onPressCalendarDate}
                    onSwitchMonths={this.onSwitchMonths}
                    isAnimated={this.isAnimated}
                    activityLogs={this.state.activityLogs}
                    deletedActivities={this.state.deletedActivities}
                    pendingActivities={this.state.pendingActivities}
                    updateActivitiesCallback={this.updateActivitiesCallback}
                    getFirstActivityTrackModalProps={this.getFirstActivityTrackModalProps}
                    dotsInfo={this.dotsInfo}
                    onHideActivityInformationText={this.onHideActivityInformationText}
                    focusedMonth={this.state.focusedMonth}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const routeParams = _.get(ownProps, 'route.params');
        return {
            activityLogs: selectors.getPreparedActivityLogsForWeekForAllUnits(state),
            allLogs: selectors.getPreparedActivityLogsForAllUnits(state),
            activities: selectors.getVisibleActivityUnits(state),
            isLoading: selectors.isTracking(state, REQUEST_ID) || selectors.isDeletingActivityLogs(state, REQUEST_ID),
            error: selectors.getTrackError(state, REQUEST_ID) || selectors.getDeletingActivityLogsError(state, REQUEST_ID),
            onlyMeFilter: _.get(coreSelectors.getOnlyMeFilter(state), 'filterId'),
            isShownFirstActivityTrackModal: selectors.isShownFirstActivityTrackModal(state),
            isHiddenActivityInformationText: selectors.isHiddenActivityInformationText(state),
            language: parsers.convertLangString(coreSelectors.getCurrentUserLanguage(state)),
            ...(routeParams || {})
        };
    };

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(timeout(ActivityTrackingCalendarBase)));
}

const DOT_SIZE = 4;

export const styles = {
    mainContainer: {
        backgroundColor: baseColors.white
    },
    header: {
        ...appFonts.smMedium,
        paddingLeft: spacing.s4,
        paddingTop: spacing.s1,
        paddingBottom: spacing.s1,
        backgroundColor: baseColors.grey90
    },
    subtitle: {
        ...appFonts.smRegular,
        color: baseColors.grey40
    },
    calendarWrapper: {
        // paddingTop: Platform.OS === "android" ? spacing.s3 : 0,
    },
    placeholder: {
        color: baseColors.grey40,
        textAlign: 'center',
        paddingTop: spacing.s3,
        marginHorizontal: spacing.s3,
        ...appFonts.lgBold
    },
    noActivities: {
        borderTopColor: baseColors.grey85,
        borderTopWidth: spacing.s3,
    },
    activityListSubtitle: {
        paddingTop: spacing.s1,
        paddingBottom: spacing.s1,
    },
    mainSubtitle: {
        marginBottom: spacing.s1
    },
    dot: {
        width: DOT_SIZE,
        height: DOT_SIZE,
        borderRadius: DOT_SIZE / 2,
        margin: 'auto'
    },
    calendarMonth: {
        ...appFonts.lgBold,
        color: baseColors.secondary,
        textAlign: 'center'
    },
    dotContainer: {
        marginLeft: spacing.s3,
        marginRight: spacing.s3,
        marginBottom: spacing.s3
    },
    dotContainerItem: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        display: 'flex'
    },
    dotContainerText: {
        ...appFonts.smRegular,
        color: baseColors.grey40,
        marginLeft: spacing.s0
    },
    dotTracked: {
        height: DOT_SIZE,
        width: DOT_SIZE,
        backgroundColor: baseColors.grey50,
        borderRadius: spacing.s1
    },
    dotPending: {
        height: DOT_SIZE,
        width: DOT_SIZE,
        backgroundColor: baseColors.secondary,
        borderRadius: spacing.s1
    },
    trackedInformationText: {
        marginBottom: spacing.s3
    },
    firstActivityModalText: {
        ...appFonts.mdRegular
    },
    firstActivityModalBold: {
        ...appFonts.mdMedium
    },
};

