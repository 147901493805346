import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { spacing, appFonts } from '../../../../styles';
import { translate, selectors as coreSelectors } from '../../../core';
import { getPromotedContentCount, getContentTags, getRecommendedContentCount, getRecommendedContentCarousel, getPromotedContentCarousel } from '../../selectors';
import { CATEGORIES } from '../../constants';
import * as contentActions from '../../actions';
import * as contentConstants from '../../constants';

const MAX_COUNT = 20;

export default function WithContentOnFeedsPromotedBase(WrappedComponent) {
    class ContentOnFeedsPromotedBase extends PureComponent {
        static propTypes = {
            isLoading: PropTypes.bool,
            i18n: PropTypes.object.isRequired,
            items: PropTypes.array.isRequired,
            tags: PropTypes.array.isRequired,
            isLiveBetter: PropTypes.bool.isRequired,
        };

        static defaultProps = {
            isLoading: false
        };

        constructor(props) {
            super(props);
            this.loadContentData();
        }

        get title() {
            return this.props.i18n.t('recommendedContentTitle');
        }

        get showTagFilterButtons() {
            const { isLiveBetter, tags } = this.props;
            return !!(!isLiveBetter && tags.length);
        }

        loadContentData = () => {
            const { actions, isLiveBetter } = this.props;

            if (!isLiveBetter) {
                actions.updateRecommendedContent();
            } else {
                actions.getContentAndShuffle(contentConstants.CATEGORIES.ALL,
                    { filters: contentConstants.TYPES.RECOMMENDED }, true, contentConstants.TYPES.RECOMMENDED);
                actions.getContent(contentConstants.CATEGORIES.ALL,
                    { filters: contentConstants.TYPES.PROMOTED }, true, contentConstants.TYPES.PROMOTED);

                actions.getContent(contentConstants.CATEGORIES.ALL, { maxCount: MAX_COUNT }, true);
            }
            actions.getContentTags();
        };

        render() {
            if (!this.props.items || !this.props.items.length || this.props.isLoading) return null;
            return (
                <WrappedComponent
                    {...this.props}
                    title={this.title}
                    showTagFilterButtons={this.showTagFilterButtons}
                    loadContentData={this.loadContentData}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        const isLiveBetter = coreSelectors.isLiveBetter(state);
        let items;
        let count;
        if (isLiveBetter) {
            items = getPromotedContentCarousel(state); //getPromotedWithRecentContent(state);
            count = getPromotedContentCount(state)[CATEGORIES.ALL];
        } else {
            items = getRecommendedContentCarousel(state);
            count = getRecommendedContentCount(state);
        }
        return {
            items,
            count,
            tags: getContentTags(state),
            isLiveBetter
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(contentActions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(ContentOnFeedsPromotedBase));
}

export const styles = {
    carouselTitle: {
        ...appFonts.mdBold
    },
    buttonContainer: {
        marginLeft: spacing.s3,
        marginRight: spacing.s3,
    },
    viewAllButton: {
        ...appFonts.lgRegular
    }
};