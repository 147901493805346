import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';

const ViewAdditional = props => (
    <Button type="text" onPress={props.onPress} size="small">
        {props.text} {props.number ? props.number : ''}
    </Button>
);

ViewAdditional.propTypes = {
    onPress: PropTypes.func,
    text: PropTypes.string.isRequired,
    number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

ViewAdditional.defaultProps = {
    onPress: undefined,
    number: undefined,
};

export default ViewAdditional;