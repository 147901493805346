import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';
import WithGoalProgressInfoBase, { styles as baseStyles } from './GoalProgressInfoBase';
import { CHALLENGE_TYPES } from '../../constants';
import { components as Core } from '../../../core';
import { layoutStyle } from '../../../../styles';
import arrowIcon from '../../../../image/arrowIcon3x.png';

class GoalProgressInfo extends PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        progressType: PropTypes.string,
        getCompetitionPointsString: PropTypes.func.isRequired,
        isUpdating: PropTypes.bool.isRequired,
        periodDurationString: PropTypes.string.isRequired,
        pointsRowTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
        competitionScoreTitle: PropTypes.string.isRequired,
        getPointsUnit: PropTypes.func.isRequired,
        isTotalTeamChallenge: PropTypes.bool.isRequired,
        isTotalRightStats: PropTypes.bool,
        averageTitle: PropTypes.string.isRequired,
        pointsPeriodValue: PropTypes.string.isRequired,
        goalPeriodTitle: PropTypes.string.isRequired,
        isRightStats: PropTypes.bool
    };

    static defaultProps = {
        progressType: CHALLENGE_TYPES.goal,
        isTotalRightStats: false,
        isRightStats: false
    };

    renderGoalInfo = () => {
        const { isUpdating, periodDurationString, pointsRowTitle, isTotalTeamChallenge, isTotalRightStats, pointsRowTitleText, getGoalPointsString, goalPeriodTitle, averageTitle, pointsPeriodValue, isRightStats } = this.props;
        return (
            <>
                <p className={css(styles.goalPeriodRowTitleText)}>{goalPeriodTitle}</p>
                {isUpdating ? this.renderUpdatingText() : (
                    <>
                        {periodDurationString !== pointsRowTitle && (
                            <div className={css(layoutStyle.flexRow, styles.statsWrap, !isRightStats ? styles.statsMR : null, layoutStyle.flexWrap)}>
                                {!isTotalTeamChallenge || !isTotalRightStats ? (
                                    <>
                                        <p className={css(styles.pointsRowTitleText)}>
                                            {pointsRowTitleText}
                                        </p>
                                        <p className={css(styles.pointsPeriodValue)}>
                                            {pointsPeriodValue}
                                        </p>
                                    </>
                                ) : (
                                    <>
                                        <p className={css(styles.pointsRowTitleText)}>
                                            {getGoalPointsString()}
                                        </p>
                                        <p className={css(styles.pointsPeriodValue)}>
                                            {averageTitle}
                                        </p>
                                    </>
                                )}
                            </div>

                        )}
                    </>
                )}
            </>
        );
    };

    renderCompetitionPointsString = () => {
        const { isUpdating, competitionScoreTitle, getCompetitionPointsString, getPointsUnit } = this.props;

        if (isUpdating) {
            return this.renderUpdatingText();
        }

        return (
            <div className={css(layoutStyle.flexColumn, layoutStyle.flexAlignCenter)}>
                <Core.Image
                    src={arrowIcon}
                    className={css(styles.arrowIcon)}
                />
                <p className={css(styles.goalPeriodRowTitleText)}>{competitionScoreTitle}</p>
                <div className={css(layoutStyle.flexJustifyCenter, layoutStyle.flexRow, styles.statsWrap)}>
                    <p className={css(styles.pointsRowTitleText)}>{getCompetitionPointsString()}</p>
                    <p className={css(styles.pointsUnit)}>{ getPointsUnit() }</p>
                </div>
            </div>
        );
    };

    renderUpdatingText = () => (
        <Core.Animated.Flash duration={3000} forever={true}>
            <p className={css(styles.updatingText)}>{this.props.i18n.t('updating')}</p>
        </Core.Animated.Flash>
    );

    render() {
        const { progressType } = this.props;

        if (progressType !== CHALLENGE_TYPES.goal && progressType !== CHALLENGE_TYPES.competition) return null;

        return (
            <div>
                {progressType === CHALLENGE_TYPES.goal ? this.renderGoalInfo() : this.renderCompetitionPointsString()}
            </div>
        );
    }
}

export default WithGoalProgressInfoBase(GoalProgressInfo);

const styles = StyleSheet.create({
    ...baseStyles
});
