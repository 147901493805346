import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { appFonts, baseColors, spacing } from '../../../../styles';
import { Alert, translate, selectors as coreSelectors, MAX_COUNT, Storage, Platform, PLATFORMS, ENTITIES_ACTIVE_TAB } from '../../../core';
import { constants as challengeConstants, actions as challengeActions } from '../../../challenges';
import * as rewardsActions from '../../actions';
import * as selectors from '../../selectors';

export const HELP_ICON_MARGIN_LEFT = 2;
export const HELP_ICON_SIZE = 14;
export const SCROLL_THROTTLE = 100;

export default function WithRewardsDetailsBase(WrappedComponent) {
    class RewardsBalanceBase extends Component {
        static propTypes = {
            rewards: PropTypes.object,
            actions: PropTypes.object,
            achievements: PropTypes.array,
            transactions: PropTypes.array,
            transactionsAmount: PropTypes.number,
            orders: PropTypes.array,
            ordersAmount: PropTypes.number,
            isLoadingAchievements: PropTypes.bool,
            isLoadingTransactions: PropTypes.bool,
            i18n: PropTypes.object.isRequired,
            fullProgramName: PropTypes.string,
            rewardsCurrencyName: PropTypes.string.isRequired,
            programName: PropTypes.string,
            isLoadingOrders: PropTypes.bool,
            currentUser: PropTypes.object.isRequired,
            isLoadingEarnPartners: PropTypes.bool,
            isEarnPartnersEnabled: PropTypes.number.isRequired,
            activeTab: PropTypes.object.isRequired
        };

        static defaultProps = {
            rewards: {},
            fullProgramName: '',
            programName: '',
            transactions: [],
            ordersAmount: 0,
            achievements: [],
            transactionsAmount: 0,
            orders: [],
            isLoadingAchievements: false,
            isLoadingTransactions: false,
            isLoadingOrders: false,
            actions: {},
            isLoadingEarnPartners: false,
        };

        constructor(props) {
            super(props);
            this.tabs = {
                TRANSACTION_LOG: {
                    id: 'transactions',
                    label: props.i18n.t('transactionLog'),
                    emptyMessage: props.i18n.t('noTransactionsMessage')
                },
                ORDER_HISTORY: {
                    id: 'orders',
                    label: props.i18n.t('orderHistory'),
                    emptyMessage: props.i18n.t('noRedemptionsMessage')
                }
            };
            this.scrollableTabs = [this.tabs.TRANSACTION_LOG, this.tabs.ORDER_HISTORY];
            this.state = {
                activeTab: props.activeTab ? this.tabs[props.activeTab] : this.tabs.TRANSACTION_LOG
            };
            props.actions.getAchievements();
            props.actions.getRewardsTransactions();
            props.actions.getRewardsOrders();
            props.actions.getRewards();
            // Enable following call once backend properly supports Bonus API call
            props.actions.getChallengesList({ filter: challengeConstants.DISPLAY_TYPES.BONUS, maxCount: MAX_COUNT, start: 0 }, true);
        }

        async componentDidMount() {
            if (Platform.OS === PLATFORMS.web) {
                const activeTab = await Storage.getItem(ENTITIES_ACTIVE_TAB.REWARDS_DETAILS);
                if (activeTab) {
                    this.setState({ activeTab });
                }
            }
        }

        onTabPress = activeTab => {
            this.setState(() => ({ activeTab }));

            if (Platform.OS === PLATFORMS.web) {
                Storage.setItem(ENTITIES_ACTIVE_TAB.REWARDS_DETAILS, activeTab);
            }
        };

        onPressYTD = () => {
            Alert.alert(
                this.props.i18n.t('pointMissedYearToDate', { rewardCurrencyName: this.props.rewardsCurrencyName }),
                this.props.i18n.t('pointMissedYearToDateDescription', { rewardCurrencyName: this.props.rewardsCurrencyName }),
                [{ text: this.props.i18n.t('button_dismiss') }]
            );
        };

        get numRedeemable() {
            return _.get(this.props.rewards, 'numRedeemable', 0);
        }

        get earnPotential() {
            return _.get(this.props.rewards, 'earnPotential', 0);
        }

        get numRemaining() {
            return _.get(this.props.rewards, 'numRemaining', 0);
        }

        get headerSubtitle() {
            return _.startCase(this.props.i18n.t('remaining'));
        }

        get userBalance() {
            return `${this.numRedeemable} ${this.props.rewardsCurrencyName}`;
        }

        get numMissed() {
            return _.get(this.props.rewards, 'numMissed', 0);
        }

        get isLoading() {
            return this.props.isLoadingAchievements || this.props.isLoadingTransactions || this.props.isLoadingOrders;
        }

        get items() {
            const propListName = this.state.activeTab.id;
            return this.props[propListName];
        }

        loadMoreItems = () => {
            if (this.state.activeTab.id === this.tabs.TRANSACTION_LOG.id && !this.props.isLoadingTransactions &&
                this.props.transactions.length < this.props.transactionsAmount) {
                this.props.actions.getRewardsTransactions({ offset: this.props.transactions.length }, false);
            } else if (this.state.activeTab.id === this.tabs.ORDER_HISTORY.id && !this.props.isLoadingOrders &&
                this.props.orders.length < this.props.ordersAmount) {
                this.props.actions.getRewardsOrders({ offset: this.props.orders.length }, false);
            }
        };

        get title() {
            return this.props.i18n.t('balanceHistory');
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    tabs={this.tabs}
                    activeTab={this.state.activeTab}
                    scrollableTabs={this.scrollableTabs}
                    userBalance={this.userBalance}
                    rewardName={this.rewardName}
                    programName={this.programName}
                    numRedeemable={this.numRedeemable}
                    earnPotential={this.earnPotential}
                    numRemaining={this.numRemaining}
                    headerSubtitle={this.headerSubtitle}
                    loadMoreItems={this.loadMoreItems}
                    onTabPress={this.onTabPress}
                    onPressYTD={this.onPressYTD}
                    items={this.items}
                    isLoading={this.isLoading}
                    numMissed={this.numMissed}
                    title={this.title}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        const routeParams = _.get(ownProps, 'route.params');
        const activeTab = _.get(routeParams, 'activeTab', undefined);
        return {
            activeTab,
            rewards: selectors.getRewards(state),
            transactions: selectors.getTransactions(state),
            transactionsAmount: selectors.getTransactionsAmount(state),
            orders: selectors.getOrders(state),
            ordersAmount: selectors.getOrdersAmount(state),
            isLoadingAchievements: selectors.isLoadingAchievements(state),
            isLoadingTransactions: selectors.isLoadingTransactions(state),
            isLoadingOrders: selectors.isLoadingOrders(state),
            achievements: selectors.getAllRewardsAcheivementsSorted(state),
            rewardsCurrencyName: selectors.externalRewardName(state),
            isLoadingEarnPartners: selectors.isLoadingEarnPartners(state),
            currentUser: coreSelectors.getCurrentUser(state),
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators({ ...rewardsActions, ...challengeActions }, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(RewardsBalanceBase));
}

export const styles = {
    headerContainer: {
        flex: 0,
        marginLeft: spacing.s3,
        marginRight: spacing.s3,
    },
    headerMain: {
        marginTop: spacing.s0,
        marginLeft: 0
    },
    headerTop: {
        flex: 0,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    headerTopPoints: {
        alignItems: 'flex-end'
    },
    headerTopNumPoints: {
        ...appFonts.mdBold
    },
    headerTopBalance: {
        ...appFonts.xsRegular,
        color: baseColors.grey40
    },
    subHeader: {
        flex: 1,
        flexDirection: 'row',
        marginTop: spacing.s3,
        marginBottom: spacing.s1
    },
    subHeaderItem: {
        flex: 1,
        alignItems: 'center',
        padding: 0
    },
    subHeaderTitle: {
        ...appFonts.xsRegular,
        color: baseColors.grey40
    },
    subHeaderValue: {
        ...appFonts.smBold,
        color: baseColors.black
    },
    subHeaderMissed: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    tabsContainer: {
        marginBottom: spacing.s3
    },
    itemContainer: {
        marginLeft: spacing.s3,
        paddingRight: spacing.s3
    },
    emptyList: {
        marginLeft: spacing.s3,
        marginRight: spacing.s3,
    },
};
