import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { appFonts, baseColors, spacing, fontSize } from '../../../../styles';
import { isTeamChallenge } from '../../selectors';
import { translate, selectors as coreSelectors, components as Core, parsers, color } from '../../../core';

const LEADERBOARD_CURRENT_USER_NAME = 'You';
export const USER_ICON_SIZE = 14;

export default function WithLeaderboardItemBase(WrappedComponent) {
    class LeaderboardItemBase extends PureComponent {
        static propTypes = {
            leaderboard: PropTypes.object.isRequired,
            user: PropTypes.object.isRequired,
            title: PropTypes.string,
            isChallenge: PropTypes.bool,
            itemIndex: PropTypes.number,
            customPointsUnit: PropTypes.string.isRequired,
            leaderboardItemsCount: PropTypes.number,
            isCurrentUser: PropTypes.bool,
        };

        static defaultProps = {
            title: undefined,
            isChallenge: false,
            itemIndex: null,
            leaderboardItemsCount: null,
            isCurrentUser: null,
        };


        get rankFont() {
            const length = this.props.leaderboard.rank.toString().length;
            if (length === 3) return styles.xxSmallText;
            if (length > 3) return styles.xxxSmallText;
            return styles.mainText;
        }

        get textFontWeight() {
            return (this.isViewer || this.isGoldenItem) ? styles.fwBold: null;
        }

        get itemBg() {
            const { isChallenge, itemIndex, leaderboardItemsCount } = this.props;
            if (itemIndex !== null) {
                const isIndexOdd = itemIndex % 2 !== 0;
                if (isChallenge) {
                    const hasBg = leaderboardItemsCount % 2 !== 0 ? isIndexOdd : !isIndexOdd;
                    return (hasBg && !this.isGoldenItem) ? styles.itemDarkBg : null;
                }
                return isIndexOdd ? styles.itemDarkBg : null;
            }
            return null;
        }

        get points() {
            return this.props.isChallenge ? this.props.leaderboard.score :
                `${this.props.leaderboard.score} ${this.props.customPointsUnit}`;
        }

        get name() {
            const { leaderboard, user, i18n } = this.props;
            if (this.viewerNotTeam) return i18n.t('you');
            if (leaderboard.name === LEADERBOARD_CURRENT_USER_NAME) {
                return user ? `${user.firstNameDisplay} ${user.lastNameDisplay}` : '';
            }
            return leaderboard.name;
        }

        get viewerNotTeam() {
            return !this.props.isTeamChallenge && this.isViewer;
        }

        get userInfo() {
            const text = this.props.leaderboard.detailsDisplay;
            return parsers.generateStringWithValuesStyled(text.template, text.values, {}, null, Core.Text, true);
        }

        get isViewer() {
            const { isCurrentUser } = this.props;
            return isCurrentUser !== null ? isCurrentUser : this.isCurrentUser;
        }

        get isCurrentUser() {
            const { leaderboard, user } = this.props;
            return parseInt(leaderboard.itemEntityId) === user.userId;
        }

        get isGoldenItem() {
            const { leaderboardItemsCount, itemIndex } = this.props;
            const count = leaderboardItemsCount;
            const case1 = (count === 2 || count === 3) && itemIndex === 0;
            const case2 = count > 3 && itemIndex < 3;
            return case1 || case2;
        }

        get showCrownIcon() {
            return this.isGoldenItem && this.props.itemIndex === 0;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    rankFont={this.rankFont}
                    points={this.points}
                    name={this.name}
                    userInfo={this.userInfo}
                    itemBg={this.itemBg}
                    isViewer={this.isViewer}
                    isGoldenItem={this.isGoldenItem}
                    viewerNotTeam={this.viewerNotTeam}
                    showCrownIcon={this.showCrownIcon}
                    textFontWeight={this.textFontWeight}
                />
            );
        }
    }


    const mapStateToProps = (state, ownProps) => ({
        user: coreSelectors.getCurrentUser(state),
        customPointsUnit: coreSelectors.getCustomPointsUnit(state),
        isTeamChallenge: isTeamChallenge(state, ownProps.challengeId)
    });

    return connect(mapStateToProps)(translate()(LeaderboardItemBase));
}

const AVATAR_TOP_MARGIN = 2;
const RANK_WIDTH = 24;
const BG_OPACITY = 0.1;
const CROWN_TOP_OFFSET = -10;
const CROWN_LEFT_OFFSET = 48;
const CROWN_WIDTH = 23;
const CROWN_HEIGHT = 14;

export const styles = {
    mainContainer: {
        flex: 1,
        paddingRight: spacing.s3,
        paddingBottom: spacing.s4,
        paddingTop: spacing.s4 - AVATAR_TOP_MARGIN,
        borderBottomWidth: 1,
        borderBottomColor: baseColors.grey80,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    detailsContainer: {
        flex: 1,
        paddingLeft: spacing.s3,
    },
    avatar: {
        paddingTop: 0,
    },
    mainText: {
        ...appFonts.mdRegular,
    },
    xxSmallText: {
        fontSize: fontSize.xxSmall,
    },
    xxxSmallText: {
        fontSize: spacing.s1,
    },
    primaryText: {
        color: baseColors.primary
    },
    pointsText: {
        color: baseColors.black,
        paddingLeft: spacing.s3,
    },
    userInfoText: {
        color: baseColors.grey40,
        ...appFonts.xsRegular,
        marginTop: spacing.s0
    },
    rank: {
        width: RANK_WIDTH,
        marginRight: spacing.s1,
        textAlign: 'center',
        fontWeight: 'bold',
    },
    detailsBody: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    listItem: {
        borderBottomWidth: 0,
        paddingTop: spacing.s2,
        paddingBottom: spacing.s2,
        paddingLeft: spacing.s1,
        paddingRight: spacing.s3,
    },
    itemDarkBg: {
        backgroundColor: baseColors.grey90,
    },
    userOrUserTeamBg: {
        backgroundColor: color.hexToRgba(baseColors.primary, BG_OPACITY),
    },
    fwBold: {
        fontWeight: 'bold',
    },
    userIcon: {
        marginRight: spacing.s0,
    },
    avatarBorder: {
        borderWidth: 2,
        borderStyle: 'solid',
        borderRadius: spacing.s4,
        borderColor: baseColors.warn,
    },
    viewerBorder: {
        borderColor: baseColors.primary,
    },
    crownIcon: {
        borderRadius: 0,
        width: CROWN_WIDTH,
        height: CROWN_HEIGHT,
        position: 'absolute',
        top: CROWN_TOP_OFFSET,
        left: CROWN_LEFT_OFFSET,
    },
};

