import React, { PureComponent } from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import PropTypes from 'prop-types';
import { baseColors, layoutStyle, spacing } from '../../../../styles';
import { components as Core, CSS_CLASSES } from '../../../core';
import WithPlatformUserItemBase, { styles as baseStyles } from './PlatformUserItemBase';

class PlatformUserItem extends PureComponent {
    static propTypes = {
        userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        user: PropTypes.object.isRequired,
        onUserInvite: PropTypes.func.isRequired,
        onDeleteInvitation: PropTypes.func.isRequired,
        i18n: PropTypes.object.isRequired,
        hasBorder: PropTypes.bool,
        imageUrl: PropTypes.string,
        hasIconRef: PropTypes.bool,
        name: PropTypes.string,
        isSameCompany: PropTypes.bool,
        description: PropTypes.string,
        iconProps: PropTypes.object.isRequired,
        isLoading: PropTypes.bool,
        isDeleting: PropTypes.bool.isRequired,
        isSuggestedUser: PropTypes.bool.isRequired,
        onPressIcon: PropTypes.func.isRequired,
    };

    static defaultProps = {
        hasBorder: true,
        imageUrl: undefined,
        hasIconRef: false,
        name: undefined,
        isSameCompany: false,
        description: undefined,
        isLoading: false
    };

    render() {
        const { name, isSameCompany, description, iconProps, hasBorder, imageUrl,
            userId, hasIconRef, isLoading, isDeleting, onPressIcon } = this.props;
        return (
            <div className={css(styles.container, hasBorder && styles.border, layoutStyle.flex, layoutStyle.listItem)}>
                <Core.Avatar
                    url={imageUrl}
                    name={name}
                    id={userId}
                />
                <div className={css(styles.innerContainer, layoutStyle.flex)}>
                    <div className={css(styles.textContainer)}>
                        <p className={css(styles.name)}>{name}</p>
                        {isSameCompany ? (
                            <div className={css(layoutStyle.flexRow, layoutStyle.alignCenter, layoutStyle.flex1)}>
                                <Core.Icon
                                    type="fa"
                                    name="briefcase"
                                    size={spacing.s3}
                                    color={baseColors.secondary}
                                    className={css(styles.icon)}
                                />
                                <p className={css(styles.description)}>{description}</p>
                            </div>
                        ) : <p className={css(styles.description)}>{description}</p>}
                    </div>
                    {isLoading || isDeleting
                        ? <Core.ListLoading key="loading"/> : (
                            <Core.IconButton
                                className={hasIconRef ? CSS_CLASSES.addedFriendsTarget : null}
                                onClick={onPressIcon}
                                size={spacing.s5}
                                {...iconProps}
                                type="fa" />
                        )}
                </div>
            </div>
        );
    }
}

const styles = StyleSheet.create({
    ...baseStyles
});

export default WithPlatformUserItemBase(PlatformUserItem);
