import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectors as coreSelectors, translate } from '../../../core';
import { isLoadingPlatformInvitations, getAllPlatformInvitations } from '../../selectors';
import * as actions from '../../actions';
import { appFonts, baseColors, spacing } from '../../../../styles';

export const CONTAINER_HEIGHT = 180;

export default function WithFindFriendBase(WrappedComponent) {
    class FindFriendBase extends PureComponent {
        static propTypes = {
            onRef: PropTypes.func,
            actions: PropTypes.object,
            isLoading: PropTypes.bool,
            i18n: PropTypes.object.isRequired,
            isFriendsAndFamilyEnabled: PropTypes.bool.isRequired,
            allPlatformInvitations: PropTypes.array.isRequired,
        };

        static defaultProps = {
            onRef: null,
            actions: {},
            isLoading: false
        };

        constructor(props) {
            super(props);
            this.loadData();
        }

        loadData = () => {
            this.props.actions.getPlatformInvitations();
        };

        get isEmptyComponent() {
            return this.props.isLoading ||
                !this.props.isFriendsAndFamilyEnabled ||
                this.props.allPlatformInvitations.length > 0;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    loadData={this.loadData}
                    isEmptyComponent={this.isEmptyComponent}
                />
            );
        }
    }

    function mapStateToProps(state) {
        const isFriendsAndFamilyEnabled = coreSelectors.isFriendsAndFamilyEnabled(state);
        return {
            isFriendsAndFamilyEnabled,
            allPlatformInvitations: isFriendsAndFamilyEnabled ? getAllPlatformInvitations(state) : [],
            isLoading: isLoadingPlatformInvitations(state),
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(FindFriendBase));
}

export const styles = {
    mainContainer: {
        backgroundColor: baseColors.grey85
    },
    container: {
        minHeight: CONTAINER_HEIGHT,
        borderRadius: spacing.s1,
        marginLeft: spacing.s3,
        marginRight: spacing.s3,
        marginBottom: spacing.s3,
        backgroundColor: baseColors.white
    },
    elementsContainer: {
        padding: spacing.s3,
        borderTopLeftRadius: spacing.s1,
        borderTopRightRadius: spacing.s1,
        backgroundColor: baseColors.white
    },
    disclaimerContainer: {
        borderRadius: spacing.s1
    },
    iconContainer: {
        marginBottom: spacing.s2
    },
    description: {
        ...appFonts.mdRegular,
        textAlign: 'center',
        marginBottom: spacing.s5
    },
    innerContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    }
};
