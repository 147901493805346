import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import translate from '../../services/translate';

export default function WithPublicVisibilitySwitchBase(WrappedComponent) {
    class PublicVisibilitySwitchBase extends PureComponent {
        static propTypes = {
            isPublic: PropTypes.number.isRequired,
            entityType: PropTypes.string.isRequired,
            i18n: PropTypes.object.isRequired,
        };

        get content() {
            const { isPublic, entityType, i18n } = this.props;
            const title = i18n.t('publicVisibilitySwitch.label.title', { entityType: _.toLower(i18n.t(entityType)) });
            const subtitle = i18n.t('publicVisibilitySwitch.label.subtitle', { entityType });
            const text = !isPublic ? i18n.t('publicVisibilitySwitch.modalSubtitle.public', { entityType }) : i18n.t('publicVisibilitySwitch.modalSubtitle.private', { entityType });
            const rightLabel = isPublic ? i18n.t('publicVisibilitySwitch.footerButton.keepPublic') : i18n.t('publicVisibilitySwitch.footerButton.keepPrivate');
            const leftLabel = !isPublic ? i18n.t('publicVisibilitySwitch.footerButton.makePublic') : i18n.t('publicVisibilitySwitch.footerButton.makePrivate');

            return {
                title,
                subtitle,
                text,
                leftLabel,
                rightLabel
            };
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    content={this.content}
                />
            );
        }
    }

    return translate()(PublicVisibilitySwitchBase);
}

