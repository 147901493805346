import axios from 'axios';
import { getBaseUrl, constants as coreConstants } from '../core';

export function getCommunities(params) {
    return axios.get(getBaseUrl('groups/communities'), { params });
}

export function getRecommendedCommunities() {
    return axios.get(getBaseUrl('groups/recommended_communities'));
}

export function getRecentCommunities() {
    return axios.get(getBaseUrl('groups/recent_communities'));
}

export function getSingleCommunity(id) {
    return axios.get(getBaseUrl(`groups/communities/${id}`));
}

export function joinCommunity(id) {
    return axios.post(getBaseUrl(`memberships/groups/${id}`), {});
}

export function leaveCommunity(id) {
    return axios.delete(getBaseUrl(`memberships/groups/${id}`), {});
}

export function deleteCommunity(id) {
    return axios.delete(getBaseUrl(`groups/1/${id}`));
}

export function createCommunity(params) {
    return axios.post(getBaseUrl('groups/communities'), params);
}

export function editCommunity(params, id) {
    return axios.put(getBaseUrl(`groups/communities/${id}`), params);
}

export function getCommunityMembers(id, parameters) {
    const params = { start: 0, maxCount: coreConstants.MEMBERS_MAX_COUNT, order: 'firstname-asc', ...parameters };
    return axios.get(getBaseUrl(`memberships/groups/${id}`), { params });
}