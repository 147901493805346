import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { connect } from 'react-redux';
import { appFonts, baseColors, spacing } from '../../../../styles';
import { dateLabelForPeriod, pointsForPeriod } from '../../services/challengePeriodHelper';
import { isPersonal } from '../../services/helper';
import { translate, pointsHelper, selectors as coreSelectors } from '../../../core';
import * as selectors from '../../selectors';
import * as actions from '../../actions';
import { selectors as onboardingSelectors } from '../../../onboarding';

export default function WithGoalActivityHistoryBase(WrappedComponent) {
    class GoalActivityHistoryBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            history: PropTypes.object.isRequired,
            challenge: PropTypes.object.isRequired,
            customPointsUnit: PropTypes.string.isRequired,
            challengeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            restartGoalPeriods: PropTypes.array.isRequired,
            isRestartGoal: PropTypes.bool.isRequired,
            periods: PropTypes.array.isRequired,
        };

        constructor(props) {
            super(props);
            if (!props.challenge) {
                this.props.actions.getChallenge(this.props.challengeId);
            }
        }

        getPeriodPointsEarned = period => {
            const { i18n, challenge, customPointsUnit } = this.props;
            return `${i18n.t('earned')} ${pointsHelper.formatPoints(pointsForPeriod(period, challenge), customPointsUnit)}`;
        };

        getPeriodLabel = period => dateLabelForPeriod(period, this.props.challenge, this.props.isRestartGoal);

        getPeriodPercentage = period => `${period.value || pointsHelper.formatPointsValue(period.totalPercentage)}%`;

        getNoActivitiesLabel = () => {
            const { challenge, i18n } = this.props;
            return i18n.t(isPersonal(challenge) ? 'havent_tracked_goal' : 'havent_tracked_challenge');
        };

        keyExtractor = period => period.index || period.restartGoalPeriodIndex;

        get subheaderTitle() {
            const { isRestartGoal, i18n } = this.props;
            return i18n.t(isRestartGoal ? 'weeklyBreakdown' : 'progressHistory');
        }

        get periods() {
            const { isRestartGoal, periods, restartGoalPeriods } = this.props;
            return isRestartGoal ? restartGoalPeriods : periods;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    getPeriodPointsEarned={this.getPeriodPointsEarned}
                    getNoActivitiesLabel={this.getNoActivitiesLabel}
                    getPeriodPercentage={this.getPeriodPercentage}
                    keyExtractor={this.keyExtractor}
                    getPeriodLabel={this.getPeriodLabel}
                    subheaderTitle={this.subheaderTitle}
                    periods={this.periods}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const challengeId = ownProps.challengeId || _.get(ownProps, 'match.params.challengeId') || _.get(ownProps, 'route.params.challengeId');
        return {
            challengeId,
            challenge: selectors.getChallenge(state, challengeId),
            periods: selectors.getProgressPeriods(state, challengeId),
            customPointsUnit: coreSelectors.getCustomPointsUnit(state),
            isShowingOnboarding: onboardingSelectors.isShowingOnboarding(state),
            restartGoalPeriods: selectors.getRestartGoalPeriods(state, challengeId),
            isRestartGoal: selectors.isRestartGoal(state, challengeId),
        };
    };

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(GoalActivityHistoryBase));
}

export const styles = {
    flexRowValueText: {
        ...appFonts.mdBold //mdBold
    },
    flexRowTitleText: {
        ...appFonts.xsRegular,
        color: baseColors.grey40
    },
    noPeriods: {
        ...appFonts.mdMedium,
    },
    list: {
        marginTop: 0,
        marginLeft: spacing.s3,
        marginRight: spacing.s3,
        borderTopWidth: 0
    },
    icon: {
        marginLeft: spacing.s3
    }
};
