import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

import { components as Core, ROUTES, tracker } from '../../../core';
import WithContentOnFeedsBase, { styles as baseStyles } from './ContentOnFeedsBase';
import CarouselItem from '../CarouselItem';
import { layoutStyle, spacing } from '../../../../styles';
import { TYPES } from '../../constants';

class ContentOnFeeds extends PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
        items: PropTypes.array.isRequired,
        count: PropTypes.number.isRequired,
        i18n: PropTypes.object.isRequired,
        title: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        hasHorizontalMargin: PropTypes.bool,
        isLoading: PropTypes.bool.isRequired,
        hideCoreButton: PropTypes.bool,
        infoText: PropTypes.string,
        headerContainerClassName: PropTypes.string,
        carouselContainerClassName: PropTypes.string,
        itemsCount: PropTypes.number,
        cardContainerClassName: PropTypes.string
    };

    static defaultProps = {
        hasHorizontalMargin: false,
        hideCoreButton: false,
        infoText: null,
        headerContainerClassName: null,
        carouselContainerClassName: null,
        itemsCount: null,
        cardContainerClassName: null
    };

    goToAll = () => {
        tracker.logEvent('library', { event: 'goToAll', from: 'feeds' });
        this.props.history.push(ROUTES.content());
    };

    goToRecommended = () => {
        const { type, i18n } = this.props;
        tracker.logEvent('library', { event: 'goToContent', from: 'feeds', type });
        this.props.history.push(ROUTES.content(), { selectedTab: { id: TYPES.RECOMMENDED, label: i18n.t('forYou') } });
    };

    goToContentItem = id => {
        tracker.logEvent('library', { event: 'goToContentItem', from: 'feeds', id });
    };

    renderCarouselItem = item => {
        if (item !== undefined) {
            return <CarouselItem item={item} callback={this.goToContentItem} key={item.id} />;
        }

        return null;
    };

    render() {
        const { items, isLoading, hasHorizontalMargin, count, title, viewAllText, i18n, hideCoreButton, infoText, headerContainerClassName, carouselContainerClassName, itemsCount, cardContainerClassName, titleSize } = this.props;
        if (!items || !items.length || isLoading) return null;
        return (
            <Core.Card className={[!hasHorizontalMargin ? css(styles.card) : null, cardContainerClassName]}>
                <div className={css(layoutStyle.db, layoutStyle.textLeft)}>
                    <Core.Carousel
                        type="multiple"
                        count={count}
                        title={title}
                        titleSize={titleSize}
                        viewAllPress={this.goToRecommended}
                        isBorderShown={false}
                        itemsLength={items.length}
                        viewAllText={viewAllText}
                        infoText={infoText}
                        headerContainerClassName={headerContainerClassName}
                        carouselContainerClassName={carouselContainerClassName}
                        className={css(styles.carousel)}
                        itemsCount={itemsCount}>
                        {_.map(items, this.renderCarouselItem)}
                    </Core.Carousel>
                    {hideCoreButton ? null : (
                        <div className={css(styles.buttonContainer)}>
                            <Core.Button
                                type="outlined"
                                className={css(layoutStyle.fw)}
                                onPress={this.goToAll}>
                                {i18n.t('openLibrary')}
                            </Core.Button>
                        </div>
                    )}
                </div>
            </Core.Card>
        );
    }
}

export default withRouter(WithContentOnFeedsBase(ContentOnFeeds));

const styles = StyleSheet.create({
    ...baseStyles,
    buttonContainer: {
        ...baseStyles.buttonContainer,
        display: 'flex',
        justifyContent: 'center',
        marginBottom: spacing.s3
    },
    card: {
        marginLeft: spacing.s1,
        marginRight: spacing.s1,
    },
    carousel: {
        width: 'auto'
    }
});