import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import moment from 'moment';
import * as eventsActions from '../../actions';
import * as selectors from '../../selectors';
import { eventStartEndString } from '../../services/eventHelper';
import { baseColors, spacing } from '../../../../styles';
import { components as Core, constants as coreConstants, entitiesHelper,
    selectors as coreSelectors, translate, Modal, Platform, PLATFORMS } from '../../../core';

const TAB_NAMES = {
    ABOUT: 'about',
    STREAM: 'stream'
};

const OPTIONS = {
    INVITE: { title: 'inviteToEvent', id: 'onInviteEvent' },
    EDIT: { title: 'edit_event', id: 'onEditEvent' },
    DELETE: { title: 'delete_event', id: 'onDeleteEvent' },
    LEAVE: { title: 'buttonLeaveEvent', id: 'onLeaveEvent' }
};

export default function WithEventDetailsBase(WrappedComponent) {
    class EventDetailsBase extends PureComponent {
        static propTypes = {
            itemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            actions: PropTypes.object,
            type: PropTypes.string,
            event: PropTypes.object.isRequired,
            isLoadingEvent: PropTypes.bool,
            user: PropTypes.object,
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            getEventError: PropTypes.object,
            eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            i18n: PropTypes.object.isRequired,
            isUserRole: PropTypes.bool,
        };

        static defaultProps = {
            isLoadingEvent: false,
            itemId: undefined,
            actions: {},
            type: null,
            user: undefined,
            getEventError: undefined,
            isUserRole: true,
        };

        constructor(props) {
            super(props);
            this.wrapped = React.createRef();
            this.fetchEventData();
            this.TABS = {
                ABOUT: { label: props.i18n.t('about'), id: TAB_NAMES.ABOUT },
                STREAM: { label: props.i18n.t('feed'), id: TAB_NAMES.STREAM }
            };
            this.state = {
                selectedTab: this.tabs[0]
            };
        }

        componentDidUpdate(prevProps) {
            if (this.props.getEventError) {
                this.props.actions.clearGetEventError(this.props.eventId);
            }
        }

        fetchEventData() {
            this.props.actions.getEventById(this.props.id);
        }

        get contentDeletedText() {
            return this.props.i18n.t('contentIsDeletedOrInappropriate');
        }

        eventTime = (dateFormat, isEndTime) => moment(isEndTime ? _.get(this.props, 'event.eventEndDateTime') : _.get(this.props, 'event.eventDateTime')).format(coreConstants.DATE_FORMATS[dateFormat]);

        get tabs() {
            return this.isMember ? [this.TABS.ABOUT, this.TABS.STREAM] : [this.TABS.ABOUT];
        }
        get owner() {
            return _.get(this.props.event, 'owner', {});
        }

        get eventType() {
            const { i18n, event } = this.props;
            return _.get(event, 'isPrivate') ? i18n.t('private') : i18n.t('public');
        }

        selectTab = selectedTab => {
            this.setState({ selectedTab });
        };

        get isAboutTabSelected() {
            return this.state.selectedTab.id === this.TABS.ABOUT.id;
        }

        get isStreamTabSelected() {
            return this.state.selectedTab.id === this.TABS.STREAM.id;
        }

        get isUserOwner() {
            return this.props.id && this.props.event ? _.get(this.props, 'user.userId') === _.get(this.props.event, 'owner.userId') : false;
        }

        get isMember() {
            return !!_.get(this.props.event, 'isMember');
        }

        get destructiveButtonIndex() {
            return _.findIndex(this.options, { id: OPTIONS.DELETE.id });
        }

        get isOwner() {
            return this.isUserOwner || !this.props.isUserRole;
        }

        get isAdminCreated() {
            return !!_.get(this.props.event, 'isCompany');
        }

        get eventStartEndString() {
            return eventStartEndString(this.props.event);
        }

        get options() {
            const { i18n, event } = this.props;
            return [
                ...(this.isMember && !entitiesHelper.isAfterDeadline(_.get(event, 'eventDateTime')) ? [
                    { title: i18n.t(OPTIONS.INVITE.title), id: OPTIONS.INVITE.id }
                ] : []),
                ...(!this.isUserOwner && this.isMember ? [
                    { title: i18n.t(OPTIONS.LEAVE.title), id: OPTIONS.LEAVE.id }
                ] : []),
                ...(this.isOwner && !entitiesHelper.isAfterDeadline(_.get(event, 'eventDateTime')) ?
                    [
                        { title: i18n.t(OPTIONS.EDIT.title), id: OPTIONS.EDIT.id }
                    ] : []),
                ...(this.isOwner ?
                    [
                        { title: i18n.t(OPTIONS.DELETE.title), id: OPTIONS.DELETE.id }
                    ] : []
                )
            ];
        }

        onLeaveEvent = () => {
            const { i18n } = this.props;
            if (Platform.OS === PLATFORMS.web) {
                this.closeInfoModal = Modal.open(
                    Core.InfoModal,
                    {
                        title: i18n.t('areYouSure'),
                        text: i18n.t('leave_event '),
                        isButtonVisible: false,
                        buttons: [
                            { text: i18n.t('buttonLeaveEvent'), onPress: this.leaveEvent, isDangerText: true },
                            { text: i18n.t('button_cancel'), onPress: () => this.closeInfoModal() },
                        ],
                    },
                    { isContainer: true, isNoPadding: true, isMaxWidthLimited: true, fadeTransition: true }
                );
            }
        };

        leaveEvent = () => {
            this.props.actions.leaveEvent(this.props.event.eventId, this.props.user.userId);
            Platform.OS === PLATFORMS.web && this.closeInfoModal();
        };

        deleteEvent = () => {
            this.props.actions.deleteEvent({
                eventId: this.props.event.eventId,
                name: this.props.event.eventName
            });
        };

        onDeleteEvent = () => this.wrapped.current.showDeletionWarning(this.deleteEvent);

        getWarningProps = () => {
            const { i18n } = this.props;
            return {
                isButtonVisible: false,
                title: i18n.t('areYouSure'),
                text: i18n.t('deleveEventPopup'),
                checkboxLabel: i18n.t('deleteCommunityCheckbox'),
            };
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    ref={this.wrapped}
                    eventTime={this.eventTime}
                    owner={this.owner}
                    tabs={this.tabs}
                    leaveEvent={this.leaveEvent}
                    getWarningProps={this.getWarningProps}
                    selectedTab={this.state.selectedTab}
                    selectTab={this.selectTab}
                    eventType={this.eventType}
                    options={this.options}
                    destructiveButtonIndex={this.destructiveButtonIndex}
                    contentDeletedText={this.contentDeletedText}
                    isAboutTabSelected={this.isAboutTabSelected}
                    isStreamTabSelected={this.isStreamTabSelected}
                    onDeleteEvent={this.onDeleteEvent}
                    onLeaveEvent={this.onLeaveEvent}
                    isAdminCreated={this.isAdminCreated}
                    eventStartEndString={this.eventStartEndString}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        const id = ownProps.itemId || (_.get(ownProps, 'match.params.id')) || _.get(ownProps, 'route.params.itemId');
        return {
            user: coreSelectors.getCurrentUser(state),
            event: selectors.getEvent(state, id),
            isLoadingEvent: selectors.isLoadingEvent(state, id),
            getEventError: selectors.getEventError(state, id),
            isUserRole: coreSelectors.isUserRole(state),
            isDeleting: selectors.isDeleting(state),
            id
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(eventsActions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(EventDetailsBase));
}

export const styles = {
    container: {
        flex: 1,
        backgroundColor: baseColors.white
    },
    innerContainer: {
        paddingBottom: spacing.s7
    }
};
