import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { appFonts, baseColors, spacing } from '../../../../../styles';
import { translate, numbers, selectors as coreSelectors, timeout, constants as coreConstants, tracker } from '../../../../core';
import * as selectors from '../../../selectors';
import { selectors as feedsSelectors } from '../../../../feeds';

export default function WithDashboardLevelItemBase(WrappedComponent) {
    class DashboardLevelItemBase extends Component {
        static propTypes = {
            levels: PropTypes.object,
            isRewardsEnabled: PropTypes.bool.isRequired,
            onPress: PropTypes.func.isRequired,
            externalRewardName: PropTypes.string,
            cropDegree: PropTypes.number,
            customPoints: PropTypes.string.isRequired,
            i18n: PropTypes.object.isRequired,
            currentLevel: PropTypes.object.isRequired,
            isScorePage: PropTypes.bool,
            isFirstLoaded: PropTypes.bool,
            setTimeout: PropTypes.func.isRequired,
            loadData: PropTypes.func.isRequired,
            isLoadingDailyMetrics: PropTypes.bool
        };

        static defaultProps = {
            levels: {},
            externalRewardName: undefined,
            cropDegree: 0,
            isFirstLoaded: false,
            isLoadingDailyMetrics: false,
            isScorePage: false
        };

        constructor(props) {
            super(props);
            this.state = { isSkeletonShown: true };
            this.props.setTimeout(() => {
                this.setState({ isSkeletonShown: false });
            }, coreConstants.SKELETON_ANIMATION_DELAY);
        }

        componentDidUpdate(prevProps) {
            if (prevProps.isLoadingDailyMetrics && !this.props.isLoadingDailyMetrics) {
                this.props.loadData();
            }
        }

        get levelStatusText() {
            const { i18n, isRewardsEnabled, currentLevel } = this.props;
            if (this.scoreForPeriod >= this.levelScore && this.levelScore !== 0) {
                return i18n.t('reachedHighestLevel');
            } else if (isRewardsEnabled) {
                return `${currentLevel.name}: ${i18n.t('earn')} 
                    ${_.get(currentLevel, 'externalRewardPoints', 0)} ${this.props.externalRewardName}`;
            }
            return undefined;
        }

        get levelScore() {
            return Number(_.get(this.props.currentLevel, 'score', 0));
        }

        get scoreForPeriod() {
            return _.get(this.props.levels, 'scoreForPeriod', 0);
        }

        get lastLevelMaxScore() {
            const { companyLevels, companyLevelsTotal } = this.props.levels;
            const lastLevel = _.find(companyLevels, ({ level }) => parseInt(level) === companyLevelsTotal);
            return Number(_.get(lastLevel, 'score'));
        }

        get formattedScoreForPeriod() {
            return numbers.numberWithCommas(this.scoreForPeriod);
        }

        get percentFilled() {
            if (this.levelScore === 0) return 0;
            const percent = _.floor((this.scoreForPeriod / this.levelScore) * 100);
            if (percent > 100) {
                return 100;
            } else if (percent < 0) {
                return 0;
            }
            return percent || 0;
        }

        get formattedNeededPoints() {
            return numbers.numberWithCommas(this.levelScore);
        }

        get levelName() {
            return _.get(this.props.currentLevel, 'name', this.props.i18n.t('bronzeLevel'));
        }

        get isTouchable() {
            return !this.props.isScorePage;
        }

        onPress = () => {
            if (this.isTouchable) this.props.onPress(this.isHighestScoreReached);
            tracker.logEvent('Dashboard_Click_Progress');
        };

        get rewardAmount() {
            return _.get(this.props.currentLevel, 'externalRewardPoints', 0);
        }

        get pointsNeededText() {
            return ` /${this.formattedNeededPoints} ${this.props.customPoints}`;
        }

        get isHighestScoreReached() {
            return this.scoreForPeriod >= this.lastLevelMaxScore;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    isTouchable={this.isTouchable}
                    onPress={this.onPress}
                    levelName={this.levelName}
                    percentFilled={this.percentFilled}
                    scoreForPeriod={this.formattedScoreForPeriod}
                    scoreForPeriodNumber={this.scoreForPeriod}
                    isHighestScoreReached={this.isHighestScoreReached}
                    isButtonVisible={this.isTouchable}
                    isSkeletonShown={this.state.isSkeletonShown}
                    rewardAmount={this.rewardAmount}
                    pointsNeededText={this.pointsNeededText}
                />
            );
        }
    }

    function mapStateToProps(state) {
        return {
            levels: selectors.getLevels(state),
            externalRewardName: selectors.getExternalRewardName(state),
            customPoints: coreSelectors.getCustomPointsUnit(state),
            isRewardsEnabled: selectors.isRewardsEnabled(state),
            isLoadingDailyMetrics: feedsSelectors.isLoadingDailyMetrics(state)
        };
    }

    return connect(mapStateToProps)(translate()(timeout(DashboardLevelItemBase)));
}

export const styles = {
    innerLevelContainer: {
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: spacing.s3
    },
    dashboardLevelName: {
        ...appFonts.lgBold,
        color: baseColors.black,
    },
    dashboardPointsEarned: {
        ...appFonts.xxxlRegular,
        color: baseColors.black,
    },
    dashboardPointsNeeded: {
        ...appFonts.lgRegular,
        color: baseColors.grey40,
        marginTop: spacing.s0,
    }
};
