import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { css, StyleSheet } from 'aphrodite-jss';
import { baseColors, appFonts, spacing, importantClass } from '../../../styles';
import { translate, components as Core } from '../../core';
import { components as AuthComponents } from '../../auth';
import { appName, appStoreUrl, appStoreName } from '../../../config/buildVariant';
import { appStoreBadge, googlePlayBadge } from '../../core/services/appBadgesHelper';

const selectAuth = state => state.auth;

const InstallMobileApp = i18n => {
    const location = window.location;
    const params = _.get(location, 'search');
    let isModa = false;
    if (params) {
        isModa = params.includes('program=moda');
    }

    const auth = useSelector(selectAuth);
    const programName = _.get(auth, 'company.programName');
    return (
        <div className={css(styles.container)}>
            <AuthComponents.Logo isPrimary={true} className={css(styles.logoContainer)} imageClassName={css(styles.logo)} />
            {isModa ? (
                <div className={css(styles.modaContainer)}>
                    <p className={css(styles.modaBold)}>{`${i18n.t('moda.installApp.title', { programName })}`}</p>
                    <p className={css(styles.modaText)}>{`${i18n.t('moda.installApp.subtitle', { programName })}`}</p>
                    {/* eslint-disable-next-line react/no-danger */}
                    <div dangerouslySetInnerHTML={({ __html: `${i18n.t('moda.installApp.stepOneA')} <strong>${i18n.t('moda.installApp.stepOneB')}</strong>` })} className={css(styles.modaText)} />
                    <p className={css(styles.modaText)}>{`${i18n.t('moda.installApp.stepTwo')}`}</p>
                    <p className={css(styles.modaText)}>{`${i18n.t('moda.installApp.stepThree', { programName })}`}</p>
                </div>
            )
                :
                <p className={css(styles.getAppText)}>{i18n.t('getMobileApp', { programName: appName, appStoreName })}</p>}
            <a href={appStoreUrl.ios}>
                <Core.Image
                    src={appStoreBadge()}
                    resizeMode="contain"
                    className={css(styles.storeButton)}
                />
            </a>
            <a href={appStoreUrl.android}>
                <Core.Image
                    src={googlePlayBadge()}
                    resizeMode="contain"
                    className={css(styles.storeButton)}
                />
            </a>
        </div>
    );
};

export default translate()(InstallMobileApp);

const styles = StyleSheet.create({
    container: importantClass({
        height: '100vh',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: baseColors.primary
    }),
    logoContainer: importantClass({
        width: '50%',
        height: 'unset',
        maxWidth: 300
    }),
    logo: importantClass({
        width: '100%',
        height: 'unset'
    }),
    getAppText: {
        ...appFonts.mdMedium,
        marginTop: spacing.s5,
        marginBottom: spacing.s3,
        maxWidth: '80%',
        color: baseColors.white,
        textAlign: 'center'
    },
    storeButton: {
        marginTop: spacing.s3,
        height: spacing.s9,
        width: 'auto'
    },
    modaContainer: {
        maxWidth: '80%',
    },
    modaBold: {
        ...appFonts.mdBold,
        color: baseColors.white,
        paddingBottom: spacing.s1
    },
    modaText: {
        ...appFonts.mdMedium,
        color: baseColors.white,
        paddingBottom: spacing.s1
    },
});
