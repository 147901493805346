import _ from 'lodash';
import * as types from './actionTypes';
import * as api from './api';
import { actions as coreActions, Storage, normalize, constants as coreConstants, crypto } from '../core';
import { CATEGORIES, TYPES } from './constants';
import { validateError } from '../../config';

export function getContent(category, params, isReplace, type, apiCall, isSearching = false) {
    const actionType = isSearching ? types.SEARCH_CONTENT : types.GET_CONTENT;
    const isAll = !type && category === CATEGORIES.ALL && params.offset === 0;
    return function (dispatch) {
        dispatch(incrementIsLoadingCount());
        dispatch({ type: actionType.REQUEST });
        if (type === TYPES.PROMOTED) {
            dispatch({ type: types.GET_CONTENT_PROMOTED.REQUEST });
        }
        return (apiCall || api.getContent(params, isSearching))
            .then(res => {
                const items = type === TYPES.PROMOTED ? _.uniqBy(_.orderBy(res.data.response, 'promote_end', 'desc'), 'id') : res.data.response;
                dispatch({
                    type: actionType.SUCCESS,
                    payload: {
                        category,
                        ...normalize.normalizeArray(_.flatten(items)),
                        count: res.data.count,
                        isReplace,
                        type,
                        isAll,
                        categories: res.data.categories
                    }
                });
                if (type === TYPES.PROMOTED) {
                    dispatch({ type: types.GET_CONTENT_PROMOTED.SUCCESS });
                }
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: actionType.ERROR, payload: { error } });
                if (type === TYPES.PROMOTED) {
                    dispatch({ type: types.GET_CONTENT_PROMOTED.ERROR });
                }
            }));
    };
}

function incrementIsLoadingCount() {
    return function (dispatch) {
        dispatch({ type: types.INCREMENT_LOADING_COUNT.SUCCESS });
    };
}

/**
 * Before passing data to getContent - check if response data hash is same as in storage,
 * if yes - shuffle items in array, if not - set new hash to storage
 */
export function getContentAndShuffle(category, params, isReplace, type) {
    const apiCall = api.getContent(params)
        .then(response => Storage.getItem(type)
            .then(value => {
                let json = '';
                try {
                    json = JSON.stringify({
                        size: _.size(response.data.response),
                        head: _.head(response.data.response),
                        last: _.last(response.data.response)
                    });
                    // eslint-disable-next-line no-empty
                } catch (err) {}
                const hash = crypto.md5(json).toString();
                if (value !== hash) {
                    Storage.setItem(type, hash);
                    return response;
                }
                return { ...response, data: { ...response.data, response: _.shuffle(response.data.response) } };
            }));
    return getContent(category, params, isReplace, type, apiCall);
}

export function clearContent(category, type) {
    return dispatch =>
        dispatch({
            type: types.GET_CONTENT.SUCCESS,
            payload: {
                category,
                items: [],
                count: 0,
                isReplace: true,
                type
            }
        });
}

export function getContentItem(id) {
    return function (dispatch) {
        dispatch({ type: types.GET_CONTENT_ITEM.REQUEST });
        return api.getContentItem(id)
            .then(res => {
                dispatch({
                    type: types.GET_CONTENT_ITEM.SUCCESS,
                    payload: {
                        item: res.data[0]
                    }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.GET_CONTENT_ITEM.ERROR, payload: { error } });
            }));
    };
}

export function getContentTags() {
    return function (dispatch) {
        dispatch({ type: types.GET_CONTENT_TAGS.REQUEST });
        return api.getContentTags()
            .then(res => {
                dispatch({
                    type: types.GET_CONTENT_TAGS.SUCCESS,
                    payload: res.data
                });
            })
            .catch(error => console.log('GET_CONTENT_TAGS error', error.response));
    };
}

export function showContentIsNotAvailable() {
    return function (dispatch) {
        dispatch(coreActions.addToast(coreConstants.TOAST_TYPES.INFO, 'NoLibraryContent'));
    };
}

export function bookmarkContent(item, value) {
    return function (dispatch) {
        dispatch({ type: types.BOOKMARK_CONTENT.REQUEST });
        dispatch({
            type: types.BOOKMARK_CONTENT.SUCCESS,
            payload: { item: { ...item, bookmarked: item.bookmarked === '1' ? '0' : '1' }, value }
        });

        const apiCall = value ? api.bookmarkContent(item.link_id) : api.unmarkContent(item.link_id);
        apiCall.catch(error => validateError(error, error => {
            dispatch({
                type: types.BOOKMARK_CONTENT.ERROR,
                payload: { item: { ...item }, value: !value }
            });

            const err = (_.get(error, 'response.data') || error);
            dispatch(coreActions.addToast('danger', undefined, err.displayMessage || err.message));
        }));
    };
}

export function markAsReadWatched(id) {
    return function (dispatch) {
        dispatch({ type: types.MARK_READ_WATCHED.REQUEST });
        return api.getContentTracking(id)
            .then(() => dispatch({ type: types.MARK_READ_WATCHED.SUCCESS }))
            .catch(error => console.log('MARK_READ_WATCHED error', error.response));
    };
}

export function markAsOpened(id) {
    return function (dispatch) {
        dispatch({ type: types.MARK_OPENED.REQUEST });
        return api.getContentTrackingOpened(id)
            .then(() => dispatch({ type: types.MARK_OPENED.SUCCESS }))
            .catch(error => console.log('MARK_OPENED error', error.response));
    };
}

export function updateRecommendedContent() {
    return function (dispatch) {
        dispatch({ type: types.UPDATE_RECOMMENDED_CONTENT.REQUEST });
        return api.updateRecommendedContent()
            .then(() => {
                dispatch({ type: types.UPDATE_RECOMMENDED_CONTENT.SUCCESS });
                dispatch(getRecommendedContent(CATEGORIES.ALL, TYPES.RECOMMENDED, {}, true));
                dispatch(getContent(CATEGORIES.ALL, { offset: 0, maxCount: 20 }, true, undefined, null, false));
            })
            .catch(error => console.log('UPDATE_RECOMMENDED_CONTENT error', error.response));
    };
}

export function getRecommendedContent(category, type, params, isReplace) {
    return function (dispatch) {
        const isAll = !type && category === CATEGORIES.ALL && params.offset === 0;
        dispatch({ type: types.GET_RECOMMENDED_CONTENT.REQUEST });
        return api.getRecommendedContent(params)
            .then(res => {
                const items = res.data.response;
                dispatch({
                    type: types.GET_RECOMMENDED_CONTENT.SUCCESS,
                    payload: {
                        category,
                        ...normalize.normalizeArray(_.flatten(items)),
                        count: res.data.count,
                        isReplace,
                        type: TYPES.RECOMMENDED,
                        isAll,
                        categories: res.data.categories
                    }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.GET_RECOMMENDED_CONTENT.ERROR, payload: { error } });
            }));
    };
}

export function clearAndUpdateContent() {
    return async function (dispatch) {
        await dispatch(clearAllContent());
        dispatch(updateRecommendedContent());
    };
}

export function clearAllContent() {
    return function (dispatch) {
        dispatch({ type: types.CLEAR_CONTENT.SUCCESS });
    };
}