export const NAME = 'invitations';
export const NOT_PERSISTED = true;

export const ENTITIES = {
    COMMUNITY: 'community',
    CHALLENGE: 'challenge',
    TEAM: 'team',
    EVENT: 'event'
};

export const TYPES = {
    USERS: 'users',
    DEPARTMENTS: 'department',
    REGIONS: 'region'
};

export const USERS_MAX_COUNT = 10;
export const NAV_BUTTON_FONT_WEIGHT = 600;

export const INVITE_BUTTON = 'invite';
export const USER_PLUS_ICON = 'user-plus';
export const USER_CHECK_ICON = 'user-check';
export const USER_CLOCK_ICON = 'user-clock';
export const USER_FRIENDS_ICON = 'user-friends';
export const ARROW_ICON = 'arrow-circle-up';