import { appFonts, spacing } from '../../styles';
import { Platform, PLATFORMS } from '../core';

export const NAME = 'challenges';
export const NOT_PERSISTED = true;
export const ONBOARDING_GOALS = ['Just keep swimming!', 'Run, Forrest, Run!', 'Relax with some yoga'];

const CIRCLE_RING_WIDTH = spacing.s0;

export const TYPES = {
    regular: 'regular',
    small: 'small',
    achieved: 'achieved',
    spanish: 'spanish'
};
export const CIRCLE_SIZES = {
    regular: Platform.OS === PLATFORMS.web ? '80%' : 112,
    small: 84,
    achieved: 80,
    spanish: 116
};

export const COMMON_PROPS = {
    [TYPES.regular]: {
        circleSize: CIRCLE_SIZES.regular,
        circleMaxSize: 120,
        circleRingWidth: CIRCLE_RING_WIDTH,
        iconSize: 20,
        percentageFont: appFonts.xlBold,
        descriptionFont: appFonts.xsRegular,
        description: 'completed'
    },
    [TYPES.small]: {
        circleSize: CIRCLE_SIZES.small,
        circleMaxSize: 220,
        circleRingWidth: CIRCLE_RING_WIDTH,
        iconSize: 16,
        percentageFont: appFonts.lgBold,
        descriptionFont: appFonts.smRegular,
        description: 'CompletedOverall'
    },
    [TYPES.achieved]: {
        circleSize: CIRCLE_SIZES.achieved,
        circleMaxSize: 220,
        circleRingWidth: CIRCLE_RING_WIDTH,
        iconSize: 16,
        percentageFont: appFonts.lgBold,
        descriptionFont: appFonts.smRegular,
        description: 'achieved'
    },
    [TYPES.spanish]: {
        circleSize: CIRCLE_SIZES.spanish,
        circleMaxSize: 220,
        circleRingWidth: CIRCLE_RING_WIDTH,
        iconSize: 16,
        percentageFont: appFonts.lgBold,
        descriptionFont: appFonts.smRegular,
        description: 'achieved'
    }
};

export const CHALLENGE_TYPES = Object.freeze({
    goal: 'goal',
    competition: 'competition'
});

// Don't translate these here: it will break goal/challenge creation. Translate where it is being used if necessary.
export const FREQUENCY = Object.freeze({
    daily: 'daily',
    weekly: 'weekly',
    monthly: 'monthly',
    total: 'total',
    none: 'none',
    overall: 'overall'
});

export const CHALLENGE_ENTITY_TYPES = Object.freeze({
    user: 'user',
    group: 'group',
    company: 'company',
    partner: 'partner',
    department: 'department',
    region: 'region',
    program: 'program'
});

export const CHALLENGE_SUBENTITY_TYPES = Object.freeze({
    userTeam: 'userTeam',
    companyTeam: 'companyTeam',
});

export const CHALLENGE_REQUIREMENTS = Object.freeze({
    combo: 'combo',
    all: 'all',
});

export const UNIT_TYPES = Object.freeze({
    category: 'activity_category',
    unit: 'activity_unit',
});

export const ELIGABLE_ACTIVITY_TYPES = Object.freeze({
    category: 'category',
    unit: 'unit',
});

export const SCORE_DISPLAY_TYPES = Object.freeze({
    percentage: 'Percentage',
    score: 'Score',
    averageScore: 'Average score',
});

export const COMPANY_ENTITIES = ['company', 'department', 'region'];

export const CHALLENGES_CAROUSEL_TYPES = Object.freeze({
    programCompetitions: 'programCompetitions',
    competitions: 'competitions',
    goals: 'goals'
});

export const PROGRESS_SQUARE_SIZE = 40;

export const MAX_PERSONAL_GOALS = 3;
export const DEFAULT_TIME_ID = 4;
export const TIME_FILTER_4_MONTHS = '4 Months';

export const FORMAT_GOALS_TYPES = Object.freeze({
    activityQuantity: 'activityQuantity',
    categoryQuantity: 'categoryQuantity'
});

export const CREATE_CHALLENGE_STEPS = {
    solo: 'solo',
    entity: 'entity',
    type: 'type',
    teamSize: 'teamSize',
    company: 'company',
    department: 'department',
    region: 'region',
    frequency: 'frequency',
    unitType: 'unitType',
    category: 'category',
    categoryQuantity: 'categoryQuantity',
    activities: 'activities',
    date: 'date',
    image: 'image',
    finalize: 'finalize',
    personalGoal: 'personalGoal',
    additional: 'additional'
};

export const CREATE_CHALLENGE_ITEM_IDS = {
    me: 'me',
    others: 'others',
    one: 'one',
    team: 'team',
    company: 'company',
    goal: 'goal',
    competition: 'competition',
    teamSize: 'teamSize',
    companyChallengeSelected: 'companyChallengeSelected',
    department: 'department',
    departmentSelected: 'departmentSelected',
    region: 'region',
    regionSelected: 'regionSelected',
    frequency: 'frequency',
    category: 'category',
    activities: 'activities',
    categorySelected: 'categorySelected',
    categoryQuantity: 'categoryQuantity',
    categoryCompetitionSelected: 'categoryCompetitionSelected',
    activitiesSelected: 'activitiesSelected',
    date: 'date',
    image: 'image',
    finalize: 'finalize',
    personalGoal: 'personalGoal',
    discard: 'discard',
    created: 'created'
};

export const CREATE_CHALLENGE_DEFAULT_PROPS = {
    personalGoal: () => ({ solo: 1, entity: 'user', type: 'goal', requirement: 'combo', public: 0 }),
    entity: () => ({ solo: 0, public: 0 }),
    type: userId => ({ solo: 0, public: 0, entity: 'user', subentity: undefined, challengees: [{ id: userId }] }),
    teamSize: () => ({ solo: 0, public: 1, entity: 'group', subentity: 'userTeam', challengees: [] }),
    department: () => ({ solo: 0, public: 1, entity: 'department', challenger: 'department' }),
    region: () => ({ solo: 0, public: 1, entity: 'region', challenger: 'region' }),
    companyMembers: () => ({ solo: 0, public: 1, entity: 'company' }),
    companyMembersTeams: () => ({ solo: 0, public: 1, entity: 'group', subentity: 'companyTeam' }),
};

export const ACTIVITIES_SEARCH_KEYS = ['activityName', 'unitName'];

export const DISPLAY_TYPES = Object.freeze({
    UNCOMPLETED_CAROUSEL: 'uncompletedCarousel',
    COMPLETED_CAROUSEL: 'completedCarousel',
    INVITATIONS: 'invites',
    ATTENDED: 'attending',
    RECOMMENDED: 'recommended',
    RECENT: 'recent',
    ALL: 'all',
    COMPANY: 'company',
    COMPLETED: 'completed',
    BONUS: 'bonus',
    ON_AND_UPCOMING: 'onAndUpcoming',
    USER: 'user'
});

export const ITEMS_PER_SLIDE = 3;

export const MAX_CARD_DISPLAY_ACTIVITIES = 2;

export const AUTOMATICALLY_TRACKED_ACTIVITY_INPUT_DEVICE = 'OpenAPI';
export const DAYS = 'days';

export const POINTS = 'pts';
export const CREDITS = 'cr';
export const UNIT_POINTS = 'points';
export const UNIT_QUANTITY = 'unitQuantity';
export const CHALLENGE_CARD_HEADER_ITEMS_SIZES = {
    UPCOMING_CHALLENGE_IMAGE_HEIGHT: 160,
    FEATURED_CHALLENGE_IMAGE_HEIGHT: 150,
    ACTIVE_CHALLENGE_IMAGE_HEIGHT: 100,
    ENDED_CHALLENGE_IMAGE_HEIGHT: 50,
    SPROUT_BRAND_ICON_HEIGHT: 12,
    SPROUT_BRAND_ICON_WIDTH: 17,
    RIGHT_ARROW_ICON_HEIGHT: 17,
    RIGHT_ARROW_ICON_WIDTH: 10,
    FLAG_ICON_SIZE: 14,
    GRAPHIC_SIZE: 20
};

export const GOAL_CARD_HEIGHT_MOBILE = 320;

export const SINGLE_DAILY_AVERAGE_UNIT_TYPES = {
    reps: 'rep',
    laps: 'lap',
    secs: 'sec',
    days: 'day',
    meals: 'meal',
    steps: 'step',
    fights: 'fight',
    snacks: 'snack',
    visits: 'visit',
    glasses: 'glass',
    actions: 'action',
    servings: 'serving'
};

export const IS_ABOUT_TAB = 'isAboutTab';
export const IS_TEAM_TAB = 'isTeamTab';
export const SELECTED_FILTER = 'selectedFilter';
export const SHOULD_REMEMBER_FILTER = 'shouldRememberFilter';
export const FEATURED_CHALLENGES = 'featuredChallenges';

export const LEADERBOARD_SLUG_TYPE = {
    location: 'Top locations',
    region: 'Top regions',
    department: 'Top departments'
};