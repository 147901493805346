import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { tracker, translate, selectors as coreSelectors } from '../../../core';
import * as actions from '../../actions';
import { appFonts, spacing, baseColors } from '../../../../styles';
import { getCompany, getCompanyError, isLoadingCompany, isTHVCSSOEnabled } from '../../selectors';
import { REGISTRATION_ITEM_IDS } from '../../constants';
import { getSupportEmail } from '../../services/helper';

export default function WithProgramDomainBase(WrappedComponent) {
    class ProgramDomainBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            callback: PropTypes.func.isRequired,
            avoidDataSubmissionCallback: PropTypes.func,
            isLoading: PropTypes.bool,
            error: PropTypes.object,
            i18n: PropTypes.object.isRequired,
            step: PropTypes.string.isRequired,
            isTelus: PropTypes.bool,
            isTHVCSSOEnabled: PropTypes.bool
        };

        static defaultProps = {
            isLoading: false,
            avoidDataSubmissionCallback: null,
            error: undefined,
            isTelus: false,
            isTHVCSSOEnabled: false
        };

        constructor(props) {
            super(props);
            this.state = { text: '' };
            // to track if need to call callback as component is not unmounted on push next
            this.isCallbackCalled = false;
            this.props.actions.clearCompanyConfiguration();
        }

        componentDidUpdate(prevProps) {
            if (prevProps.isLoading && !this.props.isLoading && !this.props.error && !this.isCallbackCalled) {
                tracker.logEvent('ProgramCode_Success');
                this.isCallbackCalled = true;
                this.props.callback(this.props.step);
            }

            if (prevProps.isLoading && !this.props.isLoading && this.props.error) {
                tracker.logEvent('ProgramCode_Invalid');
            }
        }

        get error() {
            return this.props.error ? this.props.i18n.t('program_not_found') : undefined;
        }

        _clearError = () => {
            if (this.error) this.props.actions.clearCompanyConfigurationError();
        };

        submit = () => {
            this.isCallbackCalled = false;
            this._clearError();
            const params = {};
            params.programName = this.state.text;

            // if it's corporate linking, don't change the sbudomain
            params.skipSubdomainSave = !!this.props.avoidDataSubmissionCallback;
            this.props.actions.getProgramConfigurations(params);
        };

        findDomainName = () => {
            this._clearError();
            this.props.callback(this.props.step, REGISTRATION_ITEM_IDS.findProgramDomain);
        };

        updateText = text => {
            this._clearError();
            this.setState({ text });
        };

        getInfoModalProps = () => {
            const { i18n, isTelus } = this.props;
            const supportEmail = getSupportEmail(isTelus);

            const string1 = i18n.t('programCode.infoModal.unique');
            const string2 = i18n.t('programCode.infoModal.support');
            const email = 'support@sproutatwork.com';
            const emailLink = `<a href="mailto:${supportEmail}">${supportEmail}</a>`;
            const string2Replace = string2.replace(email, emailLink);
            const htmlText = `${string1}\n\n${string2Replace}`;

            return {
                htmlText,
                title: i18n.t('whatsMyProgramCode'),
                iconName: 'question',
                buttonTitle: i18n.t('close'),
            };
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    error={this.error}
                    updateText={this.updateText}
                    text={this.state.text}
                    submit={this.submit}
                    findDomainName={this.findDomainName}
                    getInfoModalProps={this.getInfoModalProps}
                />
            );
        }
    }

    function mapStateToProps(state) {
        return {
            config: getCompany(state),
            error: getCompanyError(state),
            isLoading: isLoadingCompany(state),
            isTelus: coreSelectors.isTelus(state),
            isTHVCSSOEnabled: isTHVCSSOEnabled(state)
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(ProgramDomainBase));
}

export const styles = {
    findButton: {
        flexDirection: 'column',
        marginBottom: spacing.s2
    },
    findButtonText: {
        ...appFonts.lgRegular,
        color: baseColors.secondary
    },
    nextButton: {
        marginTop: spacing.s1,
        marginBottom: spacing.s2
    }
};
