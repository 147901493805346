import _ from 'lodash';
import color from 'color';
import systemBuildVariant, { BUILDS } from '../config/buildVariant/buildVariant';

// !!! important !!!
// if we want ti use primaryLighter, primaryDarker, etc it should be generated based on primary, same for secondary

const isLiveBetter = () => systemBuildVariant === BUILDS.mylivebetter;
const isTelus = () => systemBuildVariant === BUILDS.telus;

export const getLightenColor = (inputColor, ratio) => {
    const newColor = color(inputColor).lighten(ratio);
    return `rgb(${newColor.rgb().round().array()})`;
};

export const getDarkenColor = (inputColor, ratio) => {
    const newColor = color(inputColor).darken(ratio);
    return `rgb(${newColor.rgb().round().array()})`;
};

const medibankColor = {

    primary: '#D90432',
    primaryLighten: getLightenColor('#D90432', 0.1),
    primaryImageLabel: 'rgba(217, 4, 50, 0.7)', //program challenge label background

    secondary: '#2E92E9'
};

export const telusColor = {

    primary: '#4B286D',
    primaryLighten: getLightenColor('#4B286D', 0.1),
    primaryImageLabel: 'rgba(75, 40, 109, 0.7)', //program challenge label background

    secondary: '#4B286D'
};

const sproutColor = {

    primary: '#1DAA5B',
    primaryLighten: getLightenColor('#1DAA5B', 0.1),

    primaryImageLabel: 'rgba(46,190,104, 0.7)', // program challenge label background

    secondary: '#0071C7'
};

// Validation
const validation = {
    successLightest: '#D8FBE4',
    success: '#26A754',
    successDarker: '#1C7B3E',
    successDarkest: '#0D4520',
    
    gold: '#FFD910',

    dangerLightest: '#FBECEA',
    danger: '#EF5A4E',
    dangerDarker: '#D83E31',
    dangerDarkest: '#6F1911',

    warnLightest: '#FEEFBD',
    warn: '#D68000',
    warnDarker: '#A86500',
    warnDarkest: '#523200'
};

// Neutrals
const neutralColor = {

    black: '#1C1E24',
    grey20: '#3F4145',
    grey40: '#6D7178',
    grey50: '#8E949F',
    grey60: '#A9AEB7',
    grey70: '#DFE1E6',
    grey80: '#EEEEEE',
    grey85: '#F6F6F7',
    grey90: '#FAFAFA',
    white: '#FFFFFF',

    // Context family
    contextLightest: '#E8F0FB',
    context: '#4C92F0',
    contextDarker: '#1970E6',
    contextDarkest: '#0D3773',

    // Shadows
    modalBackgroundColor: 'rgba(0,0,0,0.5)',
    shadowHighest: '0px 8px 18px rgba(0, 0, 0, 0.15)',

    // Legacy
    colourBonus: '#FFA200',
    dashboardProgressBackground: '#535B62',
    dashboardBackground: '#181D29',
    corporateImageLabel: 'rgba(0,0,0, 0.7)' // corporate label background
};

const categoryColors = {
    moveColor: '#41E8EC',
    moveIconColor: '#0C4348',
    mindColor: '#9A5DFB',
    mindIconColor: '#1E0B40',
    eatColor: '#FF2CAC',
    eatIconColor: '#5C0F41'
};

const gradientColors = {
    gradientGreen: '#3DEE5A',
    gradientLightGreen: '#A9E952',
    gradientYellow: '#FFE54B',
    gradientRed: '#FF5353',
    gradientGolden_1: '#FFF9E5',
    gradientGolden_2: '#FFFCF1',
    gradientGolden_3: '#FFF9E3',
};

const brandColors = {
    facebook: '#3B5998',
    google: '#EA4335',
    apple: '#212B35'
};

const dashboardGradientColors = {
    dashboardLeftGradient: isLiveBetter() ? medibankColor.primary : '#1AB58F',
    dashboardRightGradient: isLiveBetter() ? medibankColor.primary : sproutColor.primary
};

const platformColors = () => {
    if (isLiveBetter()) {
        return medibankColor;
    }
    if (isTelus()) {
        return telusColor;
    }
    return sproutColor;
};

const colors = _.assign(neutralColor, validation, categoryColors, brandColors, gradientColors, dashboardGradientColors,
    platformColors());

const generateExtraColors = ({ primary, secondary, background }) => {
    // colors.primaryLighter = lighter(primary);
    // colors.primaryLightest = ...;
    // etc
};

export const updateColors = newColors => {
    _.assign(colors, newColors);
    generateExtraColors(newColors);
};

export default colors;
