import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { translate } from '../../../core';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import {
    COMMUNITY_TYPES,
    CREATE_COMMUNITY_STEPS as STEPS,
    CREATE_COMMUNITY_ITEM_IDS as ITEM_IDS,
    CREATE_COMMUNITY_DEFAULT_PROPS as DEFAULT_PROPS
} from '../../constants';

export const NEXT_SCREENS_CONFIG = {
    [ITEM_IDS.communityType]: {
        step: STEPS.communityType,
        getData: DEFAULT_PROPS.communityType,
    },

    [ITEM_IDS.publicCommunity]: {
        step: STEPS.communityName,
        getData: { type: COMMUNITY_TYPES.public }
    },

    [ITEM_IDS.privateCommunity]: {
        step: STEPS.communityName,
        getData: { type: COMMUNITY_TYPES.private }
    },

    [ITEM_IDS.publicCommunityName]: {
        step: STEPS.joinExisting,
        getData: data => data
    },

    [ITEM_IDS.privateCommunityName]: {
        step: STEPS.communityDescription,
        getData: data => data
    },

    [ITEM_IDS.communityDescription]: {
        step: STEPS.image,
        getData: data => data
    },

    [ITEM_IDS.image]: {
        step: STEPS.image,
        getData: data => data
    },

    [ITEM_IDS.finalize]: {
        getData: data => data
    },

    [ITEM_IDS.created]: {
        action: '_finish'
    },
};

export default function WithCreateCommunityBase(WrappedComponent) {
    class CreateCommunityBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            isOnboarding: PropTypes.bool,
            step: PropTypes.string,
            newCommunity: PropTypes.object,
            isCreating: PropTypes.bool,
            onClose: PropTypes.func,
            finishRedirect: PropTypes.string,
        };

        static defaultProps = {
            step: undefined,
            isOnboarding: false,
            newCommunity: null,
            onClose: null,
            isCreating: false,
            finishRedirect: undefined,
        };

        get title() {
            return this.props.i18n.t('createCommunityFlow.navigatorTitle');
        }

        get step() {
            return this.props.step;
        }

        openNextPage = item => {
            const screenConfig = NEXT_SCREENS_CONFIG[item.id];
            const data = screenConfig.getData && screenConfig.getData(item.data);

            if (data) this.props.actions.updateNewCommunity(data);
            if (screenConfig.action) this[screenConfig.action]();

            if (screenConfig.step) {
                this.updateLocalRegistrationStep(screenConfig.step);
                _.has(this, 'wrapped.openNextPage') && this.wrapped.openNextPage(screenConfig);
            }
        };

        clear = () => {
            this.props.actions.updateNewCommunity();
        }

        _finish() {
            _.has(this, 'wrapped.finish') && this.wrapped.finish();
        }

        updateLocalRegistrationStep = nextStep => {
            this.props.actions.updateCreateCommunityStep(nextStep);
        };

        saveRef = ref => (this.wrapped = ref);

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    ref={this.saveRef}
                    openNextPage={this.openNextPage}
                    title={this.title}
                    updateLocalRegistrationStep={this.updateLocalRegistrationStep}
                    originStep={this.props.step}
                    clear={this.clear}
                    isCreating={this.props.isCreating}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        const routeParams = _.get(ownProps, 'route.params');
        return {
            newCommunity: selectors.getNewCommunity(state),
            isCreating: selectors.isCreatingCommunity(state),
            lastCreatedCommunityId: selectors.getLastCreatedCommunityId(state),
            step: ownProps.step || _.get(ownProps, 'match.params.step') || _.get(ownProps, 'route.params.step'),
            localStep: selectors.getCreateCommunityStep(state),
            finishRedirect: ownProps.finishRedirect || _.get(ownProps, 'location.state.finishRedirect'),
            ...(routeParams || {}),
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(CreateCommunityBase));
}
