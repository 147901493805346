import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { StyleSheet, css } from 'aphrodite-jss';
import { withRouter } from 'react-router-dom';

import { components as Core, Modal, ROUTES } from '../../../../core';
import { baseColors, spacing, containerStyle, importantStyles, layoutStyle } from '../../../../../styles';
import WellbeingOverallProgress from '../WellbeingOverallProgress';
import WellbeingCategoryProgress from '../WellbeingCategoryProgress';
import { SLUGS } from '../../../constants';
import WithWellbeingTabBase, { styles as baseStyles, heartBubble } from './WellbeingTabBase';
import { takeHealthSurvey, healthScoreFeedback } from '../../../services';

class WellbeingTab extends PureComponent {
    static propTypes = {
        hideRecommendation: PropTypes.bool,
        i18n: PropTypes.object.isRequired,
        isVisibleReminder: PropTypes.bool,
        getSettingsGoalModalProps: PropTypes.func.isRequired,
        isExpandedCategory: PropTypes.func.isRequired,
        onToggleCategory: PropTypes.func.isRequired,
        isHealthSurveyCompleted: PropTypes.bool,
        isLoadingHealthSurveyResults: PropTypes.bool.isRequired,
        onRef: PropTypes.func.isRequired,
        emptyStateTitle: PropTypes.string.isRequired,
        emptyStateDescription: PropTypes.string.isRequired,
        startSurveyButtonLabel: PropTypes.string.isRequired,
        isAllPossibleGoalsSet: PropTypes.bool,
        browseAndSetText: PropTypes.string.isRequired,
        improveScoreProps: PropTypes.object.isRequired,
        reminderProps: PropTypes.object.isRequired,
        healthSurveyProps: PropTypes.object.isRequired,
        isWellbeingCompleted: PropTypes.bool.isRequired,
        history: PropTypes.object.isRequired,
        wasItHelpfulText: PropTypes.string.isRequired,
        myTab: PropTypes.bool,
        isOnboarding: PropTypes.bool
    };

    static defaultProps = {
        hideRecommendation: false,
        isVisibleReminder: true,
        isHealthSurveyCompleted: false,
        isAllPossibleGoalsSet: false,
        myTab: false,
        isOnboarding: false
    };

    onDetails = (title = undefined) => {
        const { getSettingsGoalModalProps } = this.props;
        const settingsGoalModalProps = getSettingsGoalModalProps(title);

        this.closeModal = Modal.open(
            Core.InfoModal,
            {
                ...settingsGoalModalProps,
                buttons: [{ text: settingsGoalModalProps.buttonTitle, onPress: () => this.closeModal() }],
            },
            { isTransparent: true, fadeTransition: true }
        );
    };

    get renderHealthScores() {
        return (
            <div>
                {_.map(SLUGS, slug => (
                    <WellbeingCategoryProgress
                        key={slug}
                        slug={slug}
                        onToggle={this.props.onToggleCategory}
                        isExpanded={() => this.props.isExpandedCategory(slug)}
                    />
                ))}
            </div>
        );
    }

    get improveScoreButtonType() {
        return this.props.isVisibleReminder ? 'contained' : 'outlined';
    }

    get renderIncompleteHealthSurvey() {
        const { emptyStateTitle, emptyStateDescription, startSurveyButtonLabel } = this.props;
        return (
            <div className={css(styles.incompleteContainer, layoutStyle.flexColumnCenter)}>
                <Core.Image src={heartBubble} className={css(styles.heartImage)} />
                <div className={css(layoutStyle.flexColumnCenter)}>
                    <p className={css(styles.emptyTitle)}>{emptyStateTitle}</p>
                    <p className={css(styles.emptyDescription)}>{emptyStateDescription}</p>
                </div>
                <div className={css(styles.sectionContainer, styles.overallButton, styles.healthSurveyButton)}>
                    <Core.Button
                        size="medium"
                        onPress={this.takeHealthSurvey}>
                        {startSurveyButtonLabel}
                    </Core.Button>
                </div>
            </div>
        );
    }

    get renderImproveScore() {
        const { wasItHelpfulText, improveScoreProps: { buttonLabel } } = this.props;
        return (
            <div className={css(styles.improveScoreContainer)}>
                <Core.Button
                    size="large"
                    type={this.improveScoreButtonType}
                    onPress={this.takeHealthSurvey}
                    className={css(styles.updateScoreButton)}>
                    {buttonLabel}
                </Core.Button>
                <Core.Button
                    size="large"
                    onPress={this.healthScoreFeedback}
                    type={this.improveScoreButtonType}
                    className={css(styles.wasThisHelpfulBtn)}>
                    <span className={css(styles.marginRight8px)}>
                        <Core.Icon
                            size={spacing.s3 - 2}
                            type="light"
                            name="comment-dots"
                        />
                    </span>
                    <span>{wasItHelpfulText}</span>
                </Core.Button>
            </div>
        );
    }

    get renderReminder() {
        const { reminderProps: { isVisibleReminder, title, subtitle, iconName } } = this.props;
        return (
            isVisibleReminder ? (
                <Core.InfoSection
                    title={title}
                    isTouchable={true}
                    subtitle={subtitle}
                    iconName={iconName}
                    iconColor={baseColors.primary}
                    onPress={this.takeHealthSurvey}
                />
            ) : null
        );
    }

    get renderCompleteHealthSurvey() {
        const { isVisibleReminder, hideRecommendation, healthSurveyProps: { results, healthSurveyResultsError } } = this.props;
        return _.get(results, 'data.length') || !healthSurveyResultsError ? (
            <div>
                {this.renderReminder}
                <WellbeingOverallProgress
                    className={css(isVisibleReminder && styles.wellbeingOverall)}
                />
                {!hideRecommendation && this.renderImproveScore}
            </div>
        ) : <div>{healthSurveyResultsError.errorDetails.description}</div>;
    }

    get renderContent() {
        return this.props.isWellbeingCompleted ?
            this.renderCompleteHealthSurvey :
            this.renderIncompleteHealthSurvey;
    }

    takeHealthSurvey = () => {
        takeHealthSurvey(this.props.i18n);
    };

    healthScoreFeedback = () => {
        healthScoreFeedback();
    }

    onPressSeeRecommendedGoalsButton = () => {
        this.props.history.push(ROUTES.setPersonalGoal());
    }

    goBack = () => this.props.history.goBack();

    goToMe = () => this.props.history.push(ROUTES.me());

    render() {
        const { hideRecommendation, onRef, isLoadingHealthSurveyResults, i18n, myTab, isOnboarding } = this.props;
        return (
            <div ref={onRef} className={css(styles.mainContainer, hideRecommendation && containerStyle.container)}>
                {myTab ? (
                    <Core.EntityDetailsHeader
                        hasBackButton={myTab ? true : false}
                        backUrl={this.goBack}
                        headerContainerClassName={null}
                    />
                ) : null}
                <Core.SubHeader
                    title={i18n.t('wellbeing.score')}
                    noTopPadding={true}
                    titleAlign={isOnboarding ? 'center' : 'left'}
                    right={!myTab && !isOnboarding ? (
                        <Core.Button
                            type="text"
                            onPress={this.goToMe}>
                            {i18n.t('done')}
                        </Core.Button>
                    )
                        : null}
                />
                <Core.SmallerContainer>
                    {isLoadingHealthSurveyResults ? (
                        <div className={css(layoutStyle.commonPaddingVertical)}>
                            <Core.ListLoading className={css(styles.loader)} />
                        </div>
                    ) : this.renderContent}
                </Core.SmallerContainer>
            </div>
        );
    }
}

const LOADER_HEIGHT = 500;

const styles = StyleSheet.create(importantStyles({
    ...baseStyles,
    improveScoreContainer: {
        padding: spacing.s5,
        textAlign: 'center',
        paddingBottom: spacing.s7,
        display: 'flex',
        flexDirection: 'column'
    },
    goalsInfo: {
        paddingTop: spacing.s7
    },
    loader: {
        height: LOADER_HEIGHT
    },
    overallButton: {
        marginTop: spacing.s4
    },
    updateScoreButton: {
        ...baseStyles.updateScoreButton,
        marginBottom: spacing.s5,
    },
    wasThisHelpfulBtn: {
        border: 'none',
        color: baseColors.grey40,
        backgroundColor: 'transparent'
    },
    updateScoreButtonBorder: {
        borderTop: `${spacing.s1}px solid ${baseColors.grey80}`
    },
    headerContainer: {
        justifyContent: 'flex-end',
    }
}));

export default withRouter(WithWellbeingTabBase(WellbeingTab));
