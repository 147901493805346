import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { translate } from '../../services';

export default function WithUnitFieldBase(WrappedComponent) {
    class UnitFieldBase extends PureComponent {
        static propTypes = {
            unitOptions: PropTypes.array.isRequired,
            unit: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
            onUnitChange: PropTypes.func,
            i18n: PropTypes.object.isRequired,
            optionsTranslated: PropTypes.bool
        };

        static defaultProps = {
            unit: undefined,
            value: undefined,
            error: undefined,
            onUnitChange: undefined,
            optionsTranslated: false
        };

        onUnitChange = () => {
            const { unitOptions, unit, i18n, onUnitChange, optionsTranslated } = this.props;
            let index = _.findIndex(unitOptions, option => (!optionsTranslated ? i18n.t(option) : option) === unit) + 1;
            index = index >= unitOptions.length ? 0 : index;
            if (onUnitChange) onUnitChange(unitOptions[index]);
        };

        get isUnitDisabled() {
            return this.props.unitOptions.length <= 1;
        }

        render() {
            const unit = this.props.unit || this.props.unitOptions[0];
            return (
                <WrappedComponent
                    {...this.props}
                    onUnitChange={this.props.onUnitChange ? this.onUnitChange : null}
                    isUnitDisabled={this.isUnitDisabled}
                    unit={unit}
                    customType="units"
                />
            );
        }
    }

    return translate()(UnitFieldBase);
}