import React, { PureComponent, Fragment } from 'react';
import { css, StyleSheet } from 'aphrodite-jss';
import { withRouter } from 'react-router-dom';
import { isIE } from 'react-device-detect';
import PropTypes from 'prop-types';
import * as Card from '../../Card';
import GoalProgress from '../../GoalProgress';
import WeeklyGoalTag from '../../WeeklyGoalTag';
import AboutChallenge from '../../AboutChallenge';
import { components as Core } from '../../../../core';
import CompetitionProgressCard from '../../CompetitionProgressCard';
import BonusChallengeMark from '../../BonusChallengeMark';
import confettiIcon from '../../../../../image/confettiIcon.png';
import { baseColors, layoutStyle, important, spacing } from '../../../../../styles';
import ChallengeCardBodyBase, { styles as baseStyles, CHECK_ICON_SIZE } from './ChallengeCardBodyBase';

class ChallengeCardBody extends PureComponent {
    static propTypes = {
        challengeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        isCompletedNotInGracePeriod: PropTypes.bool.isRequired,
        achievedDoubleBonus: PropTypes.bool.isRequired,
        hasSuccessPeriods: PropTypes.bool.isRequired,
        bonusEarnedText: PropTypes.string.isRequired,
        userTotalValue: PropTypes.string.isRequired,
        isInGracePeriod: PropTypes.bool.isRequired,
        challengeName: PropTypes.string.isRequired,
        isBonusEarned: PropTypes.string.isRequired,
        successLabel: PropTypes.string.isRequired,
        getInGraceInfo: PropTypes.func.isRequired,
        isCompetition: PropTypes.bool.isRequired,
        isRestartGoal: PropTypes.bool.isRequired,
        isUnstarted: PropTypes.bool.isRequired,
        totalPeriodsString: PropTypes.string,
        isInvite: PropTypes.bool.isRequired,
        isEnded: PropTypes.bool.isRequired,
        invitedByText: PropTypes.string,
        invitedBy: PropTypes.object,
        isUpdating: PropTypes.bool,
    };

    static defaultProps = {
        isUpdating: false,
        invitedBy: undefined,
        invitedByText: null,
        totalPeriodsString: null,
    };

    constructor(props) {
        super(props);
        this.state = { successLabelWidth: 0 };
    }

    handleTotalRef = ref => {
        !this.state.successLabelWidth && this.setState({ successLabelWidth: ref.clientWidth + spacing.s0 });
    };

    render() {
        const {
            challengeId, invitedBy, isInvite, isUnstarted, isInGracePeriod, getInGraceInfo, isRestartGoal,
            isCompletedNotInGracePeriod, isCompetition, isUpdating, invitedByText, isEnded, achievedDoubleBonus,
            challengeName, userTotalValue, totalPeriodsString, successLabel, bonusEarnedText, isBonusEarned,
            hasSuccessPeriods, isFeatured, isMember } = this.props;

        if (isFeatured && !isEnded && !isMember) return null;

        if (isInvite) {
            return (
                <Fragment>
                    <div className={css(layoutStyle.cardPadding)}>
                        {invitedBy ?
                            (
                                <div className={css(styles.invitedByContainer)}>
                                    <Core.Avatar
                                        id={invitedBy.id}
                                        url={invitedBy.url}
                                        size="xmsmall"
                                        name={invitedBy.name}
                                    />
                                    <p className={css(styles.invitedByText)}>
                                        {invitedByText}
                                    </p>
                                </div>
                            ) : null}
                    </div>
                    <Card.Body isGrey={false}>
                        <AboutChallenge challengeId={challengeId} isCard={true} />
                    </Card.Body>
                </Fragment>
            );
        }
        if ((isInGracePeriod || isCompletedNotInGracePeriod) && !isCompetition) {
            const { weekFinishedText } = getInGraceInfo();
            return (
                <Card.Body isGrey={false} className={css(layoutStyle.flexAlignCenter, styles.noTopPadding)}>
                    {isRestartGoal && (
                        <>
                            <p className={css(styles.smGreyFont)}>{weekFinishedText}</p>
                            <p className={css(styles.challengeName)}>{challengeName}</p>
                            <WeeklyGoalTag challengeId={challengeId} />
                        </>
                    )}
                    <div className={css(styles.inGracePeriodInnerContainer)}>
                        <div ref={this.handleTotalRef} className={css(layoutStyle.flexRowCenter)}>
                            {hasSuccessPeriods && <Core.Image src={confettiIcon} className={css(styles.confettiIcon)} />}
                            <p className={css(styles.userTotalValue)}>{userTotalValue}</p>
                            {hasSuccessPeriods && <Core.Image src={confettiIcon} className={css(styles.confettiIconFlipped)} />}
                        </div>
                        {totalPeriodsString && <p className={css(styles.periodsString)}>{totalPeriodsString}</p>}
                        <div className={css(styles.successLabel, !totalPeriodsString && styles.mTop)}>
                            {successLabel}
                        </div>
                        {isBonusEarned &&
                        (
                            <div className={css(layoutStyle.flexRowCenter, styles.graceBonusWrapper)}>
                                <BonusChallengeMark
                                    isChallengeCard={true}
                                    challengeId={challengeId}
                                    showDoubleBonusTag={achievedDoubleBonus}
                                />
                                {achievedDoubleBonus ? (
                                    <div className={css(styles.watchIconColor)}>
                                        <Core.Icon
                                            type="fa"
                                            fill="regular"
                                            name="watch-fitness"
                                            size={CHECK_ICON_SIZE}
                                            className={css(styles.watchIcon)}
                                        />
                                    </div>
                                ) : null}
                                <Core.Icon
                                    type="regular"
                                    name="check"
                                    size={CHECK_ICON_SIZE}
                                    color={baseColors.success}
                                    className={css(styles.checkIcon)}
                                />
                                <p className={css(styles.bonusEarned)}>{bonusEarnedText}</p>
                            </div>
                        )}
                    </div>
                </Card.Body>
            );
        }

        if (!isUnstarted) {
            if (isCompetition) {
                return (
                    <Card.Body
                        isGrey={false}
                        className={css(layoutStyle.noPadding, styles.competitionCardBody, isEnded && styles.isEnded)}>
                        <CompetitionProgressCard challengeId={challengeId} />
                    </Card.Body>
                );
            }

            // goal body
            return (
                <Card.Body isGrey={false} className={css(styles.noTopPadding)}>
                    <GoalProgress
                        challengeId={challengeId}
                        isUpdating={isUpdating}
                        isCurrentPeriod={true}
                    />
                </Card.Body>
            );
        }
        return null;
    }
}

export default withRouter(ChallengeCardBodyBase(ChallengeCardBody));

const styles = StyleSheet.create({
    ...baseStyles,
    noTopPadding: {
        paddingTop: important(0),
    },
    confettiIconFlipped: {
        ...baseStyles.confettiIcon,
        '-webkit-`transform': 'scaleX(-1)',
        transform: 'scaleX(-1)',
    },
    inGracePeriodInnerContainer: {
        ...baseStyles.inGracePeriodInnerContainer,
        marginTop: isIE ? 0 : 'auto',
    },
    isEnded: {
        flexGrow: 0,
    },
    watchIconColor: {
        color: baseColors.grey40,
    },
});
