import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { selectors as coreSelectors, translate } from '../../../core';
import { baseColors } from '../../../../styles';
import * as selectors from '../../selectors';
import * as actions from '../../actions';
import { PRIVACY_SLUGS_CONFIG } from '../../constants';

export default function WithPrivacyInfoModalBase(WrappedComponent) {
    class PrivacyInfoModalBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            slug: PropTypes.string.isRequired,
            isEnhancedPrivacyInformationEnabled: PropTypes.bool.isRequired,
            isHiddenPrivacyInformationText: PropTypes.bool.isRequired,
            action: PropTypes.func.isRequired,
            onRef: PropTypes.func,
        };

        static defaultProps = {
            onRef: undefined
        };

        componentDidMount() {
            this.props.onRef && this.props.onRef(this);
        }

        componentWillUnmount() {
            this.props.onRef && this.props.onRef(null);
        }

        get config() {
            return PRIVACY_SLUGS_CONFIG[this.props.slug] || {};
        }

        get label() {
            return this.props.i18n.t(this.config.infoButton);
        }

        /**
         * is used from parent instead of original action to check privacy first
         */
        action = () => {
            const { action, isHiddenPrivacyInformationText, isEnhancedPrivacyInformationEnabled } = this.props;

            if (!isEnhancedPrivacyInformationEnabled || isHiddenPrivacyInformationText) {
                return action();
            }

            return this.showModal();
        };

        /**
         * call showModal here to not duplicate action method for web and mobile
         */
        showModal = () => _.has(this, 'wrapped.showModal') && this.wrapped.showModal();

        onHidePrivacyInformationText = () => {
            this.props.action();
            this.props.actions.hidePrivacyInformationText(this.props.slug);
        };

        getModalProps = () => ({
            iconName: this.config.infoIcon,
            title: this.props.i18n.t(this.config.infoTitle),
            text: this.props.i18n.t(this.config.infoText),
            textSecondLine: this.props.i18n.t(this.config.infoTextSecondLine),
            isButtonVisible: false,
            iconBackgroundColor: baseColors.primary
        });

        saveRef = ref => this.wrapped = ref;

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    ref={this.saveRef}
                    getModalProps={this.getModalProps}
                    onConfirmPrivacyChange={this.onHidePrivacyInformationText}
                    label={this.label}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => ({
        isEnhancedPrivacyInformationEnabled: coreSelectors.isEnhancedPrivacyInformationEnabled(state),
        isHiddenPrivacyInformationText: !!selectors.isHiddenPrivacyInformationText(state, ownProps.slug)
    });

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(PrivacyInfoModalBase));
}
