import PropTypes from 'prop-types';
import React from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import { components as Core } from '../../../core';
import { layoutStyle } from '../../../../styles';
import WithHeroProductItemBase, { styles as baseStyles } from './HeroProductItemBase';

const HeroProductItem = props => (props.item ? (
    <div className={css(styles.heroSection)}>
        <div className={css(styles.heroContainer, layoutStyle.flex)}>
            <div className={css(styles.imageContainer)}>
                <Core.Image src={props.item.smallImageUrl ? props.item.smallImageUrl : null} className={css(styles.image)} />
            </div>
            <div className={css(styles.itemContainer, layoutStyle.flexJustifyAlignStart)}>
                <span className={css(styles.title)}>{props.item.productName}</span>
                <span className={css(styles.detailsReward)}>{`${props.item.pointsRequired} ${props.externalRewardName}`}</span>
            </div>
        </div>
    </div>

) : null);

HeroProductItem.propTypes = {
    item: PropTypes.object.isRequired,
    externalRewardName: PropTypes.string,
};

HeroProductItem.defaultProps = {
    externalRewardName: '',
};

export default WithHeroProductItemBase(HeroProductItem);

const styles = StyleSheet.create({
    ...baseStyles,
    title: {
        ...baseStyles.title,
        textAlign: 'left'
    }
});