import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import WithBonusRewardDetailsBase, { styles as baseStyles } from './BonusRewardDetailsBase';
import { baseColors, spacing, layoutStyle } from '../../../../styles';
import { components as Core } from '../../../core';

class BonusRewardDetails extends PureComponent {
    static propTypes = {
        formattedDate: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
        rewardText: PropTypes.string.isRequired,
        rewardValue: PropTypes.string.isRequired,
        dateEarnedText: PropTypes.string.isRequired,
    };

    render() {
        const { formattedDate, title, subtitle, rewardText, rewardValue, dateEarnedText } = this.props;
        return (
            <Core.SmallerContainer
                widerHeaderComponent={<Core.EntityDetailsHeader hasBackButton={true} />}>
                <div className={css(layoutStyle.flexColumnCenter)}>
                    <div className={css(layoutStyle.flexCenter, styles.coinsIconBg)}>
                        <Core.Icon
                            type="light"
                            name="coins"
                            size={spacing.s14}
                            color={baseColors.warn}
                        />
                    </div>
                    <p className={css(styles.title)}>{title}</p>
                    <p className={css(styles.subtitle)}>{subtitle}</p>
                    <div className={css(layoutStyle.flexSpaceAround, layoutStyle.fw, styles.infoContainer)}>
                        <div className={css(layoutStyle.flexColumnCenter)}>
                            <p className={css(styles.infoText)}>{rewardText}</p>
                            <p className={css(styles.infoValue)}>{rewardValue}</p>
                        </div>
                        <div className={css(layoutStyle.flexColumnCenter)}>
                            <p className={css(styles.infoText)}>{dateEarnedText}</p>
                            <p className={css(styles.infoValue)}>{formattedDate}</p>
                        </div>
                    </div>
                </div>
            </Core.SmallerContainer>
        );
    }
}

export default WithBonusRewardDetailsBase(BonusRewardDetails);

const styles = StyleSheet.create({
    ...baseStyles,
});