import _ from 'lodash';
import moment from 'moment';
import i18n from '../../../i18n';
import getUserName from './getUserName';

const MAX_INVITEES_TO_SHOW = 2;

export const isAfterDeadline = (date, isCompleted = false) => moment().diff(moment(date)) > 0 || isCompleted;

export const isPrivate = item => !!item.isPrivateUsersSummary;

export const getPrivateSummary = () => ({ id: 0, groupInfo: {}, location: ' ', isPrivateUsersSummary: true, });

export const getInvitedByText = invitations => {
    if (!_.isArray(invitations)) {
        return `${i18n.t('invited_by')} ${getUserName(invitations)}`;
    }
    const areTwoInvitees = invitations.length === MAX_INVITEES_TO_SHOW;
    const areMoreInvitees = invitations.length > MAX_INVITEES_TO_SHOW;
    let invitees = getUserName(invitations[0]);
    if (areTwoInvitees) invitees += ` ${i18n.t('and')} ${getUserName(invitations[1])}`;
    if (areMoreInvitees) {
        const moreInviteesNumber = invitations.length - MAX_INVITEES_TO_SHOW;
        invitees += `, ${getUserName(invitations[1])} ${i18n.t('and')} ${moreInviteesNumber} ${i18n.t('more')}`;
    }

    return `${i18n.t('invited_by')} ${invitees}`;
};
