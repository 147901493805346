import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { components as Core } from '../../../core';
import WithLikeItemBase, { styles as baseStyles } from './LikeItemBase';
import { importantClass, spacing } from '../../../../styles';

class LikeItem extends PureComponent {
    static propTypes = {
        subtitle: PropTypes.string.isRequired,
        user: PropTypes.object.isRequired,
    };

    render() {
        const { user, subtitle } = this.props;
        return (
            <Core.UserAvatarHeader
                className={css(styles.userPanel)}
                user={user}
                subtitle={subtitle}
                isAvatarEnabled={true}
                isCurrentUser={false}
            />
        );
    }
}

export default WithLikeItemBase(LikeItem);

const styles = StyleSheet.create({
    ...baseStyles,
    userPanel: importantClass({
        ...baseStyles.userPanel,
        borderBottomStyle: 'solid',
        margin: 0,
        marginLeft: spacing.s1
    })
});
