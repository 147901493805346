import { NAME as name } from './constants';
import { createActionTypes } from '../core';

const NAME = name.toUpperCase();

export const GET_EVENTS = createActionTypes(`${NAME}/GET_EVENTS`);
export const JOIN_EVENT = createActionTypes(`${NAME}/JOIN_EVENT`);
export const GET_EVENT_BY_ID = createActionTypes(`${NAME}/GET_EVENT_BY_ID`);
export const CREATE_EVENT = createActionTypes(`${NAME}/CREATE_EVENT`);
export const EDIT_EVENT = createActionTypes(`${NAME}/EDIT_EVENT`);
export const DELETE_EVENT = createActionTypes(`${NAME}/DELETE_EVENT`);
export const LEAVE_EVENT = createActionTypes(`${NAME}/LEAVE_EVENT`);
export const GET_EVENT_MEMBERS = createActionTypes(`${NAME}/GET_EVENT_MEMBERS`);
