import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import _ from 'lodash';
import { appFonts, spacing, baseColors } from '../../../../styles';
import {
    constants as coreConstants, translate, actions as coreActions, selectors as coreSelectors,
    Modal, ROUTES, DATE_FORMATS, validation, Platform, PLATFORMS, AsyncComponent
} from '../../../core';
import { getChallenge, hasTrackedChallenge, isTracking, getTrackError } from '../../selectors';
import * as actions from '../../actions';
import { getDailyMetrics } from '../../../feeds/actions';
import { CHALLENGE_TYPES, FREQUENCY, UNIT_TYPES } from '../../constants';
import { submitActivities, getTotalPointsEarned } from '../../services/activitiesHelper/activitiesHelper';
import { getActivityUnitName } from '../../services/activitiesHelper';
import { getSingleTrackedActivityRemainingProgress } from '../../services/helper';
import tracker from '../../../core/services/tracker/tracker';

export const UNIT_FIELD_LENGTH = 5;
export const MINIMUM_QUANTITY_VALUE = 1;

export default function WithActivityTrackingModalBase(WrappedComponent) {
    class ActivityTrackingModalBase extends PureComponent {
        static propTypes = {
            navigation: PropTypes.object,
            activity: PropTypes.object.isRequired,
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            onlyMeFilter: PropTypes.string.isRequired,
            deleteActivityData: PropTypes.func,
            callback: PropTypes.func,
            trackButtonLabel: PropTypes.string,
            title: PropTypes.string,
            isCheckingSkipped: PropTypes.bool,
            isLoading: PropTypes.bool,
            fromSampleTask: PropTypes.bool,
            hasTrackedChallenge: PropTypes.bool,
            fromSingleActivityGoal: PropTypes.bool,
            isDoubleTap: PropTypes.bool,
            challenge: PropTypes.object,
            progress: PropTypes.object,
            error: PropTypes.object,
            challengeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            customPointsName: PropTypes.string.isRequired,
            customPointsUnit: PropTypes.string.isRequired,
            isHomeCarousel: PropTypes.bool,
            isEditingActivityLog: PropTypes.bool,
            setUpdateActivityLogLoading: PropTypes.func.isRequired
        };

        static defaultProps = {
            isCheckingSkipped: false,
            isLoading: false,
            trackButtonLabel: undefined,
            title: null,
            deleteActivityData: null,
            fromSampleTask: false,
            callback: null,
            challenge: undefined,
            error: undefined,
            hasTrackedChallenge: false,
            fromSingleActivityGoal: false,
            isDoubleTap: false,
            challengeId: undefined,
            progress: {},
            navigation: {},
            isHomeCarousel: false,
            isEditingActivityLog: false
        };

        constructor(props) {
            super(props);
            this.state = { quantity: String(this.existingQuantity || this.initActivityValue || '') };
            this.onEditActivityLog = this.onEditActivityLog.bind(this);
        }

        async componentDidUpdate(prevProps) {
            const { isLoading, error, hasTrackedChallenge, fromSingleActivityGoal, actions, challengeId } = this.props;
            if (prevProps.isLoading && !isLoading) {
                if (!error) {
                    _.has(this, 'wrapped.dismissModal') && await this.wrapped.dismissModal();
                    if (!hasTrackedChallenge && fromSingleActivityGoal) {
                        _.has(this, 'wrapped.showFirstTrackChallengeScreen') && this.wrapped.showFirstTrackChallengeScreen();
                    }
                } else {
                    actions.clearTrackError(challengeId);
                }
            }
        }

        get existingQuantity() {
            const { activity } = this.props;
            return Number(activity.quantity);
        }

        get initActivityValue() {
            const { challenge, progress } = this.props;
            if (!this.isFieldVisible) return '1';
            const remainingProgress = getSingleTrackedActivityRemainingProgress(challenge, false, progress.trackedQuantity);
            if (!this.isOneUnitDailyGoal || !remainingProgress) return '';
            return String(remainingProgress);
        }

        get isDailyGoal() {
            const { challenge } = this.props;
            return _.get(challenge, 'challengeType') === CHALLENGE_TYPES.goal && _.get(challenge, 'frequency') === FREQUENCY.daily;
        }

        get isOneUnitDailyGoal() {
            const { challenge } = this.props;
            const unitDetails = _.get(challenge, 'activityUnitDetails', []);
            const isOneUnit = _.get(challenge, 'activityUnitsIds', []).length === 1 && unitDetails[0].unit_type !== UNIT_TYPES.category;
            return this.isDailyGoal && isOneUnit;
        }

        get isDailyGoalWithPoints() {
            const { challenge } = this.props;
            return this.isDailyGoal
                && _.toNumber(_.get(challenge, 'activityUnitDetails[0].points')) !== 0
                && _.toNumber(_.get(challenge, 'activityUnitDetails[0].amount'))
                / _.toNumber(_.get(challenge, 'activityUnitDetails[0].points')) <= 1;
        }

        get title() {
            const { title, i18n, activity, challenge } = this.props;
            return title
                || i18n.t(`trackActivityTitle.${this.isDailyGoalWithPoints ? 'oneUnit' : 'nUnit'}`, {
                    activity: activity.activityName,
                    date: moment(activity.date).format(DATE_FORMATS.monthFullDay),
                    unit: _.get(activity, 'unitSingular') || _.get(challenge, 'activityUnitDetails[0].unit_singular', '')
                });
        }

        get isFieldVisible() {
            const { fromSampleTask } = this.props;
            return !this.isDailyGoalWithPoints && !fromSampleTask;
        }

        get isRemoveVisible() {
            const { fromSingleActivityGoal, fromSampleTask, activity, isEditingActivityLog } = this.props;

            if (isEditingActivityLog) {
                return false;
            }

            return !fromSingleActivityGoal && _.toNumber(activity.quantity) > 0 && !fromSampleTask;
        }

        getTotalPointsEarned = () => getTotalPointsEarned(this._activities);

        get subtitle() {
            const { activity, customPointsUnit } = this.props;
            const quantity = this.state.quantity || '0';
            const { unitPoints } = activity;
            const unit = getActivityUnitName(activity, quantity);
            const pts = customPointsUnit;
            return `${unitPoints}${pts}/${unit} x ${quantity}${unit} = ${_.toInteger(unitPoints * quantity)}${pts}`;
        }

        get isWeb() {
            return Platform.OS === PLATFORMS.web;
        }

        get shouldShowOverTrackingModal() {
            const { activity, isCheckingSkipped } = this.props;
            const quantity = this.isDailyGoalWithPoints ? 1 : this.state.quantity;
            return quantity * activity.unitPoints >= coreConstants.WARNING_POINTS_NUMBER && !isCheckingSkipped;
        }

        async onEditActivityLog() {
            const { activity, actions, getActivityLog, setUpdateActivityLogLoading } = this.props;
            const quantity = this.isDailyGoalWithPoints ? 1 : this.state.quantity;
            const editedActivity = {
                ...activity,
                quantity
            };
            setUpdateActivityLogLoading(true);
            await actions.deleteActivityLog(activity.activityLogId, false);
            await actions.trackActivity('', [editedActivity], '', false, false, false, false, true, true);
            setTimeout(() => {
                getActivityLog(true);
            }, 1000);
        }

        onTrack = () => {
            const { activity, i18n, fromSingleActivityGoal, fromSampleTask, isEditingActivityLog } = this.props;

            if (isEditingActivityLog) {
                this.onEditActivityLog();
                if (Platform.OS === PLATFORMS.web) {
                    _.has(this, 'wrapped.dismissModal') && this.wrapped.dismissModal();
                }
                return;
            }

            const quantity = this.isDailyGoalWithPoints ? 1 : this.state.quantity;
            //if no quantity provided
            if (_.toNumber(quantity) <= 0) {
                this._showToast(i18n.t('enter_before_track'));
            } else if (_.toNumber(quantity) < MINIMUM_QUANTITY_VALUE) {
                this._showToast(i18n.t('createChallenge.quantityMinimum.message', { quantity: MINIMUM_QUANTITY_VALUE }));
            } else if (!fromSingleActivityGoal && !fromSampleTask && quantity === activity.quantity) {
                //if the quantity hasn't changed then show a warning msg
                this._showToast(i18n.t('change_before_track'));
            } else if (this.shouldShowOverTrackingModal) {
                // if users try to manually track over 1200 points, pop up an action sheet
                const submitAfterWarning = () => {
                    tracker.logEvent('Activity_Overtracking_Confirm');
                    this.track({ ...activity, quantity });
                };
                return this.openOverTrackingModal(submitAfterWarning);
            } else {
                this.track({ ...activity, quantity });
            }
        };

        openOverTrackingModal = submitAfterWarning => {
            if (this.isWeb) {
                this.closeOverTrackingModal = Modal.open(
                    AsyncComponent(() => import('../../components/OverTrackingModal')),
                    {
                        closeModal: () => this.closeOverTrackingModal(),
                        submitFunc: submitAfterWarning,
                        quantity: this.state.quantity,
                        selectedActivity: this.props.activity
                    },
                    {
                        isTransparent: true,
                        isContainer: false,
                        isNoPadding: true,
                        fadeTransition: true
                    }
                );
            }
            else {
                setTimeout(() => {
                    this.props.navigation.push(ROUTES.overTrackingModal(),
                        {
                            submitFunc: submitAfterWarning,
                            quantity: this.state.quantity,
                            selectedActivity: this.props.activity
                        });
                });
            }
        };

        _showToast = msg => {
            const { actions, i18n } = this.props;
            actions.addToast(coreConstants.TOAST_TYPES.INFO, undefined, msg, i18n.t('unable_to_track'));
        };

        track = activity => {
            const { callback } = this.props;
            if (callback) {
                callback(activity);
                _.has(this, 'wrapped.dismissModal') && this.wrapped.dismissModal();
            } else {
                this.submitActivity(activity);
            }
        };

        ///default function to be invoked if no callback is given
        submitActivity = activity => {
            const { challengeId, onlyMeFilter, isDoubleTap } = this.props;
            this._activities = [activity]; // to show points earned in CongratsFirstTrackChallenge
            submitActivities(
                challengeId,
                [activity],
                [],
                [],
                onlyMeFilter,
                isDoubleTap,
                true,
                this.changeOrDeleteActivities,
                this.trackActivity
            );
        };

        changeOrDeleteActivities = (id, ids, hasToast) => {
            const { actions } = this.props;
            actions.deleteActivityLogs(id, ids, hasToast);
        };

        trackActivity = (id, pendingActivities, filterId, text, image, isSingleChallenge) => {
            const { actions, isHomeCarousel } = this.props;
            actions.trackActivity(id, pendingActivities, filterId, text, image, isSingleChallenge, isHomeCarousel);
        };

        deleteActivity = async () => {
            const { actions, deleteActivityData, activity } = this.props;
            await _.has(this, 'wrapped.dismissModal') && this.wrapped.dismissModal();
            deleteActivityData(activity);
            actions.getDailyMetrics();
        };

        onChange = quantity => {
            const { i18n } = this.props;
            const { error } = this.state;
            const onlyIntegerOrFloatNumbers = validation.isOnlyIntegerOrFloatNumbers(quantity);
            if (quantity && (!onlyIntegerOrFloatNumbers || Number.isNaN(onlyIntegerOrFloatNumbers[0]))) {
                this.setState({ error: i18n.t('edit_profile_nan_value') });
            } else if (error !== '') {
                this.setState({ error: '' });
            }
            this.setState({ quantity });
        };

        get warningText() {
            const { i18n } = this.props;
            return `${i18n.t('thisIsALot')} ${i18n.t('adjust_entry')}`;
        }

        saveRef = ref => (this.wrapped = ref);

        render() {
            const { i18n } = this.props;
            const error = this.state.error ? i18n.t('edit_profile_nan_value') : undefined;
            return (
                <WrappedComponent
                    {...this.props}
                    ref={this.saveRef}
                    onTrack={this.onTrack}
                    deleteActivity={this.deleteActivity}
                    onChange={this.onChange}
                    title={this.title}
                    isFieldVisible={this.isFieldVisible}
                    isRemoveVisible={this.isRemoveVisible}
                    getTotalPointsEarned={this.getTotalPointsEarned}
                    quantity={this.state.quantity}
                    subtitle={this.subtitle}
                    error={error}
                    shouldShowOverTrackingModal={this.shouldShowOverTrackingModal}
                    warningText={this.warningText}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        const routeParams = _.get(ownProps, 'route.params');
        const challengeId = _.get(ownProps, 'challengeId') || _.get(routeParams, 'challengeId');
        return {
            ...routeParams,
            hasTrackedChallenge: hasTrackedChallenge(state),
            onlyMeFilter: _.get(coreSelectors.getOnlyMeFilter(state), 'filterId'),
            challenge: getChallenge(state, challengeId),
            isLoading: isTracking(state, ownProps.challengeId),
            error: getTrackError(state, ownProps.challengeId),
            customPointsName: coreSelectors.getCustomPointsName(state),
            customPointsUnit: coreSelectors.getCustomPointsUnit(state)
        };
    }

    const mapDispatchToProps = dispatch => ({
        actions: bindActionCreators({ ...actions, ...coreActions, getDailyMetrics }, dispatch)
    });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(ActivityTrackingModalBase));
}

export const styles = {
    container: {
        flex: 0,
        backgroundColor: baseColors.white,
        borderTopRightRadius: spacing.s2,
        borderTopLeftRadius: spacing.s2
    },
    body: {
        flex: 0,
        marginBottom: spacing.s3
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%'
    },
    title: {
        ...appFonts.mdBold,
        paddingRight: spacing.s3,
        flex: 0
    },
    subtitle: {
        ...appFonts.smRegular,
        color: baseColors.grey40,
        marginTop: spacing.s1,
        paddingRight: spacing.s3
    },
    buttonContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        display: 'flex'
    },
    deleteButton: {
        flex: 1,
        marginRight: spacing.s0,
        backgroundColor: baseColors.danger,
        borderColor: baseColors.danger
    },
    trackButton: {
        flex: 2
    },
    addButton: {
        flex: 1
    },
    warningText: {
        ...appFonts.smRegular,
        marginLeft: spacing.s1,
        marginBottom: spacing.s1,
        color: baseColors.grey40,
    },
    warnColorText: {
        color: baseColors.warnDarker
    },
    circleIcon: {
        marginRight: spacing.s0,
        marginLeft: spacing.s0
    },
    activityNameBold: {
        color: baseColors.grey20,
        ...appFonts.mdBold
    },
    activityDay: {
        color: baseColors.grey20,
        ...appFonts.mdRegular
    }
};
