import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';
import { withRouter } from 'react-router-dom';

import { components as Core } from '../../../core';
import { importantStyles, listItemImageSize, layoutStyle, spacing } from '../../../../styles';
import WithResourceLinkItemBase, { styles as baseStyles } from './ResourceLinkItemBase';

class ResourceLinkItem extends PureComponent {
    static propTypes = {
        image: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        goToResourceLinkItem: PropTypes.func.isRequired
    };

    render() {
        const { image, title } = this.props;
        return (
            <Core.TouchableItem
                onPressSingle={this.props.goToResourceLinkItem}
                containerClassName={css(layoutStyle.entityItemMainContainer, layoutStyle.flex, styles.mainContainer)}
                innerContainerClassName={css(layoutStyle.flex, layoutStyle.flex1)}
                image={image}
                hasImageObjectFitContain={false}
                imageWidth={listItemImageSize}
                imageHeight={listItemImageSize}>
                <div className={css(styles.cardHeaderLeft)}>
                    <div className={css(styles.title)}>
                        <Core.InnerHtml htmlText={title} />
                    </div>
                </div>
            </Core.TouchableItem>
        );
    }
}

export default withRouter(WithResourceLinkItemBase(ResourceLinkItem));

const styles = StyleSheet.create({
    ...importantStyles({
        ...baseStyles,
        cardHeaderLeft: {
            ...baseStyles.cardHeaderLeft,
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        title: {
            ...baseStyles.title,
            textAlign: 'left',
            marginRight: spacing.s3
        }
    }),
    mainContainer: {
        border: 'none',
        paddingTop: spacing.s0,
        paddingBottom: spacing.s0
    }
});