import axios from 'axios';
import { getBaseUrl } from '../core';
import { ENTITIES, USERS_MAX_COUNT } from './constants';

export function getUsersToInvite(parameters) {
    const params = { action: 'search_users', offset: 0, search: '', maxCount: USERS_MAX_COUNT, ...parameters };
    return axios.get(getBaseUrl('memberships/challengees'), { params });
}

export function inviteToEntity(entity, entityId, params) {
    let url;
    switch (entity) {
        case ENTITIES.COMMUNITY:
            url = getBaseUrl(`groups/communities/${entityId}/invitations`);
            break;
        case ENTITIES.CHALLENGE:
            url = getBaseUrl(`challenges/${entityId}/invitations`);
            break;
        case ENTITIES.EVENT:
            url = getBaseUrl(`events/${entityId}/invitations`);
            break;
        case ENTITIES.TEAM:
        default:
            url = getBaseUrl(`challenges/teams/${entityId}/invites`);
    }
    return axios.post(url, params);
}

export function getUsersToPlatformInvitation(parameters) {
    const params = { corporate: 1, offset: 0, maxCount: USERS_MAX_COUNT, ...parameters };
    return axios.get(getBaseUrl('users/platform_invitation_suggestions'), { params });
}

export function getPlatformInvitations() {
    return axios.get(getBaseUrl('users/platform_invitations'));
}

export function sendPlatformInvitations(email, userId) {
    return axios.post(getBaseUrl('users/platform_invitations'), email ? { email } : { userId });
}

export function deletePlatformInvitation(id) {
    return axios.delete(getBaseUrl(`users/platform_invitations/${id}`));
}

export function getUserByEmail(email) {
    return axios.get(getBaseUrl('users/platform_invitation_suggestions'), { params: { email } });
}
