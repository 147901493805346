import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ROUTES, components as Core } from '../../../core';
import JoinChallengeByTeamIdBase from './JoinChallengeByTeamIdBase';

class JoinChallengeByTeamId extends PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
        joinChallengeByTeamId: PropTypes.func.isRequired,
        isJoiningChallengeByTeamId: PropTypes.bool.isRequired,
        challengeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    };

    static defaultProps = {
        challengeId: undefined,
    };

    componentDidMount() {
        this.props.joinChallengeByTeamId();
    }

    goToChallenges = () => {
        this.props.history.push(ROUTES.challenges());
    };

    goToChallengeDetails = () => {
        this.props.history.push(ROUTES.challengeDetails(this.props.challengeId));
    };

    render() {
        return <Core.BlockingLoading isLoading={this.props.isJoiningChallengeByTeamId} />;
    }
}

export default JoinChallengeByTeamIdBase(JoinChallengeByTeamId);
