import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { tracker, translate } from '../../../core';
import * as actions from '../../actions';
import {
    isInGracePeriod,
    isCompletedNotInGracePeriod,
    isCompetition,
    isGoal,
    isBonusRecommendedGoal,
    isTeamSizeIncorrect
} from '../../services/helper';
import { getChallenge, isRestartGoal, getChallengeLeaderboard, isBonusChallenge } from '../../selectors';
import { FREQUENCY } from '../../constants';
import { appFonts, baseColors, spacing } from '../../../../styles';

export default function WithChallengeCardBase(WrappedComponent) {
    class ChallengeCardBase extends PureComponent {
        static propTypes = {
            challengeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            isRestartGoal: PropTypes.bool.isRequired,
            challenge: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            isHomeCarousel: PropTypes.bool,
            isDisabled: PropTypes.bool,
            i18n: PropTypes.object.isRequired,
            isBonusChallenge: PropTypes.number
        };

        static defaultProps = {
            isHomeCarousel: false,
            isDisabled: false,
            isBonusChallenge: 0
        };

        constructor(props) {
            super(props);
            this.props.actions.getChallengeAdditionalInfoForCard(props.challengeId);
            this.state = {
                weeklyGoalGraceCardsViewed: [],
            };
        }

        onWeeklyGoalGraceCardClick = () => tracker.logEvent('WeeklyGoal_GraceCard_Click');

        onWeeklyGoalGraceCardView = slideIndex => {
            if (!_.includes(this.state.weeklyGoalGraceCardsViewed, slideIndex)) {
                tracker.logEvent('WeeklyGoal_GraceCard_View');
                this.setState(
                    prevState => ({ weeklyGoalGraceCardsViewed: [...prevState.weeklyGoalGraceCardsViewed, slideIndex] })
                );
            }
        };

        onPress = () => {
            if (!this.props.isHomeCarousel) return;
            const eventName = this.isGoal ? 'ActivityTile_Goal_Click' : 'ActivityTile_Challenge_Click';
            tracker.logEvent(eventName);
        };

        get isGoal() {
            return isGoal(this.props.challenge);
        }

        get isRestartGoalInGracePeriod() {
            const { challenge, isRestartGoal } = this.props;
            return isRestartGoal && isInGracePeriod(challenge);
        }

        get animation() {
            const animation = require('./confetti.json');
            return animation;
        }

        get userTotalPercentage() {
            return Math.round(_.get(this.props, 'challenge.progress.overallUserTotalPercentage'));
        }

        get leaderboardPosition() {
            const myLeaderboard = _.find(_.get(this.props.leaderboard, 'items', []), item => item.isViewer === 1);
            const leaderboardRank = _.get(myLeaderboard, 'rank');
            return leaderboardRank || _.get(this.props, 'challenge.progress.rank');
        }

        get hasTopRank() {
            return isCompetition(this.props.challenge) && this.leaderboardPosition <= 3;
        }

        get hasCompetitionLoggedActivities() {
            const { challenge } = this.props;
            return isCompetition(challenge)
                && !!_.get(challenge, 'progress.overallAverageValue');
        }

        get showAnimation() {
            const { challenge } = this.props;
            return isCompletedNotInGracePeriod(challenge)
                && (this.userTotalPercentage >= 100 || this.hasTopRank)
                && this.hasCompetitionLoggedActivities
                && !isTeamSizeIncorrect(challenge);
        }

        get streakDetails() {
            const { i18n, challenge: { frequency, userCompletedPeriodsStreak }, challenge } = this.props;
            if (!challenge.isEnded) {
                if (userCompletedPeriodsStreak > 1 && frequency === FREQUENCY.daily) return i18n.t('dayStreak', { n: userCompletedPeriodsStreak });
                if (userCompletedPeriodsStreak > 1 && frequency === FREQUENCY.weekly) return i18n.t('weekStreak', { n: userCompletedPeriodsStreak });
            }
            return null;
        }

        render() {
            const { isBonusChallenge } = this.props;

            return (
                <WrappedComponent
                    {...this.props}
                    isGoal={this.isGoal}
                    onPress={this.onPress}
                    animation={this.animation}
                    showAnimation={this.showAnimation}
                    isRestartGoalInGracePeriod={this.isRestartGoalInGracePeriod}
                    onWeeklyGoalGraceCardClick={this.onWeeklyGoalGraceCardClick}
                    onWeeklyGoalGraceCardView={this.onWeeklyGoalGraceCardView}
                    streakDetails={this.streakDetails}
                    isBonusChallenge={isBonusChallenge}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => ({
        challenge: getChallenge(state, ownProps.challengeId),
        isRestartGoal: isRestartGoal(state, ownProps.challengeId),
        leaderboard: getChallengeLeaderboard(state, ownProps.challengeId),
        isBonusChallenge: ownProps.challengeId
            ? isBonusChallenge(state, ownProps.challengeId)
            : isBonusRecommendedGoal(_.get(ownProps, 'goal')),
    });

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(ChallengeCardBase));
}

export const styles = {
    streakText: {
        ...appFonts.smRegular,
        color: baseColors.primary,
        marginLeft: spacing.s3,
        flexShrink: 0,
    },
};
