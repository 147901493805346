import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite-jss';

import { baseColors } from '../../../../styles';
import { components as Core } from '../../../core';
import withBonusMarkBase, { styles as baseStyles, ICON_SIZE, ICON_NAME } from './BonusMarkBase';

class BonusMark extends PureComponent {
    static propTypes = {
        icon: PropTypes.node,
        hasReward: PropTypes.bool,
        showBonusText: PropTypes.bool,
        isAchievement: PropTypes.bool,
        labelClassName: PropTypes.string,
        isRead: PropTypes.bool.isRequired,
        containerClassName: PropTypes.string,
        bonusLabel: PropTypes.string.isRequired,
        earnedBonusText: PropTypes.string.isRequired
    };

    static defaultProps = {
        icon: undefined,
        hasReward: false,
        showBonusText: false,
        isAchievement: false,
        labelClassName: undefined,
        containerClassName: undefined,
    };

    get checkIconSection() {
        const { isRead, showBonusText, earnedBonusText } = this.props;
        return isRead ? (
            <>
                <Core.Icon type="regular" name={ICON_NAME} size={ICON_SIZE} color={baseColors.success} />
                {showBonusText && <p className={css(styles.earnedBonusText)}>{earnedBonusText}</p>}
            </>
        ): null;
    }

    render() {
        const { hasReward, bonusLabel, containerClassName, labelClassName, icon, isAchievement } = this.props;
        return (
            hasReward ? (
                <div className={classNames(css(styles.bonusWrapper), containerClassName)}>
                    <div className={classNames(css(styles.bonusText, styles.bonusBg, labelClassName, isAchievement && styles.achievementBonusBg))}>
                        {icon}
                        {bonusLabel}
                    </div>
                    {this.checkIconSection}
                </div>
            ) : null
        );
    }
}

export default withBonusMarkBase(BonusMark);

const styles = StyleSheet.create({
    ...baseStyles,
});