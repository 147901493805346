import _ from 'lodash';
import * as types from './actionTypes';
import * as api from './api';
import { normalize, selectors as coreSelectors, getInapropriateErrors, actions as coreActions } from '../core';
import { LIKES_ENTITY_TYPES, LIKES_TYPE_IDS_BY_TYPE } from './constants';
import { validateError } from '../../config';
import { actions as streamActions, selectors as streamSelectors } from '../feeds';

export function addLike(likedItemId, toUserId, entityType = LIKES_ENTITY_TYPES.stream) {
    const data = { typeId: LIKES_TYPE_IDS_BY_TYPE[entityType], likedItemId, toUserId };
    return function (dispatch, getState) {
        dispatch({ type: types.ADD_LIKE.REQUEST, payload: { requestId: likedItemId } });
        return api.addLike(data)
            .then(res => {
                dispatch({
                    type: types.ADD_LIKE.SUCCESS,
                    payload: {
                        requestId: likedItemId,
                        entityType,
                        like: res.data,
                        likedItemId,
                        user: coreSelectors.getCurrentUser(getState())
                    }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.ADD_LIKE.ERROR, payload: { requestId: likedItemId, error } });
            }));
    };
}

export function deleteLike(likedItemId, likeId, entityType = LIKES_ENTITY_TYPES.stream) {
    return function (dispatch) {
        dispatch({ type: types.DELETE_LIKE.REQUEST, payload: { requestId: likedItemId } });
        return api.deleteLike(likeId)
            .then(() => {
                dispatch({
                    type: types.DELETE_LIKE.SUCCESS,
                    payload: { requestId: likedItemId, entityType, likeId, likedItemId }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.DELETE_LIKE.ERROR, payload: { requestId: likedItemId, error } });
            }));
    };
}

export function moderateComment(commentId, commentedItemId, commentedItemType = LIKES_ENTITY_TYPES.stream) {
    const actionType = 'moderatingComment';
    return function (dispatch) {
        dispatch({ type: types.MODERATE_COMMENT.REQUEST });
        return api.moderateComment(commentId)
            .then(() => {
                dispatch({
                    type: types.MODERATE_COMMENT.SUCCESS,
                    payload: { commentedItemId, commentId, commentedItemType, toast: { actionType } }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.MODERATE_COMMENT.ERROR, payload: { error, toast: { actionType } } });
            }));
    };
}

export function deleteComment(commentId, commentedItemId, commentedItemType = LIKES_ENTITY_TYPES.stream) {
    const actionType = 'deletingComment';
    return function (dispatch) {
        dispatch({ type: types.DELETE_COMMENT.REQUEST });
        return api.deleteComment(commentId)
            .then(() => {
                dispatch(deleteCommentSuccess(commentedItemId, commentId, commentedItemType, { actionType }));
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.DELETE_COMMENT.ERROR, payload: { error, toast: { actionType } } });
            }));
    };
}

function deleteCommentSuccess(commentedItemId, commentId, commentedItemType, toast) {
    return {
        type: types.DELETE_COMMENT.SUCCESS,
        payload: { commentedItemId, commentId, commentedItemType, toast }
    };
}

export function editComment(comment, oldComment) {
    const actionType = 'editingComment';
    return function (dispatch) {
        dispatch(editCommentSuccess(comment));
        dispatch({ type: types.EDIT_COMMENT.REQUEST });
        return api.editComment(comment)
            .then(res => {
                dispatch(editCommentSuccess(res.data, { actionType }));
            })
            .catch(error => validateError(error, error => {
                const inapropriateErrors = getInapropriateErrors(error);
                dispatch({
                    type: types.EDIT_COMMENT.ERROR,
                    payload: {
                        toast: inapropriateErrors ? null : { actionType },
                        error: inapropriateErrors ? _.assign(error.response.data, { type: inapropriateErrors }) : error,
                        isErrorStore: inapropriateErrors
                    }
                });
            }));
    };
}

function editCommentSuccess(comment, toast) {
    return {
        type: types.EDIT_COMMENT.SUCCESS,
        payload: { comment, toast }
    };
}

export function postComment(commentedItemType, commentedItemId, commentText, tempComment) {
    const actionType = 'postingComment';
    return function (dispatch) {
        dispatch(postCommentSuccess(tempComment, commentedItemId, commentedItemType));
        dispatch({ type: types.POST_COMMENT.REQUEST });
        return api.postComment({ commentedItemId, commentText })
            .then(res => {
                dispatch(deleteCommentSuccess(commentedItemId, tempComment.commentId, commentedItemType));
                dispatch(postCommentSuccess(res.data, commentedItemId, commentedItemType));
            })
            .catch(error => validateError(error, error => {
                const inapropriateErrors = getInapropriateErrors(error);
                dispatch(deleteCommentSuccess(commentedItemId, tempComment.commentId, commentedItemType));
                dispatch({
                    type: types.POST_COMMENT.ERROR,
                    payload: {
                        toast: inapropriateErrors ? null : { actionType },
                        error: inapropriateErrors ? _.assign(error.response.data, { type: inapropriateErrors }) : error,
                        isErrorStore: inapropriateErrors
                    }
                });
            }));
    };
}

function postCommentSuccess(comment, commentedItemId, commentedItemType) {
    return {
        type: types.POST_COMMENT.SUCCESS,
        payload: { comment, commentedItemId, commentedItemType }
    };
}

export function getComments(commentedItemType = LIKES_ENTITY_TYPES.stream, commentedItemId, earlierId, laterId, maxCount) {
    // const actionType = 'gettingAll';
    let isReverse = true;
    const params = { commentedItemId, maxCount };
    if (earlierId && Number.isInteger(earlierId)) {
        params.idLessThan = earlierId;
    } else if (laterId && Number.isInteger(laterId)) {
        params.idGreaterThan = laterId;
        isReverse = false;
    }
    return function (dispatch) {
        dispatch({ type: types.GET_MORE_COMMENTS.REQUEST });
        return api.getComments(params)
            .then(res => {
                dispatch({
                    type: types.GET_MORE_COMMENTS.SUCCESS,
                    payload: {
                        ...normalize.normalizeArray(res.data, 'commentId'),
                        commentedItemType,
                        commentedItemId,
                        isReverse
                    }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.GET_MORE_COMMENTS.ERROR, payload: { error } });
            }));
    };
}

export function getLikes(likedItemType = LIKES_ENTITY_TYPES.stream, likedItemId, offset) {
    const params = { offset };
    return function (dispatch) {
        dispatch({ type: types.GET_LIKES.REQUEST });
        return api.getLikes(likedItemId, params)
            .then(res => {
                dispatch({
                    type: types.GET_LIKES.SUCCESS,
                    payload: {
                        ...normalize.normalizeArray(res.data, 'id'),
                        likedItemType,
                        likedItemId,
                        isReset: !offset
                    }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.GET_MORE_COMMENTS.ERROR, payload: { error } });
            }));
    };
}

export function clearPostError() {
    return coreActions.removeError(types.POST_COMMENT.NAME);
}

export function clearEditError() {
    return coreActions.removeError(types.EDIT_COMMENT.NAME);
}

export function getReactionEmojis() {
    return function (dispatch) {
        dispatch({ type: types.GET_REACTION_EMOJIS.REQUEST });
        return api.getReactionEmojis()
            .then(response => {
                dispatch({
                    type: types.GET_REACTION_EMOJIS.SUCCESS,
                    payload: { data: response.data }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.GET_REACTION_EMOJIS.ERROR, payload: { error } });
            }));
    };
}

export function addReaction(likedItemId, toUserId, itemType, emojiTypeId, reaction) {
    return function (dispatch) {
        dispatch(updateStoredReactionCount(likedItemId, reaction, true));

        dispatch({ type: types.ADD_REACTION_EMOJI.REQUEST });
        const params = { likedItemId, toUserId, typeId: LIKES_TYPE_IDS_BY_TYPE[itemType], emojiTypeId };
        return api.addReaction(params)
            .then(response => {
                dispatch({
                    type: types.ADD_REACTION_EMOJI.SUCCESS
                });
                dispatch(streamActions.getStreamItem(likedItemId));
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.ADD_REACTION_EMOJI.ERROR, payload: { error } });
            }));
    };
}

export function removeReaction(likeId, likedItemId, reaction) {
    return function (dispatch) {
        dispatch(updateStoredReactionCount(likedItemId, reaction, false));

        dispatch({ type: types.REMOVE_REACTION_EMOJI.REQUEST });
        return api.removeReaction(likeId)
            .then(response => {
                dispatch({
                    type: types.REMOVE_REACTION_EMOJI.SUCCESS
                });
                dispatch(streamActions.getStreamItem(likedItemId));
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.REMOVE_REACTION_EMOJI.ERROR, payload: { error } });
            }));
    };
}
export function getReactions(likedItemType = LIKES_ENTITY_TYPES.stream, likedItemId, offset) {
    const params = { offset };
    return function (dispatch) {
        dispatch({ type: types.GET_REACTIONS.REQUEST });
        return api.getReactions(likedItemId, params)
            .then(res => {
                dispatch({
                    type: types.GET_REACTIONS.SUCCESS,
                    payload: {
                        ...normalize.normalizeArray(res.data, 'id'),
                        likedItemType,
                        likedItemId,
                        isReset: !offset
                    }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.GET_REACTIONS.ERROR, payload: { error } });
            }));
    };
}

export function updateStoredReactionCount(likedItemId, reaction, isAdding) {
    return function (dispatch, getState) {
        const itemReactions = streamSelectors.getReactionsForStreamItem(getState(), likedItemId);
        let itemReactionsCopy = [...itemReactions];
        const currentReaction = _.find(itemReactionsCopy, item => item.slug === reaction.slug);
        const index = _.indexOf(itemReactionsCopy, currentReaction);
        const currentReactionCopy = { ...currentReaction };

        const userReactionList = streamSelectors.getUserReactionsListForStreamItem(getState(), likedItemId);
        let userReactionListCopy = [...userReactionList];

        const reactionsCount = streamSelectors.getReactionsCountForStreamItem(getState(), likedItemId);
        let newReactionsCount = reactionsCount;

        if (isAdding) {
            const userId = coreSelectors.getCurrentUser(getState()).userId;
            const firstname = coreSelectors.getUserFirstName(getState());
            const lastname = coreSelectors.getUserLastName(getState());
            const fromUser = { userId, firstname, lastname };

            if (_.get(currentReactionCopy, 'count')) {
                if (_.get(currentReactionCopy, 'userReacted') === 0) {
                    currentReactionCopy.count += 1;
                    currentReactionCopy.userReacted = 1;
                    itemReactionsCopy[index] = currentReactionCopy;
                }
            } else {
                const newReaction = { slug: reaction.slug, count: 1, userReacted: 1 };
                itemReactionsCopy = _.concat(itemReactionsCopy, newReaction);
                itemReactionsCopy = _.orderBy(itemReactionsCopy, 'slug', ['desc']);

                userReactionListCopy = _.concat(userReactionListCopy, fromUser);
            }

            newReactionsCount += 1;
        }
        else {
            if (currentReactionCopy.count === 1) {
                itemReactionsCopy = _.filter(itemReactionsCopy, item => item.slug !== reaction.slug);
            } else {
                currentReactionCopy.count -= 1;
                currentReactionCopy.userReacted = 0;
                itemReactionsCopy[index] = currentReactionCopy;
            }

            newReactionsCount -= 1;
        }

        dispatch(streamActions.tempAddReaction(likedItemId, itemReactionsCopy, newReactionsCount, userReactionListCopy));
    };
}
