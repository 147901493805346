import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import _ from 'lodash';

import {
    components as Core,
    translate,
    tracker,
    firebaseEventNamesHelper,
    Storage,
    Platform,
    PLATFORMS
} from '../../../core';
import {
    isLoadingLeaderboards,
    getFormattedTimeFilters,
    getFormattedActivities,
    getDefaultTimeFilter,
    getDefaultActivitiesFilter,
    getFormatedLeaderboardChallenges
} from '../../selectors';
import { SELECTED_FILTER, SHOULD_REMEMBER_FILTER } from '../../constants';

export const TYPES_ENTITY = {
    ACTIVITY: 'ACTIVITY',
    CHALLENGE: 'CHALLENGE'
};

export default function WithLeaderboardsFiltersBase(WrappedComponent) {
    class LeaderboardsFiltersBase extends PureComponent {
        static propTypes = {
            defaultTimeFilter: PropTypes.object,
            defaultActivitiesFilter: PropTypes.object,
            fetcher: PropTypes.func.isRequired,
            isLeaderBoard: PropTypes.bool,
            setTimeFilter: PropTypes.func,
            i18n: PropTypes.object.isRequired,
            challenges: PropTypes.object,
            activitiesFilters: PropTypes.object,
            timeFilters: PropTypes.object,
            onRef: PropTypes.func,
            setChallengeId: PropTypes.func.isRequired
        };

        static defaultProps = {
            defaultTimeFilter: {},
            defaultActivitiesFilter: {},
            isLeaderBoard: false,
            setTimeFilter: null,
            challenges: {},
            activitiesFilters: {},
            timeFilters: {},
            onRef: null
        };

        constructor(props) {
            super(props);
            const { fetcher, defaultTimeFilter, defaultActivitiesFilter } = props;
            const timeFilterId = _.get(defaultTimeFilter, 'id');
            if (timeFilterId) {
                setTimeout(() => fetcher({ timePeriodId: timeFilterId }));
            }
            if (this.props.setTimeFilter) this.props.setTimeFilter(timeFilterId);
            const timeFilter = timeFilterId ? defaultTimeFilter : null;
            if (timeFilter) this.logFirebaseFilterEvent(timeFilter.referenceName);
            this.state = {
                selectedTimeFilter: timeFilter,
                selectedActivitiesFilter: defaultActivitiesFilter,
                selectedChallengeFilter: null
            };
        }

        async componentDidMount() {
            if (this.props.onRef) this.props.onRef(this);

            if (Platform.OS === PLATFORMS.web) {

                const selectedChallengeFilter = await Storage.getItem(SELECTED_FILTER);
                const shouldRememberFilter = await Storage.getItem(SHOULD_REMEMBER_FILTER);
                if (shouldRememberFilter && selectedChallengeFilter) {
                    await Storage.removeItem(SELECTED_FILTER);
                    await Storage.removeItem(SHOULD_REMEMBER_FILTER);
                    setTimeout(() => {
                        this.setState({ selectedChallengeFilter });
                        this.getLeaderboard();
                    }, 1000);
                }
            }
        }

        logFirebaseFilterEvent = referenceName => {
            tracker.logEvent(`Leaderboard_${firebaseEventNamesHelper(referenceName, true)}_View`);
        }

        componentWillUnmount() {
            if (this.props.onRef) this.props.onRef(null);
        }

        get modalActivitiesLabel() {
            return this.props.defaultActivitiesFilter.id === '8' ?
                this.props.i18n.t('showAll') : this.props.defaultActivitiesFilter.label;
        }

        get leaderboardParams() {
            const { selectedTimeFilter, selectedActivitiesFilter, selectedChallengeFilter } = this.state;
            let params = {};
            const timePeriodParams = { timePeriodId: selectedTimeFilter.id };
            if (this.props.isLeaderBoard) {
                if (selectedChallengeFilter) {
                    params = { challengeId: selectedChallengeFilter.id };
                    this.props.setChallengeId(selectedChallengeFilter.id);
                } else {
                    params = { ...timePeriodParams, activityFilterId: selectedActivitiesFilter.id };
                }
            } else {
                params = { ...timePeriodParams };
            }
            return params;
        }

        getLeaderboard = () => this.props.fetcher(this.leaderboardParams);

        get isFilterChallenge() {
            return !!this.state.selectedChallengeFilter;
        }

        get timeFilterProps() {
            return {
                options: this.props.timeFilters,
                selected: this.state.selectedTimeFilter,
                title: _.get(this.state.selectedTimeFilter, 'label'),
                modalTitle: this.props.i18n.t('chooseTimePeriod'),
                subtitle: this.props.i18n.t('youCanFilterOneAtTime'),
            };
        }

        onChangeTimeFilter = filter => {
            const { setTimeFilter, timeFilters } = this.props;
            if (filter && (filter.id !== _.get(this.state.selectedTimeFilter, 'id'))) {
                if (setTimeFilter) setTimeFilter(filter.id);
                const selectedTimeFilter = _.find(timeFilters, { id: filter.id });
                this.logFirebaseFilterEvent(selectedTimeFilter.referenceName);
                this.setState(() => ({ selectedTimeFilter }),
                    this.getLeaderboard);
            }
        };

        onChangeChallengeFilter = filter => {
            if (filter && (!this.isFilterChallenge || filter.id !== this.state.selectedChallengeFilter.id)) {
                const selectedChallengeFilter = _.find(this.props.challenges, { id: filter.id });
                this.setState(() => ({ selectedChallengeFilter }),
                    this.getLeaderboard);
            }
        }

        onChangeActivityFilter = filter => {
            const { activitiesFilters } = this.props;
            if (filter && (filter.id !== this.state.selectedActivitiesFilter.id || this.isFilterChallenge)) {
                const selectedActivitiesFilter = _.find(activitiesFilters, { id: filter.id });
                this.setState(() => ({
                    selectedActivitiesFilter,
                    selectedChallengeFilter: null
                }), this.getLeaderboard);
            }
        }

        get typeFilter() { return { id: 'type', ...this.activityOrChallengeFilterProps }; }

        get timeFilter() { return { id: 'time', ...this.timeFilterProps }; }

        get itemSelectActivityOrChallenge() {
            const activityName = _.get(this.state.selectedActivitiesFilter, 'id') === this.props.defaultActivitiesFilter.id ?
                this.modalActivitiesLabel : _.get(this.state.selectedActivitiesFilter, 'label');
            return [
                {
                    id: TYPES_ENTITY.ACTIVITY,
                    label: this.isFilterChallenge ? this.props.i18n.t('showActivityInstead') : this.props.i18n.t('activitiesShowSame', { activityName }),
                    filterTitle: this.activityChallengeFilterTitle,
                },
                {
                    id: TYPES_ENTITY.CHALLENGE,
                    label: this.isFilterChallenge ?
                        this.props.i18n.t('challengeShowSame', { challengeName: this.state.selectedChallengeFilter.label }) :
                        this.props.i18n.t('showChallengeInstead'),
                    filterTitle: this.activityChallengeFilterTitle,
                }
            ];
        }

        get activityChallengeFilterTitle() {
            return this.isFilterChallenge ? this.state.selectedChallengeFilter.label : _.get(this.state.selectedActivitiesFilter, 'label');
        }

        get activityOrChallengeFilterProps() {
            const selectedId = this.isFilterChallenge ? TYPES_ENTITY.CHALLENGE : TYPES_ENTITY.ACTIVITY;
            return {
                options: this.itemSelectActivityOrChallenge,
                selected: _.find(this.itemSelectActivityOrChallenge, { id: selectedId }),
                modalTitle: this.props.i18n.t('chooseLeaderboard'),
                subtitle: this.props.i18n.t('filterOneOfFollowing'),
                checkboxType: Core.Checkbox.TYPES.button,
                title: this.activityChallengeFilterTitle,
            };
        }

        get challengeFilterScreenParams() {
            return { title: this.props.i18n.t('challenge') };
        }

        get challengeFilterPropsToPass() {
            return {
                options: this.props.challenges.length > 0 && this.props.challenges,
                selected: { id: _.get(this, 'state.selectedChallengeFilter.id') },
                singleSelect: true,
                isFilter: true,
                checkboxType: Core.Checkbox.TYPES.rounded,
                isDoneUsed: true,
            };
        }

        get activityFilterScreenParams() {
            return { title: this.props.i18n.t('me_tabs.activity') };
        }

        get activityFilterPropsToPass() {
            return {
                options: this.props.activitiesFilters,
                selected: { id: this.state.selectedActivitiesFilter.id },
                singleSelect: true,
                isFilter: true,
                checkboxType: Core.Checkbox.TYPES.rounded,
                isDoneUsed: true,
            };
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    selectedChallengeFilter={this.state.selectedChallengeFilter}
                    onChangeTimeFilter={this.onChangeTimeFilter}
                    onChangeChallengeFilter={this.onChangeChallengeFilter}
                    typeFilter={this.typeFilter}
                    timeFilter={this.timeFilter}
                    challengeFilterScreenParams={this.challengeFilterScreenParams}
                    challengeFilterPropsToPass={this.challengeFilterPropsToPass}
                    onChangeActivityFilter={this.onChangeActivityFilter}
                    activityFilterScreenParams={this.activityFilterScreenParams}
                    activityFilterPropsToPass={this.activityFilterPropsToPass}
                />
            );
        }
    }

    function mapStateToProps(state) {
        return {
            defaultTimeFilter: getDefaultTimeFilter(state),
            defaultActivitiesFilter: getDefaultActivitiesFilter(state),
            isLoading: isLoadingLeaderboards(state),
            timeFilters: getFormattedTimeFilters(state),
            challenges: getFormatedLeaderboardChallenges(state),
            activitiesFilters: getFormattedActivities(state),
        };
    }

    return connect(mapStateToProps)(translate()(LeaderboardsFiltersBase));
}
