import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import translate from '../../services/translate';
import { spacing } from '../../../../styles';

export default function WithMultiRowButtonsBase(WrappedComponent) {
    class MultiRowButtonsBase extends PureComponent {
        static propTypes = {
            onPressCallback: PropTypes.func.isRequired,
            radioValues: PropTypes.array.isRequired
        };

        selectOption = metadata => this.props.onPressCallback(metadata.value);

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    selectOption={this.selectOption}
                />
            );
        }
    }

    return translate()(MultiRowButtonsBase);
}

export const styles = {
    firstButton: {
        borderTopLeftRadius: spacing.s2,
        borderBottomLeftRadius: spacing.s2,
        borderRightWidth: 0
    },
    lastButton: {
        borderTopRightRadius: spacing.s2,
        borderBottomRightRadius: spacing.s2,
        borderLeftWidth: 0
    }
};