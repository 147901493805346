import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import _ from 'lodash';
import { layoutStyle, spacing } from '../../../../styles';
import { components as Core, ROUTES } from '../../../core';
import WithWelcomeBase, { DEFAULT_ICON_SIZE, styles as baseStyles } from './WelcomeBase';

class Welcome extends PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
        i18n: PropTypes.object.isRequired,
        slides: PropTypes.array.isRequired,
        signinLabel: PropTypes.string.isRequired,
    };

    signUp = () => {
        this.props.history.push(ROUTES.signup());
    };

    signIn = () => {
        this.props.history.push(ROUTES.signInFlow());
    };

    render() {
        return (
            <Core.Layout.Page>
                <Core.Layout.Content className={css(styles.carousel)}>
                    <Core.Carousel
                        type="single"
                        itemsLength={this.props.slides.length}>
                        {_.map(this.props.slides, (item, index) => (
                            <Core.Layout.FHCenteredContainer key={index}>
                                <div className={css(styles.container)}>
                                    {item.icon_image ?
                                        <Core.Icon
                                            size={item.iconSize || DEFAULT_ICON_SIZE}
                                            type="fa"
                                            name={item.icon_image}
                                            color="primary"
                                        /> : null}
                                </div>
                                <p className={css(styles.title)}>{this.props.i18n.t(item.titleSlug)}</p>
                                <p className={css(styles.description)}>{this.props.i18n.t(item.descriptionSlug)}</p>
                            </Core.Layout.FHCenteredContainer>
                        ))}
                    </Core.Carousel>
                </Core.Layout.Content>
                <Core.Layout.Container>
                    <div className={css(styles.container)}>
                        <Core.Button
                            className={css(layoutStyle.twoButtonsLeft)}
                            type="outlined"
                            color="secondary"
                            onPress={this.signIn}>
                            {this.props.signinLabel}
                        </Core.Button>
                        <Core.Button
                            className={css(layoutStyle.twoButtonsRight)}
                            type="contained"
                            color="secondary"
                            onPress={this.signUp}>
                            {this.props.i18n.t('signup')}
                        </Core.Button>
                    </div>
                </Core.Layout.Container>
            </Core.Layout.Page>
        );
    }
}

const styles = StyleSheet.create({
    ...baseStyles,
    container: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: spacing.s12,
        flexShrink: 0
    },
    carousel: {
        paddingBottom: spacing.s9
    }
});

export default WithWelcomeBase(Welcome);
