import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { appFonts, baseColors, spacing } from '../../../styles';

//common logic for InterestsBase, objectivesBase and concernsBase.
class PreferencesBase extends PureComponent {
    static propTypes = {
        actions: PropTypes.object.isRequired,
        callback: PropTypes.func,
        screenDetails: PropTypes.object,
        isSaving: PropTypes.bool,
        selectData: PropTypes.array,
        wrappedComponent: PropTypes.func.isRequired,
        saveData: PropTypes.func.isRequired,
        step: PropTypes.string,
        onNext: PropTypes.func,
        coreActions: PropTypes.object,
        i18n: PropTypes.object.isRequired,
        isOnboarding: PropTypes.bool
    };

    static defaultProps = {
        isSaving: false,
        isOnboarding: false,
        step: null,
        onNext: null,
        selectData: [],
        callback: null,
        coreActions: {},
        screenDetails: {},
    };

    constructor(props) {
        super(props);
        this.state = {
            isInited: false,
            required: props.screenDetails && props.screenDetails.required !== '0' ? props.screenDetails.required : false,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.isSaving && this.props.callback) {
            this.props.callback();
        }
        if (nextProps.selectData.length && !this.state.isInited) {
            this.setState({
                selected: _.reject(nextProps.selectData, { selected: false }),
                selectData: nextProps.selectData,
                isInited: true
            });
        }
    }

    componentDidUpdate() {
        if (this.state.error && this.state.savePressed) {
            this.showErrorToast();
        }
    }

    showErrorToast = () => {
        this.props.coreActions.addToast('danger', null, this.props.i18n.t('multi_select_check_error'));
    };

    saveData = () => {
        if (!this.state.selectData || this.state.selectData.length === 0) return;   // Data not loaded yet?
        let error = false;
        if (this.state.required) {
            const filtered = _.filter(this.state.selected, item => item.selected === true);
            error = filtered.length === 0;
            this.setState({ savePressed: true, error });
            if (error) return;
        }
        const dataToSend = _.unionBy(this.state.selected, _.map(this.state.selectData, item => {
            return { ...item, selected: false };
        }), 'id');
        this.props.saveData(dataToSend, !this.props.isOnboarding);
        if (this.props.callback) {
            this.props.callback();
        }
        if (this.props.isOnboarding && this.props.onNext) {
            this.props.onNext(this.props.step);
        }
    };

    toggleCheckbox = data => {
        const selected = _.map(data, item => {
            return { ...item, selected: true };
        });
        if (_.get(data[data.length - 1], 'id') === 'spacer') {
            data.pop();
        }
        this.setState({ selected, savePressed: false });
    };

    render() {
        const WrappedComponent = this.props.wrappedComponent;
        return (
            <WrappedComponent
                {...this.props}
                toggleCheckbox={this.toggleCheckbox}
                saveData={this.saveData}
                savePressed={this.state.savePressed}
                error={this.state.error}
                selected={this.state.selected}
            />
        );
    }
}
export default PreferencesBase;

export const styles = {
    header: {
        flex: 0,
        flexDirection: 'column',
        backgroundColor: baseColors.white,
        marginLeft: spacing.s3,
        marginRight: spacing.s3,
        marginBottom: spacing.s1
    },
    fixedHeader: {
        flex: 0,
        flexDirection: 'column',
        marginBottom: spacing.s1,
        backgroundColor: baseColors.white
    },
    description: {
        flex: 0,
        flexDirection: 'column',
        backgroundColor: baseColors.white,
    },
    titleText: {
        ...appFonts.xlBold,
        flexDirection: 'column',
        marginLeft: 0,
        marginRight: 0
    },
    button: {
        marginLeft: spacing.s8,
        marginRight: spacing.s8,
    },
    buttonOnboarding: {
        marginLeft: spacing.s3,
        marginRight: spacing.s3,
    },
    descText: {
        ...appFonts.mdRegular,
        color: baseColors.grey40
    }
};
