import { NAME as name } from './constants';
import { createActionTypes } from '../core';

const NAME = name.toUpperCase();

// for api
export const GET_STREAMS = createActionTypes(`${NAME}/GET_STREAMS`);
export const GET_MORE_STREAMS = createActionTypes(`${NAME}/GET_MORE_STREAMS`);
export const DELETE_STREAM = createActionTypes(`${NAME}/DELETE_STREAM`);
export const MODERATE_STREAM = createActionTypes(`${NAME}/MODERATE_STREAM`);
export const GET_STREAM = createActionTypes(`${NAME}/GET_STREAM`);
export const POST_STREAM = createActionTypes(`${NAME}/POST_STREAM`);
export const UPDATE_STREAM = createActionTypes(`${NAME}/UPDATE_STREAM`);
export const GET_DAILY_METRICS = createActionTypes(`${NAME}/GET_DAILY_METRICS`);
export const JOIN_ENTITY = createActionTypes(`${NAME}/JOIN_ENTITY`);

// single actions
export const SET_STREAM_VIEWER_MEMBER = `${NAME}/SET_STREAM_VIEWER_MEMBER`;
export const REMOVE_STREAM_VIEWER_MEMBER = `${NAME}/REMOVE_STREAM_VIEWER_MEMBER`;
export const DELETE_STREAMS_FOR_ID = `${NAME}/DELETE_STREAMS_FOR_ID`;
export const DELETE_STREAMS_RELATED_ITEMS_DETAILS = `${NAME}/DELETE_STREAMS_RELATED_ITEMS_DETAILS`;
export const IMAGE_ERROR = `${NAME}/IMAGE_ERROR`;
export const SET_FILTER = `${NAME}/SET_FILTER`;
export const SET_STREAM_WEIGHT = `${NAME}/SET_STREAM_WEIGHT`;

export const TEMP_ADD_REACTION = `${NAME}/TEMP_ADD_REACTION`;