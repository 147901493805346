import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { StyleSheet, css } from 'aphrodite-jss';
import { components as Core } from '../../../core';
import { spacing, appFonts, baseColors, layoutStyle, importantStyles } from '../../../../styles';
import WithTasksBase, { styles as baseStyles, PROGRESS_SIZE } from './TasksBase';
import TaskListItem from './TaskListItem';
import { components as Challenges } from '../../../challenges';

class Tasks extends PureComponent {
    static propTypes = {
        hideButtonTitle: PropTypes.string.isRequired,
        showButtonTitle: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        slides: PropTypes.array.isRequired,
        isFirstLoaded: PropTypes.bool,
        percentageCompleted: PropTypes.number,
        isCollapsed: PropTypes.bool,
        actions: PropTypes.object.isRequired,
    };

    static defaultProps = {
        isFirstLoaded: false,
        percentageCompleted: 0,
        isCollapsed: false
    };

    get data() {
        const { isHealthSurveyEnabled, slides, slidesWithoutWellbeing } = this.props;
        const data = isHealthSurveyEnabled ? slides : slidesWithoutWellbeing;
        return _.compact([...data]);
    }

    renderItem = (item, index) => (
        <TaskListItem
            task={item}
            index={index}
            length={this.data.length}
        />
    )

    keyExtractor = item => item.action_text;

    render() {
        const { isFirstLoaded, percentageCompleted, actions: { toggleSOTs }, isCollapsed, hideButtonTitle, showButtonTitle, title } = this.props;

        return isFirstLoaded ? (
            <Challenges.Card.Container containerClassName={css(styles.cardContainer)} hasPointer={false} tabIndex={null} role="none">
                <div className={css(layoutStyle.flexSpaceBetween, layoutStyle.flexAlignCenter)}>
                    <div className={css(styles.cardHeader, layoutStyle.pRelative, layoutStyle.flexRowCenter)}>
                        <div className={css(styles.circleContainer)}>
                            <Core.ProgressCircle
                                lineWidth={20}
                                progress={percentageCompleted}
                                progressColor={baseColors.primary}
                                bgColor={baseColors.grey70}
                            />
                        </div>
                        <p className={css(styles.cardHeaderText)}>{title}</p>
                    </div>
                    {isCollapsed ? (
                        <Core.ViewAllButton
                            text={_.capitalize(showButtonTitle)}
                            onPress={toggleSOTs}
                            textClassName={css(styles.showHideButton)}
                        />
                    ) : null}
                </div>
                {
                    isCollapsed ? null : (
                        <>
                            {isFirstLoaded ? (
                                <>
                                    <div className={css(layoutStyle.flexRow, styles.container)}>
                                        {_.map(this.data, this.renderItem)}
                                    </div>
                                    <div className={css(layoutStyle.flexJustifyEnd, styles.hideButton)}>
                                        <Core.ViewAllButton
                                            text={hideButtonTitle}
                                            onPress={toggleSOTs}
                                            textClassName={css(styles.showHideButton)}
                                        />
                                    </div>
                                </>
                            ) : null }
                        </>
                    )}
            </Challenges.Card.Container>
        ) : (
            <div className={css(styles.skeletonContainer)}>
                <Core.SkeletonContent width="100%" height={232}>
                    <Core.SkeletonRect width="100%" height="100%" />
                </Core.SkeletonContent>
            </div>
        );
    }
}

export default WithTasksBase(Tasks);

const styles = StyleSheet.create({
    ...baseStyles,
    container: {
        padding: spacing.s3
    },
    cardHeader: {
        ...baseStyles.cardHeader,
        paddingLeft: spacing.s3
    },
    hideButton: {
        marginBottom: spacing.s2
    },
    ...importantStyles({
        cardContainer: { height: 'auto', marginBottom: spacing.s1 },
        showHideButton: { ...appFonts.smRegular },
    }),
    circleContainer: {
        ...baseStyles.circleContainer,
        width: PROGRESS_SIZE,
        height: PROGRESS_SIZE,
        maxWidth: PROGRESS_SIZE
    }
});

