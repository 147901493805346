import fontSize from '../fontSize';

const fontDetails = {
    xxSmall: { fontSize: fontSize.xxSmall, lineHeight: 12, fontFamily: 'System' },
    xSmall: { fontSize: fontSize.xSmall, lineHeight: 16, fontFamily: 'System' },
    small: { fontSize: fontSize.small, lineHeight: 18, fontFamily: 'System' },
    medium: { fontSize: fontSize.medium, lineHeight: 22, fontFamily: 'System' },
    large: { fontSize: fontSize.large, lineHeight: 24, fontFamily: 'System' },
    xLineLarge: { fontSize: fontSize.xLarge, lineHeight: 24, fontFamily: 'System' },
    xLarge: { fontSize: fontSize.xLarge, lineHeight: 32, fontFamily: 'System' },
    xxLarge: { fontSize: fontSize.xxLarge, lineHeight: 36, fontFamily: 'System' },
    xxxLarge: { fontSize: fontSize.xxxLarge, lineHeight: 40, fontFamily: 'System' },
    xxxxLarge: { fontSize: fontSize.xxxxLarge, lineHeight: 56, fontFamily: 'System' },
};

export default fontDetails;