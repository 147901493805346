/* eslint react/no-unused-state: 'off' */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as actions from '../../actions';
import { CORPORATE_LINK_CONTEXT, LOCATION_LABELS } from '../../constants';
import { translate, selectors as coreSelectors } from '../../../core';
import {
    getCompany, getCountriesForSelect, getDepartmentsForSelect, isLoadingLocations,
    isLoadingDepartments, isLoadingUserLocations, getCurrentLocation, getCompanyConfiguration, isEnabledHRIS, isUpdatingUser, isDataPrefilled,
    getLocationDropDownLabels, getHrisCountryId, isMutliIntakeHRIS, isLocationDataReadOnly
} from '../../selectors';

export default function WithLocationDetailsBase(WrappedComponent) {
    class LocationDetailsBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            isCityIncluded: PropTypes.bool,
            countries: PropTypes.array,
            departments: PropTypes.array,
            currentLocation: PropTypes.object,
            isLoadingLocations: PropTypes.bool,
            isLoadingUserLocations: PropTypes.bool,
            isUpdatingUser: PropTypes.bool,
            isLoadingUser: PropTypes.bool,
            isLoadingDepartments: PropTypes.bool,
            hasUnsavedChanges: PropTypes.func,
            changeLocationCallback: PropTypes.func,
            isRegistration: PropTypes.bool,
            i18n: PropTypes.object.isRequired,
            isLiveBetter: PropTypes.bool.isRequired,
            partnerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            isCorporateLocation: PropTypes.bool.isRequired,
            isDataPrefilled: PropTypes.bool,
            isEnabledHRIS: PropTypes.bool,
            isEnabledHRISCurrentUser: PropTypes.bool,
            context: PropTypes.string,
            locationDropDownLabels: PropTypes.object,
            hrisCountryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            isSettingsPage: PropTypes.bool,
            isMultiIntakeHRIS: PropTypes.bool,
            isLocationDataReadOnly: PropTypes.bool
        };

        static defaultProps = {
            hasUnsavedChanges: null,
            isCityIncluded: true,
            countries: [],
            departments: [],
            currentLocation: {},
            isLoadingLocations: false,
            isLoadingUserLocations: false,
            isUpdatingUser: false,
            isLoadingUser: false,
            isLoadingDepartments: false,
            changeLocationCallback: null,
            isRegistration: false,
            isDataPrefilled: false,
            partnerId: undefined,
            companyId: undefined,
            isEnabledHRIS: false,
            isEnabledHRISCurrentUser: false,
            context: undefined,
            locationDropDownLabels: {},
            hrisCountryId: undefined,
            isSettingsPage: false,
            isMultiIntakeHRIS: false,
            isLocationDataReadOnly: false
        };

        constructor(props) {
            super(props);
            this.state = {
                isEditable: true
            };

            this.props.actions.getUser();
            if (!this.props.isRegistration && !LocationDetailsBase.hasCurrentLocation(props)) {
                this.props.actions.getUserLocations(this.props.companyId, this.props.partnerId);
            } else {
                this.props.actions.getLocationsList(this.props.companyId, this.props.partnerId);
                this.props.actions.getUserLocations(this.props.companyId, this.props.partnerId);
            }
        }

        componentDidUpdate(prevProps) {
            if (prevProps.isLoadingUserLocations && !this.props.isLoadingUserLocations && LocationDetailsBase.hasCurrentLocation(this.props)) {
                this.changeLocation();
            }
        }

        static getDerivedStateFromProps(nextProps, prevState) {
            const state = { isLoadingUserLocations: nextProps.isLoadingUserLocations, isUpdatingUser: nextProps.isUpdatingUser };

            // if it's HRIS there may or may not be location data from the user intake
            // isEnabledHRIS denotes if the company has HRIS enabled
            // locationDataPrefilled indicates whether or not location data was set as part of the user intake
            // isEnabledHRIS && locationDataPrefilled : data is shown on screen as placeholder, not editable, no options needed, submission action to be skipped
            // (isEnabledHRIS && !locationDataPrefilled) same as !EnabledHRIS, allow user to select the location info
            // HRIS needs to wait for isUpdatingUser because that api call potentially returns intaken user location info
            // !HRIS needs to wait for isLoadingUserLocations because those are the options for user selection

            // wait for everything to load
            // isEditable = false indicates that data has been intaken and as such can't be edited
            // the else if block checks this value otherwise theres a race condition where an intaken data set can be overwritten by a derived data set
            if (prevState.isUpdatingUser && !nextProps.isUpdatingUser) {
                if (nextProps.isDataPrefilled) {
                    _.assign(state, { ...nextProps.currentLocation, isEditable: false });
                }
            } else if (prevState.isLoadingUserLocations && !nextProps.isLoadingUserLocations && LocationDetailsBase.hasCurrentLocation(nextProps) && prevState.isEditable) {
                const result = LocationDetailsBase.getValuesFromSavedLocation(nextProps);
                if (result.country) {
                    _.assign(state, result);
                } else {
                    _.assign(state, { ...nextProps.currentLocation });
                }
            }

            //Show country
            if (nextProps.countries.length === 1) {
                return { ...state, country: nextProps.countries[0], isCountryShown: false };
            }
            return { ...state, isCountryShown: true };
        }

        static hasCurrentLocation(props) {
            return _.values(props.currentLocation).length > 1 || props.hrisCountryId !== undefined;
        }

        static getValuesFromSavedLocation({ hrisCountryId, currentLocation: location, countries, departments }) {
            const result = {};
            if (hrisCountryId) {
                const country = _.find(countries, c => c.country_id === hrisCountryId);
                if (country) result.country = country;
            }

            if (!result.country) {
                result.country = _.find(countries, c => c.name === location.country || c.name === _.get(location, 'country.label'));
            }
            result.region = _.find(result.country ? result.country.regions : [], r => r.name === location.region || r.name === _.get(location, 'region.label'));
            result.city = _.find(result.region ? result.region.cities : [], c => c.name === location.city || c.name === _.get(location, 'city.label'));
            result.location = _.find((result.city && result.city.locations) || (result.region && result.region.locations) || [], l => l.location_name === location.location_name || l.location_name === _.get(location, 'location.label'));
            result.department = _.find(result.location ? departments : [], d => d.department_name === location.department_name || d.department_name === _.get(location, 'department.label'));
            return result;
        }

        get isLiveBetter() {
            return this.props.isLiveBetter;
        }

        get isCorporateLocation() {
            return this.props.isCorporateLocation;
        }

        get countryName() {
            const { i18n, locationDropDownLabels } = this.props;
            const countryLabel = _.get(locationDropDownLabels, LOCATION_LABELS.country);
            return countryLabel ? countryLabel : i18n.t('countryName');
        }

        get regionName() {
            const { i18n, locationDropDownLabels } = this.props;
            const regionLabel = _.get(locationDropDownLabels, LOCATION_LABELS.region);
            if (regionLabel) return regionLabel;
            return this.isLiveBetter ? i18n.t('regionNameLiveBetter') : i18n.t('regionName');
        }

        get locationName() {
            const { i18n, locationDropDownLabels } = this.props;
            const locationLabel = _.get(locationDropDownLabels, LOCATION_LABELS.location);
            if (locationLabel) return locationLabel;
            return (this.isLiveBetter && this.isCorporateLocation) ? i18n.t('locationNameLiveBetter') : i18n.t('locationName');
        }

        get departmentName() {
            const { i18n, locationDropDownLabels } = this.props;
            const departmentLabel = _.get(locationDropDownLabels, LOCATION_LABELS.department);
            if (departmentLabel) return departmentLabel;
            return this.isLiveBetter ? i18n.t('departmentNameLiveBetter') : i18n.t('departmentName');
        }

        get cityName() {
            const { i18n, locationDropDownLabels } = this.props;
            const cityLabel = _.get(locationDropDownLabels, LOCATION_LABELS.city);
            return cityLabel ? cityLabel : i18n.t('cityName');
        }

        get regionLabel() {
            return this.isLiveBetter ? this.props.i18n.t('regionLabelLiveBetter') : this.props.i18n.t('regionLabel');
        }

        get locationLabel() {
            return (this.isLiveBetter && this.isCorporateLocation) ? this.props.i18n.t('locationLabelLiveBetter') : this.props.i18n.t('locationLabel');
        }

        get departmentLabel() {
            return this.isLiveBetter ? this.props.i18n.t('departmentLabelLiveBetter') : this.props.i18n.t('departmentLabel');
        }

        get isFirstLoading() {
            return this.props.isUpdatingUser || this.props.isLoadingLocations || this.props.isLoadingUserLocations;
        }

        get isLoadingDepartments() {
            return this.props.isLoadingDepartments && !this.isFirstLoading;
        }

        onCountryChange = country => {
            this.trackChanges();
            this.setState({ country, region: null, city: null, location: null, department: null }, this.changeLocation);
        };

        onRegionChange = region => {
            this.trackChanges();
            this.setState({ region, city: null, location: null, department: null }, this.changeLocation);
        };

        onCityChange = city => {
            this.trackChanges();
            this.setState({ city, location: null, department: null }, this.changeLocation);
        };

        onLocationChange = location => {
            this.trackChanges();
            this.setState({ location, department: null }, () => {
                if (this.props.isCorporateLocation) {
                    this.props.actions.getDepartmentsList(this.props.companyId, location.id);
                }
                this.changeLocation();
            });
        };

        onDepartmentChange = department => {
            this.trackChanges();
            this.setState({ department }, this.changeLocation);
        };

        trackChanges = () => {
            this.props.hasUnsavedChanges && this.props.hasUnsavedChanges(true);
        };

        changeLocation = () => {
            const { country, region, city, location, department } = this.state;
            this.props.changeLocationCallback({ country, region, city, location, department, isCorporateLocation: this.props.isCorporateLocation });
        };

        get countries() {
            return this.props.countries;
        }

        get country() {
            return this.state.country;
        }

        get regions() {
            return this.country ? this.country.regions : [];
        }

        get region() {
            return this.state.region;
        }

        get cities() {
            return this.region && this.region.cities ? this.region.cities : [];
        }

        get city() {
            return this.state.city;
        }

        get locations() {
            if (this.city && this.city.locations) return this.city.locations;
            return this.region && this.region.locations ? this.region.locations : [];
        }

        get location() {
            return this.state.location;
        }

        get departments() {
            return this.location ? this.props.departments : [];
        }

        get department() {
            return this.state.department;
        }

        get isDataPrefilled() {
            return this.props.isDataPrefilled;
        }

        get isMultiIntakeHRISAndSettings() {
            return this.props.isSettingsPage && this.props.isMultiIntakeHRIS;
        }

        get isEnabledHRIS() {
            if (this.isMultiIntakeHRISAndSettings) return this.props.isEnabledHRISCurrentUser;

            return this.props.isEnabledHRIS;
        }

        get isCountryPreFilled() {
            if (this.isEnabledHRIS && (this.props.hrisCountryId !== undefined && this.country)) {
                return true;
            }

            if (this.props.isLocationDataReadOnly) {
                return true;
            }

            return false;
        }

        get isEditable() {
            if (this.isMultiIntakeHRISAndSettings) return this.isEnabledHRIS;

            return this.isEnabledHRIS && this.props.isLocationDataReadOnly;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    department={this.department}
                    departments={this.departments}
                    location={this.location}
                    locations={this.locations}
                    city={this.city}
                    cities={this.cities}
                    region={this.region}
                    regions={this.regions}
                    country={this.country}
                    countries={this.countries}
                    onDepartmentChange={this.onDepartmentChange}
                    onLocationChange={this.onLocationChange}
                    onCityChange={this.onCityChange}
                    onRegionChange={this.onRegionChange}
                    onCountryChange={this.onCountryChange}
                    departmentLabel={this.departmentLabel}
                    locationLabel={this.locationLabel}
                    regionLabel={this.regionLabel}
                    departmentName={this.departmentName}
                    locationName={this.locationName}
                    regionName={this.regionName}
                    countryName={this.countryName}
                    cityName={this.cityName}
                    isLoading={this.isFirstLoading}
                    isLoadingDepartments={this.isLoadingDepartments}
                    isCountryShown={this.state.isCountryShown}
                    isEditable={!this.isEditable}
                    isCountryPreFilled={this.isCountryPreFilled}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        const companyId = ownProps.isRegistration
            ? getCompany(state).companyId : coreSelectors.getCurrentUser(state).companyId;
        return {
            companyId,
            partnerId: getCompany(state).partnerId,
            isCorporateLocation: companyId && companyId !== '0',
            isCityIncluded: _.get(getCompanyConfiguration(state), 'IsCityIncluded'),
            countries: getCountriesForSelect(state),
            departments: getDepartmentsForSelect(state),
            isLiveBetter: coreSelectors.isLiveBetter(state),
            currentLocation: ownProps.context !== CORPORATE_LINK_CONTEXT ? getCurrentLocation(state) : {},
            isLoadingLocations: isLoadingLocations(state),
            isLoadingDepartments: isLoadingDepartments(state),
            isLoadingUserLocations: isLoadingUserLocations(state),
            isUpdatingUser: isUpdatingUser(state),
            isEnabledHRIS: isEnabledHRIS(state),
            isEnabledHRISCurrentUser: coreSelectors.isEnabledHRISCurrentUser(state),
            isDataPrefilled: isDataPrefilled(state),
            locationDropDownLabels: getLocationDropDownLabels(state),
            hrisCountryId: getHrisCountryId(state),
            isMultiIntakeHRIS: isMutliIntakeHRIS(state),
            isLocationDataReadOnly: isLocationDataReadOnly(state)
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(LocationDetailsBase));
}
