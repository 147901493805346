import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';
import { components as Core } from '../../../core';
import { importantClass, spacing, baseColors } from '../../../../styles';
import WithChangePasswordBase, { styles as baseStyles, PASSWORDS } from './ChangePasswordBase';

class ChangePassword extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        i18n: PropTypes.object.isRequired,
        onChange: PropTypes.func.isRequired,
        currentPassword: PropTypes.string.isRequired,
        newPassword: PropTypes.string.isRequired,
        confirmNewPassword: PropTypes.string.isRequired,
        fieldErrors: PropTypes.object.isRequired,
        submitChangePassword: PropTypes.func.isRequired,
        userEmail: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        telusInfoText: PropTypes.string.isRequired,
        telusButonText: PropTypes.string.isRequired,
        openTelusPasswordURL: PropTypes.func.isRequired,
        isTHVCSSOEnabled: PropTypes.bool.isRequired
    };

    
    constructor(props) {
        super(props);
        this.state = {};
    }

    onChange = prop => event => {
        this.props.onChange(prop)(event.target.value);
    };

    submitChangePassword = event => {
        event.preventDefault();
        this.props.submitChangePassword();
    };

    get changeTelusPassword() {
        const { telusInfoText, telusButonText, openTelusPasswordURL } = this.props;
        return (
            <>
                <Core.SubHeader
                    title={this.props.title}
                    noTopPadding={true}
                    className={css(styles.telusHeader)}
                />
                <div className={css(styles.telusPadding)}>
                    <p className={css(styles.telusText)}>{telusInfoText}</p>
                    <Core.Button
                        fullWidth={false}
                        type="outlined"
                        withIcon={true}
                        onPress={openTelusPasswordURL}
                        className={css(styles.telusButton)}>
                        {telusButonText}
                        <Core.Icon type="fa" name="external-link" size={spacing.s3} color={baseColors.secondar} className={css(styles.externalIcon)} />
                    </Core.Button>
                </div>
            </>
        );
    }

    get changePassword() {
        return (
            <>
                <Core.SubHeader
                    title={this.props.title}
                    noTopPadding={true}
                    noLeftPadding={true}
                />
                <div>
                    <Core.Input
                        fullWidth={true}
                        label={this.props.i18n.t('emailPlaceholder')}
                        id="username"
                        disabled={true}
                        value={this.props.userEmail}
                    />
                    <Core.Input
                        fullWidth={true}
                        id="currentPassword"
                        label={this.props.i18n.t(PASSWORDS.currentPassword)}
                        value={this.props.currentPassword}
                        onChange={this.onChange(PASSWORDS.currentPassword)}
                        customType="password"
                        helperText={this.props.fieldErrors.currentPassword}
                        error={!!this.props.fieldErrors.currentPassword}
                    />
                    <Core.Input
                        fullWidth={true}
                        id="newPassword"
                        label={this.props.i18n.t(PASSWORDS.newPassword)}
                        value={this.props.newPassword}
                        onChange={this.onChange(PASSWORDS.newPassword)}
                        customType="password"
                        helperText={this.props.fieldErrors.newPassword}
                        error={!!this.props.fieldErrors.newPassword}
                    />
                    <Core.Input
                        fullWidth={true}
                        id="confirmNewPassword"
                        label={this.props.i18n.t(PASSWORDS.confirmNewPassword)}
                        value={this.props.confirmNewPassword}
                        onChange={this.onChange(PASSWORDS.confirmNewPassword)}
                        customType="password"
                        helperText={this.props.fieldErrors.confirmNewPassword}
                        error={!!this.props.fieldErrors.confirmNewPassword}
                    />
                    <Core.Button
                        fullWidth={true}
                        isSubmit={true}
                        onPress={this.submitChangePassword}
                        disabled={this.props.isLoading}
                        isLoading={this.props.isLoading}
                        className={css(styles.saveButton)}>
                        {this.props.i18n.t('submit')}
                    </Core.Button>
                </div>
            </>
        );
    }

    render() {
        const { isTHVCSSOEnabled } = this.props;
        return (
            <>
                {isTHVCSSOEnabled ? this.changeTelusPassword : this.changePassword}
            </>
        );
    }
}

export default WithChangePasswordBase(ChangePassword);

export const styles = StyleSheet.create({
    ...baseStyles,
    saveButton: importantClass({
        marginTop: spacing.s3,
    }),
    telusPadding: {
        marginLeft: spacing.s4
    },
    telusButton: importantClass({
        marginTop: spacing.s6,
    }),
    telusHeader: importantClass({
        marginBottom: spacing.s2,
    }),
});
