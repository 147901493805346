import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { StyleSheet, css } from 'aphrodite-jss';
import { containerStyle, importantStyles, layoutStyle, baseColors, spacing } from '../../../../styles';
import { Alert, AsyncComponent, components as Core, Modal } from '../../../core';
import WithLinkAccountsBase, { ICONS } from './LinkAccountsBase';
import { EXTERNAL_SERVICES, EXTERNAL_SERVICES_NO_MODAL } from '../../constants';

class LinkAccounts extends PureComponent {
    static propTypes = {
        facebookEnabled: PropTypes.bool.isRequired,
        googleEnabled: PropTypes.bool.isRequired,
        programName: PropTypes.string.isRequired,
        noEnabledServices: PropTypes.bool.isRequired,
        i18n: PropTypes.object.isRequired,
        enabledExternalServices: PropTypes.array.isRequired,
        isLoading: PropTypes.bool.isRequired,
        isGoogleLinked: PropTypes.bool.isRequired,
        isFacebookLinked: PropTypes.bool.isRequired,
        isOtherLinked: PropTypes.func.isRequired,
        linkOtherAccount: PropTypes.func.isRequired,
        unlinkOtherAccount: PropTypes.func.isRequired,
        linkGoogle: PropTypes.func.isRequired,
        unlinkGoogle: PropTypes.func.isRequired,
        linkFacebook: PropTypes.func.isRequired,
        unlinkFacebook: PropTypes.func.isRequired,
        unlinkAccount: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired,
        onEmailChange: PropTypes.func.isRequired,
        email: PropTypes.string.isRequired,
        isLastAccount: PropTypes.bool.isRequired,
        linkCorporateAccount: PropTypes.func.isRequired,
        unlinkCorporateAccount: PropTypes.func.isRequired,
        corporateAccountLinkEnabled: PropTypes.bool.isRequired,
        isCorporateAccountLinked: PropTypes.bool.isRequired,
        title: PropTypes.string.isRequired,
        hasPartnerSsoLinkingModal: PropTypes.bool,
        getLinkInfoModalProps: PropTypes.func.isRequired
    };

    static defaultProps = {
        hasPartnerSsoLinkingModal: false,
    };

    constructor(props) {
        super(props);
        this.ssoType = EXTERNAL_SERVICES.medibank;
    }

    getIconConfig(icon, isRight) {
        return {
            type: 'fa',
            name: icon.name,
            fill: icon.type,
            color: icon.color,
            size: isRight ? spacing.s3 : spacing.s5,
            className: css(layoutStyle.db)
        };
    }

    generateIcon(icon, isRight) {
        return (
            <Core.Icon
                {...this.getIconConfig(icon, isRight)}
            />
        );
    }

    getRightIcon(isLinked, handler, slug) {
        const { className: iconClassName, ...props } = this.getIconConfig(ICONS.remove, true);
        return (
            <Fragment>
                {isLinked ?
                    <Core.IconButton
                        isSmall={true}
                        {...props}
                        iconClassName={iconClassName}
                        className={css(styles.removeIcon)}
                        onClick={handler ? handler : this.unlinkAccount(slug)}
                    /> : null}
                {this.generateIcon(isLinked ? ICONS.check : ICONS.add, true)}
            </Fragment>
        );
    }

    get emptyList() {
        return (
            <Core.EmptyListSimple
                message={this.props.i18n.t('noAccountsToLink', { program_name: this.props.programName })}
            />
        );
    }

    unlinkAccount = handler => event => {
        event.stopPropagation();
        this.props.unlinkAccount(handler)();
    };

    onEmailChange = event => {
        this.props.onEmailChange(event.target.value);
    };

    showLinkCorporateAccount = () => {
        if (!this.props.isCorporateAccountLinked) {
            this.props.linkCorporateAccount();
            this.closeLinkingModal = Modal.open(
                AsyncComponent(() => import('../CorporateAccountLinkFlow')),
                {
                    carousel: true,
                    close: () => this.closeLinkingModal(),
                    rightButtonLabel: this.props.i18n.t('quit'),
                    showQuit: true,
                    isModal: true
                }, {
                    cancelable: false,
                    isNoPadding: true,
                    isContainer: true,
                    isFullHeight: true,
                    PaperProps: {
                        className: css(styles.modalPositioning)
                    },
                }
            );
        }
        else {
            Alert.alert('', this.props.i18n.t('auth.linkAccount.alreadyLinked'));
        }
    };

    linkSso = slug => () => {
        const { hasPartnerSsoLinkingModal } = this.props;

        if (!hasPartnerSsoLinkingModal || !_.includes(EXTERNAL_SERVICES_NO_MODAL, slug)) {
            return this.props.linkOtherAccount(slug);
        }

        this.showLinkInfoModal(slug);
    };

    showLinkInfoModal = ssoType => {
        const { getLinkInfoModalProps } = this.props;

        this.ssoType = ssoType;

        this.closeSsoModal = Modal.open(
            Core.InfoModal,
            getLinkInfoModalProps(this.onAcceptSsoModal, this.onCloseSsoModal),
            { isContainer: true, isTransparent: true, isNoPadding: true, fadeTransition: true, }
        );
    };

    onCloseSsoModal = () => this.closeSsoModal && this.closeSsoModal();

    onAcceptSsoModal = () => {
        this.onCloseSsoModal();
        this.props.linkOtherAccount(this.ssoType)();
    };

    render() {
        const { i18n, enabledExternalServices, noEnabledServices, isLoading, isGoogleLinked, isFacebookLinked,
            isOtherLinked, googleEnabled, facebookEnabled, unlinkOtherAccount,
            unlinkGoogle, unlinkFacebook, linkGoogle, linkFacebook, corporateAccountLinkEnabled,
            isCorporateAccountLinked, unlinkCorporateAccount, title } = this.props;
        return (
            <>
                <Core.SubHeader
                    title={title}
                    noTopPadding={true}
                />
                <Core.BlockingLoading isLoading={isLoading} />
                {noEnabledServices ? this.emptyList :
                    <div className={css(containerStyle.main)}>
                        <div>
                            {googleEnabled ?
                                <Core.ListOptionPopup
                                    title={i18n.t('Google')}
                                    icon={this.generateIcon(ICONS.google)}
                                    action={linkGoogle}
                                    rightIcon={this.getRightIcon(isGoogleLinked, this.unlinkAccount(unlinkGoogle))}
                                /> : null
                            }
                            {facebookEnabled ?
                                <Core.ListOptionPopup
                                    title={i18n.t('Facebook')}
                                    icon={this.generateIcon(ICONS.facebook)}
                                    action={linkFacebook}
                                    rightIcon={this.getRightIcon(isFacebookLinked, this.unlinkAccount(unlinkFacebook))}
                                /> : null
                            }
                            {corporateAccountLinkEnabled || isCorporateAccountLinked ?
                                <Core.ListOptionPopup
                                    title={i18n.t('corporate')}
                                    icon={this.generateIcon(ICONS.corporate)}
                                    action={this.showLinkCorporateAccount}
                                    rightIcon={this.getRightIcon(isCorporateAccountLinked, this.unlinkAccount(unlinkCorporateAccount))}
                                /> : null
                            }
                            {_.map(enabledExternalServices, slug => (
                                <Core.ListOptionPopup
                                    title={i18n.t(slug)}
                                    icon={this.generateIcon(ICONS.user)}
                                    action={this.linkSso(slug)}
                                    rightIcon={this.getRightIcon(isOtherLinked(slug), this.unlinkAccount(unlinkOtherAccount(slug)))}
                                />
                            ))}
                        </div>
                    </div> }
            </>
        );
    }
}

export default WithLinkAccountsBase(LinkAccounts);

const styles = StyleSheet.create({
    ...importantStyles({
        removeIcon: {
            marginRight: spacing.s3,
        },
        button: {
            color: baseColors.dangerDarker,
        },
        modalContent: {
            paddingTop: 0,
            paddingBottom: 0
        },
        modalPositioning: {
            position: 'unset'
        }
    })
});
