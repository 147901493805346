import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import InputAdornment from '@material-ui/core/InputAdornment';
import { isIE } from 'react-device-detect';
import classnames from 'classnames';
import WithCheckboxWithInputBase, { styles as baseStyles } from './CheckboxWithInputBase';
import { baseColors, important, importantClass, layoutStyle, spacing } from '../../../../styles';
import Icon from '../Icon';
import Checkbox from '../Checkbox';
import Input from '../Input';

class CheckboxWithInput extends PureComponent {
    static propTypes = {
        onChangeText: PropTypes.func.isRequired,
        inputUnit: PropTypes.string.isRequired,
        checked: PropTypes.bool.isRequired,
        onPress: PropTypes.func.isRequired,
        value: PropTypes.string.isRequired,
        ariaDescription: PropTypes.node,
        inputProps: PropTypes.object,
        inputHelper: PropTypes.node,
        label: PropTypes.node,
        onKeyDown: PropTypes.func
    };

    static defaultProps = {
        label: null,
        inputProps: {},
        inputHelper: null,
        ariaDescription: null,
        onKeyDown: undefined
    };

    get checkboxIcon() {
        return (
            <Icon
                type="fa"
                name="square"
                fill="light"
                size={spacing.s4}
                color={baseColors.grey50}
            />
        );
    }

    get checkedIcon() {
        return (
            <Icon
                type="fa"
                name="check-square"
                fill="solid"
                size={spacing.s4}
                color={baseColors.secondary}
            />
        );
    }

    render() {
        const { onPress, checked, value, onChangeText, inputUnit, inputProps, ariaDescription, label, inputHelper, onKeyDown } = this.props;
        return (
            <>
                <div className={css(layoutStyle.flex1, layoutStyle.flexSpaceBetween)}>
                    <Checkbox
                        className={css(styles.checkbox)}
                        checkBoxClassName="checkBoxClassName"
                        label={label}
                        onChange={onPress}
                        checked={checked}
                        customIcon={this.checkboxIcon}
                        checkedIcon={this.checkedIcon}
                        onKeyDown={event => onKeyDown && onKeyDown(event)}
                    />
                    {checked && (
                        <Input
                            InputProps={{
                                classes: { input: css(styles.input) },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <p className={classnames(css(styles.adornmentText), inputHelper && css(styles.warnColorText))}>{inputUnit}</p>
                                    </InputAdornment>
                                ),
                                startAdornment: (
                                    <InputAdornment>
                                        {ariaDescription && ariaDescription}
                                    </InputAdornment>
                                ),
                            }}
                            textFieldClassName={classnames(css(styles.textField), inputHelper && css(styles.overTrackingField))}
                            className={css(styles.textFieldWrapper)}
                            value={value}
                            inputProps={inputProps}
                            onChangeText={onChangeText}
                            autoFocus={true}
                        />
                    )}
                </div>
                {inputHelper}
            </>
        );
    }
}

export default WithCheckboxWithInputBase(CheckboxWithInput);

const TEXT_FIELD_WIDTH = 160;

const styles = StyleSheet.create({
    ...baseStyles,
    input: importantClass({
        ...baseStyles.input,
        maxWidth: isIE ? '60%' : '100%',
        marginTop: 0,
    }),
    textField: {
        height: spacing.s10,
        width: TEXT_FIELD_WIDTH,
    },
    checkbox: {
        marginBottom: 0,
    },
    textFieldWrapper: {
        marginTop: important(spacing.s1),
    },
    overTrackingField: importantClass({
        backgroundColor: baseColors.warnLightest,
        border: '1px solid',
        borderColor: baseColors.warn,
    })
});
