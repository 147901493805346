import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { StyleSheet, css } from 'aphrodite-jss';
import classnames from 'classnames';
import { components as Notifications } from '../../notifications';
import { ROUTES, components as Core, CSS_CLASSES, translate } from '../../core';
import { components as Auth } from '../../auth';
import { components as Settings } from '../../settings';
import MyContext from '../../../context';
import { SPACING_WITH_POP_UP } from '../constants';
import { baseColors, headerHeight, spacing, appFonts, importantClass, layoutStyle } from '../../../styles';
import MoreWebPopup from './MoreWebPopup';

const ICON_SIZE = {
    legacy: spacing.s4,
    accessable: spacing.s5
};
const HEADER_LINEAR_GRADIENT = ' 0px 0px 5px 0px rgba(0, 0, 0, 0.15)';

class Header extends PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        score: PropTypes.string.isRequired,
        isLoadingScore: PropTypes.bool,
        isHealthSurveyEnabled: PropTypes.bool,
        company: PropTypes.object.isRequired
    }

    static defaultProps = { isLoadingScore: false, isHealthSurveyEnabled: false };

    state = { isModalOpened: false, isScorePopupOpen: false, isMorePopupOpen: false };

    get classnameIfModalOpened() {
        return this.state.isModalOpened && styles.disabledLinks;
    }

    onPlusTabClick = () => this.setState(oldState => ({ isModalOpened: !oldState.isModalOpened }));

    closePlusModal = () => this.setState({ isModalOpened: false });

    openScorePopup = () => {
        if (!this.props.isLoadingScore) this.setState(state => ({ isScorePopupOpen: !state.isScorePopupOpen }));
    }

    openMorePopup = () => {
        this.setState(state => ({ isMorePopupOpen: !state.isMorePopupOpen }));
    }

    get isAccessableNavBar() {
        return this.props.company.configurations.navbar === 'accessible';
    }

    get navBarStyles() {
        const isAccessableNavBar = this.isAccessableNavBar;

        return {
            header: isAccessableNavBar ? styles.accessableHeader : styles.legacyHeader,
            headerLinkLabel: isAccessableNavBar ? styles.accessableHeaderButtonTextColor : styles.legacyHeaderButtonTextColor,
            iconStyles: {
                size: this.isAccessableNavBar ? ICON_SIZE.accessable : ICON_SIZE.legacy,
                fill: this.isAccessableNavBar ? 'light' : 'regular',
                color: isAccessableNavBar ? styles.accessableHeaderButtonTextColor : styles.legacyHeaderButtonTextColor,
            },
            skeletonBg: isAccessableNavBar ? styles.accessableSkeletonBg : styles.legacySkeletonBg
        };
    }

    render() {
        const { i18n, score, isLoadingScore, isHealthSurveyEnabled } = this.props;
        const { isModalOpened, isScorePopupOpen, isMorePopupOpen } = this.state;
        let tabsStyle = null;
        if (isModalOpened) {
            tabsStyle = styles.tabsOpacity;
        }
        const headerStyles = this.navBarStyles;

        const content = (
            <nav aria-label="primary">
                <Toolbar className={css(headerStyles.header)}>
                    <Core.Layout.WiderContainer itemClassName={css(layoutStyle.flexSpaceBetween)}>
                        <div className={css(layoutStyle.flexSpaceBetween, layoutStyle.flex1)}>
                            <div className={css(styles.navTabs, layoutStyle.flexSpaceBetween, layoutStyle.flex1, tabsStyle)}>
                                <div className={css(layoutStyle.flexJustifyEnd, layoutStyle.flexAlignEnd)}>
                                    <Core.NavLink
                                        className={css(styles.logo, this.classnameIfModalOpened)}
                                        exact={true}
                                        to={ROUTES.home()}>
                                        <Auth.Logo imageClassName={css(styles.logoImg)} isPrimary={!this.isAccessableNavBar} />
                                    </Core.NavLink>
                                    <Core.NavLink
                                        exact={true}
                                        to={ROUTES.home()}
                                        className={css(this.classnameIfModalOpened, styles.headerLink)}
                                        tabIndex="-1">
                                        <Core.Button
                                            type="text"
                                            className={css(layoutStyle.createEntityButton, styles.headerButton)}
                                            color="inherit">
                                            <Core.Icon
                                                color="inherit"
                                                name="home-lg-alt"
                                                type="fa"
                                                fill={headerStyles.iconStyles.fill}
                                                size={headerStyles.iconStyles.size}
                                                className={css(styles.navButton, headerStyles.iconStyles.color)}
                                            />
                                            <span className={css(styles.headerLinkLabel, headerStyles.headerLinkLabel)}>{i18n.t('home')}</span>
                                        </Core.Button>
                                    </Core.NavLink>
                                    <Core.NavLink
                                        tabIndex="-1"
                                        to={ROUTES.explore()}
                                        className={css(this.classnameIfModalOpened, styles.headerLink)}>
                                        <Core.Button
                                            type="text"
                                            className={classnames(css(layoutStyle.createEntityButton, styles.headerButton), CSS_CLASSES.exploreMenuItem)}
                                            color="inherit">
                                            <Core.Icon
                                                color="inherit"
                                                name="compass"
                                                type="fa"
                                                fill={headerStyles.iconStyles.fill}
                                                size={headerStyles.iconStyles.size}
                                                className={css(styles.navButton, headerStyles.iconStyles.color)}
                                            />
                                            <span className={css(styles.headerLinkLabel, headerStyles.headerLinkLabel)}>{i18n.t('explore')}</span>
                                        </Core.Button>
                                    </Core.NavLink>
                                    <Core.NavLink
                                        to={ROUTES.me()}
                                        tabIndex="-1"
                                        className={css(this.classnameIfModalOpened, styles.headerLink)}>
                                        <Core.Button
                                            type="text"
                                            className={classnames(css(layoutStyle.createEntityButton, styles.headerButton), CSS_CLASSES.exploreMenuItem)}
                                            color="inherit">
                                            <Core.Icon
                                                color="inherit"
                                                name="user"
                                                type="fa"
                                                fill={headerStyles.iconStyles.fill}
                                                size={headerStyles.iconStyles.size}
                                                className={css(styles.navButton, headerStyles.iconStyles.color)}
                                            />
                                            <span className={css(styles.headerLinkLabel, headerStyles.headerLinkLabel)}>{i18n.t('me')}</span>
                                        </Core.Button>
                                    </Core.NavLink>
                                </div>
                                <div>
                                    {isHealthSurveyEnabled ? (
                                        <Core.NavLink
                                            to={ROUTES.wellbeingScore()}
                                            tabIndex="-1"
                                            className={css(this.classnameIfModalOpened, styles.headerLink)}>
                                            <Core.Button
                                                type="text"
                                                className={classnames(css(layoutStyle.createEntityButton, styles.headerButton), CSS_CLASSES.exploreMenuItem)}
                                                color="inherit"
                                                onMouseEnter={this.openScorePopup}
                                                onMouseLeave={this.openScorePopup}
                                                title=""
                                                aria-label="Health survey button">
                                                <Core.Icon
                                                    color="inherit"
                                                    name="heart-square"
                                                    type="fa"
                                                    size={headerStyles.iconStyles.size}
                                                    fill={this.isAccessableNavBar ? 'light' : 'solid'}
                                                    className={css(styles.navButton, headerStyles.iconStyles.color)}
                                                />
                                                {isLoadingScore
                                                    ? <div className={css(styles.skeleton, headerStyles.skeletonBg)} /> : (
                                                        <>
                                                            {score ? <span className={css(styles.headerLinkLabel, headerStyles.headerLinkLabel)}>{score}</span> : null}
                                                        </>
                                                    )}
                                                <Settings.WellbeingScorePopup openScorePopup={this.openScorePopup} isScorePopupOpen={isScorePopupOpen} />
                                            </Core.Button>
                                        </Core.NavLink>
                                    ) : null}
                                    <Core.NavLink
                                        to={ROUTES.notifications()}
                                        tabIndex="-1"
                                        disabled={isModalOpened}
                                        className={css(this.classnameIfModalOpened, styles.headerLink)}>
                                        <Core.Button
                                            type="text"
                                            className={css(layoutStyle.createEntityButton, styles.headerButton)}
                                            color="inherit">
                                            <Notifications.NotificationsIcon
                                                color="inherit"
                                                name="bell"
                                                fill={headerStyles.iconStyles.fill}
                                                type="fa"
                                                size={headerStyles.iconStyles.size}
                                                iconClassName={css(styles.navButton, headerStyles.iconStyles.color)}
                                            />
                                        </Core.Button>
                                    </Core.NavLink>
                                    <Core.Button
                                        type="text"
                                        className={classnames(css(layoutStyle.createEntityButton, styles.headerButton), CSS_CLASSES.exploreMenuItem)}
                                        color="inherit"
                                        onMouseEnter={this.openMorePopup}
                                        onMouseLeave={this.openMorePopup}>
                                        <Core.Icon
                                            color="inherit"
                                            name="bars"
                                            type="fa"
                                            fill={headerStyles.iconStyles.fill}
                                            size={headerStyles.iconStyles.size}
                                            className={css(styles.navButton, headerStyles.iconStyles.color)}
                                        />
                                        <span className={css(styles.headerLinkLabel, headerStyles.headerLinkLabel)}>{i18n.t('moreTab')}</span>
                                        <MoreWebPopup openMoreWebPopup={this.openMorePopup} isMoreWebPopupOpen={isMorePopupOpen} />
                                    </Core.Button>
                                </div>
                            </div>
                        </div>
                    </Core.Layout.WiderContainer>
                </Toolbar>
            </nav>
        );
        return (
            <div>
                <MyContext.Consumer>
                    {({ isLatestVersion }) => (
                        <AppBar
                            elevation={this.isAccessableNavBar ? 0 : 4}
                            classes={!isLatestVersion && { positionFixed: css(styles.headerPositionFixed) }}
                            className={css(styles.appBar)}
                            position="fixed" >
                            {content}
                        </AppBar>
                    )}
                </MyContext.Consumer>
            </div>
        );
    }
}

const MAX_NAV_WIDTH = spacing.s10;

const styles = StyleSheet.create({
    appBar: {
        zIndex: '1200 !important'
    },
    legacyHeader: {
        color: baseColors.white,
        justifyContent: 'center',
        height: headerHeight,
        backgroundColor: baseColors.primary,
    },
    accessableHeader: {
        color: baseColors.grey20,
        justifyContent: 'center',
        height: headerHeight,
        backgroundColor: baseColors.white,
        'box-shadow': `${HEADER_LINEAR_GRADIENT} !important`,
    },
    logo: {
        maxHeight: '80%',
        height: 'auto',
        width: 'auto',
        padding: spacing.s0,
        borderRadius: spacing.s0,
        marginRight: spacing.s7
    },
    logoImg: {
        height: '100%'
    },
    navTabs: {
        float: 'left',
        color: baseColors.white,
        alignItems: 'flex-end'
    },
    tabsOpacity: {
        opacity: 0.5
    },
    disabledLinks: {
        pointerEvents: 'none'
    },
    navButton: importantClass({
        maxWidth: MAX_NAV_WIDTH,
    }),
    accessableHeaderButtonTextColor: {
        color: baseColors.grey20
    },
    legacyHeaderButtonTextColor: {
        baseColors: 'white !important'
    },
    headerLink: {
        textDecoration: 'none!important',
        marginRight: spacing.s1,
    },
    headerLinkLabel: {
        ...appFonts.smMedium,
        marginLeft: spacing.s1,
        '@media (max-width: 650px)': {
            display: 'none',
        },
    },
    headerButton: {
        '@media (max-width: 650px)': {
            minWidth: '48px!important',
        }
    },
    headerPositionFixed: importantClass({ top: SPACING_WITH_POP_UP }),
    skeleton: {
        width: 36,
        height: 18,
        marginLeft: spacing.s0,
        borderRadius: spacing.s0,
    },
    legacySkeletonBg: {
        backgroundColor: baseColors.primaryLighten,
    },
    accessableSkeletonBg: {
        backgroundColor: baseColors.white
    }
});

export default translate()(Header);
