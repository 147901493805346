import { StyleSheet } from 'aphrodite-jss';
import layoutStyleBase from './layoutStyleBase';
import { importantStyles } from '../style-util';
import spacing from '../spacing';
import baseColors from '../commonColor';

const layoutStyle = StyleSheet.create({
    ...layoutStyleBase,
    page: {
        ...layoutStyleBase.page,
        minHeight: '100vh',
        paddingBottom: spacing.s7
    },
    fixedFooter: {
        display: 'flex',
        justifyContent: 'center',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        width: '100%',
        zIndex: 1
    },
    pFixed: {
        position: 'fixed',
    },
    fixedFooterContent: {
        width: '100%',
        maxWidth: '980px',
        padding: `${spacing.s2}px 0`,
        '@media (max-width:996px)': {
            padding: `${spacing.s1}px ${spacing.s2}px`
        }
    },
    fixedFooterBorder: {
        borderColor: baseColors.grey80,
        borderTopWidth: 1,
        borderTopStyle: 'solid',
    },
    inlineFlex: {
        display: 'inline-flex',
    },
    db: {
        display: 'block',
        width: '100%'
    },
    vam: {
        verticalAlign: 'middle'
    },
    colorInherit: {
        color: 'inherit'
    },
    cp: {
        cursor: 'pointer'
    },
    cursorNotAllowed: {
        cursor: 'not-allowed'
    },
    textOverflow: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    pSticky: {
        position: 'sticky'
    },
    fixedFooterModalContainer: {
        ...layoutStyleBase.fixedFooterModalContainer,
        alignItems: 'center',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0
    },
    stickyContainer: {
        position: 'sticky',
        top: 64
    },
    stickyContainerUnderNavBar: {
        position: 'sticky',
        top: 130
    },
    stickyContainerOnboarding: {
        top: 0
    },
    wordBreak: {
        wordBreak: 'break-word',
        wordWrap: 'break-word',
    },
    hasBackground: {},
    ...importantStyles({
        buttonTextSmall: layoutStyleBase.buttonTextSmall,
        listItemNoHPadding: layoutStyleBase.listItemNoHPadding,
        listItemNoLeftPadding: layoutStyleBase.listItemNoLeftPadding,
        bgTransparent: layoutStyleBase.bgTransparent,
        bgWhite: layoutStyleBase.bgWhite,
        colorSecondary: layoutStyleBase.colorSecondary,
        colorBlack: layoutStyleBase.colorBlack,
        bgSecondary: layoutStyleBase.bgSecondary,
        bgDangerDarker: layoutStyleBase.bgDangerDarker,
        twoButtonsLeft: layoutStyleBase.twoButtonsLeft,
        twoButtonsRight: layoutStyleBase.twoButtonsRight,
        createEntityButton: layoutStyleBase.createEntityButton,
        cardFooterButton: layoutStyleBase.cardFooterButton,
        flexAlignStart: layoutStyleBase.flexAlignStart,
        noPadding: layoutStyleBase.noPadding,
        dangerButton: layoutStyleBase.dangerButton,
        listBorder: {
            ...layoutStyleBase.listBorder,
            borderTopStyle: 'solid',
        },
        listItem: {
            ...layoutStyleBase.listItem,
            borderBottomStyle: 'solid',
        },
        listItemBig: {
            ...layoutStyleBase.listItemBig,
            borderBottomStyle: 'solid',
        },
        noCursor: {
            cursor: 'auto'
        },
        noShadow: { boxShadow: 'none' },
        commonLayout: {
            width: '100%',
            maxWidth: '980px',
            '@media (max-width:996px)': {
                padding: `0 ${spacing.s1}px`
            }
        },
        listItemNoLastBorder: { '&:last-child': { borderBottomWidth: 0 } },
        notVisible: {
            opacity: 0,
            visibility: 'hidden',
            width: 0,
            height: 0
        },
        noMargin: layoutStyleBase.noMargin,
        textCenter: layoutStyleBase.textCenter,
        flexJustifyAlignStart: layoutStyleBase.flexJustifyAlignStart,
        footerOffset: layoutStyleBase.footerOffset,
        largeFooterOffset: layoutStyleBase.largeFooterOffset,
        smallerFooterOffset: layoutStyleBase.smallerFooterOffset,
        halfWidth: layoutStyleBase.halfWidth,
    })
});

export default layoutStyle;
