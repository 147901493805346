import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';

import { components as Core } from '../../../core';
import { baseColors, spacing } from '../../../../styles';
import WithEditCommunityBase, { styles as baseStyles } from './EditCommunityBase';

class EditCommunity extends PureComponent {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        fields: PropTypes.object.isRequired,
        isPrivate: PropTypes.bool,
        toggleType: PropTypes.func.isRequired,
        editCommunity: PropTypes.func.isRequired,
        fileChanged: PropTypes.func.isRequired,
        title: PropTypes.string,
        isEditing: PropTypes.bool,
        picture: PropTypes.string,
        entityType: PropTypes.string,
        namePlaceholder: PropTypes.string.isRequired,
        descriptionPlaceholder: PropTypes.string.isRequired,
        listTitle: PropTypes.string.isRequired,
        listSubTitle: PropTypes.string.isRequired,
        i18n: PropTypes.object.isRequired,
        rightButtonTitle: PropTypes.string,
        close: PropTypes.func.isRequired
    };

    static defaultProps = {
        isEditing: false,
        picture: undefined,
        title: undefined,
        isPrivate: false,
        entityType: null,
        rightButtonTitle: undefined
    };

    constructor(props) {
        super(props);
        this.propsToPass = {
            onPress: props.editCommunity
        };
    }

    get cancelModalData() {
        const { i18n } = this.props;
        const actionType = i18n.t('createEntity.action.editing');
        const entity = i18n.t('createEntity.type.community');
        return {
            title: i18n.t('createEntity.cancel.title', { entity, actionType }),
            text: i18n.t('createEntity.cancel.message', { entity, actionType })
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.isEditing && !nextProps.isEditing) this.props.close();
    }

    getSingleStepContent = () => {
        const { isEditing, entityType, fileChanged, picture, onChange,
            fields: { name, description }, isPrivate, toggleType, namePlaceholder, descriptionPlaceholder,
            listTitle, listSubTitle } = this.props;
        return (
            <Fragment>
                <Core.ImageUploader
                    hasDeleteOption={false}
                    fileChanged={fileChanged}
                    picture={picture}
                    title={this.props.title}
                    entityType={entityType}
                />
                <div className={css(styles.mainContainer)}>
                    <div>
                        <Core.Input
                            label={namePlaceholder}
                            onChangeText={onChange('name')}
                            value={name}
                            fullWidth={true}
                        />
                    </div>
                    <div>
                        <Core.Input
                            label={descriptionPlaceholder}
                            onChangeText={onChange('description')}
                            value={description}
                            multiline={true}
                            rows={6}
                            fullWidth={true}
                        />
                    </div>
                    <div className={css(styles.switchWrapper)}>
                        <div>
                            <div className={css(styles.privateTitle)}>{listTitle}</div>
                            <div className={css(styles.privateSubtitle)}>{listSubTitle}</div>
                        </div>
                        <Core.Switch value={!isPrivate} onChange={toggleType} />
                    </div>
                </div>
                <Core.BlockingLoading isLoading={isEditing} />
            </Fragment>
        );
    }

    cancelFlow = () => this.props.close();

    render() {
        return (
            <Core.Steps
                propsToPass={this.propsToPass}
                cancelFlow={this.cancelFlow}
                title={this.props.title}
                isCloseButtonShown={true}
                isSingleStep={true}
                cancelModalData={this.cancelModalData}
                buttonLabel={this.props.rightButtonTitle}
                edited={this.props.fields.edited}
                modalWithScrollbar={true}
                needTrackChanges={true}>
                {this.getSingleStepContent}
            </Core.Steps>
        );
    }
}

const styles = StyleSheet.create(
    {
        ...baseStyles,
        mainContainer: {
            ...baseStyles.mainContainer,
            display: 'flex',
            flexDirection: 'column',
            marginLeft: spacing.s3,
            marginRight: spacing.s3
        },
        switchWrapper: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: spacing.s3,
            paddingBottom: spacing.s3,
            borderBottomWidth: 1,
            borderBottomColor: baseColors.grey80,
            borderBottomStyle: 'solid'
        },
    }
);

export default WithEditCommunityBase(EditCommunity);
