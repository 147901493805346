import React, { PureComponent } from 'react';
import { translate } from '../../services';
import { baseColors } from '../../../../styles';

export default function withAccordionListItemBase(WrappedComponent) {
    class AccordionListItemBase extends PureComponent {

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                />
            );
        }
    }

    return translate()(AccordionListItemBase);
}

export const styles = {
    separator: {
        height: 0.5,
        backgroundColor: baseColors.grey70,
    },
};
