import _ from 'lodash';
import { combineReducers } from 'redux';
import * as types from './actionTypes';
import { actionTypes as coreTypes, reducerHelper } from '../core';
import { LIKES_ENTITY_TYPES, CHILD_NAMES } from './constants';

const initialState = {
    [LIKES_ENTITY_TYPES.stream]: {},
    items: {},
};

const reactionsInitialState = {
    [LIKES_ENTITY_TYPES.stream]: {},
    items: {},
    availableReactions: []
};

const commentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.MODERATE_COMMENT.SUCCESS:
        case types.DELETE_COMMENT.SUCCESS: {
            const { commentedItemType, commentedItemId, commentId } = action.payload;
            const comments = _.filter(state[commentedItemType][commentedItemId], item => item !== commentId);
            return {
                ...reducerHelper.updateDeepProperty(state, [commentedItemType, commentedItemId], comments),
                items: _.omit(state.items, commentId)
            };
        }
        case types.EDIT_COMMENT.SUCCESS: {
            const { comment } = action.payload;
            return {
                ...state,
                items: {
                    ...state.items,
                    [comment.commentId]: { ...state.items[comment.commentId], ...comment }
                }
            };
        }
        case types.POST_COMMENT.SUCCESS: {
            const { commentedItemType, commentedItemId, comment } = action.payload;
            const comments = [...state[commentedItemType][commentedItemId], comment.commentId];
            return {
                ...reducerHelper.updateDeepProperty(state, [commentedItemType, commentedItemId], comments),
                items: { ...state.items, [comment.commentId]: comment }
            };
        }
        case types.GET_MORE_COMMENTS.SUCCESS: {
            const { items, ids, commentedItemType, commentedItemId } = action.payload;
            const filteredItems = {
                ...state[commentedItemType],
                [commentedItemId]: _.uniq([...(state[commentedItemType][commentedItemId] || []), ...ids])
            };
            return {
                ...state,
                [commentedItemType]: filteredItems,
                items: { ...state.items, ...items }
            };
        }
        case types.DELETE_COMMENTS.SUCCESS: {
            const { commentedItemType, commentedItemId } = action.payload;
            return {
                ...state,
                [commentedItemType]: _.omit(state[commentedItemType], commentedItemId),
                items: _.omit(state.items, state[commentedItemType][commentedItemId])
            };
        }
        case types.GET_COMMENTS.SUCCESS: {
            const { commentedItemType, commentedItemId, items, ids } = action.payload;
            return {
                ...state,
                [commentedItemType]: { ...state[commentedItemType], [commentedItemId]: ids },
                items: { ...state.items, ...items }
            };
        }
        case types.GET_COMMENTS_FOR_TYPE.SUCCESS: {
            const { commentedItemType, byType, comments } = action.payload;
            return {
                ...state,
                [commentedItemType]: { ...state[commentedItemType], ...byType },
                items: { ...state.items, ...comments }
            };
        }
        case coreTypes.RESET: {
            return initialState;
        }
        default:
            return state;
    }
};

const likesReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_LIKES.SUCCESS: {
            const { likedItemType, likedItemId, items, ids, isReset } = action.payload;
            const newItems = isReset ? ids : [...state[likedItemType][likedItemId], ...ids];
            return {
                ...state,
                [likedItemType]: { ...state[likedItemType], [likedItemId]: newItems },
                items: { ...state.items, ...items }
            };
        }
        case coreTypes.RESET: {
            return initialState;
        }
        default:
            return state;
    }
};

const reactionsReducer = (state = reactionsInitialState, action) => {
    switch (action.type) {
        case types.GET_REACTION_EMOJIS.SUCCESS: {
            return {
                ...state,
                availableReactions: action.payload.data
            };
        }
        case types.GET_REACTIONS.SUCCESS: {
            const { likedItemType, likedItemId, items, ids, isReset } = action.payload;
            const newItems = isReset ? ids : [...state[likedItemType][likedItemId], ...ids];
            return {
                ...state,
                [likedItemType]: { ...state[likedItemType], [likedItemId]: newItems },
                items: { ...state.items, ...items }
            };
        }
        case coreTypes.RESET: {
            return initialState;
        }
        default:
            return state;
    }
};

export default combineReducers({
    [CHILD_NAMES.comments]: commentsReducer,
    [CHILD_NAMES.likes]: likesReducer,
    [CHILD_NAMES.reactions]: reactionsReducer
});
