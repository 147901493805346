import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { bindActionCreators } from 'redux';

import { translate, selectors as coreSelectors } from '../../../../core';
import { selectors as challengesSelectors } from '../../../../challenges';
import { selectors as onboardingSelectors, actions as onboardingActions } from '../../../../onboarding';
import { isHealthSurveyCompleted } from '../../../selectors';
import { appFonts, baseColors, spacing } from '../../../../../styles';

const TODAY = moment();
const REMINDER_TIME = 3;
export const ICON_SIZE = 56;
export const heartBubble = require('../../../../../image/heartBubble.png');

export default function WithWellbeingTabBase(WrappedComponent) {
    class WellbeingTabBase extends PureComponent {
        static propTypes = {
            hideRecommendation: PropTypes.bool,
            i18n: PropTypes.object.isRequired,
            healthSurveyStatus: PropTypes.array,
            healthSurveyResult: PropTypes.object,
            userId: PropTypes.number.isRequired
        };

        static defaultProps = {
            hideRecommendation: false,
            healthSurveyStatus: [],
            healthSurveyResult: null
        };

        componentDidMount() {
            const { actions, userId } = this.props;
            actions.getHealthSurveyResults();
            actions.getHealthSurveyResultStatus(userId);
        }

        state = { expandedCategory: '' };

        onToggleCategory = category => this.setState(prevState => ({ expandedCategory: prevState.expandedCategory === category ? '' : category }));

        get lastHealthSurveyTime() {
            return _.get(this.props.healthSurveyResult, ['hra_log', 'updated']);
        }

        get isVisibleReminder() {
            return this.lastHealthSurveyTime ? TODAY.diff(moment.unix(this.lastHealthSurveyTime), 'months') >= REMINDER_TIME : false;
        }

        get emptyStateTitle() {
            return this.props.i18n.t('wellbeing.unlock.title');
        }

        get emptyStateDescription() {
            return this.props.i18n.t('wellbeing.unlock.description');
        }

        get startSurveyButtonLabel() {
            return this.props.i18n.t('onboarding.lets_get_started');
        }

        get browseAndSetText() {
            return this.props.i18n.t('wellbeing.browseAndSet');
        }

        get wasItHelpfulText() {
            return this.props.i18n.t('wasThisHelpful');
        }

        get improveScoreProps() {
            const { i18n } = this.props;
            return {
                title: i18n.t('improve_my_score'),
                description: i18n.t('wellbeing.improveScore.description'),
                buttonLabel: i18n.t('update_wellbeing_score')
            };
        }

        get reminderProps() {
            const { i18n } = this.props;
            return {
                iconName: 'heart',
                isVisibleReminder: this.isVisibleReminder,
                title: i18n.t('wellbeing.reminder.title'),
                subtitle: i18n.t('wellbeing.reminder.updateAnswers')
            };
        }

        get healthSurveyProps() {
            return {
                results: _.get(this.props, 'healthSurveyResult'),
                healthSurveyResultsError: _.get(this.props, 'healthSurveyResultsError'),
            };
        }

        isExpandedCategory = category => this.state.expandedCategory === category;

        getSettingsGoalModalProps = title => ({
            iconName: 'bullseye-arrow',
            title: title || this.props.i18n.t('wellbeing.goalSetting'),
            text: this.props.i18n.t('settingGoals.description'),
            isButtonVisible: false,
            buttonTitle: this.props.i18n.t('got_it')
        });

        get isWellbeingCompleted() {
            const { isHealthSurveyCompleted } = this.props;
            return isHealthSurveyCompleted || _.get(this.healthSurveyProps, 'healthSurveyResultsError.errorDetails.description');
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    reminderProps={this.reminderProps}
                    improveScoreProps={this.improveScoreProps}
                    healthSurveyProps={this.healthSurveyProps}
                    emptyStateTitle={this.emptyStateTitle}
                    emptyStateDescription={this.emptyStateDescription}
                    startSurveyButtonLabel={this.startSurveyButtonLabel}
                    expandedCategory={this.state.expandedCategory}
                    isExpandedCategory={this.isExpandedCategory}
                    onToggleCategory={this.onToggleCategory}
                    browseAndSetText={this.browseAndSetText}
                    isVisibleReminder={this.isVisibleReminder}
                    improveScoreButtonType={this.improveScoreButtonType}
                    getSettingsGoalModalProps={this.getSettingsGoalModalProps}
                    isWellbeingCompleted={this.isWellbeingCompleted}
                    wasItHelpfulText={this.wasItHelpfulText}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        const myTab = ownProps.myTab || _.get(ownProps, 'route.params.myTab') || _.get(ownProps, 'location.state.myTab');
        return {
            goalsCount: challengesSelectors.getUncompletedPersonalGoalsCount(state),
            healthSurveyStatus: onboardingSelectors.getHealthSurveyStatusHra(state),
            healthSurveyResult: onboardingSelectors.getCurrentHealthSurveyResult(state),
            healthSurveyStatusHra: onboardingSelectors.getHealthSurveyStatusHra(state),
            isSavingAnswer: onboardingSelectors.isSavingAnswer(state),
            isLoadingHealthSurveyResults: onboardingSelectors.isLoadingHealthSurveyResultsStatus(state),
            healthSurveyResultsError: onboardingSelectors.getHealthSurveyResultsError(state),
            isHealthSurveyCompleted: isHealthSurveyCompleted(state),
            isAllPossibleGoalsSet: challengesSelectors.isAllPossibleGoalsSet(state),
            userId: coreSelectors.getCurrentUserId(state),
            myTab
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(onboardingActions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(WellbeingTabBase));
}

export const styles = {
    mainContainer: {
        marginBottom: spacing.s3
    },
    sectionContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: spacing.s0,
        flex: 1
    },
    wellbeingDescription: {
        ...appFonts.smRegular,
        textAlign: 'center'
    },
    healthSurveyButton: {
        marginLeft: spacing.s4,
        marginRight: spacing.s4,
        marginBottom: spacing.s4,
        flex: 1
    },
    improveScoreContainer: {
        flex: 1,
        padding: spacing.s3,
        alignItems: 'center'
    },
    improveScoreTitle: {
        ...appFonts.xlMedium,
        textAlign: 'center',
        marginBottom: spacing.s1
    },
    improveScoreDescription: {
        ...appFonts.mdRegular,
        textAlign: 'center',
        marginBottom: spacing.s5
    },
    improveScoreButton: {
        height: spacing.s9
    },
    recommendedIconsContainer: {
        marginTop: spacing.s7,
    },
    healthSurveyButtonText: {
        ...appFonts.smBold,
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3
    },
    icon: {
        marginTop: spacing.s1,
        marginBottom: spacing.s1,
        marginRight: spacing.s2
    },
    wellbeingOverall: {
        paddingTop: spacing.s5
    },
    emptyTitle: {
        ...appFonts.lgMedium,
        color: baseColors.black,
        marginTop: spacing.s3,
        textAlign: 'center',
    },
    emptyDescription: {
        ...appFonts.mdRegular,
        color: baseColors.grey20,
        marginTop: spacing.s3,
        textAlign: 'center',
    },
    heartImage: {
        width: spacing.s14,
        height: spacing.s12 + 2,
    },
    incompleteContainer: {
        marginTop: spacing.s5,
        paddingRight: spacing.s5,
        paddingLeft: spacing.s5,
        textAlign: 'center',
    },
    updateScoreButton: {
        ...appFonts.mdMedium,
        textAlign: 'center',
    },
    allSet: {
        ...appFonts.mdRegular,
        textAlign: 'center',
        marginTop: spacing.s3,
        color: baseColors.grey40,
        height: '100%',
    },
    marginRight8px: {
        marginRight: spacing.s1
    },
};
