import { isIE } from 'react-device-detect';

export default function refreshCacheAndReload() {
    if (!isIE && typeof caches !== 'undefined') {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then(names => {
            names.forEach(each => caches.delete(each));
        });
    }
    // delete browser cache and hard reload
    setTimeout(() => window.location.reload(), 4000);
}
