import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import { translate } from '../../../../core';
import { spacing } from '../../../../../styles';
import * as actions from '../../../actions';
import { getCompanyFeatures } from '../../../selectors';
import { selectors as authSelectors, actions as authActions } from '../../../../auth';
import { updateProfileValue, validateProfileValues } from '../../../services/profileEditorHelper';
import { STATE_KEYS } from '../../../constants';

const PREFERRED_NAME_ENABLE = 'preferred_name_enable';
const ORIGINAL_FIRST_NAME = 'originalFirstName';

export default function WithNameEditorBase(WrappedComponent) {
    class NameEditorBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            hasUnsavedChanges: PropTypes.func,
            profileFields: PropTypes.array,
            isLoading: PropTypes.bool,
            isSaving: PropTypes.bool,
            isEnabledHRIS: PropTypes.bool,
            companyFeatures: PropTypes.object
        };

        static defaultProps = {
            profileFields: [],
            hasUnsavedChanges: null,
            isLoading: false,
            isSaving: false,
            isEnabledHRIS: false,
            companyFeatures: {}
        };

        static getDerivedStateFromProps(nextProps, prevState) {
            const state = { isLoadingOrSaving: nextProps.isLoadingOrSaving };
            let { profileFields } = nextProps;
            if (prevState.isLoadingOrSaving && !nextProps.isLoadingOrSaving) {
                if (_.find(nextProps.companyFeatures, o => o.slug === PREFERRED_NAME_ENABLE && o.enabled === false)) {
                    profileFields = _.filter(nextProps.profileFields, o => o.stateKey !== STATE_KEYS.preferredName);
                }
                return { ...state, profileFields };
            }
            return state;
        }

        constructor(props) {
            super(props);
            this.state = {
                profileFields: props.profileFields,
                errors: {}
            };
            this.props.actions.getUser();
        }

        saveChanges = () => {
            const errors = validateProfileValues(this.state.profileFields);
            this.setState(() => ({ errors }));
            if (!_.isEmpty(errors)) {
                this.props.actions.validateProfileDataError();
            } else {
                const params = {};
                this.props.hasUnsavedChanges && this.props.hasUnsavedChanges(false);
                this.state.profileFields.forEach(field => {
                    const key = field.stateKey === STATE_KEYS.firstName ? ORIGINAL_FIRST_NAME : field.stateKey;
                    if (key && this.props.profileFields[key] !== field.value) {
                        params[field.stateKey] = field.value;
                    }
                });
                this.props.actions.saveUser(params);
                return true;
            }
        };

        updateUserValue = item => text => {
            const profileFields = updateProfileValue(this.state.profileFields, item.stateKey, text);
            this.props.hasUnsavedChanges && this.props.hasUnsavedChanges(true);
            this.setState(() => ({ profileFields }));
        };

        get isHRIS() {
            return this.props.isEnabledHRIS;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    saveChanges={this.saveChanges}
                    updateUserValue={this.updateUserValue}
                    profileFields={this.state.profileFields}
                    errors={this.state.errors}
                    isHRIS={this.isHRIS}
                />
            );
        }
    }

    const mapStateToProps = state => {
        const isLoading = authSelectors.isLoadingUser(state);
        const isSaving = authSelectors.isSavingUser(state);
        return {
            profileFields: authSelectors.getProfileFields(state),
            isLoading,
            isSaving,
            isLoadingOrSaving: isLoading || isSaving,
            isEnabledHRIS: authSelectors.isEnabledHRIS(state),
            companyFeatures: getCompanyFeatures(state)
        };
    };

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators({ ...actions, ...authActions }, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(NameEditorBase));
}

export const styles = {
    innerContainer: {
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3
    }
};