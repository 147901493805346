import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { translate } from '../../../../core';
import { selectors as onboardingSelectors } from '../../../../onboarding';

export default function WithWellbeingProgressIndicatorBase(WrappedComponent) {
    class WellbeingProgressIndicatorBase extends PureComponent {
        static propTypes = {
            progress: PropTypes.object.isRequired,
            children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
        };

        static defaultProps = {
            children: null
        };

        get score() {
            return _.get(this.props.progress, 'score', 0);
        }

        get genderAverage() {
            return _.get(this.props.progress, 'age_gender_avg', 0) && parseInt(_.get(this.props.progress, 'age_gender_avg', 0));
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    score={this.score}
                    genderAverage={this.genderAverage}
                />
            );
        }
    }

    const mapStateToProps = state => ({
        progress: onboardingSelectors.getCurrentHealthSurveyResultBySlug(state, 'overall')
    });

    return connect(mapStateToProps)(translate()(WellbeingProgressIndicatorBase));
}


export const styles = {
    mainContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    }
};