import React, { PureComponent } from 'react';
import { css, StyleSheet } from 'aphrodite-jss';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { components as Core, ROUTES } from '../../../core';
import { importantStyles, layoutStyle } from '../../../../styles';
import WithPriorityNotificationFeedBannerBase, { styles as baseStyles } from './PriorityNotificationFeedBannerBase';

class PriorityNotificationFeedBanner extends PureComponent {
    static propTypes = {
        unreadCount: PropTypes.number.isRequired,
        i18n: PropTypes.object.isRequired,
        isLoadingData: PropTypes.bool,
    };

    static defaultProps = {
        isLoadingData: false
    };

    render() {
        const { unreadCount, isLoadingData } = this.props;
        return (!isLoadingData && unreadCount > 0) ? (
            <Link to={ROUTES.notifications()} href={ROUTES.notifications} className={css(layoutStyle.flexRow, styles.linkHover, layoutStyle.fw, styles.notificationBanner)}>
                <div className={css(styles.unreadCountWrapper, layoutStyle.flexRowCenter)}>
                    <Core.Animated.Flash>
                        <div className={css(styles.successSection, layoutStyle.flexRow)}>
                            <span className={css(styles.unreadCountText)}>
                                {unreadCount}
                            </span>
                        </div>
                    </Core.Animated.Flash>
                </div>
                <div className={css(layoutStyle.flexColumn)}>
                    <span className={css(styles.title, styles.textColor)}>
                        {this.props.i18n.t('notification.priorityNotification.title')}
                    </span>
                    <span className={css(styles.subtitle, styles.textColor)}>
                        {this.props.i18n.t('notification.priorityNotification.subtitleWeb')}
                    </span>
                </div>
            </Link>
        ) : null;
    }
}

export default WithPriorityNotificationFeedBannerBase(PriorityNotificationFeedBanner);

const styles = StyleSheet.create(importantStyles({
    ...baseStyles,
    linkHover: {
        '&:hover': {
            textDecoration: 'none'
        }
    }
}));