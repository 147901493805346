import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import classnames from 'classnames';
import { components as Core } from '../../../core';
import withWeeklyGoalTagBase, { styles as baseStyles, smartModeIcon } from './WeeklyGoalTagBase';
import { baseColors, syncIconSize, spacing } from '../../../../styles';

class WeeklyGoalTag extends PureComponent {
    static propTypes = {
        isRestartGoal: PropTypes.bool.isRequired,
        weeklyGoalSubtitle: PropTypes.string.isRequired,
        containerClassName: PropTypes.string,
        isSmartModeGoal: PropTypes.bool.isRequired,
        smartGoalSubtitle: PropTypes.string.isRequired
    };

    static defaultProps = {
        containerClassName: null
    };

    get weeklySmartTags() {
        const { weeklyGoalSubtitle, isSmartModeGoal, smartGoalSubtitle } = this.props;

        if (isSmartModeGoal) {
            return (
                <>
                    <Core.Image src={smartModeIcon} className={css(styles.smartModeIcon)} />
                    <p className={css(styles.weeklySubtitle)}>{smartGoalSubtitle}</p>
                </>
            );
        }

        return (
            <>
                <Core.Icon name="sync" type="fa" color={baseColors.primary} size={syncIconSize} />
                <p className={css(styles.weeklySubtitle)}>{weeklyGoalSubtitle}</p>
            </>
        );
    }

    render() {
        const { isRestartGoal, containerClassName, isSmartModeGoal } = this.props;
        return (
            <div className={classnames(css(styles.subtitleWrapper), containerClassName)}>
                {isRestartGoal || isSmartModeGoal ? this.weeklySmartTags : null}
            </div>
        );
    }
}

export default withWeeklyGoalTagBase(WeeklyGoalTag);

const styles = StyleSheet.create({
    ...baseStyles,
    subtitleWrapper: {
        ...baseStyles.subtitleWrapper,
    },
    smartModeIcon: {
        ...baseStyles.smartModeIcon,
        marginTop: spacing.s0/2
    }
});
