import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { appFonts, baseColors, spacing, commonPadding } from '../../../../styles';
import { translate, pointsHelper, DATE_FORMATS, selectors as coreSelectors, parsers } from '../../../core';
import * as selectors from '../../selectors';
import { selectors as onboardingSelectors } from '../../../onboarding';
import * as actions from '../../actions';
import { dateLabelForPeriod, trackedChallengeActivities } from '../../services/challengePeriodHelper';
import { FREQUENCY } from '../../constants';
import { isPersonal } from '../../services/helper';

export default function WithGoalActivityHistoryDetailsBase(WrappedComponent) {
    class GoalActivityHistoryDetailsBase extends PureComponent {
        static propTypes = {
            relevantActivitiesGrouped: PropTypes.array.isRequired,
            arrayOfDatesInPeriod: PropTypes.array.isRequired,
            isWeeklyRestartGoal: PropTypes.bool.isRequired,
            customPointsUnit: PropTypes.string.isRequired,
            challengeId: PropTypes.string.isRequired,
            challenge: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            period: PropTypes.object.isRequired,
            restartGoalPeriod: PropTypes.object,
            i18n: PropTypes.object.isRequired,
            pointsForPeriod: PropTypes.string,
            restartGoal: PropTypes.object,
        };

        static defaultProps = {
            restartGoalPeriod: undefined,
            restartGoal: undefined,
            pointsForPeriod: '0',
        };

        constructor(props) {
            super(props);
            props.actions.getChallenge(props.challengeId);
            if (props.restartGoalPeriod) {
                const { challengeId, startDate, endDate } = props.restartGoalPeriod;
                props.actions.getActivityLogsExtended(startDate, endDate);
                if (challengeId === props.challengeId) return;
                props.actions.getChallenge(challengeId);
            }
        }

        componentDidUpdate(prevProps, prevState, snapshot) {
            const { challenge, restartGoalPeriod, actions, challengeId } = this.props;
            const restartGoalId = _.get(restartGoalPeriod, 'challengeId');
            if (!prevProps.challenge && challenge && (!restartGoalId || restartGoalId === challengeId)) {
                actions.getActivityLogsExtended(challenge.startDate, challenge.challengeDeadline);
            }
        }

        getFormattedDate = date => moment(date).format(DATE_FORMATS.monthFullDay);

        getActivitiesForDate = date => {
            const activitiesForDate = this.props.relevantActivitiesGrouped[date];
            return trackedChallengeActivities(activitiesForDate, this.userActivityLogsIds);
        };

        get userActivityLogsIds() {
            const { challenge, restartGoal } = this.props;
            const challengeToMap = restartGoal || challenge;
            return _.map(challengeToMap.userActivityLogs, ac => ac.activityLogId);
        }

        get hasNoActivities() {
            return this.props.relevantActivitiesGrouped.length === 0 ||
                !_.get(this.props.challenge, 'progress.periods.length');
        }

        get totalPoints() {
            const { period, customPointsUnit, isWeeklyRestartGoal, restartGoal } = this.props;
            const restartGoalTotal = pointsHelper.getRestartGoalTotal(restartGoal, isWeeklyRestartGoal);
            return pointsHelper.formatPoints(restartGoalTotal || period.goal, customPointsUnit);
        }

        get title() {
            const { restartGoalPeriod, period, challenge } = this.props;
            return dateLabelForPeriod(restartGoalPeriod || period, challenge, !!restartGoalPeriod);
        }

        get progressCircleValue() {
            const { restartGoalPeriod, period } = this.props;
            return restartGoalPeriod ? restartGoalPeriod.totalPercentage : _.get(period, 'value');
        }

        get earnedText() {
            return this.props.i18n.t('you_earned');
        }

        get ofTotalText() {
            return `${this.props.i18n.t('of')} ${this.totalPoints}`;
        }

        get emptyListText() {
            const { i18n, challenge } = this.props;
            return i18n.t(isPersonal(challenge) ? 'havent_tracked_goal' : 'havent_tracked_challenge');
        }

        get nothingTrackedText() {
            return this.props.i18n.t('noActivitiesTracked');
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    getActivitiesForDate={this.getActivitiesForDate}
                    progressCircleValue={this.progressCircleValue}
                    nothingTrackedText={this.nothingTrackedText}
                    getFormattedDate={this.getFormattedDate}
                    hasNoActivities={this.hasNoActivities}
                    emptyListText={this.emptyListText}
                    getPointsSum={this.getPointsSum}
                    ofTotalText={this.ofTotalText}
                    earnedText={this.earnedText}
                    title={this.title}
                />
            );
        }
    }


    const mapStateToProps = (state, ownProps) => {
        const restartGoalPeriod = ownProps.restartGoalPeriod || _.get(ownProps, 'location.state.restartGoalPeriod') || _.get(ownProps, 'route.params.restartGoalPeriod');
        const indexForData = restartGoalPeriod ? restartGoalPeriod.restartGoalPeriodIndex : periodIndex;
        const challengeId = ownProps.challengeId || _.get(ownProps, 'match.params.challengeId') || _.get(ownProps, 'route.params.challengeId');
        const restartGoalId = _.get(restartGoalPeriod, 'challengeId');
        const restartGoal = restartGoalId ? selectors.getChallenge(state, restartGoalId) : undefined;
        const periodIndex = () => {
            switch (true) {
                case !!_.get(ownProps, 'periodIndex'):
                    return _.get(ownProps, 'periodIndex');
                case !!_.get(ownProps, 'location.state.periodIndex'):
                    return _.get(ownProps, 'location.state.periodIndex');
                case _.get(ownProps, 'location.state.periodIndex') === 0:
                    return _.get(ownProps, 'location.state.periodIndex');
                case _.get(ownProps, 'route.params.periodIndex') === 0:
                    return _.get(ownProps, 'route.params.periodIndex');
                case !!_.get(ownProps, 'route.params.periodIndex'):
                    return _.get(ownProps, 'route.params.periodIndex');
                default:
                    return null;
            }
        };
        const period = selectors.getProgressPeriodByIndex(state, challengeId, periodIndex());
        return {
            isLoading: selectors.isLoadingActivityLogsExtended(state) || selectors.isLoadingChallengeInfo(state, challengeId),
            arrayOfDatesInPeriod: selectors.getArrayOfDatesInPeriod(state, challengeId, indexForData, restartGoalPeriod || period),
            pointsForPeriod: selectors.getPointsForPeriod(state, challengeId, indexForData, restartGoalPeriod || period),
            relevantActivitiesGrouped: restartGoalPeriod
                ? selectors.getActivitiesForRestartGoal(state, challengeId, restartGoalPeriod, restartGoal)
                : selectors.getRelevantActivitiesGrouped(state, challengeId, periodIndex()),
            language: parsers.convertLangString(coreSelectors.getCurrentUserLanguage(state)),
            isWeeklyRestartGoal: _.get(restartGoal, 'frequency') === FREQUENCY.weekly,
            isShowingOnboarding: onboardingSelectors.isShowingOnboarding(state),
            customPointsUnit: coreSelectors.getCustomPointsUnit(state),
            challenge: selectors.getChallenge(state, challengeId),
            period,
            restartGoalPeriod,
            restartGoal,
            challengeId,
        };
    };

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(GoalActivityHistoryDetailsBase));
}

export const styles = {
    headingContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: commonPadding,
        marginRight: commonPadding,
        marginTop: spacing.s9,
        marginBottom: spacing.s9,
        display: 'flex'
    },
    flexRowValueText: {
        ...appFonts.mdBold
    },
    flexRowTitleText: {
        ...appFonts.smRegular,
        color: baseColors.grey40
    },
    contentSubContainer: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'flex-start',
        paddingLeft: commonPadding,
        paddingRight: commonPadding,
        display: 'flex',
    },
    fullWidthBar: {
        width: '100%',
        backgroundColor: baseColors.grey85,
        flexDirection: 'row',
        paddingLeft: commonPadding,
        paddingRight: commonPadding,
        paddingTop: spacing.s0,
        paddingBottom: spacing.s0,
        justifyContent: 'flex-start',
        alignItems: 'center',
        display: 'flex',
    },
    noActivitiesTrackedText: {
        ...appFonts.smMedium,
        textAlign: 'center',
        color: baseColors.grey40,
        marginTop: spacing.s5,
        marginBottom: spacing.s5,
    },
    fullBarText: {
        ...appFonts.smRegular
    },
    noPeriods: {
        ...appFonts.mdMedium,
        marginLeft: commonPadding,
        marginRight: commonPadding,
        textAlign: 'center'
    },
};
