import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { baseColors, appFonts } from '../../../../styles';
import { translate } from '../../../core';
import { isRestartGoal, isSmartModeGoal } from '../../selectors';

export const smartModeIcon = require('../../../../image/smart_mode_icon_solid.png');

export default function withWeeklyGoalTagBase(WrappedComponent) {
    class WeeklyGoalTagBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired
        };

        get weeklyGoalSubtitle() {
            return this.props.i18n.t('restarts_every_week');
        }

        get smartGoalSubtitle() {
            return this.props.i18n.t('goalSmartMode.title');
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    weeklyGoalSubtitle={this.weeklyGoalSubtitle}
                    smartGoalSubtitle={this.smartGoalSubtitle}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        const challengeId = _.get(ownProps, 'challengeId');
        const goalName = _.get(ownProps, 'goalName');
        return {
            isRestartGoal: isRestartGoal(state, challengeId || goalName),
            isSmartModeGoal: isSmartModeGoal(state, challengeId || goalName)
        };
    }

    return connect(mapStateToProps)(translate()(WeeklyGoalTagBase));
}

const SUBTITLE_LEFT_OFFSET = 6;
const SMART_ICON_SIZE = 14;

export const styles = {
    weeklySubtitle: {
        ...appFonts.smRegular,
        color: baseColors.grey40,
        marginLeft: SUBTITLE_LEFT_OFFSET,
    },
    subtitleWrapper: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
    },
    smartModeIcon: {
        width: SMART_ICON_SIZE,
        height: SMART_ICON_SIZE,
    }
};