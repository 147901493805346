import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { translate, parsers, DATE_FORMATS, selectors as coreSelectors } from '../../../../core';
import { baseColors, spacing, appFonts } from '../../../../../styles';
import { isPersonal, isTrackByDevice, getConnectedDeviceName, isTeamSizeIncorrect, isInGracePeriod,
    isAfterDeadline, isGoalType, isUnstarted, daysUntilString, daysUntil, dateRange } from '../../../services/helper';
import * as actions from '../../../actions';
import { getChallenge, isRestartGoal } from '../../../selectors';

export const DISCLAIMER_ICON_SIZE = 16;
const DAYS_LEFT_TO_END = 3;
const CURRENT_AND_LAST_DAYS = 2;

export default function WithChallengeSubHeaderBase(WrappedComponent) {
    class ChallengeSubHeaderBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            challenge: PropTypes.object.isRequired,
            customPoints: PropTypes.string.isRequired,
            isRestartGoal: PropTypes.bool.isRequired,
        };

        state = {
            showDateRange: true,
            useDangerColor: false,
        };

        get hasDisclaimer() {
            const { challenge } = this.props;
            return isTrackByDevice(challenge) && !getConnectedDeviceName(challenge);
        }

        get challengeName() {
            return parsers.htmlDecode(this.props.challenge.challengeName);
        }

        get challengeType() {
            const { i18n, challenge } = this.props;
            return _.toLower(i18n.t(this.isChallengeType ? 'challenge' : challenge.challengeType));
        }

        get isChallengeType() {
            const { challenge } = this.props;
            return !isPersonal(challenge) && isGoalType(challenge);
        }

        get challengeSubTitle() {
            return this.props.challenge.isRecommended
                ? this.props.i18n.t('recommended_goal') : this.props.i18n.t('personal_goal.single');
        }

        get daysLeft() {
            const { challenge } = this.props;
            if (isInGracePeriod(challenge)) return challenge.track_deadline.days_left;
            return 0;
        }

        get challengeEndedDate() {
            return moment(this.props.challenge.challengeDeadline).format(DATE_FORMATS.monthFullDayShortYearFull);
        }

        get disclaimerTitle() {
            return this.props.i18n.t('app_device_required.disclaimer.title', { goalName: this.challengeType });
        }

        get disclaimerSubtitle() {
            return this.props.i18n.t('connectingDeviceToast.danger.title');
        }

        get teamSizeMessage() {
            const { challenge, customPoints } = this.props;
            return this.props.i18n.t('teamSizeMessage', { min: challenge.minTeamMembers, customPoints });
        }

        get daysUntilString() {
            const { challenge: { startDate }, i18n } = this.props;
            const dateIncludesToday = moment(startDate).add(1, 'days');
            return daysUntil(dateIncludesToday) === 1
                ? i18n.t('startsTomorrow')
                : daysUntilString(dateIncludesToday);
        }

        get dateInfoDetails() {
            const { challenge, i18n, isRestartGoal } = this.props;
            if (isRestartGoal) return null;
            const isToday = moment(challenge.challengeDeadline).isSame(moment(), 'day');
            const daysToEnd = daysUntil(challenge.challengeDeadline);
            const daysLeft = daysToEnd >= 0 ? daysToEnd + CURRENT_AND_LAST_DAYS : daysToEnd;
            const daysUntilEnd = isToday ? 1 : daysLeft;

            if (isUnstarted(challenge)) {
                return this.daysUntilString;
            } else {
                this.setState({
                    showDateRange: daysUntilEnd > 0,
                    useDangerColor: daysUntilEnd <= DAYS_LEFT_TO_END && daysUntilEnd > 0,
                });
                return daysUntilEnd > 0
                    ? `${daysUntilEnd} ${i18n.t(daysUntilEnd === 1 ? 'deadlineDayLeft' : 'deadlineDaysLeft')}`
                    : `${_.capitalize(i18n.t('endedOn'))} ${this.challengeEndedDate}`;
            }
        }

        get challengeDateInfo() {
            const { challenge, isRestartGoal } = this.props;
            return {
                duration: this.state.showDateRange ? dateRange(challenge, isRestartGoal) : null,
                details: this.dateInfoDetails
            };
        }

        render() {
            const { challenge, customPoints } = this.props;
            return (
                <WrappedComponent
                    {...this.props}
                    isTeamSizeIncorrect={isTeamSizeIncorrect(challenge)}
                    isPersonal={isPersonal(challenge)}
                    isInGracePeriod={isInGracePeriod(challenge)}
                    isAfterDeadline={isAfterDeadline(challenge)}
                    daysLeft={this.daysLeft}
                    challengeType={this.challengeType}
                    challengeSubTitle={this.challengeSubTitle}
                    hasDisclaimer={this.hasDisclaimer}
                    customPoints={customPoints}
                    challengeEndedDate={this.challengeEndedDate}
                    challengeName={this.challengeName}
                    disclaimerTitle={this.disclaimerTitle}
                    disclaimerSubtitle={this.disclaimerSubtitle}
                    teamSizeMessage={this.teamSizeMessage}
                    challengeDateInfo={this.challengeDateInfo}
                    useDangerColor={this.state.useDangerColor}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => ({
        challenge: getChallenge(state, ownProps.challengeId),
        customPoints: coreSelectors.getCustomPointsName(state),
        isRestartGoal: isRestartGoal(state, ownProps.challengeId),
    });

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(ChallengeSubHeaderBase));
}

export const styles = {
    // main: {
    //     flex: 1,
    //     backgroundColor: baseColors.white
    // },
    subTitle: {
        ...appFonts.smBold,
        color: baseColors.grey40,
        // marginLeft: spacing.s3,
        // marginRight: spacing.s3,
        marginTop: spacing.s1,
        marginBottom: spacing.s1,
        flex: 1
    },
    modalDescriptionText: {
        marginTop: spacing.s3,
        lineHeight: spacing.s5,
        color: baseColors.black
    },
    // appRequiredDisclaimer: {
    //     backgroundColor: baseColors.dangerDarker,
    //     marginLeft: spacing.s3,
    //     marginRight: spacing.s3,
    //     marginBottom: spacing.s5,
    //     marginTop: spacing.s3,
    //     borderRadius: spacing.s1,
    //     shadowOffset: { width: 0, height: spacing.s1 },
    //     shadowColor: baseColors.grey50,
    //     shadowOpacity: 0.3,
    //     shadowRadius: 2,
    // },
    // disclaimerInnerContainer: {
    //     flexDirection: 'row',
    //     paddingTop: spacing.s5,
    //     paddingBottom: spacing.s5,
    //     paddingLeft: spacing.s3,
    //     paddingRight: spacing.s3,
    //     display: 'flex'
    // },
    // appRequiredIcon: {
    //     marginRight: spacing.s3,
    //     marginTop: spacing.s1
    // },
    // appRequiredMessage: {
    //     ...appFonts.smBold,
    //     color: baseColors.white
    // },
    // appRequiredTitle: {
    //     ...appFonts.lgBold,
    //     color: baseColors.white
    // },
    dateInfo: {
        display: 'flex',
        marginTop: spacing.s1,
        ...appFonts.lgRegular,
        color: baseColors.grey40,
    },
    duration: {
        marginRight: spacing.s2,
    },
    disclaimerTitle: {
        ...appFonts.mdRegular,
    },
    disclaimerText: {
        ...appFonts.mdBold,
        paddingTop: spacing.s3,
    },
    disclaimerIcon: {
        marginBottom: 'auto',
        marginRight: spacing.s3
    },
    dangerColor: {
        color: baseColors.dangerDarker,
    },
};