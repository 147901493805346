import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import _ from 'lodash';
import { appFonts, baseColors, spacing, layoutStyle, importantClass } from '../../../../styles';
import Icon from '../Icon';

class PinDots extends Component {
    static propTypes = {
        callback: PropTypes.func.isRequired,
        numOfDigits: PropTypes.number.isRequired,
        obfuscate: PropTypes.bool,
        autoFocus: PropTypes.bool
    };

    static defaultProps = {
        obfuscate: false,
        autoFocus: true
    };

    constructor(props) {
        super(props);
        this.state = { text: '' };
    }

    textChanged = text => {
        this.setState({ text });
        this.props.callback(text);
    };

    clearText = () => {
        this.textChanged('');
        this._input.focus();
    };

    render() {
        return (
            <div className={css(styles.container)}>
                <input
                    ref={c => this._input = c}
                    type="text"
                    value={this.state.text}
                    maxLength={this.props.numOfDigits}
                    onChange={event => this.textChanged(event.target.value)}
                    className={css(styles.input)}
                    autoFocus={this.props.autoFocus} // eslint-disable-line
                />
                <button onClick={() => this._input.focus()} className={css(layoutStyle.db)}>
                    <div className={css(styles.pinBoxRow, styles.pinBoxBackgroundColor)}>
                        {_.map(_.times(this.props.numOfDigits), (value, index) => (
                            <div className={css(styles.pinDot)} key={index}>
                                {this.state.text[index] ?
                                    <span className={css(styles.pinText)}>
                                        {this.props.obfuscate ? '*' : this.state.text[index]}
                                    </span> :
                                    <Icon
                                        type="fa"
                                        name="circle"
                                        fill="solid"
                                        size={spacing.s3}
                                        color={baseColors.grey50}
                                        className={css(styles.drawerListIcon)}
                                    />
                                }
                            </div>
                        ))}
                    </div>
                </button>
            </div>
        );
    }
}

const styles = StyleSheet.create({
    pinBoxRow: {
        flexDirection: 'row',

        display: 'flex'
    },
    pinBoxBackgroundColor: {
        backgroundColor: baseColors.white
    },
    pinDot: {
        alignItems: 'center',
        justifyContent: 'center',

        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: appFonts.xxxlMedium.lineHeight
    },
    pinText: {
        ...appFonts.xxxlMedium
    },

    container: { alignItems: 'center' },
    input: importantClass({
        color: baseColors.white,

        position: 'absolute',
        border: 'none',
        background: 'transparent',
        width: 1,
        height: 1
    })
});


export default PinDots;