import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { appFonts, baseColors, spacing } from '../../../../../styles';

export const LEFT_ICON_SIZE = spacing.s8;

export default function WithCreateEntityListItemBase(WrapperComponent) {
    return class CreateChallengeListItemBase extends PureComponent {
        static propTypes = {
            item: PropTypes.object.isRequired,
            iconName: PropTypes.string,
            onPress: PropTypes.func,
            isActive: PropTypes.bool,
            size: PropTypes.number,
            isSmall: PropTypes.bool,
        };

        static defaultProps = {
            iconName: undefined,
            onPress: null,
            isActive: false,
            size: spacing.s3,
            isSmall: false,
        };

        onPress = () => {
            this.props.onPress && this.props.onPress(this.props.item);
        };

        render() {
            return (
                <WrapperComponent
                    {...this.props}
                    onPress={this.onPress}
                />
            );
        }
    };
}

export const styles = {
    itemContainer: {
        paddingTop: spacing.s3,
        paddingBottom: spacing.s3
    },
    itemWrapper: {
        marginLeft: 0
    },
    itemTitle: {
        ...appFonts.mdBold,
        marginLeft: 0
    },
    itemTitleActive: {
        color: baseColors.secondary
    },
    itemSubtitle: {
        ...appFonts.xsRegular,
        color: baseColors.grey40,
        marginLeft: 0
    },
    challengeItemContainer: {
        paddingTop: spacing.s3,
        paddingBottom: spacing.s3,
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3,
        borderWidth: 1,
        borderColor: baseColors.secondary,
        borderBottomColor: baseColors.secondary,
        marginBottom: spacing.s3,
        borderRadius: 10
    },
    leftIconContainer: {
        minWidth: LEFT_ICON_SIZE,
        textAlign: 'center',
        marginRight: spacing.s2
    }
};
