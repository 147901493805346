import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { layoutStyle, spacing, important } from '../../../../styles';
import { components as Core } from '../../../core';
import WeeklyGoalTag from '../WeeklyGoalTag';
import GoalIcon from '../GoalIcon';
import WithPreviousGoalItemBase, { styles as baseStyles, ICON_SIZE } from './PreviousGoalItemBase';

class PreviousGoalItem extends PureComponent {
    static propTypes = {
        goal: PropTypes.object.isRequired,
        dateString: PropTypes.string.isRequired,
        achievedString: PropTypes.string.isRequired,
        onPress: PropTypes.func.isRequired,
        challengeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    };

    get goalIcon() {
        const { goal } = this.props;
        return <GoalIcon goal={goal} slug={goal.categorySlug} size={ICON_SIZE} onPersonalGoalCard={true} />;
    }

    render() {
        return (
            <Core.TouchableItem
                onPressSingle={this.props.onPress}
                innerContainerClassName={css(styles.card)}
                imageClassName={css(styles.imageArea)}
                imageInnerComponent={this.goalIcon}
                separator={true}>
                <div className={css(layoutStyle.flex1)}>
                    <p className={css(styles.title)}>{this.props.goal.challengeName}</p>
                    <WeeklyGoalTag challengeId={this.props.challengeId} containerClassName={css(styles.weeklyTagWrapper)} />
                    <p className={css(styles.description, styles.topMargin)}>{this.props.dateString}</p>
                    <p className={css(styles.description)}>{this.props.achievedString}</p>
                </div>
            </Core.TouchableItem>
        );
    }
}

export default WithPreviousGoalItemBase(PreviousGoalItem);

const styles = StyleSheet.create({
    ...baseStyles,
    imageArea: {
        ...baseStyles.imageArea,
        marginRight: important(spacing.s2),
    },
});
