import { appFonts, spacing, baseColors, windowSize } from '../../styles';
import { ARROW_SIZE } from './constants';

export const ICON_SIZE = 20;

const BORDER_RADIUS = spacing.s1;
export const SHADOW_RADIUS = 10;
export const SHADOW_OPACITY = 0.3;

const CONTAINER_HORIZONTAL_PADDING = spacing.s1;

const ARROW_BACKGROUND_COLOR = 'transparent';
const ARROW_SHADOW_RADIUS = 2;
const ARROW_SHADOW_OPACITY = 0.15;

const ARROW_RIGHT_EXTRA_SPACE = spacing.s3;
export const ARROW_RIGHT_BORDER = windowSize.width - CONTAINER_HORIZONTAL_PADDING - BORDER_RADIUS - ARROW_SIZE - ARROW_RIGHT_EXTRA_SPACE;

export const ARROW_SHADOW_SIZE_ANDROID = 1;

const SHADOW_COLOR_ANDROID = 'rgba(0,0,0,0.15)';
const SHADOW_WIDTH_ANDROID = 1;
const Z_INDEX = 100;

export const tourStyles = {
    mainContainer: {
        backgroundColor: baseColors.transparent,
        zIndex: 1
    },
    innerContainerTop: {
        flex: 1,
        backgroundColor: baseColors.white,
        borderTopLeftRadius: BORDER_RADIUS,
        borderTopRightRadius: BORDER_RADIUS,
        padding: spacing.s3,
    },
    innerContainerBottom: {
        flex: 1,
        backgroundColor: baseColors.white,
        borderBottomLeftRadius: BORDER_RADIUS,
        borderBottomRightRadius: BORDER_RADIUS,
        padding: spacing.s3
    },
    elementsContainer: {
        backgroundColor: baseColors.white,
        borderRadius: BORDER_RADIUS,
    },
    headerContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: spacing.s1
    },
    headerContainerTitle: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-start'
    },
    title: {
        ...appFonts.lgBold
    },
    subtitle: {
        marginTop: spacing.s0 / 2,
        ...appFonts.smRegular,
        color: baseColors.grey40
    },
    action: {
        paddingLeft: spacing.s0
    },
    actionWithIcon: {
        paddingLeft: spacing.s11
    },
    actionText: {
        marginTop: spacing.s0 / 2,
        ...appFonts.smMedium
    },
    arrowContainer: {
        position: 'absolute',
        left: 0
    },
    arrowTopContainer: {
        top: 0
    },
    arrowBottomContainer: {
        bottom: 0
    }
};

export const mobileStyles = {
    mainContainer: {
        ...tourStyles.mainContainer,
        flex: 1,
        width: windowSize.width,
        zIndex: Z_INDEX
    },
    mainContainerAndroid: {
        flex: 1,
        backgroundColor: 'rgba(0,0,0,0.15)',
        width: windowSize.width,
    },
    toolTipContainer: {
        position: 'absolute',
        width: windowSize.width,
        paddingHorizontal: CONTAINER_HORIZONTAL_PADDING,
        paddingVertical: ARROW_SIZE
    },
    innerContainerTop: {
        ...tourStyles.innerContainerTop,
        shadowColor: baseColors.black
    },
    elementsContainer: {
        ...tourStyles.elementsContainer,
        shadowRadius: SHADOW_RADIUS,
        shadowOpacity: SHADOW_OPACITY,
        shadowOffset: { width: 0, height: 0 },
        shadowColor: '#000'
    },
    innerContainerAndroid: {
        borderLeftColor: SHADOW_COLOR_ANDROID,
        borderLeftWidth: SHADOW_WIDTH_ANDROID,
        borderRightColor: SHADOW_COLOR_ANDROID,
        borderRightWidth: SHADOW_WIDTH_ANDROID
    },
    bottomBorder: {
        borderBottomWidth: SHADOW_WIDTH_ANDROID,
        borderBottomColor: SHADOW_COLOR_ANDROID
    },
    topBorder: {
        borderTopWidth: SHADOW_WIDTH_ANDROID,
        borderTopColor: SHADOW_COLOR_ANDROID
    },
    arrowContainer: {
        ...tourStyles.arrowContainer,
        right: 0
    },
    arrow: {
        width: 0,
        height: 0,
        backgroundColor: ARROW_BACKGROUND_COLOR,
        borderLeftWidth: ARROW_SIZE,
        borderRightWidth: ARROW_SIZE,
        borderBottomWidth: ARROW_SIZE,
        borderLeftColor: ARROW_BACKGROUND_COLOR,
        borderRightColor: ARROW_BACKGROUND_COLOR,
        borderBottomColor: baseColors.white,
        shadowRadius: ARROW_SHADOW_RADIUS,
        shadowOpacity: ARROW_SHADOW_OPACITY,
        shadowOffset: { width: 0, height: -spacing.s0 }
    },
    arrowShadow: {
        width: 0,
        height: 0,
        backgroundColor: ARROW_BACKGROUND_COLOR,
        borderLeftWidth: ARROW_SIZE+ARROW_SHADOW_SIZE_ANDROID,
        borderRightWidth: ARROW_SIZE+ARROW_SHADOW_SIZE_ANDROID,
        borderBottomWidth: ARROW_SIZE+ARROW_SHADOW_SIZE_ANDROID,
        borderLeftColor: ARROW_BACKGROUND_COLOR,
        borderRightColor: ARROW_BACKGROUND_COLOR,
        borderBottomColor: SHADOW_COLOR_ANDROID
    }
};

export const webStyles = {
    elementsContainer: {
        ...tourStyles.elementsContainer,
        boxShadow: `0 0 8px rgba(0, 0, 0, ${SHADOW_OPACITY})`,
    },
    triangle: {
        position: 'relative',
        padding: spacing.s3,
        boxSizing: 'border-box',
        '&:after': {
            position: 'absolute',
            width: 0,
            height: 0,
            bottom: 0,
            boxSizing: 'border-box',
            borderStyle: 'solid',
            borderColor: `transparent transparent ${baseColors.white} ${baseColors.white}`,
            transformOrigin: '0 0',
            transform: 'rotate(-45deg)',
            boxShadow: `-2px 2px 2px 0px rgba(0, 0, 0, ${SHADOW_OPACITY})`,
            borderWidth: 11.5,
            marginLeft: 0,
            left: `-${spacing.s3}`,
            top: '50%',
            content: '""'
        }
    },
    topArrow: {
        transform: 'rotate(180deg)'
    },
    bottomArrow: {
        marginLeft: -spacing.s7
    },
    actionWithIcon: {
        paddingLeft: spacing.s11 + spacing.s1
    },
};
