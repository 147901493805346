import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import _ from 'lodash';

import { baseColors, spacing, layoutStyle, appFonts, importantClass } from '../../../../styles';
import { components as Core, ROUTES, onKeyPress, CAROUSEL_TYPES } from '../../../core';
import RewardTouchableItem from '../RewardTouchableItem';
import RewardCarouselItem from '../RewardCarouselItem';
import withHowToEarnBase, { styles as baseStyles } from './HowToEarnBase';
import EarnPartnersItem from '../EarnPartners/EarnPartnersItem';
import { constants as ChallengeConstants, components as ChallengeComponents } from '../../../challenges';

const SLIDES_TO_SHOW = { REWARDS: 5, CHALLENGES: 2 };

class HowToEarn extends PureComponent {
    static propTypes = {
        levels: PropTypes.array,
        isLoading: PropTypes.bool,
        isLiveBetter: PropTypes.bool,
        partnerItems: PropTypes.array,
        items: PropTypes.array.isRequired,
        title: PropTypes.string.isRequired,
        history: PropTypes.object.isRequired,
        viewAllText: PropTypes.string.isRequired,
        bonusChallengesTitle: PropTypes.string.isRequired,
        bonusChallengeItems: PropTypes.array.isRequired,
        earnPartnersTitle: PropTypes.string.isRequired,
        browsePartnersText: PropTypes.string.isRequired,
        levelsTitle: PropTypes.string.isRequired,
        levelsDescription: PropTypes.string.isRequired,
        achievementsTitle: PropTypes.string.isRequired,
        isEarnPartnersEnabled: PropTypes.number.isRequired,
        earnPartnersDescription: PropTypes.string.isRequired,
        achievementsDescription: PropTypes.string.isRequired,
        isBonusChallengesEnabled: PropTypes.number.isRequired,
        bonusChallengesDescription: PropTypes.string.isRequired,
    };

    static defaultProps = {
        levels: [],
        partnerItems: [],
        isLoading: false,
        isLiveBetter: false,
    };

    constructor(props) {
        super(props);
        this.LIST_ITEM_PROPS = {
            separator: false,
            mainContainerClassName: styles.itemContainer
        };
    }

    get partnerItems() {
        const { partnerItems, isEarnPartnersEnabled, isLiveBetter } = this.props;
        return isEarnPartnersEnabled && partnerItems.length && isLiveBetter ? (
            <div>
                {this.partnersHeaderComponent}
                <div className={css(styles.partnerContainer)}>
                    {_.map(partnerItems, this.renderPartnerItem)}
                </div>
                {this.partnersFooterButton}
            </div>) : null;
    }

    get partnersHeaderComponent() {
        const { earnPartnersTitle, earnPartnersDescription } = this.props;
        return (
            <div className={css(styles.headerSection)}>
                <div className={css(styles.headerTitle)}>{earnPartnersTitle}</div>
                <div className={css(styles.headerDescription)}>{earnPartnersDescription}</div>
            </div>
        );
    }

    get partnersFooterButton() {
        return (
            <div className={css(styles.footerButtonContainer)}>
                <Core.Button
                    id="browseAllPartners"
                    className={css(styles.button)}
                    onPress={this.openAllPartners}
                    fullWidth={true}
                    size="medium"
                    type="outlined">
                    {this.props.browsePartnersText}
                </Core.Button>
            </div>
        );
    }

    get levelItems() {
        const { levels } = this.props;
        return (
            <>
                {this.levelItemsHeader}
                <div className={css(layoutStyle.flexRow, styles.sectionWrapper)}>
                    {_.map(levels, this.renderRewardItem)}
                </div>
            </>
        );
    }

    get levelItemsHeader() {
        return (
            <div className={css(styles.headerSection)}>
                <div className={css(styles.headerTitle)}>{this.props.levelsTitle}</div>
                <div className={css(styles.headerDescription)}>{this.props.levelsDescription}</div>
            </div>
        );
    }

    get achievementsHeaderComponent() {
        return (
            <div className={css(styles.headerSection)}>
                <div className={css(styles.headerTitle, layoutStyle.flexSpaceBetween, layoutStyle.flexAlignCenter)}>
                    {this.props.achievementsTitle}
                    {this.achievementsViewAll}
                </div>
                <div className={css(styles.headerDescription)}>{this.props.achievementsDescription}</div>
            </div>
        );
    }

    get achievementsViewAll() {
        return (
            <div
                role="button"
                tabIndex="0"
                onKeyDown={onKeyPress.enter(this.openAllAchievements)}
                onClick={this.openAllAchievements}>
                <p className={css(styles.buttonText)}>{this.props.viewAllText}</p>
            </div>
        );
    }

    get achievementItems() {
        const { items } = this.props;
        if (!items.length) return null;

        return (
            <div className={css(styles.sectionWrapper, this.showAchievementsCarousel ? layoutStyle.noPadding : styles.alignLeft)}>
                {this.achievementsHeaderComponent}
                {this.showAchievementsCarousel ?
                    this.achievementsCarousel :
                    <div className={css(layoutStyle.flexRow)}>
                        {_.map(items, this.renderItem)}
                    </div>}
            </div>
        );
    }

    get challengeItems() {
        const { isBonusChallengesEnabled, bonusChallengeItems, isLoading } = this.props;
        const length = bonusChallengeItems.length;

        if (isBonusChallengesEnabled && length) {
            return (
                <div className={css(styles.sectionWrapper, this.showChallengesCarousel ? styles.challenges : styles.alignLeft)}>
                    {this.challengesHeaderComponent}
                    {length === 1 ?
                        this.renderChallengeItem(bonusChallengeItems[0]) :
                        <Core.Carousel
                            dots={true}
                            type={CAROUSEL_TYPES.twoOnly}
                            dotsClassName={css(styles.challengesDots)}
                            isFeaturedSection={true}
                            isLoading={isLoading}
                            itemsLength={bonusChallengeItems.length}>
                            {_.map(bonusChallengeItems, this.renderChallengeItem)}
                        </Core.Carousel>}
                </div>);
        }
        return null;
    }

    get challengesHeaderComponent() {
        const { bonusChallengesTitle, bonusChallengesDescription } = this.props;
        return (
            <div className={css(styles.headerSection)}>
                <div className={css(styles.headerTitle, layoutStyle.flexSpaceBetween)}>
                    {bonusChallengesTitle}
                    {this.challengesViewAll}
                </div>
                <div className={css(styles.headerDescription)}>{bonusChallengesDescription}</div>
            </div>
        );
    }

    get challengesViewAll() {
        return (
            <div
                role="button"
                tabIndex="0"
                onKeyDown={onKeyPress.enter(this.openAllBonusChallenges)}
                onClick={this.openAllBonusChallenges}>
                <p className={css(styles.buttonText)}>{this.props.viewAllText}</p>
            </div>
        );
    }

    get sections() {
        return (
            <>
                {this.levelItems}
                {this.achievementItems}
                {this.challengeItems}
                {this.partnerItems}
            </>
        );
    }

    get showAchievementsCarousel() {
        return this.props.items.length > SLIDES_TO_SHOW.REWARDS;
    }

    get showChallengesCarousel() {
        return this.props.bonusChallengeItems.length > SLIDES_TO_SHOW.CHALLENGES;
    }

    get noPadding() {
        return this.showChallengesCarousel ? layoutStyle.noPadding : undefined;
    }

    get achievementsCarousel() {
        const { items, isLoading } = this.props;
        return (
            <Core.Carousel
                dots={this.showAchievementsCarousel}
                dotsClassName={css(styles.achievementsDots)}
                slidesToShow={SLIDES_TO_SHOW.REWARDS}
                isFeaturedSection={true}
                isLoading={isLoading}
                itemsLength={items.length}>
                {_.map(items, this.renderCarouselItem)}
            </Core.Carousel>
        );
    }

    openAchievementDetails = achievementId => {
        this.props.history.push(ROUTES.achievementDetails(achievementId));
    };

    goToAchievementDetails = achievement => {
        this.openAchievementDetails(achievement.id);
    };

    openAllPartners = () => this.props.history.push(ROUTES.earnPartnersList());

    openAllAchievements = () => this.props.history.push(ROUTES.myAchievements());

    openAllBonusChallenges = () => {
        const type = ChallengeConstants.DISPLAY_TYPES.BONUS;
        this.props.history.push(ROUTES.challengesByType(type));
    };

    renderCarouselItem = item => (
        <RewardCarouselItem
            item={item}
            index={item.id}
            onPressSingle={this.goToAchievementDetails}
            innerRewardComponent={this.renderRewardItem(item)}
        />
    );

    renderRewardItem = item => (
        <div className={css(layoutStyle.flexColumnCenter, styles.levelItem)}>
            <Core.Image src={item.image_url || item.iconUrl} className={css(styles.levelIcon)} resizeMode="contain" />
            <p className={css(styles.rewardNameStyles)}>{item.name}</p>
            <div className={css(layoutStyle.flexRowCenter, !item.externalRewardPoints && styles.hideCoins)}>
                <Core.Icon type="light" name="coins" size={spacing.s3} color={baseColors.warn} />
                <p className={css(styles.rewardPoints)}>{`+ ${item.externalRewardPoints}`}</p>
            </div>
        </div>
    );

    renderItem = item => {
        const passProps = {
            onPressSingle: this.goToAchievementDetails,
            innerRewardComponent: this.renderRewardItem(item),
            ...this.LIST_ITEM_PROPS,
        };
        return <RewardTouchableItem key={item.id} item={item} {...passProps} />;
    };

    renderPartnerItem = item => <EarnPartnersItem partner={item} key={item.earnPartnerId} isCard={true} />;

    renderChallengeItem = item => {
        const CHALLENGE_ITEM_PROPS = {
            showParticipants: false,
            cardClassName: this.noPadding,
            cardInnerClassName: this.noPadding,
            descriptionClassName: styles.description,
            imageClassName: css(styles.imageContainer),
            cardContainerClassName: styles.cardContainer,
        };
        return <ChallengeComponents.ChallengeListItem challenge={item} {...CHALLENGE_ITEM_PROPS} />;
    };

    render() {
        return (
            <Core.SmallerContainer
                widerHeaderComponent={<Core.EntityDetailsHeader hasBackButton={true} />}>
                <p className={css(styles.title)}>{this.props.title}</p>
                {this.sections}
                {this.props.isLoading ? <Core.ListLoading /> : null}
            </Core.SmallerContainer>
        );
    }
}

export default withHowToEarnBase(HowToEarn);

const ICON_SIZE = 50;
const BADGE_ITEM_WIDTH = 120;
const styles = StyleSheet.create({
    ...baseStyles,
    partnerContainer: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    headerSection: {
        marginTop: spacing.s3,
        paddingBottom: spacing.s7,
    },
    title: {
        ...appFonts.xxxlBold,
    },
    sectionWrapper: {
        ...baseStyles.sectionWrapper,
        borderBottomStyle: 'solid',
    },
    rewardPoints: {
        ...appFonts.mdMedium,
        marginLeft: spacing.s0,
    },
    buttonText: {
        ...baseStyles.buttonText,
        ...appFonts.mdMedium,
        color: baseColors.secondary
    },
    achievementsDots: importantClass({
        marginTop: spacing.s3,
        marginBottom: spacing.s1,
    }),
    challengesDots: importantClass({
        paddingBottom: 0,
        paddingTop: 0,
    }),
    itemContainer: importantClass({
        flex: 'none',
        paddingTop: 0,
    }),
    alignLeft: {
        justifyContent: 'flex-start',
        paddingBottom: spacing.s5,
    },
    challenges: {
        paddingBottom: spacing.s3,
    },
    description: {
        marginBottom: spacing.s1,
    },
    cardContainer: {
        paddingLeft: 0,
        marginRight: spacing.s5,
    },
    imageContainer: {
        marginRight: spacing.s5,
    },
    levelIcon: {
        height: ICON_SIZE,
        width: ICON_SIZE,
    },
    levelItem: {
        ...baseStyles.levelItem,
        width: BADGE_ITEM_WIDTH,
        marginRight: spacing.s1,
    },
    hideCoins: {
        visibility: 'hidden'
    }
});
