import _ from 'lodash';
import I18n from '../../../i18n/i18n';
import { constants as coreConstants } from '../indexBase';

export const currentLanguage = () => {
    const language = _.split(I18n.language, '-', 2);
    return language[0];
};

export const appStoreBadge = () => coreConstants.APP_STORE_IMAGES[currentLanguage()] ?? coreConstants.APP_STORE_IMAGES.en;
export const googlePlayBadge = () => coreConstants.GOOGLE_PLAY_IMAGES[currentLanguage()] ?? coreConstants.GOOGLE_PLAY_IMAGES.en;
export const appGalleryBadge = () => coreConstants.APP_GALLERY_IMAGES[currentLanguage()] ?? coreConstants.APP_GALLERY_IMAGES.en;
