import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';

import { components as Core } from '../../../core';
import WithOnboardingProgressBarBase from './OnboardingProgressBarBase';
import { layoutStyle } from '../../../../styles';

class OnboardingProgressBar extends PureComponent {
    static propTypes = { percentage: PropTypes.number.isRequired };

    render() {
        return (
            <Core.ProgressBar
                variant="determinate"
                filledPercentage={this.props.percentage}
                className={css(layoutStyle.flex1, styles.bar)}
            />
        );
    }
}

export default WithOnboardingProgressBarBase(OnboardingProgressBar);

const styles = StyleSheet.create({
    bar: { paddingTop: 0 }
});
