import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectors as coreSelectors, translate, pointsHelper, numbers } from '../../../core';
import * as actions from '../../actions';
import { getDailyMetrics, isLoadingDailyMetrics } from '../../selectors';
import { getLevels } from '../../../rewards/selectors';
import { appFonts, spacing, baseColors } from '../../../../styles';

export const CONTAINER_HEIGHT = 210;
export const SKELETON_WIDTH = 61;
export const SKELETON_HEIGHT = 24;

export default function WithMyDayBase(WrappedComponent) {
    class MyDayBase extends PureComponent {
        static propTypes = {
            dailyMetrics: PropTypes.object,
            onRef: PropTypes.func,
            actions: PropTypes.object,
            isLoading: PropTypes.bool,
            i18n: PropTypes.object.isRequired,
            customPointsUnit: PropTypes.string.isRequired,
            scoreForPeriod: PropTypes.number.isRequired,
        };

        static defaultProps = {
            dailyMetrics: {},
            onRef: null,
            actions: {},
            isLoading: false
        };

        constructor(props) {
            super(props);
            this.loadData();
        }

        componentDidUpdate(prevProps) {
            if (prevProps.scoreForPeriod !== this.props.scoreForPeriod || this.props.isLoading) {
                this.loadData();
            }
        }

        loadData = () => {
            this.props.actions.getDailyMetrics();
        };

        get myDayHeading() {
            return this.props.i18n.t('yourDaySummary');
        }

        get earnedToday() {
            const earnedToday = _.get(this.props.dailyMetrics, 'points.day', 0);
            return pointsHelper.formatPointsValue(Math.round(earnedToday));
        }

        get totalPtsUnit() {
            const { i18n, customPointsUnit } = this.props;
            return `${i18n.t('total')} ${customPointsUnit}`;
        }

        get stepsToday() {
            const { dailyMetrics: { metrics } } = this.props;
            return Math.round(_.get(_.find(metrics, item => item.unit === 'steps'), 'value', 0));
        }

        get formattedEarnedToday() {
            return numbers.numberWithCommas(this.earnedToday);
        }

        get formattedStepsToday() {
            return numbers.numberWithCommas(this.stepsToday);
        }

        get stepsUnit() {
            return this.props.i18n.t('steps');
        }

        get trackActivityText() {
            return this.props.i18n.t('trackActivity.text');
        }

        get skeletonProps() {
            return {
                width: SKELETON_WIDTH,
                height: SKELETON_HEIGHT
            };
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    myDayHeading={this.myDayHeading}
                    earnedToday={this.formattedEarnedToday}
                    totalPtsUnit={this.totalPtsUnit}
                    stepsToday={this.formattedStepsToday}
                    stepsUnit={this.stepsUnit}
                    trackActivityText={this.trackActivityText}
                    skeletonProps={this.skeletonProps}
                />
            );
        }
    }

    function mapStateToProps(state) {
        return {
            dailyMetrics: getDailyMetrics(state),
            isLoading: isLoadingDailyMetrics(state),
            customPointsUnit: coreSelectors.getCustomPointsUnit(state),
            scoreForPeriod: getLevels(state).scoreForPeriod,
            isLiveBetter: coreSelectors.isLiveBetter(state)
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(MyDayBase));
}

export const TRACK_ACTIVITY_BTN_WIDTH = 156;

export const styles = {
    loaderContainer: {
        paddingLeft: spacing.s0,
        paddingRight: spacing.s1,
        paddingTop: spacing.s3,
        paddingBottom: spacing.s3,
        minHeight: CONTAINER_HEIGHT
    },
    loaderInner: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    loaderText: {
        marginTop: spacing.s3
    },
    myDayHeading: {
        ...appFonts.lgMedium,
        color: baseColors.black,
    },
    ptsStepsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
    },
    value: {
        ...appFonts.xlRegular,
        color: baseColors.black,
        marginTop: spacing.s1,
    },
    valueUnit: {
        ...appFonts.mdRegular,
        color: baseColors.grey20,
    },
    trackActivityBtn: {
        height: spacing.s9,
        width: TRACK_ACTIVITY_BTN_WIDTH,
        alignSelf: 'center',
    },
    trackActivityText: {
        ...appFonts.smMedium,
    },
    skeleton: {
        marginTop: spacing.s1,
        marginBottom: spacing.s1,
    }
};
