import _ from 'lodash';
import { BUILD_EXTENSION_FIELDS, DEFAULT_FIELDS, FIELDS_CONFIG, POLICY_FIELD, BELL_SUBDOMAIN, BELL_SUBDOMAIN_DEMO, BELL_SUBDOMAIN_BETA } from '../constants';
import { languageHelper } from '../../core';
import i18n from '../../../i18n/i18n';

const getFieldsConfig = (build, isLiveBetter) => _.mapValues(FIELDS_CONFIG(isLiveBetter), (item, key) => _.merge({ ...item }, _.get(BUILD_EXTENSION_FIELDS, [build, key], {})));

const NATURAL_ORDER = 9999;

export const getRegistrationFields = (registrationFields, build, isLiveBetter) => {
    const fields = _.get(registrationFields, 'length') ?
        [..._.filter(registrationFields, field => field.enabled), POLICY_FIELD] : DEFAULT_FIELDS;
    const configFields = getFieldsConfig(build, isLiveBetter);
    return _.sortBy(_.map(fields, f => _.defaults({ ...f }, configFields[f.name])), [f => f.order || NATURAL_ORDER]);
};

export const isCompanySubdomainBell = subdomain => subdomain === BELL_SUBDOMAIN || subdomain === BELL_SUBDOMAIN_DEMO || subdomain === BELL_SUBDOMAIN_BETA;

export const getSupportEmail = isTelus => {
    let supportEmail = 'support@sproutatwork.com';
    if (isTelus) {
        languageHelper.isFrenchLanguage(i18n) ?
            supportEmail = 'aide.bien-etre@sv.telussante.com'
            : supportEmail = 'help.wellbeing@vc.telushealth.com';
    }
    return supportEmail;
};
