import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { isIE, isSafari } from 'react-device-detect';
import { StyleSheet, css } from 'aphrodite-jss';
import { spacing } from '../../../../styles';
import { components as Core } from '../../../core';
import WithReactionsModalBase, { styles as baseStyles } from './ReactionsModalBase';

class ReactionsModal extends PureComponent {
    static propTypes = {
        availableReactions: PropTypes.array,
        selectReaction: PropTypes.func.isRequired,
        closeModal: PropTypes.func.isRequired,
    };

    static defaultProps = {
        availableReactions: []
    };

    renderItem = item => (
        <div className={css(styles.emojiItem, isIE ? styles.emojiItemIE : null)} key={item.slug} onClick={() => this.props.selectReaction(item, this.props.closeModal)}>
            <Core.Image src={item.emoji} className={css(styles.emoji, isSafari ? styles.emjojiWebkit : null)} />
        </div>
    );

    render() {
        const { availableReactions } = this.props;
        return (
            <div className={css(styles.innerContainer)}>
                <div className={css(styles.emojiRow)}>
                    {_.map(availableReactions, this.renderItem)}
                </div>
            </div>
        );
    }

}

export default WithReactionsModalBase(ReactionsModal);

const EMOJI_SIZE = spacing.s7;

const styles = StyleSheet.create({
    ...baseStyles,
    emojiRow: {
        ...baseStyles.emojiRow,
        paddingTop: spacing.s1,
        paddingBottom: spacing.s1,
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3,
    },
    emoji: {
        width: EMOJI_SIZE,
        height: EMOJI_SIZE,
        borderRadius: 0,
        transform: 'scale(1);',
        transformOrigin: 'bottom;',
        transition: 'all .4s cubic-bezier(0.33, 0, 0.2, 1);',
        imageRendering: 'optimize-contrast',
        '&:hover': {
            cursor: 'pointer',
            transform: 'scale(1.15);',
            transformOrigin: 'bottom;',
            transition: 'all .2s cubic-bezier(0.33, 0, 0.2, 1);',
        }
    },
    emjojiWebkit: {
        imageRendering: '-webkit-optimize-contrast',
    },
    emojiItemIE: {
        paddingLeft: spacing.s2,
        paddingRight: spacing.s2
    }
});