import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { translate, selectors as coreSelectors, PLATFORMS, Platform, tracker, actions as coreActions } from '../../../core';
import { appFonts, baseColors, spacing } from '../../../../styles';
import { actions as rewardsActions, selectors as rewardsSelectors } from '../../../rewards';
import { actions as challengesActions, selectors as challengesSelectors } from '../../../challenges';
import { selectors as appsDevicesSelectors } from '../../../appsdevices';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import {
    FLOW_STEPS as STEPS,
    PERSONALIZE_STEPS,
    INTRO_STEPS_WITH_NOTIFICATIONS,
    HEALTH_SURVEY_STEPS,
    EAP_STEPS,
    FIREBASE_NAMES,
    APPS_DEVICES_STEPS,
    PRIVACY_CONTROLS_STEPS
} from '../../constants';
import { actions as notificationsActions } from '../../../notifications';

export default function WithOnboardingFlowBase(WrappedComponent) {
    class OnboardingFlowBase extends PureComponent {
        static propTypes = {
            onNext: PropTypes.func,
            isTaskCard: PropTypes.bool,
            slug: PropTypes.string,
            numOfConnectedDevices: PropTypes.number,
            currentUser: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            onboardingFlowSteps: PropTypes.object.isRequired,
            points: PropTypes.object.isRequired,
            requiredTasks: PropTypes.array.isRequired,
            skipSteps: PropTypes.array.isRequired,
            steps: PropTypes.array.isRequired,
            screen: PropTypes.object,
            i18n: PropTypes.object.isRequired,
            step: PropTypes.string,
            pushNotificationsConfig: PropTypes.object.isRequired,
            isShowingOnboarding: PropTypes.bool.isRequired,
            isLiveBetter: PropTypes.bool.isRequired,
            isSuccessfullyConnectedDevice: PropTypes.bool,
            achievements: PropTypes.array
        };

        static defaultProps = {
            step: STEPS.welcomeUser,
            isTaskCard: false,
            numOfConnectedDevices: 0,
            slug: null,
            screen: {},
            onNext: null,
            isSuccessfullyConnectedDevice: false,
            achievements: []
        };

        constructor(props) {
            super(props);
            this.showRequiredTasksOnly = false;
            !props.achievements.length && props.actions.getAchievements();
        }

        onNext = (step, props = {}, isSkipPressed = false) => {
            const { actions: { setOnboardingComplete, getRewards, getUserTasks }, slug, isShowingOnboarding } = this.props;
            let nextStep = this.getNextStep(step, isSkipPressed);
            if (this.isNotificationsAndSkipRequired(nextStep) || this.isPlatformDeviceInfoAndSkipRequired(nextStep)) {
                nextStep = this.getNextStep(nextStep);
            }
            if (!isSkipPressed) this.saveStepPoints();
            if (!_.get(this.wrapped, 'onNext')) return;
            if (!nextStep || (nextStep === STEPS.notifications && isSkipPressed)) {
                setOnboardingComplete();
                getRewards();
                tracker.logEvent('onboarding', { completed: true });
                if (isShowingOnboarding) {
                    getUserTasks(true);
                } else if (!isSkipPressed) {
                    tracker.logEvent('CompleteProfile_FinishTask', { task: slug });
                }
            }
            _.has(this.wrapped, 'onNext') && this.wrapped.onNext(nextStep, props);
        };

        getSkipStepsNoUndefined = () => _.filter(this.props.skipSteps, step => step !== undefined)

        getNextStep = (step, isSkipPressed = false) => {
            if (!this.showRequiredTasksOnly) {
                return isSkipPressed && step !== STEPS.chooseGoal
                    ? this.nextStep(step, this.getSkipStepsNoUndefined())
                    : this.nextStep(step, this.props.steps);
            }
            return this.nextRequiredStep(step);
        };

        nextStep = (currentStep, steps) => {
            const index = _.indexOf(steps, currentStep);
            return index !== -1 ? steps[index + 1] : null;
        };

        nextRequiredStep = step => {
            const nextStep = this.nextStep(step, this.props.requiredTasks);
            if (nextStep) return nextStep;
            return _.indexOf(this.props.requiredTasks, step) === -1 ? _.get(this.props.requiredTasks, '0') : null;
        };

        isNotificationsAndSkipRequired = nextStep => {
            // Skip notifications screen on Android
            // or add (Platform.OS === PLATFORMS.android || global.seenNotificationPermissions);
            // to detect if the ios DEVICE has seen this notifications screen so it won't show it again
            // as this screen will only be shown once per INSTALL
            // If the app is removed, the storage for this flag is also removed and will be prompted again
            const value = nextStep === STEPS.notifications &&
                (Platform.OS === PLATFORMS.android);
            if (value) {
                // Just call it.. if the seenNotificationPermissions flag is correct (true), the user shouldn't see a popup
                this.props.actions.requestPushPermissions();
                this.saveStepPoints(nextStep);
            }
            return value;
        };

        isPlatformDeviceInfoAndSkipRequired = nextStep => {
            // Skip platform device screen on web
            const value = nextStep === STEPS.appsDevicesPlatform && Platform.OS === PLATFORMS.web;
            if (value) {
                this.saveStepPoints(nextStep);
            }
            return value;
        };

        saveStepPoints = (step = this.props.step) => {
            if (step === STEPS.appsDevices && !this.props.isSuccessfullyConnectedDevice) {
                tracker.logEvent('onboarding', { step, connected: 0 });
                return;
            }
            const userPoints = _.toInteger(_.get(this.props.currentUser, 'points', 0)) || 0;
            const newPoints = userPoints + _.toInteger(this.props.points[step]);
            this.props.actions.recordStepCompleted(step, true);
            this.props.actions.updateUserPoints(newPoints);
            tracker.logEvent('onboarding', { step, newPoints });
        };

        onWelcomeUserButton = showRequiredTasksOnly => {
            this.showRequiredTasksOnly = showRequiredTasksOnly;
            this.onNext(this.props.step);
        };

        saveRef = ref => (this.wrapped = ref);

        get isFirstPage() {
            return this.props.step === this.props.steps[0];
        }

        get isLastPageInSection() {
            const { onboardingFlowSteps, step } = this.props;
            const key = _.findKey(this.props.onboardingFlowSteps, s => _.includes(s, this.props.step));
            const index = _.indexOf(onboardingFlowSteps[key], step) + 1;
            const length = _.get(onboardingFlowSteps[key], 'length');

            if (index === length) {
                return true;
            }

            return false;
        }

        get hasBackButton() {
            if (this.props.step === STEPS.appsDevicesInfo || this.props.step === STEPS.eapIntro) return false;
            // TODO: remove this line when onboarding flow will be correct
            if (this.props.step === STEPS.chooseGoal) return true;
            return !this.isFirstPage && !_.includes(HEALTH_SURVEY_STEPS, this.props.step);
        }

        get hasPersonalizeTitle() {
            return _.includes(PERSONALIZE_STEPS, this.props.step);
        }

        get hasAppsDevicesTitle() {
            return this.props.step === STEPS.appsDevices;
        }
        get hasPrivacyControlsTitle() {
            return this.props.step === STEPS.visibilitySettings ||
                this.props.step === STEPS.helpUsImprove;
        }

        get hasEAPTitle() {
            return this.props.step === STEPS.eapSurvey;
        }

        get hasEAPContactTitle() {
            return this.props.step === STEPS.eapContact;
        }

        get isRecommendedGoalsScreen() {
            return this.props.step === STEPS.recommendedGoals;
        }

        get hasSkipButton() {
            return !_.includes(INTRO_STEPS_WITH_NOTIFICATIONS, this.props.step)
                && !_.includes(HEALTH_SURVEY_STEPS, this.props.step)
                && !_.includes(EAP_STEPS, this.props.step) && !_.includes(APPS_DEVICES_STEPS, this.props.step)
                && !_.includes(STEPS.chooseGoal, this.props.step)
                && !_.includes(PRIVACY_CONTROLS_STEPS, this.props.step);
        }

        get rightButtonLabel() {
            return this.isRecommendedGoalsScreen ? this.props.i18n.t('done') : this.props.i18n.t('skip');
        }

        get hasOnboardingProgress() {
            return !_.includes(_.keys(this.props.onboardingFlowSteps), this.props.step) && !_.includes(HEALTH_SURVEY_STEPS, this.props.step)
                && !_.includes(APPS_DEVICES_STEPS, this.props.step) && !_.includes(PRIVACY_CONTROLS_STEPS, this.props.step);
        }

        onSkip = () => {
            const { step, slug } = this.props;
            const prevIntroStep = _.findKey(
                this.props.onboardingFlowSteps,
                s => _.includes(s, step)
            ) || step;
            if (this.isRecommendedGoalsScreen) {
                this.props.actions.recordStepCompleted(step);
                tracker.logEvent('CompleteProfile_FinishTask', { task: slug });
            } else if (_.includes(EAP_STEPS, step)) {
                _.forEach(EAP_STEPS, s => this.props.actions.recordStepCompleted(s));
            }
            tracker.logEvent('onboarding', { skip: step, prevIntroStep });
            this.onNext(prevIntroStep, {}, true);
        };

        render() {
            return (
                <WrappedComponent
                    onRef={this.saveRef}
                    {...this.props}
                    onNext={this.onNext}
                    hasBackButton={this.hasBackButton}
                    hasAppsDevicesTitle={this.hasAppsDevicesTitle}
                    hasPersonalizeTitle={this.hasPersonalizeTitle}
                    hasEAPTitle={this.hasEAPTitle}
                    hasEAPContactTitle={this.hasEAPContactTitle}
                    hasSkipButton={this.hasSkipButton}
                    hasOnboardingProgress={this.hasOnboardingProgress}
                    onSkip={this.onSkip}
                    onWelcomeUserButton={this.onWelcomeUserButton}
                    isRecommendedGoalsScreen={this.isRecommendedGoalsScreen}
                    rightButtonLabel={this.rightButtonLabel}
                    isLastPageInSection={this.isLastPageInSection}
                    hasPrivacyControlsTitle={this.hasPrivacyControlsTitle}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const step = ownProps.step || _.get(ownProps, 'match.params.step') || _.get(ownProps, 'route.params.step');
        const slug = ownProps.slug || _.get(ownProps, 'location.state.slug') || _.get(ownProps, 'route.params.slug');
        const isTaskCard = ownProps.isTaskCard || _.get(ownProps, 'location.state.isTaskCard') || _.get(ownProps, 'route.params.isTaskCard');
        const isHomeCard = ownProps.isHomeCard || _.get(ownProps, 'location.state.isHomeCard') || _.get(ownProps, 'route.params.isHomeCard');
        const onClose = ownProps.onClose || _.get(ownProps, 'route.params.onClose');
        return ({
            slug,
            isTaskCard,
            isHomeCard,
            isShowingOnboarding: selectors.isShowingOnboarding(state),
            step: selectors.getOnboardingStep(state, step),
            points: selectors.getOnboardingTasksPoints(state),
            steps: selectors.getSteps(state, slug),
            skipSteps: selectors.getOnboardingSkipSteps(state, slug),
            numOfConnectedDevices: appsDevicesSelectors.getNumberOfConnectedDevices(state),
            isOnboardingIncomplete: coreSelectors.getOnboardingIncompleteValue(state),
            requiredTasks: selectors.getRequiredSteps(state),
            onboardingFlowSteps: selectors.getOnboardingStepsFlow(state),
            currentUser: coreSelectors.getCurrentUser(state),
            pushNotificationsConfig: coreSelectors.getPushNotificationsConfig(state),
            afterOnboardingPath: selectors.getAfterOnboardingPath(state),
            goals: challengesSelectors.getMyPersonalGoals(state),
            startGoalsTime: selectors.getStartTime(state, FIREBASE_NAMES.setGoals),
            isLiveBetter: coreSelectors.isLiveBetter(state),
            isSuccessfullyConnectedDevice: appsDevicesSelectors.isSuccessfullyConnected(state),
            achievements: rewardsSelectors.getAllRewardsAchievements(state),
            onClose
        });
    };

    const mapDispatchToProps = dispatch => ({
        actions: bindActionCreators({
            ...actions,
            ...rewardsActions,
            ...challengesActions,
            ...notificationsActions,
            ...coreActions
        }, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(OnboardingFlowBase));
}

export const styles = {
    main: {
        flex: 1,
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: baseColors.white,
        marginTop: spacing.s6,
    },
    barContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: baseColors.white,
        paddingLeft: spacing.s0,
        minHeight: spacing.s12
    },
    infoContainer: {
        flex: 1,
        backgroundColor: baseColors.white,
        alignItems: 'center',
        marginLeft: spacing.s3
    },
    skipText: {
        ...appFonts.smMedium,
        color: baseColors.secondary,
        textAlign: 'right'
    },
    personalizeText: {
        ...appFonts.mdRegular,
        textAlign: 'center'
    },
    indexText: {
        ...appFonts.xsRegular,
        textAlign: 'center'
    },
    icon: {
        paddingLeft: 0
    },
    iconWrapper: {
        flex: 1,
        backgroundColor: baseColors.white
    }
};
