import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { css, StyleSheet } from 'aphrodite-jss';
import { isIE } from 'react-device-detect';
import { layoutStyle, importantClass, spacing, baseColors } from '../../../../styles';
import { components as Core } from '../../../core';
import ProgressTitle from '../ProgressTitle';
import WithGoalProgressBase, { styles as baseStyles } from './GoalProgressBase';
import { CHALLENGE_TYPES } from '../../constants';
import GoalProgressInfo from '../GoalProgressInfo';

class GoalProgress extends PureComponent {
    static propTypes = {
        challengeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        challenge: PropTypes.object.isRequired,
        onPress: PropTypes.func,
        isUpdating: PropTypes.bool,
        isCurrentPeriod: PropTypes.bool,
        onSlideChange: PropTypes.func.isRequired,
        progressList: PropTypes.array.isRequired,
        language: PropTypes.string.isRequired,
        filledTotalPeriods: PropTypes.number.isRequired,
        isShowRightStats: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        onPress: undefined,
        isUpdating: false,
        isCurrentPeriod: false
    };

    renderPeriodProgress = item => {
        const period = item.item ? item.item : item;
        const { challengeId, isUpdating, isCurrentPeriod, isShowRightStats, filledTotalPeriods, completedPercent } = this.props;
        return (
            <div className={css(!isCurrentPeriod ? styles.cardMargin : null)}>
                <div key={challengeId} className={css(layoutStyle.flexRow, styles.cardPeriodProgress)}>
                    <div className={css(layoutStyle.flexColumn, layoutStyle.flex1, styles.leftStatsMR)}>
                        <GoalProgressInfo
                            challengeId={challengeId}
                            period={period}
                            progressType={CHALLENGE_TYPES.goal}
                            isUpdating={isUpdating}
                        />
                        <div className={css(layoutStyle.fw, styles.progressBar)}>
                            <Core.ProgressBar
                                variant="determinate"
                                height={spacing.s1}
                                progressColor={baseColors.primary}
                                filledPercentage={completedPercent}
                                classes={css(styles.progressBar)}
                                accessibilityLabels={`Goal progressbar. Value is ${completedPercent}`}
                            />
                        </div>
                    </div>

                    <div className={css(styles.progressInfoWrapper, layoutStyle.flexColumn, layoutStyle.flex1,
                        isIE ? styles.displayBlock : null)}>
                        {isShowRightStats ? (
                            <>
                                <GoalProgressInfo
                                    challengeId={challengeId}
                                    period={period}
                                    progressType={CHALLENGE_TYPES.goal}
                                    isUpdating={isUpdating}
                                    isMemberPeriod={true}
                                    isTotalRightStats={true}
                                />
                                <div className={css(layoutStyle.fw, styles.progressBar)}>
                                    <Core.ProgressBar
                                        variant="determinate"
                                        height={spacing.s1}
                                        progressColor={baseColors.primary}
                                        filledPercentage={filledTotalPeriods}
                                        classes={css(styles.progressBar)}
                                    />
                                </div>
                            </>
                        ): null }
                    </div>
                </div>
            </div>
        );
    };

    render() {
        return this.props.isCurrentPeriod ? this.renderPeriodProgress(this.props.progressList[0]) : (
            <Fragment>
                <ProgressTitle onPress={this.props.onPress} challengeId={this.props.challengeId} />
                <div className={css(layoutStyle.cardPaddingLeft)}>
                    <Core.Carousel
                        parent={this}
                        type="single"
                        arrows={true}
                        dots={true}
                        afterChange={this.props.onSlideChange}
                        itemsLength={this.props.progressList.length}>
                        {_.map(this.props.progressList, this.renderPeriodProgress)}
                    </Core.Carousel>
                </div>
            </Fragment>
        );
    }
}

export default WithGoalProgressBase(GoalProgress);

const styles = StyleSheet.create({
    ...baseStyles,
    progressInfoWrapper: {
        justifyContent: 'center',
    },
    cardPeriodProgress: importantClass({
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: spacing.s1
    }),
    cardMargin: importantClass({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }),
    displayBlock: importantClass({
        display: 'block'
    })
});