import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';
import _ from 'lodash';
import { CAROUSEL_TYPES, components as Core, CSS_CLASSES, ROUTES } from '../../../core';
import WithChallengesBase, { styles as baseStyles } from './ChallengesBase';
import ChallengeListItem from '../ChallengeListItem/ChallengeListItem';
import createChallenge from '../../services/createChallenge';
import { DISPLAY_TYPES } from '../../constants';
import { spacing, imageSize, importantClass } from '../../../../styles';
import ChallengeInvitations from '../ChallengeDetails/ChallengeInvitations/ChallengeInvitations';
import ExploreCarouselItem from '../ExploreCarouselItem';

const ITEMS_QUANTITY = 2;

class Challenges extends PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        activeTab: PropTypes.object.isRequired,
        isLoading: PropTypes.bool,
        hasMore: PropTypes.bool,
        handleChange: PropTypes.func,
        tabs: PropTypes.array.isRequired,
        challengeItemsToRender: PropTypes.array.isRequired,
        loadMoreContent: PropTypes.func,
        history: PropTypes.object.isRequired,
        invitedChallenges: PropTypes.array,
        companyChallenges: PropTypes.array,
        recentChallenges: PropTypes.array,
        keyExtractor: PropTypes.func.isRequired,
        isExtendedRole: PropTypes.bool,
        userId: PropTypes.string,
        actions: PropTypes.object.isRequired,
        title: PropTypes.string.isRequired,
        location: PropTypes.object,
    };

    static defaultProps = {
        isLoading: false,
        hasMore: false,
        invitedChallenges: [],
        companyChallenges: [],
        recentChallenges: [],
        isExtendedRole: false,
        userId: undefined,
        handleChange: null,
        loadMoreContent: null,
        location: null
    };

    constructor(props) {
        super(props);
        this.carouselRef = React.createRef();
    }

    viewAllAction = type => () => this.props.history.push(ROUTES.challengesByType(type));

    onOpenCreateChallengeActionSheet = () => {
        createChallenge({ finishRedirect: ROUTES.challenges() }, this.props.isExtendedRole, this.props.userId, this.props.actions);
    };

    renderListItem = ({ item }) => (
        <div className={css(styles.listContainer)}>
            <ChallengeListItem challenge={item} key={item.challengeId} />
        </div>
    );

    renderCarouselItem = item => (
        <div className={css(styles.listContainer)}>
            {_.map(item, challenge => (
                <ChallengeListItem key={challenge.challengeId} challenge={challenge} />
            ))}
        </div>
    );

    renderCompanyChallengesCarouselItem = item => (
        <ExploreCarouselItem
            key={item.challengeId}
            challengeId={item.challengeId}
            isOneItemInCarusel={this.props.companyChallenges.length === 1}
            isChallengesPage={true}
            carouselRef={this.carouselRef}
            hasSmallerFocusOffset={true}
            containerClassName={css(styles.onAndUpcomingCaruselWrapper)}
        />
    );

    get loadingComponent() {
        return this.props.isLoading ? <Core.ListLoading /> : null;
    }

    get generateLoadingSkeleton() {
        return (
            this.props.isLoading ? (
                <div>
                    {_.map(new Array(ITEMS_QUANTITY), item => (
                        <div className={css(styles.loadingItemsContainer)}>
                            <Core.SkeletonRect count={1} height={imageSize.smd} width={imageSize.xmd} />
                            <div className={css(styles.skeletonTextWrapper)}>
                                <Core.SkeletonRect count={1} className={css(styles.skeletonItem)} />
                                <Core.SkeletonRect count={1} width="60%" className={css(styles.skeletonItem)} />
                            </div>
                        </div>
                    ))}
                </div>
            ) : null
        );
    }

    get afterTabsComponent() {
        const countChallenges = this.props.companyChallenges.length;
        const countItems = countChallenges > 0 && countChallenges < 3 ? countChallenges : 3;

        return (
            <div>
                {this.props.isOnAndUpcomingTab ? (
                    <div>
                        <>
                            {this.props.companyChallenges.length ? (
                                <div
                                    ref={this.carouselRef}
                                    className={CSS_CLASSES.explore}>
                                    <Core.Carousel
                                        parent={this}
                                        type={CAROUSEL_TYPES.multiple}
                                        arrows={true}
                                        separator={true}
                                        itemsCount={countItems}
                                        itemsLength={this.props.companyChallenges.length}>
                                        {_.map(this.props.companyChallenges, this.renderCompanyChallengesCarouselItem)}
                                    </Core.Carousel>
                                </div>
                            ) : null}
                        </>
                        {this.props.recentChallenges.length && this.props.challengeItemsToRender(DISPLAY_TYPES.USER).length ?
                            <Core.SubHeader className={css(styles.createdByUserHeader)} noLeftPadding={true} titleSize={Core.SubHeader.SIZES.large} title={this.props.i18n.t('createdByMembersChallenges')} />
                            : null
                        }
                    </div>
                ) : null
                }
            </div>
        );
    }

    infiniteList = activeId => {
        const { challengeItemsToRender, keyExtractor, hasMore,
            loadMoreContent, isLoading } = this.props;
        return (
            <Core.InfiniteLazyListFadeIn
                isHorizontal={false}
                hasMarginHorizontal={false}
                data={challengeItemsToRender(activeId)}
                keyExtractor={keyExtractor}
                renderItem={this.renderListItem}
                hasMore={hasMore}
                onEndReached={loadMoreContent(activeId)}
                onEndReachedThreshold={0.5}
                isLoading={isLoading}
                hideLoader={true}
                itemHeight={100}
                twoColumns={false}
            />
        );
    }

    get onAndUpcomingTabContent() {
        return (
            <>
                {this.afterTabsComponent}
                {this.generateLoadingSkeleton}
                {this.props.emptyChallengesComponent}
                {this.infiniteList(DISPLAY_TYPES.USER)}
                {this.loadingComponent}
            </>
        );
    }

    get meTabContent() {
        return (
            <>
                {this.generateLoadingSkeleton}
                {this.props.emptyChallengesComponent}
                {this.infiniteList(DISPLAY_TYPES.ATTENDED)}
                {this.infiniteList(DISPLAY_TYPES.COMPLETED)}
                {this.loadingComponent}
            </>
        );
    }

    backUrl= () => this.props.history.push(this.props.location.state.backRedirect);

    render() {
        const { i18n, title, handleChange, tabs, activeTab, subHeaderText, isOnAndUpcomingTab, invitedChallenges } = this.props;
        return (
            <Core.Layout.WiderContainer>
                <Core.EntityDetailsHeader
                    hasBackButton={true}
                    backUrl={_.get(this.props, 'location.state.backRedirect') ? this.backUrl : null}
                    rightComponent={(
                        <Core.CreateEntityButton
                            onPress={this.onOpenCreateChallengeActionSheet}
                            text={i18n.t('createChallenge.createNew')}
                        />
                    )}
                    title={title}
                />
                <Core.SubHeader
                    title={title}
                    noTopPadding={true}>
                    <div className={css(styles.subHeaderText)}>{subHeaderText}</div>
                </Core.SubHeader>
                {invitedChallenges.length ? <ChallengeInvitations history={this.props.history} /> : null}
                <Core.TabsWithContent
                    onPress={handleChange}
                    tabs={tabs}
                    activeTab={activeTab}
                    style={styles.tabs}
                    isStickyUnderNavBar={true}>
                    {isOnAndUpcomingTab ? this.onAndUpcomingTabContent : this.meTabContent}
                </Core.TabsWithContent>
            </Core.Layout.WiderContainer>
        );
    }
}

const styles = StyleSheet.create({
    ...baseStyles,
    loadingItemsContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        paddingLeft: spacing.s3,
        paddingBottom: spacing.s3
    },
    skeletonTextWrapper: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        paddingRight: spacing.s4,
        paddingLeft: spacing.s2,
    },
    skeletonItem: {
        margin: spacing.s2,
        marginBottom: 0
    },
    subHeaderText: {
        ...baseStyles.subHeaderText,
        marginTop: -spacing.s2,
        marginBottom: spacing.s9
    },
    listContainer: {
        ...baseStyles.listContainer,
        marginLeft: 0
    },
    createdByUserHeader: {
        marginLeft: spacing.s1
    },
    onAndUpcomingCaruselWrapper: importantClass({
        paddingTop: spacing.s0,
        paddingBottom: spacing.s0,
        borderRadius: spacing.s1,
    })
});

export default WithChallengesBase(Challenges);
