import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import detectBrowserLanguage from 'detect-browser-language';
import locales from './locales';

const defaultLocale = 'en-CA';

i18n
    .use(LanguageDetector)
    .init({
        interpolation: {
            prefix: '%{',
            suffix: '}',
            escapeValue: false,
        },
        whitelist: ['en-CA', 'en-AU', 'fr-CA', 'fr', 'es-419', 'es', 'vi', 'pt-BR', 'de', 'ti', 'th', 'zh', 'zh-CN', 'da', 'da-DK', 'sv', 'sv-SE', 'fr-FR', 'nl-NL', 'nl', 'it-IT', 'it', 'tl', 'tl_PH'],
        fallbackLng: defaultLocale,
        lng: detectBrowserLanguage(),
        resources: {
            'fr-CA': { translation: locales.fr_CA },
            'fr': { translation: locales.fr_FR },
            'fr-US': { translation: locales.fr_FR },
            'fr-FR': { translation: locales.fr_FR },
            'en-CA': { translation: locales.en_CA },
            'en-AU': { translation: locales.en_AU },
            'es-419': { translation: locales.es_419 },
            'es-CL': { translation: locales.es_419 },
            'es-CO': { translation: locales.es_419 },
            'es-CR': { translation: locales.es_419 },
            'es-HN': { translation: locales.es_419 },
            'es-MX': { translation: locales.es_419 },
            'es-PE': { translation: locales.es_419 },
            'es-US': { translation: locales.es_419 },
            'es-UY': { translation: locales.es_419 },
            'es-VE': { translation: locales.es_419 },
            'es': { translation: locales.es_419 },
            'es_CA': { translation: locales.es_419 },
            'vi': { translation: locales.vi_VI },
            'vi-VI': { translation: locales.vi_VI },
            'vi-US': { translation: locales.vi_VI },
            'vi-CA': { translation: locales.vi_VI },
            'vi-VN': { translation: locales.vi_VI },
            'pt-BR': { translation: locales.pt_BR },
            'pt-US': { translation: locales.pt_BR },
            'pt-CA': { translation: locales.pt_BR },
            'de-AY': { translation: locales.de_DE },
            'de-LI': { translation: locales.de_DE },
            'de-LU': { translation: locales.de_DE },
            'de': { translation: locales.de_DE },
            'de-CH': { translation: locales.de_DE },
            'de-DE': { translation: locales.de_DE },
            'de-CA': { translation: locales.de_DE },
            'de-US': { translation: locales.de_DE },
            'ti': { translation: locales.th_TH },
            'th': { translation: locales.th_TH },
            'th-TH': { translation: locales.th_TH },
            'th-CA': { translation: locales.th_TH },
            'th-US': { translation: locales.th_TH },
            'zh-HK': { translation: locales.zh_HK },
            'zh-CN': { translation: locales.zh_CN },
            'zh-SG': { translation: locales.zh_CN },
            'zh-TW': { translation: locales.zh_HK },
            'zh-Hans-US': { translation: locales.zh_CN },
            'zh-Hans-CN': { translation: locales.zh_CN },
            'zh-Hans-CA': { translation: locales.zh_CN },
            'zh-CA': { translation: locales.zh_CN },
            'zh-US': { translation: locales.zh_CN },
            'da': { translation: locales.da_DK },
            'da-DK': { translation: locales.da_DK },
            'da-CA': { translation: locales.da_DK },
            'da-US': { translation: locales.da_DK },
            'sv': { translation: locales.sv_SE },
            'sv-SE': { translation: locales.sv_SE },
            'sv-CA': { translation: locales.sv_SE },
            'sv-US': { translation: locales.sv_SE },
            'nl-CA': { translation: locales.nl_NL },
            'nl-NL': { translation: locales.nl_NL },
            'nl-US': { translation: locales.nl_NL },
            'it-CA': { translation: locales.it_IT },
            'it-IT': { translation: locales.it_IT },
            'it-US': { translation: locales.it_IT },
            'tl-PH': { translation: locales.tl_PH },
            'tl-CA': { translation: locales.tl_PH },
            'tl-US': { translation: locales.tl_PH },
        },
        react: {
            wait: true,
            bindI18n: 'languageChanged loaded',
            bindStore: 'added removed',
            nsMode: 'default'
        }
    });

i18n.acceptLanguage = () => i18n.language.replace('-', '_');

i18n.currentLocale = () => i18n.language;

export default i18n;