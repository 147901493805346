import { createSelector } from 'reselect';
import _ from 'lodash';
import moment from 'moment';

import { NAME } from './constants';
import * as types from './actionTypes';
import { selectors as coreSelectors } from '../core';

const getAll = state => state[NAME].items;

const getAllRegularIds = state => state[NAME].regular;
const getAllPriorityIds = state => state[NAME].priority;
export const isFirstPriorityLoad = state => state[NAME].isFirstPriorityLoad;

export const getAllRegular = createSelector(getAll, getAllRegularIds, (items, ids) => _.filter(_.map(ids, id => items[id]), _.identity) || []);
export const getAllPriority = createSelector(getAll, getAllPriorityIds, (items, ids) => _.filter(_.map(ids, id => items[id]), _.identity) || []);

export const getRegularWithoutPriority = createSelector(
    getAllRegular,
    getAllPriority,
    (allRegular, allPriority) => _.filter(allRegular, it => !_.includes(allPriority, it))
);

export const getUnreadNotificationsLength = createSelector(getAllRegular, items => _.filter(items, it => !it.read).length);

export const getAllReadPriority = createSelector(getAllPriority, items => _.filter(items, item => !!item.read));
export const getAllUnreadPriority = createSelector(getAllPriority, items => sortArrByDate(_.filter(items, item => !item.read)));

const sortArrByDate = arr => _.orderBy(arr, item => item.dateTime && new moment(item.dateTime), ['desc']);

//return all notifications
export const getAllNotifications = createSelector(
    getAllPriority,
    getRegularWithoutPriority,
    (allReadPriority, items) => sortArrByDate([...items, ...allReadPriority])
);

export const getRefreshTime = state => state[NAME].refreshTime;
export const getUnreadCount = state => state[NAME].unread_count.count;
export const getUnseenCount = state => state[NAME].unseen_count?.count;
export const getUnreadPriorityCount = state => state[NAME].unread_count.priorityNotificationCount;

export const isLoadingNotifications = state => coreSelectors.isLoading(state, types.GET_NOTIFICATIONS.NAME);
export const isLoadingMoreNotifications = state => coreSelectors.isLoading(state, types.GET_MORE_NOTIFICATIONS.NAME);
export const isMarkingRead = state => coreSelectors.isLoading(state, types.MARK_NOTIFICATION_READ.NAME);
export const isMarkingAllRead = state => coreSelectors.isLoading(state, types.MARK_ALL_NOTIFICATIONS_READ.NAME);
export const isMarkingAllClicked = state => coreSelectors.isLoading(state, types.MARK_ALL_NOTIFICATIONS_CLICKED.NAME);

export const getPushNotificationsDeviceId = state => state[NAME].pushNotifications.deviceId;
