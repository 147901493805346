import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isIE } from 'react-device-detect';
import { css, StyleSheet } from 'aphrodite-jss';
import { withRouter } from 'react-router-dom';
import { components as Core, ActionSheet, ROUTES } from '../../../../core';
import { baseColors, layoutStyle, spacing, challengeDetailsImageSize, important } from '../../../../../styles';
import WithChallengeDetailsHeaderBase, { styles as baseStyles } from './ChallengeDetailsHeaderBase';

class ChallengeDetailsHeader extends PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
        image: PropTypes.string,
        challengeIcon: PropTypes.string,
        getOptions: PropTypes.func.isRequired,
        getInviteToChallengeProps: PropTypes.func.isRequired,
        getDestructiveButtonIndex: PropTypes.func.isRequired
    };

    static defaultProps = {
        image: null,
        challengeIcon: undefined,
    };

    openOptions = event => ActionSheet.open(this.props.getOptions(this.inviteToChallenge), this.props.getDestructiveButtonIndex(), undefined, event);

    inviteToChallenge = () => {
        const props = this.props.getInviteToChallengeProps();
        this.props.history.push(ROUTES.inviteToChallenge(props.entityId, props.entity));
    };

    get challengeIcon() {
        return this.props.challengeIcon ? (
            <div className={css(styles.cardHeader)}>
                <div className={css(styles.headerButton, styles.healthCategoryIconContainer)}>
                    <Core.Icon
                        type="fa"
                        color={baseColors.white}
                        name={this.props.challengeIcon}
                        fill="solid"
                        size={spacing.s5}
                    />
                </div>
            </div>
        ) : null;
    }

    render() {
        return (
            <div className={css(layoutStyle.flexRow, layoutStyle.pRelative, styles.imageWrapper)}>
                <Core.Image className={css(styles.image, styles.bluredImage)} src={this.props.image} />
                <div className={css(styles.pAbsolute)}>
                    <Core.SmallerContainer>
                        <Core.Image className={css(styles.image)} src={this.props.image} />
                        {this.challengeIcon}
                    </Core.SmallerContainer>
                </div>
            </div>
        );
    }
}

export default withRouter(WithChallengeDetailsHeaderBase(ChallengeDetailsHeader));

const TOP_IMAGE_OFFSET = -64;

const styles = StyleSheet.create({
    ...baseStyles,
    pAbsolute: {
        position: 'absolute',
        width: '100%',
    },
    bluredImage: {
        filter: 'blur(44px)',
        transform: 'scale(1.3)',
        height: challengeDetailsImageSize,
        position: isIE ? 'absolute' : 'relative',
    },
    imageWrapper: {
        overflow: 'hidden',
        top: TOP_IMAGE_OFFSET,
        height: challengeDetailsImageSize,
    },
    image: {
        height: challengeDetailsImageSize,
        margin: important('0 auto'),
        borderRadius: important(0),
    },
});