import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate, selectors as coreSelectors } from '../../../../core';
import { appFonts, baseColors, spacing } from '../../../../../styles';
import * as selectors from '../../../selectors';
import { QUARTER } from '../../../constants';

export default function WithDashboardEarnPointsInfoBase(WrappedComponent) {
    class DashboardEarnPointsInfoBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            isRewardsEnabled: PropTypes.bool.isRequired,
            customPointsName: PropTypes.string.isRequired,
            externalRewardName: PropTypes.string,
            levelsFrequency: PropTypes.string.isRequired,
        };

        static defaultProps = {
            externalRewardName: '',
        };

        get buttonTitle() {
            return this.props.i18n.t('ok');
        }

        get titleFirstLine() {
            const { i18n, customPointsName: customPoints } = this.props;
            return i18n.t('earnPoints', { customPoints });
        }

        get titleSecondLine() {
            const { i18n, externalRewardName, isRewardsEnabled } = this.props;
            return isRewardsEnabled ? i18n.t('getReward', { rewardName: externalRewardName }) : i18n.t('buildHealthyHabits');
        }

        get trackActivityText() {
            const { i18n, customPointsName: customPoints, externalRewardName: rewardName, isRewardsEnabled } = this.props;
            return `${i18n.t('trackYourActivity', { customPoints })} ${isRewardsEnabled 
                ? i18n.t('levelUpToGetRewarded', { rewardName }) : ''}`;
        }

        get howToLevelUpText() {
            const { i18n, isRewardsEnabled, levelsFrequency } = this.props;
            const period = levelsFrequency === QUARTER ? i18n.t('quarter') : i18n.t('period');
            return isRewardsEnabled ? i18n.t('levelUpAndEarn', { period }) : i18n.t('levelUp', { period });
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    titleFirstLine={this.titleFirstLine}
                    titleSecondLine={this.titleSecondLine}
                    buttonTitle={this.buttonTitle}
                    trackActivityText={this.trackActivityText}
                    howToLevelUpText={this.howToLevelUpText}
                />
            );
        }
    }

    function mapStateToProps(state) {
        return {
            levels: selectors.getCompanyLevels(state),
            levelsFrequency: selectors.getLevelsFrequency(state),
            externalRewardName: selectors.getExternalRewardName(state),
            customPointsUnit: coreSelectors.getCustomPointsUnit(state),
            customPointsName: coreSelectors.getCustomPointsName(state),
            isRewardsEnabled: selectors.isRewardsEnabled(state),
        };
    }

    return connect(mapStateToProps)(translate()(DashboardEarnPointsInfoBase));
}

export const ICON_SIZE = 50;

export const styles = {
    title: {
        ...appFonts.lgMedium,
        textAlign: 'center',
        color: baseColors.black,
    },
    titleContainer: {
        marginBottom: spacing.s4,
    },
    levelItem: {
        marginBottom: spacing.s4,
        alignItems: 'center',
    },
    levelIcon: {
        height: ICON_SIZE,
        width: ICON_SIZE,
        marginRight: spacing.s2,
    },
    levelName: {
        ...appFonts.smRegular,
        color: baseColors.grey20,
    },
    levelScore: {
        ...appFonts.smBold,
        color: baseColors.grey20,
    },
    levelRightContainer: {
        marginLeft: spacing.s7,
    },
    rewardContainer: {
        paddingRight: spacing.s1,
        paddingLeft: spacing.s1,
        paddingTop: spacing.s0,
        backgroundColor: baseColors.warnLightest,
        borderRadius: spacing.s0,
    },
    levelItemContainer: {
        alignSelf: 'center',
    },
    levelNameContainer: {
        marginRight: 'auto',
    },
    rewardAmount: {
        ...appFonts.mdBold,
        color: baseColors.warnDarkest,
        marginLeft: spacing.s0,
    },
    rewardName: {
        ...appFonts.xsMedium,
        color: baseColors.warnDarkest,
    },
    textContainer: {
        marginTop: spacing.s0,
        marginBottom: spacing.s0,
    },
    textParagraph: {
        ...appFonts.mdRegular,
        color: baseColors.grey20,
        marginBottom: spacing.s4,
    },
};