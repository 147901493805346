import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { TimePicker as TP } from '@material-ui/pickers';

class TimePicker extends PureComponent {
    render() {
        const { dateFormat } = this.props;
        return (
            <TP
                {...this.props}
                format={dateFormat}
            />
        );
    }
}

TimePicker.propTypes = {
    dateFormat: PropTypes.string
};

TimePicker.defaultProps = {
    dateFormat: null
};

export default TimePicker;