import React from 'react';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { PersistGate } from 'redux-persist/integration/react';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { store, history, persistor } from './store/store';
import i18n from './i18n/i18n';
import Router from './Router';
import { components as Core } from './modules/core';
import { themeStyles } from './styles';
import theme from './styles/theme.web';

const AppInner = ({ appConfig }) => {

    const materialTheme = appConfig ? createTheme({
        ...themeStyles,
        palette: {
            ...themeStyles.palette,
            secondary: { main: appConfig.secondaryColor },
            primary: { main: appConfig.secondaryColor }
        },
        overrides: {
            MuiSelect: {
                root: {
                    color: appConfig.secondaryColor
                },
                icon: {
                    fill: appConfig.secondaryColor,
                },
            },
        },
    }) : theme;

    return (
        <MuiThemeProvider theme={materialTheme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <I18nextProvider i18n={i18n}>
                    <Provider store={store}>
                        <PersistGate loading={null} persistor={persistor}>
                            <Core.SkeletonContent>
                                <Router history={history} />
                            </Core.SkeletonContent>
                        </PersistGate>
                    </Provider>
                </I18nextProvider>
            </MuiPickersUtilsProvider>
        </MuiThemeProvider>
    );

};

export default AppInner;
