import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { appFonts, baseColors, detailsImageSize, spacing } from '../../../../styles';
import translate from '../../services/translate';

export default function WithImageUploaderBase(WrappedComponent) {
    class ImageUploaderBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            fileChanged: PropTypes.func.isRequired,
            picture: PropTypes.string,
            label: PropTypes.string,
            entityType: PropTypes.string.isRequired,
            suggestedImages: PropTypes.array,
            isEditing: PropTypes.bool,
            isAdminCreated: PropTypes.bool
        };

        static defaultProps = {
            picture: undefined,
            label: undefined,
            suggestedImages: undefined,
            isEditing: false,
            isAdminCreated: false
        };

        get text() {
            return this.props.i18n.t('core.uploadPhotoText', { entityType: this.props.i18n.t(`core.entities.${this.props.entityType}`) });
        }

        get hasSuggested() {
            return !!this.props.suggestedImages && !!this.props.suggestedImages.length;
        }

        get buttonLabel() {
            return this.props.label || this.props.picture
                ? this.props.i18n.t('changeCover')
                : this.props.i18n.t('uploadCover');
        }

        get isHiddenImagePickerButton() {
            const { isAdminCreated, isEditing } = this.props;
            return isAdminCreated && isEditing;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    buttonLabel={this.buttonLabel}
                    hasSuggested={this.hasSuggested}
                    text={this.text}
                    isHiddenImagePickerButton={this.isHiddenImagePickerButton}
                />
            );
        }
    }

    return translate()(ImageUploaderBase);
}

export const styles = {
    imageWrapper: {
        position: 'relative',
        backgroundColor: baseColors.grey85,
        height: detailsImageSize
    },
    image: {
        height: '100%'
    },
    imageCover: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    text: {
        textAlign: 'center',
        padding: spacing.s3,
        ...appFonts.mdRegular
    },
    suggestedTitle: {
        padding: spacing.s3,
        ...appFonts.mdBold
    },
};