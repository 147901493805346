import spacing from '../spacing';
import baseColors from '../commonColor';
import { commonPadding, footerHeight, largeFooterHeight, headerHeight } from '../constants';
import appFonts from '../appFonts';

const layoutStyleBase = {
    page: {
        display: 'flex',
        minHeight: '100vh',
        flexDirection: 'column'
    },
    content: {
        flex: 1
    },
    fh: {
        height: '100%'
    },
    fw: {
        width: '100%'
    },
    halfWidth: {
        width: '50%'
    },
    fhFlex: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        justifyContent: 'center'
    },
    containerWithFixedFooter: {
        paddingBottom: spacing.s3
    },
    flex: {
        display: 'flex',
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
    },
    flexColumnReverse: {
        display: 'flex',
        flexDirection: 'column-reverse',
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    flexColumnCenter: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    flexRowCenter: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    flexSpaceBetween: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    flexSpaceAround: {
        display: 'flex',
        justifyContent: 'space-around'
    },
    flexJustifyCenter: {
        display: 'flex',
        justifyContent: 'center'
    },
    flexJustifyEnd: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    flexAlignCenter: {
        display: 'flex',
        alignItems: 'center'
    },
    flexAlignEnd: {
        alignItems: 'flex-end'
    },
    flexAlignStart: {
        alignItems: 'flex-start'
    },
    flexWrap: {
        flexWrap: 'wrap'
    },
    flexJustifyAlignStart: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start'
    },
    alignSelfStretch: {
        alignSelf: 'stretch',
    },
    containerOffset: {
        marginBottom: spacing.s7
    },
    textLeft: {
        textAlign: 'left'
    },
    textCenter: {
        textAlign: 'center'
    },
    textRight: {
        textAlign: 'right'
    },
    noPadding: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: 0,
        paddingLeft: 0
    },
    noMargin: {
        marginLeft: 0,
        marginRight: 0,
        marginTop: 0,
        marginBottom: 0
    },
    primary: {
        color: baseColors.primary
    },
    secondary: {
        color: baseColors.secondary
    },
    success: {
        color: baseColors.success
    },
    danger: {
        color: baseColors.danger
    },
    colorSecondary: {
        color: baseColors.secondary
    },
    colorBlack: {
        color: baseColors.black
    },
    bgWhite: {
        backgroundColor: baseColors.white
    },
    bgTransparent: {
        backgroundColor: 'transparent'
    },
    bgGrey90: {
        backgroundColor: baseColors.grey90
    },
    bgSecondary: {
        backgroundColor: baseColors.secondary
    },
    bgDangerDarker: {
        backgroundColor: baseColors.dangerDarker
    },
    bgPrimary: {
        backgroundColor: baseColors.primary
    },
    bgBonus: {
        backgroundColor: baseColors.colourBonus
    },
    flex1: {
        flex: 1
    },
    flex0: {
        flex: 0
    },
    hidden: {
        display: 'none'
    },
    opacityFull: {
        opacity: 1
    },
    opacityHalf: {
        opacity: 0.5
    },
    opacityNone: {
        opacity: 0
    },
    buttonTextSmall: {
        paddingLeft: commonPadding,
        paddingRight: commonPadding,
        minWidth: 0
    },
    listBorder: {
        borderTopWidth: 1,
        borderTopColor: baseColors.grey80,
        paddingTop: 0,
        marginTop: spacing.s1
    },
    list: {
        marginTop: 0,
        marginLeft: commonPadding,
        marginRight: commonPadding,
        borderTopWidth: 0
    },
    listWithBorder: {
        marginTop: 0,
        marginLeft: commonPadding,
        marginRight: commonPadding,
    },
    listNoBorder: {
        borderTopWidth: 0
    },
    listItem: {
        paddingTop: spacing.s1,
        paddingBottom: spacing.s1,
        paddingLeft: spacing.s1,
        paddingRight: spacing.s1,
        borderBottomWidth: 1,
        borderBottomColor: baseColors.grey70,
    },
    listItemBig: {
        paddingTop: spacing.s3,
        paddingBottom: spacing.s3,
        paddingLeft: spacing.s1,
        paddingRight: spacing.s1,
        borderBottomWidth: 1,
        borderBottomColor: baseColors.grey70,
    },
    listItemNoHPadding: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    listItemNoVPadding: {
        paddingTop: 0,
        paddingBottom: 0
    },
    listItemNoLeftPadding: {
        paddingLeft: 0
    },
    imageOverlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.25)'
    },
    pRelative: {
        position: 'relative'
    },
    commonPadding: {
        padding: commonPadding
    },
    commonPaddingHorizontal: {
        paddingLeft: commonPadding,
        paddingRight: commonPadding,
    },
    commonPaddingVertical: {
        paddingTop: commonPadding,
        paddingBottom: commonPadding,
    },
    commonPaddingBottom: {
        paddingBottom: commonPadding,
    },
    commonPaddingTop: {
        paddingTop: commonPadding,
    },
    cardPadding: {
        paddingLeft: commonPadding,
        paddingRight: commonPadding,
    },
    cardPaddingRight: {
        paddingRight: commonPadding,
    },
    cardPaddingLeft: {
        paddingLeft: commonPadding,
    },
    cardMinusPadding: {
        marginLeft: -commonPadding
    },
    cardPaddingHalf: {
        paddingLeft: commonPadding / 2,
        paddingRight: commonPadding / 2,
    },
    commonMarginHalfLeft: {
        marginLeft: commonPadding / 2,
    },
    commonMarginHalfRight: {
        marginRight: commonPadding / 2,
    },
    cardFooterButton: {
        flex: 1,
        marginLeft: commonPadding / 2,
        marginRight: commonPadding / 2
    },
    twoButtonsLeft: {
        flex: 1,
        marginRight: spacing.s2
    },
    twoButtonsRight: {
        flex: 1,
    },
    twoButtonsTop: {
        marginTop: spacing.s1
    },
    threeButtonsLeftMiddle: {
        flexGrow: 0,
        marginRight: spacing.s2
    },
    threeButtonsRight: {
        flex: 1,
    },
    topButtonOffsetSmall: {
        marginBottom: spacing.s1
    },
    smallGreyTitle: {
        ...appFonts.xsBold,
        paddingLeft: commonPadding,
        paddingTop: spacing.s0,
        paddingBottom: spacing.s0,
        backgroundColor: baseColors.grey85
    },
    fixedHeader: {
        marginLeft: commonPadding,
        marginRight: commonPadding,
    },
    entityListHeaderTitle: {
        ...appFonts.xlBold,
        margin: commonPadding,
    },
    entityItemMainContainer: {
        flex: 1,
        paddingTop: spacing.s3,
        paddingBottom: spacing.s3,
        marginLeft: spacing.s1,
        marginRight: spacing.s1,
        borderColor: baseColors.grey80,
        borderBottomWidth: 1
    },
    carouselInfoContainer: {
        marginTop: spacing.s2,
        marginLeft: spacing.s1,
        marginRight: spacing.s1,
    },
    createEntityButton: {
        borderColor: 'transparent',
        minWidth: 0
    },
    fixedFooterModalContainer: {
        flex: 0,
        display: 'flex',
        justifyContent: 'center',
        paddingTop: spacing.s3,
        paddingBottom: spacing.s3,
        flexDirection: 'column',
        backgroundColor: baseColors.white,
        borderTopWidth: 1,
        borderColor: baseColors.grey70,
    },
    dangerButton: {
        backgroundColor: baseColors.dangerDarker,
        borderColor: baseColors.dangerDarker,
        color: baseColors.white
    },
    heightAuto: {
        height: 'auto'
    },
    card: {
        minHeight: `calc(100vh - ${headerHeight}px)`
    },
    footerPadding1: {
        padding: spacing.s1,
    },
    smallerFooterOffset: {
        marginBottom: footerHeight - spacing.s1,
    },
    footerOffset: {
        marginBottom: footerHeight,
    },
    largeFooterOffset: {
        marginBottom: largeFooterHeight
    },
    justifyStart: {
        justifyContent: 'flex-start'
    },
    separator: {
        height: 1,
        backgroundColor: '#EEEEEE',
    }
};

export default layoutStyleBase;
