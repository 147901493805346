import _ from 'lodash';

export function getProductIdsByCategories(products, prevCategories, isReplace) {
    const categories = isReplace ? {} : prevCategories;
    _.forEach(products, item => {
        if (_.has(categories, [item.category])) {
            categories[item.category] = [...categories[item.category], item.id];
        } else {
            categories[item.category] = [item.id];
        }
    });
    return categories;
}

export function getEarnPartnerIdsByCategories(partners) {
    const categories = {};
    _.forEach(partners, item => {
        if (_.has(categories, [item.categoryId])) {
            categories[item.categoryId] = [...categories[item.categoryId], item.earnPartnerId];
        } else {
            categories[item.categoryId] = [item.earnPartnerId];
        }
    });
    return categories;
}
