import _ from 'lodash';

export const onActionComplete = (props, prevProps, successCallback, errorCallback, loadingProp = 'isLoading', errorProp = 'error') => {
    if (!props[loadingProp] && prevProps[loadingProp]) {
        if (props[errorProp]) {
            errorCallback && errorCallback();
            return;
        }
        successCallback();
    }
};

export const goBack = (props, backupLocation) => {
    if (props.close) {
        props.close();
    } else {
        const location = _.get(props, 'lastLocation.pathname') || backupLocation;
        location && props.history && props.history.push(location);
    }
};
