import moment from 'moment';
import _ from 'lodash';
import { DATE_FORMATS } from '../../core';
import { FREQUENCY, UNIT_TYPES, DAYS } from '../constants';
import * as units from './units';

export function startDateForPeriod(period, challenge) {
    const start = moment(challenge.startDate);
    const multiplier = _.get(units.frequencyMap()[challenge.frequency], 'multiplier', 1);
    return start.add((_.toInteger(_.get(period, 'index')) * multiplier), DAYS);
}

export function dateLabelForPeriod(period, challenge, isRestartGoal = false) {
    if(!challenge) return null;
    if (isRestartGoal) {
        const { startDate, endDate } = period;
        const getDayMonth = date => ({
            day: moment(date).format(DATE_FORMATS.dayShort),
            month: moment(date).format(DATE_FORMATS.monthFull)
        });
        const periodStart = getDayMonth(startDate);
        const periodEnd = getDayMonth(endDate);
        const isSameMonth = periodStart.month === periodEnd.month;
        return `${periodStart.month} ${periodStart.day} - ${isSameMonth ? '' : `${periodEnd.month} `}${periodEnd.day}`;
    }
    const start = moment(challenge.startDate);
    const end = moment(challenge.challengeDeadline);

    if (challenge.frequency === FREQUENCY.total) {
        const startOfChallenge = `${start.format(DATE_FORMATS.monthFullDayShort)}`;
        let endOfChallenge = `${end.format(DATE_FORMATS.monthFullDayShort)}`;
        const startOfChallengeMonth = `${start.format(DATE_FORMATS.month)}`;
        const endOfChallengeMonth = `${end.format(DATE_FORMATS.month)}`;

        if (startOfChallenge === endOfChallenge) {
            return `${startOfChallenge}`;
        } else if (startOfChallengeMonth === endOfChallengeMonth) {
            endOfChallenge = ` - ${end.format(DATE_FORMATS.day)}`;
        } else {
            endOfChallenge = ` - ${end.format(DATE_FORMATS.monthFullDayShort)}`;
        }
        return `${startOfChallenge}${endOfChallenge}`;
    }

    let endOfPeriod = '';
    const multiplier = _.get(units.frequencyMap()[challenge.frequency], 'multiplier', 1);

    const startOfPeriod = `${start.add((_.toInteger(period.index) * multiplier), DAYS).format(DATE_FORMATS.monthFullDayShort)}`;
    if (multiplier === 1) {
        return `${startOfPeriod}`;
    }
    const startOfPeriodMonth = moment(challenge.startDate).add((_.toInteger(period.index) * multiplier), DAYS).format(DATE_FORMATS.month);
    const endOfPeriodMonthDate = moment(challenge.startDate).add(((_.toInteger(period.index) + 1) * multiplier) -1, DAYS);
    const endOfPeriodMonth = endOfPeriodMonthDate.format(DATE_FORMATS.month);
    if (startOfPeriodMonth === endOfPeriodMonth) {
        endOfPeriod = ` - ${endOfPeriodMonthDate.format(DATE_FORMATS.day)}`;
    } else {
        endOfPeriod = ` - ${endOfPeriodMonthDate.format(DATE_FORMATS.monthFullDayShort)}`;
    }
    return `${startOfPeriod}${endOfPeriod}`;
}

export function pointsForPeriod(period, challenge) {
    const isRestartGoal = _.get(period, 'restartGoalPeriodIndex');
    if (isRestartGoal) return Math.ceil(_.get(period, 'totalScore'));
    if (_.has(challenge, ['activityUnitDetails', '0', 'points'])) {
        return _.ceil(_.sumBy(period.activities, activity => {
            const activityDetails = _.find(challenge.activityUnitDetails, { unit_id: activity.unit_id });
            return _.toNumber(_.get(activityDetails, 'points', 0)) * _.toNumber(_.get(activity, 'value', 0));
        }));
    }
    if (_.get(challenge, 'progress.currentPeriodPoints')) {
        return challenge.progress.currentPeriodPoints;
    }
    return _.ceil(_.toInteger(period.goal) * period.value / 100);
}

export function pointsForPeriodPercentage(period) {
    return _.round(_.toInteger(period.goal) * period.value / 100);
}

export function relevantActivitiesForPeriod(period, challenge, activityLogs, restartGoal) {
    if(!challenge) return [];
    const multiplier = restartGoal ? 7 : _.get(units.frequencyMap()[_.get(challenge, 'frequency')], 'multiplier', 1);

    let startOfPeriod;
    let endOfPeriod;

    if (challenge.frequency === FREQUENCY.total) {
        startOfPeriod = moment(challenge.startDate).format(DATE_FORMATS.full);
        endOfPeriod = moment(challenge.challengeDeadline).format(DATE_FORMATS.full);
    } else {
        const challengeData = restartGoal ? period : challenge;
        startOfPeriod = moment(challengeData.startDate)
            .add((_.toInteger(period.index) * multiplier), DAYS)
            .format(DATE_FORMATS.full);
        endOfPeriod = moment(challengeData.endDate || challengeData.challengeDeadline)
            .add((period.index ? (_.toInteger(period.index) + 1) * multiplier : 1) -1, DAYS)
            .format(DATE_FORMATS.full);
    }

    let filteredActivityLogs = [];

    const data = restartGoal || challenge;
    _.forEach(data.activityUnitDetails, activityUnitDetails => {
        if (activityUnitDetails.unit_type === UNIT_TYPES.category) {
            filteredActivityLogs = _.concat(filteredActivityLogs, (activityLogs.filter(l => String(l.categoryId) === String(activityUnitDetails.unit_id) && startOfPeriod <= l.date && endOfPeriod >= l.date)));
        } else if (activityUnitDetails.unit_type === UNIT_TYPES.unit) {
            filteredActivityLogs = _.concat(filteredActivityLogs, (activityLogs.filter(l => String(_.get(l, 'activityUnitId')) === String(activityUnitDetails.unit_id) && startOfPeriod <= l.date && endOfPeriod >= l.date)));
        }
    });
    return filteredActivityLogs;
}

export function arrayOfDatesInPeriod(period, challenge) {
    if(!challenge) return [];
    const isRestartGoal = !!period.restartGoalPeriodIndex;
    const restartGoalMultiplier = moment(period.endDate).diff(moment(period.startDate), 'days') + 1;
    const multiplier = isRestartGoal ? restartGoalMultiplier : _.get(units.frequencyMap()[challenge.frequency], 'multiplier', 1);

    const arrayOfDates = [];

    if (challenge.frequency === FREQUENCY.total) {
        const startDate = moment(challenge.startDate);
        const endDate = moment(challenge.challengeDeadline);
        //will render dates up to the end of challenge date (if it is over), or today
        const lastDateToRender = endDate.isAfter(moment()) ? moment() : endDate;
        const length = lastDateToRender.diff(startDate, DAYS);
        for (let i = 0; i <= length; i++) {
            arrayOfDates.push(moment(challenge.startDate).add(i, DAYS).format(DATE_FORMATS.full));
        }
    } else {
        const challengeData = isRestartGoal ? period : challenge;
        for (let i = 0; i < multiplier; i++) {
            arrayOfDates.push(
                moment(challengeData.startDate)
                    .add((_.toInteger(period.index) * multiplier), DAYS)
                    .add(i, DAYS)
                    .format(DATE_FORMATS.full)
            );
        }
    }
    return arrayOfDates;
}

export function trackedChallengeActivities(activitiesForDate, userActivityLogsIds) {
    if (activitiesForDate) {
        const activities = activitiesForDate;
        activities[0].logs = _.filter(activitiesForDate[0].logs, activity => _.includes(userActivityLogsIds, activity.activityLogId));
        return activities[0].logs.length ? activities : undefined;
    }

    return undefined;
}
