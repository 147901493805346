import React from 'react';
import PropTypes from 'prop-types';

const SafeA = ({ children, ...props }) => (
    <a target="_blank" rel="noopener noreferrer" {...props}>{children}</a>
);

SafeA.propTypes = {
    children: PropTypes.node.isRequired
};

export default SafeA;