import React from 'react';
import { appFonts, baseColors, spacing } from '../../../../styles';
import { translate } from '../../../core';

export default function WithOrderHeaderBase(WrappedComponent) {
    const OrderHeaderBase = ({ i18n, product, externalRewardName, ...props }) => {
        const infoTextObject = {
            confirmYourOrder: i18n.t('confirmYourOrder'),
            item: i18n.t('item'),
            productName: product.productName,
            redeemFor: i18n.t('redeemFor'),
            reward: `${product.pointsRequired} ${externalRewardName}`
        };

        return <WrappedComponent {...props} infoTextObject={infoTextObject} />;
    };
    return translate()(OrderHeaderBase);
}

export const styles = {
    mainContainer: {
        paddingTop: spacing.s3,
        paddingBottom: spacing.s3
    },
    headerTitle: {
        ...appFonts.lgMedium,
        textAlign: 'center',
        marginBottom: spacing.s3
    },
    title: {
        color: baseColors.grey40,
        ...appFonts.smRegular,
        marginBottom: spacing.s0
    },
    mainInfo: {
        ...appFonts.mdMedium
    },
    topContent: {
        marginBottom: spacing.s5
    }
};