import _ from 'lodash';
import * as types from './actionTypes';
import * as api from './api';
import { constants as coreConstants, normalize, getRequestId } from '../core';
import i18n from '../../i18n/i18n';
import { ENTITIES } from './constants';
import { validateError } from '../../config';

export const getUsersToInvite = (params = {}, actionType = 'gettingUsers') => dispatch => {
    dispatch({
        type: types.GET_USERS_TO_INVITATION.REQUEST,
        payload: { requestId: `${types.GET_USERS_TO_INVITATION.NAME}/${actionType}` }
    });
    api.getUsersToInvite(params)
        .then(res => {
            dispatch({
                type: types.GET_USERS_TO_INVITATION.SUCCESS,
                payload: {
                    users: normalize.normalizeArray(res.data.view.users),
                    count: res.data.total,
                    isReplace: !params.offset,
                    requestId: `${types.GET_USERS_TO_INVITATION.NAME}/${actionType}`
                }
            });
        })
        .catch(error => validateError(error, error => {
            dispatch({
                type: types.GET_USERS_TO_INVITATION.ERROR,
                payload: {
                    error,
                    requestId: `${types.GET_USERS_TO_INVITATION.NAME}/${actionType}`
                }
            });
        }));
};

export const inviteToEntity = (entity, entityId, userIds, emails) => (dispatch) => {
    const actionType = 'invitingUsers';
    dispatch({ type: types.INVITE_TO_ENTITY.REQUEST });
    const params = entity === ENTITIES.EVENT ? { inviteList: userIds, message: '', inviteEmailList: emails }
        : { inviteUserIds: userIds, inviteUserEmails: emails };
    return api.inviteToEntity(entity, entityId, params)
        .then(response => {
            dispatch({
                type: types.INVITE_TO_ENTITY.SUCCESS,
                payload: {
                    toast: { actionType }
                }
            });
        })
        .catch(error => validateError(error, error => {
            dispatch({
                type: types.INVITE_TO_ENTITY.ERROR,
                payload: {
                    toast: { actionType },
                    error
                }
            });
        }));
};

export const getUsersToPlatformInvitation = (params = {}, isLoadingMore = false) => dispatch => {
    const GET_USERS_TO_PLATFORM_INVITATION = isLoadingMore
        ? types.GET_USERS_TO_PLATFORM_INVITATION_MORE : types.GET_USERS_TO_PLATFORM_INVITATION;
    dispatch({ type: GET_USERS_TO_PLATFORM_INVITATION.REQUEST });
    return api.getUsersToPlatformInvitation(params)
        .then(res => {
            dispatch({
                type: GET_USERS_TO_PLATFORM_INVITATION.SUCCESS,
                payload: {
                    ...normalize.normalizeArray(_.get(res.data, 'data', [])),
                    isReplace: !params.offset,
                    count: _.get(res.data, 'total', 0)
                }
            });
        })
        .catch(error => validateError(error, error => {
            dispatch({
                type: GET_USERS_TO_PLATFORM_INVITATION.ERROR,
                payload: { error }
            });
        }));
};

export const clearUsersToPlatformInvitation = () => ({
    type: types.GET_USERS_TO_PLATFORM_INVITATION.SUCCESS,
    payload: { items: {}, ids: [], isReplace: true, count: 0 }
});

export const getPlatformInvitations = () => dispatch => {
    dispatch({ type: types.GET_PLATFORM_INVITATIONS.REQUEST });
    return api.getPlatformInvitations()
        .then(response => {
            dispatch({
                type: types.GET_PLATFORM_INVITATIONS.SUCCESS,
                payload: {
                    ...normalize.normalizeArray(_.get(response.data, 'data', [])),
                    count: _.get(response.data, 'total', 0)
                }
            });
        })
        .catch(error => validateError(error, error => {
            dispatch({
                type: types.GET_PLATFORM_INVITATIONS.ERROR,
                payload: { error }
            });
        }));
};

export const sendPlatformInvitations = (email, userId, isToast = false) => dispatch => {
    const actionType = 'sendPlatformInvitations';
    dispatch({
        type: types.SEND_PLATFORM_INVITATIONS.REQUEST,
        payload: { requestId: getRequestId(types.SEND_PLATFORM_INVITATIONS.NAME, userId) }
    });
    return api.sendPlatformInvitations(email, userId)
        .then(res => {
            dispatch({
                type: types.SEND_PLATFORM_INVITATIONS.SUCCESS,
                payload: {
                    requestId: getRequestId(types.SEND_PLATFORM_INVITATIONS.NAME, userId),
                    item: res.data,
                    userId,
                    toast: isToast && email ? {
                        actionType,
                        type: coreConstants.TOAST_TYPES.SUCCESS,
                        title: i18n.t('invitation.toast.success_title'),
                        msg: i18n.t('invitation.toast.success_description', { invite_email: email })
                    } : undefined
                }
            });
        })
        .catch(error => validateError(error, error => {
            dispatch({
                type: types.SEND_PLATFORM_INVITATIONS.ERROR,
                payload: {
                    requestId: getRequestId(types.SEND_PLATFORM_INVITATIONS.NAME, userId),
                    error,
                    toast: {
                        actionType,
                        type: coreConstants.TOAST_TYPES.DANGER,
                        title: i18n.t('invitation.toast.danger_title'),
                        msg: i18n.t('invitation.toast.danger_description')
                    }
                }
            });
        }));
};


export const deletePlatformInvitation = (invitationId, userFirstName, userId, title, msg) => dispatch => {
    const requestId = getRequestId(types.DELETE_PLATFORM_INVITATION.NAME, invitationId);
    dispatch({ type: types.DELETE_PLATFORM_INVITATION.REQUEST, payload: { requestId } });
    return api.deletePlatformInvitation(invitationId)
        .then(res => {
            dispatch({
                type: types.DELETE_PLATFORM_INVITATION.SUCCESS,
                payload: {
                    requestId,
                    invitationId,
                    userId,
                    toast: {
                        msg: msg ? msg : i18n.t('deletePlatformInvitationToast.success.message', { userFirstName }),
                        title: title ? title : null
                    }
                }
            });
        })
        .catch(error => validateError(error, error => {
            dispatch({ type: types.DELETE_PLATFORM_INVITATION.ERROR, payload: { requestId, error } });
        }));
};

export const clearUserByEmail = () => ({ type: types.CLEAR_USER_BY_EMAIL });

export const getUserByEmail = email => dispatch => {
    dispatch({ type: types.GET_USER_BY_EMAIL.REQUEST });
    return api.getUserByEmail(email)
        .then(res => {
            const user = _.get(res.data, 'data.0');
            dispatch({ type: types.GET_USER_BY_EMAIL.SUCCESS, payload: { user } });
            if (!user) dispatch(clearUserByEmail());
        })
        .catch(error => validateError(error, error => {
            dispatch({ type: types.GET_USER_BY_EMAIL.ERROR, payload: { error } });
        }));
};

export function showLearnFriendsAndFamilyModal() {
    return function (dispatch) {
        dispatch({ type: types.SHOW_LEARN_FRIENDS_AND_FAMILY_MODAL });
    };
}
