import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getFirstLoadingWrapper, translate } from '../../../core';
import {
    getScreensArrayForTasksList,
    isLoadingTasks,
    areTasksShown,
    getUnfinishedTasks,
    getPercentageCompleted,
    isShowingOnboarding,
    isHealthSurveyEnabled
} from '../../selectors';
import { getUserTasks, toggleSOTs } from '../../actions';
import { spacing, appFonts } from '../../../../styles';
import { FLOW_STEPS } from '../../constants';

export const PROGRESS_SIZE = 38;
export const LINE_WIDTH = 2;

export default function WithTasksBase(WrappedComponent) {
    class TasksBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            challenges: PropTypes.array,
            goalsCompletedNotDismissed: PropTypes.array,
            onlyOnboardingScreen: PropTypes.bool,
            isLoading: PropTypes.bool,
            slides: PropTypes.array,
            unfinishedTasks: PropTypes.array,
            i18n: PropTypes.object.isRequired,
            isShowingOnboarding: PropTypes.bool
        };

        static defaultProps = {
            challenges: [],
            goalsCompletedNotDismissed: [],
            onlyOnboardingScreen: false,
            isLoading: false,
            slides: [],
            unfinishedTasks: [],
            isShowingOnboarding: false
        };

        constructor(props) {
            super(props);
            this.state = {
                //eslint-disable-next-line
                isLoading: props.isLoading, //used in getFirstLoadingWrapper
                isFirstLoaded: false,
            };
        }

        static getDerivedStateFromProps(nextProps, prevState) {
            return getFirstLoadingWrapper(nextProps, prevState);
        }

        componentDidMount() {
            if (!this.props.isShowingOnboarding) {
                this.props.actions.getUserTasks();
            }
        }

        componentDidUpdate(prevProps) {
            if (prevProps.unfinishedTasks.length !== this.props.unfinishedTasks.length) {
                this.props.actions.getUserTasks();
            }
        }

        get isCardHidden() {
            return !this.props.unfinishedTasks.length;
        }

        get hideButtonTitle() {
            return this.props.i18n.t('hide');
        }

        get showButtonTitle() {
            return this.props.i18n.t('show');
        }
        get title() {
            return this.props.i18n.t('complete_my_profile');
        }

        get slidesWithoutWellbeing() {
            const { slides } = this.props;
            return slides.filter(slide => slide.group_slug !== FLOW_STEPS.healthSurvey);
        }

        get tasksWithoutWellbeing() {
            const { unfinishedTasks } = this.props;
            return unfinishedTasks.filter(task => task.group_slug !== FLOW_STEPS.healthSurvey);
        }
        render() {
            if (this.isCardHidden) return null;
            const { isFirstLoaded } = this.state;
            return (
                <WrappedComponent
                    {...this.props}
                    isFirstLoaded={isFirstLoaded}
                    hideButtonTitle={this.hideButtonTitle}
                    showButtonTitle={this.showButtonTitle}
                    title={this.title}
                    slidesWithoutWellbeing={this.slidesWithoutWellbeing}
                    tasksWithoutWellbeing={this.tasksWithoutWellbeing}
                />
            );
        }
    }

    const mapStateToProps = state => ({
        slides: getScreensArrayForTasksList(state),
        isLoading: isLoadingTasks(state),
        isCollapsed: !areTasksShown(state),
        unfinishedTasks: getUnfinishedTasks(state),
        percentageCompleted: getPercentageCompleted(state),
        isShowingOnboarding: isShowingOnboarding(state),
        isHealthSurveyEnabled: isHealthSurveyEnabled(state)
    });

    const mapDispatchToProps = dispatch => ({
        actions: bindActionCreators({ getUserTasks, toggleSOTs }, dispatch)
    });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(TasksBase));
}

export const styles = {
    container: {
        flex: 1,
    },
    skeletonContainer: {
        margin: spacing.s2,
        marginTop: 0
    },
    cardHeader: {
        paddingTop: spacing.s2,
        paddingBottom: spacing.s2,
    },
    cardHeaderText: {
        ...appFonts.lgMedium
    },
    circleContainer: {
        paddingRight: spacing.s2
    },
};
