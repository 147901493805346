import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as actions from '../../actions';
import {
    isLoadingPlatformInvitations,
    getPlatformInvitationsWithEmail,
    isSendingPlatformInvitation,
    getUserByEmail,
    isGettingUserByEmail
} from '../../selectors';
import { translate, validation, SEARCH_INPUT_DELAY, Alert } from '../../../core';
import { baseColors, spacing, appFonts } from '../../../../styles';
import { USER_CHECK_ICON, USER_CLOCK_ICON } from '../../constants';

export const INVITE_BUTTON_ID = 'inviteFromEntity';
export const BACK_BUTTON_ID = 'backButton';

export default function WithInviteByEmailBase(WrappedComponent) {
    class InviteByEmailBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            emailsArray: PropTypes.array,
            platformInvitations: PropTypes.array,
            isLoading: PropTypes.bool,
            isSending: PropTypes.bool,
            userByEmail: PropTypes.object
        };

        static defaultProps = {
            platformInvitations: [],
            emailsArray: [],
            isLoading: false,
            isSending: false,
            userByEmail: null
        };

        constructor(props) {
            super(props);
            this.state = { email: '', emailsList: props.emailsArray };
            this.getUserByEmail = _.debounce(
                this.getUserByEmail,
                SEARCH_INPUT_DELAY
            );
            this.props.actions.getPlatformInvitations();
        }

        componentDidMount() {
            this.props.actions.clearUserByEmail();
        }

        get isEnabled() {
            return validation.isEmail(this.state.email);
        }

        onChangeEmail = email => {
            this.setState({ email }, () => this.getUserByEmail());
        };

        onInvite = () => {
            this.props.actions.sendPlatformInvitations(this.state.email, 0, true);
        };

        getIconName = item =>
            (item.invitee_user_id ? USER_CHECK_ICON : USER_CLOCK_ICON);

        getUserByEmail = () => {
            if (this.isEnabled) {
                this.props.actions.getUserByEmail(this.state.email);
            } else if (this.props.userByEmail) {
                this.props.actions.clearUserByEmail();
            }
        };

        addEmailToList = () => {
            const inviteeEmail = this.state.email;
            if (_.find(this.state.emailsList, ['inviteeEmail', inviteeEmail])) {
                this.alreadyAddedEmailAlert();
            } else {
                this.setState(prevState => ({
                    emailsList: [...prevState.emailsList, { inviteeEmail }],
                    email: ''
                }));
            }
        };

        alreadyAddedEmailAlert = () => {
            Alert.alert(
                undefined,
                this.props.i18n.t('invitation.alreadyAddedEmail')
            );
        };

        removeItem = email => {
            const arr = _.filter(
                this.state.emailsList,
                item => item.inviteeEmail !== email.inviteeEmail
            );
            this.setState({ emailsList: arr });
        };

        customNavBarButtonsRight = () => ({
            title: this.props.i18n.t('done'),
            id: INVITE_BUTTON_ID
        });

        customNavBarButtonsLeft = () => ({
            title: this.props.i18n.t('button_cancel'),
            id: BACK_BUTTON_ID
        });

        showAlert = onPress => {
            const { emailsList } = this.state;
            const { emailsArray, i18n } = this.props;

            if (emailsList.length && !_.isEqual(emailsList, emailsArray)) {
                Alert.alert(i18n.t('discardAlert.inviteByEmail'), undefined, [
                    {
                        text: i18n.t('discardAlert.confirm'),
                        onPress,
                        isDangerButton: true
                    },
                    { text: i18n.t('discardAlert.cancel'), style: 'cancel' },
                ]);
            } else onPress();
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    email={this.state.email}
                    onChangeEmail={this.onChangeEmail}
                    onInvite={this.onInvite}
                    isEnabled={this.isEnabled}
                    getIconName={this.getIconName}
                    getUserByEmail={this.getUserByEmail}
                    emailsList={this.state.emailsList}
                    showAlert={this.showAlert}
                    addEmailToList={this.addEmailToList}
                    removeItem={this.removeItem}
                    customNavBarButtonsRight={this.customNavBarButtonsRight}
                    customNavBarButtonsLeft={this.customNavBarButtonsLeft}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const routeParams = _.get(ownProps, 'route.params');
        return {
            platformInvitations: getPlatformInvitationsWithEmail(state),
            isLoading: isLoadingPlatformInvitations(state),
            isSending: isSendingPlatformInvitation(state, 0),
            userByEmail: getUserByEmail(state),
            isChecking: isGettingUserByEmail(state),
            ...(routeParams || {})
        };
    };

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(InviteByEmailBase));
}

export const styles = {
    mainContainer: {
        flex: 1
    },
    headerContainer: {
        paddingBottom: spacing.s1,
        borderBottomColor: baseColors.grey85,
        borderBottomWidth: spacing.s0
    },
    marginHorizontal: {
        marginLeft: spacing.s3,
        marginRight: spacing.s3
    },
    emailButton: {
        height: spacing.s9,
        marginBottom: spacing.s3
    },
    contentContainer: {
        marginLeft: spacing.s3,
        marginBottom: spacing.s3
    },
    contentContainerTitle: {
        ...appFonts.lgMedium,
        marginTop: spacing.s5,
        marginBottom: spacing.s0
    },
    innerContainer: {
        marginRight: spacing.s3,
        marginTop: spacing.s9,
        marginBottom: spacing.s5
    },
    emptyMessage: {
        ...appFonts.mdRegular,
        textAlign: 'center'
    },
    itemContainer: {
        flex: 1,
        paddingTop: spacing.s3,
        paddingRight: spacing.s3,
        paddingBottom: spacing.s3,
        borderBottomWidth: 1,
        borderBottomColor: baseColors.grey80,
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    itemText: {
        ...appFonts.mdRegular
    },
    foundEmailTitle: {
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3
    }
};
