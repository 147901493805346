import { css, StyleSheet } from 'aphrodite-jss';
import { AsyncComponent, Modal } from '../../core';
import { components as Onboarding } from '../../onboarding';

export function openHealthSurveyInfo() {
    const closeHealthSurveyInfo = Modal.open(
        AsyncComponent(() => import('../components/Wellbeing/WellbeingInfo')),
        {
            closeModal: () => closeHealthSurveyInfo()
        },
        {
            scroll: 'body',
            PaperProps: {
                classes: {
                    root: css(styles.root)
                },
                elevation: 0
            },
            isTransparent: true,
            isContainer: true,
            fadeTransition: true,
        }
    );
}

export function takeHealthSurvey(i18n) {
    const closeHealthSurvey = Modal.open(
        Onboarding.HealthSurvey,
        {
            carousel: true,
            close: () => closeHealthSurvey(),
            rightButtonLabel: i18n.t('quit'),
            showQuit: true
        },
        {
            cancelable: false,
            isNoPadding: true,
            isContainer: true,
            isMaxHeight: true
        }
    );
}

const styles = StyleSheet.create({
    root: {
        margin: '0 auto'
    }
});

export function healthScoreFeedback() {
    const closeHealthScoreFeedback = Modal.open(
        AsyncComponent(() => import('../components/HealthScoreFeedbackModal')),
        {
            closeModal: () => closeHealthScoreFeedback()
        },
        {
            isTransparent: true,
            isContainer: false,
            isNoPadding: true,
        }
    );
}
