import _ from 'lodash';

import * as types from './actionTypes';
import { STATE_KEYS, personalBase, bioMetricsBase } from './constants';
import { actionTypes as coreActionTypes } from '../core';
import { actionTypes as authTypes } from '../auth';

export const initialState = {
    notificationSettings: {
        items: {},
        ids: []
    },
    privacySettings: {
        items: {},
        ids: [],
        userEmail: null,
    },
    currentUserPrivacySettings: {
        items: {},
        ids: []
    },
    company_tasks: [],
    company_features: {},
    feedback_metadata: [],
    feedback: {},
    biometrics: [],
    personalSettings: [],
    userPreferredWaistCircumferenceUnit: '',
    userPreferredHeightUnit: '',
    userPreferredWeightUnit: '',
    isHiddenPrivacyInformationText: [],
    isLanguageChanged: false,
    wellbeingScoreTab: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.GET_NOTIFICATIONS_SETTINGS.SUCCESS:
            return {
                ...state,
                notificationSettings: {
                    ...state.notificationSettings,
                    items: action.payload.items,
                    ids: action.payload.ids
                }
            };
        case types.GET_PRIVACY_SETTINGS.SUCCESS:
            return {
                ...state,
                privacySettings: {
                    ...state.privacySettings,
                    items: action.payload.items,
                    ids: action.payload.ids,
                    userEmail: action.payload.userEmail,
                }
            };
        case types.SET_NOTIFICATIONS_SETTINGS.SUCCESS: {
            const { value, typeId } = action.payload;
            const items = {
                ...state.notificationSettings.items,
                [typeId]: { ...state.notificationSettings.items[typeId], value }
            };
            return {
                ...state,
                notificationSettings: { ...state.notificationSettings, items }
            };
        }
        case types.SET_PRIVACY_SETTINGS.SUCCESS: {
            const { value, typeId } = action.payload;
            const items = {
                ...state.privacySettings.items,
                [typeId]: { ...state.privacySettings.items[typeId], value }
            };
            return {
                ...state,
                privacySettings: { ...state.privacySettings, items }
            };
        }
        case types.SET_FEEDBACK_METADATA.SUCCESS:
            return {
                ...state,
                feedback_metadata: action.payload.data
            };
        case types.GET_BIOMETRICS.SUCCESS:
        case types.SAVE_BIOMETRICS.SUCCESS: {
            const { data } = action.payload;
            const items = _.has(data, 'items') ? data.items : data;
            const base = _.filter(bioMetricsBase, i => _.has(items, i.stateKey));
            const biometrics = _.map(base, item => {
                const biometric = {
                    ...item,
                    enabled: items[item.stateKey].enabled,
                    required: items[item.stateKey].required,
                    value: items[item.stateKey].value,
                    preferredUnit: items[item.stateKey].preferredUnit,
                    defaultUnit: items[item.stateKey].defaultUnit,
                };
                if (_.isArray(_.get(items[item.stateKey], 'supportedUnits'))) {
                    biometric.supportedUnits = items[item.stateKey].supportedUnits;
                    biometric.units = _.map(items[item.stateKey].supportedUnits, item => item.name);
                    biometric.unit = _.get(_.find(items[item.stateKey].supportedUnits,
                        unit => unit.slug === biometric.unit), 'name');
                }

                return biometric;
            });
            const biometricCustomComponent = _.get(data, 'customComponent');
            return {
                ...state,
                biometrics,
                biometricCustomComponent,
                userPreferredWaistCircumferenceUnit: items.userPreferredWaistCircumferenceUnit.value
            };
        }
        case types.GET_PERSONAL_SETTINGS.SUCCESS:
        case types.SAVE_PERSONAL_SETTINGS.SUCCESS: {
            const { data } = action.payload;
            const base = _.filter(personalBase, i => _.has(data, i.stateKey));
            const personalSettings = _.map(base, item => {
                const i = {
                    ...item,
                    enabled: data[item.stateKey].enabled,
                    required: data[item.stateKey].required,
                    value: data[item.stateKey].value,
                    readOnly: data[item.stateKey].readOnly
                };

                if (item.stateKey === STATE_KEYS.dateOfBirth) {
                    i.value = _.get(data[item.stateKey], 'value');
                }
                return i;
            });
            return {
                ...state,
                personalSettings,
                userPreferredHeightUnit: data.userPreferredHeightUnit.value,
                userPreferredWeightUnit: data.userPreferredWeightUnit.value,
                waistInchesValue: data[STATE_KEYS.waistInches].value,
            };
        }
        case types.GET_COMPANY_TASKS.SUCCESS:
            return {
                ...state,
                company_tasks: action.payload.data
            };
        case types.GET_COMPANY_FEATURES.SUCCESS:
            return {
                ...state,
                company_features: action.payload.data
            };
        case types.GET_CURRENT_USER_PRIVACY_SETTINGS.SUCCESS: {
            return {
                ...state,
                currentUserPrivacySettings: {
                    ...state.currentUserPrivacySettings,
                    items: action.payload.items,
                    ids: action.payload.ids
                },
                loading: false
            };
        }
        case types.SET_CURRENT_USER_PRIVACY_SETTINGS.SUCCESS: {
            const { value, privacyTypeId } = action.payload;
            const items = {
                ...state.currentUserPrivacySettings.items,
                [privacyTypeId]: { ...state.currentUserPrivacySettings.items[privacyTypeId], value, disabled: value }
            };
            return {
                ...state,
                currentUserPrivacySettings: { ...state.currentUserPrivacySettings, items }
            };
        }
        case types.UPDATE_PRIVACY_INFORMATION_TEXT: {
            return {
                ...state,
                isHiddenPrivacyInformationText: [...action.payload]
            };
        }
        case authTypes.CHANGE_LANGUAGE:
            return { ...state, isLanguageChanged: true };
        case types.RESET_IS_LANGUAGE_CHANGED:
            return { ...state, isLanguageChanged: false };
        case types.SELECT_WELLBEING_SCORE_TAB:
            return { ...state, wellbeingScoreTab: true };
        case types.RESET_WELLBEING_SCORE_TAB:
            return { ...state, wellbeingScoreTab: false };
        case coreActionTypes.RESET:
            return initialState;
        default:
            return state;
    }
};
