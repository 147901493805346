import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';

import { components as Core } from '../../../core';
import { baseColors, spacing } from '../../../../styles';

const ICON_SIZE = spacing.s7;

class Plus extends PureComponent {
    static propTypes = {
        containerStyle: PropTypes.string.isRequired,
        onPress: PropTypes.func.isRequired
    };

    render() {
        return (
            <div className={this.props.containerStyle}>
                <Core.IconButton
                    name="plus-circle"
                    fill="light"
                    isSmall={true}
                    className={css(styles.iconContainer)}
                    size={ICON_SIZE}
                    color={baseColors.white}
                    type="fa"
                    onClick={this.props.onPress}
                />
            </div>
        );
    }
}

const CONTAINER_SIZE = spacing.s10;

const styles = StyleSheet.create({
    iconContainer: {
        width: CONTAINER_SIZE,
        height: CONTAINER_SIZE
    }
});

export default Plus;