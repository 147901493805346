import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { translate } from '../../services';
import { baseColors, spacing } from '../../../../styles';

export const ICON_SIZE = 14;

export default function WithAvatarUploadBase(WrappedComponent) {
    class AvatarUploadBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            picture: PropTypes.string,
            fileChanged: PropTypes.func.isRequired,
            deleteFile: PropTypes.func,
        };

        static defaultProps = {
            picture: '',
            deleteFile: null
        };

        fileChanged = (picture, pictureFull) =>
            picture ? this.props.fileChanged(picture, pictureFull) : this.props.deleteFile();

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    fileChanged={this.fileChanged}
                />
            );
        }
    }

    return translate()(AvatarUploadBase);
}

export const styles = {
    iconContainer: {
        width: spacing.s5,
        height: spacing.s5,
        backgroundColor: baseColors.secondary,
        borderRadius: spacing.s2,
    },
    profileImage: {
        paddingTop: 0
    }
};
