import { baseColors } from '../../../../../styles';

export const styles = {
    body: {
        flex: 1,
        width: '100%',
        justifyContent: 'center',
        flexDirection: 'column',
        display: 'flex'
    },
    grey: {
        backgroundColor: baseColors.grey90,
        borderColor: baseColors.grey80,
    }
};

