import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { css, StyleSheet } from 'aphrodite-jss';
import { components as Core, ROUTES } from '../../../core';
import { exploreImageHeight, layoutStyle, spacing } from '../../../../styles';
import defaultImage from '../../../../image/placeholder_group_o.jpg';
import WithExploreCarouselItemBase, { styles as baseStyles } from './ExploreCarouselItemBase';
import ChallengeImage from '../ChallengeImage';
import BonusChallengeMark from '../BonusChallengeMark';
import { checkItemBounds } from '../../services/helper';

const IMAGE_HEIGHT_OF_A_SINGLE_ITEM = 240;

class ExploreCarouselItem extends PureComponent {
    static propTypes = {
        isDisabled: PropTypes.bool,
        challengeImage: PropTypes.string,
        challengeName: PropTypes.string.isRequired,
        challengeInstruction: PropTypes.string.isRequired,
        history: PropTypes.object.isRequired,
        challengeId: PropTypes.number.isRequired,
        featuredTag: PropTypes.node.isRequired,
        challengeDateText: PropTypes.string.isRequired,
        isOneItemInCarusel: PropTypes.bool,
        isChallengesPage: PropTypes.bool,
        isTeamChallenge: PropTypes.bool.isRequired,
        carouselRef: PropTypes.object
    };

    static defaultProps = {
        isDisabled: false,
        challengeImage: undefined,
        isOneItemInCarusel: false,
        isChallengesPage: false,
        carouselRef: undefined
    };

    constructor(props) {
        super(props);
        this.childRef = React.createRef();
    }


    get image() {
        return this.props.challengeImage || defaultImage;
    }

    get imageInnerComponent() {
        const { challengeId, isOneItemInCarusel, isChallengesPage } = this.props;
        return (
            <div style={styles.imageContainer}>
                <ChallengeImage
                    challengeId={challengeId}
                    image={this.image}
                    isCarouselItem={true}
                    imageClassName={(!isOneItemInCarusel && isChallengesPage) ? styles.exploreHeightImage : {}}
                    resizeMode="cover"
                    imageHeight={(isOneItemInCarusel && isChallengesPage) ? IMAGE_HEIGHT_OF_A_SINGLE_ITEM : exploreImageHeight}
                    imageWidth="100%"
                    hasRoundBorder={true}
                />
            </div>
        );
    }

    openDetails = () => {
        if (this.props.isDisabled) return;
        this.props.history.push(ROUTES.challengeDetails(this.props.challengeId));
    };

    render() {
        const { challengeId, challengeName, challengeInstruction, challenge, featuredTag, challengeDateText, joinedTag, isOneItemInCarusel, isChallengesPage, isTeamChallenge,
            containerClassName, hasSmallerFocusOffset, carouselRef } = this.props;
        const tabIndex = checkItemBounds(carouselRef, this.childRef);

        return (
            <div ref={this.childRef}>
                <Core.TouchableItem
                    key={challengeId}
                    onPressSingle={this.openDetails}
                    containerClassName={containerClassName}
                    innerContainerClassName={css(layoutStyle.flex1)}
                    image={false}
                    imageHeight={(isOneItemInCarusel && isChallengesPage) ? IMAGE_HEIGHT_OF_A_SINGLE_ITEM : exploreImageHeight}
                    imageWidth="100%"
                    imageInnerComponent={this.imageInnerComponent}
                    isCarouselItem={true}
                    featuredTag={featuredTag}
                    joinedTag={joinedTag}
                    tabIndex={tabIndex}
                    hasSmallerFocusOffset={hasSmallerFocusOffset}>
                    <div className={css(styles.infoContainer, layoutStyle.carouselInfoContainer)}>
                        <div className={css(styles.startsInContainer)}>
                            <Core.ExploreItemMeta iconName="calendar-alt" text={challengeDateText} iconContainerClassName={styles.iconContainerClassName} />
                        </div>
                        <Core.ExploreItemTitle text={challengeName} />
                        <Core.ExploreItemDescription text={challengeInstruction} />
                        <Core.ExploreItemMembers members={challenge.participants} isChallenge={true} numParticipants={challenge.numChallengeParticipants} isTeamChallenge={isTeamChallenge} />
                        <BonusChallengeMark
                            challengeId={challengeId}
                            containerClassName={css(styles.bonusMarkContainer)}
                        />
                    </div>
                </Core.TouchableItem>
            </div>
        );
    }
}

export default withRouter(WithExploreCarouselItemBase(ExploreCarouselItem));

const styles = StyleSheet.create({
    ...baseStyles,
    infoContainer: {
        ...baseStyles.infoContainer,
        marginLeft: spacing.s0
    },
    startsInContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: -spacing.s1
    },
    iconContainerClassName: {
        marginBottom: spacing.s0 - 2
    }
});
