import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { css, StyleSheet } from 'aphrodite-jss';
import { layoutStyle } from '../../../../../styles';
import { styles as baseStyles } from './BodyBase';

class CardBody extends PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        className: PropTypes.string,
        isGrey: PropTypes.bool,
    };

    static defaultProps = {
        className: undefined,
        isGrey: true
    };

    render() {
        const { isGrey, className } = this.props;
        return (
            <div className={classnames(css(layoutStyle.cardPadding, styles.body, isGrey ? styles.grey : ''), className)}>
                {this.props.children}
            </div>
        );
    }
}

export default CardBody;

const styles = StyleSheet.create({
    ...baseStyles
});

