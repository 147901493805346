import moment from 'moment';
import _ from 'lodash';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { spacing, appFonts, baseColors } from '../../../../../styles';
import { STREAM_ITEM_TYPE_SLUGS, STREAM_ENTITY_TYPES } from '../../../constants';
import { translate, selectors as coreSelectors, timeout, TOAST_LIVE_TIME, entitiesHelper, tracker } from '../../../../core';
import { selectors as eventsSelectors, actions as eventsActions } from '../../../../events';
import { selectors as communitiesSelectors, actions as communitiesActions } from '../../../../communities';
import { selectors as challengesSelectors, actions as challengesActions,
    constants as challengesConstants, services as challengeServices } from '../../../../challenges';

const ANIMATION_DURATION = (TOAST_LIVE_TIME * 1000) / 3;

const STREAM_TYPES_JOIN = [
    STREAM_ITEM_TYPE_SLUGS.new_event_group_challenge,
    STREAM_ITEM_TYPE_SLUGS.add_company_event,
    STREAM_ITEM_TYPE_SLUGS.join_event_group_challenge,
    STREAM_ITEM_TYPE_SLUGS.add_group,
    STREAM_ITEM_TYPE_SLUGS.add_event,
    STREAM_ITEM_TYPE_SLUGS.add_challenge,
    STREAM_ITEM_TYPE_SLUGS.group_join_challenge,
    STREAM_ITEM_TYPE_SLUGS.add_notification
];

const JOIN_ENTITY_TYPES = {
    event: 'event',
    challenge: 'challenge',
    group: 'group'
};

export const BUTTON_TYPES = {
    outlined: 'outlined',
    processing: 'processing'
};

export default function WithJoinButtonBase(WrappedComponent) {
    class JoinButtonBase extends PureComponent {
        static propTypes = {
            stream: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            user: PropTypes.object.isRequired,
            relatedItem: PropTypes.object,
            buttonStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.number, PropTypes.array]),
            isJoining: PropTypes.bool,
            joiningError: PropTypes.object,
            i18n: PropTypes.object.isRequired,
            setTimeout: PropTypes.func.isRequired
        };

        static defaultProps = {
            relatedItem: null,
            buttonStyle: null,
            isJoining: false,
            joiningError: null
        };

        constructor(props) {
            super(props);
            const { i18n } = this.props;
            this.JOIN_TYPE_CONFIG = {
                [JOIN_ENTITY_TYPES.event]: {
                    type: JOIN_ENTITY_TYPES.event,
                    join: i18n.t('joinEvent'),
                    joined: i18n.t('joinedEvent'),
                    processing: i18n.t('joinProgress'),
                    success: i18n.t('joinSuccess'),
                    condition: data => data.eventId && this.isEventUnStarted
                },
                [JOIN_ENTITY_TYPES.challenge]: {
                    type: JOIN_ENTITY_TYPES.challenge,
                    join: i18n.t('joinChallenge'),
                    view: i18n.t('viewChallenge'),
                    joined: i18n.t('joinedChallenge'),
                    processing: i18n.t('joinProgress'),
                    success: i18n.t('joinSuccess'),
                    condition: data => data.challengeId && !data.isCompleted && !entitiesHelper.isAfterDeadline(data.challengeDeadline)
                },
                [JOIN_ENTITY_TYPES.group]: {
                    type: JOIN_ENTITY_TYPES.group,
                    join: i18n.t('joinCommunity'),
                    joined: i18n.t('joinedCommunity'),
                    processing: i18n.t('joinProgress'),
                    success: i18n.t('joinSuccess'),
                    condition: data => !!data.id
                }
            };
            this.isButtonVisibleOnFirstLoad = !this.isMember(this.props);
            this.state = {
                joinButton: this.isMember(props) ? this.joinType.joined : this.joinType.join,
                isAfterButtonFade: false
            };
        }

        UNSAFE_componentWillReceiveProps(nextProps) {
            if (this.joinType) {
                let joinButton = '';
                if (nextProps.isJoining) {
                    joinButton = this.joinType.processing;
                } else if (!nextProps.isJoining && this.props.isJoining && !nextProps.joiningError) {
                    joinButton = this.joinType.success;
                    this.onJoiningSuccess();
                } else if (this.isMember(nextProps)) {
                    joinButton = this.joinType.joined;
                } else {
                    joinButton = this.joinType.join;
                }
                this.setState({ joinButton });
            }
        }

        componentDidUpdate(prevProps) {
            if (prevProps.isJoining && !this.props.isJoining && this.wrapped.animate) {
                this.wrapped.animate();
            }
        }

        get isEventUnStarted() {
            const data = this.props.relatedItem || this.relatedItemDetails;
            return moment(data.eventDateTime).isSameOrAfter(moment());
        }

        get isTypeEvent() {
            return this.joinType.type === STREAM_ENTITY_TYPES.event;
        }

        get showEventStartedText() {
            return !this.isEventUnStarted && this.isTypeEvent;
        }

        get eventStartedText() {
            return this.props.i18n.t('eventStarted');
        }

        get _isTeamChallenge() {
            const { userTeam, companyTeam } = challengesConstants.CHALLENGE_SUBENTITY_TYPES;
            return this.relatedItemDetails.challengeEntityType === challengesConstants.CHALLENGE_ENTITY_TYPES.group
                && (this.relatedItemDetails.challengeSubentityType === userTeam || this.relatedItemDetails.challengeSubentityType === companyTeam);
        }

        onJoiningSuccess = () => {
            this.props.setTimeout(() => this.setState(() => ({ isAfterButtonFade: true })), (ANIMATION_DURATION + 10));
            this.props.setTimeout(() => this.setState(() => ({ joinButton: this.joinType.joined })), TOAST_LIVE_TIME * 1000);
        };

        joinToEntity = (relatedToId, params) => {
            const { actions, i18n } = this.props;
            if (this.joinType.type === STREAM_ENTITY_TYPES.group) {
                tracker.logEvent('join_group', { id: relatedToId, stream: true });
                return actions.joinCommunity(relatedToId, false, undefined,
                    i18n.t('joiningCommunityToast.success.message', { name: this.relatedItemDetails.name }));
            } else if (this.joinType.type === STREAM_ENTITY_TYPES.event) {
                tracker.logEvent('join_event', { id: relatedToId, stream: true });
                return actions.joinEvent(relatedToId);
            }
            tracker.logEvent('join_challenge', { id: relatedToId, stream: true });
            return actions.joinChallenge(relatedToId, this.props.user.userId, false, null, this.relatedItemDetails.challengeName);
        };

        join = challengeJoinCallback => {
            if (this.state.joinButton !== this.joinType.join) return;
            const { relatedToId } = this.props.stream;
            const params = {};
            if (this.joinType.type === STREAM_ENTITY_TYPES.challenge) {
                if (this._isTeamChallenge) {
                    challengeJoinCallback();
                }
                const representedEntityId = this._getRepresentedEntityId();
                if (!representedEntityId) return;
                params.representedEntityId = representedEntityId;
            }
            this.joinToEntity(relatedToId, params);
        };

        get relatedItemDetails() {
            return this.props.stream.relatedItemDetails || {};
        }

        // Membership API requires a representedEntityId which depends upon the type of user or challenge
        _getRepresentedEntityId() {
            const type = this.relatedItemDetails.challengeEntityType;
            switch (type) {
                case challengesConstants.CHALLENGE_ENTITY_TYPES.user:
                    return this.props.user.userId;
                case challengesConstants.CHALLENGE_ENTITY_TYPES.region:
                    return this.props.user.region.id;
                case challengesConstants.CHALLENGE_ENTITY_TYPES.company:
                    return this.props.user.userId;
                case challengesConstants.CHALLENGE_ENTITY_TYPES.group:
                    break;
                case challengesConstants.CHALLENGE_ENTITY_TYPES.team:
                case challengesConstants.CHALLENGE_ENTITY_TYPES.department:
                    alert(`Challenges for ${type}s have not been implemented.`);
                    break;
                default:
                    break;
            }
        }

        getTeamOptions = (create, join) => [
            { title: this.props.i18n.t('create_team'), onPress: create },
            { title: this.props.i18n.t('join_team'), onPress: join }
        ];

        joinedTeamCallback = () => {
            this.setState(() => ({ joinButton: this.joinType.success }));
            this.props.setTimeout(() => this.setState(() => ({ isAfterButtonFade: true })), (ANIMATION_DURATION + 10));
            this.props.setTimeout(() => this.setState(() => ({ joinButton: this.joinType.joined })), TOAST_LIVE_TIME * 1000);
        };

        get joinType() {
            const { streamItemTypeSlug, relationTypeSlug } = this.props.stream;
            if (_.includes(STREAM_TYPES_JOIN, streamItemTypeSlug)) {
                return this.JOIN_TYPE_CONFIG[relationTypeSlug];
            }
            return null;
        }

        isMember = props => {
            if (_.has(props, 'relatedItem.isMember')) return props.relatedItem.isMember;
            return props.stream.viewer ? !!props.stream.viewer.isMember : false;
        };

        get joinButtonOpacity() {
            return this.state.joinButton !== this.joinType.join ? 1 : 0.2;
        }

        get isButtonVisible() {
            if (!_.size(this.relatedItemDetails)) return false;
            const challengeAfterDeadline = challengeServices.helper.isAfterDeadline(this.relatedItemDetails);
            return this.props.setTimeout(() =>
                this.joinType.condition(this.props.relatedItem || this.relatedItemDetails)
                && !this.isMember(this.props), 0)
                && !challengeAfterDeadline;
        }

        get isJoinButtonIconShown() {
            return this.state.joinButton === this.joinType.success;
        }

        get isJoinSuccessful() {
            return this.state.joinButton === this.joinType.success || this.state.joinButton === this.joinType.joined;
        }

        get isAfterFadeAnimation() {
            return this.state.isAfterButtonFade;
        }

        get isJoinButtonShown() {
            return (this.isButtonVisible || this.state.joinButton !== this.joinType.joined);
        }

        get animationDuration() {
            return ANIMATION_DURATION;
        }

        setRef = ref => this.wrapped = ref;

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    getTeamOptions={this.getTeamOptions}
                    joinButton={this.state.joinButton}
                    isJoinButtonIconShown={this.isJoinButtonIconShown}
                    isJoinSuccessful={this.isJoinSuccessful}
                    isAfterFadeAnimation={this.isAfterFadeAnimation}
                    isButtonVisible={this.isButtonVisible}
                    joinButtonOpacity={this.joinButtonOpacity}
                    isJoinButtonShown={this.isJoinButtonShown}
                    joinToEntity={this.joinToEntity}
                    joinButtonLabel={this.state.joinButton}
                    joinType={this.joinType}
                    join={this.join}
                    joinedTeamCallback={this.joinedTeamCallback}
                    relatedItemDetails={this.relatedItemDetails}
                    animationDuration={this.animationDuration}
                    isButtonVisibleOnFirstLoad={this.isButtonVisibleOnFirstLoad}
                    ref={this.setRef}
                    showEventStartedText={this.showEventStartedText}
                    eventStartedText={this.eventStartedText}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        const { relationTypeSlug, relatedToId } = ownProps.stream || {};

        const props = { user: coreSelectors.getCurrentUser(state) };
        switch (relationTypeSlug) {
            case JOIN_ENTITY_TYPES.event:
                return {
                    ...props,
                    relatedItem: eventsSelectors.getEvent(state, relatedToId),
                    isJoining: eventsSelectors.isJoiningEvent(state, relatedToId),
                    joiningError: eventsSelectors.getJoiningError(state, relatedToId)
                };
            case JOIN_ENTITY_TYPES.group:
                return {
                    ...props,
                    relatedItem: communitiesSelectors.getSingleCommunity(state, relatedToId),
                    isJoining: communitiesSelectors.communityJoining(state, relatedToId),
                    joiningError: communitiesSelectors.getJoiningError(state, relatedToId)
                };
            case JOIN_ENTITY_TYPES.challenge:
                return {
                    ...props,
                    relatedItem: challengesSelectors.getChallenge(state, relatedToId),
                    isJoining: challengesSelectors.isJoiningChallenge(state, relatedToId),
                    joiningError: challengesSelectors.getJoiningError(state, relatedToId)
                };
            default: return { ...props, relatedItem: null, isJoining: false, joiningError: null };
        }
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators({ ...challengesActions, ...communitiesActions, ...eventsActions }, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(timeout(JoinButtonBase)));
}

export const styles = {
    joinButtonIcon: {
        marginRight: spacing.s1
    },
    successSection: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        width: '100%',
        paddingTop: spacing.s6,
        paddingBottom: spacing.s1
    },
    successText: {
        ...appFonts.mdMedium,
        color: baseColors.secondary
    },
    pastEventText: {
        ...appFonts.smRegular,
        color: baseColors.grey40,
        marginLeft: spacing.s9
    },
};
