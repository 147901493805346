import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { withRouter } from 'react-router-dom';
import { baseColors, spacing, media, appFonts } from '../../../../styles';
import { components as Core, ROUTES, tracker } from '../../../core';
import WithDashboardBase, { styles as baseStyles, SHADOW } from './RewardsBalanceBase';

class RewardsBalance extends PureComponent {
    static propTypes = {
        isRewardsEnabled: PropTypes.bool.isRequired,
        history: PropTypes.object.isRequired,
        rewardsButtonText: PropTypes.string.isRequired,
        isFirstLoaded: PropTypes.bool
    };

    static defaultProps = {
        isFirstLoaded: false
    };

    goToRewards = () => {
        tracker.logEvent('rewards', { event: 'open' });
        this.props.history.push(ROUTES.rewardsDetails());
    };

    get renderContent() {
        const { isRewardsEnabled, rewardsButtonText } = this.props;
        return isRewardsEnabled ? (
            <button onClick={this.goToRewards} className={css(styles.rewardsButton)}>
                <div className={css(styles.rewardsBalanceContainer)}>
                    <span className={css(styles.rewardsMainText, styles.whiteText)}>
                        {rewardsButtonText}
                    </span>
                </div>
            </button>
        ) : null;
    }

    render() {
        const { isFirstLoaded } = this.props;
        return (isFirstLoaded
            ? this.renderContent
            : <div className={css(styles.rewardsButton)}><Core.SkeletonRect width="100%" height={50} /></div>
        );
    }
}

export default withRouter(WithDashboardBase(RewardsBalance));

const styles = StyleSheet.create({
    ...baseStyles,
    rewardsBalanceContainer: {
        background: `linear-gradient(to top right, ${baseColors.dashboardLeftGradient} 2.69%, ${baseColors.dashboardRightGradient} 98.23%)`,
        padding: spacing.s3,
        borderRadius: spacing.s1,
        boxShadow: `0 2px ${SHADOW.height}px rgba(0, 0, 0, ${SHADOW.opacity})`
    },
    rewardsMainText: {
        ...appFonts.lgRegular,
        [media.sm]: {
            ...appFonts.mdRegular,
        },
    },
    rewardsButton: {
        marginTop: spacing.s3,
    },
});
