import { connect } from 'react-redux';
import React, { PureComponent } from 'react';
import { getFeed } from '../../../selectors';
import { translate } from '../../../../core';
import { STREAM_ITEM_TYPE_SLUGS } from '../../../constants';

export default function WithFeedItemCommentsBase(WrappedComponent) {
    class FeedItemCommentsBase extends PureComponent {
        get disableLikesComments() {
            const { stream: { streamItemTypeSlug } } = this.props;
            switch (streamItemTypeSlug) {
                case STREAM_ITEM_TYPE_SLUGS.join_event_group_challenge:
                case STREAM_ITEM_TYPE_SLUGS.add_challenge:
                case STREAM_ITEM_TYPE_SLUGS.add_group:
                case STREAM_ITEM_TYPE_SLUGS.add_event:
                case STREAM_ITEM_TYPE_SLUGS.add_user:
                case STREAM_ITEM_TYPE_SLUGS.group_join_challenge:
                    return true;
                default: return false;
            }
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    disableLikesComments={this.disableLikesComments}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        return {
            stream: getFeed(state, ownProps.streamItemId)
        };
    }

    return connect(mapStateToProps)(translate()(FeedItemCommentsBase));
}