import React, { Component } from 'react';
import axios from 'axios';
import ReactGA from 'react-ga';
import moment from 'moment/moment';
import _ from 'lodash';
import * as Sentry from '@sentry/browser';
import { partnerSubdomain, global } from './config';
import { getLightenColor, updateColors } from './styles/commonColor';
import ProgramDisabled from './ProgramDisabled';
import * as api from './modules/auth/api';
import systemBuildVariant from './config/buildVariant';
import { BUILDS } from './modules/core';

const STORAGE_KEY_CONFIGURATION = 'programConfigurationForWhiteLabel';
const EXPIRATION_DIFF_UNIT = 'minutes';
const EXPIRATION_DIFF_VALUE = 60 * 2; // value in mins - currently 2 hours
export const SENTRY_WEB_DSN = 'https://4d6a31af88754c94bfc80782ccc47b07@sentry.io/1811379';
export const GA_TRACKING_ID = 'UA-153877804-1';
export const GA_LB_TRACKING_ID = 'UA-153877804-2';

const dataCollectionDisabled =localStorage.getItem('dataCollectionDisabled');

const ERROR_CODES = Object.freeze({
    disabled: 'deployment_disabled',
    notFound: 'resource_not_found'
});

if (process.env.NODE_ENV === 'production') {
    Sentry.init({ dsn: SENTRY_WEB_DSN });
    if (!dataCollectionDisabled) {
        ReactGA.initialize([{
            trackingId: GA_TRACKING_ID,
            gaOptions: {
                name: 'sprout',
                hostname: window.location.hostname,
                storage: 'none',
                storeGac: false
            }
        }, {
            trackingId: GA_LB_TRACKING_ID,
            gaOptions: {
                name: 'livebetter',
                hostname: window.location.hostname,
                storage: 'none',
                storeGac: false
            }
        }]);
    }
}


class AppContainer extends Component {
    constructor(props) {
        super(props);
        this.state = { component: null };
    }

    componentDidMount() {
        this.checkDomain();
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        if (process.env.NODE_ENV === 'production') {
            Sentry.withScope(scope => {
                Object.keys(errorInfo).forEach(key => {
                    scope.setExtra(key, errorInfo[key]);
                });
                Sentry.captureException(error);
            });
            setTimeout(() => window.location.reload(true), 3000);
        }
    }

    static getItemSync(name) {
        const item = localStorage.getItem(name);
        return item && JSON.parse(item);
    }

    static setItemSync(name, value) {
        return localStorage.setItem(name, JSON.stringify(value));
    }

    static removeItemSync(name) {
        return localStorage.removeItem(name);
    }

    checkDomain = async () => {
        if (!partnerSubdomain || partnerSubdomain === 'login') return this.setComponent();
        // Query directory
        try {
            const response = await api.queryDirectory('program', global.systemEnvironment.environment, partnerSubdomain);
            const endpoints = response.data;
            const environmentEndpoints = { environment: global.systemEnvironment.environment, ...endpoints };
            global.systemEnvironment = environmentEndpoints;
        }
        catch (error) {
            if (systemBuildVariant === BUILDS.telus) {
                window.location = 'https://login.wellbeing.telushealth.com';
            }
            else {
                window.location = 'https://login.sproutatwork.com';
            }
        }

        const whiteLabelConfigurations = await this.getWhiteLabelConfigurations();

        if (whiteLabelConfigurations) {
            AppContainer.setItemSync(STORAGE_KEY_CONFIGURATION, whiteLabelConfigurations);
            this.changeStyles(whiteLabelConfigurations);
            document.title = whiteLabelConfigurations.programName;
        }
        this.setComponent(whiteLabelConfigurations);
    };

    getWhiteLabelConfigurations = () =>
        this.getWhiteLabelConfigurationsSaved() || this.getWhiteLabelConfigurationsRemote();

    getWhiteLabelConfigurationsRemote = async () => {
        const axiosDefault = axios.create();
        const url = `${global.systemEnvironment.SPROUT_LOGIN_URL}companies`;

        try {
            const res = await axiosDefault.get(url, { params: { programName: partnerSubdomain } });
            const brand = _.get(res, 'data.brand', {});
            return brand && { ...brand, partnerSubdomain, date: moment().toISOString(), programName: res.data.programName || partnerSubdomain };
        } catch (error) {
            if (_.get(error, 'response.data.errorCode') === ERROR_CODES.disabled) {
                this.setState({ programDisabled: true, errorMessage: _.get(error, 'response.data.displayMessage') });
            }
            if (_.get(error, 'response.data.errorCode') === ERROR_CODES.notFound) {
                if (systemBuildVariant === BUILDS.telus) {
                    window.location = 'https://login.wellbeing.telushealth.com';
                }
                else {
                    window.location = 'https://login.sproutatwork.com';
                }
            }
        }
    };

    getWhiteLabelConfigurationsSaved = () => {
        const configuration = AppContainer.getItemSync(STORAGE_KEY_CONFIGURATION);
        if (configuration && partnerSubdomain === configuration.partnerSubdomain &&
            moment().diff(moment(configuration.date), EXPIRATION_DIFF_UNIT) < EXPIRATION_DIFF_VALUE && configuration.programName) {
            return configuration;
        }
    };

    changeStyles = configurations => {
        if (!configurations) return;
        // update colors and other things
        const { primaryColor: primary, secondaryColor: secondary, backgroundColor: background, } = configurations;
        updateColors(_.pickBy({ primary, secondary, background, dashboardLeftGradient: primary, dashboardRightGradient: primary, primaryLighten: getLightenColor(primary, 0.1) }, _.identity));

        if (secondary) {
            document.documentElement.style.setProperty('--secondary', secondary);
        }
    };

    setComponent = customWhiteLabelConfigurations => {
        const App = require('./AppInner').default;
        const CacheApp = require('./Cache').default;
        const component = (
            <CacheApp>
                <App appConfig={customWhiteLabelConfigurations} />
            </CacheApp>
        );
        this.setState({ component });
    };

    render() {
        if (this.state.hasError) {
            return (
                <div>
                    <h2>Something went wrong</h2>
                    <h3>This page will be recovered soon...</h3>
                </div>
            );
        }
        else if (this.state.programDisabled) {
            return <ProgramDisabled errorMessage={this.state.errorMessage} />;
        }
        return this.state.component;
    }
}

export default AppContainer;
