import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { components as Core, ROUTES, CSS_CLASSES, CAROUSEL_TYPES } from '../../../core';
import CarouselItem from '../CarouselItem';
import WithContentOnExploreBase from './ContentOnExploreBase';

class ContentOnExplore extends PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
        content: PropTypes.array.isRequired,
        i18n: PropTypes.object.isRequired,
        exploreLoadingStatus: PropTypes.object
    };

    static defaultProps = {
        exploreLoadingStatus: {}
    };

    goToContent = () => {
        this.props.history.push(ROUTES.content());
    };

    renderCarouselItem = item => {
        if (item !== undefined) {
            return <CarouselItem item={item} key={item.id} />;
        }

        return null;
    };

    render() {
        const { content, i18n, exploreLoadingStatus: { isLoadingAllExplore, initialLoadDataComplete } } = this.props;
        return (
            <React.Fragment>
                <div className={CSS_CLASSES.explore}>
                    {content || isLoadingAllExplore ? (
                        <Core.Carousel
                            isLoading={isLoadingAllExplore || !initialLoadDataComplete}
                            titleSize={Core.SubHeader.SIZES.large}
                            type={CAROUSEL_TYPES.multiple}
                            title={i18n.t('contentTitle')}
                            viewAllPress={this.goToContent}
                            itemsLength={content.length}
                            slidesToScrollValue={4}>
                            {_.map(content, this.renderCarouselItem)}
                        </Core.Carousel>
                    ) : null}
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(WithContentOnExploreBase(ContentOnExplore));