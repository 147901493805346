import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import { components as Core, ROUTES, tracker, Modal } from '../../../../core';
import { components as AppsDevices } from '../../../../appsdevices';
import DatesSwiper from '../../DatesSwiper';
import WithProgressBase, { styles as baseStyles } from './ProgressBase';
import { layoutStyle, spacing, baseColors, appFonts } from '../../../../../styles';
import ProgressTitle from '../../ProgressTitle';
import NotificationSection from '../NotificationSection';
import RestartButton from '../RestartButton';
import CompletionBonus from '../CompletionBonus';
import ChallengeParticipants from '../ChallengeParticipants';
import { getSwiperMarginRight } from '../../../services/helper';

const BAR_CHART_HEIGHT= 180;
const BACKGROUND_LINE_WIDTH = '93%';
const BACKGROUND_LINE_TOP = 18;
const ACTIVITY_CONTAINER_TOP = '35%';

class Progress extends PureComponent {
    static propTypes = {
        isOnboarding: PropTypes.bool,
        isRestartGoal: PropTypes.bool,
        i18n: PropTypes.object.isRequired,
        isGoal: PropTypes.bool.isRequired,
        isConnectedDevice: PropTypes.bool,
        history: PropTypes.object.isRequired,
        challenge: PropTypes.object.isRequired,
        isGoalRestarted: PropTypes.bool.isRequired,
        isInGracePeriod: PropTypes.bool.isRequired,
        isAfterDeadline: PropTypes.bool.isRequired,
        progressTitle: PropTypes.string.isRequired,
        goalEndedTitle: PropTypes.string.isRequired,
        goalEndedMessage: PropTypes.string.isRequired,
        isBonusWithPoints: PropTypes.number.isRequired,
        goalRestartedText: PropTypes.string.isRequired,
        isTrackingImpossible: PropTypes.bool.isRequired,
        endedBonusMessageTitle: PropTypes.string.isRequired,
        greacePeriodBonusMessageTitle: PropTypes.string.isRequired,
        gracePeriodBonusMessageSubtitle: PropTypes.string.isRequired,
        challengeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        competitionTitle: PropTypes.string.isRequired,
        todayProgressResult: PropTypes.object.isRequired,
        isDailyGoal: PropTypes.bool.isRequired,
        goalPeriodTitle: PropTypes.string.isRequired,
        goalPeriodProgress: PropTypes.object.isRequired,
        goalProgressResult: PropTypes.object.isRequired,
        todayProgressUnit: PropTypes.string.isRequired,
        trackedPoints: PropTypes.string.isRequired,
        streakDetails: PropTypes.string,
        showStreak: PropTypes.func,
        onPressRightChevron: PropTypes.func.isRequired,
        onPressLeftChevron: PropTypes.func.isRequired,
        barChartUnit: PropTypes.string.isRequired,
        showParticipantsCompletedCount: PropTypes.bool.isRequired,
        participantsSuccessTextArray: PropTypes.array,
        shouldShowAverage: PropTypes.bool.isRequired,
        hasProgressPerPeriod: PropTypes.bool.isRequired,
        dailyAverage: PropTypes.object.isRequired,
        dayState: PropTypes.string.isRequired,
        isBonusChallenge: PropTypes.bool.isRequired
    };

    static defaultProps = {
        isOnboarding: false,
        isRestartGoal: false,
        isConnectedDevice: false,
        streakDetails: null,
        showStreak: null,
        participantsSuccessTextArray: []
    };

    viewActivityHistoryListGoals = () => {
        const { challengeId, history, isOnboarding } = this.props;
        tracker.logEvent('challenge', { event: 'view_history', id: challengeId });
        isOnboarding ? history.push(ROUTES.onboardingGoalActivityHistory(challengeId)) :
            history.push(ROUTES.competitionActivityHistory(this.props.challengeId));
    };

    viewActivityHistoryListCompetition = () => {
        tracker.logEvent('challenge', { event: 'view_history', id: this.props.challengeId });
        this.props.history.push(ROUTES.competitionActivityHistory(this.props.challengeId));
    };

    // REMOVED TILL UPDATE
    // onParticipantsPress = () => {
    //     const { history, challengeId, challenge: { participantsCompletedPeriod } } = this.props;
    //     history.push(ROUTES.challengeMembers(challengeId), { participantsCompletedPeriod });
    // }

    get statusNotification() {
        const { isAfterDeadline, isInGracePeriod, greacePeriodBonusMessageTitle, isGoal,
            gracePeriodBonusMessageSubtitle, endedBonusMessageTitle, goalEndedTitle } = this.props;
        if (!isAfterDeadline) return;
        if (isInGracePeriod) {
            return (
                <button onClick={this.openStatusNotificationModal} className={css(layoutStyle.db)}>
                    <NotificationSection
                        title={greacePeriodBonusMessageTitle}
                        text={gracePeriodBonusMessageSubtitle}
                        icon={<Core.Icon type="fa" name="alarm-clock" color={baseColors.contextDarkest} size={spacing.s7} />}
                        bg={baseColors.contextLightest}
                        detailColor={baseColors.contextDarkest}
                    />
                </button>
            );
        }
        return isGoal ? (
            <NotificationSection
                title={endedBonusMessageTitle}
                icon={<Core.Icon type="fa" name="lock-alt" color={baseColors.contextDarkest} size={spacing.s7} />}
                bg={baseColors.contextLightest}
                detailColor={baseColors.contextDarkest}
            >
                {/* {isRestartGoal ? null : this.restartButton} SFE-6591 Removing the restart button for now until we can figure out a proper fix for calculating new targets */}
            </NotificationSection>
        ) : (
            <NotificationSection
                title={goalEndedTitle}
                icon={<Core.Icon type="fa" name="lock-alt" color={baseColors.contextDarkest} size={spacing.s7} />}
                bg={baseColors.contextLightest}
                detailColor={baseColors.contextDarkest}
            />
        );
    }

    openStatusNotificationModal = () => {
        this.closeModal = Modal.open(
            Core.InfoModal,
            {
                iconName: 'alarm-clock',
                title: this.props.goalEndedTitle,
                text: this.props.goalEndedMessage,
                textStyle: css(styles.modalDescriptionText),
                buttonTitle: this.props.i18n.t('close')
            },
            { isTransparent: true }
        );
    };

    get restartButton() {
        const { isGoalRestarted, goalRestartedText, challengeId } = this.props;
        return isGoalRestarted ? (
            <div className={css(layoutStyle.flexCenter)}>
                <Core.Icon
                    type="fa"
                    name="check"
                    fill="light"
                    size={spacing.s3}
                    color={baseColors.contextDarkest}
                />
                <p className={css(styles.goalRestartedText)}>{goalRestartedText}</p>
            </div>
        ) : <RestartButton challengeId={challengeId} />;
    }

    get periodProgressInfo() {
        const { goalPeriodTitle, goalPeriodProgress, goalProgressResult, showStreak } = this.props;
        return (
            <div className={css(layoutStyle.flexRow, styles.periodProgressMT)}>
                <Grid xs={4} className={css(styles.progressTitleText)}>{ goalPeriodTitle }</Grid>
                <Grid xs={8}>
                    <div className={css(layoutStyle.flexRow, layoutStyle.flexAlignEnd, styles.progressItemMarginTop, styles.progressQuantityWrap)}>
                        <div className={css(styles.progressQuantity)}>
                            {goalPeriodProgress.trackedValue}
                        </div>
                        <div className={css(styles.progressUnit)}>
                            {`/ ${goalPeriodProgress.periodValue} ${goalPeriodProgress.unit}`}
                        </div>
                    </div>
                    <div className={css(layoutStyle.flexRow, layoutStyle.flexAlignEnd, styles.progressResult, styles.progressItemMarginTop)}>
                        <div className={css(goalProgressResult.isLeftGrey ? styles.grey40 : null)}>
                            { goalProgressResult.left }
                        </div>
                        <div className={css(styles.progressResultLM, goalProgressResult.isRightGrey ? styles.grey40 : null)}>
                            { goalProgressResult.right }
                        </div>
                        {showStreak(this.renderStreak, 'weekly')}
                    </div>
                </Grid>
            </div>
        );
    }

    get todayProgressInfo() {
        const { competitionTitle, todayProgressResult, todayProgressUnit, trackedPoints, showStreak, shouldShowAverage, dailyAverage, isDaily } = this.props;
        return (
            <div className={css(layoutStyle.flexRow)}>
                <Grid xs={4} className={css(styles.progressTitleText)}>{competitionTitle}</Grid>
                <Grid xs={8}>
                    <div className={css(layoutStyle.flexRow, layoutStyle.flexAlignEnd, styles.progressItemMarginTop, styles.progressQuantityWrap)}>
                        <div className={css(styles.progressQuantity)}>
                            {trackedPoints}
                        </div>
                        <div className={css(styles.progressUnit)}>
                            {todayProgressUnit}
                        </div>
                    </div>
                    <div className={css(layoutStyle.flexColumn, layoutStyle.flexAlignEnd, styles.progressResult, styles.progressQuantityWrap)}>
                        <div className={css(layoutStyle.flexRow)}>
                            <div className={css(todayProgressResult.isLeftGrey ? styles.grey40 : null)}>
                                {todayProgressResult.left}
                            </div>
                            <div className={css(styles.progressResultLM, todayProgressResult.isRightGrey ? styles.grey40 : null)}>
                                {todayProgressResult.right}
                            </div>
                            {showStreak(this.renderStreak, 'daily')}
                        </div>
                        {!shouldShowAverage ? (
                            <Fragment>
                                {isDaily && (
                                    <div className={css(styles.dailyAverageTextContainer, layoutStyle.flexRow)}>
                                        <div className={css(!todayProgressResult.isLeftGrey ? styles.grey40 : null)}>
                                            {dailyAverage.left}
                                        </div>
                                        <div className={css(styles.progressResultLM, !todayProgressResult.isRightGrey ? styles.grey40 : null)}>
                                            {dailyAverage.right}
                                        </div>
                                    </div>
                                )}
                            </Fragment>
                        ) : null }
                    </div>
                </Grid>
            </div>
        );
    }

    get progress() {
        const { isDailyGoal, isGoal, isAfterDeadline, isDaily, challenge: { frequency } } = this.props;
        if (isAfterDeadline) return null;
        return (
            <div className={css(styles.progressWrap)}>
                {isGoal ? (
                    <>
                        {this.todayProgressInfo}
                        {isDailyGoal && this.renderParticipantSuccessCount}
                        {!isDailyGoal ? this.periodProgressInfo : null}
                        {!isDailyGoal && this.renderParticipantSuccessCount}
                    </>
                ) : (
                    <>
                        {this.todayProgressInfo}
                        {frequency && !isDaily ? this.periodProgressInfo : null}
                        {this.renderParticipantSuccessCount}
                    </>
                )}
            </div>
        );
    }

    get renderStreak() {
        const { streakDetails } = this.props;
        return <p className={css(styles.streakText)}>{streakDetails}</p>;
    }

    get renderParticipantSuccessCount() {
        const { challenge, challengeId, showParticipantsCompletedCount, participantsSuccessTextArray } = this.props;
        const isCompletedUsers = !!challenge.participantsCompletedPeriod.total;
        const formattedText = (
            <p className={css(styles.participantsTextContainer)}>
                {participantsSuccessTextArray.map((item, index) =>
                    <span className={(index % 2) ? null : css(styles.participantsCount)}>{item}</span>
                )}
            </p>
        );

        return showParticipantsCompletedCount ? (
            <div className={css(styles.participantsContainer)}>
                {isCompletedUsers && <ChallengeParticipants sizeAvatar='xmsmall' showOnCard={true} challengeId={challengeId} showParticipantsCompletedPeriod={true} />}
                <div className={css(styles.participantsTextContainer)}>
                    {formattedText}
                </div>
            </div>
        ) : null;
    }

    get marginRightNotDaily() {
        const { isDaily, dailyWeeksProgress } = this.props;
        if (isDaily) return null;
        const maxInWeek = Math.max(...dailyWeeksProgress);
        return {
            marginRight: getSwiperMarginRight(maxInWeek),
        };
    }

    render() {
        const {
            isGoal,
            challenge,
            challengeId,
            isTrackingImpossible,
            isBonusWithPoints,
            history,
            weeksProgress,
            weekIndex,
            isConnectedDevice,
            isShowLeftChevron,
            isShowRightChevron,
            paginationControlTitle,
            dailyWeeksProgress,
            changeBarIndex,
            isDaily,
            dayState,
            onPressRightChevron,
            onPressLeftChevron,
            barChartUnit,
            hasProgressPerPeriod,
            noActivityTitle,
            isBonusChallenge,
            challengeType } = this.props;

        const noActivityLabel = () => {
            return(
                _.every(dailyWeeksProgress, (item => item === 0)) ?
                    <div className={css(styles.noActivityContainer)}>
                        <div className={css(styles.noActivityTitle)}>
                            {noActivityTitle}
                        </div>
                        <div className={css(styles.backgroundLine)}/>
                    </div> : null
            );
        };
        return (
            <Fragment>
                {this.progress}
                {this.statusNotification}
                <div className={css(layoutStyle.flexRow, styles.chartBlockWrap)} >
                    <Grid xs={4}>
                        <ProgressTitle
                            title={this.props.progressTitle}
                            onPress={isGoal ? this.viewActivityHistoryListGoals : this.viewActivityHistoryListCompetition}
                            isDailyProgress={true}
                            challengeId={this.props.challengeId}
                        />
                    </Grid>
                    <Grid xs={8} className={css(styles.mLeft)}>
                        <div className={css(layoutStyle.flexRowCenter, hasProgressPerPeriod && styles.mBottom)}>
                            {isShowLeftChevron ? (
                                <Core.Button
                                    onPress={onPressLeftChevron}
                                    type="text"
                                    size={Core.Button.SIZES.small}>
                                    <Core.Icon
                                        color={baseColors.secondary}
                                        name="chevron-left"
                                        size={spacing.s2}
                                        type="fa"
                                        fill="regular"
                                    />
                                </Core.Button>
                            ) : null}
                            <div className={css(styles.paginationControlTitle)}>
                                {paginationControlTitle}
                            </div>
                            {isShowRightChevron ? (
                                <Core.Button
                                    onPress={onPressRightChevron}
                                    type="text"
                                    size={Core.Button.SIZES.small}>
                                    <Core.Icon
                                        color={baseColors.secondary}
                                        name="chevron-right"
                                        size={spacing.s2}
                                        type="fa"
                                        fill="regular"
                                    />
                                </Core.Button>
                            ) : null}
                        </div>
                        {!hasProgressPerPeriod && <div className={css(styles.separator)} />}
                        <div className={css(layoutStyle.pRelative)}>
                            <Core.BarChart
                                data={dailyWeeksProgress}
                                height={BAR_CHART_HEIGHT}
                                isDaily={isDaily}
                                tooltipStyles={css(styles.tooltip, isDaily ? styles.dailyTooltip : styles.notDailyTooltip)}
                                dailyStateStyles={css(styles.notDailyTooltip, styles.pLeft)}
                                changeBarIndex={changeBarIndex}
                                unit={barChartUnit}
                                dayState={dayState}
                                hasProgressPerPeriod={hasProgressPerPeriod}
                                noActivityTitle={this.noActivityTitle}
                            />
                            { noActivityLabel() }
                            {!hasProgressPerPeriod && <div className={css(styles.bottomSeparator)} />}
                        </div>
                        <div className={css(styles.swiperContainer, layoutStyle.flex1, isDaily && styles.marginRightDaily)} style={this.marginRightNotDaily}>
                            <DatesSwiper
                                isWeekProgress={true}
                                weekProgress={weeksProgress[weekIndex]}
                                isShowTodayIndicator={false}
                                isShowIcons={!isTrackingImpossible}
                                challengeId={challengeId}
                                isProgress={true}
                            />
                        </div>
                    </Grid>
                </div>
                {isBonusChallenge ? (
                    <CompletionBonus
                        isBonusWithPoints={isBonusWithPoints}
                        challengeId={challengeId}
                        progress={challenge.progress}
                        isConnectedDevice={isConnectedDevice}
                        challengeType={challengeType}
                    />
                ) : null}
                <AppsDevices.ChallengeRecommendedDevices
                    recommendedTrackingApps={challenge.recommendedTrackingApps}
                    challenge={challenge}
                    history={history}
                />
            </Fragment>
        );
    }
}

export default withRouter(WithProgressBase(Progress));

const SWIPER_MARGIN_RIGHT_DAILY = 60;
const SWIPER_MARGIN_LEFT = 25;

const styles = StyleSheet.create({
    ...baseStyles,
    progressWrap: {
        marginTop: spacing.s7,
    },
    periodProgressMT: {
        marginTop: spacing.s5
    },
    progressResultLM: {
        marginLeft: spacing.s0
    },
    dailyTooltip: {
        ...appFonts.smMedium,
        color: baseColors.grey20,
    },
    notDailyTooltip: {
        ...appFonts.smRegular,
        color: baseColors.grey40,
    },
    tooltip: {
        padding: spacing.s1,
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
        borderRadius: spacing.s0,
        backgroundColor: baseColors.white
    },
    chartBlockWrap: {
        marginTop: spacing.s7,
        marginBottom: spacing.s7
    },
    swiperContainer: {
        marginTop: -spacing.s4,
        width: 'auto',
        marginLeft: SWIPER_MARGIN_LEFT,
    },
    marginRightDaily: {
        marginRight: SWIPER_MARGIN_RIGHT_DAILY
    },
    progressQuantityWrap: {
        ...baseStyles.progressQuantityWrap,
        marginBottom: spacing.s0
    },
    participantsContainer: {
        marginLeft: '33.33%',
        marginTop: spacing.s4
    },
    mLeft: {
        marginLeft: -spacing.s3,
    },
    mBottom: {
        marginBottom: -spacing.s2,
    },
    pLeft: {
        paddingLeft: spacing.s0,
    },
    noActivityContainer: {
        position: 'absolute',
        top: ACTIVITY_CONTAINER_TOP,
        left: spacing.s2,
        width: '100%',
        marginRight: spacing.s13,
        marginLeft: spacing.s0,
    },
    noActivityTitle: {
        textAlign: 'center',
        position: 'relative',
        ...appFonts.mdRegular,
        color: baseColors.grey40,
        paddingTop: spacing.s1,
        paddingBottom: spacing.s1,
        paddingRight: spacing.s2,
        paddingLeft: spacing.s2,
        width: 'fit-content',
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: baseColors.white,
        zIndex: 3,
    },
    backgroundLine: {
        backgroundColor: baseColors.grey80,
        justifyContent: 'center',
        position: 'absolute',
        width: BACKGROUND_LINE_WIDTH,
        height: 1,
        top: BACKGROUND_LINE_TOP,
        zIndex: 1
    },
});
