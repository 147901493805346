import _ from 'lodash';
import * as types from './actionTypes';
import * as api from './api';
import { selectors as coreSelectors, normalize, getRequestId, constants as coreConstants, actions as coreActions } from '../core';
import { actions as feedsActions, constants as feedsConstants } from '../feeds';
import { MAX_COUNT, TYPES } from './constants';
import { validateError } from '../../config';

export function getCommunities(params, isReplace = false) {
    return function (dispatch) {
        const { filter } = params;
        dispatch({ type: types.GET_COMMUNITIES.REQUEST });
        return api.getCommunities(params)
            .then(response => {
                let communitiesData;
                switch (filter) {
                    case TYPES.ATTENDED:
                        communitiesData = {
                            filter,
                            data: _.map(response.data.groups, item => _.omit(item, 'isInvitation')),
                            isReplace,
                            countField: 'numAttended',
                            count: response.data.numGroups
                        };
                        break;
                    case TYPES.INVITATIONS:
                        communitiesData = {
                            filter,
                            data: response.data.groups,
                            isReplace,
                            countField: 'numInvites',
                            count: response.data.numInvites
                        };
                        break;
                    case TYPES.ALL:
                        communitiesData = {
                            filter,
                            data: _.map(response.data.groups, item => _.omit(item, ['isInvitation'])),
                            isReplace,
                            countField: 'numAll',
                            count: response.data.numGroups
                        };
                        break;
                    default: break;
                }
                communitiesData.data = normalize.normalizeArray(communitiesData.data, 'id');
                dispatch({
                    type: types.GET_COMMUNITIES.SUCCESS,
                    payload: communitiesData
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.GET_COMMUNITIES.ERROR });
            }));
    };
}

export function updateNewCommunity(update) {
    return { type: types.UPDATE_NEW_COMMUNITY, payload: update };
}

export function updateCreateCommunityStep(step) {
    return {
        type: types.UPDATE_CREATE_COMMUNITY_STEP,
        payload: { step }
    };
}

export function getRecommendedCommunities() {
    return function (dispatch) {
        dispatch({ type: types.GET_COMMUNITIES.REQUEST });
        return api.getRecommendedCommunities()
            .then(response => {
                const communitiesData = {
                    filter: TYPES.RECOMMENDED,
                    data: _.map(response.data.recommended_communities, item => {
                        const newItem = {
                            ...item,
                            isRecommended: true,
                            numMembers: item.num_members,
                            numEvents: item.num_events
                        };
                        delete newItem.isInvitation;
                        return newItem;
                    }),
                    isReplace: true,
                    countField: 'numRecommended',
                    count: response.data.recommended_communities.length
                };
                communitiesData.data = normalize.normalizeArray(communitiesData.data, 'id');
                dispatch({
                    type: types.GET_COMMUNITIES.SUCCESS,
                    payload: communitiesData
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.GET_COMMUNITIES.ERROR });
            }));
    };
}

export function getRecentCommunities() {
    return function (dispatch) {
        dispatch({ type: types.GET_COMMUNITIES.REQUEST });
        return api.getRecentCommunities()
            .then(response => {
                const communitiesData = {
                    filter: TYPES.RECENT,
                    data: _.map(response.data.recent_communities, item => {
                        const newItem = {
                            ...item,
                            isRecent: true,
                            numMembers: item.num_members,
                            numEvents: item.num_events
                        };
                        delete newItem.isInvitation;
                        return newItem;
                    }),
                    isReplace: true,
                    countField: 'numRecent',
                    count: response.data.recent_communities.length
                };
                communitiesData.data = normalize.normalizeArray(communitiesData.data, 'id');
                dispatch({
                    type: types.GET_COMMUNITIES.SUCCESS,
                    payload: communitiesData
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.GET_COMMUNITIES.ERROR });
            }));
    };
}

export function getSingleCommunity(id) {
    return function (dispatch) {
        dispatch({ type: types.GET_SINGLE_COMMUNITY.REQUEST });
        return api.getSingleCommunity(id)
            .then(response => {
                const newItem = {
                    ...response.data,
                    members: response.data.groupMembers
                };
                delete newItem.groupMembers;
                dispatch({
                    type: types.GET_SINGLE_COMMUNITY.SUCCESS,
                    payload: { newItem, id }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.GET_SINGLE_COMMUNITY.ERROR });
            }));
    };
}

export function joinCommunity(id, isInvitation, actionType = 'joiningCommunity', msg) {
    return function (dispatch) {
        dispatch({ type: types.JOIN_COMMUNITY.REQUEST, payload: { requestId: getRequestId(types.JOIN_COMMUNITY.NAME, id) } });
        return api.joinCommunity(id)
            .then(response => {
                const data = {
                    ...response.data,
                    members: response.data.groupMembers
                };
                delete data.groupMembers;
                dispatch({
                    type: types.JOIN_COMMUNITY.SUCCESS,
                    payload: {
                        data: _.extend({}, data, { isMember: true, numEvents: 0 }),
                        toast: { actionType, msg },
                        requestId: getRequestId(types.JOIN_COMMUNITY.NAME, id),
                        isInvitation
                    }
                });
                dispatch(getCommunities({ filter: TYPES.ATTENDED, limit: MAX_COUNT, start: 0 }, true));
                dispatch(feedsActions.setStreamViewerMember(Number(id)));
                setTimeout(() => dispatch(coreActions.askUserForAppStoreReviewIfPossible()), coreConstants.APP_RATING_DELAY);
            })
            .catch(error => validateError(error, error => {
                dispatch({
                    type: types.JOIN_COMMUNITY.ERROR,
                    payload: {
                        error,
                        toast: { actionType },
                        requestId: getRequestId(types.JOIN_COMMUNITY.NAME, id),
                        isErrorStore: true
                    }
                });
            }));
    };
}

export function leaveCommunity(id, actionType = 'leavingCommunity') {
    return function (dispatch, getState) {
        const userId = coreSelectors.getCurrentUser(getState()).userId;
        dispatch({ type: types.LEAVE_COMMUNITY.REQUEST });
        return api.leaveCommunity(id)
            .then(response => {
                dispatch({
                    type: types.LEAVE_COMMUNITY.SUCCESS,
                    payload: {
                        id,
                        userId
                    }
                });
                dispatch(feedsActions.removeStreamViewerMember(Number(id)));
                dispatch(feedsActions.deleteStreamsForId(id, feedsConstants.STREAM_ENTITY_TYPES.group));
                dispatch(getCommunities({ filter: TYPES.ATTENDED, limit: MAX_COUNT, start: 0 }, true));
            })
            .catch(error => validateError(error, error => {
                dispatch({
                    type: types.LEAVE_COMMUNITY.ERROR,
                    payload: {
                        error,
                        toast: { actionType }
                    }
                });
            }));
    };
}

export function deleteCommunity(id, actionType = 'deletingCommunity', title) {
    return function (dispatch) {
        dispatch({ type: types.DELETE_COMMUNITY.REQUEST });
        return api.deleteCommunity(id)
            .then(response => {
                dispatch({
                    type: types.DELETE_COMMUNITY.SUCCESS,
                    payload: {
                        id,
                        toast: { actionType, title }
                    }
                });
                dispatch(feedsActions.deleteStreamsRelatedItemsDetails(id, feedsConstants.STREAM_ENTITY_TYPES.group));
            })
            .catch(error => validateError(error, error => {
                dispatch({
                    type: types.DELETE_COMMUNITY.ERROR,
                    payload: {
                        error,
                        toast: { actionType }
                    }
                });
            }));
    };
}

export function createCommunity(params) {
    const actionType = 'creatingCommunity';
    return function (dispatch) {
        dispatch({ type: types.CREATE_COMMUNITY.REQUEST });
        return api.createCommunity(params)
            .then(response => {
                dispatch({
                    type: types.CREATE_COMMUNITY.SUCCESS,
                    payload: {
                        data: _.extend({}, response.data, { isMember: true, numMembers: 1, numEvents: 0 }),
                        toast: { actionType },
                    }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({
                    type: types.CREATE_COMMUNITY.ERROR,
                    payload: {
                        error,
                        toast: { actionType }
                    }
                });
            }));
    };
}

export function editCommunity(params, id) {
    const actionType = 'editingCommunity';
    return function (dispatch) {
        dispatch({ type: types.EDIT_COMMUNITY.REQUEST });
        return api.editCommunity(params, id)
            .then(response => {
                dispatch({
                    type: types.EDIT_COMMUNITY.SUCCESS,
                    payload: {
                        data: response.data,
                        toast: { actionType },
                    }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({
                    type: types.EDIT_COMMUNITY.ERROR,
                    payload: {
                        error,
                        toast: { actionType }
                    }
                });
            }));
    };
}

export function getCommunityMembers(communityId, params) {
    return function (dispatch) {
        dispatch({ type: types.GET_COMMUNITY_MEMBERS.REQUEST });
        return api.getCommunityMembers(communityId, params)
            .then(response => {
                dispatch({
                    type: types.GET_COMMUNITY_MEMBERS.SUCCESS,
                    payload: {
                        communityId,
                        data: normalize.normalizeArray(_.get(response.data, 'groupMembers', []), 'userId'),
                        isReplace: !_.get(params, 'start')
                    }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({
                    type: types.GET_COMMUNITY_MEMBERS.ERROR,
                    payload: { error }
                });
            }));
    };
}
export function hideCommunityNotification(id) {
    return function (dispatch) {
        dispatch({
            type: types.EDIT_COMMUNITY.SUCCESS,
            payload: {
                data: { id, isNotificationShown: false },
                isReplace: false
            }
        });
    };
}
