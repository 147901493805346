import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';
import { components as Core, openUrl, onKeyPress } from '../../../../core';
import { layoutStyle, important, importantClass } from '../../../../../styles';
import WithFeedItemContentBase, { styles as baseStyles, NUMBER_OF_LINES } from './FeedItemContentBase';

class FeedItemContent extends PureComponent {
    static propTypes = {
        children: PropTypes.node,
        content: PropTypes.object,
        embeddedMedia: PropTypes.string,
        isVideo: PropTypes.bool,
        image: PropTypes.string
    };

    static defaultProps = {
        content: {},
        image: undefined,
        isVideo: false,
        embeddedMedia: undefined,
        children: undefined
    };

    openMedia = () => {
        if (this.props.content.link) openUrl(this.props.content.link);
    };

    get media() {
        const { isVideo, embeddedMedia, content, image } = this.props;
        if (isVideo && embeddedMedia) {
            return <Core.InnerHtml htmlText={embeddedMedia} />;
        } else if (!isVideo && image) {
            return (
                <Core.Image
                    isFadeIn={true}
                    alt={content.link}
                    src={image}
                    className={css(styles.cardImage, layoutStyle.noCursor)}
                />
            );
        } return null;
    }

    render() {
        const { children, content } = this.props;
        return (
            <div>
                {content.userText ? (
                    <Core.CardContent classes={{
                        root: css(styles.cardContent)
                    }}>
                        <Core.TextSeeMore
                            numberOfLines={NUMBER_OF_LINES}
                            className={css(styles.cardText, layoutStyle.textLeft)}
                            isExpandHidden={false}>
                            <Core.AutoLink text={content.userText} />
                        </Core.TextSeeMore>
                    </Core.CardContent>
                ) : null}
                <div
                    tabIndex="0"
                    role="button"
                    onClick={this.openMedia}
                    onKeyDown={onKeyPress.enter(this.openMedia)}
                    className={css(layoutStyle.db)}>
                    {this.media}
                    {content.title ? (
                        <Core.CardContent className={css(styles.cardBottomContent)}>
                            <p className={css(styles.contentDescription)}>{content.title}</p>
                            {content.link ? (
                                <div className={css(styles.contentLink)}><Core.AutoLink text={content.link} /></div>
                            ) : null}
                        </Core.CardContent>
                    ) : null}
                    {children}
                </div>
            </div>
        );
    }
}

export default WithFeedItemContentBase(FeedItemContent);

export const styles = StyleSheet.create({
    ...baseStyles,
    cardImage: importantClass({
        width: '100%',
        display: 'block',
        borderRadius: 0
    }),
    cardBottomContent: {
        textAlign: 'left'
    },
    cardContent: {
        paddingTop: important(0)
    },
    cardText: {
        ...baseStyles.cardText,
        whiteSpace: 'pre-line',
    },
});
