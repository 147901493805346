import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { baseColors, spacing, appFonts, windowSize } from '../../../../styles';
import { translate } from '../../../core';
import { QUESTION_TITLES } from '../../constants';

export const ICON_SIZE = 64;
export const HELPER_ICON_SIZE = spacing.s4;
export const APPLE_ICON = 'apple';

export default function WithChoiceQuestionBase(WrappedComponent) {
    class ChoiceQuestionBase extends PureComponent {
        static propTypes = {
            callback: PropTypes.func,
            question: PropTypes.object.isRequired,
            onClick: PropTypes.func.isRequired,
            answer: PropTypes.number,
            helperText: PropTypes.string,
            i18n: PropTypes.object.isRequired,
            isSavingAnswer: PropTypes.bool,
            isSettingUserTaskComplete: PropTypes.bool,
        };

        static defaultProps = {
            callback: null,
            answer: null,
            helperText: '',
            isSavingAnswer: false,
            isSettingUserTaskComplete: false,
        };

        constructor(props) {
            super(props);
            this.state = {
                errorText: '',
                chosenAnswerId: -1
            };
        }

        onNext = () => {
            if (this.state.chosenAnswerId > -1) {
                this.setState({ errorText: '', chosenAnswerId: -1 });
            }
            else {
                this.setState({ errorText: this.props.i18n.t('wellbeing.survey.requiredField') });
            }
        };

        onOptionClick = id => () => {
            if (this.props.isSavingAnswer || this.props.isSettingUserTaskComplete) return;
            this.setState({ disabledId: id }, () => this.props.onClick ? this.props.onClick(id) : this.submitData(id));
        };

        get helperText() {
            const { question } = this.props;
            if (question.title === QUESTION_TITLES.timeSitting) {
                return {
                    title: this.props.i18n.t('timeSittingInformation.title'),
                    list: this.props.i18n.t('timeSittingInformation.list'),
                };
            } else if (question.title === QUESTION_TITLES.activityLevel) {
                return {
                    title: this.props.i18n.t('activityLevelInformation.title'),
                    list: this.props.i18n.t('activityLevelInformation.list'),
                };
            }
            return null;
        }

        getGuideModalProps = () => {
            const { i18n } = this.props;
            const title = this.helperText ? this.helperText.title : '';

            return {
                iconName: 'question-circle',
                title,
                buttonTitle: i18n.t('got_it'),
                isButtonVisible: false
            };
        };

        get questionOptions() {
            return _.get(this.props.question, 'options');
        }

        submitData = answer => this.setState({ chosenAnswerId: answer });

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    errorText={this.state.errorText}
                    getGuideModalProps={this.getGuideModalProps}
                    onNext={this.onNext}
                    helperText={this.helperText}
                    onOptionClick={this.onOptionClick}
                    questionOptions={this.questionOptions}
                    disabledId={this.state.disabledId}
                />
            );
        }
    }

    return translate()(ChoiceQuestionBase);
}

const MIN_TEXT_AREA_HEIGHT = windowSize.height * 0.35;

export const styles = {
    textContainer: {
        flex: 1,
        minHeight: MIN_TEXT_AREA_HEIGHT
    },
    header: {
        flexDirection: 'row',
        marginTop: spacing.s5,
        alignSelf: 'center'
    },
    questionText: {
        ...appFonts.lgMedium,
        color: baseColors.grey20,
        textAlign: 'center',
        marginBottom: spacing.s3,
        maxWidth: '100%'
    },
    errorText: {
        ...appFonts.xsRegular,
        color: baseColors.dangerDarker
    },
    guidelines: {
        flexDirection: 'row',
        alignSelf: 'center',
        alignItems: 'center',
        color: baseColors.secondary
    },
    icon: {
        alignSelf: 'center',
        marginBottom: spacing.s3
    },
    option: {
        textAlign: 'center',
        marginTop: spacing.s3,
        minHeight: (spacing.s12 - 2),
        height: 'auto'
    },
    scoreMeaning: {
        ...appFonts.lgMedium,
        textAlign: 'right',
        paddingLeft: spacing.s1,
        color: baseColors.secondary
    },
    helperTextIcon: {
        textAlign: 'left',
        color: baseColors.secondary
    },
    explanation: {
        ...appFonts.smRegular,
        marginTop: spacing.s3,
    },
};
