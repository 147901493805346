import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { spacing, importantClass, layoutStyle, baseColors } from '../../../../styles';
import WithNotificationItemBase, { styles as baseStyles, IMAGE_SIZE } from './NotificationItemBase';
import Avatar from '../../../core/components/Avatar';

class NotificationItem extends PureComponent {
    static propTypes = {
        text: PropTypes.array,
        info: PropTypes.object,
        hasBorderRadius: PropTypes.bool,
        clicked: PropTypes.bool.isRequired,
        isImageSvg: PropTypes.bool.isRequired,
        getFormattedDate: PropTypes.func.isRequired,
        onNotificationPress: PropTypes.func.isRequired,
        notificationImage: PropTypes.string.isRequired,
        notificationUserName: PropTypes.string.isRequired
    };

    static defaultProps = {
        hasBorderRadius: false,
        text: [],
        info: {}
    };

    render() {
        const { info, text, getFormattedDate, onNotificationPress, clicked, hasBorderRadius, notificationImage, notificationUserName, isImageSvg } = this.props;

        const imageClasses = { img: isImageSvg ? css(styles.imgSvg) : null };

        return (
            <button className={css(styles.cardContainer, layoutStyle.flexAlignStart)} onClick={onNotificationPress}>
                <Avatar
                    url={notificationImage}
                    className={css(styles.image, hasBorderRadius ? styles.roundedImage : null)}
                    disablePress={true}
                    name={notificationUserName}
                    isAvatarContainerDisabled={true}
                    classes={imageClasses}
                />
                <section className={css(styles.card)}>
                    <div className={css(styles.cardInner)}>
                        <p className={css(styles.text, clicked ? styles.openedText : null)}>
                            {text}
                        </p>
                        <p className={css(styles.date)}>
                            {getFormattedDate(info.dateTime)}
                        </p>
                    </div>
                </section>
                <div className={css(styles.circle, !clicked ? styles.unreadCircle : null)} />
            </button>
        );
    }
}

const styles = StyleSheet.create({
    ...baseStyles,
    cardContainer: {
        display: 'flex',
        cursor: 'pointer',
        paddingTop: spacing.s4,
        clear: 'both',
        width: '100%',
        paddingRight: spacing.s3,
        paddingLeft: spacing.s3,
        '&:hover': {
            backgroundColor: baseColors.grey90
        },
    },
    card: {
        paddingBottom: spacing.s2,
        maxWidth: '100%',
        width: '100%'
    },
    circle: {
        ...baseStyles.circle,
        flexShrink: 0
    },
    image: importantClass({
        ...baseStyles.image,
        minWidth: IMAGE_SIZE,
        minHeight: IMAGE_SIZE,
        marginBottom: spacing.s3,
        borderRadius: 0
    }),
    imgSvg: importantClass({
        objectFit: 'contain'
    }),
    cardInner: {
        textAlign: 'left'
    },
    text: {
        ...baseStyles.text,
        padding: `0 ${spacing.s1}px`
    },
    date: {
        ...baseStyles.date,
        paddingLeft: spacing.s1,
    },
    roundedImage: importantClass({
        borderRadius: '50%',
        border: '0.5px solid #BBBBBB',
    })
});

export default WithNotificationItemBase(NotificationItem);