import fontDetails from './fontDetails';
import fontFamilies from './fontFamilies';

// app fonts (public)
const appFonts = {
    xxsRegular: { ...fontDetails.xxSmall, ...fontFamilies.regular },
    xsRegular: { ...fontDetails.xSmall, ...fontFamilies.regular },
    smRegular: { ...fontDetails.small, ...fontFamilies.regular },
    mdRegular: { ...fontDetails.medium, ...fontFamilies.regular },
    lgRegular: { ...fontDetails.large, ...fontFamilies.regular },
    lineXlRegular: { ...fontDetails.xLineLarge, ...fontFamilies.regular },
    xlRegular: { ...fontDetails.xLarge, ...fontFamilies.regular },
    xxlRegular: { ...fontDetails.xxLarge, ...fontFamilies.regular },
    xxxlRegular: { ...fontDetails.xxxLarge, ...fontFamilies.regular },
    xxxxlRegular: { ...fontDetails.xxxxLarge, ...fontFamilies.regular },

    xxsMedium: { ...fontDetails.xxSmall, ...fontFamilies.medium },
    xsMedium: { ...fontDetails.xSmall, ...fontFamilies.medium },
    smMedium: { ...fontDetails.small, ...fontFamilies.medium },
    mdMedium: { ...fontDetails.medium, ...fontFamilies.medium },
    lgMedium: { ...fontDetails.large, ...fontFamilies.medium },
    xlMedium: { ...fontDetails.xLarge, ...fontFamilies.medium },
    xxlMedium: { ...fontDetails.xxLarge, ...fontFamilies.medium },
    xxxlMedium: { ...fontDetails.xxxLarge, ...fontFamilies.medium },
    xxxxlMedium: { ...fontDetails.xxxLarge, ...fontFamilies.medium },

    xxsBold: { ...fontDetails.xxSmall, ...fontFamilies.bold },
    xsBold: { ...fontDetails.xSmall, ...fontFamilies.bold },
    smBold: { ...fontDetails.small, ...fontFamilies.bold },
    mdBold: { ...fontDetails.medium, ...fontFamilies.bold },
    lgBold: { ...fontDetails.large, ...fontFamilies.bold },
    xlBold: { ...fontDetails.xLarge, ...fontFamilies.bold },
    xxlBold: { ...fontDetails.xxLarge, ...fontFamilies.bold },
    xxxlBold: { ...fontDetails.xxxLarge, ...fontFamilies.bold },
    xxxxlBold: { ...fontDetails.xxxxLarge, ...fontFamilies.bold },

    programName: { fontSize: 52, ...fontFamilies.regular }
};

export default appFonts;