import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { css, StyleSheet } from 'aphrodite-jss';
import classnames from 'classnames';
import { baseColors, spacing, media, importantStyles, commonPadding, layoutStyle, appFonts } from '../../../../styles';
import ViewAllButton from '../ViewAllButton';
import SubHeader from '../SubHeader';
import translate from '../../services/translate';
import { CSS_CLASSES, CAROUSEL_TYPES } from '../../constants';
import CarouserItemLoadingSkeleton from '../web/CarouserItemLoadingSkeleton';
import Button from '../Button';
import Icon from '../Icon';
import { components as Core } from '../../indexBase';
import { AUTOPLAY_INTERVAL } from '../../../content/constants';

const COMMON_SETTINGS = {
    dots: true,
    infinite: false,
    speed: 500,
    initialSlide: 0,
    className: CSS_CLASSES.customCarousel,
    // dotsClass: '',
    lazyLoad: 'progressive',
};

const TYPES_CONFIG = (isModal, isLibraryPage, itemsCount) => {
    let showMultiplyItemsCount = 4;
    if (isModal) showMultiplyItemsCount = 2;
    if (isLibraryPage) showMultiplyItemsCount = 3;
    if (itemsCount) showMultiplyItemsCount = itemsCount;
    return {
        [CAROUSEL_TYPES.single]: {
            ...COMMON_SETTINGS,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            dots: true
        },
        [CAROUSEL_TYPES.singleCenter]: {
            ...COMMON_SETTINGS,
            className: 'center',
            centerMode: true,
            centerPadding: '27%',
            slidesToShow: 1,
            arrows: false,
            dots: false
        },
        [CAROUSEL_TYPES.multipleForLibrary]: {
            ...COMMON_SETTINGS,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
            arrows: true,
            dots: true,
            autoplay: true,
            autoplaySpeed: AUTOPLAY_INTERVAL,
        },
        [CAROUSEL_TYPES.multiple]: {
            ...COMMON_SETTINGS,
            lazyLoad: false,
            infinite: false,
            speed: 500,
            slidesToShow: showMultiplyItemsCount,
            slidesToScroll: 1,
            initialSlide: 0,
            focusOnSelect: true,
            arrows: true,
            dots: true,
            responsive: [
                {
                    breakpoint: media.values.lg,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: media.values.md,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: media.values.sm,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ],
            accessibility: true,
        },
        [CAROUSEL_TYPES.twoOnly]: {
            ...COMMON_SETTINGS,
            lazyLoad: false,
            infinite: false,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 0,
            focusOnSelect: true,
            arrows: true,
            dots: false,
            responsive: [
                {
                    breakpoint: media.values.md,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ],
            accessibility: true,
        },
        [CAROUSEL_TYPES.threeOnly]: {
            ...COMMON_SETTINGS,
            lazyLoad: false,
            slidesToShow: 3,
            slidesToScroll: 3,
            arrows: true,
            dots: false,
            responsive: [
                {
                    breakpoint: media.values.md,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                }
            ],
            accessibility: true,
        },
        [CAROUSEL_TYPES.goalsCarousel]: {
            ...COMMON_SETTINGS,
            lazyLoad: false,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 0,
            focusOnSelect: true,
            arrows: true,
            dots: true,
            responsive: [
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 2,
                        infinite: false
                    }
                }
            ],
            accessibility: true,
        }
    };
};

const CustomCarouselPrevButton = ({ onClick }) => (
    <Button
        onPress={onClick}
        type="outlined"
        size={Button.SIZES.small}
        className={css(styles.customButton)}>
        <Icon
            name="angle-left"
            type="fa"
            size={spacing.s3}
            color={baseColors.grey50}
        />
    </Button>
);
CustomCarouselPrevButton.propTypes = {
    onClick: PropTypes.func.isRequired
};

const CustomCarouselNextButton = ({ onClick }) => (
    <Button
        onPress={onClick}
        type="outlined"
        size={Button.SIZES.small}
        className={css(styles.customButton)}>
        <Icon
            name="angle-right"
            type="fa"
            size={spacing.s3}
            color={baseColors.grey50}
        />
    </Button>
);
CustomCarouselNextButton.propTypes = {
    onClick: PropTypes.func.isRequired
};

class Carousel extends PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        type: PropTypes.oneOf(_.values(CAROUSEL_TYPES)),
        title: PropTypes.string,
        i18n: PropTypes.object.isRequired,
        count: PropTypes.number,
        viewAllPress: PropTypes.func,
        updateSliding: PropTypes.func,
        beforeChange: PropTypes.func,
        afterChange: PropTypes.func,
        parent: PropTypes.object,
        isBorderShown: PropTypes.bool,
        onRef: PropTypes.func,
        titleSize: PropTypes.oneOf(_.values(SubHeader.SIZES)),
        isLoading: PropTypes.bool,
        itemsLength: PropTypes.number,
        isTopAlignedDotsAndArrows: PropTypes.bool,
        hasCustomArrows: PropTypes.bool,
        className: PropTypes.string,
        dotsClassName: PropTypes.string,
        isModal: PropTypes.bool,
        goalsChallengesSlider: PropTypes.bool,
        isFeaturedSection: PropTypes.bool,
        noBottomMargin: PropTypes.bool,
        activeSlide: PropTypes.number,
        isLibraryPage: PropTypes.bool,
        arrowsColor: PropTypes.string,
        infoText: PropTypes.string,
        headerContainerClassName: PropTypes.string,
        carouselContainerClassName: PropTypes.string,
        itemsCount: PropTypes.number,
        slidesToScrollValue: PropTypes.number,
        lastPageWithEmptySpace: PropTypes.bool
    };

    static defaultProps = {
        titleSize: SubHeader.SIZES.xlarge,
        type: CAROUSEL_TYPES.single,
        dotsClassName: '',
        title: undefined,
        count: undefined,
        viewAllPress: undefined,
        updateSliding: undefined,
        beforeChange: undefined,
        afterChange: undefined,
        parent: undefined,
        isBorderShown: true,
        onRef: undefined,
        isLoading: false,
        itemsLength: 0,
        isTopAlignedDotsAndArrows: false,
        hasCustomArrows: false,
        className: undefined,
        isModal: false,
        goalsChallengesSlider: false,
        isFeaturedSection: false,
        noBottomMargin: false,
        activeSlide: 0,
        isLibraryPage: false,
        arrowsColor: baseColors.secondary,
        infoText: null,
        headerContainerClassName: null,
        carouselContainerClassName: null,
        itemsCount: null,
        slidesToScrollValue: null,
        lastPageWithEmptySpace: true
    };

    onRef = ref => {
        if (this.props.onRef) {
            this.props.onRef(ref);
        }
        this.carouselRef = ref;
    };

    beforeChange = (...params) => {
        this.props.beforeChange && this.props.beforeChange(...params);
        this.updateSliding(true);
    };

    afterChange = (...params) => {
        this.props.afterChange && this.props.afterChange(...params);
        this.updateSliding(false);
    };

    /**
     * Method is used to track when carousel exactly is sliding (for parent to disable slide clicks on swiping)
     * To not listen beforeChange/afterChange in every place it is better to set parent state here
     */
    updateSliding = isSliding => {
        this.props.parent && this.props.parent.setState({ isSliding });
    };

    get generateLoadingSkeleton() {
        const { type, isModal } = this.props;
        const twoItemsSceleton = (
            <div className={css(styles.loadingItemsContainer)}>
                <CarouserItemLoadingSkeleton isHalfWidth={true} />
                <CarouserItemLoadingSkeleton isHalfWidth={true} />
            </div>
        );
        switch (type) {
            case CAROUSEL_TYPES.multiple:
                return isModal ? twoItemsSceleton : (
                    <div className={css(styles.loadingItemsContainer)}>
                        <CarouserItemLoadingSkeleton />
                        <CarouserItemLoadingSkeleton />
                        <CarouserItemLoadingSkeleton />
                        <CarouserItemLoadingSkeleton />
                    </div>
                );
            case CAROUSEL_TYPES.twoOnly:
                return twoItemsSceleton;
            case CAROUSEL_TYPES.multipleForLibrary:
                return (
                    <Core.ListLoading className={css(styles.loadingContainer)} />
                );
            default:
                return (
                    <div className={css(styles.loadingItemsContainer)}>
                        <CarouserItemLoadingSkeleton />
                    </div>
                );
        }
    }

    onClickNext = () => {
        if (this.carouselRef) this.carouselRef.slickNext();
    };

    onClickPrev = () => {
        if (this.carouselRef) this.carouselRef.slickPrev();
    };

    get allCarouselProps() {
        const { type, isModal, isLibraryPage, itemsCount, ...props } = this.props;
        return type ? { ...TYPES_CONFIG(isModal, isLibraryPage, itemsCount)[type], ...props } : props;
    }

    get libraryPageButtonLeft() {
        return (
            <Button
                id="carouselBackButtonLibrary"
                onPress={this.onClickPrev}
                type="outlined"
                size={Button.SIZES.small}
                className={css(layoutStyle.createEntityButton, styles.paginationPrevButton, styles.libraryPageNav)}
                activeOpacity={1}
                color="baseColors.white"
                isFadeInText={false}
                hiddenMainText={true}>
                <Icon
                    name="angle-left"
                    type="fa"
                    fill="regular"
                    size={spacing.s3}
                    color={baseColors.white}
                />
            </Button>
        );
    }

    get libraryPageButtonRight() {
        return (
            <Button
                id="carouselNextButtonLibrary"
                onPress={this.onClickNext}
                type="raised"
                size={Button.SIZES.small}
                className={css(layoutStyle.createEntityButton, styles.paginationNextButton, styles.libraryPageNav)}>
                <Icon
                    name="angle-right"
                    type="fa"
                    fill="regular"
                    size={spacing.s3}
                    color={baseColors.white}
                />
            </Button>
        );
    }

    renderDots = dots => {
        const { i18n, goalsChallengesSlider, isFeaturedSection, arrowsColor, isLibraryPage, isInvitesSection } = this.props;
        const isLeftArrowDisabled = (goalsChallengesSlider || isFeaturedSection || isInvitesSection)
            && dots[0].props.className === CSS_CLASSES.slickActive;
        const isRightArrowDisabled = (goalsChallengesSlider || isFeaturedSection || isInvitesSection)
            && dots[dots.length - 1].props.className === CSS_CLASSES.slickActive;
        return (
            <div>
                {this.allCarouselProps.arrows && (
                    (!isLibraryPage ? (
                        <Button
                            id="carouselBackButton"
                            onPress={this.onClickPrev}
                            type="outlined"
                            size={Button.SIZES.small}
                            tabIndex={isLeftArrowDisabled ? '-1' : '0'}
                            disabled={isLeftArrowDisabled}
                            className={css(layoutStyle.createEntityButton, styles.paginationPrevButton)}>
                            <Icon
                                name="angle-left"
                                type="fa"
                                size={spacing.s3}
                                color={isLeftArrowDisabled ? baseColors.grey80 : arrowsColor}
                            />
                            <span>{i18n.t('back')}</span>
                        </Button>
                    ) : this.libraryPageButtonLeft)
                )}
                <ul className={css(styles.dots)}>
                    {
                        _.map(dots, (elem, index) => (
                            <li {...elem.props}>
                                <button {...elem.props.children.props} tabIndex="0">
                                    {index}
                                </button>
                            </li>
                        )
                        )
                    }
                </ul>
                {this.allCarouselProps.arrows && (
                    (!isLibraryPage ? (
                        <Button
                            id="carouselNextButton"
                            onPress={this.onClickNext}
                            type="outlined"
                            size={Button.SIZES.small}
                            disabled={isRightArrowDisabled}
                            tabIndex={isRightArrowDisabled ? '-1' : '0'}
                            className={css(layoutStyle.createEntityButton, styles.paginationNextButton)}>
                            <span>{i18n.t('next')}</span>
                            <Icon
                                name="angle-right"
                                type="fa"
                                size={spacing.s3}
                                color={isRightArrowDisabled ? baseColors.grey80 : arrowsColor}
                            />
                        </Button>
                    ) : this.libraryPageButtonRight)
                )}
            </div>
        );
    };

    get slidesToScroll() {
        return this.props?.slidesToScrollValue || this.allCarouselProps.slidesToScroll;
    }

    get emptyElements() {
        const emptyCount = this.props.itemsLength % this.slidesToScroll;
        const newArray = _.times(emptyCount).map(elem => ({ id: elem }));
        return newArray?.length ? newArray.map(elem => <div key={elem.id} />) : null;
    }

    get generateCarouselContent() {
        const { children, type, isBorderShown, isLoading, itemsLength, isTopAlignedDotsAndArrows, noBottomMargin,
            className, hasCustomArrows, goalsChallengesSlider, isFeaturedSection, dotsClassName, activeSlide, isLibraryPage,
            carouselContainerClassName, noMargin, focusOnSelect, lastPageWithEmptySpace } = this.props;
        if (isLoading) {
            return this.generateLoadingSkeleton;
        }
        const borderShown = type === CAROUSEL_TYPES.multiple ? isBorderShown : false;
        const isInfinite = (goalsChallengesSlider || isFeaturedSection)
            ? false
            : (this.allCarouselProps.arrows && this.allCarouselProps.slidesToShow <= itemsLength && !hasCustomArrows) || isLibraryPage;
        return (
            <div className={classnames(css(
                styles.carouselWrapper,
                borderShown && styles.carouselWrapperBorder,
                (isFeaturedSection || noBottomMargin) && styles.noBottomMargin,
            ), noMargin && 'no-margin', carouselContainerClassName)}>
                {!isLoading && !itemsLength ?
                    children : (
                        <Slider
                            {...this.allCarouselProps}
                            initialSlide={activeSlide}
                            focusOnSelect={focusOnSelect}
                            className={classnames(className, hasCustomArrows ? css(styles.customCarouselWrapper) : undefined)}
                            ref={this.onRef}
                            afterChange={this.afterChange}
                            beforeChange={this.beforeChange}
                            arrows={hasCustomArrows}
                            prevArrow={<CustomCarouselPrevButton />}
                            nextArrow={<CustomCarouselNextButton />}
                            appendDots={this.renderDots}
                            dotsClass={classnames(
                                'slick-dots',
                                isLibraryPage ? 'slick-dots-white' : null,
                                css(styles.paginationContainer),
                                isTopAlignedDotsAndArrows ? css(styles.topDotsArrowsCarousel) : null,
                                css(dotsClassName)
                            )}
                            infinite={isInfinite}
                            slidesToScroll={this.slidesToScroll}>
                            {children}
                            {lastPageWithEmptySpace ? this.emptyElements : null}
                        </Slider>
                    )}
            </div>
        );
    }

    get infoSection() {
        const { infoText } = this.props;
        return infoText ? (
            <span className={css(styles.infoSection)}>
                {infoText}
            </span>
        ) : null;
    }

    render() {
        const { title, titleSize, viewAllPress, isLoading, viewAllText, headerContainerClassName } = this.props;
        return (
            <React.Fragment>
                {(title || viewAllPress) ? (
                    <SubHeader
                        titleSize={titleSize}
                        title={title ? _.capitalize(title) : null}
                        noLeftPadding={true}
                        noTopPadding={true}
                        className={(css(styles.carouselHeader), headerContainerClassName)}
                        right={!isLoading && viewAllPress ? (
                            <ViewAllButton
                                text={viewAllText}
                                onPress={viewAllPress}
                            />
                        ) : null}
                    />
                ) : null}
                {this.infoSection}
                {this.generateCarouselContent}
            </React.Fragment>
        );
    }
}

export default translate()(Carousel);

const LOADING_CONTAINER_HEIGHT = 380;

const styles = StyleSheet.create(importantStyles({
    titleWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: commonPadding,
    },
    carouselWrapper: {
        paddingBottom: spacing.s2,
        marginBottom: spacing.s1,
    },
    carouselWrapperBorder: {
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: baseColors.grey80,
    },
    customCarouselWrapper: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    loadingItemsContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    paginationPrevButton: {
        paddingLeft: spacing.s1
    },
    libraryPageNav: {
        '&:hover': {
            color: 'transparent',
            backgroundColor: 'transparent'
        },
        '&:active': {
            color: 'transparent'
        },
        '&:focus': {
            color: 'transparent',
        },
    },
    paginationNextButton: {
        paddingRight: spacing.s1
    },
    paginationSpacerWithDots: {
        height: spacing.s1
    },
    paginationSpacer: {
        height: spacing.s8
    },
    paginationContainer: {
        display: 'flex',
        justifyContent: 'center',
        position: 'static',
        paddingTop: spacing.s1
    },
    dots: {
        padding: `0 ${spacing.s5}px`,
        marginBottom: 0
    },
    topDotsArrowsCarousel: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: 'fit-content',
        maxHeight: spacing.s9,
        width: '100% !important'
    },
    customButton: {
        borderColor: baseColors.grey70,
        padding: 0,
        width: spacing.s4,
        height: spacing.s4,
        minWidth: spacing.s4,
        minHeight: spacing.s4,
        marginTop: 10
    },
    carouselHeader: {
        paddingBottom: 0,
        paddingLeft: spacing.s1
    },
    noBottomMargin: {
        marginBottom: 0,
    },
    loadingContainer: {
        height: LOADING_CONTAINER_HEIGHT,
        display: 'flex',
        justifyContent: 'center',
    },
    infoSection: {
        ...appFonts.mdRegular,
        color: baseColors.grey40,
        marginBottom: spacing.s5,
        marginRight: spacing.s3,
        marginLeft: spacing.s3
    }
}));
