import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import _ from 'lodash';
import classnames from 'classnames';
import withTrackActivityFormBase, { styles as baseStyles, INPUT_VALUE_MAX_LENGTH } from './TrackActivityFormBase';
import { AsyncComponent, components as Core, CSS_CLASSES, Modal, onKeyPress } from '../../../core';
import { baseColors, importantStyles, layoutStyle, spacing, appFonts, windowSize } from '../../../../styles';
import ActivityLogModal from '../ActivityLogModal';

const DATEPICKER_MIN_HEIGHT = 718;
const COMMON_MODAL_MARGIN = 48;
const DATEPICKER_MIN_HEIGHT_WITH_MARGIN = DATEPICKER_MIN_HEIGHT + COMMON_MODAL_MARGIN * 2;

class TrackActivityForm extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool,
        close: PropTypes.func.isRequired,
        i18n: PropTypes.object.isRequired,
        selectedActivity: PropTypes.object,
        inputUnit: PropTypes.string.isRequired,
        checkboxes: PropTypes.array.isRequired,
        onCloseModal: PropTypes.func.isRequired,
        datesTitle: PropTypes.string.isRequired,
        hasAnyValues: PropTypes.bool.isRequired,
        warningText: PropTypes.string.isRequired,
        onCheckboxPress: PropTypes.func.isRequired,
        pickDatesText: PropTypes.string.isRequired,
        checkedOffDates: PropTypes.array.isRequired,
        onSelectActivity: PropTypes.func.isRequired,
        selectFieldName: PropTypes.string.isRequired,
        shouldShowWarning: PropTypes.func.isRequired,
        onChangeInputValue: PropTypes.func.isRequired,
        selectFieldValue: PropTypes.string.isRequired,
        bottomButtonText: PropTypes.string.isRequired,
        onDatepickerSubmit: PropTypes.func.isRequired,
        trackActivityTitle: PropTypes.string.isRequired,
        chooseActivityTitle: PropTypes.string.isRequired,
        prepareAndTrackActivity: PropTypes.func.isRequired,
    };

    static defaultProps = {
        selectedActivity: null,
        isLoading: false,
    };

    constructor(props) {
        super(props);
        this.showChooseActivityList();
        this.state = { isVisible: false };
    }

    showChooseActivityList = () => {
        const { onSelectActivity, chooseActivityTitle, selectedActivity } = this.props;
        Modal.open(
            AsyncComponent(() => import('../ChooseActivityList')),
            {
                isModal: true,
                onSelectActivity,
                selectedActivity,
                chooseActivityTitle
            },
            {
                isNoPadding: true,
                isContainer: true,
                isMaxHeight: true,
                fadeTransition: true,
                closeAllOnClick: !selectedActivity,
            },
        );
    };

    dismissModal = () => {
        this.props.close();
    };

    getCheckboxLabel = (label, subLabel, isSubLabelHidden) => (
        <p>
            <span className={css(styles.checkboxLabel)}>{label}</span>
            <span className={css(styles.checkboxSubLabel)}>{!isSubLabelHidden && subLabel}</span>
        </p>
    );

    renderCheckboxWithInput = checkbox => {
        const { onCheckboxPress, onChangeInputValue, i18n, selectFieldValue, inputUnit, shouldShowWarning } = this.props;
        const { label, dateString, value, dateStringHidden, checked } = checkbox;
        return (
            <Core.CheckboxWithInput
                value={value}
                checked={checked}
                inputUnit={inputUnit}
                onPress={onCheckboxPress(dateString)}
                onKeyDown={onKeyPress.enter(onCheckboxPress(dateString))}
                onChangeText={onChangeInputValue(dateString)}
                inputHelper={shouldShowWarning(checkbox) && this.warning}
                label={this.getCheckboxLabel(label, dateString, dateStringHidden)}
                ariaDescription={(
                    <p className={css(layoutStyle.hidden)} id="input-description">
                        {i18n.t('trackActivity.inputDescription', { activity: selectFieldValue, date: dateString })}
                    </p>
                )}
                inputProps={{
                    'aria-describedby': 'input-description',
                    maxLength: INPUT_VALUE_MAX_LENGTH,
                }}
            />
        );
    };

    isHeightEnoughForDatepicker = () => windowSize.height >= DATEPICKER_MIN_HEIGHT_WITH_MARGIN;

    openDatepicker = () => {
        const { checkedOffDates, onDatepickerSubmit } = this.props;
        Modal.open(
            AsyncComponent(() => import('../TrackActivityDatepicker')),
            {
                onDatepickerSubmit,
                checkedOffDates,
                isModal: true,
            },
            {
                scroll: this.isHeightEnoughForDatepicker() ? 'paper' : 'body',
                isMaxWidthLimited: true,
                isContainer: true,
                isMaxHeight: true,
                fadeTransition: true,
                minHeight: DATEPICKER_MIN_HEIGHT,
            },
        );
    };

    get warning() {
        const { warningText } = this.props;
        return (
            <div className={css(styles.warningWrapper)}>
                <Core.Icon
                    type="fa"
                    fill="solid"
                    size={spacing.s3}
                    color={baseColors.warn}
                    name="exclamation-triangle"
                />
                <p className={css(styles.warningText)}>{warningText}</p>
            </div>
        );
    }

    get subheader() {
        const { trackActivityTitle, onCloseModal } = this.props;
        return (
            <div className={css(styles.subheader)}>
                <span />
                <p className={css(styles.title)}>{trackActivityTitle}</p>
                <div className={css(styles.closeButton)}>
                    <Core.CloseButton
                        onClick={onCloseModal}
                        title=""
                        aria-label="close button"
                        role="button"
                    />
                </div>
            </div>
        );
    }

    get selectField() {
        const { selectFieldName, selectFieldValue } = this.props;
        return (
            <Core.SelectField
                isModifiedDesign={true}
                message={selectFieldValue}
                fieldName={selectFieldName}
                onPress={this.showChooseActivityList}
                wrapperClassName={css(styles.selectFieldWrapper)}
            />
        );
    }

    get datesSection() {
        const { datesTitle, checkboxes, i18n } = this.props;
        return checkboxes.length ? (
            <>
                <div className={css(styles.datesWrapper, layoutStyle.flex)}>
                    <p className={css(styles.datesTitle)}>{datesTitle}</p>
                    <Core.Button
                        type="text"
                        size="small"
                        className={css(styles.pickDatesBtn)}
                        onPress={this.onModalOpen}
                        area-label={`${i18n.t('showMyActivityLog')}`}>
                        {i18n.t('showMyActivityLog')}
                    </Core.Button>
                </div>
                {_.map(checkboxes, this.renderCheckboxWithInput)}
            </>
        ) : null;
    }

    get footerButton() {
        const { bottomButtonText, hasAnyValues, prepareAndTrackActivity } = this.props;
        return (
            <Core.Button
                size="medium"
                className={css(layoutStyle.fw)}
                halfOpacityButton={true}
                disabled={!hasAnyValues}
                onPress={prepareAndTrackActivity}
                area-label={`${bottomButtonText}`}>
                {bottomButtonText}
            </Core.Button>
        );
    }

    get pickDatesBtn() {
        return (
            <Core.Button
                type="outlined"
                size="small"
                className={css(styles.pickDatesBtn)}
                onPress={this.openDatepicker}>
                <Core.Icon
                    name="calendar"
                    type="fa"
                    fill="regular"
                    size={spacing.s3}
                    color="secondary"
                />
                <span className={css(styles.pickDatesText)}>{this.props.pickDatesText}</span>
            </Core.Button>
        );
    }

    onModalClose = () => this.setState({ isVisible: false })

    onModalOpen = () => this.setState({ isVisible: true })

    render() {
        return (
            <Core.Layout.FHFlex>
                <ActivityLogModal isVisible={this.state.isVisible} onOpen={() => this.onModalOpen()} onClose={() => this.onModalClose()} />
                {this.subheader}
                <div className={classnames(
                    CSS_CLASSES.modalBodyWithScrollbar,
                    css(layoutStyle.commonPaddingHorizontal, styles.footerBottomOffset)
                )}>
                    {this.selectField}
                    {this.datesSection}
                    {this.pickDatesBtn}
                    <Core.Layout.FixedModalFooterButton className={css(layoutStyle.commonPaddingHorizontal)}>
                        {this.footerButton}
                    </Core.Layout.FixedModalFooterButton>
                </div>
                <Core.BlockingLoading isLoading={this.props.isLoading} />
            </Core.Layout.FHFlex>
        );
    }
}

export default withTrackActivityFormBase(TrackActivityForm);

const styles = StyleSheet.create({
    ...baseStyles,
    closeButton: {
        margin: spacing.s3,
        marginLeft: 'auto',
    },
    subheader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: `1px solid ${baseColors.grey70}`,
    },
    footerBottomOffset: {
        marginBottom: spacing.s17,
    },
    title: {
        ...appFonts.lgBold,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginLeft: spacing.s7,
        textAlign: 'center',
    },
    warningWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    ...importantStyles({
        footerButton: {
            margin: spacing.s3,
        },
        pickDatesBtn: {
            ...baseStyles.pickDatesBtn,
        },
        pickDatesText: {
            ...baseStyles.pickDatesText,
        },
        selectFieldWrapper: {
            ...baseStyles.selectFieldWrapper,
        }
    }),
});
