import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { appFonts, baseColors, spacing, windowSize } from '../../../../../styles';
import * as rewardsSelectors from '../../../selectors';

export default function WithCarouselItemBase(WrappedComponent) {
    class CarouselItemBase extends PureComponent {
        static propTypes = {
            achievementId: PropTypes.number.isRequired,
            achievement: PropTypes.object.isRequired
        };

        get rewardText() {
            return this.props.achievement.externalRewardPoints > 0 ?
                `${this.props.achievement.externalRewardPoints} ${this.props.achievement.externalRewardName}` : '';
        }

        get filledPercentages() {
            return this.props.achievement.percentageCompletion * 100;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    filledPercentages={this.filledPercentages}
                    rewardText={this.rewardText}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => ({
        achievement: rewardsSelectors.getAchievement(state, ownProps.achievementId)
    });

    return connect(mapStateToProps)(CarouselItemBase);
}

const MIN_ACHIEVEMENT_HEIGHT = 141.5;
const ACHIEVEMENT_WIDTH = windowSize.width/3;
const IMAGE_SIZE = 75;
const PROGRESS_WIDTH = 100;

export const styles = {
    mainContainer: {
        width: ACHIEVEMENT_WIDTH,
        alignItems: 'center',
        minHeight: MIN_ACHIEVEMENT_HEIGHT,
        paddingLeft: spacing.s1,
        paddingRight: spacing.s1
    },
    mainView: {
        alignItems: 'center',
        flex: 1,
        marginTop: spacing.s1
    },
    image: {
        height: IMAGE_SIZE,
        width: IMAGE_SIZE,
        marginBottom: spacing.s0
    },
    progressContainer: {
        marginBottom: spacing.s0,
        width: PROGRESS_WIDTH
    },
    subtitle: {
        color: baseColors.grey40,
        ...appFonts.smMedium,
        textAlign: 'center'
    }
};