import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { components as Core, animations } from '../../../core';
import WithFeedListBase from './FeedListBase';
import FeedItem from '../FeedItem';

class FeedList extends PureComponent {
    static propTypes = {
        renderHeader: PropTypes.node,
        feeds: PropTypes.array.isRequired,
        isLoading: PropTypes.bool,
        loadMoreContent: PropTypes.func.isRequired,
        hasMore: PropTypes.bool,
        loadContent: PropTypes.func.isRequired,
        onRef: PropTypes.func,
        getRecommendation: PropTypes.func.isRequired,
        keyExtractor: PropTypes.func.isRequired,
        ListEmptyComponent: PropTypes.node,
    };

    static defaultProps = {
        renderHeader: null,
        isLoading: false,
        hasMore: true,
        onRef: null,
        ListEmptyComponent: null
    };

    /**
     * assign ref
     */
    componentDidMount() {
        if (this.props.onRef) this.props.onRef(this);
    }

    /**
     * remove ref
     */
    componentWillUnmount() {
        if (this.props.onRef) this.props.onRef(null);
    }

    loadContent = () => this.props.loadContent();

    scrollTop = () => {
        animations.scrollToSimple(0);
    };

    /**
     * check if recommendations provided and return with item
     * @returns {(ReactElement|Array)}
     */
    renderItem = ({ item, index }) => {
        const feedItem = <FeedItem key={item} streamItemId={item} />;
        const recommendation = this.props.getRecommendation(index);
        if (!recommendation) return feedItem;
        const { component: RecommendationComponent, name } = recommendation;
        return [<RecommendationComponent key={name} {...recommendation.props} />, feedItem];
    };

    /**
     * render list
     * @returns {ReactElement}
     */
    render() {
        const { feeds, loadMoreContent, hasMore, isLoading, renderHeader, ListEmptyComponent } = this.props;
        return (
            <div>
                <Core.InfiniteLazyListFadeIn
                    data={feeds}
                    keyExtractor={this.props.keyExtractor}
                    renderItem={this.renderItem}
                    onEndReached={loadMoreContent}
                    onEndReachedThreshold={0.1}
                    hasMore={hasMore}
                    isLoading={isLoading}
                    ListHeaderComponent={renderHeader}
                    itemHeight={100}
                    ListEmptyComponent={isLoading ? null : ListEmptyComponent}
                />
            </div>
        );
    }
}

export default WithFeedListBase(FeedList);
