import { BRAND_COLOR } from '../constants';

/* eslint-disable no-bitwise */
export const lightenDarkenColor = (color, amt) => {
    let usePound = false;
    let col = color;

    if (col[0] === '#') {
        col = color.slice(1);
        usePound = true;
    }

    const num = parseInt(col, 16);

    let r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    let b = ((num >> 8) & 0x00FF) + amt;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    let g = (num & 0x0000FF) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
};

export const brandColorMaker = (color, lightness) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
    const r = parseInt(result[1], 16)/255;
    const g = parseInt(result[2], 16)/255;
    const b = parseInt(result[3], 16)/255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);

    let h = (max + min) / 2;

    if (max === min) {
        h = 0;
    } else {
        const d = max - min;
        switch (max) {
            case r: h = ((g - b) / d) + (g < b ? 6 : 0); break;
            case g: h = ((b - r) / d) + 2; break;
            case b: h = ((r - g) / d) + 4; break;
            default: return;
        }
        h = Math.round(h * 60);
        // Make negative hues positive behind 360°
        if (h < 0) h += 360;
    }

    return hslToHexHelper(h, BRAND_COLOR.SATURATION, lightness);
};

export const hslToHexHelper = (h, s, l) => {
    const S = s/100;
    const L = l/100;
    const c = (1 - Math.abs((2 * L) - 1)) * S,
        x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
        m = L - (c / 2);
    let r = 0,
        g = 0,
        b = 0;

    switch (true) {
        case h >= 0 && h < 60: r = c; g = x; b = 0; break;
        case h >= 60 && h < 120: r = x; g = c; b = 0; break;
        case h >= 120 && h < 180: r = 0; g = c; b = x; break;
        case h >= 180 && h < 240: r = 0; g = x; b = c; break;
        case h >= 240 && h < 300: r = x; g = 0; b = c; break;
        case h >= 300 && h < 360: r = c; g = 0; b = x; break;
        default: return;
    }

    // Having obtained RGB, convert channels to hex
    r = Math.round((r + m) * 255).toString(16);
    g = Math.round((g + m) * 255).toString(16);
    b = Math.round((b + m) * 255).toString(16);

    // Prepend 0s, if necessary
    if (r.length === 1) r = `0${r}`;
    if (g.length === 1) g = `0${g}`;
    if (b.length === 1) b = `0${b}`;

    return `#${r}${g}${b}`;
};

export const hexToRgba = (hex, opacity = undefined) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${opacity || 1})`
        : null;
};