import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import _ from 'lodash';

import { baseColors, spacing } from '../../../../styles';
import { components as Core, ActionSheet, ROUTES } from '../../../core';
import NotificationItem from '../NotificationItem';
import WithNotificationsBase, { styles as baseStyles } from './NotificationsBase';

class Notifications extends PureComponent {
    static propTypes = {
        sectionsData: PropTypes.array.isRequired,
        isLoading: PropTypes.bool.isRequired,
        actions: PropTypes.object.isRequired,
        markAllAsClicked: PropTypes.func.isRequired,
        performAction: PropTypes.func.isRequired,
        markAsClicked: PropTypes.func.isRequired,
        loadMoreContent: PropTypes.func.isRequired,
        loadData: PropTypes.func.isRequired,
        hasMore: PropTypes.bool.isRequired,
        history: PropTypes.object.isRequired,
        i18n: PropTypes.object.isRequired,
        title: PropTypes.string.isRequired,
    };

    componentDidMount() {
        this.loadData();
        this.props.markAllAsRead();
    }

    get options() {
        const { i18n } = this.props;
        return [{
            title: i18n.t('notificationsMarkAllReadButton'),
            onPress: () => this.props.markAllAsClicked()
        }, {
            title: i18n.t('notificationsSettingsButton'),
            onPress: () => this.openSettings()
        }];
    }

    get headerComponent() {
        const { title } = this.props;
        return (
            <Core.FixedHeader
                fullWidth={true}
                subheader={<Core.SubHeader title={this.renderPageHeader(title)} />}
            />
        );
    }

    loadData = () => {
        if (this.props.loadData && this.props.actions) {
            this.props.loadData();
        }
    };

    openSettings = () => this.props.history.push(ROUTES.notificationsEmailsSettings(), { isOpenedFromNotificationsPage: true });

    open = e => ActionSheet.open(this.options, undefined, undefined, e);

    renderItem = item => (
        <NotificationItem
            info={item}
            markAsClicked={this.props.markAsClicked}
            action={this.performAction(item)}
        />
    );

    renderPageHeader = title => {
        return(
            <div className={css(styles.container)}>
                <div className={css(styles.headingPadding)}>
                    {title}
                </div>
                <Core.EntityDetailsHeader
                    rightNavIconProps={{
                        name: 'ellipsis-h',
                        onClick: this.open }}
                    headerContainerClassName={styles.noShadowContainer}
                />
            </div>
        );
    };

    renderSections = ({ item }) => (
        <div key={item.title}>
            {_.map(item.data, this.renderItem)}
        </div>
    );

    performAction = item => () => this.props.performAction({ item, history: this.props.history });

    renderEmptyNotifications = () => {
        const { i18n } = this.props;
        const icon = (
            <Core.Icon
                type="material" name="Notifications" size={spacing.s7}
                color={baseColors.grey50}
            />
        );
        return (
            <Core.EmptyListSimple
                message={i18n.t('notificationEmptyMessage')}
                icon={icon}
                className={styles.emptyList}
            />
        );
    };

    keyExtractor = item => item.notificationId;

    render() {
        const { sectionsData, loadMoreContent, hasMore, isLoading } = this.props;
        return (
            <Core.SmallerContainer widerHeaderComponent={this.headerComponent}>
                {isLoading ? <Core.ListLoading key="Loading" /> : (
                    <Core.InfiniteLazyListFadeIn
                        data={sectionsData}
                        keyExtractor={this.keyExtractor}
                        renderItem={this.renderSections}
                        onEndReached={loadMoreContent}
                        onEndReachedThreshold={0.1}
                        hasMore={hasMore}
                        isLoading={isLoading}
                        ListEmptyComponent={this.renderEmptyNotifications()}
                    />
                )}
            </Core.SmallerContainer>
        );
    }
}

const styles = StyleSheet.create({
    ...baseStyles,
    emptyList: {
        display: 'flex',
        marginTop: spacing.s12 * 2
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        width: '100%',
        paddingRight: spacing.s3
    },
    noShadowContainer: {
        boxShadow: 'none'
    },
    headingPadding: {
        paddingRight: spacing.s19
    }
});

export default WithNotificationsBase(Notifications);
