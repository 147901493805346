import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { baseColors, appFonts } from '../../../../styles/commonCSS';
import { translate, openInapropriateContentAlert, constants as coreConstants, timeout } from '../../../core';
import { getComment, getContentError, isEditing } from '../../selectors';
import * as actions from '../../actions';

export default function WithCommentEditBase(WrappedComponent) {
    class CommentEditBase extends PureComponent {
        static propTypes = {
            comment: PropTypes.object.isRequired,
            onEdit: PropTypes.func.isRequired,
            i18n: PropTypes.object.isRequired,
            close: PropTypes.func.isRequired,
            isPosting: PropTypes.bool,
            actions: PropTypes.object.isRequired,
            contentError: PropTypes.object,
            openErrorAlert: PropTypes.func.isRequired,
            setTimeout: PropTypes.func.isRequired
        };

        static defaultProps = {
            isPosting: false,
            contentError: undefined,
        };

        constructor(props) {
            super(props);
            this.state = { text: this.props.comment.commentTextRaw };
        }

        componentDidUpdate(prevProps) {
            if (prevProps.isPosting && !this.props.isPosting) {
                if (this.props.contentError) {
                    this.props.setTimeout(() => {
                        this.props.openErrorAlert(this.props.contentError.type);
                    }, coreConstants.ALERT_TIMEOUT);
                } else {
                    this.goBack();
                }
            }
        }

        goBack = () => _.has(this, 'wrapped.goBack') && this.wrapped.goBack();

        openErrorAlert = type => {
            openInapropriateContentAlert(type, this.props.actions.clearEditError, null);
        };

        get disabled() {
            return !(this.state.text && this.state.text.length);
        }

        editComment = () => {
            this.props.actions.clearEditError();
            this.props.onEdit({ ...this.props.comment, commentText: this.state.text }, this.props.comment);
        };

        onChange = text => this.setState({ text });

        saveRef = ref => (this.wrapped = ref);

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    ref={this.saveRef}
                    onChange={this.onChange}
                    editComment={this.editComment}
                    disabled={this.disabled}
                    text={this.state.text}
                    openErrorAlert={this.openErrorAlert}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        const commentId = ownProps.commentId || _.get(ownProps, 'match.params.commentId') || _.get(ownProps, 'route.params.commentId');
        const onEdit = ownProps.onEdit || _.get(ownProps, 'match.params.onEdit') || _.get(ownProps, 'route.params.onEdit');
        return {
            commentId,
            comment: getComment(state, commentId),
            contentError: getContentError(state),
            isPosting: isEditing(state),
            onEdit
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(timeout(translate()(CommentEditBase)));
}

export const styles = {
    input: {
        ...appFonts.mdRegular,
        flex: 1,
        textAlignVertical: 'top'
    },
    footer: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    footerButton: {
        ...appFonts.mdMedium,
        color: baseColors.black
    },
    disabled: {
        color: baseColors.grey50 
    }
};
