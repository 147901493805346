import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { appFonts, spacing } from '../../../../../styles';
import { translate } from '../../../../core';
import * as selectors from '../../../selectors';
import * as actions from '../../../actions';

export default function WithGoalCardBase(WrappedComponent) {
    class GoalCardBase extends PureComponent {
        static propTypes = {
            isSelected: PropTypes.bool,
            buttonPress: PropTypes.func.isRequired,
            i18n: PropTypes.object.isRequired,
            challengeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            setChallengeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            challenge: PropTypes.object.isRequired,
            headerIcon: PropTypes.object.isRequired,
            isSetting: PropTypes.bool,
            isSet: PropTypes.bool.isRequired,
            isUnsetting: PropTypes.bool,
            onPress: PropTypes.func,
        };

        static defaultProps = {
            isSelected: false,
            isSetting: false,
            isUnsetting: false,
            setChallengeId: undefined,
            onPress: undefined,
        };

        state = {
            label: this.props.isSet ? this.props.i18n.t('goal_set') : this.props.i18n.t('set_this_goal'),
            isSetting: this.props.isSetting,
            isUnsetting: this.props.isUnsetting,
            isFadeInText: false,
        };

        static getDerivedStateFromProps(nextProps, prevState) {
            if (nextProps.isSetting !== prevState.isSetting) {
                const isJustSet = !nextProps.isSetting && prevState.isSetting;
                return {
                    isSetting: nextProps.isSetting,
                    label: isJustSet ? nextProps.i18n.t('goal_set') : prevState.label,
                    isFadeInText: isJustSet
                };
            }
            if (nextProps.isUnsetting !== prevState.isUnsetting) {
                const isJustUnset = !nextProps.isUnsetting && prevState.isUnsetting;
                return {
                    isUnsetting: nextProps.isUnsetting,
                    label: isJustUnset ? nextProps.i18n.t('set_this_goal') : prevState.label,
                    isFadeInText: isJustUnset
                };
            }
            return null;
        }

        get label() {
            return this.props.successLabel || this.state.label;
        }

        onPress = () => {
            if (this.props.onPress) {
                this.props.onPress(this.props.challenge, this.props.isSet, this.props.setChallengeId);
            }
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    label={this.label}
                    isFadeInText={this.state.isFadeInText}
                    onPress={this.onPress}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const setChallengeId = selectors.getSetRecommendedChallengeIdByName(state, ownProps.challengeId);
        const isSetting = selectors.isSettingPersonalGoal(state, ownProps.challengeId);
        const isUnsetting = selectors.isDeletingChallenge(state, setChallengeId);
        return {
            challenge: selectors.getRecommendedGoal(state, ownProps.challengeId),
            isSet: selectors.isSetRecommendedGoal(state, ownProps.challengeId),
            isLoading: isSetting || isUnsetting,
            isSetting,
            isUnsetting,
            setChallengeId
        };
    };

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(GoalCardBase));
}

export const styles = {
    cardDarkBodyTop: {
        paddingTop: spacing.s0,
        paddingBottom: spacing.s1,
    },
    cardDarkHeadingSub: {
        ...appFonts.smRegular
    },
};
