const global = {};
global.systemSubdomain = ''; // User subdomain  mylivebetter
global.originalSystemSubdomain = ''; // DO NOT ERASE WITH CODE, should be a const

global.token = ''; // User access token
global.subdomainRequired = true;
global.pin = { required: false, numbers: 4 };
global.seenNotificationPermissions = false;
global.isHiddenPrivacyInformationText = [];

global.savedLoginUser = null;
global.appStartedUserLoggedIn = false;
global.pendingNotification = null;

global.userSelectedLanguage = null;

global.dataCollectionDisabled = false;

export default global;
