import PropTypes from 'prop-types';
import React from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import { spacing } from '../../../../styles';
import WithOrderHeaderBase, { styles as baseStyles } from './OrderHeaderBase';

const OrderHeader = ({ infoTextObject: { confirmYourOrder, item, productName, redeemFor, reward } }) => (
    <div className={css(styles.mainContainer)}>
        <span className={css(styles.headerTitle)}>{confirmYourOrder}</span>
        <div className={css(styles.topContent)}>
            <p className={css(styles.title)}>{item}</p>
            <p className={css(styles.mainInfo)}>{productName}</p>
        </div>
        <div className={css(styles.bottomContent)}>
            <p className={css(styles.title)}>{redeemFor}</p>
            <p className={css(styles.mainInfo)}>{reward}</p>
        </div>
    </div>
);

OrderHeader.propTypes = {
    infoTextObject: PropTypes.object.isRequired
};

export default WithOrderHeaderBase(OrderHeader);

const styles = StyleSheet.create({
    ...baseStyles,
    mainContainer: {
        ...baseStyles.mainContainer,
        paddingTop: spacing.s7
    },
    headerTitle: {
        ...baseStyles.headerTitle,
        display: 'flex',
        justifyContent: 'center'
    }
});