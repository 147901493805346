export const NAME = 'apps_devices';

export const HEALTH_KIT = 'healthKit';
export const GOOGLE_FIT = 'googlefit';
export const HEALTH_CONNECT = 'healthConnect';

export const SUCCESSFUL_CONNECTION_STATUS = 'success';

export const TABS = {
    all: 'all'
};

export const DEVICES_DASHBOARD_STATUS = {
    WARNING: 'warning',
    ERROR: 'error',
    NONE: 'none'
};

export const DEVICE_CONNECTION = {
    SUCCESS: 'connected successfully',
    ERROR: 'connection error'
};

export const NOT_PERSISTED = false;
