import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite-jss';
import _ from 'lodash';
import { EVENT_ENTITY_TYPES } from '../../constants';
import calendarIcon from '../../../../image/explore/calendar@3x.png';
import { importantClass } from '../../../../styles';
import WithEventsBase from './EventsBase';
import EventCarouselItem from '../EventCarouselItem';
import { AsyncComponent, components as Core, Modal, ROUTES, tracker, CSS_CLASSES, CAROUSEL_TYPES } from '../../../core';

class Events extends PureComponent {
    static propTypes = {
        eventsList: PropTypes.array.isRequired,
        title: PropTypes.string,
        count: PropTypes.number.isRequired,
        actions: PropTypes.object.isRequired,
        noEvents: PropTypes.object.isRequired,
        userId: PropTypes.number.isRequired,
        callback: PropTypes.func,
        isInvitation: PropTypes.bool,
        type: PropTypes.string.isRequired,
        i18n: PropTypes.object.isRequired,
        isAllType: PropTypes.bool.isRequired,
        history: PropTypes.object.isRequired,
        isModal: PropTypes.bool,
        isLoadingItems: PropTypes.bool,
        exploreLoadingStatus: PropTypes.object
    };

    static defaultProps = {
        title: '',
        callback: null,
        isInvitation: false,
        isModal: false,
        isLoadingItems: false,
        exploreLoadingStatus: {}
    };

    get createEntity() {
        const { noEvents } = this.props;
        return (
            <Core.CreateEntity
                title={noEvents.title}
                subtitle={noEvents.subtitle}
                buttonLabel={noEvents.buttonLabel}
                buttonHandler={this.goToCreateEvent}
                image={calendarIcon}
                containerClassName={css(styles.createEntityWrapper)}
            />
        );
    }

    get eventsItems() {
        return _.map(this.props.eventsList, this.renderCarouselItem);
    }

    viewAllEvents = () => {
        const { type, userId, callback, exploreLoadingStatus } = this.props;
        if (callback) callback();
        switch (type) {
            case EVENT_ENTITY_TYPES.ALL_ITEMS:
                this.props.history.push(ROUTES.eventsAll(), { exploreLoadingStatus });
                break;
            case EVENT_ENTITY_TYPES.MY_ITEMS:
            case EVENT_ENTITY_TYPES.PAST:
            case EVENT_ENTITY_TYPES.INVITED:
            case EVENT_ENTITY_TYPES.RECOMMENDED:
            case EVENT_ENTITY_TYPES.USER:
                this.props.history.push(ROUTES.eventsList(type, userId));
                break;
            default:
                return null;
        }
    };

    goToCreateEvent = () => {
        tracker.logEvent('add_event', { plus: false });
        Modal.open(AsyncComponent(() => import('../CreateEvent')), { isModal: true }, {
            cancelable: false,
            isNoPadding: true,
            isContainer: true,
            isFullHeight: true
        });
    };

    goDetailsEvent = id => {
        if (this.props.callback) this.props.callback();
        this.props.history.push(ROUTES.eventDetails(id));
    };

    renderCarouselItem = item => (
        <EventCarouselItem
            isInvitation={this.props.isInvitation}
            item={item}
            onPressSingle={this.goDetailsEvent}
            type={this.props.type}
        />
    );

    renderCarousel = children => {
        const { type, title, i18n, count, actions, eventsList, isModal, isLoadingItems } = this.props;
        return (
            <div className={CSS_CLASSES.explore}>
                <Core.Carousel
                    type={eventsList.length === 2 ? CAROUSEL_TYPES.twoOnly : CAROUSEL_TYPES.multiple}
                    titleSize={Core.SubHeader.SIZES.large}
                    title={title || i18n.t('events')}
                    entityType={type}
                    count={count}
                    isLoading={isLoadingItems}
                    viewAllPress={this.viewAllEvents}
                    events={true}
                    isBorderShown={false}
                    joinToEntity={actions.joinEvent}
                    itemsLength={eventsList.length}
                    isModal={isModal}
                    slidesToScrollValue={isModal ? 2 : 4}>
                    {children}
                </Core.Carousel>
            </div>
        );
    };

    render() {
        const { eventsList, isAllType } = this.props;
        return (
            <React.Fragment>
                { eventsList.length ? this.renderCarousel(this.eventsItems) : null}
                {!eventsList.length && isAllType ? this.renderCarousel(this.createEntity) : null}
            </React.Fragment>
        );
    }
}

export default withRouter(WithEventsBase(Events));

const BOTTOM_MARGIN = 100;

const styles = StyleSheet.create({
    createEntityWrapper: importantClass({
        marginBottom: BOTTOM_MARGIN,
    }),
});
