import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import _ from 'lodash';
import classnames from 'classnames';
import withChooseActivityListBase, { styles as baseStyles } from './ChooseActivityListBase';
import { components as Core, CSS_CLASSES } from '../../../core';
import { appFonts, baseColors, spacing, layoutStyle } from '../../../../styles';
import ActivityTrackingItem from '../ActivityTrackingItem';
import ActivityLogModal from '../ActivityLogModal/ActivityLogModal';

class ChooseActivityList extends PureComponent {
    static propTypes = {
        close: PropTypes.func.isRequired,
        closeAll: PropTypes.func.isRequired,
        chooseActivityTitle: PropTypes.string.isRequired,
        searchText: PropTypes.string.isRequired,
        onChangeSearchText: PropTypes.func.isRequired,
        searchBarPlaceholder: PropTypes.string.isRequired,
        onClearSearchText: PropTypes.func.isRequired,
        activityLogs: PropTypes.array.isRequired,
        recentTitle: PropTypes.string.isRequired,
        onSelectActivity: PropTypes.func.isRequired,
        tabs: PropTypes.array.isRequired,
        selectedTab: PropTypes.object.isRequired,
        onTabPress: PropTypes.func.isRequired,
        showBackgroundModal: PropTypes.func.isRequired,
        filteredActivities: PropTypes.array.isRequired,
        noActivitiesText: PropTypes.string.isRequired,
        selectedActivity: PropTypes.object,
    };

    static defaultProps = {
        selectedActivity: null,
    };

    constructor(props) {
        super(props);
        this.state = { isVisible: false };
    }

    onChangeSearchText = event => {
        this.props.onChangeSearchText(event.target.value);
    };

    onSelectActivity = item => () => {
        const { onSelectActivity, close, showBackgroundModal } = this.props;
        showBackgroundModal();
        onSelectActivity(item);
        close();
    };

    renderActivity = item => (
        <ActivityTrackingItem
            key={_.get(item, 'activityLogId') || _.get(item, 'activityUnitId')}
            activity={item}
            onClick={this.onSelectActivity(item)}
            noPlusIcon={true}
            isCommonPaddingHorizontal={true}
        />
    );

    onTabChange = (event, value) => {
        const newTab = _.find(this.props.tabs, { id: value });
        this.props.onTabPress(newTab);
    };

    onModalOpen = () => this.setState({ isVisible: true })

    onModalClose = () => this.setState({ isVisible: false })

    get subheader() {
        const { close, closeAll, chooseActivityTitle, selectedActivity } = this.props;
        return (
            <>
                <div className={css(styles.subheader)}>
                    <span />
                    <p className={css(styles.title)}>{chooseActivityTitle}</p>
                    <div className={css(styles.closeButton)}>
                        <Core.CloseButton onClick={selectedActivity ? close : closeAll} />
                    </div>
                </div>
            </>
        );
    }

    get searchBar() {
        const { searchText, searchBarPlaceholder, onClearSearchText } = this.props;
        return (
            <>
                <div className={css(styles.searchBarContainer)}>
                    <Core.SearchBar
                        value={searchText}
                        onChange={this.onChangeSearchText}
                        label={searchBarPlaceholder}
                        autoFocus={true}
                        onClear={onClearSearchText}
                        isSearching={true}
                        className={css(styles.searchBar)}
                        isCustomizedSearchBar={false}
                        addWhiteBg={true}
                    />
                </div>
            </>
        );
    }

    get recentActivities() {
        const { activityLogs, recentTitle, i18n } = this.props;
        return (
            <>
                <div className={classnames(css(styles.recentWrapper, layoutStyle.flex, !activityLogs.length && layoutStyle.flexJustifyEnd))}>
                    {activityLogs.length ? <p className={css(styles.recentTitle)}>{recentTitle}</p> : null}
                    <Core.Button
                        type="text"
                        size="small"
                        onPress={this.onModalOpen}>
                        {i18n.t('showMyActivityLog')}
                    </Core.Button>
                </div>
                <div className={css(styles.separator)} />
                {_.map(activityLogs, this.renderActivity)}
            </>
        );
    }

    get tabs() {
        const { tabs, selectedTab } = this.props;
        return (
            <>
                <div className={css(styles.tabsContainer)}>
                    <Core.ScrollableTabs
                        tabs={tabs}
                        activeTab={selectedTab}
                        onChange={this.onTabChange}
                        isVertical={false}
                        isNewDesign={true}
                    />
                </div>
                <div className={css(styles.separator)} />
            </>
        );
    }

    get emptyListComponent() {
        return (
            <p className={css(styles.emptyList)}>{this.props.noActivitiesText}</p>
        );
    }

    get selectedCategoryActivities() {
        const { filteredActivities } = this.props;
        return filteredActivities.length ? _.map(filteredActivities, this.renderActivity) : this.emptyListComponent;
    }

    render() {
        return (
            <Core.Layout.FHFlex>
                <ActivityLogModal isVisible={this.state.isVisible} onOpen={() => this.onModalOpen()} onClose={() => this.onModalClose()} />
                {this.subheader}
                {this.searchBar}
                <div className={CSS_CLASSES.modalBodyWithScrollbar}>
                    {this.recentActivities}
                    {this.tabs}
                    {this.selectedCategoryActivities}
                </div>
            </Core.Layout.FHFlex>
        );
    }
}

export default withChooseActivityListBase(ChooseActivityList);

const styles = StyleSheet.create({
    ...baseStyles,
    searchBarContainer: {
        padding: spacing.s3,
        backgroundColor: baseColors.grey90,
        borderTop: `1px solid ${baseColors.grey70}`,
        borderBottom: `1px solid ${baseColors.grey80}`,
    },
    closeButton: {
        margin: spacing.s3,
        marginLeft: 'auto',
    },
    title: {
        ...appFonts.lgBold,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        paddingLeft: spacing.s7
    },
    subheader: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    searchBar: {
        backgroundColor: 'transparent',
    },
    recentTitle: {
        ...baseStyles.recentTitle,
        color: baseColors.grey20,
    },
    tabsContainer: {
        backgroundColor: baseColors.grey90,
    },
    recentWrapper: {
        ...baseStyles.recentWrapper,
        padding: spacing.s1,
        paddingLeft: 0
    }
});
