import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { StyleSheet, css } from 'aphrodite-jss';
import { components as Core, parsers, ROUTES, tracker } from '../../../core';
import WithContactBase, { styles as baseStyles } from './ContactBase';
import { containerStyle, layoutStyle, spacing, important } from '../../../../styles';

class Contact extends PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        task: PropTypes.object.isRequired,
        actions: PropTypes.object.isRequired,
        updateContactInfo: PropTypes.func.isRequired,
        submitForm: PropTypes.func.isRequired,
        contactValue: PropTypes.string,
        isSaving: PropTypes.bool,
        isLoading: PropTypes.bool,
        phoneNumber: PropTypes.string.isRequired,
        phoneNumberLabel: PropTypes.string.isRequired,
        errorPhoneNumber: PropTypes.string,
        updatePhoneNumber: PropTypes.func.isRequired,
        timeOptions: PropTypes.array.isRequired,
        selectedTime: PropTypes.object.isRequired,
        onChangeTime: PropTypes.func.isRequired,
        isShowingOnboarding: PropTypes.bool.isRequired,
        history: PropTypes.object.isRequired,
        callback: PropTypes.func,
        isOnboarding: PropTypes.bool,
        isHomeCard: PropTypes.bool,
    };

    static defaultProps = {
        contactValue: '',
        errorPhoneNumber: '',
        isSaving: false,
        isLoading: false,
        callback: null,
        isOnboarding: false,
        isHomeCard: false
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    static getDerivedStateFromProps(nextProps) {
        if (nextProps.task.disclaimer_web) {
            return {
                disclaimer: parsers.replaceBrAndATagsWithItsHrefAndReturnLinks(nextProps.task.disclaimer_web)
            };
        }
        return null;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isSaving && !this.props.isSaving) {
            if (this.props.callback) this.props.callback();
        }
    }

    onChange = value => this.props.updateContactInfo(value);

    renderLink = match => (
        <Core.SafeA
            id="sproutInternalEapOnContact"
            target="_blank"
            href={match.attrs.href}
            className={css(styles.disclaimerLink)}>
            {this.state.disclaimer.links[match.attrs.href] || match.attrs.href}
        </Core.SafeA>
    );

    onContactMe = () => {
        if (!this.props.isShowingOnboarding) {
            return this.props.history.push(ROUTES.home());
        }

        this.props.actions.setOnboardingComplete();
        tracker.logEvent('onboarding', { completed: true });
    }

    get footerWrapper() {
        return this.props.isOnboarding ? Core.Layout.FixedFooterSmallerContainer : Core.Layout.FixedFooterWiderContainer;
    }

    get innerContent() {
        const { task, isLoading, i18n, phoneNumberLabel, isOnboarding, errorPhoneNumber, phoneNumber, updatePhoneNumber,
            timeOptions, selectedTime, onChangeTime, submitForm } = this.props;
        const title = this.props.i18n.t('EAP.contactInfo');
        return (
            <Core.Layout.PageWrapper isModal={this.props.isOnboarding}>
                {isLoading && _.isEmpty(task) ?
                    <Core.ListLoading key="Loading" /> :
                    <div className={css(containerStyle.container, styles.container)}>
                        {!isOnboarding ?
                            <>
                                <Core.EntityDetailsHeader hasBackButton={true} title={title} />
                                <Core.SubHeader title={title} titleAlign={Core.SubHeader.ALIGN.center} noLeftPadding={true} />
                            </> : null
                        }
                        <p className={css(styles.textBoxHeading)}>{i18n.t('eap.contact.whatIsBestNumber')}</p>
                        <Core.Input
                            id="phoneNumberInput"
                            label={phoneNumberLabel}
                            onChangeText={updatePhoneNumber}
                            value={phoneNumber}
                            fullWidth={true}
                            error={!!errorPhoneNumber}
                            helperText={errorPhoneNumber}
                        />
                        <p className={css(styles.textBoxHeading)}>{i18n.t('eap.contact.whatIsBestTime')}</p>
                        <Core.MultiSelectCheck
                            options={timeOptions}
                            onPressCallback={onChangeTime}
                            initValues={true}
                            selected={selectedTime}
                            titleStyle={styles.timeSelectHeader}
                            wrapperStyle={styles.timeWrapperStyle}
                            containerStyle={styles.timeContainer}
                            subtitleContainerStyle={styles.timeSubtitleContainer}
                            singleSelect={true}
                        />
                        <p className={css(styles.textBoxHeading)}>{i18n.t('eap.contact.additionalDetails')}</p>
                        <Core.Input
                            id="addDetailsInput"
                            label={i18n.t('eap.contact.description')}
                            onChangeText={this.onChange}
                            value={this.props.contactValue}
                            multiline={true}
                            fullWidth={true}
                            rows={6}
                            isDisabledTextOverflow={true}
                            inputClassName={styles.inputLabel}
                        />
                        {this.state.disclaimer ?
                            <Core.AutoLink
                                text={this.state.disclaimer.text}
                                renderLink={this.renderLink}
                                className={css(styles.disclaimer)}
                            /> : null
                        }
                    </div>
                }
                <div
                    className={css(styles.footerContainer)}>
                    <div className={css(layoutStyle.bgWhite, layoutStyle.footerPadding1, layoutStyle.flexJustifyCenter)}>
                        <Core.Button
                            id="contactMeButton"
                            onPress={submitForm}
                            className={css(layoutStyle.halfWidth)}
                            isLoading={this.props.isSaving}>
                            {this.props.i18n.t('EAP.contactMe')}
                        </Core.Button>
                    </div>
                </div>
            </Core.Layout.PageWrapper>
        );
    }

    render() {
        return (
            <div className={css(layoutStyle.content, layoutStyle.pRelative, styles.content)}>
                {this.innerContent}
            </div>
        );
    }
}

const styles = StyleSheet.create({
    ...baseStyles,
    content: {
        paddingBottom: spacing.s4
    },
    inputLabel: {
        marginTop: important(spacing.s11),
    },
    footerContainer: {
        position: 'relative',
        paddingTop: spacing.s5
    }
});

export default WithContactBase(Contact);

