/* eslint-disable react/no-children-prop */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { PieChart } from 'react-minimal-pie-chart';
import { StyleSheet, css } from 'aphrodite-jss';
import { baseColors, layoutStyle } from '../../../../styles';
import { onKeyPress } from '../..';

const DEFAULT_PROPS = {
    rounded: true,
    startAngle: 270,
    lengthAngle: 360,
    lineWidth: 8,
    animationDuration: 2000
};

export default class ProgressCircle extends PureComponent {
    static propTypes = {
        className: PropTypes.object,
        textContainerClassName: PropTypes.string,
        text: PropTypes.node,
        onClick: PropTypes.func,
        progress: PropTypes.number,
        bgColor: PropTypes.string,
        progressColor: PropTypes.string,
        isAnimated: PropTypes.bool
    };

    static defaultProps = {
        className: null,
        textContainerClassName: '',
        text: undefined,
        onClick: undefined,
        progress: 0,
        bgColor: baseColors.grey80,
        progressColor: baseColors.white,
        isAnimated: false
    };

    get data() {
        return [
            { value: 100 - this.props.progress, color: this.props.bgColor },
            { value: this.props.progress, color: this.props.progressColor }
        ];
    }

    render() {
        const { className, textContainerClassName, text, onClick, isAnimated, injectSvg, ...props } = this.props;
        return (
            <button onClick={onClick} onKeyDown={onKeyPress.enter(onClick)} className={css(styles.container, layoutStyle.noCursor, className)} tabIndex={onClick ? '0' : '-1'}>
                <div className={css(styles.progressCircleContainer)}>
                    {isAnimated ? (
                        <PieChart
                            {...DEFAULT_PROPS}
                            {...props}
                            data={[this.data[0]]}
                            className={css(styles.chart)}
                            children={injectSvg}
                        />
                    ) : null
                    }
                    <PieChart
                        {...DEFAULT_PROPS}
                        {...props}
                        data={this.data}
                        animate={isAnimated}
                        className={css(styles.chart, isAnimated ? styles.animatedChart : null)}
                        children={injectSvg}
                    />
                </div>
                <div className={css(styles.textContainer, textContainerClassName)}>
                    {text}
                </div>
            </button>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        position: 'relative',
        margin: 'auto',
        height: '100%',
        width: '100%',
        display: 'flex',
    },
    progressCircleContainer: {
        transform: 'rotateY(180deg)',
        height: '100%',
        width: '100%',
        flex: 1,
        position: 'relative'
    },
    textContainer: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    animatedChart: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: '100%',
        height: '100%'
    },
    chart: {
        width: '100%',
        height: '100%'
    }
});
