import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { components as Core } from '../../../core';
import WithCommunitiesListBase from './CommunitiesListBase';
import CommunityItem from '../CommunityItem';

class CommunitiesList extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool,
        communities: PropTypes.array,
        communitiesCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        loadMoreContent: PropTypes.func.isRequired,
        title: PropTypes.string,
    };

    static defaultProps = {
        isLoading: false,
        communities: [],
        communitiesCount: undefined,
        title: '',
    };

    renderItem = community => <CommunityItem id={community.item.id} />;

    renderHeader = () => (
        <React.Fragment>
            <Core.EntityDetailsHeader
                hasBackButton={true}
            />
            <Core.SubHeader
                title={this.props.title}
                noTopPadding={true}
            />
        </React.Fragment>
    );

    render() {
        const { communities, loadMoreContent, isLoading, communitiesCount } = this.props;
        return (
            <Core.SmallerContainer>
                <Core.InfiniteLazyListFadeIn
                    data={communities}
                    keyExtractor={item => item.id}
                    renderItem={this.renderItem}
                    onEndReached={loadMoreContent}
                    onEndReachedThreshold={0.5}
                    hasMore={communities.length < communitiesCount}
                    isLoading={isLoading}
                    ListHeaderComponent={this.renderHeader}
                    isHorizontal={false}
                    twoColumns={false}
                />
            </Core.SmallerContainer>
        );
    }
}


export default WithCommunitiesListBase(CommunitiesList);
