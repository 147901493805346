import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { translate } from '../../../core';
import * as actions from '../../actions';
import { getTask, isEAPLoading, getScreens } from '../../selectors';
import { baseColors, spacing, appFonts } from '../../../../styles';

export default function WithReachGoalBase(WrappedComponent) {
    class ReachGoalBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            options: PropTypes.array,
            task: PropTypes.object,
            tasks: PropTypes.object,
            isLoading: PropTypes.bool,
            onNext: PropTypes.func,
            i18n: PropTypes.object.isRequired
        };

        static defaultProps = {
            options: [],
            task: [],
            tasks: [],
            isLoading: false,
            onNext: null
        };

        constructor(props) {
            super(props);
            this.props.actions.getEAP();
        }

        get screen() {
            return {
                group_name: this.props.i18n.t('EAP.reachGoal.title'),
                icon_name: _.get(this, 'props.tasks.eap.icon_name') || 'hands-helping',
                main_description: null
            };
        }

        get rightButtonLabel() { return this.props.i18n.t('EAP.tellMore'); }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    screen={this.screen}
                    rightButtonLabel={this.rightButtonLabel}
                />
            );
        }
    }

    const mapStateToProps = state => ({
        task: getTask(state),
        isLoading: isEAPLoading(state),
        tasks: getScreens(state)
    });

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(ReachGoalBase));
}

export const styles = {
    description: {
        ...appFonts.mdRegular,
        textAlign: 'center',
        color: baseColors.black,
        paddingHorizontal: spacing.s3
    },
    title: {
        ...appFonts.lgMedium,
        marginBottom: spacing.s3,
        marginTop: spacing.s7
    },
};

