import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as actions from '../../actions';
import { translate } from '../../../core';
import { windowSize, spacing, appFonts, baseColors } from '../../../../styles';
import { tourStyles } from '../../styles';

export const APPS_ICON_SIZE = spacing.s9;

export default function WithToolTipModalBase(WrappedComponent) {
    class ToolTipModalBase extends PureComponent {
        static propTypes = {
            position: PropTypes.shape({
                width: PropTypes.number,
                height: PropTypes.number,
                pageX: PropTypes.number,
                pageY: PropTypes.number
            }).isRequired,
        };

        defaultPosition = () => ({
            width: windowSize.width,
            height: 0,
            pageX: 0,
            pageY: windowSize.height/2
        })

        //if reference is invalid we set position on center of the screen
        get position() {
            return this.props.position || this.defaultPosition();
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    position={this.position}
                />
            );
        }
    }

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(null, mapDispatchToProps)(translate()(ToolTipModalBase));
}

const BUTTON_MIN_WIDTH = spacing.s8 * 2;

export const styles = {
    ...tourStyles,
    text: {
        ...appFonts.mdRegular,
        marginBottom: spacing.s3
    },
    buttonGotItContainer: {
        justifyContent: 'flex-start'
    },
    buttonContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    backButton: {
        borderColor: baseColors.white,
        marginRight: spacing.s3
    },
    button: {
        minWidth: BUTTON_MIN_WIDTH
    }
};