import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { translate, pointsHelper, ICON_CATEGORIES, selectors as coreSelectors } from '../../../../core';
import * as selectors from '../../../selectors';
import * as actions from '../../../actions';
import { appFonts, baseColors, spacing } from '../../../../../styles';
import { getSlug, getStep } from '../../../services';


export default function WithTaskListItemBase(WrappedComponent) {
    class TaskListItemBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            task: PropTypes.object.isRequired,
            customPointsName: PropTypes.string.isRequired,
            customPointsUnit: PropTypes.string.isRequired,
        };

        get title() {
            // todo: add to vocab
            return this.props.task.title || this.props.i18n.t('SOT.sample_task');
        }

        get subtitle() {
            return this.props.task.subtitle || this.props.i18n.t('build_your_profile');
        }

        get points() {
            const { task, customPointsUnit } = this.props;
            const { points, unit } = pointsHelper.getPointsUnits(task, customPointsUnit);
            const bonusPoints = _.get(task, 'bonusPoints', 0);
            return (bonusPoints || points) && pointsHelper.formatPoints(bonusPoints || points, unit);
        }

        get step() {
            return getStep(this.props.task);
        }

        get slug() {
            return getSlug(this.props.task);
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    title={this.title}
                    subtitle={this.subtitle}
                    description={this.description}
                    points={this.points}
                    label={this.label}
                    icon={ICON_CATEGORIES.clipboard}
                    step={this.step}
                    slug={this.slug}
                />
            );
        }
    }

    const mapStateToProps = state => ({
        screens: selectors.getScreens(state),
        customPointsName: coreSelectors.getCustomPointsName(state),
        customPointsUnit: coreSelectors.getCustomPointsUnit(state)
    });

    const mapDispatchToProps = dispatch => ({
        actions: bindActionCreators(actions, dispatch)
    });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(TaskListItemBase));
}
export const BULLET_SIZE = spacing.s1;
export const styles = {
    bodyText: {
        ...appFonts.lgMedium,
        textAlign: 'center',
        color: baseColors.black,
        paddingTop: spacing.s5
    },
    textContainer: {
        flex: 2
    },
    bonusContainerInner: {
        flex: 0,
        flexDirection: 'row'
    },
    bonusText: {
        ...appFonts.mdMedium,
        color: baseColors.primary
    },
    title: {
        ...appFonts.smMedium
    },
    bullet: {
        width: BULLET_SIZE,
        height: BULLET_SIZE,
        backgroundColor: baseColors.grey70,
        borderRadius: spacing.s0,
    },
    bulletBox: {
        backgroundColor: baseColors.white,
        borderRadius: spacing.s3,
    },
    line: {
        backgroundColor: baseColors.grey70,
        position: 'absolute',
        left: 2, //half of circle + spacing
        top: 3
    },
};
