import _ from 'lodash';
import * as types from './actionTypes';
import * as api from './api';
import { normalize, constants as coreConstants } from '../core';
import { validateError } from '../../config';
import { PushService } from './services';
import Emitter from '../../Emitter';

export function getAllNotifications() {
    return function (dispatch) {
        dispatch({ type: types.GET_NOTIFICATIONS.REQUEST });
        return api.getNotifications({})
            .then(res => {
                dispatch({
                    type: types.GET_NOTIFICATIONS.SUCCESS,
                    payload: normalize.normalizeArray(res.data, 'notificationId')
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.GET_NOTIFICATIONS.ERROR });
            }));
    };
}

export function getMoreNotifications(earlierId = null) {
    const params = {};
    if (earlierId && Number.isInteger(earlierId)) params.idLessThan = earlierId;
    return function (dispatch) {
        dispatch({ type: types.GET_MORE_NOTIFICATIONS.REQUEST });
        return api.getNotifications(params)
            .then(res => {
                dispatch({
                    type: types.GET_MORE_NOTIFICATIONS.SUCCESS,
                    payload: normalize.normalizeArray(res.data, 'notificationId')
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.GET_MORE_NOTIFICATIONS.ERROR });
            }));
    };
}

export function updatePriorityNotificationsIds(id) {
    return ({
        type: types.UPDATE_PRIORITY_NOTIFICATIONS_IDS,
        payload: { id }
    });
}

export function getUnreadCount() {
    return function (dispatch) {
        dispatch({ type: types.GET_UNREAD_COUNT.REQUEST });
        return api.getUnreadCount()
            .then(res => {
                dispatch({ type: types.GET_UNREAD_COUNT.SUCCESS, payload: res.data });
                const count = _.get(res.data, 'count', 0);
                PushService.setApplicationIconBadgeCount(count);
                Emitter.emit('updateInAppBadgeCount', { count });
            })
            .catch(error => validateError(error, error => {
                dispatch({ type: types.GET_UNREAD_COUNT.ERROR });
            }));
    };
}

export function markNotificationAsClicked(entityId, actionType = 'markingAsRead') {
    const item = { notificationId: entityId, read: 1, clicked: 1 };
    return function (dispatch) {
        dispatch({ type: types.MARK_NOTIFICATION_READ.REQUEST });
        return api.markAsClicked(item)
            .then(res => {
                dispatch({
                    type: types.MARK_NOTIFICATION_READ.SUCCESS,
                    payload: { item: res.data || item }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({
                    type: types.MARK_NOTIFICATION_READ.ERROR,
                    payload: { error, toast: { actionType } }
                });
            }));
    };
}

export function markAllNotificationsAsRead(actionType = 'markingAllNotifications') {
    return function (dispatch) {
        dispatch({
            type: types.MARK_ALL_NOTIFICATIONS_READ.REQUEST
        });
        return api.markAllAsRead()
            .then(res => {
                dispatch({
                    type: types.MARK_ALL_NOTIFICATIONS_READ.SUCCESS,
                    payload: {}
                });
                PushService.setApplicationIconBadgeCount(0);
                Emitter.emit('updateInAppBadgeCount', { count: 0 });
            })
            .catch(error => validateError(error, error => {
                dispatch({
                    type: types.MARK_ALL_NOTIFICATIONS_READ.ERROR,
                    payload: {
                        error,
                        toast: { actionType }
                    }
                });
            }));
    };
}

export function markAllNotificationsAsClicked(actionType = 'markingAllNotifications') {
    return function (dispatch) {
        dispatch({
            type: types.MARK_ALL_NOTIFICATIONS_CLICKED.REQUEST
        });
        return api.markAllAsClicked()
            .then(() => {
                dispatch({
                    type: types.MARK_ALL_NOTIFICATIONS_CLICKED.SUCCESS,
                    payload: { toast: { type: coreConstants.TOAST_TYPES.SUCCESS, actionType } }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({
                    type: types.MARK_ALL_NOTIFICATIONS_CLICKED.ERROR,
                });
            }));
    };
}

export function requestPushPermissions() {
    return async function (dispatch) {
        PushService.requestPermissions();
    };
}

export function registerDeviceForPush(deviceId, platform) {
    // platform 'GCM' or 'APNS'
    return api.registerDeviceForPush({ deviceId, platform })
        .then(() => console.log('registerDeviceForPush success'))
        .catch(error => console.warn('registerDeviceForPush', error));
}

// function registerDeviceForPushSuccess(deviceId) {
//     return {
//         type: types.REGISTER_DEVICE_FOR_PUSH.SUCCESS,
//         data: { deviceId }
//     };
// }

export function unregisterDeviceForPush(deviceId) {
    return function (dispatch) {
        return api.unregisterDeviceForPush(deviceId)
            .catch(error => console.warn('unregisterDeviceForPush', error));
    };
}

export function resetPushNotificationPermissions() {
    return function (dispatch, getState) {
        PushService.abandonPermissions();
    };
}
