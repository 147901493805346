import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { constants as coreConstants, translate, parsers } from '../../../core';
import { selectors as rewardsSelectors, constants as rewardsConstants } from '../../../rewards';
import { appFonts, baseColors, spacing } from '../../../../styles';

export default function WithBonusRewardDetailsBase(WrappedComponent) {
    class BonusRewardDetailsBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            challengeName: PropTypes.string.isRequired,
            externalRewardName: PropTypes.string.isRequired,
            rewardAmount: PropTypes.number.isRequired,
            rewardDate: PropTypes.number.isRequired,
            rewardType: PropTypes.string.isRequired
        };

        get formattedDate() {
            return moment.unix(this.props.rewardDate).format(coreConstants.DATE_FORMATS.monthFullDayShortYearFull);
        }

        get title() {
            const { i18n, rewardType } = this.props;
            return rewardType === rewardsConstants.TRANSACTION_TYPES.CHALLENGE ? i18n.t('bonusChallengeReward') : i18n.t('bonusGoalReward');
        }

        get subtitle() {
            const { i18n, challengeName } = this.props;
            return i18n.t('forCompletingChallenge', { challengeName: parsers.htmlDecode(challengeName) });
        }

        get rewardText() {
            return this.props.i18n.t('Reward');
        }

        get rewardValue() {
            const { rewardAmount, externalRewardName } = this.props;
            return `${rewardAmount} ${externalRewardName}`;
        }

        get dateEarnedText() {
            return this.props.i18n.t('date_earned');
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    formattedDate={this.formattedDate}
                    title={this.title}
                    subtitle={this.subtitle}
                    rewardText={this.rewardText}
                    rewardValue={this.rewardValue}
                    dateEarnedText={this.dateEarnedText}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const locationState = _.get(ownProps, 'history.location.state', {});
        const routeParams = _.get(ownProps, 'route.params');
        return {
            ...locationState,
            externalRewardName: rewardsSelectors.getExternalRewardName(state),
            ...(routeParams || {}),
        };
    };

    return connect(mapStateToProps)(translate()(BonusRewardDetailsBase));
}

const ICON_SIZE = 125;

export const styles = {
    coinsIconBg: {
        height: ICON_SIZE,
        width: ICON_SIZE,
        borderRadius: ICON_SIZE / 2,
        backgroundColor: baseColors.warnLightest,
        marginTop: spacing.s5 - 2,
    },
    title: {
        ...appFonts.xlBold,
        marginTop: spacing.s1,
        color: baseColors.black,
    },
    subtitle: {
        ...appFonts.smRegular,
        color: baseColors.black,
        paddingHorizontal: spacing.s3,
        marginTop: spacing.s0,
    },
    infoContainer: {
        marginTop: spacing.s5,
    },
    infoText: {
        ...appFonts.xsRegular,
        color: baseColors.grey40,
    },
    infoValue: {
        ...appFonts.smBold,
        color: baseColors.black,
    },
};