import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { REGISTRATION_STEPS as STEPS, REGISTRATION_ITEM_IDS as ITEM_IDS, SAML_SSO_STATUSES } from '../../constants';
import { selectors as coreSelectors, Platform, PLATFORMS } from '../../../core';
import { getSsoDetails, isSso, isPartnerDomain, hasDisclaimerStep, isRetail, getLocalRegistrationStep, hasSsoLoginUrl,
    hasPartnerSso, isLogining, getCompany, isEnabledHRIS, isNoEmailHRIS, isTHVCSSOEnabled, isMutliIntakeHRIS, isHRISLocationNoEmailHRIS, disclaimerAvailable, companyIsHybridLogin } from '../../selectors';
import * as actions from '../../actions';
import { autoLogin, openTelusHealthSignUpUrl } from '../../services/sso';

export default function WithSignUpFlowBase(WrappedComponent) {
    class SignUpFlowBase extends PureComponent {
        static propTypes = {
            isSSO: PropTypes.bool.isRequired,
            hasDisclaimerStep: PropTypes.bool.isRequired,
            isPartnerDomain: PropTypes.bool.isRequired,
            isRetail: PropTypes.bool,
            company: PropTypes.object.isRequired,
            step: PropTypes.string,
            localStep: PropTypes.string,
            partnerSubdomain: PropTypes.string.isRequired,
            actions: PropTypes.object.isRequired,
            ssoDetails: PropTypes.object.isRequired,
            hasSsoLoginUrl: PropTypes.bool.isRequired,
            hasPartnerSso: PropTypes.bool.isRequired,
            isHRISEnabled: PropTypes.bool,
            isNoEmailHRIS: PropTypes.bool,
            isTHVCSSOEnabled: PropTypes.bool,
            isMutliIntakeHRIS: PropTypes.bool,
            isHRISLocationNoEmailHRIS: PropTypes.bool,
            disclaimerAvailable: PropTypes.bool
        };

        static defaultProps = {
            isRetail: false,
            step: undefined,
            localStep: undefined,
            isHRISEnabled: false,
            isNoEmailHRIS: false,
            isTHVCSSOEnabled: false,
            isMutliIntakeHRIS: false,
            isHRISLocationNoEmailHRIS: false,
            disclaimerAvailable: false
        };

        determineRegistrationStep = (completedStep, userSelection) => {
            switch (completedStep) {
                case STEPS.programDomain: {
                    if (userSelection === ITEM_IDS.findProgramDomain) {
                        return STEPS.findProgramDomain;
                    }
                    if (this.props.companyIsHybridLogin) {
                        return STEPS.hybridLoginSelection;
                    }
                    if (this.props.isTHVCSSOEnabled) {
                        return STEPS.thvcSignUpSelection;
                    }
                    if (this.props.hasSsoLoginUrl) {
                        return STEPS.signUpSelection;
                    }
                    if (this.props.isRetail && this.props.hasPartnerSso) {
                        return STEPS.partnerCustomSSO;
                    }
                    if (this.props.isPartnerDomain) {
                        return STEPS.corporatePin;
                    }
                    if (this.props.isHRISEnabled && this.props.isMutliIntakeHRIS) {
                        return STEPS.HRISCountrySelection;
                    }
                    if (this.props.isHRISEnabled && this.props.isNoEmailHRIS) {
                        return STEPS.employeeIDVerification;
                    }
                    return STEPS.register;
                }
                case STEPS.employeeIDVerification: {
                    return STEPS.register;
                }
                case STEPS.HRISCountrySelection: {
                    if (this.props.isHRISEnabled && this.props.isHRISLocationNoEmailHRIS) {
                        return STEPS.employeeIDVerification;
                    }
                    return STEPS.register;
                }
                case STEPS.findProgramDomain: {
                    return STEPS.programDomain;
                }
                case STEPS.partnerCustomSSO: {
                    return STEPS.signUpSelection;
                }
                case STEPS.signUpSelection: {
                    if (userSelection === ITEM_IDS.saml) {
                        if (_.get(this.props.ssoDetails, 'status') === SAML_SSO_STATUSES.login) {
                            return STEPS.autoLogin;
                        } else if (_.get(this.props.ssoDetails, 'status') === SAML_SSO_STATUSES.registration) {
                            return STEPS.register;
                        }
                        return STEPS.signUpSelection; // if error stay on same page ?
                    }
                    return userSelection === ITEM_IDS.corporate ? STEPS.corporatePin : STEPS.register;
                }
                case STEPS.corporatePin: {
                    return STEPS.register;
                }
                case STEPS.register: {
                    return STEPS.location;
                }
                case STEPS.location: {
                    if (this.props.hasDisclaimerStep && this.props.disclaimerAvailable) {
                        return STEPS.disclaimer;
                    }
                    if (this.props.isSSO) {
                        return STEPS.autoLogin; // skip the verification step, user is verified by sso
                    }
                    return STEPS.verify;
                }
                case STEPS.disclaimer: {
                    return this.props.isSSO ? STEPS.autoLogin : STEPS.verify;
                }
                case STEPS.hybridLoginSelection: {
                    return STEPS.register;
                }
                default:
                    break;
            }
        };

        get isMobile() {
            return Platform.OS !== PLATFORMS.web;
        }

        get defaultStep() {
            const { isRetail } = this.props;
            if (isRetail) {
                return STEPS.partnerCustomSSO;
            }
            return STEPS.programDomain;
        }

        openNextPage = (step, userSelection = '', props = {}) => {
            const nextStep = this.determineRegistrationStep(step, userSelection);

            if (nextStep === STEPS.HRISCountrySelection) {
                this.props.actions.getHRISLocations();
            }

            console.log(`SignUpFlowBase::openNextPage: ${step} userSelection: ${userSelection} nextStep: ${nextStep}`);
            if (nextStep === STEPS.autoLogin) {
                autoLogin(this.props.ssoDetails, this.props.actions, this.props.company);
                return;
            }
            if (nextStep === STEPS.thvcSSO) {
                openTelusHealthSignUpUrl();
                return;
            }
            if (!this.wrapped.openNextPage) throw new Error('openNextPage should be implemented in wrapped component');
            this.wrapped.openNextPage(nextStep, userSelection, props);
        };

        saveRef = ref => (this.wrapped = ref);

        updateLocalRegistrationStep = nextStep => {
            this.props.actions.updateLocalRegistrationStep(nextStep);
        };

        get step() {
            return this.props.step || this.defaultStep;
        }

        render() {
            return (
                <WrappedComponent
                    ref={this.saveRef}
                    {...this.props}
                    openNextPage={this.openNextPage}
                    step={this.step}
                    originStep={this.props.step}
                    updateLocalRegistrationStep={this.updateLocalRegistrationStep}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        return {
            step: ownProps.step || _.get(ownProps, 'match.params.step') || _.get(ownProps, 'route.params.step'),
            ssoDetails: getSsoDetails(state),
            isSSO: isSso(state),
            hasSsoLoginUrl: hasSsoLoginUrl(state),
            company: getCompany(state),
            isPartnerDomain: isPartnerDomain(state),
            hasDisclaimerStep: hasDisclaimerStep(state),
            disclaimerAvailable: disclaimerAvailable(state),
            isRetail: isRetail(state),
            partnerSubdomain: coreSelectors.getPartnerSubdomain(state),
            localStep: getLocalRegistrationStep(state),
            hasPartnerSso: hasPartnerSso(state),
            isLogining: isLogining(state),
            isHRISEnabled: isEnabledHRIS(state),
            isNoEmailHRIS: isNoEmailHRIS(state),
            isTHVCSSOEnabled: isTHVCSSOEnabled(state),
            isMutliIntakeHRIS: isMutliIntakeHRIS(state),
            isHRISLocationNoEmailHRIS: isHRISLocationNoEmailHRIS(state),
            companyIsHybridLogin: companyIsHybridLogin(state)
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(SignUpFlowBase);
}
