import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import WithCommunityMembersBase from './CommunityMembersBase';
import { components as Core, getUserName } from '../../../core';

class CommunityMembers extends PureComponent {
    static propTypes = {
        members: PropTypes.array.isRequired,
        title: PropTypes.string.isRequired,
        loadMore: PropTypes.func.isRequired,
        isPrivate: PropTypes.func.isRequired,
        isLoading: PropTypes.bool
    };

    static defaultProps = {
        isLoading: false
    };

    renderAttendeeItem = (item, onPress) => (
        <Core.AttendeeItem
            imageUrl={item.imageURL}
            title={getUserName(item)}
            onPress={onPress}
            id={item.userId}
            isPrivate={this.props.isPrivate(item)}
        />
    );

    render() {
        return (
            <Core.SmallerContainer
                widerHeaderComponent={(
                    <Core.EntityDetailsHeader
                        hasBackButton={true}
                    />)
                }>
                <Core.SubHeader
                    title={this.props.title}
                    noTopPadding={true}
                />
                <Core.AttendeesList
                    members={this.props.members}
                    loadMore={this.props.loadMore}
                    isLoading={this.props.isLoading}
                    renderAttendee={this.renderAttendeeItem}
                />
            </Core.SmallerContainer>
        );
    }
}

export default WithCommunityMembersBase(CommunityMembers);