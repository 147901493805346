import media from '../media';
import spacing from '../spacing';

export * from './constantsBase';

export const windowSize = {
    width: window.innerWidth,
    height: window.innerHeight,
    get containerWidth() { return window.innerHeight >= media.values.sm ? window.innerWidth / 2 : window.innerWidth; },
    isLg: window.innerWidth >= media.values.md
};

export const inputButtonSize = spacing.s4;
