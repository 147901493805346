import axios from 'axios';
import _ from 'lodash';

import { getBaseUrl, Alert, getTimezoneHeader, USE_V2_API } from '../core';
import i18n from '../../i18n/i18n';
import global from '../../config/globals';
import systemBuildVariant, { BUILDS } from '../../config/buildVariant';
import { SMART_MODE_PROPERTY } from './constants';
import { API_ENDPOINTS } from '../settings/api';

let intercept401Unauthorized;
let intercept401UnauthorizedAlerted;

const isTelus = systemBuildVariant === BUILDS.telus;
const showUnspecified = '?showUnspecified=0';

// 'User-Agent': `Sprout/${getFullAppVersion()} (${Platform.OS})`
const setCommonHeaders = c => {
    _.assign(c.headers, { 'Accept-Language': i18n.acceptLanguage() }, getTimezoneHeader());
};

// Set default API timeout to 45s
axios.defaults.timeout = 45 * 1000;

axios.interceptors.request.use(config => {
    const c = config;
    const loginURL = global.systemEnvironment.SPROUT_LOGIN_URL;
    if (config.url.startsWith(loginURL)) {
        setCommonHeaders(c);
    }
    else if (config.url.startsWith(getBaseUrl('')) || config.url.startsWith(getBaseUrl('', USE_V2_API))) {
        if ((global.token !== '') && !config.headers.getAuthorization()) {
            c.headers.setAuthorization(`sprout-token ${global.token}`);
        }
        else if (config.url.indexOf('/auth/') === -1 && config.url.indexOf('/password/reset') === -1 && config.url.indexOf(API_ENDPOINTS.userUnsubscribeSettings) === -1) {
            throw new Error(`No token for: ${config.url}`);
        }
        setCommonHeaders(c);
    }
    return c;
}, error => Promise.reject(error));

export function setIntercept401Unauthorized(logout, getLoginState) {
    intercept401UnauthorizedAlerted = false;
    intercept401Unauthorized = axios.interceptors.response.use(
        response => {
            return response;
        },
        error => {
            // Do something with response error
            if (_.get(error, 'response.status')) {
                if (error.response.status === 401) {
                    axios.interceptors.response.eject(intercept401Unauthorized);
                    if (!intercept401UnauthorizedAlerted) {
                        intercept401UnauthorizedAlerted = true;
                        getLoginState() && Alert.alert ? setTimeout(() => Alert.alert(
                            '',
                            i18n.t('error_401_signed_out_message'),
                            [
                                {
                                    text: 'OK',
                                    onPress: () => {
                                        intercept401UnauthorizedAlerted = false;
                                        logout();
                                    }
                                }
                            ],
                            { cancelable: false }
                        ), 3000) : logout();
                    }
                }
            }
            throw error;
        }
    );
}

export function eject() {
    axios.interceptors.response.eject(intercept401Unauthorized);
}

export function setValidateStatus(func) {
    axios.defaults.validateStatus = func;
}

const getLoginUrl = endpoint => endpoint
    ? `${global.systemEnvironment.SPROUT_LOGIN_URL}${endpoint}`
    : global.systemEnvironment.SPROUT_LOGIN_URL;

export function queryDirectory(resource, env, key) {
    return axios.get(`https://directory.sproutatwork.media/${resource}/${env}/${key}`);
}

export function queryDirectoryEmail(resource, env, email, domain) {
    return axios.get(`https://directory.sproutatwork.media/${resource}/${env}/${email}?d=${domain}`);
}

export function login(params) {
    const postURL = params.ssotype ? getLoginUrl('auth/login_sso') : getLoginUrl('auth/login');
    return axios.post(postURL, params);
}

export function logout(params = {}) {
    const postURL = getBaseUrl('auth/logout');
    return axios.post(postURL, params);
}

export function getCompanyConfiguration(params) {
    let url;
    if (params.companyId) {
        url = getBaseUrl(`companies/${params.companyId}`);
    }
    else if (params.partnerId) {
        url = getBaseUrl(`partner_retail/${params.partnerId}`);
    }
    else {
        throw new Error('No companyId/partnerId');
    }
    return axios.get(url);
}

export function getProgramConfigurations(params) {
    if (!_.get(params, 'skipSubdomainSave', false) && params.programName) {
        global.systemSubdomain = params.programName;
    }
    return axios.get(getLoginUrl('companies'), { params });
}

export function refreshToken(rememberMe) {
    return axios.put(getBaseUrl('auth/refresh_token'), { rememberMe });
}

export function ssoLinkUser(params) {
    return axios.post(getBaseUrl('linked_accounts'), params);
}

export function generateCorporateAccountLinkEmail(params) {
    return axios.post(getBaseUrl('linked_corporate_accounts/tokens'), params);
}

export function linkCorporateAccount(params) {
    return axios.post(getBaseUrl('linked_corporate_accounts'), params);
}

export function unlinkCorporateAccount(params) {
    return axios.delete(getBaseUrl('linked_corporate_accounts'), params);
}

export function disconnectExternalAccount(data) {
    return axios.delete(getBaseUrl('linked_accounts'), { data });
}

export function getEmailProgramName(email) {
    return axios.post(getLoginUrl('tenants/email_program_name'), { email });
}

export function getTermsOfService(params) {
    const url = params.isRetail
        ? getLoginUrl(`partners/${params.partnerId}/agreements/terms`)
        : getLoginUrl(`companies/${params.companyId}/agreements/terms`);
    return axios.get(url);
}

export function getPrivacyPolicy(params) {
    const url = params.isRetail
        ? getLoginUrl(`partners/${params.partnerId}/agreements/privacy`)
        : getLoginUrl(`companies/${params.companyId}/agreements/privacy`);
    return axios.get(url);
}

export function getPolicyPopups() {
    return axios.get(getBaseUrl('users/agreement/popup'));
}

export function markPolicySeen(params) {
    return axios.put(getBaseUrl('users/agreement/popup'), params);
}

export function createAccount(params) {
    if (params.validateOnly) {
        return axios.post(getBaseUrl('users/validate_registration_fields'), params);
    }
    return axios.post(getLoginUrl('users'), params);
}

export function deleteAccount() {
    return axios.delete(getBaseUrl('users'));
}

export function getLocationsList(companyId, partnerId) {
    if (!companyId || companyId === '0') {
        let path = `partners/${partnerId}/locations`;
        if (isTelus) {
            path = `${path}${showUnspecified}`;
        }
        return axios.get(getLoginUrl(path));
    }
    let path = `companies/${companyId}/locations`;
    if (isTelus) {
        path = `${path}${showUnspecified}`;
    }
    return axios.get(getLoginUrl(path));
}

export function getDepartmentsList(companyId, locationId) {
    const params = locationId ? { location_id: locationId }: null;
    return axios.get(getBaseUrl(`companies/${companyId}/departments`), { params });
}

export function getRegions(companyId) {
    return axios.get(getBaseUrl(`companies/${companyId}/regions`));
}


export function updateUser(params) {
    return axios.put(getBaseUrl('users'), params);
}

export function getUser() {
    return axios.get(getBaseUrl('users'));
}

export function getUserLocations() {
    let path = 'users/locations';
    if (isTelus) {
        path = `${path}${showUnspecified}`;
    }
    return axios.get(getBaseUrl(path));
}

export function updateUserLocations(params) {
    return axios.put(getBaseUrl('users/locations'), params);
}

export function createUserLocations(params) {
    return axios.post(getBaseUrl('users/locations'), params);
}

export function verifyEmail() {
    return axios.get(getBaseUrl('auth/verified'));
}

export function getDisclaimer(params) {
    const url = params.isRetail
        ? getLoginUrl(`partners/${params.disclaimerId}/agreements/disclaimer`)
        : getLoginUrl(`companies/${params.disclaimerId}/agreements/disclaimer`);
    return axios.get(url);
}

export function resetPassword(email) {
    return axios.post(getLoginUrl('users/password/reset'), { email });
}

export function checkGUIDStatus(guid) {
    return axios.get(getLoginUrl(`auth/guid_status/${guid}`));
}

export function emailVerificationStepOne(params) {
    // Call will fail if has authorization token, so use blank axios instance
    const axiosDefault = axios.create();
    return axiosDefault.post(getLoginUrl('auth/login/verify'), params);
}

export function emailVerificationStepTwo(params) {
    // Call will fail if has authorization token, so use blank axios instance
    const axiosDefault = axios.create();
    return axiosDefault.post(getLoginUrl('auth/verify'), params);
}

export function completeRegistration() {
    return axios.put(getBaseUrl('users/registration_done'), false);
}

export function checkGoalSmartModeStatus(userId) {
    return axios.get(getBaseUrl(`users/user_properties?user_id=${userId}&property_name=SMART_MODE`));
}

export function updateGoalSmartModeStatus(params) {
    const allParams = { ...params, property_name: SMART_MODE_PROPERTY };
    return axios.put(getBaseUrl('users/user_properties'), allParams);
}

export function checkEmailRegistrationStatus(email) {
    return axios.get(getLoginUrl(`auth/login_info?email=${email}`));
}

export function checkEmployeeIDExists(companyId, employeeId) {
    return axios.get(getLoginUrl(`companies/${companyId}/employee_id/${employeeId}`));
}

export function getHRISLocations(companyId) {
    return axios.get(getLoginUrl(`companies/${companyId}/locations?isCountries=1&hris=1`));
}

export function getCompanyExternalApps(companyId) {
    return axios.get(getBaseUrl('external_apps'));
}