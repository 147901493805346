import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import classnames from 'classnames';
import { spacing, layoutStyle, importantClass } from '../../../../styles';
import FeedItem from '../FeedItem';
import { components as Core, animations } from '../../../core';
import WithFeedDetailsBase, { INPUT_MAX_LENGTH, styles as baseStyles } from './FeedDetailsBase';
import { components as Settings, constants as settingsConstants } from '../../../settings';
import { constants as toursConstants } from '../../../tours';

const FOOTER_HEIGHT = 62;
const ARROW_OFFSET = 80;

class FeedDetails extends PureComponent {
    static propTypes = {
        streamItemId: PropTypes.number.isRequired,
        autoFocus: PropTypes.bool,
        i18n: PropTypes.object.isRequired,
        visibleCommentsCount: PropTypes.number.isRequired,
        onRefComments: PropTypes.func,
        onChange: PropTypes.func.isRequired,
        postComment: PropTypes.func.isRequired,
        commentText: PropTypes.string,
        isDisabled: PropTypes.bool,
        isLoading: PropTypes.bool,
        hasStream: PropTypes.bool,
        isEditable: PropTypes.bool,
        history: PropTypes.object.isRequired
    };

    static defaultProps = {
        onRefComments: undefined,
        commentText: '',
        autoFocus: false,
        isDisabled: false,
        isLoading: false,
        hasStream: false,
        isEditable: true
    };

    postComment = () => {
        if (this.props.isDisabled) return;
        this.props.postComment();
        setTimeout(() => this.scrollToEnd());
    };

    scrollToEnd = () => animations.scrollToEnd();

    onChange = event => this.props.onChange(event.target.value);

    // todo: add position
    getPosition = () => ({
        width: 0,
        height: 0,
        pageX: ARROW_OFFSET,
        pageY: FOOTER_HEIGHT
    });

    render() {
        const { streamItemId, i18n, autoFocus, visibleCommentsCount, onRefComments, commentText, isDisabled,
            isLoading, hasStream, isEditable, history } = this.props;
        return (
            <Core.Layout.WiderContainer>
                <Core.EntityDetailsHeader
                    hasBackButton={true}
                />
                {!hasStream && !isLoading ?
                    history.goBack() : null}
                {hasStream ?
                    <Core.SmallerContainer isFixedFooter={true}>
                        <FeedItem
                            streamItemId={streamItemId}
                            commentProps={{ visibleCommentsCount, onRefComments }}
                            isDetails={true}
                        />
                        <Core.Layout.FixedFooterSmallerContainer
                            fixedFooterClassName={css(styles.fixedFooterClassName)}
                            isBorder={true}
                            className={css(styles.footer)}>
                            <div className={css(styles.footer)}>
                                <Settings.PrivacyTooltip
                                    slug={settingsConstants.PRIVACY_SLUGS.COMMENT_TO_ACTION}
                                    position={this.getPosition()}
                                    tooltipClassName={css(styles.tooltip)}
                                    arrow={toursConstants.ARROWS.DOWN}
                                    hasBackDrop={true}
                                />
                                <Core.Avatar
                                    isCurrentUser={true}
                                    className={css(styles.userAvatar)}
                                />
                                <Core.Input
                                    id="post"
                                    fullWidth={true}
                                    className={css(styles.footerInput)}
                                    value={commentText}
                                    onChange={this.onChange}
                                    placeholder={`${i18n.t('addCommentPlaceholder')}...`}
                                    autoFocus={autoFocus}
                                    multiline={true}
                                    onFocus={this.scrollToEnd}
                                    maxLength={INPUT_MAX_LENGTH}
                                    InputProps={{ disableUnderline: true }}
                                    margin="none"
                                    disabled={!isEditable}
                                />
                                <Core.Button
                                    type="text"
                                    className={css(styles.footerButton)}
                                    disabled={isDisabled}
                                    onPress={this.postComment}>
                                    <span
                                        className={classnames(css(styles.footerButtonText),
                                            { [css(styles.disabled)]: isDisabled })}>
                                        {i18n.t('postCommentButton')}
                                    </span>
                                </Core.Button>
                            </div>
                        </Core.Layout.FixedFooterSmallerContainer>
                    </Core.SmallerContainer> : null}
                <Core.ListLoadingControlled isLoading={isLoading} />
            </Core.Layout.WiderContainer>
        );
    }
}

export default WithFeedDetailsBase(FeedDetails);

const styles = StyleSheet.create({
    ...baseStyles,
    footer: {
        ...baseStyles.footer,
        display: 'flex',
        position: 'relative',
        paddingBottom: 0
    },
    footerInput: importantClass({
        ...baseStyles.footerInput
    }),
    footerButton: importantClass({
        marginLeft: spacing.s1,
        ...layoutStyle.buttonTextSmall
    }),
    disabled: importantClass({
        ...baseStyles.disabled
    }),
    tooltip: {
        ...importantClass({
            bottom: FOOTER_HEIGHT,
            left: spacing.s3,
            width: '50%',
            position: 'absolute',
            paddingTop: spacing.s3,
            paddingBottom: spacing.s3,
        }),
        zIndex: 2
    },
    fixedFooterClassName: {
        zIndex: '1000 !important'
    }
});
