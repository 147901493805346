import _ from 'lodash';
import { createSelector } from 'reselect';
import { NAME, REFRESH_ALLOWED_DIFF } from './constants';
import * as types from './actionTypes';
import getFilterName from './services/getFilterName';
import { selectors as coreSelectors } from '../core';
import { filtersByTimeAddedOptions } from '../shared/constants';

const getStore = state => state[NAME];
export const getAll = state => state[NAME].items;
export const getContentError = state => coreSelectors.getError(state, types.POST_STREAM.NAME) || coreSelectors.getError(state, types.UPDATE_STREAM.NAME);

export const getRefreshTime = state => state[NAME].refreshTime;
export const isRefreshAllowed = state => (new Date().getTime() - getRefreshTime(state)) > REFRESH_ALLOWED_DIFF;

const selectItemType = (state, type) => type;
const selectItemId = (state, type, id) => id;
export const getFeedsByTypeAndId = createSelector(
    [getStore, selectItemType, selectItemId],
    (items, type, id) => _.get(items, [type, id], [])
);

export const getFeed = (state, id) => getAll(state)[id];

export const getStreamFiltersForSelect = createSelector(
    [coreSelectors.getStreamFilters, coreSelectors.getCurrentUser, coreSelectors.isLiveBetter],
    (filters, user, isLiveBetter) => _.map(filters, filter => ({
        ...filter,
        id: _.get(filter, 'filterId'),
        label: getFilterName(filter, user, isLiveBetter),
        headTitle: filter.displayName
    }))
);

export const getDisableStreamsWeights = state => getStore(state).disableStreamsWeights || filtersByTimeAddedOptions[0];

const getSavedFilter = state => state[NAME].filter;

export const getFilter = createSelector(
    getStreamFiltersForSelect, getSavedFilter,
    (filters, filter) => filter || _.find(filters, filter => filter.isDefault) || filters[0]
);
export const getFilterId = createSelector(getFilter,
    filter => _.get(filter, 'filterId', 0)
);

export const getShareWithFiltersForSelect = createSelector(
    [coreSelectors.getShareWithFilters, coreSelectors.getCurrentUser],
    (filters, user) => _.map(filters, filter => {
        const { displayName } = filter;
        const filterName = getFilterName(filter, user);
        return ({
            ...filter,
            id: _.get(filter, 'filterId'),
            label: displayName === filterName ? displayName : `${displayName} (${filterName})`
        });
    })
);

export const isLoadingStreams = state => coreSelectors.isLoading(state, types.GET_STREAMS.NAME);
export const isLoadingMoreStreams = state => coreSelectors.isLoading(state, types.GET_MORE_STREAMS.NAME);
export const isLoading = state => isLoadingStreams(state) || isLoadingMoreStreams(state);

export const isPosting = state => coreSelectors.isLoading(state, types.POST_STREAM.NAME);
export const isEditing = state => coreSelectors.isLoading(state, types.UPDATE_STREAM.NAME);
export const isPostingOrEditing = state => isPosting(state) || isEditing(state);

export const isLoadingStreamItem = state => coreSelectors.isLoading(state, types.GET_STREAM.NAME);

export const getDailyMetrics = state => state[NAME].dailyMetrics;
export const isLoadingDailyMetrics = state => coreSelectors.isLoading(state, types.GET_DAILY_METRICS.NAME);

export const getReactionsForStreamItem = (state, itemId) => _.get(state[NAME].items[itemId], 'reactions', undefined);
export const getReactionsCountForStreamItem = (state, itemId) => _.get(state[NAME].items[itemId], 'reactionsCount', 0);
export const getUniqueReactionsCountForStreamItem = (state, itemId) => _.get(state[NAME].items[itemId], 'reactionsUsersCount', 0);
export const getUserReactionsListForStreamItem = (state, itemId) => _.get(state[NAME].items[itemId], 'userReactionList', []);

export const getCountForItemReactionSlug = createSelector(getReactionsForStreamItem, coreSelectors.getThirdParam, (reactions, slug) =>
    _.get(_.find(reactions, reaction => reaction.slug === slug), 'count', 0));