export const NAME = 'events';
export const MAX_COUNT = 24;

export const EVENT_ENTITY_TYPES = {
    ALL_ITEMS: 'allItems',
    RECOMMENDED: 'recommended',
    INVITED: 'invited',
    MY_ITEMS: 'myItems',
    PAST: 'pastAttended',
    USER: 'user'
};

export const END_DATE_DELTA = 1;
export const CREATE_EVENT_TEXTAREA_HEIGHT = 100;

export const PRIVACY_FIELDS = { private: 0, public: 1 };
export const EVENT_TYPES = {
    company: 'company',
    user: 'user'
};

export const ALL_ID = 'all';
export const SELECTED_ID = 'selected';

export const CREATE_INPUTS = Object.freeze({
    maxParticipants: 'maxParticipants',
    type: 'type',
    associatedLocation: 'associatedLocation',
    department: 'department',
    points: 'points',
    cost: 'cost',
    name: 'name',
    picture: 'picture',
    privacy: 'privacy',
    startDate: 'startDate',
    startTime: 'startTime',
    endTime: 'endTime',
    location: 'location',
    description: 'description'
});

export const NOT_PERSISTED = false;
