import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as actions from '../../../actions';
import { CHALLENGE_ENTITY_TYPES } from '../../../constants';
import { getChallenge, getChallengeType } from '../../../selectors';
import { constants as invitationConstants } from '../../../../invitations';
import { baseColors, spacing, detailsImageSize } from '../../../../../styles';
import { getImage, isMember, getDeletionString, getChallengeOptions } from '../../../services/helper';
import { translate, Alert, selectors as coreSelectors, Platform, PLATFORMS, ROUTES } from '../../../../core';


export default function WithChallengeDetailsHeaderBase(WrappedComponent) {
    class ChallengeDetailsHeaderBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            challenge: PropTypes.object.isRequired,
            role: PropTypes.string.isRequired,
            challengeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            isExtendedRole: PropTypes.bool.isRequired,
            navigation: PropTypes.object,
        };

        static defaultProps = {
            navigation: {}
        };

        constructor(props) {
            super(props);
            this.wrapped = React.createRef();
            this.deleteChallengeOption = {
                title: this.deleteChallengeTitle,
                onPress: this.deleteChallengePress
            };
            this.leaveChallengeOption = {
                title: props.i18n.t('leaveChallenge.menu', { challengeName: props.challenge.challengeName }),
                onPress: this.leaveChallenge
            };
        }

        deleteChallengePress = () => {
            _.has(this.wrapped, 'current.showDeletionWarning') && this.wrapped.current.showDeletionWarning();
        };

        _openAlert = (title, text, okHandler) => {
            const { i18n } = this.props;
            Alert.alert(title, text, [
                { text: i18n.t('ok'), onPress: okHandler, isPrimary: true },
                { text: i18n.t('button_cancel'), style: 'cancel' },
            ]);
        };

        leaveChallenge = () => {
            const { challenge, i18n, navigation, actions } = this.props;
            let leaveMessage = i18n.t('leave_goal_confirmation');
            //check if team challenge
            if (_.get(challenge, 'userEntity.entityType') !== CHALLENGE_ENTITY_TYPES.user) {
                if (_.get(challenge, 'userEntity.numberOfUsers', 0) === '1') {
                    leaveMessage = `${i18n.t('leaveTeam')} ${challenge.userEntity.name} ${i18n.t('leaveTeamLastMember')}`;
                } else {
                    leaveMessage = `${i18n.t('leaveTeam')} ${challenge.userEntity.name} ${i18n.t('leaveTeamChallenge')}`;
                }
            }

            if (Platform.OS !== PLATFORMS.web) {
                navigation.push(ROUTES.infoModal(), {
                    title: i18n.t('areYouSure'),
                    text: leaveMessage,
                    isButtonVisible: false,
                    buttons: [
                        { text: i18n.t('button_leave_challenge'), onPress: () => actions.leaveChallenge(challenge.challengeId), isDangerText: true },
                        { text: i18n.t('button_cancel') },
                    ],
                });
            }
        };

        getWarningProps = () => {
            const { i18n, challenge } = this.props;
            return {
                isButtonVisible: false,
                title: i18n.t('areYouSure'),
                text: getDeletionString(challenge),
                checkboxLabel: i18n.t('deleteCommunityCheckbox'),
            };
        };

        deleteChallenge = () => this.props.actions.deleteChallenge(this.props.challenge.challengeId);

        get deleteChallengeTitle() {
            return this.props.i18n.t('deleteChallenge.menu', { challengeName: this.props.challenge.challengeName });
        }

        // todo: refactor this
        getOptions = (inviteCallback, updateCallback) => {
            const { isExtendedRole, role, challenge } = this.props;
            return getChallengeOptions(inviteCallback, updateCallback, isExtendedRole, role, challenge, this.leaveChallengeOption, this.deleteChallengeOption);
        };

        getDestructiveButtonIndex = () => _.findIndex(this.getOptions(), { title: this.deleteChallengeTitle });

        getInviteToChallengeProps = () => ({
            entityId: this.props.challengeId,
            entity: invitationConstants.ENTITIES.CHALLENGE
        });

        get challengeIcon() {
            // Hid challenge icon is hidden on the header image
            return null;
            // if (this.props.challenge.challengeType !== CHALLENGE_TYPES.competition) return null;
            // if (this.props.challenge.challengeSubentityType === CHALLENGE_SUBENTITY_TYPES.companyTeam) return 'building';
            // switch (this.props.challenge.challengeEntityType) {
            //     case CHALLENGE_ENTITY_TYPES.company:
            //     case CHALLENGE_ENTITY_TYPES.partner:
            //     case CHALLENGE_ENTITY_TYPES.department:
            //     case CHALLENGE_ENTITY_TYPES.region:
            //         return 'building';
            //     case CHALLENGE_ENTITY_TYPES.program:
            //         return 'address-card';
            //     default:
            //         return 'users';
            // }
        }

        get image() {
            return getImage(this.props.challenge);
        }

        render() {
            return (
                <WrappedComponent
                    ref={this.wrapped}
                    {...this.props}
                    getWarningProps={this.getWarningProps}
                    image={this.image}
                    deleteChallenge={this.deleteChallenge}
                    challengeIcon={this.challengeIcon}
                    getOptions={this.getOptions}
                    getDestructiveButtonIndex={this.getDestructiveButtonIndex}
                    isMember={isMember(this.props.challenge)}
                    getInviteToChallengeProps={this.getInviteToChallengeProps}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => ({
        challenge: getChallenge(state, ownProps.challengeId),
        role: coreSelectors.getCurrentUserRole(state),
        isExtendedRole: coreSelectors.isUserExtendedRole(state),
        challengeType: getChallengeType(state, ownProps.challengeId),
    });

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(ChallengeDetailsHeaderBase));
}

export const styles = {
    cardHeader: {
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'space-between',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0
    },
    headerButton: {
        backgroundColor: 'transparent',
        padding: spacing.s1
    },
    healthCategoryIconContainer: {
        margin: spacing.s3,
        alignItems: 'center',
        justifyContent: 'center',
        height: spacing.s10,
        width: spacing.s10,
        borderRadius: spacing.s4,
        backgroundColor: baseColors.secondary
    },
    image: {
        // width: windowSize.width,
        // height: spacing.s4 * 10,
        height: detailsImageSize,
        // flexDirection: 'row',
        // flex: 1,
        // justifyContent: 'center',
        // alignItems: 'flex-end'
    },
};
