import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { DEFAULT_ICON_CATEGORY as DEFAULT_ICON, ICON_CATEGORIES, translate, selectors as coreSelectors, Platform, PLATFORMS } from '../../../core';
import * as selectors from '../../selectors';

export default function WithGoalIconBase(WrappedComponent) {
    class GoalIconBase extends PureComponent {
        static propTypes = {
            className: PropTypes.string,
            goal: PropTypes.object,     //eslint-disable-line
            size: PropTypes.number.isRequired,
            slug: PropTypes.string,
            category: PropTypes.string,
            iconUrl: PropTypes.string,
            isLiveBetter: PropTypes.bool,
            onPersonalGoalCard: PropTypes.bool,
        };

        static defaultProps = {
            className: null,
            goal: null,
            slug: undefined,
            category: undefined,
            iconUrl: null,
            isLiveBetter: false,
            onPersonalGoalCard: false,
        };

        get isWeb() {
            return Platform.OS === PLATFORMS.web;
        }

        get categoryIcon() {
            const { category } = this.props;
            if (this.props.slug) return _.get(ICON_CATEGORIES, [this.props.slug, 'bgr']) || DEFAULT_ICON.bgr;
            if (!category || !_.has(ICON_CATEGORIES, category)) return DEFAULT_ICON.bgr;
            return ICON_CATEGORIES[category].bgr;
        }

        get goalIcon() {
            const { iconUrl, isLiveBetter } = this.props;
            if (!isLiveBetter) {
                if (iconUrl) {
                    const url = iconUrl.iconUrl ? iconUrl.iconUrl : iconUrl;

                    return !this.isWeb ? { uri: url } : url;
                }

                return require('../../../../image/goals/goal_default_disabled.png');
            }

            return null;
        }

        get noIconUrl() {
            const { isLiveBetter, onPersonalGoalCard } = this.props;
            return !isLiveBetter && !_.get(this.props, 'goal.icon_url') && !_.get(this.props, 'goal.challengeIconURL') && onPersonalGoalCard;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    categoryIcon={this.categoryIcon}
                    goalIcon={this.goalIcon}
                    noIconUrl={this.noIconUrl}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        let iconUrl = null;
        if (_.get(ownProps, 'goal')) {
            iconUrl = _.get(ownProps, 'goal.challengeId') ? selectors.getGoalChallengeIcon(state, _.get(ownProps, 'goal.challengeId')) : selectors.getHeaderIconRecommendedGoal(state, _.get(ownProps, 'goal.goalName'));
        }
        return {
            category: selectors.getChallengeCategorySlug(state, _.get(ownProps, 'goal.challengeId')),
            iconUrl,
            isLiveBetter: coreSelectors.isLiveBetter(state)
        };
    };

    return connect(mapStateToProps)(translate()(GoalIconBase));
}

const BORDER_RADIUS = 90;

export const styles = {
    imageContainer: {
        borderRadius: BORDER_RADIUS,
        overflow: 'hidden'
    }
};