import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { css } from 'aphrodite-jss';
import classnames from 'classnames';

import { layoutStyle } from '../../../../../styles';
import { components as Core } from '../../../../core';
import WithGoalCardBase from './GoalCardButtonBase';

class GoalCard extends PureComponent {
    static propTypes = {
        onPress: PropTypes.func.isRequired,
        label: PropTypes.string.isRequired,
        challenge: PropTypes.object.isRequired,
        isLoading: PropTypes.bool,
        isFadeInText: PropTypes.bool,
        fullWidth: PropTypes.bool,
        className: PropTypes.string,
        type: PropTypes.string,
        disableRipple: PropTypes.bool,
        halfOpacityButton: PropTypes.bool,
    };

    static defaultProps = {
        isLoading: false,
        isFadeInText: false,
        fullWidth: false,
        className: undefined,
        type: undefined,
        disableRipple: false,
        halfOpacityButton: false,
    };

    render() {
        const { challenge, onPress, fullWidth, isLoading, isFadeInText, label,
            type, disableRipple, className, halfOpacityButton } = this.props;
        const id = _.camelCase(`${challenge.categoryName} ${challenge.activity_slug}SetButton`);
        return (
            <Core.Button
                id={id}
                onPress={onPress}
                type={type}
                size="medium"
                fullWidth={fullWidth}
                disableRipple={disableRipple}
                className={classnames(css(layoutStyle.footerButton), className)}
                isLoading={isLoading}
                disabled={isLoading}
                disableElevation={halfOpacityButton}
                halfOpacityButton={halfOpacityButton}
                isSettingPersonalGoal={true}
                isFadeInText={isFadeInText}>
                {label}
            </Core.Button>
        );
    }
}

export default WithGoalCardBase(GoalCard);

