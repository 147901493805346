import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { translate, selectors as coreSelectors, tracker } from '../../../core';
import { appFonts, baseColors, spacing } from '../../../../styles';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import { formatGoalForSetting } from '../../services/helper';

export default function WithGoalCarouselItemBase(WrappedComponent) {
    class GoalCarouselItemBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            goal: PropTypes.object.isRequired,
            i18n: PropTypes.object.isRequired,
            isSetting: PropTypes.bool,
            userId: PropTypes.number.isRequired,
            slug: PropTypes.string.isRequired
        };

        static defaultProps = {
            isSetting: false
        };

        setGoal = () => {
            tracker.logEvent('MePage_Goal_Set');
            this.props.actions.setGoal(formatGoalForSetting(this.props.goal, this.props.userId));
        };

        get isGoalSet() {
            return _.has(this.props.goal, 'challengeId');
        }

        get name() {
            return this.isGoalSet ? this.props.goal.challengeName : this.props.goal.goalName;
        }

        get description() {
            return this.isGoalSet ? this.props.goal.challengeInstruction : this.props.goal.instruction;
        }

        get buttonLabel() {
            return this.isGoalSet ? this.props.i18n.t('viewProgress.progress') : this.props.i18n.t('start_goal');
        }

        get imageUrl() {
            return this.isGoalSet ? this.props.goal.challengeImageURL : this.props.goal.image_url;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    buttonLabel={this.buttonLabel}
                    isGoalSet={this.isGoalSet}
                    name={this.name}
                    description={this.description}
                    imageUrl={this.imageUrl}
                    setGoal={this.setGoal}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => ({
        isSetting: selectors.isSettingPersonalGoal(state, ownProps.goal.goalName),
        userId: coreSelectors.getCurrentUserId(state),
        isSettingGoals: selectors.isSettingPersonalGoals(state)
    });

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(GoalCarouselItemBase));
}

export const CAROUSEL_ITEM_WIDTH = 294;
export const CAROUSEL_ITEM_HEIGHT = 218;

export const styles = {
    iconContainer: {
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center'
    },
    name: {
        color: baseColors.black,
        ...appFonts.mdBold,
        marginLeft: spacing.s1
    },
    description: {
        color: baseColors.black,
        ...appFonts.smRegular,
        marginTop: spacing.s1
    },
    button: {
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3,
    },
    bonusMarkContainer: {
        marginTop: spacing.s1,
    }
};
