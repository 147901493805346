import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { css, StyleSheet } from 'aphrodite-jss';
import { isIE } from 'react-device-detect';

import WithPersonalEditorBase, { styles as baseStyles } from './PersonalEditorBase';
import { components as Core, constants as coreConstants, refreshCacheAndReload } from '../../../../core';
import { STATE_KEYS, NUMBER_INPUT_MAX_LENGTH } from '../../../constants';
import { constants as onboardingConstants } from '../../../../onboarding';
import { layoutStyle, importantStyles, spacing } from '../../../../../styles';

class PersonalEditor extends Component {
    static propTypes = {
        personalFields: PropTypes.array,    //eslint-disable-line
        userPreferredHeightUnit: PropTypes.string,  //eslint-disable-line
        userPreferredWeightUnit: PropTypes.string,  //eslint-disable-line
        errors: PropTypes.object,
        saveChanges: PropTypes.func,
        getFieldByStateKey: PropTypes.func,
        onGenderChange: PropTypes.func,
        dateOfBirthValue: PropTypes.instanceOf(Date),
        onRef: PropTypes.func,
        onDateChange: PropTypes.func,
        updatePersonalValue: PropTypes.func,
        onUnitHeightChange: PropTypes.func,
        onUnitWeightChange: PropTypes.func,
        genderValue: PropTypes.string,
        isLoading: PropTypes.bool.isRequired,
        isSaving: PropTypes.bool.isRequired,
        i18n: PropTypes.object.isRequired,
        actions: PropTypes.object.isRequired,
        isUpdatingUser: PropTypes.bool.isRequired,
        feetLabel: PropTypes.string.isRequired,
        inchesOrCmLabel: PropTypes.string.isRequired,
    };

    static defaultProps = {
        errors: {},
        onRef: null,
        saveChanges: null,
        getFieldByStateKey: null,
        onGenderChange: null,
        dateOfBirthValue: undefined,
        onDateChange: null,
        updatePersonalValue: null,
        onUnitHeightChange: null,
        onUnitWeightChange: null,
        genderValue: ''
    };

    componentDidMount() {
        if (this.props.onRef) this.props.onRef(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isUpdatingUser && !this.props.isUpdatingUser && !isIE) {
            refreshCacheAndReload();
        }
    }

    componentWillUnmount() {
        if (this.props.onRef) this.props.onRef(null);
    }

    onHeightChange = prop => e => {
        const { value } = e.target;
        this.props.updatePersonalValue(this.props.getFieldByStateKey(STATE_KEYS.heightInches), parseFloat(value) || '', prop);
    };

    onWeightChange = e => {
        const { value } = e.target;
        this.props.updatePersonalValue(this.props.getFieldByStateKey(STATE_KEYS.weightLbs), parseInt(value));
    };

    onGenderChange = gender => this.props.onGenderChange(gender.id);


    get heightFields() {
        const { userPreferredHeightUnit, feetLabel, inchesOrCmLabel, getFieldByStateKey, errors, onUnitHeightChange, i18n } = this.props;
        const showTwoFields = userPreferredHeightUnit !== onboardingConstants.CM;
        const inchesOrCm = (showTwoFields) ? onboardingConstants.IN : onboardingConstants.CM;
        const hValue = _.get(getFieldByStateKey(STATE_KEYS.heightInches), 'value');
        const ftValue = _.get(getFieldByStateKey(STATE_KEYS.heightInches), 'ftValue');

        const leftInput = { root: css(layoutStyle.twoButtonsLeft) };
        const rightInput = { root: css(layoutStyle.flex1) };

        return (
            <div className={css(layoutStyle.flex)}>
                {showTwoFields ? (
                    <Core.UnitField
                        classes={leftInput}
                        unitOptions={_.map(onboardingConstants.UNIT_OPTIONS.HEIGHT_FT, unit => unit)}
                        unit={i18n.t(onboardingConstants.FT)}
                        label={feetLabel}
                        onChange={this.onHeightChange(STATE_KEYS.feetValue)}
                        value={ftValue}
                        helperText={errors.heightInches}
                        error={!!errors.heightInches}
                        onUnitChange={onUnitHeightChange}
                        inputProps={{ maxLength: NUMBER_INPUT_MAX_LENGTH, 'aria-label': feetLabel }}
                    />
                ) : null}
                <Core.UnitField
                    fullWidth={true}
                    classes={rightInput}
                    unitOptions={_.map(onboardingConstants.UNIT_OPTIONS.HEIGHT_IN, unit => unit)}
                    unit={i18n.t(inchesOrCm)}
                    label={inchesOrCmLabel}
                    onChange={this.onHeightChange()}
                    value={hValue}
                    helperText={!showTwoFields ? errors.heightInches : null}
                    error={!!errors.heightInches}
                    onUnitChange={onUnitHeightChange}
                    inputProps={{ maxLength: NUMBER_INPUT_MAX_LENGTH, 'aria-label': inchesOrCmLabel }}
                />
            </div>
        );
    }

    render() {
        const { isLoading, isSaving, getFieldByStateKey, genderValue, dateOfBirthValue, onDateChange, onUnitWeightChange,
            i18n, personalFields, errors, userPreferredWeightUnit, saveChanges } = this.props;
        const genderKey = getFieldByStateKey(STATE_KEYS.gender);
        const selectedGender = {
            label: genderKey && _.find(getFieldByStateKey(STATE_KEYS.gender).enum, e => e === genderValue),
            id: genderKey && _.indexOf(getFieldByStateKey(STATE_KEYS.gender).enum, genderValue)
        };
        const genderOptions = genderKey && getFieldByStateKey(STATE_KEYS.gender).enum.map((label, id) => ({ label: i18n.t(label), id }));
        const wValue = _.get(getFieldByStateKey(STATE_KEYS.weightLbs), 'value');

        const wName = i18n.t(_.get(getFieldByStateKey(STATE_KEYS.weightLbs), 'name'));
        return (
            <div className={css(layoutStyle.flex1)}>
                <Core.SubHeader
                    title={i18n.t('basicPhysicalDetails')}
                    noTopPadding={true}
                    right={<Core.Button size={Core.Button.SIZES.medium} onPress={saveChanges} className={css(styles.saveButton)}>{this.props.i18n.t('save')}</Core.Button>}
                />
                {personalFields.length > 0 ? (
                    <div className={css(styles.innerContainer)}>
                        <Core.SelectField
                            options={genderOptions}
                            message="choose_gender"
                            fieldName={i18n.t(getFieldByStateKey(STATE_KEYS.gender).name)}
                            selected={selectedGender}
                            onChange={this.onGenderChange}
                            checkboxType={Core.Checkbox.TYPES.simple}
                            translateOptions={true}
                        />
                        <Core.DateField
                            value={dateOfBirthValue}
                            onChange={onDateChange}
                            label={i18n.t(getFieldByStateKey(STATE_KEYS.dateOfBirth).name)}
                            fullWidth={true}
                            dateFormat={coreConstants.DATE_FORMATS.fullSlash}
                            disabled={_.get(getFieldByStateKey(STATE_KEYS.dateOfBirth), 'readOnly', false)}
                            type={coreConstants.DATE_FIELD_TYPE.text}
                            placeholder="yyyy/mm/dd"
                            error={!!errors.dateOfBirth}
                            helperText={errors.dateOfBirth}
                        />
                        {this.heightFields}
                        <Core.UnitField
                            unitOptions={_.map(onboardingConstants.UNIT_OPTIONS.WEIGHT, unit => unit)}
                            unit={i18n.t(userPreferredWeightUnit) || ''}
                            label={wName}
                            fullWidth={true}
                            onUnitChange={onUnitWeightChange}
                            value={wValue ? wValue.toString() : ''}
                            helperText={errors.weightLbs}
                            error={!!errors.weightLbs}
                            onChange={this.onWeightChange}
                            inputProps={{ maxLength: NUMBER_INPUT_MAX_LENGTH, 'aria-label': wName }}
                        />
                    </div>
                ) : null
                }
                <Core.BlockingLoading isLoading={isLoading || isSaving} />
            </div>
        );
    }
}

const styles = StyleSheet.create({
    ...baseStyles,
    ...importantStyles(
        {
            saveButton: {
                minWidth: spacing.s19
            }
        })
});

export default WithPersonalEditorBase(PersonalEditor);
