import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';
import { appFonts, spacing } from '../../../../../styles';
import { components as Core } from '../../../../core';
import WithChallengeInstructionBase, { styles as baseStyles, NUMBER_OF_LINES } from './ChallengeInstructionBase';

class ChallengeInstruction extends PureComponent {
    static propTypes = {
        instruction: PropTypes.string.isRequired,
    };

    render() {
        const { instruction } = this.props;
        return (
            <div className={css(styles.cardBodyInstructionPanel)}>
                <Core.TextSeeMore
                    numberOfLines={NUMBER_OF_LINES}
                    lineHeight={appFonts.mdRegular.lineHeight}
                    isCollapseHidden={false}
                    isExpandHidden={false}>
                    <Core.InnerHtml htmlText={instruction} className={css(styles.cardBodyInstructionText)} />
                </Core.TextSeeMore>
            </div>
        );
    }
}

export default WithChallengeInstructionBase(ChallengeInstruction);

const styles = StyleSheet.create({
    ...baseStyles,
    cardBodyInstructionPanel: {
        ...baseStyles.cardBodyInstructionPanel,
        marginRight: spacing.s3,
    },
});
