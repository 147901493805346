import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { spacing } from '../../../../styles';
import { translate } from '../../../core';
import * as rewardsActions from '../../actions';
import { PRODUCT_TYPES } from '../../constants';
import * as selectors from '../../selectors';

export default function WithProductsListBase(WrappedComponent) {
    class ProductsListBase extends Component {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            rewards: PropTypes.object,
            onPressSingle: PropTypes.func.isRequired,
            productsAmount: PropTypes.number,
            products: PropTypes.array,
            allProductsCurrentAmount: PropTypes.number,
            isLoading: PropTypes.bool,
            type: PropTypes.string,
            category: PropTypes.string,
            categoryId: PropTypes.string,
            i18n: PropTypes.object.isRequired,
            productsCountByCategory: PropTypes.number
        };

        static defaultProps = {
            rewards: {},
            productsAmount: 0,
            allProductsCurrentAmount: 0,
            products: [],
            isLoading: false,
            type: undefined,
            category: undefined,
            categoryId: undefined,
            productsCountByCategory: 0
        };

        get isAllListLoaded() {
            if (this.props.categoryId) {
                return this.productsCountByCategory >= this.props.productsAmount;
            }
            return this.props.allProductsCurrentAmount >= this.props.productsAmount;
        }

        get productsCountByCategory() {
            return this.props.products.length;
        }

        loadMoreProducts = () => {
            const { isLoading, allProductsCurrentAmount, categoryId, actions } = this.props;
            if (!isLoading && !this.isAllListLoaded) {
                let params;
                if (categoryId) {
                    params = { offset: this.productsCountByCategory, categoryId };
                } else {
                    params = { offset: allProductsCurrentAmount };
                }
                actions.getRewardsProducts(params, false);
            }
        };

        goProductDetails = item => {
            if (this.props.onPressSingle) {
                this.props.onPressSingle(item);
            }
        };

        hasItemSeparator = index => index !== this.props.products.length - 1;

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    hasItemSeparator={this.hasItemSeparator}
                    loadMoreProducts={this.loadMoreProducts}
                    goProductDetails={this.goProductDetails}
                    isAllListLoaded={this.isAllListLoaded}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        const title = ownProps.title || _.get(ownProps, 'location.state.title') || _.get(ownProps, 'route.params.title');
        const type = ownProps.type || _.get(ownProps, 'location.state.type') || _.get(ownProps, 'route.params.type');
        const onPressSingle = ownProps.onPressSingle || _.get(ownProps, 'route.params.onPressSingle');
        let products;
        if (ownProps.category) {
            products = selectors.getProductByCategory(state, ownProps.category);
        } else {
            switch (type) {
                case PRODUCT_TYPES.FEATURED:
                    products = selectors.getFeaturedProducts(state);
                    break;
                case PRODUCT_TYPES.ALL:
                    products = selectors.getProducts(state);
                    break;
                default:
                    products = [];
            }
        }
        return {
            rewards: selectors.getRewards(state),
            products: _.uniqBy(products, 'id'),
            allProductsCurrentAmount: selectors.getProductsCurrentAmount(state),
            isLoading: selectors.isLoadingProducts(state),
            productsAmount: selectors.getProductsAmount(state),
            externalRewardName: selectors.externalRewardName(state),
            onPressSingle,
            title,
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(rewardsActions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(ProductsListBase));
}

export const styles = {
    header: {
        marginTop: spacing.s3
    }
};
