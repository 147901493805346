import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { components as Core } from '../../../core';
import LeaderboardChallengesEmptyBase from './LeaderboardChallengesEmptyBase';

class LeaderboardChallengesEmpty extends PureComponent {
    static propTypes = {
        close: PropTypes.func.isRequired,
        title: PropTypes.string,
        i18n: PropTypes.object.isRequired
    };

    static defaultProps = {
        title: null
    };

    render() {
        return (
            <Fragment>
                <Core.SubHeader
                    title={this.props.title}
                    right={<Core.Button type="text" onPress={this.props.close}>{this.props.i18n.t('done')}</Core.Button>}
                />
                <Core.EmptyListSimple message={this.props.i18n.t('notParticipantingInAnyChallenges')} />
            </Fragment>
        );
    }
}

export default LeaderboardChallengesEmptyBase(LeaderboardChallengesEmpty);
