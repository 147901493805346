import React, { PureComponent } from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import { scrollTo } from '../services/animations';
import { spacing, importantStyles } from '../../../styles';
import { IconButton } from './Icon';

const OFFSET_TOP = 20;

export default class ScrollTop extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { isShown: false };
    }

    componentDidMount() {
        window.onscroll = this.onScroll;
    }

    onScroll = () => {
        if (document.documentElement.scrollTop > OFFSET_TOP) {
            this.setState(({ isShown }) => (!isShown ? ({ isShown: true }) : ({})));
        } else if (this.state.isShown) {
            this.setState(({ isShown }) => (isShown ? ({ isShown: false }) : ({})));
        }
    };

    scrollTop = () => {
        scrollTo(0, 500);
    };

    render() {
        return !this.state.isShown ? null : (
            <IconButton
                type="fa"
                color="secondary"
                className={css(styles.scrollTop)}
                onClick={this.scrollTop}
                name="arrow-up"
            />
        );
    }
}

const styles = StyleSheet.create(importantStyles({
    scrollTop: {
        position: 'fixed',
        right: spacing.s2,
        bottom: spacing.s10 * 1.5
    }
}));
