import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import classnames from 'classnames';
import { components as Core } from '../../../../core';
import WithWellbeingIconBase, { styles as baseStyles } from './WellbeingIconBase';

class WellbeingIcon extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        category: PropTypes.object.isRequired,
        iconSize: PropTypes.number.isRequired,
        containerStyles: PropTypes.object.isRequired
    };

    static defaultProps = {
        className: null
    };

    get iconName() {
        const { category } = this.props;
        return category.isExtended ? category.iconNameFromCategory : category.iconName;
    }

    render() {
        const { containerStyles, iconSize, category } = this.props;
        return (
            <div
                className={classnames(css(styles.healthScoreIconContainer), this.props.className)}
                style={containerStyles}>
                <Core.Icon type="fa" name={this.iconName} size={iconSize} color={category.icon} />
            </div>
        );
    }
}

export default WithWellbeingIconBase(WellbeingIcon);

const styles = StyleSheet.create({
    ...baseStyles,
    healthScoreIconContainer: {
        ...baseStyles.healthScoreIconContainer,
        display: 'flex'
    }
});