import React, { PureComponent } from 'react';
import _ from 'lodash';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Fade from '@material-ui/core/Fade';
import classnames from 'classnames';
import { StyleSheet, css } from 'aphrodite-jss';
import { layoutStyle, media, modalPadding, importantStyles, spacing } from '../../../styles';

const FADE_TRANSITION_TIMEOUT = 500;

const Modal = { open: null }; // will be set in components/Modal.js check it to know how it works
export default Modal;

export class ModalComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    /**
     * Assign open method to Modal service
     */
    componentDidMount() {
        Modal.open = this.open;
    }

    /**
     * Add modal to state
     * @param {Function} Component - component to render inside modal
     * @param {Object} props - props of component that will be rendered inside modal
     * @param {Object} modalProps - additional modal props
     * @returns {Function} - close current modal
     */
    open = (Component, props = {}, modalProps = {}) => {
        const id = _.uniqueId('modal_');
        const { cancelable = true, isContainer, isFullHeight, isTransparent, isNoWidthLimit, closeAllOnClick,
            isMaxWidthLimited, isMaxHeight, modalHidden, minHeight, maxWidth, ...restModalProps } = modalProps;
        const flexibleStyles = StyleSheet.create({
            minHeight: {
                minHeight
            },
            ...importantStyles({
                maxWidth: {
                    maxWidth
                }
            }),
        });

        // if isContainer - like Layout.Container (50% on big screens and 100% on small)
        // if isFullHeight - apply fullHeight className
        restModalProps.PaperProps = {
            ...(restModalProps.PaperProps || {}),
            className: classnames(
                _.get(restModalProps, 'PaperProps.className'), {
                    [css(styles.dialogContainer)]: isContainer,
                    [css(styles.noWidthLimit)]: isNoWidthLimit,
                    [css(styles.maxWidthLimited)]: isMaxWidthLimited,
                    [css(styles.fullHeight)]: isFullHeight,
                    [css(styles.transparent)]: isTransparent,
                    [css(styles.noShadow)]: isTransparent,
                    [css(styles.maxHeight)]: isMaxHeight,
                    [css(styles.bodyScrollModal)]: restModalProps.scroll === 'body',
                    [css(flexibleStyles.minHeight)]: minHeight,
                    [css(flexibleStyles.maxWidth)]: maxWidth,
                }
            )
        };
        // for infomodal, where we have an icon, overlapping the modal layout in the top
        if (restModalProps.hasIcon) { restModalProps.PaperProps.elevation = 0; }
        this.setState(({ modals }) => {
            const hiddenModalId = _.findKey(modals, 'modalHidden');
            return ({
                modals: {
                    ...modals,
                    [id]: {
                        component: (
                            <Component
                                {...props}
                                close={this.close(id)}
                                closeAll={this.closeAll}
                                showBackgroundModal={this.showModal(hiddenModalId)}
                            />),
                        modalProps: restModalProps,
                        id,
                        open: true,
                        cancelable,
                        modalHidden,
                        closeAllOnClick,
                    }
                }
            });
        });
        return this.close(id);
    };

    /**
     * Close modal by id, set open: false fro specific modal
     * @param {string} id - internal modal id
     */
    close = id => () => {
        this.setState(({ modals }) => ({ modals: { ...modals, [id]: { ...modals[id], open: false } } }));
    };

    closeAll = () => {
        this.setState({ modals: {} });
    };

    /**
     * onExited callback, remove specific modal data from state
     * @param {string} id - internal modal id
     */
    onExited = id => () => {
        this.setState(({ modals }) => ({ modals: _.omit(modals, id) }));
    };

    /**
     * Render one modal
     * @param {Object} data - modal data
     * @returns {ReactElement} - Dialog component
     */

    showModal = id => () => {
        if (!id) return;
        this.setState(oldState => ({
            modals: { ...oldState.modals, [id]: { ...oldState.modals[id], modalHidden: false } }
        }));
    };

    transitionComponent = fadeTransition => fadeTransition ? Fade : Slide;

    transitionTimeout = fadeTransition => fadeTransition ? FADE_TRANSITION_TIMEOUT : undefined;

    renderModal = data => {
        const { component, modalProps: { contentClassName, isDeviceModal, isNoPadding, isNoPaddingHorizontal, isFlexAlignStart,
            fadeTransition = false, ...restModalProps } = {}, id, open = false, cancelable, modalHidden, closeAllOnClick } = data;
        const close = this.close(id);
        return (
            <Dialog
                key={id}
                TransitionComponent={this.transitionComponent(fadeTransition)}
                TransitionProps={{ direction: 'up', timeout: this.transitionTimeout(fadeTransition) }}
                disableEscapeKeyDown={!cancelable}
                disableBackdropClick={!cancelable}
                open={open}
                onClose={closeAllOnClick ? this.closeAll : close}
                aria-labelledby="modal-dialog-title"
                aria-describedby="modal-dialog-description"
                onExited={this.onExited(id)}
                classes={{
                    container: classnames(isFlexAlignStart && css(layoutStyle.flexAlignStart), modalHidden && css(layoutStyle.opacityNone)),
                }}
                {...restModalProps}>
                <DialogContent
                    className={classnames(css(layoutStyle.fh, styles.content), {
                        [css(styles.noPaddingHorizontal)]: isNoPaddingHorizontal,
                        [css(styles.noPadding)]: isNoPadding,
                        [css(styles.scrollBarHidden)]: isDeviceModal,
                    }, contentClassName)}>
                    {component}
                </DialogContent>
            </Dialog>
        );
    };

    /**
     * Render all modals
     * @returns {ReactElement}
     */
    render() {
        const { modals } = this.state;
        return (
            <>
                {_.map(modals, this.renderModal)}
            </>
        );
    }
}

const LIMITED_MAX_WIDTH = 440;

const styles = StyleSheet.create({
    ...importantStyles({
        dialogContainer: {
            width: '50%',
            borderRadius: spacing.s1,
            [media.sm]: {
                width: '100%',
            },
        },
        fullHeight: {
            height: '100%',
            marginVertical: spacing.s3,
            [media.sm]: {
                margin: spacing.s3
            },
        },
        maxHeight: {
            height: '100%',
        },
        noPaddingHorizontal: {
            paddingLeft: 0,
            paddingRight: 0
        },
        noPadding: {
            padding: 0
        },
        noShadow: {
            boxShadow: 'none'
        },
        transparent: {
            backgroundColor: 'transparent'
        },
        content: {
            padding: modalPadding
        },
        noWidthLimit: {
            maxWidth: 'none'
        },
        scrollBarHidden: {
            '-ms-overflow-style': 'none',
            '&::-webkit-scrollbar': {
                display: 'none'
            },
        },
        maxWidthLimited: {
            maxWidth: LIMITED_MAX_WIDTH,
        },
        bodyScrollModal: {
            marginLeft: spacing.s15,
        },
    })
});
