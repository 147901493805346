import { TOURS } from '../constants';
import ProductTour from '../components/tours/ProductTour';
import FriendsAndFamilyTour from '../components/tours/FriendsAndFamilyTour';
import { Platform, PLATFORMS, ROUTES } from '../../core';

export default class TourService {
    static get isWeb() {
        return Platform.OS === PLATFORMS.web;
    }
    static showTour(type, navigation, data) {
        let tour;
        switch (type) {
            case TOURS.PRODUCT_TOUR: tour = ProductTour; break;
            case TOURS.FRIENDS_AND_FAMILY_TOUR: tour = FriendsAndFamilyTour; break;
            default: return;
        }
        navigation.navigate(ROUTES.tourLayout(), { tour, type, data });
    }
}
