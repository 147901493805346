import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { spacing } from '../../../../../styles';
import { translate } from '../../../../core';
import * as selectors from '../../../selectors';

export default function WithPersonalGoalsListBase(WrappedComponent) {
    class PersonalGoalsListBase extends PureComponent {
        static propTypes = {
            slug: PropTypes.string.isRequired,      //eslint-disable-line
            goals: PropTypes.array,
            starterGoals: PropTypes.array,
            onboardingNewDesign: PropTypes.bool,
            isSettingRecommendedGoals: PropTypes.bool,
            isDisabledSetting: PropTypes.bool.isRequired,
        };

        static defaultProps = {
            goals: [],
            starterGoals: [],
            onboardingNewDesign: false,
            isSettingRecommendedGoals: false,

        };

        state = {
            showSuccessCards: false,
        }

        componentDidUpdate(prevProps) {
            if (!this.props.isSettingRecommendedGoals && prevProps.isSettingRecommendedGoals) {
                // eslint-disable-next-line react/no-did-update-set-state
                this.setState({ showSuccessCards: true });
            }

        }

        componentWillUnmount() {
            this.setState({ showSuccessCards: false });
        }

        get items() {
            return this.props.onboardingNewDesign ? this.props.starterGoals : this.props.goals;
        }

        get canShowSuccessCards() {
            return this.props.isSetPersonalGoalPage && this.state.showSuccessCards;
        }

        get isSetButtonDisabled() {
            return this.props.isDisabledSetting && !this.props.isSettingRecommendedGoals;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    items={this.items}
                    isSetButtonDisabled={this.isSetButtonDisabled}
                    canShowSuccessCards={this.canShowSuccessCards}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        return {
            goals: ownProps.slug ? selectors.getRecommendedGoalsBySlug(state, ownProps.slug) : selectors.getRecommendedGoalsCarousel(state),
            isSettingRecommendedGoals: selectors.isSettingPersonalGoals(state),
        };
    }

    return connect(mapStateToProps)(translate()(PersonalGoalsListBase));
}

export const styles = {
    goalListContainer: {
        paddingTop: spacing.s3
    }
};