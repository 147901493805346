import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';

import { appFonts, baseColors, spacing } from '../../../../styles';
import { selectors as authSelectors } from '../../../auth';
import { selectors as coreSelectors, actions as coreActions, constants as coreConsts, translate, parsers } from '../../../core';
import { getUserDetails, isLoadingUserProfile } from '../../selectors';
import * as actions from '../../actions';

const POINTS = 45000;
export const LOGO_HEIGHT = 32;
export const LOGO_WIDTH = 100;

export default function WithUserProfileBase(WrappedComponent) {
    class UserProfileBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            isLoading: PropTypes.bool,
            userData: PropTypes.object,
            currentUserCompanyId: PropTypes.number.isRequired,
            company: PropTypes.object,
            i18n: PropTypes.object.isRequired,
            isAdding: PropTypes.bool,
            isDeleting: PropTypes.bool,
        };

        static defaultProps = {
            isLoading: false,
            userData: null,
            company: {},
            isAdding: false,
            isDeleting: false
        };

        constructor(props) {
            super(props);
            props.actions.getUserData(props.id);
        }

        get userCompanyId() {
            return _.get(this.props.userData, 'company.id');
        }

        get pointsNum() {
            return Math.round(POINTS).toLocaleString('en-US', { maximumFractionDigits: 0 });
        }

        get pointsText() {
            return this.props.i18n.t('points_earned_year');
        }

        get userName() {
            const firstName = _.get(this.props.userData, 'preferredName') || _.get(this.props.userData, 'firstName');
            const lastName = _.get(this.props.userData, 'lastName');
            return `${firstName} ${lastName}`;
        }

        get dateJoined() {
            const dateRegistered = _.get(this.props.userData, 'dateRegistered');
            const date = dateRegistered
                ? moment.unix(this.props.userData.dateRegistered).format(coreConsts.DATE_FORMATS.monthFullDayYearFull)
                : '';
            return `${this.props.i18n.t('joinSuccess')} ${date}`;
        }

        prepareString = string => _.capitalize(parsers.htmlDecode(string));

        get hasEvents() {
            return _.get(this.props.userData, 'upcomingEvents') && !!this.props.userData.upcomingEvents.length;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    hasEvents={this.hasEvents}
                    userName={this.userName}
                    dateJoined={this.dateJoined}
                    prepareString={this.prepareString}
                    pointsText={this.pointsText}
                    pointsNum={this.pointsNum}
                    userCompanyId={this.userCompanyId}
                    removeFromFriendsAndFamily={this.removeFromFriendsAndFamily}
                    addToFriendsAndFamily={this.addToFriendsAndFamily}
                />
            );
        }
    }
    function mapStateToProps(state, ownProps) {
        const id = ownProps.id || _.get(ownProps, 'match.params.id') || _.get(ownProps, 'route.params.id');
        const userData = getUserDetails(state, id);
        return {
            isLoading: isLoadingUserProfile(state),
            userData,
            id,
            currentUserCompanyId: coreSelectors.getCurrentUserCompanyId(state),
            company: authSelectors.getCompany(state),
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators({ ...coreActions, ...actions }, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(UserProfileBase));
}

export const styles = {
    container: {
        flex: 1,
        backgroundColor: baseColors.white
    },
    indicator: {
        marginTop: spacing.s3,
        marginBottom: spacing.s3
    },
    section: {
        borderBottomColor: baseColors.grey80,
        borderBottomWidth: 1,
        paddingTop: spacing.s3,
        paddingBottom: spacing.s3,
        marginLeft: spacing.s3,
        marginRight: spacing.s3
    },
    topSection: {
        flexDirection: 'row',
        paddingBottom: spacing.s5
    },
    name: {
        ...appFonts.mdBold,
        color: baseColors.black
    },
    joined: {
        ...appFonts.xsRegular,
        color: baseColors.grey40,
        marginBottom: spacing.s2
    },
    location: {
        ...appFonts.mdRegular,
        color: baseColors.grey20,
        marginTop: spacing.s0
    },
    pointsNum: {
        ...appFonts.xxxlBold,
        marginBottom: spacing.s2,
        color: baseColors.grey20
    },
    pointsText: {
        ...appFonts.smRegular,
        color: baseColors.grey20
    },
    nameWrapper: {
        marginLeft: spacing.s3,
        flex: 1
    },
    achievementsHeader: {
        ...appFonts.xlBold
    }
};
