import 'moment/locale/fr-ca';
import 'moment/locale/en-au';
import 'moment/locale/en-ca';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/pt';
import 'moment/locale/vi';
import 'moment/locale/th';
import 'moment/locale/de';
import 'moment/locale/de-ch';
import 'moment/locale/zh-cn';
import 'moment/locale/zh-hk';
import 'moment/locale/da';
import 'moment/locale/sv';
import 'moment/locale/it';
import 'moment/locale/nl';
import I18n from '../../i18n/i18n';

export const BUILDS = Object.freeze({
    sprout: 'sprout',
    upngo: 'upngo',
    mylivebetter: 'mylivebetter',
    telus: 'telus'
});

export const appNames = Object.freeze({
    [BUILDS.sprout]: I18n.t('appname'),
    [BUILDS.mylivebetter]: I18n.t('appnameLiveBetter'),
    [BUILDS.upngo]: I18n.t('appnameUPnGO'),
    [BUILDS.telus]: I18n.t('appnameTelus')
});

export const appStoreAppNames = Object.freeze({
    [BUILDS.sprout]: I18n.t('appStoreNameSprout'),
    [BUILDS.mylivebetter]: I18n.t('appStoreNameLiveBetter'),
    [BUILDS.telus]: I18n.t('appStoreNameTelus')
});

export const appStoreUrls = {
    ios: {
        [BUILDS.mylivebetter]: I18n.t('appleStoreUrlLiveBetter'),
        [BUILDS.telus]: I18n.t('appleStoreUrlTelus'),
        [BUILDS.upngo]: I18n.t('appleStoreUrlUPnGO'),
        [BUILDS.sprout]: I18n.t('appleStoreUrlSprout')
    },
    android: {
        [BUILDS.mylivebetter]: I18n.t('playStoreUrlLiveBetter'),
        [BUILDS.telus]: I18n.t('playStoreUrlTelus'),
        [BUILDS.upngo]: I18n.t('playStoreUrlUPnGO'),
        [BUILDS.sprout]: I18n.t('playStoreUrlSprout')
    },
    huawei: {
        [BUILDS.mylivebetter]: I18n.t(''),
        [BUILDS.telus]: I18n.t('huaweiStoreUrlTelus'),
        [BUILDS.upngo]: I18n.t(''),
        [BUILDS.sprout]: I18n.t('huaweiStoreUrlSprout')
    }
};