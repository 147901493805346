import { baseColors, spacing } from '../../../../styles';
import { translate } from '../../services';

export default function WithEmptyListSimpleBase(WrappedComponent) {
    return translate()(WrappedComponent);
}

export const styles = {
    empty: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    emptyText: {
        color: baseColors.grey40,
        paddingTop: spacing.s3,
        textAlign: 'center'
    }
};
