import moment from 'moment';
import { baseIconColors, baseColors } from '../../styles';
import { getIconCategories, DEFAULT_ICON_CATEGORY as DEFAULT_ICON } from '../core';

export const NAME = 'settings';

export const LIST_ITEM_TYPES = Object.freeze({
    faq: 'faq',
    feedback: 'feedback',
    help: 'help',
    policy: 'policy',
    terms: 'terms',
    disclaimer: 'disclaimer'
});

export const EDITOR_TABS = Object.freeze({
    personal: 'personal',
    interests: 'interests',
    objectives: 'objectives',
    biometrics: 'biometrics',
});

export const NOTIFICATIONS_SETTINGS_TABS = Object.freeze({
    notifications: 'notifications',
    emails: 'emails'
});

export const INPUT_MAX_LENGTH = 2000;
export const NUMBER_INPUT_MAX_LENGTH = 3;

export const MAX_DATE = new Date(moment().subtract(18, 'years').toDate());
export const MIN_DATE = new Date(moment().subtract(110, 'years').toDate());

export const STATE_KEYS = Object.freeze({
    gender: 'gender',
    waistCircumferenceInches: 'waistCircumferenceInches',
    hipCircumferenceInches: 'hipCircumferenceInches',
    heightInches: 'heightInches',
    waistInches: 'waistInches',
    weightLbs: 'weightLbs',
    dateOfBirth: 'dateOfBirth',
    firstName: 'firstName',
    preferredName: 'preferredName',
    bloodPressureHg: 'bloodPressureHg',
    bloodPressureMm: 'bloodPressureMm',
    bloodCholesterol: 'bloodCholesterol',
    bloodGlucose: 'bloodGlucose',
    lowDensityLipoprotein: 'lowDensityLipoprotein',
    highDensityLipoprotein: 'highDensityLipoprotein',
    triglycerides: 'triglycerides',
    restingHeartRate: 'restingHeartRate',
    percentageBodyFat: 'percentageBodyFat',
    userPreferredHeightUnit: 'userPreferredHeightUnit',
    userPreferredWeightUnit: 'userPreferredWeightUnit',
    userPreferredWaistUnit: 'userPreferredWaistUnit',
    heightValue: 'heightValue',
    feetValue: 'feetValue',
    waistValue: 'waistValue',
    weightValue: 'weightValue',
    waist: 'waist',
    weight: 'weight',
});

export const PROFILE_TABS = Object.freeze({
    profile: 'profile',
    stats: 'stats',
    wellbeing: 'wellbeing',
    health_score: 'health_score'
});

export const SETTINGS_TABS = Object.freeze({
    account: 'account',
    notifications: 'notifications',
    privacy: 'privacy',
    findFriendsAndFamily: 'findFriendsAndFamily',
    feedback: 'feedback',
    getHelp: 'getHelp',
    legal: 'legal',
    changePassword: 'changePassword',
    linkAccounts: 'linkAccounts',
    goalSmartMode: 'goalSmartMode',
    familyMembers: 'familyMembers'
});

export const bioMetricsBase = [
    {
        stateKey: STATE_KEYS.bloodPressureHg,
        name: 'bloodPressureHg',
        unit: 'mmHg',
        minimum: 0,
        maximum: 999
    },
    {
        stateKey: STATE_KEYS.bloodPressureMm,
        name: 'bloodPressureMm',
        unit: 'mmHg',
        minimum: 0,
        maximum: 999
    },
    {
        stateKey: STATE_KEYS.bloodCholesterol,
        name: 'bloodCholesterol',
        unit: 'mmol/L',
        minimum: 0,
        maximum: 999
    },
    {
        stateKey: STATE_KEYS.bloodGlucose,
        name: 'bloodGlucose',
        unit: 'mmol/L',
        minimum: 0,
        maximum: 999
    },
    {
        stateKey: STATE_KEYS.lowDensityLipoprotein,
        name: 'ldl',
        unit: 'mmol/L',
        minimum: 0,
        maximum: 999
    },
    {
        stateKey: STATE_KEYS.highDensityLipoprotein,
        name: 'hdl',
        unit: 'mmol/L',
        minimum: 0,
        maximum: 999
    },
    {
        stateKey: STATE_KEYS.triglycerides,
        name: 'triglycerides',
        unit: 'mmol/L',
        minimum: 0,
        maximum: 999
    },
    {
        stateKey: STATE_KEYS.restingHeartRate,
        name: 'restingHeartRate',
        unit: 'beat/min',
        minimum: 40,
        maximum: 120
    },
    {
        stateKey: STATE_KEYS.percentageBodyFat,
        name: 'percentageBodyFat',
        unit: '%',
        minimum: 0,
        maximum: 99
    },
    {
        stateKey: STATE_KEYS.waistCircumferenceInches,
        name: 'waistCircumference',
        unit: 'inches',
    },
    {
        stateKey: STATE_KEYS.hipCircumferenceInches,
        name: 'hipCircumference',
        unit: 'inches',
        minimum: 0, //assumes inches
        maximum: 999 //assumes inches
    }
];

export const personalBase = [
    {
        stateKey: STATE_KEYS.gender,
        name: 'Gender',
        enum: ['gender_male', 'gender_female', 'gender_non_binary', 'gender_rather_not_say']
    },
    {
        stateKey: STATE_KEYS.dateOfBirth,
        name: 'date_of_birth',
        minimum: moment()
            .subtract(110, 'years')
            .toDate(),
        maximum: moment()
            .subtract(18, 'years')
            .toDate()
    },
    {
        stateKey: STATE_KEYS.heightInches,
        name: 'Height',
        minimum: 23, //assuming inches
        maximum: 108 //assuming inches
    },
    {
        stateKey: STATE_KEYS.weightLbs,
        name: 'weight',
    }
];

export const SLUGS = Object.freeze({
    move: 'move',
    eat: 'eat',
    feel: 'feel'
});

export const HEALTH_SCORE_SLUGS = Object.freeze({
    move: 'move',
    overall: 'overall'
});

export const HEALTH_SCORES = Object.freeze({
    excellent: 'excellent',
    veryGood: 'very_good',
    good: 'good',
    fair: 'fair',
    poor: 'poor',
});

const extendedBaseIconColors = {
    ...baseIconColors,
    eat: {
        ...baseIconColors.eat,
        iconName: 'Eat',
        iconNameFromCategory: baseIconColors.eat.iconName,
        mainColor: baseIconColors.eat.bgr,
        iconColor: baseIconColors.eat.icon,
        isExtended: true
    },
    move: {
        ...baseIconColors.move,
        iconName: 'Move',
        iconNameFromCategory: baseIconColors.move.iconName,
        mainColor: baseIconColors.move.bgr,
        iconColor: baseIconColors.move.icon,
        isExtended: true
    },
    mind: {
        ...baseIconColors.mind,
        iconName: 'Mind',
        iconNameFromCategory: baseIconColors.mind.iconName,
        mainColor: baseIconColors.mind.bgr,
        iconColor: baseIconColors.mind.icon,
        isExtended: true
    },
};

export const CATEGORIES = getIconCategories(extendedBaseIconColors);

export const DEFAULT_ICON_CATEGORY = { ...DEFAULT_ICON, icon: baseColors.white };

export const SCORE_STATUSES = Object.freeze({
    dec: {
        id: 'dec',
        color: baseColors.danger
    },
    inc: {
        id: 'inc',
        color: baseColors.success
    },
    nan: {
        id: 'nan',
        color: baseColors.warn
    }
});

export const PRIVACY_ON_OFF_TITLES = {
    ON: 'privacyOn',
    OFF: 'privacyOff'
};

export const PRIVACY_ON_OFF_ICONS = {
    OFF: 'times-circle',
    ON: 'check-circle'
};

export const ICONS = {
    bell: 'bell',
    update: 'download',
    feedback: 'comments-alt',
    help: 'life-ring',
    legal: 'briefcase',
    password: 'key',
    userCircle: 'user-circle',
    badge: 'id-badge',
    userPlus: 'user-plus',
    privacy: 'eye',
    bolt: 'bolt',
    user: 'user',
    userFriends: 'user-friends'
};

export const PRIVACY_SLUGS = Object.freeze({
    COMMUNITIES: 'communities_participation',
    SEARCH_RESULTS: 'private_search',
    CHALLENGES: 'challenges_participation',
    EVENTS: 'events_participation',
    MAIN_LEADERBOARD: 'main_leaderboard',
    CHALLENGE_LEADERBOARD: 'challenge_leaderboard',
    THUMBS_UP_MODAL: 'thumbs_up_modal',
    COMMENT_TO_ACTION: 'comment_to_action_info_title',
    NEW_POST_INFO: 'new_post_info_title',
    CHALLENGE_TEAMS: 'challenge_teams',
    DATA_COLLECTION: 'data_collection',
    // are not used so far
    WEEKLY_EMAIL: 'weekly_email',
    COMPANY_WIDE: 'company_wide',
    MARKETING_COMM: 'marketing_comm',
    PARTNER_MARKETING_COMM: 'partner_marketing_comm',
});

export const PRIVACY_SLUGS_CONFIG = Object.freeze({
    [PRIVACY_SLUGS.SEARCH_RESULTS]: {
        messageOn: 'onboardingFamilyPrivacySettingsMessageOn',
        messageOff: 'onboardingFamilyPrivacySettingsMessageOff',
    },
    [PRIVACY_SLUGS.CHALLENGES]: {
        messageOn: 'challengeParticipationPrivacySettingsMessageOn',
        messageOff: 'challengeParticipationPrivacySettingsMessageOff',
    },
    [PRIVACY_SLUGS.COMMUNITIES]: {
        messageOn: 'communityParticipationPrivacySettingsMessageOn',
        messageOff: 'communityParticipationPrivacySettingsMessageOff',
    },
    [PRIVACY_SLUGS.EVENTS]: {
        messageOn: 'eventParticipationPrivacySettingsMessageOn',
        messageOff: 'eventParticipationPrivacySettingsMessageOff',
    },
    [PRIVACY_SLUGS.MAIN_LEADERBOARD]: {
        messageOn: 'mainLeaderboardPrivacySettingsMessageOn',
        messageOff: 'mainLeaderboardPrivacySettingsMessageOff',
    },
    [PRIVACY_SLUGS.CHALLENGE_LEADERBOARD]: {
        messageOn: 'challengeLeaderboardPrivacySettingsMessageOn',
        messageOff: 'challengeLeaderboardPrivacySettingsMessageOff',
    },
    [PRIVACY_SLUGS.THUMBS_UP_MODAL]: {
        infoTitle: 'thumbsUpConfirmationTitle',
        infoText: 'thumbsUpConfirmationDescriptionline1',
        infoTextSecondLine: 'thumbsUpConfirmationDescriptionline2',
        infoButton: 'thumbsUpConfirmationButtonOk',
        infoIcon: 'thumbs-up',
    },
    [PRIVACY_SLUGS.COMMENT_TO_ACTION]: {
        tooltipTitle: 'commentToActionInfoTitle',
        tooltipMessage: 'commentToActionMessage',
    },
    [PRIVACY_SLUGS.NEW_POST_INFO]: {
        tooltipTitle: 'postToActionTitle',
        tooltipSubtitle: 'postToActionMessageLine1',
        tooltipMessage: 'postToActionMessageLine2',
    },
    [PRIVACY_SLUGS.CHALLENGE_TEAMS]: {
        messageOn: 'joinCreateTeamPrivacyDisclaimer'
    }
});

export const NOT_PERSISTED = false;
