import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
    communityJoining,
    communityLoading,
    getSingleCommunity,
    getCommunityMembers,
    communityEditing
} from '../../selectors';
import { components as Core, ActionSheet, selectors as coreSelectors, translate, actions as coreActions,
    constants as coreConstants, getImageProp, tracker, entitiesHelper, ROUTES, Modal, Platform, PLATFORMS } from '../../../core';
import { appFonts, baseColors, spacing, detailsImageSize } from '../../../../styles';
import { constants as invitationConstants } from '../../../invitations';
import * as actions from '../../actions';

const DELETE_ENTITY_TYPE = 'deletingCommunity';

export default function WithCommunityItemDetailsBase(WrappedComponent) {
    class CommunityItemDetailsBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            isUserRole: PropTypes.bool,
            item: PropTypes.object,
            communityJoining: PropTypes.bool,
            communityLoading: PropTypes.bool,
            userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            navigation: PropTypes.object,
            membersShort: PropTypes.array,
        };

        static defaultProps = {
            item: undefined,
            isUserRole: true,
            communityJoining: false,
            communityLoading: false,
            navigation: {},
            membersShort: [],
        };

        constructor(props) {
            super(props);
            this.props.actions.getSingleCommunity(props.id);
            this.props.actions.getCommunityMembers(props.id);
            this.wrapped = React.createRef();
            this.imageSrc = getImageProp();
            this.hasImage = false;
        }

        componentDidUpdate(prevProps) {
            if(this.props.item) {
                const src = _.get(this.props.item, 'groupImageURL') || _.get(this.props.item, 'imageURL');
                this.imageSrc = getImageProp(src);
                this.hasImage = !!src;
            }
            if (!this.props.communityLoading && prevProps.communityLoading && !this.props.item) {
                this.props.actions.addToast(coreConstants.TOAST_TYPES.DANGER, '', this.props.i18n.t('CommunityNotFoundToast.danger.message'), this.props.i18n.t('CommunityNotFoundToast.danger.title'));
            }
        }

        get inviteButtonText() {
            return this.props.i18n.t('invite');
        }

        get isOwner() {
            return _.get(this.props, 'item.ownerId') && this.props.userId && this.props.userId.toString() === this.props.item.ownerId.toString();
        }

        get isMember() {
            return _.get(this.props.item, 'isMember');
        }

        get isWeb() {
            return Platform.OS === PLATFORMS.web;
        }

        get notJoinedCommunityLabel() {
            return this.props.i18n.t('notJoinedCommunityLabel');
        }

        getOptions = (editCallback, inviteCallback) => [
            ...(this.isMember ?
                [
                    { title: this.props.i18n.t('leaveCommunity'), onPress: this.leaveCommunity }
                ] : []
            ),
            ...(this.isOwner || !this.props.isUserRole ?
                [
                    { title: this.props.i18n.t('editCommunity'), onPress: editCallback },
                    { title: this.props.i18n.t('deleteCommunity'), onPress: this.deleteCommunity }
                ] : []
            )
        ];

        joinCommunity = () => {
            const { name, id } = this.props.item;
            tracker.logEvent('join_group', { group_id: id, stream: false });
            this.props.actions.joinCommunity(id, true, undefined, this.props.i18n.t('joiningCommunityToast.success.message', { name }));
        };

        deleteCommunityAction = () => {
            const { id, name } = this.props.item;
            this.props.actions.deleteCommunity(id, DELETE_ENTITY_TYPE, this.props.i18n.t(`${DELETE_ENTITY_TYPE}Toast.success.title`, { name }));
        };

        deleteCommunity = () => {
            this.wrapped.current.showDeletionWarning(this.deleteCommunityAction);
        };

        getWarningProps = () => {
            const { i18n } = this.props;
            return {
                isButtonVisible: false,
                title: i18n.t('areYouSure'),
                text: i18n.t('deleteCommunityDescription'),
                checkboxLabel: i18n.t('deleteCommunityCheckbox'),
            };
        };

        onLeaveCommunity = () => {
            this.leaveCommunityAction();
            this.isWeb && this.closeInfoModal();
        };

        getInfoModalProps = () => {
            const { i18n } = this.props;
            return {
                title: i18n.t('areYouSure'),
                text: i18n.t('leaveCommunityDescription'),
                isButtonVisible: false,
                buttons: [
                    { text: i18n.t('button_leave_community'), onPress: () => this.onLeaveCommunity(), isDangerText: true },
                    { text: i18n.t('button_cancel'), onPress: this.isWeb ? () => this.closeInfoModal() : null },
                ],
            };
        }

        leaveCommunity = () => {
            if (this.isWeb) {
                this.closeInfoModal = Modal.open(
                    Core.InfoModal,
                    this.getInfoModalProps(),
                    { isContainer: true, isNoPadding: true, isMaxWidthLimited: true, fadeTransition: true }
                );
            } else {
                this.props.navigation.push(ROUTES.infoModal(), this.getInfoModalProps());
            }
        };

        leaveCommunityAction = () => this.props.actions.leaveCommunity(this.props.item.id);

        get destructiveButtonIndex() {
            return this.isOwner || !this.props.isUserRole ? this.getOptions().length - 1 : undefined;
        }

        openOptions = (editCallback, inviteCallback, eventOrNavigation) => {
            ActionSheet.open(this.getOptions(editCallback, inviteCallback), this.destructiveButtonIndex, undefined, eventOrNavigation);
        };

        getInviteToCommunityProps = () => ({
            entityId: this.props.item.id,
            entity: invitationConstants.ENTITIES.COMMUNITY
        });

        get contentIsDeletedOrInappropriate() { return this.props.i18n.t('contentIsDeletedOrInappropriate'); }
        get seeMoreLongComment() { return this.props.i18n.t('seeMoreLongComment'); }
        get joinCommunityButtonLabel() { return this.props.i18n.t('joinCommunity'); }

        get numMembersText() {
            const { item, i18n } = this.props;
            const num = _.get(item, 'numMembers');
            return `${num} ${_.toLower(i18n.t(num > 1 ? 'membersPlural' : 'member'))}`;
        }

        get invitedByText() {
            const { item } = this.props;
            if (this.isMember || !_.get(item, 'isInvitation')) return '';
            return entitiesHelper.getInvitedByText(item.invitedBy);
        }

        render() {
            return (
                <WrappedComponent
                    ref={this.wrapped}
                    {...this.props}
                    imageSrc={this.imageSrc}
                    isMember={this.isMember}
                    getOptions={this.getOptions}
                    openOptions={this.openOptions}
                    invitedByText={this.invitedByText}
                    joinCommunity={this.joinCommunity}
                    numMembersText={this.numMembersText}
                    getWarningProps={this.getWarningProps}
                    inviteToCommunity={this.inviteToCommunity}
                    seeMoreLongComment={this.seeMoreLongComment}
                    destructiveButtonIndex={this.destructiveButtonIndex}
                    joinCommunityButtonLabel={this.joinCommunityButtonLabel}
                    getInviteToCommunityProps={this.getInviteToCommunityProps}
                    contentIsDeletedOrInappropriate={this.contentIsDeletedOrInappropriate}
                    inviteButtonText={this.inviteButtonText}
                    notJoinedCommunityLabel={this.notJoinedCommunityLabel}
                    hasImage={this.hasImage}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        const id = ownProps.id || _.get(ownProps, 'match.params.id') || _.get(ownProps, 'route.params.id');
        return {
            item: getSingleCommunity(state, id),
            communityLoading: communityLoading(state),
            communityJoining: communityJoining(state, id),
            communityEditing: communityEditing(state),
            userId: coreSelectors.getCurrentUserId(state),
            isUserRole: coreSelectors.isUserRole(state),
            members: getCommunityMembers(state, id),
            membersShort: getCommunityMembers(state, id, true),
            id,
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators({ ...actions, ...coreActions }, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(CommunityItemDetailsBase));
}

const BUTTON_HEIGHT = 50;

export const styles = {
    container: {
        flex: 1,
    },
    content: {
        marginLeft: spacing.s3,
        marginRight: spacing.s3
    },
    communityHeaderContainer: {
        flexDirection: 'column',
        marginBottom: spacing.s5
    },
    communityImage: {
        height: detailsImageSize
    },
    communityImageBackground: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.25)'
    },
    textDetailsContainer: {
        marginBottom: spacing.s1
    },
    groupName: {
        ...appFonts.xlBold,
        marginLeft: 0,
        marginRight: 0
    },
    seeMoreStyle: {
        ...appFonts.mdBold,
        color: baseColors.secondary,
        marginTop: spacing.s0
    },
    avatarListContainer: {
        marginTop: spacing.s3,
        marginBottom: spacing.s3,
    },
    membersAvatar: {
        marginLeft: -spacing.s3,
        borderWidth: 2,
        borderColor: baseColors.white
    },
    functionalButtonsContainer: {
        height: BUTTON_HEIGHT,
        marginTop: spacing.s1,
    },
    functionalButton: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        borderColor: baseColors.secondary,
        borderWidth: 1
    },
    functionalButtonText: {
        ...appFonts.mdBold
    },
    notJoinWarningContainer: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.9)'
    },
    notJoinWarningText: {
        ...appFonts.mdBold,
        color: baseColors.secondary,
        textAlign: 'center',
        width: '100%',
        bottom: 0,
        position: 'absolute',
        marginBottom: spacing.s12
    },
    streamHeader: {
        backgroundColor: baseColors.white,
        marginBottom: spacing.s0,
        overflow: 'hidden',
        borderWidth: 1,
        borderColor: baseColors.grey80
    },
    streamHeaderWrapper: {
        marginTop: spacing.s2,
        position: 'relative'
    },
    membersText: {
        ...appFonts.smRegular,
        color: baseColors.grey40,
    },
    invitedText: {
        ...appFonts.smRegular
    },
    title: {
        ...appFonts.xlBold,
        marginBottom: spacing.s3,
        color: baseColors.black,
        marginLeft: 0,
        marginRight: 0
    },
};
