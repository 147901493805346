import { NAME as name } from './constants';
import { createActionTypes } from '../core';

const NAME = name.toUpperCase();

// for api
export const LOGIN = createActionTypes(`${NAME}/LOGIN`);
export const GET_COMPANY_CONFIGURATION = createActionTypes(`${NAME}/GET_COMPANY_CONFIGURATION`);
export const LINK_EXTERNAL_ACCOUNT = createActionTypes(`${NAME}/LINK_EXTERNAL_ACCOUNT`);
export const UNLINK_EXTERNAL_ACCOUNT = createActionTypes(`${NAME}/UNLINK_EXTERNAL_ACCOUNT`);
export const LINK_CORPORATE_ACCOUNT = createActionTypes(`${NAME}/LINK_CORPORATE_ACCOUNT`);
export const GENERATE_CORPORATE_ACCOUNT_LINK_EMAIL = createActionTypes(`${NAME}/GENERATE_CORPORATE_ACCOUNT_LINK_EMAIL`);
export const GET_EMAIL_PROGRAM_NAME = createActionTypes(`${NAME}/GET_EMAIL_PROGRAM_NAME`);
export const GET_TERMS_OF_SERVICE = createActionTypes(`${NAME}/GET_TERMS_OF_SERVICE`);
export const GET_PRIVACY_POLICY = createActionTypes(`${NAME}/GET_PRIVACY_POLICY`);
export const CREATE_ACCOUNT = createActionTypes(`${NAME}/CREATE_ACCOUNT`);
export const GET_LOCATIONS = createActionTypes(`${NAME}/GET_LOCATIONS`);
export const GET_DEPARTMENTS = createActionTypes(`${NAME}/GET_DEPARTMENTS`);
export const GET_REGIONS = createActionTypes(`${NAME}/GET_REGIONS`);
export const GET_USER = createActionTypes(`${NAME}/GET_USER`);
export const GET_USER_LOCATIONS = createActionTypes(`${NAME}/GET_USER_LOCATIONS`);
export const SET_USER_LOCATIONS = createActionTypes(`${NAME}/SET_USER_LOCATIONS`);
export const GET_DISCLAIMER = createActionTypes(`${NAME}/GET_DISCLAIMER`);
export const VERIFY_EMAIL = createActionTypes(`${NAME}/VERIFY_EMAIL`);
export const UPDATE_USER = createActionTypes(`${NAME}/UPDATE_USER`);
export const SAVE_USER = createActionTypes(`${NAME}/SAVE_USER`);
export const RESET_PASSWORD = createActionTypes(`${NAME}/RESET_PASSWORD`);
export const CHANGE_PASSWORD = createActionTypes(`${NAME}/CHANGE_PASSWORD`);
export const CHECK_SAML_STATUS = createActionTypes(`${NAME}/CHECK_SAML_STATUS`);
export const SIGNOUT = createActionTypes(`${NAME}/SIGNOUT`);
export const GET_PROGRAM_DETAILS = createActionTypes(`${NAME}/GET_PROGRAM_DETAILS`);
export const SET_USER_SSO = createActionTypes(`${NAME}/SET_USER_SSO`);
export const DELETE_ACCOUNT = createActionTypes(`${NAME}/DELETE_ACCOUNT`);
export const UNLINK_CORPORATE_ACCOUNT = createActionTypes(`${NAME}/UNLINK_CORPORATE_ACCOUNT`);
export const SET_SSO_DETAILS_FROM_CURRENT_USER = createActionTypes(`${NAME}/SET_SSO_DETAILS_FROM_CURRENT_USER`);
export const CHECK_SMART_MODE_STATUS = createActionTypes(`${NAME}/CHECK_SMART_MODE_STATUS`);
export const UPDATE_GOAL_SMART_MODE_STATUS = createActionTypes(`${NAME}/UPDATE_GOAL_SMART_MODE_STATUS`);
export const CHECK_EMAIL_REGISTRATION_STATUS = createActionTypes(`${NAME}/CHECK_EMAIL_REGISTRATION_STATUS`);
export const QUERY_GLOBAL_DIRECTORY = createActionTypes(`${NAME}/QUERY_GLOBAL_DIRECTORY`);
export const CHECK_EMPLOYEE_ID = createActionTypes(`${NAME}/CHECK_EMPLOYEE_ID`);
export const GET_HRIS_LOCATIONS = createActionTypes(`${NAME}/GET_HRIS_LOCATIONS`);
export const GET_COMPANY_EXTERNAL_APPS = createActionTypes(`${NAME}/GET_COMPANY_EXTERNAL_APPS`);
export const GET_POLICY_POPUPS = createActionTypes(`${NAME}/GET_POLICY_POPUPS`);
export const MARK_POLICY_UPDATE_SEEN = createActionTypes(`${NAME}/MARK_POLICY_UPDATE_SEEN`);


// single actions
export const LOGOUT = `${NAME}/LOGOUT`;
export const INTERNAL_STATE_RESET = `${NAME}/INTERNAL_STATE_RESET`;
export const SET_LOGIN_STATUS = `${NAME}/SET_LOGIN_STATUS`;
export const LOGIN_ERROR_REGISTRATION = `${NAME}/LOGIN_ERROR_REGISTRATION`;
export const UPDATE_REMEMBER_EMAIL = `${NAME}/UPDATE_REMEMBER_EMAIL`;
export const TOGGLE_REMEMBER_ME = `${NAME}/TOGGLE_REMEMBER_ME`;
export const CLEAR_COMPANY_CONFIGURATION = `${NAME}/CLEAR_COMPANY_CONFIGURATION`;
export const UPDATE_LOCAL_REGISTRATION_STEP = `${NAME}/UPDATE_LOCAL_REGISTRATION_STEP`;
export const UPDATE_LOCAL_LOGIN_STEP = `${NAME}/UPDATE_LOCAL_LOGIN_STEP`;
export const CLEAR_USER_SSO = `${NAME}/CLEAR_USER_SSO`;
export const SET_LOGIN_INFO = `${NAME}/SET_LOGIN_INFO`;
export const UPDATE_CORPORATE_ACCOUNT_LINK_DATA = `${NAME}/UPDATE_CORPORATE_ACCOUNT_LINK_DATA`;

export const CHANGE_LANGUAGE = `${NAME}/CHANGE_LANGUAGE`;
export const SAVE_LOGIN_QUERY_PARAMS = `${NAME}/SAVE_LOGIN_QUERY_PARAMS`;

export const CLEAR_EMAIL_REGISTRATION_STATUS = `${NAME}/CLEAR_EMAIL_REGISTRATION_STATUS`;

export const SET_HRIS_LOCATION = `${NAME}/SET_HRIS_LOCATION`;
