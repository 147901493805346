import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as actions from '../../actions';
import { translate } from '../../../core';
import { TYPES, MAX_COUNT } from '../../constants';
import { communitiesLoading, COMMUNITIES_DATA_BY_TYPE } from '../../selectors';
import { spacing } from '../../../../styles';

const NOT_LOAD_MORE = [TYPES.RECOMMENDED, TYPES.RECENT];

export default function WithCommunitiesListBase(WrappedComponent) {
    class CommunitiesListBase extends PureComponent {
        static propTypes = {
            isLoading: PropTypes.bool,
            communities: PropTypes.array.isRequired,
            communitiesCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            type: PropTypes.string.isRequired,
            actions: PropTypes.object.isRequired,
            i18n: PropTypes.object.isRequired
        };

        static defaultProps = {
            isLoading: false,
        };

        constructor(props) {
            super(props);
            this.loadCommunities();
        }

        loadMoreContent = () => {
            if (!this.props.isLoading && this.props.communities.length < this.props.communitiesCount &&
                !_.includes(NOT_LOAD_MORE, this.props.type)) {
                this.props.actions.getCommunities({ filter: this.props.type, limit: MAX_COUNT, start: this.props.communities.length }, false);
            }
        };

        loadCommunities = () => {
            if (this.props.type === TYPES.RECOMMENDED) {
                this.props.actions.getRecommendedCommunities();
            }
            else if (this.props.type === TYPES.RECENT) {
                this.props.actions.getRecentCommunities();
            }
            else {
                this.props.actions.getCommunities({ filter: this.props.type, limit: MAX_COUNT, start: 0 }, true);
            }
        };

        get title() {
            const { type, i18n } = this.props;
            switch (type) {
                case TYPES.INVITATIONS:
                    return i18n.t('invitedCommunities');
                case TYPES.RECENT:
                    return i18n.t('recent');
                default:
                    return null;
            }
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    title={this.title}
                    loadMoreContent={this.loadMoreContent}
                    loadCommunities={this.loadCommunities}
                    refreshContent={this.loadCommunities}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        const type = ownProps.type || _.get(ownProps, 'match.params.type') || _.get(ownProps, 'route.params.type');
        return {
            communities: COMMUNITIES_DATA_BY_TYPE[type].items(state),
            communitiesCount: COMMUNITIES_DATA_BY_TYPE[type].count(state),
            isLoading: communitiesLoading(state),
            type
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(CommunitiesListBase));
}

export const styles = {
    mainContainer: {
        flex: 1
    },
    listContainer: {
        flex: 1
    },
    header: {
        marginBottom: spacing.s3
    }
};