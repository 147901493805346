/* eslint-disable camelcase */
import _ from 'lodash';
import { v1 as uuidv1 } from 'uuid';
import global from '../../../../config/globals';
import { openUrl, constants as coreConstants, Platform, tracker, PLATFORMS } from '../../../core';
import {
    EXTERNAL_SERVICES, SAML_SSO_STATUSES, SSO_CONTEXT, SSO_ERROR_CODES, TELUS_KRABAPPEL_URL, TELUS_LOGIN_URL_MOBILE,
    TELUS_TEST_LOGIN_URL_MOBILE, TELUS_LOGIN_URL_WEB, TELUS_TEST_LOGIN_URL_WEB, TELUS_SSO_TYPE
} from '../../constants';
import i18n from '../../../../i18n';
import systemBuildVariant, { BUILDS } from '../../../../config/buildVariant';

function getEncodedOriginParam() {
    if (Platform.OS === PLATFORMS.web) {
        const origin = encodeURIComponent(window.location.origin);
        return `&origin=${origin}`;
    }
    else {
        const origin = encodeURIComponent((systemBuildVariant === BUILDS.telus) ? 'teluswellbeing://' : 'sproutatwork://');
        return `&origin=${origin}`;
    }
}

export function getSsoUrlParams(url) {
    const string = decodeURIComponent(url);
    const splitted = string.split('?');
    return _.fromPairs((splitted[1] || splitted[0]).split('&').map(s => s.split('=')));
}

export async function checkSSOResult(url, actions, currentUser) {
    // List all possible variations of this SSO string
    // based on deep link contents, decide what partner it is and what the appropriate action to dispatch
    const params = getSsoUrlParams(url);

    if (params.ssotype === EXTERNAL_SERVICES.saml) {
        return samlSSOHandler(params, actions);
    }
    const details = await partnerOauthSSOHandler(params, actions, currentUser);
    return details;
}

function getSSOURI(uriPostfix, context) {
    const uri = `${global.systemEnvironment.SPROUT_BASE_URL.replace('{subdomain}', 'externalservice').replace('/v1/', '')}${uriPostfix}`;
    const isMobile = Platform.OS === 'web' ? 0 : 1;
    return context ? `${uri}&context=${context}&isMobileApp=${isMobile}` : `${uri}&isMobileApp=${isMobile}`;
}

export function triggerSSO(param, context) {
    let connectURI = getSSOURI(param.target, context);
    if (param.logout) {
        connectURI = param.logout + encodeURIComponent(connectURI) + getEncodedOriginParam();
    }
    openUrl(connectURI, true);
}

export function triggerSamlSSO(company) {
    // this replaces login w the supplied subdomain, should be a server change, server only properly replaces login.platform with subdomain
    const prefix = company.ssoLoginUrl.replace(/login/g, company.enteredProgramName);
    const UUID = uuidv1();
    const isMobile = Platform.OS === 'web' ? 'false' : 'true';
    const postfix = encodeURIComponent(`?ssoGuid=${UUID}&mobile=${isMobile}&context=${EXTERNAL_SERVICES.saml}`);
    const url = prefix+postfix+getEncodedOriginParam();
    if (Platform.OS === PLATFORMS.web) {
        openUrl(url, true);
    } else {
        openUrl(url, false);
    }
}

export function triggerSamlSSOFromNewLoginFlow(ssoLoginUrl) {
    const prefix = ssoLoginUrl;
    const UUID = uuidv1();
    const isMobile = Platform.OS === 'web' ? 'false' : 'true';
    const postfix = encodeURIComponent(`?ssoGuid=${UUID}&mobile=${isMobile}&context=${EXTERNAL_SERVICES.saml}`);
    const url = prefix+postfix+getEncodedOriginParam();
    if (Platform.OS === PLATFORMS.web) {
        openUrl(url, true);
    } else {
        openUrl(url, false);
    }
}

export function samlSSOHandler(params, actions) {
    // use the GUID
    const _params = {};
    _params.ssotype = params.ssotype;
    _params.status = params.status;
    _params.context = params.context;
    _params.guid = params.guid;
    _params.programName = params.program;

    if (params.status !== SAML_SSO_STATUSES.authentication_failed) {
        // hit the api with the GUID to pull user details
        // const storeToken = params.status === 'login';
        actions.updateUserSSOInfo(_params);
        actions.checkGUIDStatus(params.guid, _params);
    } else {
        actions.addToast(coreConstants.TOAST_TYPES.DANGER, null, i18n.t('registration.generalError'), null);
    }
    return _params;
}

async function partnerOauthSSOHandler(params, actions, currentUser = {}) {
    // error handling
    if (params.errorCode) {
        const errorMessageKey = _.get(SSO_ERROR_CODES, params.errorCode, null);
        const values = { ...params };
        values.message = !_.isEmpty(errorMessageKey) ? i18n.t(errorMessageKey) : null;
        actions.setSSOError(values);
        return;
    }
    // user_id means the account is already linked to Sprout user
    // will always trigger a login
    const _params = { ...params, oauth: { id: params.id } };

    if ((params.context === SSO_CONTEXT.signUp) || (params.context === SSO_CONTEXT.signIn)) {
        if (params.ssotype === TELUS_SSO_TYPE) {
            tracker.logEvent('Login_Successful_THW');
        }

        if (params.user_id) {
            const _updatedParams = { ..._params, rememberMe: true };
            await actions.login(_updatedParams);
            return { ..._updatedParams, context: SSO_CONTEXT.signIn };
        }
        // attempted sign in with a valid sso account that is not known to our system, switch to sign up flow
        actions.updateUserSSOInfo(_params);

        if (params.ssotype === TELUS_SSO_TYPE) {
            const firstName = _.get(params, 'firstName', '');
            const lastName = _.get(params, 'lastName', '');
            const email = _.get(params, 'email', '');
            const company_id = _.get(params, 'company_id', '');
            const ssotype = _.get(params, 'ssotype', '');
            const token = _.get(params, 'token', '');
            const user_agent = _.get(params, 'user_agent', '');
            const validateOnly = false;
            const allParams = { firstName, lastName, email, company_id, ssotype, token, validateOnly };
            let paramsToSend = {};
            if (user_agent !== '') {
                paramsToSend = { ...allParams, user_agent };
            } else {
                paramsToSend = { ...allParams };
            }

            await actions.createAccount(paramsToSend, true);
        }

        return { ..._params, context: SSO_CONTEXT.signUp };
    }
    if ((params.context === SSO_CONTEXT.accountLink)) {
        const { userId: currentUserId, email: currentUserEmail } = currentUser;

        if (currentUserId === parseInt(params.user_id)) {
            // this means authenticated in OKTA with an account that was at some point linked to this user
            const updatedUserInfo = [];
            if (params.email !== currentUserEmail) updatedUserInfo.push('email');
            actions.ssoLinkUserSuccess({ accountLinkType: params.ssotype, updatedUserInfo }, params.ssotype);
        } else if (!_.isEmpty(params.token) && (!params.user_id)) {
            // this is when we link an external account that is not tied to a sprout user
            actions.ssoLinkUser({ ssotype: params.ssotype, token: params.token });
        } else {
            // this most likely occurs when we attempt to link an account that is already tied to another sprout user
            actions.linkExternalAccountError({ response: {
                data: { accountLinkType: _params.ssotype, message: i18n.t('auth.linkAccount.alreadyLinkedToUser') }
            } });
            _params.context = SSO_CONTEXT.accountLinkFailure;
        }
        return _params;
    }
    actions.updateUserSSOInfo(_params);
    return _params;
}

export function autoLogin(params, actions, company = {}, isSignIn = false) {
    if (isSignIn && (params.ssotype === EXTERNAL_SERVICES.google || params.ssotype === EXTERNAL_SERVICES.facebook || params.ssotype === EXTERNAL_SERVICES.apple)) {
        actions.login(params, true);
    } else if (params.ssotype === EXTERNAL_SERVICES.saml) {
        const { partnerId, companyId } = company;
        const { token, systemSubdomain: subdomain, fullProgramName: programName } = global;
        actions.loginFromSSORegistration({ ssotype: params.ssotype, partnerId, companyId, token, subdomain, programName });
    }
    else if (params.ssotype) {
        actions.loginFromSSORegistration(params);
    }
}

export function openTelusHealthSignUpUrl() {
    openUrl(TELUS_KRABAPPEL_URL, true);
}

export function openTelusLoginUrl() {
    const env = _.get(global.systemEnvironment, 'environment', 'live');
    if (env !== 'live') {
        if (Platform.OS === 'web') {
            openUrl(TELUS_TEST_LOGIN_URL_WEB, true);
        } else {
            openUrl(TELUS_TEST_LOGIN_URL_MOBILE);
        }
    } else {
        // eslint-disable-next-line no-lonely-if
        if (Platform.OS === 'web') {
            openUrl(TELUS_LOGIN_URL_WEB, true);
        } else {
            openUrl(TELUS_LOGIN_URL_MOBILE);
        }
    }
}
