import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';
import { NAME, FILTER_TYPES, CHILD_NAMES, BUILDS, SHARE_WITH_REFERENCES, USER_ROLES, KEYBOARD_TYPES, DATE_FORMATS } from './constants';

export const getBuildName = state => state[NAME][CHILD_NAMES.general].build;
export const getPartnerSubdomain = state => state[NAME][CHILD_NAMES.general].partnerSubdomain;
export const isGeneralLoginPage = state => getPartnerSubdomain(state) === 'login';
export const isSprout = state => getBuildName(state) === BUILDS.sprout;
export const isLiveBetter = state => getBuildName(state) === BUILDS.mylivebetter;
export const isTelus = state => getBuildName(state) === BUILDS.telus;
export const nameKeyboardType = state => isLiveBetter(state) ? KEYBOARD_TYPES.ascii : KEYBOARD_TYPES.default;
export const rememberMeEnabled = state => state[NAME][CHILD_NAMES.general].rememberMeEnabled;
export const getPushNotificationsConfig = state => state[NAME][CHILD_NAMES.general].pushNotificationsConfig;

// general isLoading selector
export const isLoading = (state, requestId) => state[NAME][CHILD_NAMES.loading][requestId];

// general error selector
export const getError = (state, requestId) => state[NAME][CHILD_NAMES.error][requestId];

export const getToasts = state => state[NAME][CHILD_NAMES.toasts];

export const getFilters = state => state[NAME][CHILD_NAMES.general].filters;
export const getShareWithFilters = state => getFilters(state)[FILTER_TYPES.SHARE_WITH];
export const getStreamFilters = state => getFilters(state)[FILTER_TYPES.STREAM_ITEMS];
export const getDefaultStreamFilter = createSelector(
    getStreamFilters, items => _.find(items, filter => filter.isDefault)
);
export const getTimeFiltersOriginal = state => getFilters(state)[FILTER_TYPES.TIME_PERIODS];
export const getTimeFilters = createSelector(getTimeFiltersOriginal, items =>
    _.orderBy(items, i => parseInt(i.filterId), ['asc']));
export const getDefaultTimeFilter = createSelector(getTimeFilters, items => {
    return items.length > 0 ? _.find(items, { isDefault: 1 }) : null;
});
export const getTimeFiltersSortedByDefault = createSelector(getTimeFilters, items => _.orderBy(items, ['isDefault'], ['desc']));
export const getActivitiesFilters = state => getFilters(state)[FILTER_TYPES.ACTIVITES];
export const getActivitiesFiltersWithSubFilters = createSelector(getActivitiesFilters, items => {
    return _.flatMap(items, item => [_.omit(item, ['subFilters']), ...item.subFilters]);
});
export const getDefaultActivitiesFilter = state => getActivitiesFiltersWithSubFilters(state)[0];

export const getOnlyMeFilter = createSelector(
    getShareWithFilters, items => _.find(items, filter => filter.referenceName === SHARE_WITH_REFERENCES.onlyMe)
);

export const getCurrentUser = state => state[NAME][CHILD_NAMES.general].current_user;
export const getUserFirstName = createSelector(getCurrentUser, user => _.get(user, 'firstNameDisplay'));
export const getUserLastName = createSelector(getCurrentUser, user => _.get(user, 'lastNameDisplay'));
export const getCustomPointsName = state => _.get(getCurrentUser(state), 'customPoints.name', 'points');
export const getCustomPointsUnit = state => _.get(getCurrentUser(state), 'customPoints.unit', 'pts');
export const getOnboardingIncompleteValue = createSelector(getCurrentUser, user => _.get(user, 'onboardingIncomplete'));
export const getCurrentUserCompanyId = state => _.get(getCurrentUser(state), 'companyId');
export const getCurrentUserCompanyName = state => _.get(getCurrentUser(state), 'companyName', null);
export const getCurrentUserPartnerName = state => _.get(getCurrentUser(state), 'partnerName', null);
export const getCurrentUserPartnerId = state => getCurrentUser(state).partnerId;
export const getCurrentUserId = state => _.get(getCurrentUser(state), 'userId', null);
export const getCurrentUserRole = state => getCurrentUser(state).role;
export const getCurrentUserLanguage = state => _.get(getCurrentUser(state), 'language', 'en-CA');
export const isUserRole = createSelector(getCurrentUserRole, role => role === USER_ROLES.USER);
export const isUserExtendedRole = createSelector(getCurrentUserRole, role => role === USER_ROLES.MODERATOR || role === USER_ROLES.ADMIN || role === USER_ROLES.PARTNER_ADMIN || role === USER_ROLES.DEVELOPER);
export const isFriendsAndFamilyEnabled = state => !!getCurrentUser(state).isFriendsAndFamilyEnabled;
export const isEnabledHRISCurrentUser = state => _.get(getCurrentUser(state), 'isHRISEnabled') === true;

// this is a proper one. uncomment it and remove folowing line (temporarly one) when api is merged
export const isEnhancedPrivacyInformationEnabled = state => !!getCurrentUser(state).enhancedPrivacyInformationEnabled;
// this is a temporarly one - just to unblock myself while waiting for api
// export const isEnhancedPrivacyInformationEnabled = state => !getCurrentUser(state).enhancedPrivacyInformationEnabled;

export const getPreview = (state, id) => state[NAME][CHILD_NAMES.general].preview[id];

export const isOnline = state => state[NAME][CHILD_NAMES.general].isOnline;

export const getFirstParam = state => state;
export const getSecondParam = (state, param2) => param2;
export const getThirdParam = (state, param2, param3) => param3;
export const getFourthParam = (state, param2, param3, param4) => param4;

export const getItems = (items, ids) => _.map(getIds(ids), id => items[id]);
export const getIds = (items, isCarousel = false) => (isCarousel ? items.slice(0, 10) : items);
export const isWiderScreen = state => state[NAME][CHILD_NAMES.general].isWiderScreen;

export const getBonusChallengesConfiguration = state => getCurrentUser(state).bonusChallenges;


export const getShowTourConfiguration = state => getCurrentUser(state).showTour;

export const getIsHealthSurveyEnabled = state => getCurrentUser(state).isHealthSurveyEnabled;


export const getLastRatingRequestDate = state => state[NAME][CHILD_NAMES.general].lastRatingRequestDate;
export const getInitTime = state => state[NAME][CHILD_NAMES.general].initTime;
export const getUserDateRegistered = createSelector(getCurrentUser, user => moment.unix(user.dateRegistered).format(DATE_FORMATS.full));

export const isSmartModeEnabled = createSelector(getCurrentUser, user => _.get(user, 'isSmartModeEnabled', undefined));
export const userHasDependentRole = createSelector(getCurrentUser, user => _.get(user, 'role', undefined) === 'dependent');
