import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { appFonts, spacing, baseColors } from '../../../../styles';
import { translate } from '../../../core';
import * as selectors from '../../selectors';
import { selectors as onboardingSelectors } from '../../../onboarding';

export default function WithDeviceSupportedActivitiesListBase(WrappedComponent) {
    class DeviceSupportedActivitiesListBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            activities: PropTypes.array.isRequired
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const vendorId = _.get(ownProps, 'vendorId') || _.get(ownProps, 'match.params.vendorId') || _.get(ownProps, 'route.params.vendorId');
        return {
            activities: selectors.getDevicesSupportedActivites(state, vendorId),
            isOnboarding: onboardingSelectors.isShowingOnboarding(state)
        };
    };

    return connect(mapStateToProps)(translate()(DeviceSupportedActivitiesListBase));
}

export const styles = {
    listStyle: {
        paddingLeft: spacing.s0
    },
    itemTitle: {
        ...appFonts.smRegular
    },
    listSeparator: {
        height: 1,
        backgroundColor: baseColors.grey80
    }
};
