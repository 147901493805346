import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite-jss';
import classnames from 'classnames';
import { appFonts, baseColors, spacing, importantClass, media } from '../../../../../styles';
import { components as Core, ROUTES, tracker, onKeyPress, CSS_CLASSES } from '../../../../core';
import WithDashboardShopPointsItemBase, { styles as baseStyles } from './DashboardShopPointsItemBase';

class DashboardShopPointsItem extends PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
        numRedeemable: PropTypes.number.isRequired,
        rewardName: PropTypes.string.isRequired,
        isFirstLoadedRewards: PropTypes.bool.isRequired,
        isRewardsEnabled: PropTypes.bool.isRequired,
        hasShoppingCart: PropTypes.bool,
        hasRedirection: PropTypes.bool,
        isLoadingRewards: PropTypes.bool,
    };

    static defaultProps = {
        hasShoppingCart: true,
        hasRedirection: true,
        isLoadingRewards: false
    }

    goToRewards = () => {
        tracker.logEvent('rewards', { event: 'open' });
        this.props.history.push(ROUTES.rewards());
    };

    render() {
        const { numRedeemable, rewardName, isFirstLoadedRewards, isRewardsEnabled, hasShoppingCart, hasRedirection, isLoadingRewards } = this.props;

        if (!isRewardsEnabled) {
            return null;
        }
        return (
            <div
                tabIndex="0"
                id="shop-points-item"
                role="button"
                onKeyDown={hasRedirection && onKeyPress.enter(this.goToRewards)}
                className={css(styles.outerWrapper)}
                onClick={(isFirstLoadedRewards && hasRedirection) ? this.goToRewards : null}>
                <div id="shop-points-wrapper" className={css(styles.container)}>
                    {!isLoadingRewards ? (
                        <React.Fragment>
                            <div id="points-container" className={css(styles.pointsContainer)}>
                                <div id="points-value" className={css(styles.pointsValueContainer)}>
                                    <Core.Icon
                                        type="light"
                                        name="coins"
                                        size={spacing.s5}
                                        color={baseColors.warn}
                                        className={css(styles.coinsIcon)}
                                    />
                                    <span id="points-value-number" className={css(styles.pointsValueText)}>{numRedeemable}</span>
                                </div>
                                <p id="points-name" className={css(styles.pointsNameText)}>{rewardName}</p>
                            </div>
                            {hasShoppingCart ? (
                                <div id="shopping-icon" className={classnames(css(styles.shoppingIcon), CSS_CLASSES.rewards)}>
                                    <Core.Icon
                                        type="light"
                                        name="shopping-cart"
                                        size={spacing.s6}
                                        color={baseColors.white}
                                    />
                                </div>
                            ) : null}
                        </React.Fragment>
                    ) : (
                        <Core.SkeletonContent width={64} height={spacing.s5}>
                            <Core.SkeletonRect width={64} height={spacing.s5} />
                        </Core.SkeletonContent>
                    )}
                </div>
            </div>
        );
    }
}

export default withRouter(WithDashboardShopPointsItemBase(DashboardShopPointsItem));

const CIRCLE_SIZE = spacing.s12;
const SMALLER_CIRCLE_SIZE = spacing.s11;
const NUM_REDEEMABLE_TOP_PADDING = 6;

const styles = StyleSheet.create({
    ...baseStyles,
    container: {
        flexDirection: 'row',
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
    },
    shoppingIcon: {
        ...baseStyles.shoppingIcon,
        width: CIRCLE_SIZE,
        height: CIRCLE_SIZE,
        borderRadius: CIRCLE_SIZE / 2,
        [media.sm]: {
            width: SMALLER_CIRCLE_SIZE,
            height: SMALLER_CIRCLE_SIZE,
            borderRadius: SMALLER_CIRCLE_SIZE / 2,
        },
    },
    pointsContainer: {
        ...baseStyles.pointsContainer,
        paddingRight: spacing.s2,
    },
    pointsValueText: {
        ...baseStyles.pointsValueText,
        ...appFonts.xlRegular,
        paddingTop: NUM_REDEEMABLE_TOP_PADDING,
        lineHeight: `${spacing.s4}px`,
        [media.sm]: {
            ...appFonts.lgRegular,
            paddingTop: 0,
        }
    },
    pointsNameText: {
        ...appFonts.smRegular,
        color: baseColors.black,
        [media.sm]: {
            ...appFonts.xsRegular,
        },
    },
    outerWrapper: {
        justifySelf: 'end',
        '-ms-grid-column': 5,
        '-ms-grid-column-align': 'end',
    },
    coinsIcon: importantClass({
        [media.sm]: {
            height: spacing.s4,
            width: spacing.s4,
        }
    }),
});
