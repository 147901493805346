import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import classNames from 'classnames';
import { baseColors, fontSize, spacing } from '../../../../styles';
import Icon from '../Icon';
import WithEmptyListSimpleBase, { styles as baseStyles } from './EmptyListSimpleBase';

const EmptyListSimple = ({ message, icon, className, isIconHidden, isBigSpace, i18n, iconName }) => (
    <div className={classNames(css(styles.empty, isBigSpace ? styles.emptyBigSpace : null), className)}>
        {iconName ? <Icon type="fa" name={iconName} color={baseColors.grey70} size={spacing.s7} /> : null}
        {!isIconHidden && !iconName ? icon : null}
        <span className={css(styles.emptyText)}>{message || i18n.t('emptyListDefaultMessage')}</span>
    </div>
);

EmptyListSimple.propTypes = {
    message: PropTypes.string,
    icon: PropTypes.element,
    className: PropTypes.string,
    isIconHidden: PropTypes.bool,
    isBigSpace: PropTypes.bool,
    i18n: PropTypes.object.isRequired,
    iconName: PropTypes.string
};

EmptyListSimple.defaultProps = {
    message: '',
    icon: <Icon type="fa" name="search" color={baseColors.grey50} size={spacing.s7} />,
    className: null,
    isIconHidden: false,
    isBigSpace: false,
    iconName: 'search'
};

export default WithEmptyListSimpleBase(EmptyListSimple);

const styles = StyleSheet.create({
    ...baseStyles,
    empty: {
        ...baseStyles.empty,
        paddingTop: spacing.s4,
        clear: 'both'
    },
    emptyText: {
        ...baseStyles.emptyText,
        fontSize: fontSize.xxxxlRegular,
        maxWidth: '100%'
    },
    emptyBigSpace: {
        paddingTop: spacing.s7 * 2,
    }
});
