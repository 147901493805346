import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite-jss';

import { baseColors, importantStyles, spacing } from '../../../../styles';
import WithDeviceItemBase, { styles as baseStyles } from './DeviceItemBase';
import { components as Core, ROUTES } from '../../../core';
import { appsDevicesHelper } from '../../services';

class DeviceItem extends PureComponent {
    static propTypes = {
        device: PropTypes.object.isRequired,
        formatLastSyncDate: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired,
        i18n: PropTypes.object.isRequired,
        getDeviceHasError: PropTypes.func.isRequired,
        getShowDeviceErrorWarning: PropTypes.func.isRequired,
        getErrorWarningString: PropTypes.func.isRequired,
        deviceActivity: PropTypes.object,
        formatActivtyString: PropTypes.func.isRequired,
        isOnboarding: PropTypes.bool,
        isChallenge: PropTypes.bool,
    };

    static defaultProps = {
        deviceActivity: null,
        isOnboarding: false,
        isChallenge: false,
    };

    onDeviceDetails = () => {
        const { vendorId } = this.props.device;
        const { isOnboarding } = this.props;
        isOnboarding ? this.props.history.push(ROUTES.onboardingDeviceDetails(vendorId)) :
            this.props.history.push(ROUTES.deviceDetails(vendorId));
    };

    get deviceDescription() {
        const { i18n, device } = this.props;
        return device.connected ?
            `${i18n.t('last_synced')}: ${this.props.formatLastSyncDate()}` :
            device.description;
    }

    get rightArrowIcon() {
        return this.props.isChallenge ? (
            <Core.Icon
                type="fa"
                fill="regular"
                name="angle-right"
                color={baseColors.grey50}
                className={css(styles.iconContainer)}
            />
        ) : (
            <Core.Icon
                type="fa"
                fill="light"
                name="angle-right"
                color={baseColors.grey40}
            />
        );
    }

    renderStatus = () => {
        const { device, isConnected, getDeviceHasError } = this.props;

        const statusText = appsDevicesHelper.deviceStatusString(device);

        if (isConnected) {
            return (
                <div className={css(styles.statusSection)}>
                    <p className={css(getDeviceHasError() ? styles.errorText : styles.statusText)}>{statusText}</p>
                </div>
            );
        }

        return null;
    };

    renderActivity = () => {
        const { deviceActivity } = this.props;

        if (deviceActivity) {
            return (
                <div>
                    <p className={css(styles.activityString)}>{this.props.formatActivtyString()}</p>
                </div>
            );
        }

        return null;
    };

    render() {
        const { device, isChallenge, isOnboarding } = this.props;
        const classes = { root: isChallenge ? css(styles.challengeRoot) : css(styles.root, !isOnboarding && styles.notOnbRoot) };
        const iconStyles = css(styles.deviceIcon, styles.deviceIconNew, isChallenge && styles.deviceIconChallenge);
        return (
            <Core.ListItem key={device.name} button={true} onClick={this.onDeviceDetails} classes={classes}>
                <Core.ListItemIcon className={css(isChallenge && styles.listItemIcon)}>
                    <Core.Image src={device.iconUrl} className={iconStyles} alt={`${device.displayName} logo image`} />
                </Core.ListItemIcon>
                <div className={css(styles.cardDetails)}>
                    <p className={css(styles.deviceName, isChallenge && styles.deviceItemName)}>{device.displayName}</p>
                    {this.renderStatus()}
                    {this.renderActivity()}
                </div>
                {this.rightArrowIcon}
            </Core.ListItem>
        );
    }
}

const ICON_SIZE = spacing.s9;
const ICON_NEW_SIZE = spacing.s10;

const styles = StyleSheet.create(importantStyles({
    ...baseStyles,
    deviceIcon: {
        ...baseStyles.deviceIcon,
        height: ICON_SIZE,
        width: ICON_SIZE
    },
    deviceIconNew: {
        height: ICON_NEW_SIZE,
        width: ICON_NEW_SIZE
    },
    root: {
        ...baseStyles.mainContainer,
        paddingLeft: spacing.s5,
        paddingRight: spacing.s3,
        marginLeft: spacing.s7,
        marginRight: spacing.s7,
        width: 'unset',
        paddingTop: spacing.s2,
        paddingBottom: spacing.s2,
        borderBottomStyle: 'solid',
        '&:hover': {
            backgroundColor: baseColors.grey90
        },
    },
    notOnbRoot: {
        marginLeft: `calc(25% + ${spacing.s0}px)`,
        marginRight: `calc(25% + ${spacing.s0}px)`,
    },
    challengeRoot: {
        ...baseStyles.mainContainer,
        padding: spacing.s3,
        width: 'unset',
        borderBottomStyle: 'solid',
        '&:hover': {
            backgroundColor: baseColors.grey90
        },
    },
    listItemIcon: {
        minWidth: 'unset',
    },
    iconContainer: {
        paddingLeft: spacing.s3,
    },
}));

export default withRouter(WithDeviceItemBase(DeviceItem));
