import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import classnames from 'classnames';
import { baseColors, spacing, layoutStyle, importantClass } from '../../../../styles';
import { styles as baseStyles } from './RemovableItemBase';
import { IconButton } from '../Icon';

class RemovableItem extends PureComponent {
    static propTypes = {
        text: PropTypes.string.isRequired,
        onClose: PropTypes.func.isRequired,
        className: PropTypes.string,
    };

    static defaultProps = {
        className: undefined
    };

    render() {
        const { className } = this.props;
        return (
            <span className={classnames(css(layoutStyle.flexCenter, layoutStyle.inlineFlex, styles.container), className)}>
                <span className={styles.text}>{this.props.text}</span>
                <IconButton
                    isSmall={true}
                    className={css(styles.icon)}
                    type="fa"
                    fill="solid"
                    name="times-circle"
                    size={spacing.s3}
                    color={baseColors.grey20}
                    onClick={this.props.onClose}
                />
            </span>
        );
    }
}

const styles = StyleSheet.create({
    ...baseStyles,
    icon: importantClass(baseStyles.icon)
});

export default RemovableItem;
