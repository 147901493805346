import _ from 'lodash';
import moment from 'moment';
import I18n from '../../../i18n/i18n';
import * as constants from '../constants';
import { DATE_FORMATS } from '../../core';

const GREATER_THAN = (val, unit) => `${I18n.t('edit_profile_value_too_high')} ${Math.ceil(val)} ${unit}`;
const LESS_THAN = (val, unit) => `${I18n.t('edit_profile_value_too_low')} ${Math.floor(val)} ${unit}`;
const REQUIRED_MESSAGE = () => I18n.t('required');

// the values below are taken from the profileEditorHelper.js
const LIMITS = {
    height: {
        minimum: 23,
        maximum: 108
    },
    waist: {
        minimum: 23,
        maximum: 80
    },
    weight: {
        minimum: 9,
        maximum: 1000
    }
};

export const compareValue = (preferredUnit, unitToCompare, key, value, denominator, shouldReturnString = false) => {
    const isMetricUnit = preferredUnit === unitToCompare;
    const maximum = isMetricUnit ? LIMITS[key].maximum / denominator : LIMITS[key].maximum;
    const minimum = isMetricUnit ? Math.round(LIMITS[key].minimum / denominator) : LIMITS[key].minimum;
    const isGreater = isMetricUnit ? parseFloat(value) > maximum : parseFloat(value) >= maximum;
    const isLess = isMetricUnit ? parseFloat(value) <= minimum : parseFloat(value) <= minimum;
    let errorString = '';
    if (isGreater) {
        errorString = GREATER_THAN(maximum, preferredUnit);
    } else if (isLess) {
        errorString = LESS_THAN(minimum, preferredUnit);
    }
    if (errorString) {
        return shouldReturnString ? errorString : { [key]: errorString };
    }
    return null;
};

export const checkForErrors = ({ heightValue, weightValue, waistValue, dateOfBirth, question, allFieldsRequired, userPreferredHeightUnit, userPreferredWeightUnit, userPreferredWaistUnit }) => {
    const errors = {};

    let dateFormatted;
    if (dateOfBirth) {
        dateFormatted = moment(dateOfBirth, DATE_FORMATS.fullSlash, true);
    }

    if (!dateOfBirth && (question[constants.VITAL_STATS.dateOfBirth.required] || allFieldsRequired)) {
        _.assign(errors, { dateOfBirth: REQUIRED_MESSAGE() });
    } else if (dateFormatted && (!dateFormatted.isValid() || !dateFormatted.isBetween(constants.MIN_DATE, constants.MAX_DATE))) {
        _.assign(errors, { dateOfBirth: I18n.t('invalidDate') });
    }

    if (!heightValue && (question[constants.VITAL_STATS.heightInches.required] || allFieldsRequired)) {
        _.assign(errors, { height: REQUIRED_MESSAGE() });
    } else {
        const error = compareValue(userPreferredHeightUnit, constants.CM, 'height', heightValue, 0.394);
        if (error) _.assign(errors, error);
    }

    if (!weightValue && (question[constants.VITAL_STATS.weightLbs.required] || allFieldsRequired)) {
        _.assign(errors, { weight: REQUIRED_MESSAGE() });
    } else {
        const error = compareValue(userPreferredWeightUnit, constants.KG, 'weight', weightValue, 2.2);
        if (error) _.assign(errors, error);
    }

    return errors;
};

// Formats date in yyyy/mm/dd while typing
export const formatTypedDateForWeb = ({ dob }) => {
    const length = dob.length;
    let updatedString = dob;
    if (length === 5 && !updatedString.endsWith('/')) {
        updatedString = `${updatedString.substring(0, 4)}/${updatedString.substring(4)}`;
    }
    if (length === 7 && updatedString.endsWith('/')) {
        updatedString = `${updatedString.substring(0, 5)}0${updatedString.substring(5)}`;
    }
    if (length === 8 && !updatedString.endsWith('/') && updatedString.charAt(6) !== '/') {
        updatedString = `${updatedString.substring(0, 7)}/${updatedString.substring(7)}`;
    }
    if (length === 8 && !updatedString.endsWith('/') && updatedString.charAt(6) === '/') {
        updatedString = `${updatedString.substring(0, 4)}/0${updatedString.substring(5)}`;
    }
    if (length > 10) updatedString = updatedString.substring(0, 10);

    return updatedString;
};
