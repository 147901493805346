import _ from 'lodash';
import { normalize, actions as coreActions, getRequestId, constants as coreConstants } from '../core';
import i18n from '../../i18n/i18n';
import * as types from './actionTypes';
import * as api from './api';
import { EVENT_ENTITY_TYPES } from './constants';
import { actions as feedsActions, constants as feedsConstants } from '../feeds';
import * as selector from './selectors';
import { validateError } from '../../config';

export function getAllEvents(params = {}, type = EVENT_ENTITY_TYPES.ALL_ITEMS, isReplace) {
    return function (dispatch) {
        dispatch({ type: types.GET_EVENTS.REQUEST, payload: { requestId: getRequestId(types.GET_EVENTS.NAME, type) } });
        return api.getAllEvents(params)
            .then(response => {
                dispatch(getAllEventSuccess(params, response.data, type, isReplace));
            })
            .catch(error => validateError(error, error => {
                dispatch({
                    type: types.GET_EVENTS.ERROR,
                    payload: { requestId: getRequestId(types.GET_EVENTS.NAME, type), error }
                });
            }));
    };
}

export function getUpcomingEvents(userId, type, isReplace) {
    return function (dispatch, getState) {
        dispatch({ type: types.GET_EVENTS.REQUEST, payload: { requestId: getRequestId(types.GET_EVENTS.NAME, type) } });
        return api.getUserUpcomingEvents(userId)
            .then(response => {
                dispatch(getAllEventSuccess({}, _.map(response.data.upcomingEvents, item => {
                    const isMember = selector.isUserAndCurrentUserAntendedTheSameEntity(getState(), item.eventAttendees);
                    return {
                        ...item,
                        isMember
                    };
                }), type, isReplace));
            })
            .catch(error => validateError(error, error => {
                dispatch({
                    type: types.GET_EVENTS.ERROR,
                    payload: { requestId: getRequestId(types.GET_EVENTS.NAME, type), error }
                });
            }));
    };
}

export function getAllEventSuccess(params, events, type, isReplace) {
    let data = events;
    // TODO - remove this check after it will be implemented in server side
    if (type === EVENT_ENTITY_TYPES.RECOMMENDED) {
        data = _.filter(events, item => !item.isMember && !item.isPrivate);
    }
    return {
        type: types.GET_EVENTS.SUCCESS,
        payload: {
            data: normalize.normalizeArray(data, 'eventId'),
            params,
            requestId: getRequestId(types.GET_EVENTS.NAME, type),
            type,
            isReplace
        }
    };
}

export function getEventById(eventId) {
    return function (dispatch) {
        dispatch({ type: types.GET_EVENT_BY_ID.REQUEST,
            payload: { requestId: getRequestId(types.GET_EVENT_BY_ID.NAME, eventId) }
        });
        return api.getEventById(eventId)
            .then(response => {
                dispatch({
                    type: types.GET_EVENT_BY_ID.SUCCESS,
                    payload: { data: response.data, requestId: getRequestId(types.GET_EVENT_BY_ID.NAME, eventId) }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({
                    type: types.GET_EVENT_BY_ID.ERROR,
                    payload: {
                        toast: { actionType: 'eventAction' },
                        error,
                        requestId: getRequestId(types.GET_EVENT_BY_ID.NAME, eventId),
                        isErrorStore: true
                    }
                });
            }));
    };
}

export function joinEvent(id, params = {}) {
    return function (dispatch) {
        dispatch({ type: types.JOIN_EVENT.REQUEST, payload: { requestId: getRequestId(types.JOIN_EVENT.NAME, id) } });
        return api.joinEvent(id, params)
            .then(response => {
                dispatch({
                    type: types.JOIN_EVENT.SUCCESS,
                    payload: {
                        data: response.data,
                        toast: {
                            title: i18n.t('joiningEventToast.success.title', { eventName: response.data.eventName }),
                            msg: i18n.t('joiningEventToast.success.messageName')
                        },
                        requestId: getRequestId(types.JOIN_EVENT.NAME, id)
                    }
                });
                dispatch(feedsActions.setStreamViewerMember(id));
                setTimeout(() => dispatch(coreActions.askUserForAppStoreReviewIfPossible()), coreConstants.APP_RATING_DELAY);
            })
            .catch(error => validateError(error, error => {
                dispatch({
                    type: types.JOIN_EVENT.ERROR,
                    payload: {
                        error,
                        toast: { msg: error.data },
                        requestId: getRequestId(types.JOIN_EVENT.NAME, id),
                        isErrorStore: true
                    }
                });
            }));
    };
}

export function createEvent(data, actionType = 'eventAction') {
    return function (dispatch) {
        dispatch({ type: types.CREATE_EVENT.REQUEST });
        return api.createEvent(data)
            .then(response => {
                dispatch({
                    type: types.CREATE_EVENT.SUCCESS,
                    payload: {
                        data: { ...response.data.event, isMember: 1 },
                        toast: {
                            title: i18n.t('createEventToast.success.title', { eventName: response.data.event.eventName }),
                            msg: i18n.t('createEventToast.success.message')
                        }
                    }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({
                    type: types.CREATE_EVENT.ERROR,
                    payload: {
                        toast: { actionType },
                        error
                    }
                });
            }));
    };
}

export function editEvent(data, actionType = 'eventAction') {
    return function (dispatch) {
        dispatch({ type: types.EDIT_EVENT.REQUEST });

        return api.editEvent(data)
            .then(response => {
                dispatch({
                    type: types.EDIT_EVENT.SUCCESS,
                    payload: {
                        data: response.data.event,
                        toast: {
                            title: i18n.t('editEventToast.success.title', { eventName: response.data.event.eventName }),
                            msg: i18n.t('editEventToast.success.message')
                        }
                    }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({
                    type: types.EDIT_EVENT.ERROR,
                    payload: {
                        toast: { actionType },
                        error
                    }
                });
            }));
    };
}

export function deleteEvent({ eventId, name }, actionType = 'eventAction') {
    return function (dispatch) {
        dispatch({ type: types.DELETE_EVENT.REQUEST });
        return api.deleteEvent(eventId)
            .then(response => {
                dispatch({
                    type: types.DELETE_EVENT.SUCCESS,
                    payload: {
                        eventId,
                        toast: {
                            title: i18n.t('deleteEventToast.success.title', { eventName: name }),
                            msg: i18n.t('deleteEventToast.success.message')
                        }
                    }
                });
                dispatch(feedsActions.deleteStreamsRelatedItemsDetails(eventId, feedsConstants.STREAM_ENTITY_TYPES.event));
            })
            .catch(error => validateError(error, error => {
                dispatch({
                    type: types.DELETE_EVENT.ERROR,
                    payload: {
                        toast: { actionType },
                        error,
                    }
                });
            }));
    };
}

export function clearGetEventError(eventId) {
    return function (dispatch) {
        dispatch(coreActions.removeError(getRequestId(types.GET_EVENT_BY_ID.NAME, eventId)));
    };
}

export function leaveEvent(eventId, userId, actionType = 'leaveEvent') {
    return function (dispatch) {
        dispatch({ type: types.LEAVE_EVENT.REQUEST });
        return api.leaveEvent(eventId)
            .then(response => {
                dispatch({
                    type: types.LEAVE_EVENT.SUCCESS,
                    payload: {
                        data: { eventId, userId },
                        toast: {
                            title: i18n.t('leaveEventToast.success.title', { eventName: response.data.eventName || '' }),
                            msg: i18n.t('leaveEventToast.success.message')
                        }
                    }
                });
                dispatch(feedsActions.removeStreamViewerMember(eventId));
            })
            .catch(error => validateError(error, error => {
                dispatch({
                    type: types.LEAVE_EVENT.ERROR,
                    payload: {
                        toast: {
                            title: i18n.t('dangerToastTitle'),
                            msg: i18n.t('dangerToastMessage')
                        },
                        error
                    }
                });
            }));
    };
}

export function getEventMembers(eventId, params) {
    return function (dispatch) {
        dispatch({ type: types.GET_EVENT_MEMBERS.REQUEST });
        return api.getEventMembers(eventId, params)
            .then(response => {
                dispatch({
                    type: types.GET_EVENT_MEMBERS.SUCCESS,
                    payload: {
                        eventId,
                        data: normalize.normalizeArray(response.data),
                        isReplace: !_.get(params, 'start')
                    }
                });
            })
            .catch(error => validateError(error, error => {
                dispatch({
                    type: types.GET_EVENT_MEMBERS.ERROR,
                    payload: { error }
                });
            }));
    };
}

export function hideEventNotification(id) {
    return function (dispatch) {
        dispatch({
            type: types.EDIT_EVENT.SUCCESS,
            payload: {
                data: { eventId: id, isNotificationShown: false },
                isReplace: false
            }
        });
    };
}