import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { containerStyle, layoutStyle, spacing, baseColors } from '../../../../styles';
import { components as Core, openUrl } from '../../../core';
import WithDisclaimerBase, { styles as baseStyles } from './DisclaimerBase';

class Disclaimer extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        disclaimerContent: PropTypes.string,
        i18n: PropTypes.object.isRequired,
        toggle: PropTypes.func.isRequired,
        submit: PropTypes.func.isRequired,
        checked: PropTypes.bool.isRequired,
        isTelus: PropTypes.bool.isRequired,
        isModal: PropTypes.bool
    };

    static defaultProps = {
        disclaimerContent: undefined,
        isModal: false
    };

    componentDidMount() {
        window.addEventListener('click', this.click);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.click);
    }

    click = event => {
        if (event.target.href) {
            event.preventDefault();
            openUrl(event.target.href);
        }
    };

    render() {
        return (
            <div className={css(layoutStyle.fw)}>
                <div className={css(containerStyle.container)}>
                    <Core.SubHeader titleAlign={Core.SubHeader.ALIGN.center} left={<span />} title={this.props.i18n.t('disclaimer')} />
                    <div className={css(containerStyle.main, this.props.isModal && styles.spaceUnderContent)}>
                        {this.props.disclaimerContent ? <Core.InnerHtml htmlText={this.props.disclaimerContent} className={css(styles.aTags, styles.wrapping)} /> : null}
                    </div>
                    {this.props.isModal ? (
                        <div
                            tabIndex="0"
                            role="button"
                            onClick={this.props.close}
                            className={css(layoutStyle.fw, layoutStyle.flexRowCenter, styles.closeButton)}>
                            <p className={css(styles.closeButtonText)}>
                                {this.props.i18n.t('close')}
                            </p>
                        </div>
                    ) : (
                        <div className={css(styles.footer)}>
                            <Core.Checkbox
                                checked={this.props.checked}
                                onChange={this.props.toggle}
                                label={this.props.i18n.t('acceptTerms')}
                                color="default"
                            />
                            <div>
                                <Core.Button
                                    lowercase={true}
                                    fullWidth={true}
                                    className={css(containerStyle.buttonText)}
                                    disabled={!this.props.checked}
                                    useTelusColors={this.props.isTelus}
                                    onPress={this.props.submit}>
                                    {this.props.i18n.t('next')}
                                </Core.Button>
                            </div>
                        </div>
                    )}
                </div>
                <Core.BlockingLoading isLoading={this.props.isLoading} />
            </div>
        );
    }
}

export default WithDisclaimerBase(Disclaimer);

const styles = StyleSheet.create({
    ...baseStyles,
    wrapping: {
        wordWrap: 'break-word',
        marginLeft: -spacing.s2,
        marginRight: -spacing.s2
    },
    footer: {
        ...baseStyles.footer,
        marginTop: spacing.s3,
        textAlign: 'center'
    },
    aTags: {
        '& a': {
            color: baseColors.secondary
        }
    },
    closeButton: {
        borderColor: baseColors.grey70,
        borderTopWidth: 1,
        borderTopStyle: 'solid',
        minHeight: spacing.s10,
        position: 'absolute',
        backgroundColor: baseColors.white,
        bottom: 0,
        left: 0
    },
    closeButtonText: {
        textAlign: 'center',
        color: baseColors.secondary,
    },
    spaceUnderContent: {
        marginBottom: spacing.s11,
        padding: spacing.s3
    }
});
