import { NAME as name } from './constants';
import { createActionTypes } from '../core';

const NAME = name.toUpperCase();

export const GET_REWARDS = createActionTypes(`${NAME}/GET_REWARDS`);
export const GET_HEROS = createActionTypes(`${NAME}/GET_HEROS`);
export const GET_REWARDS_PRODUCTS = createActionTypes(`${NAME}/GET_REWARDS_PRODUCTS`);
export const GET_REWARDS_PRODUCT = createActionTypes(`${NAME}/GET_REWARDS_PRODUCT`);
export const REDEEM_REWARDS_PRODUCT = createActionTypes(`${NAME}/REDEEM_REWARDS_PRODUCT`);
export const GET_REWARDS_TRANSACTIONS = createActionTypes(`${NAME}/GET_REWARDS_TRANSACTIONS`);
export const GET_REWARDS_ORDERS = createActionTypes(`${NAME}/GET_REWARDS_ORDERS`);
export const GET_REWARDS_ORDER = createActionTypes(`${NAME}/GET_REWARDS_ORDER`);
export const GET_REWARDS_CATEGORIES = createActionTypes(`${NAME}/GET_REWARDS_CATEGORIES`);

export const GET_LEVELS = createActionTypes(`${NAME}/GET_LEVELS`);
export const GET_ACHIEVEMENTS = createActionTypes(`${NAME}/GET_ACHIEVEMENTS`);
export const GET_UPCOMING_ACHIEVEMENTS = createActionTypes(`${NAME}/GET_UPCOMING_ACHIEVEMENTS`);

export const GET_REWARD_REDEMPTION_TOKEN = createActionTypes(`${NAME}/GET_REWARD_REDEMPTION_TOKEN`);
export const CLEAR_REWARD_REDEMPTION_TOKEN = `${NAME}/CLEAR_REWARD_REDEMPTION_TOKEN`;
export const CLEAR_REDEEM_REWARD_ORDER_ID = `${NAME}/CLEAR_REDEEM_REWARD_ORDER_ID`;

export const GET_SHIPPING_ADDRESS = createActionTypes(`${NAME}/GET_SHIPPING_ADDRESS`);

export const GET_EARNPARTNERS_PARTNERS = createActionTypes(`${NAME}/GET_EARNPARTNERS_PARTNERS`);
export const GET_EARNPARTNERS_PARTNER = createActionTypes(`${NAME}/GET_EARNPARTNERS_PARTNER`);
export const GET_EARNPARTNERS_CATEGORIES = createActionTypes(`${NAME}/GET_EARNPARTNERS_CATEGORIES`);
export const CREATE_EARNPARTNERS_LINK = createActionTypes(`${NAME}/CREATE_EARNPARTNERS_LINK`);
export const UPDATE_EARNPARTNERS_LINK = createActionTypes(`${NAME}/UPDATE_EARNPARTNERS_LINK`);
export const REMOVE_EARNPARTNERS_LINK = createActionTypes(`${NAME}/REMOVE_EARNPARTNERS_LINK`);