import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { css } from 'aphrodite-jss';

import WithAppsDevicesPreviewBase from './AppsDevicesPreviewBase';
import { components as Core, ROUTES } from '../../../core';
import { baseColors, layoutStyle } from '../../../../styles';

class AppsDevicesPreview extends PureComponent {
    static propTypes = {
        syncStatus: PropTypes.string,
        numConnected: PropTypes.string,
        history: PropTypes.object.isRequired,
        i18n: PropTypes.object.isRequired
    };

    static defaultProps = {
        syncStatus: '',
        numConnected: ''
    };

    openDevices = () => {
        this.props.history.push(ROUTES.appsDevices());
    };

    render() {
        const {
            i18n,
            numConnected
        } = this.props;
        return (
            <Core.ListItem button={true} onClick={this.openDevices} className={css(layoutStyle.listItem)}>
                <Core.ListItemText
                    primary={`${i18n.t('my_apps_devices')} ${numConnected}`}
                    secondary={this.props.syncStatus}
                />
                <Core.Icon
                    type="fa"
                    name="angle-right"
                    color={baseColors.secondary}
                />
            </Core.ListItem>
        );
    }
}

export default withRouter(WithAppsDevicesPreviewBase(AppsDevicesPreview));