import { createTheme } from '@material-ui/core/styles';
import baseColors from './commonColor';

export const fontFamily = 'Helvetica Now Text SA';

export const themeStyles = {
    palette: {
        primary: { main: baseColors.primary },
        secondary: { main: baseColors.secondary },
        error: { main: baseColors.danger },
    },
    overrides: {
        MuiSelect: {
            root: {
                color: baseColors.secondary
            },
            icon: {
                fill: baseColors.secondary,
            }
        },
    },
    typography: {
        // Use the font instead of the default Roboto font.
        fontFamily: [
            fontFamily,
            'Helvetica Neue',
            'Helvetica',
            'Arial',
            'sans-serif'
        ].join(','),
    }
};

const theme = createTheme(themeStyles);

export default theme;
