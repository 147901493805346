import React, { PureComponent } from 'react';
import { translate } from '../../../core';
import { spacing } from '../../../../styles';

export default function WithLeaderboardChallengesEmptyBase(WrappedComponent) {
    class LeaderboardChallengesEmptyBase extends PureComponent {
        render() {
            return (
                <WrappedComponent {...this.props} />
            );
        }
    }

    return translate()(LeaderboardChallengesEmptyBase);
}

export const styles = {
    mainContainer: {
        flex: 1,
        paddingTop: spacing.s5
    }
};
