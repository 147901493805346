import _ from 'lodash';
import { createSelector } from 'reselect';
import { NAME, LOGIN_STATUSES, REGISTRATION_STEPS, EXTERNAL_SERVICES, HRIS_INTAKE_TYPE } from './constants';
import * as helper from './services/helper';
import * as types from './actionTypes';
import { selectors as coreSelectors, constants as coreConstants, parsers, PLATFORMS } from '../core';
import Platform from '../core/services/Platform';
import i18n from '../../i18n/i18n';

export const getSlides =
    state => state[NAME].slides[coreSelectors.getBuildName(state)] || state[NAME].slides[coreConstants.BUILDS.sprout];
export const getGlobalSettings = state => state[NAME].globalSettings;

export const getUser = state => state[NAME].user;
export const getUserId = state => _.get(getUserInfo(state), 'userId');
export const getRememberMe = state => getUser(state).rememberMe;
export const getLoginError = state => coreSelectors.getError(state, types.LOGIN.NAME);
export const getLogin = state => state[NAME].login;
export const getLoginRegistrationStep = state => getLogin(state).registrationStep;
export const isLoggedIn = state => state[NAME].loginStatus === LOGIN_STATUSES.loggedIn;
export const isLoggedOut = state => state[NAME].loginStatus === LOGIN_STATUSES.loggedOut;
export const isLogining = state => coreSelectors.isLoading(state, types.LOGIN.NAME);
export const getLoginStatus = state => state[NAME].loginStatus;
export const getRegistrationStatus = state => _.get(state[NAME].login, 'registrationStatus');
export const isCheckingRegistrationStatus = state => coreSelectors.isLoading(state, types.CHECK_EMAIL_REGISTRATION_STATUS.NAME);
export const getSSOLoginURLNewFlow = state => _.get(state[NAME].login, 'ssoLoginURL');
export const getRegistrationStatusEmail = state => _.get(state[NAME].login, 'registrationStatusEmail');

const getLoginInfo = state => _.get(getUser(state), 'login_info');
export const getProgramName = state => (getCompanyProgramName(state) ||
(_.get(getLoginInfo(state), 'fullProgramName') || _.get(getLoginInfo(state), 'programName')));
export const getCompanyProgramName = state => _.get(getCompany(state), 'programName') || _.get(getCompany(state), 'data.programName', '');
export const getCompanySubdmain = state => _.get(getCompany(state), 'subdomain') || _.get(getCompany(state), 'data.subdomain', '');
export const getProgramNameOrAppName = state => getLoginInfo(state).fullProgramName || i18n.t('appname');

export const getPartner = state => state[NAME].partner;
export const getPartnerId = state => _.get(getPartner(state), 'partnerId');
export const hasPartnerSso = state => _.has(getPartner(state), 'sso.uri');
export const hasPartnerSsoSignUpModal = state => _.has(getPartner(state), 'sso.sso_signup_modal_content');
export const hasPartnerSsoLinkingModal = state => _.has(getPartner(state), 'sso.sso_linking_account_modal_content');
export const getPartnerSsoSignUpModal = state => _.get(getPartner(state), 'sso.sso_signup_modal_content');
export const getPartnerSsoLinkingModal = state => _.get(getPartner(state), 'sso.sso_linking_account_modal_content');
export const getPartnerSsoUri = state => _.get(getPartner(state), 'sso.uri');
export const getPartnerConfiguration = state => getPartner(state).configurations;
export const getCompany = state => state[NAME].company;
export const getCompanyId = createSelector(getCompany, company => _.get(company, 'companyId'));
export const getCompanySupportedLanguages = createSelector(getCompany, company => _.get(company, 'supportedLanguages'));
export const getCompanyBrand = createSelector(getCompany, company => _.get(company, 'brand'));
export const hasCompanySso = state => _.has(getCompany(state), 'sso.uri');
export const isMultipleSignIn = state => hasCompanySso(state) || isFacebookEnabled(state) || isGoogleEnabled(state);
export const getCompanyConfiguration = state => _.get(getCompany(state), 'configurations', {});
export const getCompanySsoConfiguration = state => _.get(getCompanyConfiguration(state), 'sso', {});
export const isLoadingProgramDetails = state => coreSelectors.isLoading(state, types.GET_PROGRAM_DETAILS.NAME);
export const isLoadingCompany = state => coreSelectors.isLoading(state, types.GET_COMPANY_CONFIGURATION.NAME);
export const isLoggingIn = state => coreSelectors.isLoading(state, types.LOGIN.NAME);
export const getCompanyError = state => coreSelectors.getError(state, types.GET_COMPANY_CONFIGURATION.NAME);
export const isPartnerDomain = state => _.has(getCompany(state), 'multiCompanyPartner');
export const hasCompany = state => !!getCompany(state).companyId;
export const isRetail = state => getCompany(state).retail === 1/* && getCompany(state).companyId === '0'*/;
export const isRetailUser = state => !coreSelectors.getCurrentUserCompanyId(state);
export const isPin = state => getCompany(state).isPin;
export const getRegistrationSteps = state => getCompany(state).registrationSteps;
export const hasDisclaimerStep = state => _.includes(getRegistrationSteps(state), REGISTRATION_STEPS.disclaimer);
export const hasLocation = state => _.includes(getRegistrationSteps(state), REGISTRATION_STEPS.location);
export const getRegistrationFields = state => getCompanyConfiguration(state).registrationFields;
export const getEnabledRegistrationFields = createSelector(getRegistrationFields, coreSelectors.getBuildName, coreSelectors.isLiveBetter, helper.getRegistrationFields);
export const isWhitelabel = state => _.get(getCompanyConfiguration(state), 'isWhitelabel');
export const disclaimerAvailable = createSelector(getCompany, company => _.get(company, 'disclaimerAvailable', 0));
export const companyIsHybridLogin = createSelector(getCompany, company => _.get(company, 'hybridLogin', 0));

export const getEnabledExternalServices =
    state => _.get(getPartnerConfiguration(state), 'externalAccountsIntegration', []);
export const getOtherEnabledExternalServices = createSelector(getEnabledExternalServices,
    services => _.filter(services, s => s !== EXTERNAL_SERVICES.facebook && s !== EXTERNAL_SERVICES.google && s !== EXTERNAL_SERVICES.apple));
export const isEnabledExternalServices = state => !!getEnabledExternalServices(state).length;
export const isFacebookEnabled = state => _.includes(getEnabledExternalServices(state), EXTERNAL_SERVICES.facebook);
export const isGoogleEnabled = state => _.includes(getEnabledExternalServices(state), EXTERNAL_SERVICES.google);
export const isAppleEnabled = state => (_.includes(getEnabledExternalServices(state), EXTERNAL_SERVICES.apple) && (Platform.OS === PLATFORMS.ios && parseInt(Platform.Version) >= 13));
export const isSamlEnabled = state => !!getCompany(state).ssoLoginUrl;
export const isOnlySamlEnabled = state => !!getCompany(state).ssoLoginUrl && !isEnabledExternalServices(state);

export const getExternalAccounts = state => state[NAME].externalAccounts;
export const getEnabledExternalAccounts =
    createSelector(getExternalAccounts, services => _.filter(services, s => s.active === '1' && s.linked === true));
export const getEnabledExternalAccountNames =
    createSelector(getEnabledExternalAccounts, services => _.map(services, 'external_account_type'));
export const isLastEnabledExternalAccount = state => getEnabledExternalAccounts(state).length === 1;

export const getSsoDetails = state => getUser(state).sso;
export const isSso = state => !_.isEmpty(getSsoDetails(state));
export const hasSsoLoginUrl = state => !!getCompany(state).ssoLoginUrl;
export const isCheckingSamlStatus = state => coreSelectors.isLoading(state, types.CHECK_SAML_STATUS.NAME);
export const getCheckSamlStatusError = state => coreSelectors.getError(state, types.CHECK_SAML_STATUS.NAME);
export const getSsoLoginUrl = state => getCompany(state).ssoLoginUrl;

export const getEmailProgramNameError = state => coreSelectors.getError(state, types.GET_EMAIL_PROGRAM_NAME.NAME);
export const isLoadingEmailProgramName = state => coreSelectors.isLoading(state, types.GET_EMAIL_PROGRAM_NAME.NAME);

export const getPrivacyPolicy = state => state[NAME].privacyPolicy;
export const getTermsOfService = state => state[NAME].termsOfService;
export const isLoadingLegals = state => (
    coreSelectors.isLoading(state, types.GET_PRIVACY_POLICY.NAME) ||
    coreSelectors.isLoading(state, types.GET_TERMS_OF_SERVICE.NAME)
);

export const getDisclaimer = state => state[NAME].disclaimer;
export const isLoadingDisclaimer = state => coreSelectors.isLoading(state, types.GET_DISCLAIMER.NAME);

export const getPolicyPopups = state => state[NAME].policyPopups;
export const getUpdatedPoliesForPopup = createSelector(getPolicyPopups, popups => _.filter(popups, popup => popup.show_popup === 1));

export const getCreateAccount = state => state[NAME].createAccount;
export const isCreatingAccount = state => coreSelectors.isLoading(state, types.CREATE_ACCOUNT.NAME);
export const getCreateAccountError = state => coreSelectors.getError(state, types.CREATE_ACCOUNT.NAME);
export const isDeletingAccount = state =>
    coreSelectors.isLoading(state, types.DELETE_ACCOUNT.NAME) ||
    coreSelectors.isLoading(state, types.UNLINK_CORPORATE_ACCOUNT.NAME);

export const isLinkingAccount = state =>
    coreSelectors.isLoading(state, types.LINK_EXTERNAL_ACCOUNT.NAME) ||
    coreSelectors.isLoading(state, types.UNLINK_EXTERNAL_ACCOUNT.NAME) ||
    coreSelectors.isLoading(state, types.LINK_CORPORATE_ACCOUNT.NAME);

export const getLocations = state => state[NAME].locations;
export const isLoadingLocations = state => coreSelectors.isLoading(state, types.GET_LOCATIONS.NAME);
export const getCountries = state => getLocations(state).countries;
export const getCountriesForSelect = createSelector(
    [getCountries], countries => _.map(countries, country => ({
        ...country,
        id: country.name,
        label: country.name,
        regions: _.map(country.regions, region => ({
            ...region,
            id: region.name,
            label: region.name,
            cities: region.cities && _.map(region.cities, city => ({
                ...city,
                id: city.name,
                label: city.name,
                locations: _.map(city.locations, location => ({
                    ...location,
                    id: location.location_id,
                    label: location.location_name
                }))
            })),
            locations: region.locations && _.map(region.locations, location => ({
                ...location,
                id: location.location_id,
                label: location.location_name
            }))
        }))
    }))
);
export const getDepartments = state => state[NAME].departments;
export const getRegions = state => state[NAME].regions;
const getDepartmentFields = items => parsers.getItemsForSelect(items, 'department_name', 'department_id');
const getRegionsFields = items => parsers.getItemsForSelect(items, 'name', 'id');
export const getDepartmentsForSelect = createSelector([getDepartments], getDepartmentFields);
export const getRegionsForSelect = createSelector([getRegions], getRegionsFields);

export const getAssociatedLocations = createSelector(
    getCountries,
    countries =>
        _.reduce(
            countries,
            (el, val) => {
                _.forEach(val.regions, r => _.forEach(r.cities, c => _.forEach(c.locations, l => el.push(l))));
                return el;
            },
            []
        )
);

export const getCorporateAccountData = state => _.get(state[NAME].corporateAccountLink, 'data', {});
export const getUserLocations = state => state[NAME].userLocations;
export const isLoadingDepartments = state => coreSelectors.isLoading(state, types.GET_DEPARTMENTS.NAME);
export const isLoadingRegions = state => coreSelectors.isLoading(state, types.GET_REGIONS.NAME);
export const isLoadingUserLocations = state => coreSelectors.isLoading(state, types.GET_USER_LOCATIONS.NAME);
export const isLoadingUser = state => coreSelectors.isLoading(state, types.GET_USER.NAME);
export const isSettingLocation = state => coreSelectors.isLoading(state, types.SET_USER_LOCATIONS.NAME);

export const getTemporaryAuthentication = state => getLogin(state).temporaryAuthentication;

export const getProfileFields = state => getUser(state).profileFields;
export const isUpdatingUser = state => coreSelectors.isLoading(state, types.UPDATE_USER.NAME);
export const isSavingUser = state => coreSelectors.isLoading(state, types.SAVE_USER.NAME);
export const isCheckingVerification = state => coreSelectors.isLoading(state, types.VERIFY_EMAIL.NAME);

export const getLocalRegistrationStep = state => state[NAME].localRegistrationStep;
export const getLocalLoginStep = state => state[NAME].localLoginStep;

export const isResetingPassword = state => coreSelectors.isLoading(state, types.RESET_PASSWORD.NAME);
export const getResetPasswordError = state => coreSelectors.getError(state, types.RESET_PASSWORD.NAME);

export const isSigningOut = state => coreSelectors.isLoading(state, types.SIGNOUT.NAME);

export const isChangingPassword = state => coreSelectors.isLoading(state, types.CHANGE_PASSWORD.NAME);
export const getChangePasswordError = state => coreSelectors.getError(state, types.CHANGE_PASSWORD.NAME);

// HRIS
export const isEnabledHRIS = state => _.get(getCompanyConfiguration(state), 'isHRISEnabled') === true;
export const HRISIntakeType = state => _.get(getCompanyConfiguration(state), 'intakeType');
export const isNoEmailHRIS = createSelector(HRISIntakeType, intakeType => intakeType === HRIS_INTAKE_TYPE.noEmail);
export const isMutliIntakeHRIS = createSelector(HRISIntakeType, intakeType => intakeType === HRIS_INTAKE_TYPE.multi);

export const getHRISLocations = state => state[NAME].HRISLocations;
export const isLoadingHRISLocations = state => coreSelectors.isLoading(state, types.GET_HRIS_LOCATIONS.NAME);
export const getHrisId = state => _.get(state[NAME].savedHRISLocation, 'id', undefined);
export const HRISLocationIntakeType = state => _.get(state[NAME].savedHRISLocation, 'intake_type', undefined);
export const isHRISLocationNoEmailHRIS = createSelector(HRISLocationIntakeType, intakeType => intakeType === HRIS_INTAKE_TYPE.noEmail);
export const getHrisCountryId = state => _.get(state[NAME].savedHRISLocation, 'countryId', undefined);

export const getUserInfo = state => getUser(state).otherInfo;
export const isLoadingCurrentUser = state => coreSelectors.isLoading(state, types.GET_USER.NAME);

export const getCurrentUserLanguage = state => getUserInfo(state).language;

// API issues for country, it doesn't follow the format of the other entries, hack the id for no
// id is not used for anything so acceptable for now
// country ? null : 0, null is the valid value, means country was supplied, 0 means no country, 0 triggers the condition for data not being properly prefilled
export const getPresetLocationInfo = createSelector(
    getUserInfo,
    ({ country, company, department, location, city, region }) => ({
        country: { id: country ? null : 0, label: country || '' },
        department: { id: _.get(department, 'id', 0), label: _.get(department, 'name', '') },
        location: { id: _.get(location, 'id', 0), label: _.get(location, 'name', '') },
        city: { id: _.get(city, 'id', 0), label: _.get(city, 'name', '') },
        region: { id: _.get(region, 'id', 0), label: _.get(region, 'name', '') },
        company: { id: _.get(company, 'id', 0), label: _.get(company, 'name', '') }
    })
);

export const isDataPrefilled = state => {
    // id of zero is invalid, allow the user to select
    const invalidValueFound = _.values(getPresetLocationInfo(state)).some(i => _.get(i, 'id') === 0);
    return !invalidValueFound;
};

export const isLocationDataReadOnly = createSelector(getUserInfo, ({ readOnly }) => _.includes(readOnly, 'location'));

export const getCurrentLocation = state => {
    if (isDataPrefilled(state)) {
        return getPresetLocationInfo(state);
    }
    return state[NAME].currentLocation;
};

//(isEnabledHRIS ? getPresetLocationInfo(state) : state[NAME].currentLocation);

export const getEnteredProgramName = state => state[NAME].programName;

export const getProgram = createSelector(coreSelectors.getPartnerSubdomain, getEnteredProgramName,
    (subdomain, programName) => subdomain || programName);

export const getLoginQueryParams = state => state[NAME].loginQueryParams;

export const isCheckingEmployeeID = state => coreSelectors.isLoading(state, types.CHECK_EMPLOYEE_ID.NAME);

export const getEmployeeIDStatus = createSelector(getCompany, company => _.get(company, 'employeeIDStatus'));
export const getEmployeeID = createSelector(getCompany, company => _.get(company, 'employeeID'));

export const getLocationDropDownLabels = createSelector(getCompany, company => _.get(company, 'locationDropdownLabels', {}));

export const isTHVCSSOEnabled = state => _.get(getCompanyConfiguration(state), 'isTHVCSSOEnabled') === true;

export const isUserFromTheUS = state => {
    const UNITED_STATES = 'United States';
    return getUserInfo(state).country === UNITED_STATES;
};

export const isFamilyInviteEnabled = state => _.get(getCompanyConfiguration(state), 'isFamilyInviteEnabled', false);

export const isBiometricsEnabled = state => _.get(getCompanyConfiguration(state), 'showBiometrics', false);

export const getExternalApps = state => _.get(getCompany(state), 'externalApps', []);