import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { appFonts, baseColors, spacing } from '../../../../styles';
import { translate, tracker } from '../../../core';

export default function WithProductItemBase(WrappedComponent) {
    class ProductItemBase extends Component {
        static propTypes = {
            product: PropTypes.object.isRequired,
            onPressSingle: PropTypes.func,
            separator: PropTypes.bool,
            mainContainerStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.array])
        };

        static defaultProps = {
            onPressSingle: null,
            separator: true,
            mainContainerStyle: null
        };

        onDetailsPress = () => {
            tracker.logEvent('rewards', { event: 'product_details', item: this.item.productName });
            if (this.props.onPressSingle) {
                this.props.onPressSingle(this.item);
            }
        };

        get item() {
            return this.props.product;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    item={this.item}
                    onDetailsPress={this.onDetailsPress}
                />
            );
        }
    }

    return translate()(ProductItemBase);
}

export const styles = {
    innerContainer: {
        flexDirection: 'row'
    },
    itemContainer: {
        flex: 1,
        backgroundColor: 'transparent',
        flexDirection: 'column'
    },
    title: {
        color: baseColors.black,
        marginBottom: spacing.s0,
        ...appFonts.mdBold
    },
    details: {
        ...appFonts.smRegular,
        color: baseColors.primary
    },
    item: {
        paddingTop: spacing.s3,
        paddingBottom: spacing.s3,
    }
};