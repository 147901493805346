import React, { PureComponent } from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import PropTypes from 'prop-types';
import _ from 'lodash';

import WithOnboardingInfoScreenBase, { styles as baseStyles } from './OnboardingInfoScreenBase';
import InfoScreen from '../InfoScreen';
import { baseColors, layoutStyle, spacing } from '../../../../styles';
import { components as Core } from '../../../core';
import SkipGoButtons from '../SkipGoButtons';

class OnboardingInfoScreen extends PureComponent {
    static propTypes = {
        customButtons: PropTypes.func,
        customBody: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        skip: PropTypes.func,
        go: PropTypes.func,
        isLoading: PropTypes.bool,
        hasSkipGoBtns: PropTypes.bool,
        hasEarnPart: PropTypes.bool,
        points: PropTypes.string,
        textStyle: PropTypes.string,
        textContainerStyle: PropTypes.string,
        screen: PropTypes.object,
        headerStyles: PropTypes.string,
        timeText: PropTypes.string,
        title: PropTypes.string,
        bodyText: PropTypes.string,
        icon: PropTypes.string,
        leftButtonLabel: PropTypes.string,
        rightButtonLabel: PropTypes.string,
        hasBorder: PropTypes.bool,
        onClick: PropTypes.func,
        isFlexBodyContainer: PropTypes.bool,
        isLiveBetter: PropTypes.bool.isRequired,
        earnText: PropTypes.string,
        onboardingNewDesign: PropTypes.bool,
        isWelcomeUserScreen: PropTypes.bool,
        i18n: PropTypes.object.isRequired
    };

    static defaultProps = {
        screen: {},
        isLoading: false,
        hasSkipGoBtns: false,
        hasEarnPart: false,
        timeText: '',
        points: '',
        title: null,
        bodyText: null,
        icon: null,
        skip: null,
        go: null,
        customButtons: null,
        customBody: null,
        textStyle: null,
        textContainerStyle: null,
        headerStyles: null,
        leftButtonLabel: null,
        rightButtonLabel: null,
        onClick: null,
        hasBorder: true,
        isFlexBodyContainer: true,
        earnText: null,
        onboardingNewDesign: false,
        isWelcomeUserScreen: false,
    }

    get body() {
        const { isFlexBodyContainer, isLoading, customBody, hasEarnPart, isLiveBetter, onboardingNewDesign, isWelcomeUserScreen } = this.props;

        if (onboardingNewDesign && isWelcomeUserScreen) return customBody;
        return (
            <div className={css(isLiveBetter && layoutStyle.largeFooterOffset, isFlexBodyContainer ? layoutStyle.flexJustifyCenter : null)}>
                {isLoading ?
                    <Core.ListLoading key="Loading" /> :
                    <>
                        {customBody}
                        {hasEarnPart ? this.renderEarnPart() : null}
                    </>
                }
            </div>
        );
    }

    renderEarnPart = () => {
        const { screen, timeText, isLoading, points, i18n } = this.props;
        return (
            <div className={css(layoutStyle.flex1, styles.body)}>
                <div className={css(styles.earnSection)}>
                    {(_.get(screen, 'points') !== 0  && !isLoading) ?
                        <div className={css(layoutStyle.flexRowCenter)}>
                            <Core.Tag label={`+ ${points} ${i18n.t('bonus').toLowerCase()}`} />
                        </div> : null
                    }
                    <div className={css(layoutStyle.flexRowCenter, styles.timeSection)}>
                        <Core.Icon type="fa" name="clock" size={spacing.s2} color={baseColors.grey40} />
                        <div className={css(styles.time)}>{timeText}</div>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { customButtons, hasSkipGoBtns, headerStyles, textStyle, textContainerStyle } = this.props;
        const hasNextButton = !customButtons && !hasSkipGoBtns;
        return (
            <InfoScreen
                {...this.props}
                headerStyles={headerStyles || styles.header}
                isOnboardingInfoScreen={true}
                hasNextButton={hasNextButton}
                customBody={this.body}
                customButtons={<SkipGoButtons {...this.props} />}
                textStyle={textStyle || styles.description}
                textContainerStyle={textContainerStyle || styles.descriptionContainer}
            />
        );
    }
}

const styles = StyleSheet.create({
    ...baseStyles,
});

export default WithOnboardingInfoScreenBase(OnboardingInfoScreen);
