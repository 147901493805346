import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import classNames from 'classnames';
import { List, ListItem, ListItemText, ListItemIcon } from '../List';
import Divider from '../Divider';
import Switch from '../Switch';
import ActionSheet from '../ActionSheet';
import { spacing, importantClass } from '../../../../styles';

class ListOptionPopup extends PureComponent {
    static propTypes = {
        itemClass: PropTypes.string,
        isSwitch: PropTypes.bool,
        icon: PropTypes.node,
        title: PropTypes.string.isRequired,
        action: PropTypes.func,
        checked: PropTypes.bool,
        rightIcon: PropTypes.node,
        options: PropTypes.array
    };

    static defaultProps = {
        itemClass: undefined,
        isSwitch: false,
        icon: undefined,
        action: undefined,
        checked: undefined,
        rightIcon: undefined,
        options: undefined
    };

    open = () => {
        if (this.props.options && this.props.options.length) {
            return ActionSheet.open(this.props.options);
        }
        if (this.props.action) this.props.action();
    };

    render() {
        const { isSwitch, icon, title, checked, rightIcon, itemClass } = this.props;
        const classes = { root: classNames(itemClass) };
        return (
            <div>
                <List component="nav" disablePadding={true}>
                    <ListItem button={!isSwitch} onClick={this.open} classes={classes}>
                        {icon ?
                            <ListItemIcon>
                                <div>{icon}</div>
                            </ListItemIcon> : null}
                        <ListItemText primary={title} />
                        {isSwitch ?
                            <Switch
                                value={checked}
                                className={css(styles.switchWrapper)}
                            /> : null
                        }
                        {rightIcon ? rightIcon : null}
                    </ListItem>
                    <Divider light={true} />
                </List>
            </div>
        );
    }
}

const styles = StyleSheet.create({
    switchWrapper: importantClass({
        left: spacing.s2
    })
});

export default ListOptionPopup;