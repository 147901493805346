import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { appFonts, baseColors, spacing } from '../../../../../styles';

export default function WithNotificationSectionBase(WrappedComponent) {
    class NotificationSectionBase extends PureComponent {
        static propTypes = {
            title: PropTypes.string.isRequired,
            text: PropTypes.string,
            icon: PropTypes.node,
            bg: PropTypes.string,
            hasShadow: PropTypes.bool,
        };

        static defaultProps = {
            text: '',
            bg: baseColors.grey20,
            icon: null,
            hasShadow: false
        };

        render() {
            return <WrappedComponent {...this.props} />;
        }
    }

    return NotificationSectionBase;
}

const BORDER_RADIUS = 8;

export const styles = {
    endedNotification: {
        padding: spacing.s3,
        marginLeft: spacing.s3,
        marginRight: spacing.s3,
        marginBottom: spacing.s5,
        marginTop: spacing.s1,
        borderRadius: BORDER_RADIUS,
        justifyContent: 'flex-start'
    },
    icon: {
        marginRight: spacing.s3
    },
    endedNotificationTitle: {
        ...appFonts.mdMedium,
        color: baseColors.white
    },
    endedNotificationSubTitle: {
        ...appFonts.smRegular,
        color: baseColors.white,
        paddingTop: spacing.s1
    }
};
