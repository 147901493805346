import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import translate from '../../services/translate';
import { appFonts, spacing, baseColors } from '../../../../styles';

export default function WithInfoModalBase(WrappedComponent) {
    class InfoModalBase extends PureComponent {
        static propTypes = {
            iconName: PropTypes.string,
            renderIcon: PropTypes.func,
            i18n: PropTypes.object.isRequired,
            buttonTitle: PropTypes.string,
        };

        static defaultProps = {
            iconName: undefined,
            renderIcon: null,
            buttonTitle: undefined
        };

        constructor(props) {
            super(props);
            this.state = { isChecked: false };
        }

        onCheckbox = () => this.setState(prevState => ({ isChecked: !prevState.isChecked }));

        get isChecked() {
            return this.state.isChecked;
        }

        get buttonTitle() {
            return this.props.buttonTitle || this.props.i18n.t('continue');
        }

        get hasIcon() {
            return !!(this.props.renderIcon || this.props.iconName);
        }

        get iconBgColor() {
            return this.props.iconBackgroundColor || baseColors.primary;
        }

        get hasSecondLineText() {
            const { text, textSecondLine, htmlText, renderText } = this.props;
            return !!text && (!!textSecondLine || !!htmlText || !!renderText);
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    hasIcon={this.hasIcon}
                    isChecked={this.isChecked}
                    onCheckbox={this.onCheckbox}
                    buttonTitle={this.buttonTitle}
                    iconBgColor={this.iconBgColor}
                    hasSecondLineText={this.hasSecondLineText}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const routeParams = _.get(ownProps, 'route.params');
        return { ...(routeParams || {}) };
    };

    return connect(mapStateToProps)(translate()(InfoModalBase));
}

export const ICON_SIZE = 40;
export const BUTTON_HEIGHT = 50;
export const TOP_DISTANCE_TO_ICON = spacing.s7;
export const ICON_BACKGROUND_SIZE = spacing.s15;
export const SHADOW = { opacity: 0.3, height: 30 };

export const BOTTOM_BTN_STYLES = {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: spacing.s1,
    borderBottomLeftRadius: spacing.s1,
};

export const styles = {
    title: {
        ...appFonts.lgMedium,
        textAlign: 'center',
        marginTop: spacing.s3,
        color: baseColors.black,
        marginBottom: spacing.s3,
    },
    description: {
        ...appFonts.smRegular,
        color: baseColors.grey20,
        marginBottom: spacing.s7,
    },
    centerText: {
        textAlign: 'center'
    },
    noBorderRadius: {
        borderRadius: 0,
    },
    dangerText: {
        ...appFonts.mdMedium,
        color: baseColors.dangerDarker,
    },
    defaultText: {
        ...appFonts.mdRegular,
        color: baseColors.secondary,
    },
    boldText: {
        ...appFonts.mdMedium,
        color: baseColors.secondary,
    },
    modalBtn: {
        borderRadius: 0,
        height: BUTTON_HEIGHT,
        backgroundColor: baseColors.white,
    },
    modalBottomBtn: {
        ...BOTTOM_BTN_STYLES,
        height: BUTTON_HEIGHT,
        backgroundColor: baseColors.white,
    },
    mTop: {
        marginTop: spacing.s3,
    },
    checkboxLabel: {
        ...appFonts.smMedium,
        color: baseColors.dangerDarker,
    },
    checkboxContainer: {
        marginBottom: spacing.s7,
    },
    mBottom: {
        marginBottom: spacing.s3,
    },
};
