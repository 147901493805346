import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectors as coreSelectors, translate } from '../../../core';
import { appFonts, baseColors, spacing } from '../../../../styles';
import * as actions from '../../actions';
import * as selectors from '../../selectors';

export default function WithProfileOverviewBase(WrappedComponent) {
    class ProfileOverviewBase extends PureComponent {
        static propTypes = {
            currentUser: PropTypes.object.isRequired,
            showBorder: PropTypes.bool,
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            isLiveBetter: PropTypes.bool.isRequired,
            isUpdating: PropTypes.bool,
            isDeleting: PropTypes.bool
        };

        static defaultProps= {
            showBorder: false,
            isUpdating: false,
            isDeleting: false
        };

        get locationString() {
            const { currentUser } = this.props;
            return (currentUser.location && currentUser.location !== 'global') ? currentUser.location : null;
        }

        get departmentString() {
            const { currentUser } = this.props;
            return (currentUser.department) ? currentUser.department : null;
        }

        get avatarName() {
            const { currentUser } = this.props;
            return currentUser.firstNameDisplay !== undefined ?
                `${currentUser.firstNameDisplay} ${currentUser.lastNameDisplay}` : '';
        }

        get preferredName() {
            const { currentUser } = this.props;
            return currentUser.firstNameDisplay !== undefined ?
                `${currentUser.preferredName ?
                    currentUser.preferredName :
                    currentUser.firstNameDisplay} ${currentUser.lastNameDisplay}` : '';
        }

        updateImage = data => {
            this.props.actions.updateAvatar({ imageFileData: data });
        };

        deletePhoto = () => {
            this.props.actions.deleteAvatar();
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    locationString={this.locationString}
                    departmentString={this.departmentString}
                    avatarName={this.avatarName}
                    preferredName={this.preferredName}
                    updateImage={this.updateImage}
                    deletePhoto={this.deletePhoto}
                />
            );
        }
    }

    const mapStateToProps = state => ({
        currentUser: coreSelectors.getCurrentUser(state),
        isUpdating: selectors.isUpdatingAvatar(state),
        isDeleting: selectors.isDeletingAvatar(state),
        isLiveBetter: coreSelectors.isLiveBetter(state)
    });

    const mapDispatchToProps = dispatch => ({
        actions: bindActionCreators(actions, dispatch)
    });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(ProfileOverviewBase));
}

export const styles = {
    profileOverview: {
        marginBottom: spacing.s3
    },
    profileSummaryContainer: {
        flexDirection: 'column',
        alignItems: 'center'
    },
    profileImage: {
        marginRight: spacing.s3
    },
    leftProfileContainer: {
        marginBottom: spacing.s1,
        marginRight: spacing.s2
    },
    profileSummary: {
        flex: 1,
        marginBottom: spacing.s1,
        marginTop: spacing.s1,
        marginLeft: spacing.s0
    },
    nameText: {
        ...appFonts.lgMedium,
        color: baseColors.black,
        marginLeft: 0
    },
    locationText: {
        ...appFonts.smRegular,
        color: baseColors.grey40
    },
    editProfileText: {
        ...appFonts.mdMedium,
        color: baseColors.secondary
    },
    icon: {
        alignItems: 'center',
        justifyContent: 'center',
        height: spacing.s5,
        width: spacing.s5,
        borderRadius: spacing.s2,
        backgroundColor: baseColors.secondary
    },
    showBorder: {
        borderBottomColor: baseColors.grey80,
        borderBottomWidth: 1
    }
};
