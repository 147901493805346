import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';
import { isIE } from 'react-device-detect';
import { components as Core } from '../../../../../core';
import { spacing } from '../../../../../../styles';

class ChallengeCardHeaderIcon extends PureComponent {
    static propTypes = {
        icon: PropTypes.object.isRequired,
        isPersonal: PropTypes.bool
    };

    static defaultProps = {
        isPersonal: true
    };

    get isChallengeIcon() {
        return !this.props.isPersonal;
    }

    get iconStyle() {
        if (isIE) {
            return this.isChallengeIcon ? styles.challengeIconIE : styles.iconIE;
        }
        return styles.icon;
    }

    render() {
        const { icon } = this.props;
        return (
            <div className={css(styles.iconWrapper)} style={{ backgroundColor: icon.bgr }}>
                {icon.iconUrl ?
                    <Core.Image
                        src={icon.iconUrl}
                        className={css(this.iconStyle)}
                    /> :
                    <Core.Icon
                        type="fa"
                        name={icon.iconName}
                        size={ICON_SIZE}
                        color={icon.icon}
                    />}
            </div>
        );
    }
}

export default ChallengeCardHeaderIcon;

const ICON_SIZE = spacing.s8;
const CHALLENGE_ICON_WIDTH = spacing.s9;

const styles = StyleSheet.create({
    iconWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    icon: {
        height: '100%',
        width: '100%'
    },
    iconIE: {
        height: ICON_SIZE,
        width: ICON_SIZE
    },
    challengeIconIE: {
        height: ICON_SIZE,
        width: CHALLENGE_ICON_WIDTH
    }
});
