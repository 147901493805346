import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { baseColors, spacing } from '../../../../styles';
import { translate, Storage, Platform, PLATFORMS, ENTITIES_ACTIVE_TAB } from '../../../core';
import { ACHIEVEMENTS_STATUSES, ACHIEVEMENTS_TABS } from '../../constants';
import * as rewardsSelectors from '../../selectors';
import * as rewardsActions from '../../actions';

export default function WithMyAchievementsBase(WrappedComponent) {
    class MyAchievementsBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            selectedTab: PropTypes.object,
            achievements: PropTypes.array,
            earnedAchievements: PropTypes.array,
            upcomingAchievements: PropTypes.array,
            inProgressAchievements: PropTypes.array,
            i18n: PropTypes.object.isRequired
        };

        static defaultProps = {
            achievements: [],
            earnedAchievements: [],
            inProgressAchievements: [],
            upcomingAchievements: [],
            selectedTab: undefined
        };

        constructor(props) {
            super(props);
            this.TABS = {
                [ACHIEVEMENTS_TABS.achievementsInProgress]: {
                    label: this.props.i18n.t('in_progress'),
                    id: ACHIEVEMENTS_TABS.achievementsInProgress,
                    emptyText: this.props.i18n.t('noInProgressAchievements')
                },
                [ACHIEVEMENTS_TABS.achievementsEarned]: {
                    label: this.props.i18n.t('earned'),
                    id: ACHIEVEMENTS_TABS.achievementsEarned,
                    emptyText: this.props.i18n.t('noEarchedAchievements')
                },
                [ACHIEVEMENTS_TABS.allAchievements]: {
                    label: this.props.i18n.t('all'),
                    id: ACHIEVEMENTS_TABS.allAchievements,
                    emptyText: ''
                },
                [ACHIEVEMENTS_TABS.upcomingAchievements]: {
                    label: this.props.i18n.t('upcoming'),
                    id: ACHIEVEMENTS_TABS.upcomingAchievements,
                    emptyText: this.props.i18n.t('noUpcomingAchievements')
                }
            };
            this.tabs = [
                this.TABS.achievementsInProgress,
                this.TABS.achievementsEarned,
                this.TABS.allAchievements,
                this.TABS.upcomingAchievements
            ];
            this.state = {
                selectedTab: props.selectedTab ? this.TABS[props.selectedTab] : this.tabs[0]
            };
            props.actions.getUpcomingAchievements();
        }

        async componentDidMount() {
            if (Platform.OS === PLATFORMS.web) {
                const selectedTab = await Storage.getItem(ENTITIES_ACTIVE_TAB.MY_ACHIEVEMENTS);
                if (selectedTab) {
                    this.setState({ selectedTab });
                }
            }
        }

        get itemsToRender() {
            switch (this.state.selectedTab.id) {
                case ACHIEVEMENTS_TABS.achievementsInProgress:
                    return this.props.inProgressAchievements;
                case ACHIEVEMENTS_TABS.achievementsEarned:
                    return this.props.earnedAchievements;
                case ACHIEVEMENTS_TABS.upcomingAchievements:
                    return this.props.upcomingAchievements;
                default:
                    return this.props.achievements;
            }
        }

        tabSelected = tab => {
            this.setState({ selectedTab: tab });

            if (Platform.OS === PLATFORMS.web) {
                Storage.setItem(ENTITIES_ACTIVE_TAB.MY_ACHIEVEMENTS, tab);
            }
        };

        get title() {
            return this.props.i18n.t('my_achievements');
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    itemsToRender={this.itemsToRender}
                    tabs={this.tabs}
                    tabSelected={this.tabSelected}
                    selectedTab={this.state.selectedTab}
                    title={this.title}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const earnedAchievements = _.reverse(_.sortBy(_.map(rewardsSelectors.getEarnedAchievements(state), item => ({ ...item, badgeStatus: ACHIEVEMENTS_STATUSES.earned })), [item => _.get(item, 'datesAwarded[0].end_date', '')]));
        const inProgressAchievements = rewardsSelectors.getInProgressAchievements(state);
        const availableAchievements = rewardsSelectors.getAvailableAchievements(state);
        const upcomingAchievements = rewardsSelectors.getUpcomingAchievements(state);
        const allAchievements = [
            ..._.orderBy(inProgressAchievements, ['percentageCompletion', 'achievementEndDate', 'name'], ['desc', 'desc', 'asc']),
            ..._.orderBy(availableAchievements, ['name'], ['asc']),
            ..._.filter(upcomingAchievements, item => moment(item.achievementStartDate).isBefore(moment().endOf('year'))),
            ..._.filter(earnedAchievements, item => moment(item.dateEarned && !_.startsWith(item.dateEarned, '0000') ? item.dateEarned : _.get(item, 'datesAwarded[0].end_date')).isAfter(moment().startOf('year')))
        ];
        /*
        * For all tab:
        * 1. Show in progress badges with the most progress first
        * 2. Show upcoming badges (only up until the year period end - so if the year is calendar starting in Jan and ending in Dec and it is October,
        * upcoming should only show badges I can earn until December)
        * 3. Earned should show all the badges earned this YTD period.
        */
        return {
            earnedAchievements,
            inProgressAchievements,
            upcomingAchievements,
            achievements: allAchievements,
            selectedTab: ownProps.selectedTab || _.get(ownProps, 'location.state.selectedTab'),
        };
    };

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(rewardsActions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(MyAchievementsBase));
}

export const styles = {
    main: {
        flex: 1,
        backgroundColor: baseColors.white
    },
    header: {
        marginBottom: spacing.s3
    },
    tabs: {
        marginLeft: spacing.s0 / 2
    },
    emptyTest: {
        padding: spacing.s3
    }
};
