import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { translate, tracker } from '../../../../core';
import { spacing, baseColors, appFonts, imageSize } from '../../../../../styles';
import * as partnersActions from '../../../actions';
import { getEarnPartner, isLinkingEarnPartner, isLoadingEarnPartner } from '../../../selectors';

export const ONE_LINE = 1;
export const TWO_LINES = 2;
export const THREE_LINES = 3;

export default function WithEarnPartnersDetailBase(WrappedComponent) {
    class EarnPartnersDetailBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            partner: PropTypes.object,
            partnerId: PropTypes.number.isRequired,
            item: PropTypes.object,
            i18n: PropTypes.object.isRequired,
            isLinking: PropTypes.bool,
            isLoading: PropTypes.bool
        };

        static defaultProps = {
            partner: {},
            item: {},
            isLinking: false,
            isLoading: false
        };

        constructor(props) {
            super(props);
            const { partner } = props;
            this.partnerDescriptions = [{
                description: partner.howToEarnDescription,
                label: partner.howToEarnLabel
            }, {
                description: partner.pointsEarnRateDescription,
                label: partner.pointsEarnRateLabel
            }, {
                description: partner.instructionsDescription,
                label: partner.instructionsLabel
            }];
            this.listItems = [{
                url: partner.partnerWebsiteUrl,
                label: partner.partnerWebsiteLabel
            }, {
                url: partner.informationalWebsiteUrl,
                label: partner.informationalWebsiteLabel
            }, {
                url: partner.acctCreationUrl,
                label: partner.acctCreationWebsiteLabel
            }, {
                url: partner.termsAndConditionsUrl,
                label: partner.termsAndConditionsLabel
            }];
            this.state = {
                userAccountNumber: partner.accountNumber || '',
                updatingLinkStatus: false,
                identifierFieldError: ''
            };
            props.actions.getEarnPartner(props.partnerId);
        }

        onChange = prop => value => {
            this.setState({ [prop]: value });
        };

        get item() {
            return this.props.partner;
        }

        get showLinkingStatus() {
            if (this.props.isLoading && this.state.updatingLinkStatus) {
                return true;
            }

            return this.props.isLinking;
        }

        linkPartner = () => {
            const { partner, i18n } = this.props;
            const { userAccountNumber } = this.state;
            const parameters = {
                earnPartnerId: partner.earnPartnerId,
                accountNumber: userAccountNumber
            };

            if (!userAccountNumber) {
                this.setState({ identifierFieldError: i18n.t('membershipIdentifierRequiredError') });
            } else {
                this.setState({ updatingLinkStatus: true });
                tracker.logEvent('earnPartner', { event: 'link', id: partner.earnPartnerId });
                return this.props.actions.linkNewEarnPartnerLink(parameters);
            }
        };

        unlinkPartner = () => {
            const { partner } = this.props;
            const { userAccountNumber } = this.state;
            const parameters = {
                earnPartnerId: partner.earnPartnerId,
                accountNumber: userAccountNumber
            };

            this.setState({ updatingLinkStatus: true });
            tracker.logEvent('earnPartner', { event: 'unlink', id: partner.earnPartnerId });
            return this.props.actions.unlinkEarnPartnerLink(parameters);
        };

        updateLinkPartner = () => {
            const { partner, i18n } = this.props;
            const { userAccountNumber } = this.state;
            const parameters = {
                earnPartnerId: partner.earnPartnerId,
                accountNumber: userAccountNumber
            };

            if (!userAccountNumber) {
                this.setState({ identifierFieldError: i18n.t('membershipIdentifierRequiredError') });
            } else {
                this.setState({ updatingLinkStatus: true });
                tracker.logEvent('earnPartner', { event: 'update', id: partner.earnPartnerId });
                return this.props.actions.updateEarnPartnerLink(parameters);
            }
        };

        render() {
            const { identifierFieldError, userAccountNumber } = this.state;
            return (
                <WrappedComponent
                    {...this.props}
                    userAccountNumber={userAccountNumber}
                    onChange={this.onChange}
                    linkPartner={this.linkPartner}
                    unlinkPartner={this.unlinkPartner}
                    updateLinkPartner={this.updateLinkPartner}
                    partnerDescriptions={this.partnerDescriptions}
                    listItems={this.listItems}
                    item={this.item}
                    showLinkingStatus={this.showLinkingStatus}
                    identifierFieldError={identifierFieldError}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        const partnerId = _.get(ownProps, 'partnerId')
            || _.get(ownProps, 'match.params.partnerId')
            || _.get(ownProps, 'route.params.partnerId');
        return {
            partnerId,
            partner: getEarnPartner(state, partnerId),
            isLinking: isLinkingEarnPartner(state),
            isLoading: isLoadingEarnPartner(state)
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(partnersActions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(EarnPartnersDetailBase));
}

const CIRCLE_SIZE = spacing.s5;

export const styles = {
    mainContainer: {
        backgroundColor: baseColors.white
    },
    rowContainer: {
        flex: 0,
        paddingTop: spacing.s1,
        paddingBottom: spacing.s1,
        paddingRight: spacing.s2,
        paddingLeft: spacing.s2,
        backgroundColor: baseColors.white
    },
    sectionCard: {
        borderColor: baseColors.grey80,
        borderWidth: 0,
        borderBottomWidth: spacing.s0,
        marginTop: spacing.s0,
        marginBottom: spacing.s0
    },
    headerLabel: {
        ...appFonts.mdMedium
    },
    detailsText: {
        ...appFonts.smRegular
    },
    normalStatusLabel: {
        ...appFonts.lgBold,
        paddingRight: spacing.s2
    },
    buttonContainer: {
        marginBottom: spacing.s2
    },
    seeMoreStyle: {
        ...appFonts.mdBold,
        color: baseColors.secondary,
        marginTop: spacing.s0
    },
    noHeaderImageContainer: {
        height: imageSize.xs,
        marginBottom: spacing.s7
    },
    statusContainer: {
        flexDirection: 'row',
        marginRight: spacing.s4,
        marginBottom: spacing.s3
    },
    circle: {
        width: CIRCLE_SIZE,
        height: CIRCLE_SIZE,
        borderRadius: CIRCLE_SIZE / 2,
        marginRight: spacing.s1,
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0
    },
    linkedCircle: {
        backgroundColor: baseColors.success
    },
    pendingCircle: {
        backgroundColor: baseColors.warn
    },
    errorCircle: {
        backgroundColor: baseColors.danger
    },
    shopButtonIcon: {
        marginLeft: spacing.s1,
        marginRight: spacing.s1
    }
};