import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { StyleSheet, css } from 'aphrodite-jss';
import { withRouter } from 'react-router-dom';

import { spacing } from '../../../../styles';
import { components as Core, ROUTES } from '../../../core';
import WithEarnPartnersBase, { styles as baseStyles } from './EarnPartnersBase';
import EarnPartnersCarouselItem from './EarnPartnersCarouselItem';


class EarnPartners extends PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
        onRef: PropTypes.func,
        i18n: PropTypes.object.isRequired,
        partnersArray: PropTypes.array.isRequired,
        earnPartnersDescription: PropTypes.string,
        earnPartnersProgramName: PropTypes.string,
        isEarnPartnersEnabled: PropTypes.bool
    };

    static defaultProps = {
        onRef: undefined,
        isEarnPartnersEnabled: false,
        earnPartnersProgramName: null,
        earnPartnersDescription: null
    };

    componentDidMount() {
        if (this.props.onRef) this.props.onRef(this);
    }

    componentWillUnmount() {
        if (this.props.onRef) this.props.onRef(null);
    }

    openAllPartners = () => this.props.history.push(ROUTES.earnPartnersList());

    renderItem = partners => <EarnPartnersCarouselItem key={partners.order} partners={partners} />;

    render() {
        const { partnersArray, earnPartnersDescription, earnPartnersProgramName, isEarnPartnersEnabled } = this.props;
        return (
            isEarnPartnersEnabled ?
                <Core.Card className={css(styles.cardContainer)}>
                    <div className={css(styles.titleContainer)}>
                        <div className={css(styles.partnerTitle)}>{earnPartnersProgramName}</div>
                        <div className={css(styles.subHeaderValue)}>{earnPartnersDescription}</div>
                    </div>
                    <Core.Carousel
                        type="single"
                        arrows={true}
                        itemsLength={partnersArray.length}>
                        {_.map(partnersArray, this.renderItem)}
                    </Core.Carousel>
                    <div className={css(styles.footerButtonContainer)}>
                        <Core.Button
                            type="outlined"
                            fullWidth={true}
                            size="medium"
                            onPress={this.openAllPartners}>
                            {this.props.i18n.t('viewAllPartners')}
                        </Core.Button>
                    </div>
                </Core.Card> : null
        );
    }
}
const styles = StyleSheet.create({
    ...baseStyles,
    cardContainer: {
        ...baseStyles.cardContainer,
        paddingTop: spacing.s3,
        paddingBottom: spacing.s3
    },
    titleContainer: {
        marginLeft: spacing.s3
    }
});

export default withRouter(WithEarnPartnersBase(EarnPartners));
