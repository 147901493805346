// this component keeps all the functionality from base and pass down its state fields search and active tab
// which are used as ownProps in mapStateToProps of its child component and getting passed to selector

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate, Storage, Platform, PLATFORMS, ENTITIES_ACTIVE_TAB } from '../../../../core';
import * as earnPartnersSelectors from '../../../selectors';
import * as partnersActions from '../../../actions';
import { EARN_PARTNERS_TABS } from '../../../constants';

export default function WithEarnPartnersListBaseWrapper(WrappedComponent) {
    class EarnPartnersListBase extends Component {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            partners: PropTypes.array,
            isLoading: PropTypes.bool,
            i18n: PropTypes.object.isRequired,
            earnPartnersProgramName: PropTypes.string.isRequired,
            categoriesTabs: PropTypes.object,
            partnersWithLinkStatus: PropTypes.array
        };

        static defaultProps = {
            isLoading: false,
            partners: [],
            categoriesTabs: {},
            partnersWithLinkStatus: []
        };

        constructor(props) {
            super(props);

            this.linkedTab = {
                id: EARN_PARTNERS_TABS.linkedTabId,
                label: this.props.i18n.t('linked'),
                emptyMessage: this.props.i18n.t('noLinkedPartners')
            };

            this.allTab = {
                id: EARN_PARTNERS_TABS.allTabId,
                label: this.props.i18n.t('all'),
                emptyMessage: this.props.i18n.t('noPartnersFound')
            };

            this.tabs = this.defaultTabs;
            this.state = {
                activeTab: this.tabs.ALL,
                search: ''
            };

            this.props.actions.getEarnPartners();
            this.props.actions.getEarnPartnerCategories();
        }

        async componentDidMount() {
            if (Platform.OS === PLATFORMS.web) {
                const activeTab = await Storage.getItem(ENTITIES_ACTIVE_TAB.EARN_PARTNERS);
                if (activeTab) {
                    this.setState({ activeTab });
                }
            }
        }

        onSearch = value => {
            this.setState({ search: value });
        };

        onClearSearch = () => {
            this.setState({ search: '' });
        };

        onTabPress = activeTab => {
            this.setState(() => ({ activeTab }));

            if (Platform.OS === PLATFORMS.web) {
                Storage.setItem(ENTITIES_ACTIVE_TAB.EARN_PARTNERS, activeTab);
            }
        };

        get defaultTabs() {
            if (this.props.partnersWithLinkStatus.length > 0) {
                return ({ LINKED: this.linkedTab, ALL: this.allTab });
            }

            return ({ ALL: this.allTab });
        }

        get scrollableTabs() {
            this.tabs = _.assign(this.defaultTabs, this.props.categoriesTabs);
            return _.concat(_.values(this.defaultTabs), _.values(this.props.categoriesTabs));
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    scrollableTabs={this.scrollableTabs}
                    activeTab={this.state.activeTab}
                    onTabPress={this.onTabPress}
                    onSearch={this.onSearch}
                    onClearSearch={this.onClearSearch}
                    search={this.state.search}
                />
            );
        }
    }

    function mapStateToProps(state) {
        return {
            partners: earnPartnersSelectors.getEarnPartners(state),
            isLoading: earnPartnersSelectors.isLoadingEarnPartners(state),
            earnPartnersProgramName: earnPartnersSelectors.getEarnPartnersProgramName(state),
            categoriesTabs: earnPartnersSelectors.getCategoriesTabs(state),
            partnersWithLinkStatus: earnPartnersSelectors.getPartnersWithLinkStatus(state)
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(partnersActions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(EarnPartnersListBase));
}