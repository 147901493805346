import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

class ResetScroll extends PureComponent {
    static propTypes = {
        location: PropTypes.object.isRequired,
        children: PropTypes.node,
    };

    static defaultProps = {
        children: null
    };

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            window.scrollTo(0, 0);
        }
    }

    render() {
        return this.props.children;
    }
}

export default withRouter(ResetScroll);
