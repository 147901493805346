import _ from 'lodash';
import I18n from '../../../i18n/i18n';

export default function getFilterName(filter, user, isLiveBetter = false) {
    if (!filter) return '';
    const displayName = filter && filter.displayName ? filter.displayName : '';
    const company = _.get(user, 'company') ? user.company.name : '';
    const companyName = !isLiveBetter ? I18n.t('everyone') : company;
    switch (filter.referenceName) {
        case 'all':
            return I18n.t('all');
        case 'company':
            return user.company ? companyName : displayName;
        case 'department':
            return user.department ? user.department : displayName;
        case 'location':
            return user.location ? user.location : displayName;
        case 'region':
            return user.location ? user.region.name : displayName;
        default:
            return filter.displayName;
    }
}