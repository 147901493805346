import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import classnames from 'classnames';
import _ from 'lodash';
import { baseColors, spacing, appFonts, importantStyles, layoutStyle } from '../../../../../styles';
import { ActionSheet, AsyncComponent, components as Core, Modal } from '../../../../core';
import WithJoinButtonBase, { styles as baseStyles, BUTTON_TYPES } from './JoinButtonBase';

const STATE_PROPS = {
    buttonFadeOut: 'buttonFadeOut',
    successFadeIn: 'successFadeIn',
    isFinished: 'isFinished'
};

class JoinButton extends Component {
    static propTypes = {
        stream: PropTypes.object.isRequired,
        className: PropTypes.string,
        getTeamOptions: PropTypes.func.isRequired,
        join: PropTypes.func.isRequired,
        isButtonVisibleOnFirstLoad: PropTypes.bool.isRequired,
        joinedTeamCallback: PropTypes.func,
        isJoinSuccessful: PropTypes.bool,
        isAfterFadeAnimation: PropTypes.bool,
        joinButtonLabel: PropTypes.string,
        joinType: PropTypes.object.isRequired,
        relatedItemDetails: PropTypes.object.isRequired,
        isJoining: PropTypes.bool,
        animationDuration: PropTypes.number.isRequired,
        setTimeout: PropTypes.func.isRequired,
        isButtonVisible: PropTypes.bool,
        showEventStartedText: PropTypes.bool,
        eventStartedText: PropTypes.string
    };

    static defaultProps = {
        className: undefined,
        joinButtonLabel: undefined,
        joinedTeamCallback: null,
        isJoinSuccessful: false,
        isAfterFadeAnimation: false,
        isJoining: false,
        isButtonVisible: null,
        showEventStartedText: false,
        eventStartedText: null
    };

    constructor(props) {
        super(props);
        this.state = {
            [STATE_PROPS.successFadeIn]: false,
            [STATE_PROPS.buttonFadeOut]: true,
            [STATE_PROPS.isFinished]: false
        };
        this.animations = [
            { delay: 0, stateProp: [STATE_PROPS.buttonFadeOut] },
            { delay: props.animationDuration, stateProp: [STATE_PROPS.successFadeIn] },
            { delay: props.animationDuration*2, stateProp: [STATE_PROPS.successFadeIn] },
            { delay: props.animationDuration*3, stateProp: [STATE_PROPS.isFinished] },
        ];
    }

    challengeJoinCallback = () => {
        return ActionSheet.open(this._teamOptions, undefined, undefined);
    };

    join = e => {
        e.stopPropagation();
        this.props.join(this.challengeJoinCallback);
    };

    get relatedItemDetails() {
        return this.props.stream.relatedItemDetails || {};
    }

    get _teamOptions() {
        return this.props.getTeamOptions(this._createTeam, this._joinTeam);
    }

    _createTeam = () => {
        Modal.open(
            AsyncComponent(() => import('../../../../challenges/components/CreateEditTeam')),
            {
                challengeId: this.props.relatedItemDetails.challengeId,
                createdCallback: this.props.joinedTeamCallback,
                isModal: true
            },
            {
                cancelable: false,
                isNoPadding: true,
                isContainer: true,
                isFullHeight: true
            }
        );
    };

    _joinTeam = () => {
        this.closeJoinTeamModal = Modal.open(
            AsyncComponent(() => import('../../../../challenges/components/JoinTeamsList')),
            {
                isPage: false,
                isModal: true,
                close: () => this.closeJoinTeamModal(),
                challengeId: this.props.relatedItemDetails.challengeId,
                joinedTeamCallback: this.props.joinedTeamCallback
            },
            { isContainer: true }
        );
    };

    get successView() {
        return (
            <Core.Animated.Fade
                collapse={this.state.isFinished}
                in={this.state.successFadeIn} timeout={this.props.animationDuration}>
                <div className={css(styles.successSection, layoutStyle.flexRow)}>
                    <Core.Icon
                        className={css(styles.joinButtonIcon)}
                        name="check-circle"
                        type="fa"
                        size={spacing.s4}
                        color={baseColors.secondary} />
                    <p className={css(styles.successText)}>{this.props.joinButtonLabel}</p>
                </div>
            </Core.Animated.Fade>
        );
    }

    animate() {
        _.forEach(this.animations, item =>
            this.props.setTimeout(() => this.setState(prevState => ({ [item.stateProp]: !prevState[item.stateProp] })), item.delay)); }

    render() {
        const { showEventStartedText, eventStartedText, isButtonVisible, isButtonVisibleOnFirstLoad, isAfterFadeAnimation, animationDuration,
            isJoinSuccessful, joinType, isJoining, joinButtonLabel } = this.props;
        if (showEventStartedText) return <p className={css(styles.pastEventText)}>{eventStartedText}</p>;
        if (!isButtonVisible || !isButtonVisibleOnFirstLoad) return null;
        return !isAfterFadeAnimation ?
            (
                <Core.Animated.Fade
                    in={this.state.buttonFadeOut}
                    timeout={animationDuration}
                    collapse={!isJoinSuccessful}>
                    <Core.Button
                        onPress={this.join}
                        type={BUTTON_TYPES.outlined}
                        className={classnames(css(styles.joinButton), this.props.className)}
                        spinnerLabel={joinType.processing}
                        spinnerColor={baseColors.secondary}
                        size={Core.Button.SIZES.medium}
                        fullWidth={true}
                        isLoading={isJoining || isJoinSuccessful}>
                        {isJoinSuccessful ? joinType.processing : joinButtonLabel}
                    </Core.Button>
                </Core.Animated.Fade>
            ) : this.successView;
    }
}

export default WithJoinButtonBase(JoinButton);

const styles = StyleSheet.create(importantStyles({
    ...baseStyles,
    joinButton: {
        ...baseStyles.joinButton,
        ...appFonts.smBold
    },
    joinButtonIcon: {
        marginRight: spacing.s0
    }
}));
