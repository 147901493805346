import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

class InnerHtml extends PureComponent {
    static propTypes = {
        htmlText: PropTypes.string.isRequired,
        className: PropTypes.string
    };

    static defaultProps = {
        className: null
    };

    get createMarkup() {
        return { __html: this.props.htmlText };
    }

    render() {
        return (
            // eslint-disable-next-line react/no-danger
            <div dangerouslySetInnerHTML={this.createMarkup} className={this.props.className} />
        );
    }
}

export default InnerHtml;
