import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { spacing, appFonts, baseColors } from '../../../../styles';
import { translate, selectors as coreSelectors } from '../../../core';

const HEALTH_HISTORY_TITLE = 'Health history';
export const INFO_SCREEN_ICON_SIZE = 120;

export default function WithInfoScreenBase(WrappedComponent) {
    class InfoScreenBase extends PureComponent {
        static propTypes = {
            question: PropTypes.object,
            icon: PropTypes.string,
            title: PropTypes.string,
            bodyText: PropTypes.string,
            isLiveBetter: PropTypes.bool.isRequired,
            i18n: PropTypes.object.isRequired,
            onboardingNewDesign: PropTypes.bool
        };

        static defaultProps = {
            question: null,
            bodyText: null,
            title: null,
            icon: null,
            onboardingNewDesign: false
        };

        get bodyText() {
            const { question, i18n, isLiveBetter, bodyText } = this.props;
            const title = _.get(question, 'title') || this.props.title;
            const body = _.get(question, 'body') || bodyText || null;

            return (title === HEALTH_HISTORY_TITLE && isLiveBetter) ?
                i18n.t('liveBetterHealthInfo') : body;
        }

        get icon() {
            return _.get(this.props.question, 'icon') || this.props.icon;
        }

        get title() {
            return _.get(this.props.question, 'title') || this.props.title;
        }

        get onboardingNextButtonLabel() {
            return this.props.onboardingNewDesign ? this.props.i18n.t('continue') : this.props.i18n.t('next');
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    bodyText={this.bodyText}
                    icon={this.icon}
                    title={this.title}
                    onboardingNextButtonLabel={this.onboardingNextButtonLabel}
                />
            );
        }
    }

    const mapStateToProps = state => ({ isLiveBetter: coreSelectors.isLiveBetter(state) });

    return connect(mapStateToProps)(translate()(InfoScreenBase));
}

const commonHeader = {
    flexDirection: 'column',
    alignItems: 'center'
};

const commonHeaderText = {
    ...appFonts.xxxlBold,
    textAlign: 'center',
    marginTop: spacing.s5,
    color: baseColors.black
};

export const styles = {
    header: {
        ...commonHeader
    },
    headerLB: {
        ...commonHeader,
        marginTop: spacing.s5,
    },
    headerText: {
        ...commonHeaderText,
        marginBottom: spacing.s7
    },
    headerTextLB: {
        ...commonHeaderText,
        marginBottom: spacing.s5
    },
    body: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center'
    },
    description: {
        textAlign: 'center',
        marginBottom: spacing.s7,
        ...appFonts.lgMedium,
        color: baseColors.grey20
    }
};
