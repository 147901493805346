import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import * as settingsSelectors from '../../selectors';
import { spacing, baseColors } from '../../../../styles';
import { translate, selectors as coreSelectors, Storage } from '../../../core';
import { selectors as authSelectors, actions as authActions } from '../../../auth';
import { hasSsoLoginUrl } from '../../../auth/selectors';
import { USER_PROGRAM_NAME, HAS_SSO_URL } from '../../../auth/constants';

export default function WithSettingsBase(WrappedComponent) {
    class SettingsBase extends Component {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            user: PropTypes.object.isRequired,
            isSigningOut: PropTypes.bool,
            isLoading: PropTypes.bool,
            i18n: PropTypes.object.isRequired,
            programName: PropTypes.string.isRequired,
            isSso: PropTypes.bool,
            isLiveBetter: PropTypes.bool
        };

        static defaultProps = {
            isSigningOut: false,
            isLoading: false,
            isSso: false,
            isLiveBetter: false
        };

        constructor(props) {
            super(props);
        }

        logout = () => {
            const { actions, programName, isSso } = this.props;
            Storage.setItem(USER_PROGRAM_NAME, programName);
            isSso && Storage.setItem(HAS_SSO_URL, isSso);
            actions.logout();
        }

        get isShowingProgressIndicator() {
            return this.props.isLoading && this.props.feedbackMetadata.length === 0;
        }

        toggleRememberMe = () => {
            this.props.actions.toggleRememberMe();
        };

        get title() {
            return this.props.i18n.t('settings');
        }

        get isSmartModeOptionShown() {
            return !this.props.isLiveBetter;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    logout={this.logout}
                    isShowingProgressIndicator={this.isShowingProgressIndicator}
                    toggleRememberMe={this.toggleRememberMe}
                    title={this.title}
                    isSmartModeOptionShown={this.isSmartModeOptionShown}
                />
            );
        }
    }

    const mapStateToProps = state => ({
        isSigningOut: authSelectors.isSigningOut(state),
        user: coreSelectors.getCurrentUser(state),
        isLoading: settingsSelectors.isLoadingFeedbackMetadata(state),
        userId: coreSelectors.getCurrentUserId(state),
        rememberMe: authSelectors.getRememberMe(state),
        feedbackMetadata: settingsSelectors.getFeedbackMetadata(state),
        rememberMeEnabled: coreSelectors.rememberMeEnabled(state),
        isFriendsAndFamilyEnabled: coreSelectors.isFriendsAndFamilyEnabled(state),
        isEnhancedPrivacyInformationEnabled: coreSelectors.isEnhancedPrivacyInformationEnabled(state),
        currentUserPrivacySettings: settingsSelectors.getCurrentUserPrivacySettings(state),
        termsOfService: authSelectors.getTermsOfService(state),
        programName: authSelectors.getProgramName(state),
        isSso: hasSsoLoginUrl(state),
        isLiveBetter: coreSelectors.isLiveBetter(state),
        isFamilyInviteEnabled: authSelectors.isFamilyInviteEnabled(state),
        userHasDependentRole: coreSelectors.userHasDependentRole(state)
    });

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators({ ...actions, ...authActions }, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(SettingsBase));
}

export const styles = {
    title: {
        marginBottom: spacing.s3
    },
    button: {
        backgroundColor: baseColors.transparent,
        borderWidth: 0
    },
    buttonTitle: {
        color: baseColors.dangerDarker
    }
};
