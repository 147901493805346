import React, { PureComponent } from 'react';
import _ from 'lodash';
import { css, StyleSheet } from 'aphrodite-jss';
import PropTypes from 'prop-types';

import { components as Core, Modal } from '../../../core';
import WithFriendsAndFamilyBase, { styles as stylesBase, USER_CHECK_ICON, USER_PLUS_ICON } from './FriendsAndFamilyBase';
import { baseColors, spacing, layoutStyle } from '../../../../styles';

class FriendsAndFamily extends PureComponent {
    static propTypes = {
        modalProps: PropTypes.object.isRequired,
        programName: PropTypes.string.isRequired,
        isAdding: PropTypes.bool,
        isDeleting: PropTypes.bool,
        currentUserId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        i18n: PropTypes.object.isRequired,
        removeFromFriendsAndFamily: PropTypes.func.isRequired,
        addToFriendsAndFamily: PropTypes.func.isRequired,
        userData: PropTypes.object
    };

    static defaultProps = {
        isAdding: false,
        isDeleting: false,
        userData: {}
    };

    renderLearnModalText = () => {
        const { i18n, programName } = this.props;
        return (
            <div>
                <p className={css(styles.learnText)}>{i18n.t('friendsAndFamily.learnModal.description', { programName })}</p>
                <p className={css(styles.learnIconsMean)}>{i18n.t('friendsAndFamily.learnModal.whatIconsMean')}</p>
                <div className={css(styles.learnIconsContainer, layoutStyle.flex)}>
                    <div className={css(styles.learnIconItem)}>
                        <Core.Icon name={USER_PLUS_ICON} type="fa" size={spacing.s5} color={baseColors.secondary} />
                        <p className={css(styles.learnIconText)}>{i18n.t('friendsAndFamily.learnModal.canAdd')}</p>
                    </div>
                    <div className={css(styles.learnIconItem)}>
                        <Core.Icon name={USER_CHECK_ICON} type="fa" size={spacing.s5} />
                        <p className={css(styles.learnIconText)}>{i18n.t('friendsAndFamily.learnModal.added')}</p>
                    </div>
                </div>
            </div>
        );
    };

    onLearnMore = () => {
        Modal.open(Core.InfoModal, {
            ...this.props.modalProps,
            renderText: this.renderLearnModalText,
            isButtonVisible: true,
            textStyle: styles.explanation,
        },
        {
            cancelable: false,
            isTransparent: true
        });
    };

    get addToFriendsIcon() {
        return (
            <Core.Icon
                name={USER_PLUS_ICON}
                size={spacing.s4}
                color={baseColors.white}
                className={css(styles.friendIcon)}
            />
        );
    }

    get removeFromFriendsIcon() {
        return (
            <Core.Icon
                name={USER_CHECK_ICON}
                size={spacing.s4}
                color={baseColors.secondary}
                className={css(styles.friendIcon)}
                type="fa"
            />
        );
    }

    render() {
        const { userData, i18n, isAdding, isDeleting, currentUserId, removeFromFriendsAndFamily, addToFriendsAndFamily } = this.props;
        return _.toString(currentUserId) !== _.toString(userData.userId) ? (
            <div className={css(styles.friendsContainer, layoutStyle.flexColumn)}>
                {userData.friendsAndFamilyInviteId ?
                    <Core.Button
                        className={css(styles.friendsButton)}
                        onPress={removeFromFriendsAndFamily}
                        type="outlined"
                        isLoading={isDeleting}
                        isMedium={true}
                        icon={isDeleting ? null : this.removeFromFriendsIcon}>
                        {i18n.t('invitation.addedFriendsAndFamily')}
                    </Core.Button> :
                    <Core.Button
                        className={css(styles.friendsButton)}
                        onPress={addToFriendsAndFamily}
                        type="contained"
                        isLoading={isAdding}
                        isMedium={true}
                        icon={isAdding ? null : this.addToFriendsIcon}>
                        {i18n.t('invitation.addToFriendsAndFamily')}
                    </Core.Button>
                }
                <Core.Button className={css(styles.learnMore)} type="text" onPress={this.onLearnMore}>
                    <p className={css(styles.learnMoreText)}>
                        {i18n.t('friendsAndFamily.learnMore')}
                    </p>
                </Core.Button>
            </div>
        ) : null;
    }
}

const styles = StyleSheet.create({
    ...stylesBase
});

export default WithFriendsAndFamilyBase(FriendsAndFamily);