import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { spacing, windowSize } from '../../../../styles';
import LikeItem from '../LikeItem';
import WithLikesBase, { styles as baseStyles } from './LikesBase';
import { components as Core, onKeyPress } from '../../../core';

const ITEM_HEIGHT = 50;

class Likes extends PureComponent {
    static propTypes = {
        likes: PropTypes.array.isRequired,
        likeCount: PropTypes.number.isRequired,
        close: PropTypes.func.isRequired,
        loadMoreLikes: PropTypes.func.isRequired,
        i18n: PropTypes.object.isRequired
    };

    renderItem = ({ item }) => <LikeItem likeId={+item} />;

    render() {
        return (
            <div className={css(styles.mainContainer)}>
                <div
                    tabIndex="0"
                    role="button"
                    onKeyDown={onKeyPress.esc(this.props.close)}
                    className={css(styles.headerInnerContainer)}
                    onClick={this.props.close}>
                    <div className={css(styles.headerBody)}>
                        <Core.Icon
                            type="fa"
                            name="thumbs-up"
                            size={spacing.s9}
                            className={css(styles.likeIcon)}
                        />
                        <div className={css(styles.headerContentText)}>
                            <span className={css(styles.likesCount)}>
                                {this.props.likeCount}
                            </span>
                            <span className={css(styles.likesText)}>
                                {this.props.i18n.t('likesReceivedLabel')}
                            </span>
                        </div>
                    </div>
                    <div>
                        <Core.Icon
                            type="fa"
                            name="times"
                            size={spacing.s6}
                            className={css(styles.dismissIcon)}
                        />
                    </div>
                </div>
                <div className={css(styles.mainList)}>
                    <Core.InfiniteLazyListFadeIn
                        data={this.props.likes}
                        keyExtractor={item => item}
                        renderItem={this.renderItem}
                        onEndReached={this.props.loadMoreLikes}
                        onEndReachedThreshold={0.5}
                        hasMore={true}
                        isLoading={false}
                        itemHeight={ITEM_HEIGHT}
                        offset={windowSize.height}
                        overflow={true}
                    />
                </div>
            </div>
        );
    }
}

export default WithLikesBase(Likes);

const HEIGHT = 70;

const styles = StyleSheet.create({
    ...baseStyles,
    mainContainer: {
        ...baseStyles.mainContainer,
        display: 'flex',
        height: '100%',
        flexDirection: 'column'
    },
    headerContentText: {
        flex: 1,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
    headerInnerContainer: {
        ...baseStyles.headerInnerContainer,
        display: 'flex',
        alignItems: 'flex-end',
        padding: spacing.s3,
        minHeight: HEIGHT,
    },

    headerBody: {
        ...baseStyles.headerBody,
        display: 'flex',
        alignItems: 'flex-end'
    },
});
