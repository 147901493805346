import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { css, StyleSheet } from 'aphrodite-jss';
import WithLocationProfileEditorBase from './LocationProfileEditorBase';
import { components as Core, ROUTES } from '../../../../core';
import { components as Auth } from '../../../../auth';
import { importantStyles, layoutStyle } from '../../../../../styles';

class LocationProfileEditor extends PureComponent {
    static propTypes = {
        changeLocation: PropTypes.func.isRequired,
        errors: PropTypes.object,
        hasUnsavedChanges: PropTypes.func,
        onRef: PropTypes.func,
        isSaving: PropTypes.bool,
        isLoading: PropTypes.bool,
        subtitle: PropTypes.string.isRequired,
        submit: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired,
        i18n: PropTypes.object.isRequired
    };

    static defaultProps = {
        errors: {},
        hasUnsavedChanges: null,
        onRef: null,
        isSaving: false,
        isLoading: false
    };

    componentDidMount() {
        if (this.props.onRef) this.props.onRef(this);
    }

    componentWillUnmount() {
        if (this.props.onRef) this.props.onRef(null);
    }

    goBack = () => this.props.history.push(_.get(this.props, 'lastLocation.pathname') || ROUTES.profileEditor());

    render() {
        return (
            <div className={css(layoutStyle.flex1)}>
                <Core.SubHeader
                    title={this.props.subtitle}
                    noTopPadding={true}
                    right={<Core.Button size={Core.Button.SIZES.medium} onPress={this.props.submit} className={css(styles.saveButton)}>{this.props.i18n.t('save')}</Core.Button>}
                />
                <Auth.LocationDetails
                    hasUnsavedChanges={this.props.hasUnsavedChanges}
                    changeLocationCallback={this.props.changeLocation}
                    isRegistration={false}
                    errors={this.props.errors}
                />
                <Core.BlockingLoading isLoading={this.props.isLoading || this.props.isSaving} />
            </div>
        );
    }
}

const styles = StyleSheet.create({
    ...importantStyles(
        {
            saveButton: {
                minWidth: 100
            }
        })
});

export default withRouter(WithLocationProfileEditorBase(LocationProfileEditor));
