import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Input from '../Input';
import WithUnitFieldBase from './UnitFieldBase';

class UnitField extends Component {
    static propTypes = {
        unitOptions: PropTypes.array.isRequired,
        unit: PropTypes.string,
        onUnitChange: PropTypes.func,
        isUnitDisabled: PropTypes.bool,
    };

    static defaultProps = {
        unit: undefined,
        onUnitChange: undefined,
        isUnitDisabled: false,
    };

    render() {
        const { onUnitChange, isUnitDisabled, inputHelper } = this.props;
        return (
            <>
                <Input
                    {...this.props}
                    onPressUnits={onUnitChange}
                    unitDisabled={isUnitDisabled}
                />
                {inputHelper ? inputHelper : null}
            </>
        );
    }
}

export default WithUnitFieldBase(UnitField);
