import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { translate } from '../../../../core';
import {
    getScore,
    getScoreDescription,
    getUpdatedDate,
    updatedPercentage,
    getStatus,
    showStatus
} from '../../../selectors';
import { selectWellbeingScoreTab } from '../../../actions';

export default function WithWellbeingScorePopupBase(WrappedComponent) {
    class WellbeingScorePopupBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            updatedDate: PropTypes.string,
            scoreDescription: PropTypes.string,
        };

        static defaultProps = {
            updatedDate: null,
            scoreDescription: null
        };

        get lastUpdatedText() {
            return this.props.i18n.t('wellbeing.lastUpdated', { date: this.props.updatedDate });
        }

        get title() {
            return this.props.i18n.t('health_tab.my_health_score');
        }

        get takeSurveyButton() {
            return this.props.i18n.t('take_survey');
        }

        get updateScoreButton() {
            return this.props.i18n.t('update_wellbeing_score');
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    updatedDate={this.lastUpdatedText}
                    title={this.title}
                    takeSurveyButton={this.takeSurveyButton}
                    updateScoreButton={this.updateScoreButton}
                />
            );
        }
    }
    const mapStateToProps = state => ({
        score: getScore(state),
        scoreDescription: getScoreDescription(state),
        updatedDate: getUpdatedDate(state),
        status: getStatus(state),
        updatedPercentage: updatedPercentage(state),
        showStatus: showStatus(state)
    });

    const mapDispatchToProps = dispatch => ({
        actions: bindActionCreators({ selectWellbeingScoreTab }, dispatch),
    });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(WellbeingScorePopupBase));
}

