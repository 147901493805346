import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';

import WithSmartModeSettingsBase, { styles as baseStyles } from './SmartModeSettingsBase';
import { components as Core, Modal } from '../../../core';

class SmartModeSettings extends PureComponent {
    static propTypes = {
        title: PropTypes.string.isRequired,
        statusText: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        changeSetting: PropTypes.func.isRequired,
        isPage: PropTypes.bool,
        getInfoModalProps: PropTypes.func.isRequired,
        switchStatus: PropTypes.bool.isRequired
    };

    static defaultProps = {
        isPage: false
    };

    closeModal = () => {
        this.close && this.close();
    };

    onSwitchChange = () => {
        const { getInfoModalProps } = this.props;

        this.close = Modal.open(
            Core.InfoModal,
            getInfoModalProps(this.onConfirmSettingChange, this.closeModal),
            {
                PaperProps: { elevation: 0 },
                isNoPadding: true,
                isTransparent: true,
                isContainer: true,
                fadeTransition: true,
            }
        );
    };

    onConfirmSettingChange = () => {
        this.props.changeSetting();
        this.closeModal();
    };

    get wrapper() {
        return this.props.isPage ? Core.SmallerContainer : React.Fragment;
    }

    render() {
        const { title, description, switchStatus, statusText, isPage } = this.props;

        return (
            <this.wrapper widerHeaderComponent={<Core.EntityDetailsHeader hasBackButton={isPage} title={title} />}>
                <Core.SubHeader
                    title={title}
                    noTopPadding={true}
                    noLeftPadding={true}
                />
                <div>
                    <div className={css(styles.switchContainer)}>
                        <p className={css(styles.text)}>{statusText}</p>
                        <Core.Switch
                            onChange={this.onSwitchChange}
                            value={switchStatus}
                        />
                    </div>
                    <p className={css(styles.descriptionText)}>
                        {description}
                    </p>
                </div>
            </this.wrapper>
        );
    }
}

const styles = StyleSheet.create({
    ...baseStyles,
    switchContainer: {
        ...baseStyles.switchContainer,
        display: 'flex',
        borderBottomStyle: 'solid'
    },
});

export default WithSmartModeSettingsBase(SmartModeSettings);
