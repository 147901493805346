import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';

import Button from '../Button';
import { spacing, baseColors, importantStyles, modalStyle } from '../../../../styles';
import WithHelperModalBase, { styles as baseStyles, SHADOW } from './HelperModalBase';

const HelperModal = ({ text, onButtonPress, buttonLabel, size }) => (
    <div className={css(styles.container)}>
        <div className={css(styles.description)}>{text}</div>
        <div className={css(styles.buttonContainer)}>
            <Button
                fullWidth={true}
                type="text"
                size={size}
                disableRipple={true}
                onPress={onButtonPress}
                disableMainContainer={true}
                className={css(styles.buttonText, styles.button, styles.buttonBorder)}>
                {buttonLabel}
            </Button>
        </div>
    </div>
);

HelperModal.propTypes = {
    text: PropTypes.string.isRequired,
    onButtonPress: PropTypes.func.isRequired,
    size: PropTypes.string,
    buttonLabel: PropTypes.string.isRequired,
};

HelperModal.defaultProps = {
    size: 'medium'
};

const styles = StyleSheet.create(importantStyles({
    ...baseStyles,
    container: {
        ...baseStyles.container,
        shadowRadius: spacing.s2,
        shadowOpacity: SHADOW.opacity,
        shadowOffset: { width: 0, height: 0 },
        shadowColor: baseColors.black,
        boxShadow: `0px 30px 60px -30px rgba(0, 0, 0, ${SHADOW.opacity})`,
        width: '100%'
    },
    button: {
        ...baseStyles.button,
        ...modalStyle.bottomButton,
        '&:hover': {
            ...modalStyle.bottomButton,
            backgroundColor: baseColors.grey90,
        },
        '&:after': {
            borderRadius: 0,
        },
    },
    buttonBorder: {
        borderTop: `1px solid ${baseColors.grey80}`,
    },
}));

export default WithHelperModalBase(React.memo(HelperModal));