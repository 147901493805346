import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { appFonts, baseColors, spacing } from '../../../../styles';
import translate from '../../services/translate';

export const Alert = { open: null };

export default function WithAlertBase(WrappedComponent) {
    class AlertBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
        };

        get defaultButtonText() {
            return this.props.i18n.t('ok');
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    defaultButtonText={this.defaultButtonText}
                />
            );
        }
    }

    return translate()(AlertBase);
}

export const styles = {
    dangerColor: {
        color: baseColors.dangerDarker
    },
    title: {
        ...appFonts.lgMedium,
        color: baseColors.black,
        textAlign: 'center',
        padding: spacing.s3,
        paddingTop: spacing.s7,
        marginTop: 0,
        marginBottom: 0,
    },
    contentText: {
        ...appFonts.smRegular,
        color: baseColors.grey20,
        marginBottom: 0,
        marginTop: 0,
    },
    content: {
        paddingTop: 0,
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3,
        paddingBottom: spacing.s7,
    },
    buttonText: {
        ...appFonts.mdRegular,
        padding: spacing.s3,
        textTransform: 'none',
        color: baseColors.secondary,
        textAlign: 'center',
    },
    bolderBtnText: {
        ...appFonts.mdMedium,
    },
};
