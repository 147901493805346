import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { Prompt } from 'react-router-dom';
import { withLastLocation } from 'react-router-last-location';
import { spacing, layoutStyle, importantClass, baseColors, appFonts } from '../../../../styles';
import {
    components as Core,
    ActionSheet,
    fileToBase64,
    Modal,
    AsyncComponent,
    constants as coreConstants
} from '../../../core';
import FeedPostEditorPreview from './FeedPostEditorPreview';
import WithFeedPostEditorBase, { styles as baseStyles } from './FeedPostEditorBase';
import { components as Settings, constants as settingsConstants } from '../../../settings';
import Icon from '../../../core/components/Icon';

const ARROW_OFFSET = spacing.s10;

class FeedPostEditor extends PureComponent {
    static propTypes = {
        filters: PropTypes.array,
        isLoading: PropTypes.bool,
        i18n: PropTypes.object.isRequired,
        lastLocation: PropTypes.object, //eslint-disable-line
        preview: PropTypes.object,
        image: PropTypes.string,
        textChanged: PropTypes.func.isRequired,
        deletePhoto: PropTypes.func.isRequired,
        addPhoto: PropTypes.func.isRequired,
        onFilterChange: PropTypes.func.isRequired,
        onLinkChange: PropTypes.func.isRequired,
        createOrUpdatePost: PropTypes.func.isRequired,
        showVisibilityControl: PropTypes.bool,
        validatedLink: PropTypes.string,
        isEditing: PropTypes.bool,
        isChangesPresent: PropTypes.bool,
        visibleTo: PropTypes.object.isRequired,
        error: PropTypes.bool,
        postText: PropTypes.string,
        close: PropTypes.func.isRequired,
        isPrivacyHiddenTextOrNotEnabled: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        filters: [],
        showVisibilityControl: false,
        isLoading: false,
        preview: null,
        image: '',
        validatedLink: '',
        isEditing: false,
        isChangesPresent: false,
        error: false,
        postText: ''
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    goBack = () => {
        this.setState({ isSaved: true }, this.props.close);
    };

    _deletePhoto = () => {
        this.file.value = '';
        this.props.deletePhoto();
    };

    get options() {
        const { i18n, image } = this.props;
        const opt = [{ title: i18n.t('choosePhoto'), onPress: this._choosePhoto }];
        if (image) {
            opt.push({ title: i18n.t('deletePhoto'), onPress: this._deletePhoto });
        }
        return opt;
    }

    get destructiveButtonIndex() {
        return this.props.image ? this.options.length - 1 : undefined;
    }

    showLinkModal = () => {
        this.closeLinkModal = Modal.open(
            AsyncComponent(() => import('./FeedPostEditorLink')),
            {
                onLinkChange: this.props.onLinkChange,
                link: this.props.validatedLink,
                onClose: () => this.closeLinkModal()
            },
            {
                fadeTransition: true
            }
        );
    };

    textChanged = event => {
        this.props.textChanged(event.target.value);
    };

    fileChanged = async () => {
        if (!this.file.files[0]) return;
        const fullData = await fileToBase64(this.file.files[0]);
        const data = fullData.split('base64,')[1];
        this.props.addPhoto(data, fullData);
    };

    showPicker = () => {
        if (this.options.length > 1) {
            ActionSheet.open(this.options, this.destructiveButtonIndex);
        } else {
            this._choosePhoto();
        }
    };

    _choosePhoto = () => {
        this.file.click();
    };

    get isAskingAboutLiving() {
        return this.props.isChangesPresent && !this.state.isSaved;
    }

    // todo: add position
    getPosition = () => ({
        width: 0,
        height: 0,
        pageX: ARROW_OFFSET,
        pageY: 0
    });

    componentDidMount() {
        document.addEventListener('mousedown', this.handleOutsideClick);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleOutsideClick);
    }

    handleOutsideClick = event => {
        const { isChangesPresent, close } = this.props;
        const { discardModalVisible } = this.state;

        const modalElement = event.target.closest('.MuiDialog-root');
        const isContainPostEditorModal = modalElement.contains(this.componentContainerRef);

        if (isContainPostEditorModal && !this.componentContainerRef.contains(event.target) && !discardModalVisible) {
            isChangesPresent ? this.showDiscardModal() : close();
        }
    };


    showDiscardModal = () => {
        const { isChangesPresent, isEditing, i18n } = this.props;
        this.setState({ discardModalVisible: true });
        if (!isChangesPresent) {
            return this.props.close();
        }

        this.cancel = Modal.open(
            Core.InfoModal,
            {
                isButtonVisible: false,
                title: isEditing ? i18n.t('discardsStreamPost.discardChangesTitle') : i18n.t('discardsStreamPost.discardTitle'),
                text: isEditing ? i18n.t('discardsStreamPost.discardPostChangesText') : i18n.t('discardsStreamPost.discardPostText'),
                buttons: [{ text: i18n.t('discard'),
                    onPress: () => {
                        this.props.closeAll();
                    },
                    isDangerText: true }, { text: i18n.t('button_cancel'),
                    onPress: () => {
                        this.setState({ discardModalVisible: false }, () => this.cancel());
                    } }],

            }, {
                isNoPadding: true,
                cancelable: false
            }
        );

    }

    get selectFieldIcon() {
        return (
            <Icon
                name="caret-down"
                size={spacing.s3}
                type="fa"
                fill="solid"
                color={baseColors.grey40}
            />
        );
    }

    get modalTitle() {
        const { title, i18n } = this.props;
        return (
            <Core.TextSeeMore
                numberOfLines={1}
                isCollapseHidden={true}
                isExpandHidden={true}>
                {title ? `${i18n.t('new_post_in')} ${title}` : i18n.t('new_post')}
            </Core.TextSeeMore>
        );
    }



    render() {
        const { i18n, isEditing, showVisibilityControl, visibleTo, filters, onFilterChange, image, preview,
            validatedLink, createOrUpdatePost, isLoading, error, postText,
            isPrivacyHiddenTextOrNotEnabled } = this.props;
        return (
            <div ref={ref => (this.componentContainerRef = ref)}>
                <Core.SubHeader
                    left={<Core.CloseButton onClick={this.showDiscardModal} />}
                    title={this.modalTitle}
                    titleAlign={Core.SubHeader.ALIGN.center}
                    noTopPadding={true}
                    noLeftPadding={true}
                    titleClassName={css(styles.subHeaderTitle)}
                />
                <div className={css(layoutStyle.flex1)}>
                    {!isEditing && showVisibilityControl ? (
                        <Core.SelectField
                            fadeTransition={true}
                            wrapperClassName={css(styles.wrapper)}
                            selected={visibleTo}
                            options={filters}
                            fieldName={i18n.t('postVisibleTo')}
                            isVisibleOnFilter={false}
                            onChange={onFilterChange}
                            className={css(styles.select)}
                            checkboxType={Core.Checkbox.TYPES.simple}
                            valueTextStyle={css(styles.valueText)}
                            icon={this.selectFieldIcon}
                            iconWrapper={css(styles.iconWrapper)}
                        />
                    ) : null}
                    <Settings.PrivacyTooltip
                        slug={settingsConstants.PRIVACY_SLUGS.NEW_POST_INFO}
                        position={this.getPosition()}
                        tooltipClassName={css(styles.tooltip)}
                    />
                    {isPrivacyHiddenTextOrNotEnabled ? (
                        <div className={css(layoutStyle.flexColumn, layoutStyle.flex1)}>
                            <div className={css(styles.mainTextInput)}>
                                <Core.UserAvatarHeader
                                    hideTitle={true}
                                    bodyClassName={css(layoutStyle.noCursor)}
                                    className={css(styles.avatar)}
                                />
                                <Core.Input
                                    autoFocus={true}
                                    fullWidth={true}
                                    id="postText"
                                    value={postText}
                                    onChange={this.textChanged}
                                    InputProps={{ disableUnderline: true }}
                                    multiline={true}
                                    placeholder={i18n.t('share_smth')}
                                    rows={coreConstants.MULTIPLE_INPUT_ROWS}
                                />
                            </div>
                        </div>
                    ) : null}
                </div>
                {isPrivacyHiddenTextOrNotEnabled ? (
                    <FeedPostEditorPreview
                        image={image}
                        isEditing={isEditing}
                        preview={preview}
                        link={validatedLink}
                        showLinkModal={this.showLinkModal}
                        showPicker={this.showPicker}
                    />
                ) : null}
                <div className={css(styles.errorContainer)}>
                    {error ? (
                        <p className={css(styles.errorText, styles.inputValidationError)}>
                            {i18n.t('shareErrorText')}
                        </p>
                    ) : null}
                </div>
                <div className={css(styles.footer)}>
                    <div>
                        <Core.Button type="text" onPress={this.showLinkModal} withIcon={true}>
                            <Core.Icon
                                name="link"
                                type="fa"
                                size={spacing.s3}
                                className={css(styles.commentIcon)}
                                color="secondary"
                            />
                            <span className={css(styles.footerButtonText, styles.mRight)}>{i18n.t('link')}</span>
                        </Core.Button>
                        <Core.Button type="text" onPress={this.showPicker} withIcon={true}>
                            <Core.Icon
                                name="camera"
                                type="fa"
                                size={spacing.s3}
                                className={css(styles.commentIcon)}
                                color="secondary"
                            />
                            <span className={css(styles.footerButtonText)}>{i18n.t('photo')}</span>
                        </Core.Button>
                        <input
                            ref={ref => (this.file = ref)}
                            type="file"
                            name="filename"
                            accept="image/gif, image/jpeg, image/png"
                            onChange={this.fileChanged}
                            className={css(layoutStyle.hidden)}
                        />
                    </div>
                    <Core.Button
                        size="medium"
                        onPress={createOrUpdatePost}
                        disabled={!(postText.length || image)}
                        className={css(styles.postButton)}
                        halfOpacityButton={true}>
                        {i18n.t('postCommentButton')}
                    </Core.Button>
                </div>
                <Core.BlockingLoading isLoading={isLoading} />
                <Prompt
                    key="block-nav"
                    when={this.isAskingAboutLiving}
                    message={i18n.t('discardPostMessage')}
                />
            </div>
        );
    }
}

export default withLastLocation(WithFeedPostEditorBase(FeedPostEditor));

const OFFSET = 6;
const MIN_TEXT_INPUT_HEIGHT = 250;
const ICON_WRAPPER_OFFSET = 4;

const styles = StyleSheet.create({
    ...baseStyles,
    footer: {
        ...baseStyles.footer,
        alignItems: 'center',
        justifyContent: 'space-between',
        display: 'flex',
        padding: spacing.s2,
        borderTop: `1px solid ${baseColors.grey80}`,
    },
    tooltip: {
        paddingTop: spacing.s3,
        marginTop: spacing.s3,
        position: 'relative'
    },
    wrapper: importantClass({
        marginTop: 0,
        borderStyle: 'solid',
        height: 'auto',
        width: 'fit-content',
        paddingRight: OFFSET,
        borderRadius: spacing.s0
    }),
    mainTextInput: {
        ...baseStyles.mainTextInput,
        flexDirection: 'row',
        width: '100%',
        alignItems: 'flex-start',
        display: 'flex',
        minHeight: MIN_TEXT_INPUT_HEIGHT,
        flex: 0
    },
    avatar: {
        marginRight: -OFFSET,
        marginTop: spacing.s2
    },
    postButton: importantClass({
        paddingRight: spacing.s5,
        paddingLeft: spacing.s5
    }),
    subHeaderTitle: importantClass({
        ...appFonts.mdBold,
        paddingRight: spacing.s9
    }),
    valueText: importantClass({
        paddingRight: spacing.s0,
        paddingLeft: spacing.s1,
        color: baseColors.grey40,
        ...appFonts.smRegular,
        paddingTop: spacing.s0,
        paddingBottom: spacing.s0
    }),
    iconWrapper: importantClass({
        paddingTop: ICON_WRAPPER_OFFSET,
        paddingRight: 0
    }),
    select: {
        ...baseStyles.select,
        paddingTop: spacing.s3,
    },
    mRight: {
        marginRight: spacing.s1
    }
});
