import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { translate, selectors as coreSelectors } from '../../../core';
import { selectors as onboardingSelectors } from '../../../onboarding';
import { spacing } from '../../../../styles';
import { setStartTime } from '../../actions';

export default function WithSkipGoButtonsBase(WrappedComponent) {
    class SkipGoButtonsBase extends PureComponent {
        static propTypes = {
            customButtons: PropTypes.func,
            customBody: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
            title: PropTypes.string,
            bodyText: PropTypes.string,
            icon: PropTypes.string,
            screen: PropTypes.object,
            isLiveBetter: PropTypes.bool.isRequired,
            isOnboarding: PropTypes.bool.isRequired,
            onboardingNewDesign: PropTypes.bool,
            i18n: PropTypes.object.isRequired,
        };

        static defaultProps = {
            title: null,
            screen: {},
            bodyText: null,
            icon: null,
            customButtons: null,
            customBody: null,
            onboardingNewDesign: false
        };

        get skipButtonLabel() {
            const { onboardingNewDesign, i18n } = this.props;
            return onboardingNewDesign ? i18n.t('later'): i18n.t('skip');
        }

        get goButtonLabel() {
            const { onboardingNewDesign, i18n } = this.props;
            return onboardingNewDesign ? i18n.t('continue'): i18n.t('letsGo');
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    skipButtonLabel={this.skipButtonLabel}
                    goButtonLabel={this.goButtonLabel}
                />
            );
        }
    }

    const mapStateToProps = state => ({
        isLiveBetter: coreSelectors.isLiveBetter(state),
        isOnboarding: onboardingSelectors.isShowingOnboarding(state)
    });

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators({ setStartTime }, dispatch)
        };
    }
    return connect(mapStateToProps, mapDispatchToProps)(translate()(SkipGoButtonsBase));
}

export const styles = {
    firstButton: {
        marginRight: spacing.s3
    }
};
