import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { appFonts, baseColors, elements, spacing, imageSize } from '../../../../../styles';
import { constants as coreConstants, translate } from '../../../../core';
import * as rewardsSelectors from '../../../selectors';
import { ACHIEVEMENTS_STATUSES as STATUSES } from '../../../constants';

export default function WithAchievementItemBase(WrappedComponent) {
    class AchievementItemBase extends PureComponent {
        static propTypes = {
            achievementId: PropTypes.number.isRequired,
            achievement: PropTypes.object,
            onPress: PropTypes.func.isRequired,
            i18n: PropTypes.object.isRequired
        };

        static defaultProps = {
            achievement: null
        };

        onPress = () => {
            this.props.onPress(this.props.achievementId);
        };

        get subtitle() {
            const { achievement } = this.props;
            if (achievement.externalRewardPoints > 0) {
                return `${_.capitalize(this.props.i18n.t('reward'))}: ${achievement.externalRewardPoints} ${achievement.externalRewardName}`;
            }
            return '';
        }

        get isBadgeEarned() {
            return this.props.achievement.badgeStatus === STATUSES.earned;
        }

        get percentFilled() {
            return this.props.achievement.percentageCompletion * 100;
        }

        get formatStartDate() {
            return moment(this.props.achievement.achievementStartDate).format(coreConstants.DATE_FORMATS.monthFullDayStYearFull);
        }

        get formatedDateEarned() {
            const dateEarned = this.props.achievement.dateEarned && !_.startsWith(this.props.achievement.dateEarned, '0000') ?
                this.props.achievement.dateEarned : _.get(this.props.achievement, 'datesAwarded[0].end_date');
            return dateEarned ? moment(dateEarned).format(coreConstants.DATE_FORMATS.monthFullDayStYearFull) : '';
        }

        get optionalText() {
            if (this.props.achievement.badgeStatus === STATUSES.earned) {
                const formatedDateEarned = this.formatedDateEarned;
                return formatedDateEarned ? this.props.i18n.t('achievement_earned_date', { date: formatedDateEarned }) : '';
            }
            const formatedStartDate = this.formatStartDate;
            return formatedStartDate ? this.props.i18n.t('achievement_starts_date', { date: formatedStartDate }) : '';
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    subtitle={this.subtitle}
                    percentFilled={this.percentFilled}
                    optionalText={this.optionalText}
                    onPress={this.onPress}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => ({
        achievement: ownProps.achievement || rewardsSelectors.getAchievement(state, ownProps.achievementId)
    });

    return connect(mapStateToProps)(translate()(AchievementItemBase));
}

export const styles = {
    container: {
        borderBottomColor: baseColors.grey80,
        borderBottomWidth: 1
    },
    innerContainer: {
        flexDirection: 'row',
        padding: spacing.s3,
        margin: 0,
        alignItems: 'center'
    },
    image: {
        height: imageSize.xxs,
        width: imageSize.xxxs,
        marginRight: spacing.s3
    },
    title: {
        ...elements.header3,
        marginBottom: spacing.s1
    },
    subtitle: {
        color: baseColors.grey40,
        ...appFonts.smBold,
        marginTop: spacing.s1
    },
    optionalText: {
        ...appFonts.smRegular,
        color: baseColors.grey40
    }
};