import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import * as constants from '../../constants';
import { constants as settingsConstants } from '../../../settings';
import { translate, searchAndHighlightEntities, timeout, tracker } from '../../../core';
import { appFonts, baseColors, spacing } from '../../../../styles';

const RECENT_ACTIVITIES_MAX_COUNT = 3;

export default function withChooseActivityListBase(WrappedComponent) {
    class ChooseActivityListBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            tabs: PropTypes.array.isRequired,
            activities: PropTypes.array.isRequired,
            activityLogs: PropTypes.array.isRequired,
            onSelectActivity: PropTypes.func.isRequired,
        };

        static defaultProps = {

        };

        constructor(props) {
            super(props);
            this.tabs = [
                { id: 'all', label: props.i18n.t('all') },
                ...props.tabs,
            ];
            this.state = {
                searchText: '',
                selectedTab: this.tabs[0],
            };
        }

        onChangeSearchText = searchText => this.setState({ searchText });

        onClearSearchText = () => this.onChangeSearchText('');

        onTabPress = selectedTab => {
            const { i18n } = this.props;
            this.setState({ selectedTab });
            switch (selectedTab.label) {
                case i18n.t(settingsConstants.SLUGS.move):
                    tracker.logEvent('ActivityList_FilterMove_Click');
                    break;
                case i18n.t(settingsConstants.SLUGS.eat):
                    tracker.logEvent('ActivityList_FilterEat_Click');
                    break;
                case i18n.t(settingsConstants.SLUGS.feel):
                    tracker.logEvent('ActivityList_FilterFeel_Click');
                    break;
                default:
                    break;
            }
        };

        onSelectActivity = activity => {
            const { onSelectActivity, i18n } = this.props;
            const { searchText, selectedTab } = this.state;
            onSelectActivity(_.omit(activity, 'activityNameSearch', 'unitNameSearch'));
            tracker.logEvent('ActivityList_Activity_Click', {
                searched: !!searchText,
                filtered: selectedTab.label !== i18n.t('all'),
                recent: !!activity.quantity,
                activity_name: activity.activityName
            });
        };

        get searchBarPlaceholder() {
            return this.props.i18n.t('trackActivity.search');
        }

        get recentTitle() {
            return this.props.i18n.t('Recent');
        }

        get filteredActivities() {
            let activities = this.props.activities;
            if (this.state.selectedTab !== this.tabs[0]) {
                activities = _.filter(this.props.activities, activity => activity.activityCategory === this.state.selectedTab.label);
            }
            const sortedActivities = _.sortBy(activities, ['activityName']);
            return searchAndHighlightEntities(sortedActivities, constants.ACTIVITIES_SEARCH_KEYS, this.state.searchText);
        }

        get activityLogs() {
            return _.take(searchAndHighlightEntities(
                this.props.activityLogs, constants.ACTIVITIES_SEARCH_KEYS, this.state.searchText
            ), RECENT_ACTIVITIES_MAX_COUNT);
        }

        get noActivitiesText() {
            return this.props.i18n.t('noActivities');
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    searchText={this.state.searchText}
                    onChangeSearchText={this.onChangeSearchText}
                    searchBarPlaceholder={this.searchBarPlaceholder}
                    onClearSearchText={this.onClearSearchText}
                    recentTitle={this.recentTitle}
                    selectedTab={this.state.selectedTab}
                    tabs={this.tabs}
                    onTabPress={this.onTabPress}
                    filteredActivities={this.filteredActivities}
                    activityLogs={this.activityLogs}
                    noActivitiesText={this.noActivitiesText}
                    onSelectActivity={this.onSelectActivity}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const routeParams = _.get(ownProps, 'route.params');
        return {
            activityLogs: selectors.getPreparedRecentActivities(state),
            tabs: selectors.getActivityCategoryTabs(state),
            activities: selectors.getVisibleActivityUnits(state),
            ...(routeParams || {})
        };
    };

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(timeout(ChooseActivityListBase)));
}

export const styles = {
    recentTitle: {
        ...appFonts.smBold,
        backgroundColor: baseColors.grey90,
        paddingLeft: spacing.s3,
    },
    separator: {
        height: 1,
        backgroundColor: baseColors.grey80,
    },
    emptyList: {
        ...appFonts.smRegular,
        color: baseColors.grey40,
        marginTop: spacing.s3,
        textAlign: 'center',
    },
    recentWrapper: {
        justifyContent: 'space-between',
        alignItems: 'baseline',
        backgroundColor: baseColors.grey90,
        flexDirection: 'row',
        paddingRight: spacing.s3,
    },
};
