import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { components as Core, ROUTES, CSS_CLASSES, CAROUSEL_TYPES } from '../../../core';
import CarouselItem from '../CarouselItem';
import WithExploreCommunitiesBase from './ExploreCommunitiesBase';

class ExploreCommunities extends PureComponent {
    static propTypes = {
        hasCommunities: PropTypes.bool,
        communities: PropTypes.array,
        communitiesTitle: PropTypes.string.isRequired,
        history: PropTypes.object.isRequired,
        joinCommunity: PropTypes.func.isRequired,
        exploreLoadingStatus: PropTypes.object
    };

    static defaultProps = {
        communities: [],
        hasCommunities: false,
        exploreLoadingStatus: false
    };

    state = {};

    viewAllAction = () => this.props.history.push(ROUTES.communities());

    onPressSingle = item => {
        if (this.state.isSliding) return;
        this.props.history.push(ROUTES.communityDetails(item.id));
    };

    renderItem = item => <CarouselItem id={item.id} key={item.id} join={this.props.joinCommunity} onPressSingle={this.onPressSingle} />;

    render() {
        const { communitiesTitle, hasCommunities, communities, exploreLoadingStatus: { isLoadingAllExplore, initialLoadDataComplete } } = this.props;
        return (
            <React.Fragment>
                <div className={CSS_CLASSES.explore}>
                    {isLoadingAllExplore || hasCommunities ? (
                        <Core.Carousel
                            isLoading={isLoadingAllExplore || !initialLoadDataComplete}
                            titleSize={Core.SubHeader.SIZES.large}
                            parent={this}
                            type={CAROUSEL_TYPES.multiple}
                            title={communitiesTitle}
                            viewAllPress={this.viewAllAction}
                            itemsLength={communities.length}
                            slidesToScrollValue={4}>
                            {_.map(communities, this.renderItem)}
                        </Core.Carousel>
                    ) : null
                    }
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(WithExploreCommunitiesBase(ExploreCommunities));