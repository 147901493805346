import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import _ from 'lodash';
import { baseColors, layoutStyle, spacing, importantStyles } from '../../../../styles';
import Icon from '../Icon';
import Image from '../Image';
import Button from '../Button';
import WithImageUploaderBase, { styles as baseStyles } from './ImageUploaderBase';
import ImageUploadGeneral from '../ImageUploadGeneral';

class ImageUploader extends PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        hasDeleteOption: PropTypes.bool,
        fileChanged: PropTypes.func.isRequired,
        picture: PropTypes.string,
        buttonLabel: PropTypes.string.isRequired,
        suggestedImages: PropTypes.array,
        hasSuggested: PropTypes.bool.isRequired,
        text: PropTypes.string.isRequired,
        isDeletedPictureEmpty: PropTypes.bool,
        isHiddenImagePickerButton: PropTypes.bool,
    };

    static defaultProps = {
        hasDeleteOption: true,
        picture: undefined,
        suggestedImages: undefined,
        isDeletedPictureEmpty: false,
        isHiddenImagePickerButton: false
    };

    showPicker = () => this.imageUpload.showPicker();

    saveRef = ref => (this.imageUpload = ref);

    renderSuggestedImage = image => (
        <div className={css(styles.square)}>
            <Image
                isFadeIn={false}
                className={css(styles.suggestedImage)}
                src={image}
                alt={this.props.i18n.t('cover')}
            />
        </div>
    );

    fileChanged = (picture, pictureFull) => {
        this.props.fileChanged(picture, pictureFull);
        if (!picture && !pictureFull) this.imageUpload.clearImagePicker();
    };

    render() {
        return (
            <ImageUploadGeneral
                hasDeleteOption={this.props.hasDeleteOption}
                picture={this.props.picture}
                fileChanged={this.fileChanged}
                isDeletedPictureEmpty={this.props.isDeletedPictureEmpty}
                onRef={this.saveRef}>
                <div className={css(styles.imageWrapper)}>
                    {this.props.picture ? (
                        <Image
                            isFadeIn={true}
                            src={this.props.picture}
                            className={css(styles.image)}
                        />
                    )
                        :
                        null}
                    <div className={css(styles.imageCover)}>
                        <div className={css(this.props.picture ? layoutStyle.opacityNone : layoutStyle.textCenter)}>
                            <Icon
                                type="fa"
                                name="camera"
                                size={spacing.s8}
                                color={baseColors.black}
                            />
                            <p className={css(styles.text)}>{this.props.text}</p>
                        </div>
                        {!this.props.isHiddenImagePickerButton && (
                            <Button
                                size="medium"
                                onPress={this.showPicker}>
                                {this.props.buttonLabel}
                            </Button>
                        )}
                    </div>
                </div>
                {this.props.hasSuggested ? (
                    <div>
                        <p className={css(styles.suggestedTitle)}>Suggested Photos</p>
                        <div className={css(layoutStyle.flexRow, layoutStyle.flexWrap)}>
                            {_.map(this.props.suggestedImages, this.renderSuggestedImage)}
                        </div>
                    </div>
                ) : null}
            </ImageUploadGeneral>
        );
    }
}

const styles = StyleSheet.create({
    ...baseStyles,
    ...importantStyles({
        suggestedImage: {
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0
        },
    }),
    square: {
        width: '33.33%',
        paddingBottom: '33.33%',
        position: 'relative'
    },
});

export default WithImageUploaderBase(ImageUploader);
