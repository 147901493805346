import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { getFeed } from '../../../selectors';
import { parsers, translate } from '../../../../core';
import { appFonts, spacing, baseColors } from '../../../../../styles/commonCSS';
import { STREAM_ITEM_TYPE_SLUGS } from '../../../constants';

const POST_PADDING_LEFT = spacing.s2;
export const NUMBER_OF_LINES = 7;

export default function WithFeedItemContentBase(WrappedComponent) {
    class FeedItemContentBase extends PureComponent {
        static propTypes = {
            streamItemId: PropTypes.number.isRequired, // eslint-disable-line
            stream: PropTypes.object.isRequired,
            i18n: PropTypes.object.isRequired
        };

        get image() {
            const { stream } = this.props;
            return (_.get(stream, 'streamItemDisplay.streamItemMedia.type') === 'image') || this.isVideo
                ? _.get(stream, 'streamItemDisplay.streamItemMedia.src')
                : undefined;
        }

        get isVideo() {
            return _.get(this.props.stream, 'streamItemDisplay.streamItemMedia.type') === 'iframe';
        }

        get embeddedMedia() {
            return _.get(this.props.stream, 'streamItemDisplay.streamItemMedia.embedded_media');
        }

        determineContent = () => {
            const { stream } = this.props;
            const content = {};
            switch (stream.streamItemTypeSlug) {
                case STREAM_ITEM_TYPE_SLUGS.status_update:
                    content.userText = this.prepareHtmlText(
                        _.get(stream, 'streamItemDisplay.userText.values.text') ||
                        _.get(stream, 'streamItemDisplay.streamItemMedia.link'));
                    content.description = _.get(stream, 'streamItemDisplay.streamItemMedia.description');
                    content.title = _.get(stream, 'streamItemDisplay.streamItemMedia.title');
                    content.link = _.get(stream, 'streamItemDisplay.streamItemMedia.link');
                    break;
                case STREAM_ITEM_TYPE_SLUGS.add_activities:
                case STREAM_ITEM_TYPE_SLUGS.add_notification:
                    content.userText = this.prepareHtmlText(_.get(stream, 'streamItemDisplay.userText.values.text'));
                    break;
                default:
            }
            return content;
        };

        breakLinesInHtmlText = userText => userText.replace(/<br\s*[/]?>/gi, '\n');

        prepareHtmlText = userText => userText && parsers.removeAllHtmlTags(this.breakLinesInHtmlText(userText));

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    isVideo={this.isVideo}
                    embeddedMedia={this.embeddedMedia}
                    content={this.determineContent()}
                    image={this.image}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        return {
            stream: getFeed(state, ownProps.streamItemId)
        };
    }

    return connect(mapStateToProps)(translate()(FeedItemContentBase));
}

export const styles = {
    cardText: {
        ...appFonts.mdRegular,
    },
    cardContent: {
        paddingTop: '0'
    },
    contentDetails: {
        backgroundColor: baseColors.grey85,
        marginLeft: -POST_PADDING_LEFT,
        marginRight: -POST_PADDING_LEFT
    },
    contentDescription: {
        marginLeft: POST_PADDING_LEFT,
        marginRight: POST_PADDING_LEFT,
        paddingTop: spacing.s2,
        ...appFonts.lgRegular
    },
    contentLink: {
        ...appFonts.smRegular,
        marginLeft: POST_PADDING_LEFT,
        marginRight: POST_PADDING_LEFT,
        paddingBottom: spacing.s2
    }
};
