import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import { connect } from 'react-redux';
import { baseColors, spacing, appFonts, layoutStyle } from '../../../../styles';
import { isTeamChallenge, isUnstarted, isAfterDeadline, isGoalType } from '../../services/helper';
import { translate, DATE_FORMATS } from '../../../core';
import { getChallenge, isRestartGoal } from '../../selectors';

export default function WithAboutChallengeBase(WrappedComponent) {
    class AboutChallengeBase extends PureComponent {
        static propTypes = {
            challenge: PropTypes.object.isRequired,
            isRecommended: PropTypes.bool,
            i18n: PropTypes.object.isRequired,
            isRestartGoal: PropTypes.bool,
            isChallengeDetailsPage: PropTypes.bool,
            isCard: PropTypes.bool,
        };

        static defaultProps = {
            isRecommended: false,
            isRestartGoal: false,
            isChallengeDetailsPage: false,
            isCard: false,
        };

        get isUnstarted() {
            return isUnstarted(this.props.challenge);
        }

        get isEnded() {
            return isAfterDeadline(this.props.challenge);
        }

        get isTeamChallenge() {
            return isTeamChallenge(this.props.challenge);
        }

        get isGoal() {
            return isGoalType(this.props.challenge);
        }

        get dateFormat() {
            return DATE_FORMATS.monthDay;
        }

        get startDate() {
            return moment(this.props.challenge.startDate).format(this.dateFormat);
        }

        get endDate() {
            return moment(this.props.challenge.challengeDeadline).format(this.dateFormat);
        }

        get frequency() {
            const frequency = _.get(this.props, 'challenge.frequency');
            return frequency ? this.props.i18n.t(_.toLower(frequency)) : this.props.i18n.t('total');
        }

        get teamSizeText() {
            const { challenge, i18n } = this.props;
            return i18n.t('aboutTeamSize.text', { min: challenge.minTeamMembers, max: challenge.maxTeamMembers });
        }

        get durationUnitsText() {
            const { challenge, i18n } = this.props;
            const units = _.get(challenge, 'durationUnits');
            return units ? i18n.t(challenge.durationUnits) : '';
        }

        get titleText() {
            return this.props.i18n.t(this.showRestartGoalText ? 'restartGoal.period.title' : this.startsOrStarted);
        }

        get startsOrStarted() {
            return this.isUnstarted ? 'starts' : 'started';
        }

        get endsOrEndedText() {
            return this.props.i18n.t(this.isEnded ? 'ended' : 'ends');
        }

        get valueText() {
            return this.showRestartGoalText ? this.props.i18n.t('restartGoal.period.text') : this.startDate;
        }

        get showRestartGoalText() {
            return this.props.isRestartGoal && this.props.isChallengeDetailsPage;
        }

        get teamTitleText() {
            return this.props.i18n.t('aboutTeamSize.title');
        }

        get durationText() {
            return this.props.i18n.t('duration');
        }

        get doActivitiesText() {
            return this.props.i18n.t('do_activities');
        }

        get cardStyle() {
            return this.props.isCard ? layoutStyle.flexColumnCenter : layoutStyle.flexColumn;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    isUnstarted={this.isUnstarted}
                    isEnded={this.isEnded}
                    isTeamChallenge={this.isTeamChallenge}
                    isGoal={this.isGoal}
                    endDate={this.endDate}
                    frequency={this.frequency}
                    teamSizeText={this.teamSizeText}
                    durationUnitsText={this.durationUnitsText}
                    titleText={this.titleText}
                    valueText={this.valueText}
                    endsOrEndedText={this.endsOrEndedText}
                    teamTitleText={this.teamTitleText}
                    durationText={this.durationText}
                    doActivitiesText={this.doActivitiesText}
                    cardStyle={this.cardStyle}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => ({
        challenge: getChallenge(state, ownProps.challengeId),
        isRestartGoal: isRestartGoal(state, ownProps.challengeId),
    });

    return connect(mapStateToProps)(translate()(AboutChallengeBase));
}

export const styles = {
    cardBodyStatus: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        display: 'flex'
    },
    flexRowLeft: {
        alignItems: 'flex-start',
        justifyContent: 'center',
        paddingRight: spacing.s0,
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
    flexRowValueText: {
        ...appFonts.mdBold,
        color: baseColors.grey20,
        marginTop: spacing.s0,
    },
    flexRowTitleText: {
        ...appFonts.smMedium,
        color: baseColors.grey40
    }
};
