import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';

import { baseColors, importantStyles, layoutStyle } from '../../../../../styles';
import { components as Core, onKeyPress } from '../../../../core';
import { ACHIEVEMENTS_STATUSES as STATUSES } from '../../../constants';
import WithAchievementItemBase, { styles as baseStyles } from './AchievementItemBase';

class AchievementItem extends PureComponent {
    static propTypes = {
        achievement: PropTypes.object.isRequired,
        onPress: PropTypes.func.isRequired,
        subtitle: PropTypes.string.isRequired,
        percentFilled: PropTypes.number.isRequired,
        optionalText: PropTypes.string.isRequired
    };

    render() {
        const { achievement, onPress, optionalText, percentFilled, subtitle } = this.props;
        const classes = {
            barColorPrimary: css(styles.barColorPrimary),
            colorPrimary: css(styles.colorPrimary)
        };
        return (
            <div className={css(styles.container)}>
                <div
                    className={css(styles.innerContainer)}
                    role="button"
                    tabIndex={0}
                    onClick={onPress}
                    onKeyDown={onKeyPress.enter(onPress)}>
                    <Core.Image
                        src={achievement.iconUrl}
                        className={css(styles.image)}
                        alt="achievementImage"
                        resizeMode="contain"
                    />
                    <div className={css(layoutStyle.flex1)}>
                        <span className={css(styles.title)}>{achievement.name}</span>
                        {achievement.badgeStatus === STATUSES.earned || achievement.badgeStatus === STATUSES.upcoming ?
                            <div className={css(styles.optionalText)}>{optionalText}</div> :
                            <Core.ProgressBar
                                variant="determinate"
                                filledPercentage={percentFilled}
                                classes={classes}
                            />
                        }
                        <span className={css(styles.subtitle)}>{subtitle}</span>
                    </div>
                </div>
            </div>
        );
    }
}

const styles = StyleSheet.create(importantStyles({
    ...baseStyles,
    image: {
        ...baseStyles.image,
        objectFit: 'contain'
    },
    container: {
        borderWidth: 0,
        ...baseStyles.container,
        borderStyle: 'solid'
    },
    innerContainer: {
        ...baseStyles.innerContainer,
        display: 'flex',
        cursor: 'pointer'
    },
    barColorPrimary: {
        backgroundColor: baseColors.primary
    },
    colorPrimary: {
        backgroundColor: baseColors.grey85
    }
}));

export default WithAchievementItemBase(AchievementItem);