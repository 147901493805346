import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { components as CL, constants as CLConstants } from '../../../../commentslikes';
import { ROUTES } from '../../../../core';
import withFeedItemCommentsBase from './FeedItemCommentsBase';

class FeedItemComments extends PureComponent {
    static propTypes = {
        streamItemId: PropTypes.number.isRequired,
        stream: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        onRefComments: PropTypes.func,
        visibleCommentsCount: PropTypes.number,
        isDetails: PropTypes.bool
    };

    static defaultProps = {
        onRefComments: undefined,
        visibleCommentsCount: 0,
        isDetails: false
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    goToDetails = ({ streamItemId }, isSeeMore, autoFocus) => {
        this.props.history.push(ROUTES.feedDetails(streamItemId), { isSeeMore, autoFocus });
    };

    showComments = () => {
        this.goToDetails(this.props.stream);
    };

    makeComment = () => {
        this.goToDetails(this.props.stream, undefined, true);
    };

    seeMoreComments = () => {
        this.goToDetails(this.props.stream, true);
    };

    get seeMoreHandler() {
        return this.props.isDetails ? undefined : this.seeMoreComments;
    }

    render() {
        return (
            <React.Fragment>
                {this.props.disableLikesComments ? null : (
                    <>
                        <CL.LikesCommentsPanel
                            likeCount={this.props.stream.likeCount}
                            commentCount={this.props.stream.commentCount}
                            isLikeActive={!!this.props.stream.viewer.isLikedByViewer}
                            onCommentsClick={this.props.isDetails ? undefined : this.showComments}
                            doComment={this.props.isDetails ? undefined : this.makeComment}
                            likedItemId={this.props.streamItemId}
                            likedItemType={CLConstants.LIKES_ENTITY_TYPES.stream}
                            toUserId={this.props.stream.owner.userId}
                            likeId={this.props.stream.viewer.likeId}
                        />
                        <CL.Comments
                            commentedItemType={CLConstants.LIKES_ENTITY_TYPES.stream}
                            onRef={this.props.onRefComments}
                            commentedItemId={this.props.stream.streamItemId}
                            visibleCount={this.props.visibleCommentsCount}
                            count={this.props.stream.commentCount}
                            seeMore={this.seeMoreHandler}
                            mode={this.props.isDetails ? CLConstants.COMMENT_MODES.full : undefined}
                        />
                    </>
                )}
            </React.Fragment>
        );
    }
}

export default withRouter(withFeedItemCommentsBase(FeedItemComments));
