import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { baseColors, spacing, appFonts } from '../../../../styles';
import { translate } from '../../../core';
import * as selectors from '../../selectors';
import { CATEGORIES, TYPES } from '../../constants';

export default function WithContentOnFeedsBase(WrappedComponent) {
    class ContentOnFeedsBase extends PureComponent {
        static propTypes = {
            items: PropTypes.array,
            count: PropTypes.number,
            i18n: PropTypes.object.isRequired,
            type: PropTypes.string.isRequired,
            isLoading: PropTypes.bool
        };

        static defaultProps = {
            items: [],
            count: 0,
            isLoading: false
        };

        get title() {
            const { i18n, type } = this.props;
            return i18n.t(type === TYPES.RECOMMENDED ? 'recommendedForYou' : `${type}ForMe`);
        }

        get viewAllText() {
            return this.props.i18n.t('seeAll');
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    title={this.title}
                    viewAllText={this.viewAllText}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        if (ownProps.type === TYPES.RECOMMENDED) {
            const recommendedItems = _.slice(selectors.getRecommendedContent(state)[CATEGORIES.ALL], 0, 3);
            return {
                items: recommendedItems,
                count: recommendedItems.length
            };
        } else if (ownProps.type === TYPES.PROMOTED) {
            return {
                items: selectors.getPromotedContent(state)[CATEGORIES.ALL],
                count: selectors.getPromotedContentCount(state)[CATEGORIES.ALL]
            };
        }
        return {
            items: [],
            count: 0,
            isLoading: selectors.isLoadingContent(state)
        };
    }

    return connect(mapStateToProps)(translate()(ContentOnFeedsBase));
}

export const styles = {
    container: {
        backgroundColor: baseColors.white,
        marginBottom: spacing.s2,
        paddingBottom: spacing.s3
    },
    carouselTitle: {
        ...appFonts.mdBold
    },
    buttonContainer: {
        marginLeft: spacing.s3,
        marginRight: spacing.s3,
    }
};