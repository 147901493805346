export const NAME = 'content';
export const NOT_PERSISTED = false;

export const CATEGORIES = {
    ALL: 'all',
    VIDEO: 'video',
    SEARCH: 'search',
    BOOKMARKS: 'bookmarks',
    RECOMMENDED: 'recommended',
    MORE: 'more'
};

export const TYPES = {
    ALL: 'all',
    VIDEO: 'video',
    PROMOTED: 'promoted',
    RECOMMENDED: 'recommended',
    EARNPARTNERS: 'earnPartners',
    MORE: 'more'
};

export const CATEGORY_TYPES_API_PARAM = {
    CATEGORY: 'category_',
    TAG: 'tag_',
    TYPE: 'type_'
} ;

export const CHECK_ICON_SIZE = 20;
export const AUTOPLAY_INTERVAL = 7000;
export const PLAY_BUTTON_HEIGHT = 40;
export const PLAY_BUTTON_WIDTH = 65;
export const VIDEO_DURATION_CONTAINER_HORIZONTAL_PADDING = 6;
