import _ from 'lodash';
import { persistReducer } from 'redux-persist';
import * as types from './actionTypes';
import { NAME, DISPLAY_TYPES } from './constants';
import { actionTypes as coreTypes, tracker, Storage } from '../core';
import { addIfNotPersonalFirst, addOneToCount } from './services/helper';

export const initialState = {
    items: {},
    [DISPLAY_TYPES.UNCOMPLETED_CAROUSEL]: {
        ids: [],
        count: undefined
    },
    [DISPLAY_TYPES.COMPLETED_CAROUSEL]: {
        ids: [],
        count: undefined
    },
    [DISPLAY_TYPES.INVITATIONS]: {
        ids: [],
        count: undefined
    },
    [DISPLAY_TYPES.ATTENDED]: {
        ids: [],
        count: undefined
    },
    [DISPLAY_TYPES.RECOMMENDED]: {
        ids: [],
        count: undefined
    },
    [DISPLAY_TYPES.RECENT]: {
        ids: [],
        count: undefined
    },
    [DISPLAY_TYPES.ALL]: {
        ids: [],
        count: undefined
    },
    [DISPLAY_TYPES.COMPANY]: {
        ids: [],
        count: undefined
    },
    [DISPLAY_TYPES.COMPLETED]: {
        ids: [],
        count: undefined
    },
    [DISPLAY_TYPES.BONUS]: {
        ids: [],
        count: undefined
    },
    [DISPLAY_TYPES.USER]: {
        ids: [],
        count: undefined
    },
    invitedChallenges: {
        challenges: [],
        count: undefined
    },
    activities: {
        logs: {
            idsExtended: [],
            hasMore: true,
        }
    },
    hasTrackedChallenge: false,
    leaderboards: {},
    teams: {},
    memberships: {},
    members: {},
    allLeaderBoards: [],
    isShownFirstActivityTrackModal: false,
    isHiddenActivityInformationText: false,
    newChallenge: {},
    challengeIdsByTeamIds: {},
    carouselInstructionalSlideDismissed: false,
    createChallengeSteps: [],
    createChallengeStep: undefined,
    recommendedGoals: [],
    starterGoals: [],
    searchingResultTeams: [],
    featuredChallenges: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_CHALLENGES.SUCCESS:
        case types.GET_CHALLENGES_LIST.SUCCESS:
        case types.SET_GOAL.SUCCESS: {
            const { items, ids: receivedIds, count, type, isReplace } = action.payload;
            const ids = _.uniq((isReplace && type !== DISPLAY_TYPES.UNCOMPLETED_CAROUSEL) || type === DISPLAY_TYPES.INVITATIONS
                ? receivedIds
                : [..._.get(state, [type, 'ids'], []), ...receivedIds]);
            return {
                ...state,
                [type]: {
                    ids,
                    count: _.toInteger(!_.isUndefined(count) ? count : _.get(state, [type, 'count'], 0))
                },
                items: { ...state.items, ...items }
            };
        }
        case types.GET_INVITES.SUCCESS: {
            const { items, count } = action.payload;
            return {
                ...state,
                invitedChallenges: {
                    challenges: [...items],
                    count: _.toInteger(!_.isUndefined(count) ? count : state.invitedChallenges.count, 0),
                }
            };
        }
        case types.GET_CHALLENGE.SUCCESS:
        case types.DISMISS_FEATURED_CHALLENGE.SUCCESS:
        case types.DISMISS_SINGLE_CHALLENGE.SUCCESS: {
            const { challenge } = action.payload;
            return {
                ...state,
                items: {
                    ...state.items,
                    [challenge.challengeId]: { ...state.items[challenge.challengeId], ...challenge }
                }
            };
        }
        case types.GET_CHALLENGE_TEAM.SUCCESS: {
            return {
                ...state,
                teams: {
                    ...state.teams,
                    [action.payload.team.teamId]: action.payload.team
                }
            };
        }
        case types.GET_CHALLENGE_LEADERBOARD.SUCCESS: {
            return {
                ...state,
                leaderboards: {
                    ...state.leaderboards,
                    [action.payload.leaderboard.challengeId]: action.payload.leaderboard
                }
            };
        }
        case types.GET_CHALLENGE_MEMBERSHIP.SUCCESS: {
            return {
                ...state,
                memberships: {
                    ...state.memberships,
                    [action.payload.challengeId]: action.payload.membership
                }
            };
        }
        case types.GET_CHALLENGE_MEMBERS.SUCCESS: {
            const { challengeId, data, isReplace, isTeamChallenge } = action.payload;
            const id = isTeamChallenge ? 'entityId' : 'id';
            return {
                ...state,
                members: {
                    ...state.members,
                    [challengeId]: isReplace ? data : _.uniqBy([
                        ...state.members[challengeId],
                        ...data
                    ], id)
                }
            };
        }
        case types.UPDATE_NEW_CHALLENGE: {
            const newChallenge = action.payload ? { ...state.newChallenge, ...action.payload } : {};
            return { ...state, newChallenge };
        }
        case types.UPDATE_CREATE_CHALLENGE_STEP: {
            const { step, isReset, isClear } = action.payload;
            if (isClear) return { ...state };
            let createChallengeSteps;
            if (isReset) {
                createChallengeSteps = [step];
            } else {
                const index = _.findIndex(state.createChallengeSteps, s => s === step);
                createChallengeSteps = index === -1 ? [...state.createChallengeSteps, step] :
                    _.take(state.createChallengeSteps, index + 1);
            }
            return { ...state, createChallengeStep: step, createChallengeSteps };
        }
        case types.CREATE_CHALLENGE.SUCCESS: {
            const { challenge } = action.payload;
            const isTeamChallengeMember = challenge.challengeEntityType === 'group' ? challenge.isMember : true;
            const recentState = state[DISPLAY_TYPES.RECENT];
            const uncompletedState = state[DISPLAY_TYPES.UNCOMPLETED_CAROUSEL];
            const attendedState = state[DISPLAY_TYPES.ATTENDED];

            return {
                ...state,
                [DISPLAY_TYPES.RECENT]: {
                    ids: addIfNotPersonalFirst(challenge, recentState.ids),
                    count: addOneToCount(recentState.count)
                },
                [DISPLAY_TYPES.ATTENDED]: isTeamChallengeMember && {
                    ids: _.uniq([...(state[DISPLAY_TYPES.ATTENDED].ids || []), challenge.challengeId]),
                    count: addOneToCount(attendedState.count)
                },
                [DISPLAY_TYPES.UNCOMPLETED_CAROUSEL]: isTeamChallengeMember && {
                    ids: _.uniq([...(uncompletedState.ids || []), challenge.challengeId]),
                    count: addOneToCount(uncompletedState.count)
                },
                items: {
                    ...state.items,
                    [challenge.challengeId]: challenge
                },
                lastCreatedId: challenge.challengeId
            };
        }
        case types.JOIN_CHALLENGE.SUCCESS: {
            const { challengeId, teamId, isTeam } = action.payload;
            const attendedState = state[DISPLAY_TYPES.ATTENDED];
            const uncompletedState = state[DISPLAY_TYPES.UNCOMPLETED_CAROUSEL];

            return {
                ...state,
                [DISPLAY_TYPES.ATTENDED]: {
                    ids: _.uniq([...(state[DISPLAY_TYPES.ATTENDED].ids || []), challengeId]),
                    count: addOneToCount(attendedState.count)
                },
                [DISPLAY_TYPES.UNCOMPLETED_CAROUSEL]: {
                    ids: _.uniq([...(uncompletedState.ids || []), challengeId]),
                    count: addOneToCount(uncompletedState.count)
                },
                challengeIdsByTeamIds: action.type === types.JOIN_CHALLENGE_BY_TEAM_ID.SUCCESS
                    ? { ...state.challengeIdsByTeamIds, [teamId]: challengeId } : state.challengeIdsByTeamIds,
                items: isTeam ? { ...state.items } : {
                    ...state.items,
                    [challengeId]: {
                        ...state.items[challengeId], isMember: 1
                    }
                }
            };
        }
        case types.JOIN_CHALLENGE_BY_TEAM_ID.SUCCESS: {
            const { challengeId, teamId } = action.payload;
            const attendedState = state[DISPLAY_TYPES.ATTENDED];
            const uncompletedState = state[DISPLAY_TYPES.UNCOMPLETED_CAROUSEL];

            return {
                ...state,
                [DISPLAY_TYPES.ATTENDED]: {
                    ids: _.uniq([...(state[DISPLAY_TYPES.ATTENDED].ids || []), challengeId]),
                    count: addOneToCount(attendedState.count)
                },
                [DISPLAY_TYPES.UNCOMPLETED_CAROUSEL]: {
                    ids: _.uniq([...(uncompletedState.ids || []), challengeId]),
                    count: addOneToCount(uncompletedState.count)
                },
                challengeIdsByTeamIds: action.type === types.JOIN_CHALLENGE_BY_TEAM_ID.SUCCESS
                    ? { ...state.challengeIdsByTeamIds, [teamId]: challengeId } : state.challengeIdsByTeamIds
            };
        }
        case types.UPDATE_CHALLENGE.SUCCESS: {
            const { challenge } = action.payload;
            return {
                ...state,
                items: {
                    ...state.items,
                    [challenge.challengeId]: challenge
                },
            };
        }
        case types.DELETE_CHALLENGE.SUCCESS: {
            const { challengeId } = action.payload;
            const newState = _.mapValues(_.pick(state, _.values(DISPLAY_TYPES)), type => ({
                ids: _.includes(type.ids, challengeId) ? _.filter(type.ids, item => item !== challengeId) : type.ids,
                count: _.includes(type.ids, challengeId) && type.count ? type.count - 1 : type.count
            }));
            return {
                ...state,
                ...newState,
                items: _.omit(state.items, challengeId),
            };
        }
        case types.GET_CHALLENGE_RECOMMENDATIONS.SUCCESS: {
            return {
                ...state,
                recommendations: action.payload.recommendations
            };
        }
        case types.GET_RECOMMENDED_GOALS.SUCCESS: {
            return {
                ...state,
                recommendedGoals: action.payload.recommendedGoals
            };
        }
        case types.GET_STARTER_GOALS.SUCCESS: {
            return {
                ...state,
                starterGoals: action.payload.starterGoals
            };
        }
        case types.GET_ACTIVITY_UNITS.SUCCESS: {
            const { activities, activityUnits, items, ids } = action.payload;
            return {
                ...state,
                activities: {
                    ...state.activities,
                    activities,
                    activityUnits,
                    categories: items,
                    categoryIds: ids
                }
            };
        }
        case types.GET_ACTIVITY_LOGS.SUCCESS: {
            return {
                ...state,
                activities: {
                    ...state.activities,
                    logs: {
                        ...state.activities.logs,
                        ids: action.payload.ids,
                        items: { ...state.activities.logs.items, ...action.payload.items },
                    }
                }
            };
        }
        case types.GET_FILTERED_ACTIVITY_LOGS.SUCCESS:
        case types.GET_MORE_FILTERED_ACTIVITY_LOGS.SUCCESS: {
            const { items, ids, timePeriodId, isReplace } = action.payload;
            return {
                ...state,
                activities: {
                    ...state.activities,
                    logs: {
                        ...state.activities.logs,
                        items: {
                            ...state.activities.logs.items,
                            ...items
                        },
                        timePeriods: {
                            ...state.activities.logs.timePeriods,
                            [timePeriodId]: isReplace ? ids : [...state.activities.logs.timePeriods[timePeriodId], ...ids]
                        }
                    }
                }
            };
        }
        case types.GET_ACTIVITY_LOGS_EXTENDED.SUCCESS: {
            return {
                ...state,
                activities: {
                    ...state.activities,
                    logs: {
                        ...state.activities.logs,
                        idsExtended: _.uniq([...state.activities.logs.idsExtended, ...action.payload.ids]),
                        items: { ...state.activities.logs.items, ...action.payload.items },
                        hasMore: !_.isEmpty(action.payload.items)
                    }
                }
            };
        }
        case types.DELETE_ACTIVITY_LOG.SUCCESS: {
            const { id } = action.payload;
            const items = _.omit(state.activities.logs.items, [id]);
            const timePeriods = _.mapValues(state.activities.logs.timePeriods, ids => _.filter(ids, i => i !== id));
            const ids = _.filter(state.activities.logs.ids, logId => logId !== id);
            const idsExtended = _.filter(state.activities.logs.idsExtended, logId => logId !== id);
            return {
                ...state,
                activities: {
                    ...state.activities,
                    logs: {
                        ...state.activities.logs,
                        ids,
                        idsExtended,
                        items,
                        timePeriods: {
                            ...state.activities.logs.timePeriods,
                            ...timePeriods
                        }
                    }
                }
            };
        }
        case types.SET_USER_TRACKED_CHALLENGE_STATUS: {
            return {
                ...state,
                hasTrackedChallenge: action.payload
            };
        }
        case types.SHOW_FIRST_ACTIVITY_TRACK_MODAL: {
            return {
                ...state,
                isShownFirstActivityTrackModal: true
            };
        }
        case types.HIDE_ACTIVITY_INFORMATION_TEXT: {
            return {
                ...state,
                isHiddenActivityInformationText: true
            };
        }
        case types.GET_LEADERBOARDS.SUCCESS: {
            return {
                ...state,
                allLeaderBoards: action.payload.data
            };
        }
        case types.UPDATE_CHALLENGE_SUCCESS: {
            const { data } = action.payload;
            return {
                ...state,
                items: { ...state.items, [data.challengeId]: { ...state.items[data.challengeId], ...data } }
            };
        }
        case coreTypes.RESET: {
            return {
                ...initialState,
                isShownFirstActivityTrackModal: state.isShownFirstActivityTrackModal,
                carouselInstructionalSlideDismissed: state.carouselInstructionalSlideDismissed,
                hasTrackedChallenge: state.hasTrackedChallenge
            };
        }
        case types.DISMISS_CAROUSEL_INSTRUCTIONS: {
            return {
                ...state,
                carouselInstructionalSlideDismissed: true
            };
        }
        case types.GET_SEARCH_TEAMS_RESULT.SUCCESS: {
            return {
                ...state,
                searchingResultTeams: action.payload.teams
            };
        }
        default:
            return state;
    }
};

const serialize = data => {
    try {
        return JSON.stringify(data);
    } catch (err) {
        const message = 'Failed to serialize challenges data';
        const serializeError = JSON.stringify({
            message,
            err
        });
        tracker.logError(serializeError);
        return JSON.stringify({});
    }
};

const persistConfig = {
    key: NAME,
    storage: Storage.storageType(),
    serialize,
    whitelist: ['hasTrackedChallenge', 'isShownFirstActivityTrackModal', 'isHiddenActivityInformationText', 'carouselInstructionalSlideDismissed']
};

export default persistReducer(persistConfig, reducer);
