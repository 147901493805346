import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classnames from 'classnames';
import { css, StyleSheet } from 'aphrodite-jss';
import { appFonts, layoutStyle, important, spacing, baseColors } from '../../../../styles';
import { components as Core, AsyncComponent, Modal, onKeyPress, CSS_CLASSES } from '../../../core';
import TrackActivityButton from '../TrackActivityButton';
import WithDatesSwiperBase, { styles as baseStyles } from './DatesSwiperBase';

class DatesSwiper extends PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        progressData: PropTypes.object.isRequired,
        isCurrentWeek: PropTypes.bool,
        isDisabled: PropTypes.bool,
        challenge: PropTypes.object,
        challengeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        handlePress: PropTypes.func.isRequired,
        getFontColor: PropTypes.func.isRequired,
        dayNumber: PropTypes.func.isRequired,
        dayFirstLetter: PropTypes.func.isRequired,
        isWeekProgress: PropTypes.bool,
        isShowTodayIndicator: PropTypes.bool,
        isShowIcons: PropTypes.bool,
        isRestartGoalPreviousPeriods: PropTypes.func.isRequired,
        tabIndex: PropTypes.string
    };

    static defaultProps = {
        isCurrentWeek: false,
        isDisabled: false,
        challenge: undefined,
        isWeekProgress: false,
        isShowTodayIndicator: true,
        isShowIcons: true,
        tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])])
    };

    state = {};

    showActivityTrackingModal = (activities, isDoubleTap, props) => {
        if (this.state.isSliding) return;
        Modal.open(
            AsyncComponent(() => import('../ActivityTrackingModal')),
            props,
            { isContainer: true, isMaxWidthLimited: true }
        );
    };

    showTrackActivitiesList = (activities, isDoubleTap, props) => {
        if (this.state.isSliding) return;
        Modal.open(
            AsyncComponent(() => import('../ActivityTrackingList')),
            props,
            {
                isContainer: true,
                isFullHeight: true,
                isNoPadding: true,
                isMaxHeight: true,
            }
        );
    };

    handlePress = progress => event => {
        event.stopPropagation();
        this.props.handlePress(progress)();
    };

    renderItem = progress => {
        const { dayNumber, dayFirstLetter, isShowTodayIndicator, isShowIcons, isRestartGoalPreviousPeriods, tabIndex } = this.props;
        if (_.isEmpty(progress)) return null;
        const fontColor = this.props.getFontColor(progress);
        const isActiveDate = isShowIcons && !isRestartGoalPreviousPeriods(progress);
        const itemContainer = progress.enable && isActiveDate ? styles.itemContainer : styles.itemContainerNotEnabled;
        return (
            <div
                key={progress.date}
                tabIndex={tabIndex}
                role="button"
                onKeyDown={onKeyPress.enter(this.handlePress(progress))}
                onClick={isActiveDate ? this.handlePress(progress) : null}
                className={css(itemContainer)}>
                {isShowTodayIndicator ? <div className={css(styles.todayIndicator, !progress.today && styles.transparentIndicator)} /> : null}
                <div className={css(styles.textContainer)}>
                    <p style={{ ...(progress.today ? appFonts.smBold : appFonts.smRegular), color: fontColor }}>
                        {dayFirstLetter(progress)}
                    </p>
                    <p style={{ ...(progress.today ? appFonts.xsBold : appFonts.xsRegular), color: fontColor }}>
                        {dayNumber(progress)}
                    </p>
                </div>
                <div className={css(styles.trackButtonContainer)}>
                    {isActiveDate ? (
                        <>
                            {progress.enable ? (
                                <TrackActivityButton progress={progress} challengeId={this.props.challengeId} />
                            ) : (
                                <Core.Icon
                                    type="light"
                                    name="lock"
                                    size={spacing.s3}
                                    color={baseColors.grey60}
                                    className={css(styles.lockIconContainer)}
                                />
                            )}
                        </>
                    ): null}
                </div>
            </div>
        );
    };

    render() {
        const { isWeekProgress, isCurrentWeek, weekProgress, progressData, challenge, isDisabled } = this.props;
        if (!challenge) return null;
        return (
            <div className={classnames(css(layoutStyle.flex1, isDisabled ? layoutStyle.opacityHalf
                : layoutStyle.opacityFull), CSS_CLASSES.datesSwiper)}>
                {isCurrentWeek || isWeekProgress ? (
                    <div className={css(layoutStyle.flexRow, layoutStyle.flexSpaceAround, layoutStyle.flex1)}>
                        {_.map(isWeekProgress ? weekProgress : progressData.dailyProgress, this.renderItem)}
                    </div>
                ) : (
                    <div>
                        <Core.Carousel
                            type="multiple"
                            arrows={true}
                            hasCustomArrows={true}
                            dots={false}
                            initialSlide={progressData.index}
                            slidesToShow={7}
                            responsive={null}
                            parent={this}
                            itemsLength={progressData.dailyProgress.length}>
                            {_.map(progressData.dailyProgress, this.renderItem)}
                        </Core.Carousel>
                    </div>
                )}
            </div>
        );
    }
}

export default WithDatesSwiperBase(DatesSwiper);

const WEB_LOCK_ICON_SPACING = 10;

const styles = StyleSheet.create({
    ...baseStyles,
    itemContainer: {
        ...baseStyles.itemContainer,
        cursor: 'pointer'
    },
    itemContainerNotEnabled: {
        ...baseStyles.itemContainer,
        cursor: important('not-allowed')
    },
    trackButtonContainer: {
        paddingBottom: spacing.s1,
        display: 'flex',
        justifyContent: 'center'
    },
    lockIconContainer: {
        marginTop: WEB_LOCK_ICON_SPACING,
        justifyContent: 'center',
        alignItems: 'center'
    },
    transparentIndicator: {
        backgroundColor: 'transparent'
    },
    todayIndicator: {
        ...baseStyles.todayIndicator,
        position: 'relative'
    }
});
