import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { getImageProp, translate } from '../../../core';
import { appFonts, baseColors, spacing } from '../../../../styles';
import { isJoiningCommunity, getSingleCommunity } from '../../selectors';
import JoinedTag from '../../../core/components/JoinedTag/JoinedTag';

export const ITEM_HEIGHT = 150;

export default function WithCarouselItemBase(WrappedComponent) {
    class CarouselItemBase extends PureComponent {
        static propTypes = {
            join: PropTypes.func,
            item: PropTypes.object.isRequired,
            isInvitationCarousel: PropTypes.bool,
            isJoining: PropTypes.bool,
            i18n: PropTypes.object.isRequired
        };

        static defaultProps = {
            isInvitationCarousel: false,
            join: null,
            isJoining: false
        };

        get imageSource() {
            return getImageProp(_.get(this.props.item, 'groupImageURL') || _.get(this.props.item, 'imageURL'));
        }

        get isInvitation() {
            return this.props.item.isInvitation && this.props.isInvitationCarousel;
        }

        joinCommunity = () => {
            if (!this.props.isJoining && this.props.join) {
                this.props.join(this.props.item, true);
            }
        };

        get invitationType() {
            return this.props.i18n.t('invitationCommunity');
        }

        get joinButtonLabel() {
            return this.props.i18n.t('join');
        }

        get inviteCommunityTitle() {
            return this.props.i18n.t('invitationCommunity');
        }

        get renderJoinedTag() {
            return <JoinedTag showTag={this.props.item.isJoined} />;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    disableJoinButton={this.props.isJoining}
                    image={this.imageSource}
                    joinCommunity={this.joinCommunity}
                    isInvitation={this.isInvitation}
                    invitationType={this.invitationType}
                    joinButtonLabel={this.joinButtonLabel}
                    inviteCommunityTitle={this.inviteCommunityTitle}
                    joinedTag={this.renderJoinedTag}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        return {
            isJoining: isJoiningCommunity(state, ownProps.id),
            item: getSingleCommunity(state, ownProps.id)
        };
    }

    return connect(mapStateToProps)(translate()(CarouselItemBase));
}

export const styles = {
    members: {
        color: baseColors.grey40,
        ...appFonts.xsRegular
    },
    membersInvitation: {
        ...appFonts.xsRegular,
        color: baseColors.secondary
    },
    communityTitle: {
        backgroundColor: 'transparent',
        marginBottom: spacing.s0,
        color: baseColors.black,
        ...appFonts.lgBold
    },
    additionalInfo: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        flex: 1
    },
};
