import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { components as Core, ROUTES, constants as coreConstants } from '../../../core';
import CarouselItem from '../CarouselItem';
import { spacing } from '../../../../styles';
import WithProfileCommunitiesBase, { styles as baseStyles } from './ProfileCommunitiesBase';

class ProfileCommunities extends PureComponent {
    static propTypes = {
        hasCommunities: PropTypes.bool,
        communities: PropTypes.array,
        history: PropTypes.object.isRequired,
        numAttended: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        title: PropTypes.string.isRequired,
        discoverMoreButtonLabel: PropTypes.string.isRequired,
        promptJoinCommunities: PropTypes.string.isRequired,
        exploreCommunitiesButtonLabel: PropTypes.string.isRequired
    };

    static defaultProps = {
        communities: [],
        hasCommunities: false,
        numAttended: undefined
    };

    state = {};

    openListCommunities = props => this.props.history.push(ROUTES.communities(), props);

    renderItem = item => <CarouselItem id={item.id} key={item.id} onPressSingle={this.onPressSingle} />;

    goFullListCommunities = () => this.openListCommunities({ activeTab: 'my' });

    exploreCommunities = () => this.openListCommunities();


    onPressSingle = item => {
        if (this.state.isSliding) return;
        this.props.history.push(ROUTES.communityDetails(item.id));
    }

    render() {
        const { hasCommunities, title, numAttended, communities, discoverMoreButtonLabel, promptJoinCommunities,
            exploreCommunitiesButtonLabel } = this.props;
        return (
            hasCommunities ?
                <div>
                    <Core.Carousel
                        parent={this}
                        title={title}
                        separator={true}
                        count={numAttended}
                        items={communities}
                        isBorderShown={false}
                        itemsLength={communities.length}
                        titleSize={Core.SubHeader.SIZES.large}
                        viewAllPress={this.goFullListCommunities}
                        type={coreConstants.CAROUSEL_TYPES.multiple}
                    >
                        {_.map(communities, this.renderItem)}
                    </Core.Carousel>
                    <div className={css(styles.buttonContainer)}>
                        <Core.Button
                            type="outlined"
                            fullWidth={true}
                            onPress={this.exploreCommunities}>
                            {discoverMoreButtonLabel}
                        </Core.Button>
                    </div>
                </div> :
                <div className={css(styles.carouselEmptyContainer)}>
                    <span className={css(styles.carouselEmptyTitle)}>{title}</span>
                    <div className={css(styles.promptJoinCommunities)}>{promptJoinCommunities}</div>
                    <div className={css(styles.buttonContainer)}>
                        <Core.Button
                            className={css(styles.buttonContainer)}
                            type="contained"
                            fullWidth={true}
                            onPress={this.exploreCommunities}>
                            {exploreCommunitiesButtonLabel}
                        </Core.Button>
                    </div>
                </div>
        );
    }
}

const styles = StyleSheet.create({
    ...baseStyles,
    carouselEmptyContainer: {
        marginTop: spacing.s1,
        marginBottom: spacing.s1
    },
    buttonContainer: {
        marginBottom: spacing.s5,
    }
});

export default withRouter(WithProfileCommunitiesBase(ProfileCommunities));
