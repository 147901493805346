import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';

import { components as Core } from '../../../core';
import { EVENT_ENTITY_TYPES } from '../../constants';
import Events from '../Events';
import WithMyTabBase from './MyTabBase';
import { styles as baseStyles } from '../../styles';

class MyTab extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool,
        i18n: PropTypes.object.isRequired,
        eventsLength: PropTypes.number.isRequired,
        exploreLoadingStatus: PropTypes.object
    };

    static defaultProps = {
        isLoading: false,
        exploreLoadingStatus: {}
    };

    render() {
        return (
            <div>
                <Events
                    title={this.props.i18n.t('upcoming')}
                    type={EVENT_ENTITY_TYPES.MY_ITEMS}
                    isLoading={{ loading: this.props.isLoading, showSpinner: true }}
                    exploreLoadingStatus={this.props.exploreLoadingStatus}
                />
                <Events
                    title={this.props.i18n.t('past_events')}
                    type={EVENT_ENTITY_TYPES.PAST}
                    isLoading={{ loading: this.props.isLoading, showSpinner: true }}
                    exploreLoadingStatus={this.props.exploreLoadingStatus}
                />
                {!this.props.eventsLength && !this.props.isLoading ?
                    <div className={css(styles.placeholder)}>
                        <div className={css(styles.placeholderTitle)}>{this.props.i18n.t('no_events')}</div>
                        <Core.NotAvailableContent text={this.props.i18n.t('no_events_placeholder')}/>
                    </div> : null
                }
            </div>
        );
    }
}


export default WithMyTabBase(MyTab);


const styles = StyleSheet.create({
    ...baseStyles
});
