import { CREATE_CHALLENGE_DEFAULT_PROPS as DEFAULT_PROPS, CREATE_CHALLENGE_STEPS as STEPS } from '../../constants';
import { ActionSheet, tracker } from '../../../core';
import openCreateChallenge from './openCreateChallenge';
import i18n from '../../../../i18n';

function onCreateIndividualChallenge(params, userId, actions, navigation) {
    tracker.logEvent('add_challenge', { plus: false });
    open(STEPS.type, DEFAULT_PROPS.type(userId), params, actions, navigation);
}
function onCreateTeamChallenge(params, actions, navigation) {
    tracker.logEvent('add_challenge', { plus: false, team: true });
    open(STEPS.teamSize, DEFAULT_PROPS.teamSize(), params, actions, navigation);
}
// todo: uncomment functions below when dept/reg challenges are ready
// function onCreateInvitation(params, step, actions) {
//     tracker.logEvent('add_challenge', { plus: false });
//     open(step, DEFAULT_PROPS[step](), params, actions);
// }
//

function open(step, data, params, actions, navigation) {
    openCreateChallenge(step, params, navigation);
    clear(actions);
    updateCallback(data, step, actions);
}

export default function createChallenge(params, isExtendedRole, userId = '', actions, navigation) {
    ActionSheet.open([
        {
            title: i18n.t('createChallenge.compete.individual'),
            onPress: () => onCreateIndividualChallenge(params, userId, actions, navigation)
        }, {
            title: i18n.t('createChallenge.compete.team'),
            onPress: () => onCreateTeamChallenge(params, actions, navigation)
        }
    ], undefined, undefined, navigation);
}

const clear = ({ updateNewChallenge, updateCreateChallengeStep }) => {
    updateNewChallenge();
    updateCreateChallengeStep(undefined, undefined, true);
};

const updateCallback = (data, step, { updateNewChallenge, updateCreateChallengeStep }) => {
    updateNewChallenge(data);
    updateCreateChallengeStep(step, true);
};
