import _ from 'lodash';
import i18n from '../../../i18n';

// function formatNumber(points) {
//     if (Number(points) % 1 === 0) {
//         return '0,0';
//     } else if (Number(points) < 1) {
//         return '0,0.000';
//     }
//     return '0,0.0';
// }

export function formatPoints(points, _customPointsUnit) {
    return `${Number(points).toLocaleString(i18n.currentLocale())} ${_customPointsUnit}`;
}

export function formatPointsValue(points) {
    return `${Number(points).toLocaleString(i18n.currentLocale())}`;
}

export function formatPointsByName(points, _customPointsName) {
    return `${Number(points).toLocaleString(i18n.currentLocale())} ${_customPointsName}`;
}

export const roundedPoints = unitPoints => _.round(unitPoints, unitPoints <= 0.99 ? 3 : 1);

export const getPointsUnits = (task, customUnit) => {
    const points = _.get(task, 'externalRewardPoints') || _.get(task, 'points', 0);
    const unit = _.get(task, 'externalRewardName') || customUnit;
    return { points, unit };
};

export function getRestartGoalTotal(restartGoal, isWeeklyFrequency) {
    let restartGoalTotal = _.get(restartGoal, 'progress.overallPointsTargetValue');
    if (restartGoalTotal && !isWeeklyFrequency) restartGoalTotal *= 7;
    return restartGoalTotal;
}
