import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { TOURS } from '../../constants';
import * as actions from '../../actions';
// eslint-disable-next-line no-unused-vars
import { translate, tracker, LANGUAGES, selectors as coreSelectors, color, BRAND_COLOR } from '../../../core';
import { appFonts, baseColors, spacing, windowSize } from '../../../../styles';

export default function withNewFeatureModalBase(WrappedComponent) {
    class NewFeatureModalBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            i18n: PropTypes.object.isRequired,
            showTitle: PropTypes.bool,
            showImage: PropTypes.bool,
            lng: PropTypes.string,
        };

        static defaultProps = {
            lng: undefined,
            showTitle: true,
            showImage: false,
        };

        componentDidMount() {
            tracker.logEvent('NewFeatureModal_View', { feature: 'quick_activity_log_view' });
        }

        saveRef = ref => (this.wrapped = ref);

        dismissModal = isFooterBtn => _.has(this, 'wrapped.dismissModal') && this.wrapped.dismissModal(isFooterBtn);

        onButtonPress = (isFooterBtn = true) => {
            this.props.actions.finishTour(TOURS.NEW_FEATURE_MODAL_QUICKER_ACTIVITY_LOG_VIEW);
            this.dismissModal(isFooterBtn);
            tracker.logEvent(`NewFeatureModal_${isFooterBtn ? 'CloseButton' : 'X'}_Click`);
        };

        onCloseButtonPress = () => this.onButtonPress(false);

        get newText() {
            return this.props.i18n.t('new');
        }

        get title() {
            const { showTitle, i18n } = this.props;
            return showTitle ? i18n.t('newFeatureModal.quickActivityLogView.title') : '';
        }

        get text() {
            return this.props.i18n.t('newFeatureModal.quickActivityLogView.description');
        }

        get buttonText() {
            return this.props.i18n.t('got_it');
        }

        get LetsGoButtonText() {
            return this.props.i18n.t('newFeatureModal.mePage.letsSeeBtn');
        }

        get animation() {
            const { i18n, lng } = this.props;
            // eslint-disable-next-line no-unused-vars
            const language = i18n.locale || lng;
            const animation = require('./activityLog.json');
            // TODO: unccomment if will need diferent animations for diferent languages
            // if (language) {
            //     switch (language.substring(0, 2)) {
            //         case LANGUAGES.fr:
            //             animation = require('./french.json');
            //             break;
            //         case LANGUAGES.es:
            //             animation = require('./spanish.json');
            //             break;
            //         default:
            //             break;
            //     }
            // }
            return animation;
        }

        get image() {
            return this.props.showImage
                ? require('../../../../image/new-feature-modal-bell-eap.png')
                : null;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    onCloseButtonPress={this.onCloseButtonPress}
                    onButtonPress={this.onButtonPress}
                    buttonText={this.buttonText}
                    animation={this.animation}
                    newText={this.newText}
                    title={this.title}
                    image={this.image}
                    ref={this.saveRef}
                    text={this.text}
                    LetsGoButtonText={this.LetsGoButtonText}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const routeParams = _.get(ownProps, 'route.params');
        return {
            ...routeParams,
            customPointsName: coreSelectors.getCustomPointsName(state)
        };
    };

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators({ ...actions }, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(NewFeatureModalBase));
}

const SMALL_DEVICES_HEIGHT = 700;
const IS_SMALL_DEVICE = windowSize.height < SMALL_DEVICES_HEIGHT;

export const styles = {
    newText: {
        ...appFonts.smRegular,
        color: color.brandColorMaker(baseColors.primary, BRAND_COLOR.DARK_SHADE),
        textAlign: 'center',
        textTransform: 'uppercase'
    },
    newTextBg: {
        paddingLeft: spacing.s1,
        paddingRight: spacing.s1,
        paddingTop: spacing.s0,
        paddingBottom: spacing.s0,
        borderRadius: spacing.s0,
        backgroundColor: color.brandColorMaker(baseColors.primary, BRAND_COLOR.LIGHT_SHADE),
    },
    title: {
        ...(IS_SMALL_DEVICE ? appFonts.xlBold : appFonts.xlBold),
        color: baseColors.black,
        marginTop: spacing.s1,
        textAlign: 'center',
    },
    text: {
        ...appFonts.mdRegular,
        color: baseColors.grey20,
        marginBottom: spacing.s7,
        marginTop: spacing.s1,
        textAlign: 'center',
    },
    divider: {
        backgroundColor: '#D3D3D3',
        height: 1,
    },
    footerButton: {
        ...appFonts.mdRegular,
        color: baseColors.secondary,
        padding: spacing.s3,
        textAlign: 'center',
    },
    closeBtnContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        padding: IS_SMALL_DEVICE ? spacing.s2 : spacing.s5,
        paddingBottom: 0,
        paddingTop: IS_SMALL_DEVICE ? spacing.s0 : spacing.s5
    },
    contentContainer: {
        paddingRight: IS_SMALL_DEVICE ? spacing.s3 : spacing.s5,
        paddingLeft: IS_SMALL_DEVICE ? spacing.s3 : spacing.s5,
    },
};