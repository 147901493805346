import _ from 'lodash';
import moment from 'moment';
import I18n from '../../../i18n/i18n';
import { STATE_KEYS, HEALTH_SCORES } from '../constants';
import { constants as coreConstants, Platform, PLATFORMS } from '../../core';
import { baseColors } from '../../../styles';
import { constants as onboardingConstants, services as onboardingServices } from '../../onboarding';

export function validateProfileValues(values, userPreferredHeightUnit = undefined, userPreferredWeightUnit = undefined) {
    const errors = {};
    _.forEach(values, field => {
        const error = validateValue(field, userPreferredHeightUnit, userPreferredWeightUnit);
        if (error) {
            errors[field.stateKey] = error;
        }
    });
    return errors;
}

export function validateValue(item, userPreferredHeightUnit = undefined, userPreferredWeightUnit = undefined) {
    const errors = [];
    if (item) {
        if (item.required) {
            if (_.get(item.value, 'length') === 0) {
                errors.push(I18n.t('edit_profile_unspecified_value'));
            }
        }
        if (item.stateKey === STATE_KEYS.heightInches) {
            const isInchesHeightUnit = userPreferredHeightUnit !== onboardingConstants.CM;
            const value = isInchesHeightUnit ? convertFtToInches(item.ftValue) + Number(item.value) : item.value;
            const isMetricUnit = userPreferredHeightUnit === onboardingConstants.CM;
            const getMinOrMax = value => isMetricUnit ? value / coreConstants.CM_TO_IN_DENOMINATOR : value;
            if (_.has(item, 'minimum')) {
                const minimum = getMinOrMax(item.minimum);
                if (minimum >= Number(value) && (item.required === true || value)) {
                    errors.push(`${I18n.t('edit_profile_value_too_low')} ${Math.round(minimum)} ${isInchesHeightUnit ? userPreferredHeightUnit : ''}`);
                }
            }
            if (_.has(item, 'maximum')) {
                const maximum = getMinOrMax(item.maximum);
                if (maximum <= Number(value) && (item.required === true || value)) {
                    errors.push(`${I18n.t('edit_profile_value_too_high')} ${Math.round(maximum)} ${isInchesHeightUnit ? userPreferredHeightUnit : ''}`);
                }
            }
        } else if (item.stateKey === STATE_KEYS.weightLbs) {
            const error = onboardingServices.compareValue(
                userPreferredWeightUnit,
                onboardingConstants.KG,
                STATE_KEYS.weight,
                item.value,
                coreConstants.KG_TO_LBS_DENOMINATOR,
                true
            );
            if (error) errors.push(error);
        } else if (item.stateKey === STATE_KEYS.restingHeartRate) {
            if (((item.minimum && item.minimum > Number(item.value)) || (item.maximum && item.maximum < Number(item.value))) &&
                (item.required === true || item.value)) {
                errors.push(getRangeErrorString(item));
            }
        } else if (item.stateKey === STATE_KEYS.waistCircumferenceInches) {
            const error = onboardingServices.compareValue(
                item.unit,
                onboardingConstants.CM,
                STATE_KEYS.waist,
                item.value,
                coreConstants.CM_TO_IN_DENOMINATOR,
                true
            );
            if (error) errors.push(error);
        } else if (item.stateKey === STATE_KEYS.dateOfBirth && Platform.OS === PLATFORMS.web) {
            if (item.value) {
                const dateFormatted = moment(item.value);
                if (dateFormatted && (!dateFormatted.isValid() || !dateFormatted.isBetween(item.minimum, item.maximum))) {
                    errors.push(I18n.t('invalidDate'));
                }
            }
        } else {
            if (_.has(item, 'minimum') && item.minimum > Number(item.value)) {
                if (item.required === true || item.value) {
                    errors.push(getRangeErrorString(item));
                }
            }
            if (_.has(item, 'maximum') && item.maximum < Number(item.value)) {
                if (item.required === true || item.value) {
                    errors.push(getRangeErrorString(item));
                }
            }
        }
    }
    return errors.join(' ');
}

export function getRangeErrorString(item) {
    return I18n.t('editProfileItemCorrectRange', {
        itemName: I18n.t(item.name),
        min: item.minimum,
        max: item.maximum
    });
}

export function updateProfileValue(fields, stateKey, text, isSelectField) {
    return _.map(fields, field => {
        if (field.stateKey === stateKey) {
            return {
                ...field,
                value: isSelectField ? field.enum[text + 1] : text
            };
        }
        return field;
    });
}

export function updateBiometricUnit(fields, stateKey, newUnit, newValue, newDefaultValue = null, newStoredData = null) {
    return _.map(fields, field => {
        if (field.stateKey === stateKey) {
            return {
                ...field,
                value: newValue,
                defaultValue: !_.isNull(newDefaultValue) ? newDefaultValue : field.defaultValue,
                unit: newUnit,
                storedData: {
                    ...field.storedData,
                    ...newStoredData
                }
            };
        }
        return field;
    });
}

export function updateBiometricValue(fields, stateKey, newValue, newPreferredValue) {
    return _.map(fields, field => {
        if (field.stateKey === stateKey) {
            return {
                ...field,
                value: newValue,
                preferredValue: newPreferredValue
            };
        }
        return field;
    });
}

export function getGenderParamValue(value) {
    switch (value) {
        case 'gender_male':
            return 'm';
        case 'gender_non_binary':
            return 'o';
        case 'gender_female':
            return 'f';
        case 'gender_rather_not_say':
            return '-';
        default:
            return null;
    }
}

export function getGenderFromParam(value) {
    switch (value) {
        case 'm':
            return 'gender_male';
        case 'o':
            return 'gender_non_binary';
        case 'f':
            return 'gender_female';
        case '-':
            return 'gender_rather_not_say';
        default:
            return null;
    }
}

export function convertInchesToCm(value) {
    return parseFloat(value / 0.394);
}

export function convertCmToInches(value) {
    return parseFloat(value * 0.394);
}

export function convertLbsToKg(value) {
    return parseFloat(value / 2.2);
}

export function convertKgToLbs(value) {
    return parseFloat(value * 2.2);
}

export function convertInchesToFtAndInches(value, unit = onboardingConstants.FT) {
    if (unit === onboardingConstants.IN || unit === onboardingConstants.INCHES) {
        return parseFloat(value % 12);
    }
    return Math.floor(parseFloat(value / 12));
}

export function convertCmToFtAndInches(value) {
    const cmToInches = convertCmToInches(value);
    const ft = Math.round(convertInchesToFtAndInches(cmToInches));
    const inches = Math.round(convertInchesToFtAndInches(cmToInches, onboardingConstants.IN));
    return { ft, inches };
}

export function convertFtAndInchesToCm(ftValue, inValue) {
    const converted = convertInchesToCm(convertFtToInches(ftValue) + parseInt(inValue));
    return converted ? converted.toFixed(2) : '';
}

export function convertFtToInches(value) {
    return parseFloat(value * 12);
}

export function getSurveyResults(overall, move) {
    const excellentProps = { iconName: 'star', iconColor: baseColors.gold };
    const goodProps = { iconName: 'check-circle', iconColor: baseColors.success };
    const fairProps = { iconName: 'circle', iconColor: baseColors.warn };
    const poorProps = { iconName: 'exclamation-circle', iconColor: baseColors.danger };

    const RESULTS = {
        excellent: [
            { ...excellentProps, text: I18n.t('wellbeing.excellent.desc1') },
            { ...excellentProps, text: I18n.t('wellbeing.excellent.desc2') },
        ],
        very_good: [
            { ...goodProps, text: I18n.t('wellbeing.veryGood.desc1') },
            { ...goodProps, text: I18n.t('wellbeing.veryGood.desc2') },
        ],
        good: [
            { ...goodProps, text: I18n.t('wellbeing.good.desc1') },
            { ...goodProps, text: I18n.t('wellbeing.good.desc2') },
        ],
        fair: [
            { ...fairProps, text: I18n.t('wellbeing.fair.desc1') },
            { ...fairProps, text: I18n.t('wellbeing.fair.desc2') },
        ],
        poor: [
            { ...poorProps, text: I18n.t('wellbeing.poor.desc1') },
        ],
    };

    const results = RESULTS[overall];

    switch (true) {
        // Do not render the messages about daily movement for now
        // case move === HEALTH_SCORES.fair:
        //     return _.concat(results, [{ ...fairProps, text: I18n.t('wellbeing.poor.desc2') }]);
        // case move === HEALTH_SCORES.poor:
        //     return _.concat(results, [{ ...poorProps, text: I18n.t('wellbeing.poor.desc3') }]);
        default:
            return results;
    }
}

export function getImproveScoreDescription(move, overall) {
    const prefix = 'wellbeing.improveScore.desc';
    switch (true) {
        case move === HEALTH_SCORES.fair || move === HEALTH_SCORES.poor:
            return I18n.t(`${prefix}4`);
        case overall === HEALTH_SCORES.excellent:
            return I18n.t(`${prefix}1`);
        case overall === HEALTH_SCORES.veryGood:
            return I18n.t(`${prefix}2`);
        default:
            return I18n.t(`${prefix}3`);
    }
}
