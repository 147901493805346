import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { baseColors, containerStyle, layoutStyle } from '../../../../styles';
import { components as Core } from '../../../core';
import WithCorporateAccountLinkVerifyBase, { ICON_SIZE, styles as baseStyles } from './CorporateAccountLinkVerifyBase';

class CorporateAccountLinkVerify extends PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        corporateAccountEmail: PropTypes.string.isRequired,
        isLoading: PropTypes.bool.isRequired,
        resendEmail: PropTypes.func.isRequired,
        checkVerification: PropTypes.func.isRequired
    };

    render() {
        const { i18n, corporateAccountEmail, checkVerification, resendEmail } = this.props;
        return (
            <div className={css(layoutStyle.fw)}>
                <div className={css(containerStyle.container)}>
                    <Core.SubHeader titleAlign={Core.SubHeader.ALIGN.center} left={<span />} title={i18n.t('corporateAccountLink.verification.title')} />
                    <div className={css(containerStyle.main)}>
                        <div>
                            <p className={css(styles.textCenter)}>{this.props.i18n.t('corporateAccountLink.verification.description')}</p>
                            <p className={css(styles.textCenter, styles.email)}>{corporateAccountEmail}</p>
                        </div>
                        <div className={css(styles.alignItemsCenter)}>
                            <div className={css(styles.icon)}>
                                <Core.Icon
                                    type="fa"
                                    size={ICON_SIZE}
                                    color={baseColors.primary}
                                    name="envelope"
                                />
                            </div>
                            <Core.Button
                                lowercase={true}
                                type="text"
                                className={css(styles.button)}
                                onPress={resendEmail}>
                                {i18n.t('resendEmail')}
                            </Core.Button>
                        </div>
                    </div>
                    <div className={css(containerStyle.footerButtonContainer)}>
                        <Core.Button
                            onPress={checkVerification}
                            fullWidth={true}>
                            {i18n.t('done')}
                        </Core.Button>
                    </div>
                    <Core.BlockingLoading isLoading={this.props.isLoading} />
                </div>
            </div>
        );
    }
}

export default WithCorporateAccountLinkVerifyBase(CorporateAccountLinkVerify);

const styles = StyleSheet.create({
    ...baseStyles,
    alignItemsCenter: {
        ...baseStyles.alignItemsCenter,
        display: 'flex',
        flexDirection: 'column'
    }
});