import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { baseColors, spacing } from '../../../../../styles';
import { getRewards, isLoadingRewards, isRewardsEnabled } from '../../../selectors';
import { translate, getFirstLoadingWrapper } from '../../../../core';

export default function WithDashboardShopPointsItem(WrappedComponent) {
    class DashboardShopPointsItem extends PureComponent {
        static propTypes = {
            isLoadingRewards: PropTypes.bool,
            rewards: PropTypes.object,
            isRewardsEnabled: PropTypes.bool
        };

        static defaultProps = {
            isLoadingRewards: false,
            rewards: {},
            isRewardsEnabled: false
        };

        constructor(props) {
            super(props);
            this.state = {
                //eslint-disable-next-line
                isLoading: props.isLoadingRewards,  //used in getFirstLoadingWrapper
                isFirstLoaded: false
            };
        }

        static getDerivedStateFromProps(nextProps, prevState) {
            return getFirstLoadingWrapper(nextProps, prevState, 'isLoadingRewards');
        }

        get numRedeemable() {
            return _.get(this.props.rewards, 'numRedeemable', 0);
        }

        get rewardName() {
            return _.get(this.props.rewards, 'externalRewardName', '');
        }

        render() {
            const { isFirstLoaded } = this.state;
            return (
                <WrappedComponent
                    {...this.props}
                    numRedeemable={this.numRedeemable}
                    rewardName={this.rewardName}
                    isFirstLoadedRewards={isFirstLoaded}
                />
            );
        }
    }

    function mapStateToProps(state) {
        return {
            rewards: getRewards(state),
            isLoadingRewards: isLoadingRewards(state),
            isRewardsEnabled: isRewardsEnabled(state)
        };
    }

    return connect(mapStateToProps)(translate()(DashboardShopPointsItem));
}

export const styles = {
    pointsContainer: {
        paddingRight: spacing.s1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end'
    },
    shoppingIcon: {
        backgroundColor: baseColors.primary,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    pointsValueContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    pointsValueText: {
        color: baseColors.black,
        marginLeft: spacing.s1,
    },
};
