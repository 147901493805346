import React from 'react';
import _ from 'lodash';
import { AllHtmlEntities } from 'html-entities';
import { create, all } from 'mathjs';

const entities = new AllHtmlEntities();

const globalTagsRegex = /<(\/?)(\w+)[^>]*(\/?)>/gi;
const localUrlRegex = /<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1/;

const IMAGE_PROXY_URL = 'https://images.weserv.nl';
const GOOGLE_DOCS_URL = 'https://docs.google.com/gview?embedded=true&url=';
const PDF_FORMAT = '.pdf';
const APP_NAME_KEY = 'app_name';

export const htmlDecode = entities.decode;

/**
 * Parse notification message and mark dynamic parts as bold
 * @param {string} template
 * @param {Object} values
 * @param {Function} TextComponent
 */
export function parseNotificationMessage(template, values, TextComponent, notificationType) {
    const preparedString = template.replace('\\}', '}').replace('\\{', '{');
    const text = [];
    const links = [];
    let i = 0;
    _.forEach(preparedString.split(' '), item => {
        const key = _.findKey(values, (v, k) => _.includes(item, `{${k}}`));
        if (key) {
            const value = values[key];
            const valueReplaced = replaceATagsWithItsHref(value);
            text.push(htmlDecode(item.replace(`{${key}}`, valueReplaced)));
            const valueLinks = returnLinks(value);
            if (valueLinks.length) {
                links.push(valueLinks);
            }
            i += 2;
        } else {
            text[i] = [...(text[i] || []), item];
        }
    });

    let textStyle;
    if (notificationType !== 'admin_notify') {
        textStyle = { fontWeight: 'bold' };
    }

    const fullText = _.map(text, (v, i) => _.isArray(v)
        ? [...v, ''].join(' ')
        : <TextComponent key={v + i} style={textStyle}>{v} </TextComponent>);

    return { fullText, links };
}

function returnLinks(text) {
    const links = [];
    const linkTags = text.match(/<a[^>]+>[^<]+<\/a>/gi);
    if (text && linkTags) {
        linkTags.forEach(item => {
            const href = item.match(/href="(.*?)"/);
            if (href) {
                const link = href[0].match(/href="(.*?)"/);
                if (link) {
                    links.push(link[1]);
                }
            }
        });
    }
    return links;
}

export function generateStringWithValues(template, obj) {
    let content = template;
    const regexPattern = /{.+?}/ig;
    const found = content.match(regexPattern);
    found.forEach(longKey => {
        const key = longKey.substr(1, longKey.length - 2);
        const nestedProperties = key.split('.');
        if (nestedProperties.length > 1) {
            let nestedObject = obj;
            for (let index = 0; index < nestedProperties.length - 1; index++) {
                if (Object.prototype.hasOwnProperty.call(nestedObject, nestedProperties[index])) {
                    const tempNestedObject = nestedObject[nestedProperties[index]];
                    nestedObject = tempNestedObject;
                }
            }
            if (Object.prototype.hasOwnProperty.call(nestedObject, nestedProperties[nestedProperties.length - 1]))
            { content = content.replace(`{${key}}`, nestedObject[nestedProperties.pop()]); }
        } else if (Object.prototype.hasOwnProperty.call(obj, key))
        { content = content.replace(`{${key}}`, obj[key]); }
    });
    return content;
}

export function generateStringWithValuesStyled(template, obj, keyStyles, callback = null, TextComponent, isWeb, programName) {
    let content = template;
    const array = [];
    const regexPattern = /{.+?}/ig;
    const found = content.match(regexPattern);
    array.push(content.split(found[0])[0]);
    found.forEach((longKey, index) => {
        const key = longKey.substr(1, longKey.length - 2);
        const nestedProperties = key.split('.');
        const funcCallback = callback ? event => callback(event, key) : null;

        const props = _.omitBy({
            onPress: !isWeb && funcCallback,
            onClick: isWeb && funcCallback,
            style: !isWeb && keyStyles && [keyStyles.all, keyStyles[key]],
            className: isWeb && keyStyles && `${keyStyles.all} ${keyStyles[key]}`
        }, prop => !prop);

        if (nestedProperties.length > 1) {
            let nestedObject = obj;
            for (let index = 0; index < nestedProperties.length - 1; index++) {
                if (Object.prototype.hasOwnProperty.call(nestedObject, nestedProperties[index])) {
                    const tempNestedObject = nestedObject[nestedProperties[index]];
                    nestedObject = tempNestedObject;
                }
            }
            if (Object.prototype.hasOwnProperty.call(nestedObject, nestedProperties[nestedProperties.length - 1])) {
                array.push(
                    <TextComponent {...props} key={key}>
                        {htmlDecode(_.toString(nestedObject[nestedProperties.pop()]))}
                    </TextComponent>
                );
                content = content.split(longKey)[1];
                array.push(content.split(found[index + 1])[0]);
            }
        } else if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const name = key === APP_NAME_KEY && programName ? programName : htmlDecode(_.toString(obj[key]));
            array.push(
                <TextComponent {...props} key={key}>
                    {name}
                </TextComponent>);
            content = content.split(longKey)[1];
            array.push(content.split(found[index + 1])[0]);
        }
    });
    return array;
}


// html helpers
export function replaceAllBr(text) {
    return text.replace(/<br \/>/gi, '\n').replace(/<br\/>/gi, '\n');
}

export function getAllHtmlTags(text) {
    return text.match(globalTagsRegex);
}

export function replaceUrlsFromAllHref(text) {
    const resultText = text;
    const htmlTags = getAllHtmlTags(text);
    _.forEach(htmlTags, matchText => {
        const innerMatch = matchText.match(localUrlRegex);
        if (innerMatch && innerMatch.length > 2) {
            resultText.replace(matchText, innerMatch[2]);
        }
    });
    return resultText;
}

export function removeAllHtmlTags(text) {
    return text.replace(globalTagsRegex, '');
}

export function replaceATagsWithItsHref(text) {
    return removeAllHtmlTags(replaceUrlsFromAllHref(text));
}

export function replaceBrAndATagsWithItsHref(text) {
    return removeAllHtmlTags(replaceUrlsFromAllHref(replaceAllBr(text)));
}

export function replaceATagsAndReturnLinks(text) {
    let result = text;
    const links = {};
    const linkTags = text.match(/<a[^>]+>[^<]+<\/a>/gi);
    if (text && linkTags) {
        linkTags.forEach(item => {
            const txt = item.match(/>(.*?)</)[1];
            const href = item.match(/href="(.*?)"/);
            if (href) {
                result = result.replace(txt, href[1]);
                links[href[1]] = txt;
            }
        });
    }
    return { text: result, links };
}

export function replaceBrAndATagsWithItsHrefAndReturnLinks(text) {
    const textWithLinks = replaceATagsAndReturnLinks(text);
    return { text: replaceBrAndATagsWithItsHref(textWithLinks.text), links: textWithLinks.links };
}

// /html helpers

export function getSecureImageUrl(url) {
    if (_.startsWith(url, 'https://')) return url;
    return `${IMAGE_PROXY_URL}?url=${url.replace('http://', '')}`;
}

export function toNumbers(value) {
    return value.replace(/[^0-9]/g, '');
}

//change URLs to open PDF files on Android
export function replacePDFLinks(text) {
    if (!text) {
        return '';
    }
    let result = text;
    const linkTags = text.match(/<a[^>]+>[^<]+<\/a>/gi);
    if (!_.isNil(linkTags)) {
        linkTags.forEach(item => {
            const href = item.match(/href="(.*?).pdf"/);
            if (href) {
                result = result.replace(href[1], GOOGLE_DOCS_URL + href[1]);
            }
        });
    }
    return result;
}

export const replacePDFUrl = url => (_.endsWith(url, PDF_FORMAT) ? GOOGLE_DOCS_URL + url : url);

export const roundedPoints = unitPoints => _.round(unitPoints, unitPoints <= 0.99 ? 3 : 1);

export function stringExpressionToValue(expression) {
    const config = { };
    const math = create(all, config);
    return math.evaluate(expression);
}

export const getItemsForSelect = (array, labelField, idField) => _.map(array, item => ({ ...item, label: item[labelField], id: item[idField] }));

export const convertToLocal = (number, lang) => number && number.toLocaleString(lang.replace('_', '-'));
export const convertLangString = lang => lang.replace('_', '-');
export const fixAmpersand = string => _.replace(string, '&amp;', '&');
