import _ from 'lodash';
import moment from 'moment';

export * from './services/validationHelper';

export const getStep = screens => _.get(screens, 'tasks.0.task_slug');
export const getSlug = screens => _.get(screens, 'group_slug');
export function getDwellTime(start) {
    return `${moment().diff(start, 'minutes')} min ${moment().diff(start, 'seconds') % 60} sec`;
}

export const splitString = (title, len) => {
    const splitted = _.split(title, ' ');
    const firstRow = _.join(_.slice(splitted, 0, len), ' ');
    const secondRow = _.join(_.slice(splitted, len), ' ');
    return { firstRow, secondRow };
};
