import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { appFonts, baseColors, spacing } from '../../../../../styles';
import { translate, constants as coreConstants, DATE_FORMATS } from '../../../../core';
import * as rewardsSelectors from '../../../selectors';
import * as actions from '../../../actions';
import { ACHIEVEMENTS_STATUSES } from '../../../constants';

export default function WithAchievementDetailsBase(WrappedComponent) {
    class AchievementDetailsBase extends PureComponent {
        static propTypes = {
            achievementId: PropTypes.number.isRequired,
            achievement: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            isLoading: PropTypes.bool.isRequired,
            i18n: PropTypes.object.isRequired,
            progressPercentages: PropTypes.number,
            levelPercentage: PropTypes.number,
            isCurrentLevel: PropTypes.bool,
            isLevelCompleted: PropTypes.bool.isRequired
        };

        static defaultProps = {
            progressPercentages: 0,
            levelPercentage: 0,
            isCurrentLevel: false
        };

        componentDidMount() {
            if (!this.props.isLoading && !this.props.achievement) {
                this.props.actions.getAchievements();
            }
        }

        formatDate(date) {
            return date && moment(date).format(coreConstants.DATE_FORMATS.monthFullDayYearFull);
        }

        get rewardText() {
            const externalRewardPoints = _.get(this.props.achievement, 'externalRewardPoints');
            const externalRewardName = _.get(this.props.achievement, 'externalRewardName');
            return externalRewardPoints && externalRewardPoints > 0 ? `+ ${externalRewardPoints} ${externalRewardName}`: '';
        }

        get defaultRewardsData() {
            return [{
                name: _.capitalize(this.props.i18n.t('reward')),
                value: this.rewardText
            }];
        }

        get rewardsDataForNonEarned() {
            const achievementStartDate = _.get(this.props.achievement, 'achievementStartDate');
            const achievementEndDate = _.get(this.props.achievement, 'achievementEndDate');
            return [{
                name: this.props.i18n.t('achievement_start'),
                value: this.formatDate(achievementStartDate)
            }, {
                name: this.props.i18n.t('achievement_end'),
                value: this.formatDate(achievementEndDate)
            }];
        }

        get rewardsDataForEarned() {
            const numAwardsEarned = _.get(this.props.achievement, 'numAwardsEarned');
            const dateEarned = _.get(this.props.achievement, 'dateEarned');
            const totalEarned = _.get(this.props.achievement, 'rewardPointsEarned');
            return [{
                name: this.props.i18n.t('achievement_earned'),
                value: this.props.i18n.t(numAwardsEarned === 1 ? 'achievement_earned_time' : 'achievement_earned_times',
                    { num: numAwardsEarned })
            }, {
                name: this.props.i18n.t('date_earned'),
                value: this.formatDate(dateEarned)
            }, {
                name: this.props.i18n.t('TotalEarned'),
                value: totalEarned
            }];
        }

        get rewardData() {
            const badgeStatus = _.get(this.props.achievement, 'badgeStatus');
            return badgeStatus !== ACHIEVEMENTS_STATUSES.earned
                ? [...this.defaultRewardsData, ...this.rewardsDataForNonEarned]
                : [...this.defaultRewardsData, ...this.rewardsDataForEarned];
        }

        get progressPercentages() {
            const percentageCompletion = _.get(this.props.achievement, 'percentageCompletion');
            return percentageCompletion ? percentageCompletion * 100 : 0;
        }

        get datesAwarded() {
            const awarded = _.get(this.props.achievement, 'datesAwarded', []);
            return _.filter(awarded, d => d.awarded && d.end_date !== this.todayDate);
        }

        get isRewardDataLengthOdd() {
            return this.rewardData.length % 2;
        }

        get todayDate() {
            return moment(new Date()).format(DATE_FORMATS.full);
        }

        get itemTypePercentage() {
            const { isCurrentLevel, levelPercentage, isLevelCompleted } = this.props;
            if (levelPercentage) {
                return isCurrentLevel || isLevelCompleted ? levelPercentage : 0;
            }
            return this.progressPercentages;
        }

        get instruction() {
            const { i18n, achievement: { achievementEndDate, badgeInstruction, frequency } } = this.props;
            if (!frequency || !achievementEndDate || frequency === 'none') return badgeInstruction;
            return `${i18n.t('badgeInstruction', { badgeInstruction })} `;
        }

        get endDate() {
            const { achievement: { frequency, achievementEndDate } } = this.props;
            if (frequency && achievementEndDate) return moment(achievementEndDate).format(DATE_FORMATS.monthFullDayYearFull);
            return null;
        }

        get awardedDatesInfo() {
            const { i18n, achievement: { frequency } } = this.props;
            return {
                title: i18n.t('past_dates_earned_heading'),
                subtitle: i18n.t('youCanEarnThisBadge', { period: frequency })
            };
        }

        get isEarned() {
            const { achievement: { dateEarned } } = this.props;
            return moment(dateEarned)._isValid;
        }

        get showAwardsSection() {
            const { i18n, achievement: { frequency } } = this.props;
            return frequency && frequency !== i18n.t('frequencyNone');
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    endDate={this.endDate}
                    isEarned={this.isEarned}
                    rewardText={this.rewardText}
                    formatDate={this.formatDate}
                    rewardData={this.rewardData}
                    instruction={this.instruction}
                    datesAwarded={this.datesAwarded}
                    awardedDatesInfo={this.awardedDatesInfo}
                    showAwardsSection={this.showAwardsSection}
                    itemTypePercentage={this.itemTypePercentage}
                    progressPercentages={this.progressPercentages}
                    isRewardDataLengthOdd={this.isRewardDataLengthOdd}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const achievementId = _.get(ownProps, 'achievementId')
            || _.get(ownProps, 'match.params.achievementId')
            || _.get(ownProps, 'route.params.achievementId');
        const isUpcoming = _.get(ownProps, 'isUpcoming')
            || _.get(ownProps, 'location.state.isUpcoming')
            || _.get(ownProps, 'route.params.isUpcoming');
        const achievement = isUpcoming
            ? rewardsSelectors.getUpcomingAchievement(state, achievementId)
            : rewardsSelectors.getAchievement(state, achievementId);
        const isLoading = rewardsSelectors.isLoadingAchievements(state);
        const levelLocation = _.get(ownProps, 'location.state');
        const routeParams = _.get(ownProps, 'route.params');
        return { achievement, isLoading, ...(levelLocation || {}), ...(routeParams || {}) };
    };

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(AchievementDetailsBase));
}

const IMAGE_SIZE = 80;
const { fontFamily: TITLE_FONT_FAMILY, fontSize: TITLE_FONT_SIZE } = appFonts.xlBold;

export const styles = {
    mainContainer: {
        backgroundColor: baseColors.white
    },
    mainView: {
        flex: 1,
        marginTop: spacing.s5,
        marginLeft: spacing.s3,
        marginRight: spacing.s3
    },
    image: {
        height: IMAGE_SIZE,
        width: 'auto',
        marginBottom: spacing.s3
    },
    titleContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    title: {
        fontFamily: TITLE_FONT_FAMILY,
        fontSize: TITLE_FONT_SIZE
    },
    iconCheckContainer: {
        marginLeft: spacing.s1
    },
    iconCoinsContainer: {
        marginRight: spacing.s1
    },
    section: {
        alignItems: 'center'
    },
    centerText: {
        textAlign: 'center',
        ...appFonts.smRegular,
        marginTop: spacing.s0,
        marginBottom: spacing.s0
    },
    noMarginLeft: {
        marginLeft: 0
    },
    metricsContainer: {
        marginTop: spacing.s5,
        marginBottom: spacing.s1,
        marginLeft: spacing.s6,
        marginRight: spacing.s6,
        flexWrap: 'wrap',
        flexDirection: 'row'
    },
    metricsItem: {
        width: '50%',
        marginBottom: spacing.s3,
        justifyContent: 'center',
        alignItems: 'center'
    },
    centeredFirstItem: {
        width: '100%',
        alignItems: 'center'
    },
    progressBar: {
        width: '100%',
        marginTop: spacing.s7
    },
    itemTitle: {
        ...appFonts.xsRegular,
        color: baseColors.grey40,
        textAlign: 'center'
    },
    description: {
        marginBottom: 0,
        marginTop: spacing.s5
    },
    awardedDatesHeader: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: baseColors.grey85,
        flex: 1,
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3,
        paddingTop: spacing.s2,
        paddingBottom: spacing.s2
    },
    awardedDatesHeaderTitle: {
        ...appFonts.mdBold,
        color: baseColors.grey20,
        marginBottom: spacing.s0
    },
    awardedDatesHeaderSubtitle: {
        ...appFonts.mdRegular,
        color: baseColors.grey40
    },
    awardedDatesList: {
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3,
        borderTopWidth: 0,
        marginTop: 0,
        marginBottom: spacing.s3
    },
    separator: {
        height: 1,
        backgroundColor: baseColors.grey70
    },
    awardedDatesListItemTitle: {
        ...appFonts.mdMedium
    },
    bonusMarkContainer: {
        marginTop: spacing.s3
    },
    percentageText: {
        ...appFonts.smBold,
        color: baseColors.grey40
    },
    awardDatesContainer: {
        marginTop: spacing.s7
    },
    instructionContainer: {
        marginTop: spacing.s3,
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        textAlign: 'center'
    },
    endDateText: {
        flexWrap: 'nowrap',
        ...appFonts.lgRegular,
        color: baseColors.grey20,
        textAlign: 'center',
    },
    badgeInstruction: {
        ...appFonts.lgRegular,
        color: baseColors.grey20,
        textAlign: 'center'
    },
    bonusText: {
        color: baseColors.warnDarkest
    }
};
