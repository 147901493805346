import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { css, StyleSheet } from 'aphrodite-jss';

import poweredBySproutLogo from '../../../../image/poweredBySproutLogo.svg';
import { components as Core, translate, selectors as coreSelectors } from '../../../core';
import { baseColors, spacing, layoutStyle, importantClass, important, media } from '../../../../styles';
import Logo from '../Logo';
import * as selectors from '../../selectors';

class AuthWrapperWeb extends PureComponent {
    static propTypes = {
        isPartnerDomain: PropTypes.bool,
        brand: PropTypes.object,
        children: PropTypes.node.isRequired
    };

    static defaultProps = {
        isPartnerDomain: false,
        brand: null
    };

    render() {
        const { isPartnerDomain, brand, children, isTelus } = this.props;
        return (
            <Core.Layout.Page className={css(styles.pageContainer)}>
                <Core.Layout.Content className={css(styles.commonContainer, brand ? styles.mainBrandContainer : styles.mainContainer)}>
                    <div className={css(styles.innerContainer)}>
                        <div className={css(styles.childrenContainer)}>
                            <Logo
                                className={classnames(css(styles.welcomeLogo), css(layoutStyle.flexCenter))}
                                isPrimary={!isTelus}
                                isSaved={isTelus ? true : false}
                                isWebWelcome={true}
                                isAuth={true}
                            />
                            {children}
                        </div>
                        {isPartnerDomain ?
                            <Core.Image resizeMode='contain' src={poweredBySproutLogo} className={css(styles.poweredImage)} /> : null
                        }
                    </div>
                </Core.Layout.Content>
            </Core.Layout.Page>
        );
    }
}

const mapStateToProps = state => ({
    isPartnerDomain: selectors.isPartnerDomain(state),
    brand: selectors.getCompanyBrand(state),
    isTelus: coreSelectors.isTelus(state)
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(translate()(AuthWrapperWeb));

const WEB_LOGO_HEIGHT = 48;

const styles = StyleSheet.create({
    commonContainer: {
        paddingBottom: spacing.s9,
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3,
        flex: important('none'),
    },
    mainBrandContainer: {
        backgroundColor: baseColors.grey90,
    },
    mainContainer: {
        backgroundColor: baseColors.grey90
    },
    innerContainer: {
        maxWidth: 600,
        margin: '0 auto',
    },
    childrenContainer: {
        backgroundColor: baseColors.white,
        borderRadius: spacing.s1,
        marginTop: spacing.s19,
        marginBottom: spacing.s6,
        padding: `${spacing.s11}px 80px`,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.05)',
        [media.xs]: {
            padding: `${spacing.s4}px ${spacing.s7}px`
        }
    },
    poweredImage: importantClass({
        marginTop: spacing.s3,
        height: 50,
        width: 'auto',
        margin: 'auto'
    }),
    pageContainer: {
        paddingBottom: important(0),
        backgroundColor: baseColors.grey90,
    },
    welcomeLogo: {
        height: `${WEB_LOGO_HEIGHT}px !important`,
        marginTop: 0,
        marginBottom: spacing.s3
    }
});