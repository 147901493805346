import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { translate, timeout } from '../../../core';
import { appFonts, spacing, baseColors } from '../../../../styles';
import * as actions from '../../actions';
import { getGlobalSettings, getCompanyError, isLoadingCompany, isPin } from '../../selectors';

export const ANIMATION_DURATION = 1200; // 1.2 sec
const WAIT_BEFORE_NEXT_PAGE = 500; // 0.5 sec

export default function WithCorporatePinBase(WrappedComponent) {
    class CorporatePinBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            numOfDigits: PropTypes.number,
            callback: PropTypes.func.isRequired,
            error: PropTypes.object,
            isLoading: PropTypes.bool,
            i18n: PropTypes.object.isRequired,
            step: PropTypes.string.isRequired,
            setTimeout: PropTypes.func.isRequired
        };

        static defaultProps = {
            numOfDigits: 4,
            error: undefined,
            isLoading: false
        };

        constructor(props) {
            super(props);
            this.state = { pin: '', showCheck: false };
        }

        static getDerivedStateFromProps(nextProps, prevState) {
            const state = { isLoading: nextProps.isLoading };
            if (prevState && prevState.isLoading && !nextProps.isLoading && nextProps.isPin && !nextProps.error) {
                state.showCheck = true;
            }
            return state;
        }

        componentDidUpdate(prevProps) {
            if (prevProps.isLoading && !this.props.isLoading && this.props.error) {
                this.wrapped.clearText();
            }
        }

        componentDidMount() {
            if (this.props.error && this.state.pin.length === 0) {
                this._clearError();
            }
        }

        _clearError = () => {
            if (this.props.error) this.props.actions.clearCompanyConfigurationError();
        };

        pinReturned = pin => {
            if (_.isNaN(pin)) return;
            if (pin.toString().length === this.props.numOfDigits) {
                this._submit(pin);
            }
            if (pin.toString().length !== 0) {
                this._clearError();
            }
        };

        _submit = (pin=null) => {
            this.setState({ pin });
            this.props.actions.getProgramConfigurations({ pin });
        };

        _go() {
            this.props.callback(this.props.step);
        }

        _closeLoading() {
            this.setState({ showCheck: false });
        }

        onAnimationEnd = () => {
            this.props.setTimeout(() => {
                this._closeLoading();
                this._go();
            }, WAIT_BEFORE_NEXT_PAGE);
        };

        saveRef = ref => (this.wrapped = ref);

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    ref={this.saveRef}
                    onAnimationEnd={this.onAnimationEnd}
                    showCheck={this.state.showCheck}
                    pin={this.state.pin}
                    pinReturned={this.pinReturned}
                />
            );
        }
    }

    function mapStateToProps(state) {
        return {
            numOfDigits: _.get(getGlobalSettings(state), 'pin.numbers'),
            error: getCompanyError(state),
            isLoading: isLoadingCompany(state),
            isPin: isPin(state)
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(timeout(CorporatePinBase)));
}

export const styles = {
    question: {
        marginBottom: spacing.s9,
        alignItems: 'center'
    },
    errorMessage: {
        ...appFonts.xsRegular,
        marginTop: spacing.s0,
        textAlign: 'center'
    },
    inputValidationError: {
        color: baseColors.dangerDarker,
        marginHorizontal: spacing.s0,
        ...appFonts.xsRegular
    },
    loadingContainer: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    text: { ...appFonts.mdMedium }
};
