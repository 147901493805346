import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { appFonts, baseColors, spacing } from '../../../../styles';
import { translate, selectors as coreSelectors } from '../../../core';
import * as rewardsActions from '../../actions';
import * as rewardsSelectors from '../../selectors';
import { selectors as authSelectors } from '../../../auth';
import { SHIPPING_FIELDS } from '../../constants';

export default function WithOrderShippingAddressBase(WrappedComponent) {
    class OrderShippingAddressBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            rewards: PropTypes.object,
            product: PropTypes.object,
            user: PropTypes.object,
            userSSOData: PropTypes.object,
            token: PropTypes.string,
            i18n: PropTypes.object.isRequired,
            shippingAddress: PropTypes.object,
            isLoading: PropTypes.bool,
            productCountry: PropTypes.object,
            productRegions: PropTypes.array
        };

        static defaultProps = {
            rewards: {},
            product: {},
            user: {
                firstNameDisplay: '',
                lastNameDisplay: ''
            },
            userSSOData: {
                firstName: '',
                lastName: ''
            },
            token: '',
            shippingAddress: {},
            isLoading: false,
            productCountry: {},
            productRegions: []
        };

        constructor(props) {
            super(props);
            this.props.actions.gettingShippingAddress();

            this.state = {
                address: this.defaultShippingAddress,
                errors: {}
            };

            const { i18n } = props;
            this.fields = [
                {
                    id: SHIPPING_FIELDS.FIRST_NAME,
                    name: i18n.t('registration_field_firstName'),
                    isRequired: true,
                    nextId: SHIPPING_FIELDS.LAST_NAME
                },
                {
                    id: SHIPPING_FIELDS.LAST_NAME,
                    name: i18n.t('registration_field_lastName'),
                    isRequired: true,
                    nextId: SHIPPING_FIELDS.ADDRESS1
                },
                {
                    id: SHIPPING_FIELDS.ADDRESS1,
                    name: i18n.t('streetLine', { num: 1 }),
                    isRequired: true,
                    nextId: SHIPPING_FIELDS.ADDRESS2
                },
                {
                    id: SHIPPING_FIELDS.ADDRESS2,
                    name: i18n.t('streetLine', { num: 2 }),
                    isRequired: false,
                    nextId: SHIPPING_FIELDS.CITY
                },
                {
                    id: SHIPPING_FIELDS.CITY,
                    name: i18n.t('cityName'),
                    isRequired: true
                },
                {
                    id: SHIPPING_FIELDS.REGION,
                    name: i18n.t('stateProvince'),
                    isRequired: true
                },
                {
                    id: SHIPPING_FIELDS.COUNTRY,
                    name: i18n.t('countryName'),
                    isRequired: true
                },
                {
                    id: SHIPPING_FIELDS.POSTAL_CODE,
                    name: i18n.t('postcodePostalcodeZip'),
                    isRequired: true,
                    nextId: SHIPPING_FIELDS.PHONE
                },
                {
                    id: SHIPPING_FIELDS.PHONE,
                    name: i18n.t('phoneNumber'),
                    isRequired: true
                }
            ];
        }

        UNSAFE_componentWillReceiveProps(nextProps) {
            if (this.props.isLoading && !nextProps.isLoading) {
                this.setState(prevState => ({
                    address: {
                        ...prevState.address,
                        ...this.getShippingAddress(nextProps.shippingAddress)
                    }
                }));
            }
        }

        get defaultShippingAddress() {
            const firstName = (this.props.userSSOData.firstName) ? this.props.userSSOData.firstName : this.props.user.firstNameDisplay;
            const lastName = (this.props.userSSOData.lastName) ? this.props.userSSOData.lastName : this.props.user.lastNameDisplay;
            const contactName = `${firstName} ${lastName}`;
            const defaultAddress = {};
            _.forEach(SHIPPING_FIELDS, field => { defaultAddress[field] = ''; });
            return { ...defaultAddress, ...this.getShippingAddress(), [SHIPPING_FIELDS.CONTACT_NAME]: contactName };
        }

        getShippingAddress = (shippingAddress = this.props.shippingAddress) => {
            const newAddress = {};
            _.forEach(shippingAddress, (value, key) => {
                if (key === SHIPPING_FIELDS.CONTACT_NAME && !value) return;
                if (key === SHIPPING_FIELDS.COUNTRY) {
                    newAddress.country = value;
                    const country = this.props.productCountry;
                    newAddress.displayCountry = country.id === value ? country.label : '';
                    if (!newAddress.displayCountry) newAddress.country = '';
                } else if (key === SHIPPING_FIELDS.REGION) {
                    newAddress.region = value;
                    if (value) {
                        const foundedRegion = _.find(this.props.productRegions, item => item.id === value);
                        newAddress.displayRegion = _.get(foundedRegion, 'label', '');
                    } else {
                        newAddress.displayRegion = '';
                    }
                    if (!newAddress.displayRegion) newAddress.region = '';
                } else {
                    newAddress[key] = value || '';
                }
            });
            return newAddress;
        };

        validateShippingAddress = () => this.props.actions.validateShippingAddress();

        onNext = callback => {
            const newErrorsState = {};
            _.forEach(this.fields, (item) => {
                if (item.isRequired && _.isEmpty(this.state.address[item.id])) {
                    newErrorsState[item.id] = this.props.i18n.t('edit_profile_unspecified_value');
                }
            });
            this.setState({ errors: newErrorsState }, callback);
        };

        get item() {
            return this.props.product;
        }

        get formattedAddress() {
            return this.state.address;
        }

        updateInputValue = prop => value => {
            this.setState(prevState => ({ address: { ...prevState.address, [prop]: value } }));
        };

        updateSelectValue = (fieldName, data, value) => {
            this.setState(prevState => {
                const displayField = `display${_.upperFirst(fieldName)}`;
                return {
                    address: { ...prevState.address,
                        [fieldName]: value.id,
                        [displayField]: value
                    }
                };
            });
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    item={this.item}
                    fields={this.fields}
                    address={this.state.address}
                    formattedAddress={this.formattedAddress}
                    errors={this.state.errors}
                    validateShippingAddress={this.validateShippingAddress}
                    updateInputValue={this.updateInputValue}
                    updateSelectValue={this.updateSelectValue}
                    onNext={this.onNext}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        const title = ownProps.title || _.get(ownProps, 'location.state.title') || _.get(ownProps, 'route.params.title');
        const token = ownProps.token || _.get(ownProps, 'location.state.token') || _.get(ownProps, 'route.params.token');
        const shippingAddress = _.get(ownProps, 'location.state.shippingAddress') || rewardsSelectors.getShippingAddress(state);
        const productId = ownProps.productId || _.get(ownProps, 'location.state.productId') || _.get(ownProps, 'route.params.productId');
        return {
            rewards: rewardsSelectors.getRewards(state),
            product: rewardsSelectors.getProduct(state, productId),
            productRegions: rewardsSelectors.getProductRegions(state, productId),
            productCountry: rewardsSelectors.getProductCountry(state, productId),
            user: coreSelectors.getCurrentUser(state),
            userSSOData: authSelectors.getSsoDetails(state),
            externalRewardName: rewardsSelectors.externalRewardName(state),
            shippingAddress,
            isLoading: rewardsSelectors.isLoadingShippingAddress(state),
            title,
            token
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(rewardsActions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(OrderShippingAddressBase));
}

export const styles = {
    innerContainer: {
        paddingBottom: spacing.s7
    },
    fadedText: {
        color: baseColors.grey40,
        ...appFonts.xsRegular
    },
    containerStyle: {
        paddingBottom: spacing.s0
    },
    selectField: {
        marginBottom: spacing.s1
    }
};