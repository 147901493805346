import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { appFonts, spacing, baseColors, containerStyle, layoutStyle } from '../../../../styles';
import { components as Core, Modal, onKeyPress, CSS_CLASSES } from '../../../core';
import WithRegisterBase, { INPUT_MAX_LENGTH, styles as baseStyles } from './RegisterBase';
import { POLICY_FIELD_NAME, EXTERNAL_SERVICES_NO_MODAL } from '../../constants';
import Logo from '../Logo';

const MODAL_TIMEOUT = 1000;

const _fontSize = appFonts.mdRegular;

class Register extends PureComponent {
    static propTypes = {
        registrationFields: PropTypes.array.isRequired,
        isLoading: PropTypes.bool,
        i18n: PropTypes.object.isRequired,
        renderField: PropTypes.func.isRequired,
        fieldValue: PropTypes.func.isRequired,
        isFromSso: PropTypes.func.isRequired,
        getFieldError: PropTypes.func.isRequired,
        onChangeBool: PropTypes.func.isRequired,
        onChangeText: PropTypes.func.isRequired,
        termsOfService: PropTypes.string,
        privacyPolicy: PropTypes.string,
        onFormSubmit: PropTypes.func.isRequired,
        isFieldDisabled: PropTypes.func.isRequired,
        isSSO: PropTypes.bool,
        company: PropTypes.object.isRequired,
        hasPartnerSsoSignUpModal: PropTypes.bool,
        sso: PropTypes.object,
        getInfoModalProps: PropTypes.func.isRequired,
        showInfoModal: PropTypes.bool
    };

    static defaultProps = {
        isLoading: false,
        termsOfService: undefined,
        privacyPolicy: undefined,
        isSSO: false,
        hasPartnerSsoSignUpModal: false,
        sso: null,
        showInfoModal: true
    };

    constructor(props) {
        super(props);
        if (props.showInfoModal) {
            setTimeout(() => this.showInfoModal(), MODAL_TIMEOUT);
        }
    }

    onFormSubmit = event => {
        event.preventDefault();
        this.props.onFormSubmit();
    };

    onCloseSsoModal = () => this.closeSsoModal && this.closeSsoModal();

    openTerms = () => {
        if (this.props.termsOfService.content) {
            this.openModal(this.props.termsOfService.content);
        } else if (this.props.termsOfService) {
            this.openModal(this.props.termsOfService);
        }
    }

    openPrivacy = () => this.props.privacyPolicy && this.openModal(this.props.privacyPolicy);

    openModal = content => {
        this.closeModal = Modal.open(
            () => this.renderPolicy(content),
            {},
            { isContainer: true, isFullHeight: true }
        );
    };

    closeModal = () => this.closeModal && this.closeModal();

    showInfoModal = () => {
        const { hasPartnerSsoSignUpModal, sso, getInfoModalProps } = this.props;

        if (!hasPartnerSsoSignUpModal || !_.includes(EXTERNAL_SERVICES_NO_MODAL, sso.ssotype)) {
            return;
        }

        this.closeSsoModal = Modal.open(
            Core.InfoModal,
            getInfoModalProps(this.onCloseSsoModal),
            { isContainer: true, isTransparent: true, isNoPadding: true, fadeTransition: true }
        );
    };

    renderPolicy(content) {
        return (
            <div className={css(styles.modalContainer)}>
                <div className={css(styles.webViewContainer)}>
                    {/* eslint-disable-next-line react/no-danger */}
                    <div dangerouslySetInnerHTML={this.createMarkup(content)} className={CSS_CLASSES.disclaimerText} />
                </div>
                <div className={css(styles.footer)}>
                    <div className={css(containerStyle.largeFooterButtonContainer)}>
                        <Core.Button
                            fullWidth={true}
                            className={css(containerStyle.buttonText)}
                            onPress={this.closeModal}>
                            {this.props.i18n.t('back')}
                        </Core.Button>
                    </div>
                </div>
            </div>
        );
    }

    createMarkup = content => ({ __html: `<div style="font-size: ${_fontSize}px; font-family: “Helvetica Now Text SA", "Helvetica Neue", Helvetica, Arial, sans-serif;">${content}</div>` });

    onChangeText = prop => event => this.props.onChangeText(prop)(event.target.value);

    renderField = field => this.props.renderField(field, this.renderInput, this.renderSwitch);

    renderSwitch = field => (
        <div className={css(styles.inputContainer)} key={field.name}>
            <div className={css(styles.textWithSwitchContainer)}>
                <p className={css(styles.promoText)}>
                    {this.props.i18n.t(field.name)}
                </p>
                <Core.Switch
                    onChange={this.props.onChangeBool(field.name)}
                    value={this.props.fieldValue(field.name)}
                    disabled={field.readonly || this.props.isFromSso(field.name)}
                />
            </div>
        </div>
    );

    renderInput = (field, customType) => (
        <div className={css(styles.inputContainer)} key={field.name}>
            <Core.Input
                customType={customType}
                fullWidth={true}
                id={field.name}
                label={this.props.i18n.t(field.name)}
                value={this.props.fieldValue(field.name, field.forcedEditable) || ''}
                onChange={this.onChangeText(field.name)}
                helperText={this.props.getFieldError(field.name)}
                error={!!this.props.getFieldError(field.name)}
                inputProps={{ maxLength: INPUT_MAX_LENGTH }}
                disabled={this.props.isFieldDisabled(field)}
                required={true}
            />
        </div>
    );

    render() {
        const { i18n, company } = this.props;
        return (
            <div className={css(layoutStyle.fw)}>
                <div className={css(containerStyle.container)}>
                    <div className={css(layoutStyle.flexCenter)}>
                        {company.enteredProgramPin ?
                            <div className={css(containerStyle.header)}>
                                <Logo />
                            </div>
                            : null}
                    </div>
                    <div className={css(containerStyle.main)}>
                        {_.map(this.props.registrationFields, this.renderField)}
                        <div className={css(styles.inputContainer, styles.inputContainerMargin)}>
                            <div className={css(styles.textWithSwitchContainer, styles.textWithSwitchContainerPadding)}>
                                <p className={css(styles.policyTextWrapper)}>
                                    <span className={css(styles.policyText)}>
                                        {i18n.t('termsOfUsePrivacyPreLinks')}
                                    </span>
                                    <span
                                        role="button"
                                        tabIndex="0"
                                        onKeyDown={onKeyPress.enter(this.openTerms)}
                                        className={css(styles.linkPolicyText)}
                                        onClick={this.openTerms}>
                                        {' '}{i18n.t('termsOfService')}{' '}
                                    </span>
                                    <span className={css(styles.policyText)}>
                                        {i18n.t('and')}
                                    </span>
                                    <span
                                        role="button"
                                        tabIndex="0"
                                        onKeyDown={onKeyPress.enter(this.openPrivacy)}
                                        className={css(styles.linkPolicyText)}
                                        onClick={this.openPrivacy}>
                                        {' '}{i18n.t('privacyPolicy')}
                                    </span>
                                </p>
                                <Core.Switch
                                    onChange={this.props.onChangeBool(POLICY_FIELD_NAME)}
                                    value={this.props[POLICY_FIELD_NAME]}
                                />
                            </div>
                        </div>
                        <p className={css(styles.policyError)}>
                            {this.props.getFieldError(POLICY_FIELD_NAME)}
                        </p>
                    </div>
                    <div className={css(containerStyle.footerButtonContainer)}>
                        <Core.Button
                            isSubmit={true}
                            fullWidth={true}
                            disabled={this.props.isLoading}
                            onPress={this.onFormSubmit}
                            isLoading={this.props.isLoading}>
                            {i18n.t('next')}
                        </Core.Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(WithRegisterBase(Register));

const styles = StyleSheet.create({
    ...baseStyles,
    webViewContainer: {
        ...baseStyles.webViewContainer,
        overflow: 'auto'
    },
    modalContainer: {
        ...baseStyles.modalContainer,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',

    },
    textWithSwitchContainer: {
        ...baseStyles.textWithSwitchContainer,
        display: 'flex'
    },
    inputContainer: {
        borderBottomWidth: 0
    },
    inputContainerMargin: {
        marginTop: spacing.s2
    },
    footer: {
        borderColor: baseColors.grey70,
        borderTopWidth: 1,
        paddingHorizontal: 16
    },
    policyError: {
        ...appFonts.xsRegular,
        marginBottom: spacing.s2,
        color: baseColors.dangerDarker,
        textAlign: 'left',
        paddingLeft: spacing.s3
    },
});
