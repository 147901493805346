const needUseSameWindow = (url, isReplace) => {
    isReplace ? window.location.replace(url) : (window.location.href = url);
};

const needUseNewWindow = url => {
    const newWnd = window.open(url, '_blank');
    newWnd.opener = null; // for security, use in href for external urls => rel="noopener noreferrer"
};

const openUrl = (url, isSamePage, isReplace) => {
    isSamePage ? needUseSameWindow(url, isReplace) : needUseNewWindow(url);
};

export default openUrl;