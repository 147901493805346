import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { translate, numbers, parsers, selectors as coreSelectors } from '../../../../core';
import { appFonts, baseColors, elements, spacing } from '../../../../../styles';
import { isRestartGoal, getChallenge, getIsTeamChallenge } from '../../../selectors';
import {
    isUnstarted,
    isAfterDeadline,
    daysUntil,
    dateRange,
    isGoal,
    daysDuration,
    isFeatured,
    isTeamChallenge
} from '../../../services/helper';
import { FREQUENCY, CHALLENGE_CARD_HEADER_ITEMS_SIZES as SIZES } from '../../../constants';
import FeaturedTag from '../../../../core/components/FeaturedTag/FeaturedTag';

const CURRENT_AND_LAST_DAYS = 2;
const DAYS_LEFT_TO_END = 3;

export default function withHeaderBase(WrappedComponent) {
    class HeaderBase extends PureComponent {
        getNumberOfLines = isMultiline => isMultiline ? 2 : 1;

        constructor(props) {
            super(props);
            this.state = {
                showDateRange: true,
                useDangerColor: false,
            };
        }

        get challengeDuration() {
            const { challenge: { frequency, totalPeriods, startDate, challengeDeadline }, i18n } = this.props;
            const isToday = moment(startDate).isSame(moment(), 'day');
            const dateIncludesToday = moment(startDate).add(1, 'days');
            const diffDays = isToday || (daysUntil(dateIncludesToday) === 0) ? 1 : daysUntil(dateIncludesToday);
            const daysTotal = daysDuration(startDate, challengeDeadline);
            const daysCount = diffDays === 1 ? `${diffDays} ${i18n.t('day')}` : `${diffDays} ${i18n.t('days')}`;
            const challengeDurationData = durationCount => (
                {
                    startsIn: daysCount.toLowerCase(),
                    durationCount: durationCount.toLowerCase()
                }
            );

            switch (true) {
                case frequency === FREQUENCY.monthly && totalPeriods === 1:
                    return challengeDurationData(`${totalPeriods} ${i18n.t('month')}`);
                case frequency === FREQUENCY.monthly && totalPeriods > 1:
                    return challengeDurationData(`${totalPeriods} ${i18n.t('months')}`);
                case frequency === FREQUENCY.weekly && totalPeriods === 1:
                    return challengeDurationData(`${totalPeriods} ${i18n.t('week')}`);
                case frequency === FREQUENCY.weekly && totalPeriods > 1:
                    return challengeDurationData(`${totalPeriods} ${i18n.t('weeks')}`);
                case frequency === FREQUENCY.daily && totalPeriods === 1:
                    return challengeDurationData(`${totalPeriods} ${i18n.t('day')}`);
                case frequency === FREQUENCY.daily && totalPeriods > 1:
                    return challengeDurationData(`${totalPeriods} ${i18n.t('days')}`);
                default:
                    return challengeDurationData(`${daysTotal} ${i18n.t(daysTotal === 1 ? 'day' : 'days')}`);
            }
        }

        get dateInfoDetails() {
            const { challenge, i18n, isRestartGoal } = this.props;
            if (isRestartGoal) return null;
            const isToday = moment(challenge.challengeDeadline).isSame(moment(), 'day');
            const daysToEnd = daysUntil(challenge.challengeDeadline);
            const daysLeft = daysToEnd >= 0 ? daysToEnd + CURRENT_AND_LAST_DAYS : daysToEnd;
            const daysUntilEnd = isToday ? 1 : daysLeft;

            if (isUnstarted(challenge)) {
                return this.daysUntilString;
            } else {
                this.setState({
                    showDateRange: daysUntilEnd > 0,
                    useDangerColor: daysUntilEnd <= DAYS_LEFT_TO_END && daysUntilEnd > 0,
                });
                return daysUntilEnd > 0
                    ? `${daysUntilEnd} ${i18n.t(daysUntilEnd === 1 ? 'deadlineDayLeft' : 'deadlineDaysLeft')}`
                    : `${_.capitalize(i18n.t('endedOn'))} ${this.challengeEndedDate}`;
            }
        }

        get challengeDateInfo() {
            const { challenge, isRestartGoal, isEnded } = this.props;
            return {
                duration: this.state.showDateRange ? dateRange(challenge, isRestartGoal, isEnded) : null,
                details: this.dateInfoDetails,
            };
        }

        get endedDateText() {
            const { challenge, isRestartGoal, isEnded, i18n } = this.props;
            const end = dateRange(challenge, isRestartGoal, isEnded);
            return `${i18n.t('ended')} ${end}`;
        }


        get numMembersText() {
            const { challenge: { challengeEntityType, numChallengeParticipants }, i18n } = this.props;
            if (!numChallengeParticipants) {
                return null;
            }

            if (challengeEntityType === 'group') {
                if (numChallengeParticipants === 1) {
                    return i18n.t('one_team');
                }
                return i18n.t('number_teams', { num: numbers.numberWithCommas(numChallengeParticipants) });
            }
            if (numChallengeParticipants === 1) {
                return i18n.t('oneParticipant');
            }
            return i18n.t('numberOfParticipants', { num: numbers.numberWithCommas(numChallengeParticipants) });
        }

        get zeroParticipants() {
            const { i18n, challenge: { numChallengeParticipants } } = this.props;
            return numChallengeParticipants < 1 ? i18n.t('numberOfParticipants', { num: 0 }) : null;
        }

        get startsInTitle() {
            return this.props.i18n.t('starts_in');
        }

        get durationTitle() {
            return this.props.i18n.t('duration');
        }
        get renderFeaturedTag() {
            const { i18n, challenge } = this.props;
            const text = i18n.t('promoted');
            return <FeaturedTag showTag={isFeatured(challenge)} text={text} />;
        }

        get participantsString() {
            const { challenge, i18n, challenge: { numChallengeParticipants } } = this.props;
            const teamChallenge = isTeamChallenge(challenge);
            if (numChallengeParticipants === 1) {
                return teamChallenge ? i18n.t('one_team'): i18n.t('oneParticipant');
            } else {
                return teamChallenge ? i18n.t('number_teams', { num: numChallengeParticipants }) : i18n.t('numberOfParticipants', { num: numChallengeParticipants });
            }
        }

        get instructionString() {
            const { challenge: { challengeInstruction } } = this.props;

            const shortened = _.truncate(challengeInstruction, {
                length: 100,
                separator: ' ',
                omission: ''
            });

            return challengeInstruction.length > 100 ? `${shortened}...` : challengeInstruction;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    endedDateText={this.endedDateText}
                    startsInTitle={this.startsInTitle}
                    durationTitle={this.durationTitle}
                    numMembersText={this.numMembersText}
                    isGoal={isGoal(this.props.challenge)}
                    getNumberOfLines={this.getNumberOfLines}
                    zeroParticipants={this.zeroParticipants}
                    challengeDateInfo={this.challengeDateInfo}
                    challengeDuration={this.challengeDuration}
                    useDangerColor={this.state.useDangerColor}
                    featuredTag={this.renderFeaturedTag}
                    participantsString={this.participantsString}
                    instructionString={this.instructionString}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const challenge = getChallenge(state, ownProps.challengeId);
        const isTeamChallenge = getIsTeamChallenge(state, ownProps.challengeId);
        return {
            challenge,
            isTeamChallenge,
            isRestartGoal: isRestartGoal(state, ownProps.challengeId),
            isUnstarted: isUnstarted(ownProps.challenge || challenge),
            isEnded: isAfterDeadline(ownProps.challenge || challenge),
            language: parsers.convertLangString(coreSelectors.getCurrentUserLanguage(state))
        };
    };

    return connect(mapStateToProps, null)(translate()(HeaderBase));
}

const HEADER_GRAPHIC_RADIUS = 10;
const HEADER_TEXT_RIGHT_PADDING = 45;
const BRAND_LETTER_SPACING = 0.5;

export const styles = {
    header: {
        flexDirection: 'row',
        paddingTop: spacing.s3,
        display: 'flex',
        flex: 1,
        alignItems: 'center'
    },
    headerGraphic: {
        height: SIZES.GRAPHIC_SIZE,
        width: SIZES.GRAPHIC_SIZE,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: HEADER_GRAPHIC_RADIUS
    },
    title: {
        ...elements.header3,
        ...appFonts.mdBold,
        color: baseColors.black,
        padding: 0,
        textAlign: 'left'
    },
    instruction: {
        ...appFonts.mdRegular,
        color: baseColors.grey20,
        marginTop: spacing.s1
    },
    bonusMarkContainer: {
        marginTop: spacing.s1
    },
    imageContainer: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        width: '100%'
    },
    image: {
        width: '100%',
        borderTopRightRadius: spacing.s1,
        borderTopLeftRadius: spacing.s1,
        position: 'absolute',
        overflow: 'hidden'
    },
    dateInfoContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '70%',
        textAlign: 'center'
    },
    dateTitle: {
        ...appFonts.smBold,
        color: baseColors.white
    },
    dateInfo: {
        ...appFonts.xlBold,
        color: baseColors.white
    },
    goalDateTitle: {
        ...appFonts.smRegular,
        color: baseColors.grey40
    },
    goalDateInfo: {
        ...appFonts.xlRegular,
        color: baseColors.black
    },
    startsInContainer: {
        justifyContent: 'center',
        alignItems: 'center'
    },
    durationContainer: {
        justifyContent: 'center',
        alignItems: 'center'
    },
    brandContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
        position: 'absolute',
        left: spacing.s3,
        bottom: spacing.s1,
    },
    brandNaming: {
        ...appFonts.xsBold,
        color: baseColors.white,
        letterSpacing: BRAND_LETTER_SPACING
    },
    itemDetailsInfoContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        paddingTop: spacing.s3,
    },
    titleContainer: {
        width: '100%',
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: spacing.s0
    },
    membersContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap'
    },
    membersInfoText: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    dot: {
        marginLeft: spacing.s0,
        marginRight: spacing.s0,
    },
    flagIcon: {
        marginRight: spacing.s0,
    },
    membersAndDateText: {
        ...appFonts.smRegular,
        alignItems: 'baseline',
        color: baseColors.grey40,
        flexShrink: 0
    },
    detailsContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    dateInfoText: {
        ...appFonts.smRegular,
        color: baseColors.grey40,
    },
    brandIcon: {
        height: SIZES.SPROUT_BRAND_ICON_HEIGHT,
        width: SIZES.SPROUT_BRAND_ICON_WIDTH,
        marginRight: spacing.s1
    },
    goalTitleContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    goalIcon: {
        marginRight: spacing.s1,
        width: spacing.s7,
        height: spacing.s7
    },
    dangerColor: {
        color: baseColors.dangerDarker
    },
    zeroParticipantsText: {
        color: baseColors.grey40
    },
    headerTextArea: {
        flexDirection: 'column',
        justifyContent: 'center',
        display: 'flex',
        flex: 1
    },
    paddingRight45: {
        paddingRight: HEADER_TEXT_RIGHT_PADDING
    },
    unstartedGoalDurationSpacing: {
        marginTop: spacing.s0
    },
    goalInstructionSpacing: {
        marginTop: spacing.s3
    },
    featuredTag: {
        position: 'absolute',
        top: spacing.s1,
        left: spacing.s1,
        zIndex: 3
    },
    membersText: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: -spacing.s0
    },
};
