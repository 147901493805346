import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import * as actions from '../../actions';
import { PROGRESS_SQUARE_SIZE } from '../../constants';
import { appFonts, spacing, baseColors } from '../../../../styles';
import { pointsHelper, selectors as coreSelectors, translate } from '../../../core';

export const CHECK_ICON_SIZE = 80;
export const INFO_ICON_SIZE = 40;
export const ANIMATION_DURATION = 600;

export default function WithCongratsFirstTrackChallengeBase(WrappedComponent) {
    class CongratsFirstTrackChallengeBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            i18n: PropTypes.object.isRequired,
            pointsEarned: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            user: PropTypes.object.isRequired,
            customPointsUnit: PropTypes.string.isRequired,
        };

        constructor(props) {
            super(props);
            this.state = { screenIndex: 0 };
        }

        get labels() {
            const { i18n } = this.props;
            return {
                titleFist: i18n.t('congrats_tracking_first'),
                titleSecond: i18n.t('understanding_tracking'),
                subtitleFirst: i18n.t('keep_tracking'),
                youEarnedText: i18n.t('you_earned'),
                nextButtonLabel: i18n.t('next'),
                cannotTrackText: i18n.t('cannot_track_this_day'),
                nothingTrackedText: i18n.t('nothing_tracked_this_day'),
                trackedSomethingText: i18n.t('tracked_something_this_day'),
                reachedGoalText: i18n.t('reached_goal_this_day'),
                closeButtonLabel: i18n.t('got_it'),
            };
        }

        next = () => {
            this.setState(() => ({ screenIndex: 1 }));
        };

        gotIt = () => {
            this.props.actions.setFirstChallengeTrackedStatus(this.props.user.userId);
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    gotIt={this.gotIt}
                    next={this.next}
                    labels={this.labels}
                    screenIndex={this.state.screenIndex}
                    pointsEarned={pointsHelper.formatPoints(this.props.pointsEarned, this.props.customPointsUnit)}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const routeParams = _.get(ownProps, 'route.params');
        return {
            user: coreSelectors.getCurrentUser(state),
            customPointsUnit: coreSelectors.getCustomPointsUnit(state),
            ...(routeParams || {})
        };
    };

    const mapDispatchToProps = dispatch => ({
        actions: bindActionCreators(actions, dispatch)
    });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(CongratsFirstTrackChallengeBase));
}

const ANIMATED_HEIGHT_FINAL = 64;
const ANIMATED_BORDER_FINAL = 40;
const BUTTON_HEIGHT = 50;
export const SHADOW = { opacity: 0.3, height: 30 };

export const styles = {
    mainContainer: {
        backgroundColor: baseColors.white,
        borderRadius: spacing.s1
    },
    iconCircle: {
        backgroundColor: baseColors.primary,
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 100,
        top: 0,
        left: '50%',
        display: 'flex',
    },
    animatedView: {
        width: ANIMATED_HEIGHT_FINAL,
        height: ANIMATED_HEIGHT_FINAL,
        marginTop: -ANIMATED_HEIGHT_FINAL / 2,
        marginLeft: -ANIMATED_HEIGHT_FINAL / 2,
        borderRadius: ANIMATED_BORDER_FINAL
    },
    container: {
        flex: 1,
        backgroundColor: baseColors.white,
        borderRadius: spacing.s2
    },
    contentWrapper: {
        flex: 1,
        marginHorizontal: spacing.s3,
        marginTop: spacing.s11,
    },
    defaultSquare: {
        width: PROGRESS_SQUARE_SIZE,
        height: PROGRESS_SQUARE_SIZE,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: spacing.s10,
        borderWidth: 1,
        marginRight: spacing.s3,
        display: 'flex',
        borderStyle: 'solid'
    },
    squareContainer: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        display: 'flex',
        width: '100%',
        marginBottom: spacing.s5
    },
    titleText: {
        ...appFonts.lgMedium,
        textAlign: 'center',
        marginTop: spacing.s3,
        color: baseColors.black,
        marginBottom: spacing.s3,
        paddingRight: spacing.s3,
        paddingLeft: spacing.s3,
    },
    subtitleText: {
        ...appFonts.mdRegular,
        textAlign: 'center',
        color: baseColors.grey40
    },
    pointsText: {
        ...appFonts.xlBold,
        color: baseColors.secondary,
        textAlign: 'center'
    },
    descriptionText: {
        ...appFonts.mdRegular,
        color: baseColors.grey40
    },
    disabled: {
        backgroundColor: baseColors.white,
        borderColor: baseColors.grey70
    },
    trackable: {
        backgroundColor: baseColors.white,
        borderColor: baseColors.secondary
    },
    inProgress: {
        backgroundColor: baseColors.white,
        borderColor: baseColors.secondary
    },
    completed: {
        backgroundColor: baseColors.success,
        borderColor: baseColors.success
    },
    body: {
        paddingRight: spacing.s3,
        paddingLeft: spacing.s3,
    },
    pointsWrapper: {
        textAlign: 'center',
        marginTop: spacing.s7,
        marginBottom: spacing.s7,
    },
    button: {
        height: BUTTON_HEIGHT,
        backgroundColor: baseColors.white,
    },
    buttonText: {
        ...appFonts.mdRegular,
        color: baseColors.secondary,
    },
};
