import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import * as Card from '../../Card';
import WithChallengeCardHeaderBase from './ChallengeCardHeaderBase';
import ChallengeCardHeaderIcon from './ChallengeCardHeaderIcon';
import { isProgramGoal, isPersonal } from '../../../services/helper';

class ChallengeCardHeader extends PureComponent {
    static propTypes = {
        challenge: PropTypes.object.isRequired,
        headerIcon: PropTypes.object.isRequired,
        subtitle: PropTypes.string.isRequired,
        isChallengeSubtitle: PropTypes.bool.isRequired,
        instruction: PropTypes.string,
        isInstructionHidden: PropTypes.bool
    };

    static defaultProps = {
        instruction: '',
        isInstructionHidden: true
    };

    render() {
        const { challenge, headerIcon, subtitle, instruction, isChallengeSubtitle, isInstructionHidden, isFeaturedChallenge } = this.props;
        return (
            <Card.Header
                {...this.props}
                title={challenge.challengeName}
                subtitle={subtitle}
                instruction={instruction}
                multiLineInstruction={true}
                isProgramGoal={isProgramGoal(challenge)}
                icon={<ChallengeCardHeaderIcon icon={headerIcon} isPersonal={isPersonal(challenge)} />}
                challengeId={challenge.challengeId}
                isChallengeSubtitle={isChallengeSubtitle}
                isInstructionHidden={isInstructionHidden}
                challengeImage={challenge.challengeImageURL}
                isFeaturedChallenge={isFeaturedChallenge}
            />
        );
    }
}

export default withRouter(WithChallengeCardHeaderBase(ChallengeCardHeader));
