import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { withRouter } from 'react-router-dom';
import { components as Core, onKeyPress, ROUTES } from '../../../../core';
import WithWellbeingScorePopupBase from './WellbeingScorePopupBase';
import { appFonts, baseColors, spacing, layoutStyle, important } from '../../../../../styles';
import { SCORE_STATUSES } from '../../../constants';
import { takeHealthSurvey, openHealthSurveyInfo } from '../../../services';

export class RefHolder extends PureComponent {
    static OFFSET = null;
    render() { return null; }
}

const FIX_HEADERS_HEIGHT = 118;//48 + 70
const WELLBEING_MIN_VALUE = 50;
const WELLBEING_MIDDLE_VALUE = 61;

class WellbeingScorePopup extends PureComponent {
    static propTypes = {
        score: PropTypes.number.isRequired,
        scoreDescription: PropTypes.string.isRequired,
        updatedDate: PropTypes.string.isRequired,
        i18n: PropTypes.object.isRequired,
        openScorePopup: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired,
        status: PropTypes.string.isRequired,
        updatedPercentage: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        takeSurveyButton: PropTypes.string.isRequired,
        updateScoreButton: PropTypes.string.isRequired,
        actions: PropTypes.object.isRequired,
        showStatus: PropTypes.bool,
        isScorePopupOpen: PropTypes.bool
    };

    static defaultProps = {
        showStatus: false,
        isScorePopupOpen: false
    };

    get isTriangleSuccess() {
        return this.props.status === SCORE_STATUSES.dec.id;
    }

    openHealthSurveyInfo = () => {
        openHealthSurveyInfo();
    };

    takeHealthSurvey = e => {
        e.stopPropagation();
        e.preventDefault();
        takeHealthSurvey(this.props.i18n);
    };

    goToMeAction() {
        // depending on content;
        this.props.history.push(ROUTES.wellbeingScore());
    }

    get isMePage() {
        return this.props.history.location.pathname === ROUTES.me();
    }

    goToMe = () => {
        const offset = RefHolder.OFFSET - FIX_HEADERS_HEIGHT;
        this.props.actions.selectWellbeingScoreTab();
        if(!this.isMePage) {
            this.goToMeAction();
        } else if(window.pageYOffset !== offset || window.pageYOffset === 0) {
            window.scrollTo({ left: 0, top: offset, behavior: 'smooth' });
        }
        this.props.openScorePopup();
    }

    get scoreColor() {
        const { score } = this.props;

        if (score < WELLBEING_MIN_VALUE) {
            return { backgroundColor: baseColors.dangerLightest, color: baseColors.dangerDarkest };
        } else if (score >= WELLBEING_MIN_VALUE && score <= WELLBEING_MIDDLE_VALUE) {
            return { backgroundColor: baseColors.warnLightest, color: baseColors.warnDarkest };
        } else return { backgroundColor: baseColors.successLightest, color: baseColors.successDarkest };
    }

    render() {
        const { score, scoreDescription, updatedDate, updatedPercentage, status, title, takeSurveyButton,
            updateScoreButton, showStatus, isScorePopupOpen } = this.props;
        return isScorePopupOpen ? (
            <div className={css(styles.container)}>
                <RefHolder/>
                <div className={css(styles.bg)}>
                    <div
                        className={css(styles.topBox)}
                        onClick={this.goToMe}
                        role="button"
                        tabIndex="0"
                        onKeyDown={onKeyPress.enter(this.goToMe)}>
                        <div className={css(layoutStyle.flexRow, layoutStyle.flexAlignCenter, styles.titleContainer)}>
                            <p className={css(styles.title)}>{title}</p>
                            {score ?<Core.Icon
                                onClick={this.openHealthSurveyInfo}
                                name="question-circle"
                                size={spacing.s3}
                                type="fa"
                                fill="solid"
                                color={baseColors.grey40}
                            /> : null}
                        </div>
                        {score ?
                            <>
                                <div className={css(layoutStyle.flexRow, layoutStyle.flexAlignCenter, styles.lastUpdatedSection)}>
                                    <p className={css(styles.lastUpdated)}>{updatedDate}</p>
                                    {showStatus ?
                                        <Core.Icon
                                            color={SCORE_STATUSES[status].color}
                                            name="triangle"
                                            type="fa"
                                            size={10}
                                            fill="solid"
                                            className={css(styles.triangle, this.isTriangleSuccess && styles.triangleRotate)}
                                        /> : null}
                                    <span>{updatedPercentage}</span>
                                </div>
                                <div className={css(layoutStyle.flexRow, layoutStyle.flexAlignCenter)}>
                                    <div className={css(styles.score)}>{score}</div>
                                    <div
                                        style={this.scoreColor}
                                        className={css(styles.scoreDescription)}>{scoreDescription}
                                    </div>
                                </div>
                            </> :
                            <Core.Button
                                type="outlined"
                                size="medium"
                                className={css(layoutStyle.fw, styles.takeSurveyBtn)}
                                onPress={this.takeHealthSurvey}>
                                {takeSurveyButton}
                            </Core.Button>
                        }
                    </div>
                    {score ?
                        <div
                            className={css(styles.bottomBox)}
                            onClick={this.takeHealthSurvey}
                            role="button"
                            tabIndex="0"
                            onKeyDown={onKeyPress.enter(this.takeHealthSurvey)}>
                            {updateScoreButton}
                        </div> : null}
                </div>
            </div>
        ) : null;
    }
}

export default withRouter(WithWellbeingScorePopupBase(WellbeingScorePopup));

const TOP = 48;
const WIDTH = 280;
const LH_SCORE = 42;
const LH_SCORE_DESC = 14;
const styles = StyleSheet.create({
    container: {
        position: 'absolute',
        top: TOP,
        width: WIDTH,
        color: baseColors.black,
        right: 0,
        paddingTop: spacing.s3
    },
    bg: {
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.06), 0px 4px 16px rgba(0, 0, 0, 0.15), 0px 4px 40px rgba(0, 0, 0, 0.15)',
        borderRadius: 3,
        backgroundColor: baseColors.white,
    },
    title: {
        ...appFonts.lgMedium,
        marginRight: spacing.s1
    },
    titleContainer: {
        marginBottom: spacing.s0
    },
    topBox: {
        padding: spacing.s3
    },
    bottomBox: {
        color: baseColors.secondary,
        textAlign: 'center',
        paddingTop: spacing.s2,
        paddingBottom: spacing.s3,
        borderTopColor: baseColors.grey80,
        borderTopWidth: 1,
        borderTopStyle: 'solid',
        ...appFonts.smRegular
    },
    lastUpdated: {
        ...appFonts.xsRegular,
        color: baseColors.grey40,
        marginRight: spacing.s1,
    },
    lastUpdatedSection: {
        marginBottom: spacing.s1,
    },
    score: {
        ...appFonts.xxxlRegular,
        fontSize: 48,
        lineHeight: `${LH_SCORE}px`,
        marginRight: spacing.s4
    },
    triangle: {
        marginRight: 2,
        marginTop: -2
    },
    triangleRotate: {
        transform: 'rotate(180deg)'
    },
    scoreDescription: {
        padding: spacing.s1,
        paddingTop: spacing.s0,
        paddingBottom: spacing.s0,
        borderRadius: spacing.s0,
        ...appFonts.smMedium,
        lineHeight: `${LH_SCORE_DESC}px`,
        marginTop: -spacing.s1
    },
    takeSurveyBtn: {
        marginTop: important(spacing.s5)
    }
});
