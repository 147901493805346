export const NAME = 'shared';

export const SPACING_WITH_POP_UP = 55;

export const filtersByTimeAddedOptions = [
    { id: 0, label: 'top_posts' },
    { id: 1, label: 'most_recent' },
];

export const IS_REDIRECTED_FROM_MY_TAB = 'isRedirectedFromMyTab';

export const EVENTS_TYPE = 'myItems';

export const NOT_PERSISTED = false;

export const IS_EVENT_PAGE = 'IS_EVENT_PAGE';