import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { translate } from '../../services';

export const REMOVE_ICON_NAME = 'times-circle';

export default function WithChipBase(WrappedComponent) {
    class ChipBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            onDelete: PropTypes.func.isRequired,
            item: PropTypes.object.isRequired
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                />
            );
        }
    }

    return translate()(ChipBase);
}