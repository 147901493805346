import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import { PLATFORMS, Platform, selectors as coreSelectors, openUrl, translate, tracker } from '../../../core';
import { actions as authActions, selectors as authSelectors } from '../../../auth';
import { actions as onboardingActions, selectors as onboardingSelectors } from '../../../onboarding';
import { actions as appsdeviceActions, selectors as appsdevicesSelectors } from '../../../appsdevices';
import {
    actions as settingsActions,
    selectors as settingsSelectors,
    constants as settingsConstants
} from '../../../settings';
import global from '../../../../config/globals';
import * as selectors from '../../../appsdevices/selectors';
import { baseColors, spacing, appFonts } from '../../../../styles';

export const helpSupportId = 'helpAndSupport';
export const termsServiceId = 'termsService';
export const privacyPolicyId = 'privacyPolicy';
export const disclaimerId = 'disclaimer';

export default function WithMoreBase(WrappedComponent) {
    class MoreBase extends PureComponent {
        static propTypes = {
            connectedDevices: PropTypes.array.isRequired,
            actions: PropTypes.object.isRequired,
            healthSurvey: PropTypes.object.isRequired,
            onboarding: PropTypes.object,
            companyTasks: PropTypes.array,
            i18n: PropTypes.object.isRequired,
            companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            partnerId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            isLiveBetter: PropTypes.bool,
            healthKit: PropTypes.object,
            navigation: PropTypes.object,
            feedbackMetadata: PropTypes.array,
            termsOfService: PropTypes.object,
            currentUser: PropTypes.object,
            hasDisclaimerStep: PropTypes.bool.isRequired,
            disclaimerAvailable: PropTypes.bool.isRequired,
            externalApps: PropTypes.array
        };

        static defaultProps = {
            onboarding: {},
            companyTasks: [],
            isLiveBetter: false,
            healthKit: null,
            navigation: {},
            feedbackMetadata: [],
            termsOfService: {},
            currentUser: {},
            externalApps: []
        };

        settingsRow = {
            id: 'settings',
            title: 'settings',
            route: 'settings',
            icon: 'cog'
        }

        appsDevicesRow = {
            id: 'appsAndDevices',
            title: 'appsAndDevices.header',
            subtitleTranslated: true,
            route: 'appsDevices',
            icon: 'watch-fitness',
            horizontalSubtitle: true
        }

        sectionTwo = {
            group1: this.props.isEAPEnabled ?
                [
                    this.settingsRow,
                    this.appsDevicesRow,
                    {
                        id: 'EAP',
                        title: 'eapSavingWithoutDataToast.danger.title',
                        route: 'reachGoal',
                        withoutEAP: !this.props.isEAPEnabled,
                        icon: 'hands-helping',
                        isLastChild: true
                    }
                ] : [
                    this.settingsRow,
                    {
                        ...this.appsDevicesRow,
                        isLastChild: true
                    }
                ]
        }

        sectionThree = {
            group1:
                [
                    {
                        id: 'feedback',
                        title: 'feedback',
                        route: 'feedback',
                        smallerPadding: true,
                    },
                    {
                        id: helpSupportId,
                        title: 'helpAndSupport',
                        route: 'helpAndSupport',
                        smallerPadding: true,
                    },
                    {
                        id: termsServiceId,
                        title: 'termsOfService',
                        smallerPadding: true,
                    },
                    {
                        id: privacyPolicyId,
                        title: 'privacyPolicy',
                        smallerPadding: true,
                    }
                ]
        }

        constructor(props) {
            super(props);

            props.actions.getCompanyTasks(props.companyId);
            props.actions.getCompanyFeatures(props.companyId);
            props.actions.updatePrivacyInformationText(global.isHiddenPrivacyInformationText);
            props.actions.getAppsDevices();
            props.actions.getFeedbackMetadata(this.props.partnerId);
            props.actions.getCompanyExternalApps();
        }

        componentWillMount() {
            this.getPolicies();
        }

        getPolicies = () => {
            const params = (this.props.currentUser.companyId) ? { companyId: this.props.currentUser.companyId } :
                { partnerId: this.props.currentUser.partnerId, isRetail: true };

            this.props.actions.getTermsOfService(params);
        };

        getHelpOptions = func => {
            if (_.isArray(this.props.feedbackMetadata)) {
                return _.filter(this.props.feedbackMetadata, option => (option.type === settingsConstants.LIST_ITEM_TYPES.faq || option.type === settingsConstants.LIST_ITEM_TYPES.help))
                    .map(option => ({
                        title: option.title,
                        onPress: () => func(option)
                    }));
            }
            return [];
        };

        getLegalOptions = func => {
            const { i18n } = this.props;
            return [
                {
                    title: _.capitalize(i18n.t('privacyPolicy')),
                    onPress: () => func(settingsConstants.LIST_ITEM_TYPES.policy)
                },
                {
                    title: _.capitalize(i18n.t('termsOfService')),
                    onPress: () => func(settingsConstants.LIST_ITEM_TYPES.terms)
                }
            ];
        };

        // TODO: If deeplink, check if can open. If not, and we also have app store URL, open that (for both iOS and Android).
        handleOpenApp = item => {
            const { externalApps } = this.props;

            const externalApp = _.find(externalApps, app => app.id === item.id);

            if (externalApp.name) {
                tracker.logEvent('IncludedApp_Click', { app: externalApp.name });
            }

            if (Platform.OS === PLATFORMS.web && externalApp.web_url) {
                openUrl(externalApp.web_url);
            }

            if (Platform.OS === PLATFORMS.ios && externalApp.ios_url) {
                openUrl(externalApp.ios_url);
            }

            if (Platform.OS === PLATFORMS.android && externalApp.android_url) {
                openUrl(externalApp.android_url);
            }
        }

        get termsOfServiceUrl() {
            const { termsOfService } = this.props;
            if (_.get(termsOfService, 'terms_url')) {
                return termsOfService.terms_url;
            }
            return undefined;
        }

        get sectionThreeWithDisclaimer() {
            if (this.props.hasDisclaimerStep && this.props.disclaimerAvailable) {
                return {
                    group1: [
                        ...this.sectionThree.group1,
                        {
                            id: disclaimerId,
                            title: 'disclaimer',
                            route: 'disclaimer',
                            smallerPadding: true,
                        }
                    ]
                };
            }

            return this.sectionThree;
        }

        get companyHasExternalApps() {
            return this.props.externalApps.length > 0;
        }

        get sectionOne() {
            const { externalApps } = this.props;
            if (!this.companyHasExternalApps) return undefined;

            return _.map(externalApps, app => ({
                id: app.id,
                title: app.name,
                subtitle: app.description,
                image: app.image_url
            }));
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    title={this.title}
                    sectionOne={this.sectionOne}
                    sectionTwo={this.sectionTwo}
                    sectionThree={this.sectionThreeWithDisclaimer}
                    getHelpOptions={this.getHelpOptions}
                    getLegalOptions={this.getLegalOptions}
                    termsOfService={this.termsOfServiceUrl}
                    companyHasExternalApps={this.companyHasExternalApps}
                    handleOpenApp={this.handleOpenApp}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const connectedDevices = appsdevicesSelectors.getConnectedDevices(state);
        const routeParams = _.get(ownProps, 'route.params');
        const routeSelectedTab = _.get(routeParams, 'selectedTab', undefined);
        return {
            routeSelectedTab,
            connectedDevices,
            healthSurvey: onboardingSelectors.getHealthSurvey(state),
            userId: coreSelectors.getCurrentUser(state).userId,
            companyId: coreSelectors.getCurrentUserCompanyId(state),
            companyTasks: settingsSelectors.getCompanyTasks(state),
            partnerId: coreSelectors.getCurrentUserPartnerId(state),
            isLoadingResultsStatus: onboardingSelectors.isLoadingHealthSurveyResultsStatus(state),
            isLiveBetter: coreSelectors.isLiveBetter(state),
            healthKit: _.find(connectedDevices, { vendor: 'healthKit' }),
            numDevices: selectors.getNumberOfConnectedDevices(state),
            syncStatus: selectors.getLastSyncDate(state),
            isEAPEnabled: onboardingSelectors.isEAPEnabled(state),
            feedbackMetadata: settingsSelectors.getFeedbackMetadata(state),
            termsOfService: authSelectors.getTermsOfService(state),
            currentUser: coreSelectors.getCurrentUser(state),
            hasDisclaimerStep: authSelectors.hasDisclaimerStep(state),
            disclaimerAvailable: authSelectors.disclaimerAvailable(state),
            externalApps: authSelectors.getExternalApps(state)
        };
    };

    const mapDispatchToProps = dispatch => ({
        actions: bindActionCreators(
            {
                ...settingsActions,
                ...authActions,
                ...onboardingActions,
                ...appsdeviceActions,
            }, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(translate()(MoreBase));
}

export const styles = {
    contentContainer: {
        paddingTop: spacing.s3,
        paddingBottom: spacing.s3,
        marginLeft: spacing.s3,
        marginRight: spacing.s3,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flex: 1,
    },
    textWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        ...appFonts.mdMedium,
    },
    subtitle: {
        ...appFonts.smRegular,
        color: baseColors.grey40,
        paddingTop: spacing.s0,
    },
    challengesCount: {
        ...appFonts.mdMedium,
    },
    challengesSubtitle: {
        ...appFonts.mdRegular,
        color: baseColors.grey40,
        paddingLeft: spacing.s0,
    },
    icon: {
        marginRight: spacing.s3,
    },
    image: {
        marginRight: spacing.s3,
        width: spacing.s5,
        height: spacing.s5,
    },
    titleEmptyState: {
        color: baseColors.secondary,
        ...appFonts.smMedium,
    },
    circleIcon: {
        marginRight: spacing.s1,
        marginLeft: spacing.s1
    },
    appsDevicesSubtitle: {
        alignItems: 'center',
        display: 'flex',
    },
    membersAvatar: {
        marginLeft: -spacing.s1,
        borderWidth: 1,
        borderColor: baseColors.white
    },
    externalAppsSectionTitle: {
        ...appFonts.mdRegular,
        color: baseColors.grey40,
        paddingLeft: spacing.s2
    },
    externalAppIcon: {
        height: spacing.s9,
        width: spacing.s9,
        marginRight: spacing.s3,
    },
    externalAppTitle: {
        ...appFonts.mdMedium,
        color: baseColors.black
    },
    externalAppSubtitle: {
        ...appFonts.smRegular,
        color: baseColors.grey40
    },
    externalSubtitleContainer: {
        paddingTop: spacing.s0
    }
};
