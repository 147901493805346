import moment from 'moment-timezone';

export default function getTimezone() {
    return moment.tz.guess();
}

export function getTimezoneOffset() {
    return new Date().getTimezoneOffset();
}

export function getTimezoneHeader() {
    return { 'X-Time-Zone': getTimezone(), 'Time-Zone-Offset': getTimezoneOffset() };
}