import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { spacing } from '../../../../styles';
import { getMyPersonalGoalsLimited } from '../../selectors';
import * as actions from '../../actions';

export default function WithGoalIconsSectionBase(WrappedComponent) {
    class GoalIconsSectionBase extends PureComponent {
        static propTypes = {
            className: PropTypes.string,
            setGoals: PropTypes.array,
            goals: PropTypes.array,
            iconSize: PropTypes.number,
            iconClassName: PropTypes.string,
            actions: PropTypes.object.isRequired,
            onRef: PropTypes.func,
            loadChallenges: PropTypes.bool,
        };

        static defaultProps = {
            className: '',
            setGoals: [],
            goals: [],
            iconSize: spacing.s11,
            iconClassName: '',
            onRef: undefined,
            loadChallenges: true,
        };

        constructor(props) {
            super(props);
            if (props.loadChallenges) {
                this.props.actions.getPersonalGoalsAndChallenges({ isSolo: 1, isEnded: 0, isUnstarted: 0, isCompleted: undefined });
            }
        }

        nodes = [];

        get hasSetGoals() {
            return !!this.props.goals.length;
        }

        get goals() {
            // removed default icons of unset goals & disabled 'slideTo' animation
            return this.hasSetGoals ? this.props.goals : this.props.setGoals;
        }

        getSlug = goal => this.hasSetGoals && _.has(goal, 'categorySlug') ? goal.categorySlug : undefined;

        keyExtractor = (goal, index) => _.get(goal, 'challengeId') || _.get(goal, 'goalName') || index;

        onRef = index => node => {
            if (!this.props.onRef) return;
            this.nodes[index] = node;
            this.props.onRef(this.nodes);
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    getSlug={this.getSlug}
                    goals={this.goals}
                    keyExtractor={this.keyExtractor}
                    onRef={this.onRef}
                />
            );
        }
    }

    const mapStateToProps = state => ({ setGoals: getMyPersonalGoalsLimited(state) });

    const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

    return connect(mapStateToProps, mapDispatchToProps)(GoalIconsSectionBase);
}

export const styles = {
    iconContainer: {
        flexDirection: 'row'
    }
};
