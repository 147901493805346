import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite-jss';
import { layoutStyle } from '../../../../styles';
import WithTagBase, { styles as baseStyles } from './TagBase';

const Tag = ({ label, className, chipClassName, chipTextClassName }) => (
    <div className={css(layoutStyle.flexRowCenter, className)}>
        <div className={css(styles.chip, layoutStyle.flexRow, chipClassName)}>
            <p className={css(styles.chipText, layoutStyle.flexRow, chipTextClassName)}>{label}</p>
        </div>
    </div>
);

Tag.propTypes = {
    label: PropTypes.string,
    className: PropTypes.object,
    chipClassName: PropTypes.object,
    chipTextClassName: PropTypes.object,
};

Tag.defaultProps = {
    label: null,
    className: null,
    chipClassName: null,
    chipTextClassName: null,

};

export default WithTagBase(Tag);

const styles = StyleSheet.create({
    ...baseStyles
});

