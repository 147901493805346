import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { appFonts, baseColors, spacing } from '../../../../../styles';
import { translate } from '../../../../core';
import * as selectors from '../../../selectors';
import { isAfterDeadline, isTeamFull, teamSpotsLeft, teamMembersCountText, teamInvitedByDetails, teamIsInvitedByVisible } from '../../../services/helper';

export default function WithJoinTeamsItemBase(WrappedComponent) {
    class JoinTeamsItemBase extends PureComponent {
        static propTypes = {
            challengeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            actions: PropTypes.object.isRequired,
            history: PropTypes.object.isRequired,
            i18n: PropTypes.object.isRequired,
            joinedTeamCallback: PropTypes.func,
            challenge: PropTypes.object.isRequired,
            error: PropTypes.object,      //eslint-disable-line
            team: PropTypes.object.isRequired,
            joinTeam: PropTypes.func.isRequired,
            isPage: PropTypes.bool,
            isJoiningChallenge: PropTypes.bool,
            isJoining: PropTypes.bool,
        };

        static defaultProps = {
            error: undefined,
            isPage: true,
            joinedTeamCallback: undefined,
            isJoiningChallenge: false,
            isJoining: false
        };

        get isCompleted() {
            if (!this.props.challenge) return false;
            return isAfterDeadline(this.props.challenge);
        }

        get isFull() {
            return isTeamFull(this.props.team);
        }

        get spotsLeft() {
            return teamSpotsLeft(this.props.team);
        }

        get membersCount() {
            if (!this.props.team.userCount) return undefined;
            return teamMembersCountText(this.props.team);
        }

        get isInvitedByVisible() {
            return teamIsInvitedByVisible(this.props.team);
        }

        get invitedByDetails() {
            return teamInvitedByDetails(this.props.team);
        }

        joinTeam = () => {
            this.props.joinTeam(this.props.team);
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    isJoinVisible={this.isJoinVisible}
                    membersCount={this.membersCount}
                    joinTeam={this.joinTeam}
                    isTeamFull={this.isFull}
                    isInvitedByVisible={this.isInvitedByVisible}
                    invitedByDetails={this.invitedByDetails}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const challengeId = ownProps.challengeId || _.get(ownProps, 'match.params.challengeId');
        return {
            challengeId,
            error: selectors.getJoiningError(state),
            challenge: selectors.getChallenge(state, challengeId),
            isJoiningChallenge: selectors.isJoiningChallenge(state, challengeId),
            isJoining: selectors.isJoiningChallengeByTeamId(state, ownProps.team.entityId),
        };
    };

    return connect(mapStateToProps)(translate()(JoinTeamsItemBase));
}

export const styles = {
    rowTitleStyle: {
        ...appFonts.mdMedium,
        color: baseColors.black,
    },
    rowSubtitle: {
        color: baseColors.grey40,
        ...appFonts.smRegular,
    },
    avatar: {
        marginRight: spacing.s3
    },
    smallAvatar: {
        marginRight: spacing.s0
    },
    teamDetailsRow: {
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
    },
    dotIcon: {
        marginLeft: spacing.s1,
        marginRight: spacing.s1,
    },
    detailsSection: {
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    },
    detailsPadding: {
        paddingTop: spacing.s0
    }
};
