import _ from 'lodash';
import * as types from './actionTypes';
import { actionTypes as coreActionTypes } from '../core';
import { TYPES } from './constants';

export const initialState = {
    items: {},
    [TYPES.RECOMMENDED]: [],
    [TYPES.ATTENDED]: [],
    [TYPES.INVITATIONS]: [],
    [TYPES.RECENT]: [],
    [TYPES.ALL]: [],
    numAttended: 0,
    numInvites: 0,
    numRecommended: 0,
    numRecent: 0,
    numAll: 0,
    refreshTime: 0,
    members: {
        items: {},
        ids: {}
    },
    loading: {},
    error: {},
    newCommunity: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.GET_COMMUNITIES.SUCCESS: {
            const { isReplace, filter, data: { items, ids } } = action.payload;
            const idsArray = isReplace ? ids : _.uniq([...state[filter], ...ids]);
            return {
                ...state,
                items: _.merge({}, state.items, items),
                [filter]: idsArray,
                [action.payload.countField]: action.payload.count,
                refreshTime: new Date().getTime(),
            };
        }
        case types.GET_SINGLE_COMMUNITY.SUCCESS: {
            const { newItem, id } = action.payload;
            return {
                ...state,
                items: { ...state.items, [id]: { ...state.items[id], ...newItem } },
            };
        }
        case types.JOIN_COMMUNITY.SUCCESS: {
            const { data } = action.payload;
            return {
                ...state,
                items: {
                    ...state.items,
                    [data.id]: { ...state.items[data.id], ...data },
                },
                numInvites: action.payload.isInvitation ? state.numInvites - 1 : state.numInvites,
                numAttended: state.numAttended + 1,
                [TYPES.INVITATIONS]: _.filter(state[TYPES.INVITATIONS], item => item !== data.id)
            };
        }
        case types.LEAVE_COMMUNITY.SUCCESS: {
            return {
                ...state,
                items: {
                    ...state.items,
                    [action.payload.id]: { ...state.items[action.payload.id],
                        isInvitation: false,
                        isMember: false,
                        members: _.filter(state.items[action.payload.id].members, member => member.userId.toString() !== action.payload.userId.toString()),
                        numMembers: state.items[action.payload.id].numMembers - 1 },
                    numAttended: state.numAttended - 1,
                },
                [TYPES.ATTENDED]: _.filter(state[TYPES.ATTENDED], item => item !== action.payload.id)
            };
        }
        case types.DELETE_COMMUNITY.SUCCESS: {
            return {
                ...state,
                items: _.omit(state.items, [action.payload.id]),
                [TYPES.ATTENDED]: _.filter(state[TYPES.ATTENDED], item => item !== action.payload.id),
                [TYPES.RECOMMENDED]: _.filter(state[TYPES.RECOMMENDED], item => item !== action.payload.id),
                [TYPES.INVITATIONS]: _.filter(state[TYPES.INVITATIONS], item => item !== action.payload.id),
                [TYPES.RECENT]: _.filter(state[TYPES.RECENT], item => item !== action.payload.id),
                [TYPES.ALL]: _.filter(state[TYPES.ALL], item => item !== action.payload.id),
            };
        }
        case types.UPDATE_NEW_COMMUNITY: {
            const newCommunity = action.payload ? { ...state.newCommunity, ...action.payload } : {};

            return { ...state, newCommunity };
        }

        case types.UPDATE_CREATE_COMMUNITY_STEP: {
            return { ...state, createCommunityStep: action.payload.step };
        }
        case types.UPDATE_COMMUNITY_SUCCESS: {
            const { data } = action.payload;
            return {
                ...state,
                items: { ...state.items, [data.communityId]: { ...state.items[data.communityId], ...data } }
            };
        }
        case types.CREATE_COMMUNITY.SUCCESS: {
            return {
                ...state,
                items: {
                    ...state.items,
                    [action.payload.data.id]: action.payload.data
                },
                numAttended: state.numAttended+1,
                [TYPES.ATTENDED]: [
                    action.payload.data.id,
                    ...state[TYPES.ATTENDED],
                ],
                [TYPES.RECENT]: [
                    action.payload.data.id,
                    ...state[TYPES.RECENT],
                ],
                lastCreatedId: action.payload.data.id
            };
        }
        case types.EDIT_COMMUNITY.SUCCESS: {
            const { data, isReplace } = action.payload;
            return {
                ...state,
                items: {
                    ...state.items,
                    [data.id]: !isReplace ? { ...state.items[data.id], ...data } :
                        {
                            ...data,
                            isNotificationShown: _.get(state.items, [data.id, 'isNotificationShown'], false)
                        }
                }
            };
        }
        case types.GET_COMMUNITY_MEMBERS.SUCCESS: {
            const { communityId, data: { items, ids }, isReplace } = action.payload;
            return {
                ...state,
                members: {
                    ...state.members,
                    items: {
                        ...state.members.items,
                        ...items
                    },
                    ids: {
                        ...state.members.ids,
                        [communityId]: isReplace ? ids : [
                            ...state.members.ids[communityId],
                            ...ids
                        ]
                    }
                }
            };
        }
        case coreActionTypes.RESET:
            return initialState;
        default:
            return state;
    }
};
