import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { spacing } from '../../../../../styles';

export default function WithContainerBase(WrappedComponent) {
    class CardContainerBase extends PureComponent {
        static propTypes = {
            onPress: PropTypes.func,
            children: PropTypes.node.isRequired,
            className: PropTypes.string,
            isDisabled: PropTypes.bool
        };

        static defaultProps = {
            onPress: undefined,
            className: undefined,
            isDisabled: false,
        };

        onPress = () => {
            if (this.props.onPress && !this.props.isDisabled) this.props.onPress();
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    onPress={this.props.onPress && this.onPress}
                />
            );
        }
    }

    return CardContainerBase;
}

export const BASE_CARD_HEIGHT = 380;

export const styles = {
    cardWrapper: {
        flex: 1,
        width: '100%',
        padding: spacing.s0,
    },
    cardContainer: {
        backgroundColor: 'transparent',
        borderRadius: spacing.s1,
        borderWidth: 0,
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
};
