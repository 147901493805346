import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { appFonts, baseColors, spacing } from '../../../../styles';
import { actions as coreActions, selectors as coreSelectors, translate, openInapropriateContentAlert,
    ALERT_TIMEOUT, timeout, validation, Platform, PLATFORMS } from '../../../core';
import * as actions from '../../actions';
import { STREAM_ITEM_TYPE_SLUGS } from '../../constants';
import * as selectors from '../../selectors';
import { selectors as settingsSelectors, constants as settingsConstants } from '../../../settings';
import { selectors as challengesSelectors } from '../../../challenges';

const PREVIEW_TYPE = 'post';
const REQUEST_ID = 'calendar';

const REFERENCED_NAMES = {
    friendsAndFamily: 'familyfriends',
    location: 'location',
    me: 'me'
};

export default function WithFeedPostEditorBase(WrappedComponent) {
    class FeedPostEditorBase extends Component {
        static propTypes = {
            filters: PropTypes.array,
            actions: PropTypes.object,
            post: PropTypes.object,
            postParams: PropTypes.object,
            showVisibilityControl: PropTypes.bool,
            isTracking: PropTypes.bool,
            callback: PropTypes.func,
            isLoading: PropTypes.bool,
            i18n: PropTypes.object,
            user: PropTypes.object.isRequired,
            setTimeout: PropTypes.func.isRequired,
            navigation: PropTypes.object.isRequired,
            contentError: PropTypes.object,
            activitiesError: PropTypes.object
        };

        static defaultProps = {
            filters: [],
            actions: null,
            post: null,
            postParams: null,
            showVisibilityControl: false,
            isTracking: false,
            callback: null,
            isLoading: false,
            i18n: PropTypes.object,
            contentError: undefined,
            activitiesError: undefined
        };

        constructor(props) {
            super(props);
            this.state = {};
            this.state = {
                ...this.getInitConfig(),
                visibleTo: null,
                error: false
            };
        }

        componentDidMount() {
            this.clear();
        }

        componentDidUpdate(prevProps, prevState) {
            if (prevProps.isLoading && !this.props.isLoading) {
                const error = this.props.contentError || this.props.activitiesError;
                if (error) {
                    this.props.setTimeout(() => {
                        this.openErrorAlert(error.type);
                    }, ALERT_TIMEOUT);
                } else {
                    this.props.setTimeout(this.goBack);
                }
            }
        }

        clear = () => {
            this.props.actions.clearUrlPreview(PREVIEW_TYPE);
            this.clearError();
        };

        goBack = () => {
            this.clear();
            _.has(this, 'wrapped.goBack') && this.wrapped.goBack();
        };

        get showVisibilityControl() {
            return this.props.showVisibilityControl;
        }

        get isChangesPresent() {
            return !!(this.state.addedImage || this.state.addedImageUrl || this.state.validatedLink ||
                (this.state.postText && this.state.postText !== this._initText));
        }

        get isWeb() {
            return Platform.OS === PLATFORMS.web;
        }

        clearError = () => this.state.isEditing ? this.props.actions.clearUpdateStreamError() : this.props.actions.clearPostStreamError();

        createOrUpdatePost = () => {
            this.clearError();
            const { postText, validatedLink, addedImage, addedImageUrl } = this.state;
            if (!this.props.isTracking && !postText && !validatedLink && !addedImage) {
                this.setState(() => ({ error: true }));
                return;
            }
            //if there is a callback supplied as a prop to the feedpost editor, execute the callback instead of posting
            //one case of this is when we are making a custom post for activities
            //from the activityTrackingCard or SproutMultipleTrackActivities
            const addedLink = validatedLink;
            const text = addedLink ? postText.concat(' ', addedLink) : postText;
            if (this.props.callback) {
                const image = addedImage;
                const filterId = _.get(this.visibleTo, 'filterId');
                this.props.callback(filterId, text, image);
            } else if (!this.state.isEditing) {
                const streamItem = {
                    text,
                    streamItemTypeSlug: STREAM_ITEM_TYPE_SLUGS.status_update,
                    picture: addedImage,
                    ...this.props.postParams
                };
                if (this.showVisibilityControl) streamItem.shareWithFilterId = _.get(this.visibleTo, 'filterId');
                this.props.actions.postStream(streamItem);
            } else {
                let resultObject = {
                    streamItemId: this.props.post.streamItemId,
                    text
                };
                if (!addedImageUrl) {
                    resultObject = { ...resultObject, picture: addedImage ? addedImage : '' };
                }
                this.props.actions.updateStream(resultObject);
            }
        };

        onLinkChange = link => {
            if (!link) {
                this.props.actions.clearUrlPreview(PREVIEW_TYPE);
            } else {
                this.props.actions.getUrlPreview(PREVIEW_TYPE, link);
            }
            this.setState(() => ({ validatedLink: link }));
        };

        onFilterChange = filter => {
            this.setState(() => ({
                visibleTo: filter,
                error: false
            }));
            !this.isWeb && this.props.navigation.goBack();
        };

        openErrorAlert = type => {
            openInapropriateContentAlert(type, this.clearError, this.deletePhoto, this.wrapped.dismissAlert);
        };

        textChanged = postText => {
            this.setState({
                postText,
                error: false
            });
        };

        addPhoto = (data, fullData) => {
            this.setState({ addedImage: data, addedImageFull: fullData, addedImageUrl: '' });
        };

        deletePhoto = () => {
            this.setState({ addedImage: '', addedImageFull: '', addedImageUrl: '' });
        };

        get visibleTo() {
            return this.state.visibleTo || this.getVisibleTo();
        }

        getVisibleTo = () => {
            const { filters } = this.props;
            if (this.props.isTracking) {
                if (this.isChangesPresent) {
                    const friendsFilter = _.find(filters, { referenceName: REFERENCED_NAMES.friendsAndFamily });
                    return friendsFilter || _.find(filters, { referenceName: REFERENCED_NAMES.location }) || filters[0];
                }
                return _.find(filters, { referenceName: REFERENCED_NAMES.me }) || filters[0];
            }
            const defaultFilter = filters.filter(input => input.isDefault);
            return defaultFilter.length ? defaultFilter[0] : filters[0];
        };

        getInitConfig() {
            const { post } = this.props;
            if (post && post.streamItemDisplay) {
                const media = post.streamItemDisplay.streamItemMedia;
                const img = media && media.type === 'image' ? media.src : '';
                const userText = post.streamItemDisplay.userText;
                const text = this._initText = userText.values.textRaw || '';
                const urlFromText = validation.hasUrlInStr(text);
                const validatedLink = urlFromText ? urlFromText[0] : '';
                let postText = text;
                if (urlFromText) {
                    _.forEach(urlFromText, link => {
                        postText = _.replace(postText, link, '');
                    });
                }
                return {
                    validatedLink,
                    postText: _.trim(postText),
                    addedImageUrl: img,
                    isEditing: true,
                    streamItemId: post.streamItemId
                };
            }
            this._initText = '';
            return { postText: '' };
        }

        get preview() {
            return _.get(this.props, 'preview.streamItemDisplay.streamItemMedia');
        }

        get image() {
            return this.state.addedImageFull || this.state.addedImageUrl;
        }

        get buttonText() {
            const { i18n } = this.props;
            if (this.state.isEditing) {
                return i18n.t('save');
            } else if (this.props.isTracking) {
                return i18n.t('submit');
            }
            return i18n.t('postButton');
        }

        get subtitle() {
            const { isTracking, i18n } = this.props;
            return isTracking ? i18n.t('feeds.postActivity') : i18n.t('shareYourDaySubtitle');
        }

        saveRef = ref => (this.wrapped = ref);

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    ref={this.saveRef}
                    postText={this.state.postText}
                    image={this.image}
                    validatedLink={this.state.validatedLink}
                    preview={this.preview}
                    textChanged={this.textChanged}
                    deletePhoto={this.deletePhoto}
                    addPhoto={this.addPhoto}
                    onFilterChange={this.onFilterChange}
                    onLinkChange={this.onLinkChange}
                    createOrUpdatePost={this.createOrUpdatePost}
                    showVisibilityControl={this.showVisibilityControl}
                    isEditing={this.state.isEditing}
                    isChangesPresent={this.isChangesPresent}
                    openErrorAlert={this.openErrorAlert}
                    visibleTo={this.visibleTo}
                    error={this.state.error}
                    buttonText={this.buttonText}
                    clearError={this.clearError}
                    subtitle={this.subtitle}
                    clear={this.clear}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        const isLoadingActivities = challengesSelectors.isTracking(state, REQUEST_ID) || challengesSelectors.isDeletingActivityLogs(state, REQUEST_ID);
        const postParams = ownProps.postParams || _.get(ownProps, 'location.state.postParams') || _.get(ownProps, 'route.params.postParams');
        const post = selectors.getFeed(state, ownProps.streamItemId || _.get(ownProps, 'match.params.streamItemId')|| _.get(ownProps, 'route.params.streamItemId'));
        const showVisibilityControl = ownProps.showVisibilityControl || _.get(ownProps, 'location.state.showVisibilityControl') || _.get(ownProps, 'route.params.showVisibilityControl');
        return {
            post,
            showVisibilityControl,
            postParams,
            filters: selectors.getShareWithFiltersForSelect(state),
            isLoading: selectors.isPostingOrEditing(state) || isLoadingActivities,
            contentError: selectors.getContentError(state),
            preview: coreSelectors.getPreview(state, PREVIEW_TYPE),
            user: coreSelectors.getCurrentUser(state),
            isPrivacyHiddenTextOrNotEnabled: settingsSelectors.isHiddenTextOrNotEnabled(state,
                settingsConstants.PRIVACY_SLUGS.NEW_POST_INFO),
            activitiesError: challengesSelectors.getTrackError(state, REQUEST_ID) || challengesSelectors.getDeletingActivityLogsError(state, REQUEST_ID),
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators({ ...actions, ...coreActions }, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(timeout(FeedPostEditorBase)));
}

export const styles = {
    commentIcon: {
        marginRight: spacing.s0,
        alignSelf: 'center'
    },
    mainTextInput: {
        ...appFonts.mdRegular,
        flex: 1,
        paddingRight: spacing.s3,
        paddingTop: spacing.s2,
        color: baseColors.grey20,
        textAlignVertical: 'top'
    },
    footer: {
        flexDirection: 'row',
        padding: spacing.s3,
        backgroundColor: baseColors.white,
        borderColor: baseColors.grey70,
        borderTopWidth: 1
    },
    postButtonText: {
        ...appFonts.mdMedium,
        color: baseColors.secondary
    },
    footerButtonText: {
        ...appFonts.mdBold,
        color: baseColors.secondary
    },
    errorContainer: {
        paddingLeft: spacing.s1,
        paddingRight: spacing.s1,
    },
    errorText: {
        ...appFonts.xsRegular,
        marginTop: spacing.s0,
        marginBottom: spacing.s0,
        marginLeft: 0,
        paddingLeft: 0,
    },
    inputValidationError: {
        color: baseColors.dangerDarker,
        marginHorizontal: spacing.s2,
        ...appFonts.xsRegular
    },
    select: {
        marginTop: -spacing.s2,
        justifyContent: 'center',
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3
    }
};
