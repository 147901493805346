import { createSelector } from 'reselect';
import _ from 'lodash';
import * as types from './actionTypes';
import { NAME } from './constants';
import { selectors as coreSelectors, getRequestId } from '../core';

export const getAll = state => state[NAME].items;
const getAllIds = state => state[NAME].ids;
export const getUsers = createSelector(getAll, getAllIds, (items, ids) => _.filter(_.map(ids, id => items[id]), _.identity));
export const getUsersCount = state => state[NAME].usersCount;

const getPlatform = state => state[NAME].platform;
const getAllPlatformUsers = state => getPlatform(state).items;
export const getPlatformUsersIds = state => _.filter(getPlatform(state).ids, item => {
    return _.toNumber(item) !== _.get(coreSelectors.getCurrentUser(state), 'userId');
});
export const getPlatformUsersCount = state => getPlatform(state).count;
export const getPlatformUser = (state, id) => getAllPlatformUsers(state)[id];

const getPlatformInvitations = state => state[NAME].platformInvitations;
export const getPlatformInvitationsIds = state => getPlatformInvitations(state).ids;
export const getPlatformInvitationsItems = state => getPlatformInvitations(state).items;
export const getPlatformInvitationsWithEmail = createSelector(getPlatformInvitationsItems, getPlatformInvitationsIds,
    (items, ids) => _.filter(_.map(ids, id => items[id]), item => _.get(item, 'inviteeEmail', '')));
export const getAllPlatformInvitations = createSelector(getPlatformInvitationsItems, getPlatformInvitationsIds,
    (items, ids) => _.filter(_.map(ids, id => items[id]), _.identity));
export const getAcceptedPlatformInvitations = createSelector(getAllPlatformInvitations, items => _.filter(items, { accepted: 1 }));
export const getAcceptedPlatformUser = (state, id) => getPlatformInvitationsItems(state)[id];

export const usersLoading = (state, id) => coreSelectors.isLoading(state, `${types.GET_USERS_TO_INVITATION.NAME}/${id}`);
export const invitationSending = state => coreSelectors.isLoading(state, types.INVITE_TO_ENTITY.NAME);

export const isLoadingPlatformUsers = state =>
    coreSelectors.isLoading(state, types.GET_USERS_TO_PLATFORM_INVITATION.NAME);
export const isLoadingMorePlatformUsers = state =>
    coreSelectors.isLoading(state, types.GET_USERS_TO_PLATFORM_INVITATION_MORE.NAME);

export const isLoadingPlatformInvitations = state => coreSelectors.isLoading(state, types.GET_PLATFORM_INVITATIONS.NAME);
export const isSendingPlatformInvitation =
    (state, id) => coreSelectors.isLoading(state, getRequestId(types.SEND_PLATFORM_INVITATIONS.NAME, id));
export const isDeletingPlatformInvitation =
    (state, id) => coreSelectors.isLoading(state, getRequestId(types.DELETE_PLATFORM_INVITATION.NAME, id));

export const getUserByEmail = state => state[NAME].userByEmail;
export const isGettingUserByEmail = state => coreSelectors.isLoading(state, types.GET_USER_BY_EMAIL.NAME);

export const isShownLearnFriendsAndFamilyModal = state => state[NAME].isShownLearnFriendsAndFamilyModal;
