import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import {
    translate,
    selectors as coreSelectors,
    actions as coreActions,
    constants as coreConstants
} from '../../../core';
import * as actions from '../../actions';
import { selectors as onboardingSelectors } from '../../../onboarding';
import { getTask, isEAPSaving, getOptions, isEAPLoading } from '../../selectors';
import { baseColors, spacing, appFonts } from '../../../../styles';

const TIME_OPTIONS = {
    morning: 'morning',
    afternoon: 'afternoon',
    evening: 'evening'
};

const PHONE_MIN_LENGTH = 4;

export default function WithContactBase(WrappedComponent) {
    class ContactBase extends PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            options: PropTypes.array,
            task: PropTypes.object.isRequired,
            user: PropTypes.object.isRequired,
            isLoading: PropTypes.bool,
            isSaving: PropTypes.bool,
            callback: PropTypes.func,
            onNext: PropTypes.func,
            selected: PropTypes.array,
            step: PropTypes.string
        };

        static defaultProps = {
            options: [],
            selected: [],
            isLoading: false,
            isSaving: false,
            onNext: null,
            callback: null,
            step: null
        };

        constructor(props) {
            super(props);
            const { i18n } = this.props;
            this.timeOptions = [{
                id: TIME_OPTIONS.morning,
                label: i18n.t('eap.contact.morning')
            }, {
                id: TIME_OPTIONS.afternoon,
                label: i18n.t('eap.contact.afternoon')
            }, {
                id: TIME_OPTIONS.evening,
                label: i18n.t('eap.contact.evening')
            }];
            this.state = {
                contactValue: null,
                errorPhoneNumber: '',
                unmaskedValue: '',
                phoneNumber: '',
                time: this.timeOptions[1]
            };
            if (_.isEmpty(props.task)) {
                this.props.actions.getEAP();
            }
            this.wrapped = React.createRef();
        }

        updateContactInfo = value => this.setState({ contactValue: value });

        updatePhoneNumber = phoneNumber => {
            const unmaskedValue = _.join(phoneNumber.match(/\d+/g), '');
            this.setState({ phoneNumber, unmaskedValue });
        };

        onChangeTime = time => this.setState({ time });

        get errorPhoneNumber() {
            const { unmaskedValue } = this.state;
            if (!unmaskedValue.length) {
                return this.props.i18n.t('eap.phoneNumber.empty');
            } else if (unmaskedValue.length < PHONE_MIN_LENGTH) {
                return this.props.i18n.t('eap.phoneNumber.missingDigits');
            }
            return '';
        }

        get phoneNumberLabel() {
            return `${this.props.i18n.t('phoneNumber')} *`;
        }

        submitForm = () => {
            const { i18n } = this.props;
            const { time, contactValue, unmaskedValue } = this.state;
            const errorPhoneNumber = this.errorPhoneNumber;
            this.setState({ errorPhoneNumber });
            if (!errorPhoneNumber) {
                const selectedArr = _.map(this.props.selected, item => item.headTitle);
                this.props.actions.saveEAP(
                    selectedArr,
                    i18n.t('eap.contact.message', {
                        weekDay: time.label,
                        phoneNumber: unmaskedValue,
                        additional: contactValue
                    })
                );
                if (this.props.onNext) this.props.onNext(this.props.step);
                _.has(this.wrapped, 'current.onContactMe') && this.wrapped.current.onContactMe();
            } else this.props.actions.addToast(coreConstants.TOAST_TYPES.DANGER, 'eapSavingWithoutData');
        };

        render() {
            return (
                <WrappedComponent
                    ref={this.wrapped}
                    {...this.props}
                    contactValue={this.state.contactValue}
                    updateContactInfo={this.updateContactInfo}
                    submitForm={this.submitForm}
                    phoneNumber={this.state.phoneNumber}
                    updatePhoneNumber={this.updatePhoneNumber}
                    timeOptions={this.timeOptions}
                    selectedTime={this.state.time}
                    onChangeTime={this.onChangeTime}
                    phoneNumberLabel={this.phoneNumberLabel}
                    errorPhoneNumber={this.state.errorPhoneNumber}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        return {
            task: getTask(state),
            user: coreSelectors.getCurrentUser(state),
            isSaving: isEAPSaving(state),
            isLoading: isEAPLoading(state),
            selected: getOptions(state),
            isShowingOnboarding: onboardingSelectors.isShowingOnboarding(state),
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators({ ...actions, ...coreActions }, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(ContactBase));
}

const INPUT_HEIGHT = 160;
export const styles = {
    container: {
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3,
    },
    formContainer: {
        height: INPUT_HEIGHT
    },
    footer: {
        flexDirection: 'row',
        padding: spacing.s3
    },
    policyText: {
        alignSelf: 'center',
        marginBottom: spacing.s3
    },
    policyTextButton: {
        paddingLeft: spacing.s1
    },
    textBoxHeading: {
        marginTop: spacing.s5,
        color: baseColors.black,
        ...appFonts.mdMedium
    },
    textAreaContainer: {
        paddingBottom: spacing.s3
    },
    inputContainer: {
        paddingBottom: 0
    },
    disclaimer: {
        ...appFonts.smRegular,
        color: baseColors.black
    },
    disclaimerLink: {
        ...appFonts.smMedium,
        color: baseColors.secondary
    },
    timeContainer: {
        marginBottom: 0
    },
    timeWrapperStyle: {
        flex: 1,
        marginLeft: 0
    },
    timeSelectHeader: {
        ...appFonts.smMedium,
        color: baseColors.black,
        marginBottom: spacing.s0,
        marginLeft: 0
    },
    timeSubtitleContainer: {
        marginLeft: 0
    }
};