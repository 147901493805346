import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as actions from '../../actions';
import { getHealthObjectives, isSavingObjectives, getStartTime } from '../../selectors';
import { FIREBASE_NAMES } from '../../constants';
import PreferencesBase from '../PreferencesBase';

export default function WithHealthObjectivesBase(WrappedComponent) {
    class HealthObjectivesBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            healthObjectives: PropTypes.object.isRequired,
            hideBottomButton: PropTypes.bool,
            isSaving: PropTypes.bool,
            error: PropTypes.bool,
            i18n: PropTypes.object.isRequired,
        };

        static defaultProps = {
            hideBottomButton: false,
            isSaving: false,
            error: false
        };

        constructor(props) {
            super(props);
            this.props.actions.getHealthObjectives();
        }

        get title() {
            return this.props.i18n.t('personalize.objectives.title');
        }

        get description() {
            return this.props.i18n.t('personalize.objectives.description');
        }

        render() {
            return (
                <PreferencesBase
                    {...this.props}
                    selectData={this.props.healthObjectives.answers}
                    hideBottomButton={this.props.hideBottomButton}
                    wrappedComponent={WrappedComponent}
                    saveData={this.props.actions.saveHealthObjectives}
                    title={this.title}
                    description={this.description}
                />
            );
        }
    }
    function mapStateToProps(state, ownProps) {
        const routeParams = _.get(ownProps, 'route.params');
        return {
            healthObjectives: getHealthObjectives(state),
            isSaving: isSavingObjectives(state),
            startTime: getStartTime(state, FIREBASE_NAMES.personalize),
            ...(routeParams || {})
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        };
    }
    return connect(mapStateToProps, mapDispatchToProps)(HealthObjectivesBase);
}