import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate, entitiesHelper, constants as coreConstants, tracker } from '../../../core';
import * as eventsActions from '../../actions';
import * as selectors from '../../selectors';
import { eventStartEndString } from '../../services/eventHelper';
import { appFonts, baseColors, detailsImageSize, spacing } from '../../../../styles';

export default function WithEventHeaderBase(WrappedComponent) {
    class EventHeaderBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object,
            event: PropTypes.object,
            i18n: PropTypes.object.isRequired,
            isJoiningEvent: PropTypes.bool
        };

        static defaultProps = {
            event: {},
            actions: {},
            isJoiningEvent: false
        };

        eventTime = (dateFormat, isEndTime) => moment(isEndTime ? this.item.eventEndDateTime : this.item.eventDateTime).format(coreConstants.DATE_FORMATS[dateFormat]);

        joinEvent = () => {
            tracker.logEvent('join_event', { id: this.item.eventId, stream: false });
            this.props.actions.joinEvent(this.item.eventId);
        };

        get isEnded() {
            return entitiesHelper.isAfterDeadline(this.item.eventDateTime);
        }

        get item() {
            return this.props.event;
        }

        get owner() {
            return _.get(this.item, 'owner', {});
        }

        get eventType() {
            const { i18n, event } = this.props;
            return event.isPrivate ? i18n.t('private') : i18n.t('public');
        }

        get numMembersText() {
            const { i18n, event } = this.props;
            if (event.numEventAttendees === 1) {
                return i18n.t('oneEventAttendee');
            }
            return `${i18n.t('multipleEventAttendees', { num: event.numEventAttendees })}`;
        }

        get invitedByText() {
            const { event } = this.props;
            const invitations = _.get(event, 'eventInviters');
            if (_.get(event, 'isMember') || !_.get(invitations, 'length')) return '';
            return entitiesHelper.getInvitedByText(invitations);
        }

        get eventStartEndString() {
            return eventStartEndString(this.props.event);
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    item={this.item}
                    owner={this.owner}
                    isEnded={this.isEnded}
                    eventTime={this.eventTime}
                    eventType={this.eventType}
                    joinEvent={this.joinEvent}
                    invitedByText={this.invitedByText}
                    numMembersText={this.numMembersText}
                    eventStartEndString={this.eventStartEndString}
                />
            );
        }
    }


    function mapStateToProps(state, ownProps) {
        return {
            event: selectors.getEvent(state, ownProps.itemId),
            isJoiningEvent: selectors.isJoiningEvent(state, ownProps.itemId),
            attendees: selectors.getAttendees(state, ownProps.itemId)
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(eventsActions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(EventHeaderBase));
}

export const styles = {
    eventHeaderContainer: {
        flexDirection: 'column',
        marginBottom: spacing.s5,
        backgroundColor: baseColors.grey85
    },
    titleWrapper: {
        flex: 1,
        flexDirection: 'row'
    },
    eventImage: {
        height: detailsImageSize
    },
    textDetailsContainer: {
        marginRight: spacing.s3,
        alignItems: 'flex-start',
        flexDirection: 'row'
    },
    avatarListContainer: {
        margin: spacing.s3,
    },
    membersAvatar: {
        marginLeft: -spacing.s3,
        borderWidth: 2,
        borderColor: baseColors.white,
    },
    functionalButton: {
        marginLeft: spacing.s3,
        marginRight: spacing.s3,
    },
    functionalButtonWithPadding: {
        marginTop: spacing.s3
    },
    dateWrapper: {
        paddingLeft: spacing.s3,
        paddingRight: spacing.s3,
        alignItems: 'center',
        borderRightColor: baseColors.grey80,
        borderRightWidth: 1
    },
    month: {
        ...appFonts.smRegular,
        color: baseColors.secondary
    },
    day: {
        ...appFonts.xxxlBold
    },
    name: {
        ...appFonts.xlBold,
        marginLeft: spacing.s1
    },
    section: {
        marginBottom: spacing.s3,
        flexDirection: 'row'
    },
    eventLocation: {
        ...appFonts.mdMedium,
        color: baseColors.secondary
    },
    eventAddress: {
        ...appFonts.xsRegular,
        color: baseColors.secondary
    },
    regular: {
        ...appFonts.mdRegular
    },
    medium: {
        ...appFonts.mdMedium
    },
    icon: {
        marginRight: spacing.s3,
        marginTop: spacing.s0,
        color: baseColors.grey20
    },
    header: {
        backgroundColor: baseColors.white
    },
    subTitle: {
        ...appFonts.xsRegular,
        color: baseColors.grey40
    },
    subTitleWrapper: {
        flexDirection: 'row',
        marginLeft: spacing.s1
    },
    center: {
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: spacing.s0,
        marginRight: spacing.s0,
    },
    locationWrapper: {
        marginLeft: spacing.s3,
        marginRight: spacing.s3,
    },
    titleStyle: {
        display: 'none'
    },
    dateText: {
        ...appFonts.mdMedium,
        color: baseColors.grey20,
    },
    numMembersText: {
        ...appFonts.mdRegular,
        paddingLeft: spacing.s0,
    }
};
