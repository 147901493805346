import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite-jss';
import { components as Core } from '../../../../core';
import WithNotificationSectionBase, { styles as baseStyles } from './NotificationSectionBase';
import { spacing, layoutStyle, baseColors } from '../../../../../styles';

class NotificationSection extends PureComponent {
    static propTypes = {
        icon: PropTypes.node,
        leftIcon: PropTypes.node,
        children: PropTypes.node,
        detailColor: PropTypes.string,
        bg: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        textClassName: PropTypes.string,
        titleClassName: PropTypes.string,
        hasShadow: PropTypes.bool.isRequired,
        customNotificationClassName: PropTypes.object,
    };

    static defaultProps = {
        icon: undefined,
        leftIcon: undefined,
        children: undefined,
        textClassName: null,
        titleClassName: null,
        detailColor: baseColors.white,
        customNotificationClassName: undefined,
    };

    get leftIcon() {
        return this.props.leftIcon ? this.props.leftIcon: (
            <Core.Icon
                type="fa"
                name="lightbulb"
                size={spacing.s5}
                color={baseColors.contextDarkest}
                className={css(styles.lightbulb)}
            />
        );
    }

    render() {
        return (
            <div
                className={css(layoutStyle.flexRowCenter, styles.endedNotification, this.props.hasShadow && styles.shadow, this.props.customNotificationClassName)}
                style={{ backgroundColor: this.props.bg }}>
                <div className={css(layoutStyle.flexRowCenter)}>
                    {this.props.icon ? <div className={css(styles.icon)}>{this.props.icon}</div> : null}
                    <div>
                        <p className={classNames(css(styles.endedNotificationTitle), this.props.titleClassName)} style={{ color: this.props.detailColor }}>
                            {this.props.title}
                        </p>
                        {this.props.text ?
                            (
                                <p className={classNames(css(styles.endedNotificationSubTitle), this.props.textClassName)} style={{ color: this.props.detailColor }}>
                                    {this.props.text}
                                </p>
                            ) : null }
                    </div>
                </div>
                {this.props.children}
            </div>
        );
    }
}

export default WithNotificationSectionBase(NotificationSection);

const styles = StyleSheet.create({
    ...baseStyles,
    endedNotification: {
        ...baseStyles.endedNotification,
        justifyContent: 'space-between',
        borderRadius: spacing.s1,
    },
    shadow: {
        boxShadow: `0px 2px ${spacing.s1}px rgba(0, 0, 0, ${0.3})`
    },
    lightbulb: {
        marginBottom: 'auto',
        marginRight: spacing.s3
    }
});
