import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite-jss';
import classnames from 'classnames';

import WithChallengeImage, { styles as baseStyles } from './ChallengeImageBase';
import { components as Core } from '../../../core';
import { spacing } from '../../../../styles';


class ChallengeImage extends PureComponent {
    static propTypes = {
        isCarouselItem: PropTypes.bool,
        isProgramChallenge: PropTypes.bool.isRequired,
        isCorpChallenge: PropTypes.bool.isRequired,
        labelTitle: PropTypes.string.isRequired,
        labelIconName: PropTypes.string.isRequired,
        imageSize: PropTypes.object.isRequired,
        image: PropTypes.object.isRequired,
        imageClassName: PropTypes.object,
        hasImageContainMode: PropTypes.bool,
        labelBackgroundColor: PropTypes.object,
        resizeMode: PropTypes.string,
        hasRoundBorder: PropTypes.bool,
        labelText: PropTypes.string.isRequired,
    };

    static defaultProps = {
        isCarouselItem: false,
        hasImageContainMode: false,
        resizeMode: 'cover',
        hasRoundBorder: false,
        imageClassName: null,
        labelBackgroundColor: undefined
    };

    get renderImageWithLabel() {
        return (
            <div className={css(styles.imageContainer)}>
                <Core.ImageWithLabel
                    source={this.props.image}
                    className={classnames(css(styles.image, this.props.imageClassName))}
                    contentClassName={this.props.contentClassName}
                    onLoad={this.props.hasImageContainMode ? this.onLoadImage : null}
                    isCarouselItem={this.props.isCarouselItem}
                    labelTitle={this.props.labelTitle}
                    labelIconName={this.props.labelIconName}
                    labelBackgroundColor={this.props.labelBackgroundColor}
                    imageSize={this.props.imageSize}
                    hasRoundBorder={this.props.hasRoundBorder}
                    labelText={this.props.labelText}
                />
            </div>
        );
    }

    get renderImage() {
        const borderRadius = this.props.hasRoundBorder ? styles.exploreBorderRadius : null;
        return (
            <div style={this.props.imageSize}>
                <Core.Image
                    style={[styles.image, this.props.imageSize]}
                    src={this.props.image}
                    resizeMode={this.props.resizeMode}
                    className={css(styles.image, borderRadius, this.props.imageClassName, this.props.contentClassName)}
                    onLoad={this.props.hasImageContainMode ? this.onLoadImage : null}
                    alt="Challenge image"
                />
            </div>
        );
    }

    render() {
        return this.renderImage;
    }
}

export default WithChallengeImage(ChallengeImage);

const styles = StyleSheet.create({
    ...baseStyles,
    imageContainer: {
        borderRadius: spacing.s1
    },
    image: {
        flex: 1,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    exploreBorderRadius: {
        borderRadius: spacing.s1
    },
});
