import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { appFonts, baseColors, spacing } from '../../../../styles';

export default function WithExploreItemTitleBase(WrappedComponent) {
    class ExploreItemTitleBase extends PureComponent {
        static propTypes = {
            text: PropTypes.string,
            numberOfLines: PropTypes.number,
            color: PropTypes.string,
            style: PropTypes.oneOfType([PropTypes.object, PropTypes.number])
        };

        static defaultProps = {
            text: '',
            numberOfLines: 2,
            color: baseColors.black,
            style: undefined
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                />
            );
        }
    }

    return ExploreItemTitleBase;
}

export const styles = {
    mainContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: spacing.s0,
    },
    text: {
        ...appFonts.lgMedium,
    },
    labelContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: spacing.s1
    }
};
