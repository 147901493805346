import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { components as Core, ROUTES, CAROUSEL_TYPES, CSS_CLASSES } from '../../../core';
import challengeIcon from '../../../../image/explore/challenge@3x.png';
import ExploreCarouselItem from '../ExploreCarouselItem';
import WithExploreChallengesBase from './ExploreChallengesBase';
import { createChallenge } from '../../services';

class ExploreChallenges extends PureComponent {
    static propTypes = {
        isExtendedRole: PropTypes.bool,
        userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        actions: PropTypes.object.isRequired,
        challenges: PropTypes.array.isRequired,
        i18n: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        createTitle: PropTypes.string.isRequired,
        createButtonLabel: PropTypes.string.isRequired,
        exploreLoadingStatus: PropTypes.object
    };

    static defaultProps = {
        isExtendedRole: false,
        userId: undefined,
        exploreLoadingStatus: {}
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    viewAllAction = () => {
        !this.state.isSliding && this.props.history.push(ROUTES.challenges());
    };

    renderItem = item => (
        <ExploreCarouselItem
            challengeId={item.challengeId}
            key={item.id}
            isDisabled={this.state.isSliding}
        />
    );

    createChallenge = () => createChallenge({ finishRedirect: ROUTES.challenges() }, this.props.isExtendedRole, this.props.userId, this.props.actions);

    get createEntityComponent() {
        const { createTitle, createButtonLabel } = this.props;
        return (
            <Core.CreateEntity
                title={createTitle}
                buttonLabel={createButtonLabel}
                buttonHandler={this.createChallenge}
                image={challengeIcon}
            />
        );
    }

    render() {
        const { i18n, challenges, exploreLoadingStatus: { isLoadingAllExplore, initialLoadDataComplete } } = this.props;
        const noChallengesToDisplay = !isLoadingAllExplore && !challenges.length;
        return (
            <div className={CSS_CLASSES.explore}>
                <Core.Carousel
                    isLoading={isLoadingAllExplore || !initialLoadDataComplete}
                    parent={this}
                    type={noChallengesToDisplay ? CAROUSEL_TYPES.single : CAROUSEL_TYPES.multiple}
                    titleSize={Core.SubHeader.SIZES.large}
                    title={i18n.t('challenges')}
                    viewAllPress={this.viewAllAction}
                    slidesToScrollValue={4}
                    itemsLength={challenges.length}>
                    {noChallengesToDisplay ? this.createEntityComponent : _.map(challenges, this.renderItem)}
                </Core.Carousel>
            </div>
        );
    }
}

export default withRouter(WithExploreChallengesBase(ExploreChallenges));
